/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import portalApi, { adminPortalApi } from '../apis/v1/dashboard/portals';
import { IconType } from '../../groupware-common/types/icon';
import preferencesApi from '../apis/v1/dashboard/preferences';
import { templatesActions } from './templates';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

const name = 'dashboard/portals';

interface FolderItem {
  type: 'default' | 'setting';
  id: number;
  name: string;
  icon: IconType;
}

export interface PortalItem {
  id: number;
  name: string;
  seq: number;
  isInitial: boolean; // 초기 포탈 여부.
  isAdminPortal: boolean;
  updateAt: string;
}
export interface PortalView {
  id: number;
  name: string;
  seq: number;
  isAdminPortal: boolean;
  updateAt: string;
}

/** 기본 폴더 */
const categories: FolderItem[] = [
  // 관리자 - 메뉴.
  {
    type: 'setting',
    id: 6001,
    name: getLocalizedText('기본설정'),
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6002,
    name: getLocalizedText('회사포탈 관리'),
    icon: 'folder',
  },
];

interface State {
  folder: {
    folders: FolderItem[];
  };
  list: PortalItem[];
  view?: PortalView;
}

const initialState: State = {
  folder: {
    folders: categories,
  },
  list: [],
  view: undefined,
};

/** 관리자 - 회사 포탈 조회 */
const findCompanyPortalView = createAsyncThunk(
  `${name}/findCompanyPortalView`,
  async (
    arg: {
      companyId: number;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { companyId } = arg;
      const response = await adminPortalApi.view({ companyId });
      await dispatch(
        templatesActions.findList({ portalId: response.id, isAdmin: true }),
      );
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 공통 - 포탈 조회 */
const findPortalView = createAsyncThunk(
  `${name}/findPortalView`,
  async (
    arg: {
      portalId: number;
      isAdmin?: boolean;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { portalId, isAdmin } = arg;
      const response = isAdmin
        ? await adminPortalApi.view({
            companyId,
          })
        : await portalApi.view({
            companyId,
            employeeId,
            portalId,
          });
      if (!isAdmin)
        // 대시보드 초기 포탈 저장
        await portalApi.updateInitial({
          companyId,
          employeeId,
          portalId,
        });

      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 포탈 추가 */
const createPortal = createAsyncThunk(
  `${name}/createPortal`,
  async (
    arg: {
      title: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { title } = arg;
      const response = await portalApi.createPortal({
        companyId,
        employeeId,
        name: title,
      });
      return {
        ...response,
        name: title,
        isAdminPortal: false,
        isInitial: false,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 포탈 삭제 */
const deletePortal = createAsyncThunk(
  `${name}/deletePortal`,
  async (
    arg: {
      portalId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { portalId, updateAt } = arg;
      const response = await portalApi.deletePortal({
        companyId,
        employeeId,
        id: portalId,
        updateAt,
      });
      dispatch(findPortalList({ companyId, employeeId }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 공통 - 포탈 이름 변경  */
const updatePortal = createAsyncThunk(
  `${name}/updatePortal`,
  async (
    arg: {
      portalId: number;
      title: string;
      updateAt: string;
      isAdmin?: boolean;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { portalId, title, updateAt, isAdmin } = arg;
      const response = isAdmin
        ? await adminPortalApi.updatePortal({
            companyId,
            portalId,
            name: title,
            updateAt,
          })
        : await portalApi.updatePortal({
            companyId,
            employeeId,
            portalId,
            name: title,
            updateAt,
          });
      return { ...response, title };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 포탈 순서 변경  */
const sortPortal = createAsyncThunk(
  `${name}/sortPortal`,
  async (
    arg: { data: Array<PortalItem & { companyId: number }> } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const response = await portalApi.sortPortal({
        companyId,
        employeeId,
        data: arg.data,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 포탈 리스트 */
const findPortalList = createAsyncThunk(
  `${name}/findPortalList`,
  async (
    arg: {
      companyId: number;
      employeeId: number;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const preferences = (getState() as RootState).dashboard.preferences.basic;
      const { companyId, employeeId } = arg;
      const portalList = await portalApi.findList({ companyId, employeeId });
      const initialItem = await portalApi.findInitial({
        companyId,
        employeeId,
      });
      // 직원 포탈 사용 안함일 때 회사 포탈만 보여줌.
      const list = !preferences.useEmployeePortal
        ? portalList
            .filter((x) => x.isAdminPortal)
            .map((x) => ({ ...x, isInitial: true }))
        : portalList.map((x) => {
            if (preferences.portalPriority === 'COMPANY' && x.isAdminPortal)
              return { ...x, isInitial: true };
            if (
              preferences.portalPriority === 'EMPLOYEE' &&
              x.id === initialItem.id
            )
              return { ...x, isInitial: true };
            return { ...x, isInitial: false };
          });
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const portalsReducer = createSlice({
  name,
  initialState,
  // TODO 사용하지 않을 경우 삭제
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findPortalList.fulfilled, (state, { payload }) => {
      if (payload !== undefined) state.list = payload;
    });
    builder.addCase(findPortalView.fulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        state.list = state.list.map((x) => {
          if (x.id === payload.id) {
            return { ...x, isInitial: true };
          }
          return { ...x, isInitial: false };
        });
        state.view = payload;
      }
    });
    builder.addCase(findCompanyPortalView.fulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        state.view = payload;
      }
    });
    builder.addCase(updatePortal.fulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        state.list = state.list.map((x) => {
          if (x.id === payload.id)
            return { ...x, updateAt: payload.updateAt, name: payload.title };
          return x;
        });
        const { view } = state;
        if (view) {
          state.view = {
            ...view,
            updateAt: payload.updateAt,
            name: payload.title,
          };
        }
      }
    });
    builder.addCase(sortPortal.fulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        state.list = state.list.map((x) => {
          const changedItem = payload.find((y) => y.id === x.id);
          if (changedItem)
            return {
              ...x,
              seq: changedItem.seq,
              updateAt: changedItem.updateAt,
            };
          return x;
        });
        const { view } = state;
        if (view) {
          const changedItem = payload.find((y) => y.id === view.id);
          if (changedItem)
            state.view = {
              ...view,
              seq: changedItem.seq,
              updateAt: changedItem.updateAt,
            };
        }
      }
    });
    builder.addCase(createPortal.fulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        state.list = [
          ...state.list,
          {
            ...payload,
            seq:
              !state.list || state.list.length === 0
                ? 1
                : state.list.length + 1,
          },
        ];
      }
    });
  },
});

export default portalsReducer.reducer;

export const portalsActions = {
  findList: findPortalList,
  findView: findPortalView,
  createPortal,
  updatePortal,
  deletePortal,
  sortPortal,

  companyPortal: findCompanyPortalView,
};
