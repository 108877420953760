import React from 'react';
import { useTranslation } from 'react-i18next';
import SecuritiesDrawer from '../pages/root/security/common/SecuritiesDrawer';

function SecuritiesLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;

  const { i18n } = useTranslation();

  return (
    <SecuritiesDrawer pathname={pathname} search={search} key={i18n.language} />
  );
}

export default SecuritiesLocateDrawer;
