import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostWrite from '../../../../../components/post/PostWrite';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  RootState as R,
  RootState,
} from '../../../../../groupware-webapp/app/store';
import TextField from '../../../../../components/textfield/TextField';
import DropMenu from '../../../../../components/selectField/DropMenu';

function ApprovalArchiveTransferFolderView(props: {
  onClick(arg: { receiverOrganizationId: number; reason: string }): void;
}): JSX.Element {
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );
  const currentOrganizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );

  const display = useSelector((s: R) => s.session.display);

  const initialState = {
    organizationName: '',
    transferMenuPoint: undefined,
    change: false,
    saveing: false,
    isView: display !== 'phone',
    userSelectVisible: false,
    reason: '',
    receiverOrganizationId: 0,
    validation: '',
  };

  const [state, setState] = useState<{
    userSelectVisible: boolean;
    organizationName: string;
    transferMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    change: boolean;
    saveing: boolean;
    isView: boolean;
    validation: string;
    reason: string;
    receiverOrganizationId: number;
  }>(initialState);

  const handleCancel = () => {
    setState((prev) => ({
      ...prev,
      ...initialState,
    }));
  };

  /** 이관부서 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggleExcluder = (event?: React.MouseEvent) => {
    const { transferMenuPoint } = state;
    if (event !== undefined && transferMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        transferMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        transferMenuPoint: undefined,
      }));
    }
  };

  /** 이관부서 아이템 추가. */
  const handleExcluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, transferMenuPoint: undefined };
        return {
          ...prev,
          receiverOrganizationId: extra.organizationId,
          organizationName: extra.organizationName,
          transferMenuPoint: undefined,
          change: true,
        };
      });
    }
  };

  const handleClick = () => {
    if (state.receiverOrganizationId === 0) {
      setState((prev) => ({
        ...prev,
        validation: '이관부서를 선택하세요.',
      }));
      return;
    }
    if (state.receiverOrganizationId === currentOrganizationId) {
      setState((prev) => ({
        ...prev,
        validation: '이관부서와 현재부서가 동일합니다.',
      }));
      return;
    }
    if (state.reason.trim() === '') {
      setState((prev) => ({
        ...prev,
        validation: '사유를 입력하세요.',
      }));
      return;
    }
    props.onClick({
      receiverOrganizationId: state.receiverOrganizationId,
      reason: state.reason,
    });
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({
      ...prev,
      validation: '',
    }));
  };

  //  사유 작성
  const handleChangeMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      reason: event.target.value,
      change: true,
    }));
  };
  return (
    <>
      <EuiSetting.Header title="이관 정보">
        {!state.saveing && state.change && (
          <Button
            text="취소"
            variant="outlined"
            loading={state.saveing}
            onClick={handleCancel}
          />
        )}
        {state.change && (
          <Button
            noDuplication={state.saveing}
            text="확인"
            variant="contained"
            loading={state.saveing}
            onClick={handleClick}
          />
        )}
      </EuiSetting.Header>
      <PostWrite>
        <PostWrite.Item title="이관부서">
          <DropMenu
            value={state.organizationName}
            label="조직도"
            pressed={state.userSelectVisible}
            onClick={(event) => handleDirectoryTreeMenuToggleExcluder(event)}
          />
          {state.transferMenuPoint && (
            <DirectoryMenuTreeContainer
              organizationOnly
              deduplication
              point={state.transferMenuPoint}
              onItemClick={handleExcluderAppend}
              onClose={handleDirectoryTreeMenuToggleExcluder}
            />
          )}
        </PostWrite.Item>
        <PostWrite.Item title="사유">
          <TextField
            placeholder="필수 작성"
            maxLength={100}
            multiline
            value={state.reason}
            onChange={handleChangeMessage}
          />
        </PostWrite.Item>
      </PostWrite>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalArchiveTransferFolderView;
