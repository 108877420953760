/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import TextField from '../../../../../components/textfield/TextField';
import PostWrite from '../../../../../components/post/PostWrite';
import {
  getParentItems,
  getPathParams,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { FileUploadProps } from '../../../../../groupware-common/types';
import AddAttachDocument from '../../../../../components/attachments/AddAttachDocument';
import ApprovalAttachedDocumentSelectDrawerContainer from '../compose/ApprovalAttachedDocumentSelectDrawerContainer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ApprovalLineDialogContainer, {
  ApprovalLineGroupItemEmployeeType,
  ApprovalLineGroupItemOrganizationType,
  ApprovalLineGroupItemsAgreeType,
  ApprovalLineGroupItemsApprovalType,
  ApprovalLineType,
  getApprovalLineDrafter,
  getLastApprover,
  SharePermissionType,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import DraftRequestDialogContainer, {
  DraftRequestConfirmArg,
} from '../compose/DraftRequestDialogContainer';
import {
  directoryEqualityFn,
  directorySelector,
  getCompanyName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { ApiError } from '../../../../../groupware-common/types/error';
import {
  documentActions,
  jsonToApprovalLine,
} from '../../../../stores/approval/document';
import fileApi from '../../../../../groupware-common/apis/file/v1';
import FormBuilder from '../../../../stores/approval/FormBuilder';
import {
  dateTimeFormat,
  filenameExtensionIcon,
  readableBytes,
} from '../../../../../groupware-common/utils/ui';
import { approvalActText } from '../../../common/drawers/ApprovalStatusDrawer';
import MemoizeDivElement from '../common/components/MemoizeDivElement';
import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import { getJobClassName } from '../../../../../groupware-directory/apis/directory/v1';
import FeedBack from '../../../../../components/alert/FeedBack';
import { companyActions } from '../../../../../groupware-directory/stores/directory/company';
import { employeeActions } from '../../../../../groupware-directory/stores/directory/employee';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import ApprovalAttachedDocumentSelectDialog from '../compose/ApprovalAttachedDocumentSelectDialog';
import { attachedDocumentActions } from '../../../../stores/approval/attachedDocument';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import AddProgressAttachments from '../../../../../components/attachments/AddProgressAttachments';

type Props = {
  pathname: string;
  search: string;
};

/** 채번 전 문서번호 매크로 표시 replace */
function documentMacroReplace(documentNo: string): string {
  return documentNo
    .replace(/{년도}/gm, `년도`)
    .replace(/{월}/gm, `월`)
    .replace(/{순번}/gm, '');
}

function ApprovallinkageComposeContainer(props: Props): JSX.Element {
  // console.log(`${ApprovallinkageComposeContainer.name}.render(props)`, props);

  /** 최상위 부서 찾기. */
  const findTopLevelOrganization = (arg: {
    organizations: { companyId: number; id: number; parentId: number }[];
    companyId: number;
    id: number;
  }) => {
    const { organizations, companyId, id } = arg;
    const parents = getParentItems(organizations, id);

    if (parents.length > 1 && parents[1].id !== id) {
      return {
        id: parents[1].id,
        name: getOrganizationName({ companyId, organizationId: parents[1].id }),
      };
    }
    return undefined;
  };

  /** 문서번호 변경. */
  const replaceDocumentNo = (arg: {
    documentNo: string;
    workName: string;
    formName: string;
    organizationName: string;
    employeeName: string;
  }) => {
    const { documentNo, workName, formName, organizationName, employeeName } =
      arg;
    return documentNo
      .replace(/{(업무명)}/gm, workName)
      .replace(/{(양식명)}/gm, formName)
      .replace(/{(조직명)}/gm, organizationName)
      .replace(/{(기안자)}/gm, employeeName)
      .replace(/┼/gm, '');
  };

  /** 결재상신 시 업로드 파일 객체 생성 */
  const createUploadFiles = (attachedFiles: FileUploadProps[]) => {
    return attachedFiles
      .filter(
        (a): a is { id: string; name: string; size: number; file: File } =>
          a.file !== undefined,
      )
      .map((a) => {
        return {
          id: parseInt(a.id, 10),
          file: a.file,
        };
      });
  };
  /** 첨부파일 객체 생성 */
  const createAttachedFiles = (data: {
    attachedFiles: FileUploadProps[];
    isReDraftDocuments?: boolean;
    documentId?: number;
    uploadPath: string;
  }): {
    path: string;
    id: number;
    documentId?: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[] => {
    const { attachedFiles, isReDraftDocuments, documentId, uploadPath } = data;

    return attachedFiles.map((a, i) => {
      return {
        path: a.file !== undefined ? uploadPath : '',
        id: parseInt(a.id, 10),
        documentId:
          isReDraftDocuments && a.file === undefined && documentId
            ? documentId
            : undefined,
        seq: i + 1,
        name: a.name,
        size: a.size,
        copy: isReDraftDocuments && a.file === undefined ? true : undefined,
      };
    });
  };

  /** 결재그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineApprovalFiltered = (
    items: ApprovalLineGroupItemsApprovalType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 합의그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineAgreeFiltered = (
    items: ApprovalLineGroupItemsAgreeType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재,합의 제외 그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineDefaultFiltered = (
    items: Array<
      ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
    >,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재선 생성 시 직원 정보 생성. */
  const createApprovalLineEmployeeData = (data: {
    companyId: number;
    organizationId?: number;
    employeeId: number;
  }) => {
    const { companyId, organizationId, employeeId } = data;
    const employeeData = getDirectoryData({
      ...directory,
      companyId,
      organizationId,
      employeeId,
    });
    return {
      companyId: employeeData.companyId,
      organizationId: employeeData.organizationId,
      employeeId: employeeData.employeeId,
      companyName: employeeData.companyName,
      organizationName: employeeData.organizationName,
      employeeName: employeeData.employeeName,
      jobPositionId: employeeData.jobPositionId,
      jobPositionName: employeeData.jobPositionName,
      jobClassType: employeeData.jobClassType,
      jobDutyId: employeeData.jobDutyId,
      jobDutyName: employeeData.jobDutyName,
      avatar: employeeData.avatar,
    };
  };

  /** 결재선 생성 시 조직 정보 생성. */
  const createApprovalLineOrganizationData = (
    companyId: number,
    organizationId: number,
  ) => {
    const organizationData = getOrganizationData({
      companyId,
      organizationId,
    });
    return {
      companyId: organizationData.companyId,
      organizationId: organizationData.organizationId,
      companyName: organizationData.companyName,
      organizationName: organizationData.organizationName,
    };
  };

  const getOrganizationData = ({
    companyId,
    organizationId,
  }: {
    companyId: number;
    organizationId: number;
  }) => {
    const { organizations } = directory;
    return {
      companyId,
      companyName: getCompanyName(companyId),
      organizationId,
      organizationName: getOrganizationName(companyId, organizationId, ''),
    };
  };
  const pathParams = getPathParams('/*/*/:mode/:id', props.pathname);

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const organizationIds = principal.affiliatedOrganizations.map(({ id }) => id);
  const directory = useSelector(directorySelector, directoryEqualityFn);
  const directoryTreeItems = useMemo(
    () => getDirectoryTreeItems({ ...directory }),
    [directory],
  );
  const basic = useSelector(
    (state: RootState) => state.approval2.preferences.basic,
  );

  const companyView = useSelector((s: RootState) => {
    return s.directory.company.view.data;
  });

  const employeeView = useSelector((s: RootState) => {
    return s.directory.employee.view.data;
  });

  // 최상위 조직 이름 사용 여부.
  const useTopLevelOrganization = useSelector(
    (state: RootState) =>
      state.approval2.preferences.documentNo?.useTopLevelOrganization ?? false,
  );

  const approvalForm = useSelector(
    (state: RootState) => state.session.approvalForm,
  );

  if (!approvalForm) return <div>{getLocalizedText('잘못된 접근입니다.')}</div>;

  const maxFileCount = basic.numberOfAttachments;
  const maxFileCapacity = basic.attachmentsCapacity;

  const contentRef = useRef<HTMLDivElement>(null);
  // console.log('contentRef', contentRef);
  const [state, setState] = useState<{
    builder: FormBuilder;
    validation: string;
    drawertype?: 'attacheddocument';
    dialogtype:
      | 'draftrequest'
      | 'temporary'
      | 'approvalline'
      | 'changesave'
      | 'attacheddocumentdialog'
      | undefined;
    createDocument: boolean; // 문서 상신
    subject: string;

    // 개발
    draftOrganizationId: number;
    draftTopLevelOrganizationId: number;
    draftTopLevelOrganizationName?: string;
    workId: number;
    formId: number;
    formName: string;
    documentNo: string;
    retentionPeriod: number;
    content: string;
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    attachedFiles: FileUploadProps[];
    uploadPath: string;
    attachedDocuments: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[];
    useAttachFile: number; // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
    useAttachDocument: number; // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
    useOpinion: boolean; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
    useComment: boolean; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

    workDocumentNo?: string; // 업무 문서 번호.
    drawerAttachedCompanyId?: number; // 첨부문서 추가 시 팝업 조회 문서 정보.
    drawerAttachedId?: number; // 첨부문서 추가 시 팝업 조회 문서 정보.
    drawerAttachedSelected?: boolean; // 첨부문서 추가 시 팝업 조회 문서 정보.
    ///
    errors: ApiError[];
  }>(() => {
    const builder = new FormBuilder();
    const draftOrganizationId = principal.organizationId;
    const approvalLine = jsonToApprovalLine(approvalForm.work.approvalLine) || {
      version: '0.1',
      groups: [],
    };

    const { organizationName, employeeName } = getDirectoryData({
      ...directory,
      ...{
        companyId: principal.companyId,
        organizationId: draftOrganizationId,
        employeeId: principal.employeeId,
      },
    });

    // 작성자 최상위 조직 아이디.
    let draftTopLevelOrganizationId = 0;
    let draftTopLevelOrganizationName: string | undefined;
    if (useTopLevelOrganization) {
      const topLevelOrganization = findTopLevelOrganization({
        organizations: directory.organizations,
        companyId: principal.companyId,
        id: draftOrganizationId,
      });
      if (topLevelOrganization) {
        draftTopLevelOrganizationId = topLevelOrganization.id;
        draftTopLevelOrganizationName = topLevelOrganization.name;
      }
    }
    const documentNo = replaceDocumentNo({
      documentNo: approvalForm.work.documentNo ?? '',
      workName: approvalForm.work.name ?? '',
      formName: approvalForm.work.formName ?? '',
      organizationName: draftTopLevelOrganizationName ?? organizationName,
      employeeName,
    });

    return {
      builder,
      validation: '',
      dialogtype: undefined,
      createDocument: false,

      subject: approvalForm.provision.subject,

      // workselect --
      draftOrganizationId,
      draftTopLevelOrganizationId,
      draftTopLevelOrganizationName,
      workId: approvalForm.work.id,
      formId: approvalForm.work.formId,
      formName: approvalForm.work.formName,
      documentNo,
      retentionPeriod: approvalForm.work.retentionPeriod,
      content: approvalForm.form.content,
      approvalLine: {
        ...approvalLine,
        groups: approvalLine.groups.map((group) => {
          switch (group.type) {
            case 'draft':
              return {
                ...group,
                items: [
                  {
                    id: `${Date.now()}`,
                    approval: false,
                    ...createApprovalLineEmployeeData({
                      companyId: principal.companyId,
                      organizationId: draftOrganizationId,
                      employeeId: principal.employeeId,
                    }),
                  },
                ],
              };
            case 'approval':
              return {
                ...group,
                items: createApprovalLineApprovalFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
            case 'agree':
              return {
                ...group,
                items: createApprovalLineAgreeFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
            default:
              return {
                ...group,
                items: createApprovalLineDefaultFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
          }
        }),
      },
      referencePermission:
        approvalForm.work.referrer !== '{}'
          ? JSON.parse(approvalForm.work.referrer)
          : undefined,
      viewPermission:
        approvalForm.work.viewer !== '{}'
          ? JSON.parse(approvalForm.work.viewer)
          : undefined,
      // -- workselect

      attachedFiles: [],
      uploadPath: `${Date.now()}`,
      // 뷰에 첨부 문서가 있는 경우.
      // (접수 후 내부 결재 이거나 임시 저장된 문서 또는 문서 변경인 경우)
      attachedDocuments: [],
      drawerAttachedCompanyId: undefined,
      drawerAttachedId: undefined,
      drawerAttachedSelected: undefined,

      useAttachFile: approvalForm.work.useAttachFile,
      useAttachDocument: approvalForm.work.useAttachDocument,
      useOpinion: approvalForm.work.useOpinion,
      useComment: approvalForm.work.useComment,

      workDocumentNo: approvalForm.work.documentNo,
      ///
      errors: [],
    };
  });

  const beforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = '';
  };
  useEffect(() => {
    dispatch(companyActions.findView({ id: principal.employeeId }));
    dispatch(employeeActions.findView({ id: principal.employeeId }));
    if (!state.createDocument) {
      (() => {
        window.addEventListener('beforeunload', beforeUnload);
      })();
      return () => {
        window.removeEventListener('beforeunload', beforeUnload);
      };
    }
    return undefined;
  }, [props, state.createDocument]);

  /** 기안 요청 (상신) 대화상자 열기 */
  const handleDraftRequestDialogOpen = () => {
    const {
      subject,
      approvalLine,
      useAttachFile,
      attachedFiles,
      useAttachDocument,
      attachedDocuments,
    } = state;
    let validation = '';
    if (subject.trim() === '')
      validation = getLocalizedText('제목을 입력하세요.');
    else if (
      !approvalLine.groups.some((g) => g.type === 'draft' && g.items.length > 0)
    )
      validation = getLocalizedText('기안자를 설정하세요.');
    // 기안자 결재가 아니고 기안자 외 결재자가 없는 경우.
    else if (
      !approvalLine.groups.some(
        (g) => g.type === 'draft' && g.approval && g.items[0]?.approval,
      ) &&
      !approvalLine.groups.some((g) => g.type !== 'draft' && g.items.length > 0)
    )
      validation = getLocalizedText('기안자 외 결재자를 선택하여야 합니다.');
    else if (
      approvalLine.groups.find(
        (group) => group.type === 'draft' && group.items[0].approval === false,
      ) &&
      approvalLine.groups.find(
        (group) =>
          group.type !== 'draft' && group.required && group.items.length === 0,
      ) !== undefined
    )
      validation = getLocalizedText('필수 결재선을 설정하세요.');
    else if (useAttachFile === 2 && attachedFiles.length === 0)
      validation = getLocalizedText('첨부 파일을 추가하세요.');
    else if (useAttachDocument === 2 && attachedDocuments.length === 0)
      validation = getLocalizedText('첨부 문서를 추가하세요.');

    if (validation !== '')
      setState((prevState) => ({ ...prevState, validation }));
    else
      setState((prevState) => ({ ...prevState, dialogtype: 'draftrequest' }));
  };

  /** 결재선 대화상자 열기 */
  const handleApprovalLineDialogOpen = () => {
    setState((prevState) => ({ ...prevState, dialogtype: 'approvalline' }));
  };

  /** 첨부문서 자세히 보기 대화상자 */
  const handleAttachedDocumentSelectDialogOpen = (arg: {
    companyId: number;
    id: number;
    selected: boolean;
  }) => {
    const { companyId, id, selected } = arg;
    setState((prevState) => ({
      ...prevState,
      dialogtype: 'attacheddocumentdialog',
      drawerAttachedCompanyId: companyId,
      drawerAttachedId: id,
      drawerAttachedSelected: selected,
    }));
  };

  /** 첨부문서 자세히 보기 대화상자에서 문서 선택 또는 해제 */
  const handleApprovahAttachedDocumentClick = (arg: {
    id: number;
    checked: boolean;
  }) => {
    const { id, checked } = arg;
    dispatch(
      attachedDocumentActions.setListItemChecked({ id, checked: !checked }),
    );
    setState((prevState) => ({
      ...prevState,
      dialogtype: undefined,
      drawerAttachedCompanyId: undefined,
      drawerAttachedId: undefined,
      drawerAttachedSelected: undefined,
    }));
  };

  /** 변경 저장 대화상자 열기 */
  const handleChangeSaveDialogOpen = () => {
    setState((prevState) => ({ ...prevState, dialogtype: 'changesave' }));
  };

  /** 대화상자 닫기 */
  const handleDialogClose = () => {
    if (state.drawerAttachedId !== undefined) {
      setState((prevState) => ({
        ...prevState,
        dialogtype: undefined,
        drawerAttachedCompanyId: undefined,
        drawerAttachedId: undefined,
        drawerAttachedSelected: undefined,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      dialogtype: undefined,
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 제목 변경 */
  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('event.target.value', event.target.value);
    setState((prevState) => ({ ...prevState, subject: event.target.value }));
  };

  /** 파일 업로드 진행상태 업데이트 */
  const handleUploadProgress = (event: ProgressEvent, id: string) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    setState((prev) => ({
      ...prev,
      attachedFiles: prev.attachedFiles.map((x) => {
        if (x.id === id) {
          return { ...x, progress };
        }
        return x;
      }),
    }));
  };

  /** 첨부파일 업로드 */
  const handleAttachedFileUpload = async (
    uploadingFiles: FileUploadProps[],
  ) => {
    try {
      const files = createUploadFiles(uploadingFiles);
      setState((prev) => ({
        ...prev,
        attachedFiles: [...prev.attachedFiles, ...uploadingFiles],
      }));
      const fileAsyncFunc = files.map(async (x) => {
        await fileApi.uploadOne({
          path: state.uploadPath,
          file: x,
          module: 'approval',
          onProgress: handleUploadProgress,
        });
      });

      Promise.all(fileAsyncFunc)
        .then((response) => {
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              attachedFiles: prev.attachedFiles.map((attachedFile) => {
                const uploadingFileIndex = uploadingFiles.findIndex(
                  (x) => x.id === attachedFile.id,
                );
                if (uploadingFileIndex !== -1) {
                  return {
                    ...uploadingFiles[uploadingFileIndex],
                    progress: 100,
                    isUploaded: true,
                  };
                }
                return attachedFile;
              }),
            }));
          }, 100);
        })
        .catch((e) => {
          setState((prev) => ({
            ...prev,
            attachedFiles: prev.attachedFiles.map((attachedFile) => {
              const uploadingFileIndex = uploadingFiles.findIndex(
                (x) => x.id === attachedFile.id,
              );
              if (uploadingFileIndex !== -1) {
                return {
                  ...uploadingFiles[uploadingFileIndex],
                  progress: undefined,
                  isUploaded: false,
                  isFail: true,
                };
              }
              return attachedFile;
            }),
          }));
        });
    } catch (e) {
      dispatch(sessionActions.error(e as ApiError));
    }
  };
  /** 첨부 파일 순서 변경 */
  const handleAttachedFileSortable = (arg: FileUploadProps[]) => {
    setState((prevState) => ({
      ...prevState,
      attachedFiles: [...arg],
    }));
  };

  /** 첨부 파일 삭제 */
  const handleAttachedFileRemove = (id: string) => {
    // console.log(`handleAttachedFileRemove(id)`, id);
    // console.log(`handleAttachedFileRemove(id):state`, state);
    setState((prevState) => ({
      ...prevState,
      attachedFiles: prevState.attachedFiles.filter((x) => x.id !== id),
    }));
  };

  /** 첨부 문서 선택 드로워 열기 */
  const handleAttachedDocumentSelectDrawerOpen = () => {
    const drawertype = 'attacheddocument';
    setState((prevState) => ({ ...prevState, drawertype }));
  };

  /** 첨부 문서 선택 드로워 닫기 */
  const handleAttachedDocumentSelectDrawerClose = () => {
    setState((prevState) => ({ ...prevState, drawertype: undefined }));
  };

  /** 첨부 문서 선택 확인 */
  const handleAttachedDocumentSelectDrawerConfirm = (arg: {
    items: { companyId: number; id: number; no: string; subject: string }[];
  }) => {
    // console.log(`handleAttachedDocumentSelectDrawerConfirm(arg)`, arg);
    const { items } = arg;
    setState((prevState) => {
      const { attachedDocuments } = prevState;
      let validation = '';

      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (
          attachedDocuments.findIndex(
            ({ companyId, id }) =>
              companyId === item.companyId && id === item.id,
          ) > -1
        ) {
          validation = getLocalizedText(
            '{{subject}} 는(은) 이미 선택된 문서 입니다.',
            { subject: item.subject },
          );
          break;
        }
      }

      if (validation !== '') return { ...prevState, validation };
      return {
        ...prevState,
        attachedDocuments: [...attachedDocuments, ...items],
        drawertype: undefined,
      };
    });
  };

  /** 첨부 문서 순서 변경 */
  const handleAttachedDocumentSortable = (
    arg: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      attachedDocuments: [...arg],
    }));
  };

  /** 첨부 문서 삭제 */
  const handleAttachedDocumentRemove = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      attachedDocuments: prevState.attachedDocuments.filter((a) => a.id !== id),
    }));
  };

  /** 첨부 문서 전체 삭제 */
  const handleAttachedDocumentRemoveAll = () => {
    setState((prevState) => ({ ...prevState, attachedDocuments: [] }));
  };

  /** 결재선 저장 */
  const handleApprovalLineSave = (arg: {
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }) => {
    // console.log(`handleApprovalLineSave(arg)`, arg);
    const { approvalLine, referencePermission, viewPermission } = arg;
    setState((prevState) => ({
      ...prevState,
      approvalLine,
      referencePermission,
      viewPermission,
      dialogtype: undefined,
    }));
  };

  /** 기안 요청 (상신) */
  const handleDraftRequest = async (arg: DraftRequestConfirmArg) => {
    if (contentRef.current === null) {
      const validation = getLocalizedText('작성 문서를 읽어올 수 없습니다.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = getLocalizedText(
        '파일 전송 중에는 문서를 저장할 수 없습니다.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => x.isFail) > -1
    ) {
      const validation = getLocalizedText(
        '전송 실패한 파일을 삭제 후 다시 시도 해주세요.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const content = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    const {
      draftTopLevelOrganizationId,
      workId,
      formId,
      documentNo,
      subject,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedDocuments,
    } = state;
    const notOrganization = organizationIds.some(
      (a) => a === getApprovalLineDrafter(approvalLine)?.organizationId,
    );
    if (notOrganization === false) {
      // 기안 조직이 사용자 정보에 일치하는 조직과 없을 경우 결재 상신 오류처리.
      const validation = getLocalizedText(
        '기안자 정보와 현재 사용자 정보가 일치하지 않아 결재를 상신할 수 없습니다.',
      );
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }
    const attachedFiles: {
      path: string;
      id: number;
      documentId?: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean; // 재기안 시 기존 파일 체크
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      uploadPath: state.uploadPath,
    });

    const param = {
      publicOrNot: arg.publicOrNot,
      useUrgent: arg.useUrgent,
      archiveFolderId: arg.archiveFolderId,
      opinion: arg.opinion,
      draftTopLevelOrganizationId,
      workId,
      workUpdateAt: approvalForm.work.updateAt,
      formId,
      formUpdateAt: approvalForm.form.updateAt,
      no: documentNo,
      subject,
      contents: content,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
      attachedSharedfiles: approvalForm.provision.attachedSharedfiles,
      linkType: approvalForm.work.linkType,
      linkId: approvalForm.provision.linkId,
    };

    dispatch(documentActions.createLinked({ param }))
      .then((a) => {
        if ((a as { error?: string }).error === undefined)
          setState((prev) => ({
            ...prev,
            dialogtype: undefined,
            createDocument: true,
          }));
      })
      .catch((e) => {
        dispatch(sessionActions.error(e as ApiError));
        setState((prev) => ({ ...prev, dialogtype: undefined }));
      });
  };

  /** 콘텐트 로드. */
  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { content } = state;
      const { element } = arg;

      element.innerHTML = FormBuilder.createComposeHtml({
        html: content,
        getMacro,
      });
    },
    [state.content],
  );

  const getMacro = (arg: { id: string }) => {
    const {
      draftTopLevelOrganizationName,
      documentNo,
      subject,
      retentionPeriod,
      approvalLine,
      referencePermission,
      viewPermission,
      workDocumentNo,
    } = state;

    const { id } = arg;
    // 연동 결재 본문
    if (id === 'SYSTEMLINK/ERPBODY') {
      return approvalForm.provision.content;
    }
    // 회사명. _CMP_NAME_
    if (id === 'DIRECTORY/COMAPNY') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.companyName ?? '';
    }
    // 결재 회사 로고.
    if (id === 'APPROVAL/COMPANY_LOGO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.companyId !== undefined
        ? `/approval-static/image/${drafter?.companyId}/companylogo`
        : undefined;
    }
    // 회사 우편번호.
    if (id === 'DIRECTORY/COMPANY_POSTALCODE') {
      return companyView?.postalCode ?? '';
    }
    // 회사주소.
    if (id === 'DIRECTORY/COMPANY_ADDRESS') {
      return companyView?.address ?? '';
    }
    // 회사전화.
    if (id === 'DIRECTORY/COMPANY_PHONE_NO') {
      return companyView?.phoneNo ?? '';
    }
    // 회사팩스.
    if (id === 'DIRECTORY/COMPANY_FAX_NO') {
      return companyView?.faxNo ?? '';
    }
    // 오늘날짜.
    if (id === 'COMMON/TODAY') {
      return dateTimeFormat(new Date(), 'YYYY-MM-DD');
    }
    /* 최종결재자 명칭. */
    if (id === 'APPROVAL/LAST_APPROVER') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.employeeId ? data.employeeName : '';
    }
    /* 최종결재자 부서명. */
    if (id === 'APPROVAL/LAST_APPROVER_ORGANIZATION') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.organizationName ?? '';
    }
    /* 최종결재자 직위. */
    if (id === 'APPROVAL/LAST_APPROVER_JOBPOSITION') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.employeeName ? data.jobPositionName : '';
    }
    /* 기안자 회사 전화번호 */
    if (id === 'APPROVAL/DRAFTER_COMPANY_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.companyPhoneNo : '';
    }
    /* 기안자 폰번호 */
    if (id === 'APPROVAL/DRAFTER_MOBILE_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.mobilePhoneNo : '';
    }
    /* 기안자 내선번호 */
    if (id === 'APPROVAL/DRAFTER_EXTENSION_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.extensionPhoneNo : '';
    }
    /* 기안자 주소 */
    if (id === 'APPROVAL/DRAFTER_ADDRESS') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.address : '';
    }
    /* 기안자 사번 */
    if (id === 'APPROVAL/DRAFTER_EMPLOYEE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees.find((x) => x.id === drafter?.employeeId)?.no ?? ''
      );
    }
    /* 기안자 EMAIL */
    if (id === 'APPROVAL/DRAFTER_EMAIL') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees.find((x) => x.id === drafter?.employeeId)?.email ??
        ''
      );
    }
    /* 기안자 아이디 */
    if (id === 'APPROVAL/DRAFTER_ID') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees
          .find((x) => x.id === drafter?.employeeId)
          ?.email.split('@')[0] ?? ''
      );
    }
    // 기안자. _NAME_
    if (id === 'APPROVAL/DRAFTER') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId
        ? drafter?.employeeName
        : drafter?.organizationName;
    }
    // 기안자 조직. _DEPT_NAME_
    if (id === 'APPROVAL/DRAFTER_ORGANIZATION') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.organizationName ?? '';
    }
    // 기안자 직위. _POSITION_
    if (id === 'APPROVAL/DRAFTER_JOBPOSITION') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? drafter?.jobPositionName : '';
    }
    // 기안일. _TODAY_
    if (id === 'APPROVAL/DRAFT_DATE') {
      const drafter = getApprovalLineDrafter(approvalLine);
      const actAt = drafter?.actAt ?? new Date();
      return dateTimeFormat(actAt, 'YYYY-MM-DD');
    }
    // 문서제목. _DOC_TITLE_
    if (id === 'APPROVAL/DOCUMENT_SUBJECT') {
      return subject;
    }

    // 결재그룹, 합의그룹, 수신그룹, 감사그룹.
    // _APPROV_LINE_, _AGREE_LINE_, _RECEIVE_LINE_, AUDIT_LINE
    if (
      id === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP/INCLUDE_DRAFTER' ||
      id === 'APPROVAL/APPROVALLINE_AGREE_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_RECEIVE_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_AUDIT_GROUP'
    ) {
      const types: ('draft' | 'approval' | 'agree' | 'audit' | 'receive')[] =
        [];

      if (id.endsWith('/INCLUDE_DRAFTER')) types.push('draft');

      switch (id) {
        case 'APPROVAL/APPROVALLINE_APPROVAL_GROUP':
        case 'APPROVAL/APPROVALLINE_APPROVAL_GROUP/INCLUDE_DRAFTER':
          types.push('approval');
          break;
        case 'APPROVAL/APPROVALLINE_AGREE_GROUP':
          types.push('agree');
          break;
        case 'APPROVAL/APPROVALLINE_RECEIVE_GROUP':
          types.push('receive');
          break;
        case 'APPROVAL/APPROVALLINE_AUDIT_GROUP':
          types.push('audit');
          break;
        default:
          break;
      }

      return approvalLine.groups
        .map((group) => {
          if (types.includes(group.type)) return group.items;
          return [];
        })
        .flat()
        .map((a) => {
          let jobClass = '';
          let approvalOfficer = '';
          if (a.employeeId !== undefined) {
            jobClass = getJobClassName(
              a.jobClassType,
              a.jobPositionName,
              a.jobDutyName,
            );
            approvalOfficer = a.macroName ?? '';
          }

          let act = approvalActText(a.act);
          // 대리 결재인 경우. (직원 결재이고 결재 담당자가 아니고 결재자가 있는 경우)
          if (
            a.employeeId !== undefined &&
            a.macroId === undefined &&
            a.approverEmployeeId !== undefined
          ) {
            if (a.act === 'approval')
              act = approvalActText('surrogateApproval');
            else if (a.act === 'return')
              act = approvalActText('surrogateReturn');
          }

          let approverJobClassName = '';
          if (
            a.approverJobClassType &&
            a.approverJobPositionName &&
            a.approverJobDutyName
          )
            approverJobClassName = getJobClassName(
              a.approverJobClassType,
              a.approverJobPositionName,
              a.approverJobDutyName,
            );
          return {
            company: a.companyName,
            organization:
              a.employeeName === undefined && a.approverEmployeeName
                ? `${a.organizationName}<br>${a.approverEmployeeName} ${approverJobClassName}`
                : a.organizationName,
            employee: a.employeeName ?? '',
            jobClass,
            act,
            actAt:
              a.actAt !== undefined
                ? dateTimeFormat(a.actAt, 'YYYY-MM-DD')
                : '',
            approvalOfficer, // 결재 담당자.
            approver: a.approverEmployeeName ?? '',
          };
        });
    }
    // 결재조직, 합의조직, 수신조직, 감사조직.
    // _AGREE_DEPT_, _RECEIVE_DEPT_
    if (
      id === 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST'
    ) {
      let type = '';
      switch (id) {
        case 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST':
          type = 'approval';
          break;
        case 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST':
          type = 'agree';
          break;
        case 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST':
          type = 'receive';
          break;
        case 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST':
          type = 'audit';
          break;
        default:
          type = '';
          break;
      }

      return Array.from(
        new Set(
          approvalLine.groups
            .map((group) => {
              if (group.type !== type || group.items.length === 0) return [];
              return group.items;
            })
            .flat()
            .map((a) => a.organizationName),
        ),
      ).join(',');
    }
    // 참조.
    // if (id === '(_REFER_)') {
    if (id === 'APPROVAL/REFERENCE_PERMISSION_LIST') {
      if (referencePermission === undefined) return '';
      return referencePermission.groups
        .map(({ items }) => items)
        .flat()
        .map((a) => {
          if (a.employeeId === undefined) return a.organizationName;
          return a.macroName || a.employeeName;
        })
        .join(',');
    }
    // 조회.
    // if (id === '(_CHECK_)') {
    if (id === 'APPROVAL/VIEW_PERMISSION_LIST') {
      if (viewPermission === undefined) return '';
      return viewPermission.groups
        .map(({ items }) => items)
        .flat()
        .map((a) => {
          if (a.employeeId === undefined) return a.organizationName;
          return a.macroName || a.employeeName;
        })
        .join(',');
    }
    // 업무명.
    if (id === 'APPROVAL/WORK_NAME') {
      return approvalForm.work.name;
    }
    // 결재 항목
    if (id === 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM') {
      return approvalLine.groups
        .filter(({ type }) => type !== 'draft')
        .map((group) => {
          switch (group.type) {
            case 'approval':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'agree':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'receive':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            default:
              return group;
          }
        })
        .map((a) => {
          return a.items;
        })
        .flat()
        .map((a) => {
          let jobClass = '';
          let jobPosition = '';
          let jobDuty = '';

          let approvalOfficer = '';
          if (a.employeeId !== undefined) {
            jobClass = getJobClassName(
              a.jobClassType,
              a.jobPositionName,
              a.jobDutyName,
            );
            approvalOfficer = a.macroName ?? '';
            jobPosition = a.jobPositionName;
            jobDuty = a.jobDutyName;
          }
          let act = approvalActText(a.act);
          // 대리 결재인 경우. (직원 결재이고 결재 담당자가 아니고 결재자가 있는 경우)
          if (
            a.employeeId !== undefined &&
            a.macroId === undefined &&
            a.approverEmployeeId !== undefined
          ) {
            if (a.act === 'approval')
              act = approvalActText('surrogateApproval');
            else if (a.act === 'return')
              act = approvalActText('surrogateReturn');
          }

          let approverJobClassName = '';
          if (
            a.approverJobClassType &&
            a.approverJobPositionName &&
            a.approverJobDutyName
          )
            approverJobClassName = getJobClassName(
              a.approverJobClassType,
              a.approverJobPositionName,
              a.approverJobDutyName,
            );
          return {
            company: a.companyName,
            organization:
              a.employeeName === undefined && a.approverEmployeeName
                ? `${a.organizationName}<br>${a.approverEmployeeName} ${approverJobClassName}`
                : a.organizationName,
            employee: a.employeeName ?? '',
            jobClass,
            act,
            actAt:
              a.actAt !== undefined
                ? dateTimeFormat(a.actAt, 'YYYY-MM-DD')
                : '',
            approvalOfficer, // 결재 담당자.
            approver: a.approverEmployeeName ?? '',
            jobPosition,
            jobDuty,
            type: a.id,
          };
        });
    }
    /* 문서번호. */
    // if (id === '(_DOC_SERIAL_)') {
    if (id === 'APPROVAL/DOCUMENT_NO') {
      return {
        value: documentMacroReplace(documentNo),
        define: workDocumentNo,
      };
    }
    /* 보존기간. */
    // if (id === '(_DOC_TIME_)') {
    if (id === 'APPROVAL/DOCUMENT_RETENTION_PERIOD') {
      // console.log('ApprovalComposeContainer retentionPeriod', retentionPeriod);
      let retention = getLocalizedText('영구');
      if (retentionPeriod !== 0) {
        const year = Math.floor(retentionPeriod / 365);
        retention = year > 0 ? getLocalizedText('{{n}} 년', { n: year }) : '';
        const month = (retentionPeriod % 365) / 30;
        retention +=
          month > 0 ? getLocalizedText('{{n}} 개월', { n: month }) : '';
      }
      return retention;
    }

    if (id === '(_EDITOR_)') return undefined;

    // return undefined;
    return '';
  };

  /** 콘텐트 렌더링 */
  const renderContent = () => {
    const { mode } = pathParams;
    const { attachedFiles, attachedDocuments, createDocument } = state;
    const { attachedSharedfiles } = approvalForm.provision;

    if (createDocument)
      return (
        <PostWrite name="complete" fullSize>
          <div style={{ display: 'flex', margin: 'auto' }}>
            <h3 style={{ fontSize: '25px' }}>
              {getLocalizedText('결재문서를 상신했습니다.')}
            </h3>
          </div>
        </PostWrite>
      );
    // 작성 또는 수정인 경우.
    if (mode === 'compose' || mode === 'update') {
      const { subject, approvalLine, useAttachFile, useAttachDocument } = state;

      const includeEmptyGroup = mode === 'compose';

      if (contentRef.current !== null) {
        FormBuilder.binding({
          element: contentRef.current,
          getMacro,
        });
      }

      return (
        <PostWrite name="posting" fullSize>
          <PostWrite.Toolbar>
            {mode === 'compose' && (
              <>
                <Button
                  text={getLocalizedText('결재상신')}
                  variant="contained"
                  onClick={handleDraftRequestDialogOpen}
                />
                <Button
                  text={getLocalizedText('결재선')}
                  onClick={handleApprovalLineDialogOpen}
                />
              </>
            )}
            {mode === 'update' && (
              <Button
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleChangeSaveDialogOpen}
              />
            )}
          </PostWrite.Toolbar>
          <PostWrite.Body>
            <PostWrite.Content>
              <PostWrite.Title>
                <TextField
                  value={subject}
                  onChange={handleSubjectChange}
                  placeholder={getLocalizedText('제목을 입력하세요.')}
                />
              </PostWrite.Title>
              <PostWrite.Item>
                <ApprovalLineFlat
                  approvalLine={approvalLine}
                  includeEmptyGroup={includeEmptyGroup}
                  className="item-value"
                />
              </PostWrite.Item>
              <PostWrite.Edit>
                <MemoizeDivElement
                  ref={contentRef}
                  className="editor-html"
                  onLoad={handleContentLoad}
                />
              </PostWrite.Edit>
            </PostWrite.Content>
            {useAttachFile !== 0 ||
            useAttachDocument !== 0 ||
            (attachedSharedfiles !== undefined &&
              attachedSharedfiles.length > 0) ? (
              <PostWrite.Side>
                {useAttachFile !== 0 ? (
                  <AddProgressAttachments
                    data={attachedFiles}
                    maxCount={maxFileCount}
                    maxCapacity={maxFileCapacity}
                    onRemove={handleAttachedFileRemove}
                    onFileUpload={handleAttachedFileUpload}
                    onSortable={handleAttachedFileSortable}
                  />
                ) : null}
                {/* 공유 파일. */}
                {attachedSharedfiles && attachedSharedfiles?.length > 0 ? (
                  <div className="eui-file-upload">
                    <div className="file-info">
                      <strong className="title">
                        {getLocalizedText('공유파일')}
                      </strong>
                    </div>
                    <div className="file-list">
                      {attachedSharedfiles.map((x) => (
                        <div key={x.path} className="file-item">
                          <div
                            className="category"
                            data-file-format={filenameExtensionIcon(x.name)}
                          />
                          <div className="content">
                            <div className="file">
                              <span className="name">{x.name}</span>
                              {/* <span className="format">{path.extname(x.name)}</span> */}
                            </div>
                            <em className="size">{readableBytes(x.size, 1)}</em>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {useAttachDocument !== 0 ? (
                  <PostWrite.Item title={getLocalizedText('첨부문서')}>
                    <AddAttachDocument
                      data={attachedDocuments.map((a) => {
                        return {
                          ...a,
                          no: documentMacroReplace(a.no),
                        };
                      })}
                      onRemove={handleAttachedDocumentRemove}
                      onAllRemove={handleAttachedDocumentRemoveAll}
                      onSortable={handleAttachedDocumentSortable}
                      onOpen={handleAttachedDocumentSelectDrawerOpen}
                    />
                  </PostWrite.Item>
                ) : null}
              </PostWrite.Side>
            ) : null}
          </PostWrite.Body>
        </PostWrite>
      );
    }

    return null;
  };

  /** 대화상자 렌더링 */
  const renderDialog = () => {
    const { dialogtype } = state;
    // console.log(`renderDialog():dialogtype: '${dialogtype}'`);

    let result = null;

    if (dialogtype === 'approvalline') {
      const { approvalLine, referencePermission, viewPermission } = state;
      result = (
        <ApprovalLineDialogContainer
          approvalLine={approvalLine}
          referencePermission={referencePermission}
          viewPermission={viewPermission}
          onSave={handleApprovalLineSave}
          onCancel={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'draftrequest') {
      // console.log(`state`, state);

      const { useOpinion, draftOrganizationId } = state;
      result = (
        <DraftRequestDialogContainer
          organizationId={draftOrganizationId}
          useOpinion={useOpinion}
          onConfirm={handleDraftRequest}
          onCancel={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'attacheddocumentdialog') {
      return (
        <ApprovalAttachedDocumentSelectDialog
          companyId={state.drawerAttachedCompanyId}
          id={state.drawerAttachedId}
          checked={state.drawerAttachedSelected}
          onClick={handleApprovahAttachedDocumentClick}
          onClose={handleDialogClose}
        />
      );
    }

    return result;
  };

  // const { drawertype } = props.params;
  const { validation, drawertype } = state;

  return (
    <>
      {renderContent()}
      {drawertype && (
        <ApprovalAttachedDocumentSelectDrawerContainer
          onClick={handleAttachedDocumentSelectDialogOpen}
          onConfirm={handleAttachedDocumentSelectDrawerConfirm}
          onCancel={handleAttachedDocumentSelectDrawerClose}
        />
      )}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export { documentMacroReplace };

export default ApprovallinkageComposeContainer;
