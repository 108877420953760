import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IconType } from '../../../groupware-common/types/icon';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import settingApi from '../../apis/sangsanginsecurities/v1/setting';

const name = 'sangsanginsecurities/setting';

interface FolderItem {
  id: string;
  name: string;
  icon: IconType;
}

interface State {
  isApprovalTeam: boolean;
  accountingIds: number[]; // 회계팀 아이디 목록
  currentOrganizationId: number; // 현재 부서
  categories: FolderItem[];
}

const initialState: State = {
  isApprovalTeam: false,
  accountingIds: [],
  currentOrganizationId: 0,
  categories: [
    { id: 'securities', name: '품의서', icon: 'clipboard-text' },
    { id: 'authority', name: '품의서 전표승인', icon: 'clipboard-signature' },
    { id: 'payment', name: '출납 대기 목록', icon: 'clipboard-circle-dollar' },
  ],
};

/** 결재 권한이 있는 조직 아이디 조회. */
const findApprovalAuthorityTeam = createAsyncThunk(
  `${name}/findApprovalAuthorityTeam`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      const { companyId, organizationId, affiliatedOrganizations } = (
        getState() as RootState
      ).session.principal;
      const approvalAuthorityTeamIds = await settingApi.approvalAuthorityTeam(
        companyId,
      );
      const isApprovalTeam = approvalAuthorityTeamIds.some((a) =>
        affiliatedOrganizations.some(({ id }) => id === a),
      );
      return {
        isApprovalTeam,
        accountingIds: approvalAuthorityTeamIds,
        currentOrganizationId: organizationId,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);
const settingReducer = createSlice({
  name,
  initialState,
  reducers: {
    saveCurrentOrganizationId(state, action: PayloadAction<number>) {
      state.currentOrganizationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      findApprovalAuthorityTeam.fulfilled,
      (state, { payload }) => {
        if (payload !== undefined) {
          state.isApprovalTeam = payload.isApprovalTeam;
          state.accountingIds = payload.accountingIds;
          state.currentOrganizationId = payload.currentOrganizationId;
        }
      },
    );
  },
});

export default settingReducer.reducer;

export const settingActions = {
  saveCurrentOrganizationId: settingReducer.actions.saveCurrentOrganizationId,
  approvalAuthorityTeam: findApprovalAuthorityTeam,
};
