import { useEffect } from 'react';

import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { serviceMenuActions } from '../../../../stores/module';

function ServiceLinkMenuRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    const route = { pathname, search, clearErrors: true };
    if (mount) {
      dispatch(serviceMenuActions.linkList({ route }));
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default ServiceLinkMenuRoute;
