import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import Avatar from '../../../../../components/avatar/Avatar';
import Comment from '../../../../../components/comment/Comment';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Loading from '../../../../../components/loading/Loading';
import PostView from '../../../../../components/post/PostView';
import {
  getAvatarPath,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { createLocalizedTextFactory } from '../../../../../groupware-common/utils/i18n';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  AttachDocument,
  AttachFile,
  DocumentData,
  SharedFile,
  documentApi,
} from '../../../../apis/approval/v1/document';
import {
  CommentItem,
  jsonToApprovalLine,
} from '../../../../stores/approval/document';
import { getCommentItems } from '../ApprovalContentBodyViewContainer';

import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import FeedBack from '../../../../../components/alert/FeedBack';
import {
  dateTimeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import securitiesApi from '../../../../../groupware-custom-sangsanginsecurities/apis/sangsanginsecurities/v1/securities';
import EmptyData from '../../../../../components/data/EmptyData';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());

  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return `${createLocalizedTextFactory('approval')('{{n}}일', {
      n: day,
    })} ${createLocalizedTextFactory('approval')('{{n}}시간', {
      n: remainder,
    })}`;
  }
  return createLocalizedTextFactory('approval')('{{n}}시간', { n: time });
}

export interface DocumentDialog {
  dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
  documentId: number;
  affiliatedCompanyId?: number;
  receiptDocumentId?: number; // 발신 문서 아이디
  receiptAffiliatedCompanyId?: number; // 발신 문서 계열사 아이디
}

function ApprovalAttachedDocumentDialog(props: {
  search: string;
  dialogs?: {
    dialogType: string;
    documentId: number;
    affiliatedCompanyId: number;
  }[];
  dialog: DocumentDialog;
  onAttachedDocumentPopup?(arg: {
    dialogType: 'attacheddocument' | 'parentdocument';
    documentId: number;
    affiliateCompanyId?: number | undefined;
  }): void;
  onAttachedFilePopup?(file: AttachFile): void;
  onSharedFilePopup?(file: SharedFile): void;
  onCloseDialog?(arg: {
    dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }): void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const getLocalizedText = createLocalizedTextFactory('approval');

  const display = useSelector((state: RootState) => state.session.display);

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();
  const queryParams = getQueryParams(props.search);

  const scrollbar = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<{
    loading: boolean;
    error: boolean;
    validation: string;
    type: string;
    document:
      | {
          subject: string; // 제목
          content: string; // 내용
          approvalLine: ApprovalLineType; // 결재선
          draftAt: string; // 기안 날짜
          completeAt?: string; // 완료 날짜
          attachedFiles?: AttachFile[]; // 첨부 파일 배열.
          sharedFiles?: SharedFile[]; // 공유 파일 배열.
          attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
          opinions?: (CommentItem & { act: number })[]; // 의견 배열.
          comments?: CommentItem[]; // 댓글 배열.
          linkWait?: boolean; // 연동 대기 여부.
        }
      | null
      | undefined;
  }>({
    loading: true,
    error: false,
    validation: '',
    type: '',
    document: undefined,
  });

  useEffect(() => {
    async function run() {
      const { dialogs, dialog } = props;
      try {
        const {
          affiliatedCompanyId,
          documentId,
          dialogType,
          receiptDocumentId,
          receiptAffiliatedCompanyId,
        } = dialog;
        let response: DocumentData;
        // 접수 문서일 경우
        if (dialogType === 'parentdocument') {
          // 부모 문서 아이디를 찾을 수 없을 경우
          if (!receiptDocumentId) throw new Error();
          response = await documentApi.receiveData({
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? undefined,
            receiptDocumentId,
            receiptAffiliatedCompanyId: receiptAffiliatedCompanyId ?? undefined,
          });
        }
        // 첨부 문서일 경우
        else if (dialogType === 'attacheddocument') {
          if (dialogs === undefined) throw new Error();
          response = await documentApi.attachedData({
            documentId,
            affiliatedCompanyId:
              affiliatedCompanyId === undefined || affiliatedCompanyId === 0
                ? undefined
                : affiliatedCompanyId,
            documentIds: dialogs.map((a) => a.documentId),
            affiliatedCompanyIds: dialogs.map((a) => a.affiliatedCompanyId),
          });
        } else if (dialogType === 'draftdocument') {
          response = await securitiesApi.documentData({
            companyId: principal.companyId,
            employeeId: principal.employeeId,
            documentId,
          });
        } else throw new Error();

        if (response === undefined) throw new Error();

        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const {
          attachedFileCount,
          attachedDocumentCount,
          attachedSharedFileCount,
          id,
        } = response;

        const attachedFiles: AttachFile[] = [];
        if (attachedFileCount > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          const attachedUrlList = await documentApi.fetchAttachfileURLList(id);

          attachedList.forEach((x) => {
            const attachedURL = attachedUrlList.find((a) => x.id === a.id);
            attachedFiles.push({
              ...x,
              url: attachedURL?.url,
              isFileprotection: attachedURL?.isFileprotection,
            });
          });
        }

        const documentCompanyId =
          affiliatedCompanyId !== undefined &&
          affiliatedCompanyId !== principal.companyId
            ? affiliatedCompanyId
            : principal.companyId;

        const data = {
          ...response,
          approvalLine: jsonToApprovalLine(response.approvalline),
          draftAt: dateTimeFormat(response.draftAt, 'yyyy-MM-DD'),
          completeAt:
            response.completeAt === '1000-01-01'
              ? undefined
              : dateTimeFormat(response.completeAt, 'yyyy-MM-DD'),
          attachedFiles,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          sharedFiles:
            attachedSharedFileCount > 0
              ? await documentApi.fetchSharedFileList(id)
              : undefined,
          opinions: useOpinion
            ? await documentApi.fetchOpinionList(documentCompanyId, id)
            : undefined,
          comments: useComment
            ? await documentApi.fetchCommentList(documentCompanyId, id)
            : undefined,
        };

        setState((prev) => ({
          ...prev,
          loading: false,
          error: false,
          document: {
            subject: data.subject,
            content: data.content,
            approvalLine: data.approvalLine,
            draftAt: data.draftAt,
            completeAt: data.completeAt,
            attachedFiles,
            attachedDocuments: data.attachedDocuments,
            opinions: data.opinions,
            comments: data.comments,
            linkWait: data.linkWait,
          },
        }));
        scrollbar.current?.scrollTo(0, 0);
      } catch (e) {
        const errorMessage =
          dialog.dialogType === 'attacheddocument'
            ? getLocalizedText('첨부 문서를 찾을 수 없습니다.')
            : getLocalizedText('문서를 찾을 수 없습니다.');
        // 결재 문서 오류 처리
        setState((prev) => ({
          ...prev,
          loading: false,
          error: true,
          document: undefined,
        }));
        dispatch(sessionActions.error(errorMessage));
      }
    }
    if (state.loading) run();
  }, [props.dialog, state.loading]);

  // Dialog 닫기
  const handleClose = () => {
    if (props.onCloseDialog !== undefined && props.dialog !== undefined) {
      setState((prevState) => ({ ...prevState, loading: true }));
      props.onCloseDialog(props.dialog);
    } else {
      delete queryParams.dialogType;
      delete queryParams.companyId;
      delete queryParams.id;
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }
  };

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: {
    companyId: number;
    documentId: number;
  }) => {
    const { companyId, documentId } = arg;
    documentApi
      .downloadAttachfileAll(companyId, documentId)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    companyId: number;
    documentId: number;
    id: number;
    name: string;
  }) => {
    const { companyId, documentId, id, name } = arg;
    documentApi
      .downloadAttachfile(companyId, documentId, id)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 의견,댓글 정보 */
  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  /** 첨부문서 대화상자 */
  const handleAttachedDocumentPopup = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument';
    documentId: number;
    affiliateCompanyId?: number | undefined;
  }) => {
    if (props.onAttachedDocumentPopup) {
      setState((prevState) => ({ ...prevState, loading: true }));
      props.onAttachedDocumentPopup(arg);
    }
  };

  /** 첨부파일 미리보기 대화상자 */
  const handleAttachedFilePopup = (id: number) => {
    const file = state.document?.attachedFiles?.find((a) => a.id === id);

    if (file === undefined || file.url === undefined) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('미리보기를 불러올 수 없습니다.'),
      }));
      return;
    }
    if (props.onAttachedFilePopup) props.onAttachedFilePopup(file);
  };

  /** 공유파일 미리보기 대화상자 */
  const handleSharedFilePopup = (id: number) => {
    const file = state.document?.attachedFiles?.find((a) => a.id === id);

    if (file === undefined || file.url === undefined) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('미리보기를 불러올 수 없습니다.'),
      }));
      return;
    }
    if (props.onSharedFilePopup) props.onSharedFilePopup(file);
  };

  if (!state.document || state.loading) {
    return (
      <Dialog onClose={handleClose}>
        <div style={{ height: window.innerHeight }}>
          <DialogHeader>
            <DialogTitle>
              {props.dialog.dialogType === 'parentdocument'
                ? getLocalizedText('원문서')
                : getLocalizedText('첨부문서')}
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            {state.loading && <Loading />}
            {state.error && <EmptyData />}
          </DialogBody>
        </div>
      </Dialog>
    );
  }

  const {
    subject,
    content,
    approvalLine,
    draftAt,
    completeAt,
    attachedFiles,
    sharedFiles,
    attachedDocuments,
    linkWait,
  } = state.document;

  const opinions = getCommentItems(state.document.opinions, directory);
  const comments = getCommentItems(state.document.comments, directory);

  const drafter = getApprovalLineDrafter(approvalLine);
  if (drafter === undefined) return <div>{getLocalizedText('오류')}</div>;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : '';
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(approvalLine) : undefined;
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 공유 파일 저장. */
  const handleSharedFileDownload = (arg: {
    documentId: number;
    id: number;
    name: string;
    affiliatedcompanyid?: number;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { affiliatedcompanyid, documentId, id, name } = arg;
    documentApi
      .downloadSharedfile(documentId, id, affiliatedcompanyid)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  return (
    <>
      <Dialog onClose={handleClose}>
        <DialogHeader>
          <DialogTitle>
            {props.dialog.dialogType === 'parentdocument'
              ? getLocalizedText('원문서')
              : getLocalizedText('첨부문서')}
          </DialogTitle>
        </DialogHeader>
        <DialogBody scrollbar={scrollbar} height={window.innerHeight}>
          <PostView type="full">
            <PostView.Head>
              <PostView.Title
                chip={
                  linkWait
                    ? {
                        label: getLocalizedText('연동대기'),
                        theme: 'primary',
                      }
                    : undefined
                }
              >
                {subject}
              </PostView.Title>
              {drafter && (
                <div className="approval-view-info">
                  <Avatar
                    className="avatar"
                    name={drafter.employeeName ?? drafter.organizationName}
                    image={drafterAvatar}
                    icon={drafterAvatar === '' ? 'sitemap-fill' : undefined}
                  />
                  <ApprovalLineFlat approvalLine={approvalLine} />
                  <PostView.Info row>
                    <PostView.InfoItem
                      title={getLocalizedText('기안자')}
                      value={drafter.employeeName ?? drafter.organizationName}
                    />
                    <PostView.InfoItem
                      title={getLocalizedText('기안부서')}
                      value={drafter.organizationName}
                    />
                    <PostView.InfoItem
                      title={getLocalizedText('기안일')}
                      value={draftAt}
                    />
                    {completeAt && (
                      <PostView.InfoItem
                        title={getLocalizedText('완료일')}
                        value={completeAt}
                      />
                    )}
                    {waitedAt && (
                      <PostView.InfoItem
                        title={getLocalizedText('대기일')}
                        value={waitedAt}
                      />
                    )}
                  </PostView.Info>
                </div>
              )}
            </PostView.Head>
            <PostView.Body>
              <PostView.Content data={content} />
              {attachedFiles && attachedFiles.length > 0 && (
                <AttachmentsList
                  count={attachedFiles.length}
                  className="view-attachments"
                  saveAll={
                    attachedFiles && display === 'pc'
                      ? () =>
                          handleAttachedFileDownloadAll({
                            companyId: attachedFiles[0].companyId,
                            documentId: attachedFiles[0].documentId,
                          })
                      : undefined
                  }
                >
                  {attachedFiles &&
                    attachedFiles.map(
                      ({ companyId, documentId, id, name, size, url }) =>
                        url ? (
                          <AttachmentsItem
                            key={id}
                            name={name}
                            size={size}
                            onClick={
                              display === 'pc'
                                ? () =>
                                    handleAttachedFileDownload({
                                      companyId,
                                      documentId,
                                      id,
                                      name,
                                    })
                                : undefined
                            }
                            onView={() => handleAttachedFilePopup(id)}
                          />
                        ) : (
                          <AttachmentsItem
                            key={id}
                            name={name}
                            size={size}
                            onClick={() =>
                              handleAttachedFileDownload({
                                companyId,
                                documentId,
                                id,
                                name,
                              })
                            }
                          />
                        ),
                    )}
                </AttachmentsList>
              )}
              {sharedFiles && sharedFiles.length > 0 && (
                <AttachmentsList
                  title={getLocalizedText('공유파일')}
                  count={sharedFiles.length}
                  className="view-attachments"
                >
                  {sharedFiles.map(
                    ({
                      documentId,
                      id,
                      name,
                      size,
                      downloadUrl,
                      viewerUrl,
                    }) => (
                      <AttachmentsItem
                        key={id}
                        name={name}
                        size={size}
                        onClick={
                          downloadUrl !== undefined &&
                          downloadUrl !== null &&
                          display === 'pc'
                            ? () =>
                                handleSharedFileDownload({
                                  documentId,
                                  id,
                                  name,
                                })
                            : undefined
                        }
                        onView={
                          viewerUrl !== undefined && viewerUrl !== null
                            ? () => handleSharedFilePopup(id)
                            : undefined
                        }
                      />
                    ),
                  )}
                </AttachmentsList>
              )}
              {attachedDocuments && attachedDocuments.length > 0 && (
                <AttachDocumentList count={attachedDocuments.length}>
                  {attachedDocuments.map((x) => (
                    <AttachDocumentItem
                      key={x.id}
                      no={x.no}
                      subject={x.subject}
                      onClick={() =>
                        // TODO: 관계사 아이디 추가
                        handleAttachedDocumentPopup({
                          dialogType: 'attacheddocument',
                          documentId: x.id,
                        })
                      }
                    />
                  ))}
                </AttachDocumentList>
              )}
              {opinions && (
                <Comment
                  opinion
                  count={opinions.length || 0}
                  user={user}
                  comments={opinions}
                />
              )}
              {comments && (
                <Comment
                  count={comments.length || 0}
                  user={user}
                  comments={comments}
                />
              )}
            </PostView.Body>
          </PostView>
        </DialogBody>
      </Dialog>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalAttachedDocumentDialog;
