import React from 'react';
import { Language } from '../../../../../groupware-common/types';
import Button from '../../../../../components/button/Button';
import PostView from '../../../../../components/post/PostView';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/**
 * @property parent 상위 이름.
 * @property names 언어별 이름.
 * @property description 설명.
 * @property onUpdate 수정 이벤트.
 * @property onDelete 삭제 이벤트.
 */
type Props = {
  parent: string;
  names: { label: Language; value: string }[];
  description: string;
  onUpdate(): void;
  onDelete(): void;
  useLink?: boolean;
};

function JobDutyContentView(props: Props): JSX.Element | null {
  // console.log(`${JobDutyContentView.name}.render`);
  const { parent, names, description, onUpdate, onDelete, useLink } = props;
  return (
    <>
      <EuiSetting.Header title={getLocalizedText('직책정보')}>
        {!useLink && (
          <>
            <Button
              text={getLocalizedText('수정')}
              iconType
              icon="edit"
              onClick={onUpdate}
            />
            <Button
              text={getLocalizedText('삭제')}
              iconType
              icon="trash-full"
              onClick={onDelete}
            />
          </>
        )}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title={getLocalizedText('상위직책')}>
                  <PostView.CategoryValue value={parent} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('직책명')}>
                  {names.map((x) => {
                    const { label, value } = x;
                    return (
                      <PostView.CategoryValue
                        key={label}
                        value={value}
                        label={label}
                      />
                    );
                  })}
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('직책설명')}>
                  <PostView.CategoryValue value={description} />
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default JobDutyContentView;
