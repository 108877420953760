import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import PostView from '../../../../../components/post/PostView';
import UserInfo from '../../../../../components/user/UserInfo';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { replaceRetentionPeriodToString } from '../../../../stores/document/folders';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import Chip from '../../../../../components/chip/Chip';
import { DocumentViewItem } from '../../../../stores/document/document';
import TimeLine from '../../../../../components/timeline/TimeLine';
import TimeLineItem from '../../../../../components/timeline/TimeLineItem';
import DocumentContentVersionViewDialog from '../../../root/document/content/DocumentContentVersionViewDialog';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { documentsFileApi } from '../../../../apis/document/v1/common';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';

function DocumentCheckoutBodyView(props: {
  pathname: string;
  search: string;
  view?: DocumentViewItem;
  type: 'full';
  folderName?: string;
}): JSX.Element | null {
  const { view, folderName } = props;
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(props.search);

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props.pathname]);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const versionList = useSelector(
    (state: RootState) => state.document.documents.version.list,
  );
  const type = props.type !== undefined ? props.type : 'full';
  if (!view) return null;

  const [state, setState] = useState<{
    versionId: number;
  }>({
    versionId: 0,
  });
  const updater = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: view.updaterId,
  });

  const versionData = versionList.map((x) => {
    const employeeId = x.creatorId;
    const directoryData = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId,
    });
    const item = {
      id: x.versionSeq,
      label: `VER${x.versionSeq}`,
      subject: x.changeReason,
      updateAt: x.createAt,
    };
    const user = {
      name: directoryData.employeeName,
      avatar: directoryData.avatar,
      from: directoryData.organizationName,
    };
    return {
      item,
      user,
    };
  });

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (id: number) => {
    documentsFileApi
      .downloadAll(id, view.currentVersionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    id: number;
    fileId: number;
    name: string;
  }) => {
    const { id, fileId, name } = arg;
    documentsFileApi
      .download(id, fileId, view.currentVersionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 버전 클릭 */
  const handleVersionClick = (id: number) => {
    setState((prev) => ({ ...prev, versionId: id }));
    dispatch(sessionActions.setDialog({ type: 'version' }));
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    if (dialogType === 'version') {
      return (
        <DocumentContentVersionViewDialog
          id={view.id}
          versionSeq={state.versionId}
          onClose={handleCloseDialog}
        />
      );
    }
    return undefined;
  };

  const { retentionPeriod, attachedFiles } = view;
  const viewContent: JSX.Element = (
    <PostView data-post-type={type}>
      <PostView.Head>
        <PostView.Title>
          {folderName ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '5px', fontSize: '15px' }}>
                <Chip theme="cancel" label={folderName} />
              </div>
              {view.subject}
            </div>
          ) : (
            `${view.subject}`
          )}
        </PostView.Title>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flex: 1 }}>
            <UserInfo
              className="view-author"
              key={updater.employeeId}
              name={updater.employeeName}
              avatar={updater.avatar}
              from={updater.organizationName}
              date={view.updateAt}
            />
          </div>
        </div>
      </PostView.Head>
      <PostView.Info>
        {retentionPeriod !== '' && (
          <PostView.InfoItem
            title={getLocalizedText('보존기간')}
            value={replaceRetentionPeriodToString(retentionPeriod)}
          />
        )}
        <PostView.InfoItem
          title={getLocalizedText('조회')}
          value={view.views}
        />
        <PostView.InfoItem
          title={getLocalizedText('좋아요')}
          value={view.likes}
        />
      </PostView.Info>
      <PostView.Body>
        <PostView.Content data={view.contents} />
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={() => handleAttachedFileDownloadAll(attachedFiles[0].id)}
          >
            {attachedFiles.map((x) => (
              <AttachmentsItem
                key={x.fileId}
                name={x.name}
                size={x.size}
                onClick={() =>
                  handleAttachedFileDownload({
                    id: x.id,
                    fileId: x.fileId,
                    name: x.name,
                  })
                }
              />
            ))}
          </AttachmentsList>
        )}
        {versionData.length > 0 && (
          <TimeLine title={getLocalizedText('버전')}>
            {versionData.map((a) => {
              return (
                <TimeLineItem
                  key={`timeline_${a.item.id}`}
                  onClick={handleVersionClick}
                  item={a.item}
                  user={a.user}
                  selected={a.item.id === view.currentVersionSeq ?? true}
                />
              );
            })}
          </TimeLine>
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {viewContent}
      {renderDialog()}
    </div>
  );
}

export default DocumentCheckoutBodyView;
