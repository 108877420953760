import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function AttendanceDayOffSettingStartContainer(props: {
  onNext(type: number): void;
}): JSX.Element {
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6014)?.name ?? '';

  const type: { value: string; label: string }[] = [
    { value: '2', label: getLocalizedText('회계연도 기준') },
    { value: '1', label: getLocalizedText('입사일자 기준') },
  ];
  const [dayOffType, setDayOffType] = useState(type[0].value);

  const handleChangeDayOffType = (value: string) => {
    setDayOffType(value);
  };

  const handleNext = () => {
    props.onNext(parseInt(dayOffType, 10));
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <h2
          style={{
            margin: '0 24px',
            padding: '24px 0',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {getLocalizedText('연차생성 기준 선택')}
        </h2>
        <EuiSetting.Item title={getLocalizedText('연차생성')}>
          <RadioGroup
            data={type}
            value={dayOffType}
            name="type"
            onChange={handleChangeDayOffType}
          />
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            <Button
              text={getLocalizedText('다음')}
              variant="contained"
              onClick={handleNext}
            />
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default AttendanceDayOffSettingStartContainer;
