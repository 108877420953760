import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import { PostListItemType } from '../../../../../components/post/PostList';
import PostView from '../../../../../components/post/PostView';
import SelectField from '../../../../../components/selectField/SelectField';
import { Column } from '../../../../../groupware-common/ui/type';
import { getQueryParams, go } from '../../../../../groupware-common/utils';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import paymentsApi, {
  PendingPaymentItem,
} from '../../../../apis/sangsanginsecurities/v1/payments';
import securitiesApi from '../../../../apis/sangsanginsecurities/v1/securities';
import { paymentsActions } from '../../../../stores/sangsanginsecurities/payments';
import { settingActions } from '../../../../stores/sangsanginsecurities/setting';
import SecuritiesListSetting from '../common/SecuritiesListSetting';
import SecuritiesPaymentBody from './SecuritiesPaymentBody';
import SecuritiesPaymentHead from './SecuritiesPaymentHead';

function SecuritiesPaymentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const accessToken = useSelector((state: RootState) => state.session.jwt);
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const currentOrganizationId = useSelector(
    (state: RootState) => state.securites.setting.currentOrganizationId,
  );
  const categories = useSelector(
    (state: RootState) => state.securites.setting.categories,
  );
  const title = categories.find((a) => a.id === 'payment')?.name ?? '';

  const list = useSelector(
    (state: RootState) => state.securites.payment.items.list,
  );
  const total = useSelector(
    (state: RootState) => state.securites.payment.items.totalCount,
  );

  const [columns, setColumns] = useState<Column<PostListItemType>[]>([
    { name: 'checked', text: '', type: 'action', visible: true },
    { name: 'evidncDeptCd', text: '증빙부서', type: 'folder', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'decisCont', text: '설명', type: 'post', visible: true, disable: true },
    { name: 'evidncDvnm', text: '증빙구분', type: 'folder', visible: true },
    { name: 'pttnDy', text: '전기일', type: 'date', visible: true },
    { name: 'acctNm', text: '계정과목', type: 'folder', visible: true },
    { name: 'payTypNm', text: '지급유형', type: 'folder', visible: true },
    { name: 'sttlMthdNm', text: '결제방법', type: 'folder', visible: true },
    { name: 'useNm', text: '사용자', type: 'user', visible: true },
    { name: 'conm', text: '거래처', type: 'folder', visible: true },
    { name: 'amt', text: '거래금액', type: 'folder', visible: true },
    { name: 'payTotAmt', text: '지급금액', type: 'folder', visible: true },
    { name: 'payAmt', text: '잔액', type: 'folder', visible: true },
    { name: 'decisTypDvnm', text: '구분', type: 'folder', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'slipNo', text: '전표정보', type: 'number', visible: true, disable: true },
    { name: 'status', text: '상태', type: 'status', visible: true },
  ]);
  const [draft, setDraft] = useState(false);

  useEffect(() => {
    setColumns(
      columns.map((a) => {
        if (a.name === 'pttnDy')
          return {
            ...a,
            type: display === 'phone' ? 'folder' : 'date',
          };
        if (a.name === 'amt' || a.name === 'payTotAmt' || a.name === 'payAmt')
          return {
            ...a,
            type: display === 'phone' ? 'number' : 'folder',
          };
        if (a.name === 'evidncDeptCd')
          return {
            ...a,
            visible: display !== 'phone',
          };
        return a;
      }),
    );
  }, [display]);

  const convertNullType = (
    obj: PendingPaymentItem,
  ): { [key in keyof PendingPaymentItem]: string } => {
    const result = {} as { [K in keyof PendingPaymentItem]: string };

    Object.entries(obj).forEach((a) => {
      const [key, value] = a;
      if (value === null) result[key as keyof PendingPaymentItem] = '';
      else if (typeof value === 'number')
        result[key as keyof PendingPaymentItem] = value.toString();
      else if (typeof value === 'string')
        result[key as keyof PendingPaymentItem] = value;
    });
    return result;
  };

  useEffect(() => {
    async function run() {
      const evidGrwNos: string[] = list
        .filter(({ checked }) => checked)
        .map((a) => a.evidGrwNo);
      const listData = await paymentsApi.pendingPaymentList({
        companyId: principal.companyId,
        employeeId: principal.employeeId,
        evidGrwNos,
      });
      const pendingPayments = listData.pendingPayments.map((a) => ({
        ...convertNullType(a),
      }));
      // eslint-disable-next-line prettier/prettier
      const headerOne = ['부서', '지급구분', '거래처', '금액', '상세', '', '품의서확인'];
      const headerTwo = ['', '', '지급처', '은행명', '계좌번호', '예금주', ''];
      const mainDiv = document.createElement('div');
      const totalDiv = document.createElement('div');
      const table = document.createElement('table');
      const tbody = document.createElement('tbody');
      let totalAmt = 0;
      // 테이블 헤더 생성.
      for (let i = 0; i < 2; i += 1) {
        const tr = document.createElement('tr');
        headerOne.forEach((_, j) => {
          const td = document.createElement('td');
          const p = document.createElement('p');
          p.setAttribute('style', 'text-align: center; margin-block: 0.3em;');
          const span = document.createElement('span');
          const strong = document.createElement('strong');
          tr.style.height = '20px';
          if (i === 0) {
            if (j === 0 || j === 1 || j === 6) td.rowSpan = 2;
            if (j === 4) td.colSpan = 2;
            if (j === 5) return;
            strong.append(headerOne[j]);
          }
          if (i === 1) {
            if (j === 0 || j === 1 || j === 6) return;
            strong.append(headerTwo[j]);
          }
          span.append(strong);
          p.append(span);
          td.append(p);
          td.setAttribute(
            'style',
            'border: 2px solid rgb(226, 226, 226); background-color: rgb(247, 247, 247); padding: 0 5px',
          );
          tr.append(td);
        });
        tbody.append(tr);
      }
      for (let i = 0; i < pendingPayments.length; i += 1) {
        const data = pendingPayments[i];
        if (data.amt !== '') totalAmt += parseInt(data.amt, 10);
        let custnm: string | HTMLButtonElement = '';
        let custSttlPay: string | DocumentFragment = data.sttlPay;
        if (data.grwDcsnum !== '') {
          custnm = document.createElement('button');
          custnm.className =
            'eui-button action icon secondary approval-linkage';
          custnm.setAttribute(
            'onClick',
            `(function(el) {
              const clickInput = document.getElementById('custom-draft-document-click');
              clickInput?.setAttribute('value', '${data.grwDcsnum}');
              clickInput?.click();
          })(this)`,
          );
          const icon = document.createElement('i');
          icon.className = 'eui-icon eui-icon-document-search';
          custnm.appendChild(icon);
        }
        const prefix = 'FB지급';
        if (data.sttlPay.startsWith(prefix)) {
          const secondfix = data.sttlPay.slice(prefix.length);
          custSttlPay = document.createDocumentFragment();
          custSttlPay.append(prefix);
          custSttlPay.append(document.createElement('br'));
          custSttlPay.append(secondfix);
        }
        const bodyOne = [
          data.deptNm,
          custSttlPay,
          data.conm,
          parseInt(data.amt, 10).toLocaleString(),
          data.decisCont,
          '',
          custnm,
        ];
        const bodyTwo = [
          '',
          '',
          data.payDest,
          data.payBnkNm,
          data.transferAccNo,
          data.transferAccDpositNm,
          '',
        ];

        for (let x = 0; x < 2; x += 1) {
          const tr = document.createElement('tr');
          bodyOne.forEach((_, j) => {
            const td = document.createElement('td');
            const p = document.createElement('p');
            p.setAttribute('style', 'text-align: center; margin-block: 0.3em;');
            const span = document.createElement('span');
            tr.style.height = '20px';
            if (x === 0) {
              if (j === 0 || j === 1 || j === 6) td.rowSpan = 2;
              if (j === 4) td.colSpan = 2;
              if (j === 5) return;
              span.append(bodyOne[j]);
            }
            if (x === 1) {
              if (j === 0 || j === 1 || j === 6) return;
              span.append(bodyTwo[j]);
            }
            p.append(span);
            td.append(p);
            td.setAttribute('style', 'border: 2px solid rgb(226, 226, 226);');
            tr.append(td);
          });
          tbody.append(tr);
        }
        if (i !== pendingPayments.length - 1) {
          const dividerTr = document.createElement('tr');
          const dividerTd = document.createElement('td');
          dividerTr.style.height = '15px';
          dividerTd.colSpan = 9;
          dividerTd.setAttribute(
            'style',
            'border: 2px solid rgb(226, 226, 226);',
          );
          dividerTr.append(dividerTd);
          tbody.append(dividerTr);
        }
      }
      table.setAttribute('border', '1');
      table.setAttribute('cellspacing', '0');
      table.setAttribute('cellpadding', '0');
      table.setAttribute(
        'style',
        'display: inline-table; width: 100%; height: 100%; white-space: normal; word-break: break-all; font-size: 10pt; border: 1px none rgb(0, 0, 0); border-collapse: collapse;',
      );
      const colGroup = document.createElement('colgroup');
      const width = ['14%', '14%', '14%', '14%', '14%', '16%', '14%'];
      for (let i = 0; i < width.length; i += 1) {
        const col = document.createElement('col');
        col.setAttribute('width', width[i]);
        colGroup.append(col);
      }
      table.append(colGroup);
      table.append(tbody);
      mainDiv.setAttribute('style', 'display: flex; flex-direction: column;');
      totalDiv.setAttribute(
        'style',
        'margin-left: auto; font-weight: 600; font-size: 10pt; padding-bottom: 4px',
      );
      totalDiv.textContent = `(지급 예정액: ${totalAmt.toLocaleString()}원)`;
      mainDiv.append(totalDiv);
      mainDiv.append(table);

      delete queryParams.dialogType;
      queryParams.contentMode = 'create';
      let value = '';
      try {
        await securitiesApi
          .create({
            provider: 'sangsanginsecurities',
            data: {
              linkType: 'PAPERLESS',
              linkId: JSON.stringify(evidGrwNos),
              content: mainDiv.outerHTML,
              writerEmployeeNumber:
                directory.employees.find(
                  ({ id }) => id === principal.employeeId,
                )?.no ?? '',
              accessToken: accessToken ?? '',
            },
          })
          .then((result) => {
            const html = document.createElement('html');
            html.innerHTML = result.trim();
            const input = html.querySelector('form')?.querySelector('input');
            value = input?.getAttribute('value') ?? '';
          });
        if (value === '') throw new Error('데이터를 찾을 수 없습니다.');
        const data: {
          token: string;
          provision: {
            provider: string;
            linkType: string;
            linkId: string;
            subject: string;
            content: string;
            writerEmail: string;
            attachedSharedfiles?: {
              id: number;
              path: string;
              name: string;
              size: number;
              feature: number;
            }[];
          };
          form: {
            content: string;
            updateAt: string;
          };
          work: {
            id: number;
            folderId: number;
            name: string;
            formId: number;
            formName: string;
            documentNo: string;
            retentionPeriod: number;
            approvalLine: string;
            referrer: string;
            viewer: string;
            useAttachFile: number;
            useAttachDocument: number;
            useOpinion: boolean;
            useComment: boolean;
            linkType: string;
            updateAt: string;
          };
        } = JSON.parse(value);
        const approvalForm = {
          provision: data.provision,
          form: data.form,
          work: data.work,
        };
        dispatch(sessionActions.setApprovalForm({ approvalForm }));
        go(pathname, queryParams);
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
        setDraft(false);
      }
    }

    if (draft) run();
  }, [draft]);

  const handleActions = (type: string) => {
    if (type === 'next') {
      const pageNo = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * pageNo) < total) {
        queryParams.pageNo = pageNo;
        go(pathname, queryParams);
      }
    }
    if (type === 'prev') {
      const pageNo = (queryParams.pageNo ?? 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(pathname, queryParams);
      }
    }
    if (type === 'listSetting') dispatch(sessionActions.setDrawer({ type }));
    if (type === 'draft') {
      if (principal.affiliatedOrganizations.length > 0 && display !== 'pc') {
        dispatch(sessionActions.setDialog({ type: 'organization' }));
      } else setDraft(true);
    }
  };

  const handleChangeChecked = (arg: {
    id: 'all' | string;
    checked: boolean;
  }) => {
    const { id: checkedId, checked } = arg;
    dispatch(paymentsActions.setListItemChecked({ id: checkedId, checked }));
  };

  const handleChangeColumnVisible = (name: string, visible: boolean) => {
    setColumns(
      columns.map((a) => {
        if (a.name === name) return { ...a, visible };
        return a;
      }),
    );
  };

  // 기안부서 선택 대화상자 닫기
  const handleCloseOrganization = () => {
    dispatch(sessionActions.setDialog());
  };

  // 기안부서 변경 이벤트
  const handleChangeOrganization = (value: string) => {
    dispatch(settingActions.saveCurrentOrganizationId(parseInt(value, 10)));
  };

  // 드로워 닫기 이벤트
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const renderHead = () => {
    return (
      <SecuritiesPaymentHead
        isPhone={display === 'phone'}
        title={title}
        totalCount={total}
        checkedCount={list.filter((a) => a.checked).length}
        pageNo={queryParams.pageNo ?? 1}
        rowsPerPage={queryParams.rowsPerPage ?? 300}
        onAction={handleActions}
        onCheckedChange={handleChangeChecked}
      />
    );
  };

  const renderBody = () => {
    return (
      <SecuritiesPaymentBody
        isPhone={display === 'phone'}
        pageNo={queryParams.pageNo}
        list={list}
        columns={columns.filter((a) => a.visible === undefined || a.visible)}
        onChangeChecked={handleChangeChecked}
      />
    );
  };

  const renderDialog = () => {
    if (queryParams.dialogType === 'organization' && display !== 'pc') {
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>기안부서 선택</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <PostView>
              <SelectField
                block
                data={principal.affiliatedOrganizations.map((a) => {
                  return {
                    value: a.id.toString(),
                    label: getOrganizationName(principal.companyId, a.id),
                  };
                })}
                value={currentOrganizationId.toString()}
                onChange={handleChangeOrganization}
              />
            </PostView>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleCloseOrganization} />
            <Button
              text="확인"
              variant="contained"
              onClick={() => {
                setDraft(true);
              }}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    if (drawerType === 'listSetting')
      return (
        <SecuritiesListSetting
          columns={columns.filter((a) => a.name !== 'checked')}
          onColumnVisibleChange={handleChangeColumnVisible}
          onClose={handleCloseDrawer}
        />
      );
    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default SecuritiesPaymentContainer;
