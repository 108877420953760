import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 전표 승인, 출납 대기 접근 가능 아이디 조회. */
async function findApprovalAuthorityTeam(companyId: number): Promise<number[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/accounting/v1/companies/${companyId}/organizations`;
    const response = await axios.get<{ ids: number[] }>(url, { headers });
    return response.data.ids.map((id) => id);
  } catch (e) {
    throw apiError(e);
  }
}

const settingApi = {
  approvalAuthorityTeam: findApprovalAuthorityTeam,
};

export default settingApi;
