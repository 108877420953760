/* eslint-disable consistent-return */
/* eslint-disable no-bitwise */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { b62, getPathMap } from '../../groupware-common/utils';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import folderBoxApi from '../apis/board/v1/folder';
import { formApi } from '../apis/board/v1/form';

const name = 'board/folder';

/** 보존기간 텍스트로 변경 */
export const replaceRetentionPeriodToString = (value: string): string => {
  return value
    .replace(/([0-9])Y/, '$1년 ')
    .replace(/([0-9])M/, '$1개월 ')
    .replace(/([0-9])D/, '$1일 ')
    .replace('FOREVER', '영구')
    .trimRight();
};

export interface PermissionOptionType {
  useRead: boolean;
  useWrite: boolean;
  useMove: boolean;
  useCopy: boolean;
}

export interface PermissionType {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디 (organization | employee)
  referenceType: string; // 참조 유형
  updateAt?: string;
}

export interface PermissionUserType {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디 (organization | employee)
  referenceType: string; // 참조 유형
  options: {
    isRead: boolean; // 읽기.
    isWrite: boolean; // 쓰기.
  };
  updateAt?: string;
}

export interface FolderListItem {
  id: number;
  parentId: number;
  seq: number;
  name: string;
  status: boolean;
  defaultLayout: number;
  option: {
    useAnonymous: boolean;
    useReply: boolean;
    useSecurePost: boolean;
    useTitleClassification: boolean;
  };
  permissions?: PermissionOptionType;
  description: string;
  isAdmin?: boolean;
  updateAt: string;
}

export interface FolderViewItem {
  id: number;
  parentId: number;
  name: string;
  status: boolean;
  defaultLayout: number;
  option: {
    useAnonymous: boolean;
    useReply: boolean;
    useSecurePost: boolean;
    useTitleClassification: boolean;
  };
  forms: { id: number; name: string }[];
  titleClassifications: {
    id: number;
    titleClassification: string;
  }[];
  retentionPeriods: { id: string; checked: boolean }[];
  defaultRetentionPeriod: string;
  description: string;
  exceptioners: PermissionType[];
  managers: PermissionType[];
  users: PermissionUserType[];
  updaterId: number;
  updateAt: string;
}

export interface SaveFolderArg {
  id?: number;
  name: string;
  parentId: number;
  status: boolean; // 사용여부
  defaultLayout: number;
  option: {
    useAnonymous: boolean;
    useReply: boolean;
    useSecurePost: boolean;
    useTitleClassification: boolean;
  };
  forms: {
    id: number;
    name: string;
  }[]; // 양식
  defaultRetentionPeriod: string; // 대표 보존기간
  retentionPeriods: { id: string; checked: boolean }[]; // 보존기간 리스트
  titleClassifications: {
    id?: number;
    titleClassification: string;
  }[]; // 말머리
  description: string; // 설명
  managers: PermissionType[];
  users: PermissionUserType[];
  exceptioners: PermissionType[];
  updateAt?: string;
}

interface State {
  list: FolderListItem[];
  item: FolderViewItem | undefined;
  adminconsole: {
    list: FolderListItem[];
  };
}

const initialState: State = {
  list: [],
  item: undefined,
  adminconsole: {
    list: [],
  },
};

// 전사 관리함 폴더 리스트 조회.
const findAllFolderList = createAsyncThunk(
  `${name}/findAllFolderList`,
  async (_: void | LocateArg, { rejectWithValue }) => {
    try {
      const data = await folderBoxApi.list({});
      return data.map((a) => ({
        ...a,
        permissions: a.permissions === null ? undefined : a.permissions,
        isAdmin: a.isAdmin === null ? undefined : a.isAdmin,
      }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 전사 관리함 사용자 폴더 리스트 조회.
const findUserFolderList = createAsyncThunk(
  `${name}/findUserFolderList`,
  async (
    arg: {
      organizationId: number;
      employeeId: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { organizationId, employeeId } = arg;
      const data = await folderBoxApi.userList({ organizationId, employeeId });
      return data.map((a) => ({
        ...a,
        permissions: a.permissions === null ? undefined : a.permissions,
        isAdmin: a.isAdmin === null ? undefined : a.isAdmin,
      }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 전사 관리함 폴더 단일 조회.
const findFolderView = createAsyncThunk(
  `${name}/findFolderView`,
  async (
    arg: {
      folderId: number;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const organizations = (getState() as RootState).directory.organization.list
      .data.items;
    const employees = (getState() as RootState).directory.employee.list.data
      .items;
    try {
      const response = await folderBoxApi.view({ id: arg.folderId });
      const formTotal = await formApi.formTotalCount({});
      const formList = (
        await formApi.formList({ pageno: 1, rowsperpage: formTotal })
      ).filter((a) => a.status === 1);
      const forms: { id: number; name: string }[] = [];
      response.forms.forEach((a) => {
        const form = formList.find(({ id }) => id === a);
        if (form) forms.push({ id: form.id, name: form.name });
      });
      const retentionPeriods: {
        id: string;
        checked: boolean;
      }[] = response.retentionPeriods.map((a) => ({
        id: a,
        checked: a === response.defaultRetentionPeriod,
      }));
      const data = {
        ...response,
        forms,
        retentionPeriods,
        managers: response.managers.filter((a) =>
          employees.some((x) => x.id === a.referenceId),
        ),
        exceptioners: response.exceptioners.filter(
          (a) =>
            a.referenceType === 'COMPANY' ||
            (a.referenceType === 'ORGANIZATION' &&
              organizations.some((x) => x.id === a.referenceId)) ||
            (a.referenceType === 'EMPLOYEE' &&
              employees.some((x) => x.id === a.referenceId)),
        ),
        users: response.users
          .filter(
            (a) =>
              a.referenceType === 'COMPANY' ||
              (a.referenceType === 'ORGANIZATION' &&
                organizations.some((x) => x.id === a.referenceId)) ||
              (a.referenceType === 'EMPLOYEE' &&
                employees.some((x) => x.id === a.referenceId)),
          )
          .map((a) => ({
            ...a,
            options: {
              isRead: a.permission.useRead,
              isWrite: a.permission.useWrite,
            },
          })),
      };
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 게시함 생성
const createFolder = createAsyncThunk(
  `${name}/createFolder`,
  async (
    arg: { data: SaveFolderArg } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    const { data } = arg;
    try {
      const param = {
        name: data.name,
        parentId: data.parentId,
        status: data.status,
        defaultLayout: data.defaultLayout,
        option: data.option,
        forms: data.forms.map((a) => {
          return a.id;
        }),
        titleClassifications: data.titleClassifications,
        retentionPeriods: data.retentionPeriods.map((a) => {
          return a.id;
        }),
        defaultRetentionPeriod: data.defaultRetentionPeriod,
        description: data.description,
        exceptioners: data.exceptioners.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        managers: data.managers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        users: data.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          options: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useMove: false,
            useCopy: false,
          },
        })),
      };
      const result = await folderBoxApi.create(param);

      if (arg.route) {
        const route = {
          pathname: `${getPathMap('/*/*/*', arg.route.pathname)}/${b62(
            result.id,
          )}`,
        };
        dispatch(findAllFolderList({}));
        dispatch(findFolderView({ folderId: result.id, route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 게시함 수정.
const updateFolder = createAsyncThunk(
  `${name}/updateFolder`,
  async (
    arg: { data: SaveFolderArg } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    const { data } = arg;
    try {
      if (!data.id || !data.updateAt) return;
      const param = {
        id: data.id,
        name: data.name,
        parentId: data.parentId,
        status: data.status,
        defaultLayout: data.defaultLayout,
        option: data.option,
        forms: data.forms.map((a) => {
          return a.id;
        }),
        titleClassifications: data.titleClassifications,
        retentionPeriods: data.retentionPeriods.map((a) => {
          return a.id;
        }),
        defaultRetentionPeriod: data.defaultRetentionPeriod,
        description: data.description,
        exceptioners: data.exceptioners.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        managers: data.managers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        users: data.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          options: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useMove: false,
            useCopy: false,
          },
        })),
        updateAt: data.updateAt,
      };
      const result = await folderBoxApi.update(param);

      if (arg.route) {
        dispatch(findAllFolderList({}));
        dispatch(findFolderView({ folderId: result.id, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시함 삭제. */
const deleteFoler = createAsyncThunk(
  `${name}/deleteFolder`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const result = await folderBoxApi.delete(arg.data);
      dispatch(findAllFolderList());
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시함 순서변경. */
const sortFolder = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      await folderBoxApi.sort(arg.data);
      const list = await folderBoxApi.list({});
      return list.map((a) => ({
        ...a,
        permissions: a.permissions === null ? undefined : a.permissions,
        isAdmin: a.isAdmin === null ? undefined : a.isAdmin,
      }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시함 일괄권한 설정. */
const bulkAuthority = createAsyncThunk(
  `${name}/bulkAuthrotiy`,
  async (
    arg: {
      folders: {
        id: number;
        updateAt: string;
      }[];
      managers: PermissionType[];
      users: PermissionUserType[];
      exceptioners: PermissionType[];
    },
    { rejectWithValue },
  ) => {
    try {
      const { folders, managers, users, exceptioners } = arg;
      const result = await folderBoxApi.bulk({
        folders,
        managers: managers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        users: users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          options: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useMove: false,
            useCopy: false,
          },
        })),
        exceptioners: exceptioners.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
      });
      if (folders.length !== result.length)
        return rejectWithValue(
          appError({
            error: getLocalizedText(
              '선택한 폴더 중 권한이 중복되는 사용자가 존재하여 일부 저장 실패하였습니다.',
            ),
          }),
        );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const folderSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearFolderView(state) {
      state.item = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllFolderList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.adminconsole.list = payload;
      })
      .addCase(findUserFolderList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.list = payload;
      })
      .addCase(findFolderView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.item = payload;
      })
      .addCase(deleteFoler.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.item = undefined;
      })
      .addCase(sortFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.adminconsole.list = payload;
          const data = payload.find((a) => a.id === state.item?.id);
          if (state.item && data)
            state.item = { ...state.item, updateAt: data.updateAt };
        }
      });
  },
});

export default folderSlice.reducer;

export const folderBoxActions = {
  allFolderList: findAllFolderList,
  userFolderList: findUserFolderList,

  folderView: findFolderView,
  clearFolderView: folderSlice.actions.clearFolderView,

  create: createFolder,
  update: updateFolder,
  delete: deleteFoler,
  sort: sortFolder,
  bulkAuthority,
};
