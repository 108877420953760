import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../components/alert/Alert';
import HelperText from '../../../components/alert/HelperText';
import Button from '../../../components/button/Button';
import EuiBody from '../../../components/layout/EuiBody';
import EuiHeader from '../../../components/layout/EuiHeader';
import EuiToolbar from '../../../components/layout/EuiToolbar';
import PostWrite from '../../../components/post/PostWrite';
import TextField from '../../../components/textfield/TextField';
import loginSettingApi from '../../../groupware-authentication/apis/authentication/v1/login';
import { LocationProps } from '../../../groupware-common/types';
import { go } from '../../../groupware-common/utils';
import informationApi from '../../../groupware-setting/apis/setting/v1/information';
import { RootState, useAppDispatch } from '../../app/store';
import { appError } from '../../stores/common/utils';
import { sessionActions } from '../../stores/session';
import { createLocalizedTextFactory } from '../../../groupware-common/utils/i18n';

function PasswordChange({ location }: LocationProps): JSX.Element {
  const dispatch = useAppDispatch();
  const getLocalizedText = createLocalizedTextFactory([
    'authentication',
    'error',
  ]);

  const isPasswordChangeRequired = useSelector(
    (state: RootState) => state.session.isPasswordChangeRequired,
  );
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const [state, setState] = useState<{
    useRequiredPasswordSpecialChar: boolean;
    change: boolean;
    nextChange: boolean;
    originPW: string;
    newPW: string;
    newPWConfirm: string;
  }>({
    useRequiredPasswordSpecialChar: false,
    change: false,
    nextChange: false,
    originPW: '',
    newPW: '',
    newPWConfirm: '',
  });
  const [validation, setValidation] = useState<{
    originPW: string;
    newPW: string;
    newPWConfirm: string;
  }>({
    originPW: '',
    newPW: '',
    newPWConfirm: '',
  });

  useEffect(() => {
    let mount = true;
    async function run() {
      const { useRequiredPasswordSpecialChar } =
        await loginSettingApi.loginSetting(companyId);
      setState((prev) => ({ ...prev, useRequiredPasswordSpecialChar }));
    }
    if (mount) run();

    return () => {
      mount = false;
    };
  }, []);

  const typeMessage = state.useRequiredPasswordSpecialChar
    ? getLocalizedText('영문, 숫자, 특수문자(@, $, !, %, *, ?, &, #)')
    : getLocalizedText('영문, 숫자');

  useEffect(() => {
    async function changePassword() {
      const { originPW: currentPassword, newPW: newPassword } = state;
      await informationApi
        .changePassword({
          currentPassword,
          newPassword,
        })
        .then((result) => {
          const initialPW = {
            originPW: '',
            newPW: '',
            newPWConfirm: '',
          };
          const status = result.status as number | undefined;
          if (status === 200) {
            dispatch(sessionActions.passwordChange()).then(() => {
              const uri = new URLSearchParams(location.search).get('uri');
              const pathname = uri === null ? '/' : uri.split('?')[0];
              const search = uri === null ? '' : uri.split('?')[1];
              go(pathname, search);
            });
          } else if (status === 400) {
            setValidation((prev) => ({
              ...prev,
              newPW: getLocalizedText(
                '비밀번호는 {{typeMessage}}를 필수로 조합하여 8~16자리로 설정해야 합니다.',
                {
                  typeMessage,
                },
              ),
            }));
          } else if (status === 401) {
            setValidation((prev) => ({
              ...prev,
              originPW: getLocalizedText('현재 비밀번호가 일치하지 않습니다.'),
            }));
          } else {
            setValidation(initialPW);
            dispatch(
              sessionActions.error(
                appError({
                  message: getLocalizedText('status.INTERNAL_SERVER_ERROR'),
                }),
              ),
            );
          }

          setState((prev) =>
            status === 200
              ? {
                  ...prev,
                  ...initialPW,
                  change: false,
                }
              : { ...prev, change: false },
          );
        });
    }

    if (state.change) changePassword();
  }, [state.change]);

  useEffect(() => {
    async function passwordNextChange() {
      await informationApi.nextChangePassword().then((result) => {
        const status = result.status as number | undefined;
        if (status === 200) {
          dispatch(sessionActions.passwordChange()).then(() => {
            const uri = new URLSearchParams(location.search).get('uri');
            const pathname = uri === null ? '/' : uri.split('?')[0];
            const search = uri === null ? '' : uri.split('?')[1];
            go(pathname, search);
          });
        } else {
          dispatch(
            sessionActions.error(
              appError({
                message: getLocalizedText('status.INTERNAL_SERVER_ERROR'),
              }),
            ),
          );
        }
        setState((prev) => ({ ...prev, nextChange: false }));
      });
    }

    if (state.nextChange) passwordNextChange();
  }, [state.nextChange]);

  /** 현재 비밀번호 변경 이벤트. */
  const handleChangeOriginPW = (event: React.ChangeEvent<HTMLInputElement>) => {
    const originPW = event.target.value;
    setState((prev) => ({
      ...prev,
      originPW,
    }));
    setValidation((prev) => ({ ...prev, originPW: '' }));
  };

  /** 새 비밀번호 변경 이벤트. */
  const handleChangeNewPW = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPW = event.target.value;
    setState((prev) => ({
      ...prev,
      newPW,
    }));
    setValidation((prev) => ({ ...prev, newPW: '' }));
  };

  /** 새 비밀번호 입력 시 유효상 검사 이벤트. */
  const handleBlurNewPW = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const passwordLength = /^.{8,16}$/;
    const newPW = event.target.value;
    const newValidation = {
      originPW: validation.originPW,
      newPW: '',
      newPWConfirm: '',
    };

    const denyPattern = state.useRequiredPasswordSpecialChar
      ? /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&#])[a-zA-Z\d@$!%*?&#]+$/g
      : /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+[\]{};:'",.<>\\/?\\|`~]+$/g;

    if (newPW.trim() === '') {
      setValidation(newValidation);
      return;
    }

    if (!denyPattern.test(newPW))
      newValidation.newPW = getLocalizedText(
        '비밀번호는 {{typeMessage}}를 필수로 사용해야 합니다.',
        { typeMessage },
      );
    else if (!passwordLength.test(newPW))
      newValidation.newPW = getLocalizedText(
        '비밀번호는 8자리 이상 16자리 이하여야 합니다.',
      );
    if (
      newValidation.newPW === '' &&
      state.newPWConfirm.trim() !== '' &&
      state.newPWConfirm !== newPW
    )
      newValidation.newPWConfirm =
        getLocalizedText('새 비밀번호와 일치하지 않습니다.');
    setValidation(newValidation);
  };

  /** 새 비밀번호 확인 변경 이벤트. */
  const handleChangeNewPWConfirm = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPWConfirm = event.target.value;
    setState((prev) => ({
      ...prev,
      newPWConfirm,
    }));
    setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
  };

  /** 새 비밀번호 확인 입력 시 유효상 검사 이벤트. */
  const handleBlurNewPWConfirm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPWConfirm = event.target.value;
    if (
      newPWConfirm.trim() === '' ||
      state.newPW.trim() === '' ||
      validation.newPW.trim() !== ''
    ) {
      setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
      return;
    }

    if (state.newPW.trim() !== newPWConfirm.trim())
      setValidation((prev) => ({
        ...prev,
        newPWConfirm: getLocalizedText('새 비밀번호와 일치하지 않습니다.'),
      }));
    else setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
  };

  /** 변경하기 클릭 이벤트. */
  const handleChange = () => {
    const newValidation = { ...validation };
    if (state.originPW === '' || state.originPW.trim() === '')
      newValidation.originPW = getLocalizedText(
        '현재 비밀번호는 빈 값일 수 없습니다.',
      );

    if (state.newPW === '' || state.newPW.trim() === '')
      newValidation.newPW = getLocalizedText(
        '새 비밀번호는 빈 값일 수 없습니다.',
      );
    else if (
      state.newPWConfirm.trim() === '' ||
      (state.newPWConfirm !== '' && state.newPW !== state.newPWConfirm)
    )
      newValidation.newPWConfirm =
        getLocalizedText('새 비밀번호와 일치하지 않습니다.');

    if (
      newValidation.originPW !== '' ||
      newValidation.newPW !== '' ||
      newValidation.newPWConfirm !== ''
    ) {
      setValidation(newValidation);
      return;
    }

    setState((prev) => ({ ...prev, change: true }));
  };

  /** 다음에 변경하기 클릭 이벤트. */
  const handleNextChange = () => {
    setState((prev) => ({ ...prev, nextChange: true }));
  };

  const { originPW, newPW, newPWConfirm } = state;
  return (
    <div className="password-change">
      <EuiHeader className="password-title">
        <EuiHeader.Title navDisabled>
          {getLocalizedText('비밀번호 변경하기')}
        </EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <PostWrite>
          <Alert severity="info" size="sm">
            {getLocalizedText(
              '비밀번호는 {{typeMessage}}를 필수로 조합하여 8~16자리로 설정해야합니다.',
              { typeMessage },
            )}
          </Alert>
          <PostWrite.Item title={getLocalizedText('현재 비밀번호')}>
            <TextField
              type="password"
              value={originPW}
              error={validation.originPW !== ''}
              onChange={handleChangeOriginPW}
            />
            {validation.originPW !== '' && (
              <HelperText>{validation.originPW}</HelperText>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('새 비밀번호')}>
            <TextField
              type="password"
              value={newPW}
              error={validation.newPW !== ''}
              onChange={handleChangeNewPW}
              onBlur={handleBlurNewPW}
            />
            {validation.newPW !== '' && (
              <HelperText>{validation.newPW}</HelperText>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('새 비밀번호 확인')}>
            <TextField
              type="password"
              value={newPWConfirm}
              error={validation.newPWConfirm !== ''}
              onChange={handleChangeNewPWConfirm}
              onBlur={handleBlurNewPWConfirm}
            />
            {validation.newPWConfirm !== '' && (
              <HelperText>{validation.newPWConfirm}</HelperText>
            )}
          </PostWrite.Item>
        </PostWrite>
        <EuiToolbar className="password-button">
          <Button
            noDuplication={state.change}
            text={getLocalizedText('변경하기')}
            variant="contained"
            onClick={handleChange}
          />
          {isPasswordChangeRequired !== 'REQUIRED' && (
            <Button
              noDuplication={state.nextChange}
              text={getLocalizedText('다음에 변경하기')}
              variant="outlined"
              onClick={handleNextChange}
            />
          )}
        </EuiToolbar>
      </EuiBody>
    </div>
  );
}

export default PasswordChange;
