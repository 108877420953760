import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { archiveActions } from '../../../../stores/approval/archive';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';

import AccessDenied from '../../../../../components/error/AccessDenied';

import ApprovalArchiveTransferFolderView from './ApprovalArchiveTransferFolderView';
import ApprovalArchiveTransferFolderList from './ApprovalArchiveTransferFolderList';

import Confirmation from '../../../../../components/alert/Confirmation';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import Loading from '../../../../../components/loading/Loading';
import EuiBody from '../../../../../components/layout/EuiBody';

type StateItem = {
  text: string;
  icon: 'folder';
  organizationId: number;
  parentId: number;
  id: number;
  seq: number;
  name: string;
  description: string;
  createAt: string;
  updateAt: string;
  checked?: boolean;
  disabled: boolean;
  childrenCount?: number;
};

function ApprovalArchiveTransferContainer(props: {
  pathname: string;
}): JSX.Element {
  // console.log(`${ApprovalArchiveTransferContainer.name}(props)`, props);

  const { pathname } = props;

  const dispatch = useAppDispatch();

  const display = useSelector((s: RootState) => s.session.display);

  const key = useSelector((state: RootState) => state.session.route.key);

  /** 세션 사용자 소속 조직 항목 배열. */
  const affiliatedOrganizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );

  const organizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );

  const folders = useSelector(
    (state: RootState) => state.approval2.archive.folders.data,
  );

  const principal = useSelector((state: RootState) => state.session.principal);

  const archiveAdministor = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );

  const initialState = {
    items: folders
      .filter(({ seq }) => seq !== 0)
      .map((a) => ({
        ...a,
        text: a.name,
        icon: 'folder' as const,
        checked: false,
        disabled: false,
      }))
      .sort((a, b) => {
        // 이관받은폴더 마지막 위치로 정렬.
        if (a.seq === -1) return 1;
        if (b.seq === -1) return -1;
        return +(a.seq > b.seq) || +(a.seq === b.seq) - 1;
      }),

    filter: '',
    organizationName: '',
    reason: '',
    confirm: false,
    receiverOrganizationId: 0,
    isView: display !== 'phone',
    disable: false,
  };

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<{
    filter: string;
    organizationName: string;
    items: StateItem[];
    reason: string;
    confirm: boolean;
    receiverOrganizationId: number;
    isView: boolean;
    disable: boolean;
  }>(initialState);

  useEffect(() => {
    if (organizationId !== 0) setLoading(false);
    setState(initialState);
  }, [key, organizationId, folders]);

  // 부서장
  const manager = affiliatedOrganizations.find(
    ({ id }) => id === organizationId,
  )?.manager;

  //  기록물 관리자
  const archiveManager =
    archiveAdministor.find((x) => x.managerId === principal.employeeId) !==
    undefined;

  const handleViewClose = () => {
    setState((prev) => ({
      ...prev,
      items: prev.items.map((a) => ({
        ...a,
        checked: false,
      })),
      isView: false,
    }));
  };

  const findChildFolderItems = (arg: {
    checked?: boolean;
    id: number;
    items: StateItem[];
  }) => {
    let result = [...arg.items];
    const clickFolder = arg.items.find((a) => a.id === arg.id);
    let checked = arg.checked ?? !clickFolder?.checked ?? false;
    if (clickFolder?.seq === -1)
      checked =
        result.filter((item) => item.parentId === arg.id).length !==
        result.filter((item) => item.parentId === arg.id && item.checked)
          .length;

    function childFolder(id: number, isChild: boolean) {
      result = result.map((a) => {
        if (a.id === id && a.seq !== -1)
          return {
            ...a,
            checked,
            disabled: checked && isChild,
          };
        return a;
      });
      const child = result.filter((a) => a.parentId === id);
      if (child.length > 0) {
        const folder = result.find((a) => a.id === id);

        child.forEach((a) => childFolder(a.id, folder?.seq !== -1));
      }
    }
    childFolder(arg.id, false);
    return result;
  };

  // list 체크박스
  const handleListItemCheck = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checkItems = findChildFolderItems({
      checked: event.target.checked,
      id,
      items: state.items,
    });

    setState((prev) => ({
      ...prev,
      items: checkItems,
    }));
  };

  // list 클릭
  const handleListItemClick = (id: number) => {
    const clickItems = findChildFolderItems({
      id,
      items: state.items,
    });
    setState((prev) => ({
      ...prev,
      items: clickItems,
    }));
  };

  const handleSubmit = () => {
    const itemIds = state.items
      .filter((a) => a.checked === true && a.disabled === false)
      .map((a) => a.id);
    dispatch(
      archiveActions.transfer({
        data: {
          folderIds: itemIds,
          senderOrganizationId: organizationId,
          reason: state.reason,
          receiverOrganizationId: state.receiverOrganizationId,
        },
      }),
    ).then(() => {
      setState((prev) => ({
        ...prev,
        receiverOrganizationId: 0,
        reason: '',
        confirm: false,
      }));
    });
  };

  const handleTransfer = (arg: {
    receiverOrganizationId: number;
    reason: string;
  }) => {
    const { receiverOrganizationId, reason } = arg;
    setState((prev) => ({
      ...prev,
      receiverOrganizationId,
      reason,
      confirm: true,
    }));
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    if (state.confirm) {
      const checkeItems = state.items.filter((a) => a.checked === true);
      const items = checkeItems.filter(
        (a) => a.checked === true && !a.disabled,
      );
      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (items.length === 0) return null;

      const itemName = items[0].name;
      return (
        <Confirmation
          onSubmit={handleSubmit}
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              confirm: false,
            }))
          }
        >
          <strong>{itemName}</strong>
          {items.length > 1 ? `외 ${items.length - 1}개의 ` : ''}
          폴더를 이관 하시겠습니까?
        </Confirmation>
      );
    }

    return null;
  };

  const renderView = () => {
    const checked = state.items.filter((a) => a.checked === true);

    if (!state.isView || checked.length === 0) {
      return <SplitUnselected label="폴더를 선택하세요" />;
    }
    return (
      <EuiSetting.Right onClose={handleViewClose}>
        <ApprovalArchiveTransferFolderView onClick={handleTransfer} />
      </EuiSetting.Right>
    );
  };

  if (loading) return <Loading />;
  return !manager && !archiveManager ? (
    <AccessDenied />
  ) : (
    <EuiBody>
      <EuiSetting>
        <EuiSetting.Left>
          <ApprovalArchiveTransferFolderList
            items={state.items}
            onItemClick={handleListItemClick}
            onItemCheck={handleListItemCheck}
          />
          {!state.isView && state.items.some((a) => a.checked === true) && (
            <EuiToolbar>
              <EuiToolbar.Left>
                <></>
              </EuiToolbar.Left>
              <EuiToolbar.Right>
                <Button
                  text="선택"
                  size="sm"
                  iconType
                  icon="arrow-from-left"
                  onClick={() =>
                    setState((prev) => ({ ...prev, isView: true }))
                  }
                />
              </EuiToolbar.Right>
            </EuiToolbar>
          )}
        </EuiSetting.Left>
        {renderView()}
      </EuiSetting>
      {renderDialog()}
    </EuiBody>
  );
}

export default ApprovalArchiveTransferContainer;
