import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../components/radio/RadioGroup';
import { DirectoryTreeItemArg } from '../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { preferencesActions } from '../../../stores/preferences';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function ResourcePreferencesContainer(): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const basic = useSelector(
    (state: RootState) => state.resource.preferences.basic,
  );
  const initAdministrators = useSelector(
    (state: RootState) => state.resource.preferences.administrators,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'setting');
  const initialState = {
    saveing: false,
    change: false,
    isUsePastDate: basic.isAvailablePastReservation,
    administrators: employees
      .filter(
        (a) =>
          a.companyId === principal.companyId &&
          initAdministrators.some((b) => a.id === b.employeeId),
      )
      .map((a) => {
        const {
          companyId,
          id: employeeId,
          representativeOrganizationId: organizationId,
          avatar,
        } = a;
        const updateAt =
          initAdministrators.find((b) => b.employeeId === employeeId)
            ?.updateAt ?? '1000-01-01';
        return {
          companyId,
          employeeId,
          organizationId,
          avatar,
          updateAt,
        };
      }),
    administratorsMenuPoint: undefined,
    validation: '',
  };

  const [state, setState] = useState<{
    saveing: boolean;
    change: boolean;
    isUsePastDate: boolean;
    administrators: {
      companyId: number;
      employeeId: number;
      organizationId: number;
      avatar: string;
      updateAt: string;
    }[];
    administratorsMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    validation: string;
  }>(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { isUsePastDate, administrators } = state;

      const save = await dispatch(
        preferencesActions.savePreferences({
          isAvailablePastReservation: isUsePastDate,
          updateAt: basic.updateAt,
        }),
      );

      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }

      const removeAdministrators = initAdministrators
        .filter(
          (a) =>
            administrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId, updateAt }) => ({ employeeId, updateAt }));
      const remove = await dispatch(
        preferencesActions.removeAdministrators(removeAdministrators),
      );

      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const appendAdministrators = administrators
        .filter(
          (a) =>
            initAdministrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId }) => ({ employeeId }));
      const append = await dispatch(
        preferencesActions.appendAdministrators(appendAdministrators),
      );

      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  /** 지난 날짜 예약 사용여부 변경. */
  const handleChangeIsUsePastDate = (value: boolean) => {
    setState((prev) => ({ ...prev, change: true, isUsePastDate: value }));
  };

  /** 관리자 추가. */
  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prev) => {
      const { administrators } = prev;
      const administratorsMenuPoint = undefined;
      if (administrators.some((a) => a.employeeId === employeeId))
        return { ...prev, administratorsMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined) return { ...prev, administratorsMenuPoint };

      const updateAt =
        initAdministrators.find((a) => a.employeeId === employeeId)?.updateAt ??
        '';

      return {
        ...prev,
        change: true,
        administrators: [
          ...administrators,
          {
            companyId,
            employeeId,
            organizationId: employee.representativeOrganizationId,
            avatar: employee.avatar,
            updateAt,
          },
        ],
      };
    });
  };

  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { administratorsMenuPoint } = state;
    if (event !== undefined && administratorsMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 삭제. */
  const handleAdministratorsDelete = (employeeId: number) => {
    setState((prev) => ({
      ...prev,
      change: true,
      administrators: prev.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
    }));
  };

  /** 취소. */
  const handleCancel = () => {
    setState(initialState);
  };

  /** 저장. */
  const handleSave = () => {
    const { administrators } = state;
    if (administrators.length === 0) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('관리자를 등록하세요.'),
      }));
      return;
    }
    setState((prev) => ({ ...prev, saveing: true }));
  };

  const title = categories.find((a) => a.id === 6001)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('지난날짜 예약')}>
          <RadioGroup
            name={getLocalizedText('지난날짜예약')}
            data={[
              {
                value: false,
                label: getLocalizedText('사용 안함'),
              },
              { value: true, label: getLocalizedText('사용함') },
            ]}
            value={state.isUsePastDate}
            onChange={handleChangeIsUsePastDate}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title={getLocalizedText('관리자')}>
          <ChipGroup
            add={getLocalizedText('추가')}
            onAdd={handleDirectoryTreeMenuToggle}
          >
            {state.administrators.map(
              ({ companyId, employeeId, organizationId, avatar }) => (
                <Chip
                  key={`${companyId}/${employeeId}`}
                  label={getEmployeeName(companyId, employeeId)}
                  etc={getOrganizationName(companyId, organizationId)}
                  avatar={avatar}
                  onDelete={() => handleAdministratorsDelete(employeeId)}
                />
              ),
            )}
            {state.administratorsMenuPoint && (
              <DirectoryMenuTreeContainer
                point={state.administratorsMenuPoint}
                typeToFilter="employee"
                onItemClick={handleAdministratorsAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {state.change && (
              <Button
                text={getLocalizedText('저장')}
                variant="contained"
                loading={state.saveing}
                onClick={handleSave}
              />
            )}
            {state.change && !state.saveing && (
              <Button
                text={getLocalizedText('취소')}
                variant="outlined"
                onClick={handleCancel}
              />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
        <FeedBack
          text={state.validation}
          onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
        />
      </EuiBody>
    </>
  );
}

export default ResourcePreferencesContainer;
