import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Search, {
  SearchDateProps,
} from '../../../../../components/search/Search';
import EuiBody from '../../../../../components/layout/EuiBody';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { documentActions } from '../../../../stores/approval/document';
import ApprovalLineDialogContainer, {
  getApprovalLineDrafter,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import {
  getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { SharePermissionType } from '../common/containers/WorkApprovalLineDialogContainer';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

function statusLabel(type: string) {
  switch (type) {
    case '1':
    case 'proceeding':
      return getLocalizedText('진행중');
    case '4':
    case 'return':
      return getLocalizedText('반려');
    case '3':
    case 'done':
      return getLocalizedText('완료');
    case 'cancel':
      return getLocalizedText('취소');
    default:
      return '';
  }
}

function statusTheme(type: string) {
  switch (type) {
    case 'proceeding':
      return 'primary';
    case 'return':
      return 'error';
    case 'done':
      return 'success';
    case 'cancel':
      return 'cancel';
    default:
      return undefined;
  }
}

function ApprovalAddPermissionContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const pathmap = getPathMap('/*/*/*', pathname);
  const queryParams = getQueryParams(search);

  const directory = useDirectory();
  const principal = useSelector((s: RootState) => s.session.principal);
  const permissionView = useSelector(
    (s: RootState) => s.approval2.document.adminconsolePermission,
  );
  const { folderId } = getPathParams<{ folderId: string }>(
    '/*/*/:folderId',
    pathname,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find(
    (a) => a.id === (folderId === 'viewpermission' ? 6011 : 6010),
  )?.name;
  let toolbarActions: ActionEventProps[] = [];
  if (folderId === 'viewpermission') {
    toolbarActions = [
      {
        code: 'addViewPermission',
        label: getLocalizedText('조회권 추가'),
        type: 'text',
        icon: 'document-stamp',
      },
      {
        code: 'deleteViewPermission',
        label: getLocalizedText('조회권 삭제'),
        type: 'text',
        icon: 'document-search',
      },
    ];
  } else {
    toolbarActions = [
      {
        code: 'addReferencePermission',
        label: getLocalizedText('참조권 추가'),
        type: 'text',
        icon: 'document-stamp',
      },
      {
        code: 'deleteReferencePermission',
        label: getLocalizedText('참조권 삭제'),
        type: 'text',
        icon: 'document-search',
      },
    ];
  }

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 56 },
    {
      field: 'work',
      label: getLocalizedText('업무'),
      width: 168,
    },
    {
      field: 'documentNo',
      label: getLocalizedText('문서번호'),
      width: 220,
    },
    {
      field: 'subject',
      label: getLocalizedText('제목'),
      minWidth: 200,
    },
    {
      field: 'drafterName',
      label: getLocalizedText('기안자'),
      width: 158,
    },
    {
      field: 'permission',
      label:
        folderId === 'viewpermission'
          ? getLocalizedText('조회권')
          : getLocalizedText('참조권'),
      width: 358,
    },
    {
      field: 'date',
      label: getLocalizedText('기안일'),
      width: 118,
      align: 'right',
    },
    {
      field: 'status',
      label: getLocalizedText('상태'),
      width: 98,
      align: 'right',
    },
  ]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    scrollbar.current?.scrollTo(0, 0);
  }, [props]);

  useEffect(() => {
    setColumns((prev) =>
      prev.map((a) =>
        a.field === 'permission'
          ? {
              ...a,
              label:
                folderId === 'viewpermission'
                  ? getLocalizedText('조회권')
                  : getLocalizedText('참조권'),
            }
          : a,
      ),
    );
  }, [folderId]);

  /** 기본검색 */
  const handleSearch = (value: {
    keyword: string;
    filter: string;
    status: string;
  }) => {
    const { keyword, filter, status } = value;
    const params = { ...queryParams };
    delete params.subject;
    delete params.documentNumber;
    delete params.workName;
    delete params.formName;
    delete params.content;
    delete params.attachedFileName;
    delete params.drafterName;
    delete params.documentNumber;
    delete params.approvalTargetName;
    delete params.viewtargetname;
    delete params.referencetargetname;
    delete params.execludeviewtargetnames;
    delete params.execludereferencetargetnames;
    delete params.pageNo;

    params.searchWord = keyword === '' ? undefined : keyword;
    params.searchCode = keyword === '' || filter === '' ? undefined : filter;
    params.status = status === 'all' ? undefined : status;
    go(pathmap, getQueryParams(params));
  };

  /* 목록개수 변경 */
  const handleChangeRowLength = (value: number) => {
    const params = { ...queryParams };
    delete params.pageNo;
    params.rowsPerPage = value;
    go(pathmap, getQueryParams(params));
  };

  /** 목록 노출 */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  /* 체크박스 선택 */
  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    dispatch(
      documentActions.adminconsolePermissionChecked({
        itemId: id,
        checked: event.target.checked,
      }),
    );
  };
  /* 체크박스 전체선택 */
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      documentActions.adminconsolePermissionChecked({
        itemId: 'all',
        checked: event.target.checked,
      }),
    );
  };

  /** 클릭이벤트 */
  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id: number;
  }) => {
    const { code, id } = arg;

    /** 이전 페이지. */
    if (code === 'prePage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) - 1;
      if (params.pageNo > 0) {
        if (params.pageNo === 1) delete params.pageNo;
        go(pathmap, getQueryParams(params));
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) + 1;
      go(pathmap, getQueryParams(params));
    }

    if (code === 'cancelSelected') {
      dispatch(
        documentActions.adminconsolePermissionChecked({
          itemId: 'all',
          checked: false,
        }),
      );
    }
    if (code === 'addViewPermission') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    if (code === 'deleteViewPermission') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    if (code === 'addReferencePermission') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    if (code === 'deleteReferencePermission') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    if (code === 'subject') {
      queryParams.drawerType = 'subject';
      queryParams.id = id;
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }
  };

  const items = permissionView.list.map((a) => {
    const statusToNumber = parseInt(a.status, 10);
    let status = '';
    if (statusToNumber === 1) status = 'proceeding';
    else if (statusToNumber === 3) status = 'done';
    else if (statusToNumber === 4) status = 'return';
    return {
      checked: a.checked,
      companyId: principal.companyId,
      id: a.id,
      workName: a.workName,
      status,
      no: a.no,
      subject: a.subject,
      approvalLine: a.approvalLine,
      viewPermission: a.permissionType,
      referencePermission: a.permissionType,
      draftAt: a.draftAt,
      completeAt: a.completeAt,
      linkWait: a.linkWait ?? false,
    };
  });

  const rows: DataGridRowsProps<number> = items.map((l) => {
    const drafter = getApprovalLineDrafter(l.approvalLine);
    const permissionItems = permissionView.list
      .filter((k) => k.id === l.id)
      .flatMap((a) =>
        a.permissionType?.groups
          .filter((group) => group.items.length > 0)
          .flatMap((group) =>
            group.items.map(
              (item) => item.employeeName || item.organizationName,
            ),
          ),
      )
      .join(', ');

    if (drafter !== undefined) {
      let drafterDirectoryData:
        | {
            companyId: number;
            companyName: string;
            organizationId: number;
            organizationName: string;
            employeeId: number;
            employeeName: string;
            jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
            jobClassName: string;
            jobPositionId: number;
            jobPositionName: string;
            jobDutyId: number;
            jobDutyName: string;
            avatar?: string;
          }
        | undefined;
      if (drafter.employeeId) {
        drafterDirectoryData = getDirectoryData({
          ...directory,
          ...drafter,
        });
      }
      return [
        {
          type: 'checkbox' as const,
          id: l.id,
          value: l.checked,
        },
        { type: 'text' as const, value: l.workName },
        { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
        {
          type: 'subject' as const,
          className: 'no-click-event',
          value: l.subject,
          id: l.id,
          linkWait: l.linkWait,
          approvalLine: l.approvalLine,
        },
        {
          type: 'user' as const,
          avatar: drafterDirectoryData
            ? getAvatarPath(drafterDirectoryData)
            : '',
          icon: drafterDirectoryData ? undefined : 'sitemap-fill',
          value: drafterDirectoryData
            ? `${drafterDirectoryData.employeeName} ${drafterDirectoryData.jobPositionName}`
            : drafter.organizationName,
          from: drafterDirectoryData
            ? drafterDirectoryData.organizationName
            : '',
        },
        {
          type: 'userlist' as const,
          value: permissionItems,
          id: l.id,
        },
        { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
        {
          type: 'chip' as const,
          value: statusLabel(l.status),
          theme: statusTheme(l.status),
        },
      ];
    }
    return [
      {
        type: 'checkbox' as const,
        id: l.id,
        value: l.checked,
      },
      { type: 'text' as const, value: l.workName },
      { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
      {
        type: 'subject' as const,
        className: 'no-click-event',
        value: l.subject,
        id: l.id,
      },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
      {
        type: 'chip' as const,
        value: statusLabel(l.status),
        theme: statusTheme(l.status),
      },
    ];
  });

  // 조회권 추가
  const handleApprovalLineViewAddSave = (arg: {
    companyId: number;
    id: number;
    reason: string;
    viewPermission?: SharePermissionType;
  }) => {
    const { companyId, reason, viewPermission } = arg;
    const location = utils.getLocation({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });
    const addViewPermissionList = viewPermission?.groups[0].items;
    const listId = permissionView.list
      .filter((x) => x.checked)
      .map((a) => {
        return {
          companyId,
          id: a.id,
        };
      });

    const data = {
      documents: listId,
      sharedPermissionGroupItems: addViewPermissionList,
      reason,
    };
    dispatch(documentActions.addViewPermission({ data, location }));
  };

  // 조회권 삭제
  const handleApprovalLineViewDeleteSave = (arg: {
    companyId: number;
    id: number;
    reason: string;
    viewPermission?: SharePermissionType;
  }) => {
    const { companyId, reason, viewPermission } = arg;
    const location = utils.getLocation({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });
    const deleteViewPermission = viewPermission?.groups[0].items;
    const listId = permissionView.list
      .filter((x) => x.checked)
      .map((a) => {
        return {
          companyId,
          id: a.id,
        };
      });

    const data = {
      documents: listId,
      sharedPermissionGroupItems: deleteViewPermission,
      reason,
    };
    dispatch(documentActions.deleteViewPermission({ data, location }));
  };

  // 참조권 추가
  const handleApprovalLineReferenceAddSave = (arg: {
    companyId: number;
    id: number;
    reason: string;
    referencePermission?: SharePermissionType;
  }) => {
    const { companyId, reason, referencePermission } = arg;
    const location = utils.getLocation({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });
    const addReferencePermission = referencePermission?.groups[0].items;
    const listId = permissionView.list
      .filter((x) => x.checked)
      .map((a) => {
        return {
          companyId,
          id: a.id,
        };
      });

    const data = {
      documents: listId,
      sharedPermissionGroupItems: addReferencePermission,
      reason,
    };
    dispatch(documentActions.addReferencePermission({ data, location }));
  };

  // 참조권 삭제
  const handleApprovalLineReferenceDeleteSave = (arg: {
    companyId: number;
    id: number;
    reason: string;
    referencePermission?: SharePermissionType;
  }) => {
    const { companyId, reason, referencePermission } = arg;
    const location = utils.getLocation({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });
    const deleteRereferncePermission = referencePermission?.groups[0].items;
    const listId = permissionView.list
      .filter((x) => x.checked)
      .map((a) => {
        return {
          companyId,
          id: a.id,
        };
      });

    const data = {
      documents: listId,
      sharedPermissionGroupItems: deleteRereferncePermission,
      reason,
    };
    dispatch(documentActions.deleteReferencePermission({ data, location }));
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 조회권 추가 대화상자
    if (dialogType === 'addViewPermission') {
      return (
        <ApprovalLineDialogContainer
          title={getLocalizedText('조회권 추가')}
          type="add"
          companyId={principal.companyId}
          viewPermission={{
            version: '0.1',
            groups: [
              {
                id: `${Date.now()}`,
                required: false,
                modify: true,
                items: [],
              },
            ],
          }}
          onSave={handleApprovalLineViewAddSave}
          onCancel={handleCloseDialog}
        />
      );
    }

    // 조회권 삭제 대화상자
    if (dialogType === 'deleteViewPermission') {
      return (
        <ApprovalLineDialogContainer
          title={getLocalizedText('조회권 삭제')}
          type="delete"
          companyId={principal.companyId}
          viewPermission={{
            version: '0.1',
            groups: [
              {
                id: `${Date.now()}`,
                required: false,
                modify: true,
                items: [],
              },
            ],
          }}
          onSave={handleApprovalLineViewDeleteSave}
          onCancel={handleCloseDialog}
        />
      );
    }
    // 참조권 대화상자
    if (dialogType === 'addReferencePermission') {
      return (
        <ApprovalLineDialogContainer
          title={getLocalizedText('참조권 추가')}
          type="add"
          companyId={principal.companyId}
          referencePermission={{
            version: '0.1',
            groups: [
              {
                id: `${Date.now()}`,
                required: false,
                modify: true,
                items: [],
              },
            ],
          }}
          onSave={handleApprovalLineReferenceAddSave}
          onCancel={handleCloseDialog}
        />
      );
    }

    // 참조권 삭제 대화상자
    if (dialogType === 'deleteReferencePermission') {
      return (
        <ApprovalLineDialogContainer
          title={getLocalizedText('참조권 삭제')}
          type="delete"
          companyId={principal.companyId}
          referencePermission={{
            version: '0.1',
            groups: [
              {
                id: `${Date.now()}`,
                required: false,
                modify: true,
                items: [],
              },
            ],
          }}
          onSave={handleApprovalLineReferenceDeleteSave}
          onCancel={handleCloseDialog}
        />
      );
    }

    return null;
  };

  /** 상세 검색 */
  const handleSearchAdvanced = (arg: {
    filters: {
      code: string;
      keyword: string;
    }[];
    date: SearchDateProps;
    status?: string;
  }) => {
    delete queryParams.searchCode;
    delete queryParams.searchWord;
    delete queryParams.pageNo;

    const { start, end, type } = arg.date;

    // 기간 검색
    if (start !== null && end !== null) {
      queryParams.dateType = type;
      queryParams.startDate = dateFormat(initialDate(start), 'yyyy-MM-DD');
      queryParams.endDate = dateFormat(initialDate(end), 'yyyy-MM-DD');
    }
    if (type === 'all') {
      queryParams.dateType = undefined;
      queryParams.startDate = undefined;
      queryParams.endDate = undefined;
    }

    const { status } = arg;
    queryParams.status = status === 'all' ? undefined : status;

    // 상세 검색
    arg.filters.forEach(({ code, keyword }) => {
      if (code === 'subject')
        queryParams.subject = keyword === '' ? undefined : keyword;
      if (code === 'documentNumber')
        queryParams.documentNumber = keyword === '' ? undefined : keyword;
      if (code === 'workName')
        queryParams.workName = keyword === '' ? undefined : keyword;
      if (code === 'formName')
        queryParams.formName = keyword === '' ? undefined : keyword;
      if (code === 'content')
        queryParams.content = keyword === '' ? undefined : keyword;
      if (code === 'drafterName')
        queryParams.drafterName = keyword === '' ? undefined : keyword;
      if (code === 'draftOrganizationName')
        queryParams.draftOrganizationName =
          keyword === '' ? undefined : keyword;
      if (code === 'approvalTargetName')
        queryParams.approvalTargetName = keyword === '' ? undefined : keyword;
      if (code === 'viewtargetname')
        queryParams.viewtargetname = keyword === '' ? undefined : keyword;
      if (code === 'referencetargetname')
        queryParams.referencetargetname = keyword === '' ? undefined : keyword;
      if (code === 'execludeviewtargetnames')
        queryParams.execludeviewtargetnames =
          keyword === '' ? undefined : keyword;
      if (code === 'execludereferencetargetnames')
        queryParams.execludereferencetargetnames =
          keyword === '' ? undefined : keyword;
    });
    go(pathname, queryParams);
  };
  const advancedOptions = [
    { value: 'subject', label: getLocalizedText('제목') },
    {
      value: 'documentNumber',
      label: getLocalizedText('문서번호'),
    },
    { value: 'workName', label: getLocalizedText('업무명') },
    { value: 'formName', label: getLocalizedText('양식명') },
    { value: 'content', label: getLocalizedText('본문') },
    {
      value: 'drafterName',
      label: getLocalizedText('기안자'),
    },
    {
      value: 'draftOrganizationName',
      label: getLocalizedText('기안부서'),
    },
    {
      value: 'approvalTargetName',
      label: getLocalizedText('결재대상'),
    },
    folderId === 'viewpermission'
      ? {
          value: 'viewtargetname',
          label: getLocalizedText('조회권'),
        }
      : {
          value: 'referencetargetname',
          label: getLocalizedText('참조권'),
        },
    folderId === 'viewpermission'
      ? {
          value: 'execludeviewtargetnames',
          label: getLocalizedText('조회 제외'),
        }
      : {
          value: 'execludereferencetargetnames',
          label: getLocalizedText('참조 제외'),
        },
  ];

  const advancedFilter = [
    { code: 'subject', keyword: queryParams.subject ?? '' },
    { code: 'documentNumber', keyword: queryParams.documentNumber ?? '' },
    { code: 'workName', keyword: queryParams.workName ?? '' },
    { code: 'formName', keyword: queryParams.formName ?? '' },
    { code: 'content', keyword: queryParams.content ?? '' },
    { code: 'drafterName', keyword: queryParams.drafterName ?? '' },
    {
      code: 'draftOrganizationName',
      keyword: queryParams.draftOrganizationName ?? '',
    },
    {
      code: 'approvalTargetName',
      keyword: queryParams.approvalTargetName ?? '',
    },
    folderId === 'viewpermission'
      ? {
          code: 'viewtargetname',
          keyword: queryParams.viewtargetname ?? '',
        }
      : {
          code: 'referencetargetname',
          keyword: queryParams.referencetargetname ?? '',
        },
    folderId === 'viewpermission'
      ? {
          code: 'execludeviewtargetnames',
          keyword: queryParams.execludeviewtargetnames ?? '',
        }
      : {
          code: 'execludereferencetargetnames',
          keyword: queryParams.execludereferencetargetnames ?? '',
        },
  ];

  const status =
    queryParams.status === undefined || queryParams.status === ''
      ? 'all'
      : queryParams.status;

  /** 날짜 필터 */
  let dateFilter = {
    type: queryParams.dateType ? queryParams.dateType : 'all',
    start: queryParams.startDate ? timezoneDate(queryParams.startDate) : null,
    end: queryParams.endDate ? timezoneDate(queryParams.endDate) : null,
  };

  /** 상세검색 초기화 */
  const handleResetAdvanced = () => {
    advancedFilter.map((a) => ({
      ...a,
      keyword: '',
    }));
    dateFilter = {
      type: 'all',
      start: null,
      end: null,
    };
  };

  const isAdvancedSearch =
    advancedFilter.some((a) => a.keyword !== '') ||
    dateFilter.start !== null ||
    dateFilter.end !== null;

  const renderContent = () => {
    const result: JSX.Element = (
      <>
        <EuiHeader>
          <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
          <EuiHeader.Search>
            <Search
              keyword={queryParams.searchWord || ''}
              options={[
                {
                  value: 'subject',
                  label: getLocalizedText('제목'),
                },
                {
                  value: 'documentNumber',
                  label: getLocalizedText('문서번호'),
                },
                {
                  value: 'workName',
                  label: getLocalizedText('업무명'),
                },
                {
                  value: 'drafterName',
                  label: getLocalizedText('기안자'),
                },
                folderId === 'viewpermission'
                  ? {
                      value: 'viewtargetname',
                      label: getLocalizedText('조회권'),
                    }
                  : {
                      value: 'referencetargetname',
                      label: getLocalizedText('참조권'),
                    },
              ]}
              filter={queryParams.searchCode || 'subject'}
              statusOptions={[
                { value: 'all', label: getLocalizedText('전체') },
                { value: '1', label: getLocalizedText('진행중') },
                { value: '3', label: getLocalizedText('완료') },
              ]}
              status={status}
              advancedOptions={advancedOptions}
              advancedFilter={advancedFilter}
              onResetAdvanced={handleResetAdvanced}
              onSearchAdvanced={handleSearchAdvanced}
              onSearch={handleSearch}
              date={dateFilter}
            />
          </EuiHeader.Search>
          {isAdvancedSearch ? (
            <EuiHeader.SearchResult>
              {advancedFilter.map((a) => {
                if (a.keyword !== '')
                  return (
                    <>
                      <strong className="category">
                        {`${
                          (
                            advancedOptions.find(
                              (option) => option.value === a.code,
                            ) || {}
                          ).label
                        } : `}
                      </strong>
                      <em className="keyword">{a.keyword}</em>
                    </>
                  );
                return null;
              })}
              {dateFilter.start !== null && dateFilter.end !== null && (
                <div className="category-wrap">
                  <strong className="category">
                    {getLocalizedText('기간')}
                    {' : '}
                  </strong>
                  <em className="keyword">{`${dateFormat(
                    dateFilter.start,
                    'yyyy-MM-DD',
                  )} ~ ${dateFormat(dateFilter.end, 'yyyy-MM-DD')}`}</em>
                </div>
              )}
            </EuiHeader.SearchResult>
          ) : null}
        </EuiHeader>
        <EuiBody>
          <DataGrid
            scrollbar={scrollbar}
            caption={getLocalizedText('결재문서')}
            columns={columns}
            rows={rows}
            checkedCount={items.filter((i) => i.checked).length}
            pagination={{
              no: queryParams.pageNo || 1,
              total: permissionView.totalCount,
              row: queryParams.rowsPerPage || 15,
              onChangeRow: handleChangeRowLength,
            }}
            toolbarActions={toolbarActions}
            onChecked={handleChangeChecked}
            onCheckedAll={handleChangeCheckedAll}
            onClick={handleAction}
            onChangeColumnVisible={handleChangeColumnVisible}
            loading={loading}
          />
        </EuiBody>
      </>
    );
    return result;
  };
  return (
    <>
      {renderContent()}
      {renderDialog()}
    </>
  );
}

export default ApprovalAddPermissionContainer;
