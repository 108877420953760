import React from 'react';
import { getParentItems } from '../../../groupware-common/utils';
import { IconType } from '../../../groupware-common/types/icon';
import DefaultMenuItem from './DefaultMenuItem';

type Props = {
  selectedId?: string;
  items: {
    id: string;
    parentId: string;
    text: string;
    icon?: IconType;
    isExpand?: boolean;
  }[];
  onMenuClick?(id: string): void;
  onMenuToggle?(id: string): void;
};

type State = {
  selectedId?: string;
};

class DefaultMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedId: props.selectedId,
    };
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
  }

  handleExpandToggle(id: string): void {
    const selectedItem = this.props.items.find((x) => x.id === id);
    const { onMenuToggle } = this.props;
    if (selectedItem && onMenuToggle) {
      onMenuToggle(selectedItem.id);
    }
  }

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    if (state.selectedId === props.selectedId) return null;
    return {
      selectedId: props.selectedId,
    };
  }

  render(): JSX.Element {
    // console.log(`${DefaultMenu.name}.render`);
    // console.log('props', JSON.stringify(this.props));

    const { selectedId, items, onMenuClick } = this.props;

    return (
      <ul>
        {items
          .filter((x) => x.parentId === '')
          .map((x) => {
            return (
              <DefaultMenuItem
                key={x.id}
                id={x.id}
                parentId={x.parentId}
                text={x.text}
                icon={x.icon}
                items={items}
                selectedId={selectedId}
                isExpand={x.isExpand}
                onExpandToggle={this.handleExpandToggle}
                onClick={onMenuClick}
              />
            );
          })}
      </ul>
    );
  }
}

export default DefaultMenu;
