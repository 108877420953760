import React from 'react';
import { useSelector } from 'react-redux';
import { CustomNumbers } from '../../../../../groupware-common/types';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Button from '../../../../../components/button/Button';
import PostView from '../../../../../components/post/PostView';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';
import {
  FolderViewItem,
  replaceRetentionPeriodToString,
} from '../../../../stores/folder';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import { RootState as R } from '../../../../../groupware-webapp/app/store';

function formatUseUnUse(value: boolean): string {
  switch (value) {
    case true:
      return getLocalizedText('사용함');
    case false:
      return getLocalizedText('사용안함');
    default:
      return getLocalizedText('사용안함');
  }
}

function formatViewType(value: number): string {
  switch (value) {
    case 1:
      return getLocalizedText('목록형');
    case 2:
      return getLocalizedText('좌우분할형');
    case 3:
      return getLocalizedText('상하분할형');
    case 4:
      return getLocalizedText('갤러리형');
    default:
      return '';
  }
}

function BoardFolderBoxView(props: {
  paths: string[];
  view: FolderViewItem;
  onSort(): void;
  onUpdate(): void;
  onDelete(): void;
}): JSX.Element {
  const { paths, view, onSort, onUpdate, onDelete } = props;

  const directory = useDirectory();
  /** 직위 */
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );

  /** 권한 조회 */
  const handleMatchingPermissions = (arg: {
    isRead: boolean;
    isWrite: boolean;
  }): string => {
    const matchingPermissions: string[] = [];
    if (arg.isRead) {
      matchingPermissions.push(getLocalizedText('읽기'));
    }
    if (arg.isWrite) {
      matchingPermissions.push(getLocalizedText('쓰기'));
    }
    return matchingPermissions.join('/');
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('게시함 정보')}>
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={onSort}
        />
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={onUpdate}
        />
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-full"
          onClick={onDelete}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Body>
            <PostView.Title>{view.name}</PostView.Title>
            <PostView.Info>
              <PostView.InfoItem
                title={getLocalizedText('보존기간')}
                value={
                  view.defaultRetentionPeriod !== ''
                    ? replaceRetentionPeriodToString(
                        view.defaultRetentionPeriod,
                      )
                    : getLocalizedText('사용안함')
                }
              />
              <PostView.InfoItem
                title={getLocalizedText('사용여부')}
                value={formatUseUnUse(view.status)}
              />
              <PostView.InfoItem
                title={getLocalizedText('댓글')}
                value={formatUseUnUse(view.option.useReply)}
              />
              <PostView.InfoItem
                title={getLocalizedText('익명')}
                value={formatUseUnUse(view.option.useAnonymous)}
              />
              <PostView.InfoItem
                title={getLocalizedText('보안게시')}
                value={formatUseUnUse(view.option.useSecurePost)}
              />
            </PostView.Info>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title={getLocalizedText('설명')}>
                  <PostView.CategoryValue value={view.description} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('보기 모드')}>
                  <PostView.CategoryValue
                    value={formatViewType(view.defaultLayout)}
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('말머리')}>
                  {!view.option.useTitleClassification ? (
                    <PostView.CategoryValue
                      value={getLocalizedText('사용안함')}
                    />
                  ) : (
                    <ChipGroup>
                      {view.titleClassifications.map((a) => (
                        <Chip
                          className="cell-tag"
                          size="sm"
                          key={a.id}
                          label={a.titleClassification}
                        />
                      ))}
                    </ChipGroup>
                  )}
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('양식')}>
                  <PostView.CategoryValue
                    value={
                      view.forms.length > 0
                        ? getLocalizedText('사용함')
                        : getLocalizedText('사용안함')
                    }
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('관리자')}>
                  <ChipGroup>
                    {view.managers
                      .map((a) => {
                        let jobClassSeq = 0;
                        if (a.referenceType === 'EMPLOYEE') {
                          const employeeData = getDirectoryData({
                            ...directory,
                            companyId: a.referenceCompanyId,
                            employeeId: a.referenceId,
                          });
                          // 직위 또는 직책 순서로 정렬 순서 결정.
                          jobClassSeq =
                            (jobClassType === 'jobduty'
                              ? jobDuties.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    v.id === employeeData.jobDutyId,
                                )?.seq
                              : jobPositions.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    employeeData.jobPositionId === v.id,
                                )?.seq) || CustomNumbers.SMALLINT_MAX;
                        }
                        return {
                          ...a,
                          jobClassSeq,
                        };
                      })
                      // 회사 → 부서 → 직원 순으로 정렬 후
                      // 직위 또는 직책 순번이 낮을수록 밑으로.
                      .sort((a, b) => {
                        if (a.referenceType !== b.referenceType) {
                          if (a.referenceType === 'COMPANY') return -1;
                          if (a.referenceType === 'ORGANIZATION') {
                            if (b.referenceType === 'COMPANY') return 1;
                            return -1;
                          }
                          return 1;
                        }
                        if (a.referenceType === 'EMPLOYEE') {
                          if (a.jobClassSeq < b.jobClassSeq) return -1;
                          if (a.jobClassSeq > b.jobClassSeq) return 1;
                        }
                        return 0;
                      })
                      .map(
                        ({
                          referenceCompanyId: companyId,
                          referenceId: id,
                        }) => {
                          const DirectoryData = getDirectoryData({
                            ...directory,
                            companyId,
                            employeeId: id,
                          });
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={DirectoryData.employeeName}
                              etc={getLocalizedText('관리자')}
                              avatar={DirectoryData.avatar}
                            />
                          );
                        },
                      )}
                  </ChipGroup>
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('공유자')}>
                  <ChipGroup>
                    {view.users
                      .map((a) => {
                        let jobClassSeq = 0;
                        if (a.referenceType === 'EMPLOYEE') {
                          const employeeData = getDirectoryData({
                            ...directory,
                            companyId: a.referenceCompanyId,
                            employeeId: a.referenceId,
                          });
                          // 직위 또는 직책 순서로 정렬 순서 결정.
                          jobClassSeq =
                            (jobClassType === 'jobduty'
                              ? jobDuties.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    v.id === employeeData.jobDutyId,
                                )?.seq
                              : jobPositions.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    employeeData.jobPositionId === v.id,
                                )?.seq) || CustomNumbers.SMALLINT_MAX;
                        }
                        return {
                          ...a,
                          jobClassSeq,
                        };
                      })
                      // 회사 → 부서 → 직원 순으로 정렬 후
                      // 직위 또는 직책 순번이 낮을수록 밑으로.
                      .sort((a, b) => {
                        if (a.referenceType !== b.referenceType) {
                          if (a.referenceType === 'COMPANY') return -1;
                          if (a.referenceType === 'ORGANIZATION') {
                            if (b.referenceType === 'COMPANY') return 1;
                            return -1;
                          }
                          return 1;
                        }
                        if (a.referenceType === 'EMPLOYEE') {
                          if (a.jobClassSeq < b.jobClassSeq) return -1;
                          if (a.jobClassSeq > b.jobClassSeq) return 1;
                        }
                        return 0;
                      })
                      .map(
                        ({
                          referenceCompanyId: companyId,
                          referenceId: id,
                          referenceType,
                          options: { isRead, isWrite },
                        }) => {
                          if (referenceType === 'COMPANY') {
                            // 회사일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getCompanyName(companyId)}
                                icon="company"
                                etc={handleMatchingPermissions({
                                  isRead,
                                  isWrite,
                                })}
                              />
                            );
                          }
                          if (referenceType === 'ORGANIZATION') {
                            // 부서일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getOrganizationName(companyId, id)}
                                etc={handleMatchingPermissions({
                                  isRead,
                                  isWrite,
                                })}
                                icon="sitemap-fill"
                              />
                            );
                          }
                          if (referenceType === 'EMPLOYEE') {
                            // 사원일 경우
                            const DirectoryData = getDirectoryData({
                              ...directory,
                              companyId,
                              employeeId: id,
                            });
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={DirectoryData.employeeName}
                                etc={handleMatchingPermissions({
                                  isRead,
                                  isWrite,
                                })}
                                avatar={DirectoryData.avatar}
                              />
                            );
                          }
                          return null;
                        },
                      )}
                  </ChipGroup>
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('예외자')}>
                  <ChipGroup>
                    {view.exceptioners
                      .map((a) => {
                        let jobClassSeq = 0;
                        if (a.referenceType === 'EMPLOYEE') {
                          const employeeData = getDirectoryData({
                            ...directory,
                            companyId: a.referenceCompanyId,
                            employeeId: a.referenceId,
                          });
                          // 직위 또는 직책 순서로 정렬 순서 결정.
                          jobClassSeq =
                            (jobClassType === 'jobduty'
                              ? jobDuties.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    v.id === employeeData.jobDutyId,
                                )?.seq
                              : jobPositions.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    employeeData.jobPositionId === v.id,
                                )?.seq) || CustomNumbers.SMALLINT_MAX;
                        }
                        return {
                          ...a,
                          jobClassSeq,
                        };
                      })
                      // 회사 → 부서 → 직원 순으로 정렬 후
                      // 직위 또는 직책 순번이 낮을수록 밑으로.
                      .sort((a, b) => {
                        if (a.referenceType !== b.referenceType) {
                          if (a.referenceType === 'COMPANY') return -1;
                          if (a.referenceType === 'ORGANIZATION') {
                            if (b.referenceType === 'COMPANY') return 1;
                            return -1;
                          }
                          return 1;
                        }
                        if (a.referenceType === 'EMPLOYEE') {
                          if (a.jobClassSeq < b.jobClassSeq) return -1;
                          if (a.jobClassSeq > b.jobClassSeq) return 1;
                        }
                        return 0;
                      })
                      .map(
                        ({
                          referenceCompanyId: companyId,
                          referenceId: id,
                          referenceType,
                        }) => {
                          if (referenceType === 'COMPANY') {
                            // 회사일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getCompanyName(companyId)}
                                icon="company"
                                etc={getLocalizedText('예외자')}
                              />
                            );
                          }
                          if (referenceType === 'ORGANIZATION') {
                            // 부서일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getOrganizationName(companyId, id)}
                                icon="sitemap-fill"
                                etc={getLocalizedText('예외자')}
                              />
                            );
                          }
                          if (referenceType === 'EMPLOYEE') {
                            // 사원일 경우
                            const DirectoryData = getDirectoryData({
                              ...directory,
                              companyId,
                              employeeId: id,
                            });
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={DirectoryData.employeeName}
                                etc={getLocalizedText('예외자')}
                                avatar={DirectoryData.avatar}
                              />
                            );
                          }
                          return null;
                        },
                      )}
                  </ChipGroup>
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default BoardFolderBoxView;
