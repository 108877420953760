import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../../../components/button/Button';
import {
  getAvatarPath,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Dialog from '../../../../../components/dialog/Dialog';
import Tab from '../../../../../components/tab/Tab';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import MenuItem from '../../../../../components/menu/MenuItem';
import UserInfo from '../../../../../components/user/UserInfo';
import Menu from '../../../../../components/menu/Menu';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../../../../components/tree/DirectoryTree';
import TextField from '../../../../../components/textfield/TextField';
import { approverMacroApi } from '../../../../apis/approval/v1/approvarmacro';
import {
  getCompanyName,
  getEmployeeName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import Tooltip from '../../../../../components/tooltip/Tooltip';
import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import Loading from '../../../../../components/loading/Loading';
import FeedBack from '../../../../../components/alert/FeedBack';
import Icon from '../../../../../components/icon/Icon';
import DropMenu from '../../../../../components/selectField/DropMenu';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { IconType } from '../../../../../groupware-common/types/icon';
import { WorkView } from '../../../../stores/approval/work';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import approvalWorkApi from '../../../../apis/approval/v1/work';
import {
  getApprovalLineGroupNameFromType,
  jsonToApprovalLine,
} from '../../../../stores/approval/document';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';

function getJobClassName(
  type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty',
  jobPosition: string,
  jobDuty: string,
) {
  switch (type) {
    case 'none':
      return '';
    case 'jobposition':
      return jobPosition;
    case 'jobduty':
      return jobDuty;
    default: {
      if (jobPosition !== '' && jobDuty !== '')
        return `${jobPosition}/${jobDuty}`;
      return jobPosition || jobDuty || '';
    }
  }
}

function createApprovalLineGroupItemDraftApprovalOptions(
  approval: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'draftApproval';
      text: string;
    }
> {
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'draftApproval' as const,
      text: approval
        ? getLocalizedText('1인결재 해제')
        : getLocalizedText('1인결재 지정'),
    },
  ];
}

function getApprovalLineGroupItemOptions(
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty',
): Array<
  | {
      code: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
      text: string;
      checked: boolean;
    }
  | {
      code: 'divider';
    }
  | {
      code: 'option';
      text: string;
      checked: boolean;
      disabled: boolean;
    }
> {
  return [
    {
      code: 'jobposition' as const,
      text: getLocalizedText('직위로 표시'),
      checked: jobClassType === 'jobposition',
    },
    {
      code: 'jobduty' as const,
      text: getLocalizedText('직책으로 표시'),
      checked: jobClassType === 'jobduty',
    },
    {
      code: 'jobposition+jobduty' as const,
      text: getLocalizedText('직위/직책으로 표시'),
      checked: jobClassType === 'jobposition+jobduty',
    },
  ];
}

function getApprovalLineGroupItemAgreeOptions(
  option: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'option';
      text: string;
    }
> {
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'option' as const,
      text: option
        ? getLocalizedText('선택 해제')
        : getLocalizedText('선택으로 지정'),
    },
  ];
}

function getApprovalLineGroupItemApprovalOptions(
  arbitraryDecision: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'arbitrarydecision';
      text: string;
    }
> {
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'arbitrarydecision' as const,
      text: arbitraryDecision
        ? getLocalizedText('전결권한 해제')
        : getLocalizedText('전결권한 지정'),
    },
  ];
}

function ApprovalLineGroupItem(
  props:
    | {
        groupId: string;
        id: string;
        organizationName: string;
        onDelete?(arg: {
          groupId: string;
          id: string;
          event: React.MouseEvent;
        }): void;
        options?: Array<
          | {
              code: 'divider';
            }
          | {
              // code: 'option';
              code: string;
              text: string;
              checked?: boolean;
              disabled?: boolean;
            }
        >;
        onOptionChange?(arg: {
          groupId: string;
          id: string;
          code: string;
          checked: boolean;
        }): void;
        dragable?: boolean;
      }
    | {
        groupId: string;
        id: string;
        employeeText: string;
        organizationName: string;
        avatar?: string;
        macroName?: string;
        onDelete?(arg: {
          groupId: string;
          id: string;
          event: React.MouseEvent;
        }): void;
        options?: Array<
          | {
              code: 'divider';
            }
          | {
              // code:
              //   | 'jobposition'
              //   | 'jobduty'
              //   | 'jobposition+jobduty'
              //   | 'option'
              //   | 'arbitrarydecision';
              code: string;
              text: string;
              checked?: boolean;
              disabled?: boolean;
            }
        >;
        onOptionChange?(arg: {
          groupId: string;
          id: string;
          code: string;
          checked: boolean;
        }): void;
        dragable?: boolean;
      },
) {
  const { dragable = true } = props;
  const [optionMenuPoint, setOptionMenuPoint] = useState<
    { x: number; y: number; width: number; height: number } | undefined
  >(undefined);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { groupId, id, onDelete } = props;
    if (onDelete) onDelete({ groupId, id, event });
  };

  const handleOptionMenuToggle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (optionMenuPoint === undefined) {
      const rect = event.currentTarget.getBoundingClientRect();
      setOptionMenuPoint({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      });
    } else handleOptionMenuClose();
  };

  const handleOptionMenuClose = () => {
    setOptionMenuPoint(undefined);
  };

  const handleOptionMenuItemClick = (code: string, checked: boolean) => {
    // console.log(`handleOptionMenuItemClick(${code}, ${checked})`);
    const { groupId, id, onOptionChange } = props;
    if (onOptionChange) {
      if (
        code === 'jobposition' ||
        code === 'jobduty' ||
        code === 'jobposition+jobduty'
      ) {
        if (checked !== true)
          onOptionChange({ groupId, id, code, checked: true });
      } else onOptionChange({ groupId, id, code, checked });
    }
    handleOptionMenuClose();
  };

  const {
    employeeText = undefined,
    organizationName = '',
    avatar = undefined,
    macroName = undefined,
    onDelete,
    options,
  } = { ...props };

  /** 사용자 정보 렌더링. */
  const renderUserInfo = () => {
    // 직원인 경우.
    if (employeeText !== undefined) {
      // 매크로 이름이 없는 경우.
      if (macroName === undefined)
        return (
          <UserInfo
            name={employeeText}
            from={organizationName}
            avatar={avatar}
          />
        );
      return (
        <UserInfo
          name={employeeText}
          from={organizationName}
          icon="user-tie"
          etc={`[${macroName}]`}
        />
      );
    }
    // 조직인 경우.
    return <UserInfo name={organizationName} icon="sitemap-fill" />;
  };

  return (
    <div className="approval-line-item">
      {dragable && <div className="drag" />}
      {renderUserInfo()}
      {options && (
        <Button
          className="action setting"
          text={getLocalizedText('설정')}
          iconType
          icon="cog-fill-small"
          onClick={handleOptionMenuToggle}
          color="secondary"
        />
      )}
      {onDelete && (
        <Button
          className="action delete"
          text={getLocalizedText('삭제')}
          iconType
          icon="times-circle-fill-small"
          onClick={handleDeleteClick}
          color="secondary"
        />
      )}
      {options && optionMenuPoint && (
        <Menu point={optionMenuPoint} onClose={handleOptionMenuClose} size="sm">
          {options.map((a, i) => {
            const {
              code,
              text = '',
              checked = undefined,
              disabled = undefined,
            } = {
              ...a,
            };
            const key = `${i}_${code}`;
            if (code === 'divider') return <MenuDivider key={key} />;
            return (
              <MenuItem
                key={key}
                label={text}
                onClick={() =>
                  handleOptionMenuItemClick(code, checked ?? false)
                }
                checked={checked}
                disabled={disabled}
              />
            );
          })}
        </Menu>
      )}
    </div>
  );
}

/** 결재선 그룹 항목 정렬 변경 후 사용하지 않는 값 undefined. */
function sortableDataUndefined(data: ApprovalLineGroupItemType) {
  return {
    ...data,
    chosen: undefined,
    selected: undefined,
  };
}

/** 공유 권한 그룹 항목 조직 유형. */
type SharePermissionGroupItemOrganizationType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: undefined;
  employeeName: undefined;
};

/** 공유 권한 그룹 항목 직원 유형. */
type SharePermissionGroupItemEmployeeType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  macroId?: number;
  macroName?: string;
};

/** 공유 권한 그룹 항목 유형. */
type SharePermissionGroupItemType =
  | SharePermissionGroupItemOrganizationType
  | SharePermissionGroupItemEmployeeType;

/** 결재선 그룹 항목 조직 유형 */
type ApprovalLineGroupItemOrganizationType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: undefined;
  employeeName: undefined;
  act?:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none'; // 행위. (결재, 반려, 보류, 대면 등...)
  actAt?: string; // 행위 날짜.
  approverCompanyId?: number;
  approverCompanyName?: string;
  approverOrganizationId?: number;
  approverOrganizationName?: string;
  approverEmployeeId?: number;
  approverEmployeeName?: string;
  approverJobClassType?: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  approverJobPositionId?: number;
  approverJobPositionName?: string;
  approverJobDutyId?: number;
  approverJobDutyName?: string;
};

/** 결재선 그룹 항목 직원 유형 */
type ApprovalLineGroupItemEmployeeType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  act?:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none'; // 행위. (결재, 반려, 보류, 대면 등...)
  actAt?: string; // 행위 날짜.
  approverCompanyId?: number;
  approverCompanyName?: string;
  approverOrganizationId?: number;
  approverOrganizationName?: string;
  approverEmployeeId?: number;
  approverEmployeeName?: string;
  approverJobClassType?: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  approverJobPositionId?: number;
  approverJobPositionName?: string;
  approverJobDutyId?: number;
  approverJobDutyName?: string;
  macroId?: number;
  macroName?: string;
};

/** 결재선 그룹 항목 기본 유형 (참조권 및 조회권 사용) */
type ApprovalLineGroupItemDefaultType =
  | ApprovalLineGroupItemOrganizationType
  | ApprovalLineGroupItemEmployeeType;

/** 결재선 그룹 항목 유형 (전체) */
type ApprovalLineGroupItemType =
  | ApprovalLineGroupItemDefaultType
  | (ApprovalLineGroupItemDefaultType & { option: boolean })
  | (ApprovalLineGroupItemEmployeeType & { arbitraryDecision: boolean });

export type ApprovalLineGroupItemOutputType =
  | (ApprovalLineGroupItemOrganizationType & { option?: boolean })
  | (ApprovalLineGroupItemEmployeeType & {
      option?: boolean;
      arbitraryDecision?: boolean;
    });

/** 결재선 그룹 항목 배열 기안 유형 */
type ApprovalLineGroupItemsDraftType = Array<
  ApprovalLineGroupItemEmployeeType & { approval: boolean }
>;

/** 결재선 그룹 항목 배열 결재 유형 */
export type ApprovalLineGroupItemsApprovalType = Array<
  | ApprovalLineGroupItemOrganizationType
  | (ApprovalLineGroupItemEmployeeType & {
      arbitraryDecision: boolean;
    })
>;

/** 결재선 그룹 항목 배열 합의 유형 */
export type ApprovalLineGroupItemsAgreeType = Array<
  (
    | ApprovalLineGroupItemOrganizationType
    | ApprovalLineGroupItemEmployeeType
  ) & { option: boolean }
>;

/** 결재선 그룹 항목 배열 감사 유형 */
type ApprovalLineGroupItemsAuditType = Array<
  ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
>;

/** 결재선 그룹 항목 배열 수신 유형 */
type ApprovalLineGroupItemsReceiveType = Array<
  ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
>;

/** 결재 유형 */
type ApprovalType = 'draft' | 'agree' | 'receive' | 'approval' | 'audit';

/** 결재선 그룹 유형 */
type ApprovalLineGroupType =
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'draft';
      modify?: undefined; // 수정 여부 없음.
      approval: boolean; // 기안자 결재 여부.
      items: Array<
        (
          | ApprovalLineGroupItemEmployeeType
          | ApprovalLineGroupItemOrganizationType
        ) & { approval: boolean }
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'approval';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<
        | ApprovalLineGroupItemOrganizationType
        | (ApprovalLineGroupItemEmployeeType & {
            arbitraryDecision: boolean;
          })
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'agree';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      parallel: boolean; // 병렬 여부
      items: Array<
        (
          | ApprovalLineGroupItemOrganizationType
          | ApprovalLineGroupItemEmployeeType
        ) & { option: boolean }
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'audit';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'receive';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      parallel: boolean; // 병렬 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    };

/** 결재 라인 유형 */
export type ApprovalLineType = {
  version: '0.1';
  groups: ApprovalLineGroupType[];
};

/** 공유 권한 그룹 유형 */
type SharePermissionGroupType = {
  id: string;
  required: boolean; // 필수 여부
  modify: boolean; // 수정 여부
  items: Array<SharePermissionGroupItemType>;
};

/** 공유 권한 유형 (조회권 및 참조권) */
export type SharePermissionType = {
  version: '0.1';
  groups: SharePermissionGroupType[];
};

/** 공유 권한 그룹 배열 */
function SharePermissionGroups(props: {
  title: string;
  selectedId: string;
  groups: SharePermissionGroupType[];
  dragable?: boolean;
  onSortChange?(groups: SharePermissionGroupType[]): void;
  onSelect?(arg: { id: string; modify: boolean }): void;
  onRequiredChange?(arg: { id: string }): void;
  onModifyChange?(arg: { id: string }): void;
  onDelete?(arg: { id: string; event: React.MouseEvent }): void;
  onItemAppend?(): void;
  onItemSortChange?(arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }): void;
  onItemOptionChange?(arg: {
    groupId: string;
    id: string;
    code: string;
    checked: boolean;
  }): void;
  onItemDelete?(arg: { groupId: string; id: string }): void;
}) {
  const handleSortChange = (groups: SharePermissionGroupType[]) => {
    const { onSortChange } = props;
    if (onSortChange) onSortChange(groups);
  };

  const handleItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    const { onItemSortChange } = props;
    if (onItemSortChange) onItemSortChange(arg);
  };

  const {
    title,
    selectedId,
    groups,
    dragable = false,
    onRequiredChange,
    onModifyChange,
    onDelete,
    onItemAppend,
    onSelect,
    onItemOptionChange,
    onItemDelete,
  } = props;

  return (
    <ReactSortable
      className="line-root"
      list={groups}
      setList={handleSortChange}
      animation={200}
      handle=".group-drag"
    >
      {groups.map((a) => {
        const { id, required, modify } = a;
        let className = 'line-group';
        if (!modify) className += ' lock';
        if (required) className += ' required';
        return (
          <div
            key={a.id}
            className={className}
            onClick={onSelect ? () => onSelect({ id, modify }) : undefined}
            aria-selected={a.id === selectedId}
          >
            <div className="group-head">
              <div className="title">{title}</div>
              {!modify && (
                <Tooltip
                  title={getLocalizedText('고정 그룹은 편집할 수 없습니다.')}
                >
                  <em className="tip">{getLocalizedText('고정')}</em>
                </Tooltip>
              )}
              {required && modify && (
                <Tooltip
                  title={getLocalizedText('필수 그룹은 최소 1인 이상 필요.')}
                >
                  <em className="tip">{getLocalizedText('필수')}</em>
                </Tooltip>
              )}
              {onRequiredChange && (
                <Checkbox
                  label={getLocalizedText('필수')}
                  checked={a.required}
                  onChange={() => onRequiredChange({ id: a.id })}
                  className="group-option"
                />
              )}
              {onModifyChange && (
                <Checkbox
                  label={getLocalizedText('수정')}
                  checked={a.modify}
                  onChange={() =>
                    onModifyChange({
                      id: a.id,
                    })
                  }
                  className="group-option"
                />
              )}
              {modify && (onDelete || dragable) && a.items.length === 0 && (
                <div className="action">
                  {onDelete && (
                    <Button
                      text={getLocalizedText('삭제')}
                      iconType
                      icon="trash-full"
                      onClick={(event) => onDelete({ id: a.id, event })}
                      color="secondary"
                      className="group-delete"
                    />
                  )}
                  {dragable && <div className="drag group-drag" />}
                </div>
              )}
            </div>
            <div className="group-body">
              <ReactSortable
                className="approval-line-group"
                list={a.items}
                setList={(newState, sortable, store) =>
                  handleItemSortChange({
                    groupId: a.id,
                    items: newState,
                    sortable,
                    store,
                  })
                }
                animation={200}
                handle=".drag"
              >
                {a.items.map((b) => {
                  // 직원인 경우
                  if (b.employeeId !== undefined) {
                    const text = getEmployeeName({
                      name: b.employeeName,
                      type: b.jobClassType,
                      jobPosition: b.jobPositionName,
                      jobDuty: b.jobDutyName,
                    });
                    const options = onItemOptionChange
                      ? getApprovalLineGroupItemOptions(b.jobClassType)
                      : undefined;
                    return (
                      <ApprovalLineGroupItem
                        key={b.id}
                        groupId={a.id}
                        id={b.id}
                        employeeText={text}
                        organizationName={b.organizationName}
                        avatar={getAvatarPath(b)}
                        macroName={b.macroName}
                        onDelete={modify ? onItemDelete : undefined}
                        options={options}
                        onOptionChange={modify ? onItemOptionChange : undefined}
                        dragable={modify}
                      />
                    );
                  }
                  // 조직인 경우
                  return (
                    <ApprovalLineGroupItem
                      key={b.id}
                      groupId={a.id}
                      id={b.id}
                      organizationName={b.organizationName}
                      onDelete={modify ? onItemDelete : undefined}
                      dragable={modify}
                    />
                  );
                })}
              </ReactSortable>
              {modify && onItemAppend && (
                <Button
                  className="add-approval-line-item"
                  text={getLocalizedText('편집')}
                  onClick={onItemAppend}
                  block
                />
              )}
            </div>
          </div>
        );
      })}
    </ReactSortable>
  );
}

/** 조회권 및 참조권 플랫 */
function SharePermissionFlat({
  items,
  onDeleteAll,
}: {
  items: { id: string; organizationName: string; employeeName?: string }[];
  onDeleteAll?(): void;
}): JSX.Element {
  return (
    <>
      <span className="eui-approval-line">
        {items.map(({ id, organizationName, employeeName }, i) => (
          <span key={id} className="item">
            {i === 0 ? '' : ', '}
            {employeeName || organizationName}
          </span>
        ))}
      </span>
      {onDeleteAll && (
        <Button
          className="delete"
          text={getLocalizedText('모두삭제')}
          iconType
          icon="trash-full"
          onClick={onDeleteAll}
          color="secondary"
          size="xs"
        />
      )}
    </>
  );
}

function approvalLineGroupClassName(type: string) {
  switch (type) {
    case 'draft':
      return 'drafter';
    case 'agree':
      return 'agree';
    case 'receive':
      return 'receiver';
    case 'approval':
      return 'approval';
    case 'audit':
      return 'auditor';
    default:
      return '';
  }
}

/**
 * 결재선 기안자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 직원 유형 또는 undefined.
 */
function getApprovalLineDrafter(
  approvalLine: ApprovalLineType,
):
  | ApprovalLineGroupItemEmployeeType
  | ApprovalLineGroupItemOrganizationType
  | undefined {
  const group = approvalLine.groups.find((a) => a.type === 'draft');
  if (group?.type === 'draft' && group.items.length > 0) {
    return group.items[0];
  }
  return undefined;
}

/** 현재 행위 여부. */
function isCurrentAct(act?: string, actAt?: string) {
  // 행위가 보류 이거나 대면 이거나 수신 이거나 결재 날짜가 없는 경우.
  return (
    act === 'hold' || act === 'meet' || act === 'receipt' || actAt === undefined
  );
}

/**
 * 결재선 현재 결재 그룹.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 유형 또는 undefined.
 */
function getApprovalGroup(
  approvalLine: ApprovalLineType,
): ApprovalLineGroupType | undefined {
  const result = approvalLine.groups.find((group) => {
    const item = group.items.find(({ act, actAt }) => {
      // console.log(`getApprovalGroup:${id}`, { act, actAt });
      return isCurrentAct(act, actAt);
    });
    // console.log(`getApprovalGroup:item`, item);
    return item !== undefined;
  });
  // console.log(`getApprovalGroup:result`, result);
  return result;
}

/**
 * 결재선 이전 결재자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 출력 유형 또는 undefined.
 */
function getPreviousApprover(
  approvalLine: ApprovalLineType,
):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined {
  let previousGroupItemId = '';
  const data = {
    ...approvalLine,
    groups: approvalLine.groups.map((group) => {
      switch (group.type) {
        case 'agree': {
          return {
            ...group,
            items: group.parallel
              ? [
                  ...group.items.filter((item) => item.act !== undefined),
                  ...group.items.filter((item) => item.act === undefined),
                ]
              : group.items,
          };
        }
        case 'receive': {
          return {
            ...group,
            items: group.parallel
              ? [
                  ...group.items.filter((item) => item.act !== undefined),
                  ...group.items.filter((item) => item.act === undefined),
                ]
              : group.items,
          };
        }
        default:
          return {
            ...group,
          };
      }
    }),
  };
  for (let i = 0; i < data.groups.length; i += 1) {
    const loopGroup = data.groups[i];
    let find = false;
    for (let j = 0; j < loopGroup.items.length; j += 1) {
      const loopItem = loopGroup.items[j];
      if (loopItem.act === undefined) {
        find = true;
        break;
      }
      previousGroupItemId = loopItem.id;
    }
    if (find) break;
  }
  // console.log(`getPreviousApprover:approvalLine`, approvalLine);
  // console.log(`previousGroupItemId:${previousGroupItemId}`);

  const group = data.groups.find(
    (a) => a.id === previousGroupItemId.split('/')[0],
  );
  const item = group?.items.find((a) => a.id === previousGroupItemId);

  // console.log(`previousGroup:`, group);
  // console.log(`previousGroupItem:`, item);

  if (group === undefined || item === undefined) return undefined;

  if (group.type === 'draft') return undefined;

  if (group.type === 'receive') return undefined;

  if (group.type === 'agree' && group.parallel) {
    const result = group.items.filter(
      (a) => a.act !== undefined && a.act !== 'hold' && a.act !== 'meet',
    );
    if (result.length === 0) return undefined;
    return result;
  }
  return item;
}

/**
 * 결재선 이전 결재자 그룹 아이템 아이디.
 * @param approvalLine 결재선 유형.
 * @param companyId 결재자 회사 아이디.
 * @param employeeId 결재자 직원 아이디.
 * @returns 결재선 이전 결재자 그룹 아이템 아이디 또는 undefined.
 */
function getPreviousApproverGroupItemId(
  approvalLine: ApprovalLineType,
  companyId: number,
  employeeId: number,
): string | undefined {
  const previousApprover = getPreviousApprover(approvalLine);
  if (Array.isArray(previousApprover))
    return previousApprover.find(
      (a) => a.companyId === companyId && a.employeeId === employeeId,
    )?.id;
  if (
    previousApprover?.companyId === companyId &&
    previousApprover?.employeeId === employeeId
  )
    return previousApprover.id;
  return undefined;
}

/**
 * 결재선 이전 결재자 여부.
 * @param approvalLine 결재선 유형.
 * @param companyId 결재자 회사 아이디.
 * @param employeeId 결재자 직원 아이디.
 * @returns 이전 결재자인 경우 true 이전 결재자가 아닌 경우 false.
 */
function isPreviousApprover(
  approvalLine: ApprovalLineType,
  companyId: number,
  employeeId: number,
): boolean {
  const previousApprover = getPreviousApprover(approvalLine);

  if (Array.isArray(previousApprover)) return false;
  return (
    previousApprover?.companyId === companyId &&
    previousApprover?.employeeId === employeeId
  );
}

/**
 * 결재선 현재 결재자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 출력 유형 또는 undefined.
 */
function getCurrentApprover(arg: {
  approvalLine: ApprovalLineType;
  companyId?: undefined;
}):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined;
/**
 * 매개 변수에 해당되는 결재선 현재 결재자.
 * @param approvalLine 결재선 유형.
 * @param companyId 결재자 회사 아이디.
 * @param organizationIds 결재자 조직 아이디 배열.
 * @param employeeId 결재자 직원 아이디.
 * @param designatorIds 대리 결재 지정자 아이디 배열.
 * @param approvalFolder 결재 폴더.
 */
function getCurrentApprover(arg: {
  approvalLine: ApprovalLineType;
  companyId: number;
  organizationIds: number[];
  employeeId: number;
  designatorIds?: number[]; // 대리 결재 지정자.
  approvalFolder?: boolean; // 결재 폴더.
}): ApprovalLineGroupItemOutputType | undefined;
function getCurrentApprover(arg: {
  approvalLine: ApprovalLineType;
  companyId?: number;
  organizationIds?: number[];
  employeeId?: number;
  designatorIds?: number[]; // 대리 결재 지정자.
  approvalFolder?: boolean; // 결재 폴더.
}):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined {
  const {
    approvalLine,
    companyId,
    organizationIds = [],
    employeeId,
    designatorIds = [],
    approvalFolder,
  } = arg;

  const group = getApprovalGroup(approvalLine);
  if (group === undefined) return undefined;

  let items: ApprovalLineGroupItemType[];

  // 그룹 유형이 합의 또는 수신이고 병렬인 경우.
  if ((group.type === 'agree' || group.type === 'receive') && group.parallel) {
    items = group.items.filter(({ act, actAt }) => {
      // console.log(`getCurrentApprover:${id}`, { act, actAt });
      // 행위가 보류 이거나 대면 이거나 수신 이거나 결재 날짜가 없는 경우.
      return isCurrentAct(act, actAt);
    });
    // 결재 폴더에서 접수 중인 문서는 현재 결재자에서 제외.
    if (approvalFolder) items = items.filter((a) => a.act !== 'receipt');
    // 조직이 직원보다 앞에 있을 경우 직원 먼저 결재 순서가 되도록 아이템 순서 변경.
    const employeeItems = items.filter((a) => a.employeeId !== undefined);
    const organizationItems = items.filter((a) => a.employeeId === undefined);
    items = [...employeeItems, ...organizationItems];
  } else {
    const item = group.items.find(({ act, actAt }) => {
      // 행위가 보류 이거나 대면 이거나 수신 이거나 결재 날짜가 없는 경우.
      return isCurrentAct(act, actAt);
    });
    if (item === undefined) return undefined;
    items = [item];
  }

  // 현재 결재자 비교 매개 변수가 포함되지 않은 경우 현재 결재자 반환.
  if (companyId === undefined) {
    if (items.length === 1) return items[0];
    return items;
  }

  // 현재 결재자 매개 변수 값과 비교.
  for (let i = 0; i < items.length; i += 1) {
    const it = items[i];
    // 현재 결재자가 매개 변수 결재자와 같은 경우.
    if (it.companyId === companyId && it.employeeId === employeeId) return it;
    // 현재 결재가 조직이고 매개 변수 결재 조직에 포함 된 경우.
    if (
      it.employeeId === undefined &&
      it.companyId === companyId &&
      organizationIds.indexOf(it.organizationId) !== -1
    )
      return it;
  }

  // 현재 결재자 매개 변수 값과 비교.
  for (let i = 0; i < items.length; i += 1) {
    const it = items[i];
    // 현재 결재자가 매개 변수 결재 지정자에 포함 된 경우.
    if (
      it.employeeId !== undefined &&
      it.companyId === companyId &&
      designatorIds.indexOf(it.employeeId) !== -1
    )
      return it;
  }
  return undefined;
}

/**
 * 결재선 마지막 결재자 결재자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 출력 유형, 배열 또는 undefined.
 */
function getLastApprover(
  approvalLine: ApprovalLineType,
):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined {
  const group = [...approvalLine.groups]
    .reverse()
    .find((a) => a.items.length > 0);
  if (group === undefined) return undefined;

  // 그룹 유형이 합의 또는 수신이고 병렬인 경우.
  if ((group.type === 'agree' || group.type === 'receive') && group.parallel) {
    return group.items;
  }
  return group.items[group.items.length - 1];
}

/**
 * 결재자 배열을 생성합니다.
 * @param element 결재자 배열을 생성할 때 사용할 요소.
 * @returns 결재자 배열 객체.
 */
function approversOf(
  element:
    | ApprovalLineGroupItemOutputType
    | ApprovalLineGroupItemOutputType[]
    | undefined,
): ApprovalLineGroupItemOutputType[] {
  if (element === undefined) return [];
  if (Array.isArray(element)) return element;
  return [element];
}

/**
 * 전결 결재 진행 중 여부.
 * (전결로 결재되었는데 수신 조직이 있는 경우 수신자 전까지 결재 상태 변경되고 수신자는 대기 상태로 설정됨)
 * @param approvalLine 결재선.
 * @returns 전결 결재 진행 중인 경우 true, 아닌 경우 false.
 */
function isArbitraryDecisionApproving(approvalLine: ApprovalLineType): boolean {
  return approvalLine.groups.some(
    (group) =>
      group.type === 'approval' &&
      group.items.some((item) => item.act === 'arbitraryDecision'),
  );
}

/**
 * 전결 결재 진행 중이 아닌 여부.
 * (전결로 결재되었는데 수신 조직이 있는 경우 수신자 전까지 결재 상태 변경되고 수신자는 대기 상태로 설정됨)
 * @param approvalLine
 * @returns 전결 결재 진행 중이 아닌 경우 true, 아닌 경우 false.
 */
function nonArbitraryDecisionApproving(
  approvalLine: ApprovalLineType,
): boolean {
  return isArbitraryDecisionApproving(approvalLine) === false;
}

/**
 * 현재 결재자들 중 접수 중인 결재자 존재 여부.
 * @param approvalLine 결재선.
 * @returns 현재 결재자들 중 접수 중인 결재자가 있는 경우 true, 아닌 경우 false.
 */
function isCurrentApproversReceipting(approvalLine: ApprovalLineType): boolean {
  const approvers = approversOf(getCurrentApprover({ approvalLine }));
  return approvers.some((a) => a.act === 'receipt');
}

/** 개인 결재선 추가. */
type ApprovalLineDesignationProps = {
  type?: undefined;
  approvalLine?: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  onCancel(): void;
  onSave(arg: {
    id?: number;
    name: string;
    workId: number;
    workUpdateAt?: string;
    updateAt?: string;
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }): void;
};

/** 개인 결재선 변경. */
type ApprovalLineChangeProps = {
  type: 'change';
  id: number;
  name: string;
  workId: number;
  workName: string;
  workUpdateAt: string;
  workChange: boolean;
  approvalLine: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  updateAt: string;
  onCancel(): void;
  onSave(arg: {
    id?: number;
    name: string;
    workId?: number;
    workUpdateAt?: string;
    updateAt?: string;
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }): void;
};

// src/pages/popup/approval/ApprovalLine.tsx 내용 수정.
/**
 * 결재선 대화 상자 컨테이너
 * @param props ApprovalLineDesignationProps 결재선. 지정 (문서 작성 및 임시 보관 문서 수정)
 * @returns JSX.Element
 */
function ApprovalLinePreferencesDialogContainer(
  props: ApprovalLineDesignationProps | ApprovalLineChangeProps,
): JSX.Element {
  const mobileChooseListRef = React.useRef<HTMLDivElement>(null);

  const display = useSelector((state: RootState) => state.session.display);

  const principal = useSelector((state: RootState) => state.session.principal);

  const directory = useDirectory();

  /** 업무 폴더 배열. */
  const folders = useSelector(
    (state: RootState) => state.approval2.work.folder.list.data.items,
  );

  /** 업무 배열. */
  const works = useSelector(
    (state: RootState) => state.approval2.work.list.data.items,
  );

  const arbitraryDecisions = useSelector(
    (state: RootState) => state.approval2.arbitraryDecisions.arbitraryDecisions,
  );

  const view = useSelector(
    (state: RootState) => state.approval2.document.view.data,
  );

  const approvalTypes = useSelector(
    (state: RootState) => state.approval2.preferences.approvalType,
  );

  const directoryTreeItems = useMemo(
    () => getDirectoryTreeItems({ ...directory }),
    [directory],
  );
  const queryParams = getQueryParams(
    useSelector((s: RootState) => s.session.route.search),
  );

  /** 합의그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineAgreeFiltered = (
    items: ApprovalLineGroupItemsAgreeType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 접수 후 내부 결재(재접수) 여부. */
  const isInternalApprovalAfterReceipt =
    queryParams.contentType === 'receipt' ||
    (queryParams.contentType === 'reDraft' && view?.parentId !== undefined);

  /** 결재 진행 중인 그룹 인덱스. (결재선 변경인 경우) */
  const proceedIndex = 0;

  const getOrganizationData = ({
    companyId,
    organizationId,
  }: {
    companyId: number;
    organizationId: number;
  }) => {
    return {
      companyId,
      companyName: getCompanyName(companyId),
      organizationId,
      organizationName: getOrganizationName(companyId, organizationId, ''),
    };
  };

  const dispatch = useAppDispatch();

  /** 결재선 생성 시 조직 정보 생성. */
  const createApprovalLineOrganizationData = (
    companyId: number,
    organizationId: number,
  ) => {
    const organizationData = getOrganizationData({
      companyId,
      organizationId,
    });
    return {
      companyId: organizationData.companyId,
      organizationId: organizationData.organizationId,
      companyName: organizationData.companyName,
      organizationName: organizationData.organizationName,
    };
  };

  /** 결재선 생성 시 직원 정보 생성. */
  const createApprovalLineEmployeeData = (data: {
    companyId: number;
    organizationId?: number;
    employeeId: number;
  }) => {
    const { companyId, organizationId, employeeId } = data;
    const employeeData = getDirectoryData({
      ...directory,
      companyId,
      organizationId,
      employeeId,
    });
    return {
      companyId: employeeData.companyId,
      organizationId: employeeData.organizationId,
      employeeId: employeeData.employeeId,
      companyName: employeeData.companyName,
      organizationName: employeeData.organizationName,
      employeeName: employeeData.employeeName,
      jobPositionId: employeeData.jobPositionId,
      jobPositionName: employeeData.jobPositionName,
      jobClassType: employeeData.jobClassType,
      jobDutyId: employeeData.jobDutyId,
      jobDutyName: employeeData.jobDutyName,
      avatar: employeeData.avatar,
    };
  };

  /** 결재그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineApprovalFiltered = (
    items: ApprovalLineGroupItemsApprovalType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재,합의 제외 그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineDefaultFiltered = (
    items: Array<
      ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
    >,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재선 생성 시 결재자 정보, 결재 행위, 날짜 undefined 처리. */
  const createApprovalLineUndefined = () => {
    return {
      act: undefined,
      actAt: undefined,
      approverCompanyId: undefined,
      approverCompanyName: undefined,
      approverOrganizationId: undefined,
      approverOrganizationName: undefined,
      approverEmployeeId: undefined,
      approverEmployeeName: undefined,
      approverJobClassType: undefined,
      approverJobPositionId: undefined,
      approverJobPositionName: undefined,
      approverJobDutyId: undefined,
      approverJobDutyName: undefined,
    };
  };

  const [state, setState] = useState<{
    selectedId: number;
    dialogType: string;
    workName: string;
    workUpdateAt: string;
    approvalLineName: string;
    work?: WorkView;
    validation: string;
    /** 선택 사용자 목록 표시 여부 (모바일 화면에서 사용) */
    chooseUserDialogVisible: boolean;
    /** 개인 결재선 이름 */
    personalApprovalLineName: string;
    chooseTabSelectedId: 'organizationchart' | 'approvalofficer';
    directoryTreeFilter: string;
    directoryTreeSelectedId: string;
    //
    approvalGroupSelectedId: string;
    approvalLineGroupSelectedId: string;
    referencePermissionGroupSelectedId: string;
    viewPermissionGroupSelectedId: string;
    //
    approvalLine: ApprovalLineType;
    referencePermission: {
      version: '0.1';
      groups: {
        id: string;
        required: boolean; // 필수 여부
        modify: boolean; // 수정 여부
        items: Array<ApprovalLineGroupItemDefaultType>;
      }[];
    };
    viewPermission: SharePermissionType;
    changeReason: string;
  }>(() => {
    let approvalGroupSelectedId = '';
    let approvalLineGroupSelectedId = '';
    let referencePermissionGroupSelectedId = '';
    let viewPermissionGroupSelectedId = '';
    let directoryTreeSelectedId = '';

    // 개인결재선 생성일 때 초기 셋팅
    if (approvalGroupSelectedId === '' && props.approvalLine) {
      // 결재선 그룹 아이디 찾기.
      approvalLineGroupSelectedId =
        props.approvalLine.groups.find(
          (group) => group.type !== 'draft' && group.modify,
        )?.id ?? '';
      approvalGroupSelectedId = approvalLineGroupSelectedId;
    }

    // 결재 그룹 선택 아이디가 없는 경우 참조권 그룹 아이디 찾기.
    if (approvalGroupSelectedId === '' && props.referencePermission) {
      referencePermissionGroupSelectedId =
        props.referencePermission.groups.find((a) => a.modify)?.id ?? '';
      approvalGroupSelectedId = referencePermissionGroupSelectedId;
    }
    // 결재 그룹 선택 아이디가 없는 경우 조회권 그룹 아이디 찾기.
    if (approvalGroupSelectedId === '' && props.viewPermission) {
      viewPermissionGroupSelectedId =
        props.viewPermission.groups.find((a) => a.modify)?.id ?? '';
      approvalGroupSelectedId = viewPermissionGroupSelectedId;
    }

    // TODO: 기안자 조직 아이디 찾기.
    if (props.approvalLine !== undefined) {
      const drafter = getApprovalLineDrafter(props.approvalLine);
      if (drafter)
        directoryTreeSelectedId = `${drafter.companyId}_${drafter.organizationId}`;
    }

    return {
      selectedId: props.type === 'change' ? props.workId : 0,
      dialogType: '',
      workUpdateAt: props.type === 'change' ? props.workUpdateAt : '',
      workName: props.type === 'change' ? props.workName : '',
      approvalLineName: props.type === 'change' ? props.name : '',
      validation: '',
      chooseUserDialogVisible: false,
      personalApprovalLineName: '',
      chooseTabSelectedId: 'organizationchart',
      directoryTreeFilter: '',
      directoryTreeSelectedId:
        directoryTreeSelectedId !== ''
          ? directoryTreeSelectedId
          : directoryTreeItems[0]?.id || '',
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
      approvalLine: props.approvalLine
        ? {
            ...props.approvalLine,
            groups: props.approvalLine.groups.map((group) => {
              switch (group.type) {
                case 'draft':
                  return {
                    ...group,
                    items: group.items.map((item) => ({
                      id: item.id,
                      approval: item.approval,
                      ...createApprovalLineEmployeeData({
                        companyId: principal.companyId,
                        organizationId: principal.organizationId,
                        employeeId: principal.employeeId ?? 0,
                      }),
                    })),
                  };
                case 'approval':
                  return {
                    ...group,
                    items: createApprovalLineApprovalFiltered(group.items).map(
                      (item) => {
                        const { companyId, organizationId } = item;
                        if (item.employeeId === undefined) {
                          return {
                            ...item,
                            ...createApprovalLineOrganizationData(
                              companyId,
                              organizationId,
                            ),
                            ...createApprovalLineUndefined(),
                          };
                        }
                        return {
                          ...item,
                          ...createApprovalLineEmployeeData({
                            companyId,
                            organizationId,
                            employeeId: item.employeeId,
                          }),
                          ...createApprovalLineUndefined(),
                        };
                      },
                    ),
                  };
                case 'agree':
                  return {
                    ...group,
                    items: createApprovalLineAgreeFiltered(group.items).map(
                      (item) => {
                        const { companyId, organizationId } = item;
                        if (item.employeeId === undefined) {
                          return {
                            ...item,
                            ...createApprovalLineOrganizationData(
                              companyId,
                              organizationId,
                            ),
                            ...createApprovalLineUndefined(),
                          };
                        }
                        return {
                          ...item,
                          ...createApprovalLineEmployeeData({
                            companyId,
                            organizationId,
                            employeeId: item.employeeId,
                          }),
                          ...createApprovalLineUndefined(),
                        };
                      },
                    ),
                  };
                default:
                  return {
                    ...group,
                    items: createApprovalLineDefaultFiltered(group.items).map(
                      (item) => {
                        const { companyId, organizationId } = item;
                        if (item.employeeId === undefined) {
                          return {
                            ...item,
                            ...createApprovalLineOrganizationData(
                              companyId,
                              organizationId,
                            ),
                            ...createApprovalLineUndefined(),
                          };
                        }
                        return {
                          ...item,
                          ...createApprovalLineEmployeeData({
                            companyId,
                            organizationId,
                            employeeId: item.employeeId,
                          }),
                          ...createApprovalLineUndefined(),
                        };
                      },
                    ),
                  };
              }
            }),
          }
        : { version: '0.1', groups: [] },
      referencePermission: props.referencePermission ?? {
        version: '0.1',
        groups: [],
      },
      viewPermission: props.viewPermission ?? { version: '0.1', groups: [] },
      changeReason: '',
    };
  });

  // 업무 불러오기.
  useEffect(() => {
    async function run() {
      try {
        const { selectedId } = state;
        if (
          selectedId === 0 ||
          (props.type === 'change' && props.workId === selectedId)
        )
          return;

        const draftOrganizationId = principal.organizationId;

        const workView = await approvalWorkApi.fetchView({ id: selectedId });
        const approvalLine = jsonToApprovalLine(workView.approvalLine);

        setState((prevState) => ({
          ...prevState,
          dialogType: '',
          workName: workView.name,
          workUpdateAt: workView.updateAt,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              switch (group.type) {
                case 'draft':
                  return {
                    ...group,
                    items: [
                      {
                        id: `${Date.now()}`,
                        approval: false,
                        ...createApprovalLineEmployeeData({
                          companyId: principal.companyId,
                          organizationId: draftOrganizationId,
                          employeeId: principal.employeeId,
                        }),
                      },
                    ],
                  };
                case 'approval':
                  return {
                    ...group,
                    items: createApprovalLineApprovalFiltered(group.items).map(
                      (item) => {
                        const { companyId, organizationId } = item;
                        if (item.employeeId === undefined) {
                          return {
                            ...item,
                            ...createApprovalLineOrganizationData(
                              companyId,
                              organizationId,
                            ),
                            ...createApprovalLineUndefined(),
                          };
                        }
                        return {
                          ...item,
                          ...createApprovalLineEmployeeData({
                            companyId,
                            organizationId,
                            employeeId: item.employeeId,
                          }),
                          ...createApprovalLineUndefined(),
                        };
                      },
                    ),
                  };
                case 'agree':
                  return {
                    ...group,
                    items: createApprovalLineAgreeFiltered(group.items).map(
                      (item) => {
                        const { companyId, organizationId } = item;
                        if (item.employeeId === undefined) {
                          return {
                            ...item,
                            ...createApprovalLineOrganizationData(
                              companyId,
                              organizationId,
                            ),
                            ...createApprovalLineUndefined(),
                          };
                        }
                        return {
                          ...item,
                          ...createApprovalLineEmployeeData({
                            companyId,
                            organizationId,
                            employeeId: item.employeeId,
                          }),
                        };
                      },
                    ),
                  };
                default:
                  return {
                    ...group,
                    items: createApprovalLineDefaultFiltered(group.items).map(
                      (item) => {
                        const { companyId, organizationId } = item;
                        if (item.employeeId === undefined) {
                          return {
                            ...item,
                            ...createApprovalLineOrganizationData(
                              companyId,
                              organizationId,
                            ),
                            ...createApprovalLineUndefined(),
                          };
                        }
                        return {
                          ...item,
                          ...createApprovalLineEmployeeData({
                            companyId,
                            organizationId,
                            employeeId: item.employeeId,
                          }),
                          ...createApprovalLineUndefined(),
                        };
                      },
                    ),
                  };
              }
            }),
          },
          referencePermission:
            workView.referrer !== '{}'
              ? JSON.parse(workView.referrer)
              : { version: '0.1', groups: [] },
          viewPermission:
            workView.viewer !== '{}'
              ? JSON.parse(workView.viewer)
              : { version: '0.1', groups: [] }, // json 조회자',
        }));
      } catch (ex) {
        dispatch(sessionActions.error((ex as Error).message));
      }
    }
    run();
  }, [state.selectedId]);

  const chooseTabs = useMemo(() => {
    const result: {
      id: 'organizationchart' | 'approvalofficer';
      text: string;
    }[] = [
      {
        id: 'organizationchart',
        text: getLocalizedText('조직도'),
      },
      {
        id: 'approvalofficer',
        text: getLocalizedText('결재담당자'),
      },
    ];
    return result;
  }, []);

  const [approvalOfficers, setApprovalOfficers] = useState<
    | {
        companyId: number;
        id: number;
        name: string;
        organizationId: number;
        employeeId: number;
        updateAt: string;
      }[]
    | undefined
  >(undefined);

  useEffect(() => {
    approverMacroApi.list(1, 100).then((response) => {
      if (response) setApprovalOfficers(response);
    });
  }, []);

  useEffect(() => {
    if (display === 'phone' && chooseUserDialogVisible) {
      mobileChooseListRef.current?.scrollTo(
        mobileChooseListRef.current?.scrollWidth,
        0,
      );
    }
  }, [state.approvalLine]);

  /** 선택 탭 항목 클릭 */
  const handleChooseTabItemClick = ({
    id: chooseTabSelectedId,
  }: {
    id: 'organizationchart' | 'approvalofficer';
  }) => {
    setState((prevState) => ({ ...prevState, chooseTabSelectedId }));
  };

  /** 디렉터리 트리 필터 */
  const handleDirectoryTreeFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const directoryTreeFilter = event.target.value;
    setState((prevState) => ({ ...prevState, directoryTreeFilter }));
  };

  /** 디렉터리 트리 아이템 클릭 */
  const handleDirectoryTreeItemClick = (arg: DirectoryTreeItemArg) => {
    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    const approvalLineGroupSelected =
      approvalGroupSelectedId === approvalLineGroupSelectedId;

    const { id: directoryTreeSelectedId, extra } = arg.item;
    const item =
      extra.type === 'employee'
        ? {
            id: approvalLineGroupSelected
              ? `${Date.now()}`
              : `${extra.companyId}_${extra.employeeId}`,
            companyId: extra.companyId,
            companyName: extra.companyName,
            organizationId: extra.organizationId,
            employeeId: extra.employeeId,
            employeeName: extra.employeeName,
            organizationName: extra.organizationName,
            jobClassType: extra.jobClassType,
            jobPositionId: extra.jobPositionId,
            jobPositionName: extra.jobPositionName,
            jobDutyId: extra.jobDutyId,
            jobDutyName: extra.jobDutyName,
            avatar: extra.avatar,
          }
        : {
            id: approvalLineGroupSelected
              ? `${Date.now()}`
              : `${extra.companyId}_${extra.organizationId}`,
            companyId: extra.companyId,
            companyName: extra.companyName,
            organizationId: extra.organizationId,
            organizationName: extra.organizationName,
          };

    // 결재선 그룹이 선택된 경우
    if (approvalLineGroupSelected) {
      const approvalLineGroupType = state.approvalLine.groups.find(
        (a) => a.id === approvalLineGroupSelectedId,
      )?.type;

      const arbitraryDecision =
        approvalLineGroupType === 'approval' && item.employeeId !== undefined
          ? arbitraryDecisions.find(
              (a) =>
                a.companyId === item.companyId &&
                a.organizationId === item.organizationId &&
                a.employeeId === item.employeeId,
            ) !== undefined
          : false;

      // console.log(`arbitraryDecisions:`, arbitraryDecisions);
      // console.log(`arbitraryDecision:`, arbitraryDecision);

      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          directoryTreeSelectedId,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === approvalLineGroupSelectedId) {
                switch (group.type) {
                  case 'draft':
                    return group;
                  case 'agree': {
                    return {
                      ...group,
                      items: [...group.items, { ...item, option: false }],
                    };
                  }
                  case 'approval': {
                    if (item.employeeId === undefined) {
                      return { ...group, items: [...group.items, item] };
                    }
                    return {
                      ...group,
                      items: [...group.items, { ...item, arbitraryDecision }],
                    };
                  }
                  default:
                    return { ...group, items: [...group.items, item] };
                }
              }
              return group;
            }),
          },
        };
      });
    }
    // 참조권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      setState((prevState) => {
        const { referencePermission } = prevState;
        if (
          referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            item.employeeId !== undefined
              ? getLocalizedText('참조권에 존재하는 직원입니다.')
              : getLocalizedText('참조권에 존재하는 조직입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          directoryTreeSelectedId,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((a) => {
              if (a.id === referencePermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 조회권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      setState((prevState) => {
        const { viewPermission } = prevState;
        if (
          viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            item.employeeId !== undefined
              ? getLocalizedText('조회권에 존재하는 직원입니다.')
              : getLocalizedText('조회권에 존재하는 조직입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          directoryTreeSelectedId,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((a) => {
              if (a.id === viewPermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 선택된 그룹이 없는 경우
    else setState((prevState) => ({ ...prevState, directoryTreeSelectedId }));
  };

  /** 결재 담당자 항목 클릭 */
  const handleApprovalOfficerItemClick = (arg: {
    item: {
      companyId: number;
      id: number;
      name: string;
      organizationId: number;
      employeeId: number;
      updateAt: string;
    };
    event: React.MouseEvent;
  }) => {
    const { companyId, id: macroId, name: macroName, employeeId } = arg.item;

    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    const approvalLineGroupSelected =
      approvalGroupSelectedId === approvalLineGroupSelectedId;

    const extra = directoryTreeItems.find(
      (a) =>
        a.extra.type === 'employee' &&
        a.extra.companyId === companyId &&
        a.extra.employeeId === employeeId,
    )?.extra;
    if (extra === undefined || extra.type !== 'employee') {
      const validation = getLocalizedText('지정된 담당자를 찾을 수 없습니다.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const item = {
      id: approvalLineGroupSelected
        ? `${Date.now()}`
        : `${companyId}_${macroId}`,
      companyId: extra.companyId,
      companyName: extra.companyName,
      organizationId: extra.organizationId,
      employeeId: extra.employeeId,
      employeeName: extra.employeeName,
      organizationName: extra.organizationName,
      jobClassType: extra.jobClassType,
      jobPositionId: extra.jobPositionId,
      jobPositionName: extra.jobPositionName,
      jobDutyId: extra.jobDutyId,
      jobDutyName: extra.jobDutyName,
      avatar: extra.avatar,
      macroId,
      macroName,
    };

    // 결재선 그룹이 선택된 경우
    if (approvalLineGroupSelected) {
      const approvalLineGroupType = state.approvalLine.groups.find(
        (a) => a.id === approvalLineGroupSelectedId,
      )?.type;

      const arbitraryDecision =
        approvalLineGroupType === 'approval' && item.employeeId !== undefined
          ? arbitraryDecisions.find(
              (a) =>
                a.companyId === item.companyId &&
                a.organizationId === item.organizationId &&
                a.employeeId === item.employeeId,
            ) !== undefined
          : false;

      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === approvalLineGroupSelectedId) {
                switch (group.type) {
                  case 'draft':
                    return group;
                  case 'agree': {
                    return {
                      ...group,
                      items: [...group.items, { ...item, option: false }],
                    };
                  }
                  case 'approval': {
                    if (item.employeeId === undefined) {
                      // return { ...group, items: [...group.items, item] };
                      return group;
                    }
                    return {
                      ...group,
                      items: [...group.items, { ...item, arbitraryDecision }],
                    };
                  }
                  default:
                    return { ...group, items: [...group.items, item] };
                }
              }
              return group;
            }),
          },
        };
      });
    }
    // 참조권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      setState((prevState) => {
        const { referencePermission } = prevState;
        if (
          referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            getLocalizedText('참조권에 존재하는 담당자입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((a) => {
              if (a.id === referencePermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 조회권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      setState((prevState) => {
        const { viewPermission } = prevState;
        if (
          viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            getLocalizedText('조회권에 존재하는 담당자입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((a) => {
              if (a.id === viewPermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
  };

  /** 결재선 그룹 선택 */
  const handleApprovalLineGroupSelect = (arg: {
    id: string;
    modify: boolean;
  }) => {
    const { id, modify } = arg;
    if (modify)
      setState((prevState) => ({
        ...prevState,
        approvalGroupSelectedId: id,
        approvalLineGroupSelectedId: id,
      }));
  };

  /** 결재선 그룹 병렬 변경 (합의, 수신 그룹에서 사용) */
  const handleApprovalLineGroupParallelChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLineGroupSelectedId: id,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((a) => {
            if ((a.type === 'agree' || a.type === 'receive') && a.id === id)
              return { ...a, parallel: !a.parallel };
            return a;
          }),
        },
      };
    });
  };

  /** 결재선 그룹 삭제 */
  const handleApprovalLineGroupDelete = (arg: {
    index: number;
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  }) => {
    const { index, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.filter((v, i) => i !== index),
        },
      };
    });
  };

  /** 결재선 그룹 항목 정렬 변경 */
  const handleApprovalLineGroupItemSortChange = (
    id: string,
    items: ApprovalLineGroupItemType[],
  ) => {
    let sortItems = items;
    const prevApprovers = props.approvalLine?.groups
      .find((a) => a.id === id)
      ?.items.filter((a) => a.act !== undefined); // 현재 변경할 그룹에서 결재 완료자 체크
    const nextApprovers = sortItems.filter((a) => a.act === undefined); // 현재 변경할 그룹에서 결재 예정자 체크
    let newGroups = [...nextApprovers];
    if (prevApprovers) newGroups = [...prevApprovers, ...newGroups];
    const prevItems = state.approvalLine.groups.find((a) => a.id === id)?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== newGroups.length;
    if (prevItems.length === newGroups.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== newGroups[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    if (sortChanged)
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((z, i) => {
              let status: 'complete' | 'proceed' | 'expected';

              // 결재작성인 경우.
              if (proceedIndex === 0) {
                // 수정 여부에 따라 결재 그룹 드레그 상태 설정.
                status = z.modify !== true ? 'complete' : 'expected';
              }
              // 결재선 변경인 경우.
              else if (proceedIndex === -1 || i < proceedIndex)
                status = 'complete';
              else if (i === proceedIndex) status = 'proceed';
              else status = 'expected';

              const parallelCheck =
                z.items.length === 1 && z.modify && status !== 'proceed';

              if (z.id === id) {
                // 합의 그룹 제외 option값 삭제
                if (z.type !== 'agree') {
                  newGroups = newGroups.map((a) => {
                    return {
                      ...a,
                      option: undefined,
                    };
                  });
                  sortItems = sortItems.map((a) => {
                    return {
                      ...a,
                      option: undefined,
                    };
                  });
                }
                // 결재 그룹 제외 arbitraryDesicion값 삭제
                if (z.type !== 'approval') {
                  newGroups = newGroups.map((a) => {
                    return {
                      ...a,
                      arbitraryDecision: undefined,
                    };
                  });
                }
                newGroups = newGroups.map((a) => {
                  return sortableDataUndefined(a);
                });
                sortItems = sortItems.map((a) => {
                  return sortableDataUndefined(a);
                });
                switch (z.type) {
                  case 'approval':
                    return {
                      ...z,
                      items: (
                        newGroups as ApprovalLineGroupItemsApprovalType
                      ).map((a) => {
                        if (a.employeeId)
                          return {
                            ...a,
                            arbitraryDecision:
                              a.arbitraryDecision ??
                              arbitraryDecisions.some(
                                (x) => x.employeeId === a.employeeId,
                              ),
                          };
                        return {
                          ...a,
                        };
                      }),
                    };
                  case 'agree':
                    return {
                      ...z,
                      parallel: parallelCheck ? false : z.parallel,
                      items: z.parallel
                        ? (sortItems as ApprovalLineGroupItemsAgreeType).map(
                            (a) => {
                              return {
                                ...a,
                                option: a.option ?? false,
                              };
                            },
                          )
                        : (newGroups as ApprovalLineGroupItemsAgreeType).map(
                            (a) => {
                              return {
                                ...a,
                                option: a.option ?? false,
                              };
                            },
                          ),
                    };
                  case 'receive':
                    return {
                      ...z,
                      parallel: parallelCheck ? false : z.parallel,
                      items: z.parallel
                        ? (sortItems as ApprovalLineGroupItemsReceiveType)
                        : (newGroups as ApprovalLineGroupItemsReceiveType),
                    };
                  case 'audit':
                    return {
                      ...z,
                      items: newGroups as ApprovalLineGroupItemsAuditType,
                    };
                  // draft
                  default:
                    return {
                      ...z,
                      items: newGroups as ApprovalLineGroupItemsDraftType,
                    };
                }
              }
              return z;
            }),
          },
        };
      });
  };

  /** 결재선 그룹 항목 옵션 변경 */
  const handleApprovalLineGroupItemOptionChange = (arg: {
    groupId: string;
    id: string; // 그룹 아이템 아이디
    code: string;
    checked: boolean;
  }) => {
    const { groupId, id, code } = arg;
    if (
      code === 'jobposition' ||
      code === 'jobduty' ||
      code === 'jobposition+jobduty'
    ) {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'approval' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'agree' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'audit' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'receive' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'option') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'agree' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id)
                      return { ...item, option: !item.option };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'draftApproval') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft')
                return {
                  ...group,
                  items: group.items.map((item) => {
                    const { approval = false } = item;
                    return { ...item, approval: !approval };
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'arbitrarydecision') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'approval' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return {
                        ...item,
                        arbitraryDecision: !item.arbitraryDecision,
                      };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    }
  };

  /** 결재선 그룹 항목 항목 삭제 */
  const handleApprovalLineGroupItemDelete = (arg: {
    groupId: string;
    id: string; // 그룹 아이템 아이디
    event: React.MouseEvent;
  }) => {
    // console.log(`handleApprovalLineGroupItemDelete(arg)`, arg);
    const { groupId, id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      return {
        ...prevState,
        approvalLine: {
          ...prevState.approvalLine,
          groups: prevState.approvalLine.groups.map((a, i) => {
            let status: 'complete' | 'proceed' | 'expected';

            // 결재작성인 경우.
            if (proceedIndex === 0) {
              status = a.modify !== true ? 'complete' : 'expected';
            }
            // 결재선 변경인 경우.
            else if (proceedIndex === -1 || i < proceedIndex)
              status = 'complete';
            else if (i === proceedIndex) status = 'proceed';
            else status = 'expected';

            const parallelCheck =
              a.items.length === 2 && a.modify && status !== 'proceed';
            if (a.id === groupId) {
              switch (a.type) {
                case 'agree':
                  return {
                    ...a,
                    parallel: parallelCheck ? false : a.parallel,
                    items: a.items.filter((b) => b.id !== id),
                  };
                case 'approval':
                  return { ...a, items: a.items.filter((b) => b.id !== id) };
                case 'receive':
                  return {
                    ...a,
                    parallel: parallelCheck ? false : a.parallel,
                    items: a.items.filter((b) => b.id !== id),
                  };
                case 'audit':
                  return { ...a, items: a.items.filter((b) => b.id !== id) };
                default:
                  return a;
              }
            }
            return a;
          }),
        },
      };
    });
  };

  /** 참조 권한 그룹 선택 */
  const handleReferencePermissionGroupSelect = (arg: {
    id: string;
    modify: boolean;
  }) => {
    // console.log('handleReferencePermissionGroupSelect(arg)', arg);
    const { id, modify } = arg;
    if (modify) {
      setState((prevState) => {
        return {
          ...prevState,
          approvalGroupSelectedId: id,
          referencePermissionGroupSelectedId: id,
        };
      });
    }
  };

  /** 참조 권한 그룹 삭제 */
  const handleReferencePermissionGroupDelete = (arg: {
    id: string;
    event: React.MouseEvent;
  }) => {
    const { id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const {
        approvalGroupSelectedId,
        referencePermissionGroupSelectedId,
        referencePermission,
      } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId:
          approvalGroupSelectedId === id ? '' : approvalGroupSelectedId,
        referencePermissionGroupSelectedId:
          referencePermissionGroupSelectedId === id
            ? ''
            : referencePermissionGroupSelectedId,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.filter((a) => a.id !== id),
        },
      };
    });
  };

  /** 참조 권한 그룹 항목 정렬 변경 */
  const handleReferencePermissionGroupItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    // console.log(`handleReferencePermissionGroupItemSortChange(arg)`, arg);
    const { groupId, items } = arg;
    const prevItems = state.referencePermission.groups.find(
      ({ id }) => id === groupId,
    )?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    // console.log(`id: ${id}, sortChanged: ${sortChanged}`);
    if (sortChanged)
      setState((prevState) => {
        const { referencePermission } = prevState;
        return {
          ...prevState,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((group) => {
              if (group.id === groupId) return { ...group, items };
              return group;
            }),
          },
        };
      });
  };

  /** 참조 권한 그룹 항목 삭제 */
  const handleReferencePermissionGroupItemDelete = (arg: {
    groupId: string;
    id: string;
  }) => {
    // console.log(`handleReferencePermissionGroupItemDelete(arg)`, arg);
    const { groupId, id } = arg;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((group) => {
            // if (group.id === groupId) return { ...group, items };
            if (group.id === groupId)
              return {
                ...group,
                items: group.items.filter((item) => item.id !== id),
              };
            return group;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 선택 */
  const handleViewPermissionGroupSelect = (arg: {
    id: string;
    modify: boolean;
  }) => {
    // console.log('handleViewPermissionGroupSelect(arg)', arg);
    const { id, modify } = arg;
    if (modify) {
      setState((prevState) => {
        return {
          ...prevState,
          approvalGroupSelectedId: id,
          viewPermissionGroupSelectedId: id,
        };
      });
    }
  };

  /** 조회 권한 그룹 삭제 */
  const handleViewPermissionGroupDelete = (arg: {
    id: string;
    event: React.MouseEvent;
  }) => {
    const { id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const {
        approvalGroupSelectedId,
        viewPermissionGroupSelectedId,
        viewPermission,
      } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId:
          approvalGroupSelectedId === id ? '' : approvalGroupSelectedId,
        viewPermissionGroupSelectedId:
          viewPermissionGroupSelectedId === id
            ? ''
            : viewPermissionGroupSelectedId,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.filter((a) => a.id !== id),
        },
      };
    });
  };

  /** 조회 권한 그룹 항목 정렬 변경 */
  const handleViewPermissionGroupItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    // console.log(`handleViewPermissionGroupItemSortChange(arg)`, arg);
    const { groupId, items } = arg;
    const prevItems = state.viewPermission.groups.find(
      ({ id }) => id === groupId,
    )?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    // console.log(`id: ${id}, sortChanged: ${sortChanged}`);
    if (sortChanged)
      setState((prevState) => {
        const { viewPermission } = prevState;
        return {
          ...prevState,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((group) => {
              if (group.id === groupId) return { ...group, items };
              return group;
            }),
          },
        };
      });
  };

  /** 조회 권한 그룹 항목 삭제 */
  const handleViewPermissionGroupItemDelete = (arg: {
    groupId: string;
    id: string;
  }) => {
    // console.log(`handleViewPermissionGroupItemDelete(arg)`, arg);
    const { groupId, id } = arg;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((group) => {
            // if (group.id === groupId) return { ...group, items };
            if (group.id === groupId)
              return {
                ...group,
                items: group.items.filter((item) => item.id !== id),
              };
            return group;
          }),
        },
      };
    });
  };

  /** 선택 사용자 목록 대화 상자 열기 */
  const handleChooseUserDialogOpen = () => {
    setState((prevState) => ({ ...prevState, chooseUserDialogVisible: true }));
  };

  /** 선택 사용자 목록 대화 상자 닫기 */
  const handleChooseUserDialogClose = () => {
    setState((prevState) => ({ ...prevState, chooseUserDialogVisible: false }));
  };

  function createApprovalLineGroup(type: ApprovalType): ApprovalLineGroupType {
    const id = `${Date.now()}`;
    const modify = true;
    switch (type) {
      case 'agree':
        return {
          id,
          type,
          required: false,
          modify,
          parallel: false,
          items: [],
        };
      case 'receive':
        return {
          id,
          type,
          required: false,
          modify,
          parallel: false,
          items: [],
        };
      case 'approval':
        return { id, type, required: false, modify, items: [] };
      case 'audit':
        return { id, type, required: false, modify, items: [] };
      // draft
      default:
        return { id, type, approval: false, items: [] };
    }
  }

  /** 결재선 그룹 추가 */
  const handleApprovalLineGroupAppend = (
    type: Exclude<ApprovalType, 'draft'>,
  ) => {
    // console.log(`handleApprovalLineGroupAppend(type: '${type}')`);
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: [...approvalLine.groups, createApprovalLineGroup(type)],
        },
      };
    });
  };

  /** 참조 권한 그룹 추가 */
  const handleReferencePermissionGroupAppend = () => {
    if (state.referencePermission.groups.length !== 0) return;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: [
            ...referencePermission.groups,
            {
              id: `${Date.now()}`,
              required: false, // 필수 여부
              modify: true, // 수정 여부
              items: [],
            },
          ],
        },
      };
    });
  };

  /** 조회 권한 그룹 추가 */
  const handleViewPermissionGroupAppend = () => {
    if (state.viewPermission.groups.length !== 0) return;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: [
            ...viewPermission.groups,
            {
              id: `${Date.now()}`,
              required: false, // 필수 여부
              modify: true, // 수정 여부
              items: [],
            },
          ],
        },
      };
    });
  };

  /** 결재선 그룹 항목 모두 삭제 */
  const handleApprovalLineGroupItemDeleteAll = () => {
    const { type } = props;
    // 결재 작성에서 호출된 경우.
    if (type === undefined) {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft' || !group.modify) return group;
              return { ...group, items: [] };
            }),
          },
        };
      });
    }
    // 결재선 및 조회권, 참조권 변경에서 호출된 경우.
    else {
      setState((prevState) => {
        return {
          ...prevState,
          approvalLine: {
            ...prevState.approvalLine,
            groups: prevState.approvalLine.groups.map((a) => {
              if (a.type === 'draft' || !a.modify) return a;
              switch (a.type) {
                case 'agree':
                  return { ...a, items: a.items.filter((b) => b.act) };
                case 'approval':
                  return { ...a, items: a.items.filter((b) => b.act) };
                case 'receive':
                case 'audit':
                  return { ...a, items: a.items.filter((b) => b.act) };
                default:
                  return a;
              }
            }),
          },
        };
      });
    }
  };

  /** 참조 권한 그룹 항목 모두 삭제 */
  const handleReferencePermissionGroupItemDeleteAll = () => {
    // console.log(`handleReferencePermissionGroupItemDeleteAll`);
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((group) => {
            if (group.modify) return { ...group, items: [] };
            return group;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 항목 모두 삭제 */
  const handleViewPermissionGroupItemDeleteAll = () => {
    // console.log(`handleViewPermissionGroupItemDeleteAll`);
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((group) => {
            if (group.modify) return { ...group, items: [] };
            return group;
          }),
        },
      };
    });
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 1인 결재인 경우 */
  const isDraftApproval = (approvalLine: ApprovalLineType) => {
    if (
      approvalLine.groups.find(
        (a) => a.type === 'draft' && a.approval && a.items[0].approval,
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   * 기안자 결재(1인 결재) 유효성 검사.
   * @param approvalLine 결재선.
   * @returns 기안자 결재(1인 결재) 유효성 검사를 통과한 경우 true, 통과하지 못한 경우 false.
   */
  const validateDraftApproval = (
    approvalLine: ApprovalLineType | undefined,
  ) => {
    if (approvalLine === undefined) return true;

    // 기안자 결재(1인 결재)인 경우.
    if (isDraftApproval(approvalLine)) {
      const group = approvalLine.groups.find(
        (g) => g.type !== 'draft' && g.items.length > 0,
      );
      if (group !== undefined) {
        const approvalLineGroupSelectedId = group.id;
        const validation = `1인 결재인 경우 기안자 외 결재 그룹을 선택할 수 없습니다.`;
        if (group.modify) {
          setState((prevState) => ({
            ...prevState,
            approvalGroupSelectedId: approvalLineGroupSelectedId,
            approvalLineGroupSelectedId,
            validation,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            validation,
          }));
        }
        return false;
      }
      return true;
    }
    return true;
  };

  /**
   * 병렬 그룹의 중복 항목 유효성 검사.
   * @param approvalLine 결재선
   * @returns 결재선 병렬 그룹의 중복 항목이 없는 경우 true, 있는 경우 false
   */
  const validateDuplicateItemsInParallelGroups = (
    approvalLine: ApprovalLineType | undefined,
  ) => {
    if (approvalLine === undefined) return true;

    // 병렬 구성 중인 그룹에서 중복 항목이 있는 그룹 찾기.
    const group = approvalLine.groups.find((a) => {
      if ((a.type === 'agree' || a.type === 'receive') && a.parallel) {
        const organizationIds = a.items
          .filter((b) => b.employeeId === undefined)
          .map(({ organizationId }) => organizationId);
        const employeeIds = a.items
          .filter((b) => b.employeeId !== undefined)
          .map(({ employeeId }) => employeeId);

        return (
          organizationIds.length !==
            Array.from(new Set(organizationIds)).length ||
          employeeIds.length !== Array.from(new Set(employeeIds)).length
        );
      }
      return false;
    });
    if (group !== undefined) {
      const approvalGroupSelectedId = group.id;
      const approvalGroupName = getApprovalLineGroupNameFromType(group.type);

      const validation = `${getLocalizedText(
        `결재선그룹.${approvalGroupName}`,
      )} ${getLocalizedText(
        '그룹 병렬 구성 시 직원 또는 조직을 중복 설정할 수 없습니다.',
      )}`;
      setState((prevState) => ({
        ...prevState,
        approvalGroupSelectedId,
        approvalLineGroupSelectedId: approvalGroupSelectedId,
        validation,
      }));
      return false;
    }
    return true;
  };

  /** 저장 */
  const handleSave = () => {
    const { selectedId, approvalLineName, workUpdateAt } = state;
    if (selectedId === 0) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('업무를 선택 해주세요.'),
      }));
      return;
    }

    if (approvalLineName === '') {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('결재선명을 입력 해주세요.'),
      }));
      return;
    }

    if (props.onSave !== undefined) {
      const { approvalLine } = state;
      if (!isDraftApproval(approvalLine)) {
        const group = approvalLine.groups.find(
          (a) => a.type !== 'draft' && a.required && a.items.length === 0,
        );
        if (group !== undefined) {
          const approvalLineGroupSelectedId = group.id;
          const approvalGroupName = getApprovalLineGroupNameFromType(
            group.type,
          );
          const validation = `${getLocalizedText(
            `결재선그룹.${approvalGroupName}`,
          )} ${getLocalizedText('그룹의 직원 또는 조직을 선택하셔야 합니다.')}`;
          setState((prevState) => ({
            ...prevState,
            approvalGroupSelectedId: approvalLineGroupSelectedId,
            approvalLineGroupSelectedId,
            validation,
          }));
          return;
        }
      }

      if (!validateDraftApproval(approvalLine)) return;
      if (!validateDuplicateItemsInParallelGroups(approvalLine)) return;

      // 개인결재선 추가일 때
      if (props.type === undefined) {
        props.onSave({
          workId: selectedId,
          name: approvalLineName,
          workUpdateAt,
          approvalLine,
          referencePermission: state.referencePermission,
          viewPermission: state.viewPermission,
        });
      }
      // 개인결재선 수정일 때
      if (props.type === 'change') {
        props.onSave(
          selectedId === props.workId
            ? {
                id: props.id,
                updateAt: props.updateAt,
                name: approvalLineName,
                approvalLine,
                referencePermission: state.referencePermission,
                viewPermission: state.viewPermission,
              }
            : {
                id: props.id,
                updateAt: props.updateAt,
                workId: selectedId,
                name: approvalLineName,
                approvalLine,
                referencePermission: state.referencePermission,
                viewPermission: state.viewPermission,
                workUpdateAt,
              },
        );
      }
    }
  };

  /** 선택 사용자 목록 렌더링 (모바일 화면 크기에서 사용) */
  const renderChooseUserList = () => {
    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    // 결재선 그룹이 선택된 경우
    if (approvalGroupSelectedId === approvalLineGroupSelectedId) {
      const { approvalLine } = state;
      return (
        <>
          {approvalLine.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    // 참조권 그룹이 선택된 경우
    if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      const { referencePermission } = state;
      return (
        <>
          {referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    // 조회권 그룹이 선택된 경우
    if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      const { viewPermission } = state;
      return (
        <>
          {viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    return null;
  };

  /** 선택 탭 렌더링 */
  const renderChooseTab = () => {
    const { chooseTabSelectedId } = state;

    let content = null;
    switch (chooseTabSelectedId) {
      case 'organizationchart': {
        content = (
          <>
            <SimpleSearch
              keyword={state.directoryTreeFilter}
              onSearch={handleDirectoryTreeFilter}
            />
            <div className="tree-area">
              <DirectoryTree
                compose
                selectedId={state.directoryTreeSelectedId}
                items={directoryTreeItems}
                filter={state.directoryTreeFilter}
                onItemClick={handleDirectoryTreeItemClick}
              />
            </div>
          </>
        );
        break;
      }
      case 'approvalofficer': {
        // TODO 로딩중 퍼블리싱 작업 필요.
        content = (
          <div className="area-content manager-area">
            {approvalOfficers === undefined ? (
              <Loading />
            ) : (
              approvalOfficers.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    label={item.name}
                    icon="user-tie"
                    onClick={(event) =>
                      handleApprovalOfficerItemClick({ item, event })
                    }
                  />
                );
              })
            )}
          </div>
        );
        break;
      }
      default:
        break;
    }

    return (
      <>
        <Tab>
          {chooseTabs.map(({ id, text }) => (
            <Tab.Item
              key={id}
              label={text}
              selected={id === chooseTabSelectedId}
              onClick={() => handleChooseTabItemClick({ id })}
            />
          ))}
        </Tab>
        {content}
      </>
    );
  };

  /** 결재선 그룹 바디 항목 렌더링 */
  const renderApprovalLineGroupBodyItem = (
    a: ApprovalLineGroupType,
    b: ApprovalLineGroupItemType,
    modify: boolean,
  ) => {
    const {
      approval: draftApproval = false,
      option = false,
      arbitraryDecision = false,
    } = {
      ...b,
    };

    // 직원인 경우
    if (b.employeeId !== undefined) {
      const isJobClassChange =
        (props.type === undefined && a.type === 'draft') || modify;

      const text = getEmployeeName({
        name: b.employeeName,
        type: b.jobClassType,
        jobPosition: b.jobPositionName,
        jobDuty: b.jobDutyName,
      });

      const jobClassOptions = isJobClassChange
        ? getApprovalLineGroupItemOptions(b.jobClassType)
        : [];

      const draftApprovalOptions =
        props.type === undefined && a.type === 'draft' && a.approval === true
          ? createApprovalLineGroupItemDraftApprovalOptions(draftApproval)
          : [];

      const agreeOptions =
        modify && a.type === 'agree'
          ? getApprovalLineGroupItemAgreeOptions(option)
          : [];

      const approvalOptions =
        modify && a.type === 'approval'
          ? getApprovalLineGroupItemApprovalOptions(arbitraryDecision)
          : [];

      const options = [
        ...jobClassOptions,
        ...draftApprovalOptions,
        ...agreeOptions,
        ...approvalOptions,
      ];

      return (
        <ApprovalLineGroupItem
          key={b.id}
          groupId={a.id}
          id={b.id}
          employeeText={text}
          organizationName={b.organizationName}
          avatar={getAvatarPath(b)}
          macroName={b.macroName}
          onDelete={modify ? handleApprovalLineGroupItemDelete : undefined}
          options={options.length ? options : undefined}
          onOptionChange={
            isJobClassChange
              ? handleApprovalLineGroupItemOptionChange
              : undefined
          }
          dragable={modify}
        />
      );
    }

    const options =
      modify && a.type === 'agree'
        ? getApprovalLineGroupItemAgreeOptions(option, false)
        : [];

    // 조직인 경우
    return (
      <ApprovalLineGroupItem
        key={b.id}
        groupId={a.id}
        id={b.id}
        organizationName={b.organizationName}
        onDelete={modify ? handleApprovalLineGroupItemDelete : undefined}
        options={options.length ? options : undefined}
        onOptionChange={
          modify ? handleApprovalLineGroupItemOptionChange : undefined
        }
        dragable={modify}
      />
    );
  };

  /** 결재선 지정 렌더링 */
  const renderApprovalLineDesignation = () => {
    const {
      approvalGroupSelectedId,
      approvalLine,
      referencePermission,
      viewPermission,
    } = state;
    return (
      <div className="approval-line" style={{ paddingTop: '24px' }}>
        <div className="line-root">
          {approvalLine.groups.map((a, i) => {
            /** 결재 그룹 진행 상태. (결재선 변경인 경우) - expected: 예정, proceed: 진행, complete: 완료 */
            let status: 'complete' | 'proceed' | 'expected';

            // 결재작성인 경우.
            if (proceedIndex === 0) {
              // 수정 여부에 따라 결재 그룹 드레그 상태 설정.
              status = a.modify !== true ? 'complete' : 'expected';
            }
            // 결재선 변경인 경우.
            else if (proceedIndex === -1 || i < proceedIndex)
              status = 'complete';
            else if (i === proceedIndex) status = 'proceed';
            else status = 'expected';

            const parallel =
              (a.type === 'agree' || a.type === 'receive') && a.parallel;

            const { id, required = false, modify = false } = { ...a };

            let className = `line-group ${approvalLineGroupClassName(a.type)}`;
            if (!modify) className += ` lock`;
            if (required) className += ` required`;

            const approvalGroupName = getApprovalLineGroupNameFromType(a.type);

            return (
              <div
                key={id}
                className={className}
                onClick={() =>
                  handleApprovalLineGroupSelect({
                    id,
                    modify: modify && status !== 'complete',
                  })
                }
                aria-selected={id === approvalGroupSelectedId}
              >
                <div className="group-head">
                  <div className="title">
                    {getLocalizedText(`결재선그룹.${approvalGroupName}`)}
                  </div>
                  {(a.type === 'agree' || a.type === 'receive') &&
                    modify &&
                    a.items.length > 1 && (
                      <Checkbox
                        label={getLocalizedText('병렬')}
                        checked={a.parallel}
                        onChange={() =>
                          handleApprovalLineGroupParallelChange({ id })
                        }
                        disabled={!modify || status !== 'expected'}
                        className="group-option"
                      />
                    )}
                  {a.type !== 'draft' && !modify && (
                    <Tooltip
                      title={getLocalizedText(
                        '고정 그룹은 편집할 수 없습니다.',
                      )}
                    >
                      <em className="tip">{getLocalizedText('고정')}</em>
                    </Tooltip>
                  )}
                  {required && modify && (
                    <Tooltip
                      title={getLocalizedText(
                        '필수 그룹은 최소 1인 이상 필요.',
                      )}
                    >
                      <em className="tip">{getLocalizedText('필수')}</em>
                    </Tooltip>
                  )}
                  {isInternalApprovalAfterReceipt && a.items.length === 0 && (
                    <div className="action">
                      <Button
                        text={getLocalizedText('삭제')}
                        iconType
                        icon="trash-full"
                        onClick={(event) =>
                          handleApprovalLineGroupDelete({
                            index: i,
                            event,
                          })
                        }
                        color="secondary"
                        className="group-delete"
                      />
                    </div>
                  )}
                </div>
                <div className="group-body">
                  {status === 'complete' ||
                  (status === 'proceed' && !parallel) ? (
                    <>
                      <div className="approval-line-group">
                        {(a.items as ApprovalLineGroupItemType[])
                          .filter((b) => proceedIndex === 0 || b.act)
                          .map((b) => {
                            return renderApprovalLineGroupBodyItem(a, b, false);
                          })}
                      </div>
                      {status === 'proceed' && !parallel && (
                        <ReactSortable
                          className="approval-line-group"
                          list={(a.items as ApprovalLineGroupItemType[]).filter(
                            (b) => b.act === undefined,
                          )}
                          setList={(newState) =>
                            handleApprovalLineGroupItemSortChange(
                              a.id,
                              newState,
                            )
                          }
                          animation={200}
                          handle=".drag"
                          group="approval-line-group"
                        >
                          {(a.items as ApprovalLineGroupItemType[])
                            .filter((b) => b.act === undefined)
                            .map((b) => {
                              return renderApprovalLineGroupBodyItem(
                                a,
                                b,
                                a.modify === true,
                              );
                            })}
                        </ReactSortable>
                      )}
                    </>
                  ) : (
                    <>
                      <ReactSortable
                        className="approval-line-group"
                        list={a.items}
                        setList={(newState) =>
                          handleApprovalLineGroupItemSortChange(a.id, newState)
                        }
                        animation={200}
                        handle=".drag"
                        group="approval-line-group"
                      >
                        {(a.items as ApprovalLineGroupItemType[]).map((b) => {
                          let modifing: boolean;
                          if (status === 'expected')
                            modifing = a.modify === true;
                          else if (status === 'proceed')
                            modifing = a.modify === true && b.act === undefined;
                          else modifing = false;

                          return renderApprovalLineGroupBodyItem(
                            a,
                            b,
                            modifing,
                          );
                        })}
                      </ReactSortable>
                    </>
                  )}
                  {display === 'phone' && modify && (
                    <div className="action">
                      <Button
                        className="add-approval-line-item"
                        text={getLocalizedText('편집')}
                        onClick={handleChooseUserDialogOpen}
                        block
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {referencePermission && (
          <SharePermissionGroups
            title={getLocalizedText('참조')}
            selectedId={approvalGroupSelectedId}
            groups={referencePermission.groups}
            onSelect={handleReferencePermissionGroupSelect}
            onItemAppend={
              display === 'phone' ? handleChooseUserDialogOpen : undefined
            }
            onItemSortChange={handleReferencePermissionGroupItemSortChange}
            onItemDelete={handleReferencePermissionGroupItemDelete}
            onDelete={
              isInternalApprovalAfterReceipt
                ? handleReferencePermissionGroupDelete
                : undefined
            }
          />
        )}
        {viewPermission && (
          <SharePermissionGroups
            title={getLocalizedText('조회')}
            selectedId={approvalGroupSelectedId}
            groups={viewPermission.groups}
            onSelect={handleViewPermissionGroupSelect}
            onItemAppend={
              display === 'phone' ? handleChooseUserDialogOpen : undefined
            }
            onItemSortChange={handleViewPermissionGroupItemSortChange}
            onItemDelete={handleViewPermissionGroupItemDelete}
            onDelete={
              isInternalApprovalAfterReceipt
                ? handleViewPermissionGroupDelete
                : undefined
            }
          />
        )}
        {isInternalApprovalAfterReceipt && (
          <dl className="add-line">
            <dt>{getLocalizedText('결재유형 추가')}</dt>
            <dd>
              {approvalTypes.find((a) => a === 'APPROVAL') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('approval')}
                  className="approval"
                >
                  <Icon icon="check" />
                  <span>{getLocalizedText('결재')}</span>
                </button>
              )}
              {approvalTypes.find((a) => a === 'AGREEMENT') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('agree')}
                  className="agree"
                >
                  <Icon icon="equals" />
                  <span>{getLocalizedText('합의')}</span>
                </button>
              )}
              {approvalTypes.find((a) => a === 'AUDIT') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('audit')}
                  className="auditor"
                >
                  <Icon icon="user-tie" />
                  <span>{getLocalizedText('감사')}</span>
                </button>
              )}
              {approvalTypes.find((a) => a === 'RECIPIENT') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('receive')}
                  className="receiver"
                >
                  <Icon icon="flag-fill" />
                  <span>{getLocalizedText('수신')}</span>
                </button>
              )}
              <button
                type="button"
                onClick={handleReferencePermissionGroupAppend}
                className="referrer"
              >
                <Icon icon="search" />
                <span>{getLocalizedText('참조')}</span>
              </button>
              <br />
              <button
                type="button"
                onClick={handleViewPermissionGroupAppend}
                className="viewer"
              >
                <Icon icon="eye" />
                <span>{getLocalizedText('조회')}</span>
              </button>
            </dd>
          </dl>
        )}
      </div>
    );
  };

  /** 결재 프로세스 렌더링 */
  const renderApprovalProcess = () => {
    const { approvalLine, referencePermission, viewPermission } = state;

    const useApprovalLineDeleteAll =
      approvalLine.groups.find(
        (group) =>
          group.type !== 'draft' && group.modify && group.items.length > 0,
      ) !== undefined;

    let useReferencePermissionDelete = false;
    const referencePermissionItems = referencePermission.groups
      .map(({ items, modify }) => {
        if (items.length === 0) return [];
        if (modify) useReferencePermissionDelete = true;
        return items;
      })
      .flat();

    let useViewPermissionDelete = false;
    const viewPermissionItems = viewPermission.groups
      .map(({ items, modify }) => {
        if (items.length === 0) return [];
        if (modify) useViewPermissionDelete = true;
        return items;
      })
      .flat();

    return (
      <div className="approval-process">
        <dl>
          <dt>{getLocalizedText('결재선')}</dt>
          <dd>
            <ApprovalLineFlat
              optionalNotation
              approvalLine={approvalLine}
              ignoreGroupName
            />
            {useApprovalLineDeleteAll && (
              <Button
                className="delete"
                text={getLocalizedText('모두삭제')}
                iconType
                icon="trash-full"
                onClick={handleApprovalLineGroupItemDeleteAll}
                color="secondary"
                size="xs"
              />
            )}
          </dd>
        </dl>
        <dl>
          <dt>{getLocalizedText('참조권')}</dt>
          <dd>
            <SharePermissionFlat
              items={referencePermissionItems}
              onDeleteAll={
                useReferencePermissionDelete
                  ? handleReferencePermissionGroupItemDeleteAll
                  : undefined
              }
            />
          </dd>
        </dl>
        <dl>
          <dt>{getLocalizedText('조회권')}</dt>
          <dd>
            <SharePermissionFlat
              items={viewPermissionItems}
              onDeleteAll={
                useViewPermissionDelete
                  ? handleViewPermissionGroupItemDeleteAll
                  : undefined
              }
            />
          </dd>
        </dl>
      </div>
    );
  };

  /** 업무 선택 다이얼로그 */
  const handleWorkSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      dialogType: 'workSelect',
    }));
  };

  /** 개인결재선 이름 변경 */
  const handleApprovalLineNameNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      approvalLineName: event.target.value,
    }));
  };

  const items = useMemo(() => {
    const result = [
      ...folders
        .map((a) => {
          return {
            seq: a.seq,
            id: a.id,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as IconType,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
      ...works
        .map(({ seq, id, folderId, name }) => ({
          seq,
          id,
          parentId: folderId,
          text: name,
          icon: 'clipboard-edit' as IconType,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];

    return result;
  }, [works]);

  const renderDialog = () => {
    const { dialogType } = state;

    if (dialogType === 'workSelect') {
      return (
        <TreePicker
          title={getLocalizedText('업무 선택')}
          type="full"
          list={items}
          selectedId={state.selectedId}
          onSelected={(id) => {
            setState((prevState) => ({
              ...prevState,
              selectedId: id,
            }));
          }}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              dialogType: '',
            }))
          }
        />
      );
    }

    return null;
  };

  const { workName } = state;
  const { onCancel } = props;
  const { chooseUserDialogVisible, validation } = state;

  return (
    <>
      <Dialog className="ui-approval-line-selection-dialog">
        <div
          className={`ui-approval-line-selection ${
            display === 'phone' && chooseUserDialogVisible ? 'fixed' : ''
          }`}
        >
          <div className="selection-head">
            <div className="title">{getLocalizedText('결재선 지정')}</div>
          </div>
          <div className="selection-body">
            <div className="selector-area">
              {(display !== 'phone' || chooseUserDialogVisible) && (
                <div className="select-panel">
                  <div className="add-custom-area">
                    {props.type !== 'change' ? (
                      <DropMenu
                        value={workName ?? ''}
                        label={getLocalizedText('결재업무 선택')}
                        onClick={handleWorkSelectDialogOpen}
                        pressed={false}
                      />
                    ) : (
                      <TextField value={workName} disabled />
                    )}
                    <TextField
                      label={getLocalizedText('결재선명')}
                      value={state.approvalLineName}
                      onChange={handleApprovalLineNameNameChange}
                    />
                  </div>
                  <div className="choose-area">
                    {display === 'phone' && chooseUserDialogVisible && (
                      <div className="mobile-choose-user">
                        <div className="user-list" ref={mobileChooseListRef}>
                          {renderChooseUserList()}
                        </div>
                        <Button
                          text={getLocalizedText('선택완료')}
                          variant="contained"
                          onClick={handleChooseUserDialogClose}
                          className="action-close"
                          block
                        />
                      </div>
                    )}
                    {renderChooseTab()}
                  </div>
                </div>
              )}
              <div className="selected-panel">
                {display === 'phone' && (
                  <div>
                    {props.type !== 'change' ? (
                      <div style={{ marginBottom: '5px' }}>
                        <DropMenu
                          value={workName ?? ''}
                          label={getLocalizedText('결재업무 선택')}
                          onClick={handleWorkSelectDialogOpen}
                          pressed={false}
                          block
                        />
                      </div>
                    ) : (
                      <div>
                        <TextField value={workName} disabled />
                      </div>
                    )}
                    <TextField
                      label={getLocalizedText('결재선명')}
                      value={state.approvalLineName}
                      onChange={handleApprovalLineNameNameChange}
                    />
                  </div>
                )}
                {renderApprovalLineDesignation()}
              </div>
            </div>
            <div className="result-area">{renderApprovalProcess()}</div>
          </div>
          <div className="selection-footer">
            <Button
              text={getLocalizedText('취소')}
              color="secondary"
              onClick={onCancel}
            />
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              onClick={handleSave}
            />
          </div>
        </div>
      </Dialog>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
      {renderDialog()}
    </>
  );
}

export {
  getApprovalLineDrafter,
  getApprovalGroup,
  getPreviousApprover,
  getPreviousApproverGroupItemId,
  isPreviousApprover,
  getCurrentApprover,
  getLastApprover,
  approversOf,
  isArbitraryDecisionApproving,
  nonArbitraryDecisionApproving,
  isCurrentApproversReceipting,
};

export type {
  ApprovalLineGroupItemEmployeeType,
  ApprovalLineGroupItemOrganizationType,
};

export default ApprovalLinePreferencesDialogContainer;
