import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import BoardPreferencesRoute from '../pages/adminconsole/board/preferences/BoardPreferencesRoute';
import BoardFolderBoxRoute from '../pages/adminconsole/board/folderbox/BoardFolderBoxRoute';
import BoardFormRoute from '../pages/adminconsole/board/form/BoardFormRoute';
import BoardAuthorityRoute from '../pages/adminconsole/board/authority/BoardAuthorityRoute';
import BoardTrashRoute from '../pages/adminconsole/board/trash/BoardTrashRoute';

function AdminConsoleLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 전사 관리함
  if (menu === 'folderbox')
    return (
      <BoardFolderBoxRoute key={hash} pathname={pathname} search={search} />
    );
  // 일괄권한 설정.
  if (menu === 'authority')
    return <BoardAuthorityRoute pathname={pathname} search={search} />;
  // 양식 관리
  if (menu === 'form') return <BoardFormRoute pathname={pathname} />;
  // 삭제함 관리
  if (menu === 'trash')
    return <BoardTrashRoute pathname={pathname} search={search} />;

  return <BoardPreferencesRoute pathname={pathname} />;
}

export default AdminConsoleLocateRoute;
