import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AppDispatchAbort,
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { documentActions } from '../../../../stores/approval/document';
import {
  approvalWorkActions,
  approvalWorkFolderActions,
} from '../../../../stores/approval/work';
import { preferencesActions } from '../../../../stores/approval/preferences';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { formActions } from '../../../../stores/approval/form';
import { formActions as attendanceFormActions } from '../../../../../groupware-attendance/stores/attendance/form';
import { formActions as systemLinkFormActions } from '../../../../../groupware-systemlink/stores/systemlink/form';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';

function ApprovalComposeRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${ApprovalComposeRoute.name}.render(props)`, props);

  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  const view = useSelector((s: RootState) => s.approval2.document.view.data);

  useEffect(() => {
    let promise: AppDispatchAbort | undefined;

    const route = { pathname, search, hash };
    const pathmap = '/approval/:folderId$b62/:id$b62';
    const queryParams = getQueryParams(search);
    const formId = queryParams.receiptFormId;

    // 작성 또는 임시보관 작성인 경우.
    if (queryParams.contentMode === 'create') {
      // 임시보관 작성인 경우.
      if (queryParams.contentType === 'temp') {
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);
        if (folderId !== undefined && id !== undefined) {
          Promise.all([
            dispatch(approvaluserPreferencesActions.fetchUserPreferences()),
            dispatch(preferencesActions.findDocumentNo()),
            dispatch(approvalWorkFolderActions.list()),
            dispatch(approvalWorkActions.clear('view')),
            dispatch(approvalWorkActions.list({})),
          ]).then(() => {
            dispatch(documentActions.view({ folderId, id, route }));
          });
        } else {
          // TODO 매개변수 오류 생성 로직 작성하여야 함.
        }
      }
      // 접수 후 내부 결재인 경우.
      else if (queryParams.contentType === 'receipt') {
        if (formId && formId !== 0) {
          if (view && view.linkId !== '' && view.linkType === 'ATTENDANCE') {
            dispatch(attendanceFormActions.view({ formId })).then(() => {
              dispatch(sessionActions.setRoute(route));
            });
          } else if (view && view.linkId !== '') {
            dispatch(systemLinkFormActions.view({ formId })).then(() => {
              dispatch(sessionActions.setRoute(route));
            });
          } else {
            dispatch(formActions.view({ formId })).then(() => {
              dispatch(sessionActions.setRoute(route));
            });
          }
        }
      }
      // 재기안인 경우.
      else if (queryParams.contentType === 'reDraft') {
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);
        if (folderId !== undefined && id !== undefined) {
          Promise.all([
            dispatch(approvaluserPreferencesActions.fetchUserPreferences()),
            dispatch(preferencesActions.findDocumentNo()),
          ]).then(() => {
            dispatch(documentActions.view({ folderId, id, route }));
          });
        }
      }
      // 작성인 경우.
      else {
        Promise.all([
          dispatch(approvaluserPreferencesActions.fetchUserPreferences()),
          dispatch(preferencesActions.findDocumentNo()),
          dispatch(approvalWorkActions.clear('view')),
          dispatch(approvalWorkFolderActions.list()),
        ]).then(() => {
          dispatch(approvalWorkActions.list({ route }));
        });
      }
    } else if (queryParams.contentMode === 'update') {
      const { folderId, id } = getPathParams<{
        folderId?: number;
        id?: number;
      }>(pathmap, pathname);
      if (folderId !== undefined && id !== undefined) {
        Promise.all([dispatch(preferencesActions.findDocumentNo())]).then(
          () => {
            dispatch(documentActions.view({ folderId, id, route }));
          },
        );
      } else {
        // TODO 매개변수 오류 생성 로직 작성하여야 함.
      }
    }

    return () => {
      if (promise !== undefined) promise.abort();
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default ApprovalComposeRoute;
