import React from 'react';
import { useSelector } from 'react-redux';
import { PortalTemplateDetail } from '../../../../stores/templates';
import { extractOptionsFromUrl } from '../../../../stores/common';
import ApprovalDocumentTemplate from './templates/ApprovalDocumentTemplate';
import { getEmployeeName } from '../../../../../groupware-directory/stores/directory';
import CalendarEventsContentTemplate from './templates/CalendarEventsContentTemplate';
import ResourceReservationItemTemplate from './templates/ResourceReservationItemTemplate';
import AttendanceLeaveTemplate from './templates/AttendanceLeaveTemplate';
import BoardPostTemplate from './templates/BoardPostTemplate';
import DocumentPostTemplate from './templates/DocumentPostTemplate';
import CommonProfileTemplate from './templates/CommonProfileTemplate';
import CommonNewEmployeesTemplate from './templates/CommonNewEmployeesTemplate';
import CommonBirthdayTemplate from './templates/CommonBirthdayTemplate';
import { TemplateKey } from '../../../../constants/templates';
import { RootState } from '../../../../../groupware-webapp/app/store';
import EmptyData from '../../../../../components/data/EmptyData';
import DashboardAccessDenied from '../common/DashboardAccessDenied';

function DashBoardContentItem(props: {
  template: PortalTemplateDetail;
  onRedirect?(redirectURL: string, responseType: TemplateKey): void;
}): JSX.Element {
  const companyId = useSelector(
    (state: RootState) => state.session.principal.companyId,
  );
  const { template } = props;

  const handleRedirect = (redirectURL: string, responseType: TemplateKey) => {
    if (props.onRedirect) props.onRedirect(redirectURL, responseType);
  };

  const renderBody = () => {
    const { item } = template;
    switch (item.responseType) {
      case 'APPROVAL-DOCUMENT': {
        const folderId = extractOptionsFromUrl(item.url, 'folders');
        if (item.data.length === 0) return <EmptyData />;
        return folderId ? (
          <ApprovalDocumentTemplate
            responseData={item}
            folderId={folderId}
            onRedirect={handleRedirect}
          />
        ) : (
          <></>
        );
      }
      case 'CALENDAR-EVENTS': {
        const { data } = item;
        const list = data.map((x) => {
          const name =
            x.isAttendanceCalendar && x.name.split('_').length === 2
              ? `[${x.name.split('_')[0]}] ${getEmployeeName(
                  companyId,
                  Number(x.name.split('_')[1]),
                )}`
              : x.name;
          return {
            ...x,
            name,
          };
        });
        return (
          <CalendarEventsContentTemplate
            list={list}
            id={template.id}
            search={template.search ?? ''}
          />
        );
      }
      case 'RESOURCE-RESERVATIONS': {
        if (item.data.length === 0) return <EmptyData />;
        return (
          <ResourceReservationItemTemplate
            responseData={item}
            onRedirect={handleRedirect}
          />
        );
      }
      case 'ATTENDANCE-LEAVE': {
        const { data } = item;
        return <AttendanceLeaveTemplate item={data} />;
      }
      case 'BOARD-POSTS': {
        const folderId = extractOptionsFromUrl(item.url, 'folders');
        if (item.data.length === 0) return <EmptyData />;
        if (!folderId) return <></>;
        return (
          <BoardPostTemplate
            responseData={item}
            options={template.options}
            folderId={folderId}
            onRedirect={handleRedirect}
          />
        );
      }
      case 'DOCUMENT-POSTS': {
        if (item.data.length === 0) return <EmptyData />;
        const folderId = extractOptionsFromUrl(item.url, 'folders');
        if (!folderId) return <></>;
        return (
          <DocumentPostTemplate
            responseData={item}
            folderId={folderId}
            onRedirect={handleRedirect}
          />
        );
      }
      case 'COMMON-PROFILE': {
        return <CommonProfileTemplate responseData={item} />;
      }
      case 'COMMON-NEW_EMPLOYEES': {
        if (item.data.length === 0) return <EmptyData />;
        return <CommonNewEmployeesTemplate responseData={item} />;
      }
      case 'COMMON-BIRTHDAY': {
        if (item.data.length === 0) return <EmptyData />;
        return <CommonBirthdayTemplate responseData={item} />;
      }
      case 'DEFAULT':
      default: {
        if (item.data === 'AUTHORITY_ERROR') {
          return <DashboardAccessDenied />;
        }
        return <></>;
      }
    }
  };

  return <div className="item-content">{renderBody()}</div>;
}

export default DashBoardContentItem;
