import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Search from '../../../../../components/search/Search';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ContactContentHeadList(props: {
  title: string;
  toolbarButtons: ActionEventProps[];
  pageNo: number;
  rowsPerPage: number;
  totalCount: number;
  checkedCount: number;
  searchWord?: string;
  onSearch(arg: { keyword: string }): void;
  onCheckedChange(itemId: 'all', checked: boolean): void;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
}): JSX.Element {
  const {
    title,
    searchWord = '',
    pageNo,
    rowsPerPage,
    totalCount,
    checkedCount,
    onCheckedChange,
    onAction,
  } = props;
  const display = useSelector((state: RootState) => state.session.display);
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <Search keyword={searchWord} onSearch={props.onSearch} />
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          {checkedCount === 0 ? (
            <>
              <EuiHeader.ToolbarLeft>
                <Button
                  className="check-all"
                  text={getLocalizedText('선택')}
                  iconType
                  icon="list-check"
                  onClick={() => onCheckedChange('all', true)}
                />
              </EuiHeader.ToolbarLeft>
              <EuiHeader.ToolbarRight>
                {totalCount > 0 && (
                  <Pagination
                    no={pageNo}
                    rows={rowsPerPage}
                    count={totalCount}
                    onPrev={(event) => onAction({ code: 'prev', event })}
                    onNext={(event) => onAction({ code: 'next', event })}
                  />
                )}
                {totalCount > 0 && display !== 'phone' && (
                  <Divider orientation="vertical" />
                )}
                {display !== 'phone' && (
                  <Button
                    text={getLocalizedText('목록설정')}
                    iconType
                    icon="bar-cog"
                    onClick={(event) =>
                      onAction({ code: 'listSetting', event })
                    }
                  />
                )}
              </EuiHeader.ToolbarRight>
            </>
          ) : (
            <EuiHeader.ToolbarLeft>
              <Button
                className="action-close"
                text={getLocalizedText('취소')}
                iconType
                icon="close"
                onClick={() => onCheckedChange('all', false)}
                vibrate
              />
              <span className="selected-count">
                <em>{checkedCount}</em>{' '}
                <span>{getLocalizedText('개 선택됨')}</span>
              </span>
              <ToolbarAction event={props.toolbarButtons} onClick={onAction} />
            </EuiHeader.ToolbarLeft>
          )}
        </EuiHeader.Toolbar>
      </EuiHeader>
    </>
  );
}

export default ContactContentHeadList;
