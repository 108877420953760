import React from 'react';
import Icon from '../icon/Icon';
import { IconType } from '../../groupware-common/types/icon';
import Avatar from '../avatar/Avatar';
import Checkbox from '../checkbox/Checkbox';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import LinkMenu from '../linkmenu/LinkMenu';

function MenuItem(props: {
  label: React.ReactNode | string;
  value?: React.ReactNode | string;
  icon?: IconType;
  width?: string;
  color?: string;
  avatar?: string;
  imagePath?: string;
  flag?: string;
  className?: string;
  haspopup?: boolean;
  checked?: boolean;
  selected?: boolean;
  expanded?: boolean;
  itemDisabled?: boolean;
  disabled?: boolean;
  count?: number;
  childrenCount?: number;
  id?: number | string;
  useCheck?: boolean;
  itemCheck?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
  onCheck?(
    id: string | number,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void;
  onDoubleClick?(event: React.MouseEvent): void;
  onToggleExpanded?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  children?: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-nav-item';
  if (props.className) classname += ` ${props.className}`;
  if (props.haspopup) classname += ' haspopup';
  if (props.selected) classname += ' selected';
  if (props.checked !== undefined) classname += ' check-option';
  if (props.checked) classname += ' checked';

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onClick !== undefined) props.onClick(event);
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onCheck !== undefined) props.onCheck(props.id ?? 0, event);
  };

  let style: React.CSSProperties | undefined;
  if (props.width) style = { width: props.width };
  if (props.itemDisabled)
    style = {
      ...style,
      color: 'var(--disabled-text-color)',
      cursor: 'default',
    };
  return (
    <a
      style={style}
      className={classname}
      onClick={props.itemDisabled ? undefined : handleClick}
      onDoubleClick={props.onDoubleClick}
      aria-disabled={props.disabled}
    >
      {/* 펼치기 접기 토글 */}
      {props.onToggleExpanded &&
        props.childrenCount !== undefined &&
        props.childrenCount > 0 && (
          <button
            type="button"
            style={props.itemDisabled ? { color: 'initial' } : undefined}
            className="nav-toggle"
            onClick={props.onToggleExpanded}
            aria-expanded={props.expanded}
          >
            <span>
              {props.expanded
                ? getLocalizedText('메뉴 접기')
                : getLocalizedText('메뉴 펼치기')}
            </span>
          </button>
        )}
      {/* 국기 */}
      {props.flag && <i className="nav-flag" data-country-flag={props.flag} />}
      {/** 체크 박스 */}
      {props.useCheck === true && props.onCheck && (
        <Checkbox
          checked={props.itemCheck}
          onChange={handleCheck}
          className="invisible"
        />
      )}
      {/* 아이콘 */}
      {props.icon && (
        <Icon className="nav-badge" icon={props.icon} color={props.color} />
      )}
      {/* 아바타 */}
      {props.avatar !== undefined && (
        <Avatar
          name={getLocalizedText('이름')}
          image={props.avatar}
          className="nav-avatar"
        />
      )}
      {/* 이미지 */}
      {props.imagePath !== undefined && (
        <LinkMenu imagePath={props.imagePath} />
      )}
      {/* 라벨 */}
      <span className="nav-label">{props.label}</span>
      {/* 값 */}
      {props.value && <strong className="nav-value">{props.value}</strong>}
      {/* children */}
      {props.children}
      {/* 카운트 */}
      {props.count !== undefined && props.count !== 0 && (
        <em className="nav-count">{props.count > 99 ? '+99' : props.count}</em>
      )}
    </a>
  );
}

export default MenuItem;
