import React from 'react';
import { IconType } from '../../../../../groupware-common/types/icon';
import Button from '../../../../../components/button/Button';
import Icon from '../../../../../components/icon/Icon';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type Props = {
  title: string;
  icon: IconType;
  className?: string;
  children: React.ReactNode;
  onMore?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
};

function ApprovalDashboardItem(props: Props): JSX.Element {
  let classname = 'dashboard-item';
  if (props.className) classname += ` ${props.className}`;

  return (
    <div className={classname}>
      <div className="item-head">
        <div className="title">
          {props.icon && <Icon icon={props.icon} />}
          {props.title}
        </div>
        {props.onMore && (
          <Button
            className="more"
            text={getLocalizedText('더보기')}
            iconType
            icon="plus-small"
            color="secondary"
            onClick={props.onMore}
          />
        )}
      </div>
      <div className="item-content">{props.children}</div>
    </div>
  );
}

export default ApprovalDashboardItem;
