import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { preferencesActions } from '../../groupware-approval/stores/approval/preferences';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import AttendanceComposeRoute from '../pages/root/attendance/compose/AttendanceComposeRoute';
import AttendanceContentRoute from '../pages/root/attendance/content/AttendanceContentRoute';
import AttendanceDashboardRoute from '../pages/root/attendance/dashboard/AttendanceDashboardRoute';
import AttendanceDayOffStatusRoute from '../pages/root/attendance/dayOffStatus/AttendanceDayOffStatusRoute';
import AttendanceNoticeRoute from '../pages/root/attendance/notice/AttendanceNoticeRoute';
import AttendanceOrganizationStatusRoute from '../pages/root/attendance/organizationStatus/AttendanceOrganizationStatusRoute';
import AttendanceSubstituteRoute from '../pages/root/attendance/substitute/AttendanceSubstitueRoute';
import { attendancePreferencesActions } from '../stores/attendance/preferences';
import AttendanceTeamsNoticeRoute from '../pages/root/attendance/notice/view/AttendanceTeamsNoticeRoute';

function AttendanceLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey: key } = props;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const { contentMode } = getQueryParams(search);

  const resource = useSelector((state: RootState) => state.session.resource);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    if (mount) {
      dispatch(attendancePreferencesActions.attendanceBasic());
      dispatch(preferencesActions.findApprovalType());
      dispatch(attendancePreferencesActions.defaultWorktime()); // 근태 기본 근무 시간 조회
    }

    return () => {
      mount = false;
    };
  }, [dispatch]);

  if (contentMode === 'create') {
    return (
      <AttendanceComposeRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }

  // 대시보드
  if (p1 === undefined && p2 === undefined)
    return (
      <AttendanceDashboardRoute
        pathname={pathname}
        search={search}
        hash=""
        key={key}
      />
    );
  if (p1 === 'organizationDays' || p1 === 'organizationMonths')
    return (
      <AttendanceOrganizationStatusRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  if (p1 === 'notice')
    return (
      <AttendanceNoticeRoute pathname={pathname} search={search} key={key} />
    );
  if (p1 === 'dayOffStatus')
    return (
      <AttendanceDayOffStatusRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  if (p1 === 'substitute')
    return (
      <AttendanceSubstituteRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  // 팀즈 - 연차촉진알림 문서보기
  if (resource === 'teams' && p1 === 'alert')
    return (
      <AttendanceTeamsNoticeRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  return (
    <AttendanceContentRoute pathname={pathname} search={search} key={key} />
  );
}

export default AttendanceLocateRoute;
