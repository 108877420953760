import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Confirmation from '../../../../../components/alert/Confirmation';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import DirectorySearch, {
  OptionType,
  SearchDateProps,
} from '../../../../../components/search/DirectorySearch';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  dateFormat,
  timeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import dayOffStatusApi from '../../../../apis/attendance/v1/dayOffStatus';
import {
  AccountDayOffStatusListItem,
  statusActions,
} from '../../../../stores/attendance/dayOffStatus';
import AttendanceDayOffDialog from '../../../common/AttendanceDayOffDialog';

function AttendanceDayOffAccountContainer(props: {
  pathname: string;
  search: string;
  title: string;
}): JSX.Element {
  const { pathname, search, title } = props;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();
  const list = useSelector(
    (state: RootState) => state.attendance.dayOffStatus.list,
  );
  const items = list.items as AccountDayOffStatusListItem[];
  const { totalCount } = list;
  const { expressionUnit: unit } = useSelector(
    (state: RootState) => state.attendance.preferences.basic,
  );
  const currentYear = timezoneDate().getFullYear();

  const [state, setState] = useState<{
    excel?: { [key: string]: string }[]; // 엑셀 다운로드
    create: boolean; // 연차 생성 구분 값.
    accountYear: number;
  }>({
    create: false,
    accountYear: currentYear,
  });
  const [columns, setColumns] = useState<DataGridColDef[]>([
    {
      field: 'employeeNo',
      label: getLocalizedText('사번'),
      minWidth: 120,
    },
    {
      field: 'user',
      label: getLocalizedText('이름'),
      minWidth: 158,
    },
    {
      field: 'enteringDate',
      label: getLocalizedText('입사일자'),
      width: 118,
    },
    {
      field: 'years',
      label: getLocalizedText('근속연수'),
      align: 'center',
      width: 118,
    },
    {
      field: 'days',
      label: getLocalizedText('근속일수'),
      align: 'center',
      width: 100,
    },
    {
      field: 'revision',
      label: getLocalizedText('월차'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'occured',
      label: getLocalizedText('연차'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'total',
      label: getLocalizedText('총연차'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'extinction',
      label: getLocalizedText('소멸'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'transfer',
      label: getLocalizedText('이월'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'adjust',
      label: getLocalizedText('조정'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'use',
      label: getLocalizedText('사용'),
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'rest',
      label: getLocalizedText('잔여'),
      align: 'center',
      minWidth: 100,
    },
  ]);

  const rows: DataGridRowsProps<number> = items.map((a) => {
    const directoryData = getDirectoryData({
      ...directory,
      ...a,
    });
    const employeeNo =
      directory.employees.find(({ id }) => id === a.employeeId)?.no ?? ''; // 사번.
    const usedUse =
      a.usedLeavesCount !== getLocalizedText('0일') &&
      a.usedLeavesCount !== getLocalizedText('0분');
    return [
      { type: 'text' as const, value: employeeNo },
      {
        type: 'user' as const,
        avatar: directoryData.avatar ?? '',
        icon: directoryData.avatar === '' ? undefined : 'person',
        value: `${directoryData.employeeName} ${directoryData.jobPositionName}`,
        from: directoryData.organizationName,
      },
      { type: 'text' as const, value: a.enterDate },
      {
        type: 'text' as const,
        value: getLocalizedText('{{year}}년', {
          year: a.workingYears,
        }),
      },
      { type: 'text' as const, value: a.workingDays },
      { type: 'text' as const, value: a.revisionOccuredLeavesCount },
      { type: 'text' as const, value: a.occuredLeavesCount },
      { type: 'text' as const, value: a.totalOccuredLeavesCount },
      { type: 'text' as const, value: a.lapseLeavesCount },
      { type: 'text' as const, value: a.carryoverLeavesCount },
      { type: 'text' as const, value: a.modifiedLeavesCount },
      // eslint-disable-next-line prettier/prettier
      { type: 'subject' as const, use: usedUse, value: a.usedLeavesCount, id: a.employeeId },
      { type: 'text' as const, value: a.remainedLeavesCount },
    ];
  });

  const selectedYearOptions: OptionType[] = [];
  const getYear = currentYear - 4;
  for (let i = 0; i < 5; i += 1) {
    const value = getYear + i;
    selectedYearOptions.push({
      value: value.toString(),
      label: value.toString(),
      useType: 'allDirectory',
    });
  }
  selectedYearOptions.push({
    value: (currentYear + 1).toString(),
    label: (currentYear + 1).toString(),
    useType: 'allDirectory',
  });

  useEffect(() => {
    async function run() {
      const data = await dayOffStatusApi.accountYear(
        dateFormat(timezoneDate(), 'yyyy-MM-DD'),
      );
      setState((prev) => ({ ...prev, accountYear: data.accountYear }));
    }
    run();
  }, []);

  useEffect(() => {
    setState((prev) => ({ ...prev, excel: undefined }));
    async function run() {
      const totalList = await dayOffStatusApi.accountList({
        standardYear: queryParams.status ?? currentYear.toString(),
        searchCode: queryParams.searchCode,
        searchWord:
          queryParams.searchCode === ''
            ? queryParams.searchWord
            : queryParams.directoryKeyword,
        pageNo: 1,
        rowsPerPage: totalCount === 0 ? 15 : totalCount,
      });
      const excelData = totalList.map((a) => {
        const obj: {
          [key: string]: string;
        } = {};
        const directoryData = getDirectoryData({
          ...directory,
          ...a,
        });

        let employeeNo =
          directory.employees.find(({ id }) => id === a.employeeId)?.no ?? ''; // 사번.
        employeeNo = `=""${employeeNo}""`;
        columns
          .filter((x) => x.visible === undefined || x.visible)
          .forEach((x) => {
            if (x.field === 'employeeNo') obj[x.field] = employeeNo;
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'user') obj[x.field] = `${directoryData.organizationName}/${directoryData.employeeName} ${directoryData.jobPositionName}`;
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'enteringDate') obj[x.field] = `=""${a.enterDate}""`;
            if (x.field === 'years')
              obj[x.field] = getLocalizedText('{{year}}년', {
                year: a.workingYears,
              });
            if (x.field === 'days') obj[x.field] = `${a.workingDays}`;
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'revision') obj[x.field] = timeFormat(a.revisionOccuredLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'occured') obj[x.field] = timeFormat(a.occuredLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'total') obj[x.field] = timeFormat(a.revisionOccuredLeavesCount + a.occuredLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'extinction') obj[x.field] = timeFormat(a.lapseLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'transfer') obj[x.field] = timeFormat(a.carryoverLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'adjust') obj[x.field] = timeFormat(a.modifiedLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'use') obj[x.field] = timeFormat(a.usedLeavesCount, unit);
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'rest') obj[x.field] = timeFormat(a.remainedLeavesCount, unit);
          });
        return obj;
      });
      setState((prev) => ({ ...prev, excel: excelData }));
    }
    if (queryParams.pageNo === undefined || queryParams.pageNo === 1) run();
  }, [search]);

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    directoryFilter?: string;
    date?: SearchDateProps;
    status?: string;
  }) => {
    delete queryParams.pageNo;
    queryParams.status = arg.filter;
    queryParams.searchCode = arg.directoryFilter ?? '';
    queryParams.searchWord = arg.keyword;
    queryParams.directoryKeyword = arg.directoryKeyword;

    dispatch(
      statusActions.list({
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록 개수 변경. */
  const handleChangeRowLength = (value: number) => {
    delete queryParams.pageNo;
    queryParams.rowsPerPage = value;
    dispatch(
      statusActions.list({
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code, id } = arg;

    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = (queryParams.pageNo || 1) - 1;
      if (queryParams.pageNo > 0) {
        dispatch(
          statusActions.list({
            search: getQueryParams(queryParams),
            route: { pathname, search: getQueryParams(queryParams) },
          }),
        );
      }
    }
    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = (queryParams.pageNo || 1) + 1;
      dispatch(
        statusActions.list({
          search: getQueryParams(queryParams),
          route: { pathname, search: getQueryParams(queryParams) },
        }),
      );
    }

    /** 사용연차 클릭. */
    if (code === 'subject' && id) {
      queryParams.dialogType = 'detail';
      queryParams.id = id;
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }

    /** 연차생성 */
    if (code === 'createDayOff') {
      setState((prev) => ({ ...prev, create: true }));
    }
  };

  /** 연차 생성 대화상자 닫기. */
  const handleCreateCancel = () => {
    setState((prev) => ({ ...prev, create: false }));
  };

  /** 연차 생성 클릭. */
  const handleCreateSubmit = () => {
    const standardDate = dateFormat(timezoneDate(), 'yyyy-MM-DD');
    const createAt = dateFormat(new Date(), 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    delete queryParams.pageNo;
    delete queryParams.searchCode;
    delete queryParams.searchWord;
    delete queryParams.directoryKeyword;
    dispatch(
      statusActions.createAccountOccurs({
        search: getQueryParams(queryParams),
        standardDate,
        creatorId: principal.employeeId,
        createAt,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined)
        setState((prev) => ({ ...prev, create: false }));
    });
  };

  /** 목록 노출.  */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prev) =>
      prev.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    const { create } = state;

    if (dialogType === 'detail') {
      return (
        <AttendanceDayOffDialog
          type="account"
          search={search}
          onClose={handleDialogClose}
        />
      );
    }

    if (create) {
      return (
        <Confirmation
          noDuplication
          onCancel={handleCreateCancel}
          onSubmit={handleCreateSubmit}
        >
          <p>
            {getLocalizedText(
              '연차 생성 시 현재 데이터가 삭제되고 새로운 데이터가 생성 됩니다.',
            )}
          </p>
          <p>
            <strong>
              {getLocalizedText('{{year}}년도', {
                year: state.accountYear,
              })}
            </strong>
            {getLocalizedText('연차를 생성 하시겠습니까?')}
          </p>
        </Confirmation>
      );
    }
    // 오류.
    return null;
  };
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <DirectorySearch
            options={selectedYearOptions}
            filter={{
              value: queryParams.status ?? currentYear.toString(),
              useType: 'allDirectory',
            }}
            keyword={queryParams.searchWord}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          caption={title}
          columns={columns}
          rows={rows}
          pagination={{
            no: queryParams.pageNo || 1,
            total: totalCount,
            row: queryParams.rowsPerPage || 15,
            onChangeRow: handleChangeRowLength,
          }}
          export
          excelFileName={`${dateFormat(timezoneDate(), 'YYYY-MM-DD')} 연차현황`}
          excelData={state.excel}
          createDayOff={{
            code: 'createDayOff',
            label: getLocalizedText('연차생성'),
            type: 'contained',
            icon: 'plus-circle-dashed',
          }}
          onClick={handleClick}
          onChangeColumnVisible={handleChangeColumnVisible}
        />
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default AttendanceDayOffAccountContainer;
