import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../groupware-webapp/app/store';
import { getChildren } from '../../groupware-common/utils';
import Menu from '../../components/menu/Menu';
import SimpleSearch from '../../components/search/SimpleSearch';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../components/tree/DirectoryTree';
import Button from '../../components/button/Button';
import Divider from '../../components/divider/Divider';
import TreeList from '../../components/tree/TreeList';
import { IconType } from '../../groupware-common/types/icon';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

// <Menu><ChooseOrganizationChart /></Menu> 형식으로 작성되는 컴포넌트 기반으로 작성.

function DirectoryMenuTreeContainer(props: {
  deduplication?: boolean; // 중복사원 제거 여부.
  point?: { x: number; y: number; width?: number; height?: number };
  selectedId?: string;
  ignoreId?: string;
  organizationOnly?: boolean;
  typeToFilter?:
    | ('company' | 'organization' | 'employee')
    | ('company' | 'organization' | 'employee')[];
  typeOption?: ('directory' | 'group') | ('directory' | 'group')[];
  groupItems?: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
    strings: string[][];
  }[];
  onItemClick(arg: DirectoryTreeItemArg): void;
  onGroupItemClick?(id: number): void;
  onClose(): void;
}): JSX.Element | null {
  const { deduplication, organizationOnly } = props;
  const typeOption = props.typeOption ?? 'directory';

  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector((state: RootState) =>
    organizationOnly ? [] : state.directory.organization.employees.data.items,
  );
  const employees = useSelector((state: RootState) =>
    organizationOnly ? [] : state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector((state: RootState) =>
    organizationOnly ? undefined : state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector((state: RootState) =>
    organizationOnly ? [] : state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector((state: RootState) =>
    organizationOnly ? [] : state.directory.jobDuty.list.data.items,
  );

  const items = useMemo(() => {
    const result = getDirectoryTreeItems({
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    });
    const { ignoreId } = props;
    if (ignoreId !== undefined) {
      const ignoreItems = getChildren(result, ignoreId);
      // console.log('ignoreItems', ignoreItems);
      return result.filter(
        (a) => ignoreItems.find((b) => a.id === b.id) === undefined,
      );
    }
    return result;
  }, [
    companies,
    organizations,
    organizationEmployees,
    employees,
    jobClassType,
    jobPositions,
    jobDuties,
  ]);

  const [filter, setFilter] = useState('');
  const [options, setOption] = useState<'directory' | 'group'>('directory');

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  /** 조직도 메뉴 옵션 변경 */
  const handleChangeOption = (arg: 'directory' | 'group') => {
    setOption(arg);
  };

  /** 그룹 아이템 선택 */
  const handleGroupClick = (id: number) => {
    if (props.onGroupItemClick) {
      props.onGroupItemClick(id);
    }
  };

  const { point, selectedId, typeToFilter, onItemClick, onClose } = props;

  const renderBody = () => {
    if (options === 'group' && props.groupItems) {
      return (
        <TreeList
          filter={filter}
          items={props.groupItems}
          onItemClick={handleGroupClick}
        />
      );
    }

    return (
      <DirectoryTree
        deduplication={deduplication}
        selectedId={selectedId}
        items={items}
        filter={filter}
        typeToFilter={typeToFilter}
        onItemClick={onItemClick}
      />
    );
  };

  if (point === undefined) return null;
  return (
    <Menu point={point} onClose={onClose}>
      <div className="ui-organization-select">
        <div className="head-panel">
          <SimpleSearch keyword={filter} onSearch={handleFilter} />
        </div>
        <div className="body-panel">{renderBody()}</div>
        {Array.isArray(typeOption) &&
          typeOption.length > 1 &&
          props.groupItems && (
            <>
              <Divider className="sm" />
              <div className="footer-pannel" style={{ marginTop: '4px' }}>
                <Button
                  text={getLocalizedText('조직도')}
                  color={options === 'directory' ? undefined : 'secondary'}
                  size="sm"
                  onClick={() => handleChangeOption('directory')}
                  icon="sitemap"
                  iconStyle={{ fontSize: '20px' }}
                  iconType
                />
                <Button
                  text={getLocalizedText('사용자 그룹')}
                  color={options === 'group' ? undefined : 'secondary'}
                  size="sm"
                  onClick={() => handleChangeOption('group')}
                  icon="user-friends"
                  iconStyle={{ fontSize: '20px' }}
                  iconType
                />
              </div>
            </>
          )}
      </div>
    </Menu>
  );
}

export default DirectoryMenuTreeContainer;
