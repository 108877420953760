import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import UserInfo from '../../../../../components/user/UserInfo';
import PostSubject from '../../../../../components/post/PostSubject';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import Chip from '../../../../../components/chip/Chip';
import { RootState } from '../../../../../groupware-webapp/app/store';

type User = {
  name: string;
  organization?: string;
  job?: string;
  avatar?: string;
};

type Props = {
  id?: string;
  selected?: boolean;
  columns?: Column<PostListItemType>[];
  item?: Record<string, User | boolean | string | number | undefined>;
  onCheckedChange?(itemId: string, checked: boolean): void;
};

function SecuritiesContentBodyListItem(dumy: Props) {
  const display = useSelector((state: RootState) => state.session.display);
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || '',
    selected: dumy.selected || false,
    columns: dumy.columns || ([] as Column<PostListItemType>[]),
    item:
      dumy.item ||
      ({
        checked: false,
        decisGrwNo: '',
        decisCont: '',
        decisDy: '',
        decisEmpNm: '',
        decisAmt: '',
        pttnDy: '',
        enoDeptCd: '',
        status: 1,
      } as Record<string, User | boolean | string | number | undefined>),
    onCheckedChange: dumy.onCheckedChange || emptyFunction,
  };

  const handleCheckedChange = () => {
    props.onCheckedChange(props.id, !props.item.checked);
  };

  const { selected, columns, item } = props;

  return (
    <PostList.Item selected={selected} onClick={handleCheckedChange}>
      {columns.map(({ name, text, type }) => {
        const value = item[name];
        let children: React.ReactNode | null = null;
        if (name === 'checked' && typeof value === 'boolean') {
          children = (
            <Checkbox checked={value} onChange={handleCheckedChange} />
          );
        } else if (type === 'post') {
          const subject = value as string;
          children = <PostSubject title={subject} />;
        } else if (type === 'user') {
          const { name: username, organization } = value as User;
          children = <UserInfo name={username} from={organization} />;
        } else if (typeof value === 'string' || typeof value === 'number') {
          children = <PostList.Value value={value} />;
          if (name === 'status') {
            children = (
              <Chip
                className="cell-tag"
                label="미작성"
                theme="primary"
                size="xs"
              />
            );
          } else if (type === 'date' && typeof value === 'string') {
            children =
              display === 'phone' ? (
                <span>
                  <PostList.Value value={`${text}`} />
                  <span style={{ fontWeight: 500, paddingLeft: '20px' }}>
                    {value}
                  </span>
                </span>
              ) : (
                <PostList.Value value={value} />
              );
          } else if (
            (name === 'decisAmt' || name === 'decisDy' || name === 'pttnDy') &&
            display === 'phone'
          ) {
            children = (
              <span>
                <PostList.Value value={`${text}`} />
                <span style={{ fontWeight: 500, paddingLeft: '20px' }}>
                  {value}
                </span>
              </span>
            );
          }
        }
        let justifyContent = 'center';
        if (name === 'decisAmt') justifyContent = 'right';
        if (name === 'decisCont') justifyContent = 'left';
        const style = { justifyContent };
        return (
          <PostList.Cell key={name} type={type} style={style}>
            {children}
          </PostList.Cell>
        );
      })}
    </PostList.Item>
  );
}

export default React.memo(SecuritiesContentBodyListItem);
