import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../../../components/alert/Alert';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import informationApi from '../../../../apis/setting/v1/information';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function SettingInformationPasswordChangeDialog(props: {
  onClose(): void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const { useRequiredPasswordSpecialChar } = useSelector(
    (state: RootState) => state.authentication.login.data,
  );

  const [state, setState] = useState<{
    originPW: string;
    newPW: string;
    newPWConfirm: string;
    password: boolean;
  }>({
    originPW: '',
    newPW: '',
    newPWConfirm: '',
    password: false,
  });
  const [validation, setValidation] = useState<{
    originPW: string;
    newPW: string;
    newPWConfirm: string;
  }>({
    originPW: '',
    newPW: '',
    newPWConfirm: '',
  });

  const typeMessage = useRequiredPasswordSpecialChar
    ? `${getLocalizedText('영문, 숫자, 특수문자(@, $, !, %, *, ?, &, #)')}`
    : getLocalizedText('영문, 숫자');

  useEffect(() => {
    async function changePassword() {
      const { originPW: currentPassword, newPW: newPassword } = state;
      await informationApi
        .changePassword({
          currentPassword,
          newPassword,
        })
        .then((result) => {
          const initialPW = {
            originPW: '',
            newPW: '',
            newPWConfirm: '',
          };
          const status = result.status as number | undefined;
          if (status === 200) {
            setValidation(initialPW);
          } else if (status === 400) {
            setValidation((prev) => ({
              ...prev,
              newPW: getLocalizedText(
                '비밀번호는 {{msg}}를 필수로 조합하여 8~16자리로 설정해야 합니다.',
                { msg: typeMessage },
              ),
            }));
          } else if (status === 401) {
            setValidation((prev) => ({
              ...prev,
              originPW: getLocalizedText('현재 비밀번호가 일치하지 않습니다.'),
            }));
          } else {
            setValidation(initialPW);
            dispatch(
              sessionActions.error(
                appError({
                  message: getLocalizedText('status.INTERNAL_SERVER_ERROR', {
                    ns: 'error',
                  }),
                }),
              ),
            );
          }
          if (status === 200) props.onClose();
          else setState((prev) => ({ ...prev, password: false }));
        });
    }

    if (state.password) changePassword();
  }, [state.password]);

  /** 비밀번호 변경하기. */
  const handleChangePassWord = () => {
    const newValidation = { ...validation };
    if (state.originPW === '' || state.originPW.trim() === '')
      newValidation.originPW = getLocalizedText(
        '현재 비밀번호는 빈 값일 수 없습니다.',
      );

    if (state.newPW === '' || state.newPW.trim() === '')
      newValidation.newPW = getLocalizedText(
        '새 비밀번호는 빈 값일 수 없습니다.',
      );
    else if (
      state.newPWConfirm.trim() === '' ||
      (state.newPWConfirm !== '' && state.newPW !== state.newPWConfirm)
    )
      newValidation.newPWConfirm =
        getLocalizedText('새 비밀번호와 일치하지 않습니다.');

    if (
      newValidation.originPW !== '' ||
      newValidation.newPW !== '' ||
      newValidation.newPWConfirm !== ''
    ) {
      setValidation(newValidation);
      return;
    }

    setState((prev) => ({ ...prev, password: true }));
  };

  /** 비밀번호 필드에서 Enter 누름 */
  const handlePWKeyDonw = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') handleChangePassWord();
  };

  /** 현재 비밀번호 변경 이벤트. */
  const handleChangeOriginPW = (event: React.ChangeEvent<HTMLInputElement>) => {
    const originPW = event.target.value;
    setState((prev) => ({
      ...prev,
      originPW,
    }));
    setValidation((prev) => ({ ...prev, originPW: '' }));
  };

  /** 새 비밀번호 변경 이벤트. */
  const handleChangeNewPW = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPW = event.target.value;
    setState((prev) => ({
      ...prev,
      newPW,
    }));
    setValidation((prev) => ({ ...prev, newPW: '' }));
  };

  /** 새 비밀번호 입력 시 유효상 검사 이벤트. */
  const handleBlurNewPW = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const passwordLength = /^.{8,16}$/;
    const newPW = event.target.value;
    const newValidation = {
      originPW: validation.originPW,
      newPW: '',
      newPWConfirm: '',
    };

    const denyPattern = useRequiredPasswordSpecialChar
      ? /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&#])[a-zA-Z\d@$!%*?&#]+$/g
      : /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+[\]{};:'",.<>\\/?\\|`~]+$/g;

    if (newPW.trim() === '') {
      setValidation(newValidation);
      return;
    }

    if (!denyPattern.test(newPW))
      newValidation.newPW = getLocalizedText(
        '비밀번호는 {{msg}}를 필수로 사용해야 합니다.',
        { msg: typeMessage },
      );
    else if (!passwordLength.test(newPW))
      newValidation.newPW = getLocalizedText(
        '비밀번호는 8자리 이상 16자리 이하여야 합니다.',
      );
    if (
      newValidation.newPW === '' &&
      state.newPWConfirm.trim() !== '' &&
      state.newPWConfirm !== newPW
    )
      newValidation.newPWConfirm =
        getLocalizedText('새 비밀번호와 일치하지 않습니다.');
    setValidation(newValidation);
  };

  /** 새 비밀번호 확인 변경 이벤트. */
  const handleChangeNewPWConfirm = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPWConfirm = event.target.value;
    setState((prev) => ({
      ...prev,
      newPWConfirm,
    }));
    setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
  };

  /** 새 비밀번호 확인 입력 시 유효상 검사 이벤트. */
  const handleBlurNewPWConfirm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPWConfirm = event.target.value;
    if (
      newPWConfirm.trim() === '' ||
      state.newPW.trim() === '' ||
      validation.newPW.trim() !== ''
    ) {
      setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
      return;
    }
    if (state.newPW.trim() !== newPWConfirm.trim())
      setValidation((prev) => ({
        ...prev,
        newPWConfirm: getLocalizedText('새 비밀번호와 일치하지 않습니다.'),
      }));
    else setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
  };

  const handleCloseDialog = () => {
    props.onClose();
  };

  const { originPW, newPW, newPWConfirm } = state;
  return (
    <Dialog size="xs" onClose={handleCloseDialog}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('비밀번호 변경')}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <div style={{ padding: '12px 24px' }}>
            <Alert severity="info" size="sm">
              {getLocalizedText(
                '비밀번호는 {{msg}}를 필수로 조합하여 8~16자리로 설정해야 합니다.',
                {
                  msg: typeMessage,
                },
              )}
            </Alert>
          </div>
          <PostWrite.Item title={getLocalizedText('현재 비밀번호')}>
            <TextField
              type="password"
              value={originPW}
              error={validation.originPW !== ''}
              onKeyDown={handlePWKeyDonw}
              onChange={handleChangeOriginPW}
            />
            {validation.originPW !== '' && (
              <HelperText>{validation.originPW}</HelperText>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('새 비밀번호')}>
            <TextField
              type="password"
              value={newPW}
              error={validation.newPW !== ''}
              onKeyDown={handlePWKeyDonw}
              onChange={handleChangeNewPW}
              onBlur={handleBlurNewPW}
            />
            {validation.newPW !== '' && (
              <HelperText>{validation.newPW}</HelperText>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('새 비밀번호 확인')}>
            <TextField
              type="password"
              value={newPWConfirm}
              error={validation.newPWConfirm !== ''}
              onKeyDown={handlePWKeyDonw}
              onChange={handleChangeNewPWConfirm}
              onBlur={handleBlurNewPWConfirm}
            />
            {validation.newPWConfirm !== '' && (
              <HelperText>{validation.newPWConfirm}</HelperText>
            )}
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          block
          noDuplication={state.password}
          variant="contained"
          text={getLocalizedText('변경하기')}
          onClick={handleChangePassWord}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default SettingInformationPasswordChangeDialog;
