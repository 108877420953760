import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuItem from '../../../../../components/menu/MenuItem';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  getPathMap,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import Nav from '../../../../../components/menu/Nav';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import { getOrganizationName } from '../../../../../groupware-directory/stores/directory';

function AttendanceDrawer(props: {
  folderId: string | undefined;
  pathname: string;
}) {
  // console.log(`${ApprovalDrawer.name}.render`);

  const dispatch = useAppDispatch();

  const tenantId = useSelector((state: RootState) => state.session.tenantId);
  const display = useSelector((state: RootState) => state.session.display);
  const resource = useSelector((state: RootState) => state.session.resource);

  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  );

  /** 현재 로그인한 사용자 회사, 사원, 부서 키 */
  const principal = useSelector((state: RootState) => state.session.principal);

  /** 소속 조직 항목 배열. */
  const affiliatedOrganizationItems = principal.affiliatedOrganizations;

  /** TODO 기록물철 관리자, 회사관리자 전체 트리 구조로 수정해야됨 */
  const treeList = affiliatedOrganizationItems.map((a) => {
    const { id } = a;
    const name = getOrganizationName(principal.companyId, id);
    const icon = 'folder' as IconType;
    const parentId = 0;

    return {
      id,
      parentId,
      text: name,
      icon,
    };
  });
  // 고정 폴더 이름
  const getDefaultFolderName = (id: string) => {
    const category = categories
      .filter((a) => a.type === 'default')
      .find((a) => a.id === id);
    if (!category) return undefined;
    return getLocalizedText(`폴더.${category.name}`);
  };

  const handleGo = (folderId: string | number | 'create' | undefined) => {
    // console.log(`handleGo(folderId: ${folderId})`);
    const { pathname } = props;
    if (folderId === 'create') {
      const queryParams = getQueryParams('');
      queryParams.contentMode = 'create';
      go(pathname, queryParams);
    } else if (folderId === undefined) {
      go(`${getPathMap('/*', pathname)}`);
    } else go(`${getPathMap('/*', pathname)}/${folderId}`);
    dispatch(sessionActions.mobileNav(false));
  };

  const [state, setState] = useState<{
    selectOrganizationVisible: boolean;
  }>({
    selectOrganizationVisible: false,
  });

  const handleCloseSelectOrganization = () => {
    setState((prevState) => ({
      ...prevState,
      selectOrganizationVisible: false,
    }));
  };

  const handleClickSelectedOrganization = () =>
    // text: string,
    // path: string[],
    {
      // console.log(id, text, path);
      handleCloseSelectOrganization();
    };

  const getDialog = () => {
    if (state.selectOrganizationVisible)
      return (
        <TreePicker
          title={getLocalizedText('부서 선택')}
          list={treeList}
          onSelected={handleClickSelectedOrganization}
          onClose={handleCloseSelectOrganization}
        />
      );
    return null;
  };

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title={getLocalizedText('모듈.근태')} />
        <EuiSnb.Action>
          <Button
            text={getLocalizedText('근태등록')}
            variant="contained"
            block
            onClick={() => handleGo('create')}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <>
          <Nav>
            <MenuItem
              label={getDefaultFolderName('main')}
              icon="calendar-week"
              selected={props.folderId === undefined}
              onClick={() => handleGo(undefined)}
              count={0}
            />
          </Nav>
          <Nav title={getLocalizedText('근태관리')} expanded>
            {categories
              .filter((x) => x.type === 'status')
              .map((x) => (
                <MenuItem
                  key={x.id}
                  label={getLocalizedText(`폴더.${x.name}`)}
                  icon={x.icon}
                  selected={x.id === props.folderId}
                  onClick={() => handleGo(x.id)}
                  count={x.count}
                />
              ))}
          </Nav>
          {affiliatedOrganizationItems.some((a) => a.manager === true) && (
            <Nav title={getLocalizedText('부서별근태현황')} expanded>
              {categories
                .filter((x) => x.type === 'organization')
                .map((x) => (
                  <MenuItem
                    key={x.id}
                    label={getLocalizedText(`폴더.${x.name}`)}
                    icon={x.icon}
                    selected={x.id === props.folderId}
                    onClick={() => handleGo(x.id)}
                    count={x.count}
                  />
                ))}
            </Nav>
          )}
          {resource === 'teams' &&
            principal.roles.find(
              (role) => role === 'ADMIN' || role === 'ATTENDANCE_ADMIN',
            ) && (
              <Nav>
                <MenuDivider />
                <MenuItem
                  label={getLocalizedText('근태설정')}
                  icon="user-cog"
                  onClick={() => go('/adminconsole/attendance')}
                />
              </Nav>
            )}
        </>
      </EuiSnb.Nav>
      {getDialog()}
    </>
  );
}

export default React.memo(AttendanceDrawer);
