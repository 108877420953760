import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import ApprovalFormFolder from './ApprovalFormFolder';
import ApprovalFormList from './ApprovalFormList';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ApprovalFormFolderEdit from './ApprovalFormFolderEdit';
import ApprovalFormEdit from './ApprovalFormEdit';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import {
  formActions,
  formFolderActions,
} from '../../../../stores/approval/form';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../../../components/alert/FeedBack';

function ApprovalFormContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathmap = getPathMap('/*/*/*', props.pathname);
  const pathParams = getPathParams(`/*/*/*/:p1/:p2`, props.pathname);
  const propsFolderId = b62(pathParams.p1);
  const propsFormId = b62(pathParams.p2);
  const queryParams = getQueryParams(props.search);

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [queryParams.pageNo, queryParams.rowsPerPage, queryParams.searchWord]);

  const dispatch = useAppDispatch();
  const folderList = useSelector(
    (state: RootState) => state.approval2.form.folder.list.data.items,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6008)?.name;

  const folderItems = folderList
    .map(({ id, parentId, seq, name: text, updateAt }) => {
      return {
        id,
        parentId,
        seq,
        text,
        strings: hangul.d(text),
        icon: 'folder' as const,
        updateAt,
      };
    })
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  const formList = useSelector(
    (state: RootState) => state.approval2.form.items,
  );
  const totalCount = useSelector(
    (state: RootState) => state.approval2.form.totalCount,
  );

  const formItems = formList.map((a) => {
    return {
      checked: a.checked,
      id: a.id,
      category: folderList.find((b) => b.id === a.folderId)?.name ?? '',
      subject: a.name,
      createAt: a.createAt,
      state: a.status === 1,
    };
  });

  const [validation, setValidation] = useState('');
  const { pageNo = 1, rowsPerPage = 15 } = queryParams;

  const handleSelectedFolder = (id: number) => {
    dispatch(
      formActions.list({
        folderId: id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search: createQueryString({ pageNo: 1, rowsPerPage }),
        },
      }),
    );
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleSnackbarClose = () => {
    setValidation('');
  };

  /** 폴더 생성. */
  const handleFolderCreate = (arg: { parentId: number; name: string }) => {
    // console.log(`handleFolderCreate(arg)`, arg);
    const { parentId, name } = arg;
    const route = { pathname: `${pathmap}/{response_id}` };
    dispatch(formFolderActions.create({ parentId, name, route }));
  };

  /** 폴더 수정. */
  const handleFolderUpdate = (arg: {
    id: number;
    parentId: number;
    name: string;
    updateAt: string;
  }): void => {
    // console.log(`handleFolderUpdate(arg)`, arg);
    const params = { ...queryParams };
    delete params.drawerType;
    delete params.drawerMode;
    const route = { pathname: props.pathname, search: getQueryParams(params) };
    dispatch(formFolderActions.update({ ...arg, route }));
  };

  /** 폴더 삭제. */
  const handleDeleteFolder = () => {
    // console.log(`handleDeleteFolder()`);

    const folder = folderItems.find(({ id }) => id === propsFolderId);
    if (!folder) return;

    const { id, updateAt } = folder;
    const route = { pathname: pathmap };
    dispatch(formFolderActions.delete({ id, updateAt, route }));
  };

  /** 폴더 순서 변경. */
  const handleChangeFolderOrder = (aList: { id: number }[]) => {
    // console.log(`handleChangeFolderOrder(aList)`, aList);

    const data = aList.map((a, i) => {
      const updateAt = folderItems.find((b) => b.id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: i + 1,
        updateAt,
      };
    });

    const params = { ...queryParams };
    delete params.drawerMode;
    delete params.drawerType;
    const route = {
      pathname: props.pathname,
      search: createQueryString(params),
    };

    dispatch(formFolderActions.updateSeq({ data, route }));
  };

  /** 양식 저장. */
  const handleFormSave = (
    arg:
      | {
          id?: undefined;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
        }
      | {
          id: number;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
          updateAt: string;
        },
  ) => {
    const { folderId } = arg;

    const route = {
      pathname: `${pathmap}/${b62(folderId)}`,
      search: createQueryString({ pageNo: 1, rowsPerPage: 15 }),
    };

    if (arg.id === undefined) dispatch(formActions.create({ ...arg, route }));
    else dispatch(formActions.update({ ...arg, route }));
  };

  /** 양식 폴더 변경. */
  const handleChangeFormFolder = (folderId: number) => {
    // console.log(`handleChangeFormFolder(folderId)`, folderId);

    if (propsFolderId === folderId) {
      setValidation(getLocalizedText('원본 폴더와 대상 폴더가 동일합니다.'));
      return;
    }

    const data = formList
      .filter(({ checked }) => checked)
      .map(({ id, updateAt }) => ({ id, folderId, updateAt }));
    // console.log(`data:`, data);
    if (!data) return;

    const params = { ...queryParams };
    delete params.pageNo;
    delete params.dialogMode;
    delete params.dialogType;
    const route = {
      pathname: `${pathmap}/${b62(folderId)}`,
      search: createQueryString(params),
    };

    dispatch(formActions.changeFolder({ data, route }));
  };

  /** 드로워 렌터링 */
  const renderDrawer = () => {
    const { drawerType, drawerMode } = queryParams;

    if (drawerType === 'folder' && drawerMode === 'create') {
      return (
        <ApprovalFormFolderEdit
          folders={folderItems}
          parentId={propsFolderId}
          onSave={handleFolderCreate}
          onClose={handleCloseDrawer}
        />
      );
    }
    if (drawerType === 'folder' && drawerMode === 'update') {
      const folder = folderItems.find(({ id }) => id === propsFolderId);
      if (folder === undefined) return null;
      return (
        <ApprovalFormFolderEdit
          folders={folderItems}
          onSave={handleFolderUpdate}
          onClose={handleCloseDrawer}
          id={folder.id}
          parentId={folder.parentId}
          name={folder.text}
          updateAt={folder.updateAt}
        />
      );
    }
    if (drawerType === 'orderFolder') {
      const folder = folderItems.find(({ id }) => id === propsFolderId);
      if (!folder) return null;

      const items = folderItems
        .filter(({ parentId }) => parentId === folder.parentId)
        .map(({ id, text: label }) => ({ id, label }));

      return (
        <ChangeOrder
          title={getLocalizedText('순서변경')}
          list={items}
          onChange={handleChangeFolderOrder}
          onClose={handleCloseDrawer}
        />
      );
    }

    return null;
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    const { dialogType, dialogMode } = queryParams;

    if (dialogType === 'folder' && dialogMode === 'delete') {
      const folder = folderItems.find((x) => x.id === propsFolderId);
      if (!folder) return null;
      return (
        <DeleteConfirmation
          onSubmit={handleDeleteFolder}
          onCancel={handleCloseDialog}
        >
          <strong>&apos;{folder.text}&apos;</strong>{' '}
          {getLocalizedText('분류를 정말 삭제하시겠습니까?')}
        </DeleteConfirmation>
      );
    }

    if (dialogType === 'form' && dialogMode === 'create') {
      return (
        <ApprovalFormEdit
          folders={folderItems}
          folderId={propsFolderId}
          onClose={handleCloseDialog}
          onSave={handleFormSave}
        />
      );
    }
    if (
      dialogType === 'form' &&
      dialogMode === 'update' &&
      propsFolderId !== undefined &&
      propsFormId !== undefined
    ) {
      return (
        <ApprovalFormEdit
          folders={folderItems}
          folderId={propsFolderId}
          id={propsFormId}
          onSave={handleFormSave}
          onClose={() => {
            const params = { ...queryParams };
            delete params.dialogMode;
            delete params.dialogType;
            dispatch(
              sessionActions.setRoute({
                pathname: `${pathmap}/${b62(propsFolderId)}`,
                search: createQueryString(params),
              }),
            );
          }}
        />
      );
    }
    if (dialogType === 'form' && dialogMode === 'delete') {
      const array = formList.filter(({ checked }) => checked);
      if (array.length === 0) return null;

      return (
        <DeleteConfirmation
          onSubmit={handleDeleteForm}
          onCancel={handleCloseDialog}
        >
          {array.length === 1 ? (
            <>
              <strong>&apos;{array[0].name}&apos;</strong>{' '}
              {getLocalizedText('을(를) 삭제하시겠습니까?')}
            </>
          ) : (
            <>
              <strong>&apos;{array[0].name}&apos;</strong>{' '}
              {getLocalizedText('외 {{n}}건을 삭제하시겠습니까?', {
                n: array.length - 1,
              })}
            </>
          )}
        </DeleteConfirmation>
      );
    }

    if (dialogType === 'moveForm')
      return (
        <TreePicker
          title={getLocalizedText('양식 이동')}
          /* type="group" */
          disabledId={propsFolderId}
          list={folderItems}
          onSelected={handleChangeFormFolder}
          onClose={handleCloseDialog}
        />
      );

    return null;
  };

  /* 양식함 리스트 삭제 */
  const handleDeleteForm = () => {
    // console.log(`handleDeleteForm()`);

    const data = formList
      .filter(({ checked }) => checked)
      .map(({ id, updateAt }) => ({ id, updateAt }));
    if (data.length === 0) return;

    const params = { ...queryParams };
    delete params.dialogType;
    delete params.dialogMode;
    delete params.pageNo;
    const route = {
      pathname: props.pathname,
      search: createQueryString(params),
    };

    if (data.length === 1)
      dispatch(formActions.delete({ data: data[0], route }));
    else dispatch(formActions.delete({ data, route }));
  };

  /* 체크박스 이벤트 */
  const handleChangeCheckbox = (arg: {
    id: number | 'all';
    checked: boolean;
  }) => {
    dispatch(formActions.checked(arg));
  };

  const handleFolderListAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    // console.log(`handleFolderListAction(arg)`, arg);
    const { code } = arg;

    if (code === 'folder/create')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'create' }));
    if (code === 'form/create') {
      if (folderList.length === 0) {
        setValidation(getLocalizedText('양식 분류를 하나 이상 생성해 주세요.'));
        return;
      }
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'create' }));
    }
  };

  // 모바일에서만 사용.
  const handleFormListClose = () => {
    const pathname = getPathMap('/*/*/*', props.pathname);
    dispatch(sessionActions.setRoute({ pathname }));
  };

  /** 양식 목록 개수 변경 */
  const handleFormListChangeRowsPerPage = (value: number) => {
    // console.log(`handleFormListChangeRowsPerPage(value: ${value})`);

    if (!propsFolderId) return;

    delete queryParams.pageNo;
    dispatch(
      formActions.list({
        folderId: propsFolderId,
        route: {
          pathname: `${pathmap}/${b62(propsFolderId)}`,
          search: createQueryString({ rowsPerPage: value }, queryParams),
        },
      }),
    );
  };

  const handleFormListSearch = (arg: { keyword: string; filter?: string }) => {
    // console.log(`handleFormListSearch(arg)`, arg);

    if (!propsFolderId) return;

    const { keyword } = arg;
    dispatch(
      formActions.list({
        folderId: propsFolderId,
        route: {
          pathname: `${pathmap}/${b62(propsFolderId)}`,
          search: createQueryString(
            {
              pageNo: undefined,
              searchCode: 'form',
              searchWord: keyword,
            },
            queryParams,
          ),
        },
      }),
    );
  };

  /** 양식 목록 액션. */
  const handleFormListAction = (arg: { code: string; id?: number }) => {
    // console.log(`handleFormListAction(arg)`, arg);

    const { code, id } = arg;

    if (code === 'folder/create')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'create' }));
    if (code === 'folder/update')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'update' }));
    if (code === 'folder/delete')
      dispatch(sessionActions.setDialog({ type: 'folder', mode: 'delete' }));

    // TODO 구현 안됨.
    if (code === 'orderFolder')
      dispatch(sessionActions.setDrawer({ type: code }));

    if (code === 'form/create')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'create' }));
    if (code === 'subject' && id !== undefined) {
      dispatch(
        sessionActions.setRoute({
          pathname: `${props.pathname}/${b62(id)}`,
          search: createQueryString(
            { dialogType: 'form', dialogMode: 'update' },
            queryParams,
          ),
        }),
      );
    }
    if (code === 'form/delete')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'delete' }));

    if (code === 'moveForm') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    // 선택취소 이벤트
    if (code === 'cancelSelected') {
      dispatch(formActions.checked({ id: 'all', checked: false }));
    }

    // 이전 페이지.
    if (code === 'prePage') {
      if (!propsFolderId) return;

      const params = { ...queryParams, pageNo: pageNo - 1 };

      dispatch(
        formActions.list({
          folderId: propsFolderId,
          route: {
            pathname: `${pathmap}/${b62(propsFolderId)}`,
            search: createQueryString(params),
          },
        }),
      );
    }
    // 다음 페이지.
    if (code === 'nextPage') {
      if (!propsFolderId) return;

      const params = { ...queryParams, pageNo: pageNo + 1 };

      dispatch(
        formActions.list({
          folderId: propsFolderId,
          route: {
            pathname: `${pathmap}/${b62(propsFolderId)}`,
            search: createQueryString(params),
          },
        }),
      );
    }
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ApprovalFormFolder
              items={folderItems}
              selectedId={propsFolderId}
              onSelected={handleSelectedFolder}
              onClick={handleFolderListAction}
            />
          </EuiSetting.Left>
          {propsFolderId === undefined ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleFormListClose}>
              <ApprovalFormList
                scroll={scrollbar}
                title={
                  folderList.find((a) => a.id === propsFolderId)?.name ?? ''
                }
                items={formItems}
                onSearch={handleFormListSearch}
                onClick={handleFormListAction}
                onChangeChecked={handleChangeCheckbox}
                pagination={{
                  no: pageNo,
                  row: rowsPerPage,
                  total: totalCount,
                  onChangeRow: handleFormListChangeRowsPerPage,
                }}
                searchWord={queryParams.searchWord}
                searchCode={queryParams.searchCode}
              />
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default React.memo(ApprovalFormContainer);
