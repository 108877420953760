import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../../../../components/tree/DirectoryTree';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import ApprovalArchivesTransferContentList from './ApprovalArchivesTransferContentList';
import { archiveActions } from '../../../../stores/approval/archive';
import ApprovalArchivesTransferContentView from './ApprovalArchivesTransferContentView';
import { organizationActions } from '../../../../../groupware-directory/stores/directory/organization';

function ApprovalArchivesTransferContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const title = categories.find((a) => a.id === 6012)?.name;

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const treeItems = useMemo(() => {
    const directoryData = getDirectoryTreeItems({
      companies,
      organizations,
    });
    return directoryData;
  }, [companies, organizations]);

  const handleCloseView = () => {
    setSelectedId(undefined);
  };

  const handleSelectList = (arg: DirectoryTreeItemArg) => {
    Promise.all([
      dispatch(
        organizationActions.findView({ id: arg.item.extra.organizationId }),
      ),
      dispatch(archiveActions.check({ id: arg.item.extra.organizationId })),
    ]).then(() => {
      setSelectedId(arg.item.id);
    });
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{`${getLocalizedText(`${title}`)}`}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ApprovalArchivesTransferContentList
              items={treeItems}
              selectedId={selectedId}
              onItemClick={handleSelectList}
            />
          </EuiSetting.Left>
          {!selectedId ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleCloseView}>
              <ApprovalArchivesTransferContentView selectedId={selectedId} />
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
    </>
  );
}

export default ApprovalArchivesTransferContainer;
