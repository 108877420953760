import { useEffect } from 'react';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../groupware-webapp/stores/session';

function AuthenticationNetworkRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    if (mount) dispatch(sessionActions.setRoute({ pathname, search, hash }));

    return () => {
      mount = false;
    };
  }, [pathname, search]);

  return null;
}

export default AuthenticationNetworkRoute;
