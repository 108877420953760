import React from 'react';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Tree from '../../../../../components/tree/Tree';
import { TreeItem } from '../../../../../groupware-common/types';

function ApprovalArchiveTransferFolderList(props: {
  selectedId?: number;
  items: TreeItem[];
  onItemClick(id: number): void;
  onItemCheck(id: number, event?: React.ChangeEvent<HTMLInputElement>): void;
}): JSX.Element {
  // console.log(`ApprovalArchiveSettingsList(props)`, props);

  const { selectedId, items, onItemClick, onItemCheck } = props;

  return (
    <>
      <EuiSetting.Header title="기록물" />
      <EuiSetting.Content>
        <Tree
          useCheck
          selectedId={selectedId}
          items={items}
          onItemClick={onItemClick}
          onItemCheck={onItemCheck}
        />
      </EuiSetting.Content>
    </>
  );
}

export default ApprovalArchiveTransferFolderList;
