import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
// import Comment from '../../../../../components/comment/Comment';
import PostView from '../../../../../components/post/PostView';
import TimeLine from '../../../../../components/timeline/TimeLine';
import TimeLineItem from '../../../../../components/timeline/TimeLineItem';
import UserInfo from '../../../../../components/user/UserInfo';
import {
  // getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import {
  documentsActions,
  DocumentViewItem,
} from '../../../../stores/document/document';
import { replaceRetentionPeriodToString } from '../../../../stores/document/folders';
// import Chip from '../../../../../components/chip/Chip';
import DocumentContentVersionViewDialog from './DocumentContentVersionViewDialog';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import Button from '../../../../../components/button/Button';
import { documentsFileApi } from '../../../../apis/document/v1/common';
import Chip from '../../../../../components/chip/Chip';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';

type Props = {
  view?: DocumentViewItem;
  pathname: string;
  search: string;
  type: 'full' | 'split';
};

const DocumentContentBodyViewContainer = (props: Props): JSX.Element | null => {
  const { folderId, itemId } = getPathParams<{
    folderId?: string;
    itemId?: number;
  }>('/*/:folderId/:itemId$base62', props.pathname);
  const queryParams = getQueryParams(props.search);
  const { type, view } = props;

  if (view === undefined) return null;

  const directory = useDirectory();
  const scrollbar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const folders = useSelector(
    (state: RootState) => state.document.folders.list,
  );
  const versionList = useSelector(
    (state: RootState) => state.document.documents.version.list,
  );
  const display = useSelector((state: RootState) => state.session.display);

  const [versionId, setVersionId] = useState(0);

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: { id: number }) => {
    const { id } = arg;
    documentsFileApi
      .downloadAll(id, view.currentVersionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    id: number;
    fileId: number;
    name: string;
  }) => {
    const { id, fileId, name } = arg;
    documentsFileApi
      .download(id, fileId, view.currentVersionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 중요표시 기능 이벤트. */
  const handleItemImportanceChange = () => {
    dispatch(
      documentsActions.saveUnSaveStar({
        star: !view.isStarred,
        id: view.id,
        employeeId: principal.employeeId,
        noLoading: true,
      }),
    ).then((result) => {
      if (
        (result as { error?: string }).error === undefined &&
        folderId === 'importance'
      ) {
        const path = `${getPathMap('/*/*', props.pathname)}`;
        go(path, queryParams);
      }
    });
  };

  /** 버전 클릭 */
  const handleVersionClick = (id: number) => {
    setVersionId(id);
    dispatch(sessionActions.setDialog({ type: 'version' }));
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    // TODO: 버전 아이디 변경
    if (dialogType === 'version') {
      return (
        <DocumentContentVersionViewDialog
          id={view.id}
          versionSeq={versionId}
          onClose={handleCloseDialog}
        />
      );
    }
    return undefined;
  };

  const renderContent = () => {
    const updater = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId: view.updaterId,
    });

    const versionData = versionList.map((x) => {
      const employeeId = x.creatorId;
      const directoryData = getDirectoryData({
        ...directory,
        companyId: principal.companyId,
        employeeId,
      });
      const item = {
        id: x.versionSeq,
        label: `VER${x.versionSeq}`,
        subject: x.changeReason,
        updateAt: x.createAt,
      };
      const user = {
        name: directoryData.employeeName,
        avatar: directoryData.avatar,
        from: directoryData.organizationName,
      };
      return {
        item,
        user,
      };
    });

    const folder = folders.find((a) => a.id === view.folderId);
    const useVersion = folder?.option.isVersion ?? false;
    const folderName =
      !folderId || folderId === 'all' || folderId === 'importance'
        ? folder?.name ?? ''
        : undefined;
    return (
      <PostView type={type}>
        <PostView.Head>
          {display === 'phone' ? (
            <PostView.Title>
              <div>
                <Button
                  style={{
                    width: '24px',
                    marginRight: '12px',
                    marginBlock: '5px',
                  }}
                  text={getLocalizedText('별표표시')}
                  icon={view.isStarred ? 'star-fill' : 'star'}
                  iconType
                  onClick={handleItemImportanceChange}
                />
                {folderName ? (
                  <div>
                    <div
                      style={{
                        marginRight: '5px',
                        fontSize: '15px',
                        marginBlock: '5px',
                      }}
                    >
                      <Chip theme="cancel" label={folderName} />
                    </div>
                    <div
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      {view.subject}
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {view.subject}
                  </div>
                )}
                <Chip
                  className="view-title-chip"
                  label={getLocalizedText('체크아웃')}
                  size="xs"
                  theme="primary"
                />
              </div>
            </PostView.Title>
          ) : (
            <PostView.Title
              chip={
                view.status === 'CHECKOUT'
                  ? {
                      label: getLocalizedText('체크아웃'),
                      theme: 'primary',
                    }
                  : undefined
              }
            >
              <div style={{ display: 'flex' }}>
                <Button
                  style={{ width: '24px', marginRight: '12px' }}
                  text={getLocalizedText('별표표시')}
                  icon={view.isStarred ? 'star-fill' : 'star'}
                  iconType
                  onClick={handleItemImportanceChange}
                />
                {folderName ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '5px', fontSize: '15px' }}>
                      <Chip theme="cancel" label={folderName} />
                    </div>
                    <div
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      {view.subject}
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {view.subject}
                  </div>
                )}
              </div>
            </PostView.Title>
          )}
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <UserInfo
                className="view-author"
                key={updater.employeeId}
                name={updater.employeeName}
                avatar={updater.avatar}
                from={updater.organizationName}
                date={view.updateAt}
                dateType="customFormat"
                dateEtc={`${getLocalizedText('조회')} ${view.views}`}
              />
            </div>
          </div>
        </PostView.Head>
        <PostView.Info column>
          {view.retentionPeriod && (
            <PostView.ExcerptItem
              title={getLocalizedText('보존기간')}
              value={replaceRetentionPeriodToString(view.retentionPeriod)}
            />
          )}
        </PostView.Info>
        <PostView.Body>
          <PostView.Content data={view.contents} />
          {view.attachedFiles && view.attachedFiles.length > 0 && (
            <AttachmentsList
              count={view.attachedFiles.length}
              className="view-attachments"
              saveAll={() =>
                handleAttachedFileDownloadAll({
                  id: view.id,
                })
              }
            >
              {view.attachedFiles.map(({ id, fileId, name, size }) => (
                <AttachmentsItem
                  key={`attach_${fileId}`}
                  name={name}
                  size={size}
                  onClick={() =>
                    handleAttachedFileDownload({
                      id,
                      fileId,
                      name,
                    })
                  }
                />
              ))}
            </AttachmentsList>
          )}
          {useVersion && versionData.length > 0 && (
            <TimeLine title={getLocalizedText('버전')}>
              {versionData.map((a) => {
                return (
                  <TimeLineItem
                    key={`timeline_${a.item.id}`}
                    onClick={handleVersionClick}
                    item={a.item}
                    user={a.user}
                    selected={a.item.id === view.currentVersionSeq ?? true}
                  />
                );
              })}
            </TimeLine>
          )}
        </PostView.Body>
      </PostView>
    );
  };

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {renderContent()}
      {renderDialog()}
    </div>
  );
};

export default DocumentContentBodyViewContainer;
