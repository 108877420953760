import React from 'react';
import { Language } from '../../groupware-common/types';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import TextField from './TextField';

function LanguageInput(props: {
  error?: boolean;
  language: Language;
  value: string;
  onChange(language: Language, value: string): void;
}): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { language, onChange } = props;
    onChange(language, event.target.value);
  };

  return (
    <TextField
      error={props.error}
      label={getLocalizedText(`languages.${props.language}`)}
      value={props.value}
      onChange={handleChange}
    />
  );
}

export default LanguageInput;
