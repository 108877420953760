import React from 'react';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostView from '../../../../../components/post/PostView';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../../components/button/Button';
import {
  WorkApprovalLineFlat,
  SharePermissionFlat,
  ApprovalLineType,
  SharePermissionType,
} from '../common/containers/WorkApprovalLineDialogContainer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/** 보존 기간 가져오기. */
function getRetentionPeriodText(retentionPeriod: number): string {
  if (retentionPeriod === 0) return getLocalizedText('영구');

  const year = Math.floor(retentionPeriod / 365);
  const month = (retentionPeriod % 365) / 30;

  if (year > 0 && month > 0)
    return `${getLocalizedText('{{n}} 년', {
      n: year,
    })} ${getLocalizedText('{{n}} 개월', { n: month })}`;
  if (year > 0) return getLocalizedText('{{n}} 년', { n: year });
  return getLocalizedText('{{n}} 개월', { n: month });
}

type Props = {
  paths: string[];
  name: string;
  status: number;
  useAttachFile: number;
  retentionPeriod: number;
  formName: string;
  receiptFormName?: string;
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  description: string;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
};

function ApprovalWorkContentView(props: Props): JSX.Element {
  // console.log(`${ApprovalWorkContentView.name}.render:props`, props);

  const {
    paths,
    name,
    status,
    useAttachFile,
    retentionPeriod,
    formName,
    receiptFormName,
    approvalLine,
    referencePermission,
    viewPermission,
    description,
    onAction,
  } = props;

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('업무정보')}>
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={(event) => onAction({ code: 'work/update', event })}
        />
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-full"
          onClick={(event) => onAction({ code: 'work/delete', event })}
        />
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={(event) => onAction({ code: 'work/sort', event })}
        />
        {/* <Button
          text={getLocalizedText('이동')}
          iconType
          icon="folder-move"
          onClick={() => onDialogOpen('move')}
        />
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={() => onDrawerOpen('order')}
        /> */}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
            <PostView.Title>{name}</PostView.Title>
          </PostView.Head>
          <PostView.Info>
            <PostView.InfoItem
              title={getLocalizedText('사용여부')}
              value={
                status === 1
                  ? getLocalizedText('사용함')
                  : getLocalizedText('사용안함')
              }
            />
            <PostView.InfoItem
              title={getLocalizedText('첨부파일 필수')}
              value={
                useAttachFile === 2
                  ? getLocalizedText('사용함')
                  : getLocalizedText('사용안함')
              }
            />
            <PostView.InfoItem
              title={getLocalizedText('보존기간')}
              value={getRetentionPeriodText(retentionPeriod)}
            />
          </PostView.Info>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title={getLocalizedText('양식명')}>
                <PostView.CategoryValue value={formName} />
              </PostView.CategoryItem>
              {approvalLine.groups.some((a) => a.type === 'receive') &&
                receiptFormName && (
                  <PostView.CategoryItem
                    title={getLocalizedText('내부결재 양식명')}
                  >
                    <PostView.CategoryValue value={receiptFormName} />
                  </PostView.CategoryItem>
                )}
              <PostView.CategoryItem title={getLocalizedText('결재선')}>
                <WorkApprovalLineFlat approvalLine={approvalLine} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('참조권')}>
                {referencePermission && (
                  <SharePermissionFlat
                    items={referencePermission.groups
                      .map(({ items }) => {
                        if (items.length === 0) return [];
                        return items;
                      })
                      .flat()}
                  />
                )}
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('조회권')}>
                {viewPermission && (
                  <SharePermissionFlat
                    items={viewPermission.groups
                      .map(({ items }) => {
                        if (items.length === 0) return [];
                        return items;
                      })
                      .flat()}
                  />
                )}
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('설명')}>
                <PostView.CategoryValue value={description} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default React.memo(ApprovalWorkContentView);
