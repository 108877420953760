/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg, NoLoading } from '../../groupware-common/types';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import {
  extractOptionsFromUrl,
  objectArrayToUrlParams,
  urlSearchToObject,
} from './common/index';
import templateApi, {
  adminTemplateApi,
  APIResponse,
  templateContentApi,
} from '../apis/v1/dashboard/templates/index';
import {
  getTemplateItem,
  TemplateItem,
  TemplateModule,
  TemplateType,
} from '../constants/templates';
import { folderActions } from '../../groupware-document/stores/document/folders';
import { folderBoxActions } from '../../groupware-board/stores/folder';

const name = 'dashboard/templates';

export interface PortalTemplateItem extends TemplateItem {
  templateId: number;
  module: TemplateModule;
  type: TemplateType;
  url: string;
  search?: string; // ex ) p1=value&p2=value...
  options?: string; // ex ) '{"p1":"value","p2":"value"}'
}

export interface PortalTemplateDetail extends PortalTemplateItem {
  id: number;
  portalId: number;
  seq: number;
  updaterId: number;
  updateAt: string;
  item: APIResponse;
}

interface State {
  availableList: PortalTemplateItem[];
  list: PortalTemplateDetail[];
}

const initialState: State = {
  availableList: [],
  list: [],
};

/** 백엔드 폴더 키 값으로 카테고리 아이디를 가져옵니다. */
export const getFolderIdByKey = (
  id: string | number,
  category: typeof DocumentFolderCategories | typeof BoardFolderCategories,
): string | undefined => {
  const folderId = typeof id === 'string' ? Number(id) : id;
  return Object.keys(category).find((key) => category[key] === folderId);
};
/** 백엔드 요청 값에 맞춰 폴더 아이디 변경. */
export const DocumentFolderCategories: Record<
  string,
  1001 | 1002 | 1003 | 1004
> = {
  all: 1001,
  importance: 1002,
  temp: 1003,
  checkout: 1004,
};
/** 백엔드 요청 값에 맞춰 폴더 아이디 변경. */
export const BoardFolderCategories: Record<string, 1001 | 1002 | 1003> = {
  all: 1001,
  importance: 1002,
  temp: 1003,
};

/** 템플릿 리스트 */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      portalId: number;
      isAdmin?: boolean;
    } & LocateArg,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      // TODO 대표 포탈 === 조회 포탈일 때 초기 포탈 수정 put API 날리지 않도록 수정.
      const { companyId, organizationId, employeeId } = (
        getState() as RootState
      ).session.principal;

      await dispatch(folderActions.userFolderList({ employeeId }));
      await dispatch(
        folderBoxActions.userFolderList({ organizationId, employeeId }),
      );

      const approvalCategories = (getState() as RootState).approval2.document
        .category.list.data.items;
      const boardCategories = (getState() as RootState).boards.board.category;
      const documentCategories = (getState() as RootState).document.documents
        .category;

      const documentFolders = (getState() as RootState).document.folders.list;
      const boardFolders = (getState() as RootState).boards.folder.list;

      const { portalId, isAdmin } = arg;
      const templates = isAdmin
        ? await adminTemplateApi.findList({
            companyId,
            portalId,
          })
        : await templateApi.findList({
            companyId,
            employeeId,
            portalId,
          });
      // 템플릿 데이터 조회.
      const promises = templates.map(async (x) => {
        const templateItem = getTemplateItem(x.module, x.type);

        const pathname = x.url.split('?')[0];
        const params: string | undefined =
          x.url.split('?')[1] ?? templateItem.searchParams;

        const searchObj = params
          ? urlSearchToObject(params, templateItem.templateKey)
          : undefined;
        const search = searchObj
          ? objectArrayToUrlParams(
              searchObj,
              templateItem.templateKey === 'COMMON-PROFILE',
            )
          : undefined;

        const folderId = extractOptionsFromUrl(x.url, 'folders');
        let folderName: string | undefined;
        if (folderId) {
          switch (templateItem.templateKey) {
            case 'APPROVAL-DOCUMENT': {
              folderName = approvalCategories.find(
                (y) => y.id === Number(folderId),
              )?.name;
              break;
            }
            case 'DOCUMENT-POSTS': {
              const categoryFolderId = getFolderIdByKey(
                folderId,
                DocumentFolderCategories,
              );
              folderName = categoryFolderId
                ? documentCategories.find((y) => y.id === categoryFolderId)
                    ?.name
                : documentFolders.find((y) => y.id === Number(folderId))?.name;
              break;
            }
            case 'BOARD-POSTS': {
              const categoryFolderId = getFolderIdByKey(
                folderId,
                BoardFolderCategories,
              );
              folderName = categoryFolderId
                ? boardCategories.find((y) => y.id === categoryFolderId)?.name
                : boardFolders.find((y) => y.id === Number(folderId))?.name;
              break;
            }
            default: {
              folderName = '';
              break;
            }
          }
        }
        const res = await templateContentApi.find({
          url: search ? `${pathname}?${search}` : x.url,
          responseType: templateItem.templateKey,
        });
        return {
          ...x,
          search,
          folderName,
          url: pathname,
          templateKey: templateItem.templateKey,
          icon: templateItem.icon,
          title: templateItem.title,
          isEdit: templateItem.isEdit,
          item: res,
        };
      });
      return await Promise.all(promises).then((res) => {
        return {
          portalId,
          list: res,
        };
      });
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 템플릿 콘텐츠 개별 조회 */
const view = createAsyncThunk(
  `${name}/view`,
  async (
    arg: {
      id: number;
      search?: string;
    } & LocateArg &
      NoLoading,
    { rejectWithValue, getState },
  ) => {
    try {
      const { list } = (getState() as RootState).dashboard.templates;
      const template = list.find((x) => x.id === arg.id);
      // TODO 에러 처리
      if (!template) return null;
      const res = await templateContentApi.find({
        url: arg.search ? `${template.url}?${arg.search}` : template.url,
        responseType: template.templateKey,
      });
      return { id: arg.id, search: arg.search, items: res };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 템플릿 순서변경 */
const sort = createAsyncThunk(
  `${name}/sort`,
  async (
    arg: {
      portalId: number;
      list: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
      isAdmin?: boolean;
    } & LocateArg,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { portalId, list, isAdmin } = arg;
      const response = isAdmin
        ? await adminTemplateApi.sort({
            companyId,
            portalId,
            list,
          })
        : await templateApi.sort({
            companyId,
            employeeId,
            portalId,
            list,
          });
      await dispatch(findList({ portalId, isAdmin }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);
/** 템플릿 추가 */
const save = createAsyncThunk(
  `${name}/save`,
  async (
    arg: {
      portalId: number;
      templateId: number;
      url: string;
      options?: string;
      isAdmin?: boolean;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { portalId, templateId, options, url, isAdmin } = arg;
      const response = isAdmin
        ? await adminTemplateApi.save({
            companyId,
            portalId,
            templateId,
            url,
            options,
          })
        : await templateApi.save({
            companyId,
            employeeId,
            portalId,
            templateId,
            url,
            options,
          });
      const { list } = (getState() as RootState).dashboard.templates;
      const item = list.find((x) => x.templateId === templateId);
      if (item)
        return {
          id: response.id,
          portalId,
          seq: 1,
          templateId,
          module: item.module,
          type: item.type,
          url,
          options,
          updaterId: employeeId,
          updateAt: response.updateAt,
        };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);
/** 템플릿 수정 */
const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: { data: PortalTemplateDetail; isAdmin?: boolean } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { data, route, isAdmin } = arg;
      const { portalId, url, updateAt, id, options, templateKey } = data;
      const response = isAdmin
        ? await adminTemplateApi.update({
            companyId,
            portalId,
            portalTemplateId: id,
            url,
            options,
            updateAt,
          })
        : await templateApi.update({
            companyId,
            employeeId,
            portalId,
            portalTemplateId: id,
            url,
            options,
            updateAt,
          });

      const templateContents = await templateContentApi.find({
        url,
        responseType: data.templateKey,
      });

      const approvalCategories = (getState() as RootState).approval2.document
        .category.list.data.items;
      const boardCategories = (getState() as RootState).boards.board.category;
      const documentCategories = (getState() as RootState).document.documents
        .category;

      const documentFolders = (getState() as RootState).document.folders.list;
      const boardFolders = (getState() as RootState).boards.folder.list;

      const folderId = extractOptionsFromUrl(url, 'folders');
      let folderName: string | undefined;
      if (folderId) {
        switch (templateKey) {
          case 'APPROVAL-DOCUMENT': {
            folderName = approvalCategories.find(
              (y) => y.id === Number(folderId),
            )?.name;
            break;
          }
          case 'DOCUMENT-POSTS': {
            const categoryFolderId = Object.keys(DocumentFolderCategories).find(
              (key) => DocumentFolderCategories[key] === Number(folderId),
            );
            folderName = categoryFolderId
              ? documentCategories.find((y) => y.id === categoryFolderId)?.name
              : documentFolders.find((y) => y.id === Number(folderId))?.name;
            break;
          }
          case 'BOARD-POSTS': {
            const categoryFolderId = Object.keys(BoardFolderCategories).find(
              (key) => BoardFolderCategories[key] === Number(folderId),
            );
            folderName = categoryFolderId
              ? boardCategories.find((y) => y.id === categoryFolderId)?.name
              : boardFolders.find((y) => y.id === Number(folderId))?.name;
            break;
          }
          default: {
            folderName = '';
            break;
          }
        }
      }

      return {
        url,
        options,
        folderName,
        item: templateContents,
        id: response.id,
        updateAt: response.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 템플릿 삭제 */
const deleteTemplate = createAsyncThunk(
  `${name}/deleteTemplate`,
  async (
    arg: {
      portalId: number;
      portalTemplateId: number;
      updateAt: string;
      isAdmin?: boolean;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const { portalId, portalTemplateId, updateAt, isAdmin } = arg;
      const response = isAdmin
        ? await adminTemplateApi.deleteTemplate({
            companyId,
            portalId,
            portalTemplateId,
            item: {
              id: portalTemplateId,
              updateAt,
            },
          })
        : await templateApi.deleteTemplate({
            companyId,
            employeeId,
            portalId,
            portalTemplateId,
            item: {
              id: portalTemplateId,
              updateAt,
            },
          });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 생성 가능한 포탈 템플릿 리스트 */
const findAllTemplate = createAsyncThunk(
  `${name}/findAllTemplate`,
  async (arg: { isAdmin?: boolean }, { rejectWithValue, getState }) => {
    try {
      const { isAdmin } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      const response = isAdmin
        ? await adminTemplateApi.findAbailableList({
            companyId,
          })
        : await templateApi.findAbailableList({
            companyId,
          });
      const list = response
        .map((x) => ({
          ...x,
          templateId: x.id,
          ...getTemplateItem(x.module, x.type),
          url: x.url,
        }))
        // NOTE 개발 중인 템플릿 보이지 않게 처리
        .filter((x) => x.templateKey !== 'DEFAULT');
      return { list };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const templatesReducer = createSlice({
  name,
  initialState,
  reducers: {
    setSearch(state, actions: PayloadAction<{ id: number; search: string }>) {
      state.list = state.list.map((x) => {
        if (x.id === actions.payload.id) {
          return { ...x, search: actions.payload.search };
        }
        return x;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findList.fulfilled, (state, { payload }) => {
      if (payload !== undefined) state.list = payload.list;
    });
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload !== undefined)
        state.list = state.list.map((x) =>
          x.id === payload.id ? { ...x, ...payload } : x,
        );
    });
    builder.addCase(view.fulfilled, (state, { payload }) => {
      if (payload && payload.items) {
        state.list = state.list.map((x) => {
          if (x.id === payload.id)
            return { ...x, item: payload.items, search: payload.search };
          return x;
        });
      }
    });
    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      if (payload !== undefined)
        state.list = state.list.filter((x) => x.id !== payload.id);
    });
    builder.addCase(findAllTemplate.fulfilled, (state, { payload }) => {
      if (payload !== undefined) state.availableList = payload.list;
    });
  },
});

export default templatesReducer.reducer;

export const templatesActions = {
  setSearch: templatesReducer.actions.setSearch,

  findAllTemplate,
  findList,
  view,
  save,
  update,
  deleteTemplate,
  sort,
};
