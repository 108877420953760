import React, { useEffect, useRef, useState } from 'react';
import { Language } from '../../../../../groupware-common/types';
import Button from '../../../../../components/button/Button';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import SelectField from '../../../../../components/selectField/SelectField';
import DaumPostCode from '../../../../../components/daum-postcode';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import OrganizationJobDutySelectDialogContainer from './OrganizationJobDutySelectDialogContainer';
import MultilingualTextField from '../../../../../components/textfield/MultilingualTextField';
import { parseDate } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Drawer from '../../../../../components/drawer/Drawer';
import UploadImage from '../../../../../components/upload/UploadImage';
import accountApi from '../../../../apis/directory/v1/account';
import HelperText from '../../../../../components/alert/HelperText';
import informationApi from '../../../../../groupware-setting/apis/setting/v1/information';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import PostView from '../../../../../components/post/PostView';
import Alert from '../../../../../components/alert/Alert';
import loginSettingApi from '../../../../../groupware-authentication/apis/authentication/v1/login';
import FeedBack from '../../../../../components/alert/FeedBack';

type Props = {
  companyId: number;
  id: number;
  email: string;
  names: { label: Language; value: string }[];
  enterDate: string;
  no: string;
  companyPhoneNo: string;
  extensionPhoneNo: string;
  mobilePhoneNo: string;
  representativeOrganizationId: number;
  jobPositionId: number;
  jobRank: number;
  task: string;
  personalEmail: string;
  birthday: string;
  lunarBirthdayUse: boolean;
  postalCode: string;
  address: string;

  affiliatedOrganizations: {
    id: number;
    name: string;
    jobDutyId: number;
    jobDutyName: string;
    paths: { name: string }[];
    updateAt?: string;
  }[];
  avatar: string;
  isUserAvatar: boolean; // 사용자 이미지 사용 유무 확인.

  accountLinkage?: {
    provider: string; // 계정 연동 제공자.
    id: string; // 계정 연동 아이디.
  };

  onCreate?(arg: unknown): void;
  onUpdate?(arg: unknown): void;
  onClose(): void;

  jobPositionItems: { value: string; label: string }[];
  jobRankItems: { value: string; label: string }[];

  inTeams: boolean;
};

function OrganizationChartContentEmployeeEdit(dummy: Props): JSX.Element {
  const props = {
    ...dummy,
  };
  const h3Ref = useRef<HTMLHeadingElement>(null);

  const [state, setState] = useState(() => {
    const {
      isUserAvatar,
      enterDate,
      birthday,
      affiliatedOrganizations,
      inTeams,
    } = props;
    return {
      ...props,

      avatar: isUserAvatar ? props.avatar : '',
      enterDate: parseDate(enterDate),
      birthday: parseDate(birthday),
      affiliatedOrganizations: affiliatedOrganizations.map(
        ({ id, name, jobDutyId, jobDutyName, paths }) => ({
          id,
          name,
          jobDutyId,
          jobDutyName,
          paths,
        }),
      ),

      password: inTeams ? 'a123456!' : '',
      passwordConfirm: inTeams ? 'a123456!' : '',
      tempPassword: undefined as string | undefined,
      issuingPassword: false, // 비밀번호 발급 이벤트

      organizationJobDutySelectDialog: false,
      avatarError: false,
      useRequiredPasswordSpecialChar: false,
      tempPasswordCopyError: '',
    };
  });
  const [validation, setValidation] = useState({
    email: '',
    emailCheck: '',
    password: '',
    passwordConfirm: '',
    name: '',
  });

  // console.log(`${OrganizationChartContentEmployeeEdit.name}.props`, props);
  // console.log(`${OrganizationChartContentEmployeeEdit.name}.state`, state);

  useEffect(() => {
    async function run() {
      const { useRequiredPasswordSpecialChar } =
        await loginSettingApi.loginSetting(props.companyId);
      setState((prev) => ({ ...prev, useRequiredPasswordSpecialChar }));
    }
    if (props.onCreate && !props.onUpdate) run();
  }, []);

  const typeMessage = state.useRequiredPasswordSpecialChar
    ? `영문, 숫자, 특수문자(@, $, !, %, *, ?, &, #)`
    : getLocalizedText('영문, 숫자');

  useEffect(() => {
    async function run() {
      await informationApi
        .changeAdminPassword(props.id)
        .then((result) => {
          if ((result as { error?: string }).error === undefined)
            setState((prev) => ({
              ...prev,
              tempPassword: result,
              issuingPassword: false,
            }));
        })
        .catch(() => {
          setState((prev) => ({
            ...prev,
            issuingPassword: false,
            tempPassword: '',
          }));
        });
    }

    if (state.issuingPassword) run();
  }, [state.issuingPassword]);

  /** 아바타 오류. */
  const handleAvatarError = () => {
    setState((prevState) => ({ ...prevState, avatar: '', avatarError: true }));
  };

  /** 아바타 변경 */
  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) setState((prev) => ({ ...prev, avatar: base64.toString() }));
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.

      const eventTargetValue = { ...event };
      eventTargetValue.target.value = ''; // 같은 파일을 다시 받는 경우때문에 value reset
    }
    setState((prev) => ({ ...prev, isUserAvatar: true }));
  };

  /** 아바타 삭제 */
  const handleAvatarDelete = () => {
    setState((prev) => ({
      ...prev,
      avatar: '',
      isUserAvatar: false,
    }));
  };

  /** 이메일 변경 */
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      email: event.target.value,
    }));
    setValidation((prevState) => ({
      ...prevState,
      emailCheck: '',
    }));
  };

  /** 이메일 가용성 확인 */
  const handleEmailCheck = async () => {
    if (state.email === '' || state.email.trim() === '') {
      setValidation((prevState) => ({
        ...prevState,
        email: getLocalizedText('이메일은 빈 값 일 수 없습니다.'),
        emailCheck: '',
      }));
    }
    if (state.email !== '') {
      const response = await accountApi.fetchEmailAvailAbility(state.email);
      if (response === 'Y') {
        setValidation((prevState) => ({
          ...prevState,
          email: '',
          emailCheck: getLocalizedText('사용 가능한 이메일 입니다.'),
        }));
      } else if (response === 'N') {
        setValidation((prevState) => ({
          ...prevState,
          email: getLocalizedText(
            '이미 사용된 사용자 이메일입니다. 다른 이메일을 선택하세요.',
          ),
          emailCheck: '',
        }));
      } else if (response === 'F') {
        setValidation((prevState) => ({
          ...prevState,
          email: getLocalizedText('이메일 주소가 올바르지 않습니다.'),
          emailCheck: '',
        }));
      }
    }
  };

  /** 비밀번호 변경 */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, password: event.target.value }));
  };

  /** 비밀번호 변경 확인 */
  const handlePasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, passwordConfirm: event.target.value }));
  };

  /** 비밀번호 초기화. */
  const handleInitializePassword = () => {
    setState((prev) => ({ ...prev, tempPassword: '' }));
  };

  /** 비밀번호 초기화 대화상자 닫기. */
  const handleCancelInitializePassword = () => {
    setState((prev) => ({ ...prev, tempPassword: undefined }));
  };

  /** 임시 비밀번호 복사. */
  const handleTempPasswordCopy = () => {
    if (h3Ref.current === null) {
      setState((prev) => ({
        ...prev,
        tempPasswordCopyError: getLocalizedText('복사할 값이 없습니다.'),
      }));
      return;
    }

    window.navigator.clipboard.writeText(h3Ref.current.textContent ?? '');
    setState((prev) => ({
      ...prev,
      tempPasswordCopyError: getLocalizedText(
        '클립보드에 임시 비밀번호를 복사하였습니다.',
      ),
    }));
  };

  /** 이름 변경 */
  const handleNameChange = (code: Language, value: string) => {
    setState((prev) => ({
      ...prev,
      names: state.names.map((a) =>
        a.label === code ? { ...a, ...{ label: code, value } } : a,
      ),
    }));
  };

  /** 입사일자 변경 */
  const handleEnterDateChange = (enterDate: Date | null) => {
    setState((prev) => ({ ...prev, enterDate }));
  };

  /** 직원번호 변경 */
  const handleNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, no: event.target.value }));
  };

  /** 회사 전화 변경 */
  const handlecompanyPhoneNoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, companyPhoneNo: event.target.value }));
  };

  /** 내선 전화 변경 */
  const handleextensionPhoneNoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({ ...prev, extensionPhoneNo: event.target.value }));
  };

  /** 휴대 전화 변경 */
  const handlemobilePhoneNoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const mobilePhoneNo = event.target.value;
    setState((prev) => ({ ...prev, mobilePhoneNo }));
  };

  /** 조직 직책 대화상자 열기 */
  const handleOrganizationJobDutySelectDialogOpen = () => {
    setState((prev) => ({ ...prev, organizationJobDutySelectDialog: true }));
  };

  /** 조직 직책 대화상자 닫기 */
  const handleOrganizationJobDutySelectDialogClose = () => {
    setState((prev) => ({ ...prev, organizationJobDutySelectDialog: false }));
  };

  /** 조직 직책 저장 */
  const handleOrganizationJobDutySave = ({
    representativeOrganizationId,
    organizationJobDuties,
  }: {
    representativeOrganizationId: number;
    organizationJobDuties: {
      id: number;
      name: string;
      jobDutyId: number;
      jobDutyName: string;
      paths: { name: string }[];
    }[];
  }) => {
    setState((prev) => ({
      ...prev,
      representativeOrganizationId,
      affiliatedOrganizations: organizationJobDuties,
      organizationJobDutySelectDialog: false,
    }));
  };

  /** 직위 변경 */
  const handleJobPositionChange = (value: string) => {
    setState((prev) => ({ ...prev, jobPositionId: parseInt(value, 10) }));
  };

  /** 직급 변경 */
  const handleJobRankChange = (value: string) => {
    setState((prev) => ({ ...prev, jobRank: parseInt(value, 10) }));
  };

  /** 업무 변경 */
  const handleTaskChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, task: event.target.value });
  };

  /** 개인 메일 변경 */
  const handlePersonalEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, personalEmail: event.target.value }));
  };

  /** 생일 변경 */
  const handleBirthdayChange = (birthday: Date | null) => {
    setState((prev) => ({ ...prev, birthday }));
  };

  /** 양력 음력 변경 */
  const handleSolarOrLunarChange = (lunarBirthdayUse: boolean) => {
    setState((prev) => ({ ...prev, lunarBirthdayUse }));
  };

  /** 주소 변경 */
  const handleAddressChange = ({
    postalCode,
    address,
  }: {
    postalCode: string;
    address: string;
  }) => {
    setState((prev) => ({ ...prev, postalCode, address }));
  };

  /** 연동 계정 아이디 변경. */
  const handleAccountLinkageIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { accountLinkage } = state;
    if (accountLinkage === undefined) return;
    setState((prevState) => ({
      ...prevState,
      accountLinkage: {
        ...accountLinkage,
        id: event.target.value,
      },
    }));
  };

  /** 저장 */
  const handleSave = () => {
    const { onCreate, onUpdate, inTeams } = props;

    if (onCreate) {
      const avatar =
        state.avatar.indexOf('data:') === 0 ? state.avatar : undefined;
      const {
        email,
        names,
        password,
        passwordConfirm,
        enterDate,
        no,
        companyPhoneNo,
        extensionPhoneNo,
        mobilePhoneNo,
        affiliatedOrganizations,
        representativeOrganizationId,
        jobPositionId,
        jobRank,
        task,
        personalEmail,
        birthday,
        lunarBirthdayUse,
        postalCode,
        address,
        accountLinkage,
      } = state;

      const newValidation = { ...validation };

      if (validation.emailCheck === '')
        newValidation.email =
          getLocalizedText('이메일 중복 확인을 해야 합니다.');
      if (email === '' || email.trim() === '')
        newValidation.email =
          getLocalizedText('이메일은 빈 값 일 수 없습니다.');

      if (!inTeams) {
        const passwordLength = /^.{8,16}$/;
        const denyPattern = state.useRequiredPasswordSpecialChar
          ? /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&#])[a-zA-Z\d@$!%*?&#]+$/g
          : /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+[\]{};:'",.<>\\/?\\|`~]+$/g;

        if (password === '' || password.trim() === '')
          newValidation.password = getLocalizedText(
            getLocalizedText('비밀번호는 빈 값 일 수 없습니다.'),
          );
        else if (!denyPattern.test(password))
          newValidation.password = getLocalizedText(
            '비밀번호는 {{arg}}를 필수로 사용해야 합니다.',
            { arg: typeMessage },
          );
        else if (!passwordLength.test(password))
          newValidation.password = getLocalizedText(
            '비밀번호는 8자리 이상 16자리 이하여야 합니다.',
          );
        else newValidation.password = '';

        if (password !== passwordConfirm)
          newValidation.passwordConfirm =
            getLocalizedText('비밀번호가 일치하지 않습니다.');
        else newValidation.passwordConfirm = '';
      }

      if (!names.find((a) => a.label === 'ko-KR' && a.value.trim() !== ''))
        newValidation.name = getLocalizedText('이름은 빈 값 일 수 없습니다.');
      else newValidation.name = '';

      if (
        newValidation.name !== '' ||
        newValidation.password !== '' ||
        newValidation.passwordConfirm !== '' ||
        newValidation.email !== ''
      ) {
        setValidation(newValidation);
        return;
      }

      const param = {
        email,
        names: names.filter(({ value }) => value.trim() !== ''),
        password,
        passwordConfirm,
        enterDate: parseDate(enterDate),
        no,
        companyPhoneNo,
        extensionPhoneNo,
        mobilePhoneNo,
        affiliatedOrganizations,
        representativeOrganizationId,
        jobPositionId,
        jobRank,
        task,
        personalEmail,
        birthday: parseDate(birthday),
        lunarBirthdayUse: birthday === null ? false : lunarBirthdayUse,
        postalCode,
        address,
        avatar,
        accountLinkage,
      };

      onCreate(param);
    } else if (onUpdate) {
      let avatar: string | undefined;

      if (props.avatar === state.avatar) avatar = undefined;
      else if (state.avatar.indexOf('data:') === 0) avatar = state.avatar;
      else if (state.avatarError && state.avatar === '') avatar = undefined;
      // 아바타 수정 안 했을 경우 기본 아바타 이미지일 때 avatar = undefined
      else if (props.isUserAvatar === false && state.avatar === '')
        avatar = undefined;
      else avatar = '';

      const {
        enterDate,
        no,
        companyPhoneNo,
        extensionPhoneNo,
        mobilePhoneNo,
        representativeOrganizationId,
        jobPositionId,
        jobRank,
        task,
        birthday,
        postalCode,
        address,
        accountLinkage,
      } = state;

      const names: { label: Language; value: string | null }[] = [];
      props.names.forEach((a, i) => {
        const b = state.names[i];
        if (a.label === b.label && a.value !== b.value) {
          if (b.value.trim() !== '')
            names.push({ label: b.label, value: b.value });
          else names.push({ label: b.label, value: null });
        }
      });

      const newValidation = { ...validation };

      if (
        names.find(
          (a) => a.label === 'ko-KR' && (a.value === '' || a.value === null),
        )
      )
        newValidation.name = getLocalizedText('이름은 빈 값 일 수 없습니다.');
      else newValidation.name = '';

      if (newValidation.name !== '') {
        setValidation(newValidation);
        return;
      }

      const affiliatedOrganizations: {
        id: number;
        jobDutyId: number;
        lookupUpdateAt?: string;
        lookupDeleteAt?: string;
      }[] = [];

      state.affiliatedOrganizations.forEach((a) => {
        const c = props.affiliatedOrganizations.find((b) => a.id === b.id);
        if (c === undefined) {
          // 등록 데이터
          affiliatedOrganizations.push({
            id: a.id,
            jobDutyId: a.jobDutyId,
          });
        } else if (a.jobDutyId !== c.jobDutyId) {
          // 수정 데이터.
          affiliatedOrganizations.push({
            id: c.id,
            jobDutyId: a.jobDutyId,
            lookupUpdateAt: c.updateAt,
          });
        }
      });

      props.affiliatedOrganizations.forEach((a) => {
        const c = state.affiliatedOrganizations.find((b) => a.id === b.id);
        if (c === undefined) {
          // 삭제 데이터.
          affiliatedOrganizations.push({
            id: a.id,
            jobDutyId: a.jobDutyId,
            lookupDeleteAt: a.updateAt,
          });
        }
      });

      const param = {
        avatar,
        email,
        names: names.length === 0 ? undefined : names,
        enterDate: parseDate(enterDate),
        no,
        companyPhoneNo,
        extensionPhoneNo,
        mobilePhoneNo,
        affiliatedOrganizations,
        representativeOrganizationId,
        jobPositionId,
        jobRank,
        task,
        personalEmail,
        birthday: parseDate(birthday),
        lunarBirthdayUse,
        postalCode,
        address,
        accountLinkage,
      };
      onUpdate(param);
    }
  };

  const {
    onCreate,
    onUpdate,
    onClose,
    jobPositionItems,
    jobRankItems,
    inTeams,
  } = props;
  const {
    email,
    password,
    passwordConfirm,
    enterDate,
    no,
    companyPhoneNo,
    extensionPhoneNo,
    mobilePhoneNo,
    affiliatedOrganizations,
    representativeOrganizationId,
    jobPositionId,
    jobRank,
    task,

    personalEmail,
    birthday,
    lunarBirthdayUse,
    postalCode,
    address,
    avatar,
    accountLinkage,
    //
    organizationJobDutySelectDialog,
  } = state;
  const names = state.names.map(({ label: language, value }) => ({
    language,
    value,
  }));

  const renderDialog = () => {
    const { tempPassword } = state;
    if (tempPassword !== undefined && tempPassword === '')
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message eui-confirmation-message">
              <p>{getLocalizedText('비밀번호를 초기화 하시겠습니까?')}</p>
              <p>
                {getLocalizedText(
                  '비밀번호 초기화 시 임시 비밀번호가 발급됩니다.',
                )}
              </p>
            </div>
          </DialogBody>
          <DialogFooter>
            {!state.issuingPassword && (
              <Button
                text={getLocalizedText('취소')}
                onClick={handleCancelInitializePassword}
              />
            )}
            <Button
              noDuplication={state.issuingPassword}
              text={getLocalizedText('확인')}
              variant="contained"
              onClick={() =>
                setState((prev) => ({ ...prev, issuingPassword: true }))
              }
            />
          </DialogFooter>
        </Dialog>
      );

    if (tempPassword !== undefined && tempPassword !== '')
      return (
        <Dialog size="xs" onClose={handleCancelInitializePassword}>
          <DialogHeader>
            <DialogTitle>{getLocalizedText('임시 비밀번호')}</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <PostView>
              <Alert size="sm" severity="warning">
                {getLocalizedText('아래의 비밀번호를 사용자에게 전달해주세요.')}
              </Alert>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '16px',
                }}
              >
                <span>{getLocalizedText('비밀번호')} :</span>
                <h3 ref={h3Ref} style={{ margin: '0 4px' }}>
                  {state.tempPassword}
                </h3>
                <Button
                  text={getLocalizedText('복사')}
                  iconType
                  icon="copy"
                  size="xs"
                  onClick={handleTempPasswordCopy}
                  color="secondary"
                />
              </div>
            </PostView>
          </DialogBody>
        </Dialog>
      );
    return null;
  };

  const title =
    onCreate !== undefined
      ? getLocalizedText('직원등록')
      : getLocalizedText('직원수정');

  return (
    <Drawer title={title} onClose={onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item>
            <UploadImage
              path={avatar}
              name={names[0]?.value}
              type="profile"
              onChange={handleAvatarChange}
              onDelete={handleAvatarDelete}
              onError={handleAvatarError}
            />
          </PostWrite.Item>
          {onCreate !== undefined && (
            <>
              <PostWrite.Item title={getLocalizedText('이메일')}>
                <PostWrite.ItemArea>
                  <TextField
                    value={email}
                    onChange={handleEmailChange}
                    error={validation.email !== ''}
                  />
                  <Button
                    text={getLocalizedText('중복확인')}
                    variant="outlined"
                    onClick={handleEmailCheck}
                  />
                </PostWrite.ItemArea>
                {validation.email !== '' ? (
                  <HelperText>{validation.email}</HelperText>
                ) : (
                  <HelperText
                    className="confirm"
                    text={validation.emailCheck}
                  />
                )}
              </PostWrite.Item>
              {!inTeams && (
                <>
                  <PostWrite.Item title={getLocalizedText('비밀번호')}>
                    <TextField
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      error={validation.password !== ''}
                    />
                    <HelperText
                      text={getLocalizedText(
                        '비밀번호는 {{arg}}를 필수로 조합하여 8~16자리로 설정해야 합니다.',
                        { arg: typeMessage },
                      )}
                    />
                    {validation.password !== '' && (
                      <HelperText>{validation.password}</HelperText>
                    )}
                  </PostWrite.Item>
                  <PostWrite.Item title={getLocalizedText('비밀번호확인')}>
                    <TextField
                      type="password"
                      value={passwordConfirm}
                      onChange={handlePasswordConfirmChange}
                      error={validation.passwordConfirm !== ''}
                    />
                    {validation.passwordConfirm !== '' && (
                      <HelperText>{validation.passwordConfirm}</HelperText>
                    )}
                  </PostWrite.Item>
                </>
              )}
            </>
          )}
          {onUpdate !== undefined && (
            <>
              <PostWrite.Item title={getLocalizedText('이메일')}>
                <TextField value={email} disabled />
              </PostWrite.Item>
              {!inTeams && (
                <>
                  <PostWrite.Item title={getLocalizedText('비밀번호 초기화')}>
                    <Button
                      variant="outlined"
                      text={getLocalizedText('초기화')}
                      onClick={handleInitializePassword}
                    />
                  </PostWrite.Item>
                </>
              )}
            </>
          )}
          <PostWrite.Item title={getLocalizedText('이름')}>
            <MultilingualTextField
              value={names}
              onChange={handleNameChange}
              error={validation.name !== ''}
            />
            {validation.name !== '' && (
              <HelperText>{validation.name}</HelperText>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('입사일자')}>
            <CustomDatePicker
              selected={enterDate}
              dateFormat="yyyy-MM-dd"
              onChange={handleEnterDateChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('사원번호')}>
            <TextField value={no} onChange={handleNoChange} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('회사전화')}>
            <TextField
              type="tel"
              value={companyPhoneNo}
              onChange={handlecompanyPhoneNoChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('내선전화')}>
            <TextField
              type="tel"
              value={extensionPhoneNo}
              onChange={handleextensionPhoneNoChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('휴대전화')}>
            <TextField
              type="tel"
              value={mobilePhoneNo}
              onChange={handlemobilePhoneNoChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('소속')}>
            <div className="ui-organization-duty-select">
              <div className="selected-list">
                {affiliatedOrganizations.map((a) => (
                  <div className="list-item" key={a.id}>
                    <span className="path">
                      {a.paths.map(({ name }, i, array) => {
                        if (i !== array.length - 1)
                          return (
                            <span className="item" key={`${a.id}_${name}`}>
                              {name} &gt;{' '}
                            </span>
                          );
                        return (
                          <strong className="item last" key={`${a.id}_${name}`}>
                            {name}
                          </strong>
                        );
                      })}
                    </span>
                    <em className="duty">({a.jobDutyName})</em>
                    {a.id === representativeOrganizationId && (
                      <em className="master">
                        {getLocalizedText('[대표부서]')}
                      </em>
                    )}
                  </div>
                ))}
              </div>
              <div className="select-action">
                <Button
                  text={
                    affiliatedOrganizations.length === 0
                      ? getLocalizedText('등록')
                      : getLocalizedText('편집')
                  }
                  variant="outlined"
                  size="sm"
                  onClick={handleOrganizationJobDutySelectDialogOpen}
                />
                {organizationJobDutySelectDialog && (
                  <OrganizationJobDutySelectDialogContainer
                    representativeOrganizationId={representativeOrganizationId}
                    organizationJobDuties={affiliatedOrganizations}
                    onSave={handleOrganizationJobDutySave}
                    onClose={handleOrganizationJobDutySelectDialogClose}
                  />
                )}
              </div>
            </div>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('직위')}>
            <SelectField
              data={jobPositionItems}
              value={`${jobPositionId}`}
              onChange={handleJobPositionChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('직급')}>
            <SelectField
              data={jobRankItems}
              value={`${jobRank}`}
              onChange={handleJobRankChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('업무')}>
            <TextField multiline value={task} onChange={handleTaskChange} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('개인메일')}>
            <TextField
              type="email"
              value={personalEmail}
              onChange={handlePersonalEmailChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('생일')}>
            <PostWrite.ItemArea>
              <CustomDatePicker
                selected={birthday}
                dateFormat="yyyy-MM-dd"
                onChange={handleBirthdayChange}
                width={120}
              />
              {birthday !== null && (
                <RadioGroup
                  data={[
                    {
                      value: false,
                      label: getLocalizedText('양력'),
                    },
                    {
                      value: true,
                      label: getLocalizedText('음력'),
                    },
                  ]}
                  value={lunarBirthdayUse}
                  name="lunarBirthdayUse"
                  onChange={handleSolarOrLunarChange}
                />
              )}
            </PostWrite.ItemArea>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('자택주소')}>
            <DaumPostCode
              postalCode={postalCode}
              address={address}
              onChange={handleAddressChange}
            />
          </PostWrite.Item>
          {accountLinkage && (
            <PostWrite.Item title={`${accountLinkage.provider}`}>
              <TextField
                type="email"
                label={getLocalizedText('아이디')}
                value={accountLinkage.id}
                onChange={handleAccountLinkageIdChange}
              />
            </PostWrite.Item>
          )}
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
      {renderDialog()}
      <FeedBack
        text={state.tempPasswordCopyError}
        onClose={() =>
          setState((prev) => ({ ...prev, tempPasswordCopyError: '' }))
        }
      />
    </Drawer>
  );
}

export default OrganizationChartContentEmployeeEdit;
