import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { preferencesActions } from '../../../../stores/approval/preferences';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ApprovalSettingDocumentNoContainer(): JSX.Element {
  // console.log(`ApprovalSettingDocumentNoContainer()`);

  const dispatch = useAppDispatch();

  const documentNo = useSelector(
    (state: RootState) => state.approval2.preferences.documentNo,
  );

  const [state, setState] = useState(() => {
    return {
      change: false,
      saveing: false,
      numberingTime: documentNo?.numberingTime ?? 1,
      numberingMethod: documentNo?.numberingMethod ?? 1,
      useTopLevelOrganization: documentNo?.useTopLevelOrganization ?? false,
      numberingReset: documentNo?.numberingReset ?? 1,
      syncIncomingAndOutgoing: documentNo?.syncIncomingAndOutgoing ?? false,
      updateAt: documentNo?.updateAt ?? '1000-01-01T00:00:00',
    };
  });

  useEffect(() => {
    let mount = true;
    if (state.saveing) {
      const {
        numberingTime,
        numberingMethod,
        useTopLevelOrganization,
        numberingReset,
        syncIncomingAndOutgoing,
        updateAt,
      } = state;
      dispatch(
        preferencesActions.modifyDocumentNo({
          numberingTime,
          numberingMethod,
          useTopLevelOrganization,
          numberingReset,
          syncIncomingAndOutgoing,
          updateAt,
        }),
      ).then((a) => {
        if (!mount) return;
        if (a.type.endsWith('rejected')) {
          setState((prevState) => ({ ...prevState, saveing: false }));
          return;
        }
        const payload = a.payload as { updateAt: string };
        setState({
          change: false,
          saveing: false,
          numberingTime,
          numberingMethod,
          useTopLevelOrganization,
          numberingReset,
          syncIncomingAndOutgoing,
          updateAt: payload.updateAt,
        });
      });
    }
    return () => {
      mount = false;
    };
  }, [state.saveing]);

  if (documentNo === undefined) return <div>404</div>;

  const handleChangeNumberingTime = (value: number) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      numberingTime: value,
    }));
  };

  const handleChangeNumberingMethod = (value: number) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      numberingMethod: value,
    }));
  };

  const handleChangeUseTopLevelOrganization = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const useTopLevelOrganization = event.currentTarget.checked;
    setState((prevState) => ({
      ...prevState,
      change: true,
      useTopLevelOrganization,
    }));
  };

  const handleChangeNumberingReset = (value: number) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      numberingReset: value,
    }));
  };

  const handleChangeSyncIncomingAndOutgoing = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const syncIncomingAndOutgoing = event.currentTarget.checked;
    setState((prevState) => ({
      ...prevState,
      change: true,
      syncIncomingAndOutgoing,
    }));
  };

  const {
    change,
    saveing,
    numberingTime,
    numberingMethod,
    useTopLevelOrganization,
    numberingReset,
    syncIncomingAndOutgoing,
  } = state;

  return (
    <>
      <EuiSetting.Item title={getLocalizedText('문서번호 채번시점')}>
        <RadioGroup
          data={[
            { value: 1, label: getLocalizedText('상신시점') },
            { value: 2, label: getLocalizedText('결재완료시점') },
          ]}
          value={numberingTime}
          name="numberingTime"
          onChange={handleChangeNumberingTime}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('문서번호 채번방식')}>
        <RadioGroup
          data={[
            {
              value: 1,
              label: getLocalizedText('조직 & 업무 일련번호'),
            },
            {
              value: 2,
              label: getLocalizedText('전사 공통 일련번호'),
            },
            {
              value: 3,
              label: getLocalizedText('업무별 일련번호'),
            },
            {
              value: 4,
              label: getLocalizedText('조직별 일련번호'),
            },
          ]}
          value={numberingMethod}
          name="numberingMethod"
          onChange={handleChangeNumberingMethod}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('문서번호 부서명')}>
        <CheckboxGroup>
          <Checkbox
            label={getLocalizedText('최상위 부서명 사용')}
            checked={useTopLevelOrganization}
            onChange={handleChangeUseTopLevelOrganization}
          />
        </CheckboxGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('문서번호 초기화')}>
        <RadioGroup
          data={[
            {
              value: 1,
              label: getLocalizedText('매년 문서번호 초기화'),
            },
            {
              value: 2,
              label: getLocalizedText('매월 문서번호 초기화'),
            },
          ]}
          value={numberingReset}
          name="numberingReset"
          onChange={handleChangeNumberingReset}
          column
        />
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('수발신결재 문서번호')}>
        <CheckboxGroup>
          <Checkbox
            label={getLocalizedText('접수후 내부결재시 기안부서 문서번호 승계')}
            checked={syncIncomingAndOutgoing}
            onChange={handleChangeSyncIncomingAndOutgoing}
          />
        </CheckboxGroup>
      </EuiSetting.Item>
      <EuiToolbar>
        <EuiToolbar.Left>
          {change && (
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              loading={saveing}
              onClick={() =>
                setState((prevState) => ({ ...prevState, saveing: true }))
              }
            />
          )}
          {!saveing && change && (
            <Button
              text={getLocalizedText('취소')}
              onClick={() =>
                setState({ change: false, saveing: false, ...documentNo })
              }
            />
          )}
        </EuiToolbar.Left>
      </EuiToolbar>
    </>
  );
}

export default ApprovalSettingDocumentNoContainer;
