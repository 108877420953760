/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  EntityKeyable,
  apiError,
  apiErrorAsync,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { CreateRandomString } from '../../../../../groupware-common/utils/ui';
import { AdvancedSearchParams } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { SharePermissionGroupItemType } from '../../../../pages/adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';

// 파라미터에 배열로 넘길때 &id[]=1&id[]=2 와 같은 형식을 &id=1&id=2 (&id=1%2C2) 로 변경해주는 옵션
// 전체 옵션 말고 개별 옵션으로 처리도 가능하다. paramsSerializer 검색

// axios.defaults.paramsSerializer = (paramObj) => {
//   const Params = new URLSearchParams();
//   paramObj.forEach((key: any) => {
//     Params.append(key, paramObj[key]);
//   });
//   // for (const key in paramObj) {
//   //   Params.append(key, paramObj[key]);
//   // }

//   return Params.toString();
// };

/** 백엔드에 첨부 문서 아이디 배열을 보낼 때 부모 아이디가 최상위로 오도록 보내도록 수정
 * ex ) [1001(첨부 문서1), 1002(첨부 문서2), 1003(첨부 문서3)], [0(관계사 아이디가 없을 경우 0), 0, 0]
 *  ->  linkeddocumentids=1001&linkedaffiliatedcompanyids=0&linkeddocumentids=1002&linkedaffiliatedcompanyids=0linkeddocumentids=1003..
 */
function createQueryString(arg: {
  documentIds: number[];
  affiliatedCompanyIds: number[];
}): string {
  const { documentIds, affiliatedCompanyIds } = arg;
  if (documentIds.length !== affiliatedCompanyIds.length) {
    throw new Error(getLocalizedText('첨부 문서 조회 오류'));
  }
  const queryStringArray = [];
  for (let i = documentIds.length - 2; i >= 0; i -= 1) {
    queryStringArray.push(
      `linkeddocumentids=${documentIds[i]}&linkedaffiliatedcompanyids=${affiliatedCompanyIds[i]}`,
    );
  }
  return queryStringArray.join('&');
}

/**
 * 결재 문서 유형.
 * @param useApproval 결재 사용 여부.
 * @param useAgree 합의 사용 여부.
 * @param useReceive 수신 사용 여부.
 * @param useAudit 감사 사용 여부.
 * @returns 결재 문서 유형 값.
 */
function getApprovalDocumentType(arg: {
  useApproval: boolean;
  useAgree: boolean;
  useReceive: boolean;
  useAudit: boolean;
}): number {
  const { useApproval, useAgree, useReceive, useAudit } = arg;

  // approval 결재 1 0000 0001
  // agree    합의 2 0000 0010
  // receive  수신 4 0000 0100
  // audit    감사 8 0000 1000
  // eslint-disable-next-line no-underscore-dangle
  const _approval = useApproval ? 1 : 0;
  const agree = useAgree ? 2 : 0;
  const receive = useReceive ? 4 : 0;
  const audit = useAudit ? 8 : 0;

  // eslint-disable-next-line no-bitwise
  return _approval | agree | receive | audit;
}

/**
 * 결재 행위의 해당 하는 값을 반환.
 * @param a 행위. (숫자 또는 문자 형식)
 * @returns 결재 행위의 해당 하는 값. (숫자 또는 문자 형식)
 */
function getApprovalAct(a: undefined): undefined;
// eslint-disable-next-line prettier/prettier
function getApprovalAct(a: undefined | number): undefined | 'draft' | 'back' | 'approval' | 'surrogateApproval' | 'defer' | 'arbitraryDecision' | 'return' | 'retrieve' | 'hold' | 'meet' | 'receipt' | 'none';
// eslint-disable-next-line prettier/prettier
function getApprovalAct(a: undefined | 'draft' | 'back' | 'approval' | 'surrogateApproval' | 'defer' | 'arbitraryDecision' | 'return' | 'retrieve' | 'hold' | 'meet' | 'receipt' | 'none'): number;
// eslint-disable-next-line prettier/prettier
function getApprovalAct(a: undefined | number | 'draft' | 'back' | 'approval' | 'surrogateApproval' | 'defer' | 'arbitraryDecision' | 'return' | 'retrieve' | 'hold' | 'meet' | 'receipt' | 'none'): undefined | 'draft' | 'back' | 'approval' | 'surrogateApproval' | 'defer' | 'arbitraryDecision' | 'return' | 'retrieve' | 'hold' | 'meet' | 'receipt' | 'none' | number {

  if (a === undefined) return undefined;
  // 'draft'; // 기안
  // 'back'; // 전단계 반려 (기안자 다다음 사람 부터 가능)
  // //
  // 'approval'; // 승인 (최종 결재인 경우 완료)
  // 'defer'; // 후결 (최종 전인 경우 완료)
  // 'arbitraryDecision'; // 전결 (바로 완료)
  // //
  // 'return'; // 반려
  // //
  // 'retrieve'; // 회수 (상태 삭제)
  // // 결재 상태 변경 없음.
  // 'hold'; // 보류
  // 'meet'; // 대면
  // 'receipt'; // 접수
  // //
  // 'none'; // 없음

  if (typeof a === 'number') {
    //
    if (a === 1) return 'draft'; // 기안
    if (a === 2) return 'back'; // 전단계 반려 (기안자 다다음 사람 부터 가능)
    //
    if (a === 3) return 'approval'; // 승인 (최종 결재인 경우 완료)
    if (a === 4) return 'defer'; // 후결 (최종 전인 경우 완료)
    if (a === 5) return 'arbitraryDecision'; // 전결 (바로 완료)
    //
    if (a === 6) return 'return'; // 반려
    //
    if (a === 7) return 'retrieve'; // 회수 (상태 삭제)
    // 결재 상태 변경 없음.
    if (a === 8) return 'hold'; // 보류
    if (a === 9) return 'meet'; // 대면
    if (a === 10) return 'receipt'; // 접수
    if (a === 255) return 'none'; // 결재 없음.
    // if (a === 0)
    return undefined; // 없음
  }

  if (a === 'draft') return 1; // 기안
  if (a === 'back') return 2; // 전단계 반려 (기안자 다다음 사람 부터 가능)
  //
  if (a === 'approval') return 3; // 승인 (최종 결재인 경우 완료)
  if (a === 'defer') return 4; // 후결 (최종 전인 경우 완료)
  if (a === 'arbitraryDecision') return 5; // 전결 (바로 완료)
  //
  if (a === 'return') return 6; // 반려
  //
  if (a === 'retrieve') return 7; // 회수 (상태 삭제)
  // 결재 상태 변경 없음.
  if (a === 'hold') return 8; // 보류
  if (a === 'meet') return 9; // 대면
  if (a === 'receipt') return 10; // 접수
  if (a === 'none') return 255; // 결재 없음.
  // if (a === undefined)
  return 0; // 없음
}

export interface OpinionSaveArgApi {
  companyId: number;
  documentId: number;
  id?: number;
  parentId?: number;
  act: '1' | '2' | '3'; // 행위 - 1: 승인,2: 부결,3: 보류
  contents: string;
  likes?: number;
  dislikes?: number;
  loves?: number;
  count?: number;
  updateAt?: string;
}
export interface CommentSaveArgApi {
  companyId: number;
  documentId: number;
  id?: number;
  parentId?: number;
  contents: string;
  likes?: number;
  dislikes?: number;
  loves?: number;
  count?: number;
  updateAt?: string;
}

export interface OpinionDeleteArgApi {
  companyId: number;
  documentId: number;
  id: number;
  updateAt: string;
}

export type CommentDeleteArgApi = OpinionDeleteArgApi;

export interface AttachFile {
  companyId: number;
  documentId: number;
  id: number;
  seq: number;
  name: string;
  size: number;
  updateAt: string;
  url?: string;
  isFileprotection?: boolean; // 파일보호 여부
}

export interface SharedFile {
  affiliatedCompanyId?: number;
  documentId: number;
  id: number;
  name: string;
  size: number;
  downloadUrl?: string;
  viewerUrl?: string;
}

export interface AttachFileURL {
  affiliatedCompanyId: number;
  documentId: number; // 문서 아이디
  id: number; // 첨부된 파일 아이디
  url: string; // 웹주소
  isFileprotection: boolean; // 파일보호 여부
}

export interface AttachDocument {
  companyId: number;
  documentId: number;
  id: number; // 첨부된 문서 아이디
  seq: number;
  updateAt: string;
  no: string; // 첨부된 문서 번호
  subject: string; // 첨부된 문서 제목
}

/** [링크 유형 타입.]
 * - [NONE] 없음.
 * - [ATTENDANCE] 근태.
 * - [ATTENDANCE_ALWAYS]
 */
export type LinkType = 'NONE' | 'ATTENDANCE' | 'ATTENDANCE_ALWAYS';

export interface DocumentData {
  affiliatedCompanyId?: number;
  id: number;
  parentCompanyId: number;
  parentId: number;
  workId: number;
  workName: string;
  formId: number;
  formName: string;
  receiptFormId: number;
  status: string;
  isPublic: boolean;
  number: string;
  subject: string;
  content: string;
  approvalline: string;
  referencePermission: string;
  viewPermission: string;
  opinionCount: number;
  commentCount: number;
  attachedDocumentCount: number;
  attachedFileCount: number;
  attachedSharedFileCount: number;
  draftAt: string;
  completeAt: string;
  retentionPeriod: string;
  option: number; // 업무 옵션.(비트 연산으로 값 확인) 1: 첨부파일 사용, 2: 첨부파일 필수, 4: 첨부문서 사용, 8: 첨부문서 필수, 16: 의견 사용, 32: 의견 필수, 64: 댓글 사용
  setting: number; // 설정. (작성 시점에 채번: 1, 완료 시점에 채번: 2, 접수 후 내부 결재 시 부모 문서번호 승계: 4, 최상위 조직 이름 채번 사용: 8, 결재란에 서명 이미지 사용: 16
  change: boolean;
  formWorkChange: number;
  linkType: LinkType;
  linkId: string;
  linkWait: boolean;
  creatorId: number;
  createAt: string;
  updateAt: string;
  isOutgoing: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
}

interface AdminconsoleDocumentItem {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디. (관계사인 경우만 출력)
  id: number; // 문서 아이디.
  workName: string; // 업무 이름.
  formName: string; // 양식 이름.
  status: string; // TODO: string -> number
  number: string; // 문서번호.
  subject: string; // 제목.
  approvalline: string; // 결재선.
  referencePermission: string; // 참조 권한.
  viewPermission: string; // 조회 권한.
  viewCount: number; // 조회 수. (직원별로 최대1 증가)
  opinionCount: number; // 의견 수.
  commentCount: number; // 댓글 수.
  attachedDocumentCount: number; // 첨부문서 수.
  attachedFileCount: number; // 첨부파일 수.
  size: number; // 크기.
  draftAt: string; // 기안 날짜.
  completeAt: string; // 완료 날짜.
  createAt: string; // 생성 날짜.
  updateAt: string; // 수정 날짜.
  linkWait?: boolean; // 연동 대기 여부.
}

/**
 *  링크 대화상자 문서를 조회합니다.
 */
async function linkedData(arg: {
  documentId: number;
  affiliatedCompanyId?: number;
}): Promise<DocumentData> {
  try {
    const { documentId, affiliatedCompanyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/dialog/linked/documents/${documentId}`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedCompanyid: affiliatedCompanyId,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 접수 후 내부결재 문서 조회. */
async function receiveData(arg: {
  documentId: number;
  affiliatedCompanyId?: number;
  receiptDocumentId: number;
  receiptAffiliatedCompanyId?: number;
}): Promise<DocumentData> {
  try {
    const {
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/dialog/sent/documents/${documentId}`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedcompanyid:
          affiliatedCompanyId === undefined || affiliatedCompanyId === 0
            ? undefined
            : affiliatedCompanyId,
        receiptdocumentid: receiptDocumentId,
        receiptaffiliatedcompanyid: receiptAffiliatedCompanyId,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 첨부 문서 조회. */
async function attachedData(arg: {
  documentId: number;
  affiliatedCompanyId?: number;
  documentIds: number[]; // 첨부문서의 첨부문서를 조회할 때 첨부문서의 아이디 배열 전달.
  affiliatedCompanyIds: number[]; // 첨부문서의 첨부문서를 조회할 때 첨부문서의 관계사 아이디 배열 전달.
}): Promise<DocumentData> {
  try {
    const {
      documentId,
      affiliatedCompanyId,
      documentIds,
      affiliatedCompanyIds,
    } = arg;
    const { host, headers } = getApiConfig();
    const queryString = createQueryString({
      documentIds,
      affiliatedCompanyIds,
    });
    const url = `${host}/api/approval/v1/dialog/attached/documents/${documentId}?${queryString}`;

    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedcompanyid: affiliatedCompanyId ?? undefined,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 상태함 문서를 조회합니다.
 */
async function stateData(arg: {
  folderId: number;
  documentId: number;
  affiliatedCompanyId?: number;
}): Promise<DocumentData> {
  try {
    const { folderId, documentId, affiliatedCompanyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/state/folders/${folderId}/documents/${documentId}`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedCompanyid: affiliatedCompanyId,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 문서를 조회합니다.
 * @param affiliatedCompanyId 문서 관계사 회사 아이디. (관계사 문서인 경우)
 * @param id 문서 아이디.
 * @param organizationIds 조직 아이디 배열.
 * @returns 문서 객체.
 */
// eslint-disable-next-line no-underscore-dangle
async function adminconsoleData(arg: {
  affiliatedCompanyId?: number;
  id: number;
  organizationIds: number[];
}): Promise<{
  affiliatedCompanyId?: number;
  companyId: number; // TODO 관계사 회사 아이디 사용으로 삭제 예정.
  id: number;
  parentCompanyId: number;
  parentId: number;
  workId: number;
  workName: string;
  formId: number;
  formName: string;
  receiptFormId: number;
  status: string;
  publicOrNot: boolean;
  security: boolean;
  number: string;
  subject: string;
  content: string;
  approvalline: string;
  referencePermission: string;
  viewPermission: string;
  opinionCount: number;
  commentCount: number;
  attachedDocumentCount: number;
  attachedFileCount: number;
  attachedSharedFileCount: number;
  draftAt: string;
  completeAt: string;
  retentionPeriod: string;
  option: number;
  setting: number; // 설정. (작성 시점에 채번: 1, 완료 시점에 채번: 2, 접수 후 내부 결재 시 부모 문서번호 승계: 4, 최상위 조직 이름 채번 사용: 8, 결재란에 서명 이미지 사용: 16
  change: boolean;
  formWorkChange: number;
  linkType: LinkType;
  linkId: string;
  linkWait: boolean;
  creatorCompanyId: number;
  creatorOrganizationId: number;
  creatorId: number;
  updaterCompanyId: number;
  updaterId: number;
  updateAt: string;
  isOutgoing: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  createAt: string;
  isPublic: boolean;
}> {
  const { affiliatedCompanyId, id } = arg;
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/approval/v1/adminconsole/documents/${id}`;
    if (affiliatedCompanyId)
      url += `&affiliatedcompanyid=${affiliatedCompanyId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 문서를 조회합니다.
 * @param affiliatedCompanyId 문서 관계사 회사 아이디. (관계사 문서인 경우)
 * @param id 문서 아이디.
 * @param organizationIds 조직 아이디 배열.
 * @returns 문서 객체.
 */
// eslint-disable-next-line no-underscore-dangle
async function attendanceData(arg: {
  affiliatedCompanyId?: number;
  id: number;
}): Promise<DocumentData> {
  const { affiliatedCompanyId, id } = arg;
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/approval/v1/dialog/linked/documents/${id}`;
    if (affiliatedCompanyId)
      url += `&affiliatedcompanyid=${affiliatedCompanyId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 문서 결재자 중 열람자를 조회합니다.
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @returns 참조 아이디 객체.
 */
async function browse(arg: { affiliatedCompanyId?: number; id: number }) {
  const { affiliatedCompanyId, id } = arg;
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/approval/v1/approver/seen/all?id=${id}`;
    if (affiliatedCompanyId)
      url += `&affiliatedcompanyid=${affiliatedCompanyId}`;
    const response = await axios.get<
      {
        referenceCompanyId: number;
        referenceId: number;
        referenceType: number;
      }[]
    >(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchOpinionList(
  companyId: number,
  documentId: number,
): Promise<
  {
    companyId: number; // 문서 회사 아이디
    documentId: number; // 문서 아이디
    id: number; // 아이디
    parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
    employeeCompanyId: number; // 직원 회사 아이디
    employeeId: number; // 직원 아이디 - employee.id
    act: number; // 행위 - 1: 승인,2: 부결,3: 보류
    contents: string; // 내용
    likes: number; // 좋아요 수
    dislikes: number; // 싫어요 수
    loves: number; // 사랑해요 수
    createAt: string; // 생성 날짜
    updateAt: string; // 수정 날짜
    isHidden: boolean; // 숨김 여부
    isDeleted: boolean; // 삭제 여부
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/opinion?companyid=${companyId}&id=${documentId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function saveOpinion(arg: {
  companyId: number;
  documentId: number;
  parentId: number;
  id?: number;
  act: number;
  contents: string;
  updateAt?: string;
}): Promise<{
  companyId: number;
  documentId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/opinion`;
    const response =
      arg.id === undefined
        ? await axios.post(url, arg, { headers })
        : await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteOpinion(arg: {
  companyId: number;
  documentId: number;
  id: number;
  parentId: number;
  updateAt: string;
}): Promise<{
  companyId: number;
  documentId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/opinion`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchCommentList(
  companyId: number,
  documentId: number,
): Promise<
  {
    companyId: number; // 문서 회사 아이디
    documentId: number; // 문서 아이디
    id: number; // 아이디
    parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
    employeeCompanyId: number; // 직원 회사 아이디
    employeeId: number; // 직원 아이디 - employee.id
    contents: string; // 내용
    likes: number; // 좋아요 수
    dislikes: number; // 싫어요 수
    loves: number; // 사랑해요 수
    createAt: string; // 생성 날짜
    updateAt: string; // 수정 날짜
    isHidden: boolean; // 숨김 여부
    isDeleted: boolean; // 삭제 여부
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/comment?companyid=${companyId}&id=${documentId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function saveComment(arg: CommentSaveArgApi): Promise<{
  companyId: number;
  documentId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/comment`;

    if (!arg.id || arg.id === undefined) {
      const response = await axios.post(
        url,
        {
          companyId: arg.companyId,
          documentId: arg.documentId,
          parentId: arg.parentId || 0,
          contents: arg.contents,
        },
        { headers },
      );
      return response.data;
    }
    const response = await axios.put(
      url,
      {
        companyId: arg.companyId,
        documentId: arg.documentId,
        id: arg.id,
        contents: arg.contents,
        likes: arg.likes,
        dislikes: arg.dislikes,
        loves: arg.loves,
        count: arg.count,
        updateAt: arg.updateAt,
      },
      { headers },
    );
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteComment(arg: {
  companyId: number;
  documentId: number;
  id: number;
  parentId: number;
  updateAt: string;
}): Promise<{
  companyId: number;
  documentId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/comment`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchAttachfileList(documentId: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/attachedfile?id=${documentId}`;
    const response = await axios.get<AttachFile[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공유파일 리스트 */
async function fetchSharedFileList(
  documentId: number,
  affiliatedCompanyId?: number,
) {
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/approval/v1/interworking/sharedfile/document/attachedsharedfile/all?documentid=${documentId}`;
    if (affiliatedCompanyId)
      url += `&affiliatedcompanyid=${affiliatedCompanyId}`;
    const response = await axios.get<SharedFile[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 첨부파일 미리보기 URL 리스트를 가져옵니다. */
async function fetchAttachfileURLList(documentId: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/interworking/viewer/document/attachedfile/url/all?documentid=${documentId}`;
    const response = await axios.get<AttachFileURL[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 미리보기 유효 액세스 키를 조회합니다. */
async function fetchAttachAccessKey() {
  try {
    // const { host, headers } = getApiConfig();
    // const url = `${host}`;
    // const response = await axios.get<string>(url, { headers });
    // return response.data;
    // TODO: 랜덤 5글자. 백엔드 API 연결 필요.
    return CreateRandomString();
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 첨부파일을 복호화합니다. */
async function fetchAttachDecrypt(
  documentid: number,
  id: number,
  affiliatedcompanyid?: number,
) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/interworking/fileprotection/decrypt`;
    const params = {
      documentid,
      id,
      affiliatedcompanyid,
    };
    const response = await axios.get<boolean>(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function downloadAttachfileAll(companyId: number, documentId: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/attachedfile/download/all?companyid=${companyId}&id=${documentId}`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function downloadAttachfile(
  companyId: number,
  documentId: number,
  id: number,
) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/attachedfile/download?companyid=${companyId}&id=${documentId}&fileId=${id}`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공유파일 다운로드 */
async function downloadSharedfile(
  documentId: number,
  id: number,
  affiliatedCompanyId?: number,
) {
  try {
    const { host, headers } = getApiConfig();

    let url = `${host}/api/approval/v1/interworking/sharedfile/document/attachedsharedfile/download?&documentid=${documentId}&id=${id}`;
    if (affiliatedCompanyId)
      url += `&affiliatedcompanyid=${affiliatedCompanyId}`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 첨부 문서조회 */
async function fetchAttachdocumentList(documentId: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/attacheddocument?id=${documentId}`;
    // TODO: 관계사 아이디 추가
    const response = await axios.get<AttachDocument[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
export interface HistoryItem {
  companyId: number;
  documentId: number;
  id: number; // 히스토리 아이디
  employeeCompanyId: number; // 직원 회사 아이디
  employeeId: number; // 직원 아이디
  reason: string; // 변경사유
  createAt: string;
}
export interface HistoryItemView {
  companyId: number;
  documentId: number;
  id: number; // 히스토리 아이디
  employeeCompanyId: number; // 직원 회사 아이디
  employeeId: number; // 직원 아이디
  reason: string; // 변경사유
  subject: string; // 제목
  contents: string; // 내용
  attachedFile: string; // 첨부파일
  attachedDocument: string; // 첨부문서
  approvalLine: string; // 결재선
  referencePermission: string; // 참조권
  viewPermission: string; // 조회권
  createAt: string;
}

async function fetchHistoryList(documentId: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/popup/history/all?documentId=${documentId}`;

    const response = await axios.get<HistoryItem[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchHistoryView(documentId: number, id: number[]) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/popup/history`;

    /*
    params = { documentId, id: _id.join(',') };
    let header: AxiosRequestConfig;
    if (params) header = { ...config, params };
    else header = { ...config };

    header = {
      ...header,
      paramsSerializer: (_params) => {
        return $.param(_params);
      },
    };
    */

    const response = await axios.get<HistoryItemView[]>(url, {
      headers,
      params: {
        documentId,
        id,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 상태 결재함 총 수 조회. */
async function fetchTotalCount(arg: {
  folderId: number;
  organizationIds?: number[];
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    folderId: folderid,
    organizationIds: organizationids,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  const status = getFolderStatus(folderid);
  try {
    // console.log(`fetchTotalCount(arg)`, arg);

    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/all/status/totalcount`;
    const response = await axios.get<number>(url, {
      headers,
      params: {
        organizationids,
        status,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 부서 결재함 총 수 조회. */
async function fetchOrganizationTotalCount(arg: {
  folderId: number;
  organizationIds?: number[];
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    folderId: folderid,
    organizationIds: organizationids,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  const type = getFolderType(folderid);
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/all/organization/totalcount`;
    const response = await axios.get<number>(url, {
      headers,
      params: {
        organizationids,
        type,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function fetchApprovalLine(companyId: number, id: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/approvalline?companyId=${companyId}&id=${id}`;
    const response = await axios.get<{
      companyId: number;
      id: number;
      approvalLine: string;
      updateAt: string;
    }>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface DocumentCreateArg {
  workId: number;
  workUpdateAt: string;
  formId: number;
  formUpdateAt: string;
  importance: number;
  publicOrNot: boolean;
  no: string;
  subject: string;
  contents: string;
  approvalLine: string;
  referencePermission: string;
  viewPermission: string;
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  attachedFiles?: {
    path: string;
    id: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
  }[];
  creatorRootOrganizationId: number;
  archiveFolderId: number;
  opinion?: string;

  temporaryDocumentId?: number; // 임시 보관 문서 상신의 경우 값 설정.
  previousDocumentId?: number; // 재기안의 경우 값 설정.

  linkType?: string; // 연동 유형. (NONE: 없음, ATTENDANCE: 근태)
  linkId?: string; // 연동 아이디. (연동 유형이 있는 경우 필수, null 또는 빈값은 아니어야 함)
}

async function create(arg: DocumentCreateArg) {
  // return { companyId: 0, id: 0, updateAt: '' };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document`;
    const response = await axios.post<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동 문서 생성 */
async function createLinked(arg: DocumentCreateArg) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/linked`;
    const response = await axios.post<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface DocumentReceiptArg {
  parentCompanyId: number;
  parentId: number;
  parentUpdateAt: string;
  importance: number;
  publicOrNot: boolean;
  no: string;
  subject: string;
  contents: string;
  approvalLine: string;
  referencePermission: string;
  viewPermission: string;
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  attachedFiles?: {
    documentId?: number;
    path: string;
    id: number;
    name: string;
    size: number;
    seq: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  creatorRootOrganizationId: number;
  archiveFolderId: number;
  opinion?: string;
}
/** 접수 후 내부결재 */
async function receipt(arg: DocumentReceiptArg) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/receipt`;
    const response = await axios.post<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface DocumetnTemporaryArg {
  workId: number;
  workUpdateAt: string;
  formId: number;
  formUpdateAt: string;
  subject: string;
  contents: string;
  approvalLine: string;
  referencePermission: string;
  viewPermission: string;
  attachedFiles?: {
    documentId?: number;
    path: string;
    id: number;
    name: string;
    size: number;
    seq: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
}
/** 문서 최초 임시저장. */
async function temporary(arg: DocumetnTemporaryArg) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/temporary`;
    const response = await axios.post<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 임시저장 한 문서 수정 후 다시 임시저장 */
async function reTemporary(arg: {
  id: number;
  updateAt: string;
  subject: string;
  contents: string;
  approvalLine: string;
  referencePermission: string;
  viewPermission: string;
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  attachedFiles?: {
    documentId?: number;
    path: string;
    id: number;
    name: string;
    size: number;
    seq: number;
    delete?: boolean;
    copy?: boolean;
  }[];
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/temporary`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 결재 */
async function approbate(arg: {
  affiliatedCompanyId?: number;
  organizationId?: number; // 조직 결재인 경우 추가 매개 변수.
  designatorId?: number; // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수.

  internalApprovalAfterReceipt?: {
    parentCompanyId: number;
    parentId: number;
    parentApprovalLine: string;
    parentContents: string;
    parentUpdateAt: string;
  };

  id: number;
  updateAt: string;
  opinion?: string;
  // 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
  approver?: {
    companyId: number;
    companyName: string;
    organizationId: number;
    organizationName: string;
    employeeId: number;
    employeeName: string;
    jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
    jobClassName: string;
    jobPositionId: number;
    jobPositionName: string;
    jobDutyId: number;
    jobDutyName: string;
  };
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/approbate`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 반려 */
async function reject(arg: {
  affiliatedCompanyId?: number;
  organizationId?: number; // 조직 결재인 경우 추가 매개 변수.
  designatorId?: number; // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수.
  id: number;
  updateAt: string;
  opinion?: string;
  // 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
  approver?: {
    companyId: number;
    companyName: string;
    organizationId: number;
    organizationName: string;
    employeeId: number;
    employeeName: string;
    jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
    jobClassName: string;
    jobPositionId: number;
    jobPositionName: string;
    jobDutyId: number;
    jobDutyName: string;
  };
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/reject`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 발신 문서 반려 */
async function rejectOutgoingDocument(arg: {
  affiliatedCompanyId?: number;
  organizationId?: number; // 조직 결재인 경우 추가 매개 변수.
  designatorId?: number; // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수.
  id: number;
  updateAt: string;
  opinion?: string;
  // 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
  approver?: {
    companyId: number;
    companyName: string;
    organizationId: number;
    organizationName: string;
    employeeId: number;
    employeeName: string;
    jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
    jobClassName: string;
    jobPositionId: number;
    jobPositionName: string;
    jobDutyId: number;
    jobDutyName: string;
  };
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/reject-outgoing-document`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회수 */
async function withdraw(arg: {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  opinion?: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/withdraw`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대면 */
async function meet(arg: {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  opinion?: string;
  meetingEmployees?: {
    companyId: number; // 직원 회사 아이디.
    id: number; // 직원 아이디.
  }[];
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/request-meet`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 보류 */
async function holdOn(arg: {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  opinion?: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/hold-on`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 이전 승인 취소 */
async function cancelPreviousApprobation(arg: {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  opinion?: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/cancel-previous-approbation`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 승인 취소 */
async function cancelApprobation(arg: {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  opinion?: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/cancel-approbation`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 전결 */
async function approvalAll(arg: {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  opinion?: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/approval/approbate-all`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function change(arg: {
  companyId: number;
  id: number;
  subject: string;
  contents: string;
  attachedFiles?: {
    path: string;
    id: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
  }[];
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  reason: string;
  updateAt: string;
}) {
  // return { companyId: 0, id: 0, updateAt: '' };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface DocumentReReceiptArg {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  parentCompanyId: number;
  parentId: number;
  parentUpdateAt: string;
  importance: number;
  publicOrNot: boolean;
  no: string;
  subject: string;
  contents: string;
  approvalLine: string;
  referencePermission: string;
  viewPermission: string;
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  attachedFiles?: {
    path: string;
    id: number;
    documentId?: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  creatorRootOrganizationId: number;
  archiveFolderId: number;
  opinion?: string;
}
/** 반려된 내부문서 - 재접수 */
async function reReceipt(arg: DocumentReReceiptArg) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/rereceipt`;
    const response = await axios.post<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchChangeApprovalLine(arg: {
  affiliatedCompanyId?: number;
  id: number;
  approvalline: string;
  reason: string;
  updateAt: string;
}) {
  // console.log(`fetchChangeApprovalLine(arg)`, arg);
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/approvalline`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchChangeReferencePermission(arg: {
  companyId: number;
  id: number;
  referencePermission: string;
  reason: string;
  contents: string;
  updateAt: string;
}) {
  // console.log(`fetchChangeReferencePermission(arg)`, arg);
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/referencepermissions`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchChangeViewPermission(arg: {
  companyId: number;
  id: number;
  viewPermission: string;
  reason: string;
  contents: string;
  updateAt: string;
}) {
  // console.log(`fetchChangeViewPermission(arg)`, arg);
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/viewpermissions`;
    const response = await axios.put<EntityKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// 조회 권한 추가
async function fetchAddViewPermission(arg: {
  companyId: number;
  data: {
    documents: {
      companyId: number;
      id: number;
    }[];
    sharedPermissionGroupItems?: SharePermissionGroupItemType[];
    reason: string;
  };
}) {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/adminconsoles/approval/v1/companies/${companyId}/sharedpermissions/view/documents`;
    const response = await axios.post<EntityKeyable>(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// 참조 권한 추가
async function fetchAddReferencePermission(arg: {
  companyId: number;
  data: {
    documents: {
      companyId: number;
      id: number;
    }[];
    sharedPermissionGroupItems?: SharePermissionGroupItemType[];
    reason: string;
  };
}) {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/adminconsoles/approval/v1/companies/${companyId}/sharedpermissions/reference/documents`;
    const response = await axios.post<EntityKeyable>(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// 조회 권한 삭제
async function fetchDeleteViewPermission(arg: {
  companyId: number;
  data: {
    documents: {
      companyId: number;
      id: number;
    }[];
    sharedPermissionGroupItems?: SharePermissionGroupItemType[];
    reason: string;
  };
}) {
  // console.log(`fetchChangeViewPermission(arg)`, arg);
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/adminconsoles/approval/v1/companies/${companyId}/sharedpermissions/view/documents`;
    const response = await axios.delete<EntityKeyable>(url, {
      headers,
      data,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// 참조 권한 삭제
async function fetchDeleteReferencePermission(arg: {
  companyId: number;
  data: {
    documents: {
      companyId: number;
      id: number;
    }[];
    sharedPermissionGroupItems?: SharePermissionGroupItemType[];
    reason: string;
  };
}) {
  // console.log(`fetchChangeViewPermission(arg)`, arg);
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/adminconsoles/approval/v1/companies/${companyId}/sharedpermissions/reference/documents`;
    const response = await axios.delete<EntityKeyable>(url, { data, headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchAdminconsoleList(arg: {
  state: string;
  advancedSearch?: AdvancedSearchParams;
  pageno: number;
  rowsperpage: number;
}) {
  try {
    const { state, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/${state}/documents`;
    const response = await axios.get<{
      count?: number; // 문서 총 갯수.
      list: AdminconsoleDocumentItem[];
    }>(url, {
      headers,
      params: {
        ...advancedSearch,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchAdminconsoleTotal(arg: {
  state: string;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}) {
  try {
    const { state, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/${state}/documents/count`;
    const response = await axios.get<{ count: number }>(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data.count;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchAdminconsolePrev(arg: {
  state: string;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{
  companyId: number;
  id: number;
}> {
  try {
    const { state, documentId, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/${state}/documents/${documentId}/PREV`;
    const response = await axios.get(url, {
      headers,
      params: {
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
async function fetchAdminconsoleNext(arg: {
  state: string;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{
  companyId: number;
  id: number;
}> {
  try {
    const { state, documentId, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/${state}/documents/${documentId}/NEXT`;
    const response = await axios.get(url, {
      headers,
      params: {
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 폴더 아이디에 해당하는 폴더 상태 반환.
 * @param folderId 폴더 아이디. (1000 ~ 2000)
 * @returns 폴더 상태. (11: 기안 상태, 2: 결재 상태, 1: 진행 상태, 3: 완료 상태, 4: 반려 상태, 12: 회수 상태, 10: 임시보관 상태, 13: 삭제 상태)
 */
function getFolderStatus(folderId: number): number {
  switch (folderId) {
    case 1001:
      return 11; // 기안 상태.
    case 1002:
      return 2; // 결재 상태.
    case 1003:
      return 1; // 진행 상태.
    case 1004:
      return 3; // 완료 상태.
    case 1005:
      return 4; // 반려 상태.
    case 1006:
      return 12; // 회수 상태.
    case 1007:
      return 10; // 임시보관 상태.
    case 1008:
      return 13; // 삭제함 상태.
    case 1009:
      return 5; // 결재 + 진행 상태.
    default:
      throw new Error(getLocalizedText('지원하지 않는 폴더 입니다.'));
  }
}
function getFolderType(folderId: number): number {
  switch (folderId) {
    case 3001:
      return 1; // 품의함.
    case 3002:
      return 2; // 합의함.
    case 3003:
      return 3; // 발신함.
    case 3004:
      return 4; // 수신함.
    default:
      throw new Error(getLocalizedText('지원하지 않는 폴더 입니다.'));
  }
}

/**
 * 문서 다운로드.
 * @param arg 다운로드 대상 문서 아이디(id), 관계사 아이디(affiliateCompanyId) 배열.
 * @returns 바이너리 대형 객체.
 */
async function download(
  arg: {
    id: number;
    affiliateCompanyId?: number;
  }[],
): Promise<Blob> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/download`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, {
      headers,
      responseType,
      params: { data: JSON.stringify(arg) },
    });
    return response.data;
  } catch (ex) {
    throw await apiErrorAsync(ex);
  }
}

export interface InterworkingLogs {
  companyId: number; // 회사 아이디
  id: number; // 로그 아이디
  documentId: number; // 문서 아이디
  documentStatus: string; // 문서 상태
  requesterCompanyId: number; // 요청자 회사 아이디
  requesterId: number; // 요청자 아이디
  responseResult: boolean; // 응답 결과
  responseMessage: string; // 응답 메시지
  requestAt: string; // 요청 시간
  responseAt: string; // 응답 시간
}

/** 연동결재 로그를 조회합니다. */
async function findInterworkingLogs(arg: {
  companyId: number;
  documentId: number;
  employeeId: number;
}): Promise<InterworkingLogs[]> {
  try {
    const { companyId, employeeId, documentId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/documents/${documentId}/interworkingLogs`;
    const result = await axios.get<{ interworkingLogs: InterworkingLogs[] }>(
      url,
      { headers, params: { employeeId } },
    );
    return result.data.interworkingLogs;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동대기 문서 상태를 재전송합니다. */
async function transferInterworkingStatus(arg: {
  companyId: number;
  documentId: number;
  employeeId: number;
}): Promise<EntityKeyable> {
  try {
    const { companyId, employeeId, documentId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/documents/${documentId}/transferStatus`;
    const result = await axios.post(url, undefined, {
      headers,
      params: { employeeId },
    });
    return result.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 결재 문서의 이전 또는 다음 문서 아이디를 조회 합니다.
 * @param type 이전 또는 다음.
 * @param folderId 폴더 아이디.
 * @param companyId 문서 회사 아이디.
 * @param documentId 문서 아이디.
 * @param organizationIds 조직 아이디 배열.
 * @param searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param searchWord 조회 단어.
 * @returns 이전 또는 다음 문서 아이디 객체 또는 undefined.
 */
async function readPrevIdOrNextId(arg: {
  type: 'PREV' | 'NEXT';
  documentId: number;
  folderId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ companyId: number; id: number } | undefined> {
  const { type, folderId, documentId, advancedSearch } = arg;

  try {
    const { host, headers } = getApiConfig();
    // 상태 폴더인 경우. (11: 기안 상태, 2: 결재 상태, 1: 진행 상태, 3: 완료 상태, 4: 반려 상태, 12: 회수 상태, 10: 임시보관 상태, 13: 삭제 상태)
    if (folderId > 1000 && folderId < 2000) {
      const url = `${host}/api/approval/v1/shared/state/folders/${folderId}/documents/${documentId}/${type}`;
      const response = await axios.get(url, {
        headers,
        params: {
          ...advancedSearch,
        },
      });
      return response.data;
    }
    // 조직 폴더인 경우. (품의 폴더, 합의 폴더, 발신 폴더, 수신 폴더)
    if (folderId > 3000 && folderId < 4000) {
      const url = `${host}/api/approval/v1/shared/organization/folders/${folderId}/documents/${documentId}/${type}`;
      const response = await axios.get(url, {
        headers,
        params: {
          ...advancedSearch,
        },
      });
      return response.data;
    }
    return undefined;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 결재 문서의 이전 아이디를 조회 합니다.
 * @param folderId 폴더 아이디.
 * @param companyId 문서 회사 아이디.
 * @param documentId 문서 아이디.
 * @param organizationIds 조직 아이디 배열.
 * @param searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param searchWord 조회 단어.
 * @returns 이전 문서 아이디 객체 또는 undefined.
 */
async function prevId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ companyId: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'PREV' });
}

/**
 * 결재 문서의 다음 아이디를 조회 합니다.
 * @param folderId 폴더 아이디.
 * @param companyId 문서 회사 아이디.
 * @param documentId 문서 아이디.
 * @param organizationIds 조직 아이디 배열.
 * @param searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param searchWord 조회 단어.
 * @returns 다음 문서 아이디 객체 또는 undefined.
 */
async function nextId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ companyId: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'NEXT' });
}

export { getApprovalAct };

// eslint-disable-next-line import/prefer-default-export
export const documentApi = {
  browse,
  fetchOpinionList,
  saveOpinion,
  deleteOpinion,
  fetchCommentList,
  saveComment,
  deleteComment,
  fetchAttachfileList,

  fetchAttachfileURLList,
  fetchAttachAccessKey,
  fetchAttachDecrypt,
  // fetchAttachDecryptEcho,

  downloadAttachfileAll,
  downloadAttachfile,
  downloadSharedfile,

  fetchAttachdocumentList,
  fetchSharedFileList,
  fetchHistoryList,
  fetchHistoryView,

  fetchAdminconsoleList,
  fetchAdminconsoleTotal,
  fetchAdminconsolePrev,
  fetchAdminconsoleNext,

  getApprovalDocumentType,
  fetchTotalCount,
  organizationTotalCount: fetchOrganizationTotalCount,
  fetchApprovalLine,
  create,
  receipt,
  temporary,
  reTemporary,

  approbate,
  reject,
  rejectOutgoingDocument,
  withdraw,
  meet,
  holdOn,
  cancelPreviousApprobation,
  cancelApprobation,
  approvalAll,

  createLinked,

  change,
  reReceipt,
  fetchChangeApprovalLine,
  fetchChangeReferencePermission,
  fetchChangeViewPermission,
  fetchAddViewPermission,
  fetchAddReferencePermission,
  fetchDeleteViewPermission,
  fetchDeleteReferencePermission,

  download,
  prevId,
  nextId,

  stateData,
  adminconsoleData,
  attendanceData,
  receiveData,
  attachedData,
  linkedData,

  interworkingLogs: findInterworkingLogs,
  transferInterworkingStatus,
};
