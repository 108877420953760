import React from 'react';
import EmptyLocateRoute from '../../groupware-common/locates/EmptyLocateRoute';
import { getPathParams } from '../../groupware-common/utils';
import AuthenticationBlockedUserRoute from '../pages/adminconsole/blockedUser/AuthenticationBlockedUserRoute';
import AuthenticationLoginRoute from '../pages/adminconsole/login/AuthenticationLoginRoute';
import AuthenticationNetworkRoute from '../pages/adminconsole/network/AuthenticationNetworkRoute';

function AuthenticationLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  key: string;
}): JSX.Element {
  const { pathname, search, hash, key } = props;
  const { menu } = getPathParams<{ menu: string }>('/*/*/:menu', pathname);

  if (menu === 'network')
    return (
      <AuthenticationNetworkRoute
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );

  if (menu === 'login')
    return (
      <AuthenticationLoginRoute pathname={pathname} search={search} key={key} />
    );

  if (menu === 'blockedUser')
    return (
      <AuthenticationBlockedUserRoute pathname={pathname} search={search} />
    );

  return (
    <EmptyLocateRoute
      pathname={pathname}
      search={search}
      hash={hash}
      key={key}
    />
  );
}

export default AuthenticationLocateRoute;
