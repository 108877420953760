import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { securitiesActions } from '../../../../stores/sangsanginsecurities/securities';

function SecuritiesContentRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(sessionActions.clearApprovalForm()),
      dispatch(securitiesActions.list({ search, route: { pathname, search } })),
    ]);
  }, [pathname, search]);

  return null;
}

export default SecuritiesContentRoute;
