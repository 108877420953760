import React from 'react';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostView from '../../../../../components/post/PostView';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function AttendanceWorkContentFolderView(props: {
  paths: string[];
  name: string;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
}): JSX.Element {
  // console.log(`${AttendanceWorkContentFolderView.name}.render`);

  const { paths, name, onAction } = props;
  return (
    <>
      <EuiSetting.Header title={getLocalizedText('분류정보')}>
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={(event) => onAction({ code: 'folder-edit', event })}
        />
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-full"
          onClick={(event) => onAction({ code: 'folder-delete', event })}
        />
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={(event) => onAction({ code: 'folder-sort', event })}
        />
        {/* <Button
          text=getLocalizedText('이동')
          iconType
          icon="folder-move"
          onClick={() => onDialogOpen('move')}
        />
        <Button
          text=getLocalizedText('순서변경')
          iconType
          icon="sort-amount"
          onClick={() => onDrawerOpen('order')}
        /> */}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
            {/* <PostView.Title>{name}</PostView.Title> */}
          </PostView.Head>
          <PostView.Category type="text">
            <PostView.CategoryList>
              {/* <PostView.CategoryItem title=getLocalizedText('상위 분류명')>
                <PostView.CategoryValue value={itemvalues.description} />
              </PostView.CategoryItem> */}
              <PostView.CategoryItem title={getLocalizedText('분류명')}>
                <PostView.CategoryValue value={name} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default AttendanceWorkContentFolderView;
