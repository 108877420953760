import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import Drawer from '../../../../components/drawer/Drawer';
import Comment from '../../../../components/comment/Comment';
import PostView from '../../../../components/post/PostView';
import Loading from '../../../../components/loading/Loading';
import { documentApi } from '../../../apis/approval/v1/document';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import {
  appError,
  getDirectoryData,
} from '../../../../groupware-webapp/stores/common/utils';
import { getCommentItems } from './ApprovalContentBodyViewContainer';
import { getAvatarPath } from '../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import {
  CommentItem,
  documentActions,
} from '../../../stores/approval/document';

function ApprovalCommentDrawerContainer(props: {
  companyId: number;
  type?: string;
  id?: number;
  onClose(): void;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();
  const isOpnions = props.type === 'opinions';

  const [comments, setComments] = useState<CommentItem[]>([]);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { companyId, id, type } = props;
      if (id !== undefined) {
        let response: CommentItem[] | undefined;

        if (type === 'comments')
          response = await documentApi.fetchCommentList(companyId, id);
        else response = await documentApi.fetchOpinionList(companyId, id);
        if (mount) setComments(response);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, [props.companyId, props.id]);

  /** 댓글, 의견 답글 추가 또는 수정. */
  const handleSaveComment = (arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }) => {
    if (props.id === undefined) return;

    const { id, parentId, message, parentWriterKey } = arg;

    try {
      const contents =
        parentWriterKey !== undefined
          ? `${parentWriterKey}┼${message}`
          : message;
      // 의견일 때
      if (isOpnions) {
        dispatch(
          documentActions.saveOpinion({
            id,
            companyId: props.companyId,
            documentId: props.id,
            parentId,
            act: 0,
            contents,
            updateAt: id
              ? comments.find((x) => x.id === id)?.updateAt
              : undefined,
          }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined) {
            const { data } = result.payload as {
              data: CommentItem;
            };
            if (id) {
              setComments(
                comments.map((x) => {
                  if (x.id === id) return data;
                  return x;
                }),
              );
            } else {
              setComments([...comments, data]);
            }
          }
        });
      } else {
        // 댓글일 때
        dispatch(
          documentActions.saveComment({
            companyId: props.companyId,
            documentId: props.id,
            id,
            parentId,
            contents,
            updateAt: id
              ? comments.find((x) => x.id === id)?.updateAt
              : undefined,
          }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined) {
            const { data } = result.payload as {
              data: CommentItem;
            };
            if (id) {
              setComments(
                comments.map((x) => {
                  if (x.id === id) return data;
                  return x;
                }),
              );
            } else {
              setComments([...comments, data]);
            }
          }
        });
      }
    } catch (e) {
      dispatch(sessionActions.error(appError(e)));
    }
  };

  /** 의견, 댓글 삭제. */
  const handleDelete = async (id: number) => {
    if (props.id === undefined) return;

    try {
      const comment = comments?.find((a) => a.id === id);
      if (comment === undefined) return;

      if (isOpnions) {
        dispatch(
          documentActions.deleteOpinion({
            companyId: comment.companyId,
            documentId: comment.documentId,
            id,
            parentId: comment.parentId,
            updateAt: comment.updateAt,
          }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined) {
            const { data } = result.payload as {
              data: CommentItem;
            };
            setComments(comments.filter((x) => x.id !== data.id));
          }
        });
      } else
        dispatch(
          documentActions.deleteComment({
            companyId: comment.companyId,
            documentId: comment.documentId,
            id,
            parentId: comment.parentId,
            updateAt: comment.updateAt,
          }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined) {
            const { data, isReply } = result.payload as {
              data: CommentItem;
              isReply: boolean;
            };
            if (isReply) setComments(comments.filter((x) => x.id !== data.id));
            else
              setComments(comments.map((x) => (x.id === data.id ? data : x)));
          }
        });
    } catch (e) {
      dispatch(sessionActions.error(appError(e)));
    }
  };

  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  const items = getCommentItems(comments, directory);

  return (
    <Drawer
      title={isOpnions ? getLocalizedText('의견') : getLocalizedText('댓글')}
      onClose={props.onClose}
    >
      <DrawerBody>
        {items === undefined || items.length === 0 ? (
          <Loading />
        ) : (
          <PostView>
            <Comment
              className="only"
              opinion={isOpnions}
              count={comments.filter((x) => !x.isDeleted).length}
              user={user}
              comments={items}
              onAdd={handleSaveComment}
              onDelete={handleDelete}
            />
          </PostView>
        )}
      </DrawerBody>
    </Drawer>
  );
}

export default ApprovalCommentDrawerContainer;
