import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getParentItems } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Button from '../../../../../components/button/Button';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Dialog from '../../../../../components/dialog/Dialog';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeId,
  getDirectoryTreeItems,
} from '../../../../../components/tree/DirectoryTree';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import SelectField from '../../../../../components/selectField/SelectField';
import { DirectoryType } from '../../../../../groupware-common/types';
import Chip from '../../../../../components/chip/Chip';
import FeedBack from '../../../../../components/alert/FeedBack';
import { getJobDutyName } from '../../../../stores/directory';

type Props = {
  /** 대표 조직 아이디 */
  representativeOrganizationId: number;
  organizationJobDuties: {
    id: number;
    name: string;
    jobDutyId: number;
  }[];
  onSave(arg: {
    representativeOrganizationId: number;
    organizationJobDuties: {
      id: number;
      name: string;
      jobDutyId: number;
      jobDutyName: string;
      paths: { type: DirectoryType; id: number; name: string }[];
    }[];
  }): void;
  onClose(): void;
};

function OrganizationJobDutySelectDialogContainer(props: Props): JSX.Element {
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );

  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );

  const treeItems = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
      }),
    [companies, organizations],
  );

  const jobDutyItems = useMemo(() => {
    return jobDuties.map(({ id }) => ({
      value: `${id}`,
      label: getJobDutyName(companyId, id, getLocalizedText('미지정')),
    }));
  }, [jobDuties]);

  const [state, setState] = useState<{
    treeSelectedId: string | undefined;
    listSelectedId: number | undefined;
    representativeOrganizationId: number;
    organizationJobDuties: {
      id: number;
      name: string;
      jobDutyId: number;
    }[];
    validation: string;
  }>(() => {
    const { representativeOrganizationId, organizationJobDuties } = props;
    return {
      treeSelectedId: getDirectoryTreeId(
        companyId,
        representativeOrganizationId,
      ),
      listSelectedId: representativeOrganizationId,
      representativeOrganizationId,
      organizationJobDuties,
      validation: '',
    };
  });
  const [filter, setFilter] = useState('');

  /** 디렉터리 트리 항목 필터 */
  const handleTreeItemFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  /** 디렉터리 트리 항목 클릭 */
  const handleTreeItemClick = (arg: DirectoryTreeItemArg) => {
    // console.log('handleTreeItemClick(arg)', arg);

    // const { item: { id: treeId, extra: { type, organizationId, organizationName } } } = arg;
    const { id: treeId, extra } = arg.item;
    const { type, organizationId, organizationName } = extra;

    if (type === 'employee') return;

    setState((prev) => {
      if (prev.organizationJobDuties.find((a) => a.id === organizationId))
        return {
          ...prev,
          treeSelectedId: treeId,
          listSelectedId: organizationId,
        };
      return {
        ...prev,
        treeSelectedId: treeId,
        listSelectedId: organizationId,
        organizationJobDuties: [
          ...prev.organizationJobDuties,
          {
            id: organizationId,
            name: organizationName,
            jobDutyId: 0,
          },
        ],
      };
    });
  };

  /** 조직 직책 항목 선택 */
  const handleOrganizationJobDutiesItemSelect = (id: number) => {
    setState((prev) => ({
      ...prev,
      treeSelectedId: getDirectoryTreeId(companyId, id),
      listSelectedId: id,
    }));
  };

  /** 조직 직책 변경 */
  const handleOrganizationJobDutyChange = (
    organizationId: number,
    jobDutyId: number,
  ) => {
    setState((prev) => ({
      ...prev,
      organizationJobDuties: prev.organizationJobDuties.map((a) => {
        if (a.id === organizationId) return { ...a, jobDutyId };
        return a;
      }),
    }));
  };

  /** 조직 직책 아이템 삭제 */
  const handleOrganizationJobDutyItemDelete = (
    id: number,
    event: React.MouseEvent,
  ) => {
    event.stopPropagation();
    setState((prev) => ({
      ...prev,
      treeSelectedId: undefined,
      listSelectedId: undefined,
      organizationJobDuties: prev.organizationJobDuties.filter(
        (a) => a.id !== id,
      ),
    }));
  };

  /** 조직 직책 아이템 모두 삭제 */
  const handleOrganizationJobDutyItemDeleteAll = () => {
    setState((prev) => ({
      ...prev,
      treeSelectedId: undefined,
      listSelectedId: undefined,
      organizationJobDuties: [],
    }));
  };

  /** 대표 조직 변경 */
  const handleRepresentativeOrganizationChange = (id: number) => {
    setState((prev) => ({ ...prev, representativeOrganizationId: id }));
  };

  /** 저장 */
  const handleSave = () => {
    const { onSave } = props;
    const { representativeOrganizationId, organizationJobDuties } = state;

    if (organizationJobDuties.length === 0) {
      const validation = getLocalizedText('조직을 선택하셔야 합니다.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      !organizationJobDuties.find((a) => a.id === representativeOrganizationId)
    ) {
      const validation = getLocalizedText('대표 조직을 설정하셔야 합니다.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const param = {
      representativeOrganizationId,
      organizationJobDuties: organizationJobDuties.map(
        ({ id, name, jobDutyId }) => ({
          id,
          name,
          jobDutyId,
          jobDutyName: getJobDutyName(
            companyId,
            jobDutyId,
            getLocalizedText('미지정'),
          ),
          paths: getParentItems(
            treeItems,
            getDirectoryTreeId(companyId, id),
          ).map(({ extra: { type, organizationId, organizationName } }) => ({
            type,
            id: organizationId,
            name: organizationName,
          })),
        }),
      ),
    };

    // console.log('handleSave - param', param);
    onSave(param);
  };

  /** 유효성 검증 지우기 */
  const handleValidationClear = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  const {
    treeSelectedId,
    representativeOrganizationId,
    organizationJobDuties,
    listSelectedId,
    validation,
  } = state;

  const useRepresentativeOrganizationChange =
    representativeOrganizationId !== listSelectedId;

  return (
    <>
      <Dialog size="lg" className="ui-dialog-selection">
        <DialogHeader>
          <DialogTitle>{getLocalizedText('조직/직책 선택')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div className="ui-dialog-selection-root">
            <div className="select-area">
              <div className="area-item tree-area">
                <div className="item-head">
                  <div className="title">{getLocalizedText('조직도')}</div>
                </div>
                <div className="item-toolbar">
                  <SimpleSearch
                    keyword={filter}
                    onSearch={handleTreeItemFilter}
                  />
                </div>
                <div className="item-body">
                  <DirectoryTree
                    selectedId={treeSelectedId}
                    items={treeItems}
                    filter={filter}
                    onItemClick={handleTreeItemClick}
                  />
                </div>
              </div>
            </div>
            <div className="selected-area">
              <div className="area-item tree-area">
                <div className="item-head">
                  <div className="title">{getLocalizedText('선택목록')}</div>
                </div>
                <div className="item-body">
                  {organizationJobDuties.map((a) => (
                    <div
                      key={a.id}
                      className="selected-item"
                      aria-selected={a.id === listSelectedId}
                      onClick={() =>
                        handleOrganizationJobDutiesItemSelect(a.id)
                      }
                    >
                      <div className="content">
                        <div className="text">{a.name}</div>
                        {a.id === representativeOrganizationId && (
                          <Chip
                            label={getLocalizedText('대표조직')}
                            theme="success"
                            size="xs"
                          />
                        )}
                      </div>
                      <div className="action">
                        <SelectField
                          data={jobDutyItems}
                          value={`${a.jobDutyId}`}
                          onChange={(value) =>
                            handleOrganizationJobDutyChange(
                              a.id,
                              parseInt(value, 10),
                            )
                          }
                          size="sm"
                        />
                        <Button
                          text={getLocalizedText('삭제')}
                          iconType
                          icon="trash-full"
                          onClick={(event) =>
                            handleOrganizationJobDutyItemDelete(a.id, event)
                          }
                          color="secondary"
                          size="sm"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="item-toolbar">
                  <Button
                    text={getLocalizedText('전체삭제')}
                    variant="outlined"
                    size="sm"
                    onClick={handleOrganizationJobDutyItemDeleteAll}
                  />
                  {listSelectedId && useRepresentativeOrganizationChange && (
                    <Button
                      text={getLocalizedText('대표부서 지정')}
                      variant="outlined"
                      size="sm"
                      onClick={() =>
                        handleRepresentativeOrganizationChange(listSelectedId)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            text={getLocalizedText('취소')}
            color="secondary"
            onClick={props.onClose}
          />
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DialogFooter>
      </Dialog>
      <FeedBack text={validation} onClose={handleValidationClear} />
    </>
  );
}

export default OrganizationJobDutySelectDialogContainer;
