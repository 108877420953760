import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import { go } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { userPreferencesActions } from '../../../../stores/calendar/userPreferences';

function CalendarUserPreferencesBasic(): JSX.Element {
  const dispatch = useAppDispatch();
  const basic = useSelector(
    (state: RootState) => state.calendar.userPreferences.basic,
  );

  const initialState = {
    save: false,
    change: false,
    employeeId: basic.employeeId,
    initialFrame: basic.initialFrame,
    firstDayOfWeek: basic.firstDayOfWeek,
    updateAt: basic.updateAt,
  };

  const [state, setState] = useState(initialState);
  /** 초기화면 변경. */
  const handleChangeInitialFrame = (initialFrame: string) => {
    setState((prev) => ({ ...prev, change: true, initialFrame }));
  };

  /** 한 주의 시작일 변경. */
  const handleChangeFirstDay = (firstDayOfWeek: string) => {
    setState((prev) => ({ ...prev, change: true, firstDayOfWeek }));
  };

  /** 저장. */
  const handleUserPreferencesSave = () => {
    setState((prev) => ({ ...prev, save: true }));
    dispatch(
      userPreferencesActions.saveBasic({
        initialFrame: state.initialFrame,
        firstDayOfWeek: state.firstDayOfWeek,
        updateAt: basic.updateAt,
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const { updateAt } = result.payload as { updateAt: string };
        setState((prev) => ({
          ...prev,
          save: false,
          change: false,
          updateAt,
        }));
      }
    });
  };

  return (
    <>
      <EuiSetting.Item title={getLocalizedText('초기화면')}>
        <RadioGroup
          data={[
            { value: 'DAILY', label: getLocalizedText('일간') },
            { value: 'WEEKLY', label: getLocalizedText('주간') },
            { value: 'MONTHLY', label: getLocalizedText('월간') },
          ]}
          value={state.initialFrame}
          name={getLocalizedText('초기화면')}
          onChange={handleChangeInitialFrame}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('한 주의 시작일')}>
        <RadioGroup
          data={[
            { value: 'SUN', label: getLocalizedText('일요일') },
            { value: 'MON', label: getLocalizedText('월요일') },
          ]}
          value={state.firstDayOfWeek}
          name={getLocalizedText('한 주의 시작')}
          onChange={handleChangeFirstDay}
        />
      </EuiSetting.Item>
      <EuiToolbar>
        <EuiToolbar.Left>
          {state.change && (
            <>
              <Button
                loading={state.save}
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleUserPreferencesSave}
              />
              <Button
                text={getLocalizedText('취소')}
                onClick={() => setState(initialState)}
              />
            </>
          )}
        </EuiToolbar.Left>
        <EuiToolbar.Right>
          <Button
            text={getLocalizedText('캘린더로 돌아가기')}
            onClick={() => go('/calendar')}
          />
        </EuiToolbar.Right>
      </EuiToolbar>
    </>
  );
}

export default CalendarUserPreferencesBasic;
