import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { useDeviceSelectors } from 'react-device-detect';
import * as microsoftTeams from '@microsoft/teams-js';
import { getI18n } from 'react-i18next';
import { RootState, useAppDispatch } from './store';
import { useTeams } from '../hooks/useTeams';
import Signin from '../pages/signin/Signin';
import RootRouter from '../routers/RootRouter';
import AdminConsoleRouter from '../routers/AdminConsoleRouter';
import LoadingContainer from '../pages/common/containers/LoadingContainer';
import DevelopmentRouter from '../routers/DevelopmentRouter';
import { sessionActions } from '../stores/session';
import TestRouter from '../routers/TestRouter';
import ErrorDialog from '../../components/error/ErrorDialog';
import Password from '../pages/signin/Password';
import OneTimePassword from '../pages/signin/OneTimePassword';
import { contrastColor, hexToRgb } from '../../groupware-common/utils/ui';
import TeamsError from '../../components/error/TeamsError';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import ErrorPage from '../../components/error/ErrorPage';
import { getTokenValue } from '../../groupware-common/apis/common/v1';
import PasswordChange from '../pages/signin/PasswordChange';
import Reload from '../../components/reload/Reload';

function App(): JSX.Element {
  const [start, setStart] = useState(true);

  // console.log(`App.render()`);

  const isClooworks = Boolean(process.env.REACT_APP_ISCLOOWORKS); // 클루웍스 구분.
  const dispatch = useAppDispatch();

  const display = useSelector((state: RootState) => state.session.display);
  const themeColor = useSelector(
    (state: RootState) => state.session.basicSetting.themeColor,
  );
  const themeMode = useSelector(
    (state: RootState) => state.session.basicSetting.themeMode,
  );

  /** 인증 여부. */
  const authenticated = useSelector(
    (states: RootState) => states.session.authenticated,
  );
  /** 세션 로그인 여부. */
  const isSessionLogin = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );

  /** 오류 배열. */
  const errors = useSelector((state: RootState) => state.session.errors);
  /** 인증 오류 배열. */
  const authenticatedErrors = useSelector(
    (state: RootState) => state.session.authenticatedError,
  );
  /** 로그인 오류 */
  const signErrors = useSelector(
    (state: RootState) => state.session.signin.errorMessage,
  );
  const { text: loading } = useSelector(
    (state: RootState) => state.session.loading,
  );
  /** 초기 메뉴 */
  const initialModule = useSelector(
    (state: RootState) => state.session.initialModule,
  );
  const isPasswordChangeRequired = useSelector(
    (state: RootState) => state.session.isPasswordChangeRequired,
  );
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  useEffect(() => {
    const i18n = getI18n();
    if (i18n && i18n.language !== currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  const teamsOnThemeChange = useCallback(
    (theme: string) => {
      // console.log(`teamsOnThemeChange(theme: '${theme}')`);
      switch (theme) {
        case 'contrast':
        case 'dark':
          dispatch(
            sessionActions.themeMode({ inTeams: true, designTheme: 'dark' }),
          );
          break;
        case 'default':
        default:
          dispatch(
            sessionActions.themeMode({ inTeams: true, designTheme: 'light' }),
          );
      }
    },
    [dispatch],
  );

  const [{ inTeams, teamsHostClientType }] = useTeams(teamsOnThemeChange);

  // https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/create-tab-pages/content-page#show-a-native-loading-indicator
  useEffect(() => {
    if (inTeams === true) {
      microsoftTeams.app.notifySuccess();
    }
  }, [inTeams]);

  const handleResize = () => {
    document.documentElement.setAttribute('data-display-size', `${display}`);

    if (display === 'pc' && window.innerWidth < 1280) {
      dispatch(sessionActions.display('tablet'));
      // console.log('나는 Tablet');
    }
    if (display === 'tablet' && window.innerWidth >= 1280) {
      dispatch(sessionActions.display('pc'));
      // console.log('나는 PC');
    }
    if (display === 'tablet' && window.innerWidth < 768) {
      dispatch(sessionActions.display('phone'));
      // console.log('나는 Phone');
    }
    if (display === 'phone' && window.innerWidth >= 768) {
      dispatch(sessionActions.display('tablet'));
      // console.log('나는 Tablet');
    }
  };

  handleResize();

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [display]);

  useEffect(() => {
    setStart(false);
    const preventDefault = (e: DragEvent) => {
      e.preventDefault();
    };

    const stopPropagation = (e: DragEvent) => {
      e.stopPropagation();
    };

    // 파일 드래그 앤 드롭 이벤트에 대한 전역 핸들러 등록
    window.addEventListener('dragover', preventDefault);
    window.addEventListener('drop', preventDefault);
    window.addEventListener('dragenter', stopPropagation);
    window.addEventListener('dragleave', stopPropagation);

    return () => {
      // 컴포넌트가 언마운트될 때 핸들러 제거
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
      window.removeEventListener('dragenter', stopPropagation);
      window.removeEventListener('dragleave', stopPropagation);
    };
  }, []);

  /** 테마 컬러 및 모드 */
  const root = document.querySelector('html');
  root?.style.setProperty('--primary-color', themeColor);
  root?.style.setProperty('--primary-text-color', contrastColor(themeColor));
  root?.style.setProperty('--primary-color-rgb', `${hexToRgb(themeColor)}`);
  root?.style.setProperty(
    '--primary-text-color-rgb',
    `${hexToRgb(contrastColor(themeColor))}`,
  );
  root?.style.setProperty(
    '--primary-light-color',
    `rgba(${hexToRgb(themeColor)}, var(--opacity))`,
  );
  document.documentElement.setAttribute('data-theme-mode', themeMode);

  /** 디바이스 체크 */
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  if (selectors.isWindows)
    document.documentElement.setAttribute('data-os', 'windows');
  if (selectors.isMacOs)
    document.documentElement.setAttribute('data-os', 'mac');
  if (selectors.isIOS) document.documentElement.setAttribute('data-os', 'ios');
  if (selectors.isAndroid)
    document.documentElement.setAttribute('data-os', 'android');
  if (selectors.isDesktop)
    document.documentElement.setAttribute('data-device', 'pc');
  if (selectors.isTablet)
    document.documentElement.setAttribute('data-device', 'tablet');
  if (selectors.isMobileOnly)
    document.documentElement.setAttribute('data-device', 'mobile');
  if (selectors.isSmartTV)
    document.documentElement.setAttribute('data-device', 'tv');
  document.documentElement.setAttribute('data-browser', selectors.browserName);

  const location = useLocation();
  const module = location.pathname.substring(1).split('/')[0] || '';
  const folder = location.pathname.substring(1).split('/')[1] || '';
  const queryParams = getQueryParams(location.search);

  // console.log('location', location);
  const paramsHasError = queryParams.error !== undefined;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.onpopstate = (event: PopStateEvent) => {
      // console.log('window.onpopstate:document.location ', document.location);
      // console.log('window.onpopstate:document.event.state ', JSON.stringify(event.state));
    };
  }, [dispatch]);

  const accessToken = getTokenValue('SID');

  if (location.pathname === '/teams') {
    // console.log(`location.pathname === '/teams'`);
    if (inTeams === undefined) return <></>;
    if (inTeams === false)
      return (
        <TeamsError error="잘못된 접근입니다." isClooworks={isClooworks} />
      );

    if (authenticated) {
      // console.log(`teams:authenticated === true`);

      const params = new URLSearchParams(location.search);
      const uri = params.get('uri');
      if (uri === null) return <Redirect to="/" />;

      const [pathname, search] = uri.split('?');
      return <Redirect to={{ pathname, search }} />;
    }
    if (!authenticated && errors.length === 0) {
      // console.log(`teams:authenticated === false && errors.length === 0`);

      const params = new URLSearchParams(location.search);
      const tenantId = params.get('tenantid') ?? undefined;
      const id = params.get('id') ?? '';
      const theme =
        (params.get('theme') ?? 'default') === 'default' ? 'light' : 'dark';
      const uri = params.get('uri') ?? '';
      const { appModule } = getPathParams<{ appModule?: string }>(
        '/:appModule',
        uri,
      );
      document.documentElement.setAttribute('data-platform', 'teams');
      document.documentElement.setAttribute('data-theme-mode', theme);

      dispatch(
        sessionActions.teamsSignin({
          id,
          tenantId,
          appModule,
          theme,
          teamsHostClientType,
        }),
      );

      return (
        <div className="ui-app-loading">
          <div className="logo">
            <img src="/images/company_loading_logo.png" alt="로딩 중" />
          </div>
        </div>
      );
    }

    // console.log(`teams:authenticated === false && errors.length > 0`, errors);
    return (
      <TeamsError
        error={errors[0].message || errors[0].error}
        isClooworks={isClooworks}
      />
    );
  }

  // 연동결재 호출
  if (module === 'approvalinterworking') {
    const { id } = getPathParams<{ id: string }>('/*/*/:id', location.pathname);
    const input = document.getElementById(id);
    // 인증 완료.
    if (authenticated) {
      if (input) input.remove();

      // 연동결재 보기 호출
      if (folder === 'document')
        return (
          <Redirect
            to={{ pathname: `/approval/approvalinterworking/document/${id}` }}
          />
        );

      // 연동결재 상신 호출
      return (
        <Redirect
          to={{ pathname: `/approval/approvalinterworking/compose/${id}` }}
        />
      );
    }
    if (!authenticated && errors.length === 0) {
      // console.log(`api:authenticated === false && errors.length === 0`);
      const value = input?.getAttribute('value');
      if (value === null || value === undefined)
        return <div>잘못된 접근입니다.</div>;

      const theme: 'auto' | 'light' | 'dark' = 'auto';

      document.documentElement.setAttribute('data-theme-mode', theme);

      // 연동결재 보기 로그인.
      if (folder === 'document') dispatch(sessionActions.viewSign({ value }));
      // 연동결재 상신 로그인.
      else dispatch(sessionActions.apiSignin({ value }));

      return (
        <div className="ui-app-loading">
          <div className="logo">
            <img src="/images/company_loading_logo.png" alt="로딩 중" />
          </div>
        </div>
      );
    }

    return (
      <div>{errors[0].message || errors[0].error || (signErrors ?? '')}</div>
    );
  }

  // 테스트용 인증. (운영 시 제거)
  if (location.pathname === '/bypass') {
    if (authenticated) {
      const params = new URLSearchParams(location.search);
      const uri = params.get('uri');
      if (uri === null) return <Redirect to="/" />;

      const [pathname, search] = uri.split('?');
      return <Redirect to={{ pathname, search }} />;
    }
    if (!authenticated && errors.length === 0) {
      // console.log(`teams:authenticated === false && errors.length === 0`);

      const params = new URLSearchParams(location.search);
      const tenantId =
        params.get('tenantid') ?? '6be666ef-f7a6-4e62-8187-80ce5c7cb138';
      const id = params.get('id') ?? '';
      let theme: 'auto' | 'light' | 'dark';
      switch (params.get('theme')) {
        case 'light':
          theme = 'light';
          break;
        case 'dark':
          theme = 'dark';
          break;
        default:
          theme = 'auto';
          break;
      }
      // const uri = params.get('uri') ?? '';

      document.documentElement.setAttribute('data-theme-mode', theme);

      dispatch(sessionActions.bypass({ id, password: '', tenantId, theme }));

      return (
        <div className="ui-app-loading">
          <div className="logo">
            <img src="/images/company_loading_logo.png" alt="로딩 중" />
          </div>
        </div>
      );
    }

    // console.log(`teams:authenticated === false && errors.length > 0`, errors);
    return <TeamsError error={errors[0].message || errors[0].error} />;
  }

  if (!authenticated && location.pathname !== '/signin') {
    // console.log(`authenticated === false && location.pathname !== '/signin'`);

    const resource = document.documentElement.getAttribute('data-platform');
    if (resource === 'teams') {
      return <div>세션이 만료되었습니다.</div>;
    }

    // 개발용 인증 패스 코드.
    if (window.location.hostname === 'localhost' && errors.length === 0) {
      const param = { companyId: 5001, employeeId: 20019 };
      dispatch(sessionActions.authenticate(param));
      return <div>개발용 로그인 ...</div>;
    }

    let search = '';
    const clearHistory =
      localStorage.getItem('clearHistory') === null ||
      localStorage.getItem('clearHistory') !== 'true';
    if (location.pathname !== '/' && clearHistory)
      search = `?uri=${encodeURIComponent(
        location.pathname + location.search,
      )}`;

    // 최초 load된 경우 token이 있을 때 인증 로그인 진행
    if (
      errors.length === 0 &&
      !signErrors &&
      (!authenticatedErrors || authenticatedErrors.length === 0) &&
      accessToken
    ) {
      dispatch(
        sessionActions.jwtAuthenticate({
          isTeams: inTeams ?? false,
          token: accessToken,
        }),
      );

      if (start || (!start && loading !== ''))
        return (
          <div className="ui-app-loading">
            <div className="logo">
              <img src="/images/company_loading_logo.png" alt="로딩 중" />
            </div>
          </div>
        );
    }
    if (isPasswordChangeRequired === 'NONE')
      return <Redirect to={{ pathname: '/signin', search }} />;
  }

  if (!authenticated && location.pathname === '/signin') {
    if (isPasswordChangeRequired !== 'NONE') {
      return (
        <Redirect
          to={{ pathname: '/passwordChange', search: location.search }}
        />
      );
    }
  }

  if (authenticated && location.pathname === '/signin') {
    // console.log(`authenticated === true && location.pathname === '/signin'`);

    const uri = new URLSearchParams(location.search).get('uri');
    if (localStorage.getItem('isCurrentScript') === 'false') {
      localStorage.removeItem('isCurrentScript');
      let path = uri;
      if (path === null)
        path = initialModule === '' ? '/' : `/${initialModule.toLowerCase()}`;
      window.location.replace(path);
    } else if (uri === null) return <Redirect to="/" />;
    else {
      const [pathname, search] = uri.split('?');
      return <Redirect to={{ pathname, search }} />;
    }
  }

  if (authenticated && location.pathname === '/') {
    if (initialModule !== '')
      return <Redirect to={`/${initialModule.toLowerCase()}`} />;
  }

  if (localStorage.getItem('clearHistory') !== null)
    localStorage.removeItem('clearHistory');

  if (paramsHasError)
    return <ErrorPage error={queryParams.error} type={queryParams.type} />;
  // 세션 웹 로그인인 경우 - 세션아이디 없을 때 인증 만료.
  if (!inTeams && isSessionLogin && authenticated && !accessToken)
    dispatch(sessionActions.signout());

  let element = null;
  switch (module as string) {
    case 'signin':
      element = <Signin />;
      break;
    case 'password':
      element = <Password />;
      break;
    case 'passwordChange':
      element = <PasswordChange location={location} />;
      break;
    case 'otp':
      element = <OneTimePassword />;
      break;
    case 'ac':
    case 'adminconsole':
      element = (
        <AdminConsoleRouter location={location} key={currentLanguage} />
      );
      break;
    case 'dev':
    case 'development':
      element = <DevelopmentRouter location={location} />;
      break;
    case 'test':
      element = <TestRouter location={location} />;
      break;
    default:
      element = <RootRouter location={location} key={currentLanguage} />;
      break;
  }

  const handleAuthenticatedErrorConfirm = () => {
    dispatch(sessionActions.authenticatedErrorDelete());
    dispatch(sessionActions.signout());
  };

  const error =
    errors.length === 0
      ? undefined
      : {
          ...errors[0],
          onConfirm: () => dispatch(sessionActions.errorDelete()),
        };
  const authenticatedError =
    authenticatedErrors === undefined || authenticatedErrors.length === 0
      ? undefined
      : {
          ...authenticatedErrors[0],
          onConfirm: handleAuthenticatedErrorConfirm,
        };

  return (
    <>
      {element}
      {window.location.hostname !== 'localhost' && inTeams !== true && (
        <Reload />
      )}
      <LoadingContainer />
      <ErrorDialog
        pathname={location.pathname}
        search={location.search}
        error={error}
        authenticedError={authenticatedError}
      />
    </>
  );
}

export default App;
