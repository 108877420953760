/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Language, LocateArg } from '../../groupware-common/types';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import serviceBasicApi from '../apis/basic';
import roleApi from '../apis/role';

const name = 'service/basic';

interface State {
  basic: {
    initialModule: string;
    languages: Array<Language>;
    updateAt: string;
  };
  administrators: { employeeId: number; updateAt: string }[];
}

const initialState: State = {
  basic: {
    initialModule: 'DASHBOARD',
    languages: ['ko-KR'],
    updateAt: '',
  },
  administrators: [],
};

const findPreferences = createAsyncThunk(
  `${name}/findPreferences`,
  async (arg: { companyId: number } & LocateArg, { rejectWithValue }) => {
    try {
      const { companyId } = arg;
      const response = await serviceBasicApi.findPreferences({ companyId });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      data: {
        companyId: number;
        initialModule: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const response = await serviceBasicApi.update({ ...data });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 조회. */
const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const result = await roleApi.find(companyId);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 추가. */
const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const result = await roleApi.append({
        companyId,
        data: arg,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 삭제. */
const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const result = await roleApi.remove({
        companyId,
        data: arg,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const basicSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findPreferences.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.basic = payload;
      })
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      });
  },
});

export default basicSlice.reducer;

export const serviceBasicActions = {
  findPreferences,
  update,
  findAdministrators,
  appendAdministrators,
  removeAdministrators,
};
