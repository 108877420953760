import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import Button from '../button/Button';

function MenuClose(props: {
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    props.onClick(event);
    event.stopPropagation();
  };
  return (
    <Button
      iconType
      text={getLocalizedText('닫기')}
      icon="times-small"
      size="xs"
      className="context-close"
      onClick={handleClose}
    />
  );
}

export default MenuClose;
