import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import Avatar from '../../../../../components/avatar/Avatar';
import Comment from '../../../../../components/comment/Comment';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Loading from '../../../../../components/loading/Loading';
import PostView from '../../../../../components/post/PostView';
import { getAvatarPath } from '../../../../../groupware-common/utils';
import { createLocalizedTextFactory } from '../../../../../groupware-common/utils/i18n';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  AttachDocument,
  AttachFile,
  documentApi,
} from '../../../../apis/approval/v1/document';
import {
  CommentItem,
  getAffiliatedCompanyId,
  jsonToApprovalLine,
} from '../../../../stores/approval/document';
import { getCommentItems } from '../ApprovalContentBodyViewContainer';

import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import Button from '../../../../../components/button/Button';
import { dateTimeFormat } from '../../../../../groupware-common/utils/ui';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());

  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return `${createLocalizedTextFactory('approval')('{{n}}일', {
      n: day,
    })} ${createLocalizedTextFactory('approval')('{{n}}시간', {
      n: remainder,
    })}`;
  }
  return createLocalizedTextFactory('approval')('{{n}}시간', { n: time });
}

function ApprovalAttachedDocumentSelectDialog(props: {
  companyId?: number;
  id?: number;
  checked?: boolean;
  onClose(): void;
  onClick(arg: { id: number; checked: boolean }): void;
}): JSX.Element {
  // console.log(`ApprovalAttachedDocumentSelectDialog(props)`, props);
  const dispatch = useAppDispatch();
  const getLocalizedText = createLocalizedTextFactory('approval');

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const [state, setState] = useState<{
    loading: boolean;
    document:
      | {
          subject: string; // 제목
          content: string; // 내용
          approvalLine: ApprovalLineType; // 결재선
          draftAt: string; // 기안 날짜
          completeAt?: string; // 완료 날짜
          attachedFiles?: AttachFile[]; // 첨부 파일 배열.
          attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
          opinions?: (CommentItem & { act: number })[]; // 의견 배열.
          comments?: CommentItem[]; // 댓글 배열.
        }
      | null
      | undefined;
  }>({
    loading: true,
    document: undefined,
  });

  useEffect(() => {
    async function run() {
      let data;
      try {
        const { companyId, id } = props;
        if (companyId === undefined || id === undefined)
          throw new Error(getLocalizedText('문서를 찾을 수 없습니다.'));
        const affiliatedCompanyId = getAffiliatedCompanyId(
          companyId,
          principal.companyId,
        );
        const response = await documentApi.linkedData({
          documentId: id,
          affiliatedCompanyId,
        });

        if (response.id !== id) return;

        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const { attachedFileCount, attachedDocumentCount } = response;
        data = {
          ...response,
          approvalLine: jsonToApprovalLine(response.approvalline),
          draftAt: dateTimeFormat(response.draftAt, 'yyyy-MM-DD'),
          completeAt:
            response.completeAt === '1000-01-01'
              ? undefined
              : dateTimeFormat(response.completeAt, 'yyyy-MM-DD'),
          attachedFiles:
            attachedFileCount > 0
              ? await documentApi.fetchAttachfileList(id)
              : undefined,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion
            ? await documentApi.fetchOpinionList(companyId, id)
            : undefined,
          comments: useComment
            ? await documentApi.fetchCommentList(companyId, id)
            : undefined,
        };

        setState({
          loading: false,
          document: {
            subject: data.subject,
            content: data.content,
            approvalLine: data.approvalLine,
            draftAt: data.draftAt,
            completeAt: data.completeAt,
            attachedFiles: data.attachedFiles,
            attachedDocuments: data.attachedDocuments,
            opinions: data.opinions,
            comments: data.comments,
          },
        });
      } catch (e) {
        // 결재 문서 오류 처리
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        dispatch(sessionActions.error((e as Error).message));
      }
    }
    run();
  }, []);

  // Dialog 닫기
  const handleClose = () => {
    props.onClose();
  };

  const handleClick = () => {
    if (props.id === undefined) return;
    props.onClick({ id: props.id, checked: props.checked ?? false });
  };

  /** 의견,댓글 정보 */
  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  if (!state.document) {
    return (
      <Dialog onClose={handleClose}>
        <div style={{ height: window.innerHeight }}>
          <DialogHeader>
            <DialogTitle>{getLocalizedText('첨부문서')}</DialogTitle>
          </DialogHeader>
          <DialogBody>{state.loading && <Loading />}</DialogBody>
        </div>
      </Dialog>
    );
  }

  const {
    subject,
    content,
    approvalLine,
    draftAt,
    completeAt,
    attachedFiles,
    attachedDocuments,
  } = state.document;

  const opinions = getCommentItems(state.document.opinions, directory);
  const comments = getCommentItems(state.document.comments, directory);

  const drafter = getApprovalLineDrafter(approvalLine);
  if (drafter === undefined) return <div>{getLocalizedText('오류')}</div>;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : '';
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(approvalLine) : undefined;
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);
  const style: React.CSSProperties = {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    marginLeft: 'auto',
  };
  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('첨부문서')}</DialogTitle>
        <div style={style}>
          <Button
            text={
              props.checked
                ? getLocalizedText('선택해제')
                : getLocalizedText('선택')
            }
            variant="contained"
            onClick={handleClick}
          />
          <Button
            text={getLocalizedText('닫기')}
            iconType
            icon="close"
            onClick={handleClose}
          />
        </div>
      </DialogHeader>
      <DialogBody height={window.innerHeight}>
        <PostView type="full">
          <PostView.Head>
            <PostView.Title>{subject}</PostView.Title>
            {drafter && (
              <div className="approval-view-info">
                <Avatar
                  className="avatar"
                  name={drafter.employeeName ?? drafter.organizationName}
                  image={drafterAvatar}
                  icon={drafterAvatar === '' ? 'sitemap-fill' : undefined}
                />
                <ApprovalLineFlat approvalLine={approvalLine} />
                <PostView.Info row>
                  <PostView.InfoItem
                    title={getLocalizedText('기안자')}
                    value={drafter.employeeName ?? drafter.organizationName}
                  />
                  <PostView.InfoItem
                    title={getLocalizedText('기안부서')}
                    value={drafter.organizationName}
                  />
                  <PostView.InfoItem
                    title={getLocalizedText('기안일')}
                    value={draftAt}
                  />
                  {completeAt && (
                    <PostView.InfoItem
                      title={getLocalizedText('완료일')}
                      value={completeAt}
                    />
                  )}
                  {waitedAt && (
                    <PostView.InfoItem
                      title={getLocalizedText('대기일')}
                      value={waitedAt}
                    />
                  )}
                </PostView.Info>
              </div>
            )}
          </PostView.Head>
          <PostView.Body>
            <PostView.Content data={content} />
            {attachedFiles && attachedFiles.length > 0 && (
              <AttachmentsList
                count={attachedFiles.length}
                className="view-attachments"
              >
                {attachedFiles &&
                  attachedFiles.map(({ id, name, size }) => (
                    <AttachmentsItem key={id} name={name} size={size} />
                  ))}
              </AttachmentsList>
            )}
            {attachedDocuments && attachedDocuments.length > 0 && (
              <AttachDocumentList count={attachedDocuments.length}>
                {attachedDocuments.map((x) => (
                  <AttachDocumentItem
                    key={x.id}
                    no={x.no}
                    subject={x.subject}
                  />
                ))}
              </AttachDocumentList>
            )}
            {opinions && (
              <Comment
                opinion
                count={opinions.filter((x) => !x.isDeleted).length || 0}
                user={user}
                comments={opinions}
              />
            )}
            {comments && (
              <Comment
                count={comments.filter((x) => !x.isDeleted).length || 0}
                user={user}
                comments={comments}
              />
            )}
          </PostView.Body>
        </PostView>
      </DialogBody>
    </Dialog>
  );
}

export default ApprovalAttachedDocumentSelectDialog;
