import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import Gnb from '../../../../components/navigation/Gnb';
import { Language, Module } from '../../../../groupware-common/types';
import {
  getAvatarPath,
  getQueryParams,
  go,
  parseModule,
} from '../../../../groupware-common/utils';
import { moduleIcon } from '../../../../groupware-common/utils/ui';
import Menu from '../../../../components/menu/Menu';
import MenuItem from '../../../../components/menu/MenuItem';
import MenuDivider from '../../../../components/menu/MenuDivider';
import Icon from '../../../../components/icon/Icon';
import Avatar from '../../../../components/avatar/Avatar';
import SubMenu from '../../../../components/menu/SubMenu';
import {
  directoryEqualityFn,
  directorySelector,
} from '../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../stores/common/utils';
import Dialog from '../../../../components/dialog/Dialog';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import Button from '../../../../components/button/Button';
import { sessionActions } from '../../../stores/session';
import DirectoryTree, {
  getDirectoryTreeItems,
} from '../../../../components/tree/DirectoryTree';
import SimpleSearch from '../../../../components/search/SimpleSearch';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import DialogToolbar from '../../../../components/dialog/DialogToolbar';
import ColorPicker from '../../../../components/color/ColorPicker';
import CustomColorPicker from '../../../../components/color/CustomColorPicker';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';
import LinkMenu from '../../../../components/linkmenu/LinkMenu';

type menuType = 'default' | 'themeMode' | 'department' | 'language';
export type gnbType = 'linkMenu' | 'profile';
type lnbType = menuType | gnbType;

export function languageList(type: string): string {
  switch (type) {
    case 'en-US':
      return 'English';
    case 'zh-CN':
      return '中文 (简体)';
    case 'ja-JP':
      return '日本語';
    case 'vi-VN':
      return 'Tiếng Việt';
    default:
      return '한국어';
  }
}

function themeModeLabel(mode: 'auto' | 'light' | 'dark') {
  switch (mode) {
    case 'light':
      return getLocalizedText('밝은 테마');
    case 'dark':
      return getLocalizedText('어두운 테마');
    default:
      return getLocalizedText('기기 테마 사용');
  }
}

function GnbContainer(props: {
  module: Module;
  onChangeLanguage(language: Language): void;
}) {
  const dispatch = useAppDispatch();

  const languages = useSelector((state: RootState) => state.session.languages);

  const modules = useSelector((state: RootState) => state.session.modules);
  const linkMenus = useSelector((state: RootState) => state.session.linkMenus);
  const themeColor = useSelector(
    (state: RootState) => state.session.basicSetting.themeColor,
  );
  const themeMode = useSelector(
    (state: RootState) => state.session.basicSetting.themeMode,
  );

  // TODO 계정 변경용 테스트 코드
  const route = useSelector((state: RootState) => state.session.route);

  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const isSessionLogin = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );
  // 개발 환경 유무.
  const isDevelopment =
    window.location.hostname === 'localhost' || !isSessionLogin;
  const resource = useSelector((state: RootState) => state.session.resource);
  const roles = useSelector(
    (state: RootState) => state.session.principal.roles,
  );
  const organizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );
  const organizationId =
    organizations.find(({ representative }) => representative)?.id || 0;
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  const directory = useSelector(directorySelector, directoryEqualityFn);

  const directoryData = getDirectoryData({
    ...directory,
    companyId,
    organizationId,
    employeeId,
  });

  const { organizationName, employeeName, jobClassName } = directoryData;
  const avatar = `${getAvatarPath(companyId, employeeId)}/nocache`;

  const items = modules.map((a) => {
    return {
      id: a.code,
      text: getLocalizedText(`모듈.${a.name}`),
      icon: moduleIcon(a.code),
    };
  });

  const [state, setState] = useState<{
    menuType: lnbType;
    menuPoint: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    menuVisible: boolean;
    colorPickerModal: boolean;
  }>({
    menuType: 'default',
    menuPoint: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    colorPickerModal: false,
    menuVisible: false,
  });

  const handleLink = (module: string) => {
    const a = parseModule(module);
    const o = modules.find((x) => x.code === a);
    if (a !== undefined && o !== undefined) go(`/${a}`);
  };

  const handleOpenColorPicker = () => {
    setState((prevState) => ({
      ...prevState,
      colorPickerModal: true,
    }));
    handleMenuClose();
  };

  const handleCloseColorPicker = () => {
    setState((prevState) => ({
      ...prevState,
      colorPickerModal: false,
    }));
  };

  const handleSaveMainTheme = (color: string) => {
    dispatch(sessionActions.themeColor({ colorTheme: color }));
    handleCloseColorPicker();
  };

  const handleChangeThemeMode = (mode: 'auto' | 'light' | 'dark') => {
    dispatch(sessionActions.themeMode({ designTheme: mode }));
    handleMenuClose();
  };

  // TODO 테스트용 계정 변경 팝업. (실서버 적용 시 관련 코드 제거)
  const [accountChangeDialog, setAccountChangeDialog] = useState<
    | {
        selectedId: string;
        filter: string;
        companyId: number;
        employeeId: number;
      }
    | undefined
  >(undefined);

  const handleMenu = (event: React.MouseEvent, type: gnbType) => {
    if (state.menuVisible) handleMenuClose();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      if (type === 'linkMenu') {
        setState((prevState) => ({
          ...prevState,
          menuPoint: {
            x: rect.x + 44,
            y: rect.y + 44,
            width: rect.width,
            height: rect.height,
          },
          menuVisible: true,
          menuType: type,
        }));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        menuPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        menuVisible: true,
        menuType: type,
      }));
    }
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      menuVisible: false,
      menuType: 'default',
    }));
  };

  const handleMenuItemClick = (type: lnbType) => {
    setState((prevState) => ({
      ...prevState,
      menuType: type,
    }));
  };

  /** 로그아웃 */
  const handleLogout = () => {
    localStorage.setItem('clearHistory', 'true');
    dispatch(sessionActions.signout());
  };

  const getMenu = () => {
    let result = null;
    if (!state.menuVisible) return result;

    let body = null;
    let width: undefined | number = 280;
    let height: undefined | number;

    switch (state.menuType) {
      case 'default':
      case 'profile':
        body = (
          <>
            <div className="context-content context-profile">
              <Avatar className="avatar" name={employeeName} image={avatar} />
              <div className="info">
                <div className="name">
                  {jobClassName
                    ? `${employeeName} ${jobClassName}`
                    : employeeName}
                </div>
                <div className="meta">
                  <Icon
                    className="badge"
                    label={getLocalizedText('소속')}
                    icon="sitemap-fill"
                  />
                  <span>{organizationName}</span>
                </div>
                <div className="meta">
                  <Icon
                    className="badge"
                    label={getLocalizedText('최근접속')}
                    icon="sign-in"
                  />
                  <span className="time">2022.1.1 13:30:25</span>
                </div>
              </div>
            </div>
            <MenuDivider />
            {(isDevelopment || resource !== 'web') && (
              <MenuItem
                label={getLocalizedText('테스트용 계정 변경')}
                icon="user-circle"
                onClick={() => {
                  handleMenuClose();
                  setAccountChangeDialog({
                    selectedId: `${companyId}_${companyId}`,
                    filter: '',
                    companyId,
                    employeeId,
                  });
                }}
              />
            )}
            <MenuItem
              label={getLocalizedText('기본 정보')}
              icon="user-circle"
              onClick={() => go('/setting/information')}
            />
            <MenuItem
              label={getLocalizedText('로그아웃')}
              icon="sign-out"
              onClick={handleLogout}
            />
            <MenuDivider />
            {isDevelopment && (
              <MenuItem label={getLocalizedText('메뉴설정')} icon="bar-cog" />
            )}
            <MenuItem
              label={getLocalizedText('환경설정')}
              icon="cog"
              onClick={() => go('/setting')}
            />
            {/* 개발 환경이거나 관리자 권한이 존재할 경우. */}
            {(isDevelopment || roles.length > 0) && (
              <MenuItem
                label={getLocalizedText('관리자페이지')}
                icon="user-cog"
                onClick={() => go('/adminconsole')}
              />
            )}
            <MenuDivider />
            <MenuItem
              label={getLocalizedText('테마')}
              icon="palette"
              haspopup
              onClick={handleOpenColorPicker}
            >
              <ColorPicker color={themeColor} />
            </MenuItem>
            <MenuItem
              label={getLocalizedText('디자인')}
              value={themeModeLabel(themeMode)}
              icon="dark-mode"
              haspopup
              onClick={() => handleMenuItemClick('themeMode')}
            />
            {/* <MenuItem
              label="겸직부서"
              value="솔루션개발부"
              icon="sitemap"
              haspopup
              onClick={() => handleMenuItemClick('department')}
            /> */}
            <MenuItem
              label={getLocalizedText('언어')}
              value={languageList(currentLanguage)}
              icon="global"
              haspopup
              onClick={() => handleMenuItemClick('language')}
            />
          </>
        );
        break;
      case 'linkMenu': {
        width = 320;
        height = 302;
        body = (
          <>
            <div className="eui-link-menu">
              {linkMenus.map((x) => {
                return (
                  <a href={`${x.url}`} target="_blank" rel="noreferrer">
                    <div className="eui-link-item">
                      <div className="image-wrapper">
                        <LinkMenu imagePath={x.imagePath} />
                      </div>
                      <div className="text-wrapper">
                        <span>{x.name}</span>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </>
        );
        break;
      }
      case 'department': {
        const department = [
          { id: '1', label: '솔루션개발부' },
          { id: '2', label: '연구소' },
        ];
        body = (
          <SubMenu
            title={getLocalizedText('부서선택')}
            onBack={() => handleMenuItemClick('default')}
          >
            {department.map((x) => {
              return (
                <MenuItem key={x.id} label={x.label} checked={x.id === '1'} />
              );
            })}
          </SubMenu>
        );
        break;
      }
      case 'themeMode': {
        const themeModes: {
          value: 'auto' | 'light' | 'dark';
          label: string;
        }[] = [
          { value: 'auto', label: getLocalizedText('기기 테마 사용') },
          { value: 'dark', label: getLocalizedText('어두운 테마') },
          { value: 'light', label: getLocalizedText('밝은 테마') },
        ];
        body = (
          <SubMenu
            title={getLocalizedText('디자인')}
            onBack={() => handleMenuItemClick('default')}
          >
            {themeModes.map((x) => {
              return (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  checked={x.value === themeMode}
                  onClick={() => handleChangeThemeMode(x.value)}
                />
              );
            })}
          </SubMenu>
        );
        break;
      }
      case 'language':
        body = (
          <SubMenu
            title={getLocalizedText('언어선택')}
            onBack={() => handleMenuItemClick('default')}
          >
            {languages.map((x) => {
              return (
                <MenuItem
                  key={x}
                  label={languageList(x)}
                  onClick={() => {
                    props.onChangeLanguage(x);
                    handleMenuClose();
                  }}
                  checked={x === currentLanguage}
                  flag={x.substr(-2)}
                />
              );
            })}
          </SubMenu>
        );
        break;
      default:
        break;
    }

    result = (
      <Menu
        point={state.menuPoint}
        onClose={handleMenuClose}
        width={width}
        height={height}
      >
        {body}
      </Menu>
    );

    return result;
  };

  const renderTestDialog = () => {
    if (accountChangeDialog === undefined) return null;

    const treeItems = getDirectoryTreeItems({ ...directory });

    return (
      <Dialog size="xs" align="start">
        <DialogHeader>
          <DialogTitle>{getLocalizedText('테스트용 계정 변경')}</DialogTitle>
        </DialogHeader>
        <DialogToolbar>
          <SimpleSearch
            keyword={accountChangeDialog.filter}
            onSearch={(event) => {
              const filter = event.currentTarget.value;
              setAccountChangeDialog({ ...accountChangeDialog, filter });
            }}
          />
        </DialogToolbar>
        <DialogBody>
          <DirectoryTree
            selectedId={accountChangeDialog.selectedId}
            items={treeItems}
            filter={accountChangeDialog.filter}
            onItemClick={(arg) => {
              if (arg.item.extra.type === 'employee') {
                setAccountChangeDialog({
                  selectedId: arg.item.id,
                  filter: accountChangeDialog.filter,
                  companyId: arg.item.extra.companyId,
                  employeeId: arg.item.extra.employeeId,
                });
              }
            }}
          />
        </DialogBody>
        <DialogFooter>
          <Button
            text={getLocalizedText('취소')}
            onClick={() => setAccountChangeDialog(undefined)}
            color="secondary"
          />
          <Button
            text={getLocalizedText('확인')}
            variant="contained"
            onClick={() =>
              dispatch(sessionActions.authenticate(accountChangeDialog)).then(
                (result) => {
                  if ((result as { error?: string }).error === undefined) {
                    setAccountChangeDialog(undefined);
                    const qs = getQueryParams(route.search);
                    // eslint-disable-next-line dot-notation
                    qs['_'] = `${Date.now()}`;
                    go(route.pathname, qs, undefined, 'replace');
                  }
                },
              )
            }
          />
        </DialogFooter>
      </Dialog>
    );
  };

  const { module } = props;

  return (
    <>
      <Gnb
        id={module}
        items={items}
        linkMenus={linkMenus}
        employeeName={employeeName}
        avatar={avatar}
        onItemClick={handleLink}
        onMenu={handleMenu}
      />
      {getMenu()}
      {renderTestDialog()}
      {state.colorPickerModal && (
        <CustomColorPicker
          color={themeColor}
          description={getLocalizedText('메인 테마 색상을 선택하세요.')}
          onSave={handleSaveMainTheme}
          onClose={handleCloseColorPicker}
        />
      )}
    </>
  );
}

export default React.memo(GnbContainer);
