import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { appError } from '../../groupware-webapp/stores/common/utils';
import preferencesApi from '../apis/v1/dashboard/preferences';
import { RootState } from '../../groupware-webapp/app/store';

const name = 'dashboard/preferences';

interface State {
  basic: {
    portalPriority: 'COMPANY' | 'EMPLOYEE';
    useEmployeePortal: boolean;
    updateAt: string;
  };
  administrators: { employeeId: number; updateAt: string }[]; // 관리자
}

const initialState: State = {
  basic: {
    portalPriority: 'COMPANY',
    useEmployeePortal: true,
    updateAt: '1000-01-01',
  },
  administrators: [],
};

/** 관리자 조회. */
const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (_: void | LocateArg, { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const data = await preferencesApi.find({
        companyId,
        role: 'DASHBOARD_ADMIN',
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 추가. */
const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue, getState }) => {
    try {
      if (arg.length === 0) return undefined;
      const { companyId } = (getState() as RootState).session.principal;
      const data = await preferencesApi.append({
        companyId,
        data: arg.map((a) => ({ ...a, role: 'DASHBOARD_ADMIN' as const })),
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 삭제. */
const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue, getState },
  ) => {
    try {
      if (arg.length === 0) return undefined;
      const { companyId } = (getState() as RootState).session.principal;
      const data = await preferencesApi.remove({
        companyId,
        data: arg.map((a) => ({ ...a, role: 'DASHBOARD_ADMIN' as const })),
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 기본설정 조회. */
const findPreferences = createAsyncThunk(
  `${name}/findPreferences`,
  async (_: void | LocateArg, { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const data = await preferencesApi.findPreferences({ companyId });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 기본설정 수정. */
const updatePreferences = createAsyncThunk(
  `${name}/updatePreferences`,
  async (
    arg: {
      data: {
        portalPriority: 'COMPANY' | 'EMPLOYEE';
        useEmployeePortal: boolean;
        updateAt: string;
      };
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const data = await preferencesApi.updatePreferences({
        companyId,
        ...arg.data,
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const preferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      })
      .addCase(findPreferences.fulfilled, (state, { payload }) => {
        state.basic = { ...state.basic, ...payload };
      })
      .addCase(updatePreferences.fulfilled, (state, { payload }) => {
        state.basic = { ...state.basic, ...payload };
      });
  },
});

export default preferencesSlice.reducer;

export const preferencesActions = {
  findAdministrators,
  appendAdministrators,
  removeAdministrators,
  findPreferences,
  updatePreferences,
};
