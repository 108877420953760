/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { LocateArg } from '../../../groupware-common/types';
import roleApi from '../../apis/attendance/v1/role';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import attendanceFormApi from '../../apis/attendance/v1/form';
import AttendancePreferencesApi from '../../apis/attendance/v1/preferences';
import dayOffStatusApi from '../../apis/attendance/v1/dayOffStatus';
import { dateFormat } from '../../../groupware-common/utils/ui';

/**  근태 기본설정 */
const name = 'attendance/preferences';

export interface AttendanceCodeList {
  id: number; // 아이디.
  name: string; // 근태 코드 명.
  seq: number; // 순서
  operationType: number; // 기능 작동 구분. (0: 기능 없음, 1: 연차 차감, 2: 대휴 차감, 3: 대휴 발생)
  updateAt: string; // 수정 일/시간.
}

export interface AttendanceStandardDate {
  annualGenerationStandardType: number; // 연차생성기준 (1 :입사일 기준 , 2 : 회계년도 기준)
  decimalHandlingType: number; // 소수점 처리 구분 (0: 내림, 1: 반올림, 2: 올림)
  useLeaveExcess: boolean; // 연차초과사용 사용여부
  startDate: string; // 회계연도 시작일
  endDate: string; // 회계연도 시작일
  countsYearList: {
    workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
    isAutoCalculate: boolean; // 자동 계산 여부 (false 시 지정된 개수 부여)
    fixedCounts: number; // 연차 지정 개수
    updateAt: string; // 수정 날짜/시작
    lookupDeleteAt?: string; // 삭제 시 updateAt.
  }[];
  updateAt: string; // 수정 날짜
}

interface PreferencesState {
  // 근태 기본 설정.
  basic: {
    companyId: number;
    expressionUnit: 'DAY' | 'MINUTE';
    updateAt: string;
  };
  /** 근무 시간 고정 값 - 휴게시간은 배열로. */
  workingHours: {
    workTime: string; // 출근 시간.
    offTime: string; // 퇴근 시간.
    breakeTime: {
      start: string; // 휴게 시작 시간.
      end: string; // 휴게 종료 시간.
    }[];
    totalWorkTime: number; // 총 근무시간 (퇴근시간-출근시간-휴게(제외)시간)
    updateAt: string;
  };
  // 근태 관리자
  administrators: { employeeId: number; updateAt: string }[];
  // 연차 기본 설정.
  standard: {
    isExists: boolean;
    leave?: {
      leaveOccurType: number;
      updateAt: string;
    };
    data: AttendanceStandardDate;
  };
  rejection: {
    laborRejectFormId: number; // 노무수령거부통지 양식 아이디.
    laborRejectContents: string; // 노무수령거부통지 양식 내용.
    laborRejectFormName: string; // 노무수령거부통지 양식 이름.
    updateAt: string; // 수정 날짜/시간.
    laborRejectType: number; // 노무수령거부통지서 사용여부(0 : 사용안함 , 1 : 사용함)
  };
  notice: {
    companyId: number;
    alertEmployeeId: number; // 촉진 담당자 아이디
    alertFormId: number; // 연차촉진 알림 문서 양식 아이디
    alertFormName: string; // 연차촉진 알림 문서 양식 이름
    alertFormContents: string; // 연차촉진 알림 문서 양식 내용
    usePlanFormId: number; // 연차사용계획 문서 양식 아이디
    usePlanFormName: string; // 연차사용계획 문서 양식 이름
    usePlanFormContents: string; // 연차사용계획 문서 양식 내용
    planNotifyFormId: number; // 지정일통보 문서 양식 아이디
    planNotifyFormName: string; // 지정일통보 알림 문서 양식 이름
    planNotifyFormContents: string; // 지정일통보 알림 문서 양식 내용
    updateAt: string; // 수정 날짜
    alertType: number; // 연차촉진알림 상태값(0 : 사용안함 , 1 : 법적기준 , 2 : 자체 설정)
  };
  attendanceCode: {
    list: AttendanceCodeList[];
    view: AttendanceCodeList | undefined;
  };
}

const initialState: PreferencesState = {
  basic: {
    companyId: 0,
    expressionUnit: 'DAY',
    updateAt: '',
  },
  workingHours: {
    workTime: '09:00',
    offTime: '18:00',
    breakeTime: [],
    totalWorkTime: 8,
    updateAt: '',
  },
  administrators: [],
  standard: {
    isExists: false,
    data: {
      annualGenerationStandardType: 0,
      decimalHandlingType: 1,
      useLeaveExcess: false,
      startDate: '0101',
      endDate: '1231',
      countsYearList: [],
      updateAt: '',
    },
  },
  rejection: {
    laborRejectFormId: 0,
    laborRejectContents: '',
    laborRejectFormName: '',
    updateAt: '',
    laborRejectType: 0,
  },
  notice: {
    companyId: 0,
    alertEmployeeId: 0,
    alertFormId: 0,
    alertFormName: '',
    alertFormContents: '',
    usePlanFormId: 0,
    usePlanFormName: '',
    usePlanFormContents: '',
    planNotifyFormId: 0,
    planNotifyFormName: '',
    planNotifyFormContents: '',
    updateAt: '',
    alertType: 0,
  },
  attendanceCode: {
    list: [],
    view: undefined,
  },
};

/** 00:00 형태 string 시간,분으로 변환. */
function stringToTime(date: string) {
  const start = parseInt(date.split(':')[0], 10);
  const end = parseInt(date.split(':')[1], 10);
  return { start, end };
}

/** 날짜계산 후 분으로 환산. */
function getTimeToMinutes(startDate: Date, endDate: Date) {
  const minutes = (endDate.getTime() - startDate.getTime()) / 1000 / 60;
  return minutes;
}

/** 총 근무시간 구하기 (퇴근시간-출근시간-휴게(제외)시간) */
function getTotalWorkTime(workingHours: {
  workTime: string; // 출근 시간.
  offTime: string; // 퇴근 시간.
  breakeTime: {
    start: string; // 휴게 시작 시간.
    end: string; // 휴게 종료 시간.
  }[];
}) {
  const { start: fixedWorkHours, end: fixedWorkMins } = stringToTime(
    workingHours.workTime,
  );
  const { start: fixedOffHours, end: fixedOffMins } = stringToTime(
    workingHours.offTime,
  );

  const start = new Date(new Date().setHours(fixedWorkHours, fixedWorkMins));
  const end = new Date(new Date().setHours(fixedOffHours, fixedOffMins));
  let total = getTimeToMinutes(start, end);

  workingHours.breakeTime.forEach((a) => {
    const { start: startHours, end: startMins } = stringToTime(a.start);
    const { start: endHours, end: endMins } = stringToTime(a.end);
    const breakeStart = new Date(new Date().setHours(startHours, startMins));
    const breakeEnd = new Date(new Date().setHours(endHours, endMins));
    // 근무 시간에 포함되는 휴게시간일 경우.
    if (
      moment(start).isSameOrBefore(breakeStart) &&
      moment(breakeEnd).isSameOrBefore(end)
    )
      total -= getTimeToMinutes(breakeStart, breakeEnd);
  });
  return total;
}

/** 연차생성기준 초기값 조회 */
const findInitialStandard = createAsyncThunk(
  `${name}/findInitialStandard`,
  async (arg: { type: 'enter' | 'account' }, { rejectWithValue }) => {
    try {
      const response = await AttendancePreferencesApi.initialStandard(arg.type);

      const {
        companyId,
        accountingStartDate,
        accountingEndDate,
        decimalHandlingType,
        countsYearList,
        useLeaveExcess,
        updateAt,
        leaveOccurType,
      } = response;

      return {
        companyId,
        startDate: accountingStartDate,
        endDate: accountingEndDate,
        decimalHandlingType,
        useLeaveExcess,
        countsYearList: countsYearList.map((a) => ({
          ...a,
          isAutoCalculate: a.isAutoCalculate === 1,
        })),
        annualGenerationStandardType: leaveOccurType,
        updateAt,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연차생성기준 조회 */
const findStandard = createAsyncThunk(
  `${name}/findStandard`,
  async (_: void | LocateArg, thunkApi) => {
    try {
      const standardDate = dateFormat(new Date(), 'yyyy-MM-DD');
      const { isExists } = await AttendancePreferencesApi.isExistsDayOff(
        standardDate,
      );

      if (!isExists) return { isExists };

      const response = await AttendancePreferencesApi.selectStandard();

      const {
        companyId,
        accountingStartDate,
        accountingEndDate,
        decimalHandlingType,
        countsYearList,
        useLeaveExcess,
        updateAt,
        leaveOccurType,
      } = response;

      return {
        isExists,
        data: {
          companyId,
          startDate: accountingStartDate,
          endDate: accountingEndDate,
          decimalHandlingType,
          useLeaveExcess,
          countsYearList: countsYearList.map((a) => ({
            ...a,
            isAutoCalculate: a.isAutoCalculate === 1,
          })),
          annualGenerationStandardType: leaveOccurType,
          updateAt,
        },
      };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

/** 연차생성기준 저장 */
const modifyStandard = createAsyncThunk(
  `${name}/modifyStandard`,
  async (
    arg: {
      startDate: string; // 회계 시작일
      endDate: string; // 회계 종료일
      decimalHandlingType: number; // 소수점 처리 구분
      useMinusLeaves: boolean; // 마이너스 연차 적용 여부
      useLeaveExcess: boolean; // 연차 초과 사용 여부
      countsYearList: {
        workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
        isAutoCalculate: boolean; // 자동 계산 여부 (false 시 지정된 개수 부여)
        fixedCounts: number; // 연차 지정 개수
        updateAt: string | null; // 수정 날짜/시작
        lookupDeleteAt?: string; // 삭제 시 updateAt.
      }[];
      updateAt: string; // 수정 날짜
    },
    { rejectWithValue },
  ) => {
    try {
      const param = {
        accountingStartDate: arg.startDate,
        accountingEndDate: arg.endDate,
        decimalHandlingType: arg.decimalHandlingType,
        useMinusLeaves: arg.useMinusLeaves,
        useLeaveExcess: arg.useLeaveExcess,
        countsYearList: arg.countsYearList.map((a) => ({
          ...a,
          isAutoCalculate: a.isAutoCalculate ? 1 : 0,
        })),
        updateAt: arg.updateAt,
      };

      await AttendancePreferencesApi.updateStandard(param);
      const response = await AttendancePreferencesApi.selectStandard();
      const {
        companyId,
        accountingStartDate: startDate,
        accountingEndDate: endDate,
        decimalHandlingType,
        useLeaveExcess,
        countsYearList,
        updateAt,
        leaveOccurType: annualGenerationStandardType,
      } = response;

      return {
        companyId,
        startDate,
        endDate,
        decimalHandlingType,
        useLeaveExcess,
        countsYearList: countsYearList.map((a) => ({
          ...a,
          isAutoCalculate: a.isAutoCalculate === 1,
        })),
        updateAt,
        annualGenerationStandardType,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연차 생성 기준에 따라 연차 발생. */
const createOccurs = createAsyncThunk(
  `${name}/createOccurs`,
  async (
    arg: {
      type: 'account' | 'enter';
      standardDate: string;
      creatorId: number;
      createAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { type, ...data } = arg;
      if (type === 'account') await dayOffStatusApi.createAccountOccurs(data);
      else await dayOffStatusApi.createEnterDateOccurs(data);

      const { isExists } = await AttendancePreferencesApi.isExistsDayOff(
        data.standardDate,
      );

      return isExists;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연차 생성 기준 조회. */
const findUserStandard = createAsyncThunk(
  `${name}/findUserStandard`,
  async (arg: { isErrorThrow?: boolean }, { rejectWithValue }) => {
    try {
      const standardDate = dateFormat(new Date(), 'yyyy-MM-DD');
      const { isExists } = await AttendancePreferencesApi.isExistsDayOff(
        standardDate,
      );

      if (!isExists) return { isExists, leave: undefined };
      const resposne = await AttendancePreferencesApi.selectUserStandard();
      return {
        isExists,
        leave: resposne,
      };
    } catch (ex) {
      if (arg.isErrorThrow) return { isExists: false };
      return rejectWithValue(appError(ex));
    }
  },
);

/** 노무수령거부통지 조회 */
const findRejection = createAsyncThunk(
  `${name}/findRejection`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      const response = await AttendancePreferencesApi.selectRejection();

      const { laborRejectFormId, updateAt, laborRejectType } = response;
      let laborRejectContents = '';
      let laborRejectFormName = '';

      if (laborRejectFormId !== 0) {
        await attendanceFormApi.view(laborRejectFormId).then((result) => {
          if (result !== null && result.status === 1) {
            laborRejectContents = result.contents;
            laborRejectFormName = result.name;
          }
        });
      }

      return {
        laborRejectFormId,
        laborRejectContents,
        laborRejectFormName,
        updateAt,
        laborRejectType,
      };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 노무수령거부통지 저장 */
const updateRejection = createAsyncThunk(
  `${name}/updateRejection`,
  async (
    arg: {
      laborRejectFormId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await AttendancePreferencesApi.updateRejection(arg);
      const { laborRejectFormId, updateAt, laborRejectType } = response;
      let laborRejectContents = '';
      let laborRejectFormName = '';

      if (laborRejectFormId !== 0) {
        await attendanceFormApi.view(laborRejectFormId).then((result) => {
          if (result !== null && result.status === 1) {
            laborRejectContents = result.contents;
            laborRejectFormName = result.name;
          }
        });
      }

      return {
        laborRejectFormId,
        laborRejectContents,
        laborRejectFormName,
        updateAt,
        laborRejectType,
      };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 연차사용촉진알림 조회 */
const findNotice = createAsyncThunk(
  `${name}/findNotice`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      const response = await AttendancePreferencesApi.selectNotice();
      const {
        companyId,
        alertEmployeeId,
        alertFormId,
        workerPlanFormId: usePlanFormId,
        noticeFormId: planNotifyFormId,
        updateAt,
        alertType,
      } = response;

      let alertFormName = '';
      let alertFormContents = '';
      if (alertFormId !== 0) {
        await attendanceFormApi.view(alertFormId).then((result) => {
          if (result !== null && result.status === 1) {
            alertFormName = result.name;
            alertFormContents = result.contents;
          }
        });
      }

      let usePlanFormName = '';
      let usePlanFormContents = '';
      if (usePlanFormId !== 0) {
        await attendanceFormApi.view(usePlanFormId).then((result) => {
          if (result !== null && result.status === 1) {
            usePlanFormName = result.name;
            usePlanFormContents = result.contents;
          }
        });
      }

      let planNotifyFormName = '';
      let planNotifyFormContents = '';
      if (planNotifyFormId !== 0) {
        await attendanceFormApi.view(planNotifyFormId).then((result) => {
          if (result !== null && result.status === 1) {
            planNotifyFormName = result.name;
            planNotifyFormContents = result.contents;
          }
        });
      }

      return {
        companyId,
        alertType: alertType ?? 0,
        alertEmployeeId,
        alertFormId,
        alertFormName,
        usePlanFormId,
        usePlanFormName,
        planNotifyFormId,
        planNotifyFormName,
        alertFormContents,
        usePlanFormContents,
        planNotifyFormContents,
        updateAt,
      };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

/** 연차사용촉진알림 저장 */
const modifyNotice = createAsyncThunk(
  `${name}/modifyNotice`,
  async (
    arg: {
      alertType: number;
      alertEmployeeId?: number;
      alertDateLess?: string;
      alertDateOver?: string;
      alertFormId?: number;
      usePlanFormId?: number;
      planNotifyFormId?: number;
      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await AttendancePreferencesApi.updateNotice(arg);

      const { companyId, updateAt, alertType, alertEmployeeId, alertFormId } =
        response;

      let alertFormName = '';
      let alertFormContents = '';

      if (alertFormId !== 0) {
        await attendanceFormApi.view(alertFormId).then((result) => {
          if (result !== null && result.status === 1) {
            alertFormName = result.name;
            alertFormContents = result.contents;
          }
        });
      }

      const usePlanFormId = 0;
      const planNotifyFormId = 0;
      const usePlanFormName = '';
      const planNotifyFormName = '';
      const usePlanFormContents = '';
      const planNotifyFormContents = '';

      return {
        companyId,
        alertFormId,
        alertFormName,
        alertFormContents,
        usePlanFormContents,
        planNotifyFormContents,
        usePlanFormId,
        usePlanFormName,
        planNotifyFormId,
        planNotifyFormName,
        updateAt,
        alertType: alertType ?? 0,
        alertEmployeeId,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 기본설정 - 근태수량기준 조회. */
const findAttendanceBasic = createAsyncThunk(
  `${name}/findAttendanceBasic`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      const data = await AttendancePreferencesApi.findAttendanceBasic();
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 기본설정 - 근태수량기준 저장 */
const updateAttendanceBasic = createAsyncThunk(
  `${name}/updateAttendanceBasic`,
  async (
    arg: {
      expressionUnit: 'DAY' | 'MINUTE';
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await AttendancePreferencesApi.updateAttendanceBasic({
        expressionUnit: arg.expressionUnit,
        updateAt: arg.updateAt,
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 기본설정 - 관리자 조회. */
const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (_: void, { rejectWithValue }) => {
    try {
      const data = await roleApi.find('ATTENDANCE_ADMIN');
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 기본설정 - 관리자 추가. */
const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue }) => {
    try {
      const data = await roleApi.append(
        arg.map((a) => ({ ...a, role: 'ATTENDANCE_ADMIN' as const })),
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 기본설정 - 관리자 삭제. */
const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue },
  ) => {
    try {
      const data = await roleApi.remove(
        arg.map((a) => ({ ...a, role: 'ATTENDANCE_ADMIN' as const })),
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 코드 리스트 조회. */
const findAttendanceCodeList = createAsyncThunk(
  `${name}/findAttendanceCodeList`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      const list: AttendanceCodeList[] =
        await AttendancePreferencesApi.findAttendanceCode();
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 코드 뷰 조회. */
const findAttendanceCodeView = createAsyncThunk(
  `${name}/findAttendanceCodeView`,
  async (
    arg: {
      id: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { list } = (getState() as RootState).attendance.preferences
        .attendanceCode;
      const data = list.find((a) => a.id === arg.id);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 코드 저장 */
const createAttendanceCode = createAsyncThunk(
  `${name}/createAttendanceCode`,
  async (
    arg: {
      name: string;
      operationType: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { operationType } = arg;
    try {
      const data = await AttendancePreferencesApi.createAttendanceCode({
        name: arg.name,
        operationType,
      });
      const returnData: AttendanceCodeList = {
        id: data.id,
        name: data.name,
        seq: data.seq,
        operationType: data.operationType,
        updateAt: data.updateAt,
      };
      return returnData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 코드 수정 */
const updateAttendanceCode = createAsyncThunk(
  `${name}/updateAttendanceCode`,
  async (
    arg: {
      id: number;
      name: string;
      operationType: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { id, operationType, updateAt } = arg;
    try {
      const data = await AttendancePreferencesApi.updateAttendanceCode({
        id,
        name: arg.name,
        operationType,
        updateAt,
      });
      const returnData: AttendanceCodeList = {
        id: data.id,
        name: data.name,
        seq: data.seq,
        operationType: data.operationType,
        updateAt: data.updateAt,
      };
      return returnData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 코드 리스트 순서 변경. */
const updateAttendanceSeq = createAsyncThunk(
  `${name}/updateAttendanceSeq`,
  async (
    arg: {
      param: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { param } = arg;
    try {
      const data = await AttendancePreferencesApi.updateAttendanceCodeSeq(
        param,
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 코드 삭제 */
const deleteAttendanceCode = createAsyncThunk(
  `${name}/deleteAttendanceCode`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    const { id, updateAt } = arg;
    try {
      const data = await AttendancePreferencesApi.deleteAttendanceCode({
        id,
        updateAt,
      });
      return data;
    } catch (ex) {
      await dispatch(sessionActions.setDialog());
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 기본 근무 시간 조회. */
const findDefaultWorktime = createAsyncThunk(
  `${name}/findDefaultWorktime`,
  async (_: void | LocateArg, { rejectWithValue }) => {
    try {
      const result = await AttendancePreferencesApi.findDefaultWorktime();
      const workTime = `${result.worktimeStart.slice(
        0,
        2,
      )}:${result.worktimeStart.slice(2, 4)}`;
      const offTime = `${result.worktimeEnd.slice(
        0,
        2,
      )}:${result.worktimeEnd.slice(2, 4)}`;
      const breakeTime = [
        {
          start: `${result.resttimeStart.slice(
            0,
            2,
          )}:${result.resttimeStart.slice(2, 4)}`,
          end: `${result.resttimeEnd.slice(0, 2)}:${result.resttimeEnd.slice(
            2,
            4,
          )}`,
        },
      ];

      const totalWorkTime = getTotalWorkTime({
        workTime,
        offTime,
        breakeTime,
      });

      const data = {
        workTime,
        offTime,
        breakeTime,
        updateAt: result.updateAt,
        totalWorkTime,
      };

      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const attendancePreferencesSlice = createSlice({
  name,
  initialState,
  reducers: {
    attendanceCodeViewClear(state) {
      state.attendanceCode.view = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findInitialStandard.fulfilled, (state, { payload }) => {
        state.standard.data = payload;
      })
      .addCase(findUserStandard.fulfilled, (state, { payload }) => {
        state.standard.isExists = payload.isExists;
        state.standard.leave = payload.leave;
      })
      .addCase(findStandard.fulfilled, (state, { payload }) => {
        state.standard.isExists = payload.isExists;
        state.standard.data = payload.data ?? {
          annualGenerationStandardType: 0,
          decimalHandlingType: 1,
          useLeaveExcess: false,
          startDate: '0101',
          endDate: '1231',
          countsYearList: [],
          updateAt: '',
        };
      })
      .addCase(modifyStandard.fulfilled, (state, { payload }) => {
        state.standard.data = payload;
      })
      .addCase(createOccurs.fulfilled, (state, { payload }) => {
        state.standard.isExists = payload;
      })
      .addCase(findRejection.fulfilled, (state, { payload }) => {
        state.rejection = payload;
      })
      .addCase(updateRejection.fulfilled, (state, { payload }) => {
        state.rejection = payload;
      })
      .addCase(findNotice.fulfilled, (state, { payload }) => {
        state.notice = payload;
      })
      .addCase(modifyNotice.fulfilled, (state, { payload }) => {
        state.notice = payload;
      })
      .addCase(findAttendanceBasic.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(updateAttendanceBasic.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        if (payload) state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      })
      .addCase(findAttendanceCodeList.fulfilled, (state, { payload }) => {
        state.attendanceCode.list = payload;
      })
      .addCase(findAttendanceCodeView.fulfilled, (state, { payload }) => {
        state.attendanceCode.view = payload;
      })
      .addCase(createAttendanceCode.fulfilled, (state, { payload }) => {
        state.attendanceCode.list = [...state.attendanceCode.list, payload];
      })
      .addCase(updateAttendanceCode.fulfilled, (state, { payload }) => {
        state.attendanceCode.view = undefined;
        state.attendanceCode.list = state.attendanceCode.list.map((a) => {
          if (a.id === payload.id) return payload;
          return a;
        });
      })
      .addCase(updateAttendanceSeq.fulfilled, (state, { payload }) => {
        state.attendanceCode.list = state.attendanceCode.list
          .map((a) => {
            const p = payload.find((b) => a.id === b.id);
            if (!p) return a;
            return { ...a, ...p };
          })
          .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      })
      .addCase(deleteAttendanceCode.fulfilled, (state, { payload }) => {
        state.attendanceCode.list = state.attendanceCode.list.filter(
          (a) => a.id !== payload.id,
        );
      })
      .addCase(findDefaultWorktime.fulfilled, (state, { payload }) => {
        state.workingHours = payload;
      });
  },
});

export default attendancePreferencesSlice.reducer;

export const attendancePreferencesActions = {
  modifyStandard,
  findInitialStandard,
  findUserStandard,
  findStandard,
  findRejection,
  updateRejection,
  findNotice,
  modifyNotice,

  createOccurs,

  attendanceBasic: findAttendanceBasic,
  updateAttendanceBasic,

  findAdministrators,
  appendAdministrators,
  removeAdministrators,

  attendanceCodeList: findAttendanceCodeList,
  attendanceCodeViewClear:
    attendancePreferencesSlice.actions.attendanceCodeViewClear,
  attendanceCodeView: findAttendanceCodeView,
  attendanceCreate: createAttendanceCode,
  attendanceUpdate: updateAttendanceCode,
  attendanceUpdateSeq: updateAttendanceSeq,
  attendanceDelete: deleteAttendanceCode,

  defaultWorktime: findDefaultWorktime,
};
