import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import SelectField from '../../../../../components/selectField/SelectField';
import { Country } from '../../../../../groupware-common/types';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ComponentSelectCustom(): JSX.Element {
  const options: {
    value: string;
    label: string;
    flag: Country;
    add: string;
  }[] = [
    {
      value: 'us',
      label: getLocalizedText('미국'),
      flag: 'US',
      add: '+1',
    },
    {
      value: 'kr',
      label: getLocalizedText('대한민국'),
      flag: 'KR',
      add: '+82',
    },
    {
      value: 'cn',
      label: getLocalizedText('중국'),
      flag: 'CN',
      add: '+86',
    },
  ];

  const newCountrySelect = options.sort((a, b) => (a.label > b.label ? 1 : -1));

  const [state, stateState] = useState('kr');

  const handleChangeCountryCode = (value: string) => {
    stateState(value);
  };

  return (
    <ComponentSampleUI.Content title="지역 선택">
      <SelectField
        data={newCountrySelect}
        value={state}
        onChange={handleChangeCountryCode}
      />
      {options.find((x) => x.value === state)?.add}
    </ComponentSampleUI.Content>
  );
}

export default ComponentSelectCustom;
