import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../groupware-common/apis/common/v1';
import { ModulePermission } from '../../stores/module';

export const ModuleArg = [
  'DASHBOARD',
  'APPROVAL',
  'MAIL',
  'ATTENDANCE',
  'BOARD',
  'CALENDAR',
  'DOCUMENT',
  'CONTACTS',
  'RESOURCE',
  'DIRECTORY',
] as const;
export type ModuleType = typeof ModuleArg[number];

export interface ModuleItemArg {
  module: ModuleType;
  seq: number;
  status: boolean;
  updateAt: string;
}

async function findList(arg: { companyId: number }): Promise<ModuleItemArg[]> {
  try {
    const { companyId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// TODO module 타입 변경 string -> ModuleType 커스텀 타입 생성 [HOME, DIRECTORY, APPROVAL, MAIL, ATTENDANCE, BOARD, CALENDAR, DOCUMENT, CONTACTS]
async function findView(arg: {
  companyId: number;
  module: string;
  updateAt?: string;
}): Promise<{
  module: string;
  seq: number;
  status: boolean;
  isInitial: boolean;
  includers: ModulePermission[];
  excluders: ModulePermission[];
  updateAt: string;
}> {
  try {
    const { companyId, module } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules/${module}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function update(arg: {
  companyId: number;
  module: string;
  status: boolean;
  includers?: ModulePermission[];
  excluders?: ModulePermission[];
  updateAt: string;
}): Promise<{
  module: string;
  seq: number;
  status: boolean;
  // isInitial: boolean; // 초기 메뉴 여부
  includers: ModulePermission[];
  excluders: ModulePermission[];
  updateAt: string;
}> {
  try {
    const { companyId, ...data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function sortMenu(arg: {
  companyId: number;
  list: {
    module: string;
    seq: number;
    updateAt: string;
  }[];
}): Promise<
  {
    module: string;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules/sequence/all`;
    const response = await axios.put(url, arg.list, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function list(arg: {
  companyId: number;
  employeeId: number;
}): Promise<ModuleItemArg[]> {
  try {
    const { companyId, employeeId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/companies/${companyId}/employees/${employeeId}/modules`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function linkUserList(arg: {
  companyId: number;
  employeeId: number;
}): Promise<
  {
    id: number;
    seq: number;
    name: string;
    url: string;
    status: boolean;
    isImage: boolean;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, employeeId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/companies/${companyId}/employees/${employeeId}/linkmenus`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 페이지 - 링크메뉴 리스트 */
async function linkList(arg: { companyId: number }): Promise<
  {
    id: number;
    seq: number;
    name: string;
    status: boolean;
    isImage: boolean;
    updateAt: string;
  }[]
> {
  try {
    const { companyId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/linkmenus`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function linkView(arg: { companyId: number; id: number }): Promise<{
  id: number;
  seq: number;
  name: string;
  status: boolean;
  url: string;
  isImage: boolean;
  updateAt: string;
}> {
  try {
    const { companyId, id } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/linkmenus/${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteLink(arg: {
  companyId: number;
  data: {
    id: number;
    updateAt: string;
  };
}): Promise<{
  id: number;
}> {
  try {
    const { companyId, data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/linkmenus/${data.id}`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 링크 메뉴 생성 */
async function saveLink(arg: {
  companyId: number;
  data: {
    name: string;
    url: string;
    image?: string;
  };
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/linkmenus`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updateLink(arg: {
  companyId: number;
  data: {
    id: number;
    name: string;
    url: string;
    status: boolean;
    image?: string;
    updateAt: string;
  };
}): Promise<{
  id: number;
  imagePath?: string;
}> {
  try {
    const { companyId, data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/linkmenus`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function sortLink(arg: {
  companyId: number;
  data: {
    id: number;
    seq: number;
    updateAt: string;
  }[];
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/linkmenus/sequence/all`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const serviceMenuApi = {
  sortMenu,
  update,
  findList,
  findView,
  list,

  saveLink,
  updateLink,
  sortLink,
  deleteLink,
  linkList,
  linkView,
  linkUserList,
};

export default serviceMenuApi;
