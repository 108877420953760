import React from 'react';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DashboardAccessDenied(props: { message?: string }): JSX.Element {
  return (
    <div className="eui-access-denied-data">
      {props.message || getLocalizedText('접근 권한이 없습니다.')}
    </div>
  );
}

export default DashboardAccessDenied;
