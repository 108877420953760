/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosError } from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../groupware-common/apis/common/v1';
import { ApiError } from '../../../../groupware-common/types/error';

export type PasswordChange = 'NONE' | 'OPTIONAL' | 'REQUIRED';
interface AuthenticateResponse {
  accessToken: string;
  refreshToken: {
    token: string;
    expireAt: string;
  };
  isPasswordChangeRequired: PasswordChange;
}

/** 로그인. */
async function signin(arg: {
  id: string;
  password: string;
}): Promise<AuthenticateResponse> {
  try {
    const { id, password } = arg;
    const { host } = getApiConfig();
    const url = `${host}/api/authenticate/v1/users/${id}/refreshtoken`;
    const response = await axios.post(url, { password });
    return response.data;
  } catch (e) {
    const ex = e as AxiosError<ApiError>;
    throw apiError({
      ...ex,
      response: {
        ...ex.response,
        data: {
          ...ex.response?.data,
          error: undefined,
        },
      },
    });
  }
}

/** 리프레시토큰 이용하여 액세스 토큰 발급. */
async function issuingAccessToken(token: string): Promise<string> {
  try {
    const { host } = getApiConfig();
    const url = `${host}/api/authenticate/v1/refreshtoken`;
    const response = await axios.get(url, {
      params: {
        refreshToken: token,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const sessionApi = {
  signin,
  issuingAccessToken,
};

export default sessionApi;
