import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import PostView from '../../../../../components/post/PostView';
import UserInfo from '../../../../../components/user/UserInfo';
import Comment from '../../../../../components/comment/Comment';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import { boardActions } from '../../../../stores/board';
import {
  getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { replaceRetentionPeriodToString } from '../../../../stores/folder';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import boardFileApi from '../../../../apis/board/v1/common';
import Divider from '../../../../../components/divider/Divider';
import Icon from '../../../../../components/icon/Icon';
import {
  dateTimeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

interface NameKeyItem {
  companyId?: number;
  id: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function getCommentItems(
  items:
    | {
        id: number; // 아이디
        postId: number; // 게시글 아이디
        parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
        employeeId: number; // 직원 아이디 - employee.id
        comment: string; // 내용
        isAuthorized: boolean; // 수정 권한 여부.
        isDeleted: boolean; // 삭제 여부
        updateAt: string; // 수정 날짜
      }[]
    | undefined,
  companyId: number,
  directory: {
    organizations: NameKeyItem[];
    organizationEmployees: {
      companyId: number;
      id: number;
      employeeId: number;
      jobDutyId: number;
    }[];
    employees: {
      companyId: number;
      id: number;
      representativeOrganizationId: number;
      jobPositionId: number;
      avatar: string;
    }[];
    jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
    jobPositions: NameKeyItem[];
    jobDuties: NameKeyItem[];
  },
) {
  if (items === undefined) return undefined;

  const temp: {
    id: number;
    postId: number;
    parentId: number;
    from?: {
      key: string;
      name: string;
    };
    message: string;
    isAuthorized?: boolean;
    writer: {
      key: string;
      name: string; // 이름
      organization: string; // 부서
      class: string; // 직위 혹은 직책
      avatar: string; // 아바타
    };
    createAt: string;
    updateAt: string;
    authModify?: boolean;
    authDelete?: boolean;
  }[] = items.map((a, index) => {
    const {
      id,
      postId,
      parentId,
      employeeId,
      isAuthorized,
      isDeleted,
      comment,
      updateAt,
    } = a;

    const directoryData =
      employeeId === 0
        ? undefined
        : getDirectoryData({
            ...directory,
            companyId,
            employeeId,
          });

    const messages = comment.split('┼');

    let from: { key: string; name: string } | undefined;

    if (messages.length > 1) {
      const ids = messages[0].split('/');
      const fromData =
        parseInt(ids[1], 10) === 0
          ? undefined
          : getDirectoryData({
              ...directory,
              companyId: parseInt(ids[0], 10),
              employeeId: parseInt(ids[1], 10),
            });

      from = {
        key: messages[0],
        name: fromData ? fromData.employeeName : getLocalizedText('익명'),
      };
    }

    const message = (messages.length === 1 ? messages[0] : messages[1]) || '';

    return {
      id,
      postId,
      parentId,
      from,
      message,
      isAuthorized,
      writer: directoryData
        ? {
            key: `${directoryData.companyId}/${directoryData.employeeId}`,
            name: directoryData.employeeName, // 이름
            organization: directoryData.organizationName, // 부서
            class: directoryData.jobClassName, // 직위 혹은 직책
            avatar: getAvatarPath(companyId, employeeId), // 아바타
          }
        : {
            key: `${companyId}/${index}`,
            name: isDeleted ? '' : getLocalizedText('익명'), // 이름
            organization: '', // 부서
            class: '', // 직위 혹은 직책
            avatar: '', // 아바타
          },
      createAt: updateAt,
      updateAt,
      authModify: false,
      authDelete: false,
    };
  });

  return temp
    .filter((a) => a.parentId === 0)
    .map((a) => {
      return {
        ...a,
        replies: temp.filter((b) => b.parentId === a.id),
      };
    });
}

function BoardContentBodyView(props: {
  pathname: string;
  search: string;
  type: 'full' | 'split';
}): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{
    folderId?: string;
  }>('/*/:folderId', props.pathname);
  const queryParams = getQueryParams(props.search);

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props.pathname]);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const folders = useSelector((state: RootState) => state.boards.folder.list);
  const view = useSelector((state: RootState) => state.boards.board.view.data);
  const folderSetting = useSelector(
    (state: RootState) => state.boards.board.folderSetting,
  );
  const totalCount = useSelector(
    (state: RootState) => state.boards.board.totalCount,
  );
  const type = props.type !== undefined ? props.type : 'full';

  if (!view) return null;

  const folder = folders.find((a) => a.id === view.folderId);
  /** 폴더 익명권한 여부. */
  const isAnony = folder?.option.useAnonymous ?? false;
  /** 폴더 댓글 사용여부. */
  const useComment = folder?.option.useReply ?? true;
  const folderName =
    !folderId || folderId === 'all' || folderId === 'importance'
      ? folder?.name ?? ''
      : undefined;
  /** 글 작성자. */
  const writer =
    view.employeeId === 0
      ? undefined
      : getDirectoryData({
          ...directory,
          companyId: principal.companyId,
          employeeId: view.employeeId,
        });
  /** 로그인한 사용자. */
  const creator = getDirectoryData({
    ...directory,
    ...principal,
  });
  const comments = getCommentItems(
    view.commentData,
    principal.companyId,
    directory,
  );

  /** 중요표시 기능 이벤트. */
  const handleItemImportanceChange = () => {
    dispatch(
      boardActions.saveUnSaveStar({
        star: !view.isStarred,
        id: view.id,
        employeeId: principal.employeeId,
        noLoading: true,
      }),
    ).then((result) => {
      if (
        (result as { error?: string }).error === undefined &&
        folderId === 'importance'
      ) {
        const { pageNo = 1 } = queryParams;
        const rowsPerPage =
          queryParams.rowsPerPage ?? folderSetting.setting?.rowsPerPage ?? 15;
        const path = `${getPathMap('/*/*', props.pathname)}`;
        const currentPage = (pageNo - 1) * rowsPerPage + 1;
        if (totalCount === currentPage && pageNo > 1)
          queryParams.pageNo = pageNo - 1;
        go(path, queryParams);
      }
    });
  };

  /** 좋아요 기능 이벤트. */
  const handleLikeClick = () => {
    dispatch(
      boardActions.saveUnSaveLike({
        like: !view.isLiked,
        id: view.id,
        employeeId: principal.employeeId,
        noLoading: true,
      }),
    );
  };

  /** 좋아요 정보 클릭 이벤트. */
  const handleLikeInfoClick = () => {
    dispatch(sessionActions.setDrawer({ type: 'likes' }));
  };

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (id: number) => {
    boardFileApi
      .downloadAll(id)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    id: number;
    fileId: number;
    name: string;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { id, fileId, name } = arg;
    boardFileApi
      .download(id, fileId)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 댓글 및 답글 추가. */
  const handleCommentAppend = (arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }) => {
    const { id, parentId, message, parentWriterKey } = arg;

    // 답글의 답글 여부에 따른 내용 생성.
    const contents =
      parentWriterKey !== undefined ? `${parentWriterKey}┼${message}` : message;

    // 등록.
    if (id === undefined) {
      dispatch(
        boardActions.createComment({
          postId: view.id,
          parentId,
          employeeId: principal.employeeId,
          comment: contents,
        }),
      ).then(() => {
        scrollbar.current?.scrollTo(0, scrollbar.current?.scrollHeight);
      });
    }
    // 수정.
    else {
      const comment = view.commentData?.find((a) => a.id === id);
      if (comment === undefined) return;
      dispatch(
        boardActions.updateComment({
          id,
          postId: comment.postId,
          comment: contents,
          updateAt: comment.updateAt,
        }),
      );
    }
  };

  /** 댓글 및 답글 삭제. */
  const handleCommentDelete = (id: number) => {
    const comment = view.commentData?.find((a) => a.id === id);
    if (comment === undefined) return;

    dispatch(
      boardActions.deleteComment({
        id,
        postId: comment.postId,
        parentId: comment.parentId,
        employeeId: principal.employeeId,
        updateAt: comment.updateAt,
      }),
    );
  };

  const { retentionPeriod, attachedFiles } = view;
  const noticeRange =
    view.notifyStartDate === '1000-01-01' && view.notifyEndDate === '9999-12-31'
      ? getLocalizedText('상시')
      : `${dateTimeFormat(
          view.notifyStartDate,
          'yyyy-MM-DD',
        )} ~ ${dateTimeFormat(view.notifyEndDate, 'yyyy-MM-DD')}`;
  const viewContent: JSX.Element = (
    <PostView data-post-type={type}>
      <PostView.Head>
        <PostView.Title>
          <div style={{ display: 'flex' }}>
            <Button
              style={{ width: '24px', marginRight: '12px' }}
              text={getLocalizedText('별표표시')}
              icon={view.isStarred ? 'star-fill' : 'star'}
              iconType
              onClick={handleItemImportanceChange}
            />
            {folderName ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '5px', fontSize: '15px' }}>
                  <Chip theme="cancel" label={folderName} />
                </div>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {view.titleClassification !== '' &&
                    `[${view.titleClassification}] `}
                  {view.subject}
                </div>
              </div>
            ) : (
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                {view.titleClassification !== '' &&
                  `[${view.titleClassification}] `}
                {view.subject}
              </div>
            )}
          </div>
        </PostView.Title>
        <UserInfo
          className="view-author"
          avatar={writer ? writer.avatar : undefined}
          icon={writer ? undefined : 'person'}
          name={writer ? writer.employeeName : getLocalizedText('익명')}
          from={writer ? writer.organizationName : undefined}
          date={view.createAt}
          dateType="customFormat"
          dateEtc={`${getLocalizedText('조회')} ${view.views}`}
        />
      </PostView.Head>
      <PostView.Info column>
        {retentionPeriod !== '' && (
          <PostView.ExcerptItem
            title={getLocalizedText('보존기간')}
            value={replaceRetentionPeriodToString(retentionPeriod)}
          />
        )}
        {view.isNotified && (
          <PostView.ExcerptItem
            title={getLocalizedText('공지기간')}
            value={noticeRange}
          />
        )}
      </PostView.Info>
      <PostView.Body>
        <PostView.Content data={view.contents} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: '8px',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={handleLikeClick}>
            <Icon
              color="var(--primary-color)"
              icon={view.isLiked ? 'heart-fill' : 'heart'}
            />
          </div>
          <div style={{ marginLeft: '4px', fontSize: '14px' }}>
            <span>{getLocalizedText('좋아요')}</span>
            <span
              onClick={
                isAnony || view.likes === 0
                  ? undefined
                  : () => handleLikeInfoClick()
              }
              style={{
                marginLeft: '4px',
                cursor: isAnony || view.likes === 0 ? undefined : 'pointer',
                color:
                  isAnony || view.likes === 0
                    ? undefined
                    : 'var(--primary-color)',
              }}
            >
              {view.likes > 99 ? '99+' : view.likes}
            </span>
          </div>
        </div>
        {((attachedFiles && attachedFiles.length > 0) ||
          folder?.option.useReply) && <Divider />}
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={() => handleAttachedFileDownloadAll(attachedFiles[0].id)}
          >
            {attachedFiles.map((x) => (
              <AttachmentsItem
                key={x.fileId}
                name={x.name}
                size={x.size}
                onClick={() =>
                  handleAttachedFileDownload({
                    id: x.id,
                    fileId: x.fileId,
                    name: x.name,
                  })
                }
              />
            ))}
          </AttachmentsList>
        )}
        {useComment && (
          <Comment
            count={view.comments}
            user={{
              key: `${creator.companyId}/${creator.employeeId}`,
              name: creator.employeeName,
              organization: creator.organizationName,
              class: creator.jobClassName,
              avatar: isAnony ? '' : creator.avatar ?? '',
            }}
            comments={comments ?? []}
            onAdd={handleCommentAppend}
            onDelete={handleCommentDelete}
          />
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {viewContent}
    </div>
  );
}

export default BoardContentBodyView;

export { getCommentItems };
