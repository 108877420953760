import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DropMenu from '../../../../../../components/selectField/DropMenu';
import Menu from '../../../../../../components/menu/Menu';
import Tree from '../../../../../../components/tree/Tree';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import PostWrite from '../../../../../../components/post/PostWrite';
import {
  extractOptionsFromUrl,
  generateUrlWithParams,
} from '../../../../../stores/common';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

function ApprovalDocumentComposeTemplate(props: {
  onChange?(url: string): void;
  id: number;
  url?: string;
}): JSX.Element {
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  );
  const templates = useSelector(
    (state: RootState) => state.dashboard.templates.availableList,
  );
  const [state, setState] = useState<{
    selectedId?: number;
    menuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(() => {
    const selectedId = props.url
      ? extractOptionsFromUrl(props.url, 'folders')
      : undefined;

    return {
      selectedId: selectedId !== undefined ? Number(selectedId) : undefined,
      menuPoint: undefined,
    };
  });

  useEffect(() => {
    const template = templates.find((x) => x.templateId === props.id);
    if (props.onChange && template && state.selectedId) {
      const url = generateUrlWithParams(template.url, {
        folderId: state.selectedId.toString(),
        rowsperpage: '5',
      });
      props.onChange(url);
    }
  }, [state.selectedId]);

  /** 폴더 드롭메뉴 클릭. */
  const handleDropClick = (event?: React.MouseEvent) => {
    const { menuPoint } = state;
    if (event !== undefined && menuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        menuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        menuPoint: undefined,
      }));
    }
  };

  /** 결재함 선택. */
  const handleDropItemClick = (id: number) => {
    setState((prev) => ({
      ...prev,
      selectedId: id,
      menuPoint: undefined,
    }));
  };

  const treeData = categories
    .filter((x) => x.type === 'status')
    .map((x) => {
      return {
        id: x.id,
        parentId: 0,
        text: x.name,
        icon: x.icon,
      };
    });
  return (
    <>
      <div>
        <div className="item-content" />
        <PostWrite.Item title={getLocalizedText('결재함')}>
          <DropMenu
            value={
              categories.find((x) => x.id === state.selectedId)?.name ?? ''
            }
            onClick={handleDropClick}
            label={getLocalizedText('결재함 선택')}
          />
          {state.menuPoint && (
            <Menu
              point={state.menuPoint}
              onClose={() =>
                setState((prev) => ({
                  ...prev,
                  menuPoint: undefined,
                }))
              }
            >
              <div className="ui-organization-select">
                <div className="body-panel">
                  <Tree
                    selectedId={state.selectedId}
                    items={treeData}
                    onItemClick={handleDropItemClick}
                  />
                </div>
              </div>
            </Menu>
          )}
        </PostWrite.Item>
      </div>
    </>
  );
}

export default ApprovalDocumentComposeTemplate;
