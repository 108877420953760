import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { isDesktop } from 'react-device-detect';
import UserInfo from '../user/UserInfo';
import { IconType } from '../../groupware-common/types/icon';
import { dateSimplify } from '../../groupware-common/utils/ui';
import Checkbox from '../checkbox/Checkbox';
import Button from '../button/Button';
import ToolbarAction, {
  ActionEventProps,
} from '../toolbarAction/ToolbarAction';
import SelectField from '../selectField/SelectField';
import Icon from '../icon/Icon';
import Menu from '../menu/Menu';
import MenuItem from '../menu/MenuItem';
import EuiToolbar from '../layout/EuiToolbar';
import Loading from '../loading/Loading';
import Switch from '../switch/Switch';
import MenuGroup from '../menu/MenuGroup';
import EmptyData from './EmptyData';
import Pagination from '../../groupware-approval/pages/root/approval/common/components/Pagination';
import Radio from '../radio/Radio';
import Chip from '../chip/Chip';
import Avatar from '../avatar/Avatar';
import TextField from '../textfield/TextField';
import CustomDatePicker from '../date/CustomDatePicker';
import FileChipGroup from '../chip/FileChipGroup';
import { ApprovalLineType } from '../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import DataGridSubject from './DataGridSubject';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

export interface DataGridColDef {
  field: string;
  label: string;
  description?: string;
  /**
   * 사이즈 규칙
   * - 켄텐츠 100px + 좌 8px(첫번째 + 24px) + 우 8px(마지막+24px) = 설정사이즈
   * - 예: 150px = 8px + 134px + 8px;
   * - 첫번째: 166px = 24px + 134px + 8px;
   * - 마지막: 166px = 8px + 134px + 24px;
   * */
  width?: number;
  minWidth?: number;
  sorting?: SortingProps;
  sortable?: boolean;
  align?: TableAlignment;
  visible?: boolean;
}

export interface DataGridMoreActionProps {
  code: string;
  label: string;
  icon: IconType;
}

type TableAlignment = 'left' | 'right' | 'center';

export type SortingProps =
  | 'none'
  | 'ascending'
  | 'descending'
  | 'other'
  | undefined;

const sorting = (value: SortingProps) => {
  switch (value) {
    case 'none':
      return 'ascending';
    case 'ascending':
      return 'descending';
    case 'descending':
      return 'none';
    default:
      return 'none';
  }
};

/* 데이터 추가 컴포넌트 */
export type DataGridAppendItemDef<T extends number | string> = (
  | {
      type: 'date'; // CustomDatePicker 컴포넌트
      value: Date;
      format?: string | string[];
      width?: number;
      onChange(value: Date): void;
    }
  | {
      type: 'text'; // TextField 컴포넌트
      value: string;
      readonly?: boolean;
      maxLength?: number;
      width?: number;
      onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
      onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    }
  | {
      type: 'button'; // Button 컴포넌트
      icon: IconType;
      iconType?: boolean;
      width?: number;
      onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    }
  | {
      type: 'holiday'; // 기본공휴일 월/일 컴포넌트
      data: { label: string; value: string }[];
      value: string;
      onChange(value: string): void;
    }
)[];

export type DataGridRowsProps<T extends number | string> = (
  | {
      // 단항선택
      type: 'radio';
      id: T;
      value: boolean;
      name: string;
      onChange?(arg: {
        id: number;
        checked: boolean;
        event: React.ChangeEvent<HTMLInputElement>;
      }): void;
    }
  | {
      // 다항선택
      type: 'checkbox';
      id: T;
      value: boolean;
      onChange?(arg: {
        id: number;
        checked: boolean;
        event: React.ChangeEvent<HTMLInputElement>;
      }): void;
      disabled?: boolean;
    }
  | {
      // 아이콘
      type: 'icon';
      value: string;
      icon: IconType | undefined;
      theme?: 'primary' | 'danger' | 'warning' | 'success' | 'info';
    }
  | {
      // 제목
      type: 'subject';
      use?: boolean;
      className?: string;
      id: T;
      value: string;
      folder?: {
        name: string;
        fontWeight?: number | 'bold';
      }; // 폴더 객체.
      comments?: number; // 댓글 수
      opinions?: number; // 의견 수 (결재만 사용.)
      attachedFiles?: {
        id: number; // 파일 아이디.
        name: string; // 파일 이름.
      }[];
      onClick?(arg: {
        id: T;
        event: React.MouseEvent<HTMLDivElement, MouseEvent>;
      }): void;
      approvalLine?: ApprovalLineType;
      linkWait?: boolean;
    }
  | {
      // 아바타
      type: 'avatar';
      value: string;
      name: string;
    }
  | {
      // 이미지
      type: 'image';
      value: string;
      width: number;
      height: number;
    }
  | {
      // 사용자정보
      type: 'user';
      avatar: string;
      icon?: IconType;
      value: string;
      from: string;
    }
  | {
      // 사용자리스트
      type: 'userlist';
      value: string;
    }
  | {
      // 클릭
      type: 'click';
      id: T;
      value: string;
      code: string;
      icon?: IconType;
    }
  | {
      // 날짜
      type: 'date';
      value: string;
      format:
        | 'full' // 2000년 1월 1일 오전 10:30
        | 'date' // 2000년 1월 1일
        | 'time' // 오전 10:30
        | 'simple' // 오늘이면 오전/오후 몇시:몇분; 올해이면 몇월 몇일; 올해가 아니면 년. 월. 일
        | 'newSimple' // 오늘이면 오전/오후 n시:n분; 올해이면 n월 n일; 올해가 아니면 n년 n월 n일
        | 'separate' // 첫줄 날짜 두번째줄 시간
        | 'dateNumber' // 2000-01-01
        | 'timeNumber'; // 10:30
    }
  | {
      // 칩
      type: 'chip';
      value: string;
      theme?: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
    }
  | {
      // More
      type: 'more';
      id: T;
    }
  | {
      // HTML
      type: 'html';
      value: string;
    }
  | {
      // 기본 텍스트
      type: 'text';
      value: string | number;
    }
  | {
      // 첨부파일 다운로드
      type: 'download';
      icon?: IconType;
      value?: string;
      id: T;
      fileId?: T;
    }
)[][];

function DataGrid<T extends number | string>(props: {
  scrollbar?: React.RefObject<HTMLDivElement>;
  /** 제목 */
  caption: string;
  /** header */
  columns: DataGridColDef[];
  /** body */
  rows: DataGridRowsProps<T>;
  /** 페이징 */
  pagination?: {
    no: number;
    total: number;
    row: number;
    onChangeRow?(value: number): void;
  };
  /** 클래스 명 */
  className?: string;
  /** checked 수량 */
  checkedCount?: number;
  /** 새로고침 */
  refresh?: boolean;
  /** 내보내기 */
  export?: boolean;
  excelFileName?: string;
  excelData?: { [key: string]: string }[];
  /** 연차 생성 */
  createDayOff?: ActionEventProps;
  /** 로딩상태 */
  loading?: boolean;
  /** 선택전 표시되는 버튼 */
  defaultActions?: ActionEventProps[];
  /** 선택시 표시되는 버튼 */
  toolbarActions?: ActionEventProps[];
  /** 더보기에서 표시되는 버튼 */
  moreActions?: DataGridMoreActionProps[];
  /** 선택전 공백 Footer 표시 여부 */
  useDefaultMargin?: boolean;
  /** 입력 컴포넌트 사용 여부 */
  useAddListItems?: boolean;
  appendItemRow?: DataGridAppendItemDef<T>;
  onChecked?(
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | string,
  ): void;
  onCheckedAll?(event: React.ChangeEvent<HTMLInputElement>): void;
  onClick?(arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: T;
    name?: string;
    fileId?: T;
  }): void;
  onSorting?(field: string, state: SortingProps): void;
  onChangeColumnVisible?(
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void;
  children?: React.ReactNode;
}): JSX.Element {
  const rootRef = props.scrollbar
    ? props.scrollbar
    : useRef<HTMLDivElement>(null);

  const {
    pagination,
    checkedCount,
    onChecked,
    onClick,
    onSorting,
    moreActions,
  } = props;

  let classname = 'eui-data-grid';
  if (props.className) classname += ` ${props.className}`;

  let indeterminate = false;
  if (checkedCount !== undefined && props.rows.length !== undefined) {
    if (checkedCount < props.rows.length && checkedCount > 0) {
      indeterminate = true;
    }
  }

  const pageCountOptions = [
    { value: '5', label: getLocalizedText('{{n}}개', { n: 5 }) },
    { value: '10', label: getLocalizedText('{{n}}개', { n: 10 }) },
    { value: '15', label: getLocalizedText('{{n}}개', { n: 15 }) },
    { value: '20', label: getLocalizedText('{{n}}개', { n: 20 }) },
    { value: '25', label: getLocalizedText('{{n}}개', { n: 25 }) },
    { value: '30', label: getLocalizedText('{{n}}개', { n: 30 }) },
    { value: '35', label: getLocalizedText('{{n}}개', { n: 35 }) },
    { value: '50', label: getLocalizedText('{{n}}개', { n: 50 }) },
    { value: '100', label: getLocalizedText('{{n}}개', { n: 100 }) },
  ];

  const handleActionClick = (arg: {
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    code: string;
    id?: T;
    name?: string;
    fileId?: T;
  }) => {
    if (onClick)
      onClick({
        code: arg.code,
        event: arg.event,
        id: arg.id,
        name: arg.name,
        fileId: arg.fileId,
      });
  };

  const handleChangeRowsLength = (value: string) => {
    const number = parseInt(value, 10);
    if (pagination && pagination.onChangeRow) pagination.onChangeRow(number);
  };

  let tableWidth = 0;

  const [state, setState] = useState<{
    moreId: T;
    columnVisible: boolean;
    columnPoint: { x: number; y: number; width: number; height: number };
    moreVisible: boolean;
    morePoint: { x: number; y: number; width: number; height: number };
    filterVisible: boolean;
    filterPoint: { x: number; y: number; width: number; height: number };
    currentFilter: string;
    scrollState?: 'start' | 'end' | 'ing';
    holidayName: string;
    validation: string;
    holidayMonth?: string;
    holidayDay?: string;
  }>({
    moreId: 0 as T,
    columnVisible: false,
    columnPoint: { x: 0, y: 0, width: 0, height: 0 },
    moreVisible: false,
    morePoint: { x: 0, y: 0, width: 0, height: 0 },
    filterVisible: false,
    filterPoint: { x: 0, y: 0, width: 0, height: 0 },
    currentFilter: '',
    holidayName: '',
    validation: '',
    holidayMonth: '1',
    holidayDay: '1',
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const top = event.currentTarget.scrollTop;
    const root = rootRef.current;

    if (root) {
      if (root.scrollHeight - top === root.clientHeight) {
        setState((prevState) => ({
          ...prevState,
          scrollState: 'end',
        }));
      } else if (top === 0) {
        setState((prevState) => ({
          ...prevState,
          scrollState: 'start',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          scrollState: 'ing',
        }));
      }
    }
  };

  const handleOpenColumn = (event: React.MouseEvent) => {
    if (state.columnVisible) handleCloseColumn();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        columnPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        columnVisible: true,
      }));
    }
  };

  const handleCloseColumn = () => {
    setState((prevState) => ({
      ...prevState,
      columnVisible: false,
    }));
  };

  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (props.onChangeColumnVisible) props.onChangeColumnVisible(field, event);
  };

  const handleSorting = (field: string, status: SortingProps) => {
    if (onSorting) onSorting(field, status);
  };

  props.columns.forEach((x) => {
    if (x.visible === false) tableWidth += 0;
    else tableWidth += x.width || x.minWidth || 0;
  });

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | string,
  ) => {
    if (onChecked) onChecked(event, id);
  };

  const handleOpenMore = (event: React.MouseEvent, id: T) => {
    if (state.moreVisible) handleCloseMore();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        morePoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        moreVisible: true,
        moreId: id,
      }));
    }
  };

  const handleCloseMore = () => {
    setState((prevState) => ({
      ...prevState,
      moreVisible: false,
    }));
  };

  return (
    <div className={classname}>
      <div
        className={`grid-root ${props.rows.length === 0 ? 'grid-no-data' : ''}`}
        data-scroll={state.scrollState}
        ref={rootRef}
        onScroll={handleScroll}
      >
        <table className="grid" style={{ minWidth: `${tableWidth}px` }}>
          <caption>{props.caption}</caption>
          <colgroup>
            {props.columns.map((column) => {
              if (column.visible === false) return null;
              return (
                <col
                  key={column.field}
                  style={
                    column.minWidth !== undefined
                      ? { minWidth: column.minWidth, width: 'auto' }
                      : { width: column.width }
                  }
                  id={`grid-col-${column.field}`}
                />
              );
            })}
          </colgroup>
          <thead className="grid-head">
            <DataGrid.Row>
              {props.columns.map((column) => {
                if (column.visible === false) return null;
                return (
                  <DataGrid.Column
                    type="th"
                    align={column.align}
                    key={column.field}
                    scope="col"
                  >
                    {column.field === 'checkbox' && props.onCheckedAll ? (
                      <Checkbox
                        className="row-checked"
                        checked={
                          checkedCount !== 0 &&
                          checkedCount === props.rows.length
                        }
                        indeterminate={indeterminate}
                        onChange={props.onCheckedAll}
                      />
                    ) : (
                      <span className="head-cell">
                        {column.sorting !== undefined ? (
                          <span
                            className="cell-label"
                            aria-sort={column.sorting}
                            onClick={() =>
                              handleSorting(
                                column.field,
                                sorting(column.sorting),
                              )
                            }
                          >
                            {column.label}
                          </span>
                        ) : (
                          <span className="cell-label">{column.label}</span>
                        )}
                      </span>
                    )}
                  </DataGrid.Column>
                );
              })}
            </DataGrid.Row>
          </thead>
          <tbody className="grid-body">
            {props.useAddListItems ? (
              <DataGrid.Row className="append-item">
                {props.appendItemRow !== undefined
                  ? props.appendItemRow.map((item, i) => {
                      const key = `item.type-${i + 1}`;
                      switch (item.type) {
                        case 'date': {
                          return (
                            <DataGrid.Append key={key}>
                              <CustomDatePicker
                                selected={item.value}
                                dateFormat={item.format}
                                onChange={item.onChange}
                              />
                            </DataGrid.Append>
                          );
                        }
                        case 'text': {
                          return (
                            <DataGrid.Append key={key}>
                              <TextField
                                value={item.value}
                                readonly={item.readonly}
                                maxLength={item.maxLength}
                                onChange={item.onChange}
                                onKeyDown={item.onKeyDown}
                              />
                            </DataGrid.Append>
                          );
                        }
                        case 'button': {
                          return (
                            <DataGrid.Append key={key} align="right">
                              <Button
                                iconType={item.iconType}
                                icon={item.icon}
                                onClick={item.onClick}
                              />
                            </DataGrid.Append>
                          );
                        }
                        case 'holiday': {
                          return (
                            <span key={key} className="holiday">
                              <SelectField
                                data={item.data}
                                value={item.value}
                                onChange={item.onChange}
                              />
                            </span>
                          );
                        }
                        default: {
                          return undefined;
                        }
                      }
                    })
                  : undefined}
              </DataGrid.Row>
            ) : undefined}
            {props.rows.map((row, rowIndex) => {
              const rowKey = `row-${rowIndex + 1}`;
              return (
                <DataGrid.Row key={rowKey}>
                  {row.map((i, columnIndex) => {
                    const key = `column-${rowIndex + 1}-${columnIndex + 1}-${
                      i.type
                    }`;
                    const column = props.columns[columnIndex];
                    const columnAlign = column.align;
                    const columnType = i.type;
                    if (column.visible === false) return null;
                    switch (i.type) {
                      case 'checkbox': {
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <Checkbox
                              className="row-checked"
                              checked={i.value}
                              onChange={(event) =>
                                handleChangeChecked(event, i.id)
                              }
                              disabled={i.disabled}
                            />
                          </DataGrid.Column>
                        );
                      }
                      case 'radio': {
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <Radio
                              className="row-checked"
                              checked={i.value}
                              name={i.name}
                              onChange={(event) =>
                                handleChangeChecked(event, i.id)
                              }
                            />
                          </DataGrid.Column>
                        );
                      }
                      case 'icon':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            {i.icon && (
                              <Icon
                                icon={i.icon}
                                theme={i.theme}
                                tooltip={i.value}
                              />
                            )}
                          </DataGrid.Column>
                        );
                      case 'subject':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            {i.use || i.use === undefined ? (
                              <div className="cell-hyperlink">
                                {i.folder ? (
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: 'initial',
                                        marginRight: '3px',
                                      }}
                                    >
                                      <Chip
                                        theme="cancel"
                                        size="sm"
                                        label={i.folder.name}
                                        fontWeight={i.folder.fontWeight}
                                      />
                                    </div>
                                    <DataGrid.Subject
                                      id={i.id}
                                      className={i.className}
                                      field={column.field}
                                      value={i.value}
                                      approvalLine={i.approvalLine}
                                      onClick={handleActionClick}
                                      chip={
                                        i.linkWait
                                          ? {
                                              label:
                                                getLocalizedText('연동대기'),
                                              theme: 'primary',
                                            }
                                          : undefined
                                      }
                                      comments={i.comments}
                                      opinions={i.opinions}
                                    />
                                  </div>
                                ) : (
                                  <DataGrid.Subject
                                    id={i.id}
                                    className={i.className}
                                    field={column.field}
                                    value={i.value}
                                    approvalLine={i.approvalLine}
                                    onClick={handleActionClick}
                                    chip={
                                      i.linkWait
                                        ? {
                                            label: getLocalizedText('연동대기'),
                                            theme: 'primary',
                                          }
                                        : undefined
                                    }
                                    comments={i.comments}
                                    opinions={i.opinions}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="cell-value">
                                {i.folder && (
                                  <div
                                    style={{
                                      color: 'initial',
                                      marginRight: '3px',
                                      display: 'inline-flex',
                                    }}
                                  >
                                    <Chip
                                      theme="cancel"
                                      size="sm"
                                      label={i.folder.name}
                                      fontWeight={i.folder.fontWeight}
                                    />
                                  </div>
                                )}
                                {i.value}
                              </div>
                            )}
                            {i.attachedFiles && i.attachedFiles.length > 0 && (
                              <div style={{ margin: '5px 0px 3px 0px' }}>
                                <FileChipGroup
                                  data={i.attachedFiles.map((a) => ({
                                    id: a.id,
                                    name: a.name,
                                  }))}
                                  max={3}
                                  onDownload={(event, id, name) =>
                                    handleActionClick({
                                      code: 'attachedFile',
                                      event,
                                      id: i.id,
                                      name,
                                      fileId: (id as T) ?? 0,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </DataGrid.Column>
                        );
                      case 'avatar':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.Avatar value={i.value} name={i.name} />
                          </DataGrid.Column>
                        );
                      case 'image':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.Image
                              value={i.value}
                              width={i.width}
                              height={i.height}
                            />
                          </DataGrid.Column>
                        );
                      case 'user':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.User
                              avatar={i.avatar}
                              icon={i.icon}
                              name={i.value}
                              from={i.from}
                            />
                          </DataGrid.Column>
                        );
                      case 'userlist':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.DataGridPermissionValue value={i.value} />
                          </DataGrid.Column>
                        );
                      case 'click':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.Click
                              value={i.value}
                              icon={i.icon}
                              onClick={(event) =>
                                handleActionClick({
                                  code: i.code,
                                  event,
                                  id: i.id,
                                })
                              }
                            />
                          </DataGrid.Column>
                        );
                      case 'download':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            {i.value && i.icon ? (
                              <DataGrid.Dowmload
                                value={i.value}
                                icon={i.icon}
                                onClick={(event) =>
                                  handleActionClick({
                                    code: 'download',
                                    event,
                                    id: i.id,
                                    name: i.value,
                                    fileId: i.fileId,
                                  })
                                }
                              />
                            ) : null}
                          </DataGrid.Column>
                        );
                      case 'date':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            {i.value !== '' ? (
                              <DataGrid.Date value={i.value} type={i.format} />
                            ) : (
                              <DataGrid.Value value={i.value} />
                            )}
                          </DataGrid.Column>
                        );
                      case 'chip':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.Chip value={i.value} theme={i.theme} />
                          </DataGrid.Column>
                        );
                      case 'more':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            {moreActions && moreActions.length === 1 ? (
                              moreActions.map((x) => (
                                <Button
                                  key={`${x.code}_${key}`}
                                  className="cell-more"
                                  text={x.label}
                                  iconType
                                  icon={x.icon}
                                  onClick={(event) =>
                                    handleActionClick({
                                      code: x.code,
                                      id: i.id,
                                      event,
                                    })
                                  }
                                />
                              ))
                            ) : (
                              <Button
                                className="cell-more"
                                text={getLocalizedText('더보기')}
                                iconType
                                icon="ellipsis-h"
                                onClick={(event) => handleOpenMore(event, i.id)}
                              />
                            )}
                          </DataGrid.Column>
                        );
                      case 'html':
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.Html value={i.value} />
                          </DataGrid.Column>
                        );
                      default:
                        return (
                          <DataGrid.Column
                            key={key}
                            align={columnAlign}
                            field={columnType}
                          >
                            <DataGrid.Value value={i.value} />
                          </DataGrid.Column>
                        );
                    }
                  })}
                </DataGrid.Row>
              );
            })}
          </tbody>
          {props.children && (
            <tfoot className="grid-foot">{props.children}</tfoot>
          )}
        </table>
        {props.loading && <Loading />}
        {props.rows.length === 0 && <EmptyData />}
      </div>
      <EuiToolbar className="grid-toolbar">
        {checkedCount === 0 || checkedCount === undefined ? (
          <>
            <EuiToolbar.Left>
              {props.defaultActions &&
                props.defaultActions.map((x) => {
                  return x.type === 'icon' ? (
                    <Button
                      key={x.code}
                      text={x.label}
                      iconType
                      icon={x.icon}
                      onClick={(event) =>
                        handleActionClick({ code: x.code, event })
                      }
                    />
                  ) : (
                    <Button
                      key={x.code}
                      text={x.label}
                      variant={x.type as undefined | 'contained' | 'outlined'}
                      onClick={(event) =>
                        handleActionClick({ code: x.code, event })
                      }
                      size="sm"
                    />
                  );
                })}
              {props.refresh && (
                <Button
                  text={getLocalizedText('새로고침')}
                  iconType
                  icon="sync"
                  onClick={(event) =>
                    handleActionClick({ code: 'refresh', event })
                  }
                />
              )}
              {props.export && (
                <CSVLink
                  headers={props.columns
                    .filter(
                      (a) =>
                        a.field !== 'checkbox' &&
                        a.field !== 'radio' &&
                        a.field !== 'more' &&
                        (a.visible === undefined || a.visible),
                    )
                    .map((a) => {
                      return {
                        label: a.label,
                        key: a.field,
                      };
                    })}
                  style={{
                    textDecoration: 'none',
                    marginLeft: props.defaultActions ? '5px' : undefined,
                  }}
                  data={props.excelData ?? []}
                  onClick={() => {
                    if (props.excelData === undefined) return false;
                    return true;
                  }}
                  filename={props.excelFileName}
                >
                  <Button
                    disabled={props.excelData === undefined}
                    className="action-close"
                    text="파일 내보내기"
                    iconType
                    icon="csv-logo"
                    iconStyle={{ fontSize: '26px' }}
                    onClick={(event) =>
                      handleActionClick({ code: 'excel', event })
                    }
                  />
                </CSVLink>
              )}
              {props.createDayOff &&
                (props.createDayOff.type === 'icon' ? (
                  <Button
                    style={{ marginLeft: '10px' }}
                    key={props.createDayOff.code}
                    text={props.createDayOff.label}
                    iconType
                    icon={props.createDayOff.icon}
                    onClick={(event) =>
                      handleActionClick({ code: 'createDayOff', event })
                    }
                  />
                ) : (
                  <Button
                    style={{ marginLeft: '10px' }}
                    key={props.createDayOff.code}
                    text={props.createDayOff.label}
                    variant={
                      props.createDayOff.type as
                        | undefined
                        | 'contained'
                        | 'outlined'
                    }
                    onClick={(event) =>
                      handleActionClick({ code: 'createDayOff', event })
                    }
                    size="sm"
                  />
                ))}
              {props.useDefaultMargin && <div style={{ height: '36px' }} />}
            </EuiToolbar.Left>
            <EuiToolbar.Right>
              {pagination && (
                <>
                  {pagination.onChangeRow && (
                    <SelectField
                      className="rows"
                      data={pageCountOptions}
                      value={`${pagination.row}`}
                      onChange={handleChangeRowsLength}
                      size="sm"
                    />
                  )}
                  {pagination.total > 0 && (
                    <Pagination
                      no={pagination.no}
                      rows={pagination.row}
                      count={pagination.total}
                      onPrev={(event) =>
                        handleActionClick({ code: 'prePage', event })
                      }
                      onNext={(event) =>
                        handleActionClick({ code: 'nextPage', event })
                      }
                    />
                  )}
                </>
              )}
              {props.onChangeColumnVisible && (
                <Button
                  text="Column"
                  iconType
                  icon="view-column"
                  onClick={handleOpenColumn}
                />
              )}
            </EuiToolbar.Right>
          </>
        ) : (
          <EuiToolbar.Left>
            <Button
              className="action-close"
              text={getLocalizedText('취소')}
              iconType
              icon="close"
              onClick={(event) =>
                handleActionClick({ code: 'cancelSelected', event })
              }
            />
            <span className="selected-count">
              <em>{checkedCount}</em>{' '}
              <span>{getLocalizedText('개 선택됨')}</span>
            </span>
            {props.toolbarActions && (
              <ToolbarAction
                event={props.toolbarActions}
                onClick={handleActionClick}
              />
            )}
          </EuiToolbar.Left>
        )}
      </EuiToolbar>
      {props.onChangeColumnVisible && state.columnVisible && (
        <Menu
          point={state.columnPoint}
          onClose={handleCloseColumn}
          className="eui-data-grid-column-visible"
        >
          <MenuGroup>{getLocalizedText('목록노출')}</MenuGroup>
          <div className="column-list">
            {props.columns.map((x) => {
              return (
                x.field !== 'checkbox' &&
                x.field !== 'radio' &&
                x.field !== 'more' && (
                  <div className="column-item" key={x.field}>
                    <strong className="item-label">{x.label}</strong>
                    <Switch
                      className="item-toggle"
                      key={x.field}
                      checked={x.visible === undefined ? true : x.visible}
                      onChange={(event) =>
                        handleChangeColumnVisible(x.field, event)
                      }
                      disabled={!!x.minWidth}
                      size="sm"
                    />
                  </div>
                )
              );
            })}
          </div>
        </Menu>
      )}

      {state.moreVisible && moreActions && (
        <Menu point={state.morePoint} onClose={handleCloseMore}>
          {moreActions.map((i) => {
            return (
              <MenuItem
                key={i.code}
                label={i.label}
                icon={i.icon}
                onClick={(event) => {
                  handleActionClick({ code: i.code, event, id: state.moreId });
                  handleCloseMore();
                }}
              />
            );
          })}
        </Menu>
      )}
    </div>
  );
}

function DataGridAppendData(props: {
  field?: string;
  // className?: string;
  align?: 'left' | 'center' | 'right';
  colSpan?: number;
  rowspan?: number;
  // scope?: 'col' | 'row';
  children?: React.ReactNode;
  // onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}): JSX.Element {
  let classname = 'grid-column';
  if (props.align) classname += ` align-${props.align}`;
  return (
    <td
      className={classname}
      rowSpan={props.rowspan}
      colSpan={props.colSpan}
      data-field={props.field}
    >
      {props.children}
    </td>
  );
}

function DataGridRow(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'grid-row';
  if (props.className) classname += ` ${props.className}`;
  return <tr className={classname}>{props.children}</tr>;
}

function DataGridColumn(props: {
  field?: string;
  className?: string;
  type?: 'th';
  align?: 'left' | 'center' | 'right';
  colSpan?: number;
  rowspan?: number;
  scope?: 'col' | 'row';
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'grid-column';
  if (props.className) classname += ` ${props.className}`;
  if (props.align) classname += ` align-${props.align}`;
  return props.type === undefined ? (
    <td
      className={classname}
      rowSpan={props.rowspan}
      colSpan={props.colSpan}
      data-field={props.field}
    >
      {props.children}
    </td>
  ) : (
    <th
      scope={props.scope}
      rowSpan={props.rowspan}
      colSpan={props.colSpan}
      className={classname}
      data-field={props.field}
    >
      {props.children}
    </th>
  );
}

function DataGridHtml(props: { value: string }): JSX.Element {
  const { value } = props;
  return (
    <div className="cell-html">
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );
}

function DataGridValue(props: {
  value: string | number | boolean;
}): JSX.Element {
  return <div className="cell-value">{props.value}</div>;
}

// 조회 참조 일괄 부여 data grid value
function DataGridPermissionValue(props: {
  value: string | number | boolean | string[];
}): JSX.Element {
  const timerRef = React.useRef<number>();

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const [state, setState] = useState<{
    visible: boolean;
    point: { x: number; y: number; width: number; height: number };
  }>({
    visible: false,
    point: { x: 0, y: 0, width: 0, height: 0 },
  });

  /** 마우스 커서 진입 열기 */
  const handleOpenApprovalLine = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    if (isDesktop) {
      const rect = event.currentTarget.getBoundingClientRect();

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = window.setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          point: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          visible: true,
        }));
      }, 500);
    }
  };

  /** 마우스 커서 이탈 닫기 */
  const handleCloseApprovalLine = () => {
    if (isDesktop) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setState((prevState) => ({
        ...prevState,
        visible: false,
      }));
    }
  };
  return (
    <div
      className="cell-value-hidden"
      onMouseEnter={handleOpenApprovalLine}
      onMouseLeave={handleCloseApprovalLine}
    >
      {props.value}
      {state.visible && (
        <Menu point={state.point} onClose={handleCloseApprovalLine}>
          {props.value}
        </Menu>
      )}
    </div>
  );
}

function DataGridImage(props: {
  value: string;
  width: number;
  height: number;
}): JSX.Element {
  const style: React.CSSProperties = {
    maxWidth: props.width,
    maxHeight: props.height,
  };

  return (
    <div className="cell-image">
      {props.value && (
        <img src={props.value} alt={getLocalizedText('이미지')} style={style} />
      )}
    </div>
  );
}

function DataGridAvatar(props: { value: string; name: string }): JSX.Element {
  return (
    <div className="cell-avatar">
      <Avatar image={props.value} name={props.name} />
    </div>
  );
}

function DataGridClick(props: {
  value: string;
  icon?: IconType;
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  return props.icon ? (
    <Button
      text={props.value}
      iconType
      icon={props.icon}
      onClick={props.onClick}
      className="cell-icon-action"
    />
  ) : (
    <Button
      text={props.value}
      onClick={props.onClick}
      className="cell-text-action"
    />
  );
}

function DataGridChip(props: {
  value: string;
  theme?: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  size?: 'sm' | 'xs';
}): JSX.Element {
  return (
    <Chip
      className="cell-tag"
      label={props.value}
      theme={props.theme}
      size={props.size || 'xs'}
    />
  );
}

function DataGridUser(props: {
  name: string;
  avatar?: string;
  icon?: IconType;
  from?: string;
}): JSX.Element {
  return (
    <UserInfo
      avatar={props.avatar}
      icon={props.icon}
      name={props.name}
      from={props.from}
    />
  );
}

function DataGridDownload(props: {
  value: string;
  icon: IconType;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  return (
    <Button
      text={props.value}
      iconType
      icon={props.icon}
      onClick={props.onClick}
      className="cell-icon-action"
    />
  );
}

function DataGridDate(props: {
  value: string;
  type?:
    | 'full'
    | 'date'
    | 'time'
    | 'simple'
    | 'newSimple'
    | 'separate'
    | 'dateNumber'
    | 'timeNumber';
}): JSX.Element {
  const content =
    props.type === 'separate' ? (
      <>
        <span className="date">{dateSimplify(props.value, 'date')}</span>
        <span className="time">{dateSimplify(props.value, 'time')}</span>
      </>
    ) : (
      dateSimplify(props.value, props.type)
    );
  return <time className="cell-date">{content}</time>;
}

DataGrid.Row = DataGridRow;
DataGrid.Column = DataGridColumn;
DataGrid.Append = DataGridAppendData;

DataGrid.Value = DataGridValue;
DataGrid.DataGridPermissionValue = DataGridPermissionValue;
DataGrid.Html = DataGridHtml;
DataGrid.Image = DataGridImage;
DataGrid.Avatar = DataGridAvatar;
DataGrid.Click = DataGridClick;
DataGrid.Dowmload = DataGridDownload;
DataGrid.Chip = DataGridChip;
DataGrid.User = DataGridUser;
DataGrid.Date = DataGridDate;
DataGrid.Subject = DataGridSubject;

export default DataGrid;
