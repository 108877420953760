import React, { useState } from 'react';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Drawer from '../../../../components/drawer/Drawer';
import DrawerAction from '../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../components/post/PostWrite';
import DropMenu from '../../../../components/selectField/DropMenu';
import TextField from '../../../../components/textfield/TextField';
import { IconType } from '../../../../groupware-common/types/icon';
import { getParentItems } from '../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';
import TreePicker from '../../../../groupware-webapp/pages/popup/TreePicker';
import { ResourceFolderItem } from '../../../stores/folders';

function ResourceFolderEditDrawer(props: {
  parentId?: number;
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  folder?: ResourceFolderItem;
  onClose(): void;
  onSave(arg: {
    id: number;
    parentId: number;
    name: string;
    description: string;
  }): void;
}): JSX.Element {
  const { folders, folder, onClose, onSave } = props;

  const [state, setState] = useState(() => {
    let id = 0;
    let parentId = props.parentId ?? 0;
    let name = '';
    let description = '';
    let paths: string[] = props.parentId
      ? getParentItems(folders, props.parentId).map(({ text }) => text)
      : [getLocalizedText('자원등록')];
    if (folder) {
      id = folder.id;
      parentId = folder.parentId;
      name = folder.name;
      description = folder.description;
      paths = getParentItems(folders, folder.parentId).map(({ text }) => text);
    }
    return {
      id,
      parentId,
      name,
      description,
      paths,
      validation: '',
      parentSelectDialogVisible: false,
    };
  });

  /** 폴더명 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: event.target.value }));
  };

  /** 설명 변경. */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };

  /** 상위 선택 대화 상자 열기. */
  const handleParentSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: true,
    }));
  };

  /** 상위 선택 대화 상자 닫기. */
  const handleParentSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: false,
    }));
  };

  /** 상위 선택 대화 상자 확인. */
  const handleParentSelectDialogConfirm = (
    id: number,
    text: string,
    path: string[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      paths: path,
      parentId: id,
      parentSelectDialogVisible: false,
    }));
  };

  /** 저장 */
  const handleSave = () => {
    if (state.name.trim() === '') {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('폴더명을 입력하세요.'),
      }));
      return;
    }
    onSave({
      id: state.id,
      parentId: state.parentId,
      name: state.name,
      description: state.description,
    });
  };

  const renderDialog = () => {
    const { parentSelectDialogVisible, parentId } = state;
    if (parentSelectDialogVisible) {
      return (
        <TreePicker
          title={getLocalizedText('상위 폴더 선택')}
          list={folders}
          selectedId={props.parentId ?? parentId}
          rootId={0}
          onSelected={handleParentSelectDialogConfirm}
          onClose={handleParentSelectDialogClose}
        />
      );
    }
    return null;
  };

  const title = folder
    ? getLocalizedText('폴더 수정')
    : getLocalizedText('폴더 등록');
  return (
    <>
      <Drawer title={getLocalizedText(`${title}`)} size="sm" onClose={onClose}>
        <DrawerBody>
          <PostWrite>
            <PostWrite.Item title={getLocalizedText('상위 폴더')}>
              <DropMenu
                value={state.paths}
                label={getLocalizedText('상위 폴더 선택')}
                onClick={handleParentSelectDialogOpen}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('폴더명')} required>
              <TextField value={state.name} onChange={handleNameChange} />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('설명')}>
              <TextField
                value={state.description}
                onChange={handleDescriptionChange}
              />
            </PostWrite.Item>
          </PostWrite>
          {renderDialog()}
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack
        text={state.validation}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            validation: '',
          }))
        }
      />
    </>
  );
}

export default ResourceFolderEditDrawer;
