import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomNumbers, Language } from '../../../../../groupware-common/types';
import Button from '../../../../../components/button/Button';
import PostWrite from '../../../../../components/post/PostWrite';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import { RootState as R } from '../../../../../groupware-webapp/app/store';
import { MenuItemDetail, ModulePermission } from '../../../../stores/module';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import Switch from '../../../../../components/switch/Switch';
import FeedBack from '../../../../../components/alert/FeedBack';
import { getUserTreeItems } from '../../../../../groupware-directory/pages/adminconsole/directory/usergroup/DirectoryUserGroupContainer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type UserRole = 'excluders' | 'includers';

export interface UpdateMenuArg {
  companyId: number;
  module: string;
  status: boolean;
  includers: ModulePermission[];
  excluders: ModulePermission[];
  updateAt: string;
}
function ServiceMenuView(props: {
  view: MenuItemDetail;
  onUpdate(arg: UpdateMenuArg): void;
}): JSX.Element {
  const { view, onUpdate } = props;

  const directory = useDirectory();
  /** 직위 */
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );
  const employees = useSelector((s: R) => s.directory.employee.list.data.items);
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );
  const principal = useSelector((s: R) => s.session.principal);

  const userGroups = useSelector((state: R) => state.directory.group.list);
  const lang = (document.documentElement.getAttribute('lang') ||
    'ko-KR') as Language;

  const initialState = {
    module: view.id,
    name: getLocalizedText(`모듈.${view.name}`),
    status: view.status ?? false,
    isInitial: view.isInitial,
    includers: view.includers.map((x) => ({
      ...x,
      isDeleted: false,
    })),
    excluders: view.excluders.map((x) => ({
      ...x,
      isDeleted: false,
    })),
    groupIncluders: [], // TODO 추가 예정
    groupExcluders: [], // TODO 추가 예정
    includerMenuPoint: undefined,
    excluderMenuPoint: undefined,
    validation: '',
  };

  const [state, setState] = useState<{
    name: string;
    includers: ModulePermission[];
    isInitial: boolean;
    status: boolean;
    includerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    excluders: ModulePermission[];
    groupIncluders: number[];
    groupExcluders: number[];
    excluderMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    validation: string;
  }>(initialState);

  const groupItems = useMemo(() => {
    return getUserTreeItems({
      items: userGroups,
      companyId: principal.companyId,
      lang,
    });
  }, [userGroups]);

  useEffect(() => {
    setState(initialState);
  }, [view]);

  /** 사용여부 변경. */
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      status: event.target.checked,
    }));
  };

  /** 사용자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (
    role: UserRole,
    event?: React.MouseEvent,
  ) => {
    const menuPoint =
      role === 'includers' ? state.includerMenuPoint : state.excluderMenuPoint;
    if (event !== undefined && menuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();

      if (role === 'includers') {
        setState((prev) => ({
          ...prev,
          includerMenuPoint: { x, y, width, height },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          excluderMenuPoint: { x, y, width, height },
        }));
      }
    } else if (role === 'includers') {
      setState((prev) => ({
        ...prev,
        includerMenuPoint: undefined,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: undefined,
      }));
    }
  };

  /** 사용자 아이템 삭제. */
  const handleUserDelete = (
    id: number,
    referenceType: string,
    role: UserRole,
  ) => {
    if (role === 'includers') {
      setState((prev) => ({
        ...prev,
        includers: prev.includers.filter(
          (a) => !(a.referenceId === id && a.referenceType === referenceType),
        ),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluders: prev.excluders.filter(
          (a) => !(a.referenceId === id && a.referenceType === referenceType),
        ),
      }));
    }
  };

  /** 사용자, 예외자 조직도 추가 */
  const handleUserAppend = (
    addUser: DirectoryTreeItemArg,
    prevUser: ModulePermission[],
    role: UserRole,
  ) => {
    const { extra } = addUser.item;
    let menuPoint =
      role === 'includers' ? state.includerMenuPoint : state.excluderMenuPoint;
    let newUser: ModulePermission | undefined;

    // 회사일 때
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      if (prevUser.some((a) => a.referenceId === employeeId))
        menuPoint = undefined;

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined) menuPoint = undefined;
      else {
        newUser = {
          referenceCompanyId: companyId,
          referenceId: employeeId,
          referenceType: 'EMPLOYEE',
        };
      }
    } else if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      if (
        prevUser.some(
          (a) =>
            a.referenceType === 'ORGANIZATION' &&
            a.referenceId === organizationId,
        )
      )
        menuPoint = undefined;

      const organization = organizations.find(
        (a) => a.companyId === companyId && a.id === organizationId,
      );
      if (organization === undefined) menuPoint = undefined;
      else {
        newUser = {
          referenceCompanyId: companyId,
          referenceId: organization.id,
          referenceType: 'ORGANIZATION',
        };
      }
    } else if (extra.type === 'company') {
      const { companyId } = extra;
      if (
        prevUser.some(
          (a) => a.referenceType === 'COMPANY' && a.referenceId === companyId,
        )
      )
        menuPoint = undefined;
      else {
        newUser = {
          referenceCompanyId: companyId,
          referenceId: companyId,
          referenceType: 'COMPANY',
        };
      }
    }

    if (menuPoint === undefined || newUser === undefined) {
      const roleMenuName =
        role === 'includers' ? 'includerMenuPoint' : 'excluderMenuPoint';
      setState((prev) => ({
        ...prev,
        [roleMenuName]: undefined,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [role]: [...prevUser, newUser],
      }));
    }
  };

  /** 사용자, 예외자 그룹 추가 */
  const handleGroupAppend = (selectedId: number, role: UserRole) => {
    if (role === 'includers') {
      if (state.groupIncluders.findIndex((x) => x === selectedId) > -1) {
        setState((prev) => ({
          ...prev,
          includerMenuPoint: undefined,
        }));
        return;
      }
      setState((prev) => ({
        ...prev,
        groupIncluders: [...prev.groupIncluders, selectedId],
      }));
    } else {
      if (state.groupExcluders.findIndex((x) => x === selectedId) > -1) {
        setState((prev) => ({
          ...prev,
          excluderMenuPoint: undefined,
        }));
        return;
      }
      setState((prev) => ({
        ...prev,
        groupExcluders: [...prev.groupExcluders, selectedId],
      }));
    }
  };

  /** 수정 여부 체크 */
  const handleUpdatedUser = (
    list: ModulePermission[],
    item: ModulePermission,
    isDeleted?: boolean,
    createAt?: string,
  ) => {
    list.push({
      referenceCompanyId: item.referenceCompanyId,
      referenceId: item.referenceId,
      referenceType: item.referenceType,
      ...(isDeleted && createAt && { isDeleted, createAt }),
    });
  };

  /** 메뉴 수정 */
  const handleUpdate = () => {
    const updatedIncluders: ModulePermission[] = [];
    const updatedExcluders: ModulePermission[] = [];

    // 중복 확인
    const isDuple = state.includers.some((x) => {
      const idx = state.excluders.findIndex(
        (a) =>
          a.referenceId === x.referenceId &&
          a.referenceType === x.referenceType,
      );
      return idx > -1;
    });
    if (isDuple) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText(
          '사용자 또는 예외자 인원을 중복으로 설정할 수 없습니다.',
        ),
      }));
      return;
    }

    // 삭제자 추가
    view.includers.forEach((x) => {
      if (
        state.includers.findIndex(
          (a) =>
            a.referenceId === x.referenceId &&
            a.referenceType === x.referenceType,
        ) < 0
      )
        handleUpdatedUser(updatedIncluders, x, true, x.createAt);
    });

    view.excluders.forEach((x) => {
      if (
        state.excluders.findIndex(
          (a) =>
            a.referenceId === x.referenceId &&
            a.referenceType === x.referenceType,
        ) < 0
      )
        handleUpdatedUser(updatedExcluders, x, true, x.createAt);
    });

    // 사용자 추가
    state.includers.forEach((x) => {
      if (
        view.includers.findIndex(
          (a) =>
            a.referenceId === x.referenceId &&
            a.referenceType === x.referenceType,
        ) < 0
      )
        handleUpdatedUser(updatedIncluders, x);
    });

    state.excluders.forEach((x) => {
      if (
        view.excluders.findIndex(
          (a) =>
            a.referenceId === x.referenceId &&
            a.referenceType === x.referenceType,
        ) < 0
      )
        handleUpdatedUser(updatedExcluders, x);
    });

    const arg = {
      companyId: principal.companyId,
      module: view.id,
      status: state.status,
      includers: updatedIncluders,
      excluders: updatedExcluders,
      updateAt: view.updateAt ?? '',
    };
    onUpdate(arg);
  };

  const renderUser = (users: ModulePermission[], role: UserRole) => {
    return (
      users
        .map((a) => {
          let jobClassSeq = 0;
          if (a.referenceType === 'EMPLOYEE') {
            const employeeData = getDirectoryData({
              ...directory,
              companyId: a.referenceCompanyId,
              employeeId: a.referenceId,
            });
            // 직위 또는 직책 순서로 정렬 순서 결정.
            jobClassSeq =
              (jobClassType === 'jobduty'
                ? jobDuties.find(
                    (v) =>
                      v.companyId === a.referenceCompanyId &&
                      v.id === employeeData.jobDutyId,
                  )?.seq
                : jobPositions.find(
                    (v) =>
                      v.companyId === a.referenceCompanyId &&
                      employeeData.jobPositionId === v.id,
                  )?.seq) || CustomNumbers.SMALLINT_MAX;
          }
          return {
            ...a,
            jobClassSeq,
          };
        })
        // 회사 → 부서 → 직원 순으로 정렬 후
        // 직위 또는 직책 순번이 낮을수록 밑으로.
        .sort((a, b) => {
          if (a.referenceType !== b.referenceType) {
            if (a.referenceType === 'COMPANY') return -1;
            if (a.referenceType === 'ORGANIZATION') {
              if (b.referenceType === 'COMPANY') return 1;
              return -1;
            }
            return 1;
          }
          if (a.referenceType === 'EMPLOYEE') {
            if (a.jobClassSeq < b.jobClassSeq) return -1;
            if (a.jobClassSeq > b.jobClassSeq) return 1;
          }
          return 0;
        })
        .map(
          ({
            referenceCompanyId: companyId,
            referenceId: id,
            referenceType,
          }) => {
            if (referenceType === 'COMPANY') {
              // 회사일 경우
              return (
                <Chip
                  key={`${companyId}/${id}`}
                  label={getCompanyName(companyId)}
                  icon="company"
                  onDelete={() => handleUserDelete(id, referenceType, role)}
                />
              );
            }
            if (referenceType === 'ORGANIZATION') {
              // 부서일 경우
              return (
                <Chip
                  key={`${companyId}/${id}`}
                  label={getOrganizationName(companyId, id)}
                  icon="sitemap-fill"
                  onDelete={() => handleUserDelete(id, referenceType, role)}
                />
              );
            }
            if (referenceType === 'COMPANY') {
              // 회사일 경우
              return (
                <Chip
                  key={`${companyId}/${id}`}
                  label={getCompanyName(companyId)}
                  icon="company"
                  onDelete={() => handleUserDelete(id, referenceType, role)}
                />
              );
            }
            if (referenceType === 'EMPLOYEE') {
              // 사원일 경우
              const DirectoryData = getDirectoryData({
                ...directory,
                companyId,
                employeeId: id,
              });
              return (
                <Chip
                  key={`${companyId}/${id}`}
                  label={DirectoryData.employeeName}
                  avatar={DirectoryData.avatar}
                  onDelete={() => handleUserDelete(id, referenceType, role)}
                />
              );
            }
            return null;
          },
        )
    );
  };

  const renderGroupUser = (users: number[], role: UserRole) => {
    return users.map((x) => {
      const item = groupItems.find((y) => y.id === x);
      if (item) {
        return (
          <Chip
            key={`${principal.companyId}/${item.id}`}
            label={item.text}
            icon="user-friends"
            onDelete={() =>
              setState((prev) => ({
                ...prev,
                groupIncluders: prev.groupIncluders.filter(
                  (a) => a !== item.id,
                ),
                includerMenuPoint: undefined,
              }))
            }
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('메뉴 정보')}>
        <Button
          text={getLocalizedText('저장')}
          onClick={handleUpdate}
          variant="contained"
        />
      </EuiSetting.Header>

      <EuiSetting.Content>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('메뉴명')}>
            {getLocalizedText(`모듈.${view.name}`)}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('사용 여부')}>
            <Switch checked={state.status} onChange={handleStatusChange} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('사용자')}>
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={(event) =>
                handleDirectoryTreeMenuToggle('includers', event)
              }
            >
              {renderUser(state.includers, 'includers')}
              {renderGroupUser(state.groupIncluders, 'includers')}
              {state.includerMenuPoint && (
                <DirectoryMenuTreeContainer
                  deduplication
                  point={state.includerMenuPoint}
                  typeToFilter={['employee', 'organization', 'company']}
                  onItemClick={(addUser) =>
                    handleUserAppend(addUser, state.includers, 'includers')
                  }
                  onGroupItemClick={(arg) =>
                    handleGroupAppend(arg, 'includers')
                  }
                  onClose={() => handleDirectoryTreeMenuToggle('includers')}
                  typeOption={['directory', 'group']}
                  groupItems={groupItems}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>

          <PostWrite.Item title={getLocalizedText('예외자')}>
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={(event) =>
                handleDirectoryTreeMenuToggle('excluders', event)
              }
            >
              {renderUser(state.excluders, 'excluders')}
              {renderGroupUser(state.groupExcluders, 'excluders')}

              {state.excluderMenuPoint && (
                <DirectoryMenuTreeContainer
                  deduplication
                  point={state.excluderMenuPoint}
                  typeToFilter={['employee', 'organization', 'company']}
                  onItemClick={(arg) =>
                    handleUserAppend(arg, state.excluders, 'excluders')
                  }
                  onGroupItemClick={(arg) =>
                    handleGroupAppend(arg, 'excluders')
                  }
                  onClose={() => handleDirectoryTreeMenuToggle('excluders')}
                  typeOption={['directory', 'group']}
                  groupItems={groupItems}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
      </EuiSetting.Content>
      <FeedBack
        text={state.validation}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            validation: '',
          }))
        }
      />
    </>
  );
}

export default ServiceMenuView;
