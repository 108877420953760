import React, { useEffect, useState } from 'react';
import HelperText from '../../../../components/alert/HelperText';
import Button from '../../../../components/button/Button';
import EmptyData from '../../../../components/data/EmptyData';
import Dialog from '../../../../components/dialog/Dialog';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import Divider from '../../../../components/divider/Divider';
import Loading from '../../../../components/loading/Loading';
import calendarsApi, {
  SystemIdType,
} from '../../../apis/calendar/v1/calendars';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function CalendarSubFolderCreateDialog(props: {
  onSubscribe(systemId: SystemIdType): void;
  onClose(): void;
}): JSX.Element {
  const [state, setState] = useState<{
    list: {
      system: SystemIdType;
      name: string;
      isAdded: boolean;
    }[];
    loading: boolean;
  }>({
    list: [],
    loading: true,
  });

  useEffect(() => {
    async function run() {
      const list = await calendarsApi.systemSubCal();
      setState({ list, loading: false });
    }
    run();
  }, []);

  return (
    <Dialog size="sm" onClose={props.onClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('캘린더 구독')}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <div style={{ margin: '0 30px' }}>
          <HelperText
            text={getLocalizedText(
              '구독하기를 선택하면 구독 캘린더로 추가됩니다.',
            )}
          />
          <Divider />
          {state.loading ? (
            <>
              <Loading />
              <EmptyData />
            </>
          ) : (
            state.list.map((a) => {
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 500,
                        fontSize: 'inherit',
                        fontFamily: 'inherit',
                        height: '30px',
                      }}
                    >
                      {a.name}
                    </div>
                    <Button
                      disabled={a.isAdded}
                      style={{ marginLeft: 'auto' }}
                      variant="outlined"
                      text={getLocalizedText('구독하기')}
                      onClick={() => props.onSubscribe(a.system)}
                    />
                  </div>
                  <Divider />
                </>
              );
            })
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <></>
      </DialogFooter>
    </Dialog>
  );
}

export default CalendarSubFolderCreateDialog;
