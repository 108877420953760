import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import UserInfo from '../../../../../components/user/UserInfo';
import { Column } from '../../../../../groupware-common/ui/type';
import { getAvatarPath } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';

/**
 * @property id 아이디.
 * @property columns 컬럼 배열.
 * @property item 항목.
 * @property onCheckedChange 체크 변경 이벤트.
 * @property onClick 클릭 이벤트.
 */
type Props = {
  columns: Column<PostListItemType>[];
  item: Record<string, boolean | string | number>;
  onhandleDocumentPopup(arg: {
    columnsName: string;
    columnsValue: number;
    employeeId: number;
    standardDate: string;
    alertType: number;
    workerPlanId?: number;
    firstAlarmSendDate: string;
  }): void;
};

function AttendanceNoticeBodyListItem(props: Props): JSX.Element {
  const display = useSelector((state: RootState) => state.session.display);
  const directory = useDirectory();

  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const { item, columns } = props;

  const directoryData = getDirectoryData({
    ...directory,
    employeeId: item.firstAlarmSender as number,
    companyId: item.companyId as number,
  });

  const handleDocumentPopup = (name: string, value: number) => {
    const arg = {
      columnsName: name,
      columnsValue: value,
      employeeId: item.employeeId as number,
      standardDate: item.standardDate as string,
      alertType: item.alertType as number,
      firstAlarmSendDate: item.firstAlarmSendDate as string,
      workerPlanId: item.workerPlanId as number,
    };
    props.onhandleDocumentPopup(arg);
  };

  const isMobile = display === 'phone';

  return (
    <>
      <PostList.Item>
        {columns.map(({ name, type, text }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;
          if (type === 'user') {
            if (item.firstAlarmSender === 0) children = undefined;
            else {
              children = (
                <UserInfo
                  avatar={getAvatarPath(directoryData)}
                  name={directoryData.employeeName}
                  from={directoryData.organizationName}
                />
              );
            }
          } else if (name === 'firstAlarmId' && value !== 0) {
            children = (
              <div>
                <span
                  style={
                    isMobile
                      ? {
                          fontSize: '14px',
                          color: 'var(--secondary-text-color)',
                        }
                      : { display: 'none' }
                  }
                >
                  {text}
                </span>
                <Button
                  parentDocument
                  className="action"
                  text={getLocalizedText('알림문서 보기')}
                  iconType
                  icon="document-search"
                  onClick={() => handleDocumentPopup(name, value as number)}
                  color="secondary"
                  style={{ paddingTop: '2px', paddingBottom: '2px' }}
                />
              </div>
            );
          } else if (name === 'workerPlanId' && value !== 0) {
            children = (
              <>
                <span
                  style={
                    isMobile
                      ? {
                          fontSize: '14px',
                          color: 'var(--secondary-text-color)',
                        }
                      : { display: 'none' }
                  }
                >
                  {text}
                </span>
                <Button
                  parentDocument
                  className="action"
                  text={getLocalizedText('사용계획서 보기')}
                  iconType
                  icon="document-search"
                  onClick={() => handleDocumentPopup(name, value as number)}
                  color="secondary"
                  style={{ paddingTop: '2px', paddingBottom: '2px' }}
                />
              </>
            );
          } else if (name === 'secondAlarmId' && value !== 0) {
            children = (
              <>
                <span
                  style={
                    isMobile
                      ? {
                          fontSize: '14px',
                          color: 'var(--secondary-text-color)',
                        }
                      : { display: 'none' }
                  }
                >
                  {text}
                </span>
                <Button
                  parentDocument
                  className="action"
                  text={getLocalizedText('지정일통보문서 보기')}
                  iconType
                  icon="document-search"
                  onClick={() => handleDocumentPopup(name, value as number)}
                  color="secondary"
                  style={{ paddingTop: '2px', paddingBottom: '2px' }}
                />
              </>
            );
          } else if (
            typeof value === 'string' &&
            (name === 'secondAlarmSendDate' || name === 'firstAlarmSendDate')
          ) {
            let sendDate;
            if (value === '9999-12-31') {
              sendDate = '-';
            } else sendDate = value;
            children = (
              <>
                <span
                  style={
                    isMobile
                      ? {
                          fontSize: '14px',
                          color: 'var(--secondary-text-color)',
                          marginRight: '10px',
                        }
                      : { display: 'none' }
                  }
                >
                  {text}
                </span>
                <span>
                  <PostList.Value value={sendDate} />
                </span>
              </>
            );
          } else if (typeof value === 'number' && value === 0) {
            children = (
              <>
                <span
                  style={
                    isMobile
                      ? {
                          fontSize: '14px',
                          color: 'var(--secondary-text-color)',
                          marginRight: '10px',
                        }
                      : { display: 'none' }
                  }
                >
                  {text}
                </span>
                <Chip
                  className="cell-tag"
                  label={getLocalizedText('미제출')}
                  theme="error"
                  size="xs"
                />
              </>
            );
          } else if (typeof value === 'string' || typeof value === 'number') {
            children = <PostList.Value value={value} />;
          }
          return (
            <PostList.Cell
              key={name}
              type={type}
              align={
                type === 'status' || type === 'count' ? 'center' : undefined
              }
              autoSize
              style={isMobile ? { margin: '10px' } : undefined}
            >
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
    </>
  );
}

export default AttendanceNoticeBodyListItem;
