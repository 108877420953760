import axios, { AxiosError } from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  TemplateKey,
  TemplateModule,
  TemplateType,
} from '../../../../constants/templates';

interface ApiInfo {
  url: string;
  responseType: TemplateKey;
}

/** 대시보드 생일자 데이터 */
export interface CommonBirtdayResponse {
  responseType: Extract<TemplateKey, 'COMMON-BIRTHDAY'>;
  url: string;
  data: {
    employeeId: number;
    birthday: string;
    isSolar?: boolean;
  }[];
}

/** 대시보드 신규 입사자 데이터 */
export interface CommonNewEmployeesResponse {
  responseType: Extract<TemplateKey, 'COMMON-NEW_EMPLOYEES'>;
  url: string;
  data: {
    employeeId: number;
    enterDate: string;
  }[];
}

/** 대시보드 프로필 데이터 */
export interface CommonProfileResponse {
  responseType: Extract<TemplateKey, 'COMMON-PROFILE'>;
  url: string;
  data: {
    [key: string]: {
      count: number;
    };
  };
}

/** 대시보드 문서글 목록 데이터 */
export interface DocumentPostResponse {
  responseType: Extract<TemplateKey, 'DOCUMENT-POSTS'>;
  url: string;
  data: {
    folderId: number;
    subject: string;
    folderName: string;
    id: number;
    isStarred: boolean;
    creatorId: number;
    updaterId: number;
    createAt: string;
    updateAt: string;
    checkoutAt: string;
  }[];
}

/** 대시보드 게시글 목록 데이터 */
export interface BoardPostResponse {
  responseType: Extract<TemplateKey, 'BOARD-POSTS'>;
  url: string;
  data: {
    folderId: number;
    folderName: string;
    id: number;
    employeeId: number;
    titleClassification: string;
    subject: string;
    isThumbnail: true;
    thumbnailPath: string;
    isSecure: boolean;
    views: number;
    isStarred: boolean;
    isViewed: boolean;
    updateAt: string;
  }[];
}

/** 대시보드 연차현황 데이터 */
export interface AttendanceLeaveResponse {
  responseType: Extract<TemplateKey, 'ATTENDANCE-LEAVE'>;
  url: string;
  data: {
    occursLeaves: number;
    useLeaves: number;
    modifingLeaves: number;
    remainedLeaves: number;
  };
}

/** 대시보드 자원 데이터 */
export interface ReservationResponse {
  responseType: Extract<TemplateKey, 'RESOURCE-RESERVATIONS'>;
  url: string;
  data: {
    id: number;
    itemId: number;
    itemName: string;
    employeeId: number;
    name: string;
    startDateTime: string;
    endDateTime: string;
    isRepeat: boolean;
    isApproval: number;
  }[];
}

/** 대시보드 일정 데이터 */
export interface CalendarResponse {
  responseType: Extract<TemplateKey, 'CALENDAR-EVENTS'>;
  url: string;
  data: {
    calendarName: string;
    color: string;
    name: string;
    startDateTime: string;
    endDateTime: string;
    isFull: boolean;
    isAttendanceCalendar: boolean;
  }[];
}

/** 대시보드 문서 데이터 */
export interface ApprovalDocumentResponse {
  responseType: Extract<TemplateKey, 'APPROVAL-DOCUMENT'>;
  url: string;
  data: {
    id: number;
    subject: string;
    approvalline: string;
    draftAt: string;
    completeAt: string;
    createAt: string;
  }[];
}

export interface DefaultResponse {
  responseType: Extract<TemplateKey, 'DEFAULT'>;
  url: string;
  data?: 'AUTHORITY_ERROR';
}

export type APIResponse =
  | ApprovalDocumentResponse
  | CalendarResponse
  | ReservationResponse
  | AttendanceLeaveResponse
  | BoardPostResponse
  | DocumentPostResponse
  | CommonProfileResponse
  | CommonNewEmployeesResponse
  | CommonBirtdayResponse
  | DefaultResponse;

async function find(apiInfo: ApiInfo): Promise<APIResponse> {
  try {
    const { url: apiUrl } = apiInfo;
    const { host, headers } = getApiConfig();
    const url = `${host}${apiUrl}`;
    const response = await axios.get(url, {
      headers,
    });
    switch (apiInfo.responseType) {
      case 'APPROVAL-DOCUMENT':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as ApprovalDocumentResponse;
      case 'CALENDAR-EVENTS':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as CalendarResponse;
      case 'RESOURCE-RESERVATIONS':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as ReservationResponse;
      case 'ATTENDANCE-LEAVE':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as AttendanceLeaveResponse;
      case 'BOARD-POSTS':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as BoardPostResponse;
      case 'DOCUMENT-POSTS':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as DocumentPostResponse;
      case 'COMMON-PROFILE':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as CommonProfileResponse;
      case 'COMMON-NEW_EMPLOYEES':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as CommonNewEmployeesResponse;
      case 'COMMON-BIRTHDAY':
        return {
          responseType: apiInfo.responseType,
          url: apiUrl,
          data: response.data,
        } as CommonBirtdayResponse;
      default:
        return {
          responseType: 'DEFAULT',
          url: apiUrl,
          data: response.data,
        } as DefaultResponse;
    }
  } catch (ex) {
    const err = ex as AxiosError;
    if (err.response && err.response.status === 403) {
      return {
        responseType: 'DEFAULT',
        url: '',
        data: 'AUTHORITY_ERROR',
      } as DefaultResponse;
    }
    return {
      responseType: 'DEFAULT',
      url: '',
      data: undefined,
    } as DefaultResponse;
  }
}

/** 대시보드 템플릿 리스트 */
async function findList(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
}): Promise<
  {
    id: number;
    portalId: number;
    seq: number;
    templateId: number;
    module: TemplateModule;
    type: TemplateType;
    url: string;
    options: string;
    updaterId: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, employeeId, portalId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}/templates`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}
/** 생성가능한 템플릿 리스트 */
async function findAbailableList(arg: { companyId: number }): Promise<
  {
    id: number;
    module: TemplateModule;
    type: TemplateType;
    url: string;
  }[]
> {
  try {
    const { companyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/templates`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 관리자 - 생성가능한 템플릿 리스트 */
async function adminconsoleAvailableList(arg: { companyId: number }): Promise<
  {
    id: number;
    module: TemplateModule;
    type: TemplateType;
    url: string;
  }[]
> {
  try {
    const { companyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/templates`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 템플릿 순서변경 */
async function sort(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
  list: {
    id: number;
    seq: number;
    updateAt: string;
  }[];
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, employeeId, portalId, list } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}/templates/sequence/all`;
    const response = await axios.put(url, [...list], {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}
/** 템플릿 저장 */
async function save(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
  templateId: number;
  url: string;
  options?: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId, portalId, templateId, url, options } = arg;
    const { host, headers } = getApiConfig();
    const apiUrl = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}/templates`;
    const response = await axios.post(
      apiUrl,
      {
        templateId,
        url,
        options,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 템플릿 수정 */
async function update(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
  portalTemplateId: number;
  url: string;
  updateAt: string;
  options?: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const {
      companyId,
      employeeId,
      portalId,
      portalTemplateId,
      url,
      updateAt,
      options,
    } = arg;
    const { host, headers } = getApiConfig();
    const apiUrl = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}/templates/${portalTemplateId}`;
    const response = await axios.put(
      apiUrl,
      {
        id: portalTemplateId,
        url,
        options,
        updateAt,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}
/** 템플릿 삭제 */
async function deleteTemplate(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
  portalTemplateId: number;
  item: {
    id: number;
    updateAt: string;
  };
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId, portalId, portalTemplateId, item } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}/templates/${portalTemplateId}`;
    const response = await axios.delete(url, {
      headers,
      data: { ...item },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 프로필 생성가능한 옵션 리스트 */
async function findProfileOption(): Promise<{
  [key: string]: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/profile/elements`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 관리자 - 대시보드 템플릿 리스트 */
async function adminconsoleList(arg: {
  companyId: number;
  portalId: number;
}): Promise<
  {
    id: number;
    portalId: number;
    seq: number;
    templateId: number;
    module: TemplateModule;
    type: TemplateType;
    url: string;
    options: string;
    updaterId: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, portalId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals/${portalId}/templates`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 관리자 - 템플릿 삭제 */
async function adminconsoleDelete(arg: {
  companyId: number;
  portalId: number;
  portalTemplateId: number;
  item: {
    id: number;
    updateAt: string;
  };
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, portalId, portalTemplateId, item } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals/${portalId}/templates/${portalTemplateId}`;
    const response = await axios.delete(url, {
      headers,
      data: { ...item },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 관리자 템플릿 수정 */
async function adminconsoleUpdate(arg: {
  companyId: number;

  portalId: number;
  portalTemplateId: number;
  url: string;
  updateAt: string;
  options?: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, portalId, portalTemplateId, url, updateAt, options } =
      arg;
    const { host, headers } = getApiConfig();
    const apiUrl = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals/${portalId}/templates/${portalTemplateId}`;
    const response = await axios.put(
      apiUrl,
      {
        id: portalTemplateId,
        url,
        options,
        updateAt,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 템플릿 저장 */
async function adminconsoleSave(arg: {
  companyId: number;
  portalId: number;
  templateId: number;
  url: string;
  options?: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, portalId, templateId, url, options } = arg;
    const { host, headers } = getApiConfig();
    const apiUrl = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals/${portalId}/templates`;
    const response = await axios.post(
      apiUrl,
      {
        templateId,
        url,
        options,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 템플릿 순서변경 */
async function adminconsoleSort(arg: {
  companyId: number;

  portalId: number;
  list: {
    id: number;
    seq: number;
    updateAt: string;
  }[];
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, portalId, list } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals/${portalId}/templates/sequence/all`;
    const response = await axios.put(url, [...list], {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const templateApi = {
  findList,
  findAbailableList,
  save,
  update,
  sort,
  deleteTemplate,
};

export const templateContentApi = {
  find,
  findProfileOption,
};

export const adminTemplateApi = {
  findAbailableList: adminconsoleAvailableList,
  findList: adminconsoleList,
  save: adminconsoleSave,
  update: adminconsoleUpdate,
  sort: adminconsoleSort,
  deleteTemplate: adminconsoleDelete,
};

export default templateApi;
