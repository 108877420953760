import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../groupware-webapp/app/store';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import Drawer from '../../../../components/drawer/Drawer';
import Button from '../../../../components/button/Button';
import DrawerAction from '../../../../components/drawer/DrawerAction';
import { HistoryItem } from '../../../apis/approval/v1/document';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import ApprovalChangeHistoryBeforeDialog from '../dialogs/ApprovalChangeHistoryBeforeDialog';
import ApprovalChangeHistoryCompareDialog from '../dialogs/ApprovalChangeHistoryCompareDialog';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../components/data/DataGrid';
import { getAvatarPath } from '../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function ApprovalChangeHistoryDrawer(props: {
  historys: HistoryItem[];
  onClose(): void;
}) {
  const { historys, onClose } = props;

  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );

  const getEmployeeData = (
    companyId: number,
    organizationId: number,
    employeeId: number,
  ) => {
    return getDirectoryData({
      organizationEmployees,
      employees,
      jobClassType,
      companyId,
      organizationId,
      employeeId,
    });
  };

  const columns: DataGridColDef[] = [
    { field: 'checkbox', label: '', width: 56 },
    { field: 'click', label: '', width: 60 },
    {
      field: 'html',
      label: getLocalizedText('변경사유'),
      minWidth: 150,
    }, //
    {
      field: 'user',
      label: getLocalizedText('사용자'),
      width: 150,
    },
    {
      field: 'date',
      label: getLocalizedText('날짜'),
      width: 150,
      align: 'right',
    },
  ];

  const [state, setState] = useState(() => {
    return historys
      .map((a) => {
        const employeeOrganizationId =
          employees.find(
            (b) => b.companyId === a.employeeCompanyId && b.id === a.employeeId,
          )?.representativeOrganizationId || 0;

        const employeeData = getEmployeeData(
          a.employeeCompanyId,
          employeeOrganizationId,
          a.employeeId,
        );
        return {
          id: a.id,
          documentId: a.documentId,
          checked: false,
          reason: a.reason,
          user: {
            avatar: getAvatarPath(employeeData),
            organization: employeeData.organizationName,
            name: employeeData.employeeName,
            job: employeeData.jobPositionName,
          },
          date: a.createAt,
        };
      })
      .sort((a, b) => +new Date(b.date) - +new Date(a.date));
  });
  const [visible, setVisible] = useState<{
    code: string;
    documentId: number;
    id: number;
  }>({
    code: '',
    documentId: 0,
    id: 0,
  });

  const rows: DataGridRowsProps<number> = state.map((a) => {
    const userName =
      a.user.job !== '' ? `${a.user.name} ${a.user.job}` : a.user.name;
    return [
      { type: 'checkbox' as const, id: a.id, value: a.checked },
      // eslint-disable-next-line prettier/prettier
      { type: 'click' as const, id: a.id, documentId: a.documentId, value: getLocalizedText('변경 전 문서 보기'), code: 'before', icon: 'document-search' },
      // eslint-disable-next-line prettier/prettier
      { type: 'html' as const, value: a.reason.replaceAll('\n', '<br/>') },
      {
        type: 'user' as const,
        avatar: a.user.avatar || '',
        value: userName,
        from: a.user.organization,
      },
      { type: 'date' as const, value: a.date, format: 'separate' },
    ];
  });

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number,
  ) => {
    setState((prevState) =>
      prevState.map((x) =>
        x.id === id ? { ...x, checked: event.target.checked } : x,
      ),
    );
  };

  /** 변경 전 문서 보기  */
  const handleOpenDocumentBefore = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number | undefined;
  }) => {
    const { documentId } = historys[0];
    const { code } = arg;
    const id = arg.id === undefined ? 0 : arg.id;
    setVisible({ code, documentId, id });
  };

  /** 비교하기  */
  const handleOpenDocumentCompare = () => {
    setVisible({ code: 'compare', documentId: 0, id: 0 });
  };

  const handleCloseDialog = () => {
    setVisible({ code: '', documentId: 0, id: 0 });
  };

  const getDialog = () => {
    const { code, documentId, id } = visible;
    if (code === 'before')
      return (
        <ApprovalChangeHistoryBeforeDialog
          data={{ documentId, id }}
          onClose={handleCloseDialog}
        />
      );
    if (code === 'compare')
      return (
        <ApprovalChangeHistoryCompareDialog
          data={state}
          onClose={handleCloseDialog}
        />
      );
    return null;
  };

  return (
    <>
      <Drawer title={getLocalizedText('변경내역')} onClose={onClose}>
        <DrawerBody>
          <DataGrid
            caption={getLocalizedText('변경내역')}
            columns={columns}
            rows={rows}
            onChecked={handleChangeCheckbox}
            onClick={handleOpenDocumentBefore}
          />
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getLocalizedText('비교하기')}
            variant="contained"
            disabled={state.filter((x) => x.checked).length !== 2}
            onClick={handleOpenDocumentCompare}
          />
        </DrawerAction>
      </Drawer>
      {getDialog()}
    </>
  );
}

export default React.memo(ApprovalChangeHistoryDrawer);
