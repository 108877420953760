import React from 'react';
import TextField from '../textfield/TextField';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function SimpleSearch(props: {
  className?: string;
  keyword: string;
  onSearch(event: React.ChangeEvent<HTMLInputElement>): void;
}): JSX.Element {
  let classname = 'eui-simple-search';
  if (props.className) classname += ` ${props.className}`;
  return (
    <div className={classname}>
      <TextField
        type="search"
        value={props.keyword}
        onChange={props.onSearch}
        icon="search"
        className="keyword"
        placeholder={getLocalizedText('검색')}
      />
    </div>
  );
}

export default SimpleSearch;
