import React, { useState } from 'react';
import Button from '../../../../components/button/Button';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../components/radio/RadioGroup';
import { createLocalizedTextFactory } from '../../../../groupware-common/utils/i18n';

function AuthenticationNetworkContainer(): JSX.Element {
  const initialState = {
    change: false,
    saveing: false,
    useRestrictedIP: false,
  };
  const [state, setState] = useState<{
    change: boolean;
    saveing: boolean;
    useRestrictedIP: boolean;
  }>(initialState);

  const getLocalizedText = createLocalizedTextFactory('authentication');

  /** 접속 ip 제한 사용 여부. */
  const handleChangeUseRestrictedIP = (value: boolean) => {
    setState((prev) => ({ ...prev, change: true, useRestrictedIP: value }));
  };

  const handleCancel = () => {
    setState(initialState);
  };

  const { useRestrictedIP } = state;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText('네트워크 관리')}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('접속 IP 제한')}>
          <RadioGroup
            column
            name={getLocalizedText('접속 IP 제한')}
            data={[
              {
                value: false,
                label: getLocalizedText('모든 IP에서 접속 허용'),
              },
              {
                value: true,
                label: getLocalizedText('지정한 IP에서만 접속 허용'),
              },
            ]}
            value={useRestrictedIP}
            onChange={handleChangeUseRestrictedIP}
          />
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {state.change && (
              <>
                <Button text={getLocalizedText('저장')} variant="contained" />
                {!state.saveing && (
                  <Button
                    text={getLocalizedText('취소')}
                    variant="outlined"
                    onClick={handleCancel}
                  />
                )}
              </>
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default AuthenticationNetworkContainer;
