import React from 'react';
import Icon from '../icon/Icon';

function LinkMenu(props: {
  imagePath: string;
  className?: string;
}): JSX.Element {
  let className = 'nav-logo';
  if (props.className) className += ` ${props.className}`;
  return props.imagePath === '' ? (
    <Icon className="nav-badge" icon="link" />
  ) : (
    <img src={props.imagePath} className={className} alt="링크 아이콘" />
  );
}

export default LinkMenu;
