import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import sessionApi from '../../../../groupware-webapp/apis/session/v1';
import store from '../../../../groupware-webapp/app/store';
import {
  jwtDecode,
  sessionActions,
} from '../../../../groupware-webapp/stores/session';
import { ApiError } from '../../../types/error';
import { createLocalizedTextFactory } from '../../../utils/i18n';
import { Language } from '../../../types';

/**
 * 매개변수를 직렬화합니다.
 * @param params 매개변수.
 * @returns 직렬화 문자열.
 */
axios.defaults.paramsSerializer = (params) => {
  // 배열을 전달 시 ?id[]=1&id[]=2 와 같은 형식을 ?id=1&id=2 와 같은 형식으로 변경합니다.
  const urlSearchParams = new URLSearchParams();
  // eslint-disable-next-line no-restricted-syntax
  for (const key in params) {
    if (Array.isArray(params[key]))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (params[key] as any[]).forEach((v) => urlSearchParams.append(key, v));
    else if (params[key] !== undefined)
      urlSearchParams.append(key, params[key]);
  }
  return urlSearchParams.toString();
};

const refreshToken = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  const { Authorization } = config.headers as { Authorization?: string };
  const refresh = getTokenValue('RSID');
  let token = Authorization ? Authorization.replace(/^Bearer /g, '') : '';
  const decodeToken = jwtDecode(token);

  if (decodeToken && decodeToken.payload.exp < Date.now() / 1000) {
    if (refresh === undefined) {
      // 리프레시 토큰이 존재하지 않거나 만료된 경우.
      token = '';
      store.dispatch(
        sessionActions.authenticatedError('인증 세션이 만료되었습니다.'),
      );
    } else {
      const { resource } = store.getState().session;
      const response = await sessionApi.issuingAccessToken(refresh);
      token = response;
      const tokenName = resource === 'teams' ? 'TSID' : 'SID';
      document.cookie = `${tokenName}=${token}; path=/; SameSite=None; Secure;`;
      store.dispatch(sessionActions.jwt(token));
    }
  }
  const newConfig = { ...config };
  if (token !== '') newConfig.headers.Authorization = `Bearer ${token}`;
  else delete newConfig.headers.Authorization;
  return newConfig;
};

axios.interceptors.request.use(refreshToken);

export function getHost(): string {
  return `${window.location.origin}`;
}

export function getTokenValue(keyName: string): string | undefined {
  let token: string | undefined;
  const cookie = document.cookie.split('; ');
  if (document.cookie !== '' && cookie.length > 0)
    cookie.forEach((a) => {
      const name = a.split('=')[0];
      const value = a.split('=')[1];
      if (name === keyName) token = value;
    });
  return token;
}

export function getCurrentLanguage(): Language {
  try {
    return store.getState().session.basicSetting.currentLanguage;
  } catch (ex) {
    return 'ko-KR';
  }
}

export function getAuthorization(): { Authorization: string } {
  const { jwt } = store.getState().session;
  return { Authorization: `Bearer ${jwt}` };
}

export function getApiConfig(): {
  host: string;
  headers: { Authorization: string };
} {
  return {
    host: `${getHost()}`,
    headers: {
      ...getAuthorization(),
    },
  };
}

/** 엔티티 아이디 키 인터페이스. */
export interface EntityIdKeyable {
  /** 아이디. */
  id: number;
  /** 수정 날짜. */
  updateAt: string;
}

/** 엔티티 회사 키 인터페이스. */
export interface EntityCompanyKeyable {
  /** 회사 아이디. */
  companyId: number;
  /** 수정 날짜. */
  updateAt: string;
}

/** 엔티티 키 인터페이스. */
export interface EntityKeyable {
  /** 회사 아이디. */
  companyId: number;
  /** 아이디. */
  id: number;
  /** 수정 날짜. */
  updateAt: string;
}

/** 엔티티 이름 키 인터페이스. */
export interface EntityNameKeyable {
  /** 회사 아이디. */
  companyId: number;
  /** 아이디. */
  id: number;
  /** 이름 아이디. */
  nameId: number;
  /** 수정 날짜. */
  updateAt: string;
}

/** 엔티티 직원 키 인터페이스. */
export interface EntityEmployeeKeyable {
  /** 회사 아이디. */
  companyId: number;
  /** 아이디. */
  id: number;
  /** 직원 아이디. */
  employeeId: number;
  /** 수정 날짜. */
  updateAt: string;
}

/**
 * 예외 객체로 오류 객체를 반환.
 * @param exception 예외 객체.
 * @return 오류 객체.
 */
export function apiError(exception: unknown): ApiError {
  const ex = exception as AxiosError<ApiError>;
  const getLocalizedText = createLocalizedTextFactory(['error', 'code']);
  let result: ApiError = {
    error: '',
    path: '',
    status: 0,
    timestamp: '',
  };

  if (ex.response) {
    // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
    result = {
      ...result,
      ...ex.response.data,
    };

    const {
      error,
      status,
      errors,
      type = '',
      message,
    } = ex.response.data as ApiError & {
      errors?: { defaultMessage: string }[];
      type?: string;
    };

    // access token이 만료된 경우.
    if (ex.response.status === 401) {
      store.dispatch(
        sessionActions.authenticatedError('인증 세션이 만료되었습니다.'),
      );
      return result;
    }
    if (status === 433 && message) {
      store.dispatch(sessionActions.authenticatedError(message));
      return result;
    }

    if (!type || type === '') return result;

    const formatType = getLocalizedText(`${type.toUpperCase()}`, {
      defaultValue: '',
    });
    let formatError = '';

    if (error !== undefined)
      formatError =
        formatType !== ''
          ? `status.n_${error.replace(/ /g, '_').toUpperCase()}`
          : `status.${error.replace(/ /g, '_').toUpperCase()}`;
    const serverError = 'status.INTERNAL_SERVER_ERROR';

    const formatMessage =
      formatType !== ''
        ? getLocalizedText(formatError, {
            n: `${formatType}`,
            defaultValue: '',
          })
        : getLocalizedText(formatError, { defaultValue: '' });

    if (formatMessage !== '') result.message = formatMessage;
    // 백엔드 벨리데이션 오류 메시지가 있는 경우 해당 오류 메시지로 설정.
    // 벨리데이션 오류 메시지 다국어 처리 안되어있는 경우 defaultMessage 보이도록 설정.
    else if (status >= 400 && errors !== undefined) {
      const defaultMessage = `validation.${errors[0].defaultMessage.replace(
        / /g,
        '',
      )}`;
      result.message = getLocalizedText(defaultMessage, {
        defaultValue: errors[0].defaultMessage,
      });
    } else if (message !== undefined)
      result.message =
        status >= 500 && message.indexOf('sql') > 0
          ? getLocalizedText(serverError)
          : message;
    else result.message = getLocalizedText(serverError);

    return result;
  }
  if (ex.request) result.message = getLocalizedText('common.NOT_RESPONSE');
  else if (ex.message === undefined)
    result.message = getLocalizedText('common.NOT_REQUEST');
  else result.message = ex.message;

  return result;
}

export async function apiErrorAsync(exception: unknown): Promise<ApiError> {
  const ex = exception as AxiosError;
  if (ex.response?.config.responseType === 'blob') {
    const data = JSON.parse((await (ex.response.data as Blob).text()) ?? '{}');
    return apiError({
      ...ex,
      response: {
        ...ex.response,
        data: {
          error: data.error ?? '',
          path: data.path ?? '',
          status: data.status ?? 0,
          message: data.message,
          timestamp: data.timestamp ?? '',
        },
      },
    });
  }
  return apiError(exception);
}
