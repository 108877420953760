import React, { useEffect, useRef } from 'react';
import { Column } from '../../../../groupware-common/ui/type';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../components/post/PostList';
import ApprovalContentBodyListItem from './ApprovalContentBodyListItem';
import { ApprovalLineType } from '../../common/dialogs/ApprovalLineDialogContainer';
import EmptyData from '../../../../components/data/EmptyData';
import { getPathParams } from '../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

/**
 * @property companyId 화사 아이디
 * @property id 아이디.
 * @property work 업무.
 * @property documentNo 문서 번호.
 * @property subject 제목.
 * @property attachments 첨부 파일 존재 여부.
 * @property opinions 의견 수.
 * @property comments 댓글 수.
 * @property urgent 긴급 여부.
 * @property archive 기록물철 표시 여부.
 * @property drafter 기안자
 * @property drafterOrganization 기안자 부서
 * @property drafterJob 기안자 직위 또는 직책 또는 직위/직책
 * @property drafterAvatar 기안자 아바타.
 * @property draftAt 기안일
 * @property completeAt 완료일.
 * @property checked 체크 여부.
 */
type Item = {
  companyId: number;
  id: number;
  workName: string;
  documentNo: string;
  subject: string;
  attachments: boolean;
  opinions: number;
  comments: number;
  drafter: string;
  drafterOrganization: string;
  drafterJob: string;
  drafterAvatar: string;
  draftAt: string;
  completeAt: string;
  checked: boolean;
  approvalLine: ApprovalLineType;
  linkWait?: boolean;
};

/**
 * @property type 유형.
 * @property columns 컬럼 배열.
 * @property items 항목 배열.
 * @property onItemCheckedChange 항목 체크 변경 이벤트.
 * @property onItemClick 항목 클릭 이벤트.
 */
type Props = {
  pathname: string;
  search: string;
  type?: PostListType;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: Item[];
  selectedId?: number;
  onItemCheckedChange?(id: number, checked: boolean): void;
  onItemClick?(id: number): void;
  onCountClick?(id: number, type: string): void;
};

function ApprovalContentBodyList(props: Props): JSX.Element {
  // console.log(`${ApprovalContentBodyList.name}.render:props`, dumy);

  const {
    pathname,
    search,
    type,
    density,
    columns,
    items,
    selectedId,
    onItemCheckedChange,
    onItemClick,
    onCountClick,
  } = props;
  const { folderId } = getPathParams<{ folderId?: number }>(
    `/*/:folderId$base62`,
    pathname,
  );

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pathname, search]);

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <PostList name="approval" type={type} density={density}>
      <PostList.Head>
        {columns.map(({ name, type: colunmType, text }) => (
          <PostList.Cell key={name} name={text} type={colunmType} />
        ))}
      </PostList.Head>
      <PostList.Body scrollableNodeProps={scrollbar}>
        {items.map(
          (
            {
              companyId,
              id,
              checked,
              workName,
              documentNo,
              subject,
              opinions,
              comments,
              attachments,
              drafter,
              drafterOrganization: organization,
              drafterJob: job,
              drafterAvatar: avatar,
              draftAt,
              completeAt,
              approvalLine,
              linkWait,
            },
            i,
          ) => {
            let item: {
              checked: boolean;
              work: string;
              documentNo: string;
              subject: {
                value: string;
                security: boolean;
                opinions: number;
                comments: number;
                attachments: boolean;
                urgent: boolean;
                chip?: {
                  label: string;
                  theme: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
                };
              };
              drafter?: {
                name: string;
                organization: string;
                job: string;
                avatar: string;
              };
              draftAt: string;
              completeAt: string;
            } = {
              checked,
              work: workName,
              documentNo,
              subject: {
                value: subject,
                security: false,
                comments,
                opinions,
                attachments,
                urgent: false,
                chip: linkWait
                  ? {
                      label: getLocalizedText('연동대기'),
                      theme: 'primary',
                    }
                  : undefined,
              },
              drafter: {
                name: drafter,
                organization,
                job,
                avatar,
              },
              draftAt,
              completeAt,
            };
            // 임시보관함일 경우 기안자 컬럼 제거.
            if (folderId === 1007) {
              item = {
                checked,
                work: workName,
                documentNo,
                subject: {
                  value:
                    subject.trim() === ''
                      ? getLocalizedText('(제목 없음)')
                      : subject,
                  security: false,
                  comments,
                  opinions,
                  attachments,
                  urgent: false,
                },
                draftAt,
                completeAt,
              };
            }
            // 조인으로 인한 키 중복 데이터가 조회 되는 경우 오류가 발생하여 인덱스 값 추가.
            const key = `/i${i}/${companyId}/${id}/}`;
            return (
              <ApprovalContentBodyListItem
                key={key}
                id={id}
                selected={checked || id === selectedId}
                columns={columns}
                item={item}
                approvalLine={approvalLine}
                onCheckedChange={onItemCheckedChange}
                onClick={onItemClick}
                onClickCount={onCountClick}
              />
            );
          },
        )}
      </PostList.Body>
    </PostList>
  );
}

export default ApprovalContentBodyList;
