import React, { useEffect } from 'react';
import { getPathParams } from '../../groupware-common/utils';
import EmptyLocateRoute from '../../groupware-common/locates/EmptyLocateRoute';
import AttendanceHolidayYearRoute from '../pages/adminconsole/attendance/holiday/AttendanceHolidayYearRoute';
import AttendanceHolidayBasicRoute from '../pages/adminconsole/attendance/holiday/AttendanceHolidayBasicRoute';
import AttendanceFormRoute from '../pages/adminconsole/attendance/form/AttendanceFormRoute';
import AttendanceSettingRoute from '../pages/adminconsole/attendance/setting/AttendanceSettingRoute';
import AttendanceWorkRoute from '../pages/adminconsole/attendance/work/AttendanceWorkRoute';
import AttendanceDayOffSettingRoute from '../pages/adminconsole/attendance/dayOffSetting/AttendanceDayOffSettingRoute';
import AttendanceAdjustRoute from '../pages/adminconsole/attendance/adjustment/AttendanceAdjustRoute';
import AttendanceDetailStatusRoute from '../pages/adminconsole/attendance/detail/AttendanceDetailStatusRoute';
import AttendanceDayOffStatusRoute from '../pages/adminconsole/attendance/dayOffStatus/AttendanceDayOffStatusRoute';
import AttendanceSendNoticeRoute from '../pages/adminconsole/attendance/sendNotice/AttendanceSendNoticeRoute';
import AttendanceNoticeSettingRoute from '../pages/adminconsole/attendance/sendNotice/AttendanceNoticeSettingRoute';
import AttendanceSubstituteRoute from '../pages/adminconsole/attendance/substitute/AttendanceSubstituteRoute';
import { useAppDispatch } from '../../groupware-webapp/app/store';
import { attendancePreferencesActions } from '../stores/attendance/preferences';
import { preferencesActions } from '../../groupware-approval/stores/approval/preferences';

function AdminConsoleLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // console.log(`${AdminConsoleLocateRoute.name}.render(props)`, props);

  const { pathname, search, hash, locationKey: key } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(attendancePreferencesActions.attendanceBasic());
    dispatch(preferencesActions.findApprovalType());
    dispatch(attendancePreferencesActions.defaultWorktime()); // 근태 기본 근무 시간 조회
  }, [dispatch]);

  // 기본 메뉴
  if (menu === undefined)
    return <AttendanceSettingRoute pathname={pathname} key={key} />;
  // 기본 공휴일
  if (menu === 'holidaysave') {
    return (
      <AttendanceHolidayYearRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  }
  // 휴일관리
  if (menu === 'basicHoliday') {
    return <AttendanceHolidayBasicRoute pathname={pathname} key={key} />;
  }
  // 연차관리
  if (menu === 'dayOffSetting')
    return <AttendanceDayOffSettingRoute pathname={pathname} key={key} />;
  // 연차조정, 대휴조정
  if (menu === 'dayOffAdjust' || menu === 'substituteAdjust')
    return (
      <AttendanceAdjustRoute pathname={pathname} search={search} key={key} />
    );
  // 연차현황
  if (menu === 'dayOffStatus')
    return (
      <AttendanceDayOffStatusRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  // 연차사용촉진알림 기본설정
  if (menu === 'noticeSetting')
    return <AttendanceNoticeSettingRoute pathname={pathname} key={key} />;
  // 발송내역
  if (menu === 'sendList')
    return (
      <AttendanceSendNoticeRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  // 대휴현황
  if (menu === 'substituteDeadline' || menu === 'substituteStatus')
    return (
      <AttendanceSubstituteRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  // 양식관리
  if (menu === 'form')
    return <AttendanceFormRoute pathname={pathname} key={key} />;
  if (menu === 'work')
    return (
      <AttendanceWorkRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  if (menu === 'detailStatus')
    return (
      <AttendanceDetailStatusRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  return (
    <EmptyLocateRoute
      pathname={pathname}
      search={search}
      hash={hash}
      key={key}
    />
  );
}

export default AdminConsoleLocateRoute;
