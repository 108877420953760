import React, { useState } from 'react';
import Button from '../../../../../components/button/Button';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import DropMenu from '../../../../../components/selectField/DropMenu';
import TextField from '../../../../../components/textfield/TextField';
import { getParentItems } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import FeedBack from '../../../../../components/alert/FeedBack';

function ApprovalArchiveSettingsFolderEditDrawer(
  props:
    | {
        id?: undefined;
        parentId?: undefined;
        name?: undefined;
        description?: undefined;
        updateAt?: undefined;
        onSave(arg: {
          parentId: number;
          name: string;
          description: string;
        }): void;

        folders: {
          id: number;
          parentId: number;
          name: string;
          seq?: number;
          icon: 'folder';
        }[];
        onClose(): void;
        checkFolder?: boolean;
      }
    | {
        id: number;
        parentId: number;
        name: string;
        description: string;
        updateAt: string;
        onSave(arg: {
          id: number;
          parentId: number;
          name: string;
          description: string;
          updateAt: string;
        }): void;

        folders: {
          id: number;
          parentId: number;
          name: string;
          seq?: number;
          icon: 'folder';
        }[];
        onClose(): void;
        checkFolder?: boolean;
      },
): JSX.Element {
  // console.log(`ApprovalArchiveSettingsFolderEditDrawer(props)`, props);

  const [state, setState] = useState(() => {
    const { parentId = 0, name = '', description = '', folders } = props;
    return {
      validation: '',
      dialogOpened: false,
      paths: getParentItems(folders, parentId).map(({ name: path }) => path),
      parentId: props.parentId || 0,
      name,
      description,
    };
  });

  const handleParentFolderSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      dialogOpened: true,
    }));
  };

  const handleParentFolderSelectDialogClose = () => {
    setState((prevState) => ({ ...prevState, dialogOpened: false }));
  };

  const handleParentFolderSelectDialogConfirm = (
    id: number,
    text: string,
    paths: string[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      paths,
      parentId: id,
      dialogOpened: false,
    }));
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setState((prevState) => ({ ...prevState, name: value }));
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;
    setState((prevState) => ({ ...prevState, description: value }));
  };

  const handleSave = () => {
    // console.log(`handleSave()`);

    const { parentId, name, description } = state;

    let validation = '';
    if (name.trim() === '') validation = '이름을 입력하세요.';
    if (validation !== '') {
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    if (props.id === undefined) props.onSave({ parentId, name, description });
    else {
      const { id, updateAt, onSave } = props;
      onSave({ id, parentId, name, description, updateAt });
    }
  };

  /** 스낵바 닫기. */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const renderDialog = () => {
    const { dialogOpened, parentId } = state;
    if (!dialogOpened) return null;

    const { id, folders } = props;
    return (
      <TreePicker
        title={getLocalizedText('상위 폴더 선택')}
        list={folders
          .filter((a) => a.id !== id && a.seq !== -1)
          .map((a) => ({ ...a, text: a.name }))}
        selectedId={parentId}
        rootId={0}
        onSelected={handleParentFolderSelectDialogConfirm}
        onClose={handleParentFolderSelectDialogClose}
      />
    );
  };

  const { id, onClose } = props;
  const { validation, paths, name, description } = state;

  const title = id === undefined ? '폴더 등록' : '폴더 수정';

  return (
    <>
      <Drawer title={getLocalizedText(`폴더.${title}`)} onClose={onClose}>
        <DrawerBody>
          <PostWrite>
            <PostWrite.Item title={getLocalizedText('상위 폴더')}>
              <DropMenu
                value={paths}
                label={getLocalizedText('상위 폴더 선택')}
                onClick={handleParentFolderSelectDialogOpen}
              />
              {renderDialog()}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('폴더 이름')}>
              <TextField value={name} onChange={handleNameChange} />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('설명')}>
              <TextField
                multiline
                value={description}
                onChange={handleDescriptionChange}
              />
            </PostWrite.Item>
          </PostWrite>
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalArchiveSettingsFolderEditDrawer;
