import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Menu from '../../../../../components/menu/Menu';
import PostWrite from '../../../../../components/post/PostWrite';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import Tree from '../../../../../components/tree/Tree';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  createQueryString,
  getPathParams,
  getQueryParams,
  go,
  hangul,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { ResourceListItem } from '../../../../stores/folders';
import { resourcesActions } from '../../../../stores/resources';
import { summaryType } from '../../../../../components/repeatDialog/DetailRepeatDialog';
import { makeTreeData } from '../common/ResourceDrawer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/** 예약 상태 */
function formatReservationStatus(status: number) {
  switch (status) {
    case 0:
      return getLocalizedText('승인대기');
    case 1:
      return getLocalizedText('예약완료');
    case 2:
      return getLocalizedText('반려');
    default:
      return '';
  }
}

function formatReservationTheme(status: string) {
  switch (status) {
    case getLocalizedText('승인대기'):
      return 'primary';
    case getLocalizedText('반려'):
      return 'error';
    case getLocalizedText('예약완료'):
      return 'success';
    default:
      return undefined;
  }
}

function formatRentalTheme(takeback: boolean) {
  if (takeback) return 'success';
  return 'primary';
}

function ResourceLookupDialog(props: {
  myReservation?: boolean; // 내 예약 조회 리스트 보기일 경우. - 반복 예약 수정, 삭제 - 모든 예약만 가능
  shared?: boolean; // 공유 받은 예약 조회 리스트 보기일 경우. - 수정, 삭제 불가능
  pathname: string;
  search: string;
  hash: string;
  onSave?(arg: {
    itemId: number;
    employeeId: number;
    name: string;
    remark: string;
    startDateTime: string;
    endDateTime: string;
  }): void;
  onUpdate?(arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      itemId: number;
      employeeId: number;
      name: string;
      remark?: string;
      startDateTime?: string;
      endDateTime?: string;
      lookupStartDateTime?: string;
      lookupUpdateAt?: string;
      updateAt?: string;
    };
  }): void;
  onDelete?(arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      startDateTime?: string;
      updateAt: string;
    };
  }): void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const { p2 } = getPathParams('/*/*/:p2', props.pathname);
  const queryParams = getQueryParams(props.search);
  const queryWord:
    | {
        itemId: number;
        itemName: string;
        start: string;
        end: string;
      }
    | undefined = queryParams.queryWord
    ? JSON.parse(queryParams.queryWord)
    : undefined;

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const display = useSelector((state: RootState) => state.session.display);
  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  );
  const reservation = useSelector(
    (state: RootState) => state.resource.resources.view,
  );
  let item: ResourceListItem | undefined;
  if (queryWord) item = items.find((a) => a.id === queryWord.itemId);
  if (reservation) item = items.find((a) => a.id === reservation.itemId);

  const originStart = new Date(queryWord?.start ?? '');
  const originEnd = new Date(queryWord?.end ?? '');
  const { isAvailablePastReservation: isAvailable } = useSelector(
    (state: RootState) => state.resource.preferences.basic,
  );

  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }
  const initialState = reservation
    ? {
        itemId: reservation.itemId,
        itemName: items.find((a) => a.id === reservation.itemId)?.name ?? '',
        reservationStatus: formatReservationStatus(reservation.isApproval),
        isTakeback: item && item.useRental ? reservation.isTakeback : undefined,
        view: true,
        update: false,
        delete: false,
        repeatType: props.myReservation ? 'all' : 'piece',
        start: queryWord
          ? new Date(queryWord.start)
          : timezoneDate(reservation.startDateTime),
        end: queryWord
          ? new Date(queryWord.end)
          : timezoneDate(reservation.endDateTime),
        isPopupVisible: false,
        title: reservation.name,
        remark: reservation.remark,
        summary: reservation.repeat
          ? summaryType(timezoneDate(reservation.startDateTime), {
              frequency: reservation.repeat.frequency,
              cycle: reservation.repeat.cycle.toString(),
              repeatDays: reservation.repeat.repeatDays,
              monthRepeatStandard: reservation.repeat.monthRepeatStandard,
              endType:
                reservation.repeat.endDate !== '9999-12-31'
                  ? getLocalizedText('반복 종료일')
                  : getLocalizedText('계속 반복'),
              endDay: reservation.repeat.endDate,
              endCount: 0,
            })
          : undefined,
        alarms: reservation.alarms,
        sharers: reservation.sharers,
        resourceMenuPoint: undefined,
      }
    : {
        itemId: queryWord?.itemId ?? 0,
        itemName: queryWord?.itemName ?? '',
        reservationStatus: formatReservationStatus(0),
        view: false,
        update: false,
        delete: false,
        repeatType: props.myReservation ? 'all' : 'piece',
        start: queryWord ? new Date(queryWord.start) : timezoneDate(),
        end: queryWord ? new Date(queryWord.end) : timezoneDate(),
        isPopupVisible: false,
        title: '',
        remark: '',
        summary: undefined,
        alarms: [],
        sharers: [],
        resourceMenuPoint: undefined,
      };

  const [state, setState] = useState<{
    itemId: number;
    itemName: string;
    reservationStatus: string;
    isTakeback?: boolean; // 반납 유무.
    view: boolean; // 보기 모드.
    delete: boolean; // 삭제 유무.
    update: boolean; // 수정 유무.
    repeatType: string; // 반복 예약 삭제(수정) 시 타입.
    start: Date;
    end: Date;
    isPopupVisible: boolean;
    title: string;
    remark: string;
    summary?: {
      one: string;
      two: string;
      three: string;
    };
    alarms: {
      type: string; // mail | alarm
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
    }[];
    sharers: {
      companyId: number;
      reservationId: number;
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      updateAt: string;
    }[];
    resourceMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(initialState);
  const [validation, setValidation] = useState('');

  const alarmTypeData = [
    { value: 'NONE', label: '- 선택 -' },
    { value: 'ALARM', label: getLocalizedText('알람') },
    { value: 'MAIL', label: getLocalizedText('메일') },
  ];
  const timeTypeData = [
    { value: 'MINUTE', label: getLocalizedText('분전') },
    { value: 'HOUR', label: getLocalizedText('시간전') },
    { value: 'DAY', label: getLocalizedText('일전') },
    { value: 'WEEK', label: getLocalizedText('주전') },
  ];

  /** 작성자 정보. */
  const writer = getDirectoryData({
    ...directory,
    companyId: reservation?.companyId ?? principal.companyId,
    employeeId: reservation?.employeeId ?? principal.employeeId,
  });

  /** 예약명 변경. */
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  /** 날짜 변경. */
  const handleChangeDate = (date: Date | null, type: string) => {
    if (date === null) return;
    date.setSeconds(0);
    if (type === 'start') {
      let { end } = state;
      if (end <= date) {
        end = new Date(date);
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
        end.setSeconds(0);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      setState((prev) => ({
        ...prev,
        start: date < prev.start ? date : prev.start,
        end: date,
      }));
    }
  };

  /** 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const { start, end } = state;
    const newTime = Number(value.split(':')[0]);
    const newMinute = Number(value.split(':')[1]);
    if (type === 'start') {
      const date = new Date(start);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (end <= date) {
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      const date = new Date(end);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (date <= start) {
        start.setHours(date.getHours());
        start.setMinutes(date.getMinutes() - 30);
      }
      setState((prev) => ({ ...prev, start, end: date }));
    }
  };

  /** 신청 사유 변경. */
  const handleChangeRemark = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      remark: event.target.value,
    }));
  };

  /** 대화상자 닫기. */
  const handleDialogClose = () => {
    const search = createQueryString(
      { queryWord: undefined, dialogMode: undefined, dialogType: undefined },
      queryParams,
    );
    dispatch(resourcesActions.reservationViewClear());
    dispatch(sessionActions.search(search));
  };

  /** 자원명 드롭메뉴 클릭. */
  const handleDropClick = (event?: React.MouseEvent) => {
    if (reservation) {
      setValidation(getLocalizedText('예약 수정 시 자원 수정은 불가능합니다.'));
      return;
    }
    if (!reservation && p2) {
      setValidation(
        getLocalizedText(
          '자원 아이템에서 예약 간단 설정 시 자원 수정은 불가능합니다.',
        ),
      );
      return;
    }
    const { resourceMenuPoint } = state;
    if (event !== undefined && resourceMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        resourceMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        resourceMenuPoint: undefined,
      }));
    }
  };

  /** 자원명 선택. */
  const handleDropItemClick = (
    id: number,
    parentId?: number,
    icon?: IconType,
  ) => {
    if (icon === ('folder' as const)) return;
    const name = items.find((a) => a.id === id)?.name ?? '';
    setState((prev) => ({
      ...prev,
      itemId: id,
      itemName: name,
      resourceMenuPoint: undefined,
    }));
  };

  /** 예약 상세입력 페이지로 이동. */
  const handleDetail = () => {
    delete queryParams.dialogType;
    delete queryParams.dialogMode;
    queryParams.contentMode = reservation ? 'update' : 'create'; // 등록, 수정 구분하기
    const updateData = {
      itemId: state.itemId,
      itemName: state.itemName,
      start: dateFormat(state.start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(state.end, 'yyyy-MM-DD HH:mm'),
      title: state.title,
      remark: state.remark,
      originStart: dateFormat(originStart, 'yyyy-MM-DD HH:mm'),
      originEnd: dateFormat(originEnd, 'yyyy-MM-DD HH:mm'),
      repeatType: reservation && state.summary ? state.repeatType : undefined,
    };
    dispatch(resourcesActions.updateData(updateData));
    go(props.pathname, getQueryParams(queryParams), props.hash);
  };

  /** 필수 입력값 체크 이벤트 */
  const handleEssential = () => {
    if (state.itemId === 0 || state.itemName === '') {
      setValidation(() => getLocalizedText('자원 아이템을 선택하세요.'));
      return;
    }
    if (state.title.trim() === '')
      setValidation(getLocalizedText('제목을 입력하세요.'));
  };

  /** 저장 */
  const handleSave = () => {
    if (!props.onSave) return;
    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const arg = {
      itemId: state.itemId,
      employeeId: principal.employeeId,
      name: state.title,
      remark: state.remark,
      startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
    };
    props.onSave(arg);
  };

  /** 수정 */
  const handleUpdate = () => {
    if (!reservation || !props.onUpdate) return;
    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const startDateTime = moment(reservation.startDateTime).isSame(start)
      ? undefined
      : dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    const endDateTime = moment(reservation.endDateTime).isSame(end)
      ? undefined
      : dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    // 반복 예약 수정
    if (state.update) {
      let arg = {
        id: reservation.id,
        itemId: state.itemId,
        employeeId: principal.employeeId,
        name: state.title,
        remark: reservation.remark === state.remark ? undefined : state.remark,
        startDateTime,
        endDateTime,
        lookupStartDateTime: undefined as string | undefined,
        lookupUpdateAt: undefined as string | undefined,
        updateAt: undefined as string | undefined,
      };
      if (state.repeatType !== 'all')
        arg = {
          ...arg,
          lookupStartDateTime: dateFormat(
            initialDate(originStart),
            'yyyy-MM-DD[T]HH:mm:ss.SSS',
          ),
          lookupUpdateAt: reservation.updateAt,
        };
      else arg = { ...arg, updateAt: reservation.updateAt };
      props.onUpdate({
        isRepeat: state.update,
        repeatType: state.repeatType,
        data: {
          ...arg,
          startDateTime,
          endDateTime,
        },
      });
    }
    // 일반 예약 수정
    else {
      const arg = {
        id: reservation.id,
        itemId: state.itemId,
        employeeId: principal.employeeId,
        name: state.title,
        remark: reservation.remark === state.remark ? undefined : state.remark,
        startDateTime,
        endDateTime,
        updateAt: reservation.updateAt,
      };
      props.onUpdate({
        isRepeat: false,
        repeatType: '',
        data: arg,
      });
    }
  };

  /** 예약 삭제 */
  const handleDelete = (isRepeat: boolean) => {
    if (!reservation || !props.onDelete) return;
    const start = initialDate(state.start);
    if (isRepeat) {
      const param = {
        id: reservation.id,
        startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS[Z]'),
        updateAt:
          reservation.repeat && state.repeatType === 'after'
            ? reservation.repeat.updateAt
            : reservation.updateAt,
      };
      props.onDelete({
        isRepeat,
        repeatType: state.repeatType,
        data: param,
      });
    } else
      props.onDelete({
        isRepeat,
        repeatType: '',
        data: {
          id: reservation.id,
          updateAt: reservation.updateAt,
        },
      });
  };

  /** 반복 예약 타입 변경. */
  const handleChangeRepeatType = (value: string) => {
    setState((prev) => ({
      ...prev,
      repeatType: value,
    }));
  };

  /** 트리 아이템 생성. */
  const treeData = useMemo(() => {
    const result = [
      ...folders
        .filter((z) => z.id === item?.folderId ?? 0)
        .map((a) => {
          return {
            id: a.id,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as const,
            seq: a.seq,
          };
        }),
      ...items
        .filter((z) => z.folderId === item?.folderId ?? 0)
        .map((a) => {
          return {
            id: a.id,
            parentId: a.folderId,
            text: a.name,
            strings: hangul.d(a.name),
            // eslint-disable-next-line prettier/prettier
          icon: a.useApprove ? 'document-check' as const : 'document-clock' as const,
            seq: a.seq,
          };
        }),
    ];
    return makeTreeData(result);
  }, [folders, items]);

  const renderDialog = () => {
    if (state.delete) {
      if (state.summary && !props.myReservation)
        return (
          <Dialog size="xs">
            <DialogHeader>
              <DialogTitle>{getLocalizedText('반복 예약 삭제')}</DialogTitle>
            </DialogHeader>
            <DialogBody>
              <div style={{ margin: '0 24px' }}>
                <RadioGroup
                  column
                  name="repeat delete"
                  data={[
                    {
                      value: 'piece',
                      label: getLocalizedText('이 예약만 삭제'),
                    },
                    {
                      value: 'after',
                      label: getLocalizedText('이 예약 및 향후 예약 삭제'),
                    },
                    {
                      value: 'all',
                      label: getLocalizedText('모든 예약 삭제'),
                    },
                  ]}
                  value={state.repeatType}
                  onChange={handleChangeRepeatType}
                />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('취소')}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    delete: false,
                    repeatType: 'piece',
                  }))
                }
              />
              <Button
                noDuplication
                text={getLocalizedText('삭제')}
                variant="contained"
                color="danger"
                onClick={() => handleDelete(true)}
              />
            </DialogFooter>
          </Dialog>
        );
      if (state.summary && props.myReservation)
        return (
          <DeleteConfirmation
            onCancel={() =>
              setState((prev) => ({
                ...prev,
                delete: false,
              }))
            }
            onSubmit={() => handleDelete(true)}
            message={getLocalizedText('반복 예약 삭제 시 모든 예약이 삭제')}
          >
            <strong>&apos;{state.title}&apos;</strong>
            {getLocalizedText('의 모든 예약을 정말 삭제하시겠습니까?')}
          </DeleteConfirmation>
        );
      return (
        <DeleteConfirmation
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              delete: false,
            }))
          }
          onSubmit={() => handleDelete(false)}
        >
          <strong>&apos;{state.title}&apos;</strong> 예약을 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }
    if (state.update) {
      if (state.summary && !props.myReservation)
        return (
          <Dialog size="xs">
            <DialogHeader>
              <DialogTitle>{getLocalizedText('반복 예약 수정')}</DialogTitle>
            </DialogHeader>
            <DialogBody>
              <div style={{ margin: '0 24px' }}>
                <RadioGroup
                  column
                  name="repeat update"
                  data={[
                    {
                      value: 'piece',
                      label: getLocalizedText('이 예약만 수정'),
                    },
                    {
                      value: 'after',
                      label: getLocalizedText('이 예약 및 향후 예약 수정'),
                    },
                    {
                      value: 'all',
                      label: getLocalizedText('모든 예약 수정'),
                    },
                  ]}
                  value={state.repeatType}
                  onChange={handleChangeRepeatType}
                />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('취소')}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    update: false,
                    repeatType: 'piece',
                  }))
                }
              />
              <Button
                text={getLocalizedText('수정')}
                variant="contained"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    view: false,
                    start:
                      reservation && state.repeatType === 'all'
                        ? timezoneDate(reservation.startDateTime)
                        : prev.start,
                    end:
                      reservation && state.repeatType === 'all'
                        ? timezoneDate(reservation.endDateTime)
                        : prev.end,
                  }))
                }
              />
            </DialogFooter>
          </Dialog>
        );
      if (state.summary && props.myReservation)
        return (
          <Dialog size="xs">
            <DialogBody>
              <div className="eui-alert-message">
                <p>
                  <strong>&apos;{state.title}&apos;</strong>
                  {getLocalizedText('의 모든 예약을 정말 수정하시겠습니까?')}
                </p>
                <p>
                  {getLocalizedText(
                    '반복 예약 수정 시 모든 예약이 수정됩니다.',
                  )}
                </p>
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('취소')}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    update: false,
                  }))
                }
              />
              <Button
                text={getLocalizedText('수정')}
                variant="contained"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    view: false,
                    start: reservation
                      ? new Date(reservation.startDateTime)
                      : prev.start,
                    end: reservation
                      ? new Date(reservation.endDateTime)
                      : prev.end,
                  }))
                }
              />
            </DialogFooter>
          </Dialog>
        );
    }
    return null;
  };

  // 조건에 따른 수정, 삭제 메뉴
  const footerMenu = () => {
    if (props.shared) return null;

    return (
      writer.employeeId === principal.employeeId &&
      (isAvailable || (!isAvailable && state.start > timezoneDate())) && (
        <>
          {/* 예약제인 경우만 수정 가능. */}
          {!items.find(({ id }) => id === reservation?.itemId)?.useApprove && (
            <Button
              text={getLocalizedText('수정')}
              variant="outlined"
              onClick={
                state.summary
                  ? () =>
                      setState((prev) => ({
                        ...prev,
                        update: true,
                      }))
                  : () =>
                      setState((prev) => ({
                        ...prev,
                        view: false,
                      }))
              }
            />
          )}
          <Button
            text={getLocalizedText('삭제')}
            variant="contained"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                delete: true,
              }))
            }
          />
        </>
      )
    );
  };

  const startTime = state.start.getHours().toString().padStart(2, '0');
  const startMinute = state.start.getMinutes().toString().padStart(2, '0');
  const endTime = state.end.getHours().toString().padStart(2, '0');
  const endMinute = state.end.getMinutes().toString().padStart(2, '0');

  if (state.view)
    return (
      <Dialog onClose={handleDialogClose}>
        <DialogHeader>
          <DialogTitle>{getLocalizedText('예약보기')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            <PostWrite.Item title={getLocalizedText('자원명')}>
              <span style={{ fontSize: '15px', fontWeight: 700 }}>
                {state.itemName}
              </span>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('제목')}>
              {state.title}
              <span style={{ marginLeft: '5px' }}>
                <Chip
                  className="cell-tag"
                  label={state.reservationStatus}
                  theme={formatReservationTheme(state.reservationStatus)}
                  size="xs"
                />
              </span>
              {state.isTakeback !== undefined && (
                <span style={{ marginLeft: '5px' }}>
                  <Chip
                    className="cell-tag"
                    label={
                      state.isTakeback
                        ? getLocalizedText('반납')
                        : getLocalizedText('대여')
                    }
                    theme={formatRentalTheme(state.isTakeback)}
                    size="xs"
                  />
                </span>
              )}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('예약자')}>
              {`${writer.organizationName}/${writer.employeeName}`}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사용기간')}>
              {`${dateFormat(state.start, 'yyyy-MM-DD HH:mm')} ~ ${dateFormat(
                state.end,
                'yyyy-MM-DD HH:mm',
              )}`}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('신청사유')}>
              {state.remark}
            </PostWrite.Item>
            {reservation && reservation.repeat && (
              <PostWrite.Item title={getLocalizedText('반복예약')}>
                {reservation.repeat.frequency === 1
                  ? `${state.summary?.one}${state.summary?.three}`
                  : `${state.summary?.one}${state.summary?.two}${state.summary?.three}`}
              </PostWrite.Item>
            )}
            {state.alarms.length > 0 && (
              <PostWrite.Item title={getLocalizedText('알림')}>
                {state.alarms.map((a, i) => {
                  const alarmValue =
                    alarmTypeData.find((x) => x.value === a.type)?.label ?? '';
                  const timeValue =
                    timeTypeData.find((x) => x.value === a.timeUnit)?.label ??
                    '';
                  const key = `${a.type}_${i}`;
                  return (
                    <div key={key} style={{ marginBottom: '2px' }}>
                      {`${alarmValue} / ${a.ammount}${timeValue}`}
                    </div>
                  );
                })}
              </PostWrite.Item>
            )}
            {state.sharers.length > 0 && (
              <PostWrite.Item title={getLocalizedText('공유자')}>
                <ChipGroup>
                  {state.sharers.map(
                    ({ referenceCompanyId, referenceId, referenceType }) => {
                      if (referenceType !== 3)
                        return (
                          <Chip
                            key={`${referenceCompanyId}/${referenceId}`}
                            label={getOrganizationName(
                              referenceCompanyId,
                              referenceId,
                            )}
                            etc={getCompanyName(referenceCompanyId)}
                            icon={
                              referenceType === 2
                                ? ('sitemap-fill' as const)
                                : ('company' as const)
                            }
                          />
                        );
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId: referenceCompanyId,
                        employeeId: referenceId,
                      });
                      return (
                        <Chip
                          key={`${referenceCompanyId}/${referenceId}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                        />
                      );
                    },
                  )}
                </ChipGroup>
              </PostWrite.Item>
            )}
          </PostWrite>
        </DialogBody>
        <DialogFooter>{footerMenu()}</DialogFooter>
        {renderDialog()}
      </Dialog>
    );

  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('예약설정')}</DialogTitle>
      </DialogHeader>
      <DialogBody noneOverFlow>
        <PostWrite>
          <PostWrite.Item required title={getLocalizedText('자원명')}>
            <DropMenu
              value={state.itemName}
              onClick={
                reservation
                  ? () =>
                      setValidation(
                        getLocalizedText(
                          '예약 수정 시 자원 수정은 불가능합니다.',
                        ),
                      )
                  : handleDropClick
              }
              label={getLocalizedText('자원 선택')}
            />
            {state.resourceMenuPoint && (
              <Menu
                point={state.resourceMenuPoint}
                onClose={() =>
                  setState((prev) => ({
                    ...prev,
                    resourceMenuPoint: undefined,
                  }))
                }
              >
                <div className="ui-organization-select">
                  <div className="body-panel">
                    <Tree
                      rootId={treeData[0].id}
                      selectedId={state.itemId}
                      items={treeData}
                      onItemClick={handleDropItemClick}
                    />
                  </div>
                </div>
              </Menu>
            )}
          </PostWrite.Item>
          <PostWrite.Item required title={getLocalizedText('제목')}>
            <TextField value={state.title} onChange={handleChangeTitle} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('예약자')}>
            {`${writer.organizationName}/${writer.employeeName}`}
          </PostWrite.Item>
          <PostWrite.Item required title={getLocalizedText('사용기간')}>
            <div
              style={{
                display: 'inline-flex',
                flexDirection: display === 'phone' ? 'column' : undefined,
              }}
            >
              {display === 'phone' ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '5px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      시작
                    </span>
                    <CustomDatePicker
                      controlClassName={
                        state.summary && state.repeatType !== 'piece'
                          ? 'disabled positionToTop'
                          : 'positionToTop'
                      }
                      disabled={state.summary && state.repeatType !== 'piece'}
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={state.start}
                      startDate={state.start}
                      endDate={state.end}
                      selectsStart
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'start')
                      }
                    />
                    <SelectField
                      data={bottom}
                      value={`${startTime}:${startMinute}`}
                      onChange={(value) => handleChangeTime(value, 'start')}
                    />
                  </div>
                  <div
                    style={
                      display === 'phone'
                        ? {
                            display: 'flex',
                            flexDirection: 'row',
                          }
                        : undefined
                    }
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      종료
                    </span>
                    <CustomDatePicker
                      controlClassName="positionToTop" // datePicker 위치 상단 고정.
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={state.end}
                      startDate={state.start}
                      endDate={state.end}
                      minDate={state.start}
                      selectsEnd
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'end')
                      }
                    />
                    <SelectField
                      data={bottom}
                      value={`${endTime}:${endMinute}`}
                      onChange={(value) => handleChangeTime(value, 'end')}
                    />
                  </div>
                </>
              ) : (
                <>
                  <CustomDatePicker
                    controlClassName={
                      state.summary && state.repeatType !== 'piece'
                        ? 'disabled'
                        : undefined
                    }
                    disabled={state.summary && state.repeatType !== 'piece'}
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.start}
                    startDate={state.start}
                    endDate={state.end}
                    selectsStart
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'start')
                    }
                  />
                  <SelectField
                    data={bottom}
                    value={`${startTime}:${startMinute}`}
                    onChange={(value) => handleChangeTime(value, 'start')}
                  />
                  <span style={{ margin: '2px 10px' }}> ~ </span>
                  <CustomDatePicker
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.end}
                    startDate={state.start}
                    endDate={state.end}
                    minDate={state.start}
                    selectsEnd
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'end')
                    }
                  />
                  <SelectField
                    data={bottom}
                    value={`${endTime}:${endMinute}`}
                    onChange={(value) => handleChangeTime(value, 'end')}
                  />
                </>
              )}
            </div>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('신청사유')}>
            <TextField
              value={state.remark}
              multiline
              rows={2}
              onChange={handleChangeRemark}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          text={getLocalizedText('자세히')}
          variant="outlined"
          onClick={handleDetail}
        />
        {state.itemId === 0 || state.title.trim() === '' ? (
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleEssential}
          />
        ) : (
          <Button
            noDuplication
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={reservation ? handleUpdate : handleSave}
          />
        )}
      </DialogFooter>
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </Dialog>
  );
}

export default ResourceLookupDialog;
