import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { paymentsActions } from '../../../../stores/sangsanginsecurities/payments';

function SecuritiesPaymentRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    if (mount)
      dispatch(
        paymentsActions.paymentList({ search, route: { pathname, search } }),
      );

    return () => {
      mount = false;
    };
  }, [pathname, search]);

  return null;
}

export default SecuritiesPaymentRoute;
