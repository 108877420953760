import React from 'react';
import { IconType } from '../../groupware-common/types/icon';
import { hangul } from '../../groupware-common/utils';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import Tree from './Tree';
import EmptyData from '../data/EmptyData';
import MenuItem from '../menu/MenuItem';

type Props<T> = {
  type?: 'full';
  disabledId?: T;
  selectedId?: T;
  items: {
    id: T;
    parentId: T;
    text: string;
    icon?: IconType;
    color?: string;
    strings: string[][];
    checked?: boolean;
    imagePath?: string;
    children?: React.ReactNode;
  }[];
  filter?: string;
  onItemClick?(id: T): void;
  onItemCheck?(id: T, event: React.ChangeEvent<HTMLInputElement>): void;
  useCheck?: boolean;
  checkItems?: T[];
};

function TreeList<T extends number | string>(props: Props<T>): JSX.Element {
  const handleItemClick = (id: T) => {
    const { onItemClick } = props;
    if (onItemClick) onItemClick(id);
  };

  const getTree = () => {
    const { type, disabledId, selectedId, items } = props;
    return (
      <Tree
        type={type}
        disabledId={disabledId}
        selectedId={selectedId}
        items={items}
        onItemClick={handleItemClick}
        useCheck={props.useCheck}
        onItemCheck={props.onItemCheck}
      />
    );
  };

  const getList = () => {
    const { disabledId, selectedId, items, filter = '' } = props;
    const strings = hangul.d(filter);
    const list = items.filter(
      (x) => x.strings && hangul.test(x.strings, strings),
    );
    if (list.length === 0)
      return <EmptyData message={getLocalizedText('검색결과가 없습니다.')} />;

    return (
      <>
        {list.map(({ id, text, icon, color, checked, children, imagePath }) => {
          return (
            <MenuItem
              key={id}
              id={id}
              label={text}
              icon={icon}
              color={color}
              disabled={id === disabledId}
              selected={id === selectedId}
              onClick={() => handleItemClick(id)}
              useCheck={props.useCheck}
              onCheck={props.onItemCheck}
              itemCheck={checked}
              imagePath={imagePath}
            >
              {children && children}
            </MenuItem>
          );
        })}
      </>
    );
  };

  const { filter = '' } = props;
  return !filter ? getTree() : getList();
}

export default TreeList;
