import React, { useState } from 'react';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Button from '../../../../../components/button/Button';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import Search from '../../../../../components/search/Search';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DocumentFormList(props: {
  scroll: React.RefObject<HTMLDivElement>;
  title: string;
  items: {
    checked: boolean;
    id: number;
    category: string;
    subject: string;
    createAt: string;
    updateAt: string;
    isEnable: boolean;
  }[];
  pagination: {
    no: number;
    total: number;
    row: number;
    onChangeRow(value: number): void;
  };
  onSearch(arg: { keyword: string; filter?: string }): void;
  onChangeChecked(arg: { id: number | 'all'; checked: boolean }): void;
  onClick(arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }): void;
  searchCode?: string;
  searchWord?: string;
}): JSX.Element {
  // console.log(`${DocumentFormList.name}.render(props)`, props);

  const { onClick } = props;

  const toolbarActions: ActionEventProps[] = [
    {
      code: 'form/delete',
      label: getLocalizedText('삭제'),
      type: 'icon',
      icon: 'trash-full',
    },
    {
      code: 'moveForm',
      label: getLocalizedText('양식이동'),
      type: 'icon',
      icon: 'folder-move',
    },
  ];

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 80 },
    {
      field: 'category',
      label: getLocalizedText('분류'),
      width: 130,
    },
    {
      field: 'subject',
      label: getLocalizedText('양식명'),
      minWidth: 200,
    },
    {
      field: 'createAt',
      label: getLocalizedText('등록일'),
      width: 150,
    },
    {
      field: 'state',
      label: getLocalizedText('상태'),
      width: 100,
      align: 'right',
    },
  ]);

  const rows: DataGridRowsProps<number> = props.items.map((row) => {
    return [
      {
        type: 'checkbox' as const,
        id: row.id,
        value: row.checked,
      },
      { type: 'text' as const, value: row.category },
      { type: 'subject' as const, value: row.subject, id: row.id },
      { type: 'date' as const, value: row.createAt, format: 'newSimple' },
      {
        type: 'chip' as const,
        value: row.isEnable
          ? getLocalizedText('사용')
          : getLocalizedText('미사용'),
        theme: row.isEnable ? 'success' : 'error',
      },
    ];
  });

  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.currentTarget;
    props.onChangeChecked({ id, checked });
  };

  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.currentTarget;
    props.onChangeChecked({ id: 'all', checked });
  };

  const { title, onSearch } = props;

  const handleSearch = (arg: { keyword: string; filter?: string }) => {
    onSearch(arg);
  };

  return (
    <>
      <EuiSetting.Header title={title}>
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={(event) => onClick({ code: 'folder/update', event })}
        />
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={(event) => onClick({ code: 'orderFolder', event })}
        />
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-full"
          onClick={(event) => onClick({ code: 'folder/delete', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <Search keyword={props.searchWord ?? ''} onSearch={handleSearch} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <DataGrid
          scrollbar={props.scroll}
          caption={getLocalizedText('결재문서')}
          columns={columns}
          rows={rows}
          checkedCount={props.items.filter((i) => i.checked).length}
          pagination={props.pagination}
          toolbarActions={toolbarActions}
          onChecked={handleChangeChecked}
          onCheckedAll={handleChangeCheckedAll}
          onClick={onClick}
          onChangeColumnVisible={handleChangeColumnVisible}
        />
      </EuiSetting.Content>
    </>
  );
}

export default React.memo(DocumentFormList);
