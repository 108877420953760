import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import { getApiConfig } from '../../groupware-common/apis/common/v1';
import { RootState } from '../../groupware-webapp/app/store';
import { getQueryParams } from '../../groupware-common/utils';

interface AssetManifest {
  files: {
    [key: string]: string;
  };
}
// 현재 페이지 main 스크립트 파일
export function findCurrentPageScripts(): string {
  const script = (
    document.querySelector('script[src*="main."]') as HTMLScriptElement
  )?.src;
  return script;
}

// 현재 빌드한 스크립트 파일
export async function findBuildScripts(host: string): Promise<AssetManifest> {
  try {
    const response = await fetch(`${host}/asset-manifest.json`);
    const json = response.json();
    return json;
  } catch (ex) {
    throw new Error();
  }
}

function Reload(): JSX.Element | null {
  const route = useSelector((state: RootState) => state.session.route);
  const { pathname, search } = route;
  const queryParams = getQueryParams(search);
  const authenticated = useSelector(
    (state: RootState) => state.session.authenticated,
  );
  const [error, setError] = useState('');
  const [useReload, setUseReload] = useState(true);
  useEffect(() => {
    setError('');
    if (
      !authenticated ||
      queryParams.contentMode ||
      queryParams.contentType ||
      queryParams.dialogMode === 'create' ||
      queryParams.dialogMode === 'update' ||
      pathname === '/adminconsole/dashboard/company'
    )
      setUseReload(false);
    else setUseReload(true);
  }, [route]);

  const checkChangeScript = async () => {
    const message = '업데이트된 내용이 존재하여 새로고침이 진행됩니다.';

    const { host } = getApiConfig();
    const currentScriptSrc = findCurrentPageScripts();
    const buildManifest = await findBuildScripts(host);
    const latestMainScript = `${host}${buildManifest.files['main.js']}`;

    if (currentScriptSrc !== latestMainScript && error === '')
      setError(message);
  };

  useEffect(() => {
    const startReload = setInterval(checkChangeScript, 3600000);
    if (error !== '' || !useReload) clearInterval(startReload);
    return () => {
      clearInterval(startReload);
    };
  }, [error, useReload]);

  // 새로고침 확인 클릭 이벤트.
  const handleConfirm = () => {
    window.location.reload();
  };

  // 업데이트 메세지 존재할 경우 time 반복 중지 후 안내메세지 대화상자 open.
  if (error !== '') {
    return (
      <Dialog size="xs">
        <DialogBody>
          <div className="eui-alert-message">{error}</div>
        </DialogBody>
        <DialogFooter>
          <Button text="확인" variant="contained" onClick={handleConfirm} />
        </DialogFooter>
      </Dialog>
    );
  }
  return null;
}

export default Reload;
