import { IconType } from '../../../groupware-common/types/icon';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

export type TemplateModule =
  | 'APPROVAL'
  | 'MAIL'
  | 'BOARD'
  | 'CALENDAR'
  | 'DOCUMENT'
  | 'RESOURCE'
  | 'ATTENDANCE'
  | 'COMMON';

export type TemplateType =
  | 'DOCUMENTS'
  | 'COUNT'
  | 'MESSAGES '
  | 'POSTS'
  | 'EVENTS'
  | 'TODOS'
  | 'RESERVATIONS'
  | 'WORK_TIME'
  | 'WORK_COUNT'
  | 'LEAVE'
  | 'BIRTHDAY'
  | 'PROFILE'
  | 'NEW_EMPLOYEES';

export type TemplateKey =
  | 'APPROVAL-DOCUMENT'
  | 'APPROVAL-COUNT'
  | 'CALENDAR-EVENTS'
  | 'RESOURCE-RESERVATIONS'
  | 'ATTENDANCE-LEAVE'
  | 'BOARD-POSTS'
  | 'DOCUMENT-POSTS'
  | 'COMMON-PROFILE'
  | 'COMMON-NEW_EMPLOYEES'
  | 'COMMON-BIRTHDAY'
  | 'DEFAULT';

export const getTemplateItem = (
  module: TemplateModule,
  type: TemplateType,
): TemplateItem => {
  if (module === 'APPROVAL' && type === 'DOCUMENTS')
    return {
      templateKey: 'APPROVAL-DOCUMENT',
      title: '모듈.결재',
      icon: 'stamp',
      isEdit: true,
    };
  if (module === 'APPROVAL' && type === 'COUNT')
    return {
      templateKey: 'APPROVAL-COUNT',
      title: '결재 카운트',
      icon: 'stamp',
    };
  if (module === 'CALENDAR' && type === 'EVENTS')
    return {
      templateKey: 'CALENDAR-EVENTS',
      title: '모듈.일정',
      icon: 'calendar',
      searchParams: 'selectedDate={selectedDate}&from={from}&to={to}',
    };
  if (module === 'RESOURCE' && type === 'RESERVATIONS')
    return {
      templateKey: 'RESOURCE-RESERVATIONS',
      title: '모듈.자원',
      icon: 'module',
    };
  if (module === 'ATTENDANCE' && type === 'LEAVE')
    return {
      templateKey: 'ATTENDANCE-LEAVE',
      title: '모듈.근태',
      icon: 'calendar-check',
    };
  if (module === 'BOARD' && type === 'POSTS')
    return {
      templateKey: 'BOARD-POSTS',
      title: '모듈.게시',
      icon: 'archive',
      isEdit: true,
    };
  if (module === 'DOCUMENT' && type === 'POSTS')
    return {
      templateKey: 'DOCUMENT-POSTS',
      title: '모듈.문서',
      icon: 'document',
      isEdit: true,
    };
  if (module === 'COMMON' && type === 'PROFILE')
    return {
      templateKey: 'COMMON-PROFILE',
      title: '프로필',
      icon: 'user-circle',
      isEdit: true,
    };
  if (module === 'COMMON' && type === 'NEW_EMPLOYEES')
    return {
      templateKey: 'COMMON-NEW_EMPLOYEES',
      title: '신규 입사자',
      icon: 'new',
      searchParams: 'from={from}&to={to}',
    };
  if (module === 'COMMON' && type === 'BIRTHDAY')
    return {
      templateKey: 'COMMON-BIRTHDAY',
      title: '생일자',
      icon: 'cake',
      searchParams: 'from={from}&to={to}',
    };
  return {
    templateKey: 'DEFAULT',
    title: '',
    icon: 'dot',
  };
};

export interface TemplateItem {
  templateKey: TemplateKey;
  title: string;
  icon: IconType;
  folderName?: string;
  isEdit?: boolean;
  searchParams?: string; // pageNo, rowsPerPage, start, to 등
}
