import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { dateSimplify } from '../../../../../../groupware-common/utils/ui';
import { IconType } from '../../../../../../groupware-common/types/icon';
import { BoardPostResponse } from '../../../../../apis/v1/dashboard/templates';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../../groupware-directory/stores/directory';
import { TemplateKey } from '../../../../../constants/templates';
import { b62 } from '../../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';
import {
  BoardFolderCategories,
  getFolderIdByKey,
} from '../../../../../stores/templates';

type BoardPostResponseData = Pick<BoardPostResponse, 'data' | 'responseType'>;

function BoardPostTemplate(props: {
  responseData: BoardPostResponseData;
  folderId: string;
  options?: string;
  onRedirect(redirectURL: string, responseType: TemplateKey): void;
}): JSX.Element {
  const companyId = useSelector(
    (state: RootState) => state.session.principal.companyId,
  );
  const directory = useDirectory();
  const { responseData } = props;
  const options = props.options ? JSON.parse(props.options) : { mode: 'list' };

  const handleGo = (id: number) => {
    const folderId = getFolderIdByKey(props.folderId, BoardFolderCategories);
    if (folderId)
      props.onRedirect(
        `/board/${folderId}/${b62(id)}`,
        responseData.responseType,
      );
    else
      props.onRedirect(
        `/board/${b62(Number(props.folderId))}/${b62(id)}`,
        responseData.responseType,
      );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {responseData.data.map((item) => {
        let employeeData:
          | {
              companyId: number;
              companyName: string;
              organizationId: number;
              organizationName: string;
              employeeId: number;
              employeeName: string;
              jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
              jobClassName: string;
              jobPositionId: number;
              jobPositionName: string;
              jobDutyId: number;
              jobDutyName: string;
              avatar?: string;
            }
          | undefined;
        if (item.employeeId) {
          employeeData = getDirectoryData({
            ...directory,
            companyId,
            employeeId: item.employeeId,
          });
        }

        const user = employeeData
          ? {
              name: employeeData.employeeName,
              from: employeeData.organizationName,
              jobClass: employeeData.jobClassName,
              avartar: employeeData.avatar,
            }
          : {
              name: '',
              icon: 'sitemap-fill' as IconType,
            };
        if (options && options.mode === 'gallery') {
          const className = item.isSecure
            ? 'default-thumbnail security'
            : 'default-thumbnail';
          const thumbnail =
            item.isThumbnail && item.thumbnailPath !== null && !item.isSecure
              ? `/board-static/thumbnail/${companyId}/${item.id}/${item.thumbnailPath}`
              : '';
          const children =
            thumbnail !== '' ? (
              <img src={thumbnail} alt={item.subject} />
            ) : (
              <div className={className}>
                {item.isSecure ? (
                  <span>{getLocalizedText('비공개이미지')}</span>
                ) : (
                  <span>{getLocalizedText('이미지없음')}</span>
                )}
              </div>
            );
          return (
            <a
              key={item.id}
              className="template-content board-post gallery"
              style={{ width: '100%', height: '100%' }}
              onClick={() => handleGo(item.id)}
            >
              <div
                className="post-thumbnail"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                }}
              >
                {children}
                <div
                  className="subject"
                  style={{ display: 'flex', width: '100%', paddingTop: '8px' }}
                >
                  <div className="text" style={{ paddingLeft: '3px' }}>
                    {item.subject}
                  </div>
                  <div
                    className="date"
                    style={{
                      flexShrink: 0,
                      marginLeft: 'auto',
                      fontSize: '12px',
                      color: 'var(--secondary-text-color)',
                    }}
                  >
                    {dateSimplify(item.updateAt)}
                  </div>
                </div>
                {/* <div
                      style={{
                        display: 'flex',
                        color: 'var(--secondary-text-color)',
                        alignItems: 'center',
                      }}
                    >
                      <UserInfo
                        avatar={user.avartar === '' ? undefined : user.avartar}
                        name={user.name}
                        from={user.from}
                      />
                    </div> */}
              </div>
            </a>
          );
        }
        if (options && options.mode === 'media') {
          const className = item.isSecure
            ? 'default-thumbnail security'
            : 'default-thumbnail';
          const thumbnail =
            item.isThumbnail && item.thumbnailPath !== null && !item.isSecure
              ? `/board-static/thumbnail/${companyId}/${item.id}/${item.thumbnailPath}`
              : '';
          const children =
            thumbnail !== '' ? (
              <img src={thumbnail} alt={item.subject} />
            ) : (
              <div className={className}>
                {item.isSecure ? (
                  <span>{getLocalizedText('비공개이미지')}</span>
                ) : (
                  <span>{getLocalizedText('이미지없음')}</span>
                )}
              </div>
            );
          return (
            <a
              key={item.id}
              onClick={() => handleGo(item.id)}
              className="template-content board-post media"
            >
              <div className="content" style={{ flexDirection: 'row' }}>
                <div
                  className="post-thumbnail"
                  style={{ height: '42px', width: '14%' }}
                >
                  {children}
                </div>
                <div className="subject" style={{ paddingLeft: '4px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="text">{item.subject}</div>
                    <div className="date">{dateSimplify(item.updateAt)}</div>
                  </div>
                </div>
              </div>
            </a>
          );
        }

        return (
          <a
            key={item.id}
            className="template-content board-post list"
            onClick={() => handleGo(item.id)}
          >
            <div className="content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div className="subject">{item.subject}</div>
              </div>
              <div className="head">
                <div className="user">
                  <span className="name">{user.name}</span>
                  {user.jobClass && (
                    <span className="class">{user.jobClass}</span>
                  )}
                  {user.from && (
                    <span className="organization">{user.from ?? ''}</span>
                  )}
                </div>
                <div className="date">{dateSimplify(item.updateAt)}</div>
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
}

export default BoardPostTemplate;
