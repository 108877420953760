import React, { useEffect, useRef } from 'react';
import EmptyData from '../../../../../components/data/EmptyData';
import EuiBody from '../../../../../components/layout/EuiBody';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { PaymentListItem } from '../../../../stores/sangsanginsecurities/payments';
import SecuritiesPaymentBodyItem from './SecuritiesPaymentBodyItem';

type User = {
  name: string;
  organization?: string;
  job?: string;
  avatar?: string;
};

function SecuritiesPaymentBody(props: {
  isPhone: boolean;
  pageNo?: number;
  list: PaymentListItem[];
  columns: Column<PostListItemType>[];
  onChangeChecked(arg: { id: string; checked: boolean }): void;
}): JSX.Element {
  const { isPhone, pageNo, list, columns, onChangeChecked } = props;

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo]);

  return (
    <EuiBody>
      {list.length === 0 ? (
        <EmptyData />
      ) : (
        <PostList
          name="payment"
          density="normal"
          type="full"
          style={{ minWidth: '2200px' }}
        >
          <PostList.Head>
            {columns.map(({ text, name, type: colunmType }) => (
              <PostList.Cell
                key={name}
                name={text}
                type={colunmType}
                align={colunmType === 'post' ? 'left' : 'center'}
              />
            ))}
          </PostList.Head>
          <PostList.Body scrollableNodeProps={scrollbar}>
            {list.map((a) => {
              const item: Record<
                string,
                User | string | number | boolean | undefined
              > = isPhone
                ? {
                    checked: a.checked,
                    evidncDeptCd: a.evidncDeptNm,
                    decisCont: a.decisCont,
                    evidncDvnm: a.evidncDvnm,
                    pttnDy: a.pttnDy,
                    acctNm: a.acctNm,
                    payTypNm: a.payTypNm,
                    sttlMthdNm: a.sttlMthdNm,
                    useNm: {
                      name: a.useNm,
                      organization: a.evidncDeptCd,
                    },
                    conm: a.conm,
                    amt: a.amt,
                    payTotAmt: a.payTotAmt,
                    payAmt: a.payAmt,
                    decisTypDvnm: a.decisTypDvnm,
                    slipNo: a.slipNo,
                    status: 1,
                  }
                : {
                    checked: a.checked,
                    evidncDeptCd: a.evidncDeptNm,
                    decisCont: a.decisCont,
                    evidncDvnm: a.evidncDvnm,
                    pttnDy: a.pttnDy,
                    acctNm: a.acctNm,
                    payTypNm: a.payTypNm,
                    sttlMthdNm: a.sttlMthdNm,
                    useNm: { name: a.useNm },
                    conm: a.conm,
                    amt: a.amt,
                    payTotAmt: a.payTotAmt,
                    payAmt: a.payAmt,
                    decisTypDvnm: a.decisTypDvnm,
                    slipNo: a.slipNo,
                    status: 1,
                  };

              return (
                <SecuritiesPaymentBodyItem
                  key={a.evidGrwNo}
                  isPhone={isPhone}
                  id={a.evidGrwNo}
                  selected={a.checked}
                  columns={columns}
                  item={item}
                  onCheckedChange={(id, checked) =>
                    onChangeChecked({ id, checked })
                  }
                />
              );
            })}
          </PostList.Body>
        </PostList>
      )}
    </EuiBody>
  );
}

export default SecuritiesPaymentBody;
