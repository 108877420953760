import React, { useState } from 'react';
import FileInput from '../../../../../components/attachments/FileInput';
import Button from '../../../../../components/button/Button';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import { FileUploadProps } from '../../../../../groupware-common/types';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function AttendanceAdjustDrawer(props: {
  folderId?: string;
  onClose(): void;
  onSave(arg: {
    id: number;
    name: string;
    reason: string;
    adjustMinutes: number;
    attachedment?: FileUploadProps;
  }): void;
}): JSX.Element {
  const directory = useDirectory();

  const addOptions: { value: string; label: string }[] = [
    { value: '+', label: getLocalizedText('추가') },
    { value: '-', label: getLocalizedText('제외') },
  ];
  const [state, setState] = useState<{
    userSelectMenuSelectedId?: string;
    userSelectMenuPoint?: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    reason: string;
    adjustMinutes: string;
    addOrMinus: string;
    id: number;
    name: string;
    organizationId: number;
    employeeId: number;
    attachedment?: FileUploadProps;
  }>({
    userSelectMenuSelectedId: undefined,
    userSelectMenuPoint: undefined,
    reason: '',
    addOrMinus: '+',
    adjustMinutes: '',
    id: 0,
    name: '',
    organizationId: 0,
    employeeId: 0,
    attachedment: undefined,
  });

  /** 사용자 선택 메뉴 토글. */
  const handleUserSelectMenuToggle = (event?: React.MouseEvent) => {
    const { userSelectMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        userSelectMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        userSelectMenuPoint: undefined,
      }));
    }
  };

  /** 사용자 선택. */
  const handleUserSelected = (arg: DirectoryTreeItemArg) => {
    const { item } = arg;
    const { extra } = item;
    if (extra.type === 'employee') {
      const directoryData = getDirectoryData({
        ...directory,
        companyId: extra.companyId,
        employeeId: extra.employeeId,
      });
      setState((prev) => ({
        ...prev,
        userSelectMenuSelectedId: item.id,
        userSelectMenuPoint: undefined,
        id: extra.employeeId,
        name: `${directoryData.organizationName} / ${extra.employeeName}`,
        organizationId: directoryData.organizationId,
        employeeId: extra.employeeId,
      }));
    }
  };

  /** 사유 작성. */
  const handleChangeReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      reason: event.target.value,
    }));
  };

  /** 조정일수 작성. */
  const handleChangeMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value
      .replace(/[^0-9]/g, '')
      .replace(/(\..*)\./g, '$1');
    setState((prev) => ({
      ...prev,
      adjustMinutes: data,
    }));
  };

  /** 추가/제외 선택. */
  const handleChangeSelectField = (value: string) => {
    setState((prev) => ({
      ...prev,
      addOrMinus: value,
    }));
  };

  /** 조정일수 선택. */
  const handleClick = (type: number) => {
    const adjustMinutes =
      state.adjustMinutes === '' ? 0 : parseFloat(state.adjustMinutes);
    setState((prev) => ({
      ...prev,
      adjustMinutes: (adjustMinutes + type).toString(),
    }));
  };

  /** 등록. */
  const handleSave = () => {
    const { id, name, addOrMinus, reason, attachedment, adjustMinutes } = state;
    let minutes = adjustMinutes !== '' ? parseFloat(adjustMinutes) * 60 : 0;
    if (addOrMinus === '-') minutes *= -1;
    props.onSave({
      id,
      name,
      reason,
      adjustMinutes: minutes,
      attachedment,
    });
  };

  /** 첨부파일 등록. */
  const handleChangeAttachedment = (name: string, file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) {
        const data: FileUploadProps = {
          id: `${Date.now()}`,
          name: file.name,
          size: file.size,
          file,
        };

        setState((prevState) => ({
          ...prevState,
          attachedment: data,
        }));
      }
    };

    if (file !== null && file !== undefined) {
      reader.readAsDataURL(file); // 1. 파일을 읽어 버퍼에 저장합니다.
    }
  };

  /** 첨부파일 삭제. */
  const handleRemoveAttachedment = () => {
    setState((prevState) => ({
      ...prevState,
      attachedment: undefined,
    }));
  };

  const {
    userSelectMenuPoint,
    userSelectMenuSelectedId,
    reason,
    addOrMinus,
    adjustMinutes,
    name,
    attachedment,
  } = state;

  return (
    <Drawer
      title={
        props.folderId === 'dayOffAdjust'
          ? getLocalizedText('연차조정')
          : getLocalizedText('대휴조정')
      }
      onClose={props.onClose}
    >
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('대상자')}>
            <DropMenu
              value={name}
              label={getLocalizedText('대상자 선택')}
              pressed={userSelectMenuPoint !== undefined}
              onClick={handleUserSelectMenuToggle}
            />
            {userSelectMenuPoint && (
              <DirectoryMenuTreeContainer
                deduplication
                point={userSelectMenuPoint}
                selectedId={userSelectMenuSelectedId}
                typeToFilter="employee"
                onItemClick={handleUserSelected}
                onClose={handleUserSelectMenuToggle}
              />
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('조정시간')}>
            <TextField
              value={adjustMinutes}
              onChange={handleChangeMinutes}
              width={100}
            />
            <span
              style={{
                marginRight: '5px',
              }}
            />
            <SelectField
              value={addOrMinus}
              data={addOptions}
              onChange={handleChangeSelectField}
            />
            <div className="item-content" style={{ marginTop: '10px' }}>
              <Button
                variant="outlined"
                text={getLocalizedText('1일')}
                size="sm"
                onClick={() => handleClick(8)}
              />
              <Button
                variant="outlined"
                text={getLocalizedText('4시간')}
                size="sm"
                onClick={() => handleClick(4)}
              />
              <Button
                variant="outlined"
                text={getLocalizedText('2시간')}
                size="sm"
                onClick={() => handleClick(2)}
              />
            </div>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('조정사유')}>
            <TextField value={reason} onChange={handleChangeReason} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('첨부파일')}>
            <FileInput
              value={attachedment ? attachedment.name : ''}
              onChange={handleChangeAttachedment}
              onRemove={handleRemoveAttachedment}
            />
          </PostWrite.Item>
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getLocalizedText('등록')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default AttendanceAdjustDrawer;
