import React from 'react';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import SecuritiesContentContainer from '../pages/root/security/content/SecuritesContentContainer';
import SecuritiesAuthorityContainer from '../pages/root/security/authority/SecuritiesAuthorityContainer';
import SecuritiesComposeContainer from '../pages/root/security/compose/SecuritiesComposeContainer';
import SecuritiesPaymentContainer from '../pages/root/security/payment/SecuritiesPaymentContainer';

function SecuritiesLocateContainer(props: {
  pathname: string;
  search: string;
  locationKey: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, locationKey } = props;
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const queryParams = getQueryParams(search);

  if (queryParams.contentMode === 'create')
    return <SecuritiesComposeContainer pathname={pathname} search={search} />;

  if (folderId === 'authority')
    return <SecuritiesAuthorityContainer pathname={pathname} search={search} />;

  if (folderId === 'payment')
    return <SecuritiesPaymentContainer pathname={pathname} search={search} />;
  return <SecuritiesContentContainer pathname={pathname} search={search} />;
}

export default SecuritiesLocateContainer;
