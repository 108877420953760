import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function DashBoardContentRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const route = { pathname, search };

    let mount = true;
    if (mount) {
      dispatch(sessionActions.setRoute(route));
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default DashBoardContentRoute;
