import React, { useState } from 'react';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Button from '../../../../../components/button/Button';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import DirectoryTree, {
  DirectoryTreeItem,
  DirectoryTreeItemArg,
} from '../../../../../components/tree/DirectoryTree';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/**
 * @property selectedId 선택된 아이디.(? undefined 허용)
 * @property items 트리 아이템 배열.
 * @property onItemClick 트리 아이템 클릭 이벤트.(? undefined 허용)
 * @property onOrganizationCreate 조직 생성 이벤트.
 * @property onEmployeeCreate 직원 생성 이벤트.
 */
type Props = {
  useLink?: boolean;
  selectedId?: string;
  items: DirectoryTreeItem[];
  onOrganizationLinkClick?(): void;
  onItemClick?(arg: DirectoryTreeItemArg): void;
  onOrganizationCreate(): void;
  onEmployeeCreate(): void;
};

function OrganizationChartContentList(props: Props): JSX.Element {
  // console.log(`${OrganizationChartContentList.name}.render`);

  const [filter, setFilter] = useState('');

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const {
    useLink,
    selectedId,
    items,
    onItemClick,
    onOrganizationCreate,
    onEmployeeCreate,
    onOrganizationLinkClick,
  } = props;

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('조직도')}>
        {!useLink ? (
          <>
            <Button
              iconType
              text={getLocalizedText('조직추가')}
              icon="department-plus"
              onClick={onOrganizationCreate}
            />
            <Button
              iconType
              text={getLocalizedText('직원추가')}
              icon="user-plus"
              onClick={onEmployeeCreate}
            />
          </>
        ) : (
          <Button
            iconType
            text={getLocalizedText('동기화')}
            icon="sync"
            onClick={onOrganizationLinkClick}
          />
        )}
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilter} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <DirectoryTree
          selectedId={selectedId}
          items={items}
          filter={filter}
          onItemClick={onItemClick}
        />
      </EuiSetting.Content>
    </>
  );
}

export default OrganizationChartContentList;
