import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { Language } from '../../../../../groupware-common/types';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  GroupItem,
  GroupView,
  SaveGroupArg,
  userGroupActions,
} from '../../../../stores/directory/group';
import DirectoryUserGroupEditDrawer from './DirectoryUserGroupEditDrawer';
import DirectoryUserGroupList from './DirectoryUserGroupList';
import DirectoryUserGroupView from './DirectoryUserGroupView';
import { IconType } from '../../../../../groupware-common/types/icon';
import { getGroupName } from '../../../../stores/directory';

/** 사용자 그룹 트리 아이템 생성. */
export function getUserTreeItems(arg: {
  items: GroupItem[];
  companyId: number;
  lang: Language;
}): {
  id: number;
  parentId: number;
  text: string;
  icon: IconType;
  strings: string[][];
}[] {
  const { items, companyId, lang } = arg;
  const treeItems = items.map((x) => {
    const name = getGroupName(companyId, x.id, '', lang);

    return {
      id: x.id,
      parentId: 0,
      text: name,
      icon: 'user-friends' as const,
      strings: hangul.d(name),
    };
  });
  return treeItems;
}

function DirectoryUserGroupContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const pathmap = getPathMap('/*/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/*/:selectedId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const list = useSelector((state: RootState) => state.directory.group.list);
  const view = useSelector((state: RootState) => state.directory.group.view);
  const languages = useSelector((s: RootState) => s.session.languages);
  const lang = (document.documentElement.getAttribute('lang') ||
    'ko-KR') as Language;

  const items = useMemo(() => {
    return getUserTreeItems({ items: list, companyId, lang });
  }, [list]);

  const handleLink = (id: number) => {
    dispatch(
      userGroupActions.findView({
        id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search,
        },
      }),
    );
  };

  // 모바일에서만 적용.
  const handleCloseView = () => {
    const route = { pathname: pathmap };
    dispatch(sessionActions.setRoute(route));
  };

  const handleDelete = () => {
    if (!view) return;

    const route = { pathname: `${getPathMap('/*/*/*', props.pathname)}` };
    dispatch(
      userGroupActions.deleteGroup({
        id: view.id,
        updateAt: view.updateAt,
        route,
      }),
    );
  };

  const handleSave = (data: SaveGroupArg) => {
    dispatch(userGroupActions.save({ data, route: { pathname } }));
  };

  const handleCRUD = (mode: 'create' | 'read' | 'update' | 'delete') => {
    switch (mode) {
      case 'create':
        dispatch(sessionActions.setDrawer({ mode }));
        break;
      // case 'update':
      case 'delete':
        dispatch(sessionActions.setDialog({ mode }));
        break;
      default:
        dispatch(sessionActions.search());
        break;
    }
  };

  /** 그룹 수정 */
  const handleUpdateGroup = (arg: GroupView) => {
    dispatch(
      userGroupActions.update({
        data: arg,
        route: {
          pathname,
          search: '',
        },
      }),
    );
  };

  /** 그룹 삭제 */
  const handleDeleteGroup = (arg: { id: number; updateAt: string }) => {
    const { id, updateAt } = arg;
    dispatch(
      userGroupActions.deleteGroup({
        id,
        updateAt,
        route: {
          pathname,
          search: '',
        },
      }),
    );
  };

  const renderList = () => {
    let result = null;
    result = (
      <DirectoryUserGroupList
        folderId={selectedId}
        items={items}
        onCreate={() => handleCRUD('create')}
        onItemClick={handleLink}
      />
    );
    return result;
  };

  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId
          ? getLocalizedText('그룹을 선택하세요.')
          : undefined;
      return <SplitUnselected label={message} />;
    }

    const localeView: GroupView = {
      ...view,
      names: languages.map((language) => {
        const value = getGroupName(companyId, view.id, '', language);
        return { code: language, value };
      }),
    };

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <DirectoryUserGroupView
          view={localeView}
          onUpdate={handleUpdateGroup}
          onDelete={handleDeleteGroup}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerMode } = queryParams;
    if (drawerMode === 'create')
      return (
        <DirectoryUserGroupEditDrawer
          onSave={handleSave}
          onClose={() => handleCRUD('read')}
        />
      );

    return null;
  };

  const renderDialog = () => {
    let result = null;

    if (queryParams.dialogMode === 'delete') {
      result = (
        <DeleteConfirmation
          onSubmit={handleDelete}
          onCancel={() => handleCRUD('read')}
        >
          <strong>
            &apos;{items.find((x) => x.id === selectedId)?.text}&apos;
          </strong>{' '}
          {getLocalizedText('그룹을 정말 삭제하시겠습니까?')}
        </DeleteConfirmation>
      );
    }

    return result;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{getLocalizedText('사용자 그룹')}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default DirectoryUserGroupContainer;
