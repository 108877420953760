import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import Drawer from '../../../../components/drawer/Drawer';
import DrawerAction from '../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../components/post/PostWrite';
import RadioGroup from '../../../../components/radio/RadioGroup';
import DropMenu from '../../../../components/selectField/DropMenu';
import SelectField from '../../../../components/selectField/SelectField';
import Switch from '../../../../components/switch/Switch';
import TextField from '../../../../components/textfield/TextField';
import { DirectoryTreeItemArg } from '../../../../components/tree/DirectoryTree';
import UploadImage from '../../../../components/upload/UploadImage';
import { CustomNumbers } from '../../../../groupware-common/types';
import { IconType } from '../../../../groupware-common/types/icon';
import { getParentItems } from '../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';
import DirectoryMenuTreeContainer from '../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../groupware-directory/stores/directory';
import { RootState } from '../../../../groupware-webapp/app/store';
import TreePicker from '../../../../groupware-webapp/pages/popup/TreePicker';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import { ResourceItem } from '../../../stores/folders';

function ResourceFolderItemEditDrawer(props: {
  parentId?: number;
  folders: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  item?: ResourceItem;
  onClose(): void;
  onSave(arg: {
    id?: number;
    folderId: number;
    name: string;
    status: boolean;
    useApprove: boolean;
    useRental: boolean;
    useTimeAvailable: boolean;
    availableFromTime: string;
    availableToTime: string;
    description: string;
    managers: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    users: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    exceptioners: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    image?: string;
  }): void;
}): JSX.Element {
  const { parentId, folders, item, onClose, onSave } = props;

  const directory = useDirectory();
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  /** 직위 */
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );

  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }

  const filteredManagers =
    item?.managers.filter((a) =>
      employees.some((x) => x.id === a.referenceId),
    ) ?? [];
  const filteredUsers =
    item?.users.filter(
      (a) =>
        a.referenceType === 1 ||
        (a.referenceType === 2 &&
          organizations.some((x) => x.id === a.referenceId)) ||
        (a.referenceType === 3 &&
          employees.some((x) => x.id === a.referenceId)),
    ) ?? [];
  const filteredExceptioners =
    item?.exceptioners.filter(
      (a) =>
        a.referenceType === 1 ||
        (a.referenceType === 2 &&
          organizations.some((x) => x.id === a.referenceId)) ||
        (a.referenceType === 3 &&
          employees.some((x) => x.id === a.referenceId)),
    ) ?? [];
  const initialState = item
    ? {
        id: item.id,
        folderId: item.folderId,
        name: item.name,
        status: item.status,
        description: item.description ?? '',
        paths: getParentItems(folders, item.folderId).map(({ text }) => text),
        validation: '',
        parentSelectDialogVisible: false,
        useApprove: item.useApprove,
        useTimeAvailable: item.useTimeAvailable,
        useRental: item.useRental,
        start: item.availableFromTime,
        end: item.availableToTime,
        managers: filteredManagers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        users: filteredUsers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        exceptioners: filteredExceptioners.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        managerMenuPoint: undefined,
        userMenuPoint: undefined,
        exceptionMenuPoint: undefined,
        imageData: undefined,
        imagePath: item.imagePath,
      }
    : {
        id: 0,
        folderId: parentId ?? 0,
        name: '',
        status: true,
        description: '',
        paths: parentId
          ? getParentItems(folders, parentId).map(({ text }) => text)
          : [],
        validation: '',
        parentSelectDialogVisible: false,
        useApprove: false,
        useTimeAvailable: false,
        useRental: false,
        start: '09:00',
        end: '18:00',
        managers: [],
        users: [],
        exceptioners: [],
        managerMenuPoint: undefined,
        userMenuPoint: undefined,
        exceptionMenuPoint: undefined,
        imageData: undefined,
        imagePath: '',
      };

  const [state, setState] = useState<{
    id: number;
    folderId: number;
    name: string;
    status: boolean;
    description: string;
    paths: string[];
    validation: string;
    parentSelectDialogVisible: boolean;
    useApprove: boolean;
    useTimeAvailable: boolean;
    useRental: boolean;
    start: string;
    end: string;
    imageData?: string;
    imagePath: string;
    managers: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    users: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    exceptioners: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    managerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    userMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    exceptionMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(initialState);

  /** 자원명 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, name: event.target.value }));
  };

  /** 자원 사용여부 변경. */
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, status: event.target.checked }));
  };

  /** 설명 변경. */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, description: event.target.value }));
  };

  /** 상위 선택 대화 상자 열기. */
  const handleParentSelectDialogOpen = () => {
    setState((prev) => ({ ...prev, parentSelectDialogVisible: true }));
  };

  /** 상위 선택 대화 상자 닫기. */
  const handleParentSelectDialogClose = () => {
    setState((prev) => ({ ...prev, parentSelectDialogVisible: false }));
  };

  /** 상위 선택 대화 상자 확인. */
  const handleParentSelectDialogConfirm = (
    id: number,
    text: string,
    path: string[],
  ) => {
    setState((prev) => ({
      ...prev,
      paths: path,
      folderId: id,
      parentSelectDialogVisible: false,
    }));
  };

  /** 예약 방식 변경. */
  const handleUseApproveChange = (value: boolean) => {
    setState((prev) => ({ ...prev, useApprove: value }));
  };

  /** 반납여부 변경. */
  const handleUseRentalChange = (value: boolean) => {
    setState((prev) => ({ ...prev, useRental: value }));
  };

  /** 사용 시간 제한 변경. */
  const handleUseTimeAvailableChange = (value: boolean) => {
    setState((prev) => ({ ...prev, useTimeAvailable: value }));
  };

  /** 사용 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const startIndex = bottom.findIndex((a) => a.value === state.start);
    const endIndex = bottom.findIndex((a) => a.value === state.end);
    const valueIndex = bottom.findIndex((a) => a.value === value);
    if (type === 'start') {
      setState((prev) => ({
        ...prev,
        start: value,
        end: endIndex < valueIndex ? bottom[valueIndex].value : prev.end,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        start: startIndex > valueIndex ? bottom[valueIndex].value : prev.start,
        end: value,
      }));
    }
  };

  /** 담당자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { managerMenuPoint } = state;
    if (event !== undefined && managerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        managerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        managerMenuPoint: undefined,
      }));
    }
  };

  /** 담당자 아이템 삭제. */
  const handleAdministratorsDelete = (id: number) => {
    setState((prev) => ({
      ...prev,
      managers: prev.managers.filter((a) => a.referenceId !== id),
    }));
  };

  /** 담당자 아이템 추가. */
  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prev) => {
      const { managers } = prev;
      const managerMenuPoint = undefined;
      if (managers.some((a) => a.referenceId === employeeId))
        return { ...prev, managerMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined) return { ...prev, managerMenuPoint };

      return {
        ...prev,
        managers: [
          ...managers,
          {
            referenceCompanyId: companyId,
            referenceId: employeeId,
            referenceType: 3,
          },
        ],
      };
    });
  };

  /** 사용권한 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuUserToggle = (event?: React.MouseEvent) => {
    const { userMenuPoint } = state;
    if (event !== undefined && userMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        userMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        userMenuPoint: undefined,
      }));
    }
  };

  /** 사용권한 아이템 추가. */
  const handleUserAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.users.some(
            (a) => a.referenceType === 1 && a.referenceId === companyId,
          )
        )
          return { ...prev, userMenuPoint: undefined };
        return {
          ...prev,
          users: [
            ...prev.users,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 1,
            },
          ],
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.users.some(
            (a) => a.referenceType === 2 && a.referenceId === organizationId,
          )
        )
          return { ...prev, userMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, userMenuPoint: undefined };

        return {
          ...prev,
          users: [
            ...prev.users,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 2,
            },
          ],
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.users.some((a) => a.referenceId === employeeId))
          return { ...prev, userMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, userMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          users: [
            ...prev.users,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 3,
            },
          ],
        };
      });
    }
  };

  /** 사용 권한 아이템 삭제. */
  const handleUserDelete = (id: number) => {
    setState((prev) => ({
      ...prev,
      users: prev.users.filter((a) => a.referenceId !== id),
    }));
  };

  /** 예외자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuExceptToggle = (event?: React.MouseEvent) => {
    const { exceptionMenuPoint } = state;
    if (event !== undefined && exceptionMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        exceptionMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        exceptionMenuPoint: undefined,
      }));
    }
  };

  /** 예외자 아이템 추가. */
  const handleExceptAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) => a.referenceType === 1 && a.referenceId === companyId,
          )
        )
          return { ...prev, exceptionMenuPoint: undefined };
        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 1,
            },
          ],
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) => a.referenceType === 2 && a.referenceId === organizationId,
          )
        )
          return { ...prev, exceptionMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, exceptionMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 2,
            },
          ],
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.exceptioners.some((a) => a.referenceId === employeeId))
          return { ...prev, exceptionMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, exceptionMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 3,
            },
          ],
        };
      });
    }
  };

  /** 예외자 아이템 삭제. */
  const handleExceptDelete = (id: number) => {
    setState((prev) => ({
      ...prev,
      exceptioners: prev.exceptioners.filter((a) => a.referenceId !== id),
    }));
  };

  /** 사진 정보 업로드. */
  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64)
        setState((prev) => ({
          ...prev,
          imagePath: getLocalizedText('자원 사진 정보'),
          imageData: base64.toString(),
        }));
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      reader.readAsDataURL(event.target.files[0]);
      const eventTargetValue = { ...event };
      eventTargetValue.target.value = '';
    }
  };

  /** 사진 정보 삭제. */
  const handleImageRemove = () => {
    setState((prev) => ({
      ...prev,
      imagePath: '',
      imageData: item?.imagePath ? '' : undefined,
    }));
  };

  /** 저장 */
  const handleSave = () => {
    if (state.folderId === 0) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('폴더를 선택하세요.'),
      }));
      return;
    }
    if (state.name.trim() === '') {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('자원명을 입력하세요.'),
      }));
      return;
    }
    if ((state.useApprove || state.useRental) && state.managers.length === 0) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('담당자를 1명 이상 설정하세요.'),
      }));
      return;
    }

    const managers = [...state.managers];
    const users = [...state.users];
    const exceptioners = [...state.exceptioners];

    if (item && item.managers.length > 0) {
      item.managers.forEach((a) => {
        if (
          managers.find((b) => b.referenceId === a.referenceId) === undefined
        ) {
          managers.push({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
            lookupDeleteAt: a.updateAt,
          });
        }
      });
    }
    if (item && item.users.length > 0) {
      item.users.forEach((a) => {
        if (users.find((b) => b.referenceId === a.referenceId) === undefined) {
          users.push({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
            lookupDeleteAt: a.updateAt,
          });
        }
      });
    }
    if (item && item.exceptioners.length > 0) {
      item.exceptioners.forEach((a) => {
        if (
          exceptioners.find((b) => b.referenceId === a.referenceId) ===
          undefined
        ) {
          exceptioners.push({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
            lookupDeleteAt: a.updateAt,
          });
        }
      });
    }
    const data = {
      id: item ? state.id : undefined,
      folderId: state.folderId,
      name: state.name,
      status: state.status,
      useApprove: state.useApprove,
      useRental: state.useRental,
      useTimeAvailable: state.useTimeAvailable,
      availableFromTime: state.start,
      availableToTime: state.end,
      description: state.description,
      managers,
      users,
      exceptioners,
      image: state.imageData,
    };
    onSave(data);
  };

  const renderDialog = () => {
    const { parentSelectDialogVisible, folderId } = state;
    if (parentSelectDialogVisible) {
      return (
        <TreePicker
          title={getLocalizedText('상위 폴더 선택')}
          list={folders}
          selectedId={folderId}
          onSelected={handleParentSelectDialogConfirm}
          onClose={handleParentSelectDialogClose}
        />
      );
    }
    return null;
  };

  const title = item
    ? getLocalizedText('자원 수정')
    : getLocalizedText('자원 등록');
  const src = state.imageData ?? state.imagePath;
  return (
    <>
      <Drawer title={getLocalizedText(`${title}`)} size="sm" onClose={onClose}>
        <DrawerBody>
          <PostWrite>
            <PostWrite.Item required title={getLocalizedText('폴더')}>
              <DropMenu
                value={state.paths}
                label={getLocalizedText('폴더 선택')}
                onClick={handleParentSelectDialogOpen}
              />
            </PostWrite.Item>
            <PostWrite.Item required title={getLocalizedText('자원명')}>
              <TextField value={state.name} onChange={handleNameChange} />
            </PostWrite.Item>
            <PostWrite.Item required title={getLocalizedText('사용여부')}>
              <Switch checked={state.status} onChange={handleStatusChange} />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('설명')}>
              <TextField
                multiline
                rows={2}
                value={state.description}
                onChange={handleDescriptionChange}
              />
            </PostWrite.Item>
            <PostWrite.Item
              title={getLocalizedText('예약방식')}
              tooltip={getLocalizedText(
                '예약 방식은 초기 설정 이후 변경할 수 없습니다.',
              )}
            >
              <RadioGroup
                disabled={!!item}
                data={[
                  {
                    label: getLocalizedText('즉시예약'),
                    value: false,
                  },
                  {
                    label: getLocalizedText('승인예약'),
                    value: true,
                  },
                ]}
                value={state.useApprove}
                name="useApprove"
                onChange={handleUseApproveChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('예약가능시간')}>
              <RadioGroup
                data={[
                  {
                    label: getLocalizedText('종일'),
                    value: false,
                  },
                  {
                    label: getLocalizedText('시간선택'),
                    value: true,
                  },
                ]}
                value={state.useTimeAvailable}
                name="useTimeAvailable"
                onChange={handleUseTimeAvailableChange}
              />
              {state.useTimeAvailable && (
                <div style={{ marginTop: '10px' }}>
                  <SelectField
                    data={bottom}
                    value={state.start}
                    onChange={(value) => handleChangeTime(value, 'start')}
                  />
                  <span style={{ margin: '5px 10px', display: 'inline-flex' }}>
                    ~
                  </span>
                  <SelectField
                    data={bottom}
                    value={state.end}
                    onChange={(value) => handleChangeTime(value, 'end')}
                  />
                </div>
              )}
            </PostWrite.Item>
            <PostWrite.Item
              title={getLocalizedText('반납여부')}
              tooltip={getLocalizedText(
                '반납 여부는 초기 설정 이후 변경할 수 없습니다.',
              )}
            >
              <RadioGroup
                disabled={!!item}
                data={[
                  {
                    label: getLocalizedText('일반자원'),
                    value: false,
                  },
                  {
                    label: getLocalizedText('반납자원'),
                    value: true,
                  },
                ]}
                value={state.useRental}
                name="useRental"
                onChange={handleUseRentalChange}
              />
            </PostWrite.Item>
            {(state.useApprove || state.useRental) && (
              <PostWrite.Item
                title={getLocalizedText('담당자')}
                tooltip={getLocalizedText(
                  '승인예약자원이거나 반납자원일 경우 담당자를 필수로 지정해야 하고 담당자는 직원만 선택할 수 있습니다.',
                )}
              >
                <ChipGroup
                  add={getLocalizedText('추가')}
                  onAdd={handleDirectoryTreeMenuToggle}
                >
                  {state.managers
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 3) {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                      return 0;
                    })
                    .map(
                      ({ referenceCompanyId: companyId, referenceId: id }) => {
                        const directoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={directoryData.employeeName}
                            etc={directoryData.organizationName}
                            avatar={directoryData.avatar}
                            onDelete={() => handleAdministratorsDelete(id)}
                          />
                        );
                      },
                    )}
                  {state.managerMenuPoint && (
                    <DirectoryMenuTreeContainer
                      deduplication
                      point={state.managerMenuPoint}
                      typeToFilter="employee"
                      onItemClick={handleAdministratorsAppend}
                      onClose={handleDirectoryTreeMenuToggle}
                    />
                  )}
                </ChipGroup>
              </PostWrite.Item>
            )}
            <PostWrite.Item title={getLocalizedText('사용권한')}>
              <ChipGroup
                add={getLocalizedText('추가')}
                onAdd={handleDirectoryTreeMenuUserToggle}
              >
                {state.users
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 3) {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 1) return -1;
                      if (a.referenceType === 2) {
                        if (b.referenceType === 1) return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 3) {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType: type,
                    }) => {
                      if (type !== 3)
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            etc={getCompanyName(companyId)}
                            icon={
                              type === 2
                                ? ('sitemap-fill' as const)
                                : ('company' as const)
                            }
                            onDelete={() => handleUserDelete(id)}
                          />
                        );
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                          onDelete={() => handleUserDelete(id)}
                        />
                      );
                    },
                  )}
                {state.userMenuPoint && (
                  <DirectoryMenuTreeContainer
                    deduplication
                    point={state.userMenuPoint}
                    onItemClick={handleUserAppend}
                    onClose={handleDirectoryTreeMenuUserToggle}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('예외자')}>
              <ChipGroup
                add={getLocalizedText('추가')}
                onAdd={handleDirectoryTreeMenuExceptToggle}
              >
                {state.exceptioners
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 3) {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 1) return -1;
                      if (a.referenceType === 2) {
                        if (b.referenceType === 1) return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 3) {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType: type,
                    }) => {
                      if (type !== 3)
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            etc={getCompanyName(companyId)}
                            icon={
                              type === 2
                                ? ('sitemap-fill' as const)
                                : ('company' as const)
                            }
                            onDelete={() => handleExceptDelete(id)}
                          />
                        );
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                          onDelete={() => handleExceptDelete(id)}
                        />
                      );
                    },
                  )}
                {state.exceptionMenuPoint && (
                  <DirectoryMenuTreeContainer
                    deduplication
                    point={state.exceptionMenuPoint}
                    onItemClick={handleExceptAppend}
                    onClose={handleDirectoryTreeMenuExceptToggle}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사진정보')}>
              <UploadImage
                style={{ minHeight: '50px', maxHeight: '170px' }}
                path={src}
                name={getLocalizedText('사진정보')}
                type="information"
                onChange={handleChangeImage}
                onDelete={handleImageRemove}
              />
            </PostWrite.Item>
          </PostWrite>
          {renderDialog()}
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack
        text={state.validation}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            validation: '',
          }))
        }
      />
    </>
  );
}

export default ResourceFolderItemEditDrawer;
