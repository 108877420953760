import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import FeedBack from '../../../../../components/alert/FeedBack';
import SelectField from '../../../../../components/selectField/SelectField';
import { serviceBasicActions } from '../../../../stores/basic';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import Chip from '../../../../../components/chip/Chip';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ServiceBasicContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const list = useSelector(
    (state: RootState) => state.service.menu.adminconsole.menus.list,
  );
  const basic = useSelector((state: RootState) => state.service.basic.basic);
  const initAdministrators = useSelector(
    (state: RootState) => state.service.basic.administrators,
  );
  const categories = useSelector(
    (state: RootState) => state.service.menu.category,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 7001)?.name ?? '';

  const initialState = {
    validation: '',
    change: false,
    saveing: false,
    initialModule: basic.initialModule,
    administrators: employees
      .filter(
        (a) =>
          a.companyId === principal.companyId &&
          initAdministrators.some(({ employeeId }) => a.id === employeeId),
      )
      .map((a) => {
        const updateAt =
          initAdministrators.find(({ employeeId }) => a.id === employeeId)
            ?.updateAt ?? '1000-01-01';
        return {
          companyId: a.companyId,
          employeeId: a.id,
          organizationId: a.representativeOrganizationId,
          avatar: a.avatar,
          updateAt,
        };
      }),
    administratorsMenuPoint: undefined as
      | { x: number; y: number; width: number; height: number }
      | undefined,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { initialModule, administrators } = state;

      const save = await dispatch(
        serviceBasicActions.update({
          data: {
            companyId: principal.companyId,
            initialModule,
            updateAt: basic.updateAt,
          },
        }),
      );

      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }

      const removeAdministrators = initAdministrators
        .filter(
          (a) =>
            administrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId, updateAt }) => ({ employeeId, updateAt }));
      const remove = await dispatch(
        serviceBasicActions.removeAdministrators(removeAdministrators),
      );

      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const appendAdministrators = administrators
        .filter(
          (a) =>
            initAdministrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId }) => ({ employeeId }));
      const append = await dispatch(
        serviceBasicActions.appendAdministrators(appendAdministrators),
      );

      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const { initialModule: newModule, updateAt: newUpdateAt } =
        save.payload as { initialModule: string; updateAt: string };
      setState((prev) => ({
        ...prev,
        change: false,
        saveing: false,
        initialModule: newModule,
        updateAt: newUpdateAt,
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  /** 취소. */
  const handleCancel = () => {
    setState(initialState);
  };

  /** 저장. */
  const handleSave = () => {
    setState((prev) => ({ ...prev, saveing: true }));
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  const handleChangeInitialModule = (module: string) => {
    setState((prev) => ({
      ...prev,
      initialModule: module,
      change: true,
    }));
  };

  /** 디렉터리 트리 메뉴 이벤트. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { administratorsMenuPoint } = state;
    if (event && !administratorsMenuPoint) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 추가. */
  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;

      setState((prev) => {
        const { administrators } = prev;
        if (administrators.some((a) => a.employeeId === employeeId))
          return { ...prev, administratorsMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, administratorsMenuPoint: undefined };
        const updateAt =
          initAdministrators.find((a) => a.employeeId === employeeId)
            ?.updateAt ?? '';

        return {
          ...prev,
          administrators: [
            ...administrators,
            {
              companyId,
              employeeId,
              organizationId: employee.representativeOrganizationId,
              avatar: employee.avatar,
              updateAt,
            },
          ],
          change: true,
        };
      });
    }
  };

  /** 관리자 삭제. */
  const handleAdministratorsDelete = (employeeId: number) => {
    setState((prev) => ({
      ...prev,
      administrators: prev.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
      change: true,
    }));
  };

  const {
    administrators,
    administratorsMenuPoint,
    change,
    saveing,
    validation,
  } = state;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('초기 메뉴')}>
          <SelectField
            data={list.map((x) => ({
              label: getLocalizedText(`모듈.${x.name}`),
              value: x.id,
            }))}
            value={state.initialModule}
            onChange={handleChangeInitialModule}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title={getLocalizedText('관리자')}>
          <ChipGroup
            add={getLocalizedText('추가')}
            onAdd={handleDirectoryTreeMenuToggle}
          >
            {administrators.map(
              ({ companyId, employeeId, organizationId, avatar }) => (
                <Chip
                  key={`${companyId}/${employeeId}`}
                  label={getEmployeeName(companyId, employeeId)}
                  etc={getOrganizationName(companyId, organizationId)}
                  avatar={avatar}
                  onDelete={() => handleAdministratorsDelete(employeeId)}
                />
              ),
            )}
            {administratorsMenuPoint && (
              <DirectoryMenuTreeContainer
                point={administratorsMenuPoint}
                typeToFilter="employee"
                onItemClick={handleAdministratorsAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {change && (
              <Button
                noDuplication={validation === ''}
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleSave}
              />
            )}
            {change && !saveing && (
              <Button
                text={getLocalizedText('취소')}
                variant="outlined"
                onClick={handleCancel}
              />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
        <FeedBack text={validation} onClose={handleSnackbarClose} />
      </EuiBody>
    </>
  );
}

export default ServiceBasicContainer;
