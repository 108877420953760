import React from 'react';
import { getQueryParams } from '../../groupware-common/utils';
import DashBoardContentContainer from '../pages/root/dashboard/content/DashBoardContentContainer';
import DashBoardComposeContainer from '../pages/root/dashboard/compose/DashBoardComposeContainer';

function DashBoardLocateContainer(props: {
  pathname: string;
  search: string;
  locationKey: string;
}): JSX.Element | null {
  const { pathname, search, locationKey: key } = props;
  const queryParams = getQueryParams(search);
  if (queryParams.contentMode === 'update') {
    return <DashBoardComposeContainer pathname={pathname} search={search} />;
  }
  return <DashBoardContentContainer pathname={pathname} search={search} />;
}

export default DashBoardLocateContainer;
