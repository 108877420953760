import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import {
  CommonProfileResponse,
  templateContentApi,
} from '../../../../../apis/v1/dashboard/templates';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../../groupware-directory/stores/directory';
import Divider from '../../../../../../components/divider/Divider';
import { getAvatarPath } from '../../../../../../groupware-common/utils';
import Avatar from '../../../../../../components/avatar/Avatar';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

type CommonProfileResponseData = Pick<CommonProfileResponse, 'data'>;

function CommonProfileTemplate(props: {
  responseData: CommonProfileResponseData;
}): JSX.Element {
  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const directory = useDirectory();

  const directoryData = getDirectoryData({
    ...directory,
    companyId,
    employeeId,
  });

  const { organizationName, employeeName, jobClassName } = directoryData;
  const avatar = getAvatarPath(companyId, employeeId);

  const { responseData } = props;

  const [state, setState] = useState<{
    loading: boolean;
    options?: { label: string; value: number }[];
  }>(() => {
    return {
      loading: true,
    };
  });

  useEffect(() => {
    let mount = true;
    async function run() {
      try {
        const response = await templateContentApi.findProfileOption();
        const options: { label: string; value: number }[] = [];
        if (response && mount) {
          Object.keys(response).forEach((key) => {
            if (key in responseData.data) {
              // TODO 라벨 백엔드에서 받아오도록 수정.
              let label = getLocalizedText('카운트');
              switch (key) {
                case 'APPROVAL_REQUEST': {
                  label = getLocalizedText('결재');
                  break;
                }
                case 'UNREAD_MAIL': {
                  label = getLocalizedText('메일');
                  break;
                }
                case 'TODAY_EVENT': {
                  label = getLocalizedText('일정');
                  break;
                }
                default:
                  break;
              }
              options.push({
                label,
                value: responseData.data[key].count,
              });
            }
          });
          setState((prev) => ({
            ...prev,
            loading: false,
            options,
          }));
        }
      } catch (ex) {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, []);

  return (
    <div className="template-content common-profile">
      <div
        className="profile-area"
        style={{
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1,
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          image={avatar}
          name={employeeName}
          className="dashboard-profile"
        />
        <div
          className="info"
          style={{ fontSize: '16px', fontWeight: 700, paddingTop: 20 }}
        >
          <div className="name">
            {jobClassName ? `${employeeName} ${jobClassName}` : employeeName}
          </div>
        </div>
        <div
          className="meta"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: 16,
          }}
        >
          <span style={{ paddingLeft: 4 }}>{organizationName}</span>
        </div>
      </div>
      <div className="count-area">
        {state.options ? (
          state.options.map((x, idx) => {
            if (idx === 0)
              return (
                <div key={x.label} className="content">
                  <div className="content-item">
                    <span className="count">{x.value}</span>
                    <span className="subject">{x.label}</span>
                  </div>
                </div>
              );
            return (
              <React.Fragment key={x.label}>
                <Divider orientation="vertical" />
                <div className="content">
                  <div className="content-item">
                    <span className="count">{x.value}</span>
                    <span className="subject">{x.label}</span>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CommonProfileTemplate;
