import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import {
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { serviceMenuActions } from '../../../../stores/module';
import ServiceLinkMenuEdit, { createMenuArg } from './ServiceLinkMenuEdit';
import ServiceLinkMenuItemList from './ServiceLinkMenuItemList';
import ServiceLinkMenuView, { UpdateMenuArg } from './ServiceLinkMenuView';

function ServiceLinkMenuContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;

  const pathmap = getPathMap('/*/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: string }>(
    '/*/*/*/:selectedId',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const principal = useSelector((s: RootState) => s.session.principal);
  const list = useSelector(
    (state: RootState) => state.service.menu.adminconsole.linkMenus.list,
  );
  const view = useSelector(
    (state: RootState) => state.service.menu.adminconsole.linkMenus.view,
  );
  const categories = useSelector(
    (state: RootState) => state.service.menu.category,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 7003)?.name ?? '';
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const items = useMemo(() => {
    const folders = list
      .map((x) => {
        return {
          id: x.id,
          parentId: 0,
          seq: x.seq,
          text: x.name,
          imagePath: x.imagePath,
          strings: hangul.d(x.name),
          updateAt: x.updateAt,
        };
      })
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
    return folders;
  }, [list, props.pathname]);

  /** 메뉴 보기 */
  const handleView = (menuId: number) => {
    const id = Number(menuId);
    if (id === undefined || id === null) return;
    dispatch(
      serviceMenuActions.linkView({
        companyId,
        id,
        route: {
          pathname: `${pathmap}/${id}`,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  // 모바일에서만 적용.
  const handleCloseView = () => {
    const route = { pathname: pathmap };
    dispatch(sessionActions.setRoute(route));
    dispatch(serviceMenuActions.clearView());
  };

  /** 순서 변경 드로워. */
  const handleSortChangeDrawer = () => {
    dispatch(sessionActions.setDrawer({ type: 'sortChange' }));
  };

  /** 서비스 메뉴 저장 */
  const handleSave = (data: createMenuArg | UpdateMenuArg) => {
    if ('id' in data) {
      delete queryParams.drawerMode;
      const route = {
        pathname: `${pathmap}/${data.id}`,
      };
      dispatch(serviceMenuActions.updateLink({ data, route }));
    } else {
      const route = {
        pathname: pathmap,
      };
      dispatch(serviceMenuActions.saveLink({ data, route }));
    }
  };

  /** 순서 변경 저장. */
  const handleSortChange = (
    arg: {
      id: number;
      label?: string;
    }[],
  ) => {
    const sortList = arg.map((a, i) => {
      const updateAt = list.find((x) => x.id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: i + 1,
        updateAt,
      };
    });
    const route = {
      pathname,
      search: createQueryString(
        { drawerMode: undefined, drawerType: undefined },
        queryParams,
      ),
    };

    dispatch(
      serviceMenuActions.sortLink({ companyId, data: [...sortList], route }),
    );
  };

  const handleOpenDrawer = (mode: 'create' | 'update') => {
    dispatch(sessionActions.setDrawer({ mode }));
  };

  const handleOpenDialog = (mode: 'delete') => {
    dispatch(sessionActions.setDialog({ mode }));
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const handleDeleteMenu = () => {
    if (view === undefined) return;
    delete queryParams.dialogMode;

    dispatch(
      serviceMenuActions.deleteLink({
        data: {
          id: view.id,
          updateAt: view.updateAt,
          companyId: principal.companyId,
        },
        route: {
          pathname: pathmap,
        },
      }),
    );
  };

  /** 메뉴 리스트 */
  const renderList = () => {
    let result = null;
    result = (
      <ServiceLinkMenuItemList
        selectedId={selectedId ? Number(selectedId) : undefined}
        items={items}
        onSort={handleSortChangeDrawer}
        onItemClick={handleView}
        onCreate={() => handleOpenDrawer('create')}
      />
    );
    return result;
  };

  /** 메뉴 보기 */
  const renderView = () => {
    if (view) {
      return (
        <EuiSetting.Right onClose={handleCloseView}>
          <ServiceLinkMenuView
            view={view}
            onUpdate={() => handleOpenDrawer('update')}
            onDelete={() => handleOpenDialog('delete')}
          />
        </EuiSetting.Right>
      );
    }
    const message =
      !view && selectedId ? '메뉴를 찾을 수 없습니다.' : undefined;
    return <SplitUnselected label={message} />;
  };

  const renderDrawer = () => {
    if (queryParams.drawerMode === 'create') {
      return (
        <ServiceLinkMenuEdit onSave={handleSave} onClose={handleCloseDrawer} />
      );
    }

    if (queryParams.drawerMode === 'update' && view) {
      return (
        <ServiceLinkMenuEdit
          view={view}
          onSave={handleSave}
          onClose={handleCloseDrawer}
        />
      );
    }

    if (queryParams.drawerType === 'sortChange') {
      const changeList = items
        .map(({ id, text, seq, updateAt }) => ({
          id,
          label: text,
          seq,
          updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

      return (
        <ChangeOrder
          title="순서 변경"
          list={changeList}
          onClose={handleCloseDrawer}
          onChange={handleSortChange}
        />
      );
    }

    return null;
  };

  const renderDialog = () => {
    const { dialogMode } = queryParams;
    if (view && dialogMode === 'delete') {
      return (
        <DeleteConfirmation
          onSubmit={handleDeleteMenu}
          onCancel={handleCloseDialog}
        >
          <strong>&apos;{view.name}&apos;</strong> 메뉴를 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }
    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default ServiceLinkMenuContainer;
