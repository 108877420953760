import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Menu from '../../../../../components/menu/Menu';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../../../../components/tree/DirectoryTree';
import { RootState } from '../../../../../groupware-webapp/app/store';

function SecuritiesSearch(props: {
  className?: string;
  keyword: string;
  options: { label: string; value: string }[];
  filter?: string;
  onSearch(arg: { filter: string; keyword?: string }): void;
}): JSX.Element {
  const { options, keyword, filter, onSearch } = props;

  const timerRef = useRef<number>();
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );

  const items = useMemo(() => {
    const result = getDirectoryTreeItems({
      companies,
      organizations,
    }).map((a, i) => {
      if (i === 0) return { ...a, expanded: true };
      return a;
    });
    return result;
  }, [companies, organizations]);

  const [directoryFilter, setDirectoryFilter] = useState('');
  const [state, setState] = useState<{
    option: string;
    directoryName: string;
    menuPoint: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    menuVisible: boolean;
    width: number;
    pressed: boolean;
  }>({
    option: filter ?? options[0].value,
    directoryName: keyword,
    menuPoint: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    menuVisible: false,
    width: 0,
    pressed: false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      option: props.filter ?? props.options[0].value,
      directoryName: props.keyword,
    }));
  }, [props]);

  const handleChangeApprovalState = (value: string) => {
    setState((prev) => ({ ...prev, option: value }));
  };

  const handleDirectoryFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDirectoryFilter(event.target.value);
  };

  const handleOrganizationSearch = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (state.menuVisible) handleMenuClose();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        menuPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        menuVisible: true,
        width: rect.width,
        pressed: true,
      }));
    }
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      menuVisible: false,
      pressed: false,
      width: 0,
      currentIndex: undefined,
    }));
    setDirectoryFilter('');
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const handleDirectoryItemClick = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    setState((prev) => ({ ...prev, directoryName: extra.organizationName }));
    handleMenuClose();
  };

  const handleDirectoryItemClear = () => {
    setState((prev) => ({ ...prev, directoryName: '' }));
    handleMenuClose();
  };

  const handleSearch = () => {
    const data = {
      filter: state.option,
      keyword: state.directoryName === '' ? undefined : state.directoryName,
    };
    onSearch(data);
  };

  let classname = 'eui-search';
  if (props.className) classname += ` ${props.className}`;
  return (
    <div className={classname}>
      <div className="basic-search">
        {options && state.option !== undefined && (
          <SelectField
            data={options}
            value={state.option}
            onChange={handleChangeApprovalState}
          />
        )}
        <TextField
          readonly
          type="search"
          className="keyword"
          placeholder="검색어"
          value={state.directoryName}
          onFocus={handleOrganizationSearch}
        />
        {state.menuVisible && (
          <Menu
            minWidth={state.width}
            point={state.menuPoint}
            onClose={handleMenuClose}
          >
            <div className="ui-organization-select">
              <div className="head-panel">
                <SimpleSearch
                  keyword={directoryFilter}
                  onSearch={handleDirectoryFilter}
                />
              </div>
              <div className="body-panel">
                <DirectoryTree
                  items={items}
                  filter={directoryFilter}
                  onItemClick={handleDirectoryItemClick}
                />
              </div>
              <div className="foot-panel">
                <Button
                  variant="outlined"
                  text="초기화"
                  onClick={handleDirectoryItemClear}
                />
              </div>
            </div>
          </Menu>
        )}
        <Button
          text="검색"
          iconType
          icon="search"
          className="submit"
          onClick={handleSearch}
        />
      </div>
    </div>
  );
}

export default SecuritiesSearch;
