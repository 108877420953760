/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  createQueryString,
  getPathMap,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';

import FeedBack from '../../../../../components/alert/FeedBack';
import ApprovalArchiveTransferHistoryList from '../compose/ApprovalArchiveTransferHistoryList';
import AccessDenied from '../../../../../components/error/AccessDenied';
import Loading from '../../../../../components/loading/Loading';
import { userSettingsSliceActions } from '../../../../stores/approval/user-settings';
import EmptyData from '../../../../../components/data/EmptyData';
import EuiBody from '../../../../../components/layout/EuiBody';

function ApprovalArchiveTransferHistoryContainer(props: {
  pathname: string;
  search: string;
  hash?: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const display = useSelector((state: RootState) => state.session.display);
  const list = useSelector(
    (state: RootState) => state.approval2.archive.transferHistoryItem,
  );
  const displayDensity = useSelector(
    (state: RootState) => state.approval2.userSettings.displayDensity,
  );
  const readingPaneMode = useSelector((state: RootState) =>
    display === 'phone' ? 'list' : state.approval2.archive.readingPaneMode,
  );
  const organizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );
  const archiveAdministor = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );
  const affiliatedOrganizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );
  const currentRowsPerPage = useSelector(
    (state: RootState) =>
      queryParams.rowsPerPage ?? state.approval2.userSettings.rowsPerPage,
  );
  const principal = useSelector((state: RootState) => state.session.principal);

  const [defaultColumns, setDefaultColumns] = useState<
    Column<PostListItemType>[]
  >([
    // eslint-disable-next-line prettier/prettier
   { name: 'createAt', text: '이관날짜', type: 'date', visible: true,  },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: '폴더명', type: 'folder', visible: true, disable: true},
    // eslint-disable-next-line prettier/prettier
    { name: 'reason', text: '사유', type: 'post', visible: true, disable: true },
    { name: 'sender', text: '보낸 사람', type: 'user', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'senderOrganization', text: '보낸 부서', type: 'user', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'receiveOrganization', text: '받은 부서', type: 'user', visible: true, disable: true },
  ]);

  const [state, setState] = useState<{
    validation: string;
  }>({
    validation: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (organizationId !== 0) setLoading(false);
  }, [organizationId]);

  // 부서장
  const manager = affiliatedOrganizations.find(
    ({ id }) => id === organizationId,
  )?.manager;

  //  기록물 관리자
  const archiveManager =
    archiveAdministor.find((x) => x.managerId === principal.employeeId) !==
    undefined;

  // 컬럼 보이기 변경.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setDefaultColumns((prevState) =>
      prevState.map((x) => (x.name === name ? { ...x, visible } : x)),
    );
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
    setState((prev) => ({
      ...prev,
    }));
  };

  // 목록 개수 변경.
  const handleChangeListCount = (value: string) => {
    const path = `${getPathMap('/*/*', pathname)}`;
    const query = createQueryString(
      {
        pageNo: 1,
        rowsPerPage: parseInt(value, 10),
        drawerMode: undefined,
        drawerType: undefined,
        type: undefined,
      },
      queryParams,
    );
    go(path, query, hash);
  };

  // 화면밀도 설정
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(userSettingsSliceActions.displayDensity(value));
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    if (drawerType === 'listSetting') {
      return (
        <ListSetting
          module="approval"
          isDisableModeOption
          type={readingPaneMode}
          density={displayDensity}
          listCount={`${currentRowsPerPage}`}
          columns={defaultColumns}
          onChangeDensity={handleChangeDensity}
          onColumnVisibleChange={handleColumnVisibleChange}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
          pathname="approval"
        />
      );
    }
    return null;
  };

  if (loading) return <Loading />;
  if (!manager && !archiveManager) {
    return <AccessDenied />;
  }
  return (
    <>
      <EuiBody>
        <ApprovalArchiveTransferHistoryList
          pathname={pathname}
          pageNo={queryParams.pageNo || 1}
          search={search}
          density={displayDensity}
          columns={defaultColumns.filter(({ visible }) => visible)}
          items={list}
          type="full"
        />
        {renderDrawer()}
        <FeedBack
          text={state.validation}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              validation: '',
            }))
          }
        />
      </EuiBody>
    </>
  );
}

export default ApprovalArchiveTransferHistoryContainer;
