import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { ContactListItem } from '../../../../stores/contacts';
import ContactContentBodyListItem from './ContactContentBodyListItem';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import EuiBody from '../../../../../components/layout/EuiBody';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

type Label = {
  id: number;
  name: string;
};

function ContactContentBodyList(props: {
  pathname: string;
  search: string;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  list: ContactListItem[];
  onItemClick?(id: number): void;
  onItemCheckedChange?(id: number, checked: boolean): void;
  onItemImportanceChange?(id: number): void;
  onLabelClick?(id: number): void;
}): JSX.Element {
  const { pathname, search, columns, list, density } = props;
  const { menu } = getPathParams<{ menu?: string }>('/*/:menu', pathname);
  const queryParams = getQueryParams(search);
  const display = useSelector((state: RootState) => state.session.display);
  const labels = useSelector((state: RootState) => state.contact.labels.list);

  const scrollbar = useRef<HTMLDivElement>(null);
  const searchChange = [
    queryParams.pageNo,
    queryParams.rowsPerPage,
    queryParams.searchCode,
    queryParams.searchWord,
  ];

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pathname, ...searchChange]);

  return (
    <EuiBody>
      <PostList name="contact" type="full" density={density}>
        {list.length === 0 ? (
          <EmptyData message={getLocalizedText('연락처가 없습니다.')} />
        ) : (
          <>
            <PostList.Head>
              {columns.map(({ name, type: colunmType, text }) => (
                <PostList.Cell
                  key={name}
                  name={text}
                  type={colunmType}
                  align={colunmType !== 'contact' ? 'center' : undefined}
                />
              ))}
            </PostList.Head>
            <PostList.Body scrollableNodeProps={scrollbar}>
              {list.map((a, i) => {
                const label: Label[] = a.labels
                  .map((labelId) => ({
                    id: labelId,
                    name: labels.find((x) => x.id === labelId)?.name ?? '',
                  }))
                  .sort((x, y) => +(x.id > y.id) || +(x.id === y.id) - 1);
                const name = {
                  name:
                    a.nickName !== '' ? `${a.name} [${a.nickName}]` : a.name,
                  organization: a.department,
                  job: a.jobTitle,
                  avatar: '',
                };
                let item: Record<
                  string,
                  string | number | boolean | User | Label[] | undefined
                > =
                  menu === 'trash'
                    ? {
                        checked: a.checked,
                        name,
                        company: a.company,
                        phone: a.representPhone,
                        email: a.representEmail,
                      }
                    : {
                        checked: a.checked,
                        importance: a.isStarred,
                        name,
                        company: a.company,
                        phone: a.representPhone,
                        email: a.representEmail,
                        label,
                      };
                if (display === 'tablet') {
                  if (menu === 'trash') {
                    item = {
                      checked: a.checked,
                      name,
                      company: a.company,
                      phone: a.representPhone,
                      email: a.representEmail,
                    };
                  } else {
                    item = {
                      checked: a.checked,
                      importance: a.isStarred,
                      name,
                      company: a.company,
                      phone: a.representPhone,
                      email: a.representEmail,
                    };
                  }
                }
                if (display === 'phone') {
                  if (menu === 'trash') {
                    item = {
                      checked: a.checked,
                      name,
                      phone: a.representPhone,
                      email: a.representEmail,
                    };
                  } else {
                    item = {
                      checked: a.checked,
                      importance: a.isStarred,
                      name,
                      phone: a.representPhone,
                      email: a.representEmail,
                    };
                  }
                }
                const key = `/i${i}/${a.id}`;
                return (
                  <ContactContentBodyListItem
                    key={key}
                    id={a.id}
                    selected={a.checked}
                    columns={columns}
                    item={item}
                    onClick={props.onItemClick}
                    onCheckedChange={props.onItemCheckedChange}
                    onImportanceChange={props.onItemImportanceChange}
                    onLabelClick={props.onLabelClick}
                  />
                );
              })}
            </PostList.Body>
          </>
        )}
      </PostList>
    </EuiBody>
  );
}

export default ContactContentBodyList;
