import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Search from '../../../../../components/search/Search';
import EuiBody from '../../../../../components/layout/EuiBody';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { documentActions } from '../../../../stores/approval/document';
import {
  getApprovalLineDrafter,
  getLastApprover,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import {
  b62,
  getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import {
  AttachFile,
  SharedFile,
  documentApi,
} from '../../../../apis/approval/v1/document';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import ApprovalPrint from '../../../common/components/ApprovalPrint';
import ApprovalContentHeadView from '../../../root/approval/ApprovalContentHeadView';
import ApprovalContentBodyViewContainer from '../../../root/approval/ApprovalContentBodyViewContainer';
import Loading from '../../../../../components/loading/Loading';
import ApprovalAttachedDocumentDialog from '../../../root/approval/content/ApprovalAttachedDocumentDialog';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import ApprovalAttachedFileDialog from '../../../root/approval/content/ApprovalAttachedFileDialog';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Button from '../../../../../components/button/Button';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import ApprovalInterworkingLogDrawer from '../../../common/drawers/ApprovalInterworkingLogDrawer';

function statusLabel(type: string) {
  switch (type) {
    case 'proceeding':
      return getLocalizedText('진행중');
    case 'return':
      return getLocalizedText('반려');
    case 'done':
      return getLocalizedText('완료');
    case 'cancel':
      return getLocalizedText('취소');
    default:
      return '';
  }
}

function statusTheme(type: string) {
  switch (type) {
    case 'proceeding':
      return 'primary';
    case 'return':
      return 'error';
    case 'done':
      return 'success';
    case 'cancel':
      return 'cancel';
    default:
      return undefined;
  }
}

function ApprovalTrashContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const pathParams = getPathParams<{ p1?: number }>(
    `/*/*/*/:p1$base62`,
    props.pathname,
  );
  const propsItemId = pathParams.p1;
  const queryParams = getQueryParams(props.search);

  const directory = useDirectory();
  const principal = useSelector((s: RootState) => s.session.principal);
  const trashItems = useSelector((s: RootState) => s.approval2.document.trash);
  const view = useSelector((s: RootState) =>
    propsItemId !== undefined &&
    propsItemId === s.approval2.document.trash.view.data?.id
      ? s.approval2.document.trash.view.data
      : undefined,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6007)?.name;

  const toolbarActions: ActionEventProps[] = [
    {
      code: 'moveToTrash',
      label: getLocalizedText('완전삭제'),
      type: 'icon',
      icon: 'trash-full',
    },
    {
      code: 'moveToRestore',
      label: getLocalizedText('복원'),
      type: 'icon',
      icon: 'redo',
    },
  ];

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 56 },
    {
      field: 'importance',
      label: getLocalizedText('중요도'),
      width: 68,
      align: 'center',
    },
    {
      field: 'work',
      label: getLocalizedText('업무'),
      width: 168,
    },
    {
      field: 'documentNo',
      label: getLocalizedText('문서번호'),
      width: 220,
    },
    {
      field: 'subject',
      label: getLocalizedText('제목'),
      minWidth: 200,
    },
    {
      field: 'drafter',
      label: getLocalizedText('기안자'),
      width: 158,
    },
    {
      field: 'lastApprover',
      label: getLocalizedText('최종결재자'),
      width: 158,
    },
    {
      field: 'lastUpdater',
      label: getLocalizedText('삭제자'),
      width: 158,
    },
    {
      field: 'date',
      label: getLocalizedText('기안일'),
      width: 118,
      align: 'right',
    },
    {
      field: 'status',
      label: getLocalizedText('상태'),
      width: 98,
      align: 'right',
    },
  ]);

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<{
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
    attachFileView?: AttachFile;
    sharedFileView?: SharedFile;
  }>({
    dialogs: [],
    attachFileView: undefined,
    sharedFileView: undefined,
  });

  useEffect(() => {
    setLoading(false);
    scrollbar.current?.scrollTo(0, 0);
  }, [props]);

  /** 기본검색 */
  const handleSearch = (value: { keyword: string; filter: string }) => {
    const params = { ...queryParams };
    delete params.pageNo;
    params.searchCode = value.filter;
    params.searchWord = value.keyword;
    dispatch(
      documentActions.trashList({
        search: getQueryParams(params),
        route: {
          pathname: `${getPathMap('/*/*/*', props.pathname)}`,
          search: getQueryParams(params),
        },
      }),
    );
  };

  /* 목록개수 변경 */
  const handleChangeRowLength = (value: number) => {
    const params = { ...queryParams };
    delete params.pageNo;
    params.rowsPerPage = value;
    dispatch(
      documentActions.trashList({
        search: getQueryParams(params),
        route: {
          pathname: `${getPathMap('/*/*/*', props.pathname)}`,
          search: getQueryParams(params),
        },
      }),
    );
  };

  /** 목록 노출 */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  /* 체크박스 선택 */
  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    dispatch(
      documentActions.trashChecked({
        itemId: id,
        checked: event.target.checked,
      }),
    );
  };
  /* 체크박스 전체선택 */
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      documentActions.trashChecked({
        itemId: 'all',
        checked: event.target.checked,
      }),
    );
  };

  // 파일 다운로드
  const download = (blob: Blob) => {
    if (!blob)
      throw new Error(
        getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
      );

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  /** 클릭이벤트 */
  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id: number;
  }) => {
    const { code, id } = arg;

    /** 이전 페이지. */
    if (code === 'prePage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) - 1;
      if (params.pageNo > 0) {
        if (params.pageNo === 1) {
          delete params.pageNo;
        }
        dispatch(
          documentActions.trashList({
            search: getQueryParams(params),
            route: {
              pathname,
              search: getQueryParams(params),
            },
          }),
        );
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) + 1;
      dispatch(
        documentActions.trashList({
          search: getQueryParams(params),
          route: {
            pathname,
            search: getQueryParams(params),
          },
        }),
      );
    }

    if (code === 'cancelSelected') {
      dispatch(documentActions.trashChecked({ itemId: 'all', checked: false }));
    }

    /** 리스트 아이템 클릭 */
    if (code === 'subject') {
      dispatch(
        documentActions.trashView({
          id,
          route: {
            pathname: `${pathname}/${b62(id)}`,
            search,
          },
        }),
      );
    }

    /** 삭제, 복원 */
    if (code === 'moveToTrash' || code === 'moveToRestore') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    if (code === 'print') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    if (code === 'save') {
      const param = trashItems.list
        .filter((a) => a.checked)
        .map((a) => {
          const affiliateCompanyId =
            a.companyId !== principal.companyId ? a.companyId : undefined;
          return {
            id: a.id,
            affiliateCompanyId,
          };
        });
      if (param.length === 0) return;
      documentApi
        .download(param)
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
  };

  /** 목록으로 돌아가기. */
  const handleListGo = () => {
    dispatch(
      sessionActions.setRoute({
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        search,
      }),
    );
  };

  const items = trashItems.list.map((a) => {
    const statusToNumber = parseInt(a.status, 10);
    let status = '';
    if (statusToNumber === 1) status = 'proceeding';
    else if (statusToNumber === 3) status = 'done';
    else if (statusToNumber === 4) status = 'return';
    return {
      checked: a.checked,
      companyId: a.companyId,
      id: a.id,
      workName: a.workName,
      status,
      importance: parseInt(a.importance, 10),
      no: a.no,
      subject: a.subject,
      approvalLine: a.approvalLine,
      draftAt: a.draftAt,
      completedAt: a.completeAt,
      updaterId: a.deleterId,
      linkWait: a.linkWait,
    };
  });

  const rows: DataGridRowsProps<number> = items.map((l) => {
    const drafter = getApprovalLineDrafter(l.approvalLine);
    const lastApprover = getLastApprover(l.approvalLine);
    const lastUpdater = l.updaterId;

    if (drafter !== undefined) {
      let drafterDirectoryData:
        | {
            companyId: number;
            companyName: string;
            organizationId: number;
            organizationName: string;
            employeeId: number;
            employeeName: string;
            jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
            jobClassName: string;
            jobPositionId: number;
            jobPositionName: string;
            jobDutyId: number;
            jobDutyName: string;
            avatar?: string;
          }
        | undefined;
      if (drafter.employeeId) {
        drafterDirectoryData = getDirectoryData({
          ...directory,
          ...drafter,
        });
      }

      let lastUpdaterDirectoryData:
        | {
            companyId: number;
            companyName: string;
            organizationId: number;
            organizationName: string;
            employeeId: number;
            employeeName: string;
            jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
            jobClassName: string;
            jobPositionId: number;
            jobPositionName: string;
            jobDutyId: number;
            jobDutyName: string;
            avatar?: string;
          }
        | undefined;

      if (lastUpdater) {
        lastUpdaterDirectoryData = getDirectoryData({
          ...directory,
          companyId: principal.companyId,
          employeeId: lastUpdater,
        });
      }
      if (lastApprover !== undefined) {
        const data = Array.isArray(lastApprover)
          ? lastApprover[0]
          : lastApprover;
        let lastApproverJobClassName = '';
        if (data.employeeId) {
          const lastApproverDirectoryData = getDirectoryData({
            ...directory,
            ...data,
          });
          lastApproverJobClassName = lastApproverDirectoryData.jobPositionName;
        }
        return [
          {
            type: 'checkbox' as const,
            id: l.id,
            value: l.checked,
          },
          {
            type: 'icon' as const,
            value:
              l.importance === 2
                ? getLocalizedText('높음')
                : getLocalizedText('보통'),
            icon: l.importance === 2 ? 'exclamation' : undefined,
            theme: l.importance === 2 ? 'danger' : undefined,
          },
          { type: 'text' as const, value: l.workName },
          { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
          {
            type: 'subject' as const,
            value: l.subject,
            id: l.id,
            approvalLine: l.approvalLine,
            linkWait: l.linkWait,
          },
          {
            type: 'user' as const,
            avatar: drafterDirectoryData
              ? getAvatarPath(drafterDirectoryData)
              : '',
            icon: drafterDirectoryData ? undefined : 'sitemap-fill',
            value: drafterDirectoryData
              ? `${drafterDirectoryData.employeeName} ${drafterDirectoryData.jobPositionName}`
              : drafter.organizationName,
            from: drafterDirectoryData
              ? drafterDirectoryData.organizationName
              : '',
          },
          {
            type: 'user' as const,
            avatar: data.employeeId ? getAvatarPath(data) : '',
            icon: data.employeeId ? undefined : 'sitemap-fill',
            value: data.employeeId
              ? `${data.employeeName} ${lastApproverJobClassName}`
              : data.organizationName,
            from: data.employeeId ? data.organizationName : '',
          },

          {
            type: 'user' as const,
            avatar: lastUpdaterDirectoryData
              ? getAvatarPath(lastUpdaterDirectoryData)
              : '',
            icon: lastUpdaterDirectoryData ? undefined : 'sitemap-fill',
            value: lastUpdaterDirectoryData
              ? `${lastUpdaterDirectoryData.employeeName} ${lastUpdaterDirectoryData.jobPositionName}`
              : drafter.organizationName,
            from: lastUpdaterDirectoryData
              ? lastUpdaterDirectoryData.organizationName
              : '',
          },

          { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
          {
            type: 'chip' as const,
            value: statusLabel(l.status),
            theme: statusTheme(l.status),
          },
        ];
      }
      return [
        {
          type: 'checkbox' as const,
          id: l.id,
          value: l.checked,
        },
        {
          type: 'icon' as const,
          value:
            l.importance === 2
              ? getLocalizedText('높음')
              : getLocalizedText('보통'),
          icon: l.importance === 2 ? 'exclamation' : undefined,
          theme: l.importance === 2 ? 'danger' : undefined,
        },
        { type: 'text' as const, value: l.workName },
        { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
        { type: 'subject' as const, value: l.subject, id: l.id },
        {
          type: 'user' as const,
          avatar: drafterDirectoryData
            ? getAvatarPath(drafterDirectoryData)
            : '',
          icon: drafterDirectoryData ? undefined : 'sitemap-fill',
          value: drafterDirectoryData
            ? `${drafterDirectoryData.employeeName} ${drafterDirectoryData.jobPositionName}`
            : drafter.organizationName,
          from: drafterDirectoryData
            ? drafterDirectoryData.organizationName
            : '',
        },
        {
          type: 'user' as const,
          avatar: '',
          value: '',
          from: '',
        },
        { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
        {
          type: 'chip' as const,
          value: statusLabel(l.status),
          theme: statusTheme(l.status),
        },
      ];
    }
    return [
      {
        type: 'checkbox' as const,
        id: l.id,
        value: l.checked,
      },
      {
        type: 'icon' as const,
        value:
          l.importance === 2
            ? getLocalizedText('높음')
            : getLocalizedText('보통'),
        icon: l.importance === 2 ? 'exclamation' : undefined,
        theme: l.importance === 2 ? 'danger' : undefined,
      },
      { type: 'text' as const, value: l.workName },
      { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
      { type: 'subject' as const, value: l.subject, id: l.id },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
      {
        type: 'chip' as const,
        value: statusLabel(l.status),
        theme: statusTheme(l.status),
      },
    ];
  });

  /** dialog 닫기 */
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId, affiliateCompanyId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument' || dialogType === 'draftdocument') {
      if (state.dialogs.length > 2 || dialogType === 'draftdocument') {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  /** 문서 완전 삭제 */
  const handleMoveToTrash = () => {
    if (queryParams.pageNo) delete queryParams.pageNo;
    if (queryParams.dialogType) delete queryParams.dialogType;

    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });

    if (view === undefined) {
      const data = trashItems.list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      if (data.length === 1) {
        dispatch(documentActions.deleteDocument({ data: data[0], route }));
      } else {
        dispatch(documentActions.deleteDocument({ data, route }));
      }
    } else {
      const data = {
        affiliatedCompanyId: view.affiliatedCompanyId,
        id: view.id,
        updateAt: view.updateAt,
      };
      dispatch(documentActions.deleteDocument({ data, route }));
    }
  };

  /** 문서 복원 */
  const handleMoveToRestore = () => {
    if (queryParams.pageNo) delete queryParams.pageNo;

    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        option: 'CLEAR_DIALOG',
      },
    });

    if (view === undefined) {
      const data = trashItems.list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      if (data.length === 1) {
        dispatch(documentActions.restoreDocument({ data: data[0], route }));
      } else {
        dispatch(documentActions.restoreDocument({ data, route }));
      }
      dispatch(sessionActions.setDialog());
    } else {
      const data = {
        affiliatedCompanyId: view.affiliatedCompanyId,
        id: view.id,
        updateAt: view.updateAt,
      };
      dispatch(documentActions.restoreDocument({ data, route }));
    }
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 인쇄 대화상자
    if (dialogType === 'print') {
      let listId = trashItems.list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
          };
        });
      if (propsItemId && view) listId = [{ id: view.id }];
      return (
        <ApprovalPrint
          folderId={0}
          onClose={handleCloseDialog}
          listId={listId}
        />
      );
    }

    // 첨부파일 보기
    if (dialogType === 'attachedfile') {
      const { attachFileView } = state;
      if (attachFileView === undefined) return null;
      if (attachFileView.url === undefined) return null;

      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: attachFileView.documentId,
            id: attachFileView.id,
            name: attachFileView.name,
            size: attachFileView.size,
            url: attachFileView.url,
            isFileprotection: attachFileView.isFileprotection,
          }}
        />
      );
    }

    // 공유파일 보기
    if (dialogType === 'sharedfile') {
      const { sharedFileView } = state;
      if (sharedFileView === undefined) return null;
      if (sharedFileView.viewerUrl === undefined) return null;

      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: sharedFileView.documentId,
            id: sharedFileView.id,
            name: sharedFileView.name,
            size: sharedFileView.size,
            url: sharedFileView.viewerUrl,
          }}
        />
      );
    }

    const { dialogs } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={props.search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onAttachedFilePopup={handleAttachedFileDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }

    /** 문서 완전삭제 */
    if (dialogType === 'moveToTrash') {
      if (
        view?.hasReceipt ||
        view?.isReceipt ||
        trashItems.list
          .filter((x) => x.checked)
          .find((a) => a.hasReceipt || a.isReceipt)
      ) {
        return (
          <Dialog size="xs">
            <DialogBody>
              <div className="eui-alert-message">
                <Trans
                  i18nKey="선택한 문서 중 <strong>수발신 문서 혹은 접수문서가</strong>가 포함되어 있습니다."
                  components={{ strong: <strong />, br: <br /> }}
                  ns="approval"
                >
                  선택한 문서 중 <strong>수발신 문서 혹은 접수문서가</strong>가
                  포함되어 있습니다. 수발신 문서와 접수문서의 경우{' '}
                  <strong>수발신 문서 또는 접수문서가 같이 삭제</strong>됩니다.
                  <br />
                  또한 첨부 문서로 등록되어 있을 경우 함께 삭제됩니다. 삭제
                  하시겠습니까?
                </Trans>
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('취소')}
                onClick={handleCloseDialog}
              />
              <Button
                text={getLocalizedText('삭제')}
                variant="contained"
                onClick={handleMoveToTrash}
              />
            </DialogFooter>
          </Dialog>
        );
      }

      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              {getLocalizedText('삭제 하시겠습니까?')}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text={getLocalizedText('취소')}
              onClick={handleCloseDialog}
            />
            <Button
              text={getLocalizedText('삭제')}
              variant="contained"
              onClick={handleMoveToTrash}
            />
          </DialogFooter>
        </Dialog>
      );
    }

    /** 문서 복원 */
    if (dialogType === 'moveToRestore') {
      if (
        view?.hasReceipt ||
        view?.isReceipt ||
        trashItems.list
          .filter((x) => x.checked)
          .find((a) => a.hasReceipt || a.isReceipt)
      ) {
        return (
          <Dialog size="xs">
            <DialogBody>
              <div className="eui-alert-message">
                <Trans
                  i18nKey="선택한 문서 중 <strong>수발신 문서</strong>가 포함되어 있습니다."
                  components={{ strong: <strong /> }}
                  ns="approval"
                >
                  선택한 문서 중 <strong>수발신 문서</strong>가 포함되어
                  있습니다. 수발신 문서의 경우{' '}
                  <strong>수신문서와 발신문서가 같이 복원</strong>됩니다. 복원
                  하시겠습니까?
                </Trans>
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('취소')}
                onClick={handleCloseDialog}
              />
              <Button
                text={getLocalizedText('복원')}
                variant="contained"
                onClick={handleMoveToRestore}
              />
            </DialogFooter>
          </Dialog>
        );
      }

      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              {getLocalizedText('복원 하시겠습니까?')}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text={getLocalizedText('취소')}
              onClick={handleCloseDialog}
            />
            <Button
              text={getLocalizedText('복원')}
              variant="contained"
              onClick={handleMoveToRestore}
            />
          </DialogFooter>
        </Dialog>
      );
    }

    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    // 연동오류 내역 확인
    if (drawerType === 'interworking-log') {
      if (view === undefined) return null;

      return (
        <ApprovalInterworkingLogDrawer
          documentCompanyId={principal.companyId}
          documentId={view.id}
          onClose={handleCloseDrawer}
        />
      );
    }

    return null;
  };

  const handlePrevView = (arg: { id: number }) => {
    dispatch(
      documentActions.trashView({
        id: arg.id,
        route: {
          pathname: `${getPathMap('/*/*/*', props.pathname)}/${b62(arg.id)}`,
          search,
        },
      }),
    );
  };
  // 다음 결재문서
  const handleNextView = (arg: { id: number }) => {
    dispatch(
      documentActions.trashView({
        id: arg.id,
        route: {
          pathname: `${getPathMap('/*/*/*', props.pathname)}/${b62(arg.id)}`,
          search,
        },
      }),
    );
  };

  const handleHeadViewAction = (arg: {
    code: string;
    event: React.MouseEvent;
  }) => {
    const { code } = arg;
    /** 삭제, 복원 */
    if (code === 'moveToTrash' || code === 'moveToRestore') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    // 인쇄 다이얼로그
    else if (code === 'print')
      dispatch(sessionActions.setDialog({ type: code }));
    else if (code === 'interworking-log')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 저장
    else if (code === 'save') {
      if (view)
        documentApi
          .download([{ id: view.id }])
          .then(download)
          .catch((ex) => {
            dispatch(sessionActions.error(appError(ex)));
          });
    }
  };

  // 첨부문서 대화상자 열기
  const handleAttachedDocumentDialog = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument'; // 부모 문서, 첨부 문서
    documentId: number;
    affiliateCompanyId?: number | undefined;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      dialogType,
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'parentdocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
            receiptDocumentId,
            receiptAffiliatedCompanyId,
          },
        ],
      }));
    }
    if (dialogType === 'attacheddocument') {
      // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
      if (state.dialogs.length === 0 && view) {
        setState((prev) => ({
          ...prev,
          dialogs: [
            {
              dialogType: 'attacheddocument',
              documentId: view.id,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [
            ...prev.dialogs,
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      }
    }
  };

  // 품의서 대화상자
  const handleDraftDocumentDialog = (documentId: number) => {
    setState((prev) => ({
      ...prev,
      dialogs: [
        ...prev.dialogs,
        {
          dialogType: 'draftdocument',
          documentId,
          affiliatedCompanyId: 0,
        },
      ],
    }));
  };

  // 첨부파일 대화상자
  const handleAttachedFileDialog = (file: AttachFile) => {
    dispatch(sessionActions.setDialog({ type: 'attachedfile' }));
    setState((prev) => ({
      ...prev,
      attachFileView: file,
    }));
  };

  // 공유파일 대화상자
  const handleSharedFileDialog = (file: SharedFile) => {
    dispatch(sessionActions.setDialog({ type: 'sharedfile' }));
    setState((prev) => ({
      ...prev,
      sharedFileView: file,
    }));
  };

  const renderContent = () => {
    let result: JSX.Element = (
      <>
        <EuiHeader>
          <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
          <EuiHeader.Search>
            <Search
              keyword={queryParams.searchWord || ''}
              options={[
                {
                  value: 'SUBJECT',
                  label: getLocalizedText('제목'),
                },
                {
                  value: 'NUMBER',
                  label: getLocalizedText('문서번호'),
                },
                {
                  value: 'WORK',
                  label: getLocalizedText('업무명'),
                },
                // TODO: 검색 옵션 방식 통일하게 적용하기 위해 주석.
                // { value: 'FORM', label: '양식명' },
                // { value: 'DRAFTER', label: '기안자명' },
              ]}
              filter={queryParams.searchCode || 'SUBJECT'}
              onSearch={handleSearch}
            />
          </EuiHeader.Search>
        </EuiHeader>
        <EuiBody>
          <DataGrid
            scrollbar={scrollbar}
            caption={getLocalizedText('결재문서')}
            columns={columns}
            rows={rows}
            checkedCount={items.filter((i) => i.checked).length}
            pagination={{
              no: queryParams.pageNo || 1,
              total: trashItems.totalCount,
              row: queryParams.rowsPerPage || 15,
              onChangeRow: handleChangeRowLength,
            }}
            toolbarActions={toolbarActions}
            onChecked={handleChangeChecked}
            onCheckedAll={handleChangeCheckedAll}
            onClick={handleAction}
            onChangeColumnVisible={handleChangeColumnVisible}
            loading={loading}
          />
        </EuiBody>
      </>
    );
    if (propsItemId) {
      if (view === undefined) return <Loading />;

      const prev = view.prev?.id;
      const next = view.next?.id;

      const toolbarButtons: ActionEventProps[] = [
        {
          code: 'moveToTrash',
          label: getLocalizedText('삭제'),
          type: 'text',
          icon: 'trash-full',
        },
        {
          code: 'moveToRestore',
          label: getLocalizedText('복원'),
          type: 'text',
          icon: 'redo',
        },
      ];

      if (
        view.linkType !== 'NONE' &&
        view.linkType !== 'ATTENDANCE' &&
        view.linkType !== 'ATTENDANCE_ALWAYS'
      )
        toolbarButtons.unshift({
          code: 'interworking-log',
          label: getLocalizedText('연동 로그'),
          type: 'text',
          icon: 'list',
        });

      result = (
        <>
          <ApprovalContentHeadView
            type="full"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleHeadViewAction}
            onListGo={handleListGo}
            onPrev={prev ? () => handlePrevView({ id: prev }) : undefined}
            onNext={next ? () => handleNextView({ id: next }) : undefined}
          />
          <EuiBody>
            <ApprovalContentBodyViewContainer
              pathname={props.pathname}
              type="full"
              view={view}
              isCommentDisable
              onAttachedDocumentPopup={handleAttachedDocumentDialog}
              onDraftDocumentPopup={handleDraftDocumentDialog}
              onAttachedFilePopup={handleAttachedFileDialog}
              onSharedFilePopup={handleSharedFileDialog}
            />
          </EuiBody>
        </>
      );
    }
    return result;
  };
  return (
    <>
      {renderContent()}
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default ApprovalTrashContainer;
