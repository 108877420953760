import React from 'react';
import Button from '../button/Button';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function AttachDocumentItem(props: {
  no: string;
  subject: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  return (
    <div className="document-item">
      <div className="name">
        <em>[{props.no}]</em>
        <span>{props.subject}</span>
      </div>
      {props.onClick && (
        <Button
          className="action"
          text={getLocalizedText('보기')}
          iconType
          icon="document-search"
          onClick={props.onClick}
          color="secondary"
        />
      )}
    </div>
  );
}

export default AttachDocumentItem;
