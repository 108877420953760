import React from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import { RootState } from '../../../../../groupware-webapp/app/store';
import AttendanceDayOffAccountContainer from './AttendanceDayOffAccountStatusContainer';
import AttendanceDayOffEnterStatusContainer from './AttendanceDayOffEnterStatusContainer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function AttendanceDayOffStatusContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const standard = useSelector(
    (state: RootState) => state.attendance.preferences.standard.data,
  );
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6006)?.name ?? '';

  if (standard.updateAt === '')
    return <EmptyData message={getLocalizedText('생성된 연차가 없습니다.')} />;

  // 입사일자 기준 연차 현황
  if (standard.annualGenerationStandardType === 1)
    return (
      <AttendanceDayOffEnterStatusContainer
        pathname={pathname}
        search={search}
        title={getLocalizedText(`${title}`)}
      />
    );

  // 회계연도 기준 연차 현황
  return (
    <AttendanceDayOffAccountContainer
      pathname={pathname}
      search={search}
      title={getLocalizedText(`${title}`)}
    />
  );
}

export default AttendanceDayOffStatusContainer;
