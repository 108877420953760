import React, { useCallback, useEffect, useRef, useState } from 'react';
import Calendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction, { DateClickArg } from '@fullcalendar/interaction';
import {
  DateSelectArg,
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  b62,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import ResourceLookupDialog from './ResourceLookupDialog';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../../stores/resources';
import EmptyData from '../../../../../components/data/EmptyData';
import FeedBack from '../../../../../components/alert/FeedBack';

function ResourceMonthlyContentContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const queryParams = getQueryParams(search);

  /** 해당 자원 분류의 자원 유형 리스트. */
  let items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  ).filter((a) => a.folderId === b62(p1));
  /** 자원 유형 클릭 시 */
  if (p2) items = items.filter((a) => a.id === b62(p2));

  /** 예약 - 자원유형 클릭 시 */
  const reservationList = useSelector(
    (state: RootState) => state.resource.resources.list,
  );
  const basic = useSelector(
    (state: RootState) => state.resource.userPreferences.preferences,
  );
  const adminBasic = useSelector(
    (state: RootState) => state.resource.preferences.basic,
  );
  const display = useSelector((state: RootState) => state.session.display);

  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const handleResize = () => {
    setWindowSize(window.innerHeight);
  };

  const initialEvents: EventSourceInput = reservationList.map((a, i) => {
    const itemName = items.find((z) => z.id === a.itemId)?.name;
    return {
      ...a,
      id: i.toString(),
      eventId: a.id,
      start: timezoneDate(a.startDateTime),
      end: timezoneDate(a.endDateTime),
      title: itemName ? `[${itemName}] ${a.name}` : a.name,
      isReadOnly: true,
      calendarId: '0',
    };
  });

  const calendarRef = useRef<typeof Calendar>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const getCal = useCallback(() => calendarRef.current?.getApi?.(), []);

  useEffect(() => {
    const calendar = getCal();
    if (!calendar) return;
    const date = timezoneDate(queryParams.startDate);
    calendar.gotoDate(date);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [validation, setValidation] = useState('');

  const updateRenderRangeText = (action: string) => {
    const startDate = timezoneDate(queryParams.startDate);
    if (action === 'prev') startDate.setMonth(startDate.getMonth() - 1);
    else if (action === 'next') startDate.setMonth(startDate.getMonth() + 1);

    if (action === 'today') delete queryParams.startDate;
    else
      queryParams.startDate = dateFormat(initialDate(startDate), 'yyyy-MM-DD');
    go(pathname, queryParams, hash);
  };

  /** 헤드 버튼 액션 (today, prev, next) */
  const handleDateClick = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const button = ev.target as HTMLButtonElement;
    if (button.tagName === 'BUTTON') {
      const actionName = (
        button.getAttribute('data-action') ?? 'month'
      ).replace('move-', '');
      if (actionName === 'prev') getCal().prev();
      else if (actionName === 'next') getCal().next();
      else if (actionName === 'today') getCal().today();

      updateRenderRangeText(actionName);
    }
  };

  /** 예약 등록. - 단일 */
  const handleSelectDateTime = (eventData: DateClickArg) => {
    const today = timezoneDate();
    const start = new Date(eventData.date);
    const end = new Date(eventData.dateStr);
    today.setHours(0, 0, 0, 0);

    if (
      !adminBasic.isAvailablePastReservation &&
      moment(start).isBefore(today)
    ) {
      setValidation(getLocalizedText('지난 날짜는 예약할 수 없습니다.'));
      getCal()?.unselect();
      return;
    }
    start.setHours(timezoneDate().getHours());
    start.setMinutes(timezoneDate().getMinutes() < 30 ? 0 : 30);
    end.setHours(
      timezoneDate().getMinutes() < 30
        ? timezoneDate().getHours()
        : timezoneDate().getHours() + 1,
    );
    end.setMinutes(timezoneDate().getMinutes() < 30 ? 30 : 0);
    const query: {
      itemId: number;
      itemName: string;
      start: string;
      end: string;
    } = {
      itemId: items[0].id,
      itemName: items[0].name,
      start: dateFormat(start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(end, 'yyyy-MM-DD HH:mm'),
    };
    queryParams.dialogMode = 'create';
    queryParams.queryWord = JSON.stringify(query);
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  /** 예약 등록. - 기간 */
  const handleSelectRangeDate = (eventData: DateSelectArg) => {
    const { start, end } = eventData;
    const today = timezoneDate();
    today.setHours(0, 0, 0, 0);

    if (
      !adminBasic.isAvailablePastReservation &&
      moment(start).isBefore(today)
    ) {
      setValidation(getLocalizedText('지난 날짜는 예약할 수 없습니다.'));
      getCal()?.unselect();
      return;
    }
    start.setHours(timezoneDate().getHours());
    start.setMinutes(timezoneDate().getMinutes() < 30 ? 0 : 30);
    end.setDate(end.getDate() - 1);
    end.setHours(
      timezoneDate().getMinutes() < 30
        ? timezoneDate().getHours()
        : timezoneDate().getHours() + 1,
    );
    end.setMinutes(timezoneDate().getMinutes() < 30 ? 30 : 0);
    const query: {
      itemId: number;
      itemName: string;
      start: string;
      end: string;
    } = {
      itemId: items[0].id,
      itemName: items[0].name,
      start: dateFormat(start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(end, 'yyyy-MM-DD HH:mm'),
    };
    queryParams.dialogMode = 'create';
    queryParams.queryWord = JSON.stringify(query);
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  /** 예약 보기. */
  const handleClick = (res: EventClickArg) => {
    const contents = document.getElementsByClassName('fc-more-popover');
    if (contents.length > 0) {
      // 더보기 창 있을 경우 더보기창 삭제.
      const content = contents[0];
      const style = content.getAttribute('style');
      content.setAttribute('style', `${style} display: none;`);
    }

    const { _def: def } = res.event;
    const { eventId } = def.extendedProps as { eventId: number };

    const clickDate = new Date(def.extendedProps.startDateTime);
    const itemName = def.title.substring(
      def.title.indexOf('[') + 1,
      def.title.indexOf(']'),
    );
    const itemId = items.find(({ name }) => name === itemName)?.id ?? 0;
    const data = reservationList
      .filter((a) => a.id === eventId)
      .find((a) => moment(clickDate).isSame(new Date(a.startDateTime)));
    if (!data) return;
    const query: {
      itemId: number;
      itemName: string;
      start: string;
      end: string;
    } = {
      itemId,
      itemName,
      start: dateTimeFormat(data.startDateTime, 'yyyy-MM-DD HH:mm'),
      end: dateTimeFormat(data.endDateTime, 'yyyy-MM-DD HH:mm'),
    };
    queryParams.dialogMode = 'read';
    queryParams.queryWord = JSON.stringify(query);
    dispatch(
      resourcesActions.reservationView({
        id: eventId,
        route: {
          pathname,
          search: getQueryParams(queryParams),
          hash,
        },
      }),
    );
  };

  /** 예약 저장 */
  const handleSave = (arg: {
    itemId: number;
    employeeId: number;
    name: string;
    remark: string;
    startDateTime: string;
    endDateTime: string;
  }) => {
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(
      resourcesActions.saveReservation({
        data: { ...arg, sharers: [], alarms: [] },
        location,
      }),
    );
  };

  /** 예약 수정 */
  const handleUpdate = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      itemId: number;
      employeeId: number;
      name: string;
      remark?: string;
      startDateTime?: string;
      endDateTime?: string;
      lookupStartDateTime?: string;
      lookupUpdateAt?: string;
      updateAt?: string;
    };
  }) => {
    const { isRepeat, repeatType } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: props.pathname,
        search: getQueryParams(queryParams),
        hash: props.hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    // 반복 예약 수정
    if (isRepeat) {
      dispatch(
        resourcesActions.updateRepeatReservation({
          type: repeatType,
          data: arg.data,
          location,
        }),
      );
    }
    // 일반 예약 수정
    else {
      const data = {
        ...arg.data,
        updateAt: arg.data.updateAt ?? '',
      };
      dispatch(resourcesActions.updateReservation({ data, location }));
    }
  };

  /** 예약 삭제. */
  const handleDelete = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      startDateTime?: string;
      updateAt: string;
    };
  }) => {
    const { isRepeat, repeatType, data } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    if (isRepeat) {
      dispatch(
        resourcesActions.deleteRepeatReservation({
          data: {
            type: repeatType,
            ...data,
            startDateTime: data.startDateTime ?? '',
          },
          location,
        }),
      );
    } else {
      dispatch(
        resourcesActions.deleteReservation({
          id: data.id,
          updateAt: data.updateAt,
          location,
        }),
      );
    }
  };

  const renderDialog = () => {
    if (queryParams.dialogMode === 'read') {
      return (
        <ResourceLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      );
    }
    if (queryParams.dialogMode === 'create')
      return (
        <ResourceLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onSave={handleSave}
        />
      );
    return null;
  };

  let height = windowSize - 184;
  if (height <= 450) {
    height = 450;
  }

  if (items.length === 0) return <EmptyData />;
  const startDate = timezoneDate(queryParams.startDate);
  return (
    <EuiBody>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '24px',
        }}
      >
        <Button
          className="btn btn-default btn-sm move-day"
          dataAction="move-prev"
          text="<"
          onClick={handleDateClick}
        />
        <div
          className="eui-form-field"
          style={{
            margin: '0 5px',
            background: 'none',
            width: 'auto',
          }}
        >
          <h3 className="control">{dateFormat(startDate, 'MM월 yyyy')}</h3>
        </div>
        <Button
          className="btn btn-default btn-sm move-day"
          dataAction="move-next"
          text=">"
          onClick={handleDateClick}
        />

        <div style={{ marginLeft: '10px' }}>
          <Button
            className="btn btn-default btn-sm move-today"
            dataAction="move-today"
            text={getLocalizedText('오늘')}
            variant="outlined"
            onClick={handleDateClick}
          />
        </div>
      </div>
      <Calendar
        plugins={[dayGridPlugin, interaction]}
        initialView="dayGridMonth"
        events={initialEvents}
        height={`${height}px`}
        firstDay={basic?.firstDayOfWeek === 0 ? 0 : 1}
        headerToolbar={false}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={calendarRef}
        now={timezoneDate()} // 시간대 설정.
        eventClick={handleClick}
        eventTextColor="var(--primary-text-color)"
        eventColor="var(--primary-color)"
        eventBorderColor={undefined}
        editable={false} // editable = true일 경우 이벤트 드래그 이동 가능.
        selectable
        dayMaxEvents
        dateClick={display === 'pc' ? undefined : handleSelectDateTime}
        dayHeaderContent={(arg) => {
          return dateFormat(arg.date, 'dd');
        }}
        select={handleSelectRangeDate}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
      />
      {renderDialog()}
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </EuiBody>
  );
}

export default ResourceMonthlyContentContainer;
