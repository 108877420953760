/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg, NoLoading } from '../../groupware-common/types';
import { ReadingPaneMode } from '../../groupware-webapp/stores/types';
import { appError } from '../../groupware-webapp/stores/common/utils';
import boardsApi, {
  LikesReturnType,
  StarReturnType,
} from '../apis/board/v1/boards';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../groupware-common/utils';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import { RootState } from '../../groupware-webapp/app/store';
import { sessionActions } from '../../groupware-webapp/stores/session';
import folderBoxApi from '../apis/board/v1/folder';
import { IconType } from '../../groupware-common/types/icon';

const name = 'boards/board';

interface CategoryItem {
  type: 'status' | 'organization' | 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}
export interface AttachFile {
  fileId: number;
  name: string;
}

// 목록 설정 객체
export interface FolderSetting {
  version: '1.0';
  density: 'wide' | 'narrow' | 'normal'; // 화면 밀도.
  readingPaneMode: ReadingPaneMode; // 보기 모드.
  columns: string[]; // 노출하는 목록 컬럼 이름.
  rowsPerPage: number; // 목록 개수.
}

export interface CommentItem {
  id: number; // 아이디
  postId: number; // 게시글 아이디.
  parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
  employeeId: number; // 직원 아이디 - employee.id
  comment: string; // 댓글 내용
  isAuthorized: boolean; // 수정 권한 여부. (작성자 or 해당 게시함 관리자 or 게시 모듈 관리자.)
  isDeleted: boolean; // 삭제 여부.
  updateAt: string; // 수정 날짜
}

/** 게시판 첨부파일 객체 */
export interface BoardAttachedFile {
  companyId: number;
  id: number;
  fileId: number;
  seq: number;
  name: string;
  size: number;
  updatAt: string;
}

export interface BoardListItem {
  folderId: number; // 폴더 아이디.
  isNotice: boolean; // 번호.
  id: number; // 아이디.
  listId: string; // 게시글 아이디.
  checked: boolean; // 체크 여부.
  employeeId: number; // 작성자 아이디.
  titleClassification: string; // 말머리.
  subject: string; // 제목.
  views: number; // 조회 수.
  likes: number; // 좋아요 수.
  comments: number; // 덧글 수.
  attachedFiles?: {
    fileId: number;
    name: string;
  }[]; // 첨부파일 요약 객체 리스트.
  isSecure: boolean; // 보안게시 여부.
  isStarred: boolean; // 중요 표시 여부.
  isViewed: boolean; // 조회 표시 여부. (true: 읽음, false: 안읽음)
  isThumbnail: boolean; // 썸네일 유무.
  thumbnailPath: string | null; // 썸네일 파일 경로.
  deleterId?: number; // 삭제자 아이디.
  createAt: string; // 작성일.
  updateAt: string; // 수정일.
}

export interface BoardViewItem {
  id: number; // 아이디.
  folderId: number; // 게시함 아이디.
  employeeId: number; // 작성자 아이디.
  isStarred: boolean; // 조회자 중요표시 여부.
  isLiked: boolean; // 조회자 좋아요 표시 여부.
  titleClassification: string; // 말머리.
  formId: number; // 양식 아이디.
  subject: string; // 제목.
  contents: string; // 본문.
  isAuthorized: boolean; // 수정 권한 여부(작성자 or 해당 게시함 관리자)
  isSecure: boolean; // 보안게시 여부.
  isAlarmSend: boolean; // 게시알림 여부.
  isNotified: boolean; // 공지(상단 노출) 여부.
  notifyStartDate: string; // 공지 시작일.
  notifyEndDate: string; // 공지 종료일.
  retentionPeriod: string; // 보존기간.
  views: number; // 조회 수.
  likes: number; // 좋아요 수.
  attachedFiles?: BoardAttachedFile[]; // 첨부파일 객체.
  comments: number; // 댓글 수.
  commentData?: CommentItem[]; // 댓글 객체.
  prev?: {
    companyId: number;
    id: number;
  }; // 이전 아이디.
  next?: {
    companyId: number;
    id: number;
  }; // 다음 아이디.
  deleterId: number; // 삭제자 아이디.
  createAt: string; // 작성일.
  updateAt: string;
}

export interface BoardReadOnlyItem {
  id: number; // 아이디.
  folderId: number; // 게시함 아이디.
  employeeId: number; // 작성자 아이디.
  titleClassification: string; // 말머리.
  formId: number; // 양식 아이디.
  subject: string; // 제목.
  contents: string; // 본문.
  isAlarmSend: boolean; // 게시알림 여부.
  isNotified: boolean; // 공지(상단 노출) 여부.
  notifyStartDate: string; // 공지 시작일.
  notifyEndDate: string; // 공지 종료일.
  retentionPeriod: string; // 보존기간.
  views: number; // 조회 수.
  likes: number; // 좋아요 수.
  attachedFiles?: BoardAttachedFile[]; // 첨부파일 객체.
  comments: number; // 댓글 수.
  commentData?: CommentItem[]; // 댓글 객체.
  prev?: {
    companyId: number;
    id: number;
  }; // 이전 아이디.
  next?: {
    companyId: number;
    id: number;
  }; // 다음 아이디.
  createAt: string; // 작성일.
  updateAt: string;
}

/** 기본 폴더 */
const categories: CategoryItem[] = [
  {
    type: 'status',
    id: 'all',
    name: '모든게시함',
    icon: 'archive',
  },
  {
    type: 'status',
    id: 'importance',
    name: '중요게시함',
    icon: 'star',
  },
  {
    type: 'status',
    id: 'temp',
    name: '임시보관함',
    icon: 'document',
  },
  // 관리자 - 메뉴.
  {
    type: 'setting',
    id: 6001,
    name: '기본설정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6002,
    name: '게시함 관리',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6003,
    name: '일괄권한 설정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6004,
    name: '삭제함 관리',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6005,
    name: '양식관리',
    icon: 'folder',
  },
];

interface State {
  displayDensity: 'wide' | 'normal' | 'narrow';
  readingPaneMode: ReadingPaneMode;
  folderSetting: {
    setting?: FolderSetting;
    updateAt: string;
  };

  category: CategoryItem[];
  list: BoardListItem[];
  totalCount: number;
  view: {
    isSecure: boolean;
    data: BoardViewItem | undefined;
  };
  printView: BoardReadOnlyItem | undefined;
}

const initialState: State = {
  displayDensity: 'normal',
  readingPaneMode: 'list',
  folderSetting: {
    updateAt: '1000-01-01T00:00:00',
  },

  category: categories,
  list: [],
  totalCount: 0,
  view: {
    isSecure: false,
    data: undefined,
  },
  printView: undefined,
};

function formatDefaultLayout(value?: number): ReadingPaneMode {
  switch (value) {
    case 1:
      return 'list';
    case 2:
      return 'vertical';
    case 3:
      return 'horizontal';
    case 4:
      return 'gallery';
    default:
      return 'list';
  }
}

/** 게시글 목록 조회. */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      folderId?: string;
      search: string;
      isStarred: boolean;
      isTemporary: boolean;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const queryParams = getQueryParams(arg.search);
      const { employeeId } = (getState() as RootState).session.principal;
      let folderid: number | undefined;
      let noticeFolderid: number | undefined;
      let folderSetting: {
        setting?: FolderSetting;
        updateAt: string;
      } = {
        updateAt: '1000-01-01T00:00:00',
      };
      // 모든 게시함.
      if (!arg.folderId || arg.folderId === 'all') {
        const allSetting = await boardsApi.allFolderSetting(employeeId);
        folderSetting =
          allSetting.configs !== ''
            ? {
                setting: JSON.parse(allSetting.configs),
                updateAt: allSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: allSetting.updateAt,
              };
        folderid = undefined;
        noticeFolderid = 0;
      }
      // 임시 보관함.
      else if (arg.folderId === 'temp') {
        folderSetting = {
          updateAt: '1000-01-01T00:00:00',
        };
        folderid = 0;
      }
      // 중요 게시함.
      else if (arg.folderId === 'importance') {
        const importSetting = await boardsApi.starredSetting(employeeId);
        folderSetting =
          importSetting.configs !== ''
            ? {
                setting: JSON.parse(importSetting.configs),
                updateAt: importSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: importSetting.updateAt,
              };
        folderid = 0;
      }
      // 게시함.
      else {
        const defaultSetting = await boardsApi.folderSetting({
          folderid: b62(arg.folderId),
          employeeid: employeeId,
        });
        folderSetting =
          defaultSetting.configs !== ''
            ? {
                setting: JSON.parse(defaultSetting.configs),
                updateAt: defaultSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: defaultSetting.updateAt,
              };
        folderid = b62(arg.folderId);
        noticeFolderid = b62(arg.folderId);
      }

      const { setting } = folderSetting;
      const list = await boardsApi.findList({
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        folderid,
        titleclassificationid: queryParams.status
          ? Number(queryParams.status)
          : undefined,
        isStarred: arg.isStarred,
        isTemporary: arg.isTemporary,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? setting?.rowsPerPage ?? 15,
      });
      const totalCount = await boardsApi.findTotalCount({
        folderid,
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        titleclassificationid: queryParams.status
          ? Number(queryParams.status)
          : undefined,
        isStarred: arg.isStarred,
        isTemporary: arg.isTemporary,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      });
      const noticeList =
        noticeFolderid !== undefined
          ? await boardsApi.findNoticeList(noticeFolderid)
          : undefined;

      let readingPaneMode: ReadingPaneMode | undefined;
      let displayDensity: 'wide' | 'narrow' | 'normal' | undefined;
      if (arg.route && !queryParams.pageNo) {
        const folders = await folderBoxApi.list({});
        const defaultLayout =
          folderid && folderid !== 0
            ? folders.find((a) => a.id === folderid)?.defaultLayout
            : undefined;
        readingPaneMode = formatDefaultLayout(defaultLayout);
        displayDensity = 'normal';
        if (setting) {
          readingPaneMode = setting.readingPaneMode;
          displayDensity = setting.density;
        }
      }

      const useNoticeList =
        !queryParams.pageNo ||
        (queryParams.pageNo === 1 &&
          !queryParams.searchCode &&
          !queryParams.searchWord &&
          !queryParams.startDate &&
          !queryParams.endDate &&
          !queryParams.status);
      return {
        list:
          noticeList && useNoticeList
            ? [
                ...noticeList.map((a) => ({
                  checked: false,
                  isNotice: true,
                  ...a,
                  listId: `${a.id}_notice`,
                  deleterId: a.deleterId === null ? undefined : a.deleterId,
                })),
                ...list.map((a) => ({
                  checked: false,
                  isNotice: false,
                  ...a,
                  listId: `${a.id}`,
                  deleterId: a.deleterId === null ? undefined : a.deleterId,
                })),
              ]
            : list.map((a) => ({
                checked: false,
                isNotice: false,
                ...a,
                listId: `${a.id}`,
                deleterId: a.deleterId === null ? undefined : a.deleterId,
              })),
        totalCount,
        readingPaneMode,
        displayDensity,
        folderSetting,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 단일 조회. */
const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { id: number; updateAt?: string } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const pathname =
        arg.route?.pathname ?? (getState() as RootState).session.route.pathname;
      const search =
        arg.route?.search ?? (getState() as RootState).session.route.search;
      const { folderId } = getPathParams<{ folderId?: string }>(
        '/*/:folderId',
        pathname,
      );
      const queryParams = getQueryParams(search);

      let commentData: CommentItem[] | undefined;
      let prev: { companyId: number; id: number } | undefined;
      let next: { companyId: number; id: number } | undefined;
      let response: BoardViewItem | undefined;
      if (folderId === 'temp') {
        const view = await boardsApi.findReadOnlyView({
          id: arg.id,
          updateAt: arg.updateAt,
        });
        response = {
          ...view,
          isStarred: false,
          isLiked: false,
          isAuthorized: false,
          isSecure: false,
          deleterId: 0,
        };
      } else {
        response = await boardsApi.findView({
          id: arg.id,
          updateAt: arg.updateAt,
        });
      }

      // 공지글이 아닌 경우, 임시보관함 글 조회가 아닌 경우 이전, 다음아이디 조회.
      if (!queryParams.type && folderId !== 'temp') {
        prev = await boardsApi.prevId({
          folderId,
          id: arg.id,
          startdate: queryParams.startDate,
          enddate: queryParams.endDate,
          searchcode: queryParams.searchCode,
          searchword:
            queryParams.directoryFilter === 'true'
              ? queryParams.directoryKeyword
              : queryParams.searchWord,
          isdirectoryselected: queryParams.directoryFilter === 'true',
          titleclassificationid: queryParams.status
            ? Number(queryParams.status)
            : undefined,
        });
        next = await boardsApi.nextId({
          folderId,
          id: arg.id,
          startdate: queryParams.startDate,
          enddate: queryParams.endDate,
          searchcode: queryParams.searchCode,
          searchword:
            queryParams.directoryFilter === 'true'
              ? queryParams.directoryKeyword
              : queryParams.searchWord,
          isdirectoryselected: queryParams.directoryFilter === 'true',
          titleclassificationid: queryParams.status
            ? Number(queryParams.status)
            : undefined,
        });
      }
      if (response.comments > 0)
        commentData = await boardsApi.findComments({ postid: response.id });

      const attachedFiles: BoardAttachedFile[] = [];
      if (response.attachedFiles) {
        response.attachedFiles.forEach((a) => attachedFiles.push(a));
        attachedFiles.sort(
          (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
        );
      }

      return {
        ...response,
        attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
        commentData,
        prev,
        next,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 단일 조회 시 보안게시 여부. */
const isSecretView = createAsyncThunk(
  `${name}/isSecretView`,
  async (
    arg: { id: number } & LocateArg,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { data: view } = (getState() as RootState).boards.board.view;
      const { isSecure } = await boardsApi.isSecretView(arg.id);
      // 보안 게시글 최초 클릭 시.
      if (isSecure && (!view || (view && view.id !== arg.id)))
        return { isSecure, data: undefined };
      await dispatch(findView({ id: arg.id }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 폴더 이동. */
const moveFolder = createAsyncThunk(
  `${name}/moveFolder`,
  async (
    arg: {
      data:
        | {
            folderId: number;
            id: number;
            updateAt: string;
          }
        | {
            folderId: number;
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await boardsApi.moveFolder(arg.data);
      if (arg.route) {
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          arg.route.pathname,
        );
        dispatch(
          findList({
            folderId,
            search: arg.route.search ?? '',
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
          }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 댓글 작성. */
const createComment = createAsyncThunk(
  `${name}/createComment`,
  async (
    arg: {
      postId: number;
      parentId: number;
      employeeId: number;
      comment: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const { IPv4 } = await ipData.json();
      await boardsApi.createComments({
        postId: arg.postId,
        parentId: arg.parentId,
        employeeId: arg.employeeId,
        comment: arg.comment,
        ip: IPv4,
      });
      const commentData = await boardsApi.findComments({ postid: arg.postId });
      return commentData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 댓글 수정. */
const updateComment = createAsyncThunk(
  `${name}/updateComment`,
  async (
    arg: {
      id: number;
      postId: number;
      comment: string;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const { IPv4 } = await ipData.json();
      await boardsApi.updateComments({
        id: arg.id,
        postId: arg.postId,
        comment: arg.comment,
        ip: IPv4,
        updateAt: arg.updateAt,
      });
      const commentData = await boardsApi.findComments({ postid: arg.postId });
      return commentData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 댓글 삭제. */
const deleteComment = createAsyncThunk(
  `${name}/deleteComment`,
  async (
    arg: {
      id: number;
      postId: number;
      parentId: number;
      employeeId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      await boardsApi.deleteComments({
        id: arg.id,
        postId: arg.postId,
        parentId: arg.parentId,
        employeeId: arg.employeeId,
        updateAt: arg.updateAt,
      });

      const commentData = await boardsApi.findComments({ postid: arg.postId });
      return commentData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 인쇄 게시글 조회. */
const findPrintView = createAsyncThunk(
  `${name}/findPrintView`,
  async (
    arg: { id: number; updateAt?: string } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let commentData: CommentItem[] | undefined;
      const response = await boardsApi.findReadOnlyView({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      if (response.comments > 0) {
        commentData = await boardsApi.findComments({ postid: response.id });
      }
      const attachedFiles: BoardAttachedFile[] = [];
      if (response.attachedFiles) {
        response.attachedFiles.forEach((a) => attachedFiles.push(a));
        attachedFiles.sort(
          (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
        );
      }
      return {
        ...response,
        commentData,
        attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 중요표시 */
const saveUnSaveStar = createAsyncThunk(
  `${name}/saveUnSaveStar`,
  async (
    arg: {
      star: boolean;
      id: number;
      employeeId: number;
    } & LocateArg &
      NoLoading,
    { rejectWithValue },
  ) => {
    try {
      const { star, id, employeeId } = arg;
      const response = await boardsApi.saveUnSaveStar({ star, id, employeeId });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 좋아요표시 */
const saveUnSaveLike = createAsyncThunk(
  `${name}/saveUnSaveLike`,
  async (
    arg: {
      like: boolean;
      id: number;
      employeeId: number;
    } & LocateArg &
      NoLoading,
    { rejectWithValue },
  ) => {
    try {
      const { like, id, employeeId } = arg;
      const response = await boardsApi.saveUnSaveLike({ like, id, employeeId });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 게시함 목록 설정 기준 저장. */
const saveFolderSetting = createAsyncThunk(
  `${name}/saveFolderSetting`,
  async (
    arg: {
      folderId?: string;
      setting: FolderSetting;
      updateAt: string;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      const { folderId, updateAt } = arg;
      const configs = JSON.stringify(arg.setting);
      if (!folderId || folderId === 'all') {
        const saveData = { employeeId, configs, updateAt };
        const result = boardsApi.saveAllFolderSetting(saveData);
        return result;
      }
      if (folderId === 'importance') {
        const saveData = { employeeId, configs, updateAt };
        const result = boardsApi.saveStarredSetting(saveData);
        return result;
      }
      if (folderId === 'temp') {
        return {
          configs: '',
          updateAt: '1000-01-01T00:00:00',
        };
      }
      const saveData = {
        folderId: b62(folderId),
        employeeId,
        configs,
        updateAt,
      };
      const result = boardsApi.saveFolderSetting(saveData);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 작성. */
const create = createAsyncThunk(
  `${name}/create`,
  async (
    arg: {
      data: {
        folderId: number;
        titleClassificationId: number;
        formId: number;
        subject: string;
        contents: string;
        isSecure: boolean;
        securalPassword?: string;
        isAlarmSend: boolean;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          path: string;
          fileId: number; // 파일 자체 내부코드.
          seq: number;
          name: string;
          size: number;
          copy?: boolean;
          delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
        }[];
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await boardsApi.create(arg.data);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 수정. */
const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      data: {
        id: number;
        folderId: number;
        titleClassificationId: number;
        formId: number;
        subject: string;
        contents: string;
        isSecure: boolean;
        securalPassword?: string;
        isAlarmSend: boolean;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          path: string;
          fileId: number; // 파일 자체 내부코드.
          seq: number;
          name: string;
          size: number;
          copy?: boolean;
          delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
        }[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { readingPaneMode } = (getState() as RootState).boards.board;
      const result = await boardsApi.update(arg.data);
      const isSplitView =
        readingPaneMode === 'vertical' || readingPaneMode === 'horizontal';
      if (isSplitView && arg.location) {
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          arg.location.pathname,
        );
        dispatch(
          findList({
            folderId,
            search: arg.location.search ?? '',
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
          }),
        );
      }
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 임시저장. */
const temporary = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      data: {
        id?: number;
        folderId: number;
        titleClassificationId: number;
        formId: number;
        subject: string;
        contents: string;
        isAlarmSend: boolean;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          path: string;
          fileId: number; // 파일 자체 내부코드.
          seq: number;
          name: string;
          size: number;
          copy?: boolean;
          delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
        }[];
        updateAt?: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const { id, updateAt } = data;
      if (id && updateAt) {
        const result = await boardsApi.reTemporary({
          ...data,
          id,
          isSecure: false,
          updateAt,
        });
        return result;
      }
      const result = await boardsApi.temporary({
        ...data,
        isSecure: false,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 임시저장 게시글을 활성화. */
const tempToActive = createAsyncThunk(
  `${name}/tempToActive`,
  async (
    arg: {
      data: {
        id: number;
        folderId: number;
        titleClassificationId: number;
        formId: number;
        subject: string;
        contents: string;
        isSecure: boolean;
        securalPassword?: string;
        isAlarmSend: boolean;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          path: string;
          fileId: number; // 파일 자체 내부코드.
          seq: number;
          name: string;
          size: number;
          copy?: boolean;
          delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
        }[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await boardsApi.tempToActive(arg.data);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 삭제. */
const deleteBoard = createAsyncThunk(
  `${name}/deleteBoard`,
  async (
    arg: {
      data:
        | {
            id: number;
            updateAt: string;
          }
        | {
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    const { data, route } = arg;
    try {
      const sessionRoute = (getState() as RootState).session.route;
      const { folderId } = getPathParams<{ folderId?: string }>(
        '/*/:folderId',
        route ? route.pathname : sessionRoute.pathname,
      );
      if (Array.isArray(data)) {
        const result =
          folderId === 'temp'
            ? await boardsApi.forceDeleteBoard(data)
            : await boardsApi.deleteBoard(data);
        if (Array.isArray(result) && result.length !== data.length) {
          await dispatch(sessionActions.setDialog());
          await dispatch(
            findList({
              folderId,
              search: sessionRoute.search ?? '',
              isStarred: folderId === 'importance',
              isTemporary: folderId === 'temp',
            }),
          );
          return rejectWithValue(
            appError({
              error: getLocalizedText(
                '게시글 삭제 중 오류가 발생하여 일부 게시글은 삭제 실패하였습니다.',
              ),
            }),
          );
        }
      } else if (folderId === 'temp') await boardsApi.forceDeleteBoard(data);
      else await boardsApi.deleteBoard(data);
      if (route)
        dispatch(
          findList({
            folderId,
            search: route.search ?? '',
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
          }),
        );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const boardSlice = createSlice({
  name,
  initialState,
  reducers: {
    setListItemChecked(
      state,
      action: PayloadAction<{ itemId: string | 'all'; checked: boolean }>,
    ) {
      if (action.payload.itemId === 'all') {
        state.list = state.list.map((x) => {
          if (x.isNotice) return { ...x, checked: false };
          if (x.checked === action.payload.checked) return x;
          return { ...x, checked: action.payload.checked };
        });
      } else {
        const index = state.list.findIndex(
          (x) => x.listId === action.payload.itemId,
        );
        if (index > -1) {
          state.list[index] = {
            ...state.list[index],
            checked: action.payload.checked,
          };
        }
      }
    },
    setReadingPaneMode(state, action: PayloadAction<ReadingPaneMode>) {
      if (state.readingPaneMode !== action.payload) {
        state.readingPaneMode = action.payload;

        if (state.readingPaneMode === 'list') {
          state.view.data = undefined;
        }
      }
    },
    displayDensity(state, action: PayloadAction<'wide' | 'normal' | 'narrow'>) {
      if (state.displayDensity !== action.payload)
        state.displayDensity = action.payload;
    },
    viewClear(state) {
      state.view.data = undefined;
    },
    printViewClear(state) {
      state.printView = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = {
            isSecure: false,
            data: undefined,
          };
          state.list = payload.list;
          state.totalCount = payload.totalCount;
          state.folderSetting = payload.folderSetting;
          if (payload.readingPaneMode)
            state.readingPaneMode = payload.readingPaneMode;
          if (payload.displayDensity)
            state.displayDensity = payload.displayDensity;
        }
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = {
            isSecure: false,
            data: payload,
          };
          state.list = state.list.map((a) => {
            if (a.id === payload.id)
              return {
                ...a,
                views: a.isViewed ? a.views : a.views + 1,
                isViewed: true,
              };
            return a;
          });
        }
      })
      .addCase(isSecretView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(createComment.fulfilled, (state, { payload }) => {
        const { data } = state.view;
        if (payload !== undefined && state.view.data && data) {
          const comments = payload.filter((a) => !a.isDeleted).length;
          state.view.data = {
            ...state.view.data,
            comments,
            commentData: payload,
          };
          state.list = state.list.map((a) => {
            if (a.id === data.id)
              return {
                ...a,
                comments: a.comments + 1,
              };
            return a;
          });
        }
      })
      .addCase(updateComment.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.view.data)
          state.view.data.commentData = payload;
      })
      .addCase(deleteComment.fulfilled, (state, { payload }) => {
        const { data } = state.view;
        if (payload !== undefined && state.view.data && data) {
          const comments = payload.filter((a) => !a.isDeleted).length;
          state.view.data = {
            ...state.view.data,
            comments,
            commentData: payload,
          };
          state.list = state.list.map((a) => {
            if (a.id === data.id)
              return {
                ...a,
                comments: a.comments - 1,
              };
            return a;
          });
        }
      })
      .addCase(findPrintView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.printView = payload;
      })
      .addCase(saveUnSaveStar.fulfilled, (state, { payload }) => {
        const { data } = state.view;
        if (payload !== undefined) {
          const result = payload as StarReturnType;
          if (data && data.id === result.id)
            state.view.data = {
              ...data,
              isStarred: result.isStarred,
            };
          state.list = state.list.map((a) => {
            if (a.id === result.id)
              return {
                ...a,
                isStarred: result.isStarred,
              };
            return a;
          });
        }
      })
      .addCase(saveUnSaveLike.fulfilled, (state, { payload }) => {
        const { data } = state.view;
        if (payload !== undefined) {
          const result = payload as LikesReturnType;
          if (data && data.id === result.id)
            state.view.data = {
              ...data,
              isLiked: result.isLiked,
              likes: result.likes,
            };
          state.list = state.list.map((a) => {
            if (a.id === result.id)
              return {
                ...a,
                likes: result.likes,
              };
            return a;
          });
        }
      })
      .addCase(saveFolderSetting.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const { configs, updateAt } = payload;
          state.folderSetting = {
            setting: configs !== '' ? JSON.parse(configs) : undefined,
            updateAt,
          };
        }
      });
  },
});

export default boardSlice.reducer;

export const boardActions = {
  setListItemChecked: boardSlice.actions.setListItemChecked,
  setReadingPaneMode: boardSlice.actions.setReadingPaneMode,
  displayDensity: boardSlice.actions.displayDensity,
  viewClear: boardSlice.actions.viewClear,
  printViewClear: boardSlice.actions.printViewClear,

  findList,
  findView,
  printView: findPrintView,
  isSecretView,

  moveFolder,

  createComment,
  updateComment,
  deleteComment,

  saveUnSaveStar,
  saveUnSaveLike,
  create,
  update,
  temporary,
  tempToActive,

  deleteBoard,

  saveFolderSetting,
};
