import moment from 'moment';
import { scheduleDate } from '../../../groupware-calendar/stores/calendar/schedules';
import { TemplateKey } from '../../constants/templates';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

/** URL에서 상태 아이디 추출. */
export const extractOptionsFromUrl = (
  url: string,
  option: string,
): string | undefined => {
  const regex = new RegExp(`/${option}/(\\d+)`);
  const match = url.match(regex);
  return match ? match[1] : undefined;
};

/** 동적 URL 템플릿 생성. */
export const generateUrlWithParams = (
  url: string,
  options: Record<string, string>,
): string => {
  let newUrl = url;
  Object.keys(options).forEach((key) => {
    newUrl = newUrl.replace(`{${key}}`, options[key]);
  });
  return newUrl;
};

/** params Object를 URL paramser으로 변경 */
export const objectArrayToUrlParams = (
  data: {
    [key: string]: string;
  },
  // key=value 순서를 value=key 로 변경
  reverse?: boolean,
): string => {
  return !reverse
    ? Object.entries(data)
        .map(([key, value]) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&')
    : Object.entries(data)
        .map(([key, value]) => {
          return `${encodeURIComponent(value)}=${encodeURIComponent(key)}`;
        })
        .join('&');
};

/** search를 value로 */
export function urlParamsToValue(search: string, key: string): string {
  let value = '';
  if (search && search !== '') {
    new URLSearchParams(search).forEach((v, k) => {
      if (k === key && v && v !== '') value = v;
    });
  }
  return value;
}

export function urlSearchToObject(
  search: string,
  templateKey: TemplateKey,
): { [key: string]: string };
export function urlSearchToObject(
  search: string,
  templateKey: 'CALENDAR-EVENTS',
): {
  selectedDate: string;
  from: string;
  to: string;
};
export function urlSearchToObject(
  search: string,
  templateKey: TemplateKey,
): { [key: string]: string } {
  let value: { [key: string]: string } = {};
  if (templateKey !== undefined) {
    switch (templateKey) {
      case 'CALENDAR-EVENTS': {
        const paramObj = new URLSearchParams(search);
        let selectedDate = paramObj.get('selectedDate');
        let from = paramObj.get('from');
        let to = paramObj.get('to');

        if (selectedDate === null || selectedDate === '{selectedDate}')
          selectedDate = moment().format('YYYY-MM-DD');
        if (from === null || from === '{from}')
          from = scheduleDate({
            hash: '#monthly',
            date: moment().format('YYYY-MM-DD'),
            type: 'start',
          });
        if (to === null || to === '{to}')
          to = scheduleDate({
            hash: '#monthly',
            date: scheduleDate({
              hash: '#monthly',
              date: moment().format('YYYY-MM-DD'),
              type: 'start',
            }),
            type: 'end',
          });
        value = { selectedDate, from, to };
        break;
      }
      case 'COMMON-NEW_EMPLOYEES': {
        value = {
          from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        };
        break;
      }
      case 'COMMON-BIRTHDAY': {
        value = {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().endOf('month').format('YYYY-MM-DD'),
        };
        break;
      }
      case 'COMMON-PROFILE': {
        const profileParams: { [key: string]: string } = {};
        new URLSearchParams(search).forEach((v, k) => {
          if (k === 'module') {
            profileParams[v] = k;
          }
        });
        value = profileParams;
        break;
      }
      default: {
        if (search === 'rowsperpage={rowsperpage}')
          value = { rowsperpage: '5' };
        break;
      }
    }
  }
  return value;
}

/** url validation */
export function validateUrl(url: string, templateKey: TemplateKey): string {
  if (url.includes('{folderId}'))
    return getLocalizedText('폴더를 선택해 주세요.');
  return '';
}
