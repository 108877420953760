import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 역할에 해당되는 직원을 조회합니다.
 * @param role 역할.
 * @returns 직원 역할 배열.
 */
async function find(arg: {
  companyId: number;
  employeeId?: number;
  role?: 'DASHBOARD_USER' | 'DASHBOARD_ADMIN';
}): Promise<
  {
    employeeId: number;
    role: 'DASHBOARD_USER' | 'DASHBOARD_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { companyId, employeeId } = arg;
    const { host, headers } = getApiConfig();
    // 직원 아이디가 있을 경우 직원의 역할만 조회. 아이디가 없을 경우 전체 역할 조회.
    const url =
      employeeId !== undefined
        ? `${host}/apis/dashboard/v1/employees/companies/${companyId}/${employeeId}/roles`
        : `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/roles`;
    const response = await axios.get(url, {
      headers,
      params: arg.role ? { role: arg.role } : undefined,
    });
    return response.data;
  } catch (e) {
    return [];
  }
}

/**
 * 직원 역할을 추가합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function append(arg: {
  companyId: number;
  data: { employeeId: number; role: 'DASHBOARD_USER' | 'DASHBOARD_ADMIN' }[];
}): Promise<
  {
    employeeId: number;
    role: 'DASHBOARD_USER' | 'DASHBOARD_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/roles`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 직원 역할을 삭제합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function remove(arg: {
  companyId: number;
  data: {
    employeeId: number;
    role: 'DASHBOARD_USER' | 'DASHBOARD_ADMIN';
    updateAt: string;
  }[];
}): Promise<
  {
    employeeId: number;
    role: 'DASHBOARD_USER' | 'DASHBOARD_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/roles`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function findPreferences(arg: { companyId: number }): Promise<{
  portalPriority: 'COMPANY' | 'EMPLOYEE'; // 우선순위 포탈
  useEmployeePortal: boolean; // 직원 포탈 사용 여부
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${arg.companyId}/preferences`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updatePreferences(arg: {
  companyId: number;
  portalPriority: 'COMPANY' | 'EMPLOYEE';
  useEmployeePortal: boolean;
  updateAt: string;
}): Promise<{
  portalPriority: 'COMPANY' | 'EMPLOYEE'; // 우선순위 포탈
  useEmployeePortal: boolean; // 직원 포탈 사용 여부
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const { companyId, ...data } = arg;
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/preferences`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const preferencesApi = {
  find,
  append,
  remove,
  findPreferences,
  updatePreferences,
};

export default preferencesApi;
