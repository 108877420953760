import React from 'react';
import Button from '../../../../../../components/button/Button';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

function Pagination({
  no,
  rows,
  count,
  onPrev,
  onNext,
}: {
  no: number;
  rows: number;
  count: number;
  onPrev(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  onNext(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element | null {
  // console.log(`${Pagination.name}.render`);

  if (count < 1) return null;

  const start = (no - 1) * rows + 1;
  let end = no * rows;
  if (end > count) end = count;
  const range = start === end ? `${start}` : `${start}-${end}`;

  return (
    <div className="eui-pagination">
      <span className="page-count total">{`${range} of ${count}`}</span>
      <Button
        text={getLocalizedText('이전')}
        className="page-action"
        iconType
        icon="chevron-left"
        disabled={start === 1}
        onClick={onPrev}
      />
      <Button
        text={getLocalizedText('다음')}
        className="page-action"
        iconType
        icon="chevron-right"
        disabled={end === count}
        onClick={onNext}
      />
    </div>
  );
}

export default Pagination;
