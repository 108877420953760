import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import MultilingualTextField from '../../../../../components/textfield/MultilingualTextField';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import { CustomNumbers, Language } from '../../../../../groupware-common/types';
import { RootState as R } from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import DirectoryMenuTreeContainer from '../../../../containers/DirectoryMenuTreeContainer';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../stores/directory';
import { GroupUserArg, SaveGroupArg } from '../../../../stores/directory/group';
import { LocaleString } from '../../../../../groupware-webapp/stores/i18n';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DirectoryUserGroupEditDrawer(props: {
  onSave(arg: SaveGroupArg): void;
  onClose(): void;
}): JSX.Element {
  const { onSave, onClose } = props;
  const directory = useDirectory();
  const employees = useSelector((s: R) => s.directory.employee.list.data.items);
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );
  const languages = useSelector((s: R) => s.session.languages);

  const initialState = {
    names: languages.map((lang) => ({ code: lang, value: '' })),
    includers: [],
    includerMenuPoint: undefined,
    validation: '',
  };

  const [state, setState] = useState<{
    id?: number;
    names: LocaleString[];
    includers: GroupUserArg[];
    includerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    validation: string;
  }>(initialState);

  /** 그룹명 변경 이벤트 */
  const handleNameChange = (code: Language, value: string) => {
    setState((prev) => ({
      ...prev,
      names: state.names.map((a) =>
        a.code === code ? { ...a, ...{ code, value } } : a,
      ),
    }));
  };

  const handleSubmit = () => {
    if (state.includers.length < 1) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('사용자를 1명 이상 등록 해주세요.'),
      }));
      return;
    }
    onSave({
      names: state.names.filter(({ value }) => value.trim() !== ''),
      includers: state.includers,
    });
  };

  /** 사용자 아이템 삭제. */
  const handleIncluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      includers: prev.includers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 사용자 추가 창 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { includerMenuPoint } = state;
    if (event !== undefined && includerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        includerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        includerMenuPoint: undefined,
      }));
    }
  };

  /** 사용자 아이템 추가. */
  const handleIncluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.includers.some((a) => a.referenceId === employeeId))
          return { ...prev, includerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, includerMenuPoint: undefined };

        return {
          ...prev,
          includers: [
            ...prev.includers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
            },
          ],
        };
      });
    } else if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.includers.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, includerMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, includerMenuPoint: undefined };

        return {
          ...prev,
          includers: [
            ...prev.includers,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
              isDuple: false,
            },
          ],
          change: true,
        };
      });
    }
  };

  return (
    <>
      <Drawer title={getLocalizedText('그룹 등록')} onClose={onClose}>
        <DrawerBody className="ui-board-company">
          <PostWrite>
            <PostWrite.Item required title={getLocalizedText('그룹명')}>
              {/* <TextField value={state.name} onChange={handleNameChange} /> */}
              <MultilingualTextField
                value={state.names.map((x) => ({
                  language: x.code,
                  value: x.value,
                }))}
                onChange={handleNameChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사용자')}>
              <ChipGroup
                add={getLocalizedText('추가')}
                onAdd={handleDirectoryTreeMenuToggle}
              >
                {state.includers
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      if (referenceType === 'ORGANIZATION') {
                        // 부서일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            icon="sitemap-fill"
                            onDelete={() =>
                              handleIncluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'EMPLOYEE') {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            avatar={DirectoryData.avatar}
                            onDelete={() =>
                              handleIncluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      return null;
                    },
                  )}
                {state.includerMenuPoint && (
                  <DirectoryMenuTreeContainer
                    deduplication
                    point={state.includerMenuPoint}
                    typeToFilter={['organization', 'employee']}
                    onItemClick={handleIncluderAppend}
                    onClose={handleDirectoryTreeMenuToggle}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>
          </PostWrite>
        </DrawerBody>
        <DrawerAction>
          <Button
            noDuplication={state.validation === ''}
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleSubmit}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
    </>
  );
}

export default DirectoryUserGroupEditDrawer;
