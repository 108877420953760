import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import AuthenticationBlockedUserContainer from '../pages/adminconsole/blockedUser/AuthenticationBlockedUserContainer';
import AuthenticationLoginContainer from '../pages/adminconsole/login/AuthenticationLoginContainer';
import AuthenticationNetworkContainer from '../pages/adminconsole/network/AuthenticationNetworkContainer';

function AuthenticationLocateContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = props;
  const { menu } = getPathParams<{ menu: string }>('/*/*/:menu', pathname);

  if (menu === 'network') return <AuthenticationNetworkContainer />;

  if (menu === 'blockedUser')
    return (
      <AuthenticationBlockedUserContainer pathname={pathname} search={search} />
    );

  return <AuthenticationLoginContainer />;
}

export default AuthenticationLocateContainer;
