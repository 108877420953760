import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getQueryParams } from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import { sessionActions } from '../../groupware-webapp/stores/session';
import DashBoardComposeRoute from '../pages/root/dashboard/compose/DashBoardComposeRoute';
import DashBoardContentRoute from '../pages/root/dashboard/content/DashBoardContentRoute';
import { templatesActions } from '../stores/templates';
import { portalsActions } from '../stores/portals';
import { preferencesActions } from '../stores/preferences';

function DashBoardLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element | null {
  const { pathname, search, locationKey: key, hash } = props;

  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);

  useEffect(() => {
    const route = { pathname, search, hash, clearErrors: true };

    dispatch(preferencesActions.findPreferences()).then(() => {
      dispatch(portalsActions.findList({ companyId, employeeId })).then(
        (result) => {
          if (result.type.endsWith('fulfilled')) {
            const payload = result.payload as {
              isInitial: boolean;
              id: number;
              seq: number;
              name: string;
              isAdminPortal: boolean;
              updateAt: string;
            }[];
            const initialPortal = payload.find((x) => x.isInitial);
            if (initialPortal !== undefined) {
              dispatch(
                templatesActions.findList({ portalId: initialPortal.id }),
              );
            }
            dispatch(templatesActions.findAllTemplate({}));
          }
        },
      );
    });
    dispatch(sessionActions.setRoute(route));
  }, [dispatch]);

  if (queryParams.contentMode === 'update') {
    return <DashBoardComposeRoute pathname={pathname} search={search} />;
  }
  return <DashBoardContentRoute pathname={pathname} search={search} />;
}

export default DashBoardLocateRoute;
