import { combineReducers } from '@reduxjs/toolkit';
import i18nReducer from './i18n';
import sessionReducer from './session';

import directoryPreferencesReducer from '../../groupware-directory/stores/directory/preferences';
import companyReducer from '../../groupware-directory/stores/directory/company';
import organizationReducer from '../../groupware-directory/stores/directory/organization';
import employeeReducer from '../../groupware-directory/stores/directory/employee';
import jobPositionReducer from '../../groupware-directory/stores/directory/jobposition';
import jobDutyReducer from '../../groupware-directory/stores/directory/jobduty';
import userGroupReducer from '../../groupware-directory/stores/directory/group';

import approvalReducer from '../../groupware-approval/stores/approval';
import approvalPreferencesReducer from '../../groupware-approval/stores/approval/preferences';
import approvalArbitraryDecisionReducer from '../../groupware-approval/stores/approval/arbitraryDecisions';
import approvalApproverMacroReducer from '../../groupware-approval/stores/approval/approverMacro';
import approvalSurrogateApprovalReducer from '../../groupware-approval/stores/approval/surrogate-approval';
import approvalViewPermissionReducer from '../../groupware-approval/stores/approval/viewPermission';
import approvalWorkReducer from '../../groupware-approval/stores/approval/work';
import approvalFormReducer from '../../groupware-approval/stores/approval/form';
import approvalUserPreferencesReducer from '../../groupware-approval/stores/approval/userPreferences';
import approvalDocumentReducer from '../../groupware-approval/stores/approval/document';
import approvalAttachedDocumentReducer from '../../groupware-approval/stores/approval/attachedDocument';
import approvalArchiveReducer from '../../groupware-approval/stores/approval/archive';
import userSettingsSliceReducer from '../../groupware-approval/stores/approval/user-settings';

import boardReducer from '../../groupware-board/stores/board';
import boardPreferencesReducer from '../../groupware-board/stores/preferences';
import boardFormReducer from '../../groupware-board/stores/form';
import boardFolderReducer from '../../groupware-board/stores/folder';
import boardTrashReducer from '../../groupware-board/stores/trash';

import attendancePreferencesReducer from '../../groupware-attendance/stores/attendance/preferences';
import attendanceAttendancesReducer from '../../groupware-attendance/stores/attendance/attendances';
import attendenceHolidayReducer from '../../groupware-attendance/stores/attendance/holiday';
import attendanceFormReducer from '../../groupware-attendance/stores/attendance/form';
import attendanceWorkReducer from '../../groupware-attendance/stores/attendance/work';
import adjustReducer from '../../groupware-attendance/stores/attendance/adjust';
import dayOffStatusReducer from '../../groupware-attendance/stores/attendance/dayOffStatus';
import attendanceCancelStatusReducer from '../../groupware-attendance/stores/attendance/detailStatus';
import attendanceNoticeReducer from '../../groupware-attendance/stores/attendance/notice';
import attendanceSendNoticeReducer from '../../groupware-attendance/stores/attendance/sendNotice';
import organizationStatusReducer from '../../groupware-attendance/stores/attendance/organizationStatus';

import resourcesReducer from '../../groupware-resource/stores/resources';
import resourceFolderReducer from '../../groupware-resource/stores/folders';
import resourceUserPreferencesReducer from '../../groupware-resource/stores/userPreferences';
import resourcePreferencesReducer from '../../groupware-resource/stores/preferences';
import resourceApprovalReducer from '../../groupware-resource/stores/approval';

import documentReducer from '../../groupware-document/stores/document/document';
import documentFoldersReducer from '../../groupware-document/stores/document/folders';
import documentFormReducer from '../../groupware-document/stores/document/form';
import documentPreferencesReducer from '../../groupware-document/stores/document/preferences';
import documentTrashReducer from '../../groupware-document/stores/document/trash';

import calendarPreferenecsReducer from '../../groupware-calendar/stores/calendar/preferences';
import calendarUserPreferencesReducer from '../../groupware-calendar/stores/calendar/userPreferences';
import calendarCalendarsReducer from '../../groupware-calendar/stores/calendar/calendars';
import calendarSchedulesReducer from '../../groupware-calendar/stores/calendar/schedules';

import contactsReducer from '../../groupware-contact/stores/contacts';
import contactLabelReducer from '../../groupware-contact/stores/labels';

import SystemLinkFormReducer from '../../groupware-systemlink/stores/systemlink/form';
import SystemLinkWorkReducer from '../../groupware-systemlink/stores/systemlink/work';
import SystemLinkPreferencesReducer from '../../groupware-systemlink/stores/systemlink/preferences';

import serviceMenuReducer from '../../groupware-service/stores/module';
import serviceBasicReducer from '../../groupware-service/stores/basic';

import settingInformationReducer from '../../groupware-setting/stores/information';

import authenticationKLoginReducer from '../../groupware-authentication/store/login';
import authenticationBlockedReducer from '../../groupware-authentication/store/blockedUser';

import dashBoardPreferenReducer from '../../groupware-dashboard/stores/preferences';
import dashBoardPortalReducer from '../../groupware-dashboard/stores/portals';
import dashBoardTemplateReducer from '../../groupware-dashboard/stores/templates';

import SecuritiesReducer from '../../groupware-custom-sangsanginsecurities/stores/sangsanginsecurities/securities';
import SecuritiesSettingReducer from '../../groupware-custom-sangsanginsecurities/stores/sangsanginsecurities/setting';
import SecuritiesAuthorityReducer from '../../groupware-custom-sangsanginsecurities/stores/sangsanginsecurities/authority';
import SecuritiesPaymentReducer from '../../groupware-custom-sangsanginsecurities/stores/sangsanginsecurities/payments';

const rootReducer = combineReducers({
  i18n: i18nReducer,

  session: sessionReducer,
  directory: combineReducers({
    preferences: directoryPreferencesReducer,
    company: companyReducer,
    organization: organizationReducer,
    employee: employeeReducer,
    jobPosition: jobPositionReducer,
    jobDuty: jobDutyReducer,
    group: userGroupReducer,
  }),

  approval: approvalReducer,

  authentication: combineReducers({
    login: authenticationKLoginReducer,
    blockedUser: authenticationBlockedReducer,
  }),

  approval2: combineReducers({
    preferences: approvalPreferencesReducer,
    arbitraryDecisions: approvalArbitraryDecisionReducer,
    approverMacro: approvalApproverMacroReducer,
    surrogateApproval: approvalSurrogateApprovalReducer,
    viewPermission: approvalViewPermissionReducer,
    work: approvalWorkReducer,
    form: approvalFormReducer,
    userPreferences: approvalUserPreferencesReducer,
    document: approvalDocumentReducer,
    attachedDocument: approvalAttachedDocumentReducer,
    archive: approvalArchiveReducer,
    userSettings: userSettingsSliceReducer,
  }),

  attendance: combineReducers({
    preferences: attendancePreferencesReducer,
    attendances: attendanceAttendancesReducer,
    organization: organizationStatusReducer,
    holiday: attendenceHolidayReducer,
    form: attendanceFormReducer,
    work: attendanceWorkReducer,
    adjust: adjustReducer,
    dayOffStatus: dayOffStatusReducer,
    detailStatus: attendanceCancelStatusReducer,
    notice: attendanceNoticeReducer,
    sendNotice: attendanceSendNoticeReducer,
  }),

  systemlink: combineReducers({
    preferences: SystemLinkPreferencesReducer,
    form: SystemLinkFormReducer,
    work: SystemLinkWorkReducer,
  }),

  resource: combineReducers({
    preferences: resourcePreferencesReducer,
    resources: resourcesReducer,
    approval: resourceApprovalReducer,
    folder: resourceFolderReducer,
    userPreferences: resourceUserPreferencesReducer,
  }),

  boards: combineReducers({
    board: boardReducer,
    preferences: boardPreferencesReducer,
    form: boardFormReducer,
    folder: boardFolderReducer,
    trash: boardTrashReducer,
  }),

  document: combineReducers({
    preferences: documentPreferencesReducer,
    documents: documentReducer,
    folders: documentFoldersReducer,
    form: documentFormReducer,
    trash: documentTrashReducer,
  }),

  calendar: combineReducers({
    preferences: calendarPreferenecsReducer,
    userPreferences: calendarUserPreferencesReducer,
    calendars: calendarCalendarsReducer,
    schedules: calendarSchedulesReducer,
  }),

  contact: combineReducers({
    contacts: contactsReducer,
    labels: contactLabelReducer,
  }),

  service: combineReducers({
    menu: serviceMenuReducer,
    basic: serviceBasicReducer,
  }),

  setting: combineReducers({
    information: settingInformationReducer,
  }),

  dashboard: combineReducers({
    preferences: dashBoardPreferenReducer,
    portals: dashBoardPortalReducer,
    templates: dashBoardTemplateReducer,
  }),

  securites: combineReducers({
    security: SecuritiesReducer,
    setting: SecuritiesSettingReducer,
    authority: SecuritiesAuthorityReducer,
    payment: SecuritiesPaymentReducer,
  }),
});

export default rootReducer;
