import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AttachFile } from '../../../../stores/board';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import UserInfo from '../../../../../components/user/UserInfo';
import PostSubject from '../../../../../components/post/PostSubject';
import PostList, {
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import Button from '../../../../../components/button/Button';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';
import FileChipGroup from '../../../../../components/chip/FileChipGroup';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

type Subject = {
  folderName?: string;
  subjectHeader?: string;
  value: string;
  new: boolean;
  comments?: number;
  security: boolean;
  notice: boolean;
};

type Props = {
  type?: PostListType;
  id?: number;
  listId?: string;
  isSecure?: boolean;
  isNotice?: boolean;
  selected?: boolean;
  columns?: Column<PostListItemType>[];
  item?: Record<string, Subject | User | boolean | string | number | undefined>;
  attachedFiles?: AttachFile[];
  onClick?(arg: { id: string; isNotice: boolean }): void;
  onCheckedChange?(itemId: string, checked: boolean): void;
  onImportanceChange?(arg: { id: number; isNotice: boolean }): void;
  onFileDownload?(fileId: number, name: string): void;
};

function BoardContentBodyListItem(dumy: Props) {
  const display = useSelector((state: RootState) => state.session.display);
  const readingPaneMode = useSelector(
    (state: RootState) => state.boards.board.readingPaneMode,
  );
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || 0,
    listId: dumy.listId || '',
    isSecure: dumy.isSecure ?? false,
    isNotice: dumy.isNotice ?? false,
    selected: dumy.selected || false,
    columns: dumy.columns || ([] as Column<PostListItemType>[]),
    item:
      dumy.item ||
      ({
        checked: false,
        importance: false,
        subject: {
          value: getLocalizedText('제목'),
          new: false,
          comments: 3,
          security: false,
          notice: false,
        },
        views: 0,
        likes: 0,
        drafter: {
          name: getLocalizedText('홍길동'),
          organization: getLocalizedText('조직'),
          job: getLocalizedText('직위/직책'),
          avatar: '/images/faces/faces_31.jpg',
        },
        createAt: '2023-03-06',
      } as Record<
        string,
        Subject | User | boolean | string | number | undefined
      >),
    attachedFiles: dumy.attachedFiles ?? [],
    onCheckedChange: dumy.onCheckedChange || emptyFunction,
    onImportanceChange: dumy.onImportanceChange || emptyFunction,
    onClick: dumy.onClick || emptyFunction,
  };

  const displayFileVisible = useSelector(
    (state: RootState) => state.boards.board.displayDensity,
  );

  const handleClick = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    props.onClick({
      id: props.listId,
      isNotice: props.isNotice,
    });
  };

  const handleCheckedChange = () => {
    props.onCheckedChange(props.listId, !props.item.checked);
  };

  const handleImportanceChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    props.onImportanceChange({ id: props.id, isNotice: props.isNotice });
  };

  const handleDownload = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fileId?: number, // 파일 아이디.
    name?: string, // 파일 이름.
  ) => {
    event.stopPropagation();
    if (props.onFileDownload && fileId && name)
      props.onFileDownload(fileId, name);
  };

  const { selected, columns, item, attachedFiles } = props;

  return (
    <PostList.Item selected={selected} onClick={handleClick}>
      {columns.map(({ name, type, text }) => {
        const value = item[name];
        let children: React.ReactNode | null = null;
        if (name === 'checked' && typeof value === 'boolean') {
          if (props.isNotice) {
            if (
              display !== 'phone' &&
              readingPaneMode !== 'gallery' &&
              readingPaneMode !== 'vertical'
            ) {
              children = (
                <i
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  className="post-badge notice"
                  aria-label={getLocalizedText('공지')}
                  title={getLocalizedText('공지')}
                />
              );
            } else {
              children = null;
            }
          } else
            children = (
              <Checkbox checked={value} onChange={handleCheckedChange} />
            );
        } else if (name === 'importance' && typeof value === 'boolean') {
          children = (
            <Button
              text={getLocalizedText('별표표시')}
              icon={value ? 'star-fill' : 'star'}
              iconType
              onClick={handleImportanceChange}
            />
          );
        } else if (type === 'count' && typeof value === 'number') {
          if (display === 'phone' || readingPaneMode === 'vertical')
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px' }}>{value}</span>
              </span>
            );
          else if (readingPaneMode === 'gallery')
            children = (
              <span>
                <PostList.Value value={`${text} ${value}`} />
              </span>
            );
          else children = <PostList.Value value={value} />;
        } else if (type === 'post') {
          const subject = value as Subject;
          const style: React.CSSProperties = props.isNotice
            ? { color: 'var(--primary-color)' }
            : { fontWeight: 'normal' };
          let subjectPaddingTop: string | undefined;
          if (readingPaneMode === 'gallery') {
            if (subject.folderName) subjectPaddingTop = '4px';
            else subjectPaddingTop = '8px';
          }
          children = (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: subjectPaddingTop,
              }}
            >
              <div style={{ display: 'flex' }}>
                <PostSubject
                  folder={
                    subject.folderName
                      ? { name: subject.folderName }
                      : undefined
                  }
                  style={style}
                  notice={
                    subject.notice &&
                    (display === 'phone' ||
                      readingPaneMode === 'gallery' ||
                      readingPaneMode === 'vertical')
                  }
                  security={subject.security}
                  new={subject.new}
                  comments={subject.comments}
                  titleHeader={subject.subjectHeader}
                  title={subject.value}
                  attachments={attachedFiles?.length > 0}
                />
              </div>
              {props.type !== 'gallery' &&
                displayFileVisible === 'normal' &&
                attachedFiles &&
                attachedFiles.length > 0 && (
                  <FileChipGroup
                    data={attachedFiles.map((a) => ({
                      id: a.fileId,
                      name: a.name,
                    }))}
                    max={3}
                    onDownload={handleDownload}
                  />
                )}
            </div>
          );
        } else if (type === 'user') {
          const { avatar, name: username, organization } = value as User;
          children = (
            <UserInfo
              className={props.isNotice ? 'notice' : undefined}
              avatar={username === '' ? undefined : avatar}
              name={username === '' ? organization : username}
              from={username === '' ? undefined : organization}
              icon={username === '' ? 'sitemap-fill' : undefined}
            />
          );
        } else if (typeof value === 'string') {
          if (type === 'date') {
            children = (
              <PostList.Value value={dateSimplify(value, 'dateNumber')} />
            );
          } else if (type === 'thumbnail') {
            const subject = item.subject as Subject;
            const { value: title, security } = subject;
            const className = security
              ? 'default-thumbnail security'
              : 'default-thumbnail';
            children =
              value !== '' ? (
                <img src={value} alt={title} />
              ) : (
                <div className={className}>
                  {security ? (
                    <span>{getLocalizedText('비공개이미지')}</span>
                  ) : (
                    <span>{getLocalizedText('이미지없음')}</span>
                  )}
                </div>
              );
          } else {
            children = <PostList.Value value={value} />;
          }
        }
        const style =
          type === 'count' ? { justifyContent: 'center' } : undefined;
        return (
          <PostList.Cell key={name} type={type} style={style}>
            {children}
          </PostList.Cell>
        );
      })}
    </PostList.Item>
  );
}

export default React.memo(BoardContentBodyListItem);
