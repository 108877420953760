import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function TeamsError(props: {
  error?: string;
  isClooworks?: boolean;
}): JSX.Element | null {
  if (props.isClooworks)
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '8px',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            padding: '0 8px',
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{ marginBottom: '48px' }}
            src="/images/company_logo.png"
            alt={getLocalizedText('클루커스 로고')}
          />
          {props.error && (
            <div style={{ marginBottom: '16px' }}>{props.error}</div>
          )}
          <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
            <div style={{ marginRight: '8px;' }}>
              [{getLocalizedText('관련 문의')}]
            </div>
            <a
              style={{ marginTop: '2px', fontSize: '14px' }}
              href="https://www.cloocus.com/talk-to-an-expert/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.cloocus.com/taks-to-an-expert/
              <i
                className="eui-icon eui-icon-external-link"
                style={{ verticalAlign: 'middle', fontSize: '14px' }}
              />
            </a>
          </div>
        </div>
        <img
          style={{ padding: '0 8px', maxWidth: '100%' }}
          src="/images/company_error_img.png"
          alt={getLocalizedText('클루커스 오류 이미지')}
        />
      </div>
    );
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '8px',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          padding: '0 8px',
          fontSize: '16px',
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{ marginBottom: '48px' }}
          src="/images/company_logo.png"
          alt={getLocalizedText('회사 로고')}
        />
        {props.error && (
          <div style={{ marginBottom: '16px' }}>{props.error}</div>
        )}
      </div>
      <img
        style={{ padding: '0 8px', maxWidth: '100%' }}
        src="/images/company_error_img.png"
        alt={getLocalizedText('회사 오류 이미지')}
      />
    </div>
  );
}

export default TeamsError;
