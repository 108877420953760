import React, { useEffect, useState } from 'react';
import { documentApi } from '../../../apis/approval/v1/document';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import Drawer from '../../../../components/drawer/Drawer';
import Avatar from '../../../../components/avatar/Avatar';
import Divider from '../../../../components/divider/Divider';
import {
  ApprovalLineGroupItemOutputType,
  ApprovalLineType,
} from '../dialogs/ApprovalLineDialogContainer';
import { getAvatarPath } from '../../../../groupware-common/utils';
import { createLocalizedTextFactory } from '../../../../groupware-common/utils/i18n';
import { Language } from '../../../../groupware-common/types';
import { getJobClassName } from '../../../../groupware-directory/apis/directory/v1';
import {
  DocumentView,
  getApprovalLineGroupNameFromType,
} from '../../../stores/approval/document';
import { dateTimeFormat } from '../../../../groupware-common/utils/ui';

function approvalActText(
  a:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'surrogateReturn' // 대리 결재 반려
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none'
    | undefined,
): string {
  const getLocalizedText = createLocalizedTextFactory('approval');

  if (a === 'draft') return getLocalizedText('상신');
  // if (a === 'back') return 2; // 전단계 반려 (기안자 다다음 사람 부터 가능)
  //
  if (a === 'approval' || a === 'surrogateApproval')
    return getLocalizedText('승인'); // (최종 결재인 경우 완료)
  if (a === 'defer') return getLocalizedText('후결'); // (최종 전인 경우 완료)
  if (a === 'arbitraryDecision') return getLocalizedText('전결'); // (바로 완료)
  //
  if (a === 'return' || a === 'surrogateReturn')
    return getLocalizedText('반려');
  //
  if (a === 'retrieve') return getLocalizedText('회수'); // (상태 삭제)
  // 결재 상태 변경 없음.
  if (a === 'hold') return getLocalizedText('보류');
  if (a === 'meet') return getLocalizedText('대면');
  if (a === 'receipt') return getLocalizedText('접수');
  // if (a === undefined)
  return ''; // 없음
}

/**
 * 결재 상태 드로워.
 * @param language 언어.
 * @param approvalLine 결재선.
 * @param complete 완료 여부.
 * @returns JSX 엘리먼트.
 */
function ApprovalStatusDrawer(props: {
  language: Language;
  view: DocumentView;
  approvalLine: ApprovalLineType;
  complete: boolean;
  onClose(): void;
}) {
  // console.log(`${ApprovalStatusDrawer.name}.render(props)`, props);
  const { view } = props;
  const [state, setState] = useState<
    | {
        referenceCompanyId: number;
        referenceId: number;
        referenceType: number;
      }[]
    | undefined
  >(undefined);

  useEffect(() => {
    async function run() {
      const data = await documentApi.browse({
        affiliatedCompanyId: view.affiliatedCompanyId,
        id: view.id,
      });
      setState(data);
    }
    run();
  }, []);

  const { language, approvalLine } = props;

  const getLocalizedText = createLocalizedTextFactory('approval');

  // 완료 이거나 회수인 경우.
  const complete =
    props.complete ||
    approvalLine.groups.find(
      (group) =>
        group.type === 'draft' &&
        group.items.length > 0 &&
        group.items[0].act === 'retrieve',
    ) !== undefined;

  // console.log(`${ApprovalStatusDrawer.name}(props):complete`, complete);

  let approverSeq = 0; // 결재자 순번.
  let approvalSeq = 0; // 결재 순번.
  const items = approvalLine.groups
    .map((group) => {
      const { type } = group;
      const parallel =
        (group.type === 'agree' || group.type === 'receive') &&
        group.items.length > 1 &&
        group.parallel;

      if ((group.type === 'agree' || group.type === 'receive') && parallel)
        approverSeq += 1;

      return (group.items as ApprovalLineGroupItemOutputType[]).map((item) => {
        if (!parallel) approverSeq += 1;

        // 회수인 경우.
        if (item.act === 'retrieve') approvalSeq = -1;

        // 합의 필수 반려 및 반려, 보류, 대면, 접수인 경우.
        if (
          ((item.act === 'return' && item.option !== true) ||
            item.act === 'hold' ||
            item.act === 'meet' ||
            item.act === 'receipt') &&
          approvalSeq === 0
        )
          approvalSeq = approverSeq;

        if (item.actAt === undefined && approvalSeq === 0)
          approvalSeq = approverSeq;

        let className: 'waiting' | 'expected' | 'return' | undefined;

        // 합의 선택이 아니고 반려인 경우. (합의 선택이 아닌 경우 필수)
        if (item.act === 'return' && item.option !== true) className = 'return';
        // 완료 문서가 아닌 경우.
        else if (!complete) {
          // 보류, 대면, 접수인 경우.
          if (
            item.act === 'hold' ||
            item.act === 'meet' ||
            item.act === 'receipt'
          )
            className = 'waiting';
          else if (approvalSeq > 0 && approvalSeq < approverSeq)
            className = 'expected';
          else if (approvalSeq === approverSeq && item.act === undefined)
            className = 'waiting';
        }

        // console.log(`approvalSeq:${approvalSeq},approvalSeq:${approvalSeq},act:${item.act},${item.employeeName}`);

        let act: string;
        act = approvalActText(item.act);
        // 완료 문서가 아닌 경우.
        if (!complete) {
          // 접수인 경우.
          if (item.act === 'receipt' && item.actAt !== undefined)
            act = '접수중';
          else if (approvalSeq !== 0 && approvalSeq < approverSeq) act = '예정';
          else if (approvalSeq === approverSeq && item.act === undefined)
            act = '대기';
        }

        // console.log(`approvalSeq:${approvalSeq}, approverSeq:${approverSeq}, act:${act}`);

        return {
          type,
          parallel,
          ...item,
          avatar:
            item.employeeId !== undefined ? getAvatarPath(item) : undefined,
          class: className,
          act: getLocalizedText(act),
          actAt: item.act === 'none' ? undefined : item.actAt,
        };
      });
    })
    .flat();

  // console.log(`${ApprovalStatusDrawer.name}(props):items`, items);

  const style: React.CSSProperties = {
    marginLeft: '8px',
    paddingBottom: '5px',
    color: '#f44336',
    fontWeight: 'bold',
    fontSize: '13px',
  };

  return (
    <Drawer
      title={getLocalizedText('결재현황')}
      size="sm"
      onClose={props.onClose}
    >
      <DrawerBody>
        <div className="approval-line-status">
          {items.map((a) => {
            let classname = 'line-item';
            if (a.class !== undefined) classname = `${classname} ${a.class}`;

            const approvalGroupName = getApprovalLineGroupNameFromType(a.type);

            // 조직인 경우.
            if (a.employeeId === undefined) {
              const { organizationName: name } = a;
              return (
                <div key={a.id} className={classname}>
                  <Avatar name={name} icon="sitemap" className="avatar" />
                  <div className="info">
                    <div className="name">
                      {name}
                      <span style={style}>
                        {state &&
                        state.find(
                          (z) =>
                            z.referenceType === 2 &&
                            z.referenceCompanyId === a.companyId &&
                            z.referenceId === a.organizationId,
                        ) !== undefined
                          ? getLocalizedText('열람')
                          : getLocalizedText('미열람')}
                      </span>
                    </div>
                    <div className="status">
                      <em className="state">{`${getLocalizedText(
                        `결재선그룹.${approvalGroupName}`,
                      )}${a.option ? getLocalizedText('[선택]') : ''} ${
                        a.act
                      }`}</em>
                      {a.actAt !== undefined && (
                        <>
                          <Divider orientation="vertical" />
                          <time className="date">
                            {dateTimeFormat(a.actAt, 'YYYY-MM-DD(dd) H:mm')}
                          </time>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            }

            const {
              avatar,
              employeeName: name,
              jobClassType: jobClass,
              jobPositionName: jobPosition,
              jobDutyName: jobDuty,
              organizationName: organization,
            } = a;

            const position = getJobClassName(jobClass, jobPosition, jobDuty);

            return (
              <div key={a.id} className={classname}>
                <Avatar name={name} image={avatar} className="avatar" />
                <div className="info">
                  <div className="name">
                    {position !== '' ? `${name} ${position}` : name}
                    <span style={style}>
                      {state &&
                      state.find(
                        (z) =>
                          z.referenceType === 3 &&
                          z.referenceCompanyId === a.companyId &&
                          z.referenceId === a.employeeId,
                      ) !== undefined
                        ? getLocalizedText('열람')
                        : getLocalizedText('미열람')}
                    </span>
                  </div>
                  <div className="from">{organization}</div>
                  <div className="status">
                    <em className="state">{`${getLocalizedText(
                      `결재선그룹.${approvalGroupName}`,
                    )}${a.option ? getLocalizedText('[선택]') : ''} ${
                      a.act
                    }`}</em>
                    {a.actAt !== undefined && (
                      <>
                        <Divider orientation="vertical" />
                        <time className="date">
                          {dateTimeFormat(a.actAt, 'YYYY-MM-DD(dd) H:mm')}
                        </time>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </DrawerBody>
    </Drawer>
  );
}

export default React.memo(ApprovalStatusDrawer);

export { approvalActText };
