import React, { useState } from 'react';
import { Language } from '../../../../../groupware-common/types';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import MultilingualTextField from '../../../../../components/textfield/MultilingualTextField';
import TextField from '../../../../../components/textfield/TextField';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import DaumPostCode from '../../../../../components/daum-postcode';
import { parseDate } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Drawer from '../../../../../components/drawer/Drawer';

type Props = {
  names: { code: Language; value: string }[];
  representativesName: string;
  establishedDate: string;
  businessRegistrationNo: string;
  corporationRegistrationNo: string;
  businessType: string;
  businessItem: string;
  phoneNo: string;
  faxNo: string;
  postalCode: string;
  address: string;
  employeeCount: number;
  onUpdate(arg: unknown): void;
  onClose(): void;
};

function OrganizationChartContentCompanyEdit(dummy: Props): JSX.Element {
  const props = {
    ...dummy,
  };

  const [state, setState] = useState({
    names: props.names,
    representativesName: props.representativesName,
    establishedDate: parseDate(props.establishedDate),
    businessRegistrationNo: props.businessRegistrationNo,
    corporationRegistrationNo: props.corporationRegistrationNo,
    businessType: props.businessType,
    businessItem: props.businessItem,
    phoneNo: props.phoneNo,
    faxNo: props.faxNo,
    postalCode: props.postalCode,
    address: props.address,
    employeeCount: props.employeeCount,
    validation: {
      name: '',
      businessNo: '',
      corporationNo: '',
    },
  });

  /** 이름 변경 */
  const handleNameChange = (code: Language, value: string) => {
    setState((prevState) => ({
      ...prevState,
      names: prevState.names.map((a) => {
        if (a.code === code) return { code, value };
        return a;
      }),
    }));
  };

  /** 대표자 이름 변경 */
  const handleRepresentativesNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const representativesName = event.target.value;
    setState((prevState) => ({ ...prevState, representativesName }));
  };

  /** 설립일 변경 */
  const handleEstablishedDateChange = (establishedDate: Date | null) => {
    setState((prevState) => ({ ...prevState, establishedDate }));
  };

  /** 사업자등록번호 변경 */
  const handleBusinessRegistrationNoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.validity.valid) return;
    const businessRegistrationNo = event.target.value;
    setState((prevState) => ({ ...prevState, businessRegistrationNo }));
  };

  /** 법인등록번호 변경 */
  const handleCorporationRegistrationNoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.validity.valid) return;
    const corporationRegistrationNo = event.target.value;
    setState((prevState) => ({ ...prevState, corporationRegistrationNo }));
  };

  /** 업태 변경 */
  const handleBusinessTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const businessType = event.target.value;
    setState((prevState) => ({ ...prevState, businessType }));
  };

  /** 종목 변경 */
  const handleBusinessItemChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const businessItem = event.target.value;
    setState((prevState) => ({ ...prevState, businessItem }));
  };

  /** 전화번호 변경 */
  const handlePhoneNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNo = event.target.value;
    setState((prevState) => ({ ...prevState, phoneNo }));
  };

  /** 팩스번호 변경 */
  const handleFaxNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const faxNo = event.target.value;
    setState((prevState) => ({ ...prevState, faxNo }));
  };

  /** 직원수 변경 */
  const handleEmployeeCountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const employeeCount = parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, employeeCount }));
  };

  /** 주소 변경 */
  const handleAddressChange = ({
    postalCode,
    address,
  }: {
    postalCode: string;
    address: string;
  }) => {
    setState((prev) => ({ ...prev, postalCode, address }));
  };

  /** 저장 */
  const handleSave = () => {
    // console.log('handleSave');

    const { onUpdate } = props;
    const {
      // names,
      representativesName,
      // establishedDate,
      businessRegistrationNo,
      corporationRegistrationNo,
      businessType,
      businessItem,
      phoneNo,
      faxNo,
      postalCode,
      address,
      validation,
    } = state;

    // 직원수 값이 빈 값일 경우 employeeCount 값이 NaN 으로 설정됨.
    const employeeCount = state.employeeCount || 0;

    const newValidation = { ...validation };

    if (
      state.corporationRegistrationNo !== '' &&
      state.corporationRegistrationNo.length !== 13
    ) {
      newValidation.corporationNo =
        getLocalizedText('올바르지 않은 법인등록번호입니다.');
    } else {
      newValidation.corporationNo = '';
    }

    if (
      state.businessRegistrationNo !== '' &&
      state.businessRegistrationNo.length !== 10
    ) {
      newValidation.businessNo = getLocalizedText(
        '올바르지 않은 사업자등록번호입니다.',
      );
    } else {
      newValidation.businessNo = '';
    }

    if (state.names[0] === undefined || state.names[0].value.trim() === '') {
      newValidation.name = getLocalizedText('이름은 빈 값 일 수 없습니다.');
    } else {
      newValidation.name = '';
    }

    if (
      newValidation.name !== '' ||
      newValidation.corporationNo !== '' ||
      newValidation.businessNo !== ''
    ) {
      setState((prev) => ({ ...prev, validation: newValidation }));
      return;
    }

    const names: { code: Language; value: string | null }[] = [];
    props.names.forEach((a, i) => {
      const b = state.names[i];
      if (a.code === b.code && a.value !== b.value) {
        if (b.value.trim() !== '') names.push({ code: b.code, value: b.value });
        else names.push({ code: b.code, value: null });
      }
    });

    const establishedDate = parseDate(state.establishedDate);
    const param = {
      names: names.length === 0 ? undefined : names,
      representativesName:
        props.representativesName === representativesName
          ? undefined
          : representativesName,
      establishedDate:
        props.establishedDate === establishedDate ? undefined : establishedDate,
      businessRegistrationNo:
        props.businessRegistrationNo === businessRegistrationNo
          ? undefined
          : businessRegistrationNo,
      corporationRegistrationNo:
        props.corporationRegistrationNo === corporationRegistrationNo
          ? undefined
          : corporationRegistrationNo,
      businessType:
        props.businessType === businessType ? undefined : businessType,
      businessItem:
        props.businessItem === businessItem ? undefined : businessItem,
      phoneNo: props.phoneNo === phoneNo ? undefined : phoneNo,
      faxNo: props.faxNo === faxNo ? undefined : faxNo,
      postalCode: props.postalCode === postalCode ? undefined : postalCode,
      address: props.address === address ? undefined : address,
      employeeCount:
        props.employeeCount === employeeCount ? undefined : employeeCount,
    };

    onUpdate(param);
  };

  // const handleChangeWebSite = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     webSite: event.target.value,
  //   }));
  // };

  // const handleChangeMasterName = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     masterName: event.target.value,
  //   }));
  // };

  // const handleChangeMasterLoginId = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     masterLoginId: event.target.value,
  //   }));
  // };

  const { onClose } = props;
  const {
    names,
    representativesName,
    establishedDate,
    businessRegistrationNo,
    corporationRegistrationNo,
    businessType,
    businessItem,
    phoneNo,
    faxNo,
    postalCode,
    address,
    employeeCount,
    validation,
  } = state;

  const style: React.CSSProperties = {
    marginTop: '4px',
    fontSize: '12px',
    color: '#dc3545',
    lineHeight: '18px',
  };

  return (
    <Drawer title={getLocalizedText('회사정보 수정')} onClose={onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('회사명')}>
            <MultilingualTextField
              value={names.map((a) => ({ language: a.code, value: a.value }))}
              onChange={handleNameChange}
              error={validation.name !== ''}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('대표자')}>
            <TextField
              value={representativesName}
              onChange={handleRepresentativesNameChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('설립일')}>
            <CustomDatePicker
              selected={establishedDate}
              dateFormat="yyyy-MM-dd"
              onChange={handleEstablishedDateChange}
            />
          </PostWrite.Item>
          <PostWrite.Item
            title={getLocalizedText('사업자등록번호')}
            tooltip={getLocalizedText('사업자등록번호는 10자리로 구성됩니다.')}
          >
            <TextField
              value={businessRegistrationNo}
              onChange={handleBusinessRegistrationNoChange}
              error={validation.businessNo !== ''}
              pattern="[0-9]*"
              maxLength={10}
            />
            {validation.businessNo !== '' && (
              <div className="eui-form-help-text error" style={style}>
                {validation.businessNo}
              </div>
            )}
          </PostWrite.Item>
          <PostWrite.Item
            title={getLocalizedText('법인등록번호')}
            tooltip={getLocalizedText('법인등록번호는 13자리로 구성됩니다.')}
          >
            <TextField
              value={corporationRegistrationNo}
              onChange={handleCorporationRegistrationNoChange}
              error={validation.corporationNo !== ''}
              pattern="[0-9]*"
              maxLength={13}
            />
            {validation.corporationNo !== '' && (
              <div className="eui-form-help-text error" style={style}>
                {validation.corporationNo}
              </div>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('업태')}>
            <TextField
              value={businessType}
              onChange={handleBusinessTypeChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('종목')}>
            <TextField
              value={businessItem}
              onChange={handleBusinessItemChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('전화번호')}>
            <TextField value={phoneNo} onChange={handlePhoneNoChange} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('팩스번호')}>
            <TextField value={faxNo} onChange={handleFaxNoChange} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('사업장소재지')}>
            <DaumPostCode
              postalCode={postalCode}
              address={address}
              onChange={handleAddressChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('직원수')}>
            <TextField
              value={`${Number.isNaN(employeeCount) ? '' : employeeCount}`}
              onChange={handleEmployeeCountChange}
              width={80}
              maxLength={5}
              pattern="[0-9]*"
            />
          </PostWrite.Item>
          {/*
          <PostWrite.Item title="홈페이지">
            <TextField value={state.webSite} onChange={handleChangeWebSite} />
          </PostWrite.Item>
          <PostWrite.Item title="회사 관리자 정보">
            <TextField
              label="관리자 이름"
              value={state.masterName}
              onChange={handleChangeMasterName}
            />
            <TextField
              label="관리자 로그인 ID"
              value={state.masterLoginId}
              onChange={handleChangeMasterLoginId}
              showPassword
            />
          </PostWrite.Item>
          */}
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default OrganizationChartContentCompanyEdit;
