import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { IconType } from '../../../../../groupware-common/types/icon';
import DashBoardHeader, {
  DashBoardHeaderAction,
} from '../common/DashBoardHeader';
import DashBoardBody from '../common/DashBoardBody';
import DashBoardComposeItemBody from './DashBoardComposeItemBody';
import { PortalTemplateDetail } from '../../../../stores/templates';
import DashBoardContentItem from '../content/DashBoardContentItem';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

export interface DashBoardListItem {
  id: number;
  value: string;
  index: number;
}
function DashBoardComposeItem(props: {
  item: PortalTemplateDetail;
  onUpdate?(url: string, id: number, options?: string): void;
  onDelete?(id: number): void;
}): JSX.Element {
  const { item } = props;

  // 사용 가능한 템플릿인지 조회.
  const isAvailable =
    useSelector(
      (state: RootState) => state.dashboard.templates.availableList,
    ).find((x) => x.templateId === item.templateId) !== undefined;

  const [state, setState] = useState<{
    isEdit: boolean;
    url: string;
    options?: string;
    updateAt: string;
  }>({
    isEdit: false,
    url: item.url,
    options: item.options,
    updateAt: item.updateAt,
  });

  useEffect(() => {
    if (state.updateAt !== item.updateAt) {
      setState((prev) => ({
        ...prev,
        isEdit: false,
        updateAt: item.updateAt,
      }));
    }
  }, [item.updateAt]);

  const handleChangeTemplate = () => {
    setState((prev) => ({
      ...prev,
      isEdit: !prev.isEdit,
    }));
  };

  const handleActions = (code: string) => {
    if (code === 'update') {
      if (props.onUpdate) {
        props.onUpdate(state.url, item.id, state.options);
      }
      return;
    }
    if (code === 'changeUpdate' || code === 'cancel') {
      handleChangeTemplate();
    }
    if (code === 'delete') {
      if (props.onDelete) {
        props.onDelete(item.id);
      }
    }
  };

  const handleChangeUrl = (arg: {
    url: string;
    params?: string;
    options?: string;
  }) => {
    const { url, params, options } = arg;
    setState((prev) => ({
      ...prev,
      url: params ? `${url}/${params}` : url,
      options,
    }));
  };

  const headerActions = (): DashBoardHeaderAction[] => {
    const actions: DashBoardHeaderAction[] = [];
    if (item.isEdit && isAvailable) {
      if (state.isEdit) {
        actions.push(
          {
            text: getLocalizedText('수정'),
            code: 'update',
            onClick: handleActions,
            icon: 'check' as IconType,
            iconType: true,
          },
          {
            text: getLocalizedText('취소'),
            code: 'cancel',
            onClick: handleActions,
            icon: 'close' as IconType,
            iconType: true,
          },
        );
      } else {
        actions.push(
          {
            text: getLocalizedText('템플릿 수정 전환'),
            code: 'changeUpdate',
            onClick: handleActions,
            icon: 'edit' as IconType,
            iconType: true,
          },
          {
            text: getLocalizedText('삭제'),
            code: 'delete',
            onClick: handleActions,
            icon: 'trash-full' as IconType,
            iconType: true,
          },
        );
      }
    } else {
      actions.push({
        text: getLocalizedText('삭제'),
        code: 'delete',
        onClick: handleActions,
        icon: 'trash-full' as IconType,
        iconType: true,
      });
    }
    return actions;
  };

  return (
    <div className="dashboard-item">
      <DashBoardHeader
        title={getLocalizedText(item.title)}
        folderName={item.folderName}
        actions={headerActions()}
      />
      <DashBoardBody>
        {state.isEdit ? (
          <DashBoardComposeItemBody item={item} onChange={handleChangeUrl} />
        ) : (
          <DashBoardContentItem template={item} />
        )}
      </DashBoardBody>
    </div>
  );
}

export default DashBoardComposeItem;
