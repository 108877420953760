import React from 'react';
import EuiSetting from '../../../../components/layout/EuiSetting';
import PostView from '../../../../components/post/PostView';
import { Language } from '../../../../groupware-common/types';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

/**
 * @property parentNames 상위 조직 이름 배열.
 * @property names 언어별 이름.
 * @property description 설명.
 * @property managerName 조직 관리자 이름.
 * @property onUpdate 수정 이벤트.
 * @property onDelete 삭제 이벤트.
 */
type Props = {
  parentNames: string[];
  names: { label: Language; value: string }[];
  description: string;
  managerName: string;
};

function DirectoryOrganizationView(props: Props): JSX.Element {
  const { parentNames, names, description, managerName } = props;

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('부서정보')} />
      <EuiSetting.Content>
        <PostView>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title={getLocalizedText('상위부서')}>
                  <PostView.CategoryValue>
                    {parentNames.map((v, i, a) => {
                      if (i !== a.length - 1)
                        return <span key={v}>{v} &gt;</span>;
                      return <span key={v}>{v}</span>;
                    })}
                  </PostView.CategoryValue>
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('부서명')}>
                  {names.map(({ label, value }) => {
                    return (
                      <PostView.CategoryValue
                        key={label}
                        value={value}
                        label={label}
                      />
                    );
                  })}
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('부서설명')}>
                  <PostView.CategoryValue value={description} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('부서장')}>
                  <PostView.CategoryValue value={managerName} />
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default DirectoryOrganizationView;
