import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { portalsActions } from '../../../../stores/portals';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { templatesActions } from '../../../../stores/templates';

function DashBoardCompanyPortalRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    if (mount) {
      Promise.all([
        dispatch(
          portalsActions.companyPortal({
            companyId,
          }),
        ),
        dispatch(templatesActions.findAllTemplate({ isAdmin: true })),
      ]).then(() => {
        dispatch(sessionActions.setRoute({ pathname, search }));
      });
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default DashBoardCompanyPortalRoute;
