import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Checkbox from '../../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../../components/checkbox/CheckboxGroup';
import CustomDatePicker from '../../../../components/date/CustomDatePicker';
import Dialog from '../../../../components/dialog/Dialog';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../components/post/PostWrite';
import Radio from '../../../../components/radio/Radio';
import TextField from '../../../../components/textfield/TextField';
import { getApiConfig } from '../../../../groupware-common/apis/common/v1';
import { getQueryParams } from '../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { appError } from '../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function getStateWord(state?: string): string[] {
  switch (state) {
    case '1':
      return ['PROGRESS'];
    case '2':
      return ['PROGRESS'];
    case '3':
      return ['COMPLETE'];
    case '4':
      return ['REJECT'];
    default:
      return ['PROGRESS', 'COMPLETE', 'REJECT'];
  }
}

function ApprovalExcelDownloadDialog(props: {
  search: string;
  onClose(): void;
}): JSX.Element {
  const queryParams = getQueryParams(props.search);
  const dispatch = useAppDispatch();
  const { companyId, employeeId } = useSelector(
    (s: RootState) => s.session.principal,
  );
  const [state, setState] = useState<{
    status: string[];
    date?: {
      start: Date;
      end: Date;
    };
    subject: string;
    documentNo: string;
    workName: string;
    formName: string;
    drafterName: string;
    draftOrganizationName: string;
    approvalTargetName: string;
  }>({
    status: getStateWord(queryParams.status),
    date:
      queryParams.startDate && queryParams.endDate
        ? {
            start: timezoneDate(queryParams.startDate),
            end: timezoneDate(queryParams.endDate),
          }
        : undefined,
    subject: queryParams.subject ?? '',
    documentNo: queryParams.documentNumber ?? '',
    workName: queryParams.workName ?? '',
    formName: queryParams.formName ?? '',
    drafterName: queryParams.drafterName ?? '',
    draftOrganizationName: queryParams.draftOrganizationName ?? '',
    approvalTargetName: queryParams.approvalTargetName ?? '',
  });
  const [validation, setValidation] = useState('');

  // 상태 체크 이벤트
  const handleChangeStatus = (arg: { type: string; checked: boolean }) => {
    const { checked } = arg;

    let changeStatus = state.status;
    switch (arg.type) {
      case 'all':
        changeStatus = checked ? ['PROGRESS', 'COMPLETE', 'REJECT'] : [];
        break;
      default:
        changeStatus = checked
          ? [...changeStatus, arg.type]
          : changeStatus.filter((a) => a !== arg.type);
        break;
    }

    setState((prev) => ({
      ...prev,
      status: changeStatus,
    }));
  };

  //  기간 타입 변경 이벤트.
  const handleChangeDateType = (type: 'all' | 'range') => {
    const start = timezoneDate();
    start.setDate(start.getDate() - 7);
    setState((prev) => ({
      ...prev,
      date:
        type === 'all'
          ? undefined
          : {
              start,
              end: timezoneDate(),
            },
    }));
  };

  // 기간 시작 날짜 변경 이벤트
  const handleChangeStartDate = (value: Date | null) => {
    if (value === null) return;

    setState((prev) => {
      if (!prev.date) return prev;
      return {
        ...prev,
        date: {
          start: value,
          end: prev.date.end < value ? value : prev.date.end,
        },
      };
    });
  };

  // 기간 종료 날짜 변경 이벤트
  const handleChangeEndDate = (value: Date | null) => {
    if (value === null) return;

    setState((prev) => {
      if (!prev.date) return prev;
      return {
        ...prev,
        date: {
          ...prev.date,
          end: value,
        },
      };
    });
  };

  // 제목 변경 이벤트
  const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, subject: event.target.value }));
  };

  // 문서번호 변경 이벤트
  const handleChangeDocumentNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, documentNo: event.target.value }));
  };

  //  업무명 변경 이벤트
  const handleChangeWorkName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, workName: event.target.value }));
  };

  // 양식명 변경 이벤트
  const handleChangeFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, formName: event.target.value }));
  };

  // 기안자 변경 이벤트
  const handleChangeDrafterName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, drafterName: event.target.value }));
  };

  // 기안부서 변경 이벤트
  const handleChangeDraftOrganizationName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      draftOrganizationName: event.target.value,
    }));
  };

  // 결재대상 변경 이벤트
  const handleChangeApprovalTargetName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, approvalTargetName: event.target.value }));
  };

  // 내보내기 클릭 이벤트
  const handleDownload = () => {
    if (state.status.length === 0) {
      setValidation(getLocalizedText('결재 상태를 한 개 이상 선택해 주세요.'));
      return;
    }

    const { host, headers } = getApiConfig();
    let searchParams = '';
    state.status.forEach((a, i) => {
      if (i === 0) searchParams = `statuses=${a}`;
      else searchParams += `&statuses=${a}`;
    });
    const date = state.date
      ? {
          start: dateFormat(initialDate(state.date.start), 'yyyy-MM-DD'),
          end: dateFormat(initialDate(state.date.end), 'yyyy-MM-DD'),
        }
      : undefined;
    if (state.subject.trim() !== '')
      searchParams += `&subject=${state.subject}`;
    if (state.documentNo.trim() !== '')
      searchParams += `&documentnumber=${state.documentNo}`;
    if (state.workName.trim() !== '')
      searchParams += `&workname=${state.workName}`;
    if (state.drafterName.trim() !== '')
      searchParams += `&draftername=${state.drafterName}`;
    if (state.draftOrganizationName.trim() !== '')
      searchParams += `&draftorganizationname=${state.draftOrganizationName}`;
    if (state.approvalTargetName.trim() !== '')
      searchParams += `&approvaltargetname=${state.approvalTargetName}`;
    if (date) searchParams += `&startdate=${date.start}&enddate=${date.end}`;
    // console.log('searchParams', searchParams);
    const url = `${host}/downloads/adminconsole/approval/v1/companies/${companyId}/employees/${employeeId}/folders/all/documents:cvs?${searchParams}`;

    const input = document.createElement('input');
    input.setAttribute('name', 'Authorization');
    input.setAttribute('value', headers.Authorization);
    input.setAttribute('type', `hidden`);
    const form = document.createElement('form');
    form.appendChild(input);
    form.action = `${url}&authToken=${headers.Authorization.split(' ')[1]}`;
    form.method = 'POST';
    document.body.appendChild(form);

    const submitForm = () => {
      try {
        form.submit();
        props.onClose();
      } catch (ex) {
        dispatch(
          sessionActions.error(
            appError({
              error: getLocalizedText(
                '파일 다운로드 중 문제가 발생하였습니다.',
              ),
            }),
          ),
        );
      } finally {
        // 폼 제출 후 정리 작업
        if (document.body.contains(form)) document.body.removeChild(form);
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
      }
    };

    form.onsubmit = () => false;
    submitForm();
  };

  const {
    status,
    subject,
    documentNo,
    workName,
    formName,
    drafterName,
    draftOrganizationName,
    approvalTargetName,
    date,
  } = state;
  return (
    <Dialog size="sm" onClose={props.onClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('파일 내보내기')}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('상태')}>
            <CheckboxGroup>
              <Checkbox
                label={getLocalizedText('전체')}
                checked={status.length === 3}
                onChange={(event) =>
                  handleChangeStatus({
                    checked: event.target.checked,
                    type: 'all',
                  })
                }
              />
              <Checkbox
                label={getLocalizedText('진행중')}
                checked={status.some((a) => a === 'PROGRESS')}
                onChange={(event) =>
                  handleChangeStatus({
                    checked: event.target.checked,
                    type: 'PROGRESS',
                  })
                }
              />
              <Checkbox
                label={getLocalizedText('완료')}
                checked={status.some((a) => a === 'COMPLETE')}
                onChange={(event) =>
                  handleChangeStatus({
                    checked: event.target.checked,
                    type: 'COMPLETE',
                  })
                }
              />
              <Checkbox
                label={getLocalizedText('반려')}
                checked={status.some((a) => a === 'REJECT')}
                onChange={(event) =>
                  handleChangeStatus({
                    checked: event.target.checked,
                    type: 'REJECT',
                  })
                }
              />
            </CheckboxGroup>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('기간')}>
            <fieldset className="eui-check-group">
              <div className="group">
                <Radio
                  label={getLocalizedText('전체기간')}
                  checked={date === undefined}
                  onChange={() => handleChangeDateType('all')}
                />
                <Radio
                  label={getLocalizedText('기간')}
                  checked={date !== undefined}
                  onChange={() => handleChangeDateType('range')}
                >
                  <div
                    style={{
                      display: 'inline-flex',
                      width: '230px',
                      alignItems: 'center',
                      marginRight: '8px',
                    }}
                  >
                    <div
                      style={{
                        background: 'var(--fill-color)',
                        borderRadius: '4px',
                        lineHeight: 1,
                        padding: '0 16px',
                        height: '36px',
                      }}
                    >
                      <CustomDatePicker
                        disabled={date === undefined}
                        placeholderText={getLocalizedText('연도.월.일')}
                        dateFormat="yyyy-MM-dd"
                        selectsStart
                        startDate={date?.start}
                        endDate={date?.end}
                        selected={date?.start}
                        onChange={handleChangeStartDate}
                      />
                    </div>
                    <div style={{ margin: '4px' }}>~</div>
                    <div
                      style={{
                        background: 'var(--fill-color)',
                        borderRadius: '4px',
                        lineHeight: 1,
                        padding: '0 16px',
                        height: '36px',
                      }}
                    >
                      <CustomDatePicker
                        disabled={date === undefined}
                        placeholderText={getLocalizedText('연도.월.일')}
                        dateFormat="yyyy-MM-dd"
                        selected={date?.end}
                        startDate={date?.start}
                        endDate={date?.end}
                        minDate={date?.start}
                        onChange={handleChangeEndDate}
                        selectsEnd
                      />
                    </div>
                  </div>
                </Radio>
              </div>
            </fieldset>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('제목')}>
            <TextField value={subject} onChange={handleChangeSubject} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('문서번호')}>
            <TextField value={documentNo} onChange={handleChangeDocumentNo} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('업무명')}>
            <TextField value={workName} onChange={handleChangeWorkName} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('양식명')}>
            <TextField value={formName} onChange={handleChangeFormName} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('기안자')}>
            <TextField value={drafterName} onChange={handleChangeDrafterName} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('기안부서')}>
            <TextField
              value={draftOrganizationName}
              onChange={handleChangeDraftOrganizationName}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('결재대상')}>
            <TextField
              value={approvalTargetName}
              onChange={handleChangeApprovalTargetName}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          // noDuplication
          icon="csv-logo"
          text={getLocalizedText('내보내기')}
          variant="contained"
          onClick={handleDownload}
        />
      </DialogFooter>
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </Dialog>
  );
}

export default ApprovalExcelDownloadDialog;
