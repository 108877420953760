import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { archiveActions } from '../../../../stores/approval/archive';
import Button from '../../../../../components/button/Button';
import PostWrite from '../../../../../components/post/PostWrite';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import Chip from '../../../../../components/chip/Chip';
import Menu from '../../../../../components/menu/Menu';
import MenuItem from '../../../../../components/menu/MenuItem';
import FeedBack from '../../../../../components/alert/FeedBack';
import PostView from '../../../../../components/post/PostView';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import { getAvatarPath } from '../../../../../groupware-common/utils';

function ApprovalArchivesTransferContentView(props: {
  selectedId: string;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const items = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const archiveAdministor = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );
  const principal = useSelector((state: RootState) => state.session.principal);
  const organizationView = useSelector(
    (state: RootState) => state.directory.organization.view.data,
  );
  const selectedId = parseInt(props.selectedId.split('_')[1], 10);

  const initialState = {
    modecheck: false,
    buttonVisible: false,
    list: organizationEmployees
      .filter(
        (a) =>
          a.companyId === principal.companyId &&
          a.id === selectedId &&
          a.employeeId !== organizationView?.managerId &&
          items.some(({ managerId }) => managerId === a.employeeId),
      )
      .map((a) => {
        const updateAt =
          items.find(({ managerId }) => managerId === a.employeeId)?.updateAt ??
          '1000-01-01';
        return {
          managerId: a.employeeId,
          managerName: getEmployeeName(a.companyId, a.employeeId),
          avatar: getAvatarPath(a.companyId, a.employeeId),
          updateAt,
        };
      }),
    userSelectPoint: undefined as
      | { x: number; y: number; width: number; height: number }
      | undefined,
    alertMessage: '',
    saveing: false,
  };

  const [state, setState] = useState(initialState);
  const { buttonVisible, list, userSelectPoint, saveing, alertMessage } = state;
  useEffect(() => {
    let mount = true;

    async function run() {
      const removeAdministrators = items
        .filter(
          (a) => list.find((b) => a.managerId === b.managerId) === undefined,
        )
        .map(({ managerId, updateAt }) => ({ managerId, updateAt }));

      const remove = await dispatch(
        archiveActions.adminRemove({
          data: removeAdministrators,
          organizationId: selectedId,
        }),
      );
      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const data = list
        .filter(
          (a) => items.find((b) => a.managerId === b.managerId) === undefined,
        )
        .map(({ managerId }) => ({ managerId }));

      const append = await dispatch(
        archiveActions.adminDesignate({
          data,
          organizationId: selectedId,
        }),
      );
      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));

        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        saveing: false,
        list: list.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { managerId: number; updateAt: string }[]).find(
              (b) => b.managerId === v.managerId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
      dispatch(archiveActions.check({ id: selectedId }));
    }
    if (saveing) run();
    return () => {
      mount = false;
    };
  }, [saveing]);

  useEffect(() => {
    setState(initialState);
  }, [selectedId]);

  const members = useMemo(() => {
    const data: { id: number; text: string; avatar: string; name: string }[] =
      [];
    const result = getDirectoryTreeItems({
      companies,
      organizationEmployees,
      employees,
    }).filter((a) => {
      return a.extra.organizationId === selectedId;
    });
    result.forEach((a) => {
      if (
        a.extra.type !== 'employee' ||
        a.extra.employeeId === organizationView?.managerId
      )
        return;
      data.push({
        id: a.extra.employeeId,
        text: a.text,
        avatar: a.avatar ?? '',
        name: a.extra.employeeName,
      });
    });
    return data;
  }, [companies, organizationEmployees, employees, selectedId]);

  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    if (event !== undefined && userSelectPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        userSelectPoint: undefined,
      }));
    }
    if (members.length === 0)
      setState((prev) => ({
        ...prev,
        alertMessage: getLocalizedText('조직에 사원이 존재하지 않습니다.'),
        userSelectPoint: undefined,
      }));
  };

  const handleAdministratorsAppend = (arg: {
    id: number;
    name: string;
    avatar: string;
  }) => {
    // 중복체크
    const duplication = list.some((k) => arg.id === k.managerId);
    if (duplication) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
      }));
      return;
    }

    const updateAt =
      archiveAdministor.find((a) => a.managerId === arg.id)?.updateAt ?? '';
    setState((prevState) => ({
      ...prevState,
      list: [
        ...prevState.list,
        {
          managerId: arg.id,
          managerName: arg.name,
          avatar: arg.avatar,
          updateAt,
        },
      ],
      buttonVisible: true,
    }));
  };
  const handleSave = async () => {
    setState((prevState) => ({
      ...prevState,
      saveing: true,
      buttonVisible: false,
    }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      alertMessage: '',
    }));
  };

  const handleManagerMenuClose = () => {
    setState((prevState) => ({ ...prevState, userSelectPoint: undefined }));
  };

  const handleAdministratorsDelete = (managerId: number) => {
    setState((prevState) => ({
      ...prevState,
      buttonVisible: true,
      list: prevState.list.filter((a) => a.managerId !== managerId),
    }));
  };

  const organizationManagerName =
    organizationView?.id === selectedId && organizationView?.managerId !== 0
      ? getEmployeeName(principal.companyId, organizationView.managerId)
      : getLocalizedText('미지정');

  const organizationName = selectedId
    ? getOrganizationName(
        principal.companyId,
        organizationView?.nameId ?? principal.companyId,
      )
    : getLocalizedText('');

  return (
    <>
      <EuiSetting.Header title={organizationName}>
        {buttonVisible && (
          <>
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              onClick={handleSave}
            />
            <Button
              text={getLocalizedText('취소')}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  buttonVisible: false,
                  list: initialState.list,
                  userSelectPoint: undefined,
                }))
              }
            />
          </>
        )}
      </EuiSetting.Header>
      <EuiBody>
        <PostWrite>
          <PostView>
            <PostView.Body>
              <PostView.Category type="text">
                <PostView.CategoryList>
                  <PostView.CategoryItem title={getLocalizedText('부서장')}>
                    <PostView.CategoryValue value={organizationManagerName} />
                  </PostView.CategoryItem>
                </PostView.CategoryList>
              </PostView.Category>
            </PostView.Body>
          </PostView>
          <PostWrite.Item
            title={getLocalizedText('관리자')}
            tooltip={getLocalizedText('부서장은 추가할 수 없습니다.')}
          >
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={handleDirectoryTreeMenuToggle}
            >
              {list.map(({ managerId, managerName, avatar }) => {
                return (
                  <Chip
                    key={managerId}
                    label={managerName}
                    avatar={avatar}
                    onDelete={() => handleAdministratorsDelete(managerId)}
                  />
                );
              })}

              {userSelectPoint && members.length !== 0 && (
                <Menu point={userSelectPoint} onClose={handleManagerMenuClose}>
                  {members.map((a) => (
                    <MenuItem
                      key={a.id}
                      label={a.text}
                      avatar={a.avatar}
                      onClick={() =>
                        handleAdministratorsAppend({
                          id: a.id,
                          avatar: a.avatar,
                          name: a.name,
                        })
                      }
                    />
                  ))}
                </Menu>
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
      </EuiBody>
      <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalArchivesTransferContentView;
