import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Button from '../../../../../components/button/Button';
import PostWrite from '../../../../../components/post/PostWrite';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import DropMenu from '../../../../../components/selectField/DropMenu';
import { getEmployeeName } from '../../../../../groupware-directory/stores/directory';
import {
  DirectoryTreeItem,
  getDirectoryTreeId,
} from '../../../../../components/tree/DirectoryTree';
import { DirectoryType } from '../../../../../groupware-common/types';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { surrogateApprovalActions } from '../../../../stores/approval/surrogate-approval';
import FeedBack from '../../../../../components/alert/FeedBack';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

function ApprovalApproverProxyContentEdit(props: {
  approverproxyData: {
    companyId: number;
    id: number;
    useSurrogate: boolean;
    surrogateStartDate: string;
    surrogateEndDate: string;
    surrogateEmployeeId: number;
    surrogateOrganizationId: number;
    updateAt: string;
  };
}): JSX.Element {
  const dispatch = useAppDispatch();
  // console.log(`${OrganizationChartContentList.name}.render`);
  const { approverproxyData } = props;

  let empName = ''; // 대결사용자
  let srgEmployeeName = ''; // 대결자
  if (approverproxyData.id !== 0)
    empName = getEmployeeName(
      approverproxyData.companyId,
      approverproxyData.id,
      '',
    );
  if (approverproxyData.surrogateEmployeeId !== 0)
    srgEmployeeName = getEmployeeName(
      approverproxyData.companyId,
      approverproxyData.surrogateEmployeeId,
      '',
    );

  const useLabel: { value: boolean; label: string }[] = [
    { value: true, label: getLocalizedText('사용함') },
    { value: false, label: getLocalizedText('사용안함') },
  ];

  const datalist = {
    savecheck: false,
    alertMessage: '',
    userSelectVisible: false,
    userSelectPoint: { x: 0, y: 0, width: 0, height: 0 },
    companyId: approverproxyData.companyId,
    employeeId: approverproxyData.id,
    employeeName: empName,
    useSurrogate: approverproxyData.useSurrogate && srgEmployeeName !== '',
    surrogateStartDate:
      approverproxyData.surrogateStartDate === '1000-01-01'
        ? null
        : timezoneDate(approverproxyData.surrogateStartDate),
    surrogateEndDate:
      approverproxyData.surrogateEndDate === '1000-01-01'
        ? null
        : timezoneDate(approverproxyData.surrogateEndDate),
    surrogateOrganizationId: approverproxyData.surrogateOrganizationId,
    surrogateEmployeeId: approverproxyData.surrogateEmployeeId,
    updateAt: approverproxyData.updateAt,
    surrogateEmployeeName: srgEmployeeName,
  };

  const [state, setState] = useState<{
    savecheck: boolean;
    alertMessage: string;
    userSelectVisible: boolean;
    userSelectPoint: { x: number; y: number; width: number; height: number };
    companyId: number;
    employeeId: number;
    employeeName: string;
    useSurrogate: boolean;
    surrogateStartDate: Date | null;
    surrogateEndDate: Date | null;
    surrogateOrganizationId: number;
    surrogateEmployeeId: number;
    surrogateEmployeeName: string;
    updateAt: string;
  }>(datalist);

  if (datalist.employeeId !== state.employeeId) {
    setState(datalist);
  }

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      alertMessage: '',
    }));
  };

  const handleDelete = () => {
    setState((prevState) => ({
      ...prevState,
      surrogateEmployeeId: 0,
      surrogateEmployeeName: '',
      savecheck: true,
    }));
  };

  const handleChangeActivate = (value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      useSurrogate: value,
      savecheck: true,
    }));
  };

  const handleChangeStartDate = (value: Date | null) => {
    let { surrogateEndDate } = state;
    if (value !== null && surrogateEndDate !== null)
      surrogateEndDate = value < surrogateEndDate ? surrogateEndDate : value;

    setState((prev) => ({
      ...prev,
      surrogateStartDate: value,
      surrogateEndDate,
      savecheck: true,
    }));
  };

  const handleChangeEndDate = (value: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      surrogateEndDate: value,
      savecheck: true,
    }));
  };

  const handleOpenUserSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.userSelectVisible) handleCloseUserSelect();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        userSelectVisible: true,
      }));
    }
  };

  const handleCloseUserSelect = () => {
    setState((prevState) => ({
      ...prevState,
      userSelectVisible: false,
    }));
  };

  const handleUserSelected = (arg: {
    item: DirectoryTreeItem;
    paths: {
      type: DirectoryType;
      id: number;
      name: string;
      treeId: string;
      text: string;
    }[];
  }) => {
    if (arg.item.extra.type === 'employee') {
      const parseData = arg.item.id.split('_');

      if (approverproxyData.id === Number(parseData[2])) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: getLocalizedText(
            '대결사용자를 대결자로 선택하실 수 없습니다.',
          ),
        }));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        surrogateOrganizationId: Number(parseData[1]),
        surrogateEmployeeId: Number(parseData[2]),
        surrogateEmployeeName: arg.item.text,
        savecheck: true,
      }));
    }

    handleCloseUserSelect();
  };

  const handleSave = () => {
    const {
      employeeId,
      useSurrogate,
      surrogateStartDate,
      surrogateEndDate,
      surrogateOrganizationId,
      surrogateEmployeeId,
      surrogateEmployeeName,
      updateAt,
    } = state;

    if (useSurrogate) {
      if (surrogateEmployeeName === '') {
        setState((prevState) => ({
          ...prevState,
          alertMessage: getLocalizedText(
            '대결사용을 선택하신 경우, 대결자를 반드시 지정하셔야 합니다.',
          ),
        }));
        return;
      }
      if (surrogateStartDate === null || surrogateEndDate === null) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: getLocalizedText(
            '대결사용을 선택하신 경우, 대결지정기간을 반드시 지정하셔야 합니다.',
          ),
        }));
        return;
      }
    }
    const arg = {
      employeeId,
      useSurrogate,
      surrogateStartDate:
        surrogateStartDate === null
          ? '1000-01-01'
          : dateFormat(initialDate(surrogateStartDate), 'YYYY-MM-DD'),
      surrogateEndDate:
        surrogateEndDate === null
          ? '1000-01-01'
          : dateFormat(initialDate(surrogateEndDate), 'YYYY-MM-DD'),
      surrogateEmployeeId,
      surrogateOrganizationId,
      updateAt,
    };

    dispatch(surrogateApprovalActions.save(arg)).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const payload = result.payload as { updateAt: string };
        setState((prevState) => ({
          ...prevState,
          savecheck: false,
          updateAt: payload.updateAt,
        }));
      }
    });
  };

  const handleCancle = () => {
    setState(datalist);
  };

  const {
    savecheck,
    companyId,
    employeeName,
    userSelectVisible,
    userSelectPoint,
    useSurrogate,
    surrogateOrganizationId,
    surrogateEmployeeId,
    surrogateEmployeeName,
    alertMessage,
  } = state;

  const { surrogateStartDate, surrogateEndDate } = state;
  return (
    <>
      <EuiSetting.Header title={getLocalizedText('대결자지정')}>
        {savecheck && (
          <>
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              onClick={handleSave}
            />
            <Button text={getLocalizedText('취소')} onClick={handleCancle} />
          </>
        )}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('대결사용자')}>
            <PostWrite.Value>{employeeName}</PostWrite.Value>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('대결지정')}>
            <RadioGroup
              data={useLabel}
              value={useSurrogate}
              name="use"
              onChange={handleChangeActivate}
            />
          </PostWrite.Item>
          {useSurrogate && (
            <>
              <PostWrite.Item title={getLocalizedText('대결지정기간')}>
                <PostWrite.ItemArea>
                  <CustomDatePicker
                    placeholderText={getLocalizedText('시작일')}
                    selected={surrogateStartDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date: Date) => handleChangeStartDate(date)}
                    selectsStart
                    startDate={surrogateStartDate}
                    endDate={surrogateEndDate}
                    width={120}
                  />
                  <CustomDatePicker
                    placeholderText={getLocalizedText('종료일')}
                    selected={surrogateEndDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date: Date) => handleChangeEndDate(date)}
                    selectsEnd
                    startDate={surrogateStartDate}
                    endDate={surrogateEndDate}
                    minDate={surrogateStartDate}
                    popperPlacement="bottom-end"
                    width={120}
                  />
                </PostWrite.ItemArea>
              </PostWrite.Item>
              <PostWrite.Item title={getLocalizedText('대결자')}>
                <PostWrite.ItemArea>
                  <DropMenu
                    value={surrogateEmployeeName}
                    label={getLocalizedText('대결자 선택')}
                    pressed={userSelectVisible}
                    onClick={(event) => handleOpenUserSelect(event)}
                  />
                  {surrogateEmployeeId !== 0 && (
                    <Button
                      text={getLocalizedText('삭제')}
                      iconType
                      icon="trash-fill"
                      onClick={handleDelete}
                    />
                  )}
                </PostWrite.ItemArea>
                {userSelectVisible && (
                  <DirectoryMenuTreeContainer
                    point={userSelectPoint}
                    selectedId={
                      surrogateEmployeeName === ''
                        ? undefined
                        : getDirectoryTreeId(
                            companyId,
                            surrogateOrganizationId,
                            surrogateEmployeeId,
                          )
                    }
                    typeToFilter="employee"
                    onItemClick={handleUserSelected}
                    onClose={handleCloseUserSelect}
                  />
                )}
              </PostWrite.Item>
            </>
          )}
        </PostWrite>
        <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
      </EuiSetting.Content>
    </>
  );
}

export default ApprovalApproverProxyContentEdit;
