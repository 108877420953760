import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { documentApi } from '../../../../../groupware-approval/apis/approval/v1/document';
import ApprovalPrint from '../../../../../groupware-approval/pages/common/components/ApprovalPrint';
import ApprovalDocumentInfoDrawer from '../../../../../groupware-approval/pages/common/drawers/ApprovalDocumentInfoDrawer';
import ApprovalStatusDrawer from '../../../../../groupware-approval/pages/common/drawers/ApprovalStatusDrawer';
import ApprovalAttachedDocumentDialog from '../../../../../groupware-approval/pages/root/approval/content/ApprovalAttachedDocumentDialog';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { attendancesActions } from '../../../../stores/attendance/attendances';
import AttendanceContentBodyList from './AttendanceContentBodyList';
import AttendanceContentBodyView from './AttendanceContentBodyView';
import AttendanceContentHeadList from './AttendanceContentHeadList';
import AttendanceContentHeadView from './AttendanceContentHeadView';

export function formatStatus(status: number): string {
  let data = '';
  if (status === 1) data = 'proceeding';
  else if (status === 3) data = 'done';
  else if (status === 4) data = 'return';
  else if (status === 44) data = 'cancel';
  return data;
}

function AttendanceContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const pathParams = getPathParams<{ p1?: string; p2?: number }>(
    `/*/:p1/:p2$base62`,
    pathname,
  );
  const propsFolderId = pathParams.p1;
  const propsItemId = pathParams.p2;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const teamsHostClientType = useSelector(
    (state: RootState) => state.session.teamsHostClientType,
  );
  const isMobileTeams =
    teamsHostClientType &&
    (teamsHostClientType === 'android' || teamsHostClientType === 'ios');
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const readingPaneMode = useSelector((state: RootState) =>
    display === 'phone' ? 'list' : state.attendance.attendances.readingPaneMode,
  );
  /** 분할 모드일 경우. */
  const isSplitView =
    readingPaneMode === 'horizontal' || readingPaneMode === 'vertical';
  const displayDensity = useSelector(
    (state: RootState) => state.attendance.attendances.displayDensity,
  );

  const folders = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  );
  const list = useSelector(
    (state: RootState) => state.attendance.attendances.list,
  );
  const view = useSelector((state: RootState) =>
    propsItemId ? state.attendance.attendances.view.data : undefined,
  );
  const attendanceCode = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );

  function formatApplyType(useType: number) {
    const attendance = attendanceCode.find((a) => a.id === useType);
    if (attendance) return attendance.name;
    return '';
  }

  const items = list.items.map((a) => {
    const status = formatStatus(a.status);
    const applyType = formatApplyType(a.useType);

    if (display === 'phone')
      return {
        checked: a.checked,
        companyId: a.companyId,
        applyId: a.useId,
        approvalId: a.approvalId,
        applyType,
        subject: a.subject,
        startDate: `${a.startDate} ~ ${a.endDate}`,
        useMinutes: a.useMinutes,
        createAt: a.createAt,
        status,
      };

    return {
      checked: a.checked,
      companyId: a.companyId,
      applyId: a.useId,
      approvalId: a.approvalId,
      applyType,
      subject: a.subject,
      startDate: a.startDate,
      endDate: a.endDate,
      useMinutes: a.useMinutes,
      createAt: a.createAt,
      status,
    };
  });

  const [state, setState] = useState<{
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
  }>({
    dialogs: [],
  });

  const [defaultColumns, setDefaultColumns] = useState<
    Column<PostListItemType>[]
  >([
    { name: 'checked', text: '', type: 'action', visible: true },
    {
      name: 'applyType',
      text: getLocalizedText('구분'),
      type: 'folder',
      visible: true,
    },
    {
      name: 'startDate',
      text: getLocalizedText('시작날짜'),
      type: 'date',
      visible: true,
    },
    {
      name: 'endDate',
      text: getLocalizedText('종료날짜'),
      type: 'date',
      visible: true,
    },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: getLocalizedText('제목') , type: 'post', visible: true, disable: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'drafter', text: getLocalizedText('기안자') , type: 'user', visible: display === 'phone' },
    {
      name: 'useMinutes',
      text: getLocalizedText('사용'),
      type: 'folder',
      visible: true,
    },
    {
      name: 'createAt',
      text: getLocalizedText('작성일'),
      type: 'date',
      visible: true,
    },
    {
      name: 'status',
      text: getLocalizedText('결재상태'),
      type: 'status',
      visible: true,
    },
  ]);

  /** 리스트 아이템 모두 체크 변경. */
  const handleListItemCheckedChange = (itemId: 'all', checked: boolean) => {
    dispatch(attendancesActions.checked({ itemId, checked }));
  };

  /** 리스트 아이템 체크 변경. */
  const handleItemCheckedChange = (id: number, checked: boolean) => {
    dispatch(attendancesActions.checked({ itemId: id, checked }));
  };

  /** 검색. */
  const handleSearch = (arg: {
    code: string;
    word?: string;
    status?: string;
    date: {
      start: Date | null;
      end: Date | null;
    };
  }) => {
    let searchPath = pathname;
    if (isSplitView) searchPath = `${getPathMap('/*/*', pathname)}`;
    const { code, word, status, date } = arg;

    delete queryParams.pageNo;
    queryParams.startDate = date.start
      ? dateFormat(initialDate(date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = date.end
      ? dateFormat(initialDate(date.end), 'yyyy-MM-DD')
      : undefined;
    queryParams.searchCode = code;
    queryParams.searchWord = word ?? status;

    dispatch(sessionActions.search(getQueryParams(queryParams)));
    go(searchPath, getQueryParams(queryParams));
  };

  /** 목록 이동. */
  const handleListGo = () => {
    go(`${getPathMap('/*/*', pathname)}`, search);
  };

  /** 문서 저장. */
  const download = (blob: Blob) => {
    if (!blob)
      throw new Error(
        getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
      );

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  /** 헤드 리스트 액션. */
  const handleHeadAction = (arg: {
    code: string;
    event: React.MouseEvent<Element, MouseEvent>;
  }) => {
    const { code } = arg;

    let searchPath = pathname;
    if (isSplitView) searchPath = `${getPathMap('/*/*', pathname)}`;

    // 목록 설정 드로워.
    if (code === 'listSetting')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 이전 페이지
    if (code === 'prevPage') {
      const pageNo = (queryParams.pageNo ?? 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(searchPath, queryParams);
      }
    }
    if (code === 'nextPage') {
      const total = list.totalCount;
      const pageNo = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * pageNo) < total) {
        queryParams.pageNo = pageNo;
        go(searchPath, queryParams);
      }
    }
    if (code === 'prints') dispatch(sessionActions.setDialog({ type: code }));
    if (code === 'save') {
      const param = list.items
        .filter((a) => a.checked)
        .map((a) => {
          const affiliateCompanyId =
            a.companyId !== principal.companyId ? a.companyId : undefined;
          return {
            id: a.approvalId,
            affiliateCompanyId,
          };
        });

      if (param.length > 0)
        documentApi
          .download(param)
          .then(download)
          .catch(() => {
            dispatch(
              sessionActions.error(
                appError({
                  error: getLocalizedText('저장할 수 없습니다.'),
                }),
              ),
            );
          });
    }
  };

  /** 헤드 뷰 액션 */
  const handleHeadViewAction = (arg: {
    code: string;
    event: React.MouseEvent;
  }) => {
    const { code: type } = arg;
    // 문서 정보 드로워 열기.
    if (type === 'info') dispatch(sessionActions.setDrawer({ type }));
    // 결재 현황 드로워 열기.
    else if (type === 'status') dispatch(sessionActions.setDrawer({ type }));
  };

  /** 리스트 아이템 클릭 - view 화면 이동. */
  const handleListItemClick = (id: number) => {
    const searchPath = `${getPathMap(`/*/*`, pathname)}/${b62(id)}`;
    if (isSplitView) {
      const route = {
        pathname: searchPath,
        search,
      };
      dispatch(attendancesActions.view({ id, route }));
    } else go(searchPath, getQueryParams(queryParams));
  };

  /** 화면 밀도 설정. */
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(attendancesActions.displayDensity(value));
  };

  /** 목록 개수 설정. */
  const handleChangeListCount = (value: string) => {
    const rowsPerPage = parseInt(value, 10);
    go(
      `${getPathMap('/*/*', pathname)}`,
      createQueryString(
        {
          pageNo: undefined,
          rowsPerPage: rowsPerPage === 15 ? undefined : rowsPerPage,
          drawerMode: undefined,
          drawerType: undefined,
        },
        queryParams,
      ),
    );
  };

  /** 읽기 창 모드 변경. */
  const handleReadingPaneModeChange = (
    mode: 'list' | 'vertical' | 'horizontal',
  ) => {
    if (mode === 'list') {
      const location = utils.getLocation({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          mode: 'replace',
        },
      });
      dispatch(sessionActions.setRoute(location));
    }
    dispatch(attendancesActions.setReadingPaneMode(mode));
  };

  // 컬럼 보이기 변경.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setDefaultColumns((prevState) =>
      prevState.map((x) => (x.name === name ? { ...x, visible } : x)),
    );
  };

  // 첨부문서 대화상자 열기
  const handleAttachedDocumentDialog = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument'; // 부모 문서, 첨부 문서
    documentId: number;
    affiliateCompanyId?: number | undefined;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      dialogType,
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'parentdocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
            receiptDocumentId,
            receiptAffiliatedCompanyId,
          },
        ],
      }));
    }
    if (dialogType === 'attacheddocument') {
      // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
      if (state.dialogs.length === 0 && view) {
        setState((prev) => ({
          ...prev,
          dialogs: [
            {
              dialogType: 'attacheddocument',
              documentId: view.id,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [
            ...prev.dialogs,
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      }
    }
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  /** 리스트 아이템 한 개 이상 체크되어있을 경우 - 체크박스 범위 확장 */
  if (list.items.filter((x) => x.checked).length) {
    document.documentElement.setAttribute('data-selecting', 'true');
  } else {
    document.documentElement.removeAttribute('data-selecting');
  }

  const renderHead = (arg?: { type: 'list' | 'view' }) => {
    const type =
      arg?.type || (readingPaneMode === 'list' && propsItemId)
        ? 'view'
        : 'list';
    if (type === 'view') {
      if (!view) return null;

      const toolbarButtons: ActionEventProps[] = [
        {
          code: 'info',
          label: getLocalizedText('문서정보'),
          type: 'text',
          icon: 'info-circle',
        },
        {
          code: 'status',
          label: getLocalizedText('결재현황'),
          type: 'text',
          icon: 'line-dot',
        },
      ];

      if (readingPaneMode === 'vertical' || readingPaneMode === 'horizontal')
        return (
          <AttendanceContentHeadView
            type="split"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleHeadViewAction}
          />
        );
      return (
        <AttendanceContentHeadView
          type="full"
          onListGo={handleListGo}
          toolbarButtons={toolbarButtons}
          onToolbarAction={handleHeadViewAction}
        />
      );
    }

    const title = folders.find(({ id }) => id === propsFolderId)?.name ?? '';
    let toolbarButtons: ActionEventProps[];

    if (propsFolderId === 'detail' && !isMobileTeams)
      toolbarButtons = [
        {
          code: 'prints',
          label: getLocalizedText('인쇄'),
          type: 'icon',
          icon: 'print',
        },
        {
          code: 'save',
          label: getLocalizedText('저장'),
          type: 'icon',
          icon: 'save',
        },
      ];
    else toolbarButtons = [];

    const searchWord =
      queryParams.searchCode === 'subject' ? queryParams.searchWord : undefined;
    const status =
      queryParams.searchCode === 'status' || queryParams.searchCode === 'code'
        ? queryParams.searchWord
        : undefined;

    return (
      <AttendanceContentHeadList
        attendanceCode={attendanceCode}
        title={getLocalizedText(`폴더.${title}`)}
        pageNo={queryParams.pageNo ?? 1}
        rowsPerPage={queryParams.rowsPerPage ?? 15}
        totalCount={list.totalCount}
        checkedCount={list.items.filter((x) => x.checked).length}
        onCheckedChange={handleListItemCheckedChange}
        toolbarButtons={toolbarButtons}
        searchCode={queryParams.searchCode}
        searchWord={searchWord}
        startDate={queryParams.startDate}
        endDate={queryParams.endDate}
        status={status}
        onSearch={handleSearch}
        onAction={handleHeadAction}
      />
    );
  };

  useEffect(() => {
    setDefaultColumns(
      defaultColumns.map((a) => {
        if (a.name === 'startDate') {
          return {
            ...a,
            type: display === 'phone' ? 'folder' : 'date',
          };
        }
        if (a.name === 'endDate') {
          return {
            ...a,
            visible: display !== 'phone',
          };
        }
        if (a.name === 'drafter') {
          return {
            ...a,
            visible: display === 'phone',
          };
        }
        return a;
      }),
    );
  }, [display]);

  const renderBody = () => {
    let result = <div>{getLocalizedText('근태 상세 현황 페이지 - BODY')}</div>;
    const listComponent: JSX.Element = (
      <AttendanceContentBodyList
        folderId={propsFolderId}
        pageNo={queryParams.pageNo}
        rowsPerPage={queryParams.rowsPerPage}
        searchCode={queryParams.searchCode}
        searchWord={queryParams.searchWord}
        type={readingPaneMode === 'vertical' ? 'split' : 'full'}
        density={displayDensity}
        columns={
          readingPaneMode === 'list' || readingPaneMode === 'horizontal'
            ? defaultColumns.filter(({ visible }) => visible)
            : defaultColumns
        }
        items={items}
        selectedId={propsItemId}
        onItemClick={handleListItemClick}
        onItemCheckedChange={handleItemCheckedChange}
      />
    );
    if (propsFolderId) {
      switch (readingPaneMode) {
        // 좌우분할.
        case 'vertical':
          if (!list) return null;
          result = (
            <EuiBody>
              <SplitPane
                className="ui-split eui-post"
                split="vertical"
                minSize={344}
                maxSize={560}
                defaultSize={parseInt(
                  localStorage.getItem('approval-split-vertical-sizes') ||
                    '400',
                  10,
                )}
                onChange={(size) =>
                  localStorage.setItem(
                    'approval-split-vertical-sizes',
                    `${size}`,
                  )
                }
              >
                {listComponent}
                <>
                  {renderHead({ type: 'view' })}
                  <AttendanceContentBodyView
                    pathname={pathname}
                    type="split"
                    view={view}
                    onDraftDocumentPopup={handleDraftDocumentDialog}
                    onAttachedDocumentPopup={handleAttachedDocumentDialog}
                  />
                </>
              </SplitPane>
            </EuiBody>
          );
          break;

        // 상하 분할.
        case 'horizontal':
          if (!list) return null;
          result = (
            <EuiBody>
              <SplitPane
                className="ui-split eui-post"
                split="horizontal"
                minSize={150}
                defaultSize={parseInt(
                  localStorage.getItem('approval-split-horizontal-sizes') ||
                    '250',
                  10,
                )}
                onChange={(size) =>
                  localStorage.setItem(
                    'approval-split-horizontal-sizes',
                    `${size}`,
                  )
                }
              >
                {listComponent}
                <>
                  {renderHead({ type: 'view' })}
                  <AttendanceContentBodyView
                    pathname={pathname}
                    type="split"
                    view={view}
                    onDraftDocumentPopup={handleDraftDocumentDialog}
                    onAttachedDocumentPopup={handleAttachedDocumentDialog}
                  />
                </>
              </SplitPane>
            </EuiBody>
          );
          break;
        // 분할 안함.
        default:
          // 뷰인 경우.
          if (propsItemId !== undefined) {
            result = (
              <EuiBody>
                <AttendanceContentBodyView
                  pathname={pathname}
                  type="full"
                  view={view}
                  onDraftDocumentPopup={handleDraftDocumentDialog}
                  onAttachedDocumentPopup={handleAttachedDocumentDialog}
                />
              </EuiBody>
            );
          } else {
            result = <EuiBody>{listComponent}</EuiBody>;
          }
          break;
      }
    }

    return result;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;
    if (drawerType === 'listSetting')
      return (
        <ListSetting
          type={readingPaneMode}
          columns={defaultColumns.filter(
            ({ name }) => name !== 'checked' && name !== 'drafter',
          )}
          onColumnVisibleChange={handleColumnVisibleChange}
          readingPaneMode={readingPaneMode}
          onReadingPaneModeChange={handleReadingPaneModeChange}
          density={displayDensity}
          onChangeDensity={handleChangeDensity}
          listCount={`${queryParams.rowsPerPage ?? 15}`}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
        />
      );
    // 문서정보
    if (drawerType === 'info') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalDocumentInfoDrawer
          view={{ ...view, draftAt: view.draftAt }}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 결재현황
    if (drawerType === 'status') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalStatusDrawer
          language="ko-KR"
          view={{ ...view, content: view.contents, draftAt: view.draftAt }}
          approvalLine={view.approvalLine}
          complete={view.completeAt !== undefined}
          onClose={handleCloseDrawer}
        />
      );
    }
    return null;
  };

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  /** dialog 닫기 */
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId, affiliateCompanyId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument' || dialogType === 'draftdocument') {
      if (state.dialogs.length > 2 || dialogType === 'draftdocument') {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  // 품의서 대화상자 열기
  const handleDraftDocumentDialog = (documentId: number) => {
    setState((prev) => ({
      ...prev,
      dialogs: [
        ...prev.dialogs,
        {
          dialogType: 'draftdocument',
          documentId,
          affiliatedCompanyId: 0,
        },
      ],
    }));
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    if (dialogType === 'prints') {
      let listId:
        | {
            id: number;
            updateAt: string;
          }[]
        | undefined;
      if (dialogType === 'prints') {
        listId = list.items
          .filter((x) => x.checked)
          .map((a) => {
            return {
              id: a.approvalId,
              updateAt: a.updateAt,
            };
          });
      }
      if (listId === undefined) return null;
      return (
        <ApprovalPrint
          onClose={handleDialogClose}
          listId={listId}
          folderId={0}
        />
      );
    }

    const { dialogs } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default AttendanceContentContainer;
