import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 초기 대시보드 */
async function findInitial(arg: {
  companyId: number;
  employeeId: number;
}): Promise<{
  id: number;
  seq: number;
  name: string;
  isAdminPortal: boolean;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/initial`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 리스트 */
async function findList(arg: {
  companyId: number;
  employeeId: number;
}): Promise<
  {
    id: number;
    seq: number;
    name: string;
    isAdminPortal: boolean;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, employeeId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 보기 */
async function findView(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
}): Promise<{
  id: number;
  name: string;
  seq: number;
  isAdminPortal: boolean;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId, portalId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 초기 포탈 수정 */
async function updateInitial(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
}): Promise<boolean> {
  try {
    const { companyId, employeeId, portalId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}/initial`;
    const response = await axios.put(
      url,
      {},
      {
        headers,
      },
    );
    return response.status === 200;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 포탈 추가 */
async function createPortal(arg: {
  companyId: number;
  employeeId: number;
  name: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId, name } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals`;
    const response = await axios.post(
      url,
      {
        name,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 포탈 변경 */
async function updatePortal(arg: {
  companyId: number;
  employeeId: number;
  portalId: number;
  name: string;
  updateAt: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId, portalId, name, updateAt } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${portalId}`;
    const response = await axios.put(
      url,
      {
        id: portalId,
        name,
        updateAt,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 포탈 변경 */
async function sortPortal(arg: {
  companyId: number;
  employeeId: number;
  data: { id: number; seq: number; companyId: number; updateAt: string }[];
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const { companyId, employeeId, data } = arg;
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/sequence/all`;
    const response = await axios.put(url, data, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 대시보드 포탈 삭제 */
async function deletePortal(arg: {
  companyId: number;
  employeeId: number;
  id: number;
  updateAt: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId, id, updateAt } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/dashboard/v1/companies/${companyId}/employees/${employeeId}/portals/${id}`;
    const response = await axios.delete(url, {
      headers,
      data: {
        id,
        updateAt,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 관리자 - 대시보드 포탈 변경 */
async function updateAdminPortal(arg: {
  companyId: number;
  portalId: number;
  name: string;
  updateAt: string;
}): Promise<{
  id: number;
  updateAt: string;
}> {
  try {
    const { companyId, portalId, name, updateAt } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals/${portalId}`;
    const response = await axios.put(
      url,
      {
        id: portalId,
        name,
        updateAt,
      },
      {
        headers,
      },
    );
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 관리자 - 대시보드 포탈 보기 */
async function findAdminPortalView(arg: { companyId: number }): Promise<{
  id: number;
  name: string;
  seq: number;
  isAdminPortal: boolean;
  updateAt: string;
}> {
  try {
    const { companyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/dashboard/v1/companies/${companyId}/portals`;
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const portalApi = {
  findList,
  view: findView,
  createPortal,
  updatePortal,
  sortPortal,
  deletePortal,
  findInitial,
  updateInitial,
};
export const adminPortalApi = {
  view: findAdminPortalView,
  updatePortal: updateAdminPortal,
};

export default portalApi;
