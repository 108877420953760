import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function ApprovalArchivesAdminstorRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    const route = { pathname, search, hash };
    if (mount) dispatch(sessionActions.setRoute(route));

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);
  return null;
}

export default ApprovalArchivesAdminstorRoute;
