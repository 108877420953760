import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Loading from '../../../../../components/loading/Loading';
import PostView from '../../../../../components/post/PostView';
import { RootState } from '../../../../../groupware-webapp/app/store';
import securitiesApi from '../../../../apis/sangsanginsecurities/v1/securities';

interface TaxReceiptItem {
  taxDvcd: string; // 세금 계산서 종류
  billseq: string; // 키값
  dt: string; // 날짜
  reportIssueId: string; // 승인번호
  svenderno: string; // 공급자 사업자번호
  scompany: string; // 공급자 상호
  sceoname: string; // 공급자 대표명
  saddress: string; // 공급자 주소
  suptae: string; // 공급자 업태
  supjong: string; // 공급자 업종
  semail: string; // 공급자 이메일
  rvenderno: string; // 공급받는자 사업자번호
  rcompany: string; // 공급받는자 회사명
  rceoname: string; // 공급받는자 대표명
  raddress: string; // 공급받는자 주소
  ruptae: string; // 공급받는자 업태
  rupjong: string; // 공급받는자 업종
  remail: string; // 공급받는자 이메일
  supmoney: string; // 공급가
  taxmoney: string; // 세액
  totmoney: string; // 총액
  cash: string; // 현금
  checks: string; // 수표
  note: string; // 어음
  credit: string; // 외상미수금
  reportAmendCd: string; // 수정사유코드
  amendNm: string; // 수정사유
  bigo: string; // 비고
  cyGubun: string; // 청구영수구분
  items: {
    itDt: string; // 날짜
    itMonth: string; // 달
    itDay: string; // 일
    obj: string; // 품목
    unit: string; // 규격
    vlm: string; // 수량
    danga: string; // 단가
    sup: string; // 공급가액
    tax: string; // 세액
    remark: string; // 비고
  }[]; // 항목
}

function SecuritiesDocumentDialog(props: {
  billseq: string;
  onClose(): void;
}): JSX.Element {
  const { billseq, onClose } = props;
  const principal = useSelector((state: RootState) => state.session.principal);

  // 숫자 데이터 포맷.
  const addNumberToLocale = (value: number | null): string => {
    return value !== null ? value.toLocaleString() : '';
  };

  const [state, setState] = useState<{
    data?: TaxReceiptItem;
    loading: boolean;
  }>({ loading: true });

  useEffect(() => {
    async function run() {
      const result = await securitiesApi.taxReceipts({
        companyId: principal.companyId,
        employeeId: principal.employeeId,
        billseq,
      });
      setState({
        data: {
          ...result,
          supmoney: addNumberToLocale(result.supmoney),
          taxmoney: addNumberToLocale(result.taxmoney),
          note: addNumberToLocale(result.note),
          cash: addNumberToLocale(result.cash),
          checks: addNumberToLocale(result.checks),
          totmoney: addNumberToLocale(result.totmoney),
          credit: addNumberToLocale(result.credit),
          reportAmendCd:
            result.reportAmendCd === null ? '' : result.reportAmendCd,
          amendNm: result.amendNm === null ? '' : result.amendNm,
          items: result.items.map((a) => ({
            ...a,
            obj: a.obj === null ? '' : a.obj,
            unit: a.unit === null ? '' : a.unit,
            vlm: a.vlm === null ? '' : a.vlm,
            danga: a.danga === null ? '' : a.danga,
            sup: addNumberToLocale(a.sup), // 공급가액
            tax: addNumberToLocale(a.tax), // 세액
            remark: a.remark === null ? '' : a.remark,
          })),
        },
        loading: false,
      });
    }

    run();
  }, []);

  const { data, loading } = state;
  if (loading || data === undefined)
    return (
      <Dialog onClose={onClose}>
        <DialogHeader>
          <DialogTitle>세금계산서</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostView>
            <Loading />
            <EmptyData />
          </PostView>
        </DialogBody>
      </Dialog>
    );

  const renderTable = () => {
    const itemsTable = data.items.map((a, i) => {
      const key = `${a.obj}_${i}`;
      return (
        <tr key={key}>
          <td>{a.itMonth}</td>
          <td>{a.itDay}</td>
          <td className="text">{a.obj}</td>
          <td>{a.unit}</td>
          <td>{a.vlm}</td>
          <td className="number">{a.danga}</td>
          <td className="number">{a.sup}</td>
          <td className="number">{a.tax}</td>
          <td>{a.remark}</td>
        </tr>
      );
    });

    if (data.items.length < 4) {
      for (let i = 0; i < 4 - data.items.length; i += 1) {
        itemsTable.push(
          <tr key={data.reportIssueId}>
            <td />
            <td />
            <td className="text" />
            <td />
            <td />
            <td className="number" />
            <td className="number" />
            <td className="number" />
            <td />
          </tr>,
        );
      }
    }

    return itemsTable;
  };
  return (
    <Dialog size="lg" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>세금계산서</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostView>
          <div className="tax-dialog">
            {/* header */}
            <div className="group1">
              <div className="title">{data.taxDvcd}</div>
              <div className="approval-number">
                <div className="name">승인번호</div>
                <div className="value">{data.reportIssueId}</div>
              </div>
            </div>
            {/*  공급자, 공급받은 자 */}
            <div className="group2">
              <div className="provider">
                <div className="title">
                  공<br />급<br />자
                </div>
                <div className="information">
                  <table>
                    <colgroup>
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '19%' }} />
                      <col style={{ width: '31%' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          등록
                          <br />
                          번호
                        </th>
                        <td className="cell-td">{data.svenderno}</td>
                        <th className="cell-th" scope="row">
                          종사업장
                          <br />
                          번호
                        </th>
                        <td className="cell-td" />
                      </tr>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          상호
                          <br />
                          (법인명)
                        </th>
                        <td className="cell-td">{data.scompany}</td>
                        <th className="cell-th" scope="row">
                          성명
                        </th>
                        <td className="cell-td">{data.sceoname}</td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          사업장
                          <br />
                          주소
                        </th>
                        <td className="cell-td" colSpan={3}>
                          {data.saddress}
                        </td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          업태
                        </th>
                        <td className="cell-td">{data.suptae}</td>
                        <th className="cell-th" scope="row">
                          종목
                        </th>
                        <td className="cell-td">{data.supjong}</td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th email" scope="row">
                          이메일
                        </th>
                        <td className="cell-td" colSpan={3}>
                          {data.semail}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="recipient">
                <div className="title">
                  공<br />급<br />받<br />는<br />자
                </div>
                <div className="information">
                  <table>
                    <colgroup>
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '19%' }} />
                      <col style={{ width: '31%' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          등록
                          <br />
                          번호
                        </th>
                        <td className="cell-td">{data.rvenderno}</td>
                        <th className="cell-th" scope="row">
                          종사업장
                          <br />
                          번호
                        </th>
                        <td className="cell-td" />
                      </tr>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          상호
                          <br />
                          (법인명)
                        </th>
                        <td className="cell-td">{data.rcompany}</td>
                        <th className="cell-th" scope="row">
                          성명
                        </th>
                        <td className="cell-td">{data.rceoname}</td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          사업장
                          <br />
                          주소
                        </th>
                        <td className="cell-td" colSpan={3}>
                          {data.raddress}
                        </td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th" scope="row">
                          업태
                        </th>
                        <td className="cell-td">{data.ruptae}</td>
                        <th className="cell-th" scope="row">
                          종목
                        </th>
                        <td className="cell-td">{data.rupjong}</td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th email" scope="row">
                          이메일
                        </th>
                        <td className="cell-td" colSpan={3}>
                          {data.remail}
                        </td>
                      </tr>
                      <tr className="cell">
                        <th className="cell-th email" scope="row">
                          이메일
                        </th>
                        <td className="cell-td" colSpan={3} />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* 작성일자 */}
            <div className="group3">
              <div className="textBox">
                <div className="header">작성일자</div>
                <div className="bottom">{data.dt}</div>
              </div>
              <div className="textBox">
                <div className="header">공급가액</div>
                <div className="bottom number">{data.supmoney}</div>
              </div>
              <div className="textBox">
                <div className="header">세액</div>
                <div className="bottom number">{data.taxmoney}</div>
              </div>
              <div className="textBox">
                <div className="header">수정사유</div>
                <div className="bottom">{data.amendNm}</div>
              </div>
              <div className="textBox wide">
                <div className="header">비고</div>
                <div className="bottom">{data.bigo}</div>
              </div>
            </div>
            {/* 품목  minLength: 4 ~ maxLength: 16 */}
            <div className="group4">
              <table>
                <thead>
                  <tr>
                    <th>월</th>
                    <th>일</th>
                    <th>품목</th>
                    <th>규격</th>
                    <th>수량</th>
                    <th>단가</th>
                    <th>공급가액</th>
                    <th>세액</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>{renderTable()}</tbody>
              </table>
            </div>
            {/*  bottom */}
            <div className="group3">
              <div className="textBox">
                <div className="header">합계금액</div>
                <div className="bottom">{data.totmoney}</div>
              </div>
              <div className="textBox">
                <div className="header">현금</div>
                <div className="bottom">{data.cash}</div>
              </div>
              <div className="textBox">
                <div className="header">수표</div>
                <div className="bottom">{data.checks}</div>
              </div>
              <div className="textBox">
                <div className="header">어음</div>
                <div className="bottom">{data.note}</div>
              </div>
              <div className="textBox">
                <div className="header">외상미수금</div>
                <div className="bottom">{data.credit}</div>
              </div>
              <div className="textBox wide cyGubun">
                {`이 금액을 (${data.cyGubun})함`}
              </div>
            </div>
          </div>
        </PostView>
      </DialogBody>
    </Dialog>
  );
}

export default SecuritiesDocumentDialog;
