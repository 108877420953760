import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Radio from '../../../../../components/radio/Radio';
import TextField from '../../../../../components/textfield/TextField';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function AttendanceEnteringDayOffCountSetting(props: {
  isExists: boolean;
  data: {
    workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
    isAutoCalculate: boolean; // 자동 계산 여부 (false 시 지정된 개수 부여)
    fixedCounts: number; // 연차 지정 개수
    updateAt: string | null; // 수정 날짜/시작
    lookupDeleteAt?: string; // 삭제 시 updateAt.
  }[];
  onCancel: boolean;
  onChange(arg: {
    data: {
      workingYearCount: number;
      isAutoCalculate: boolean;
      fixedCounts: number | null;
      updateAt: string | null;
      lookupDeleteAt?: string | undefined;
    }[];
  }): void;
}): JSX.Element {
  const { isExists, data, onChange } = props;
  const display = useSelector((state: RootState) => state.session.display);
  const sliceData = data.slice(1);
  const initialState = {
    first: {
      ...data[0],
      fixedCounts: data[0].fixedCounts === 0 ? 11 : data[0].fixedCounts,
    },
    year: sliceData.map((a, i) => ({
      id: i,
      ...a,
    })),
    validation: '',
  };

  const [state, setState] = useState<{
    first: {
      workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
      isAutoCalculate: boolean; // 자동 계산 여부 (false 시 지정된 개수 부여)
      fixedCounts: number | null; // 연차 지정 개수
      updateAt: string | null; // 수정 날짜/시작
      lookupDeleteAt?: string; // 삭제 시 updateAt.
    };
    year: {
      id: number;
      workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
      isAutoCalculate: boolean; // 자동 계산 여부 (false 시 지정된 개수 부여)
      fixedCounts: number | null; // 연차 지정 개수
      updateAt: string | null; // 수정 날짜/시작
      lookupDeleteAt?: string; // 삭제 시 updateAt.
    }[];
    validation: string;
  }>(initialState);

  /** 1년차 연차설정 */
  const handleFirstTypeChange = (value: boolean) => {
    if (isExists) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText(
          '연차 생성 후 기본 설정 변경은 불가능합니다.',
        ),
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      first: {
        ...prev.first,
        isAutoCalculate: value,
        fixedCounts: value ? 0 : 11,
      },
    }));
    const change = [
      {
        ...state.first,
        isAutoCalculate: value,
        fixedCounts: value ? 0 : 11,
      },
      ...state.year.map((a) => ({
        workingYearCount: a.workingYearCount,
        isAutoCalculate: a.isAutoCalculate,
        fixedCounts: a.fixedCounts,
        updateAt: a.updateAt,
        lookupDeleteAt: a.lookupDeleteAt,
      })),
    ];
    onChange({ data: change });
  };

  /** 1년차 연차 개수 설정. */
  const handleFirstCountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isExists) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText(
          '연차 생성 후 기본 설정 변경은 불가능합니다.',
        ),
      }));
      return;
    }

    const count = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    setState((prev) => ({
      ...prev,
      first: {
        ...prev.first,
        fixedCounts: count === '' ? null : parseInt(count, 10),
      },
    }));
    const change = [
      {
        ...state.first,
        fixedCounts: count === '' ? null : parseInt(count, 10),
      },
      ...state.year.map((a) => ({
        workingYearCount: a.workingYearCount,
        isAutoCalculate: a.isAutoCalculate,
        fixedCounts: a.fixedCounts,
        updateAt: a.updateAt,
        lookupDeleteAt: a.lookupDeleteAt,
      })),
    ];
    onChange({ data: change });
  };

  /** 2년차 이상 연차 개수 설정. */
  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (isExists) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText(
          '연차 생성 후 기본 설정 변경은 불가능합니다.',
        ),
      }));
      return;
    }

    const count = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    setState((prev) => ({
      ...prev,
      year: state.year.map((a) => {
        if (a.id === id)
          return {
            ...a,
            fixedCounts: count === '' ? null : parseInt(count, 10),
          };
        return a;
      }),
    }));
    const change = [
      state.first,
      ...state.year.map((a) => {
        if (a.id === id)
          return {
            workingYearCount: a.workingYearCount,
            isAutoCalculate: a.isAutoCalculate,
            fixedCounts: count === '' ? null : parseInt(count, 10),
            updateAt: a.updateAt,
            lookupDeleteAt: a.lookupDeleteAt,
          };
        return {
          workingYearCount: a.workingYearCount,
          isAutoCalculate: a.isAutoCalculate,
          fixedCounts: a.fixedCounts,
          updateAt: a.updateAt,
          lookupDeleteAt: a.lookupDeleteAt,
        };
      }),
    ];
    onChange({ data: change });
  };

  const newYear = state.year.filter(
    ({ lookupDeleteAt }) => lookupDeleteAt === undefined,
  );

  /** 근속연수 추가 또는 삭제 설정. */
  const handlePlusMinusYear = (type: string, id?: number) => {
    if (isExists) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText(
          '연차 생성 후 기본 설정 변경은 불가능합니다.',
        ),
      }));
      return;
    }

    if (type === 'plus') {
      const plusYear = {
        id: state.year.length,
        workingYearCount: newYear.length + 2,
        isAutoCalculate: false,
        fixedCounts:
          newYear.length > 0 ? newYear[newYear.length - 1].fixedCounts : 15,
        updateAt: null,
      };
      setState((prev) => ({
        ...prev,
        year: [...prev.year, plusYear],
      }));
      const change = [
        state.first,
        ...state.year.map((a) => ({
          workingYearCount: a.workingYearCount,
          isAutoCalculate: a.isAutoCalculate,
          fixedCounts: a.fixedCounts,
          updateAt: a.updateAt,
          lookupDeleteAt: a.lookupDeleteAt,
        })),
        {
          workingYearCount: newYear.length + 2,
          isAutoCalculate: false,
          fixedCounts:
            newYear.length > 0 ? newYear[newYear.length - 1].fixedCounts : 15,
          updateAt: null,
        },
      ];
      onChange({ data: change });
      return;
    }
    if (type === 'minus' && id) {
      let minusData = [...state.year];
      if (state.year.some((a) => a.id === id && a.updateAt !== null))
        minusData = state.year.map((a) => {
          if (a.id === id)
            return {
              ...a,
              lookupDeleteAt: a.updateAt ?? undefined,
            };
          return a;
        });
      if (state.year.some((a) => a.id === id && a.updateAt === null))
        minusData = state.year.slice(0, -1);
      setState((prev) => ({ ...prev, year: minusData }));
      const change = [
        state.first,
        ...minusData.map((a) => {
          return {
            workingYearCount: a.workingYearCount,
            isAutoCalculate: a.isAutoCalculate,
            fixedCounts: a.fixedCounts,
            updateAt: a.updateAt,
            lookupDeleteAt: a.lookupDeleteAt,
          };
        }),
      ];
      onChange({ data: change });
    }
  };

  const { first } = state;
  useEffect(() => {
    if (props.onCancel) setState(initialState);
  }, [props.onCancel]);
  return (
    <>
      <EuiSetting.Item title={getLocalizedText('근속연수 연차  설정')}>
        <div className="attendance-countsYearList center">
          <span className="attendance-one-count-year" style={{ width: '52px' }}>
            &nbsp;&nbsp;{getLocalizedText('1년미만')}
          </span>
          <fieldset className="eui-check-group">
            <div className="group">
              <Radio
                readonly={isExists}
                label={getLocalizedText('월차부여')}
                checked={first.isAutoCalculate}
                onChange={() => handleFirstTypeChange(true)}
              />
              <Radio
                readonly={isExists}
                label=""
                checked={!first.isAutoCalculate}
                onChange={() => handleFirstTypeChange(false)}
              >
                <TextField
                  readonly={first.isAutoCalculate}
                  width={60}
                  value={
                    first.isAutoCalculate
                      ? ''
                      : first.fixedCounts?.toString() ?? ''
                  }
                  onChange={handleFirstCountChange}
                />
              </Radio>
            </div>
          </fieldset>
          <span className="attendance-year-description">
            <span style={{ marginRight: 'auto' }}>
              {getLocalizedText(
                '* 월차 부여 시 다음 연도 입사 일자의 전월까지 누적해서 부여됩니다.',
              )}
            </span>
            <span>
              {getLocalizedText(
                '* 일괄 부여 시 연차사용촉진은 다음 입사일자로부터 6개월 전에 이루어집니다.',
              )}
            </span>
          </span>
        </div>
        <div
          className="attendance-countsYearList"
          style={{
            flexDirection: display === 'pc' ? undefined : 'column',
          }}
        >
          <div>
            {newYear.length > 0 &&
              newYear.map((a, index) => {
                const newYearKey = `${a.id}_${index}`;
                if (index === 0 || (index + 1) % 5 === 1)
                  return (
                    <div
                      key={newYearKey}
                      style={{ display: 'flex', marginTop: '8px' }}
                    >
                      {newYear.map((x, i) => {
                        if (i < index + 5 && index <= i) {
                          if (i < 9)
                            return (
                              <div key={x.id} style={{ display: 'flex' }}>
                                <span className="attendance-one-count-year">
                                  &nbsp;&nbsp;
                                  {getLocalizedText('{{n}}년차', {
                                    n: x.workingYearCount - 1,
                                  })}
                                </span>
                                <TextField
                                  margin="0 8px 0 0"
                                  width={60}
                                  value={x.fixedCounts?.toString() ?? ''}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleTypeChange(event, x.id)}
                                />
                              </div>
                            );
                          return (
                            <div key={x.id} style={{ display: 'flex' }}>
                              <span className="attendance-one-count-year">
                                {getLocalizedText('{{n}}년차', {
                                  n: x.workingYearCount - 1,
                                })}
                              </span>
                              <TextField
                                margin="0 8px 0 0"
                                width={60}
                                value={x.fixedCounts?.toString() ?? ''}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => handleTypeChange(event, x.id)}
                              />
                            </div>
                          );
                        }
                        return <div key={x.id} style={{ display: 'none' }} />;
                      })}
                    </div>
                  );
                return <div key={newYearKey} style={{ display: 'none' }} />;
              })}
          </div>
          {!isExists && (
            <div
              style={{
                marginLeft: display === 'pc' ? 'auto' : undefined,
                paddingTop: display === 'pc' ? '5px' : '10px',
              }}
            >
              <Button
                icon="plus-small"
                variant="contained"
                iconType
                size="xs"
                onClick={() => handlePlusMinusYear('plus')}
              />
              <Button
                icon="minus-small"
                variant="outlined"
                iconType
                size="xs"
                onClick={() =>
                  handlePlusMinusYear('minus', newYear[newYear.length - 1].id)
                }
              />
            </div>
          )}
        </div>
      </EuiSetting.Item>
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
    </>
  );
}

export default AttendanceEnteringDayOffCountSetting;
