import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { IconType } from '../../../../../groupware-common/types/icon';
import { hangul } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Button from '../../../../../components/button/Button';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import SelectField from '../../../../../components/selectField/SelectField';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostView from '../../../../../components/post/PostView';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import TreeList from '../../../../../components/tree/TreeList';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import attendanceFormApi from '../../../../apis/attendance/v1/form';
import { attendanceWorkActions } from '../../../../stores/attendance/work';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import ErrorDialog from '../../../../../components/error/ErrorDialog';
import ApprovalLineFlat from '../../../../../groupware-approval/pages/common/components/ApprovalLineFlat';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { getOrganizationName } from '../../../../../groupware-directory/stores/directory';

export type AttendanceComposeWorkSelectConfirmArg = {
  draftOrganizationId: number;
  workId: number;
  workName: string;
  attendanceCode: number;
  formId: number;
  formName: string;
  documentNo: string;
  retentionPeriod: number;
  approvalLine: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  useAttachFile: number;
  useAttachDocument: number;
  useOpinion: boolean;
  useComment: boolean;
  contents: string;
};

function AttendanceComposeWorkSelectContainer(props: {
  draftOrganizationId: number;
  onConfirm(arg: AttendanceComposeWorkSelectConfirmArg): void;
  // 연차 생성여부.
  isDayOffCreated?: boolean;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const companyId = useSelector(
    (state: RootState) => state.session.principal.companyId,
  );

  /** 소속 조직 항목 배열. */
  const affiliatedOrganizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );

  /** 업무 폴더 배열. */
  const folders = useSelector(
    (state: RootState) => state.attendance.work.folder.list.data.items,
  );

  /** 업무 배열. */
  const works = useSelector(
    (state: RootState) => state.attendance.work.list.data.items,
  );

  /** 업무 뷰. */
  const work = useSelector(
    (state: RootState) => state.attendance.work.view.data,
  );

  /** 오류 배열. */
  const errors = useSelector((state: RootState) => state.session.errors);
  /** 근태 코드 리스트 */
  const attendanceCodeList = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );

  const [state, setState] = useState({
    draftOrganizationId: props.draftOrganizationId,
    filter: '',
  });

  /** 소속 조직 항목 아이템. */
  const affiliatedOrganizationItems = affiliatedOrganizations.map((a) => ({
    value: `${a.id}`,
    label: getOrganizationName(companyId, a.id),
  }));

  /** 트리 아이템 생성. */
  const items = useMemo(() => {
    let result = [
      ...folders
        .map((a) => {
          return {
            seq: a.seq,
            id: a.id,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as IconType,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
      ...works
        .filter((a) => a.status === 1)
        .map((a) => {
          return {
            seq: a.seq,
            id: a.id,
            parentId: a.folderId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'clipboard-edit' as IconType,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];

    // 필터가 설정된 경우 권한 있는 업무만 아이템 항목으로 설정.
    if (state.filter !== '')
      result = result.filter(({ icon }) => icon === 'clipboard-edit');

    return result;
  }, [folders, works, state.draftOrganizationId, state.filter]);

  /** 기안 조직 변경. */
  const handleDraftOrganizationChange = (value: string) => {
    // console.log(`handleDraftOrganizationChange(value)`, value);
    setState((prevState) => ({
      ...prevState,
      draftOrganizationId: parseInt(value, 10),
    }));
  };

  /** 근태코드 찾기. */
  const formatApplyType = (value: number) => {
    const attendance = attendanceCodeList.find((a) => a.id === value);
    if (attendance) return attendance.name;
    return '';
  };

  /** 필터 변경. */
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, filter: event.target.value }));
  };

  /** 업무 트리 아이템 클릭. */
  const handleWorkTreeItemClick = (id: number) => {
    if (works.find((a) => a.id === id) === undefined) return;
    // return '업무가 사용 중지되었거나 삭제되었습니다'
    dispatch(attendanceWorkActions.view({ id }));
  };

  const handleCloseWorkView = () => {
    dispatch(attendanceWorkActions.clear());
  };

  /** 확인. */
  const handleConfirm = () => {
    // console.log(`handleConfirm():state`, state);
    const { draftOrganizationId } = state;
    if (work === null || work === undefined) {
      const message = getLocalizedText(
        '업무가 사용 중지되었거나 삭제되었습니다.',
      );
      dispatch(sessionActions.error(message));
      return;
    }

    const {
      id,
      name,
      formId,
      formName,
      attendanceCode,
      documentNo,
      retentionPeriod,
      approvalLine,
      referencePermission,
      viewPermission,
      useAttachFile,
      useAttachDocument,
      useOpinion,
      useComment,
    } = work;

    attendanceFormApi
      .view(formId)
      .then((response) => {
        if (response === null || response.status !== 1) {
          const message = getLocalizedText(
            '양식이 사용 중지되었거나 삭제되었습니다.',
          );
          dispatch(sessionActions.error(message));
          return;
        }

        props.onConfirm({
          draftOrganizationId,
          workId: id,
          workName: name,
          attendanceCode,
          formId,
          formName,
          documentNo,
          retentionPeriod,
          approvalLine,
          referencePermission,
          viewPermission,
          useAttachFile,
          useAttachDocument,
          useOpinion,
          useComment,

          contents: response.contents,
        });
      })
      .catch((e) => {
        dispatch(sessionActions.error(e));
      });
  };

  const { draftOrganizationId, filter } = state;
  const error =
    errors.length === 0
      ? undefined
      : {
          ...errors[0],
          onConfirm: () => dispatch(sessionActions.errorDelete()),
        };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText('근태업무 선택')}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <EuiSetting.Header title={getLocalizedText('근태업무')}>
              {affiliatedOrganizationItems.length > 1 && (
                <SelectField
                  data={affiliatedOrganizationItems}
                  value={`${draftOrganizationId}`}
                  onChange={handleDraftOrganizationChange}
                  size="sm"
                />
              )}
            </EuiSetting.Header>
            <EuiSetting.Toolbar>
              <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
            </EuiSetting.Toolbar>
            <EuiSetting.Content>
              <TreeList
                type="full"
                selectedId={work?.id ?? 0}
                items={items}
                filter={filter}
                onItemClick={handleWorkTreeItemClick}
              />
            </EuiSetting.Content>
          </EuiSetting.Left>
          {work === null || work === undefined ? (
            <SplitUnselected label={getLocalizedText('업무를 선택하세요.')} />
          ) : (
            <EuiSetting.Right onClose={handleCloseWorkView}>
              <EuiSetting.Header title={getLocalizedText('업무정보')} />
              <EuiSetting.Content>
                <PostView>
                  <PostView.Body>
                    <PostView.Category type="text" vertical>
                      <PostView.CategoryList>
                        <PostView.CategoryItem
                          title={getLocalizedText('근태코드')}
                        >
                          <PostView.CategoryValue
                            value={formatApplyType(work.attendanceCode)}
                          />
                        </PostView.CategoryItem>
                        <PostView.CategoryItem
                          title={getLocalizedText('양식명')}
                        >
                          <PostView.CategoryValue value={work.formName} />
                        </PostView.CategoryItem>
                        {work.approvalLine.groups.some(
                          (a) => a.type === 'receive',
                        )}
                        <PostView.CategoryItem title={getLocalizedText('설명')}>
                          <PostView.CategoryValue value={work.description} />
                        </PostView.CategoryItem>
                        <PostView.CategoryItem
                          title={getLocalizedText('결재선')}
                        >
                          <ApprovalLineFlat
                            optionalNotation
                            approvalLine={work.approvalLine}
                            includeEmptyGroup
                          />
                        </PostView.CategoryItem>
                      </PostView.CategoryList>
                    </PostView.Category>
                  </PostView.Body>
                </PostView>
                <EuiToolbar>
                  <EuiToolbar.Left>
                    <Button
                      text={getLocalizedText('선택작성')}
                      variant="contained"
                      disabled={
                        !props.isDayOffCreated &&
                        attendanceCodeList.find(
                          (x) => x.id === work.attendanceCode,
                        )?.operationType === 1
                      }
                      onClick={handleConfirm}
                    />
                  </EuiToolbar.Left>
                </EuiToolbar>
              </EuiSetting.Content>
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
      <ErrorDialog error={error} />
    </>
  );
}

export default AttendanceComposeWorkSelectContainer;
