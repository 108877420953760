import * as H from 'history';
import { IconType } from './icon';

// eunm 사용시 문제점
// https://engineering.linecorp.com/ko/blog/typescript-enum-tree-shaking/

export interface LocationProps<S = H.LocationState> {
  location: H.Location<S>;
}

/** 라우트 인터페이스. */
export interface Routable {
  pathmap?: string;
  pathname: string;
  search?: string;
  hash?: string;
  key?: string;
}

/** 위치 인터페이스. */
export interface Locationable {
  pathname: string;
  search?: string;
  hash?: string;
  mode?: 'auto' | 'push' | 'replace';
}

/** 위치 인수 유형. */
export type LocateArg =
  | {
      route?: undefined;
      location?: undefined;
    }
  | {
      route: Routable;
      location?: undefined;
    }
  | {
      route?: undefined;
      location: Locationable;
    };

/** 로딩 표시 여부. */
export type NoLoading =
  | {
      noLoading: true;
    }
  | {
      noLoading?: undefined;
    };

export interface Point<T = number> {
  x: T;
  y: T;
}

export const CountryStrings = [
  'AD', // 안도라
  'AE', // 아랍에미리트
  'AF', // 아프가니스탄
  'AG', // 앤티가 바부다
  'AI', // 앵귈라
  'AL', // 알바니아
  'AM', // 아르메니아
  'AO', // 앙골라
  'AQ', // 남극
  'AR', // 아르헨티나
  'AS', // 아메리칸사모아
  'AT', // 오스트리아
  'AU', // 오스트레일리아
  'AW', // 아루바
  'AX', // 올란드 제도
  'AZ', // 아제르바이잔
  'BA', // 보스니아 헤르체고비나
  'BB', // 바베이도스
  'BD', // 방글라데시
  'BE', // 벨기에
  'BF', // 부르키나파소
  'BG', // 불가리아
  'BH', // 바레인
  'BI', // 부룬디
  'BJ', // 베냉
  'BM', // 버뮤다
  'BN', // 브루나이
  'BO', // 볼리비아
  'BR', // 브라질
  'BS', // 바하마
  'BT', // 부탄
  'BV', // 부베섬
  'BW', // 보츠와나
  'BY', // 벨라루스
  'BZ', // 벨리즈
  'CA', // 캐나다
  'CC', // 코코스 제도
  'CD', // 콩고 민주 공화국
  'CF', // 중앙아프리카 공화국
  'CG', // 콩고 공화국
  'CH', // 스위스
  'CI', // 코트디부아르
  'CK', // 쿡 제도
  'CL', // 칠레
  'CM', // 카메룬
  'CN', // 중국
  'CO', // 콜롬비아
  'CR', // 코스타리카
  'CU', // 쿠바
  'CV', // 카보베르데
  'CX', // 크리스마스섬
  'CY', // 키프로스
  'CZ', // 체코
  'DE', // 독일
  'DJ', // 지부티
  'DK', // 덴마크
  'DM', // 도미니카 연방
  'DO', // 도미니카 공화국
  'DZ', // 알제리
  'EC', // 에콰도르
  'EE', // 에스토니아
  'EG', // 이집트
  'EH', // 서사하라
  'ER', // 에리트레아
  'ES', // 스페인
  'ET', // 에티오피아
  'FI', // 핀란드
  'FJ', // 피지
  'FK', // 포클랜드 제도
  'FM', // 미크로네시아 연방
  'FO', // 페로 제도
  'FR', // 프랑스
  'GA', // 가봉
  'GB', // 영국
  'GD', // 그레나다
  'GE', // 조지아
  'GF', // 프랑스령 기아나
  'GG', // 건지섬
  'GH', // 가나
  'GI', // 지브롤터
  'GL', // 그린란드
  'GM', // 감비아
  'GN', // 기니
  'GP', // 과들루프
  'GQ', // 적도 기니
  'GR', // 그리스
  'GS', // 사우스조지아 사우스샌드위치 제도
  'GT', // 과테말라
  'GU', // 괌
  'GW', // 기니비사우
  'GY', // 가이아나
  'HK', // 홍콩
  'HM', // 허드 맥도널드 제도
  'HN', // 온두라스
  'HR', // 크로아티아
  'HT', // 아이티
  'HU', // 헝가리
  'ID', // 인도네시아
  'IE', // 아일랜드
  'IL', // 이스라엘
  'IM', // 맨섬
  'IN', // 인도
  'IO', // 영국령 인도양 지역
  'IQ', // 이라크
  'IR', // 이란
  'IS', // 아이슬란드
  'IT', // 이탈리아
  'JE', // 저지섬
  'JM', // 자메이카
  'JO', // 요르단
  'JP', // 일본
  'KE', // 케냐
  'KG', // 키르기스스탄
  'KH', // 캄보디아
  'KI', // 키리바시
  'KM', // 코모로
  'KN', // 세인트키츠 네비스
  'KP', // 조선민주주의인민공화국
  'KR', // 대한민국
  'KW', // 쿠웨이트
  'KY', // 케이맨 제도
  'KZ', // 카자흐스탄
  'LA', // 라오스
  'LB', // 레바논
  'LC', // 세인트루시아
  'LI', // 리히텐슈타인
  'LK', // 스리랑카
  'LR', // 라이베리아
  'LS', // 레소토
  'LT', // 리투아니아
  'LU', // 룩셈부르크
  'LV', // 라트비아
  'LY', // 리비아
  'MA', // 모로코
  'MC', // 모나코
  'MD', // 몰도바
  'ME', // 몬테네그로
  'MG', // 마다가스카르
  'MH', // 마셜 제도
  'MK', // 북마케도니아
  'ML', // 말리
  'MM', // 미얀마
  'MN', // 몽골
  'MO', // 마카오
  'MP', // 북마리아나 제도
  'MQ', // 마르티니크
  'MR', // 모리타니
  'MS', // 몬트세랫
  'MT', // 몰타
  'MU', // 모리셔스
  'MV', // 몰디브
  'MW', // 말라위
  'MX', // 멕시코
  'MY', // 말레이시아
  'MZ', // 모잠비크
  'NA', // 나미비아
  'NC', // 누벨칼레도니
  'NE', // 니제르
  'NF', // 노퍽섬
  'NG', // 나이지리아
  'NI', // 니카라과
  'NL', // 네덜란드
  'NO', // 노르웨이
  'NP', // 네팔
  'NR', // 나우루
  'NU', // 니우에
  'NZ', // 뉴질랜드
  'OM', // 오만
  'PA', // 파나마
  'PE', // 페루
  'PF', // 프랑스령 폴리네시아
  'PG', // 파푸아뉴기니
  'PH', // 필리핀
  'PK', // 파키스탄
  'PL', // 폴란드
  'PM', // 생피에르 미클롱
  'PN', // 핏케언 제도
  'PR', // 푸에르토리코
  'PS', // 팔레스타인
  'PT', // 포르투갈
  'PW', // 팔라우
  'PY', // 파라과이
  'QA', // 카타르
  'RE', // 레위니옹
  'RO', // 루마니아
  'RS', // 세르비아
  'RU', // 러시아
  'RW', // 르완다
  'SA', // 사우디아라비아
  'SB', // 솔로몬 제도
  'SC', // 세이셸
  'SD', // 수단
  'SE', // 스웨덴
  'SG', // 싱가포르
  'SH', // 세인트헬레나
  'SI', // 슬로베니아
  'SJ', // 스발바르 얀마옌
  'SK', // 슬로바키아
  'SL', // 시에라리온
  'SM', // 산마리노
  'SN', // 세네갈
  'SO', // 소말리아
  'SR', // 수리남
  'SS', // 남수단
  'ST', // 상투메 프린시페
  'SV', // 엘살바도르
  'SX', // 신트마르턴
  'SY', // 시리아
  'SZ', // 에스와티니
  'TC', // 터크스 케이커스 제도
  'TD', // 차드
  'TF', // 프랑스령 남방 및 남극지역
  'TG', // 토고
  'TH', // 태국
  'TJ', // 타지키스탄
  'TK', // 토켈라우
  'TL', // 동티모르
  'TM', // 투르크메니스탄
  'TN', // 튀니지
  'TO', // 통가
  'TR', // 터키
  'TT', // 트리니다드 토바고
  'TV', // 투발루
  'TW', // 타이완
  'TZ', // 탄자니아
  'UA', // 우크라이나
  'UG', // 우간다
  'UM', // 미국령 군소 제도
  'US', // 미국
  'UY', // 우루과이
  'UZ', // 우즈베키스탄
  'VA', // 바티칸 시국
  'VC', // 세인트빈센트 그레나딘
  'VE', // 베네수엘라
  'VG', // 영국령 버진아일랜드
  'VI', // 미국령 버진아일랜드
  'VN', // 베트남
  'VU', // 바누아투
  'WF', // 왈리스 푸투나
  'WS', // 사모아
  'YE', // 예멘
  'YT', // 마요트
  'ZA', // 남아프리카 공화국
  'ZM', // 잠비아
  'ZW', // 짐바브웨
  'EU', // 유럽 연합
] as const;
export type Country = typeof CountryStrings[number];

export const LanguageStrings = [
  'ko-KR',
  'en-US',
  'ja-JP',
  'zh-CN',
  'vi-VN',
] as const;
export type Language = typeof LanguageStrings[number];

// 백엔드 네임 스페이스
export const BackendNamespaceStrings = ['directory', 'jobclass'] as const;

// 네임 스페이스
export const NamespaceStrings = [
  'approval',
  'authentication',
  'attendance',
  'board',
  'calendar',
  'common',
  'contacts',
  'dashboard',
  'document',
  'jobclass',
  'resource',
  'setting',
  'mail',
  'error', // 공통 오류 상태
  'code', // 공통 오류 타입
] as const;

// 전체 네임 스페이스
export const AllNamespaceStrings = [
  ...BackendNamespaceStrings,
  ...NamespaceStrings,
] as const;

// 전체 네임스페이스 타입
export type NamespaceType = typeof AllNamespaceStrings[number];

export const CRUDStrings = ['create', 'read', 'update', 'delete'] as const;
export type CRUD = typeof CRUDStrings[number];

export const DirectoryTypeStrings = [
  'company',
  'organization',
  'employee',
] as const;

export type DirectoryType = typeof DirectoryTypeStrings[number];

export const PermissionTypeStrings = [
  'group',
  'company',
  'organization',
  'employee',
] as const;
export type PermissionType = typeof PermissionTypeStrings[number];

export const ModuleStrings = [
  'dashboard', // 대시보드
  'approval', // 결재
  'attendance', // 근태
  'calendar', // 일정
  'document', // 문서
  'mail', // 메일
  'board', // 게시
  'resource', // 자원
  'contacts', // 주소록
  'systemlink', // 시스템연동
  'directory', // 조직도

  'signin', // 로그인
  '404', // 없는 모듈

  // 관리자 콘솔 모듈
  'adminconsole', // 관리자콘솔
  'adminconsole/dashboard', // 대시보드
  'adminconsole/default', // 기본설정
  'adminconsole/service', // 서비스
  'adminconsole/authentication', // 보안
  'adminconsole/approval', // 전자결재
  'adminconsole/directory', // 디렉터리
  'adminconsole/jobclass', // 직위,직책
  'adminconsole/systemlink', // 시스템연동
  /*
  'adminconsole/user',  // 사용자
  'adminconsole/organizationunit', // 조직단위
  'adminconsole/jobposition', // 직위
  'adminconsole/jobduty', // 직책
  'adminconsole/jobrank', // 직급
  */

  'adminconsole/board', // 게시판
  'adminconsole/calendar', // 일정관리
  'adminconsole/document', // 문서관리
  'adminconsole/test', // 테스트
  'adminconsole/attendance', // 근태관리
  'adminconsole/resource', // 자원관리

  // 개발 모듈 ---
  'development/components-sample',
  // --- 개발 모듈
] as const;
export type Module = typeof ModuleStrings[number];

export interface ModuleItem {
  id: string;
  code: Module;
  name: string;
  seq: number;
  updateAt?: string;
  status?: boolean;
}

export type Role =
  | 'ADMIN'
  | 'DIRECTORY_USER'
  | 'DIRECTORY_ADMIN'
  | 'APPROVAL_USER'
  | 'APPROVAL_ADMIN'
  | 'ATTENDANCE_USER'
  | 'ATTENDANCE_ADMIN'
  | 'RESOURCE_USER'
  | 'RESOURCE_ADMIN'
  | 'DOCUMENT_USER'
  | 'DOCUMENT_ADMIN'
  | 'BOARD_USER'
  | 'BOARD_ADMIN'
  | 'CALENDAR_USER'
  | 'CALENDAR_ADMIN'
  | 'DASHBOARD_USER'
  | 'DASHBOARD_ADMIN';

export type FolderBox = 'company' | 'organization';

export interface ContentListData<T> {
  items: T[];
  totalCount: number;
  search: string;
}

export interface FolderItem {
  companyId: number;
  id: number;
  parentId: number;
  updateAt: string;
  permissions: {
    id: number;
    value: number;
  }[];
}

export interface FolderView {
  id: number;
  parentId: number;
  nameId: number;
  description: string;
  updateAt: string;
  postCount: number;
  size: number;
  permissions: {
    id: number;
    permission: number;
    text: string;
  }[];
}

export interface CacheMeta {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg?: any;
  lastUpdateAt: string;
  timestamp: number;
}

// TODO 이름 변경 후 삭제 예정. (CacheEntityMeta → CacheMeta)
export type CacheEntityMeta = CacheMeta;

export interface CacheEntity<D, M = CacheEntityMeta> {
  data: D;
  meta: M;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ParentableItem<E = any> {
  companyId?: number;
  id: number;
  parentId: number;
  updateAt: string;
  extra?: E; // 특수 가변 할당값.
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ListItem<E = any> {
  id: number;
  text: string;
  icon?: IconType;
  strings?: string[][];
  extra?: E; // 특수 가변 할당값.
}

// export interface TreeItem<T = number> {
//   id: T;
//   parentId: T;
//   text: string;
//   icon?: IconType;
//   avatar?: string;
//   strings?: string[][];
// }

// export interface TreeItem<T = number, E = any> {
//   id: T;
//   parentId: T;
//   text: string;
//   icon?: IconType;
//   avatar?: string;
//   strings?: string[][];
//   extra: E; // 특수 가변 할당값.
// }

export interface TreeItem<T = number, E = unknown> {
  id: T;
  parentId: T;
  text: string;
  icon?: IconType;
  color?: string;
  avatar?: string;
  strings?: string[][];
  updateAt?: string;
  extra?: E; // 특수 가변 할당값.
  checked?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  imagePath?: string;
  children?: React.ReactNode;
}

export interface TreeItemExtra<T = number, E = unknown> {
  id: T;
  parentId: T;
  text: string;
  icon?: IconType;
  avatar?: string;
  strings?: string[][];
  extra: E; // 특수 가변 할당값.
}

/*
export interface TreeItemWithExtra<E> extends Omit<TreeItem2<number, E>, 'extra'> {
  extra: E;
}
*/

export interface NotificationsProps {
  id: number;
  category: Module;
  message: string;
  writer: string;
  date: string;
  unread: boolean;
}

export interface GnbProps {
  module: Module;
  label: string;
  icon: IconType;
  use: boolean;
}

export interface FileUploadProps {
  id: string;
  name: string;
  size: number;
  file?: File;
  progress?: number;
  isUploaded?: boolean;
  isFail?: boolean;
}

export interface CategoryItem<T, U extends number | string> {
  type: T;
  id: U;
  name: string;
  icon: IconType;
}

export const CustomNumbers = { SMALLINT_MAX: 32767 as const };

export const DashBoardNumbers = { PORTAL_MAX: 3 as const };

export const AttendanceStrings = {
  LEAVE_DAY: '연차' as const,
  LEAVE_AM: '오전반차' as const,
  LEAVE_PM: '오후반차' as const,
  ALL_DAY: '종일' as const,
  LEAVE_HALF: '반차' as const,
  HOUR: '시간' as const,
  전체기간: '전체기간' as const,
};
