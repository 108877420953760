import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import securitiesApi, {
  SecuritiesItem,
} from '../../apis/sangsanginsecurities/v1/securities';

const name = 'sangsanginsecurities/securities';

export interface SecurityListItem extends SecuritiesItem {
  checked: boolean;
  listId: string;
}

interface State {
  list: {
    items: SecurityListItem[];
    totalCount: number;
  };
}

const initialState: State = {
  list: {
    items: [],
    totalCount: 0,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: { search: string } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const queryParams = getQueryParams(arg.search);
      const data = await securitiesApi.list({
        companyId,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: 300,
      });
      const items: SecurityListItem[] = data.list.map((a, i) => {
        const listId = `/i${i}/${a.decisGrwNo}`;
        return {
          checked: false,
          listId,
          ...a,
          decisDy: a.decisDy.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
          decisAmt: parseInt(a.decisAmt, 10).toLocaleString(),
          pttnDy: a.pttnDy.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
        };
      });
      const totalCount = data.count;
      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const securitiesReducer = createSlice({
  name,
  initialState,
  reducers: {
    setListItemChecked(
      state,
      action: PayloadAction<{ itemId: string | 'all'; checked: boolean }>,
    ) {
      if (action.payload.itemId === 'all') {
        state.list.items = state.list.items.map((x) => {
          return { ...x, checked: action.payload.checked };
        });
      } else {
        const index = state.list.items.findIndex(
          (x) => x.listId === action.payload.itemId,
        );
        if (index > -1) {
          state.list.items[index] = {
            ...state.list.items[index],
            checked: action.payload.checked,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findList.fulfilled, (state, { payload }) => {
      if (payload !== undefined) state.list = payload;
    });
  },
});

export default securitiesReducer.reducer;

export const securitiesActions = {
  setListItemChecked: securitiesReducer.actions.setListItemChecked,

  list: findList,
};
