import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import Button from '../button/Button';

function AddAttachDocument(props: {
  data: {
    companyId: number;
    id: number;
    no: string;
    subject: string;
  }[];
  onRemove(id: number): void;
  onAllRemove(): void;
  onSortable(
    arg: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[],
  ): void;
  onOpen(): void;
}): JSX.Element {
  const { data, onRemove, onAllRemove, onSortable, onOpen } = props;

  return (
    <div className="eui-document">
      {data.length > 0 && (
        <ReactSortable
          className="document-list"
          list={data}
          setList={onSortable}
          animation={200}
          delayOnTouchOnly
          delay={100}
        >
          {data.map((x) => (
            <div className="list-item" key={x.id}>
              <div className="title">
                <div className="number">{x.no}</div>
                <div className="subject">{x.subject}</div>
              </div>
              <Button
                className="button delete"
                text={getLocalizedText('삭제')}
                iconType
                icon="times"
                onClick={() => onRemove(x.id)}
                size="sm"
                color="secondary"
              />
            </div>
          ))}
        </ReactSortable>
      )}
      <div className="document-action">
        <Button
          className="document-choice"
          text={getLocalizedText('문서선택')}
          variant="outlined"
          size="sm"
          onClick={onOpen}
        />
        {data.length > 0 && (
          <Button
            className="document-choice"
            text={getLocalizedText('전체삭제')}
            iconType
            icon="trash-full"
            size="sm"
            onClick={onAllRemove}
          />
        )}
      </div>
    </div>
  );
}

export default AddAttachDocument;
