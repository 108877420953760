import axios, { AxiosError } from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { ApiError } from '../../../../../groupware-common/types/error';
import { SaveInformationItem } from '../../../../stores/information';

interface InformationItem {
  id: number;
  no: string;
  email: string;
  representOrganizationId: number;
  jobPositionId: number;
  personalEmail: string;
  companyPhoneNo: string;
  extensionPhoneNo: string;
  modilePhoneNo: string;
  enterDate: string;
  task: string;
  isUserAvatar: boolean;
  updateAt: string;
}

/** 사용자 정보 조회. */
async function fetchEmployeeInfo(arg: {
  companyId: number;
  employeeId: number;
}): Promise<InformationItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${arg.companyId}/employees/${arg.employeeId}/info`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 정보 수정 */
async function updateEmployeeInfo(arg: {
  companyId: number;
  employeeId: number;
  data: SaveInformationItem;
}): Promise<{
  companyId: number;
  nameId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${arg.companyId}/employees/${arg.employeeId}/info`;
    const response = await axios.put(url, arg.data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 비밀번호 변경 */
async function changePassword(arg: {
  currentPassword: string;
  newPassword: string;
}): Promise<{ status?: number }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/account/password`;
    const response = await axios.put(url, arg, { headers });
    return { status: response.status };
  } catch (e) {
    const error = e as AxiosError<ApiError>;
    return { status: error.response?.status };
  }
}

/** 관리자 임시 비밀번호 발급 */
async function changeAdminPassword(employeeId: number): Promise<string> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/adminconsole/account/employees/${employeeId}/password`;
    const response = await axios.put(url, undefined, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 비밀번호 다음에 변경. */
async function nextChangePassword(): Promise<{ status?: number }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/account/password/pass`;
    const response = await axios.put(url, undefined, { headers });
    return { status: response.status };
  } catch (e) {
    const error = e as AxiosError<ApiError>;
    return { status: error.response?.status };
  }
}

const informationApi = {
  employeeInfo: fetchEmployeeInfo,
  updateEmployeeInfo,
  changePassword,
  changeAdminPassword,
  nextChangePassword,
};

export default informationApi;
