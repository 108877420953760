import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import archiveApi, { archiveFolderApi } from '../../apis/approval/v1/archive';
import {
  CacheEntity,
  CacheMeta,
  LocateArg,
} from '../../../groupware-common/types';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { thunkCondition } from '../../../groupware-webapp/stores/utils';
import archiveDocumentApi from '../../apis/approval/v1/archive/document';
import { documentActions } from './document';
import { RootState } from '../../../groupware-webapp/app/store';
import { ReadingPaneMode } from '../../../groupware-webapp/stores/types';
import { getQueryParams } from '../../../groupware-common/utils';

/** 보관소 */
const NAMESPACE = 'approval/archive';

type FolderItem = {
  organizationId: number;
  parentId: number;
  id: number;
  seq: number;
  name: string;
  description: string;
  createAt: string;
  updateAt: string;
  checked?: boolean;
};

export interface TransferHistoryItem {
  id: number;
  senderOrganizationId: number;
  senderEmployeeId: number;
  receiveOrganizationId: number;
  folderName: string;
  reason: string;
  createAt: string;
}

interface ArchiveState {
  readingPaneMode: ReadingPaneMode;
  // 현재 조직 아이디.
  currentOrganizationId: number;
  // 기록물철 관리자
  administrators: { managerId: number; updateAt: string }[];
  // 폴더 배열.
  folders: CacheEntity<FolderItem[]>;
  // 이관 내역 리스트
  transferHistoryItem: TransferHistoryItem[];
  // 이관 내역 총 개수
  totalCount: number;
}

const initialState: ArchiveState = {
  readingPaneMode: 'list',
  currentOrganizationId: 0,
  administrators: [],
  folders: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: [],
  },
  transferHistoryItem: [],
  totalCount: 0,
};

const findAllFolder = createAsyncThunk(
  `${NAMESPACE}/findAllFolder`,
  async (
    arg: { data: { organizationId: number; current?: true } } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    let _response_id_: number | undefined;

    try {
      // meta = { lastUpdateAt: '', timestamp: Date.now() };
      const { organizationId } = arg.data;
      await dispatch(checkManager({ id: organizationId }));
      const response = await archiveFolderApi.findAll({ organizationId });
      return { meta, data: response, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findAllFolder.typePrefix, arg, getState);
    },
  },
);

const appendFolder = createAsyncThunk(
  `${NAMESPACE}/appendFolder`,
  async (
    arg: {
      data: {
        organizationId: number;
        parentId: number;
        name: string;
        description: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await archiveFolderApi.append(arg.data);
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const modifyFolder = createAsyncThunk(
  `${NAMESPACE}/modifyFolder`,
  async (
    arg: {
      data: {
        organizationId: number;
        id: number;
        parentId: number;
        name: string;
        description: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const response = await archiveFolderApi.modify(data);
      return response.map((a) => {
        if (a.id === data.id)
          return {
            parentId: data.parentId,
            name: data.name,
            description: data.description,
            ...a,
          };
        return a;
      });
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const removeFolder = createAsyncThunk(
  `${NAMESPACE}/removeFolder`,
  async (
    arg: {
      data: {
        organizationId: number;
        id: number;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await archiveFolderApi.remove(arg.data);
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const changeFolderSequence = createAsyncThunk(
  `${NAMESPACE}/changeFolderSequence`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await archiveFolderApi.change(arg.data);
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 기록물철 문서 이동
const movefetchArchiveDocument = createAsyncThunk(
  `${NAMESPACE}/movefetchArchiveDocument`,
  async (
    arg: {
      data: {
        organizationId: number;
        sourceFolderId: number;
        targetFolderId: number;
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { data, route, location } = arg;
      const response = await archiveDocumentApi.move(data);

      // location으로 경로이동이 없는 경우.
      if (location === undefined) {
        const { sourceFolderId: folderId } = data[0];
        const search =
          route?.search ?? (getState() as RootState).session.route.search;
        dispatch(documentActions.list({ folderId, search }));
      }
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 기록물철 관리자 지정
const designateManager = createAsyncThunk(
  `${NAMESPACE}/designateManager`,
  async (
    arg: {
      data: { managerId: number }[];
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const organizationId = (getState() as RootState).approval2.archive
        .currentOrganizationId;
      const { companyId } = (getState() as RootState).session.principal;
      const response = await archiveApi.designate({
        companyId,
        organizationId,
        data,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 관리자 페이지 기록물철 관리자 지정
const adminDesignateManager = createAsyncThunk(
  `${NAMESPACE}/adminDesignateManager`,
  async (
    arg: {
      data: { managerId: number }[];
      organizationId: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { data, organizationId } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      const response = await archiveApi.designate({
        companyId,
        organizationId,
        data,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 기록물철 관리자 조회
const checkManager = createAsyncThunk(
  `${NAMESPACE}/checkManger`,
  async (arg: { id: number } & LocateArg, { getState, rejectWithValue }) => {
    try {
      const { id } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      const response = await archiveApi.check({
        companyId,
        organizationId: id,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 기록물철 관리자 삭제
const removeManager = createAsyncThunk(
  `${NAMESPACE}/removeManager`,
  async (
    arg: { managerId: number; updateAt: string }[] & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      if (arg.length === 0) return undefined;
      const organizationId = (getState() as RootState).approval2.archive
        .currentOrganizationId;
      const { companyId } = (getState() as RootState).session.principal;
      const response = await archiveApi.remove({
        companyId,
        organizationId,
        data: arg,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 관리자 페이지 기록물철 관리자 삭제
const adminRemoveManager = createAsyncThunk(
  `${NAMESPACE}/adminRemoveManager`,
  async (
    arg: {
      data: { managerId: number; updateAt: string }[];
      organizationId: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      if (arg.data.length === 0) return undefined;
      const { companyId } = (getState() as RootState).session.principal;
      const response = await archiveApi.remove({
        companyId,
        organizationId: arg.organizationId,
        data: arg.data,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

//  기록물철 문서 이관
const archiveTransfer = createAsyncThunk(
  `${NAMESPACE}/archiveTransfer`,
  async (
    arg: {
      data: {
        folderIds: number[];
        senderOrganizationId: number;
        receiverOrganizationId: number;
        reason: string;
      };
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const organizationId = (getState() as RootState).approval2.archive
        .currentOrganizationId;
      const { companyId } = (getState() as RootState).session.principal;
      await archiveApi.transfer({
        companyId,
        organizationId,
        data,
      });
      const list = await archiveFolderApi.findAll({ organizationId });
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 이관 내역
const transferHistory = createAsyncThunk(
  `${NAMESPACE}/transferHistory`,
  async (
    arg: {
      id: number;
      search: string;
      pageno?: number;
      rowsperpage?: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const queryParams = getQueryParams(arg.search);
      const params = {
        organizationId: arg.id,
        companyId,
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      };
      const response = await archiveApi.transferHistory({
        ...params,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 15,
      });
      const totalCount = await archiveApi.totalCount(params);
      return {
        items: response,
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const archiveSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllFolder.fulfilled, (state, { meta, payload }) => {
        if (payload?.meta !== undefined) state.folders.meta = payload.meta;
        if (payload?.data !== undefined) state.folders.data = payload.data;
        if (meta.arg.data.current)
          state.currentOrganizationId = meta.arg.data.organizationId;
      })
      .addCase(appendFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.folders.data = [...state.folders.data, payload];
      })
      .addCase(modifyFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.folders.data = state.folders.data.map((folder) => {
            const data = payload.find((a) => a.id === folder.id);
            if (data) return { ...folder, ...data };
            return folder;
          });
      })
      .addCase(removeFolder.fulfilled, (state, { meta, payload }) => {
        if (payload !== undefined)
          state.folders.data = state.folders.data
            .filter(({ id }) => id !== meta.arg.data.id)
            .map((folder) => {
              const data = payload.find((a) => a.id === folder.id);
              if (data) return { ...folder, ...data };
              return folder;
            });
      })
      .addCase(changeFolderSequence.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.folders.data = state.folders.data.map((folder) => {
            const b = payload.find((a) => folder.id === a.id);
            if (folder.id === b?.id) return { ...folder, ...b };
            return folder;
          });
      })
      .addCase(checkManager.fulfilled, (state, { payload }) => {
        state.administrators = payload;
      })
      .addCase(designateManager.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeManager.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.managerId === b.managerId) === false,
          );
      })
      .addCase(archiveTransfer.fulfilled, (state, { payload }) => {
        state.folders.data = payload;
      })
      .addCase(transferHistory.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.transferHistoryItem = payload.items;
          state.totalCount = payload.totalCount;
        }
      });
  },
});

export default archiveSlice.reducer;

export const archiveActions = {
  move: movefetchArchiveDocument,
  designate: designateManager,
  adminDesignate: adminDesignateManager,
  check: checkManager,
  remove: removeManager,
  adminRemove: adminRemoveManager,
  transfer: archiveTransfer,
  transferHistory,
};

export const archiveFolderActions = {
  findAll: findAllFolder,
  append: appendFolder,
  modify: modifyFolder,
  remove: removeFolder,
  change: changeFolderSequence,
};
