import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function EmptyData(props: { message?: string }): JSX.Element {
  return (
    <div className="eui-empty-data">
      {props.message || getLocalizedText('등록된 데이터가 없습니다.')}
    </div>
  );
}

export default EmptyData;
