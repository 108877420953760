/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../groupware-webapp/app/store';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import { getQueryParams } from '../../../../../groupware-common/utils';
import ApprovalArchiveTransferHistoryListItem from './ApprovalArchiveTransferHistoryListItem';
import { TransferHistoryItem } from '../../../../stores/approval/archive';

type User = {
  name?: string;
  organization?: string;
  avatar: string;
};

type Subject = {
  folderId?: number;
  folderName?: string;
  value: string;
  reason?: string;
};

function ApprovalArchiveTransferHistoryList(props: {
  pathname: string;
  search: string;
  type?: PostListType;
  pageNo: number;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: TransferHistoryItem[];
}): JSX.Element {
  const { search, items, type, density, columns } = props;

  const { pageNo } = getQueryParams(search);
  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo]);

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <PostList name="approval" type={type} density={density}>
      <PostList.Head>
        {columns.map(({ name, type: colunmType, text }) => (
          <PostList.Cell
            key={name}
            name={text}
            type={colunmType}
            align={colunmType === 'count' ? 'center' : undefined}
          />
        ))}
      </PostList.Head>
      <PostList.Body scrollableNodeProps={scrollbar}>
        {items.map((a, i) => {
          const directoryData =
            a.id === 0
              ? undefined
              : getDirectoryData({
                  ...directory,
                  companyId: principal.companyId,
                  employeeId: a.senderEmployeeId,
                  organizationId: a.receiveOrganizationId,
                });
          const directroySenderData =
            a.id === 0
              ? undefined
              : getOrganizationName({
                  ...directory,
                  companyId: principal.companyId,
                  organizationId: a.senderOrganizationId,
                });
          const item: Record<
            string,
            string | number | boolean | Subject | User | undefined
          > = {
            createAt: a.createAt,
            subject: a.folderName,
            reason: { value: a.reason },

            sender: !directoryData
              ? {
                  name: '',
                  avatar: '',
                }
              : {
                  name: directoryData.employeeName,
                  avatar: directoryData.avatar ?? '이관자',
                },
            senderOrganization: !directoryData
              ? {
                  organization: '',
                  avatar: '',
                }
              : {
                  organization: directroySenderData,
                  avatar: directoryData.avatar ?? '보낸부서',
                },
            receiveOrganization: !directoryData
              ? {
                  organization: '',
                  avatar: '',
                }
              : {
                  organization: directoryData.organizationName,
                  avatar: directoryData.avatar ?? '받은부서',
                },
          } as Record<
            string,
            string | number | boolean | Subject | User | undefined
          >;
          const key = `/i${i}/${a.id}/}`;
          return (
            <ApprovalArchiveTransferHistoryListItem
              key={key}
              type={props.type}
              id={a.id}
              columns={columns}
              item={item}
              text={a.reason}
            />
          );
        })}
      </PostList.Body>
    </PostList>
  );
}

export default ApprovalArchiveTransferHistoryList;
