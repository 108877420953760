import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import DropMenu from '../../../../../components/selectField/DropMenu';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getOrganizationName } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { settingActions } from '../../../../stores/sangsanginsecurities/setting';

function SecuritiesDrawer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const pathmap = getPathMap('/*', props.pathname);
  const { folder } = getPathParams<{ folder?: string }>(
    '/*/:folder',
    props.pathname,
  );
  const queryParams = getQueryParams(props.search);
  const { companyId, affiliatedOrganizations } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const categories = useSelector(
    (state: RootState) => state.securites.setting.categories,
  );
  const isApprovalTeam = useSelector(
    (state: RootState) => state.securites.setting.isApprovalTeam,
  );
  const currentOrganizationId = useSelector(
    (state: RootState) => state.securites.setting.currentOrganizationId,
  );

  const treeList = affiliatedOrganizations.map((a) => {
    const { id } = a;
    const icon = 'folder' as IconType;
    const parentId = 0;

    return {
      id,
      parentId,
      text: getOrganizationName(companyId, id),
      icon,
    };
  });

  const [state, setState] = useState<{
    visible: boolean;
    validation: string;
  }>({
    visible: false,
    validation: '',
  });

  const handleGo = (id?: string) => {
    const pathname = id ? `${pathmap}/${id}` : pathmap;
    go(pathname);
  };

  /** 기안 부서 변경 이벤트. */
  const handleChangeOrganization = () => {
    if (queryParams.contentMode === 'create') {
      setState((prev) => ({
        ...prev,
        validation: '작성 중에는 기안 부서를 변경할 수 없습니다.',
      }));
      return;
    }
    setState((prev) => ({ ...prev, visible: true }));
  };

  /** 기안 부서 선택 대화상자 닫기 이벤트. */
  const handleCloseOrganization = () => {
    setState((prev) => ({ ...prev, visible: false }));
  };

  /** 기안 부서 선택 이벤트. */
  const handleSelectOrganization = (id: number) => {
    dispatch(settingActions.saveCurrentOrganizationId(id));
    handleCloseOrganization();
  };

  const renderDialog = () => {
    if (state.visible)
      return (
        <TreePicker
          title="부서선택"
          list={treeList}
          selectedId={currentOrganizationId}
          onSelected={handleSelectOrganization}
          onClose={handleCloseOrganization}
        />
      );
    return null;
  };

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title="전표결재" />
      </EuiSnb.Header>
      <EuiSnb.Tab>
        {affiliatedOrganizations.length > 1 && (
          <DropMenu
            value={getOrganizationName(companyId, currentOrganizationId)}
            label="기안 부서 선택"
            block
            onClick={handleChangeOrganization}
          />
        )}
      </EuiSnb.Tab>
      <EuiSnb.Nav>
        <Nav>
          <MenuItem
            icon={categories.find((a) => a.id === 'securities')?.icon}
            label={categories.find((a) => a.id === 'securities')?.name ?? ''}
            selected={folder === undefined}
            onClick={() => handleGo()}
          />
          {isApprovalTeam && (
            <>
              <MenuItem
                icon={categories.find((a) => a.id === 'authority')?.icon}
                label={categories.find((a) => a.id === 'authority')?.name ?? ''}
                selected={folder === 'authority'}
                onClick={() => handleGo('authority')}
              />
              <MenuItem
                icon={categories.find((a) => a.id === 'payment')?.icon}
                label={categories.find((a) => a.id === 'payment')?.name ?? ''}
                selected={folder === 'payment'}
                onClick={() => handleGo('payment')}
              />
            </>
          )}
        </Nav>
      </EuiSnb.Nav>
      {renderDialog()}
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
    </>
  );
}

export default SecuritiesDrawer;
