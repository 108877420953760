import React from 'react';
import ApprovalDocumentComposeTemplate from './templates/ApprovalDocumentComposeTemplate';
import BoardPostComposeTemplate from './templates/BoardPostComposeTemplate';
import { PortalTemplateItem } from '../../../../stores/templates';
import DocumentPostComposeTemplate from './templates/DocumentPostComposeTemplate';
import CommonProfileComposeTemplate from './templates/CommonProfileComposeTemplate';

export interface DashBoardListItem {
  id: number;
  value: string;
  index: number;
}

function DashBoardComposeItemBody(props: {
  item: PortalTemplateItem;
  onChange?(arg: { url: string; params?: string; options?: string }): void;
}): JSX.Element {
  const { item } = props;

  const handleChangeUrl = (url: string, params?: string, options?: string) => {
    if (props.onChange) props.onChange({ url, params, options });
  };

  const renderContent = () => {
    switch (item.templateKey) {
      case 'APPROVAL-DOCUMENT': {
        return (
          <ApprovalDocumentComposeTemplate
            url={props.item.url}
            id={props.item.templateId}
            onChange={handleChangeUrl}
          />
        );
      }
      case 'BOARD-POSTS': {
        return (
          <BoardPostComposeTemplate
            item={props.item}
            onChange={handleChangeUrl}
          />
        );
      }
      case 'DOCUMENT-POSTS': {
        return (
          <DocumentPostComposeTemplate
            item={props.item}
            onChange={handleChangeUrl}
          />
        );
      }
      case 'COMMON-PROFILE': {
        return (
          <CommonProfileComposeTemplate
            item={props.item}
            onChange={handleChangeUrl}
          />
        );
      }
      default:
        return <></>;
    }
  };
  return <div className="item-content">{renderContent()}</div>;
}

export default DashBoardComposeItemBody;
