import React, { useEffect } from 'react';
import { getQueryParams } from '../../groupware-common/utils';
import { useAppDispatch } from '../../groupware-webapp/app/store';
import ContactContentRoute from '../pages/root/contact/content/ContactContentRoute';
import ContactComposeRoute from '../pages/root/contact/compose/ContactComposeRoute';
import { labelActions } from '../stores/labels';

function ContactLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, locationKey: key, hash } = props;
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);

  useEffect(() => {
    dispatch(labelActions.list());
  }, [dispatch]);

  if (
    queryParams.contentMode === 'create' ||
    queryParams.contentMode === 'update'
  )
    return (
      <ContactComposeRoute pathname={pathname} search={search} key={key} />
    );

  return <ContactContentRoute pathname={pathname} search={search} key={key} />;
}

export default ContactLocateRoute;
