import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../../components/post/PostWrite';
import {
  createQueryString,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { dateFormat } from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { schedulesActions } from '../../../../stores/calendar/schedules';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function CalendarSubLookupDialog(props: {
  search: string;
}): JSX.Element | null {
  const queryParams = getQueryParams(props.search);
  const queryWord:
    | {
        id: string;
        calendarName: string;
      }
    | undefined = queryParams.queryWord
    ? JSON.parse(queryParams.queryWord)
    : undefined;
  const dispatch = useAppDispatch();
  const schedules = useSelector(
    (state: RootState) => state.calendar.schedules.subList,
  );
  const view = schedules.find((a) => a.id === queryWord?.id);

  if (!view || !queryWord) return null;

  const handleDialogClose = () => {
    const search = createQueryString(
      {
        queryWord: undefined,
        dialogMode: undefined,
        dialogType: undefined,
      },
      queryParams,
    );
    dispatch(schedulesActions.viewClear());
    dispatch(sessionActions.search(search));
  };

  const startDateTime = new Date(view.startDateTime);
  const endDateTime = new Date(view.endDateTime);

  let range = `${dateFormat(startDateTime, 'yyyy-MM-DD HH:mm')} ~ ${dateFormat(
    endDateTime,
    'yyyy-MM-DD HH:mm',
  )}`;

  // 휴일 캘린더.
  if (view.employeeId === 0) {
    if (moment(startDateTime).isSame(endDateTime, 'day'))
      range = `${dateFormat(view.startDateTime, 'yyyy-MM-DD')} (종일)`;
    else
      range = `${dateFormat(startDateTime, 'yyyy-MM-DD')} ~ ${dateFormat(
        endDateTime,
        'yyyy-MM-DD',
      )} (종일)`;
  }

  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('일정보기')}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <div style={{ paddingBottom: '22px' }}>
          <PostWrite>
            <PostWrite.Item title={getLocalizedText('캘린더명')}>
              <span style={{ fontSize: '15px', fontWeight: 700 }}>
                {queryWord.calendarName}
              </span>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('제목')}>
              {view.name}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('일정기간')}>
              {range}
            </PostWrite.Item>
          </PostWrite>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default CalendarSubLookupDialog;
