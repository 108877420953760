import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DropMenu from '../../../../../../components/selectField/DropMenu';
import Menu from '../../../../../../components/menu/Menu';
import Tree from '../../../../../../components/tree/Tree';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import PostWrite from '../../../../../../components/post/PostWrite';
import {
  extractOptionsFromUrl,
  generateUrlWithParams,
} from '../../../../../stores/common';
import {
  DocumentFolderCategories,
  PortalTemplateItem,
} from '../../../../../stores/templates';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

function DocumentPostComposeTemplate(props: {
  item: PortalTemplateItem;
  onChange?(url: string): void;
}): JSX.Element {
  const folders = useSelector(
    (state: RootState) => state.document.folders.list,
  );
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  );

  const templates = useSelector(
    (state: RootState) => state.dashboard.templates.availableList,
  );

  const [state, setState] = useState<{
    selectedId?: string | number;
    menuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(() => {
    const selectedId = props.item.url
      ? extractOptionsFromUrl(props.item.url, 'folders')
      : undefined;

    return {
      selectedId: selectedId !== undefined ? Number(selectedId) : undefined,
      menuPoint: undefined,
    };
  });

  useEffect(() => {
    const template = templates.find(
      (x) => x.templateId === props.item.templateId,
    );
    if (props.onChange && template && state.selectedId) {
      const folderId = state.selectedId.toString();
      const url = generateUrlWithParams(template.url, {
        folderId,
        rowsperpage: '5',
      });
      props.onChange(url);
    }
  }, [state.selectedId]);

  /** 폴더 드롭메뉴 클릭. */
  const handleDropClick = (event?: React.MouseEvent) => {
    const { menuPoint } = state;
    if (event !== undefined && menuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        menuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        menuPoint: undefined,
      }));
    }
  };

  /** 문서함 선택. */
  const handleDropItemClick = (id: number | string) => {
    setState((prev) => ({
      ...prev,
      selectedId: id,
      menuPoint: undefined,
    }));
  };

  const treeData = [
    ...categories
      .filter((a) => a.type === 'status')
      .filter((x) => x.id !== 'temp')
      .filter((x) => x.id !== 'checkout')
      .map((x) => {
        return {
          id: DocumentFolderCategories[x.id],
          parentId: 0,
          text: x.name,
          icon: x.icon,
        };
      }),
    ...folders.map((x) => {
      return {
        id: x.id,
        parentId: 0,
        text: x.name,
        icon: 'folder' as const,
      };
    }),
  ];

  return (
    <>
      <div>
        <div className="item-content" />
        <PostWrite.Item title={getLocalizedText('문서함')}>
          <DropMenu
            value={treeData.find((x) => x.id === state.selectedId)?.text ?? ''}
            onClick={handleDropClick}
            label={getLocalizedText('문서함 선택')}
          />
        </PostWrite.Item>
        {state.menuPoint && (
          <Menu
            point={state.menuPoint}
            onClose={() =>
              setState((prev) => ({
                ...prev,
                menuPoint: undefined,
              }))
            }
          >
            <div className="ui-organization-select">
              <div className="body-panel">
                <Tree
                  selectedId={state.selectedId}
                  items={treeData}
                  onItemClick={handleDropItemClick}
                />
              </div>
            </div>
          </Menu>
        )}
      </div>
    </>
  );
}

export default DocumentPostComposeTemplate;
