import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { CalendarColorType } from '../../../../stores/calendar/preferences';

/**
 * 역할에 해당되는 직원을 조회합니다.
 * @param role 역할.
 * @returns 직원 역할 배열.
 */
async function find(arg: {
  role: 'CALENDAR_USER' | 'CALENDAR_ADMIN';
  isLogin?: boolean;
}): Promise<
  {
    employeeId: number;
    role: 'CALENDAR_USER' | 'CALENDAR_ADMIN';
    updateAt: string;
  }[]
> {
  const { role, isLogin } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/roles`;
    const response = await axios.get(url, { headers, params: { role } });
    return response.data;
  } catch (e) {
    if (isLogin) return [];
    throw apiError(e);
  }
}

/**
 * 직원 역할을 추가합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function append(
  arg: { employeeId: number; role: 'CALENDAR_USER' | 'CALENDAR_ADMIN' }[],
): Promise<
  {
    employeeId: number;
    role: 'CALENDAR_USER' | 'CALENDAR_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/roles`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 직원 역할을 삭제합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function remove(
  arg: {
    employeeId: number;
    role: 'CALENDAR_USER' | 'CALENDAR_ADMIN';
    updateAt: string;
  }[],
): Promise<
  {
    employeeId: number;
    role: 'CALENDAR_USER' | 'CALENDAR_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/roles`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 캘린더 색상 리스트 조회. */
async function findColorList(): Promise<CalendarColorType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/adminconsole/v1/colors`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const preferencesApi = {
  find,
  append,
  remove,

  colorList: findColorList,
};

export default preferencesApi;
