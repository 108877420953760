import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

async function fetchBlockedUser(arg: {
  companyId: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number;
    employeeId: number;
    createAt: string;
  }[]
> {
  try {
    const params = {
      pageNo: arg.pageNo,
      rowsPerPage: arg.rowsPerPage,
    };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/v1/signin/block/companies/${arg.companyId}/employees`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchBlockedUserTotalCount(arg: {
  companyId: number;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/v1/signin/block/companies/${arg.companyId}/employees/totalcount`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function unBlockUsers(arg: { companyId: number; ids: number[] }): Promise<
  {
    employeeId: number;
    createAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const params = { employeeIds: arg.ids };
    const url = `${host}/api/preferences/v1/signin/block/companies/${arg.companyId}/employees`;
    const response = await axios.delete(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const blockedUserApi = {
  blockedUser: fetchBlockedUser,
  blockedUserTotal: fetchBlockedUserTotalCount,

  unBlockUsers,
};

export default blockedUserApi;
