import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function CalendarHeadContentContainer(props: {
  today: string;
  onDateClick(event: React.MouseEvent): void;
}): JSX.Element {
  const { today, onDateClick } = props;
  const dispatch = useAppDispatch();
  const display = useSelector((state: RootState) => state.session.display);

  const handleTableClick = () => {
    dispatch(sessionActions.setDialog({ type: 'list' }));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '24px',
      }}
    >
      <Button
        className="btn btn-default btn-sm move-day"
        dataAction="move-prev"
        text="<"
        onClick={onDateClick}
      />
      <div
        style={{
          margin: '0 5px',
          background: 'none',
          width: 'auto',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <h3
          style={{
            fontSize: display === 'phone' ? '13px' : '14px',
            margin: '0',
          }}
        >
          {today}
        </h3>
      </div>
      <Button
        className="btn btn-default btn-sm move-day"
        dataAction="move-next"
        text=">"
        onClick={onDateClick}
      />
      <div style={{ marginLeft: '10px', display: 'flex' }}>
        <Button
          className="btn btn-default btn-sm move-today"
          dataAction="move-today"
          text={getLocalizedText('오늘')}
          variant="outlined"
          onClick={onDateClick}
        />
        <Button
          iconType={display === 'phone'}
          icon={display === 'phone' ? 'calendar-grid' : undefined}
          text={getLocalizedText('표로 보기')}
          variant={display === 'phone' ? undefined : 'outlined'}
          onClick={handleTableClick}
        />
      </div>
    </div>
  );
}

export default CalendarHeadContentContainer;
