import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostWrite from '../../../../../components/post/PostWrite';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import TreeList from '../../../../../components/tree/TreeList';
import { CustomNumbers } from '../../../../../groupware-common/types';
import { hangul } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  folderActions,
  PermissionType,
  PermissionUserType,
} from '../../../../stores/document/folders';
import DocumentPermissionDialogContainer from '../folderbox/company/DocumentPermissionDialogContainer';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { handleMatchingPermissions } from '../folderbox/company/DocumentFolderEditDrawer';

function DocumentAuthorityContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const display = useSelector((s: RootState) => s.session.display);

  /** 폴더 리스트. */
  const folders = useSelector(
    (state: RootState) => state.document.folders.adminconsole.list,
  );

  const directory = useDirectory();

  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );

  const organizations = useSelector(
    (s: RootState) => s.directory.organization.list.data.items,
  );

  /** 직위 */
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );

  /** 직책 */
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );

  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'setting');

  const initialState = {
    items: folders
      .map((a) => {
        return {
          id: a.id,
          seq: a.seq,
          parentId: a.parentId,
          text: a.name,
          strings: hangul.d(a.name),
          icon: 'folder' as const,
          checked: false,
          updateAt: a.updateAt,
        };
      })
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    filter: '',
    folderSelectedId: undefined,
    users: [],
    managers: [],
    excluders: [],
    validation: '',
    change: false,
    saveing: false,
    managerMenuPoint: undefined,
    excluderMenuPoint: undefined,
    permissionDialogVisible: false,
    isView: display !== 'phone',
  };

  const [state, setState] = useState<{
    items: {
      id: number;
      seq: number;
      parentId: number;
      text: string;
      strings: string[][];
      icon: 'folder';
      checked: boolean;
      updateAt: string;
    }[];

    filter: string;
    folderSelectedId: string | undefined;

    users: PermissionUserType[];
    managers: PermissionType[];
    excluders: PermissionType[];

    managerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    excluderMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    permissionDialogVisible: boolean;

    validation: string;
    change: boolean;
    saveing: boolean;
    isView: boolean;
  }>({
    ...initialState,
  });

  useEffect(() => {
    async function run() {
      const { managers, users, excluders } = state;
      const items = state.items
        .filter(({ checked }) => checked)
        .map((a) => ({
          id: a.id,
          updateAt: a.updateAt,
        }));
      dispatch(
        folderActions.bulkAuthority({
          folders: items,
          managers,
          users,
          excluders,
        }),
      ).then(() => {
        setState((prev) => ({
          ...prev,
          ...initialState,
        }));
      });
    }

    if (state.saveing) run();
  }, [state.saveing]);

  // 모바일에서만 적용
  const handleCloseView = () => {
    setState((prev) => ({
      ...prev,
      isView: display !== 'phone',
    }));
  };

  /** 문서함 목록 폴더 체크 */
  const handleFolderCheck = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const items = state.items.map((a) => {
      if (a.id === id) return { ...a, checked: event.target.checked };
      return a;
    });

    if (items.some(({ checked }) => checked === true))
      setState((prev) => ({
        ...prev,
        items,
      }));
    else
      setState((prev) => ({
        ...prev,
        items,
        managers: [],
        users: [],
        exceptioners: [],
        change: false,
      }));
  };

  /** 문서함 목록 검색필터 변경 */
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, filter: event.target.value }));
  };

  /** 관리자 아이템 삭제. */
  const handleManagerDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      managers: prev.managers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 공유자 대화 상자 저장 */
  const handlePermissionDialogSave = (users: PermissionUserType[]) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      permissionDialogVisible: false,
      users,
    }));
  };

  /** 공유자 대화 상자 닫기. */
  const handlePermissionDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      permissionDialogVisible: false,
    }));
  };

  /** 관리자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { managerMenuPoint } = state;
    if (event !== undefined && managerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        managerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        managerMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 아이템 추가. */
  const handleManagerAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.managers.some((a) => a.referenceId === employeeId))
          return { ...prev, managerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, managerMenuPoint: undefined };

        return {
          ...prev,
          managers: [
            ...prev.managers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
              options: {
                useRead: true,
                useWrite: true,
                useMove: true,
              },
            },
          ],
          change: true,
        };
      });
    }
  };

  /** 권한 등록 */
  const handlePermissionOpenDialog = () => {
    setState((prev) => ({ ...prev, permissionDialogVisible: true }));
  };

  /** 권한 삭제 */
  const handleAdminstratorsDelete = (id: number, referenceType: string) => {
    const { users } = state;
    setState((prev) => ({
      ...prev,
      users: users.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 예외자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggleExcluder = (event?: React.MouseEvent) => {
    const { excluderMenuPoint } = state;
    if (event !== undefined && excluderMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: undefined,
      }));
    }
  };

  /** 예외자 아이템 삭제. */
  const handleExcluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      excluders: prev.excluders.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 예외자 아이템 추가. */
  const handleExcluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.excluders.some(
            (a) => a.referenceType === 'COMPANY' && a.referenceId === companyId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };
        return {
          ...prev,
          excluders: [
            ...prev.excluders,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 'COMPANY',
            },
          ],
          change: true,
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.excluders.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          excluders: [
            ...prev.excluders,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
            },
          ],
          change: true,
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.excluders.some((a) => a.referenceId === employeeId))
          return { ...prev, excluderMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          excluders: [
            ...prev.excluders,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
            },
          ],
        };
      });
    }
  };

  const handleSave = () => {
    const permissions: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: string;
    }[] = [];

    state.managers.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.users.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.excluders.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });

    let isDuplicate = false;
    // 중복 확인
    for (let i = 0; i < permissions.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (
          permissions[i].referenceType === permissions[j].referenceType &&
          permissions[i].referenceId === permissions[j].referenceId
        ) {
          isDuplicate = true;
        }
      }
    }

    if (isDuplicate) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText(
          '관리자 또는 공유자, 예외자 인원을 중복으로 설정할 수 없습니다.',
        ),
      }));
      return;
    }

    setState((prev) => ({ ...prev, saveing: true }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const handleCancel = () => {
    setState((prev) => ({
      ...prev,
      ...initialState,
    }));
  };

  const renderDialog = () => {
    const { permissionDialogVisible } = state;
    if (permissionDialogVisible) {
      const { users } = state;
      return (
        <DocumentPermissionDialogContainer
          users={users}
          onSave={handlePermissionDialogSave}
          onClose={handlePermissionDialogClose}
        />
      );
    }
    return null;
  };

  const { items, filter, saveing } = state;

  const renderView = () => {
    const checked = state.items.filter((a) => a.checked === true);
    if (checked.length === 0 || !state.isView) return <SplitUnselected />;

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <EuiSetting.Header title={getLocalizedText('선택목록')}>
          {!state.saveing && state.change && (
            <Button
              text={getLocalizedText('취소')}
              variant="outlined"
              loading={state.saveing}
              onClick={handleCancel}
            />
          )}
          {state.change && (
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              loading={saveing}
              onClick={handleSave}
            />
          )}
        </EuiSetting.Header>
        <PostWrite>
          <PostWrite.Item
            title={getLocalizedText('관리자')}
            tooltip={getLocalizedText('관리자는 직원만 선택가능합니다.')}
          >
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={handleDirectoryTreeMenuToggle}
            >
              {state.managers
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc={getLocalizedText('관리자')}
                          onDelete={() =>
                            handleManagerDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          icon="sitemap-fill"
                          etc={getLocalizedText('관리자')}
                          onDelete={() =>
                            handleManagerDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc={getLocalizedText('관리자')}
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleManagerDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    return null;
                  },
                )}
              {state.managerMenuPoint && (
                <DirectoryMenuTreeContainer
                  point={state.managerMenuPoint}
                  typeToFilter="employee"
                  onItemClick={handleManagerAppend}
                  onClose={handleDirectoryTreeMenuToggle}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>

          <PostWrite.Item title={getLocalizedText('공유자')}>
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={handlePermissionOpenDialog}
            >
              {state.users
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                    options,
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc={handleMatchingPermissions(options).join('/')}
                          onDelete={() =>
                            handleAdminstratorsDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          etc={handleMatchingPermissions(options).join('/')}
                          icon="sitemap-fill"
                          onDelete={() =>
                            handleAdminstratorsDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc={handleMatchingPermissions(options).join('/')}
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleAdminstratorsDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    return null;
                  },
                )}
            </ChipGroup>
          </PostWrite.Item>

          <PostWrite.Item title={getLocalizedText('예외자')}>
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={handleDirectoryTreeMenuToggleExcluder}
            >
              {state.excluders
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc={getLocalizedText('예외자')}
                          onDelete={() =>
                            handleExcluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          icon="sitemap-fill"
                          etc={getLocalizedText('예외자')}
                          onDelete={() =>
                            handleExcluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc={getLocalizedText('예외자')}
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleExcluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    return null;
                  },
                )}
              {state.excluderMenuPoint && (
                <DirectoryMenuTreeContainer
                  point={state.excluderMenuPoint}
                  typeToFilter="employee"
                  onItemClick={handleExcluderAppend}
                  onClose={handleDirectoryTreeMenuToggleExcluder}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
      </EuiSetting.Right>
    );
  };

  const title = categories.find((a) => a.id === 6003)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <EuiSetting.Header title={getLocalizedText('문서함 목록')} />
            <EuiSetting.Toolbar>
              <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
            </EuiSetting.Toolbar>
            <EuiSetting.Content>
              <TreeList
                items={items}
                filter={filter}
                useCheck
                onItemCheck={handleFolderCheck}
              />
            </EuiSetting.Content>
            {!state.isView && state.items.some((a) => a.checked === true) && (
              <EuiToolbar>
                <EuiToolbar.Left>
                  <></>
                </EuiToolbar.Left>
                <EuiToolbar.Right>
                  <Button
                    text={getLocalizedText('선택')}
                    size="sm"
                    iconType
                    icon="arrow-from-left"
                    onClick={() =>
                      setState((prev) => ({ ...prev, isView: true }))
                    }
                  />
                </EuiToolbar.Right>
              </EuiToolbar>
            )}
          </EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default DocumentAuthorityContainer;
