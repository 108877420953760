import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import Tree from '../../../../../components/tree/Tree';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function DocumentDrawer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();
  const resource = useSelector((state: RootState) => state.session.resource);

  /** 현재 로그인한 사용자 회사, 사원, 부서 키 */
  const principal = useSelector((state: RootState) => state.session.principal);
  const userFolder = useSelector(
    (state: RootState) => state.document.folders.list,
  );
  const folders = userFolder;
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'status');
  const queryParams = getQueryParams(search);

  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const selectedId =
    !folderId ||
    folderId === 'all' ||
    folderId === 'importance' ||
    folderId === 'trash' ||
    folderId === 'temp' ||
    folderId === 'checkout'
      ? undefined
      : b62(folderId);

  const handleGo = (id: number | 'create') => {
    const useWrite =
      folders.find((a) => a.id === selectedId)?.permissions?.useWrite ?? true;
    // 문서등록
    if (id === 'create') {
      if (useWrite) {
        queryParams.contentMode = 'create';
        delete queryParams.dialogType;
        delete queryParams.contentType;
        go(getPathMap('/*/*', pathname), queryParams);
      } else {
        dispatch(
          sessionActions.error(
            appError({
              error: getLocalizedText('선택한 폴더에 작성 권한이 없습니다.'),
            }),
          ),
        );
      }
    } else if (typeof id === 'string') {
      go(`${getPathMap('/*', pathname)}/${id}`);
    } else if (typeof id === 'number') {
      go(`${getPathMap('/*', pathname)}/${b62(id)}`);
    } else {
      go(`${getPathMap('/*', pathname)}`);
    }
  };

  /** 트리 아이템 생성. */
  const data = useMemo(() => {
    return folders
      .map((a) => ({ ...a, text: a.name, icon: 'folder' as const }))
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
  }, [folders]);
  // 고정 폴더 이름.
  const getDefaultFolderName = (id: string) => {
    const category = categories.find((a) => a.id === id);
    if (!category) return undefined;
    return getLocalizedText(`폴더.${category.name}`);
  };

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title={getLocalizedText('모듈.문서')} />
        <EuiSnb.Action>
          <Button
            text={getLocalizedText('문서등록')}
            variant="contained"
            block
            onClick={() => handleGo('create')}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <Nav>
          <MenuItem
            label={getDefaultFolderName('all')}
            icon="archive"
            selected={!folderId || folderId === 'all'}
            onClick={() => {
              go('/document/all');
              dispatch(sessionActions.mobileNav(false));
            }}
          />
          <MenuItem
            label={getDefaultFolderName('importance')}
            icon="star-fill"
            selected={folderId === 'importance'}
            onClick={() => go('/document/importance')}
          />
          <MenuItem
            label={getDefaultFolderName('temp')}
            icon="document"
            selected={folderId === 'temp'}
            onClick={() => {
              go(`/document/temp`);
              dispatch(sessionActions.mobileNav(false));
            }}
          />
          <MenuItem
            label={getDefaultFolderName('checkout')}
            icon="document-update"
            selected={folderId === 'checkout'}
            onClick={() => {
              go(`/document/checkout`);
              dispatch(sessionActions.mobileNav(false));
            }}
          />
        </Nav>
        <Nav title={getLocalizedText('문서함')}>
          <Tree
            selectedId={b62(folderId)}
            items={data}
            onItemClick={(id: number) => handleGo(id)}
          />
        </Nav>
        <Nav>
          {resource === 'teams' &&
            principal.roles.find(
              (role) => role === 'ADMIN' || role === 'DOCUMENT_ADMIN',
            ) && (
              <>
                <MenuDivider />
                <MenuItem
                  label={getLocalizedText('폴더.문서설정')}
                  icon="user-cog"
                  onClick={() => {
                    go('/adminconsole/document');
                    dispatch(sessionActions.mobileNav(false));
                  }}
                />
              </>
            )}
        </Nav>
      </EuiSnb.Nav>
    </>
  );
}

export default React.memo(DocumentDrawer);
