import { useSelector } from 'react-redux';
import { RootState } from '../../../groupware-webapp/app/store';
import { deepEqual } from '../../../groupware-common/utils';
import { JobClassType } from './preferences';
import {
  RESOURCE_KEY,
  getLocalizedText,
} from '../../../groupware-common/utils/i18n';
import { Language } from '../../../groupware-common/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function directorySelector(state: RootState) {
  return {
    companies: state.directory.company.list.data.items,
    organizations: state.directory.organization.list.data.items,
    organizationEmployees: state.directory.organization.employees.data.items,
    employees: state.directory.employee.list.data.items,
    jobClassType: state.directory.preferences.jobClassType,
    jobPositions: state.directory.jobPosition.list.data.items,
    jobDuties: state.directory.jobDuty.list.data.items,
    timestamps: {
      companies: state.directory.company.list.meta.timestamp,
      organizations: state.directory.organization.list.meta.timestamp,
      organizationEmployees:
        state.directory.organization.employees.meta.timestamp,
      employees: state.directory.employee.list.meta.timestamp,
      jobClassType: state.directory.preferences.jobClassType,
      jobPositions: state.directory.jobPosition.list.meta.timestamp,
      jobDuties: state.directory.jobDuty.list.meta.timestamp,
    },
  };
}

interface DirectoryTimestamps {
  companies: number;
  organizations: number;
  organizationEmployees: number;
  employees: number;
  jobClassType: JobClassType;
  jobPositions: number;
  jobDuties: number;
}

function directoryEqualityFn(
  left: { timestamps: DirectoryTimestamps },
  right: { timestamps: DirectoryTimestamps },
): boolean {
  // console.log(
  //   `deepEqual(left.timestamps, right.timestamps)`,
  //   deepEqual(left.timestamps, right.timestamps),
  // );
  return deepEqual(left.timestamps, right.timestamps);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useDirectory() {
  return useSelector(directorySelector, directoryEqualityFn);
}

function getCompanyName(
  id: number,
  defaultName?: string,
  affiliateCompanyId?: number,
  lng?: Language,
): string {
  const key =
    affiliateCompanyId === undefined
      ? `${RESOURCE_KEY.DIRECTORY.COMPANY}.${id}_${id}`
      : `${RESOURCE_KEY.DIRECTORY.COMPANY}.${id}_${affiliateCompanyId}`;
  const options = lng ? { lng } : undefined;
  const name = getLocalizedText(key, options);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getOrganizationName(
  companyId: number,
  id: number,
  defaultName?: string,
  lng?: Language,
): string;
function getOrganizationName(
  a: {
    companyId: number;
    organizationId: number;
    defaultName?: string;
    lng?: Language;
  },
  b?: undefined,
): string;
function getOrganizationName(
  a:
    | number
    | {
        companyId: number;
        organizationId: number;
        defaultName?: string;
        lng?: Language;
      },
  b?: number,
  c?: string,
  d?: Language,
): string {
  const { companyId, organizationId, defaultName, lng } =
    typeof a === 'number'
      ? {
          companyId: a,
          organizationId: b,
          defaultName: c,
          lng: d,
        }
      : a;

  if (companyId === organizationId)
    return getCompanyName(companyId, defaultName);

  const key = `${RESOURCE_KEY.DIRECTORY.ORGANIZATION}.${companyId}_${organizationId}`;
  const options = lng ? { lng } : undefined;
  const name = getLocalizedText(key, options);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getEmployeeName(
  companyId: number,
  id: number,
  defaultName?: string,
  lng?: Language,
): string;
function getEmployeeName(
  a:
    | {
        name: string;
        type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty';
        jobPosition: string;
        jobDuty: string;
        companyId?: undefined;
      }
    | {
        companyId: number;
        employeeId: number;
        defaultName?: string;
        lng?: Language;
      },
  b?: undefined,
): string;
function getEmployeeName(
  a:
    | number
    | {
        name: string;
        type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty';
        jobPosition: string;
        jobDuty: string;
        companyId?: undefined;
      }
    | {
        companyId: number;
        employeeId: number;
        defaultName?: string;
        lng?: Language;
      },
  b?: number,
  c?: string,
  d?: Language,
): string {
  if (typeof a === 'object' && a.companyId === undefined) {
    const { name, type, jobPosition, jobDuty } = a;

    let jobClass = '';
    switch (type) {
      case 'none':
        jobClass = '';
        break;
      case 'jobposition':
        jobClass = jobPosition;
        break;
      case 'jobduty':
        jobClass = jobDuty;
        break;
      default: {
        if (jobPosition !== '' && jobDuty !== '')
          jobClass = `${jobPosition}/${jobDuty}`;
        jobClass = jobPosition || jobDuty || '';
        break;
      }
    }
    if (jobClass !== '') return `${name} ${jobClass}`;
    return name;
  }

  const { companyId, employeeId, defaultName, lng } =
    typeof a === 'object' && a.companyId !== undefined
      ? a
      : {
          companyId: a,
          employeeId: b,
          defaultName: c,
          lng: d,
        };

  const key = `${RESOURCE_KEY.DIRECTORY.EMPLOYEE}.${companyId}_${employeeId}`;
  const options = lng ? { lng } : undefined;
  const name = getLocalizedText(key, options);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getGroupName(
  companyId: number,
  id: number,
  defaultName?: string,
  lng?: Language,
): string {
  const key = `${RESOURCE_KEY.DIRECTORY.GROUP}.${companyId}_${id}`;
  if (id === 0 && defaultName !== undefined) return defaultName;
  const options = lng ? { lng } : undefined;
  const name = getLocalizedText(key, options);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getJobPositionName(
  companyId: number,
  id: number,
  defaultName?: string,
  lng?: Language,
): string {
  const key = `${RESOURCE_KEY.JOBCLASS.JOBPOSITION}.${companyId}_${id}`;
  if (id === 0 && defaultName !== undefined) return defaultName;

  const options = lng ? { lng } : undefined;
  const name = getLocalizedText(key, options);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getJobDutyName(
  companyId: number,
  id: number,
  defaultName?: string,
  lng?: Language,
): string {
  const key = `${RESOURCE_KEY.JOBCLASS.JOBDUTY}.${companyId}_${id}`;
  if (id === 0 && defaultName !== undefined) return defaultName;
  const options = lng ? { lng } : undefined;
  const name = getLocalizedText(key, options);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

export {
  useDirectory,
  directorySelector,
  directoryEqualityFn,
  getCompanyName,
  getOrganizationName,
  getEmployeeName,
  getGroupName,
  getJobPositionName,
  getJobDutyName,
};
