import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../groupware-common/apis/common/v1';
import { Language } from '../../../groupware-common/types';

/** 초기 메뉴 설정 보기 */
async function findPreferences(arg: { companyId: number }): Promise<{
  initialModule: string;
  languages: Array<Language>;
  updateAt: string;
}> {
  try {
    const { companyId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/general`;
    const response = await axios.get(url, { headers });

    // TODO 회사별 사용 가능한 언어 리스트 조회 기능 추가.
    const languages: Array<Language> = [
      'http://localhost:3000',
      'https://dev.test.com',
      'https://fe.test.com',
    ].includes(host)
      ? ['ko-KR', 'en-US', 'ja-JP', 'zh-CN', 'vi-VN']
      : ['ko-KR'];

    return { ...response.data, languages };
  } catch (e) {
    throw apiError(e);
  }
}

/** 초기 메뉴 설정 수정 */
async function update(arg: {
  companyId: number;
  initialModule: string;
  updateAt: string;
}): Promise<{
  initialModule: string;
  updateAt: string;
}> {
  try {
    const { companyId, ...data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/general`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const serviceBasicApi = {
  findPreferences,
  update,
};

export default serviceBasicApi;
