import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import Button from '../button/Button';

function FileInput(props: {
  value: string;
  accept?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  onChange(name: string, file: File): void;
  onRemove(): void;
}): JSX.Element {
  let classname = 'eui-form-field file-input';

  if (props.disabled) classname += ` disabled`;
  if (props.required) classname += ` required`;
  if (props.readonly) classname += ` readonly`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files[0] !== undefined) {
      props.onChange(event.target.files[0].name, event.target.files[0]);

      const eventTargetValue = { ...event };
      eventTargetValue.target.value = '';
    }
  };

  return (
    <div className={classname}>
      <span className={`file-value ${props.value !== '' ? '' : 'placeholder'}`}>
        <span>
          {props.value !== '' ? props.value : getLocalizedText('파일선택')}
        </span>
      </span>
      <input
        type="file"
        accept={props.accept}
        disabled={props.disabled}
        required={props.required}
        readOnly={props.readonly}
        className="control"
        onChange={handleChange}
      />
      {props.value && (
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="times"
          onClick={props.onRemove}
        />
      )}
    </div>
  );
}

export default FileInput;
