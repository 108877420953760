import React from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { archiveFolderActions } from '../../../../stores/approval/archive';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import ApprovalArchiveSettingsFolderList from './ApprovalArchiveSettingsFolderList';
import ApprovalArchiveSettingsFolderEditDrawer from './ApprovalArchiveSettingsFolderEditDrawer';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import ApprovalArchiveSettingHead from '../preferences/ApprovalArchiveSettingHead';
import ApprovalArchiveSettingsFolderView from './ApprovalArchiveSettingsFolderView';
import ApprovalArchiveTransferContainer from './ApprovalArchiveTransferContainer';
import AccessDenied from '../../../../../components/error/AccessDenied';
import ApprovalArchiveTransferHistoryContainer from './ApprovalArchiveTransferHistoryContainer';
import { SearchDateProps } from '../../../../../components/search/Search';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

function ApprovalArchiveSettingsContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const map = `/approval/archivesettings`;
  const { id: selectedId } = getPathParams<{ id?: number }>(
    `${map}/:id$base62`,
    props.pathname,
  );

  const dispatch = useAppDispatch();

  const organizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );

  const display = useSelector((state: RootState) => state.session.display);
  const folders = useSelector(
    (state: RootState) => state.approval2.archive.folders.data,
  );
  const archiveAdministor = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );
  const affiliatedOrganizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );
  const folderSetting = useSelector(
    (state: RootState) => state.boards.board.folderSetting,
  );
  const queryParams = getQueryParams(props.search);

  const totalCount = useSelector(
    (state: RootState) => state.approval2.archive.totalCount,
  );

  const principal = useSelector((state: RootState) => state.session.principal);

  const items = folders
    .filter((a) => a.seq !== 0)
    .map((a) => ({ ...a, text: a.name, icon: 'folder' as const }))
    .sort((a, b) => {
      // 이관받은폴더 마지막 위치로 정렬.
      if (a.seq === -1) return 1;
      if (b.seq === -1) return -1;
      return +(a.seq > b.seq) || +(a.seq === b.seq) - 1;
    });

  // 부서장
  const manager = affiliatedOrganizations.find(
    ({ id }) => id === organizationId,
  )?.manager;

  //  기록물 관리자
  const archiveManager =
    archiveAdministor.find((x) => x.managerId === principal.employeeId) !==
    undefined;

  const rowsPerPage =
    display === 'phone'
      ? queryParams.rowsPerPage ?? 15
      : queryParams.rowsPerPage ?? folderSetting.setting?.rowsPerPage ?? 15;

  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    directoryFilter?: string;
    date: SearchDateProps;
    status?: string;
  }) => {
    const { keyword, directoryKeyword, filter, date } = arg;
    let searchPath = props.pathname;
    delete queryParams.pageNo;
    searchPath = `${getPathMap('/*/*', props.pathname)}`;

    delete queryParams.type;
    queryParams.pageNo = 1;
    queryParams.searchCode = keyword === '' ? undefined : filter;
    queryParams.searchWord = keyword === '' ? undefined : keyword;
    queryParams.startDate = date.start
      ? dateFormat(initialDate(date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = date.end
      ? dateFormat(initialDate(date.end), 'yyyy-MM-DD')
      : undefined;
    queryParams.directoryKeyword = directoryKeyword;
    queryParams.directoryFilter =
      directoryKeyword && keyword !== '' ? 'true' : undefined;
    go(searchPath, getQueryParams(queryParams), props.hash);
  };

  const folderSeq = folders.find((a) => a.seq === -1)?.seq;

  const handleListItemClick = (arg: { id: number }) => {
    dispatch(sessionActions.setRoute({ pathname: `${map}/${b62(arg.id)}` }));
  };

  const handleListAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;
    // 기록물철 폴더 생성
    if (code === 'create') dispatch(sessionActions.setDrawer({ mode: code }));
  };

  const handleViewClose = () => {
    // console.log(`handleViewClose()`);
    dispatch(sessionActions.setRoute({ pathname: map }));
  };

  const handleViewAction = (arg: { code: string; event: React.MouseEvent }) => {
    // console.log(`handleViewAction(arg)`, arg);
    const { code } = arg;
    // 기록물철 폴더 수정
    if (code === 'update') dispatch(sessionActions.setDrawer({ mode: code }));
    // 기록물철 순서 변경
    if (code === 'sequencechange')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 기록물철 폴더 삭제
    if (code === 'delete') {
      if (folders.find((a) => a.parentId === selectedId) !== undefined) {
        dispatch(
          sessionActions.error(
            getLocalizedText('하위 폴더가 있어 삭제할 수 없습니다.'),
          ),
        );
        return;
      }
      dispatch(sessionActions.setDialog({ mode: code }));
    }
  };

  const handleSequenceChange = (arg: { id: number; updateAt: string }[]) => {
    // console.log(`handleSequenceChange(arg)`, arg);
    const data = arg.map(({ id, updateAt }, index) => ({
      id,
      seq: index + 1,
      updateAt,
    }));
    const { pathname } = props;
    dispatch(archiveFolderActions.change({ data, route: { pathname } }));
  };

  const handleChangeFilter = (value: string) => {
    queryParams.pageNo = 1;
    if (value === '') delete queryParams.status;
    else queryParams.status = value;
    go(`${getPathMap('/*/*', props.pathname)}`, queryParams);
  };

  const handleCreate = (arg: {
    parentId: number;
    name: string;
    description: string;
  }) => {
    // console.log(`handleCreate(arg)`, arg);
    const { parentId, name, description } = arg;

    dispatch(
      archiveFolderActions.append({
        data: {
          organizationId,
          parentId,
          name,
          description,
        },
        route: { pathname: `${map}` },
      }),
    );
  };

  const handleUpdate = (arg: {
    id: number;
    parentId: number;
    name: string;
    description: string;
    updateAt: string;
  }) => {
    // console.log(`handleUpdate(arg)`, arg);
    const { pathname } = props;
    dispatch(
      archiveFolderActions.modify({
        data: { ...arg, organizationId },
        route: { pathname },
      }),
    );
  };

  const handleDelete = (arg: {
    organizationId: number;
    id: number;
    updateAt: string;
  }) => {
    // console.log(`handleDelete(arg)`, arg);
    dispatch(
      archiveFolderActions.remove({ data: arg, route: { pathname: `${map}` } }),
    );
  };

  /** 헤드 액션 */
  const handleHeadAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;
    const { code: type } = arg;
    // 이전 페이지인 경우.
    if (code === 'prevPage') {
      const pageNo = (queryParams.pageNo || 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(props.pathname, queryParams, props.hash);
      }
      return;
    }
    // 다음 페이지인 경우.
    if (code === 'nextPage') {
      const pageNo = (queryParams.pageNo || 1) + 1;
      if ((rowsPerPage || 1 * pageNo) < totalCount) {
        queryParams.pageNo = pageNo;
        go(props.pathname, queryParams, props.hash);
      }
    }
    if (type === 'listSetting') dispatch(sessionActions.setDrawer({ type }));
  };

  /** 드로워 렌터링 */
  const renderDrawer = () => {
    const { drawerType, drawerMode } = getQueryParams(props.search);

    // 기록물철 순서 변경
    if (drawerType === 'sequencechange') {
      const item = items.find(({ id }) => id === selectedId);

      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (item === undefined) return null;

      /** 순서 변경 항목. */
      const sequenceChangeItems = items
        .filter((a) => a.parentId === item.parentId && a.seq !== -1)
        .map(({ id, text: label, updateAt }) => ({ id, label, updateAt }));

      return (
        <ChangeOrder
          title={getLocalizedText('순서변경')}
          list={sequenceChangeItems}
          onChange={handleSequenceChange}
          onClose={() => dispatch(sessionActions.setDrawer())}
        />
      );
    }

    // 기록물철 폴더 생성
    if (drawerMode === 'create') {
      const filteredItems = items.filter(({ seq }) => seq !== -1);
      return (
        <ApprovalArchiveSettingsFolderEditDrawer
          folders={[
            {
              id: 0,
              parentId: -1,
              name: getLocalizedText('기록물'),
              icon: 'folder' as const,
            },
            ...filteredItems,
          ]}
          onSave={handleCreate}
          onClose={() => dispatch(sessionActions.setDrawer())}
        />
      );
    }
    // 기록물철 폴더 수정
    if (drawerMode === 'update') {
      const item = items.find(({ id }) => id === selectedId);

      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (item === undefined) return null;

      return (
        <ApprovalArchiveSettingsFolderEditDrawer
          id={item.id}
          parentId={item.parentId}
          name={item.name}
          description={item.description}
          updateAt={item.updateAt}
          folders={[
            {
              id: 0,
              parentId: -1,
              name: getLocalizedText('기록물'),
              icon: 'folder' as const,
            },
            ...items,
          ]}
          checkFolder={item.seq === folderSeq}
          onSave={handleUpdate}
          onClose={() => dispatch(sessionActions.setDrawer())}
        />
      );
    }

    return null;
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    const { dialogMode } = getQueryParams(props.search);
    if (dialogMode === 'delete') {
      const item = items.find(({ id }) => id === selectedId);

      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (item === undefined) return null;

      // TODO '하위 폴더가 있어 삭제할 수 없습니다.'
      if (item === undefined) return null;

      const { id, updateAt } = item;
      return (
        <DeleteConfirmation
          onSubmit={() => handleDelete({ organizationId, id, updateAt })}
          onCancel={() => dispatch(sessionActions.setDialog())}
        >
          <strong>&apos;{item.name}&apos;</strong> 폴더를 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }
    return null;
  };

  const item = items.find(({ id }) => id === selectedId);
  const parentNames = item
    ? getParentItems(items, item.parentId).map(({ text }) => text)
    : [];

  const renderBody = () => {
    const { pathname, hash, search } = props;

    if (hash === '#archivetransfer') {
      return <ApprovalArchiveTransferContainer pathname={pathname} />;
    }
    if (hash === '#transferhistory') {
      return (
        <ApprovalArchiveTransferHistoryContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    }
    if (!manager && !archiveManager) {
      return <AccessDenied />;
    }

    return (
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ApprovalArchiveSettingsFolderList
              selectedId={selectedId}
              items={items}
              onItemClick={handleListItemClick}
              onAction={handleListAction}
            />
          </EuiSetting.Left>
          {item ? (
            <EuiSetting.Right onClose={handleViewClose}>
              <ApprovalArchiveSettingsFolderView
                parentNames={parentNames}
                name={item.name}
                description={item.description}
                onAction={handleViewAction}
                checkFolder={item.seq === folderSeq}
              />
            </EuiSetting.Right>
          ) : (
            <SplitUnselected label={getLocalizedText('폴더를 선택하세요.')} />
          )}
        </EuiSetting>
      </EuiBody>
    );
  };

  return (
    <>
      <ApprovalArchiveSettingHead
        pathname={props.pathname}
        hash={props.hash}
        date={{
          start: queryParams.startDate
            ? timezoneDate(queryParams.startDate)
            : null,
          end: queryParams.endDate ? timezoneDate(queryParams.endDate) : null,
        }}
        searchWord={queryParams.searchWord}
        searchCode={queryParams.searchCode}
        onSearch={handleSearch}
        pageNo={queryParams.pageNo || 1}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        onAction={handleHeadAction}
        onChangeFilter={handleChangeFilter}
      />
      {renderBody()}
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default ApprovalArchiveSettingsContainer;
