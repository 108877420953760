import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  calendarsActions,
  SaveCalendarData,
} from '../../../../stores/calendar/calendars';
import CalendarFolderBoxList from '../../../common/CalendarFolderBoxList';
import CalendarFolderBoxView from '../../../common/CalendarFolderBoxView';
import CalendarFolderBoxEditDrawer from './CalendarUserPreferencesCalEditDrawer';

function CalendarUserPreferencesSharedCal(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();
  const pathmap = getPathMap('/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/:selectedId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const principal = useSelector((state: RootState) => state.session.principal);
  const list = useSelector(
    (state: RootState) => state.calendar.calendars.user.sharedList,
  );
  const view = useSelector((state: RootState) => state.calendar.calendars.view);
  const items = useMemo(() => {
    const folders = list.map((x) => {
      return {
        id: x.id,
        parentId: 0,
        status: x.status,
        seq: x.seq,
        text: x.name,
        icon: 'folder-fill' as const,
        color: x.color,
        strings: hangul.d(x.name),
      };
    });
    return folders;
  }, [list]);

  /** 모바일에만 적용. */
  const handleCloseView = () => {
    const route = { pathname: pathmap, search, hash };
    dispatch(sessionActions.setRoute(route));
  };

  const handleCRUD = (mode: 'read' | 'update') => {
    switch (mode) {
      case 'update':
        dispatch(sessionActions.setDrawer({ mode }));
        break;
      default:
        dispatch(sessionActions.search());
        break;
    }
  };

  /** 캘린더 클릭 이벤트 */
  const handleItemClick = (id: number) => {
    dispatch(
      calendarsActions.calendarView({
        type: 'shared',
        id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search,
          hash,
        },
      }),
    );
  };

  /** 캘린더 수정 이벤트. */
  const handleSave = (data: SaveCalendarData & { isNotManagers?: boolean }) => {
    const route = utils.getRoute({
      target: props,
      source: { pathname, search: '', hash },
    });
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: pathmap,
        search: '',
        hash,
        mode: 'replace',
      },
    });

    const { id, name, status, isNotManagers } = data;
    if (!id) return;

    /**
     * 공유권한 존재 여부.
     * 예외자에 사용자의 부서나 사용자가 존재하지 않고
     * 공유자 또는 관리자에 사용자의 부서나 사용자가 존재할 경우.
     */
    const isReadAutority =
      !data.exceptioners.some((a) => {
        if (a.referenceType === 'COMPANY')
          return a.referenceId === principal.companyId;
        if (a.referenceType === 'ORGANIZATION')
          return a.referenceId === principal.organizationId;
        return a.referenceId === principal.employeeId;
      }) &&
      (data.managers.some((a) => a.referenceId === principal.employeeId) ||
        data.users.some((a) => {
          if (a.referenceType === 'COMPANY')
            return a.referenceId === principal.companyId;
          if (a.referenceType === 'ORGANIZATION')
            return a.referenceId === principal.organizationId;
          return a.referenceId === principal.employeeId;
        }));

    // 관리자 권한이 없을 경우 캘린더명, 사용여부만 변경 가능.
    if (isNotManagers)
      dispatch(calendarsActions.changeSharedCal({ id, name, status, route }));
    else if (isReadAutority)
      dispatch(
        calendarsActions.updateMyCalendar({ type: 'shared', data, route }),
      );
    else
      dispatch(
        calendarsActions.updateMyCalendar({ type: 'shared', data, location }),
      );
  };

  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId
          ? getLocalizedText('폴더를 찾을 수 없습니다.')
          : undefined;
      return <SplitUnselected label={message} />;
    }
    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <CalendarFolderBoxView
          paths={getParentItems(items, view.id).map(({ text }) => text)}
          view={view}
          onUpdate={() => handleCRUD('update')}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerMode: mode } = queryParams;
    if (mode === 'update' && view) {
      const originName = list.find((a) => a.id === view.id)?.originalName ?? '';
      return (
        <CalendarFolderBoxEditDrawer
          type="sharedCal"
          onSave={handleSave}
          originName={originName}
          view={view}
          onClose={() => handleCRUD('read')}
        />
      );
    }
    return null;
  };

  return (
    <>
      <EuiSetting>
        <EuiSetting.Left>
          <CalendarFolderBoxList
            calendarId={selectedId}
            items={items}
            onItemClick={handleItemClick}
          />
        </EuiSetting.Left>
        {renderView()}
      </EuiSetting>
      {renderDrawer()}
    </>
  );
}

export default CalendarUserPreferencesSharedCal;
