import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  AttachDocument,
  AttachFile,
  documentApi,
  LinkType,
} from '../../../groupware-approval/apis/approval/v1/document';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import {
  CommentItem,
  CommentSaveArg,
  jsonToApprovalLine,
} from '../../../groupware-approval/stores/approval/document';
import { LocateArg } from '../../../groupware-common/types';
import { IconType } from '../../../groupware-common/types/icon';
import { getPathMap, getQueryParams } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timeFormat,
  timezoneDate,
} from '../../../groupware-common/utils/ui';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  ReadingPaneMode,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import attendancesApi from '../../apis/attendance/v1/documents';
import AttendancePreferencesApi from '../../apis/attendance/v1/preferences';

const name = 'attendance/attendances';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface FolderItem {
  type: 'status' | 'organization' | 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
  count?: number;
}

/** 사용자 - 신청내역 리스트 객체. */
interface AttendanceListItem {
  checked: boolean;
  companyId: number; // 회사 아이디.
  useId: number; // 근태 신청 아이디.
  useYear: string; // 기준 년도.
  employeeId: number; // 근태 신청 사원 아이디.
  approvalId: number; // 전자결재 문서 아이디
  useType: number; // 근태 코드(1 :연차 , 2: 휴가 , 3:출장, 4:교육, 5:경조, 6:대휴 , 7:외근, 8: 휴일근무, 9: 시간외근무, 10: 기타)
  subject: string; // 제목
  status: number; // 전자결재 상태 (1: 기안,  3: 완료, 4: 반려, 12: 회수, 44: 취소)
  startDate: string; // 시작 일자(yyyy-MM-dd)
  endDate: string; // 종료 일자(yyyy-MM-dd)
  useMinutes: string; // 사용시간.
  createAt: string; // 등록 날짜.
  updateAt: string; // 수정 시간(yyyy-MM-dd'T'HH:mm:ss.SSSSSS)
}

/** 사용자,관리자 - 대휴 리스트 객체. */
interface SubstituteListItem {
  employeeId: number; // 직원 아이디.
  year: string; // 기준 년도.
  occursAlternative: string; // 발생.
  modifingAlternative: string; // 조정일수.
  useAlternative: string; // 사용.
  remainedAlternative: string; // 잔여.
}

/** 근태 문서 뷰 객체 */
export interface AttendanceViewItem {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 문서 아이디
  workId: number; // 업무 아이디.
  workName: string; // 업무 이름.
  formId: number; // 양식 아이디.
  formName: string; // 양식 이름.
  status: string; // 결재상태 - 1:진행,2:요구,3:완료,4:삭제
  publicOrNot: boolean; // 공개 여부 - 공개:1, 비공개: 0
  no: string; // 문서번호.
  subject: string; // 제목
  contents: string; // 내용
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  draftAt: string; // 기안 날짜
  completeAt?: string; // 완료 날짜
  retentionPeriod: string; // 보존기간.
  option: number; // 업무 옵션.
  isTopLevelOrganizationNameNumbering: boolean; // 최상위 조직 이름 채번 여부.
  isNumberingAtCompose: boolean; // 작성 시점에 채번 여부.
  isSyncIncomingAndOutgoing: boolean; // 접수 후 내부 결재 시 부모 문서번호 승계 여부.
  isApprovalboxSignatureImage: boolean; // 결재란에 서명 이미지 사용 여부.
  change: boolean; // 문서 변경 유무
  isReReceipt: boolean; // 반려된 내부문서 재접수 여부.
  isReDraft: boolean; // 업무,양식 변경 여부.
  linkType: LinkType; // 연동 유형. (NONE: '없음', ATTENDANCE: '근태')
  linkId: string; // 연동 아이디.
  linkWait: boolean; // 연동 대기 여부. (true: 대기, false: 대기 없음)
  creatorId: number; // 생성자 아이디
  updateAt: string; // 수정 날짜
  attachedFiles?: AttachFile[]; // 첨부 파일 배열.
  attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
  opinions?: (CommentItem & { act: number })[]; // 의견 배열.
  comments?: CommentItem[]; // 댓글 배열.
  prev?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  next?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
}

/** 기본 폴더 */
const categories: FolderItem[] = [
  {
    type: 'default',
    id: 'main',
    name: '근태현황',
    icon: 'calendar-week',
  },
  {
    type: 'status',
    id: 'detail',
    name: '신청내역',
    icon: 'calendar-check',
  },
  {
    type: 'status',
    id: 'notice',
    name: '연차사용촉진 문서함',
    icon: 'clipboard-list',
  },
  {
    type: 'organization',
    id: 'dayOffStatus',
    name: '연차현황',
    icon: 'calendar-check',
  },
  {
    type: 'organization',
    id: 'substitute',
    name: '대휴현황',
    icon: 'calendar-check',
  },
  {
    type: 'organization',
    id: 'organizationDays',
    name: '기간별근태현황',
    icon: 'calendar-check',
  },
  {
    type: 'organization',
    id: 'organizationMonths',
    name: '월별근태현황',
    icon: 'calendar-check',
  },
  // 관리자 - 메뉴.
  {
    type: 'setting',
    id: 6001,
    name: '기본설정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6002,
    name: '기본공휴일',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6003,
    name: '휴일등록',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6014,
    name: '연차생성',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6004,
    name: '기본설정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6005,
    name: '연차조정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6006,
    name: '전사연차현황',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6007,
    name: '기본설정',
    icon: 'folder',
  }, // 연차사용촉진알림
  {
    type: 'setting',
    id: 6008,
    name: '발송내역',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6009,
    name: '대휴조정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6010,
    name: '대휴현황',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6011,
    name: '양식관리',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6012,
    name: '업무관리',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6013,
    name: '신청내역',
    icon: 'folder',
  },
];

interface State {
  requests: { id: string; type: string; arg: unknown }[];

  folder: {
    folders: FolderItem[];
  };
  list: {
    items: AttendanceListItem[];
    totalCount: number;
  };
  substituteList: {
    items: SubstituteListItem[];
    totalCount: number;
  };
  view: {
    data: AttendanceViewItem | undefined;
  };
  readingPaneMode: ReadingPaneMode;
  displayDensity: 'wide' | 'normal' | 'narrow';
}

const initialState: State = {
  requests: [],

  folder: {
    folders: categories,
  },
  list: {
    items: [],
    totalCount: 0,
  },
  substituteList: {
    items: [],
    totalCount: 0,
  },
  view: {
    data: undefined,
  },
  readingPaneMode: 'list',
  displayDensity: 'normal',
};

/** 사용자 - 신청내역 목록 조회. */
const findDetailedList = createAsyncThunk(
  `${name}/findDetailedList`,
  async (
    arg: {
      employeeId: number;
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { employeeId, search } = arg;
    try {
      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();
      const queryParams = getQueryParams(search);
      const newYearDate = timezoneDate();
      newYearDate.setMonth(0);
      newYearDate.setDate(1);
      const startDate =
        queryParams.startDate ??
        dateFormat(initialDate(newYearDate), 'yyyy-MM-DD');
      const endDate =
        queryParams.endDate ?? dateFormat(new Date(), 'YYYY-MM-DD');
      const items = (
        await attendancesApi.detailedList({
          employeeId,
          startDate,
          endDate,
          searchCode: queryParams.searchCode,
          searchWord: queryParams.searchWord,
          pageNo: queryParams.pageNo ?? 1,
          rowsPerPage: queryParams.rowsPerPage ?? 15,
        })
      ).map((a) => ({
        ...a,
        checked: false,
        startDate: dateTimeFormat(a.startDate, 'YYYY-MM-DD'),
        endDate: dateTimeFormat(a.endDate, 'YYYY-MM-DD'),
        createAt: dateTimeFormat(a.createAt, 'YYYY-MM-DD'),
        useMinutes: timeFormat(a.useMinutes, expressionUnit),
      }));
      const totalCount = await attendancesApi.detailedTotalCount({
        employeeId,
        startDate,
        endDate,
        searchCode: queryParams.searchCode,
        searchWord: queryParams.searchWord,
      });
      return {
        items,
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자,관리자 - 대휴 리스트 목록 조회. */
const findSubstituteList = createAsyncThunk(
  `${name}/findSubstituteList`,
  async (
    arg: { search: string } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const { search } = arg;
    const isAdmin =
      getPathMap('/*', arg.route?.pathname ?? '') === '/adminconsole';
    const queryParams = getQueryParams(search);
    try {
      const { principal } = (getState() as RootState).session;
      const organizationIds = principal.affiliatedOrganizations
        .filter((a) => a.manager === true)
        .map(({ id }) => id);
      if (!isAdmin && organizationIds.length === 0)
        throw new Error(getLocalizedText('접근 권한이 없습니다.'));

      const searchCode = isAdmin ? queryParams.searchCode : 'organization';
      let searchWord: string | undefined;
      if (isAdmin)
        searchWord =
          queryParams.searchCode === '' || queryParams.searchCode === undefined
            ? queryParams.searchWord
            : queryParams.directoryKeyword;
      else
        searchWord =
          queryParams.directoryKeyword ??
          `${principal.companyId}_${organizationIds[0]}`;
      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();
      const items = (
        await attendancesApi.substituteList({
          standardYear:
            queryParams.status ?? timezoneDate().getFullYear().toString(),
          searchCode,
          searchWord,
          pageNo: queryParams.pageNo ?? 1,
          rowsPerPage: queryParams.rowsPerPage ?? 15,
        })
      ).map((a) => {
        let modifingAlternative = '';
        if (a.modifingAlternative > 0)
          modifingAlternative = `+${timeFormat(
            a.modifingAlternative,
            expressionUnit,
          )}`;
        else
          modifingAlternative = timeFormat(
            a.modifingAlternative,
            expressionUnit,
          );
        return {
          ...a,
          occursAlternative: timeFormat(a.occursAlternative, expressionUnit),
          modifingAlternative,
          useAlternative: timeFormat(a.useAlternative, expressionUnit),
          remainedAlternative: timeFormat(
            a.remainedAlternative,
            expressionUnit,
          ),
        };
      });
      const totalCount = await attendancesApi.substituteTotalCount({
        standardYear: queryParams.status ?? moment().format('yyyy'),
        searchCode,
        searchWord,
      });

      return {
        items,
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태상세현황 보기 */
const findView = createAsyncThunk(
  `${name}/findView`,
  async (arg: { id: number } & LocateArg, { getState, rejectWithValue }) => {
    try {
      const { session } = getState() as RootState;

      const { companyId } = session.principal;
      const queryParams = getQueryParams(arg.route?.search ?? '');
      const affiliatedCompanyId = queryParams.affiliatedCompanyId
        ? parseInt(queryParams.affiliatedCompanyId, 10)
        : undefined;
      const response = await documentApi.attendanceData({
        affiliatedCompanyId,
        id: arg.id,
      });
      const { option } = response;

      // eslint-disable-next-line no-bitwise
      const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
      // eslint-disable-next-line no-bitwise
      const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

      const { attachedFileCount, attachedDocumentCount } = response;

      const opinions = response.opinionCount
        ? await documentApi.fetchOpinionList(companyId, arg.id)
        : [];
      const comments = response.commentCount
        ? await documentApi.fetchCommentList(companyId, arg.id)
        : [];
      const attachedFiles: AttachFile[] = [];
      if (attachedFileCount > 0) {
        const attachedList = await documentApi.fetchAttachfileList(arg.id);
        attachedList.forEach((a) => attachedFiles.push(a));
        attachedFiles.sort(
          (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
        );
      }

      const data: AttendanceViewItem = {
        ...response,
        approvalLine: jsonToApprovalLine(response.approvalline),
        no: response.number,
        referencePermission:
          response.referencePermission === '{}'
            ? undefined
            : JSON.parse(response.referencePermission),
        viewPermission:
          response.viewPermission === '{}'
            ? undefined
            : JSON.parse(response.viewPermission),
        draftAt: dateTimeFormat(response.draftAt, 'yyyy-MM-DD'),
        completeAt:
          response.completeAt === '1000-01-01'
            ? undefined
            : dateTimeFormat(response.completeAt, 'yyyy-MM-DD'),
        attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
        attachedDocuments:
          attachedDocumentCount > 0
            ? await documentApi.fetchAttachdocumentList(arg.id)
            : undefined,
        opinions: useOpinion ? opinions : undefined,
        comments: useComment ? comments : undefined,
        // eslint-disable-next-line no-bitwise
        isTopLevelOrganizationNameNumbering: (response.setting & 8) === 8, // 최상위 조직 이름 채번 사용: 8
        // eslint-disable-next-line no-bitwise
        isNumberingAtCompose: (response.setting & 1) === 1, // 작성 시점에 채번: 1, 완료 시점에 채번: 2
        // eslint-disable-next-line no-bitwise
        isSyncIncomingAndOutgoing: (response.setting & 4) === 4, // 접수 후 내부 결재 시 부모 문서번호 승계: 4
        // eslint-disable-next-line no-bitwise
        isApprovalboxSignatureImage: (response.setting & 16) === 16, // 결재란에 서명 이미지 사용: 16
        // eslint-disable-next-line no-bitwise
        isReReceipt: (response.formWorkChange & 4) === 4, // 반려된 내부문서 재접수: 4,
        // eslint-disable-next-line no-bitwise
        isReDraft: response.formWorkChange !== 0 || response.linkId !== '', // 업무,양식 변경: 1
        publicOrNot: response.isPublic,
        contents: response.content,
        // create, creatorOrganizationId
      };
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 댓글 등록 및 수정 */
const saveComment = createAsyncThunk(
  `${name}/saveComment`,
  async (arg: CommentSaveArg & LocateArg, { rejectWithValue }) => {
    try {
      const responseSave = await documentApi.saveComment(arg);
      if (!responseSave) return undefined;

      const response = await documentApi.fetchCommentList(
        arg.companyId,
        arg.documentId,
      );
      if (!response) return undefined;

      return response.map((a) => {
        return {
          ...a,
          createAt: a.updateAt, // TODO api에 생성 날짜 반환 추가 해야 합니다.
        };
      });
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 댓글 삭제 */
const deleteComment = createAsyncThunk(
  `${name}/deleteComment`,
  async (
    arg: {
      companyId: number;
      documentId: number;
      id: number;
      parentId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const responseDelete = await documentApi.deleteComment({
        companyId: arg.companyId,
        documentId: arg.documentId,
        id: arg.id,
        parentId: arg.parentId,
        updateAt: arg.updateAt,
      });
      if (!responseDelete) return undefined;
      const response = await documentApi.fetchCommentList(
        arg.companyId,
        arg.documentId,
      );
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 의견 등록 및 수정 */
const saveOpinion = createAsyncThunk(
  `${name}/saveOpinion`,
  async (
    arg:
      | {
          companyId: number;
          documentId: number;
          parentId: number;
          id?: undefined;
          act: number;
          contents: string;
        }
      | {
          companyId: number;
          documentId: number;
          parentId: number;
          id: number;
          act: number;
          contents: string;
          updateAt: string;
        },
    { getState, rejectWithValue },
  ) => {
    try {
      const response = await documentApi.saveOpinion(arg);

      const { companyId: employeeCompanyId, employeeId } = (
        getState() as RootState
      ).session.principal;
      const result = {
        ...arg,
        ...response,
        createAt: response.updateAt,
        employeeCompanyId,
        employeeId,
        likes: 0, // 좋아요 수
        dislikes: 0, // 싫어요 수
        loves: 0, // 사랑해요 수
      };
      return result;
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
);

/** 의견 삭제 */
const deleteOpinion = createAsyncThunk(
  `${name}/deleteOpinion`,
  async (
    arg: {
      companyId: number;
      documentId: number;
      id: number;
      parentId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const responseDelete = await documentApi.deleteOpinion({
        companyId: arg.companyId,
        documentId: arg.documentId,
        id: arg.id,
        parentId: arg.parentId,
        updateAt: arg.updateAt,
      });
      if (!responseDelete) return undefined;
      const response = await documentApi.fetchOpinionList(
        arg.companyId,
        arg.documentId,
      );
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const attendanceAttendancesReducer = createSlice({
  name,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (state.list.items) {
        if (action.payload.itemId === 'all') {
          state.list.items = state.list.items.map((a) => {
            if (a.checked === action.payload.checked) return a;
            return { ...a, checked: action.payload.checked };
          });
        } else {
          const index = state.list.items.findIndex(
            (a) => a.useId === action.payload.itemId,
          );
          if (index > -1) {
            state.list.items[index] = {
              ...state.list.items[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    setReadingPaneMode(state, action: PayloadAction<ReadingPaneMode>) {
      if (state.readingPaneMode !== action.payload) {
        state.readingPaneMode = action.payload;

        if (state.readingPaneMode === 'list') {
          // view data clear 시키기.
        }
      }
    },
    displayDensity(state, action: PayloadAction<'wide' | 'normal' | 'narrow'>) {
      if (state.displayDensity !== action.payload)
        state.displayDensity = action.payload;
    },
    viewClear(state) {
      state.view.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findDetailedList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.list = payload;
        }
      })
      .addCase(findSubstituteList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.substituteList = payload;
        }
      })
      .addCase(findView.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.view.data = payload;
        }
      })
      .addCase(saveComment.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.view.data)
          state.view.data.comments = payload;
      })
      .addCase(deleteComment.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.view.data)
          state.view.data.comments = payload;
      })
      .addCase(saveOpinion.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.view.data?.opinions) {
          if (state.view.data.opinions.find((a) => a.id === payload.id)) {
            state.view.data.opinions = state.view.data.opinions.map((a) => {
              if (a.id !== payload.id) return a;
              return {
                ...a,
                contents: payload.contents,
                updateAt: payload.updateAt,
              };
            });
          } else
            state.view.data.opinions = [...state.view.data.opinions, payload];
        }
      })
      .addCase(deleteOpinion.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.view.data)
          state.view.data.opinions = payload;
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default attendanceAttendancesReducer.reducer;

export const attendancesActions = {
  list: findDetailedList,
  substituteList: findSubstituteList,

  view: findView,

  saveComment,
  deleteComment,
  saveOpinion,
  deleteOpinion,

  checked: attendanceAttendancesReducer.actions.checked,
  setReadingPaneMode: attendanceAttendancesReducer.actions.setReadingPaneMode,
  displayDensity: attendanceAttendancesReducer.actions.displayDensity,
  viewClear: attendanceAttendancesReducer.actions.viewClear,
};
