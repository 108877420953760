/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CategoryItem, LocateArg } from '../../groupware-common/types';
import { appError } from '../../groupware-webapp/stores/common/utils';

import { getAvatarPath } from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import informationApi from '../apis/setting/v1/information';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

const name = 'setting/information';

/** 개인 정보 */
interface InformationItem {
  companyId: number;
  id: number;
  no: string;
  email: string;
  jobPositionId: number;
  personalEmail: string;
  companyPhoneNo: string;
  extensionPhoneNo: string;
  mobilePhoneNo: string;
  nameId: number;
  representativeOrganizationId: number;
  task: string;
  avatar: string;
  isUserAvatar: boolean; // 사용자 이미지 사용 유무 확인.
  enterDate: string;
  updateAt: string;
}

/** 개인 정보 수정 아이템 */
export interface SaveInformationItem {
  id: number;
  personalEmail?: string;
  companyPhoneNo?: string;
  extensionPhoneNo?: string;
  mobilePhoneNo?: string;
  task?: string;
  avatar?: string;
  updateAt: string;
}

/** 기본 폴더 */
const categories: CategoryItem<'basic' | 'setting', string>[] = [
  {
    type: 'basic',
    id: 'information',
    name: '기본정보',
    icon: 'person-fill',
  },
  {
    type: 'setting',
    id: 'setting',
    name: '기본설정',
    icon: 'cog-fill',
  },
  // { type: 'setting', id: 'alarm', name: '알림 설정', icon: 'alarm' },
];

interface State {
  categories: CategoryItem<'basic' | 'setting', string>[];
  person: InformationItem | undefined;
}

const initialState: State = {
  categories,
  person: undefined,
};

// 기본 정보 조회.
const findPersonalInformation = createAsyncThunk(
  `${name}/findPersonalInformaion`,
  async (_: LocateArg | void, { getState, rejectWithValue }) => {
    try {
      const { employeeId, companyId } = (getState() as RootState).session
        .principal;
      const result = await informationApi.employeeInfo({
        employeeId,
        companyId,
      });
      const enterDate =
        result.enterDate === '1000-01-01' ? '' : result.enterDate;

      const data: InformationItem = {
        companyId,
        id: result.id,
        email: result.email,
        personalEmail: result.personalEmail,
        no: result.no,
        nameId: result.id,
        representativeOrganizationId: result.representOrganizationId,
        jobPositionId: result.jobPositionId,
        enterDate,
        companyPhoneNo: result.companyPhoneNo,
        extensionPhoneNo: result.extensionPhoneNo,
        mobilePhoneNo: result.modilePhoneNo,
        task: result.task,
        avatar: `${getAvatarPath(companyId, result.id)}/nocache`,
        isUserAvatar: result.isUserAvatar,
        updateAt: result.updateAt,
      };
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 기본 정보 수정.
const updatePersonalInformation = createAsyncThunk(
  `${name}/updatePersonalInformation`,
  async (
    arg: SaveInformationItem & LocateArg,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { route, location, ...data } = arg;
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      await informationApi.updateEmployeeInfo({
        companyId,
        employeeId,
        data,
      });
      dispatch(findPersonalInformation());
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const informationSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findPersonalInformation.fulfilled, (state, { payload }) => {
      if (payload !== undefined) state.person = payload;
    });
  },
});

export default informationSlice.reducer;

export const informationActions = {
  personalInformation: findPersonalInformation,
  updateInformation: updatePersonalInformation,
};
