import React, { useState } from 'react';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import DropMenu from '../../../../../components/selectField/DropMenu';
import AttendanceWorkForm from './AttendanceWorkForm';
import WorkApprovalLineDialogContainer, {
  ApprovalLineType,
  SharePermissionType,
} from '../../../../../groupware-approval/pages/adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';
import ApprovalWorkDocumentNoDialog from '../../../../../groupware-approval/pages/adminconsole/approval/work/ApprovalWorkDocumentNoDialog';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { IconType } from '../../../../../groupware-common/types/icon';
import FeedBack from '../../../../../components/alert/FeedBack';
import SelectField from '../../../../../components/selectField/SelectField';
import { AttendanceCodeList } from '../../../../stores/attendance/preferences';

type Props = {
  attendanceCodeList: AttendanceCodeList[];
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  folderPaths: string[]; // 폴더 경로 배열.
  folderId: number; // 폴더 아이디.
  onClose(): void;
  onSave(arg: {
    id: number | undefined;
    folderPaths: string[];
    folderId: number;
    name: string;
    formId: number;
    formName: string;
    documentNo: string;
    status: number;
    retentionPeriod: number;
    approvalLine: ApprovalLineType;
    referencePermission: SharePermissionType | undefined;
    viewPermission: SharePermissionType | undefined;
    useAttachFile: number;
    description: string;
  }): void;

  id?: number;
  name?: string; // 이름.
  formId?: number; // 양식 아이디.
  formName?: string; // 양식 이름.
  receiptFormId?: number; // 내부결재 양식 아이디.
  receiptFormName?: string; // 내부결재 양식 이름.
  documentNo?: string; // 문서 번호.
  status?: number; // 상태. - 1: 사용, 2: 중지
  retentionPeriod?: number; // 보존기간.
  approvalLine?: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  useAttachFile?: number; // 첨부 파일 사용 여부. - 0: 사용 안 함, 1: 사용, 2: 필수'
  description?: string; // 설명.
  attendanceCode?: number;
};

function AttendanceWorkEditWork(props: Props): JSX.Element {
  const defaultDocumentNo = '제 {년도} {순번}';

  const { onClose } = props;
  const useLabel = [
    { value: true, label: getLocalizedText('사용함') },
    { value: false, label: getLocalizedText('사용안함') },
  ];

  const [state, setState] = useState<{
    validation: string;

    folderSelectDialogVisible: boolean; // 폴더 선택 대화 상자 보임 여부.
    formSelectDialogVisible: boolean; // 양식 선택 대화 상자 보임 여부.
    approvalLineSelectDialogVisible: boolean; // 결재선 선택 대화 상자 보임 여부.
    documentNoSelectDialogVisible: boolean; // 문서번호 선택 대화 상자 보임 여부.

    retentionPermanence: boolean; // 보존 영구 여부.
    retentionYear: string; // 보존 연도.
    retentionMonth: string; // 보존 월.

    receiptFormId: number; // 내부결재 양식 아이디.
    receiptFormName: string; // 내부결재 양식 이름.
    receiptFormSelectDialogVisible: boolean; // 내부결재 양식 선택 대화 상자 보임 여부.

    folderPaths: string[]; // 폴더 경로 배열.
    folderId: number; // 폴더 아이디.
    name: string; // 이름.
    formId: number; // 양식 아이디.
    formName: string; // 양식 이름.
    documentNo: string; // 문서 번호.
    status: number; // 상태. - 1: 사용, 2: 중지
    approvalLine?: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    useAttachFile: number; // 첨부 파일 사용 여부. - 0: 사용 안 함, 1: 사용, 2: 필수'
    description: string; // 설명.
    attendanceCode: number; // 근태코드.
  }>(() => {
    const retentionPeriod = props.retentionPeriod || 0;

    let retentionPermanence = true; // 보존 영구 여부.
    let retentionYear = ''; // 보존 연도.
    let retentionMonth = ''; // 보존 월.

    if (retentionPeriod !== 0) {
      retentionPermanence = false;
      const year = Math.floor(retentionPeriod / 365);
      retentionYear = year > 0 ? `${year}` : '';
      const month = (retentionPeriod % 365) / 30;
      retentionMonth = month > 0 ? `${month}` : '';
    }

    return {
      validation: '',

      folderSelectDialogVisible: false,
      formSelectDialogVisible: false,
      approvalLineSelectDialogVisible: false,
      documentNoSelectDialogVisible: false,

      retentionPermanence,
      retentionYear,
      retentionMonth,

      receiptFormId: props.receiptFormId || 0,
      receiptFormName: props.receiptFormName || '',
      receiptFormSelectDialogVisible: false,

      folderPaths: props.folderPaths || [],
      folderId: props.folderId || 0,
      name: props.name || '',
      formId: props.formId || 0,
      formName: props.formName || '',
      documentNo: props.documentNo || defaultDocumentNo,
      status: props.status || 1,
      approvalLine: props.approvalLine || undefined,
      referencePermission: props.referencePermission || undefined,
      viewPermission: props.viewPermission || undefined,
      useAttachFile: props.useAttachFile || 1,
      description: props.description || '',
      attendanceCode: props.attendanceCode || props.attendanceCodeList[0].id,
    };
  });

  /** 폴더 선택 대화 상자 열기. */
  const handleFolderSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      folderSelectDialogVisible: true,
    }));
  };

  /** 폴더 선택 대화 상자 닫기. */
  const handleFolderSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      folderSelectDialogVisible: false,
    }));
  };

  /** 폴더 선택 대화 상자 확인. */
  const handleFolderSelectDialogConfirm = (
    id: number,
    text: string,
    path: string[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      folderPaths: path,
      folderId: id,
      folderSelectDialogVisible: false,
    }));
  };

  /** 이름 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: event.target.value }));
  };

  /** 양식 선택 대화 상자 열기. */
  const handleFormSelectDialogOpen = () => {
    setState((prevState) => ({ ...prevState, formSelectDialogVisible: true }));
  };

  /** 양식 선택 대화 상자 닫기. */
  const handleFormSelectDialogClose = () => {
    setState((prevState) => ({ ...prevState, formSelectDialogVisible: false }));
  };

  /** 양식 선택 대화 상자 확인. */
  const handleFormSelectDialogConfirm = (arg: { id: number; name: string }) => {
    // console.log(`handleFormSelectDialogConfirm(arg)`, arg);
    const { id: formId, name: formName } = arg;
    setState((prevState) => ({
      ...prevState,
      formId,
      formName,
      formSelectDialogVisible: false,
    }));
  };

  /** 내부결재 양식 선택 대화 상자 열기. */
  const handleReceiptFormSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      receiptFormSelectDialogVisible: true,
    }));
  };

  /** 내부결재 양식 선택 대화 상자 닫기. */
  const handleReceiptFormSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      receiptFormSelectDialogVisible: false,
    }));
  };

  /** 양식 선택 대화 상자 확인. */
  const handleReceiptFormSelectDialogConfirm = (arg: {
    id: number;
    name: string;
  }) => {
    const { id: receiptFormId, name: receiptFormName } = arg;
    setState((prevState) => ({
      ...prevState,
      receiptFormId,
      receiptFormName,
      receiptFormSelectDialogVisible: false,
    }));
  };

  /** 결재선 선택 대화 상자 열기. */
  const handleApprovalLineSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      approvalLineSelectDialogVisible: true,
    }));
  };

  /** 결재선 선택 대화 상자 닫기. */
  const handleApprovalLineSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      approvalLineSelectDialogVisible: false,
    }));
  };

  /** 결재선 선택 대화 상자 확인. */
  const handleApprovalLineSelectDialogConfirm = (arg: {
    approvalLine: ApprovalLineType;
    referencePermission: SharePermissionType;
    viewPermission: SharePermissionType;
  }) => {
    setState((prevState) => ({
      ...prevState,
      approvalLine: arg.approvalLine,
      referencePermission: arg.referencePermission,
      viewPermission: arg.viewPermission,
      approvalLineSelectDialogVisible: false,
    }));
  };

  /** 첨부파일 필수 여부 변경. */
  const handleUseAttachFileChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, useAttachFile: value ? 2 : 1 }));
  };

  /** 보존 기간 영구 변경. */
  const handleRetentionPermanenceChange = () => {
    setState((prevState) => ({
      ...prevState,
      retentionPermanence: !prevState.retentionPermanence,
    }));
  };

  /** 보존 연도 변경. */
  const handleRetentionYearChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionYear = event.target.value;
    setState((prevState) => ({ ...prevState, retentionYear }));
  };

  /** 보존 월 변경. */
  const handleRetentionMonthChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionMonth = event.target.value;
    setState((prevState) => ({ ...prevState, retentionMonth }));
  };

  /** 문서번호 선택 대화 상자 열기. */
  const handleDocumentNoSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      documentNoSelectDialogVisible: true,
    }));
  };

  /** 문서번호 선택 대화 상자 닫기. */
  const handleDocumentNoSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      documentNoSelectDialogVisible: false,
    }));
  };

  /** 문서번호 선택 대화 상자 확인. */
  const handleDocumentNoSelectDialogConfirm = (values: string) => {
    setState((prevState) => ({
      ...prevState,
      documentNo: values,
      documentNoSelectDialogVisible: false,
    }));
  };

  /** 문서번호 초기화. */
  const handleDocumentNoReset = () => {
    setState((prevState) => ({ ...prevState, documentNo: defaultDocumentNo }));
  };

  /** 설명 변경. */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };

  /** 사용 여부 변경 */
  const handleStatusChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, status: value ? 1 : 2 }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const handleAttendanceCodeChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      attendanceCode: parseInt(value, 10),
    }));
  };

  /** 저장. */
  const handleSave = () => {
    // console.log(`handleSave():state`, state);
    const {
      folderId,
      name,
      formId,
      receiptFormId,
      approvalLine,
      referencePermission,
      viewPermission,
      retentionPermanence,
      retentionYear,
      retentionMonth,
    } = state;
    const receiveStatus =
      approvalLine && approvalLine.groups.some((a) => a.type === 'receive');

    let validation = '';
    if (folderId === 0)
      validation = getLocalizedText('근태 업무 분류를 선택하세요.');
    if (name.trim() === '')
      validation = getLocalizedText('업무명을 입력하세요.');
    else if (formId === 0) validation = getLocalizedText('양식을 선택하세요.');
    else if (approvalLine === undefined)
      validation = getLocalizedText('결재선을 지정하세요.');
    else if (receiveStatus === true && receiptFormId === 0)
      validation = getLocalizedText('내부결재 양식을 선택하세요.');
    if (validation !== '') {
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (approvalLine === undefined) return;

    let year = parseInt(retentionYear, 10) || 0;
    let month = parseInt(retentionMonth, 10) || 0;
    if (month > 11) {
      year += Math.floor(month / 12);
      month %= 12;
    }

    const { id, onSave } = props;

    const param = {
      id,
      folderPaths,
      folderId,
      name,
      formId,
      formName,
      receiptFormId: receiveStatus === true ? receiptFormId : 0,
      documentNo,
      status,
      retentionPeriod: !retentionPermanence ? year * 365 + month * 30 : 0,
      approvalLine,
      referencePermission,
      viewPermission,
      useAttachFile,
      description,
      attendanceCode,
    };
    // console.log(`param`, param);
    onSave(param);
  };

  const renderDialog = () => {
    // 폴더 선택 대화 상자가 보임인 경우.
    if (state.folderSelectDialogVisible) {
      const { folders } = props;
      const { folderId } = state;
      return (
        <TreePicker
          title={getLocalizedText('분류 선택')}
          list={folders}
          selectedId={folderId}
          onSelected={handleFolderSelectDialogConfirm}
          onClose={handleFolderSelectDialogClose}
        />
      );
    }

    // 양식 선택 대화 상자가 보임인 경우.
    if (state.formSelectDialogVisible) {
      return (
        <AttendanceWorkForm
          onConfirm={handleFormSelectDialogConfirm}
          onClose={handleFormSelectDialogClose}
        />
      );
    }

    // 내부결재 양식 선택 대화 상자가 보임인 경우.
    if (state.receiptFormSelectDialogVisible) {
      return (
        <AttendanceWorkForm
          onConfirm={handleReceiptFormSelectDialogConfirm}
          onClose={handleReceiptFormSelectDialogClose}
        />
      );
    }

    // 결재선 선택 대화 상자가 보임인 경우.
    if (state.approvalLineSelectDialogVisible) {
      const { approvalLine, referencePermission, viewPermission } = state;
      return (
        <WorkApprovalLineDialogContainer
          approvalLine={approvalLine}
          referencePermission={referencePermission}
          viewPermission={viewPermission}
          onCancel={handleApprovalLineSelectDialogClose}
          onSave={handleApprovalLineSelectDialogConfirm}
        />
      );
    }

    // 문서번호 선택 대화 상자가 보임인 경우.
    if (state.documentNoSelectDialogVisible)
      return (
        <ApprovalWorkDocumentNoDialog
          no={state.documentNo}
          onConfirm={handleDocumentNoSelectDialogConfirm}
          onClose={handleDocumentNoSelectDialogClose}
        />
      );

    return null;
  };

  const {
    validation,

    documentNo,
    retentionPermanence,
    retentionYear,
    retentionMonth,

    folderPaths,
    name,
    status,
    formName,
    receiptFormName,
    approvalLine,
    useAttachFile,
    description,
    attendanceCode,
  } = state;

  const documentNoPreview = documentNo
    .replace(/{([^}]*)}/gm, '$1')
    .replace(/┼/gm, '');
  // console.log(`docNo: '${documentNo}', docNoPreview: '${documentNoPreview}'`);

  const attendanceCodeItem: { value: string; label: string }[] =
    props.attendanceCodeList.map((a) => {
      return {
        value: a.id.toString(),
        label: a.name,
      };
    });

  return (
    <>
      <Drawer title={getLocalizedText('업무 등록')} onClose={onClose}>
        <DrawerBody>
          <PostWrite>
            <PostWrite.Item required title={getLocalizedText('근태업무분류')}>
              <DropMenu
                value={folderPaths}
                label={getLocalizedText('근태업무분류 선택')}
                onClick={handleFolderSelectDialogOpen}
              />
            </PostWrite.Item>
            <PostWrite.Item required title={getLocalizedText('근태코드')}>
              <SelectField
                data={attendanceCodeItem}
                value={`${attendanceCode}`}
                onChange={handleAttendanceCodeChange}
              />
            </PostWrite.Item>
            <PostWrite.Item required title={getLocalizedText('업무명')}>
              <TextField value={name} onChange={handleNameChange} />
            </PostWrite.Item>
            <PostWrite.Item required title={getLocalizedText('양식')}>
              <DropMenu
                value={formName}
                label={getLocalizedText('양식 선택')}
                onClick={handleFormSelectDialogOpen}
                block
              />
            </PostWrite.Item>
            {approvalLine &&
              approvalLine.groups.some((a) => a.type === 'receive') && (
                <PostWrite.Item
                  required
                  title={getLocalizedText('내부결재 양식')}
                >
                  <DropMenu
                    value={receiptFormName}
                    label={getLocalizedText('양식 선택')}
                    onClick={handleReceiptFormSelectDialogOpen}
                    block
                  />
                </PostWrite.Item>
              )}
            <PostWrite.Item required title={getLocalizedText('결재선')}>
              <DropMenu
                value=""
                label={getLocalizedText('결재선 지정')}
                onClick={handleApprovalLineSelectDialogOpen}
                block
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('첨부파일 필수')}>
              <RadioGroup
                data={useLabel}
                value={useAttachFile === 2}
                name="useAttachFile"
                onChange={handleUseAttachFileChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('보존기간')}>
              <PostWrite.ItemArea>
                {retentionPermanence ? (
                  <CheckboxGroup>
                    <Checkbox
                      label={getLocalizedText('영구보존')}
                      checked={retentionPermanence}
                      onChange={handleRetentionPermanenceChange}
                    />
                  </CheckboxGroup>
                ) : (
                  <>
                    <TextField
                      label={getLocalizedText('년')}
                      value={retentionYear}
                      onChange={handleRetentionYearChange}
                      width={80}
                      maxLength={2}
                      pattern="[0-9]*"
                    />
                    <TextField
                      label={getLocalizedText('개월')}
                      value={retentionMonth}
                      onChange={handleRetentionMonthChange}
                      width={80}
                      maxLength={2}
                      pattern="[0-9]*"
                    />
                    <Checkbox
                      label={getLocalizedText('영구보존')}
                      checked={retentionPermanence}
                      onChange={handleRetentionPermanenceChange}
                    />
                  </>
                )}
              </PostWrite.ItemArea>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('문서번호')}>
              <PostWrite.ItemArea>
                <DropMenu
                  value={documentNoPreview}
                  label={documentNoPreview}
                  onClick={handleDocumentNoSelectDialogOpen}
                />
                <Button
                  text={getLocalizedText('초기화')}
                  onClick={handleDocumentNoReset}
                />
              </PostWrite.ItemArea>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('업무설명')}>
              <TextField
                multiline
                rows={3}
                value={description}
                onChange={handleDescriptionChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사용여부')}>
              <RadioGroup
                data={useLabel}
                value={status === 1}
                name="status"
                onChange={handleStatusChange}
              />
            </PostWrite.Item>
          </PostWrite>
          {renderDialog()}
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getLocalizedText('저장')}
            onClick={handleSave}
            variant="contained"
          />
        </DrawerAction>
      </Drawer>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceWorkEditWork;
