import React from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Tab from '../../../../../components/tab/Tab';
import { go } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { RootState } from '../../../../../groupware-webapp/app/store';

function ApprovalPreferencesHead(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${ApprovalPreferencesHead.name}.render(props)`, props);

  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'default');
  // 고정 폴더 이름
  const title = categories.find((a) => a.id === 5003)?.name;
  const menus = [
    { id: '', label: getLocalizedText('개인 환경설정') },
    {
      id: '#notifications',
      label: getLocalizedText('개인 알림설정'),
    },
    {
      id: '#approvalline',
      label: getLocalizedText('개인 결재선 관리'),
    },
    {
      id: '#folder',
      label: getLocalizedText('개인 결재함 관리'),
    },
  ];

  const { pathname, search, hash } = props;
  return (
    <EuiHeader>
      <EuiHeader.Content>
        <EuiHeader.Title>{getLocalizedText(`폴더.${title}`)}</EuiHeader.Title>
        <Tab>
          {menus.map(({ id, label }) => (
            <Tab.Item
              key={id}
              label={label}
              selected={id === hash}
              onClick={() => go(pathname, search, id)}
            />
          ))}
        </Tab>
      </EuiHeader.Content>
    </EuiHeader>
  );
}

export default ApprovalPreferencesHead;
