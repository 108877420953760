import React, { useState } from 'react';
import { Language } from '../../../../../groupware-common/types';
import Button from '../../../../../components/button/Button';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import DropMenu from '../../../../../components/selectField/DropMenu';
import MultilingualTextField from '../../../../../components/textfield/MultilingualTextField';
import Menu from '../../../../../components/menu/Menu';
import MenuItem from '../../../../../components/menu/MenuItem';
import {
  DirectoryTreeItemArg,
  getDirectoryTreeId,
} from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../containers/DirectoryMenuTreeContainer';
import Drawer from '../../../../../components/drawer/Drawer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type Props = {
  parentNames: string[];

  onCreate?(arg: {
    parentId: number;
    names: { label: Language; value: string }[];
    managerId: number;
    description: string;
  }): void;
  onUpdate?(arg: {
    parentId: number | undefined;
    names: { label: Language; value: string | null }[] | undefined;
    managerId: number | undefined;
    description: string | undefined;
  }): void;
  onClose(): void;

  companyId: number;
  id?: number;
  parentId: number;
  names?: { label: Language; value: string }[];
  managerId?: number;
  description?: string;

  members?: { id: number; text: string; avatar: string }[];
};

function OrganizationChartContentOrganizationEdit(dummy: Props): JSX.Element {
  // console.log(`${OrganizationChartContentOrganizationEdit.name}.render`);

  const props = {
    ...dummy,
    names: dummy.names || [{ label: 'ko-KR', value: '' }],
    managerId: dummy.managerId || 0,
    description: dummy.description || '',
  };

  const mode = props.id === undefined ? 'create' : 'update';

  const [state, setState] = useState(() => {
    let directoryMenuTreeProps:
      | {
          x: number;
          y: number;
          width?: number;
          height?: number;
        }
      | undefined;
    let managerMenuProps:
      | {
          x: number;
          y: number;
          width?: number;
          height?: number;
        }
      | undefined;

    const { managerId, members = [] } = props;
    return {
      parentId: props.parentId,
      parentNames: props.parentNames,
      names: props.names,
      managerId,
      managerName: members.find((a) => a.id === managerId)?.text || '',
      description: props.description,

      directoryMenuTreeProps,
      managerMenuProps,

      validation: {
        name: '',
      },
    };
  });

  const handleNameChange = (label: Language, value: string) => {
    setState({
      ...state,
      names: state.names.map((x) =>
        x.label === label ? { ...x, ...{ label, value } } : x,
      ),
    });
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState({ ...state, description: event.target.value });
  };

  const handleSave = () => {
    const { onCreate, onUpdate } = props;
    if (onCreate) {
      const { parentId, names, managerId, description, validation } = state;

      const newValidation = { ...validation };

      if (names[0] === undefined || names[0].value.trim() === '')
        newValidation.name = getLocalizedText('이름은 빈 값 일 수 없습니다.');
      else newValidation.name = '';

      if (newValidation.name !== '') {
        setState((prev) => ({ ...prev, validation: newValidation }));
        return;
      }

      const param = {
        parentId,
        names: names.filter(({ value }) => value.trim() !== ''),
        managerId,
        description,
      };

      // console.log('----------onCreate:props', props);
      // console.log('----------onCreate:state', state);
      // console.log('----------onCreate:param', param);
      onCreate(param);
    } else if (onUpdate) {
      const { parentId, managerId, description, validation } = state;

      const newValidation = { ...validation };

      if (state.names[0] === undefined || state.names[0].value.trim() === '')
        newValidation.name = getLocalizedText('이름은 빈 값 일 수 없습니다.');
      else newValidation.name = '';

      if (newValidation.name !== '') {
        setState((prev) => ({ ...prev, validation: newValidation }));
        return;
      }

      const names: { label: Language; value: string | null }[] = [];
      props.names.forEach((a, i) => {
        const b = state.names[i];
        if (a.label === b.label && a.value !== b.value) {
          if (b.value.trim() !== '')
            names.push({ label: b.label, value: b.value });
          else names.push({ label: b.label, value: null });
        }
      });

      const param = {
        parentId: props.parentId === parentId ? undefined : parentId,
        names: names.length === 0 ? undefined : names,
        managerId: props.managerId === managerId ? undefined : managerId,
        description:
          props.description === description ? undefined : description,
      };

      // if (
      //   param.parentId === undefined &&
      //   param.names === undefined &&
      //   param.managerId === undefined &&
      //   param.description === undefined
      // )
      //   console.log('----------param', undefined);
      // else console.log('----------param', param);

      onUpdate(param);
    }
  };

  const handleDirectoryMenuTreeToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.directoryMenuTreeProps) handleDirectoryMenuTreeClose();
    else {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      const directoryMenuTreeProps = { x, y, width, height };
      setState((prevState) => ({ ...prevState, directoryMenuTreeProps }));
    }
  };
  const handleDirectoryMenuTreeClose = () => {
    setState((prev) => ({ ...prev, directoryMenuTreeProps: undefined }));
  };
  const handleDirectoryMenuTreeItemClick = ({
    item: {
      extra: { organizationId },
    },
    paths,
  }: DirectoryTreeItemArg) => {
    setState((prevState) => ({
      ...prevState,
      parentId: organizationId || companyId,
      parentNames: paths.map(({ name }) => name),
      directoryMenuTreeProps: undefined,
    }));
  };

  const handleManagerMenuToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.managerMenuProps) handleManagerMenuClose();
    else {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      const managerMenuProps = { x, y, width, height };
      setState((prevState) => ({ ...prevState, managerMenuProps }));
    }
  };
  const handleManagerMenuClose = () => {
    setState((prevState) => ({ ...prevState, managerMenuProps: undefined }));
  };
  const handleManagerMenuItemClick = (id: number, name: string) => {
    setState((prevState) => ({
      ...prevState,
      managerId: id,
      managerName: name,
      managerMenuProps: undefined,
    }));
  };

  const { companyId, id, members } = props;
  const {
    parentId,
    directoryMenuTreeProps,
    managerName,
    managerMenuProps,
    validation,
  } = state;
  const title =
    mode === 'create'
      ? getLocalizedText('조직등록')
      : getLocalizedText('조직수정');
  const ignoreMenuTreeId =
    mode === 'create' ? undefined : getDirectoryTreeId(companyId, id);
  return (
    <Drawer title={title} onClose={props.onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('상위조직')}>
            <DropMenu
              value={state.parentNames}
              label={getLocalizedText('상위조직 선택')}
              onClick={handleDirectoryMenuTreeToggle}
            />
            <DirectoryMenuTreeContainer
              point={directoryMenuTreeProps}
              organizationOnly
              ignoreId={ignoreMenuTreeId}
              selectedId={getDirectoryTreeId(companyId, parentId)}
              onItemClick={handleDirectoryMenuTreeItemClick}
              onClose={handleDirectoryMenuTreeClose}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('조직명')}>
            <MultilingualTextField
              value={state.names.map(({ label: language, value }) => ({
                language,
                value,
              }))}
              onChange={handleNameChange}
              error={validation.name !== ''}
            />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('설명')}>
            <TextField
              multiline
              value={state.description}
              onChange={handleDescriptionChange}
            />
          </PostWrite.Item>
          {members && (
            <PostWrite.Item title={getLocalizedText('조직 관리자')}>
              <DropMenu
                value={managerName}
                label={getLocalizedText('조직 관리자 선택')}
                onClick={handleManagerMenuToggle}
              />
              {managerMenuProps && (
                <Menu point={managerMenuProps} onClose={handleManagerMenuClose}>
                  {members.map((a) => {
                    return (
                      <MenuItem
                        key={a.id}
                        label={a.text}
                        avatar={a.avatar}
                        onClick={() => handleManagerMenuItemClick(a.id, a.text)}
                      />
                    );
                  })}
                </Menu>
              )}
            </PostWrite.Item>
          )}
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default OrganizationChartContentOrganizationEdit;
