import React, { useEffect } from 'react';
import { approvalArbitraryDecisionActions } from '../../groupware-approval/stores/approval/arbitraryDecisions';
import { preferencesActions } from '../../groupware-approval/stores/approval/preferences';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import { useAppDispatch } from '../../groupware-webapp/app/store';
import SecuritiesAuthorityRoute from '../pages/root/security/authority/SecuritiesAuthorityRoute';
import SecuritiesComposeRoute from '../pages/root/security/compose/SecuritiesComposeRoute';
import SecuritiesContentRoute from '../pages/root/security/content/SecuritiesContentRoute';
import SecuritiesPaymentRoute from '../pages/root/security/payment/SecuritiesPaymentRoute';
import { settingActions } from '../stores/sangsanginsecurities/setting';

function SecuritiesLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey: key } = props;
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(preferencesActions.findBasic());
    dispatch(approvalArbitraryDecisionActions.fetchArbitraryDecision());
    dispatch(settingActions.approvalAuthorityTeam());
  }, [dispatch]);

  if (queryParams.contentMode === 'create')
    return (
      <SecuritiesComposeRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );

  if (folderId === 'authority')
    return (
      <SecuritiesAuthorityRoute pathname={pathname} search={search} key={key} />
    );
  if (folderId === 'payment')
    return (
      <SecuritiesPaymentRoute pathname={pathname} search={search} key={key} />
    );
  return (
    <SecuritiesContentRoute pathname={pathname} search={search} key={key} />
  );
}

export default SecuritiesLocateRoute;
