import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import EmptyData from '../../../../../components/data/EmptyData';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import EuiBody from '../../../../../components/layout/EuiBody';
import Loading from '../../../../../components/loading/Loading';
import PostWrite from '../../../../../components/post/PostWrite';
import SelectField from '../../../../../components/selectField/SelectField';
import TreeList from '../../../../../components/tree/TreeList';
import { IconType } from '../../../../../groupware-common/types/icon';
import { hangul } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import resourcePreferencesApi from '../../../../../groupware-resource/apis/resource/v1/preferences';
import resourcesApi from '../../../../../groupware-resource/apis/resource/v1/resources';
import resourceFolderApi from '../../../../../groupware-resource/apis/resource/v1/folder';
import { ResourceListItem } from '../../../../../groupware-resource/stores/folders';
import { ReservationList } from '../../../../../groupware-resource/stores/resources';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { makeTreeData } from '../content/CalendarLookupDialog';

function CalendarResourceSelect(props: {
  resourcesItems: ResourceListItem[];
  resourceItemId?: number;
  start: Date;
  end: Date;
  onResourceSelectConfirm(arg: {
    start: Date;
    end: Date;
    checkResource: number;
  }): void;
  onClose(): void;
}): JSX.Element {
  const { resourcesItems } = props;
  const display = useSelector((state: RootState) => state.session.display);
  const [state, setState] = useState<{
    loading: boolean;
    resourceData: {
      id: number;
      parentId: number;
      text: string;
      strings: string[][];
      icon: IconType;
    }[];
    reservationList: ReservationList[];
    isAvailablePastReservation: boolean;
    start: Date;
    end: Date;
    selectDate: Date;
    checkResourceItem?: {
      type: 'folder' | 'item';
      id: number;
    };
  }>(() => {
    const item = props.resourceItemId
      ? props.resourcesItems.find((a) => a.id === props.resourceItemId)
      : undefined;
    return {
      loading: true,
      resourceData: [],
      reservationList: [],
      isAvailablePastReservation: false,
      start: props.start,
      end: props.end,
      selectDate: props.start,
      checkResourceItem: item ? { type: 'item', id: item.id } : undefined,
    };
  });
  const [validation, setValidation] = useState('');

  useEffect(() => {
    async function run() {
      const folders = await resourceFolderApi.list();
      const { isAvailablePastReservation } =
        await resourcePreferencesApi.setting();
      const resourceItem = props.resourcesItems.map((a) => {
        return {
          id: a.id,
          parentId: a.folderId,
          text: a.name,
          strings: hangul.d(a.name),
          // eslint-disable-next-line prettier/prettier
          icon: a.useApprove ? 'document-check' as const : 'document-clock' as const,
          seq: a.seq,
        };
      });
      const treeData = makeTreeData([
        ...folders.map((a) => {
          return {
            id: a.id,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as const,
            seq: a.seq,
          };
        }),
        ...resourceItem,
      ]);
      setState((prev) => ({
        ...prev,
        resourceData: treeData,
        isAvailablePastReservation,
        loading: false,
      }));
    }
    run();
  }, []);

  useEffect(() => {
    async function run() {
      if (!state.checkResourceItem) return;
      const { type, id } = state.checkResourceItem;
      const initialStart = initialDate(state.start);
      const initialEnd = initialDate(state.end);
      const reservationList = (
        await resourcesApi.reservationList({
          type,
          id,
          fromDate: dateFormat(initialStart, 'yyyy-MM-DD'),
          toDate: dateFormat(initialEnd, 'yyyy-MM-DD'),
        })
      ).map((a) => ({
        ...a,
        startDateTime: dateTimeFormat(a.startDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
        endDateTime: dateTimeFormat(a.endDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
      }));
      setState((prev) => ({ ...prev, reservationList }));
    }
    run();
  }, [state.checkResourceItem, state.start, state.end]);

  /** 날짜 변경. */
  const handleChangeDate = (date: Date | null, type: string) => {
    if (date === null) return;
    date.setSeconds(0);
    let selectDate = new Date(state.selectDate);
    if (type === 'start') {
      let { end } = state;
      if (end <= date) {
        end = new Date(date);
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
        end.setSeconds(0);
      }
      if (selectDate < date) selectDate = new Date(date);
      setState((prev) => ({
        ...prev,
        start: date,
        end,
        selectDate,
        repeatState: 'false',
        repeat: undefined,
        summary: undefined,
      }));
    } else {
      if (date < selectDate) selectDate = new Date(date);
      setState((prev) => ({
        ...prev,
        start: date < prev.start ? date : prev.start,
        end: date,
        selectDate,
      }));
    }
  };

  /** 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const { start, end } = state;
    const newTime = Number(value.split(':')[0]);
    const newMinute = Number(value.split(':')[1]);
    if (type === 'start') {
      const date = new Date(start);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (end <= date) {
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      const date = new Date(end);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (date <= start) {
        start.setHours(date.getHours());
        start.setMinutes(date.getMinutes() - 30);
      }
      setState((prev) => ({ ...prev, start, end: date }));
    }
  };

  /** 자원 폴더/아이템 선택. */
  const handleSelectResourceFolder = (id: number) => {
    const item = state.resourceData.find((a) => a.id === id);
    if (!item) {
      setValidation(getLocalizedText('선택된 자원 폴더(아이템)이 없습니다.'));
      return;
    }
    setState((prev) => ({
      ...prev,
      checkResourceItem: {
        type: item.icon === 'folder' ? 'folder' : 'item',
        id,
      },
    }));
  };

  /** 자원 아이템 날짜 페이징 */
  const handlePagination = (type: 'prev' | 'next') => {
    const selectDate = new Date(state.selectDate);
    if (type === 'prev') selectDate.setDate(selectDate.getDate() - 1);
    else selectDate.setDate(selectDate.getDate() + 1);

    setState((prev) => ({ ...prev, selectDate }));
  };

  /** 자원 선택 대화상자 확인 이벤트. */
  const handleResourceSelectConfirm = () => {
    if (
      state.checkResourceItem === undefined ||
      state.checkResourceItem.type === 'folder'
    ) {
      setValidation(getLocalizedText('선택된 자원 아이템이 없습니다.'));
      return;
    }

    let isNotAvailable = false;
    // 선택된 자원 아이템.
    const filteredResourceItems = resourcesItems.find(
      (a) => a.id === state.checkResourceItem?.id,
    );
    if (!filteredResourceItems) return;
    const availableStart = filteredResourceItems.availableFromTime.split(':');
    const availableEnd = filteredResourceItems.availableToTime.split(':');

    dateRange.forEach((d, i) => {
      const lastIndex = dateRange.length - 1;
      const date = new Date(`${d.value} 00:00`);

      let start = new Date(date);
      let end = new Date(date);
      const today = timezoneDate();
      today.setMinutes(today.getMinutes() < 30 ? 0 : 30);
      today.setSeconds(0);
      today.setMilliseconds(0);
      if (i === 0) start = new Date(state.start);
      if (i === lastIndex) end = new Date(state.end);
      else if (!filteredResourceItems.useTimeAvailable)
        end = new Date(new Date(date).setDate(date.getDate() + 1));

      const resourceAvailableFromTime = new Date(start);
      resourceAvailableFromTime.setHours(
        Number(availableStart[0]),
        Number(availableStart[1]),
      );
      const resourceAvailableToTime = new Date(end);
      resourceAvailableToTime.setHours(
        Number(availableEnd[0]),
        Number(availableEnd[1]),
      );
      if (i === lastIndex && !filteredResourceItems.useTimeAvailable)
        resourceAvailableToTime.setDate(end.getDate() + 1);

      /** 자원 아이템 이용가능시간 여부 확인. */
      // 지난 날짜 예약 불가능한 경우.
      if (!state.isAvailablePastReservation) {
        // 과거의 날짜는 항상 예약 불가능
        if (moment(start).isBefore(today, 'days')) isNotAvailable = true;
        // 오늘인 경우
        if (moment(start).isSame(today, 'days')) {
          // 사용가능시간 종일인 경우.
          if (!filteredResourceItems.useTimeAvailable) {
            if (start < today) isNotAvailable = true;
          }
          // 사용가능시간 종일이 아닌 경우.
          else {
            // 현재 시간이 사용가능 시작시간을 지난 경우
            if (
              today.getHours() * 60 + today.getMinutes() >=
                Number(availableStart[0]) * 60 + Number(availableStart[1]) &&
              today.getHours() * 60 + today.getMinutes() <=
                Number(availableEnd[0]) * 60 + Number(availableEnd[1])
            ) {
              const minute = today.getMinutes() < 30 ? 0 : 30;
              resourceAvailableFromTime.setHours(today.getHours(), minute);
            }
            if (
              today.getHours() * 60 + today.getMinutes() >=
                Number(availableEnd[0]) * 60 + Number(availableEnd[1]) ||
              !moment(start).isBetween(
                resourceAvailableFromTime,
                resourceAvailableToTime,
                undefined,
                '[]',
              ) ||
              !moment(end).isBetween(
                resourceAvailableFromTime,
                resourceAvailableToTime,
                undefined,
                '[]',
              )
            )
              isNotAvailable = true;
          }
        }
        // 미래인 경우
        if (moment(start).isAfter(today, 'days')) {
          if (
            filteredResourceItems.useTimeAvailable &&
            (!moment(start).isBetween(
              resourceAvailableFromTime,
              resourceAvailableToTime,
              undefined,
              '[]',
            ) ||
              !moment(end).isBetween(
                resourceAvailableFromTime,
                resourceAvailableToTime,
                undefined,
                '[]',
              ))
          )
            isNotAvailable = true;
        }
      }
      // 지난 날짜 예약 가능한 경우.
      else if (
        filteredResourceItems.useTimeAvailable &&
        (!moment(start).isBetween(
          resourceAvailableFromTime,
          resourceAvailableToTime,
          undefined,
          '[]',
        ) ||
          !moment(end).isBetween(
            resourceAvailableFromTime,
            resourceAvailableToTime,
            undefined,
            '[]',
          ))
      )
        isNotAvailable = true;
    });
    // }
    /** 예약리스트 중 자원 등록 예정일과 겹치는 날짜가 있는지 확인. */
    state.reservationList.forEach((a) => {
      if (
        !(
          moment(state.start).isSameOrAfter(a.endDateTime) ||
          moment(state.end).isSameOrBefore(a.startDateTime)
        )
      )
        isNotAvailable = true;
    });
    if (isNotAvailable) {
      setValidation(getLocalizedText('해당 자원은 예약이 불가능합니다.'));
      return;
    }

    props.onResourceSelectConfirm({
      start: state.start,
      end: state.end,
      checkResource: state.checkResourceItem.id,
    });
  };

  const {
    start,
    end,
    checkResourceItem,
    resourceData,
    reservationList,
    isAvailablePastReservation,
  } = state;
  const dateRange: {
    value: string;
    label: string;
  }[] = [];
  const timeDiff = moment(end).diff(start, 'days');
  for (let i = 0; i < timeDiff + 1; i += 1) {
    const range = new Date(start);
    range.setDate(range.getDate() + i);
    dateRange.push({
      value: dateFormat(range, 'yyyy-MM-DD'),
      label: dateFormat(range, 'yyyy-MM-DD (dd)'),
    });
  }
  const time: { value: string; label: string }[] = [];
  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    time.push({ value: timeValue, label: timeValue });
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }
  const startTime = start.getHours().toString().padStart(2, '0');
  const startMinute = start.getMinutes().toString().padStart(2, '0');
  const endTime = end.getHours().toString().padStart(2, '0');
  const endMinute = end.getMinutes().toString().padStart(2, '0');
  const selectFormatDate = dateFormat(state.selectDate, 'yyyy-MM-DD');
  return (
    <Dialog onClose={props.onClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('자원예약')}</DialogTitle>
      </DialogHeader>
      <DialogBody height={400} noneOverFlow>
        <PostWrite>
          {state.loading && <Loading />}
          <PostWrite.Item title={getLocalizedText('일정기간')}>
            <div
              style={{
                display: 'inline-flex',
                flexDirection: display === 'phone' ? 'column' : undefined,
              }}
            >
              {display === 'phone' ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '5px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {getLocalizedText('시작')}
                    </span>
                    <CustomDatePicker
                      disabled
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={start}
                      startDate={start}
                      endDate={end}
                      selectsStart
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'start')
                      }
                    />
                    <SelectField
                      disabled
                      data={bottom}
                      value={`${startTime}:${startMinute}`}
                      onChange={(value) => handleChangeTime(value, 'start')}
                    />
                  </div>
                  <div
                    style={
                      display === 'phone'
                        ? {
                            display: 'flex',
                            flexDirection: 'row',
                          }
                        : undefined
                    }
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {getLocalizedText('종료')}
                    </span>
                    <CustomDatePicker
                      disabled
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={end}
                      startDate={start}
                      endDate={end}
                      minDate={start}
                      selectsEnd
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'end')
                      }
                    />
                    <SelectField
                      disabled
                      data={bottom}
                      value={`${endTime}:${endMinute}`}
                      onChange={(value) => handleChangeTime(value, 'end')}
                    />
                  </div>
                </>
              ) : (
                <>
                  <CustomDatePicker
                    disabled
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={start}
                    startDate={start}
                    endDate={end}
                    selectsStart
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'start')
                    }
                  />
                  <SelectField
                    disabled
                    data={bottom}
                    value={`${startTime}:${startMinute}`}
                    onChange={(value) => handleChangeTime(value, 'start')}
                  />
                  <span style={{ margin: '2px 10px' }}> ~ </span>
                  <CustomDatePicker
                    disabled
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={end}
                    startDate={start}
                    endDate={end}
                    minDate={start}
                    selectsEnd
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'end')
                    }
                  />
                  <SelectField
                    disabled
                    data={bottom}
                    value={`${endTime}:${endMinute}`}
                    onChange={(value) => handleChangeTime(value, 'end')}
                  />
                </>
              )}
            </div>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('자원폴더 선택')}>
            <TreeList
              selectedId={checkResourceItem?.id}
              items={resourceData}
              onItemClick={handleSelectResourceFolder}
            />
          </PostWrite.Item>
          {checkResourceItem && (
            <PostWrite.Item title={getLocalizedText('자원 예약 현황')}>
              {resourcesItems.filter((a) => {
                if (checkResourceItem.type === 'folder')
                  return a.folderId === checkResourceItem.id;
                return a.id === checkResourceItem.id;
              }).length > 0 ? (
                <EuiBody>
                  <div
                    style={{
                      margin: '5px 0 10px',
                      display: ' flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      text={getLocalizedText('이전')}
                      size="xs"
                      className="page-action"
                      iconType
                      icon="chevron-left"
                      disabled={selectFormatDate === dateRange[0].value}
                      onClick={() => handlePagination('prev')}
                    />
                    <Button
                      style={{
                        marginLeft:
                          selectFormatDate !==
                          dateRange[dateRange.length - 1].value
                            ? '12px'
                            : undefined,
                      }}
                      text={getLocalizedText('다음')}
                      size="xs"
                      className="page-action"
                      iconType
                      icon="chevron-right"
                      disabled={
                        selectFormatDate ===
                        dateRange[dateRange.length - 1].value
                      }
                      onClick={() => handlePagination('next')}
                    />
                    <div
                      style={{
                        marginLeft: '12px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {dateFormat(state.selectDate, 'yyyy-MM-DD (dd)')}
                    </div>
                  </div>
                  <div
                    className="resource-content"
                    style={{ minWidth: '700px' }}
                  >
                    <div className="resource-day-content">
                      <div
                        className="title"
                        style={{
                          maxWidth: display !== 'phone' ? '200px' : '150px',
                          width: '100%',
                        }}
                      >
                        {/* {dateFormat(new Date(d.value), 'yyyy-MM-DD (dd)')} */}
                      </div>
                      <div className="header">
                        {time.map((a, i) => {
                          const timeKey = `${i}_${a.label}`;
                          return (
                            <div key={timeKey} className="timeline">
                              {a.label}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {resourcesItems
                      .filter((a) => {
                        if (checkResourceItem.type === 'folder')
                          return a.folderId === checkResourceItem.id;
                        return a.id === checkResourceItem.id;
                      })
                      .map((item) => {
                        const endIndex = bottom.findIndex(
                          (a) => a.value === item.availableToTime,
                        );
                        // const itemId = item.id;
                        const reservation = reservationList.filter(
                          (a) => a.itemId === item.id,
                        ); // 예약 리스트.
                        const title = item.name;
                        const child: {
                          date: string;
                          width: number;
                          left: number;
                          listId: number;
                          isApproval: number;
                        }[] = [];
                        let startBlock = <></>;
                        let endBlock = <></>;
                        const today = timezoneDate();
                        today.setHours(0, 0, 0, 0);
                        const selectedDay = new Date(state.selectDate);
                        selectedDay.setHours(0, 0, 0, 0);
                        if (today <= selectedDay) {
                          // 오늘이면서 지난 날짜 예약 불가능일 경우 - 현재보다 지난 시간은 예약 불가능.
                          if (
                            moment(today).isSame(selectedDay, 'days') &&
                            !isAvailablePastReservation
                          ) {
                            const availableStart =
                              item.availableFromTime.split(':');
                            const minute =
                              timezoneDate().getMinutes() < 30 ? '00' : '30';
                            const hours = timezoneDate()
                              .getHours()
                              .toString()
                              .padStart(2, '0');
                            if (item.availableFromTime !== '00:00') {
                              let length = 0;
                              // 현재 시간이 사용가능 시작시간을 아직 안지난 경우
                              if (
                                timezoneDate().getHours() * 60 +
                                  timezoneDate().getMinutes() <=
                                Number(availableStart[0]) * 60 +
                                  Number(availableStart[1])
                              ) {
                                length = bottom.findIndex(
                                  (a) => a.value === item.availableFromTime,
                                );
                              }
                              // 현재 시간이 사용가능 시작시간을 지난 경우
                              else {
                                length = bottom.findIndex(
                                  (a) => a.value === `${hours}:${minute}`,
                                );
                              }
                              // 현재 시간이 사용가능 종료시간을 지난 경우
                              if (
                                item.availableToTime !== '00:00' &&
                                length > endIndex
                              )
                                length = endIndex;
                              const width = 2.083 * length;
                              startBlock = (
                                <div
                                  style={{
                                    width: `${width}%`,
                                    left: `0%`,
                                  }}
                                  className="block"
                                />
                              );
                            } else {
                              const length = bottom.findIndex(
                                (a) => a.value === `${hours}:${minute}`,
                              );
                              const width = 2.083 * length;
                              startBlock = (
                                <div
                                  style={{
                                    width: `${width}%`,
                                    left: `0%`,
                                  }}
                                  className="block"
                                />
                              );
                            }
                          }
                          // 미래일 경우 또는 지난 날짜 예약 가능일 경우
                          else if (item.availableFromTime !== '00:00') {
                            const length = bottom.findIndex(
                              (a) => a.value === item.availableFromTime,
                            );
                            const width = 2.083 * length;
                            startBlock = (
                              <div
                                key={`start_${item.id}`}
                                style={{ width: `${width}%`, left: `0%` }}
                                className="block"
                              />
                            );
                          }

                          if (item.availableToTime !== '00:00') {
                            const length = bottom.findIndex(
                              (a) => a.value === item.availableToTime,
                            );
                            const width = 2.084 * (bottom.length - length);
                            const left = 2.083 * length;
                            endBlock = (
                              <div
                                style={{
                                  width: `${width}%`,
                                  left: `${left}%`,
                                }}
                                className="block"
                              />
                            );
                          }
                        }
                        // 과거이면서 지난 날짜 예약 가능일 경우.
                        else if (isAvailablePastReservation) {
                          if (item.availableFromTime !== '00:00') {
                            const length = bottom.findIndex(
                              (a) => a.value === item.availableFromTime,
                            );
                            startBlock = (
                              <div
                                style={{
                                  width: `${2.083 * length}%`,
                                  left: `0%`,
                                }}
                                className="block"
                              />
                            );
                          }
                          if (item.availableToTime !== '00:00') {
                            const length = bottom.findIndex(
                              (a) => a.value === item.availableToTime,
                            );
                            const width = 2.084 * (bottom.length - length);
                            const left = 2.083 * length;
                            endBlock = (
                              <div
                                style={{
                                  width: `${width}%`,
                                  left: `${left}%`,
                                }}
                                className="block"
                              />
                            );
                          }
                        }
                        // 과거이면서 지난 날짜 예약 불가능인 경우.
                        else {
                          startBlock = (
                            <div
                              style={{
                                width: `${2.083 * bottom.length}%`,
                                left: `0%`,
                              }}
                              className="block"
                            />
                          );
                        }
                        // console.log('reservation', reservation);
                        return (
                          <div
                            key={item.id}
                            className="resource-day-content"
                            style={{ height: '30px', lineHeight: '30px' }}
                          >
                            <div
                              className="title"
                              style={{
                                minWidth:
                                  display !== 'phone' ? '200px' : '150px',
                                fontWeight: 'normal',
                                maxWidth:
                                  display !== 'phone' ? '200px' : '150px',
                              }}
                            >
                              {getLocalizedText(`폴더.${title}`)}
                            </div>
                            <div className="bottom">
                              {bottom.map((a, i) => {
                                reservation.forEach((z) => {
                                  const startDate = new Date(z.startDateTime);
                                  const endDate = new Date(z.endDateTime);
                                  const formatStart = dateFormat(
                                    startDate,
                                    'yyyy-MM-DD',
                                  );
                                  const formatEnd = dateFormat(
                                    endDate,
                                    'yyyy-MM-DD',
                                  );
                                  const formatToday = dateFormat(
                                    state.selectDate,
                                    'yyyy-MM-DD',
                                  );
                                  // 예약일이 하루이면서 조회 날짜와 동일한 경우.
                                  // 예약일이 이틀 이상이면서 예약 시작일이 조회 날짜와 동일한 경우.
                                  if (
                                    formatStart === formatToday &&
                                    startDate.getHours() ===
                                      Number(a.value.split(':')[0]) &&
                                    startDate.getMinutes() ===
                                      Number(a.value.split(':')[1])
                                  ) {
                                    let length = bottom.length - i;
                                    if (formatEnd === formatToday) {
                                      const days =
                                        (endDate.getDate() -
                                          startDate.getDate()) *
                                        24 *
                                        60;
                                      const hours =
                                        (endDate.getHours() -
                                          startDate.getHours()) *
                                        60;
                                      const minutes =
                                        Math.round(
                                          (endDate.getMinutes() -
                                            startDate.getMinutes()) /
                                            10,
                                        ) * 10;
                                      length = (days + hours + minutes) / 30;
                                    }
                                    const width = 2.084 * length;
                                    const left = 2.083 * i;
                                    child.push({
                                      date: formatToday,
                                      width,
                                      left,
                                      listId: Number(z.id),
                                      isApproval: z.isApproval,
                                    });
                                  }
                                  // 예약일이 삼일 이상이면서 조회 날짜가 예약 시작, 종료일 중간인 경우.
                                  if (
                                    moment(state.selectDate).isBetween(
                                      startDate,
                                      endDate,
                                      'days',
                                    ) &&
                                    a.value === '00:00'
                                  ) {
                                    const width = 2.084 * bottom.length;
                                    child.push({
                                      date: formatToday,
                                      width,
                                      left: 0,
                                      listId: Number(z.id),
                                      isApproval: z.isApproval,
                                    });
                                  }
                                  // 예약일이 이틀 이상이면서 예약 종료일이 조회날짜인 경우.
                                  if (
                                    formatEnd === formatToday &&
                                    formatEnd !== formatStart &&
                                    a.value === '00:00'
                                  ) {
                                    const hours = endDate.getHours() * 60;
                                    const minutes = endDate.getMinutes();
                                    const length = (hours + minutes) / 30;
                                    const width = 2.084 * length;
                                    child.push({
                                      date: formatToday,
                                      width,
                                      left: 0,
                                      listId: Number(z.id),
                                      isApproval: z.isApproval,
                                    });
                                  }
                                });

                                const bottomKey = `${i}_${a.label}`;
                                return (
                                  <div
                                    key={bottomKey}
                                    className="timeline"
                                    style={{ cursor: 'initial' }}
                                  />
                                );
                              })}
                              {startBlock}
                              {endBlock}
                              {child.length > 0 &&
                                child.map((a, i) => {
                                  const childKey = `${i}_${a.listId}`;
                                  return (
                                    <div
                                      key={childKey}
                                      style={{
                                        width: `${a.width}%`,
                                        left: `${a.left}%`,
                                        borderLeft:
                                          a.left === 0 ? '0' : '1px solid #ddd',
                                        cursor: 'initial',
                                      }}
                                      // eslint-disable-next-line prettier/prettier
                                      className={a.isApproval === 1 ? 'selected' : 'selected approval'}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </EuiBody>
              ) : (
                <EmptyData />
              )}
            </PostWrite.Item>
          )}
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          text={getLocalizedText('확인')}
          variant="contained"
          onClick={handleResourceSelectConfirm}
        />
      </DialogFooter>
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </Dialog>
  );
}

export default CalendarResourceSelect;
