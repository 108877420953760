import React, { useState } from 'react';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import TreeList from '../../../../../components/tree/TreeList';
import { IconType } from '../../../../../groupware-common/types/icon';
import Button from '../../../../../components/button/Button';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ServiceMenuItemList(props: {
  selectedId: string | undefined;
  items: {
    id: string;
    parentId: string;
    text: string;
    strings: string[][];
    icon: IconType;
    children?: React.ReactNode;
  }[];
  onSort(): void;
  onItemClick(id: string): void;
}): JSX.Element {
  const { items, selectedId, onItemClick, onSort } = props;

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleItemClick = (id: string) => {
    if (items.find((x) => x.id === id)) onItemClick(id);
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('메뉴 목록')}>
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={onSort}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <TreeList
          selectedId={selectedId}
          filter={filter}
          items={items}
          onItemClick={handleItemClick}
        />
      </EuiSetting.Content>
    </>
  );
}

export default ServiceMenuItemList;
