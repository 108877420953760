import React, { useState } from 'react';
import Button from '../../../components/button/Button';
import EmptyData from '../../../components/data/EmptyData';
import EuiSetting from '../../../components/layout/EuiSetting';
import SimpleSearch from '../../../components/search/SimpleSearch';
import { IconType } from '../../../groupware-common/types/icon';
import { hangul } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';
import CalendarItem from '../root/calendar/common/CalendarItem';

function CalendarFolderBoxList(props: {
  calendarId: number | undefined;
  items: {
    id: number;
    parentId: number;
    seq: number;
    status?: boolean;
    isInitial?: boolean;
    text: string;
    icon: IconType;
    color: string;
    strings: string[][];
  }[];
  onCreate?(): void;
  onItemClick(id: number): void;
}): JSX.Element {
  const { calendarId, items, onCreate, onItemClick } = props;
  const [filter, setFilter] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleItemClick = (id: number) => {
    if (items.find((a) => a.id === id)) onItemClick(id);
  };

  const renderList = () => {
    const strings = hangul.d(filter);
    const list = items.filter(
      (x) => x.strings && hangul.test(x.strings, strings),
    );
    if (list.length === 0)
      return <EmptyData message={getLocalizedText('검색결과가 없습니다.')} />;

    return (
      <>
        {list.map(({ id, isInitial, text, color, status }) => {
          return (
            <CalendarItem
              key={id}
              id={id}
              initialCalendar={isInitial}
              label={text}
              width="auto"
              status={status}
              color={color}
              selected={id === calendarId}
              onClick={() => handleItemClick(id)}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('캘린더 목록')}>
        {props.onCreate && (
          <Button
            text={getLocalizedText('캘린더 추가')}
            iconType
            icon="plus"
            onClick={onCreate}
          />
        )}
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>{renderList()}</EuiSetting.Content>
    </>
  );
}

export default CalendarFolderBoxList;
