/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import Alert from '../../../../../components/alert/Alert';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import Dext5 from '../../../../../components/editor/Dext5';
import SelectField from '../../../../../components/selectField/SelectField';
import Switch from '../../../../../components/switch/Switch';
import Tab from '../../../../../components/tab/Tab';
import TextField from '../../../../../components/textfield/TextField';
import Editor from '../../../../../groupware-approval/editor/Editor';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import BoardFormEditMenu from './BoardFormEditMenu';
import { hangul } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import BoardFormPopupDialog from './BoardFormPopupDialog';

function BoardFormEditContentDialog(props: {
  name: string;
  content: string;
  goInfo(): void;
  onCancel(): void;
  onSave(arg: { content: string }): void;
}): JSX.Element {
  // console.log(`BoardFormEditContentDialog.render(props)`, props);

  const widthRef = useRef<HTMLInputElement>(null);
  const iframe = useRef<HTMLIFrameElement>(null);

  const editorId = 'editor1';

  const editorConfig = {
    Width: '100%',
    Height: '100%',
    ShowTopMenu: '0',
    RemoveItem: 'new,file_open',
    UserCssUrl: '/editor/dext5/css/user_editor.css',
    UserCssAlwaysSet: '1',
  };

  const [state, setState] = useState(() => {
    const option: {
      title: string;
      description: string;
      correction?: boolean; // true일 때 근태매크로 연차 셀렉트박스 수정 불가능하도록.

      textAlign?: string; // 텍스트 정렬.
      width?: string; // 너비.
      height?: string; // 높이.
      value?: string; // 값.
      fontSize?: string; // 글꼴 크기.
      required?: boolean; // 필수 여부.

      rows?: string; // 행 수.

      number?: {
        type: string;
        id?: string;
        formula?: string;
        currencySymbol: string;
      };

      items?: { id: string; label: string; checked: boolean }[];

      dialog?: {
        id: string;
        name: string;
        width: string;
        title: string;
        content: string;
      };
      approvalLineGroupIncludeDrafter?: boolean;
      approvalItem?: {
        group: string;
        type: string;
        seq: string;
      };
      time?: number; // 근태 시간 간격 (15분, 30분, 60분)
    } = {
      title: '',
      description: '',
    };
    const builder = new FormBuilder();
    return {
      content: builder.createEditorFormHtml({ html: props.content }),
      selectedTabId: 0,
      preview: false,
      modifyPopup: false,
      builder,
      option,
      filter: '',
    };
  });

  const handlePreview = () => {
    if (iframe.current === null) {
      return;
    }
    const { builder } = state;
    builder.unSelect();
    const content = Editor.getHtml(iframe.current);
    setState((prevState) => ({
      ...prevState,
      preview: true,
      content,
    }));
  };

  const handleSave = () => {
    if (iframe.current === null) {
      return;
    }
    const { onSave } = props;
    const html = Editor.getHtml(iframe.current);
    const content = FormBuilder.createFormHtml(html);
    onSave({ content });
  };

  const handleToolbarItemClick = (
    arg:
      | { role: 'control'; type: string }
      | { role: 'board'; type: string; text: string },
  ) => {
    const { type } = arg;
    const { builder } = state;

    if (arg.role === 'control') builder.append({ role: 'control', type });
    if (arg.role === 'board')
      builder.append({ role: 'board', type, text: arg.text });
  };

  const handleEditorLoad = (editor: any, DEXT5: any, editorDom: any) => {
    // console.log(`handleEditorLoad(editor, DEXT5, editorDom)`, { editor, DEXT5, editorDom });
    setState((prevState) => {
      const { builder } = prevState;
      // const builder = new FormBuilder(DEXT5, editorId, editorDom);
      builder.setEditor({ iframe: iframe.current });
      builder.onSelect = handleEditorElementSelect;
      return { ...prevState, builder };
    });
  };

  /** 에디터 엘리먼트 선택. */
  const handleEditorElementSelect = (element: HTMLElement | null) => {
    // console.log(`handleEditorElementSelect(element)`, element);

    if (element === null) {
      const option = { title: '', description: '' };
      setState((prevState) => ({ ...prevState, option }));
      return;
    }

    const role = element.getAttribute('data-role') || '';
    const type = element.getAttribute(`data-${role}`) || '';

    const { builder } = state;

    const title = builder.getItemText({ role, type });
    const description = builder.getItemDescription({ role, type });

    let textAlign: string | undefined;
    let width: string | undefined;
    let height: string | undefined;
    let value: string | undefined;
    let fontSize: string | undefined;
    let required: boolean | undefined;
    let correction: boolean | undefined;

    let rows: string | undefined;

    let number:
      | { type: string; id?: string; formula?: string; currencySymbol: string }
      | undefined;

    let items: { id: string; label: string; checked: boolean }[] | undefined;

    let dialog:
      | {
          id: string;
          name: string;
          width: string;
          title: string;
          content: string;
        }
      | undefined;

    let time: number | undefined;

    // console.log(`handleEditorElementSelect(element):role`, role);
    // console.log(`handleEditorElementSelect(element):type`, type);

    if (type === 'CONTROL/TEXT') {
      textAlign = builder.getAttribute({ element, name: 'text-align' });
      width = builder.getAttribute({ element, name: 'width' });
      value = builder.getAttribute({ element, name: 'value' });
      fontSize = builder.getAttribute({ element, name: 'font-size' });
    }
    if (type === 'CONTROL/TEXTAREA') {
      textAlign = builder.getAttribute({ element, name: 'text-align' });
      width = builder.getAttribute({ element, name: 'width' });
      rows = builder.getAttribute({ element, name: 'rows' });
      // value = builder.getAttribute({ element, name: 'value' }); // 현재 지원 안함.
      fontSize = builder.getAttribute({ element, name: 'font-size' });
    }
    if (type === 'CONTROL/EDITOR') {
      height = builder.getAttribute({ element, name: 'height' });
    }
    if (
      type === 'CONTROL/NUMBER' ||
      type === 'CONTROL/CALCULATION_VALUE' ||
      type === 'CONTROL/FORMULA'
    ) {
      textAlign = builder.getAttribute({ element, name: 'text-align' });
      width = builder.getAttribute({ element, name: 'width' });
      // value = builder.getAttribute({ element, name: 'value' });
      number = {
        type,
        id: builder.getAttribute({ element, name: 'data-calculate-id' }),
        formula: builder.getAttribute({ element, name: 'data-formula' }),
        currencySymbol: builder.getAttribute({
          element,
          name: 'data-currency-symbol',
        }),
      };
      fontSize = builder.getAttribute({ element, name: 'font-size' });
    }
    if (type === 'CONTROL/RADIO' || type === 'CONTROL/CHECKBOX') {
      items = builder.getAttribute({ element, name: 'check-items' });
    }
    if (type === 'CONTROL/SELECT') {
      items = builder.getAttribute({ element, name: 'check-items' });
    }
    if (type === 'CONTROL/DIALOG') {
      const control = element.querySelector('input');
      if (control === null) return;

      dialog = {
        id: builder.getAttribute({ element, name: 'id' }),
        name: builder.getAttribute({ element, name: 'name' }),
        width: builder.getAttribute({ element, name: 'data-width' }),
        title: builder.getAttribute({ element, name: 'data-title' }),
        content: builder.getAttribute({ element, name: 'data-content' }),
      };
    }

    let approvalLineGroupIncludeDrafter: boolean | undefined;
    let approvalItem:
      | {
          group: string;
          type: string;
          seq: string;
        }
      | undefined;

    setState((prevState) => {
      return {
        ...prevState,
        option: {
          title,
          description,
          correction,

          textAlign,
          width,
          height,
          value,
          fontSize,
          required,

          rows,
          number,
          items,

          dialog,

          approvalLineGroupIncludeDrafter,
          approvalItem,

          time,
        },
      };
    });
  };

  /** 옵션 텍스트 정렬 변경. */
  const handleOptionTextAlignChange = (value: string) => {
    const { builder, option } = state;
    builder.setAttribute({ name: 'text-align', value });
    setState((prevState) => ({
      ...prevState,
      option: { ...option, textAlign: value },
    }));
  };

  /** 옵션 너비 유형 변경. */
  const handleOptionWidthTypeChange = (value: string) => {
    const { builder, option } = state;
    const width = value === '100%' ? value : '150px';
    builder.setAttribute({ name: 'width', value: width });
    setState((prevState) => ({ ...prevState, option: { ...option, width } }));
    if (value === 'pixel') {
      setTimeout(() => {
        widthRef.current?.focus();
      }, 100);
    }
  };

  /** 옵션 너비 변경. */
  const handleOptionWidthChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.validity.valid) return;
    const { builder, option } = state;
    const { value } = event.currentTarget;
    const width = `${value}px`;
    builder.setAttribute({ name: 'width', value: width });
    setState((prevState) => ({ ...prevState, option: { ...option, width } }));
  };

  /** 옵션 높이 변경. */
  const handleOptionHeightChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.validity.valid) return;
    const { builder, option } = state;
    const { value } = event.currentTarget;
    const height = value === '' ? '' : `${value}px`;
    builder.setAttribute({ name: 'height', value: height });
    setState((prevState) => ({ ...prevState, option: { ...option, height } }));
  };

  /** 옵션 행수 변경. */
  const handleOptionRowsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.validity.valid) return;
    const { builder, option } = state;
    const { value: rows } = event.currentTarget;
    builder.setAttribute({ name: 'rows', value: rows });
    setState((prevState) => ({ ...prevState, option: { ...option, rows } }));
  };

  /** 옵션 숫자 유형 변경. */
  const handleOptionNumberTypeChange = (value: string) => {
    // console.log(`handleOptionNumberTypeChange(value)`, value);

    const { builder, option } = state;
    const { number } = option;
    if (number === undefined) return;

    let { id, formula } = number;

    if (value === 'CONTROL/NUMBER') {
      id = undefined;
      formula = undefined;
      builder.removeAttribute({ name: 'data-calculate-id' });
      builder.removeAttribute({ name: 'data-formula' });
      builder.removeAttribute({ name: 'value' });
    } else if (
      value === 'CONTROL/CALCULATION_VALUE' ||
      value === 'CONTROL/FORMULA'
    ) {
      if (id === undefined) {
        id = '';
        builder.setAttribute({ name: 'data-calculate-id', value: '' });
      }
      if (value === 'CONTROL/FORMULA') {
        if (formula === undefined) {
          formula = '';
          builder.setAttribute({ name: 'data-formula', value: '' });
          builder.setAttribute({ name: 'value', value: '0' });
        }
        builder.setAttribute({
          name: 'data-currency-symbol',
          value: number.currencySymbol,
        });
      } else {
        formula = undefined;
        builder.removeAttribute({ name: 'data-formula' });
        builder.removeAttribute({ name: 'value' });
      }
    }

    if (value === 'CONTROL/NUMBER' || value === 'CONTROL/CALCULATION_VALUE')
      builder.removeAttribute({ name: 'disabled' });
    else builder.setAttribute({ name: 'disabled', value: 'disabled' });

    builder.setType({ role: 'control', type: value });

    setState((prevState) => ({
      ...prevState,
      option: {
        ...option,
        number: {
          ...number,
          type: value,
          id,
          formula,
        },
      },
    }));
  };

  /** 옵션 숫자 아이디 변경. */
  const handleOptionNumberIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // console.log(`handleOptionNumberIdChange(event)`, event);
    const { value: id } = event.currentTarget;

    const { builder, option } = state;
    const { number } = option;
    if (number === undefined || !event.currentTarget.validity.valid) return;

    builder.setAttribute({ name: 'data-calculate-id', value: id });
    setState((prevState) => ({
      ...prevState,
      option: { ...option, number: { ...number, id } },
    }));
  };

  /** 옵션 숫자 계산식 변경. */
  const handleOptionNumberFormulaChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { builder, option } = state;
    const { number } = option;
    if (number === undefined) return;
    const { value: formula } = event.currentTarget;
    builder.setAttribute({ name: 'data-formula', value: formula });
    setState((prevState) => ({
      ...prevState,
      option: { ...option, number: { ...number, formula } },
    }));
  };

  /** 옵션 숫자 통화 기호 변경. */
  const handleOptionNumberCurrencySymbolChange = (value: string) => {
    const { builder, option } = state;
    const { number } = option;
    if (number === undefined) return;
    const currencySymbol = value;
    builder.setAttribute({
      name: 'data-currency-symbol',
      value: currencySymbol,
    });
    setState((prevState) => ({
      ...prevState,
      option: { ...option, number: { ...number, currencySymbol } },
    }));
  };

  /** 옵션 기본값 변경. */
  const handleOptionDefaultValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { builder, option } = state;
    const { value } = event.currentTarget;
    builder.setAttribute({ name: 'value', value });
    setState((prevState) => ({ ...prevState, option: { ...option, value } }));
  };

  /** 옵션 글꼴 크기 변경. */
  const handleOptionFontSizeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.validity.valid) return;
    const { builder, option } = state;
    const { value } = event.currentTarget;
    const fontSize = value === '' ? '' : `${value}px`;
    builder.setAttribute({ name: 'font-size', value: fontSize });
    setState((prevState) => ({
      ...prevState,
      option: { ...option, fontSize },
    }));
  };

  /** 옵션 아이템 순서 변경. */
  const handleOptionItemOrderChange = (
    items: {
      id: string;
      label: string;
      checked: boolean;
    }[],
  ) => {
    // console.log(`handleOptionItemOrderChange(items)`, items);
    const { builder, option } = state;

    let changed = items.length !== option.items?.length;
    if (!changed && option.items !== undefined) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].id !== option.items[i].id) {
          changed = true;
          break;
        }
      }
    }

    if (changed) {
      const itemId = items[0]?.id ?? 'none';
      const type = itemId.startsWith('option/')
        ? 'option'
        : itemId.split('/').splice(0, 2).join('/');
      items.forEach((a) => builder.remove(a.id));
      items.forEach((a) => {
        builder.append({ id: a.id, type, value: a.label });
        const attribute = type === 'option' ? 'selected' : 'checked';
        if (a.checked)
          builder.setAttribute({ id: a.id, name: attribute, value: attribute });
      });

      setState((prevState) => ({ ...prevState, option: { ...option, items } }));
    }
  };

  /** 옵션 아이템 체크 변경. */
  const handleOptionItemCheckedChange = (
    id: string,
    event: React.MouseEvent,
  ) => {
    // console.log(`handleOptionItemCheckedChange(id: "${id}", event)`);
    event.stopPropagation();
    event.preventDefault();
    const { builder, option } = state;

    const type = id.startsWith('option/')
      ? 'option'
      : id.split('/').slice(0, 2).join('/');
    let items: { id: string; label: string; checked: boolean }[] | undefined;
    if (type === 'CONTROL/RADIO') {
      const checked = !(
        option.items?.find((a) => a.id === id)?.checked || false
      );
      items = option.items?.map((a) => {
        if (a.id === id) return { ...a, checked };
        return { ...a, checked: false };
      });

      items?.forEach((a) => {
        builder.setAttribute({ id: a.id, name: 'checked', value: '' });
      });
      if (checked)
        builder.setAttribute({ id, name: 'checked', value: 'checked' });
    } else if (type === 'CONTROL/CHECKBOX') {
      let value = '';
      items = option.items?.map((a) => {
        if (a.id !== id) return a;
        if (!a.checked) value = 'checked';
        return { ...a, checked: !a.checked };
      });
      builder.setAttribute({ id, name: 'checked', value });
    } else if (type === 'option') {
      items = option.items?.map((a) => {
        if (a.id !== id) return { ...a, checked: false };
        return { ...a, checked: true };
      });
      items?.forEach((a) =>
        builder.setAttribute({ id: a.id, name: 'selected', value: '' }),
      );
      builder.setAttribute({ id, name: 'selected', value: 'selected' });
    }

    if (items !== undefined)
      setState((prevState) => ({ ...prevState, option: { ...option, items } }));
  };

  /** 옵션 아이템 라벨 변경. */
  const handleOptionItemLabelChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { builder, option } = state;
    const label = event.currentTarget.value;
    builder.setAttribute({ id, name: 'label', value: label });
    setState((prevState) => ({
      ...prevState,
      option: {
        ...option,
        items: option.items?.map((a) => (a.id === id ? { ...a, label } : a)),
      },
    }));
  };

  /** 옵션 아이템 삭제. */
  const handleOptionItemDelete = (id: string) => {
    const { builder, option } = state;
    if (option.items === undefined) return;
    const items = option.items.filter((a) => a.id !== id);
    builder.remove(id);

    // 셀렉트/옵션 항목에서 선택 항목이 없는 경우 처움 항목 선택으로 설정.
    if (
      id.startsWith('option/') &&
      items.find((a) => a.checked) === undefined &&
      items.length > 1
    ) {
      items[0].checked = true;
      builder.setAttribute({
        id: items[0].id,
        name: 'selected',
        value: 'selected',
      });
    }

    setState((prevState) => ({ ...prevState, option: { ...option, items } }));
  };

  /** 옵션 아이템 추가. */
  const handleOptionItemAppend = () => {
    const { builder, option } = state;
    const { items } = option;
    if (items === undefined) return;

    const itemId = items[0]?.id ?? 'none';
    const type = itemId.startsWith('option/')
      ? 'option'
      : itemId.split('/').splice(0, 2).join('/');
    const id = `${type}/${Date.now()}`;
    const label = getLocalizedText('미지정');
    builder.append({ id, type, value: label });

    setState((prevState) => ({
      ...prevState,
      option: { ...option, items: [...items, { id, label, checked: false }] },
    }));
  };

  /** 결재선 그룹 기안자 포함 여부 값 변경. */
  const handleApprovalLineGroupIncludeDrafterChange = () => {
    const { builder, option } = state;
    if (option === undefined) return;
    const { approvalLineGroupIncludeDrafter } = option;
    if (approvalLineGroupIncludeDrafter === undefined) return;
    const value = !approvalLineGroupIncludeDrafter;

    if (value)
      builder.setAttribute({ name: 'data-include-drafter', value: `${value}` });
    else builder.removeAttribute({ name: 'data-include-drafter' });

    setState((prevState) => ({
      ...prevState,
      option: { ...option, approvalLineGroupIncludeDrafter: value },
    }));
  };

  /** 결재항목 그룹 변경. */
  const handleApprovalItemGroupChange = (arg: {
    value: string;
    text: string;
  }) => {
    const { value, text } = arg;
    const { builder, option } = state;
    if (option === undefined) return;
    const { approvalItem } = option;
    if (approvalItem === undefined) return;

    builder.setAttribute({ name: 'data-group', value });
    builder.setAttribute({ name: 'data-group-text', value: text });
    setState((prevState) => ({
      ...prevState,
      option: {
        ...option,
        approvalItem: {
          ...approvalItem,
          group: value,
        },
      },
    }));
  };

  /** 결재항목 유형 변경. */
  const handleApprovalItemTypeChange = (arg: {
    value: string;
    text: string;
  }) => {
    const { value, text } = arg;
    const { builder, option } = state;
    if (option === undefined) return;
    const { approvalItem } = option;
    if (approvalItem === undefined) return;

    builder.setAttribute({ name: 'data-type', value });
    builder.setAttribute({ name: 'data-type-text', value: text });
    setState((prevState) => ({
      ...prevState,
      option: {
        ...option,
        approvalItem: {
          ...approvalItem,
          type: value,
        },
      },
    }));
  };

  /** 결재항목 순번 변경. */
  const handleApprovalItemSeqChange = (arg: {
    value: string;
    text: string;
  }) => {
    const { value, text } = arg;
    const { builder, option } = state;
    if (option === undefined) return;
    const { approvalItem } = option;
    if (approvalItem === undefined) return;

    builder.setAttribute({ name: 'data-seq', value });
    builder.setAttribute({ name: 'data-seq-text', value: text });
    setState((prevState) => ({
      ...prevState,
      option: {
        ...option,
        approvalItem: {
          ...approvalItem,
          seq: value,
        },
      },
    }));
  };

  /** 근태 - 시작,종료시간 간격 설정 변경. */
  const handleChangeTimeInterval = (value: number) => {
    const { builder } = state;
    builder.setAttribute({
      name: 'data-time-interval',
      value: value.toString(),
    });
    setState((prev) => ({
      ...prev,
      option: {
        ...prev.option,
        time: value,
      },
    }));
  };

  /** 매크로 필터. */
  const handleMacroFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      filter: event.target.value,
    }));
  };

  const renderToolbarButtons = () => {
    const { selectedTabId } = state;
    if (selectedTabId === 0) return handleMenuFilter({ role: 'control' });
    if (state.selectedTabId === 1) return handleMenuFilter({ role: 'board' });
    return undefined;
  };

  const handleMenuFilter = (arg: { role: 'board' | 'control' }) => {
    const { builder, filter } = state;
    const strings = hangul.d(filter.trim());
    return builder
      .getItems(arg)
      .filter(
        (a) => strings.length === 0 || hangul.test(hangul.d(a.text), strings),
      )
      .map((a) => (
        <BoardFormEditMenu
          key={a.id}
          label={a.text}
          icon={a.icon}
          onClick={() =>
            handleToolbarItemClick({
              role: arg.role,
              type: a.id,
              text: a.text,
            })
          }
        />
      ));
  };

  const handlePopupOpen = () => {
    setState((prev) => ({ ...prev, modifyPopup: true }));
  };

  const handlePopupClose = () => {
    setState((prev) => ({
      ...prev,
      modifyPopup: false,
    }));
  };

  const handlePopupSave = (arg: {
    name: string;
    width: string;
    title: string;
    content: string;
  }) => {
    const { builder, option } = state;
    if (option.dialog === undefined) return;

    builder.setAttribute({ name: 'value', value: arg.name });
    builder.setAttribute({ name: 'data-width', value: arg.width });
    builder.setAttribute({ name: 'data-title', value: arg.title });
    builder.setAttribute({ name: 'data-content', value: arg.content });

    const dialog = { ...option.dialog, ...arg };
    setState((prev) => ({
      ...prev,
      option: {
        ...prev.option,
        dialog,
      },
    }));

    handlePopupClose();
  };

  /** 시트 렌터링. (옵션 설정 영역) */
  const renderSheet = () => {
    // console.log(`renderSheet():state`, state);

    const approvalItemGroups = [
      { value: 'approval', label: getLocalizedText('결재') },
      { value: 'agree', label: getLocalizedText('합의') },
      { value: 'receive', label: getLocalizedText('수신') },
      { value: 'audit', label: getLocalizedText('감사') },
    ];

    const approvalItemTypes = [
      { value: 'name', label: getLocalizedText('이름') },
      { value: 'sign', label: getLocalizedText('서명') },
      { value: 'jobposition', label: getLocalizedText('직위') },
      { value: 'jobduty', label: getLocalizedText('직책') },
      {
        value: 'jobposition+jobduty',
        label: getLocalizedText('직위+직책'),
      },
      { value: 'action', label: getLocalizedText('행위') },
      { value: 'approvaldate', label: getLocalizedText('결재날짜') },
    ];

    const approvalItemSeqs = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
    ];

    const {
      title,
      description,
      correction,
      textAlign,
      width,
      height,
      value,
      fontSize,
      required,
      rows,
      number,
      items,
      dialog,
      approvalLineGroupIncludeDrafter,
      approvalItem,
      time,
    } = state.option;

    return (
      <div className="edit-option">
        {title === '' ? (
          <>
            <div className="option-none">
              <strong>{getLocalizedText('선택된 항목이 없습니다.')}</strong>
              <p>
                {getLocalizedText(
                  '항목을 선택하면 각 항목에서 설정할 수 있는 속성들을 적용할 수 있습니다.',
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="option-title">{getLocalizedText(`${title}`)}</div>
            <div className="option-description">{description}</div>
            {items !== undefined &&
              (correction === false || correction === undefined) && (
                <div className="option-item">
                  <Alert severity="info" size="sm">
                    {getLocalizedText(
                      '선택항목에서 마우스 오른쪽 클릭시 선택으로 설정이 가능합니다.',
                    )}
                  </Alert>
                </div>
              )}
            {textAlign !== undefined && (
              <div className="option-item">
                <SelectField
                  label={getLocalizedText('정렬')}
                  data={[
                    { value: '', label: getLocalizedText('왼쪽') },
                    {
                      value: 'center',
                      label: getLocalizedText('가운데'),
                    },
                    {
                      value: 'right',
                      label: getLocalizedText('오른쪽'),
                    },
                  ]}
                  value={textAlign}
                  onChange={handleOptionTextAlignChange}
                  block
                />
              </div>
            )}
            {width !== undefined && (
              <div className="option-item">
                <div className="eui-form-field hasLabel">
                  <span className="label" data-shrink="true">
                    {getLocalizedText('너비')}
                  </span>
                  <SelectField
                    data={[
                      { value: '100%', label: '100%' },
                      { value: 'pixel', label: 'pixel' },
                    ]}
                    value={width === '100%' ? '100%' : 'pixel'}
                    onChange={handleOptionWidthTypeChange}
                    block={width === '100%'}
                  />
                  {width !== '100%' && (
                    <TextField
                      pattern="[0-9]*"
                      value={width.replace('px', '')}
                      onChange={handleOptionWidthChange}
                      ref={widthRef}
                      align="right"
                      append="px"
                    />
                  )}
                </div>
              </div>
            )}
            {height !== undefined && (
              <div className="option-item">
                <TextField
                  label={getLocalizedText('높이')}
                  placeholder="px"
                  pattern="[0-9]*"
                  value={height.replace('px', '')}
                  onChange={handleOptionHeightChange}
                />
              </div>
            )}
            {rows !== undefined && (
              <div className="option-item">
                <TextField
                  label={getLocalizedText('행 수')}
                  pattern="[0-9]*"
                  value={rows}
                  onChange={handleOptionRowsChange}
                />
              </div>
            )}
            {number !== undefined && (
              <div className="option-item">
                <SelectField
                  label={getLocalizedText('유형')}
                  data={[
                    // { value: 'NORMAL', label: '일반 숫자' },
                    // { value: 'INPUT', label: '합계 입력' },
                    // { value: 'DISPLAY', label: '합계 표시' },
                    // { value: 'TOTAL', label: '총 합계' },
                    // { value: 'VAT', label: 'VAT' },
                    // { value: 'VATADD', label: 'VAT 포함' },
                    {
                      value: 'CONTROL/NUMBER',
                      label: getLocalizedText('숫자'),
                    },
                    {
                      value: 'CONTROL/CALCULATION_VALUE',
                      label: getLocalizedText('계산값'),
                    },
                    {
                      value: 'CONTROL/FORMULA',
                      label: getLocalizedText('계산식'),
                    },
                  ]}
                  value={number.type}
                  onChange={handleOptionNumberTypeChange}
                  block
                />
              </div>
            )}

            {number?.id !== undefined && (
              <div className="option-item">
                <TextField
                  label={getLocalizedText('아이디')}
                  pattern="[0-9a-zA-Z]*"
                  value={number.id}
                  onChange={handleOptionNumberIdChange}
                />
              </div>
            )}

            {/* 계산식 */}
            {number?.formula !== undefined && (
              <>
                <div className="option-item">
                  <TextField
                    label={getLocalizedText('계산식')}
                    value={number.formula}
                    onChange={handleOptionNumberFormulaChange}
                  />
                </div>
                <div className="option-description">
                  {getLocalizedText('계산식은 + - / * 로 설정해주세요.')}
                </div>
              </>
            )}
            {number?.currencySymbol !== undefined && (
              <div className="option-item">
                <SelectField
                  label={getLocalizedText('통화기호')}
                  data={[
                    { value: '', label: getLocalizedText('미지정') },
                    { value: '￦', label: getLocalizedText('원화') }, // ₩
                    { value: '＄', label: getLocalizedText('달러') },
                    { value: '￥', label: getLocalizedText('위안') }, // ￥ ¥
                    { value: '¥', label: getLocalizedText('엔화') }, // Ұ
                    { value: '€', label: getLocalizedText('유로') },
                    // { value: '￡', label: '파운드' },
                  ]}
                  value={number.currencySymbol}
                  onChange={handleOptionNumberCurrencySymbolChange}
                  block
                />
              </div>
            )}

            {value !== undefined && (
              <div className="option-item">
                <TextField
                  label={getLocalizedText('기본값')}
                  value={value}
                  onChange={handleOptionDefaultValueChange}
                />
              </div>
            )}

            {fontSize !== undefined && (
              <div className="option-item">
                <TextField
                  label={getLocalizedText('글꼴 크기')}
                  pattern="[0-9]*"
                  value={fontSize.replace('px', '')}
                  onChange={handleOptionFontSizeChange}
                />
              </div>
            )}

            {/* 라디오버튼, 체크박스, 펼침목록의 선택항목 */}
            {items !== undefined && (
              <div className="option-item">
                <div className="optional">
                  <div className="optional-title">
                    {getLocalizedText('선택항목')}
                  </div>
                  {correction ? (
                    <>
                      <ReactSortable
                        className="optional-list"
                        list={items}
                        setList={handleOptionItemOrderChange}
                        animation={200}
                      >
                        {items.map((a) => (
                          <div key={a.id} className="list-item">
                            <div className="handle" />
                            <input
                              disabled
                              className="input"
                              type="text"
                              value={a.label}
                            />
                            {a.checked && <div className="badge" />}
                          </div>
                        ))}
                      </ReactSortable>
                    </>
                  ) : (
                    <>
                      <ReactSortable
                        className="optional-list"
                        list={items}
                        setList={handleOptionItemOrderChange}
                        animation={200}
                      >
                        {items.map((a) => (
                          <div
                            key={a.id}
                            className="list-item"
                            onContextMenu={(event) =>
                              handleOptionItemCheckedChange(a.id, event)
                            }
                          >
                            <div className="handle" />
                            <input
                              className="input"
                              type="text"
                              value={a.label}
                              onChange={(event) =>
                                handleOptionItemLabelChange(a.id, event)
                              }
                            />
                            {a.checked && <div className="badge" />}
                            <Button
                              className="delete"
                              text={getLocalizedText('삭제')}
                              iconType
                              icon="trash-full"
                              disabled={items.length < 2}
                              onClick={() => handleOptionItemDelete(a.id)}
                            />
                          </div>
                        ))}
                      </ReactSortable>
                      <Button
                        className="add-optional"
                        text={getLocalizedText('추가')}
                        icon="plus"
                        variant="contained"
                        onClick={handleOptionItemAppend}
                        block
                      />
                    </>
                  )}
                </div>
              </div>
            )}

            {/* 대화상자 */}
            {dialog !== undefined && (
              <div className="option-item">
                <Button
                  block
                  variant="outlined"
                  text={getLocalizedText('대화상자 설정')}
                  onClick={handlePopupOpen}
                />
              </div>
            )}

            {/* 결재선 그룹 기안자 포함 여부 값이 있는 경우. */}
            {approvalLineGroupIncludeDrafter !== undefined && (
              <div className="option-item">
                <Switch
                  label={getLocalizedText('기안자 포함')}
                  checked={approvalLineGroupIncludeDrafter}
                  onChange={handleApprovalLineGroupIncludeDrafterChange}
                  vertical
                />
              </div>
            )}

            {approvalItem !== undefined && (
              <>
                <div className="option-item">
                  <SelectField
                    label={getLocalizedText('그룹')}
                    data={approvalItemGroups}
                    value={approvalItem.group}
                    onChange={(changeValue) =>
                      handleApprovalItemGroupChange({
                        value: changeValue,
                        text:
                          approvalItemGroups.find(
                            (a) => a.value === changeValue,
                          )?.label || '',
                      })
                    }
                    block
                  />
                </div>
                <div className="option-item">
                  <SelectField
                    label={getLocalizedText('유형')}
                    data={approvalItemTypes}
                    value={approvalItem.type}
                    onChange={(changeValue) =>
                      handleApprovalItemTypeChange({
                        value: changeValue,
                        text:
                          approvalItemTypes.find((a) => a.value === changeValue)
                            ?.label || '',
                      })
                    }
                    block
                  />
                </div>
                <div className="option-item">
                  <SelectField
                    label={getLocalizedText('순번')}
                    data={approvalItemSeqs}
                    value={approvalItem.seq}
                    onChange={(changeValue) =>
                      handleApprovalItemSeqChange({
                        value: changeValue,
                        text:
                          approvalItemSeqs.find((a) => a.value === changeValue)
                            ?.label || '',
                      })
                    }
                    block
                  />
                </div>
              </>
            )}

            {time !== undefined && (
              <div className="option-item">
                <div className="optional">
                  <div className="optional-title">
                    {getLocalizedText('시간 간격')}
                  </div>
                  <RadioGroup
                    name={getLocalizedText('시간 간격 설정')}
                    value={time}
                    data={[
                      { value: 15, label: getLocalizedText('15분') },
                      { value: 30, label: getLocalizedText('30분') },
                      { value: 60, label: getLocalizedText('60분') },
                    ]}
                    onChange={handleChangeTimeInterval}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderContentHead = () => {
    return (
      <>
        <Button
          text={getLocalizedText('양식정보로 가기')}
          iconType
          icon="arrow-left"
          className="edit-back"
          onClick={goInfo}
        />
        <div className="edit-title">{name}</div>
        <div className="edit-action">
          <Button text={getLocalizedText('취소')} onClick={onCancel} />
          <Button text={getLocalizedText('미리보기')} onClick={handlePreview} />
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </div>
      </>
    );
  };

  const renderContentBody = () => {
    const { selectedTabId, content, filter } = state;
    return (
      <>
        {/* TAB 버튼 영역 */}
        <div className="edit-toolbar">
          <div className="toolbar-head">
            <div
              className="eui-simple-search toolbar-toolbar"
              style={{ padding: '10px 20px' }}
            >
              <TextField
                type="search"
                value={filter}
                onChange={handleMacroFilter}
                icon="search"
                className="keyword"
                placeholder={getLocalizedText('검색')}
              />
            </div>
            <Tab>
              <Tab.Item
                label={getLocalizedText('기본')}
                selected={selectedTabId === 0}
                onClick={() =>
                  setState((prevState) => ({ ...prevState, selectedTabId: 0 }))
                }
              />
              <Tab.Item
                label={getLocalizedText('게시')}
                selected={selectedTabId === 1}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    selectedTabId: 1,
                  }))
                }
              />
            </Tab>
          </div>
          <div className="toolbar-body">
            <div className="toolbar-group">{renderToolbarButtons()}</div>
          </div>
        </div>

        {/* 에디터 영역 */}
        <div className="edit-content">
          <Dext5
            ref={iframe}
            editorId={editorId}
            editorConfig={editorConfig}
            // onClick={handleClickEditor}
            onLoad={handleEditorLoad}
            value={content}
          />
        </div>
        {renderSheet()}
      </>
    );
  };

  const renderPreviewHead = () => {
    const { name } = props;
    return (
      <>
        <div className="edit-title">{name}</div>
        <div className="edit-action">
          <Button
            text={getLocalizedText('미리보기 닫기')}
            onClick={() =>
              setState((prevState) => ({ ...prevState, preview: false }))
            }
          />
        </div>
      </>
    );
  };

  const renderPreviewBody = () => {
    const { content } = state;
    const html = FormBuilder.createComposeHtml({ html: content });
    return (
      <div
        className="edit-content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };

  const renderDialog = () => {
    const { dialog } = state.option;

    if (state.modifyPopup && dialog)
      return (
        <BoardFormPopupDialog
          option={dialog}
          onSave={handlePopupSave}
          onClose={handlePopupClose}
        />
      );

    return null;
  };

  const { name, goInfo, onCancel } = props;
  const { preview } = state;

  return (
    <Dialog size="full" className="ui-form-edit">
      <div className="ui-form-edit-container">
        <div className="edit-header">
          {preview ? renderPreviewHead() : renderContentHead()}
        </div>
        <div className={`edit-body ${preview ? 'preview-body' : ''}`}>
          {preview ? renderPreviewBody() : renderContentBody()}
        </div>
      </div>
      {renderDialog()}
    </Dialog>
  );
}

export default BoardFormEditContentDialog;
