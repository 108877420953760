import React from 'react';
import Button from '../button/Button';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function SubMenu(props: {
  title: string;
  children: React.ReactNode;
  onBack?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  return (
    <div className="sub-container">
      <div className="sub-head">
        <Button
          className="back"
          text={getLocalizedText('뒤로가기')}
          iconType
          icon="arrow-left"
          onClick={props.onBack}
          color="secondary"
        />
        <h2 className="title">{props.title}</h2>
      </div>
      <div className="sub-content">{props.children}</div>
    </div>
  );
}

export default SubMenu;
