import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { archiveActions } from '../../../../stores/approval/archive';

function ApprovalArchiveSettingsRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`ApprovalArchiveSettingsRoute(props)`, props);

  const { pathname, search, hash } = props;
  const organizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const route = { pathname, search, hash, clearErrors: true };
    if (hash === '#transferhistory')
      dispatch(
        archiveActions.transferHistory({
          id: organizationId,
          search,
          route,
        }),
      );
    else dispatch(sessionActions.setRoute(route));
  }, [pathname, search, hash, organizationId]);
  return null;
}

export default ApprovalArchiveSettingsRoute;
