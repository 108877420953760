import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function SplitUnselected(props: { label?: string }): JSX.Element {
  return (
    <div className="ui-split-unselected">
      {props.label || getLocalizedText('선택된 항목이 없습니다')}
    </div>
  );
}

export default SplitUnselected;
