import React, { useState } from 'react';
import Avatar from '../avatar/Avatar';
import { IconType } from '../../groupware-common/types/icon';
import { dateSimplify } from '../../groupware-common/utils/ui';
import Menu from '../menu/Menu';
import MenuClose from '../menu/MenuClose';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

/**
 * @property name 이름 외 직책/직위 등.
 * @property avatar 아바타
 * @property icon 아이콘 아바타 대체 아이콘
 * @property from 소속
 * @property date 날짜/시간
 * @property onClick 클릭 이벤트
 */
function UserInfo(props: {
  name: string;
  position?: string;
  avatar?: string;
  from?: string;
  icon?: IconType;
  dateEtc?: string;
  date?: string;
  dateType?:
    | 'full'
    | 'date'
    | 'time'
    | 'fullNumber'
    | 'dateNumber'
    | 'timeNumber'
    | 'simple'
    | 'customFormat';
  etc?: string;
  onlyAvatar?: boolean;
  className?: string;
  direction?: 'row' | 'column';
}): JSX.Element {
  const {
    direction,
    name,
    position,
    avatar,
    from,
    dateEtc,
    date,
    etc,
    className,
    icon,
  } = props;

  const [state, setState] = useState<{
    profilePoint: { x: number; y: number };
    profileVisible: boolean;
  }>({
    profilePoint: { x: 0, y: 0 },
    profileVisible: false,
  });

  const handleProfileClose = () => {
    setState((prevState) => ({
      ...prevState,
      profileVisible: false,
    }));
  };

  let classname = 'user-info';
  if (className) classname += ` ${className}`;
  if (direction) classname += ` ${direction}`;

  /* const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      profileVisible: true,
      profilePoint: { x: event.clientX, y: event.clientY },
    }));
  }; */

  return (
    <>
      <div className={classname} aria-expanded={state.profileVisible}>
        <Avatar icon={icon} name={name} image={avatar} className="avatar" />
        {props.onlyAvatar || (
          <div className="info">
            <span className="name">
              {name}
              {position && <em className="position">{position}</em>}
            </span>
            {from && <span className="from">{from}</span>}
            {date && (
              <time className="date">
                {dateEtc ? (
                  <>
                    <span>
                      {dateSimplify(
                        date,
                        props.dateType ? props.dateType : 'full',
                      )}
                    </span>
                    <span style={{ marginLeft: '10px' }}>{dateEtc}</span>
                  </>
                ) : (
                  `${dateSimplify(
                    date,
                    props.dateType ? props.dateType : 'full',
                  )}`
                )}
              </time>
            )}
            {etc && <em className="etc">{etc}</em>}
          </div>
        )}
      </div>
      {state.profileVisible && (
        <Menu
          point={state.profilePoint}
          onClose={handleProfileClose}
          className="ui-profile-card"
        >
          <MenuClose onClick={handleProfileClose} />
          <div className="card-root">
            <div className="side">
              <Avatar
                name={getLocalizedText('홍길동')}
                className="avatar"
                image="/images/faces/faces_31.jpg"
              />
              <strong className="name">{getLocalizedText('홍길동')}</strong>

              <div className="action">
                <Button
                  text={getLocalizedText('통화하기')}
                  iconType
                  icon="phone-fill"
                />
                <Button
                  text={getLocalizedText('이메일 보내기')}
                  iconType
                  icon="mail-fill"
                />
                <Button
                  text={getLocalizedText('쪽지 보내기')}
                  iconType
                  icon="message-fill"
                />
              </div>
            </div>
            <div className="info">
              <dl>
                <dt>
                  <Icon label={getLocalizedText('회사')} icon="company" />
                </dt>
                <dd>{getLocalizedText('이든비즈텍(주)')}</dd>
              </dl>
              <dl>
                <dt>
                  <Icon label={getLocalizedText('소속')} icon="sitemap" />
                </dt>
                <dd>{getLocalizedText('솔루션개발부')}</dd>
              </dl>
              <dl>
                <dt>
                  <Icon label={getLocalizedText('직위/직책')} icon="tie" />
                </dt>
                <dd>{getLocalizedText('과장/팀장')}</dd>
              </dl>
              <dl>
                <dt>
                  <Icon label={getLocalizedText('이메일')} icon="at" />
                </dt>
                <dd>honggildong@edenbiz.co.kr</dd>
              </dl>
              <dl>
                <dt>
                  <Icon label={getLocalizedText('연락처')} icon="mobile" />
                </dt>
                <dd>010-1234-5678</dd>
              </dl>
              <dl>
                <dt>
                  <Icon
                    label={getLocalizedText('회사전화')}
                    icon="phone-office"
                  />
                </dt>
                <dd>02-1234-5678</dd>
              </dl>
              <dl>
                <dt>
                  <Icon label={getLocalizedText('담당업무')} icon="briefcase" />
                </dt>
                <dd>{getLocalizedText('디자이너')}</dd>
              </dl>
            </div>
          </div>
        </Menu>
      )}
    </>
  );
}

export default UserInfo;
