import React, { useState } from 'react';
import Button from '../../../../components/button/Button';
import TextField from '../../../../components/textfield/TextField';
import PostWrite from '../../../../components/post/PostWrite';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import Dialog from '../../../../components/dialog/Dialog';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function ApprovalChange(props: {
  onSave(reason: string): void;
  onClose(): void;
}): JSX.Element {
  // console.log(`${ApprovalChange.name}.render(props)`, props);

  const [state, setState] = useState<string>('');

  const handleChangeReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState(event.target.value);
  };

  return (
    <Dialog size="sm">
      <DialogHeader>
        <DialogTitle>{getLocalizedText('문서변경')}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <PostWrite.Item className="comment-item">
            <TextField
              label={getLocalizedText('변경 사유')}
              multiline
              rows={5}
              count
              maxLength={500}
              value={state}
              onChange={handleChangeReason}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button text={getLocalizedText('취소')} onClick={props.onClose} />
        <Button
          text={getLocalizedText('변경')}
          variant="contained"
          onClick={() => props.onSave(state)}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default ApprovalChange;
