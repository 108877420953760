import React from 'react';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import ToolbarAction from '../../../../../components/toolbarAction/ToolbarAction';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';

type Props = {
  isPhone: boolean;
  title: string;
  totalCount: number;
  checkedCount: number;
  pageNo: number;
  rowsPerPage: number;
  onAction(type: string): void;
  onCheckedChange(arg: { id: 'all'; checked: boolean }): void;
};

function SecuritiesPaymentHead(props: Props): JSX.Element {
  const {
    isPhone,
    title,
    totalCount,
    checkedCount,
    pageNo,
    rowsPerPage,
    onAction,
    onCheckedChange,
  } = props;

  const handleChangeCheckedAll = (checked: boolean) => {
    onCheckedChange({ id: 'all', checked });
  };
  return (
    <EuiHeader>
      <EuiHeader.Title>{title}</EuiHeader.Title>
      <EuiHeader.Toolbar>
        {checkedCount === 0 ? (
          <>
            <EuiHeader.ToolbarLeft>
              <Button
                className="check-all"
                text="선택"
                iconType
                icon="list-check"
                onClick={() => handleChangeCheckedAll(true)}
              />
            </EuiHeader.ToolbarLeft>
            <EuiHeader.ToolbarRight>
              {totalCount > 0 && (
                <Pagination
                  no={pageNo}
                  count={totalCount}
                  rows={rowsPerPage}
                  onNext={() => onAction('next')}
                  onPrev={() => onAction('prev')}
                />
              )}
              {totalCount > 0 && !isPhone && <Divider orientation="vertical" />}
              {!isPhone && (
                <Button
                  text="목록설정"
                  iconType
                  icon="bar-cog"
                  onClick={() => onAction('listSetting')}
                />
              )}
            </EuiHeader.ToolbarRight>
          </>
        ) : (
          <EuiHeader.ToolbarLeft>
            <Button
              className="action-close"
              text="취소"
              iconType
              icon="close"
              onClick={() => handleChangeCheckedAll(false)}
            />
            <span className="selected-count">
              <em>{checkedCount}</em> <span>개 선택됨</span>
            </span>
            <ToolbarAction
              // eslint-disable-next-line prettier/prettier
              event={[{ code: 'draft', label: '기안', type: 'outlined', icon: 'clipboard-text' }]}
              onClick={({ code }) => onAction(code)}
            />
          </EuiHeader.ToolbarLeft>
        )}
      </EuiHeader.Toolbar>
    </EuiHeader>
  );
}

export default SecuritiesPaymentHead;
