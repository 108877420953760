/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { getQueryParams } from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import blockedUserApi from '../apis/authentication/v1/blockedUser';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

export interface BlockedUserItem {
  checked: boolean;
  companyId: number;
  employeeId: number;
  createAt: string;
}

const name = 'authentication/block';

interface State {
  data: BlockedUserItem[];
  total: number;
}

const initialState: State = {
  data: [],
  total: 0,
};

const blockedUsers = createAsyncThunk(
  `${name}/blockedUsers`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const queryParams = getQueryParams(arg.search);
      const result = await blockedUserApi.blockedUser({
        companyId,
        pageNo: queryParams.pageNo ?? 1,
        rowsPerPage: queryParams.rowsPerPage ?? 15,
      });
      const total = await blockedUserApi.blockedUserTotal({ companyId });
      const data = result.map((a) => ({
        checked: false,
        companyId: a.companyId,
        employeeId: a.employeeId,
        createAt: a.createAt,
      }));
      return {
        data,
        total,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const unBlockUsers = createAsyncThunk(
  `${name}/unBlockUsers`,
  async (
    arg: {
      data: number[];
    } & LocateArg,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const result = await blockedUserApi.unBlockUsers({
        companyId,
        ids: arg.data,
      });
      if (result.length !== arg.data.length && arg.route) {
        await dispatch(
          blockedUsers({ search: arg.route.search ?? '', route: arg.route }),
        );
        return rejectWithValue(
          appError({
            error: getLocalizedText('일부 사용자는 차단 해제 실패하였습니다.', {
              ns: 'authentication',
            }),
          }),
        );
      }
      if (arg.route)
        dispatch(
          blockedUsers({ search: arg.route.search ?? '', route: arg.route }),
        );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const blockedUserSlice = createSlice({
  name,
  initialState,
  reducers: {
    setItemChecked(
      state,
      action: PayloadAction<{ id: number | 'all'; checked: boolean }>,
    ) {
      if (action.payload.id === 'all')
        state.data = state.data.map((a) => {
          if (a.checked === action.payload.checked) return a;
          return { ...a, checked: action.payload.checked };
        });
      else {
        const index = state.data.findIndex(
          (a) => a.employeeId === action.payload.id,
        );
        if (index > -1)
          state.data[index] = {
            ...state.data[index],
            checked: action.payload.checked,
          };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(blockedUsers.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.total = payload.total;
    });
  },
});

export default blockedUserSlice.reducer;

export const blockedUserActions = {
  setItemChecked: blockedUserSlice.actions.setItemChecked,

  blockedUsers,
  unBlockUsers,
};
