import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import DirectorySearch from '../../../../../components/search/DirectorySearch';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { FileUploadProps } from '../../../../../groupware-common/types';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import attendanceFileApi from '../../../../apis/attendance/v1/common';
import { adjustActions } from '../../../../stores/attendance/adjust';
import AttendanceAdjustDrawer from './AttendanceAdjustDrawer';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

function AttendanceAdjustContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname } = props;
  const queryParams = getQueryParams(props.search);
  const { folderId } = getPathParams<{ folderId: string }>(
    '/*/*/:folderId',
    pathname,
  );

  const list = useSelector(
    (state: RootState) => state.attendance.adjust.list.items,
  );
  const totalCount = useSelector(
    (state: RootState) => state.attendance.adjust.list.totalCount,
  );
  const principal = useSelector((state: RootState) => state.session.principal);
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const categoryId = folderId === 'dayOffAdjust' ? 6005 : 6009;
  const title = categories.find(({ id }) => id === categoryId)?.name ?? '';
  const directory = useDirectory();

  const dispatch = useAppDispatch();

  const [state, setState] = useState('');
  const defaultActions: ActionEventProps[] = [
    {
      code: 'create',
      label: getLocalizedText('등록'),
      type: 'icon',
      icon: 'plus-circle-fill',
    },
  ];

  const [columns, setColumns] = useState<DataGridColDef[]>([
    {
      field: 'days',
      label: getLocalizedText('조정일자'),
      minWidth: 118,
    },
    {
      field: 'employeeNo',
      label: getLocalizedText('사번'),
      minWidth: 120,
    },
    {
      field: 'user',
      label: getLocalizedText('이름'),
      minWidth: 158,
    },
    {
      field: 'useDays',
      label: getLocalizedText('조정'),
      align: 'center',
      width: 150,
    },
    {
      field: 'reason',
      label: getLocalizedText('사유'),
      minWidth: 200,
    },
    {
      field: 'download',
      label: getLocalizedText('첨부파일'),
      align: 'center',
      width: 98,
    },
  ]);
  const rows: DataGridRowsProps<number> = list.map((a) => {
    const directoryData = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId: a.employeeId,
    });
    const employeeNo =
      directory.employees.find(({ id }) => id === a.employeeId)?.no ?? ''; // 사번.
    return [
      { type: 'date' as const, value: a.modifingDate, format: 'dateNumber' },
      { type: 'text' as const, value: employeeNo },
      {
        type: 'user' as const,
        avatar: directoryData.avatar ?? '',
        icon: directoryData.avatar === '' ? undefined : 'person',
        value: `${directoryData.employeeName} ${directoryData.jobPositionName}`,
        from: directoryData.organizationName,
      },
      { type: 'text' as const, value: a.modifingLeaves },
      { type: 'text' as const, value: a.remark },
      {
        type: 'download',
        value: a.attachedFiles ? `${a.attachedFiles.name}` : undefined,
        icon: a.attachedFiles ? 'file-download' : undefined,
        id: a.attachedFiles ? a.attachedFiles.id : 0,
        fileId: a.attachedFiles ? a.attachedFiles.attachedFileId : 0,
      },
    ];
  });

  /** 첨부파일 객체 생성 */
  const createAttachedFiles = (data: {
    attachedFiles: FileUploadProps[];
    uploadPath: string;
  }): {
    path: string;
    attendanceFileId: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[] => {
    const { attachedFiles, uploadPath } = data;

    return attachedFiles.map((a, i) => {
      return {
        path: a.file !== undefined ? uploadPath : '',
        attendanceFileId: parseInt(a.id, 10),
        seq: i + 1,
        name: a.name,
        size: a.size,
      };
    });
  };
  /** 업로드 파일 객체 생성 */
  const createUploadFiles = (attachedFiles: FileUploadProps[]) => {
    return attachedFiles
      .filter(
        (a): a is { id: string; name: string; size: number; file: File } =>
          a.file !== undefined,
      )
      .map((a) => {
        return {
          id: parseInt(a.id, 10),
          file: a.file,
        };
      });
  };

  const handleClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
    name?: string; // 파일 다운로드 시에만 존재.
    fileId?: number; // 파일 다운로드 시에만 존재.
  }) => {
    const { code, id, name, fileId } = arg;

    /** 생성 드로워 열기. */
    if (code === 'create') {
      dispatch(sessionActions.setDrawer({ mode: 'create' }));
    }

    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = (queryParams.pageNo || 1) - 1;
      if (queryParams.pageNo > 0) {
        if (queryParams.pageNo === 1) {
          delete queryParams.pageNo;
        }
        dispatch(
          adjustActions.list({
            folderId,
            search: getQueryParams(queryParams),
            route: {
              pathname,
              search: getQueryParams(queryParams),
            },
          }),
        );
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = (queryParams.pageNo || 1) + 1;
      dispatch(
        adjustActions.list({
          folderId,
          search: getQueryParams(queryParams),
          route: {
            pathname,
            search: getQueryParams(queryParams),
          },
        }),
      );
    }

    /** 첨부파일 다운로드. */
    if (code === 'download') {
      if (id && name && fileId) {
        attendanceFileApi
          .download(id, fileId)
          .then((blob) => {
            if (!blob)
              throw new Error(
                getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
              );

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 3000);
            a.remove();
          })
          .catch((ex) => {
            dispatch(sessionActions.error(appError(ex)));
          });
      }
    }
  };

  /** 목록 개수 변경. */
  const handleChangeRowLength = (value: number) => {
    queryParams.rowsPerPage = value;
    delete queryParams.pageNo;
    dispatch(
      adjustActions.list({
        folderId,
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록 노출. */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  /** 드로워 닫기. */
  const handleDrawerClose = () => {
    dispatch(sessionActions.setDrawer());
  };

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    directoryFilter?: string;
    date: {
      start: Date | null;
      end: Date | null;
    };
    status?: string;
  }) => {
    delete queryParams.pageNo;
    queryParams.searchCode = arg.directoryFilter ?? '';
    queryParams.searchWord = arg.keyword;
    queryParams.directoryKeyword = arg.directoryKeyword;
    queryParams.startDate = arg.date.start
      ? dateFormat(initialDate(arg.date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = arg.date.end
      ? dateFormat(initialDate(arg.date.end), 'yyyy-MM-DD')
      : undefined;
    dispatch(
      adjustActions.list({
        folderId,
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 연차조정 등록. */
  const handleSave = (arg: {
    id: number;
    name: string;
    reason: string;
    adjustMinutes: number;
    attachedment?: FileUploadProps;
  }) => {
    const { id, name, reason, adjustMinutes, attachedment } = arg;
    if (id === 0 || name === '') {
      setState(getLocalizedText('대상자를 선택해주세요.'));
      return;
    }
    if (adjustMinutes === 0) {
      setState(getLocalizedText('조정시간을 입력해주세요.'));
      return;
    }
    if (reason === '') {
      setState(getLocalizedText('사유를 입력해주세요.'));
      return;
    }

    const params = getQueryParams('');
    params.rowsPerPage = queryParams.rowsPerPage;
    const uploadPath = `${Date.now()}`;
    const data: {
      modifingDate: string;
      employeeId: number;
      modifingLeaves: number;
      remark: string;
      attachedFiles?: {
        path: string;
        attendanceFileId: number;
        seq: number;
        name: string;
        size: number;
        delete?: boolean;
        copy?: boolean;
      }[];
    } = {
      modifingDate: dateFormat(new Date(), 'yyyy-MM-DD'),
      employeeId: id,
      modifingLeaves: adjustMinutes,
      remark: reason,
      attachedFiles: attachedment
        ? createAttachedFiles({
            attachedFiles: [attachedment],
            uploadPath,
          })
        : undefined,
    };

    if (attachedment && data.attachedFiles && data.attachedFiles.length > 0) {
      const uploadFiles = createUploadFiles([attachedment]);
      attendanceFileApi
        .upload(uploadPath, uploadFiles)
        .then(() => {
          // console.log(`attendanceFileApi.upload:then:data`, data);
          dispatch(
            adjustActions.create({
              folderId,
              data,
              route: {
                pathname,
                search: getQueryParams(params),
              },
            }),
          );
        })
        .catch(() => {
          // console.log(`fileApi.upload:catch`);
        });
    } else
      dispatch(
        adjustActions.create({
          folderId,
          data,
          route: {
            pathname,
            search: getQueryParams(params),
          },
        }),
      );
  };

  const renderDrawer = () => {
    const { drawerMode } = queryParams;
    if (drawerMode === 'create') {
      return (
        <AttendanceAdjustDrawer
          folderId={folderId}
          onClose={handleDrawerClose}
          onSave={handleSave}
        />
      );
    }
    return null;
  };
  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState('');
  };

  const newYearDate = timezoneDate();
  newYearDate.setMonth(0);
  newYearDate.setDate(1);
  const start = queryParams.startDate
    ? timezoneDate(queryParams.startDate)
    : newYearDate;
  const end = timezoneDate(queryParams.endDate ?? undefined);

  const renderContent = () => {
    return (
      <>
        <EuiHeader>
          <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
          <EuiHeader.Search>
            <DirectorySearch
              filter={{
                value: queryParams.searchCode ?? '',
                useType: 'allDirectory',
              }}
              date={{ start, end }}
              keyword={queryParams.searchWord}
              onSearch={handleSearch}
            />
          </EuiHeader.Search>
        </EuiHeader>
        <EuiBody>
          <DataGrid
            caption={getLocalizedText(`${title}`)}
            columns={columns}
            rows={rows}
            pagination={{
              no: queryParams.pageNo || 1,
              total: totalCount,
              row: queryParams.rowsPerPage || 15,
              onChangeRow: handleChangeRowLength,
            }}
            defaultActions={defaultActions}
            onClick={handleClick}
            onChangeColumnVisible={handleChangeColumnVisible}
          />
        </EuiBody>
      </>
    );
  };

  return (
    <>
      {renderContent()}
      {renderDrawer()}
      <FeedBack text={state} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceAdjustContainer;
