import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../..';
import FeedBack from '../../../../../components/alert/FeedBack';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import Loading from '../../../../../components/loading/Loading';
import Menu from '../../../../../components/menu/Menu';
import PostWrite from '../../../../../components/post/PostWrite';
import NavigationGuard from '../../../../../components/prompt/NavigationGuard';
import DetailRepeatDialog, {
  dateToWeekString,
  formatRepeatDaysType,
  RepeatDaysType,
  summaryType,
} from '../../../../../components/repeatDialog/DetailRepeatDialog';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import Tree from '../../../../../components/tree/Tree';
import { FileUploadProps } from '../../../../../groupware-common/types';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { itemApi as resourceItemApi } from '../../../../../groupware-resource/apis/resource/v1/folder';
import { ResourceListItem } from '../../../../../groupware-resource/stores/folders';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { schedulesActions } from '../../../../stores/calendar/schedules';
import CalendarResourceSelect from '../common/CalendarResourceSelect';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function formatDate(date: Date | string, isFull: boolean) {
  if (isFull) return new Date(date);
  return timezoneDate(date);
}

/** 예약 상태 */
function formatReservationStatus(status?: string) {
  switch (status) {
    case 'WAIT':
      return getLocalizedText('승인대기');
    case 'PASS':
      return getLocalizedText('예약완료');
    case 'REJECT':
      return getLocalizedText('반려');
    default:
      return '';
  }
}

function formatReservationTheme(status?: string) {
  switch (status) {
    case 'WAIT':
      return 'primary';
    case 'REJECT':
      return 'error';
    case 'PASS':
      return 'success';
    default:
      return undefined;
  }
}

function CalendarComposeContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);

  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const list = useSelector(
    (state: RootState) => state.calendar.calendars.user.list,
  );
  const sharedList = useSelector(
    (state: RootState) => state.calendar.calendars.user.sharedList,
  );
  const view = useSelector((state: RootState) => state.calendar.schedules.view);
  const updateData = useSelector(
    (state: RootState) => state.calendar.schedules.updateData,
  );
  const isSharedSchedule = view && view.rootEventId !== 0;

  /** 트리 아이템 생성. */
  const treeData: {
    id: number;
    parentId: number;
    text: string;
    icon?: IconType;
    color?: string;
  }[] = [
    { id: 0, parentId: -1, text: getLocalizedText('캘린더 선택') },
    ...list
      .filter((a) => a.status)
      .map((a) => {
        return {
          id: a.id,
          parentId: 0,
          text: a.name,
          icon: 'folder-fill' as const,
          color: a.color,
        };
      }),
    ...sharedList
      .filter((a) => a.status && a.permissions.useWrite)
      .map((a) => {
        return {
          id: a.id,
          parentId: 0,
          text: a.name,
          icon: 'folder-fill' as const,
          color: a.color,
        };
      }),
  ];

  const time: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    time.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    time.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }

  const week = [
    getLocalizedText('일'),
    getLocalizedText('월'),
    getLocalizedText('화'),
    getLocalizedText('수'),
    getLocalizedText('목'),
    getLocalizedText('금'),
    getLocalizedText('토'),
  ];
  const todayStart = timezoneDate();
  if (todayStart.getMinutes() < 30) todayStart.setMinutes(0);
  else todayStart.setMinutes(30);
  const todayEnd = new Date(todayStart);
  todayEnd.setMinutes(todayStart.getMinutes() + 30);

  const initialState = view
    ? {
        loading: true,
        title: updateData?.name ?? '',
        description: updateData?.description ?? '',
        start: updateData ? new Date(updateData.start) : todayStart,
        end: updateData ? new Date(updateData.end) : todayEnd,
        calendarId: updateData?.calendarId ?? 0,
        calendarName: updateData?.calendarName ?? '',
        calendarMenuPoint: undefined,
        isRepeatPopup: false,
        repeatState: 'false',
        repeat:
          updateData?.repeatType === 'piece'
            ? undefined
            : view.repeat && {
                frequency: view.repeat.frequency,
                cycle: view.repeat.cycle.toString(),
                repeatDays: {
                  mon: view.repeat.repeatDays.some((a) => a === week[1]),
                  tue: view.repeat.repeatDays.some((a) => a === week[2]),
                  wed: view.repeat.repeatDays.some((a) => a === week[3]),
                  thu: view.repeat.repeatDays.some((a) => a === week[4]),
                  fri: view.repeat.repeatDays.some((a) => a === week[5]),
                  sat: view.repeat.repeatDays.some((a) => a === week[6]),
                  sun: view.repeat.repeatDays.some((a) => a === week[0]),
                },
                monthRepeatStandard: view.repeat.monthRepeatStandard,
                endType:
                  view.repeat.endDate !== '9999-12-31'
                    ? getLocalizedText('반복 종료일')
                    : getLocalizedText('계속 반복'),
                endDay: view.repeat.endDate,
                endCount: 1,
              },
        summary: undefined,
        isAllDay: updateData?.isAllDay ?? false,
        useResource: updateData?.useResource ?? false,
        resourcesItems: [],
        resourceItemId: updateData?.resourceItemId,
        resourceSelect: false,
        alarms: view.alarms,
        participantMenuPoint: undefined,
        participants: view.participants,
        attachments: [],
      }
    : {
        loading: true,
        title: updateData?.name ?? '',
        description: updateData?.description ?? '',
        start: updateData ? new Date(updateData.start) : todayStart,
        end: updateData ? new Date(updateData.end) : todayEnd,
        calendarId: updateData?.calendarId ?? 0,
        calendarName: updateData?.calendarName ?? '',
        calendarMenuPoint: undefined,
        isRepeatPopup: false,
        repeatState: 'false',
        repeat: undefined,
        summary: undefined,
        isAllDay: updateData?.isAllDay ?? false,
        useResource: updateData?.useResource ?? false,
        resourcesItems: [],
        resourceItemId: updateData?.resourceItemId,
        resourceSelect: false,
        alarms: [],
        participantMenuPoint: undefined,
        participants: [],
        attachments: [],
      };

  const [state, setState] = useState<{
    loading: boolean;
    title: string;
    description: string;
    start: Date;
    end: Date;
    calendarId: number;
    calendarName: string;
    isRepeatPopup: boolean;
    repeatState: string;
    repeat?: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: string; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    };
    summary?: {
      one: string;
      two: string;
      three: string;
    }; // 반복 요약 객체.
    isAllDay: boolean;
    useResource: boolean; // 자원 예약 사용 유무.
    resourcesItems: ResourceListItem[];
    resourceItemId?: number;
    resourceSelect: boolean;
    alarms: {
      id: number;
      type: string;
      timeUnit: string;
      ammount: number;
      updateAt?: string;
    }[];
    participants: {
      participantCompanyId: number;
      participantId: number;
      updateAt?: string;
    }[];
    attachments: FileUploadProps[];
    calendarMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    participantMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(initialState);
  const [validation, setValidation] = useState('');
  const [reload, setReload] = useState(true);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록; deprecated
  };

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', handlePrevClose);
    })();
    if (reload) {
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();

      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    return () => {
      window.removeEventListener('beforeunload', handlePrevClose);
    };
  }, [queryParams.contentMode]);

  /** 반복일정 리스트 구하기. */
  const makeRepeatList = (date: Date) => {
    if (dateToWeekString(date) === getLocalizedText('다섯'))
      return [
        { value: 'false', label: getLocalizedText('반복 안함') },
        { value: 'days', label: getLocalizedText('매일') },
        { value: 'weeks', label: `매주 ${week[date.getDay()]}요일` },
        {
          value: 'lastMonths',
          label: `매월 마지막 ${week[date.getDay()]}요일`,
        },
        {
          value: 'years',
          label: `매년 ${dateFormat(date, 'MM월 DD일')}`,
        },
        { value: 'weekdays', label: `주중 매일(월-금)` },
        { value: 'etc', label: getLocalizedText('맞춤...') },
      ];
    const aWeekLater = new Date(date);
    aWeekLater.setDate(date.getDate() + 7);
    if (aWeekLater.getMonth() === date.getMonth())
      return [
        { value: 'false', label: getLocalizedText('반복 안함') },
        { value: 'days', label: getLocalizedText('매일') },
        { value: 'weeks', label: `매주 ${week[date.getDay()]}요일` },
        {
          value: 'months',
          label: `매월 ${dateToWeekString(date)}번째 ${
            week[date.getDay()]
          }요일`,
        },
        {
          value: 'years',
          label: `매년 ${dateFormat(date, 'MM월 DD일')}`,
        },
        { value: 'weekdays', label: `주중 매일(월-금)` },
        { value: 'etc', label: getLocalizedText('맞춤...') },
      ];
    return [
      { value: 'false', label: getLocalizedText('반복 안함') },
      { value: 'days', label: getLocalizedText('매일') },
      { value: 'weeks', label: `매주 ${week[date.getDay()]}요일` },
      {
        value: 'months',
        label: `매월 ${dateToWeekString(date)}번째 ${week[date.getDay()]}요일`,
      },
      {
        value: 'lastMonths',
        label: `매월 마지막 ${week[date.getDay()]}요일`,
      },
      {
        value: 'years',
        label: `매년 ${dateFormat(date, 'MM월 DD일')}`,
      },
      { value: 'weekdays', label: `주중 매일(월-금)` },
      { value: 'etc', label: getLocalizedText('맞춤...') },
    ];
  };
  const initialSelected = makeRepeatList(state.start);
  const [repeatSelected, setRepeatSelected] = useState(initialSelected);

  useEffect(() => {
    async function run() {
      const resourcesItems = (
        await resourceItemApi.userList({
          organizationId: principal.organizationId,
          employeeId: principal.employeeId,
        })
      )
        .map((a) => ({
          ...a,
          availableFromTime: a.useTimeAvailable ? a.availableFromTime : '00:00',
          availableToTime: a.useTimeAvailable ? a.availableToTime : '00:00',
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      setState((prev) => ({
        ...prev,
        resourcesItems,
        loading: false,
      }));
    }
    run();
  }, [state.start, state.end]);

  useEffect(() => {
    setState(initialState);
    if (view && view.repeat && updateData?.repeatType !== 'piece') {
      const summary = summaryType(formatDate(view.startDateTime, view.isFull), {
        frequency: view.repeat.frequency,
        cycle: view.repeat.cycle.toString(),
        repeatDays: view.repeat.repeatDays,
        monthRepeatStandard: view.repeat.monthRepeatStandard,
        endType:
          view.repeat.endDate !== '9999-12-31'
            ? getLocalizedText('반복 종료일')
            : getLocalizedText('계속 반복'),
        endDay: view.repeat.endDate,
        endCount: 0,
      });
      const value =
        view.repeat.frequency === 1
          ? `${summary.one}${summary.three}`
          : `${summary.one}${summary.two}${summary.three}`;
      const selected = initialSelected.find((a) => a.label === value);
      if (selected) {
        setState((prev) => ({
          ...prev,
          summary,
          repeatState: selected.value,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          summary,
          repeatState: 'add',
        }));
        initialSelected.splice(6, 0, { value: 'add', label: `${value}` });
      }
      setRepeatSelected(initialSelected);
    }
  }, [view]);

  const alarmTypeData = [
    { value: 'NONE', label: '- 선택 -' },
    { value: 'ALARM', label: getLocalizedText('알람') },
    { value: 'MAIL', label: getLocalizedText('메일') },
  ];
  const timeTypeData = [
    { value: 'MINUTE', label: getLocalizedText('분전') },
    { value: 'HOUR', label: getLocalizedText('시간전') },
    { value: 'DAY', label: getLocalizedText('일전') },
    { value: 'WEEK', label: getLocalizedText('주전') },
  ];
  const resource = state.resourcesItems.find(
    (a) => a.id === state.resourceItemId,
  );

  const handleCancel = () => {
    delete queryParams.contentMode;
    delete queryParams.queryWord;
    go(pathname, getQueryParams(queryParams), hash);
  };

  /** 저장 */
  const handleSave = () => {
    if (state.calendarId === 0 || state.calendarName === '') {
      setValidation(() => getLocalizedText('캘린더를 선택하세요.'));
      return;
    }
    if (state.title.trim() === '') {
      setValidation(getLocalizedText('제목을 입력하세요.'));
      return;
    }
    if (state.useResource && !state.resourceItemId) {
      setValidation(getLocalizedText('자원을 선택하세요.'));
      return;
    }
    if (state.alarms.find((a) => a.type === 'NONE')) {
      setValidation(getLocalizedText('알림 타입을 지정하세요.'));
      return;
    }
    if (state.alarms.find((a) => a.ammount === 0)) {
      setValidation(getLocalizedText('알림 시간을 지정하세요.'));
      return;
    }
    delete queryParams.contentMode;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
      },
    });

    let { start, end } = state;
    if (state.isAllDay) {
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
    } else {
      start = initialDate(start);
      end = initialDate(end);
    }

    const data = {
      employeeId: principal.employeeId,
      name: state.title,
      description: state.description,
      startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      resourceItemId: state.resourceItemId,
      isFull: state.isAllDay,
      repeat:
        state.repeat && !state.useResource
          ? {
              frequency: state.repeat.frequency,
              cycle: parseInt(state.repeat.cycle, 10),
              monthRepeatStandard:
                state.repeat.frequency === 3
                  ? state.repeat.monthRepeatStandard
                  : undefined,
              endDate:
                state.repeat.endType === getLocalizedText('반복 종료일')
                  ? dateFormat(initialDate(state.repeat.endDay), 'yyyy-MM-DD')
                  : '9999-12-31',
              days:
                state.repeat.frequency === 2
                  ? state.repeat.repeatDays
                  : undefined,
            }
          : undefined,
      participants:
        state.participants.length > 0
          ? state.participants.map((a) => ({
              companyId: a.participantCompanyId,
              employeeId: a.participantId,
            }))
          : [],
      alarms:
        state.alarms.length > 0
          ? state.alarms.map((a) => ({
              type: a.type,
              timeUnit: a.timeUnit,
              ammount: a.ammount,
            }))
          : [],
    };
    setReload(false);
    dispatch(
      schedulesActions.create({
        calendarId: state.calendarId,
        data,
        location,
      }),
    );
  };

  /** 수정 */
  const handleUpdate = () => {
    if (!view) return;
    if (state.calendarId === 0 || state.calendarName === '') {
      setValidation(() => getLocalizedText('캘린더를 선택하세요.'));
      return;
    }
    if (state.title.trim() === '') {
      setValidation(getLocalizedText('제목을 입력하세요.'));
      return;
    }
    if (state.alarms.find((a) => a.type === 'NONE')) {
      setValidation(getLocalizedText('알림 타입을 지정하세요.'));
      return;
    }
    if (state.alarms.find((a) => a.ammount === 0)) {
      setValidation(getLocalizedText('알림 시간을 지정하세요.'));
      return;
    }
    delete queryParams.contentMode;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
      },
    });
    setReload(false);
    // 공유 받은 일정 수정
    if (isSharedSchedule) {
      const calCreatorId = sharedList.find(
        (a) => a.id === view.calendarId,
      )?.creatorId;
      const employeeId = view.participants.find(
        (a) => a.participantId === calCreatorId,
      )?.participantId;
      dispatch(
        schedulesActions.updateSharedSchedule({
          employeeId: employeeId ?? principal.employeeId,
          eventId: view.id,
          data: state.alarms.map((a) => ({
            type: a.type,
            timeUnit: a.timeUnit,
            ammount: a.ammount,
          })),
          location,
        }),
      );
      return;
    }

    let repeat:
      | {
          frequency: number;
          cycle: number;
          monthRepeatStandard?: number;
          endDate: string;
          days?: RepeatDaysType;
          isDelete: boolean; // 반복객체 사용 유무
        }
      | undefined = state.repeat
      ? {
          frequency: state.repeat.frequency,
          cycle: parseInt(state.repeat.cycle, 10),
          monthRepeatStandard:
            state.repeat.frequency === 3
              ? state.repeat.monthRepeatStandard
              : undefined,
          endDate:
            state.repeat.endType === getLocalizedText('반복 종료일')
              ? dateFormat(initialDate(state.repeat.endDay), 'yyyy-MM-DD')
              : '9999-12-31',
          days:
            state.repeat.frequency === 2 ? state.repeat.repeatDays : undefined,
          isDelete: false,
        }
      : undefined;
    const oldRepeat = view.repeat
      ? {
          frequency: view.repeat.frequency,
          cycle: view.repeat.cycle,
          monthRepeatStandard:
            view.repeat.frequency === 3
              ? view.repeat.monthRepeatStandard
              : undefined,
          endDate:
            view.repeat.endDate !== '9999-12-31'
              ? dateFormat(initialDate(view.repeat.endDate), 'yyyy-MM-DD')
              : '9999-12-31',
          days:
            view.repeat.repeatDays.length === 0
              ? undefined
              : {
                  mon: view.repeat.repeatDays.some((a) => a === week[1]),
                  tue: view.repeat.repeatDays.some((a) => a === week[2]),
                  wed: view.repeat.repeatDays.some((a) => a === week[3]),
                  thu: view.repeat.repeatDays.some((a) => a === week[4]),
                  fri: view.repeat.repeatDays.some((a) => a === week[5]),
                  sat: view.repeat.repeatDays.some((a) => a === week[6]),
                  sun: view.repeat.repeatDays.some((a) => a === week[0]),
                },
          isDelete: false,
        }
      : undefined;
    if (JSON.stringify(oldRepeat) === JSON.stringify(repeat))
      repeat = undefined;
    else if (oldRepeat && !repeat) repeat = { ...oldRepeat, isDelete: true };

    const newAlarms: {
      id?: number;
      type: string;
      timeUnit: string;
      ammount: number;
      isDelete: boolean;
    }[] = state.alarms.map((a) => ({
      id: a.updateAt ? a.id : undefined,
      type: a.type,
      timeUnit: a.timeUnit,
      ammount: a.ammount,
      isDelete: false,
    }));
    const newPartis: {
      companyId: number;
      employeeId: number;
      isDelete: boolean;
    }[] = state.participants.map((a) => ({
      companyId: a.participantCompanyId,
      employeeId: a.participantId,
      isDelete: false,
    }));

    if (view.alarms.length > 0) {
      view.alarms.forEach((a) => {
        if (newAlarms.find((b) => b.id === a.id) === undefined)
          newAlarms.push({
            id: a.id,
            type: a.type,
            timeUnit: a.timeUnit,
            ammount: a.ammount,
            isDelete: true,
          });
      });
    }
    if (view.participants.length > 0) {
      view.participants.forEach((a) => {
        const index = newPartis.findIndex(
          (b) => b.employeeId === a.participantId && !b.isDelete,
        );
        if (index !== -1) newPartis.splice(index, 1);
        if (
          state.participants.find(
            (b) => b.participantId === a.participantId,
          ) === undefined
        ) {
          newPartis.push({
            companyId: a.participantCompanyId,
            employeeId: a.participantId,
            isDelete: true,
          });
        }
      });
    }
    let { start, end } = state;
    if (state.isAllDay) {
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
    } else {
      start = initialDate(start);
      end = initialDate(end);
    }
    const parseStart = updateData
      ? initialDate(updateData.originStart)
      : undefined;

    // 반복 일정 수정
    if (parseStart && updateData?.repeatType) {
      const update = {
        calendarId: state.calendarId,
        eventId: view.id,
        repeatType: updateData.repeatType,
        data: {
          employeeId: view.employeeId,
          name: state.title,
          description: state.description,
          startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          isFull: state.isAllDay,
          alarms: newAlarms,
          updateAt: view.updateAt,
        },
        location,
      };
      if (updateData.repeatType !== 'all') {
        const arg = {
          ...update,
          data: {
            ...update.data,
            repeat: updateData.repeatType === 'after' ? repeat : undefined,
            participants: newPartis.length === 0 ? undefined : newPartis,
            lookupStartDateTime: dateFormat(
              parseStart,
              'yyyy-MM-DD[T]HH:mm:ss.SSS',
            ),
          },
        };
        dispatch(schedulesActions.updateRecurringSchedule(arg));
      } else {
        // 모든 일정 수정.
        const arg = {
          ...update,
          data: {
            ...update.data,
            repeat,
            participants: newPartis.length === 0 ? undefined : newPartis,
          },
        };
        dispatch(schedulesActions.updateRecurringSchedule(arg));
      }
    }
    // 일반 일정 수정
    else {
      const isResourceDelete = view.resource
        ? view.resource && !state.resourceItemId
        : undefined;
      const resourceItemId =
        view.resource?.resourceItemId === state.resourceItemId
          ? undefined
          : state.resourceItemId;
      const data = {
        calendarId: state.calendarId,
        eventId: view.id,
        data: {
          employeeId: principal.employeeId,
          name: state.title,
          description: state.description,
          startDateTime: dateTimeFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          endDateTime: dateTimeFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          isFull: state.isAllDay,
          repeat,
          isResourceDelete,
          resourceItemId,
          participants: newPartis.length === 0 ? undefined : newPartis,
          alarms: newAlarms,
          updateAt: view.updateAt,
        },
        location,
      };
      dispatch(schedulesActions.update(data));
    }
  };

  /** 캘린더 메뉴 선택. */
  const handleClick = (event?: React.MouseEvent) => {
    const { calendarMenuPoint } = state;
    if (event !== undefined && calendarMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        calendarMenuPoint: { x, y, width, height },
      }));
    } else setState((prev) => ({ ...prev, calendarMenuPoint: undefined }));
  };

  /** 캘린더 선택. */
  const handleItemClick = (id: number) => {
    const calendarName = treeData.find((a) => a.id === id)?.text ?? '';
    setState((prev) => ({
      ...prev,
      calendarId: id,
      calendarName,
      calendarMenuPoint: undefined,
    }));
  };

  /** 제목 변경. */
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  /** 내용 변경. */
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  /** 반복일 변경. */
  const handleChangeRepeatUse = (value: string) => {
    if (value !== 'add' && value !== 'etc') setRepeatSelected(initialSelected);
    if (value === 'false')
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: undefined,
        summary: undefined,
      }));
    else if (value === 'days') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 1,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 0,
          endType: getLocalizedText('계속 반복'),
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: getLocalizedText('매일'),
          two: '',
          three: '',
        },
      }));
    } else if (value === 'weeks') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 2,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 0,
          endType: getLocalizedText('계속 반복'),
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: getLocalizedText('매주'),
          two: ` ${week[state.start.getDay()]}요일`,
          three: '',
        },
      }));
    } else if (value === 'months') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 3,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 1,
          endType: getLocalizedText('계속 반복'),
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: getLocalizedText('매월'),
          two: ` ${dateToWeekString(state.start)}번째 ${
            week[state.start.getDay()]
          }요일`,
          three: '',
        },
      }));
    } else if (value === 'lastMonths') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 3,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 2,
          endType: getLocalizedText('계속 반복'),
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: getLocalizedText('매월'),
          two: ` 마지막 ${week[state.start.getDay()]}요일`,
          three: '',
        },
      }));
    } else if (value === 'years') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 4,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 0,
          endType: getLocalizedText('계속 반복'),
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: getLocalizedText('매년'),
          two: ` ${dateFormat(state.start, 'MM월 DD일')}`,
          three: '',
        },
      }));
    } else if (value === 'weekdays') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 2,
          cycle: '1',
          repeatDays: {
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: false,
            sun: false,
          },
          monthRepeatStandard: 0,
          endType: getLocalizedText('계속 반복'),
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: getLocalizedText('매주'),
          two: getLocalizedText(' 월요일 화요일 수요일 목요일 금요일'),
          three: '',
        },
      }));
    } else if (value === 'etc') {
      setState((prev) => ({
        ...prev,
        isRepeatPopup: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        repeatState: value,
      }));
    }
  };

  /** 반복설정 저장 */
  const handleRepeatSave = (
    repeat: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: string; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    },
    summary: {
      one: string;
      two: string;
      three: string;
    },
  ) => {
    const { one, two, three } = summary;
    let selectedData =
      repeat.frequency === 1 ? `${one}${three}` : `${one}${two}${three}`;
    if (
      selectedData ===
      getLocalizedText('매주 월요일 화요일 수요일 목요일 금요일')
    )
      selectedData = '주중 매일(월-금)';
    let repeatState = 'add';
    const selectedRepeat = initialSelected.find(
      (a) => a.label === selectedData,
    );
    if (selectedRepeat) {
      repeatState = selectedRepeat.value;
      setRepeatSelected(initialSelected);
    } else {
      repeatState = 'add';
      initialSelected.splice(6, 0, { value: 'add', label: `${selectedData}` });
      setRepeatSelected(initialSelected);
    }
    setState((prev) => ({
      ...prev,
      isRepeatPopup: false,
      repeatState,
      repeat,
      summary,
    }));
  };

  /** 반복설정 대화상자 닫기 */
  const handleRepeatClose = () => {
    setState((prev) => ({ ...prev, isRepeatPopup: false }));
  };

  /** 알림 타입 변경. */
  const handleChangeAlarmType = (value: string, id: number) => {
    const alarms = state.alarms.map((a) => {
      if (a.id === id)
        return {
          ...a,
          type: value,
        };
      return a;
    });
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 알림 추가. */
  const handleAlarmClick = () => {
    setState((prev) => ({
      ...prev,
      alarms: [
        ...prev.alarms,
        {
          id: timezoneDate().getTime(),
          type: 'NONE',
          ammount: 0,
          timeUnit: 'MINUTE',
        },
      ],
    }));
  };

  /** 알림 시간 변경 */
  const handleChangeAlarmTime = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
  ) => {
    const timeAmmount = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    const alarms = state.alarms.map((a) => {
      if (a.id === id)
        return {
          ...a,
          ammount: Number(timeAmmount),
        };
      return a;
    });
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 알림 시간 타입 변경. */
  const handleChangeAlarmTimeType = (value: string, id: number) => {
    const alarms = state.alarms.map((a) => {
      if (a.id === id)
        return {
          ...a,
          timeUnit: value,
        };
      return a;
    });
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 알림 삭제 */
  const handleAlarmDelete = (id: number) => {
    const alarms = state.alarms.filter((a) => a.id !== id);
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 참석자 조직도 오픈. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { participantMenuPoint } = state;
    if (event !== undefined && participantMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        participantMenuPoint: { x, y, width, height },
      }));
    } else setState((prev) => ({ ...prev, participantMenuPoint: undefined }));
  };

  /** 참석자 삭제. */
  const handleParticipantDelete = (id: number) => {
    setState((prev) => ({
      ...prev,
      participants: prev.participants.filter((a) => a.participantId !== id),
    }));
  };

  /** 참석자 추가. */
  const handleParticipantAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.participants.some((a) => a.participantId === employeeId))
          return { ...prev, participantMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, participantMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          participants: [
            ...prev.participants,
            {
              participantCompanyId: companyId,
              participantId: employeeId,
            },
          ],
        };
      });
    }
  };

  /** 날짜 변경. */
  const handleChangeDate = (date: Date | null, type: string) => {
    if (date === null) return;
    date.setSeconds(0);
    if (type === 'start') {
      let { end } = state;
      const { repeat, repeatState, summary } = state;
      if (
        repeat &&
        repeat.endDay !== '9999-12-31' &&
        new Date(repeat.endDay) < date
      ) {
        setValidation(
          getLocalizedText('반복 일정의 종료일이 일정 시작일보다 빠릅니다.'),
        );
        return;
      }
      if (end <= date) {
        end = new Date(date);
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
        end.setSeconds(0);
      }
      const now = summary
        ? `${summary.one}${summary.two}${summary.three}`
        : getLocalizedText('반복 안함');
      const makeRepeat = makeRepeatList(date);
      if (makeRepeat.some((a) => a.label === now)) {
        setRepeatSelected(makeRepeat);
        setState((prev) => ({ ...prev, start: date, end }));
      } else {
        const addRepeat = repeatSelected.find((a) => a.value === repeatState);
        setRepeatSelected(
          addRepeat
            ? [
                ...makeRepeat.filter((a) => a.value !== 'etc'),
                addRepeat,
                ...makeRepeat.filter((a) => a.value === 'etc'),
              ]
            : makeRepeat,
        );
        setState((prev) => ({ ...prev, start: date, end, repeatState: 'add' }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        start: date < prev.start ? date : prev.start,
        end: date,
      }));
    }
  };

  /** 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const { start, end } = state;
    const newTime = Number(value.split(':')[0]);
    const newMinute = Number(value.split(':')[1]);
    if (type === 'start') {
      const date = new Date(start);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (end <= date) {
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      const date = new Date(end);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (date <= start) {
        start.setHours(date.getHours());
        start.setMinutes(date.getMinutes() - 30);
      }
      setState((prev) => ({ ...prev, start, end: date }));
    }
  };

  /** 종일 상태 변경 여부. */
  const handleChangeIsAllDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      isAllDay: event.target.checked,
    }));
  };

  /** 자원 예약 사용 여부 변경. */
  const handleChangeUseResource = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      useResource: event.target.checked,
      resourceItemId: undefined,
    }));
  };

  /** 자원 선택 대화상자 닫기 이벤트. */
  const handleResourceSelectClose = () => {
    setState((prev) => ({
      ...prev,
      resourceSelect: false,
    }));
  };

  /** 자원 선택 대화상자 확인 이벤트. */
  const handleResourceSelectConfirm = (arg: {
    start: Date;
    end: Date;
    checkResource: number;
  }) => {
    setState((prev) => ({
      ...prev,
      start: arg.start,
      end: arg.end,
      resourceItemId: arg.checkResource,
      resourceSelect: false,
    }));
  };

  /** 선택된 자원 삭제 이벤트. */
  const handleDeleteResourceId = () => {
    setState((prev) => ({
      ...prev,
      resourceItemId: undefined,
    }));
  };

  /** 작성자 정보 */
  const writer = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: view?.employeeId ?? principal.employeeId,
  });

  const renderDialog = () => {
    if (state.isRepeatPopup) {
      return (
        <DetailRepeatDialog
          start={state.start}
          repeat={state.repeat}
          summary={state.summary}
          onSave={handleRepeatSave}
          onClose={handleRepeatClose}
        />
      );
    }
    if (state.resourceSelect) {
      return (
        <CalendarResourceSelect
          resourcesItems={state.resourcesItems}
          onResourceSelectConfirm={handleResourceSelectConfirm}
          onClose={handleResourceSelectClose}
          resourceItemId={state.resourceItemId}
          start={state.start}
          end={state.end}
        />
      );
    }
    return null;
  };

  const startTime = state.start.getHours().toString().padStart(2, '0');
  const startMinute = state.start.getMinutes().toString().padStart(2, '0');
  const endTime = state.end.getHours().toString().padStart(2, '0');
  const endMinute = state.end.getMinutes().toString().padStart(2, '0');
  const resourceStatus = formatReservationStatus(view?.resource?.status);
  const resourceStatusTheme = formatReservationTheme(view?.resource?.status);
  return (
    <>
      {state.loading && <Loading />}
      <PostWrite name="calendar" fullSize>
        <PostWrite.Toolbar onCancel={handleCancel}>
          <Button
            noDuplication={validation === ''}
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={view ? handleUpdate : handleSave}
          />
        </PostWrite.Toolbar>
        <PostWrite.Item required title={getLocalizedText('캘린더명')}>
          <DropMenu
            value={state.calendarName}
            onClick={
              isSharedSchedule
                ? () =>
                    setValidation(
                      getLocalizedText(
                        '공유받은 일정 수정 시 캘린더 수정은 불가능 합니다.',
                      ),
                    )
                : handleClick
            }
            label={getLocalizedText('캘린더 선택')}
          />
          {state.calendarMenuPoint && (
            <Menu
              point={state.calendarMenuPoint}
              onClose={() =>
                setState((prev) => ({ ...prev, calendarMenuPoint: undefined }))
              }
            >
              <div className="ui-organization-select">
                <div className="body-panel">
                  <Tree
                    selectedId={state.calendarId}
                    items={treeData}
                    onItemClick={handleItemClick}
                  />
                </div>
              </div>
            </Menu>
          )}
        </PostWrite.Item>
        <PostWrite.Item required title={getLocalizedText('제목')}>
          <TextField
            disabled={isSharedSchedule}
            value={state.title}
            onChange={handleChangeTitle}
          />
        </PostWrite.Item>
        <PostWrite.Item title={getLocalizedText('내용')}>
          <TextField
            disabled={isSharedSchedule}
            multiline
            rows={2}
            value={state.description}
            onChange={handleChangeDescription}
          />
        </PostWrite.Item>
        <PostWrite.Item title={getLocalizedText('작성자')}>
          {`${writer.organizationName}/${writer.employeeName}`}
        </PostWrite.Item>
        <PostWrite.Item required title={getLocalizedText('일정기간')}>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: display === 'phone' ? 'column' : undefined,
            }}
          >
            {display === 'phone' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '5px',
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    시작
                  </span>
                  <CustomDatePicker
                    disabled={isSharedSchedule}
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.start}
                    startDate={state.start}
                    endDate={state.end}
                    selectsStart
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'start')
                    }
                  />
                  {!state.isAllDay && (
                    <SelectField
                      disabled={isSharedSchedule}
                      data={time}
                      value={`${startTime}:${startMinute}`}
                      onChange={(value) => handleChangeTime(value, 'start')}
                    />
                  )}
                </div>
                <div
                  style={
                    display === 'phone'
                      ? {
                          display: 'flex',
                          flexDirection: 'row',
                        }
                      : undefined
                  }
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    종료
                  </span>
                  <CustomDatePicker
                    disabled={isSharedSchedule}
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.end}
                    startDate={state.start}
                    endDate={state.end}
                    minDate={state.start}
                    selectsEnd
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'end')
                    }
                  />
                  {!state.isAllDay && (
                    <SelectField
                      disabled={isSharedSchedule}
                      data={time}
                      value={`${endTime}:${endMinute}`}
                      onChange={(value) => handleChangeTime(value, 'end')}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <CustomDatePicker
                  disabled={isSharedSchedule}
                  width={110}
                  dateFormat="yyyy-MM-dd"
                  selected={state.start}
                  startDate={state.start}
                  endDate={state.end}
                  selectsStart
                  onChange={(date: Date | null) =>
                    handleChangeDate(date, 'start')
                  }
                />
                {!state.isAllDay && (
                  <SelectField
                    disabled={isSharedSchedule}
                    data={time}
                    value={`${startTime}:${startMinute}`}
                    onChange={(value) => handleChangeTime(value, 'start')}
                  />
                )}
                <span style={{ margin: '2px 10px' }}> ~ </span>
                <CustomDatePicker
                  disabled={isSharedSchedule}
                  width={110}
                  dateFormat="yyyy-MM-dd"
                  selected={state.end}
                  startDate={state.start}
                  endDate={state.end}
                  minDate={state.start}
                  selectsEnd
                  onChange={(date: Date | null) =>
                    handleChangeDate(date, 'end')
                  }
                />
                {!state.isAllDay && (
                  <SelectField
                    disabled={isSharedSchedule}
                    data={time}
                    value={`${endTime}:${endMinute}`}
                    onChange={(value) => handleChangeTime(value, 'end')}
                  />
                )}
              </>
            )}
            <Checkbox
              disabled={state.useResource || isSharedSchedule}
              style={{ marginLeft: display !== 'phone' ? '8px' : undefined }}
              label={getLocalizedText('종일')}
              checked={state.isAllDay}
              onChange={handleChangeIsAllDay}
            />
          </div>
        </PostWrite.Item>
        <PostWrite.Item title={getLocalizedText('자원예약')}>
          <Checkbox
            disabled={state.isAllDay || isSharedSchedule}
            label={getLocalizedText('예약사용')}
            checked={state.useResource}
            onChange={handleChangeUseResource}
          />
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '5px',
              color: 'var(--secondary-text-color)',
            }}
          >
            * 종일 일정 사용 또는 공유받은 일정 수정 시에는 자원예약을 할 수
            없습니다.
          </span>
        </PostWrite.Item>
        {state.useResource && (
          <PostWrite.Item title={getLocalizedText('자원선택')}>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Button
                disabled={isSharedSchedule}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    resourceSelect: true,
                  }))
                }
                iconType
                icon="plus"
                variant="contained"
                size="xs"
                style={{ marginRight: '8px' }}
              />
              {state.resourceItemId && resource && (
                <div className="eui-chip" style={{ height: 'auto' }}>
                  <div className="eui-avatar avatar icon" title={resource.name}>
                    {resource.useApprove ? (
                      <i className="eui-icon eui-icon-document-check" />
                    ) : (
                      <i className="eui-icon eui-icon-document-clock" />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      margin: '4px',
                    }}
                  >
                    <span className="text" style={{ marginBottom: '3px' }}>
                      <span className="label">
                        <span>
                          {resource.name}
                          {view?.resource?.resourceItemId ===
                            state.resourceItemId && (
                            <Chip
                              style={{ marginLeft: '5px' }}
                              size="xs"
                              theme={resourceStatusTheme}
                              label={resourceStatus ?? ''}
                            />
                          )}
                        </span>
                      </span>
                    </span>
                    <span
                      className="etc"
                      style={{ marginLeft: 'initial', width: 'fit-content' }}
                    >
                      {getLocalizedText('예약가능시간')}{' '}
                      {resource.useTimeAvailable
                        ? `${resource.availableFromTime} ~ ${resource.availableToTime}`
                        : getLocalizedText('종일')}
                    </span>
                  </div>
                  {!isSharedSchedule && (
                    <button
                      type="button"
                      className="delete"
                      aria-label={getLocalizedText('삭제')}
                      onClick={handleDeleteResourceId}
                    />
                  )}
                </div>
              )}
            </div>
            <HelperText
              text={getLocalizedText(
                '* 자원예약 시에는 반복일정을 등록할 수 없습니다.',
              )}
            />
          </PostWrite.Item>
        )}
        {!state.useResource && (
          <PostWrite.Item title={getLocalizedText('반복일정')}>
            <SelectField
              disabled={updateData?.repeatType === 'piece' || isSharedSchedule}
              data={repeatSelected}
              value={state.repeatState}
              onChange={handleChangeRepeatUse}
            />
            {updateData?.repeatType === 'piece' && (
              // eslint-disable-next-line prettier/prettier
              <div style={{ display: 'inline-flex', margin: '5px 10px', color: 'var(--secondary-text-color)' }}>
                * 이 일정만 수정할 경우, 반복을 설정 할 수 없습니다.
              </div>
            )}
          </PostWrite.Item>
        )}
        <PostWrite.Item title={getLocalizedText('알림')}>
          {state.alarms.length > 0 && (
            <div style={{ marginBottom: '5px' }}>
              {state.alarms.map((a) => (
                <div key={a.id} style={{ marginTop: '5px' }}>
                  <SelectField
                    data={alarmTypeData}
                    value={a.type}
                    onChange={(value) => handleChangeAlarmType(value, a.id)}
                  />
                  <span style={{ margin: '2.5px' }} />
                  <TextField
                    width={80}
                    value={a.ammount.toString()}
                    onChange={(event) => handleChangeAlarmTime(event, a.id)}
                  />
                  <span style={{ margin: '2.5px' }} />
                  <SelectField
                    data={timeTypeData}
                    value={a.timeUnit}
                    onChange={(value) => handleChangeAlarmTimeType(value, a.id)}
                  />
                  <Button
                    text={getLocalizedText('삭제')}
                    iconType
                    icon="trash-fill"
                    size="sm"
                    onClick={() => handleAlarmDelete(a.id)}
                  />
                </div>
              ))}
            </div>
          )}
          <Button
            text={getLocalizedText('+ 알림추가')}
            onClick={handleAlarmClick}
          />
        </PostWrite.Item>
        <PostWrite.Item title={getLocalizedText('참석자')}>
          <ChipGroup
            add={isSharedSchedule ? undefined : getLocalizedText('추가')}
            onAdd={isSharedSchedule ? undefined : handleDirectoryTreeMenuToggle}
          >
            {state.participants.map(
              ({ participantCompanyId, participantId }) => {
                const directoryData = getDirectoryData({
                  ...directory,
                  companyId: participantCompanyId,
                  employeeId: participantId,
                });
                return (
                  <Chip
                    key={`${participantCompanyId}/${participantId}`}
                    label={directoryData.employeeName}
                    etc={directoryData.organizationName}
                    avatar={directoryData.avatar}
                    onDelete={
                      isSharedSchedule
                        ? undefined
                        : () => handleParticipantDelete(participantId)
                    }
                  />
                );
              },
            )}
            {state.participantMenuPoint && (
              <DirectoryMenuTreeContainer
                deduplication
                point={state.participantMenuPoint}
                typeToFilter={['employee']}
                ignoreId={`${principal.companyId}_${principal.organizationId}_${principal.employeeId}`}
                onItemClick={handleParticipantAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </PostWrite.Item>
      </PostWrite>
      {renderDialog()}
      <FeedBack text={validation} onClose={() => setValidation('')} />
      <NavigationGuard
        pathname={pathname}
        search={search}
        hash={hash}
        reload={reload}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (reload) {
            return true;
          }
          return false;
        }}
      />
    </>
  );
}

export default CalendarComposeContainer;
