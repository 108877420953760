import { useEffect } from 'react';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { authenticationActions } from '../../../store/login';

function AuthenticationLoginRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    dispatch(authenticationActions.loginSetting()).then(() => {
      if (mount) dispatch(sessionActions.setRoute({ pathname, search }));
    });
    return () => {
      mount = false;
    };
  }, [pathname, search]);
  return null;
}

export default AuthenticationLoginRoute;
