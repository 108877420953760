import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  hangul,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  calendarsActions,
  SaveCalendarData,
} from '../../../../stores/calendar/calendars';
import CalendarFolderBoxList from '../../../common/CalendarFolderBoxList';
import CalendarFolderBoxView from '../../../common/CalendarFolderBoxView';
import CalendarFolderBoxEditDrawer from './CalendarUserPreferencesCalEditDrawer';

function CalendarUserPreferencesMyCal(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();
  const pathmap = getPathMap('/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/:selectedId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const list = useSelector(
    (state: RootState) => state.calendar.calendars.user.list,
  );
  const view = useSelector((state: RootState) => state.calendar.calendars.view);
  const items = useMemo(() => {
    const folders = list.map((x) => {
      return {
        id: x.id,
        parentId: 0,
        seq: x.seq,
        status: x.status,
        isInitial: x.isInitialAtUserStarted,
        text: x.name,
        icon: 'folder-fill' as const,
        color: x.color,
        strings: hangul.d(x.name),
      };
    });
    return folders;
  }, [list]);

  /** 모바일에만 적용. */
  const handleCloseView = () => {
    const route = { pathname: pathmap, search, hash };
    dispatch(sessionActions.setRoute(route));
  };

  const handleCRUD = (mode: 'create' | 'read' | 'update' | 'delete') => {
    switch (mode) {
      case 'create':
      case 'update':
        dispatch(sessionActions.setDrawer({ mode }));
        break;
      case 'delete':
        dispatch(sessionActions.setDialog({ mode }));
        break;
      default:
        dispatch(sessionActions.search());
        break;
    }
  };

  /** 캘린더 클릭 이벤트 */
  const handleItemClick = (id: number) => {
    go(`${pathmap}/${b62(id)}`, search, hash);
  };

  /** 캘린더 저장(수정) 이벤트. */
  const handleSave = (data: SaveCalendarData) => {
    const route = { pathname, search: '', hash };
    if (data.id !== undefined && data.updateAt !== undefined)
      dispatch(
        calendarsActions.updateMyCalendar({
          type: 'mine',
          data,
          route,
        }),
      );
    else dispatch(calendarsActions.saveMyCalendar({ data, route }));
  };

  /** 캘린더 삭제 이벤트. */
  const handleDeleteCalendar = () => {
    if (!view) return;
    dispatch(
      calendarsActions.deleteMyCal({
        id: view.id,
        updateAt: view.updateAt,
        route: { pathname: pathmap, search: '', hash },
      }),
    );
  };

  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId
          ? getLocalizedText('폴더를 찾을 수 없습니다.')
          : undefined;
      return <SplitUnselected label={message} />;
    }
    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <CalendarFolderBoxView
          paths={getParentItems(items, view.id).map(({ text }) => text)}
          view={view}
          onUpdate={() => handleCRUD('update')}
          onDelete={() => handleCRUD('delete')}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerMode: mode } = queryParams;
    if (mode === 'create')
      return (
        <CalendarFolderBoxEditDrawer
          type="myCal"
          onSave={handleSave}
          onClose={() => handleCRUD('read')}
        />
      );
    if (mode === 'update' && view)
      return (
        <CalendarFolderBoxEditDrawer
          type="myCal"
          onSave={handleSave}
          view={view}
          onClose={() => handleCRUD('read')}
        />
      );
    return null;
  };

  const renderDialog = () => {
    const { dialogMode: mode } = queryParams;

    let result = null;
    if (mode === 'delete' && view)
      result = (
        <DeleteConfirmation
          onSubmit={handleDeleteCalendar}
          onCancel={() => handleCRUD('read')}
        >
          <strong>&apos;{view.name}&apos;</strong>{' '}
          {getLocalizedText('캘린더를 정말 삭제하시겠습니까?')}
        </DeleteConfirmation>
      );

    return result;
  };

  return (
    <>
      <EuiSetting>
        <EuiSetting.Left>
          <CalendarFolderBoxList
            calendarId={selectedId}
            items={items}
            onCreate={() => handleCRUD('create')}
            onItemClick={handleItemClick}
          />
        </EuiSetting.Left>
        {renderView()}
      </EuiSetting>
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default CalendarUserPreferencesMyCal;
