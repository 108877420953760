import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import DashBoardBasicContainer from '../pages/adminconsole/dashboard/basic/DashBoardBasicContainer';
import DashBoardComposeContainer from '../pages/root/dashboard/compose/DashBoardComposeContainer';

function AdminconsoleLocateContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element | null {
  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', props.pathname);

  // 회사 포탈
  if (menu === 'company') {
    return <DashBoardComposeContainer pathname={pathname} search={search} />;
  }
  // 기본 설정
  return <DashBoardBasicContainer pathname={pathname} search={search} />;
}

export default AdminconsoleLocateContainer;
