import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostView from '../../../../../components/post/PostView';
import { LinkMenuItem, LinkMenuDetail } from '../../../../stores/module';
import Icon from '../../../../../components/icon/Icon';
import { RootState } from '../../../../../groupware-webapp/app/store';
import LinkMenu from '../../../../../components/linkmenu/LinkMenu';

export interface UpdateMenuArg {
  id: number;
  seq: number;
  name: string;
  url: string;
  imageFile: string | undefined;
  updateAt: string;
  status: boolean;
}

function ServiceLinkMenuView(props: {
  view: LinkMenuDetail;
  onUpdate(): void;
  onDelete(): void;
}): JSX.Element {
  const { view, onUpdate, onDelete } = props;
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  return (
    <>
      <EuiSetting.Header title="링크메뉴 정보">
        <>
          <Button text="수정" iconType icon="edit" onClick={onUpdate} />
          <Button text="삭제" iconType icon="trash-full" onClick={onDelete} />
        </>
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <div
                  className="link-file-wrapper"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    style={{
                      border: '1px solid var(--line-color)',
                      color: 'var(--secondary-text-color)',
                      display: 'flex',
                      padding: '8px',
                      justifyContent: 'center',
                      alignContent: 'center',
                      width: '60px',
                      height: '60px',
                      marginRight: '6px',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                  >
                    <LinkMenu imagePath={view.imagePath} />
                  </div>
                </div>
                <PostView.CategoryItem title="메뉴명">
                  <PostView.CategoryValue value={view.name} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="URL">
                  <PostView.CategoryValue value={view.url} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="사용여부">
                  <PostView.CategoryValue
                    value={view.status ? '사용함' : '사용안함'}
                  />
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default ServiceLinkMenuView;
