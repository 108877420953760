import React from 'react';
import Button from '../../../../../components/button/Button';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function AttendanceContentHeadView(
  props: (
    | {
        type: 'full';
        onListGo?(): void;
      }
    | {
        type: 'split';
        onListGo?: undefined;
      }
  ) & {
    toolbarButtons: ActionEventProps[];
    onToolbarAction(arg: { code: string; event: React.MouseEvent }): void;
  },
): JSX.Element {
  const { type, onListGo, toolbarButtons, onToolbarAction } = props;

  return (
    <EuiToolbar className={`view-toolbar ${type}`}>
      <EuiToolbar.Left>
        {onListGo && (
          <Button
            text={getLocalizedText('목록으로 돌아가기')}
            iconType
            icon="arrow-left"
            onClick={onListGo}
            className="back-list"
          />
        )}
        <ToolbarAction event={toolbarButtons} onClick={onToolbarAction} />
      </EuiToolbar.Left>
    </EuiToolbar>
  );
}

export default AttendanceContentHeadView;
