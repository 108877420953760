/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../groupware-common/apis/common/v1';
import { jwtDecode } from '../../../stores/session';

/**
 * 리소스 마지막 수정 시간 조회
 */
async function findLastupdateAt(arg: {
  language: string;
  namespace: string;
}): Promise<{ lastUpdateAt: string }> {
  try {
    const { language, namespace } = arg;
    const { host, headers } = getApiConfig();
    let url = `${host}/api/locales/${language}/${namespace}/lastupdateat`;
    const jwt = jwtDecode(headers.Authorization.split(' ')[1] ?? '');
    if (jwt !== undefined) {
      const companyId = jwt.payload.cid;
      const employeeId = jwt.payload.eid;
      url = `${host}/apis/i18n/v1/companies/${companyId}/employees/${employeeId}/locales/${language}/${namespace}/lastUpdateAt`;
    }
    const response = await axios.get<{ lastUpdateAt: string }>(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const i18nApi = {
  findLastupdateAt,
};

export default i18nApi;
