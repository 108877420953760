import React, { useState } from 'react';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import Drawer from '../../../../../components/drawer/Drawer';
import DropMenu from '../../../../../components/selectField/DropMenu';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';

function ApprovalApproverMacroEditDrawer(
  props: (
    | { id?: undefined }
    | {
        id: number;
        name: string;
        organizationId: number;
        employeeId: number;
        updateAt: string;
        approverName: string;
        selectedTreeId: string;
      }
  ) & {
    onSave(
      arg:
        | {
            id?: undefined;
            name: string;
            organizationId: number;
            employeeId: number;
          }
        | {
            id: number;
            name: string;
            organizationId: number;
            employeeId: number;
            updateAt: string;
          },
    ): void;
    onClose(): void;
  },
): JSX.Element {
  // console.log(`${ApprovalApproverMacroEditDrawer.name}.render(props)`, props);

  const [state, setState] = useState(() => {
    let userSelectMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;

    // 등록인 경우.
    if (props.id === undefined)
      return {
        id: props.id,
        name: '',
        organizationId: 0,
        employeeId: 0,
        updateAt: '',
        approverName: '',

        userSelectMenuSelectedId: '',
        userSelectMenuPoint,
      };

    return {
      id: props.id,
      name: props.name,
      organizationId: props.organizationId,
      employeeId: props.employeeId,
      updateAt: props.updateAt,
      approverName: props.approverName,

      userSelectMenuSelectedId: props.selectedTreeId,
      userSelectMenuPoint,
    };
  });

  /** 사용자 선택 메뉴 토글. */
  const handleUserSelectMenuToggle = (event?: React.MouseEvent) => {
    const { userSelectMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        userSelectMenuPoint: undefined,
      }));
    }
  };

  /** 사용자 선택. */
  const handleUserSelected = (arg: DirectoryTreeItemArg) => {
    // console.log(`handleUserSelected(arg)`, arg);
    const { item } = arg;
    const { extra } = item;
    if (extra.type === 'employee') {
      const employeeName =
        extra.jobClassName === ''
          ? extra.employeeName
          : `${extra.employeeName} ${extra.jobClassName}`;

      setState((prevState) => ({
        ...prevState,
        approverName: `${extra.organizationName} / ${employeeName}`,
        organizationId: extra.organizationId,
        employeeId: extra.employeeId,

        userSelectMenuSelectedId: item.id,
        userSelectMenuPoint: undefined,
      }));
    }
  };

  /** 담당자 변경. */
  const handlePersonInChargeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({ ...prevState, name: event.target.value }));
  };

  /** 저장. */
  const handleSave = () => {
    const { id, name, organizationId, employeeId, updateAt } = state;

    // 등록인 경우.
    if (id === undefined) {
      props.onSave({
        name,
        organizationId,
        employeeId,
      });
    } else {
      props.onSave({
        id,
        name,
        organizationId,
        employeeId,
        updateAt,
      });
    }
  };

  const { id, onClose } = props;
  const { approverName, name, userSelectMenuPoint, userSelectMenuSelectedId } =
    state;

  const title =
    id === undefined
      ? getLocalizedText('결재 담당자 등록')
      : getLocalizedText('결재 담당자 수정');

  return (
    <Drawer title={getLocalizedText(`${title}`)} onClose={onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('결재자')}>
            <DropMenu
              value={approverName}
              label={getLocalizedText('결재자 선택')}
              pressed={userSelectMenuPoint !== undefined}
              onClick={handleUserSelectMenuToggle}
            />
            {userSelectMenuPoint && (
              <DirectoryMenuTreeContainer
                point={userSelectMenuPoint}
                selectedId={userSelectMenuSelectedId}
                typeToFilter="employee"
                onItemClick={handleUserSelected}
                onClose={handleUserSelectMenuToggle}
              />
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('담당자명')}>
            <TextField value={name} onChange={handlePersonInChargeChange} />
          </PostWrite.Item>
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default ApprovalApproverMacroEditDrawer;
