/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import Icon from '../../../../../components/icon/Icon';
import { PortalTemplateDetail } from '../../../../stores/templates';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

export interface DashBoardListItem {
  id: number;
  value: string;
  index: number;
}
function DashBoardDraggableItem(props: {
  item: PortalTemplateDetail;
  // TODO 사용하지 않을 경우 삭제
  onSelect?(id: number): void;
}): JSX.Element {
  const { item, onSelect } = props;
  const handleDivStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'end',
  };

  return (
    <div className="dashboard-item">
      <div className="item-head">
        <div className="head-left">
          <div className="title">{getLocalizedText(item.title)}</div>
        </div>
        <div className="head-right">
          <div
            role="handle"
            className="drag"
            style={handleDivStyle}
            onClick={onSelect ? () => onSelect(item.id) : undefined}
          >
            <Icon icon="drag" theme="primary" />
          </div>
        </div>
      </div>
      <div className="dashboard-body draggable">
        <div className="item-draggable">
          <Icon icon={item.icon} />
        </div>
      </div>
    </div>
  );
}

export default DashBoardDraggableItem;
