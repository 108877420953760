import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import Chip from '../../../../../components/chip/Chip';
import PostWrite from '../../../../../components/post/PostWrite';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import {
  getEmployeeName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import FeedBack from '../../../../../components/alert/FeedBack';
import { approvalArbitraryDecisionActions } from '../../../../stores/approval/arbitraryDecisions';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

interface ListType {
  companyId: number;
  employeeId: number;
  id: string;
  name: string;
  organization: string;
  avatar: string;
  updateAt?: string;
}

function ApprovalArbitraryDecisionContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const items = useSelector(
    (state: RootState) => state.approval2.arbitraryDecisions.arbitraryDecisions,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const directory = useDirectory();
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6003)?.name;

  const itemsData = items
    .filter((a) => {
      if (!employees.some((x) => x.id === a.employeeId)) return false;
      return true;
    })
    .map((a) => {
      const employeeItem = getDirectoryData({
        ...directory,
        companyId: a.companyId,
        organizationId: a.organizationId,
        employeeId: a.employeeId,
      });
      let name = '';
      let organization = '';
      let id = '';
      let avatar = '';
      let updateAt = '';
      if (employeeItem !== undefined) {
        name = employeeItem.employeeName;
        organization = employeeItem.organizationName;
        if (a.companyId === a.organizationId)
          organization = employeeItem.companyName;
        id = `${a.companyId}_${a.organizationId}_${a.employeeId}`;
        avatar = employeeItem.avatar || '';
        updateAt = a.updateAt ?? '';
      }

      return {
        ...a,
        id,
        name,
        organization,
        avatar,
        updateAt,
      };
    });
  const deleteData = items
    .filter((a) => !employees.some((x) => x.id === a.employeeId))
    .map((a) => ({
      id: `${a.companyId}_${a.organizationId}_${a.employeeId}`,
      updateAt: a.updateAt,
    }));

  const [state, setState] = useState<{
    list: ListType[];
    modelist: { id: string; updateAt: string }[];
    userSelectVisible: boolean;
    userSelectPoint: { x: number; y: number; width: number; height: number };
    alertMessage: string;
    modecheck: boolean;
  }>({
    list: itemsData,
    modelist: deleteData,
    userSelectVisible: false,
    userSelectPoint: { x: 0, y: 0, width: 0, height: 0 },
    alertMessage: '',
    modecheck: false,
  });

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      alertMessage: '',
    }));
  };

  const handleOpenUserSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.userSelectVisible) handleCloseUserSelect();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        userSelectVisible: true,
      }));
    }
  };

  const handleCloseUserSelect = () => {
    setState((prevState) => ({
      ...prevState,
      userSelectVisible: false,
    }));
  };

  const handleUserSelected = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const id = `${extra.companyId}_${extra.organizationId}_${extra.employeeId}`;
      if (state.list.find((x) => x.id === id)) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
        }));
        return;
      }
      const list = {
        companyId: extra.companyId,
        employeeId: extra.employeeId,
        id,
        name: getEmployeeName(extra.companyId, extra.employeeId),
        organization: extra.organizationName,
        avatar: extra.avatar,
      };

      setState((prevState) => ({
        ...prevState,
        list: [...prevState.list, list],
        modecheck: true,
      }));

      handleCloseUserSelect();
    }
  };

  const handleDeleteUser = (id: string) => {
    state.list.forEach((a) => {
      const updateAt = a.updateAt ?? '';
      if (a.id === id && updateAt.length > 1) {
        state.modelist.push({
          id,
          updateAt,
        });
      }
    });

    setState((prevState) => ({
      ...prevState,
      list: state.list.filter((x) => x.id !== id),
      modelist: state.modelist,
      modecheck: true,
    }));
  };

  const handleSave = async () => {
    // 기존 목록에서 삭제한 직원 리스트
    const delret = state.modelist
      .filter(
        (k) =>
          k.id !== undefined && (k.updateAt !== undefined || k.updateAt !== ''),
      )
      .map((x) => {
        const { updateAt, id } = x;
        const arbitraryDecisionId = id.split('_').map((a) => parseInt(a, 10));
        const organizationId = arbitraryDecisionId[1];
        const employeeId = arbitraryDecisionId[2];
        return {
          organizationId,
          employeeId,
          updateAt,
        };
      });
    if (delret[0] !== undefined) {
      await dispatch(
        approvalArbitraryDecisionActions.modefetchArbitraryDecision(delret),
      );
    }

    // 새로 추가한 직원 리스트
    const saveret = state.list
      .filter(
        (x) =>
          (x.updateAt === undefined || x.updateAt === '') && x.id !== undefined,
      )
      .map((z) => {
        const arbitraryDecisionId = z.id.split('_').map((a) => parseInt(a, 10));
        const organizationId = arbitraryDecisionId[1];
        const employeeId = arbitraryDecisionId[2];
        return {
          organizationId,
          employeeId,
          updateAt: '',
        };
      });
    if (saveret[0] !== undefined) {
      dispatch(
        approvalArbitraryDecisionActions.modefetchArbitraryDecision(saveret),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined) {
          const payload = result.payload as {
            companyId: number;
            employeeId: number;
            organizationId: number;
            updateAt: string;
          }[];
          const datas = payload.map((a) => {
            const id = `${a.companyId}_${a.organizationId}_${a.employeeId}`;
            const data = getDirectoryData({
              ...directory,
              companyId: a.companyId,
              organizationId: a.organizationId,
              employeeId: a.employeeId,
            });

            let organization = data.organizationName;
            if (a.companyId === a.organizationId)
              organization = data.companyName;

            return {
              ...a,
              id,
              name: data.employeeName,
              organization,
              avatar: data.avatar || '',
            };
          });
          setState((prevState) => ({
            ...prevState,
            list: datas,
          }));
        }
      });
    }
    setState((prevState) => ({
      ...prevState,
      modecheck: false,
      modelist: [],
    }));
  };

  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      modecheck: false,
      list: itemsData,
      modelist: [],
    }));
  };
  const { list, userSelectVisible, userSelectPoint, alertMessage, modecheck } =
    state;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <PostWrite>
          <PostWrite.Item>
            <ChipGroup
              add={getLocalizedText('추가')}
              onAdd={handleOpenUserSelect}
            >
              {list.map((x) => (
                <Chip
                  key={x.id}
                  label={x.name}
                  etc={x.organization}
                  avatar={x.avatar}
                  onDelete={() => handleDeleteUser(x.id)}
                />
              ))}
              {userSelectVisible && (
                <DirectoryMenuTreeContainer
                  point={userSelectPoint}
                  typeToFilter="employee"
                  onItemClick={handleUserSelected}
                  onClose={handleCloseUserSelect}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
        <EuiToolbar>
          {modecheck && (
            <EuiToolbar.Left>
              <Button
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleSave}
              />
              <Button text={getLocalizedText('취소')} onClick={handleCancel} />
            </EuiToolbar.Left>
          )}
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalArbitraryDecisionContainer;
