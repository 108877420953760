import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { hangul } from '../../../groupware-common/utils';
import Tree from '../../../components/tree/Tree';
import SimpleSearch from '../../../components/search/SimpleSearch';
import { getDirectoryTreeItems } from '../../../components/tree/DirectoryTree';
import MenuItem from '../../../components/menu/MenuItem';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

function ChooseOrganizationChart(props: {
  onSelected(arg: {
    type: 'employee' | 'organization' | 'company';
    item: {
      id: string;
      name: string;
      organization: string;
      avatar: string;
    };
    companyId?: number;
    organizationId?: number;
    employeeId?: number;
    jobPositionId?: number;
    jobDutyId?: number;
  }): void;
}): JSX.Element {
  // console.log(`${ChooseOrganizationChart.name}.render`);

  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );

  const treeItems = useMemo(() => {
    const result = getDirectoryTreeItems({
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    });
    return result;
  }, [
    companies,
    organizations,
    organizationEmployees,
    employees,
    jobClassType,
    jobPositions,
    jobDuties,
  ]);

  const [state, setState] = useState<{
    selectedId: number;
    selectedTreeNodeId: string;
    searchFilter: string;
  }>({
    selectedId: 0,
    selectedTreeNodeId: '5001',
    searchFilter: '',
  });

  const handleTreeNodeClick = (id: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedTreeNodeId: id,
    }));

    const item = treeItems.find((x) => x.id === id);

    let companyId: number | undefined;
    let organizationId: number | undefined;
    let employeeId: number | undefined;

    if (item) {
      const [parentId, childId] = id.split('_').map((x) => parseInt(x, 10));

      if (childId !== undefined) {
        employeeId = childId;
      }
      if (companies.find((x) => x.id === parentId)) companyId = parentId;
      else {
        companyId = companies[0].id;
        organizationId = parentId;
      }
    }

    // console.log('item : ', item);

    if (item !== undefined && item.extra.type === 'employee') {
      const parent = treeItems.find((x) => x.id === item.parentId);
      // const newId = item.id.split('_')[1];
      props.onSelected({
        type: item.extra.type,
        item: {
          id: item.id,
          name: item.text,
          organization: parent?.text || '',
          avatar: item.avatar || '',
        },
        companyId,
        organizationId,
        employeeId,
      });
    }

    if (item !== undefined && item.extra.type === 'organization') {
      props.onSelected({
        type: item.extra.type,
        item: {
          id: item.id,
          name: item.text,
          organization: '',
          avatar: '',
        },
        companyId,
        organizationId,
        employeeId,
      });
    }

    if (item !== undefined && item.extra.type === 'company') {
      props.onSelected({
        type: item.extra.type,
        item: {
          id: item.id,
          name: item.text,
          organization: '',
          avatar: '',
        },
        companyId,
        organizationId,
        employeeId,
      });
    }
  };

  let treeOrganizationList: JSX.Element | JSX.Element[];
  if (state.searchFilter === '') {
    treeOrganizationList = (
      <Tree
        selectedId={state.selectedTreeNodeId}
        items={treeItems}
        onItemClick={handleTreeNodeClick}
      />
    );
  } else {
    const strings = hangul.d(state.searchFilter);
    treeOrganizationList = treeItems
      .filter((x) => x.strings && hangul.test(x.strings, strings))
      .map((x) => {
        return (
          <MenuItem
            key={x.id}
            label={x.text}
            className="tree-item"
            icon={x.icon}
            selected={x.id === state.selectedTreeNodeId}
            onClick={() => handleTreeNodeClick(x.id)}
          />
        );
      });

    if (treeOrganizationList.length === 0)
      treeOrganizationList = (
        <div className="empty">{getLocalizedText('검색결과가 없습니다.')}</div>
      );
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      searchFilter: event.target.value,
    }));
  };

  return (
    <div className="ui-organization-select">
      <div className="head-panel">
        <SimpleSearch keyword={state.searchFilter} onSearch={handleSearch} />
      </div>
      <div className="body-panel">{treeOrganizationList}</div>
    </div>
  );
}

export default ChooseOrganizationChart;
