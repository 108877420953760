import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import Chip from '../../../../../components/chip/Chip';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../containers/DirectoryMenuTreeContainer';
import {
  directoryPreferencesActions as actions,
  JobClassType,
} from '../../../../stores/directory/preferences';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../stores/directory';
import FeedBack from '../../../../../components/alert/FeedBack';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DirectoryPreferencesContainer(): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const preferences = useSelector(
    (state: RootState) => state.directory.preferences,
  );

  const initialState = () => {
    return {
      validation: '',
      change: false,
      saveing: false,
      jobClassType: preferences.jobClassType,
      administrators: employees
        .filter(
          (a) =>
            a.companyId === principal.companyId &&
            preferences.administrators.some((b) => a.id === b.employeeId),
        )
        .map((a) => {
          const {
            companyId,
            id: employeeId,
            representativeOrganizationId: organizationId,
            avatar,
          } = a;
          const updateAt =
            preferences.administrators.find((b) => b.employeeId === employeeId)
              ?.updateAt ?? '1000-01-01';
          return {
            companyId,
            employeeId,
            organizationId,
            avatar,
            updateAt,
          };
        }),
      administratorsMenuPoint: undefined as
        | { x: number; y: number; width: number; height: number }
        | undefined,
    };
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { jobClassType, administrators } = state;

      const save = await dispatch(actions.save({ jobClassType }));
      // console.log(`save:`, save);
      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }

      const removeAdministrators = preferences.administrators
        .filter(
          (a) =>
            administrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId, updateAt }) => ({ employeeId, updateAt }));
      const remove = await dispatch(
        actions.removeAdministrators(removeAdministrators),
      );
      // console.log(`remove:`, remove);
      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
          jobClassType,
        }));
        return;
      }

      const appendAdministrators = administrators
        .filter(
          (a) =>
            preferences.administrators.find(
              (b) => a.employeeId === b.employeeId,
            ) === undefined,
        )
        .map(({ employeeId }) => ({ employeeId }));
      const append = await dispatch(
        actions.appendAdministrators(appendAdministrators),
      );
      // console.log(`append:`, append);
      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
          jobClassType,
        }));
        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        jobClassType,
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  const handleJobClassItemChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      jobClassType: value as JobClassType,
    }));
  };

  const handleAdministratorsDelete = (employeeId: number) => {
    // console.log(`handleAdministratorsDelete(employeeId: ${employeeId})`);
    setState((prevState) => ({
      ...prevState,
      change: true,
      administrators: prevState.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
    }));
  };

  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    // console.log(`handleAdministratorsAppend(arg)`, arg);
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prevState) => {
      const { administrators } = prevState;
      const administratorsMenuPoint = undefined;
      if (administrators.some((a) => a.employeeId === employeeId))
        return { ...prevState, administratorsMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined)
        return { ...prevState, administratorsMenuPoint };

      const updateAt =
        preferences.administrators.find((a) => a.employeeId === employeeId)
          ?.updateAt ?? '';

      return {
        ...prevState,
        change: true,
        administrators: [
          ...administrators,
          {
            companyId,
            employeeId,
            organizationId: employee.representativeOrganizationId,
            avatar: employee.avatar,
            updateAt,
          },
        ],
      };
    });
  };

  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    // console.log(`handleAdministratorsAppend(event)`, event);
    const { administratorsMenuPoint } = state;
    if (event !== undefined && administratorsMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  const handleSave = () => {
    const { administrators } = state;
    if (administrators.length === 0) {
      const validation = getLocalizedText('관리자를 등록하세요.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  const handleCancel = () => {
    setState(initialState);
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const {
    validation,
    change,
    saveing,
    jobClassType,
    administrators,
    administratorsMenuPoint,
  } = state;

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText('기본설정')}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('조직도 직급')}>
          <RadioGroup
            data={[
              {
                value: 'jobposition',
                label: getLocalizedText('직위'),
              },
              {
                value: 'jobduty',
                label: getLocalizedText('직책'),
              },
              {
                value: 'jobposition+jobduty',
                label: getLocalizedText('직위/직책'),
              },
            ]}
            value={jobClassType}
            name="jobClassItems"
            onChange={handleJobClassItemChange}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title={getLocalizedText('관리자')}>
          <ChipGroup
            add={getLocalizedText('추가')}
            onAdd={handleDirectoryTreeMenuToggle}
          >
            {administrators.map(
              ({ companyId, employeeId, organizationId, avatar }) => (
                <Chip
                  key={`${companyId}/${employeeId}`}
                  label={getEmployeeName(companyId, employeeId)}
                  etc={getOrganizationName(companyId, organizationId)}
                  avatar={avatar}
                  onDelete={() => handleAdministratorsDelete(employeeId)}
                />
              ),
            )}
            {administratorsMenuPoint && (
              <DirectoryMenuTreeContainer
                point={administratorsMenuPoint}
                typeToFilter="employee"
                onItemClick={handleAdministratorsAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </EuiSetting.Item>
        <EuiToolbar>
          {change && (
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              loading={saveing}
              onClick={handleSave}
            />
          )}
          {!saveing && change && (
            <Button text={getLocalizedText('취소')} onClick={handleCancel} />
          )}
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default DirectoryPreferencesContainer;
