import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApprovalDashboardItem from './ApprovalDashboardItem';
import Avatar from '../../../../../components/avatar/Avatar';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import Divider from '../../../../../components/divider/Divider';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EmptyData from '../../../../../components/data/EmptyData';
import Chip from '../../../../../components/chip/Chip';
import api from '../../../../apis/approval/v1/index';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getApprovalLineDrafter } from '../../../common/dialogs/ApprovalLineDialogContainer';
import {
  b62,
  getAvatarPath,
  getPathMap,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  documentActions,
  jsonToApprovalLine,
} from '../../../../stores/approval/document';
import permissionDocumentApi from '../../../../apis/approval/v1/permission';

function ApprovalDashboard(props: { pathname: string }): JSX.Element {
  const dispatch = useAppDispatch();

  /** 분할 보기 여부. */
  const readingPaneMode = useSelector(
    (state: RootState) => state.approval2.document.readingPaneMode,
  );
  const isSplitView =
    readingPaneMode === 'horizontal' || readingPaneMode === 'vertical';

  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'default');
  // 고정 폴더 이름
  const title = categories.find((a) => a.id === 5001)?.name;

  /** 결재 */
  const [stateApproval, setStateApproval] = useState<
    | {
        id: number;
        drafter: {
          id: number;
          name: string;
          organization: string;
          class: string;
          avatar: string;
        };
        subject: string;
        createAt: string;
      }[]
  >([]);

  useEffect(() => {
    let mount = true;

    async function run() {
      const response = await api.documents({
        folderId: 1002,
        pageno: 1,
        rowsperpage: 5,
      });
      if (mount) {
        const approvalList = response.list.map((a) => {
          const approvalLineJson = jsonToApprovalLine(a.approvalline);
          const drafter = getApprovalLineDrafter(approvalLineJson);
          return {
            id: a.id,
            drafter: {
              id: drafter?.employeeId || 0,
              name: drafter?.employeeName || '',
              organization: drafter?.organizationName || '',
              class: drafter?.employeeId ? drafter?.jobPositionName : '',
              avatar: getAvatarPath(
                drafter?.companyId || 0,
                drafter?.employeeId || 0,
              ),
            },
            subject: a.subject,
            createAt: a.createAt,
          };
        });
        setStateApproval(approvalList);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, []);

  /** 완료 */
  const [stateComplete, setStateComplete] = useState<
    | {
        id: number;
        drafter: {
          id: number;
          name: string;
          organization: string;
          class: string;
          avatar: string;
        };
        subject: string;
        createAt: string;
      }[]
  >([]);

  useEffect(() => {
    let mount = true;

    async function run() {
      const response = await api.documents({
        folderId: 1004,
        pageno: 1,
        rowsperpage: 5,
      });
      if (mount) {
        const completeList = response.list.map((a) => {
          const approvalLineJson = jsonToApprovalLine(a.approvalline);
          const drafter = getApprovalLineDrafter(approvalLineJson);

          return {
            id: a.id,
            drafter: {
              id: drafter?.employeeId || 0,
              name: drafter?.employeeName || '',
              organization: drafter?.organizationName || '',
              class: drafter?.employeeId ? drafter?.jobPositionName : '',
              avatar: getAvatarPath(
                drafter?.companyId || 0,
                drafter?.employeeId || 0,
              ),
            },
            subject: a.subject,
            createAt: a.createAt,
          };
        });
        setStateComplete(completeList);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, []);

  /** 조회 */
  const [stateLookup, setStateLookup] = useState<
    | {
        id: number;
        drafter: {
          id: number;
          name: string;
          organization: string;
          class: string;
          avatar: string;
        };
        subject: string;
        createAt: string;
      }[]
  >([]);

  useEffect(() => {
    let mount = true;

    async function run() {
      const response = await permissionDocumentApi.documents({
        folderId: 4002,
        pageno: 1,
        rowsperpage: 5,
      });
      if (mount) {
        const lookupList = response.list.map((a) => {
          const approvalLineJson = jsonToApprovalLine(a.approvalline);
          const drafter = getApprovalLineDrafter(approvalLineJson);

          return {
            id: a.id,
            drafter: {
              id: drafter?.employeeId || 0,
              name: drafter?.employeeName || '',
              organization: drafter?.organizationName || '',
              class: drafter?.employeeId ? drafter?.jobPositionName : '',
              avatar: getAvatarPath(
                drafter?.companyId || 0,
                drafter?.employeeId || 0,
              ),
            },
            subject: a.subject,
            createAt:
              approvalLineJson.groups.find((g) => g.type === 'draft')?.items[0]
                ?.actAt ?? '1000-01-01',
          };
        });
        setStateLookup(lookupList);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, []);

  /** 참조 */
  const [stateReference, setStateReference] = useState<
    | {
        id: number;
        drafter: {
          id: number;
          name: string;
          organization: string;
          class: string;
          avatar: string;
        };
        subject: string;
        createAt: string;
      }[]
  >([]);

  useEffect(() => {
    let mount = true;
    async function run() {
      const response = await permissionDocumentApi.documents({
        folderId: 4001,
        pageno: 1,
        rowsperpage: 5,
      });
      if (mount) {
        const referenceList = response.list.map((a) => {
          const approvalLineJson = jsonToApprovalLine(a.approvalline);
          const drafter = getApprovalLineDrafter(approvalLineJson);

          return {
            id: a.id,
            drafter: {
              id: drafter?.employeeId || 0,
              name: drafter?.employeeName || '',
              organization: drafter?.organizationName || '',
              class: drafter?.employeeId ? drafter?.jobPositionName : '',
              avatar: getAvatarPath(
                drafter?.companyId || 0,
                drafter?.employeeId || 0,
              ),
            },
            subject: a.subject,
            createAt:
              approvalLineJson.groups.find((g) => g.type === 'draft')?.items[0]
                ?.actAt ?? '1000-01-01',
          };
        });
        setStateReference(referenceList);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, []);

  /** 나의기안 */
  const [stateMyWish, setStateMyWish] = useState<
    | {
        id: number;
        folderId: number;
        subject: string;
        statusElement: JSX.Element;
        createAt: string;
      }[]
  >([]);

  useEffect(() => {
    let mount = true;

    async function run() {
      const response = await api.findMyWishList({
        pageno: 1,
        rowsperpage: 5,
      });
      if (mount) {
        const myWishList = response.list.map((a) => {
          let statusElement: JSX.Element;
          switch (a.status) {
            case 1:
              statusElement = (
                <Chip
                  label={getLocalizedText('폴더.진행')}
                  theme="primary"
                  size="xs"
                />
              );
              break;
            case 3:
              statusElement = (
                <Chip
                  label={getLocalizedText('폴더.완료')}
                  theme="success"
                  size="xs"
                />
              );
              break;
            case 4:
              statusElement = (
                <Chip
                  label={getLocalizedText('폴더.반려')}
                  theme="error"
                  size="xs"
                />
              );
              break;
            case 44:
              statusElement = (
                <Chip
                  label={getLocalizedText('취소')}
                  theme="cancel"
                  size="xs"
                />
              );
              break;
            default:
              statusElement = (
                <Chip label={getLocalizedText('폴더.기안')} size="xs" />
              );
              break;
          }

          return {
            id: a.id,
            folderId: 1001,
            subject: a.subject,
            statusElement,
            createAt: a.createAt,
          };
        });

        setStateMyWish(myWishList);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, []);

  /** 진행 */
  const [stateProgress, setStateProgress] = useState<
    | {
        id: number;
        drafter: {
          id: number;
          name: string;
          organization: string;
          class: string;
          avatar: string;
        };
        current: {
          count: number;
          user: {
            id: string;
            category: string; // 'employee' | 'organization'; // 사원 혹은 부서
            name: string;
            organization: string;
            class: string;
            avatar: string;
          }[];
        };
        total: number;
        subject: string;
        createAt: string;
      }[]
  >([]);

  useEffect(() => {
    let mount = true;

    async function run() {
      const response = await api.documents({
        folderId: 1003,
        pageno: 1,
        rowsperpage: 5,
      });
      if (mount) {
        const progressList = response.list.map((a) => {
          const approvalLineJson = jsonToApprovalLine(a.approvalline);
          const drafter = getApprovalLineDrafter(approvalLineJson);

          let total = 0;
          let count = 1;

          const user: {
            id: string;
            category: string;
            name: string;
            organization: string;
            class: string;
            avatar: string;
          }[] = [];

          /** 현재 결재 total 카운트, 현재 결재자 순번 구하기 */
          approvalLineJson.groups
            .filter((s) => s.type !== 'draft')
            // eslint-disable-next-line consistent-return
            .forEach((x) => {
              /** 합의자, 수신자 */
              if (x.type === 'agree' || x.type === 'receive') {
                /** 병렬일 경우 */
                if (x.parallel) {
                  total += 1;
                  if (
                    x.items.filter((s) => s.act).length === x.items.length &&
                    x.items.filter((s) => s.act === 'receipt').length === 0
                  )
                    count += 1;
                } else {
                  total += x.items.length;
                  if (
                    x.items.filter((s) => s.act).length > 0 &&
                    x.items.filter((s) => s.act === 'receipt').length === 0
                  )
                    count += x.items.filter(
                      (s) => s.act && s.act !== 'receipt',
                    ).length;
                }
              } else {
                total += x.items.length;
                if (x.items.filter((s) => s.act).length > 0)
                  count += x.items.filter(
                    (s) => s.act && s.act !== 'receipt',
                  ).length;
              }
            });

          /** 현재 결재자 구하기 */
          approvalLineJson.groups
            .filter((s) => s.type !== 'draft')
            // eslint-disable-next-line consistent-return
            .forEach((x, xIndex) => {
              /** 합의자, 수신자 */
              if (x.type === 'agree' || x.type === 'receive') {
                if (user.length > 0) return false;

                /** 병렬일 경우 */
                if (x.parallel) {
                  x.items
                    .filter((s) => !s.act || s.act === 'receipt')
                    .forEach((p, pIndex) => {
                      user.push({
                        id: `${x.id}_${pIndex}_${
                          p.employeeName ? p.employeeId : p.organizationId
                        }`,
                        category: p.employeeName ? 'employee' : 'organization',
                        name: p.employeeName
                          ? p.employeeName
                          : p.organizationName,
                        organization: p?.organizationName || '',
                        class: p?.approverJobPositionName || '',
                        avatar: getAvatarPath(
                          p?.companyId || 0,
                          p?.employeeId || 0,
                        ),
                      });
                    });
                } else {
                  if (
                    (user.length > 0 ||
                      x.items.filter((s) => !s.act).length === 0) &&
                    x.items.filter((s) => s.act === 'receipt').length === 0
                  )
                    return false;

                  user.push({
                    id: `${x.id}_${xIndex}_${
                      x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                        .employeeName
                        ? x.items.filter(
                            (s) => !s.act || s.act === 'receipt',
                          )[0].employeeId
                        : x.items.filter(
                            (s) => !s.act || s.act === 'receipt',
                          )[0].organizationId
                    }`,
                    category: x.items.filter(
                      (s) => !s.act || s.act === 'receipt',
                    )[0].employeeName
                      ? 'employee'
                      : 'organization',
                    name: x.items.filter(
                      (s) => !s.act || s.act === 'receipt',
                    )[0].employeeName
                      ? x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                          .employeeName || ''
                      : x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                          ?.organizationName || '',
                    organization:
                      x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                        ?.organizationName || '',
                    class:
                      x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                        ?.approverJobPositionName || '',
                    avatar: getAvatarPath(
                      x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                        ?.companyId || 0,
                      x.items.filter((s) => !s.act || s.act === 'receipt')[0]
                        ?.employeeId || 0,
                    ),
                  });
                }
                /** 결재자 */
              } else if (x.type === 'approval') {
                if (
                  user.length > 0 ||
                  x.items.filter((s) => !s.act).length === 0
                )
                  return false;

                user.push({
                  id: `${x.id}_${xIndex}_${
                    x.items.filter((s) => !s.act)[0].employeeName
                      ? x.items.filter((s) => !s.act)[0].employeeId
                      : x.items.filter((s) => !s.act)[0].organizationId
                  }`,
                  category: x.items.filter((s) => !s.act)[0].employeeName
                    ? 'employee'
                    : 'organization',
                  name: x.items.filter((s) => !s.act)[0].employeeName
                    ? x.items.filter((s) => !s.act)[0].employeeName || ''
                    : x.items.filter((s) => !s.act)[0]?.organizationName || '',
                  organization:
                    x.items.filter((s) => !s.act)[0]?.organizationName || '',
                  class:
                    x.items.filter((s) => !s.act)[0]?.approverJobPositionName ||
                    '',
                  avatar: getAvatarPath(
                    x.items.filter((s) => !s.act)[0]?.companyId || 0,
                    x.items.filter((s) => !s.act)[0]?.employeeId || 0,
                  ),
                });
              }
            });

          return {
            id: a.id,
            drafter: {
              id: drafter?.employeeId || 0,
              name: drafter?.employeeName || '',
              organization: drafter?.organizationName || '',
              class: drafter?.employeeId ? drafter?.jobPositionName : '',
              avatar: getAvatarPath(
                drafter?.companyId || 0,
                drafter?.employeeId || 0,
              ),
            },
            current: {
              count,
              user,
            },
            total,
            subject: a.subject,
            createAt: a.createAt,
          };
        });

        // console.log('progressList', progressList);
        setStateProgress(progressList);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, []);

  /** 더보기 클릭 이벤트 */
  const handleClickMore = (folderId: number) => {
    go(`${getPathMap('/*', props.pathname)}/${b62(folderId)}`);
  };

  /** 리스트 클릭 이벤트 */
  const handleGo = (
    id: number,
    folderId: number,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (isSplitView) dispatch(documentActions.setReadingPaneMode('list'));
    go(`${getPathMap('/*', props.pathname)}/${b62(folderId)}/${b62(id)}`);
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`폴더.${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <div className="ui-approval-dashboard">
          <ApprovalDashboardItem
            title={getLocalizedText('폴더.결재')}
            icon="signature"
            onMore={() => handleClickMore(1002)}
          >
            {stateApproval.map((x) => (
              <a
                href="#"
                key={`approval_${x.id}`}
                className="content-item"
                onClick={(event) => handleGo(x.id, 1002, event)}
              >
                <Avatar
                  name={
                    x.drafter.name === ''
                      ? x.drafter.organization
                      : x.drafter.name
                  }
                  image={x.drafter.name === '' ? undefined : x.drafter.avatar}
                  icon={x.drafter.name === '' ? 'sitemap-fill' : undefined}
                  className="avatar"
                />
                <div className="content">
                  <div className="head">
                    <div className="user">
                      <span className="name">
                        {x.drafter.name === ''
                          ? x.drafter.organization
                          : x.drafter.name}
                      </span>
                      <span className="class">{x.drafter.class}</span>
                      {x.drafter.name !== '' && (
                        <span className="organization">
                          {x.drafter.organization}
                        </span>
                      )}
                    </div>
                    <div className="date">{dateSimplify(x.createAt)}</div>
                  </div>
                  <div className="subject">{x.subject}</div>
                </div>
              </a>
            ))}
            {stateApproval.length === 0 && <EmptyData />}
          </ApprovalDashboardItem>
          <ApprovalDashboardItem
            title={getLocalizedText('폴더.진행')}
            icon="waveform-path"
            className="proceeding"
            onMore={() => handleClickMore(1003)}
          >
            {stateProgress.map((x) => {
              if (x.current.user.length === 0) return null;
              const firstUser = x.current.user[0];

              return (
                <a
                  href="#"
                  key={x.id}
                  className="content-item"
                  onClick={(event) => handleGo(x.id, 1003, event)}
                >
                  <Avatar
                    name={
                      x.drafter.name === ''
                        ? x.drafter.organization
                        : x.drafter.name
                    }
                    image={x.drafter.name === '' ? undefined : x.drafter.avatar}
                    icon={x.drafter.name === '' ? 'sitemap-fill' : undefined}
                    className="avatar"
                  />
                  <div className="content">
                    <div className="head">
                      <div className="user">
                        <span className="name">
                          {x.drafter.name === ''
                            ? x.drafter.organization
                            : x.drafter.name}
                        </span>
                        <span className="class">{x.drafter.class}</span>
                        {x.drafter.name !== '' && (
                          <span className="organization">
                            {x.drafter.organization}
                          </span>
                        )}
                      </div>
                      <div className="date">{dateSimplify(x.createAt)}</div>
                    </div>
                    <div className="subject">{x.subject}</div>
                    <div className="process">
                      {firstUser.category === 'employee' ? (
                        <>
                          <Avatar
                            name={firstUser.name}
                            image={firstUser.avatar}
                            className="avatar"
                          />
                          <div className="user">
                            <span className="name">{firstUser.name}</span>
                            <span className="class">{firstUser.class}</span>
                            <span className="organization">
                              {firstUser.organization}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <Avatar
                            name={firstUser.name}
                            icon="sitemap-fill"
                            className="avatar"
                          />
                          <div className="user">
                            <span className="name">{firstUser.name}</span>
                          </div>
                        </>
                      )}
                      {x.current.user.length > 1 && (
                        <span className="others">
                          {getLocalizedText('외')} {x.current.user.length - 1}
                          {getLocalizedText('명')}
                        </span>
                      )}
                      <Divider orientation="vertical" className="divider" />
                      <div className="count">
                        <em>{x.current.count}</em>
                        <span>/</span>
                        <strong>{x.total}</strong>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
            {stateProgress.length === 0 && <EmptyData />}
          </ApprovalDashboardItem>
          <ApprovalDashboardItem
            title={getLocalizedText('폴더.기안')}
            icon="user-my"
            onMore={() => handleClickMore(1001)}
          >
            {stateMyWish.map((x) => (
              <a
                href="#"
                key={x.id}
                className="content-item"
                onClick={(event) => handleGo(x.id, x.folderId, event)}
              >
                <div className="content">
                  <div className="head">
                    {x.statusElement}
                    <div className="date">{dateSimplify(x.createAt)}</div>
                  </div>
                  <div className="subject">{x.subject}</div>
                </div>
              </a>
            ))}
            {stateMyWish.length === 0 && <EmptyData />}
          </ApprovalDashboardItem>
          <ApprovalDashboardItem
            title={getLocalizedText('폴더.완료')}
            icon="check-circle-todo"
            onMore={() => handleClickMore(1004)}
          >
            {stateComplete.map((x) => (
              <a
                href="#"
                key={`complete_${x.id}`}
                className="content-item"
                onClick={(event) => handleGo(x.id, 1004, event)}
              >
                <Avatar
                  name={
                    x.drafter.name === ''
                      ? x.drafter.organization
                      : x.drafter.name
                  }
                  image={x.drafter.name === '' ? undefined : x.drafter.avatar}
                  icon={x.drafter.name === '' ? 'sitemap-fill' : undefined}
                  className="avatar"
                />
                <div className="content">
                  <div className="head">
                    <div className="user">
                      <span className="name">
                        {x.drafter.name === ''
                          ? x.drafter.organization
                          : x.drafter.name}
                      </span>
                      <span className="class">{x.drafter.class}</span>
                      {x.drafter.name !== '' && (
                        <span className="organization">
                          {x.drafter.organization}
                        </span>
                      )}
                    </div>
                    <div className="date">{dateSimplify(x.createAt)}</div>
                  </div>
                  <div className="subject">{x.subject}</div>
                </div>
              </a>
            ))}
            {stateComplete.length === 0 && <EmptyData />}
          </ApprovalDashboardItem>
          <ApprovalDashboardItem
            title={getLocalizedText('폴더.참조')}
            icon="clipboard-text"
            onMore={() => handleClickMore(4001)}
          >
            {stateReference.map((x) => (
              <a
                href="#"
                key={`reference_${x.id}`}
                className="content-item"
                onClick={(event) => handleGo(x.id, 4001, event)}
              >
                <Avatar
                  name={
                    x.drafter.name === ''
                      ? x.drafter.organization
                      : x.drafter.name
                  }
                  image={x.drafter.name === '' ? undefined : x.drafter.avatar}
                  icon={x.drafter.name === '' ? 'sitemap-fill' : undefined}
                  className="avatar"
                />
                <div className="content">
                  <div className="head">
                    <div className="user">
                      <span className="name">
                        {x.drafter.name === ''
                          ? x.drafter.organization
                          : x.drafter.name}
                      </span>
                      <span className="class">{x.drafter.class}</span>
                      {x.drafter.name !== '' && (
                        <span className="organization">
                          {x.drafter.organization}
                        </span>
                      )}
                    </div>
                    <div className="date">{dateSimplify(x.createAt)}</div>
                  </div>
                  <div className="subject">{x.subject}</div>
                </div>
              </a>
            ))}
            {stateReference.length === 0 && <EmptyData />}
          </ApprovalDashboardItem>
          <ApprovalDashboardItem
            title={getLocalizedText('폴더.조회')}
            icon="clipboard-text"
            onMore={() => handleClickMore(4002)}
          >
            {stateLookup.map((x) => (
              <a
                href="#"
                key={`lookup_${x.id}`}
                className="content-item"
                onClick={(event) => handleGo(x.id, 4002, event)}
              >
                <Avatar
                  name={
                    x.drafter.name === ''
                      ? x.drafter.organization
                      : x.drafter.name
                  }
                  image={x.drafter.name === '' ? undefined : x.drafter.avatar}
                  icon={x.drafter.name === '' ? 'sitemap-fill' : undefined}
                  className="avatar"
                />
                <div className="content">
                  <div className="head">
                    <div className="user">
                      <span className="name">
                        {x.drafter.name === ''
                          ? x.drafter.organization
                          : x.drafter.name}
                      </span>
                      <span className="class">{x.drafter.class}</span>
                      {x.drafter.name !== '' && (
                        <span className="organization">
                          {x.drafter.organization}
                        </span>
                      )}
                    </div>
                    <div className="date">{dateSimplify(x.createAt)}</div>
                  </div>
                  <div className="subject">{x.subject}</div>
                </div>
              </a>
            ))}
            {stateLookup.length === 0 && <EmptyData />}
          </ApprovalDashboardItem>
        </div>
      </EuiBody>
    </>
  );
}

export default ApprovalDashboard;
