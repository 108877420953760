import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import EuiSetting from '../../../../components/layout/EuiSetting';
import PostView from '../../../../components/post/PostView';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../groupware-directory/stores/directory';
import { ResourceItem } from '../../../stores/folders';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import { RootState } from '../../../../groupware-webapp/app/store';
import { CustomNumbers } from '../../../../groupware-common/types';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

export function formatUseApprove(data: boolean): string {
  if (data) return getLocalizedText('승인제');
  return getLocalizedText('예약제');
}
export function formatUseRental(data: boolean): string {
  if (data) return getLocalizedText('반납자원');
  return getLocalizedText('일반자원');
}
export function formatUseTimeAvailable(
  data: boolean,
  start: string,
  end: string,
): string {
  if (data) return `${start} ~ ${end}`;
  return getLocalizedText('종일');
}

function ResourceFolderItemContentView(props: {
  paths: string[];
  folderName: string;
  item: ResourceItem;
  onAction(code: string): void;
}): JSX.Element {
  const { paths, folderName, item, onAction } = props;
  const directory = useDirectory();
  const organizations = useSelector(
    (s: RootState) => s.directory.organization.list.data.items,
  );
  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );
  /** 직위 */
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );

  const filteredManagers = item.managers.filter((a) =>
    employees.some((x) => x.id === a.referenceId),
  );
  const filteredUsers = item.users.filter(
    (a) =>
      a.referenceType === 1 ||
      (a.referenceType === 2 &&
        organizations.some((x) => x.id === a.referenceId)) ||
      (a.referenceType === 3 && employees.some((x) => x.id === a.referenceId)),
  );
  const filteredExceptioners = item.exceptioners.filter(
    (a) =>
      a.referenceType === 1 ||
      (a.referenceType === 2 &&
        organizations.some((x) => x.id === a.referenceId)) ||
      (a.referenceType === 3 && employees.some((x) => x.id === a.referenceId)),
  );
  return (
    <>
      <EuiSetting.Header title={getLocalizedText('자원정보')}>
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={() => onAction('item/sort')}
        />
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={() => onAction('item/update')}
        />
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-full"
          onClick={() => onAction('item/delete')}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem
                title={getLocalizedText('폴더', { ns: 'common' })}
              >
                <PostView.CategoryValue value={folderName} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('자원명')}>
                <PostView.CategoryValue value={item.name} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('사용여부')}>
                <PostView.CategoryValue
                  value={
                    item.status
                      ? getLocalizedText('사용함')
                      : getLocalizedText('사용안함')
                  }
                />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('예약방식')}>
                <PostView.CategoryValue
                  value={formatUseApprove(item.useApprove)}
                />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('반납여부')}>
                <PostView.CategoryValue
                  value={formatUseRental(item.useRental)}
                />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('사용가능시간')}>
                <PostView.CategoryValue
                  value={formatUseTimeAvailable(
                    item.useTimeAvailable,
                    item.availableFromTime,
                    item.availableToTime,
                  )}
                />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('설명')}>
                <PostView.CategoryValue value={item.description ?? ''} />
              </PostView.CategoryItem>
              {(item.useApprove || item.useRental) && (
                <PostView.CategoryItem title={getLocalizedText('담당자')}>
                  <ChipGroup>
                    {filteredManagers
                      .map((a) => {
                        let jobClassSeq = 0;
                        if (a.referenceType === 3) {
                          const employeeData = getDirectoryData({
                            ...directory,
                            companyId: a.referenceCompanyId,
                            employeeId: a.referenceId,
                          });
                          // 직위 또는 직책 순서로 정렬 순서 결정.
                          jobClassSeq =
                            (jobClassType === 'jobduty'
                              ? jobDuties.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    v.id === employeeData.jobDutyId,
                                )?.seq
                              : jobPositions.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    employeeData.jobPositionId === v.id,
                                )?.seq) || CustomNumbers.SMALLINT_MAX;
                        }
                        return {
                          ...a,
                          jobClassSeq,
                        };
                      })
                      // 회사 → 부서 → 직원 순으로 정렬 후
                      // 직위 또는 직책 순번이 낮을수록 밑으로.
                      .sort((a, b) => {
                        if (a.jobClassSeq < b.jobClassSeq) return -1;
                        if (a.jobClassSeq > b.jobClassSeq) return 1;
                        return 0;
                      })
                      .map(
                        ({
                          referenceCompanyId: companyId,
                          referenceId: id,
                        }) => {
                          const directoryData = getDirectoryData({
                            ...directory,
                            companyId,
                            employeeId: id,
                          });
                          return (
                            <Chip
                              key={directoryData.employeeId}
                              label={directoryData.employeeName}
                              etc={directoryData.organizationName}
                              avatar={directoryData.avatar}
                            />
                          );
                        },
                      )}
                  </ChipGroup>
                </PostView.CategoryItem>
              )}
              <PostView.CategoryItem title={getLocalizedText('사용자')}>
                <ChipGroup>
                  {filteredUsers
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 3) {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.referenceType !== b.referenceType) {
                        if (a.referenceType === 1) return -1;
                        if (a.referenceType === 2) {
                          if (b.referenceType === 1) return 1;
                          return -1;
                        }
                        return 1;
                      }
                      if (a.referenceType === 3) {
                        if (a.jobClassSeq < b.jobClassSeq) return -1;
                        if (a.jobClassSeq > b.jobClassSeq) return 1;
                      }
                      return 0;
                    })
                    .map(
                      ({
                        referenceCompanyId: companyId,
                        referenceId: id,
                        referenceType: type,
                      }) => {
                        if (type === 1 || type === 2) {
                          const company = getCompanyName(companyId);
                          const data = getOrganizationName(companyId, id);
                          return (
                            <Chip
                              key={id}
                              label={data}
                              etc={company}
                              icon={
                                type === 2
                                  ? ('sitemap-fill' as const)
                                  : ('company' as const)
                              }
                            />
                          );
                        }
                        const directoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={directoryData.employeeId}
                            label={directoryData.employeeName}
                            etc={directoryData.organizationName}
                            avatar={directoryData.avatar}
                          />
                        );
                      },
                    )}
                </ChipGroup>
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('예외자')}>
                <ChipGroup>
                  {filteredExceptioners
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 3) {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.referenceType !== b.referenceType) {
                        if (a.referenceType === 1) return -1;
                        if (a.referenceType === 2) {
                          if (b.referenceType === 1) return 1;
                          return -1;
                        }
                        return 1;
                      }
                      if (a.referenceType === 3) {
                        if (a.jobClassSeq < b.jobClassSeq) return -1;
                        if (a.jobClassSeq > b.jobClassSeq) return 1;
                      }
                      return 0;
                    })
                    .map(
                      ({
                        referenceCompanyId: companyId,
                        referenceId: id,
                        referenceType: type,
                      }) => {
                        if (type === 1 || type === 2) {
                          const company = getCompanyName(companyId);
                          const data = getOrganizationName(companyId, id);
                          return (
                            <Chip
                              key={id}
                              label={data}
                              etc={company}
                              icon={
                                type === 2
                                  ? ('sitemap-fill' as const)
                                  : ('company' as const)
                              }
                            />
                          );
                        }
                        const directoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={directoryData.employeeId}
                            label={directoryData.employeeName}
                            etc={directoryData.organizationName}
                            avatar={directoryData.avatar}
                          />
                        );
                      },
                    )}
                </ChipGroup>
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('사진정보')}>
                {item.imagePath ? (
                  <img
                    src={item.imagePath}
                    alt={getLocalizedText('사진정보')}
                    width={150}
                  />
                ) : (
                  <i
                    className="eui-icon eui-icon-no-image"
                    style={{
                      fontSize: '85px',
                      color: 'var(--secondary-text-color)',
                    }}
                  />
                )}
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default ResourceFolderItemContentView;
