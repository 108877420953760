import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import CheckboxGroup from '../checkbox/CheckboxGroup';
import CustomDatePicker from '../date/CustomDatePicker';
import Dialog from '../dialog/Dialog';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import DialogHeader from '../dialog/DialogHeader';
import DialogTitle from '../dialog/DialogTitle';
import PostWrite from '../post/PostWrite';
import Radio from '../radio/Radio';
import SelectField from '../selectField/SelectField';
import { dateFormat } from '../../groupware-common/utils/ui';
import { RootState } from '../../groupware-webapp/app/store';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

export interface RepeatDaysType {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

export function formatRepeatDaysType(date: Date): RepeatDaysType {
  const week = [
    getLocalizedText('일'),
    getLocalizedText('월'),
    getLocalizedText('화'),
    getLocalizedText('수'),
    getLocalizedText('목'),
    getLocalizedText('금'),
    getLocalizedText('토'),
  ];
  const data = {
    mon: week[date.getDay()] === getLocalizedText('월'),
    tue: week[date.getDay()] === getLocalizedText('화'),
    wed: week[date.getDay()] === getLocalizedText('수'),
    thu: week[date.getDay()] === getLocalizedText('목'),
    fri: week[date.getDay()] === getLocalizedText('금'),
    sat: week[date.getDay()] === getLocalizedText('토'),
    sun: week[date.getDay()] === getLocalizedText('일'),
  };
  return data;
}

/** 입력한 날짜의 해당 달 기준 주차 구하기. */
export function dateToWeekString(date: Date): string {
  switch (Math.ceil(date.getDate() / 7)) {
    case 1:
      return getLocalizedText('첫');
    case 2:
      return getLocalizedText('두');
    case 3:
      return getLocalizedText('세');
    case 4:
      return getLocalizedText('네');
    case 5:
      return getLocalizedText('다섯');
    default:
      return getLocalizedText('첫');
  }
}

/** repeat 객체 -> 요약 객체로 변환 */
export function summaryType(
  date: Date, // 반복 시작 날짜
  d: {
    frequency: number; // 반복 빈도.
    cycle: string; // 반복 주기.
    repeatDays: string[]; // 반복일. (반복빈도 - 매주일 경우)
    monthRepeatStandard: number; // 반복 마감일. (반복빈도 - 매월일 경우)
    endType: string; // 종료일타입
    endDay: string; // 반복 종료일 - 날짜
    endCount: number; // 반복 종료일 - 횟수
  },
): {
  one: string;
  two: string;
  three: string;
} {
  const week = [
    getLocalizedText('일'),
    getLocalizedText('월'),
    getLocalizedText('화'),
    getLocalizedText('수'),
    getLocalizedText('목'),
    getLocalizedText('금'),
    getLocalizedText('토'),
  ];
  const days = {
    mon: d.repeatDays.some((a) => a === week[1]),
    tue: d.repeatDays.some((a) => a === week[2]),
    wed: d.repeatDays.some((a) => a === week[3]),
    thu: d.repeatDays.some((a) => a === week[4]),
    fri: d.repeatDays.some((a) => a === week[5]),
    sat: d.repeatDays.some((a) => a === week[6]),
    sun: d.repeatDays.some((a) => a === week[0]),
  };
  let oneSummary = '';
  let twoSummary = '';
  let threeSummary = '';
  if (d.cycle === '1' && d.frequency === 1)
    oneSummary = getLocalizedText('매일');
  else if (d.cycle !== '1' && d.frequency === 1)
    oneSummary = `${d.cycle}${getLocalizedText('일마다')}`;
  if (d.cycle === '1' && d.frequency === 2)
    oneSummary = getLocalizedText('매주');
  else if (d.cycle !== '1' && d.frequency === 2)
    oneSummary = `${d.cycle}${getLocalizedText('주마다')}`;
  if (d.cycle === '1' && d.frequency === 3)
    oneSummary = getLocalizedText('매월');
  else if (d.cycle !== '1' && d.frequency === 3)
    oneSummary = `${d.cycle}${getLocalizedText('개월마다')}`;
  if (d.cycle === '1' && d.frequency === 4)
    oneSummary = getLocalizedText('매년');
  else if (d.cycle !== '1' && d.frequency === 4)
    oneSummary = `${d.cycle}${getLocalizedText('년마다')}`;

  if (d.frequency === 2) {
    const weekend = {
      월요일: days.mon,
      화요일: days.tue,
      수요일: days.wed,
      목요일: days.thu,
      금요일: days.fri,
      토요일: days.sat,
      일요일: days.sun,
    };

    const object: string[] = [];
    Object.keys(weekend).forEach((a, i) => {
      if (Object.values(weekend)[i]) object.push(a);
    });
    for (let i = 0; i < object.length; i += 1) {
      twoSummary += ` ${object[i]}`;
    }
  } else if (d.frequency === 3) {
    if (d.monthRepeatStandard === 0) {
      twoSummary = ` ${date.getDate().toString()}${getLocalizedText('일')}`;
    } else if (d.monthRepeatStandard === 1) {
      const turn = dateToWeekString(date);
      twoSummary = getLocalizedText('{{n}}번째 {{day}}요일', {
        n: turn,
        day: week[date.getDay()],
      });
      // } else twoSummary = ` 마지막 ${week[date.getDay()]}${getLocalizedText('요일')}`;
    } else
      twoSummary = getLocalizedText('마지막 {{day}}요일', {
        day: week[date.getDay()],
      });
  } else if (d.frequency === 4) {
    twoSummary = ` ${dateFormat(date, 'MM월 DD일')}`;
  }
  if (d.endType === '') {
    threeSummary = ` ${d.endCount}${getLocalizedText('회')}`;
  } else if (d.endType === getLocalizedText('반복 종료일')) {
    threeSummary = ` ${dateFormat(
      d.endDay,
      'yyyy년 MM월 DD일',
    )}${getLocalizedText('까지')}`;
  }
  return {
    one: oneSummary, // 반복 주기
    two: twoSummary, // 반복일 또는 반복 마감일 | 반복주기 1년일 경우 날짜 표시.
    three: threeSummary, // 종료일
  };
}

function DetailRepeatDialog(props: {
  resource?: boolean; // 자원관리모듈이면 true 자원관리 모듈이 아니면 undefined
  start: Date;
  repeat?: {
    frequency: number; // 반복 빈도.
    cycle: string; // 반복 주기.
    repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
    monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
    endType: string; // 종료일
    endDay: string; // 반복 종료일 - 날짜
    endCount: number; // 반복 종료일 - 횟수
  };
  summary?: {
    one: string;
    two: string;
    three: string;
  };
  onClose(): void;
  onSave(
    repeat: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: string; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    },
    summary: {
      one: string;
      two: string;
      three: string;
    },
  ): void;
}): JSX.Element {
  const { resource, start, repeat, summary } = props;
  const basic = resource
    ? useSelector(
        (state: RootState) => state.resource.userPreferences.preferences,
      )
    : useSelector((state: RootState) => state.calendar.userPreferences.basic);

  const week = [
    getLocalizedText('일'),
    getLocalizedText('월'),
    getLocalizedText('화'),
    getLocalizedText('수'),
    getLocalizedText('목'),
    getLocalizedText('금'),
    getLocalizedText('토'),
  ];
  let initialEndDay = new Date(start);
  if (repeat?.endType === getLocalizedText('반복 종료일')) {
    initialEndDay = new Date(repeat.endDay);
    initialEndDay.setHours(start.getHours());
    initialEndDay.setMinutes(start.getMinutes());
  } else {
    initialEndDay.setMonth(start.getMonth() + 3);
  }

  const initialState =
    repeat && summary
      ? {
          start,
          repeat: {
            ...repeat,
            endDay: initialEndDay,
          },
          summary,
        }
      : {
          start,
          repeat: {
            frequency: 2,
            cycle: '1',
            repeatDays: formatRepeatDaysType(start),
            monthRepeatStandard: 0,
            endType: getLocalizedText('반복 종료일'),
            endDay: initialEndDay,
            endCount: 1,
          },
          summary: {
            one: getLocalizedText('매주'),
            two: ` ${week[start.getDay()]}${getLocalizedText('요일')}`,
            three: ` ${dateFormat(
              initialEndDay,
              'yyyy년 MM월 DD일',
            )}${getLocalizedText('까지')}`,
          },
        };

  const [state, setState] = useState<{
    start: Date;
    repeat: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: Date; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    };
    summary: {
      one: string; // 반복빈도 + 반복주기
      two: string; // 반복일 또는 반복 마감일
      three: string; // 종료일
    };
  }>(initialState);

  let repeatCycle: { value: string; label: string }[] = [];
  let index = 0;
  switch (state.repeat.frequency) {
    case 1:
      index = 31;
      break;
    case 2:
      index = 6;
      break;
    case 3:
      index = 13;
      break;
    case 4:
      index = 51;
      break;
    default:
      break;
  }
  for (let i = 1; i < index; i += 1) {
    repeatCycle = [
      ...repeatCycle,
      { value: i.toString(), label: i.toString() },
    ];
  }

  const repeatOptions = [
    { value: '1', label: getLocalizedText('일') },
    { value: '2', label: getLocalizedText('주') },
    { value: '3', label: getLocalizedText('개월') },
    { value: '4', label: getLocalizedText('년') },
  ];

  /** 반복 주기 타입 변경 */
  const handleChangeRepeatType = (repeatType: string) => {
    const value = parseInt(repeatType, 10);
    let frequency = '';
    let summaryTwo = '';

    if (value === 1) frequency = getLocalizedText('매일');
    else if (value === 2) {
      frequency = getLocalizedText('매주');
      const data = {
        월요일: state.repeat.repeatDays.mon,
        화요일: state.repeat.repeatDays.tue,
        수요일: state.repeat.repeatDays.wed,
        목요일: state.repeat.repeatDays.thu,
        금요일: state.repeat.repeatDays.fri,
        토요일: state.repeat.repeatDays.sat,
        일요일: state.repeat.repeatDays.sun,
      };

      const object: string[] = [];
      Object.keys(data).forEach((a, i) => {
        if (Object.values(data)[i]) object.push(a);
      });
      for (let i = 0; i < object.length; i += 1) {
        summaryTwo += ` ${object[i]}`;
      }
    } else if (value === 3) {
      frequency = getLocalizedText('매월');
      if (state.repeat.monthRepeatStandard === 0) {
        summaryTwo = ` ${state.start.getDate().toString()}${getLocalizedText(
          '일',
        )}`;
      } else if (state.repeat.monthRepeatStandard === 1) {
        const th = dateToWeekString(state.start);
        summaryTwo = ` ${th}번째 ${
          week[state.start.getDay()]
        }${getLocalizedText('요일')}`;
      } else
        summaryTwo = ` 마지막 ${week[state.start.getDay()]}${getLocalizedText(
          '요일',
        )}`;
    } else if (value === 4) frequency = getLocalizedText('매년');
    setState((prev) => ({
      ...prev,
      repeat: {
        ...prev.repeat,
        frequency: value,
        cycle: '1',
      },
      summary: {
        ...prev.summary,
        one: frequency,
        two: summaryTwo,
      },
    }));
  };

  /** 반복 주기 싸이클 변경 */
  const handleChangeRepeatCycle = (value: string) => {
    let one = '';
    if (value === '1' && state.repeat.frequency === 1)
      one = getLocalizedText('매일');
    else if (value !== '1' && state.repeat.frequency === 1)
      one = `${value}${getLocalizedText('일마다')}`;
    if (value === '1' && state.repeat.frequency === 2)
      one = getLocalizedText('매주');
    else if (value !== '1' && state.repeat.frequency === 2)
      one = `${value}${getLocalizedText('주마다')}`;
    if (state.repeat.frequency === 3 && value === '1')
      one = getLocalizedText('매월');
    else if (value !== '1' && state.repeat.frequency === 3)
      one = `${value}${getLocalizedText('개월마다')}`;
    if (state.repeat.frequency === 4 && value === '1')
      one = getLocalizedText('매년');
    else if (value !== '1' && state.repeat.frequency === 4)
      one = `${value}${getLocalizedText('년마다')}`;
    setState((prev) => ({
      ...prev,
      summary: {
        ...prev.summary,
        one,
      },
      repeat: {
        ...prev.repeat,
        cycle: value,
      },
    }));
  };

  /** 반복 빈도 매주일 경우 - 반복일 변경 */
  const handleChangeRepeatDays = (type: string) => {
    let two = '';
    let data = {
      월요일: state.repeat.repeatDays.mon,
      화요일: state.repeat.repeatDays.tue,
      수요일: state.repeat.repeatDays.wed,
      목요일: state.repeat.repeatDays.thu,
      금요일: state.repeat.repeatDays.fri,
      토요일: state.repeat.repeatDays.sat,
      일요일: state.repeat.repeatDays.sun,
    };
    const object: string[] = [];
    switch (type) {
      case 'mon':
        data = {
          ...data,
          월요일: state.repeat.repeatDays.mon !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              mon: !prev.repeat.repeatDays.mon,
            },
          },
        }));
        break;
      case 'tue':
        data = {
          ...data,
          화요일: state.repeat.repeatDays.tue !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              tue: !prev.repeat.repeatDays.tue,
            },
          },
        }));
        break;
      case 'wed':
        data = {
          ...data,
          수요일: state.repeat.repeatDays.wed !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              wed: !prev.repeat.repeatDays.wed,
            },
          },
        }));
        break;
      case 'thu':
        data = {
          ...data,
          목요일: state.repeat.repeatDays.thu !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              thu: !prev.repeat.repeatDays.thu,
            },
          },
        }));
        break;
      case 'fri':
        data = {
          ...data,
          금요일: state.repeat.repeatDays.fri !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              fri: !prev.repeat.repeatDays.fri,
            },
          },
        }));
        break;
      case 'sat':
        data = {
          ...data,
          토요일: state.repeat.repeatDays.sat !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              sat: !prev.repeat.repeatDays.sat,
            },
          },
        }));
        break;
      case 'sun':
        data = {
          ...data,
          일요일: state.repeat.repeatDays.sun !== true,
        };
        Object.keys(data).forEach((a, i) => {
          if (Object.values(data)[i]) object.push(a);
        });
        for (let i = 0; i < object.length; i += 1) {
          two += ` ${object[i]}`;
        }
        setState((prev) => ({
          ...prev,
          summary: {
            ...prev.summary,
            two,
          },
          repeat: {
            ...prev.repeat,
            repeatDays: {
              ...prev.repeat.repeatDays,
              sun: !prev.repeat.repeatDays.sun,
            },
          },
        }));
        break;
      default:
        break;
    }
  };

  /** 종료일 - 횟수 */
  // const handleChangeRepeatDeatlineCount = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   const dd = event.target.value
  //     .replace(/[^0-9.]/g, '')
  //     .replace(/(\..*)\./g, '$1');
  //   setState((prev) => ({
  //     ...prev,
  //     summary: {
  //       ...prev.summary,
  //       three: ` ${Number(dd)}${getLocalizedText('회')}`,
  //     },
  //     repeat: {
  //       ...prev.repeat,
  //       endCount: Number(dd),
  //     },
  //   }));
  // };

  /** 반복 빈도 매월일 경우 - 빈복 마감일 변경 */
  const handleChangeRepeatDeadline = (deadline: string) => {
    const value = parseInt(deadline, 10);
    let two = '';
    if (value === 0) {
      two = ` ${state.start.getDate().toString()}${getLocalizedText('일')}`;
    } else if (value === 1) {
      const th = dateToWeekString(state.start);
      two = ` ${th}번째 ${week[state.start.getDay()]}${getLocalizedText(
        '요일',
      )}`;
    } else
      two = ` 마지막 ${week[state.start.getDay()]}${getLocalizedText('요일')}`;

    setState((prev) => ({
      ...prev,
      summary: {
        ...prev.summary,
        two,
      },
      repeat: {
        ...prev.repeat,
        monthRepeatStandard: value,
      },
    }));
  };

  /** 종료일 유형 변경. */
  const handleChangeRepeatEndType = (value: string) => {
    let tt = '';
    if (value === '') tt = ` ${state.repeat.endCount}${getLocalizedText('회')}`;
    else if (value === getLocalizedText('반복 종료일'))
      tt = ` ${dateFormat(
        state.repeat.endDay,
        'yyyy년 MM월 DD일',
      )}${getLocalizedText('까지')}`;

    setState((prev) => ({
      ...prev,
      summary: {
        ...prev.summary,
        three: tt,
      },
      repeat: {
        ...prev.repeat,
        endType: value,
      },
    }));
  };

  /** 반복 종료일 */
  const handleChangeRepeatEndDay = (date: Date) => {
    if (date < start) return;
    setState((prev) => ({
      ...prev,
      summary: {
        ...prev.summary,
        three: ` ${dateFormat(date, 'yyyy년 MM월 DD일')}${getLocalizedText(
          '까지',
        )}`,
      },
      repeat: {
        ...prev.repeat,
        endDay: date,
      },
    }));
  };

  const handleSave = () => {
    const data: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: string; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    } = {
      frequency: state.repeat.frequency,
      cycle: state.repeat.cycle,
      repeatDays: state.repeat.repeatDays,
      monthRepeatStandard: state.repeat.monthRepeatStandard,
      endType: state.repeat.endType,
      endDay: dateFormat(state.repeat.endDay, 'yyyy-MM-DD'),
      endCount: state.repeat.endCount,
    };
    props.onSave(data, state.summary);
  };

  /** 반복예약 리스트 구하기. */
  const makeRepeatList = (date: Date) => {
    if (dateToWeekString(date) === getLocalizedText('다섯'))
      return [
        {
          value: '0',
          label: getLocalizedText('매월 {{date}}일', {
            date: state.start.getDate().toString(),
          }),
        },
        {
          value: '2',
          label: getLocalizedText('매월 마지막 {{day}}요일', {
            day: week[state.start.getDay()],
          }),
        },
      ];
    const aWeekLater = new Date(date);
    aWeekLater.setDate(date.getDate() + 7);
    if (aWeekLater.getMonth() === date.getMonth())
      return [
        {
          value: '0',
          label: getLocalizedText('매월 {{date}}일', {
            date: state.start.getDate().toString(),
          }),
        },
        {
          value: '1',
          label: getLocalizedText('매월 {{n}}번째 {{day}}요일', {
            n: dateToWeekString(date),
            day: week[date.getDay()],
          }),
        },
      ];
    return [
      {
        value: '0',
        label: getLocalizedText('매월 {{date}}일', {
          date: state.start.getDate().toString(),
        }),
      },
      {
        value: '1',
        label: getLocalizedText('매월 {{n}}번째 {{day}}요일', {
          n: dateToWeekString(date),
          day: week[date.getDay()],
        }),
      },
      {
        value: '2',
        label: getLocalizedText('매월 마지막 {{day}}요일', {
          day: week[state.start.getDay()],
        }),
      },
    ];
  };

  return (
    <Dialog size="sm" onClose={() => props.onClose()}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('반복 설정')}</DialogTitle>
      </DialogHeader>
      <DialogBody noneOverFlow>
        <PostWrite>
          <PostWrite.Item title={getLocalizedText('반복 주기')}>
            <SelectField
              value={state.repeat.cycle}
              data={repeatCycle}
              onChange={handleChangeRepeatCycle}
            />
            <span style={{ margin: '3px' }} />
            <SelectField
              value={state.repeat.frequency.toString()}
              data={repeatOptions}
              onChange={handleChangeRepeatType}
            />
          </PostWrite.Item>
          {state.repeat.frequency === 2 && (
            <PostWrite.Item title={getLocalizedText('반복 요일')}>
              <CheckboxGroup type="simple">
                {basic?.firstDayOfWeek === 0 ? (
                  <Checkbox
                    label={getLocalizedText('일')}
                    checked={state.repeat.repeatDays.sun}
                    onChange={() => handleChangeRepeatDays('sun')}
                  />
                ) : undefined}
                <Checkbox
                  label={getLocalizedText('월')}
                  checked={state.repeat.repeatDays.mon}
                  onChange={() => handleChangeRepeatDays('mon')}
                />
                <Checkbox
                  label={getLocalizedText('화')}
                  checked={state.repeat.repeatDays.tue}
                  onChange={() => handleChangeRepeatDays('tue')}
                />
                <Checkbox
                  label={getLocalizedText('수')}
                  checked={state.repeat.repeatDays.wed}
                  onChange={() => handleChangeRepeatDays('wed')}
                />
                <Checkbox
                  label={getLocalizedText('목')}
                  checked={state.repeat.repeatDays.thu}
                  onChange={() => handleChangeRepeatDays('thu')}
                />
                <Checkbox
                  label={getLocalizedText('금')}
                  checked={state.repeat.repeatDays.fri}
                  onChange={() => handleChangeRepeatDays('fri')}
                />
                <Checkbox
                  label={getLocalizedText('토')}
                  checked={state.repeat.repeatDays.sat}
                  onChange={() => handleChangeRepeatDays('sat')}
                />
                {basic?.firstDayOfWeek === 0 ? undefined : (
                  <Checkbox
                    label={getLocalizedText('일')}
                    checked={state.repeat.repeatDays.sun}
                    onChange={() => handleChangeRepeatDays('sun')}
                  />
                )}
              </CheckboxGroup>
            </PostWrite.Item>
          )}
          {state.repeat.frequency === 3 && (
            <PostWrite.Item title={getLocalizedText('반복 마감일')}>
              <SelectField
                data={makeRepeatList(state.start)}
                value={state.repeat.monthRepeatStandard.toString()}
                onChange={handleChangeRepeatDeadline}
              />
            </PostWrite.Item>
          )}
          <PostWrite.Item title={getLocalizedText('종료')}>
            <fieldset className="eui-check-group column">
              <div className="group">
                <Radio
                  label={getLocalizedText('없음')}
                  checked={
                    state.repeat.endType === getLocalizedText('계속 반복')
                  }
                  onChange={() =>
                    handleChangeRepeatEndType(getLocalizedText('계속 반복'))
                  }
                />
                <Radio
                  label={getLocalizedText('반복 종료일')}
                  checked={
                    state.repeat.endType === getLocalizedText('반복 종료일')
                  }
                  onChange={() =>
                    handleChangeRepeatEndType(getLocalizedText('반복 종료일'))
                  }
                >
                  <div
                    style={{
                      background: 'var(--fill-color)',
                      borderRadius: '4px',
                      lineHeight: 1,
                      padding: '0 16px',
                      height: '36px',
                      width: '150px',
                    }}
                  >
                    <CustomDatePicker
                      controlClassName="positionToTop"
                      disabled={
                        state.repeat.endType !== getLocalizedText('반복 종료일')
                      }
                      placeholderText="yyyy-MM-dd"
                      dateFormat="yyyy-MM-dd"
                      minDate={start}
                      selected={
                        state.repeat.endType !== getLocalizedText('반복 종료일')
                          ? null
                          : state.repeat.endDay
                      }
                      onChange={handleChangeRepeatEndDay}
                    />
                  </div>
                </Radio>
                {/* {!resource && (
                  <Radio
                    label=""
                    checked={state.repeat.endType === ''}
                    onChange={() => handleChangeRepeatEnd('')}
                  >
                    <TextField
                      width={70}
                      value={
                        state.repeat.endType !== ''
                          ? ''
                          : state.repeat.endCount.toString()
                      }
                      readonly={state.repeat.endType !== ''}
                      onChange={handleChangeRepeatDeatlineCount}
                    />
                    <div style={style}>번 이후</div>
                  </Radio>
                )} */}
              </div>
            </fieldset>
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          text={getLocalizedText('취소')}
          variant="outlined"
          onClick={() => props.onClose()}
        />
        <Button
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default DetailRepeatDialog;
