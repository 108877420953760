import { useEffect } from 'react';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { blockedUserActions } from '../../../store/blockedUser';

function AuthenticationBlockedUserRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      blockedUserActions.blockedUsers({ search, route: { pathname, search } }),
    );
  }, [pathname, search]);

  return null;
}

export default AuthenticationBlockedUserRoute;
