import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import FileChipGroup from '../../../../../components/chip/FileChipGroup';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import PostSubject from '../../../../../components/post/PostSubject';
import UserInfo from '../../../../../components/user/UserInfo';
import { Column } from '../../../../../groupware-common/ui/type';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type Subject = {
  id: number;
  subject: string;
  comments: number;
  new: boolean;
  notice: boolean;
  folderName?: string;
  status: string;
};

type User = {
  companyId: number;
  id: number;
  name: string;
  organization: string;
  avatar?: string;
};

type Props = {
  id: number;
  listId?: string;
  columns: Column<PostListItemType>[];
  item: Record<
    string,
    Subject | User | string | boolean | number | number[] | undefined
  >;
  isNotice?: boolean;
  selected?: boolean;
  readingPaneMode?: string;
  attachedFiles?: {
    fileId: number;
    name: string;
  }[];
  onCheckedChange(id: string, checked: boolean): void;
  onClick(arg: { id: string; isNotice: boolean }): void;
  onImportanceChange?(arg: { id: number }): void;
  onFileDownload?(fileId: number, name: string): void;
};

function DocumentContentBodyListItem(props: Props): JSX.Element {
  const display = useSelector((state: RootState) => state.session.display);
  const readingPaneMode = useSelector(
    (state: RootState) => state.document.documents.readingPaneMode,
  );
  const displayFileVisible = useSelector(
    (state: RootState) => state.document.documents.displayDensity,
  );

  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const {
    columns,
    item,
    selected,
    onCheckedChange,
    attachedFiles = [],
    listId = '',
    isNotice = false,
  } = props;

  const handleClick = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    props.onClick({
      id: props.listId ?? '',
      isNotice: props.isNotice ?? false,
    });
  };

  const handleCheckedChange = () => {
    onCheckedChange(listId, !item.checked);
  };

  const handleDownload = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fileId?: number,
    name?: string,
  ) => {
    event.stopPropagation();
    if (props.onFileDownload && fileId && name)
      props.onFileDownload(fileId, name);
  };

  const handleImportanceChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (props.onImportanceChange === undefined) return;
    props.onImportanceChange({ id: props.id });
  };

  return (
    <>
      <PostList.Item selected={selected} onClick={handleClick}>
        {columns.map(({ name, type, text }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;
          if (name === 'checked' && typeof value === 'boolean') {
            if (props.isNotice) {
              if (display !== 'phone' && readingPaneMode !== 'vertical') {
                children = (
                  <i
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    className="post-badge notice"
                    aria-label={getLocalizedText('공지')}
                    title={getLocalizedText('공지')}
                  />
                );
              } else {
                children = null;
              }
            } else
              children = (
                <Checkbox checked={value} onChange={handleCheckedChange} />
              );
          } else if (name === 'importance' && typeof value === 'boolean') {
            children = (
              <Button
                text={getLocalizedText('별표표시')}
                icon={value ? 'star-fill' : 'star'}
                iconType
                onClick={handleImportanceChange}
              />
            );
          } else if (name === 'user') {
            const { avatar, name: username, organization } = value as User;
            children = (
              <UserInfo
                avatar={username === '' ? undefined : avatar}
                name={username === '' ? organization : username}
                from={username === '' ? undefined : organization}
                icon={username === '' ? 'sitemap-fill' : undefined}
              />
            );
          } else if (type === 'post') {
            const subject = value as Subject;
            const style: React.CSSProperties = isNotice
              ? { color: 'var(--primary-color)' }
              : { fontWeight: 'normal' };
            children = (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <PostSubject
                    folder={
                      subject.folderName
                        ? { name: subject.folderName }
                        : undefined
                    }
                    style={style}
                    notice={
                      subject.notice &&
                      (display === 'phone' || readingPaneMode === 'vertical')
                    }
                    new={subject.new}
                    title={subject.subject}
                    chip={
                      subject.status === 'CHECKOUT'
                        ? {
                            label: getLocalizedText('체크아웃'),
                            theme: 'primary',
                          }
                        : undefined
                    }
                    attachments={attachedFiles?.length > 0}
                  />
                </div>
                {displayFileVisible === 'normal' &&
                  attachedFiles &&
                  attachedFiles.length > 0 && (
                    <FileChipGroup
                      data={attachedFiles.map((a) => ({
                        id: a.fileId,
                        name: a.name,
                      }))}
                      max={3}
                      onDownload={handleDownload}
                    />
                  )}
              </div>
            );
          }
          if (typeof value === 'string') {
            if (type === 'date') {
              children = (
                <PostList.Value value={dateSimplify(value, 'dateNumber')} />
              );
            } else {
              children = <PostList.Value value={value} />;
            }
          } else if (typeof value === 'number') {
            if (display === 'phone' || props.readingPaneMode === 'vertical')
              children = (
                <span>
                  <PostList.Value value={text} />
                  <span style={{ paddingLeft: '20px' }}>{value}</span>
                </span>
              );
            else children = <PostList.Value value={value} />;
          }
          const style =
            type === 'count' ? { justifyContent: 'center' } : undefined;
          return (
            <PostList.Cell key={name} type={type} style={style}>
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
    </>
  );
}

export default React.memo(DocumentContentBodyListItem);
