/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { LinkType } from '../document';

/** 삭제함 문서 리스트 */
async function documentList(arg: {
  pageNo: number;
  rowsPerPage: number;
  searchCode?: string;
  searchWord?: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/trash/document/all`;
    const params = {
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
      searchcode: arg.searchCode,
      searchword: arg.searchWord,
    };
    const response = await axios.get<
      {
        checked: boolean;
        companyId: number;
        id: number;
        workName: string;
        status: string;
        importance: string;
        no: string;
        subject: string;
        approvalLine: string;
        draftedAt: string;
        completedAt: string;
        updateAt: string;
        deleterId?: number;
        isOutgoing: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
        hasReceipt: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
        isReceipt: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
        linkWait: boolean;
      }[]
    >(url, {
      headers,
      params,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 삭제함 총 갯수 */
async function totalCount(arg: { searchCode?: string; searchWord?: string }) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/trash/document/totalcount`;
    const params = {
      searchcode: arg.searchCode,
      searchword: arg.searchWord,
    };
    const response = await axios.get<{ totalCount: number }>(url, {
      headers,
      params,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 삭제함으로 이동 */
async function moveDocument(arg: {
  data:
    | { affiliatedCompanyId?: number; id: number; updateAt: string }
    | {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
      }[];
}) {
  const { host, headers } = getApiConfig();
  try {
    const url = Array.isArray(arg.data)
      ? `${host}/api/approval/v1/adminconsole/trash/document/all`
      : `${host}/api/approval/v1/adminconsole/trash/document`;
    const response = await axios.post(url, arg.data, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 문서 완전 삭제 */
async function deleteDocument(arg: {
  data:
    | {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
      }
    | {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
      }[];
}) {
  const { host, headers } = getApiConfig();
  try {
    const url = Array.isArray(arg.data)
      ? `${host}/api/approval/v1/adminconsole/trash/document/all`
      : `${host}/api/approval/v1/adminconsole/trash/document`;
    const response = await axios.delete(url, { headers, data: arg.data });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 문서 복원 */
async function restoreDocument(arg: {
  data:
    | { affiliatedCompanyId?: number; id: number; updateAt: string }
    | {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
      }[];
}) {
  const { host, headers } = getApiConfig();
  try {
    const url = Array.isArray(arg.data)
      ? `${host}/api/approval/v1/adminconsole/trash/document/restore/all`
      : `${host}/api/approval/v1/adminconsole/trash/document/restore`;
    const response = await axios.put(url, arg.data, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서를 조회합니다.
 * @param id 문서 아이디.
 * @returns 문서 객체.
 */
async function documentView(arg: { id: number }): Promise<{
  id: number;
  parentCompanyId: number;
  parentId: number;
  status: string;
  subject: string;
  contents: string;
  approvalLine: string;
  opinions: number;
  comments: number;
  attachedDocument: number;
  attachedFile: number;
  attachedSharedFileCount: number;
  draftedAt: string;
  completedAt: string;
  option: number;
  updateAt: string;
  isOutgoing: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  linkType: LinkType;
  linkWait: boolean;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/adminconsole/trash/document`;
    const response = await axios.get(url, {
      headers,
      params: { id: arg.id },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 삭제 문서의 이전 아이디를 조회 */
async function prevId(arg: {
  id: number;
  searchCode?: string;
  searchWord?: string;
}): Promise<{ affiliatedCompanyId: number; id: number } | undefined> {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/adminconsole/trash/document/prev`;
  const response = await axios.get(url, {
    headers,
    params: arg,
  });
  return response.data;
}

/** 삭제 문서의 다음 아이디를 조회 */
async function nextId(arg: {
  id: number;
  searchCode?: string;
  searchWord?: string;
}): Promise<{ affiliatedCompanyId: number; id: number } | undefined> {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/adminconsole/trash/document/next`;
  const response = await axios.get(url, {
    headers,
    params: arg,
  });
  return response.data;
}

const approvalTrashApi = {
  documentList,
  documentView,
  prevId,
  nextId,
  totalCount,

  moveDocument,
  deleteDocument,
  restoreDocument,
};

export default approvalTrashApi;
