import React from 'react';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../components/button/Button';
import EuiSetting from '../../../../components/layout/EuiSetting';
import PostView from '../../../../components/post/PostView';
import { ResourceFolderItem } from '../../../stores/folders';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function ResourceFolderContentView(props: {
  paths: string[];
  folder: ResourceFolderItem;
  parentFolderName: string;
  onAction(code: string): void;
}): JSX.Element {
  const { paths, folder, parentFolderName, onAction } = props;
  return (
    <>
      <EuiSetting.Header title={getLocalizedText('폴더정보')}>
        <Button
          text={getLocalizedText('순서변경')}
          iconType
          icon="sort-amount"
          onClick={() => onAction('folder/sort')}
        />
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={() => onAction('folder/update')}
        />
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-full"
          onClick={() => onAction('folder/delete')}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title={getLocalizedText('상위폴더명')}>
                <PostView.CategoryValue value={parentFolderName} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('폴더명')}>
                <PostView.CategoryValue value={folder.name} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title={getLocalizedText('설명')}>
                <PostView.CategoryValue value={folder.description} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default ResourceFolderContentView;
