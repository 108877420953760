import React from 'react';
import { Language } from '../../../../../groupware-common/types';
import Button from '../../../../../components/button/Button';
import PostView from '../../../../../components/post/PostView';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/**
 * @property parentNames 상위 조직 이름 배열.
 * @property names 언어별 이름.
 * @property description 설명.
 * @property managerName 조직 관리자 이름.
 * @property onUpdate 수정 이벤트.
 * @property onDelete 삭제 이벤트.
 */
type Props = {
  useLink?: boolean;
  parentNames: string[];
  names: { label: Language; value: string }[];
  description: string;
  managerName: string;
  onUpdate(): void;
  onChangeSeq(): void;
  onDelete(): void;
};

function OrganizationChartContentOrganizationView(props: Props): JSX.Element {
  // console.log(`${OrganizationChartContentOrganizationView.name}.render`);

  /*
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dummy,
    parentNames: dummy.parentNames || ['상위부서1', '상위부서2'],
    names: dummy.names || [{ label: 'ko-KR' as Language, value: '이름' }],
    description: dummy.description || '설명',
    managerName: dummy.managerName || '미지정',
    onUpdate: dummy.onUpdate || emptyFunction,
    onDelete: dummy.onDelete || emptyFunction,
  };
  */

  const {
    useLink,
    parentNames,
    names,
    description,
    managerName,
    onUpdate,
    onChangeSeq,
    onDelete,
  } = props;

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('부서정보')}>
        {!useLink && (
          <>
            <Button
              text={getLocalizedText('수정')}
              iconType
              icon="edit"
              onClick={onUpdate}
            />
            <Button
              text={getLocalizedText('순서변경')}
              iconType
              icon="sort-amount"
              onClick={onChangeSeq}
            />
            <Button
              text={getLocalizedText('삭제')}
              iconType
              icon="trash-full"
              onClick={onDelete}
            />
          </>
        )}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title={getLocalizedText('상위부서')}>
                  <PostView.CategoryValue>
                    {parentNames.map((v, i, a) => {
                      if (i !== a.length - 1)
                        return <span key={v}>{v} &gt;</span>;
                      return <span key={v}>{v}</span>;
                    })}
                  </PostView.CategoryValue>
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('부서명')}>
                  {names.map(({ label, value }) => {
                    return (
                      <PostView.CategoryValue
                        key={label}
                        value={value}
                        label={label}
                      />
                    );
                  })}
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('부서설명')}>
                  <PostView.CategoryValue value={description} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('부서장')}>
                  <PostView.CategoryValue value={managerName} />
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default OrganizationChartContentOrganizationView;
