import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import BoardComposeRoute from '../pages/root/board/compose/BoardComposeRoute';
import BoardContentRoute from '../pages/root/board/content/BoardContentRoute';
import { folderBoxActions, FolderListItem } from '../stores/folder';
import { preferencesActions } from '../stores/preferences';

function RootLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash, locationKey: key } = props;
  const { module, folderId } = getPathParams<{
    module: string;
    folderId?: string;
  }>('/:module/:folderId', pathname);
  const { contentMode } = getQueryParams(search);
  const { organizationId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const dispatch = useAppDispatch();

  const [state, setState] = useState<{
    defaultFolderId: number;
    folders: FolderListItem[];
  }>({
    defaultFolderId: 0,
    folders: [],
  });

  useEffect(() => {
    Promise.all([
      dispatch(preferencesActions.findPreferences()).then(({ payload }) => {
        const { defaultFolderId } = payload as { defaultFolderId: number };
        setState((prev) => ({ ...prev, defaultFolderId }));
      }),
      dispatch(
        folderBoxActions.userFolderList({ organizationId, employeeId }),
      ).then(({ payload }) => {
        const folders = payload as FolderListItem[];
        setState((prev) => ({ ...prev, folders }));
      }),
    ]);
  }, [dispatch]);
  const { defaultFolderId, folders } = state;
  const isExist =
    defaultFolderId !== 0 &&
    folders.length > 0 &&
    folders.some((a) => a.id === defaultFolderId);

  if (module === 'board' && !folderId && search === '' && isExist)
    return <Redirect to={`/board/${b62(defaultFolderId)}`} />;
  if (contentMode === 'create' || contentMode === 'update')
    return <BoardComposeRoute pathname={pathname} search={search} key={key} />;

  // 리스트.
  return <BoardContentRoute pathname={pathname} search={search} key={key} />;
}

export default RootLocateRoute;
