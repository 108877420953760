import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import PostView from '../../../../../components/post/PostView';
import UserInfo from '../../../../../components/user/UserInfo';
import Comment from '../../../../../components/comment/Comment';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { BoardReadOnlyItem } from '../../../../stores/board';
import { replaceRetentionPeriodToString } from '../../../../stores/folder';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import boardFileApi from '../../../../apis/board/v1/common';
import { getCommentItems } from '../../../root/board/content/BoardContentBodyView';
import Chip from '../../../../../components/chip/Chip';
import Divider from '../../../../../components/divider/Divider';
import {
  dateTimeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function BoardTrashBodyView(props: {
  pathname: string;
  view?: BoardReadOnlyItem;
  type: 'full' | 'split';
  folderName?: string;
}): JSX.Element | null {
  const { view, folderName } = props;
  const dispatch = useAppDispatch();

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props.pathname]);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const folders = useSelector(
    (state: RootState) => state.boards.folder.adminconsole.list,
  );
  const type = props.type !== undefined ? props.type : 'full';
  if (!view) return null;
  const folder = folders.find((a) => a.id === view.folderId);
  /** 글 작성자. */
  const writer =
    view.employeeId === 0
      ? undefined
      : getDirectoryData({
          ...directory,
          companyId: principal.companyId,
          employeeId: view.employeeId,
        });
  const comments = getCommentItems(
    view.commentData,
    principal.companyId,
    directory,
  );

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (id: number) => {
    boardFileApi
      .downloadAll(id)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    id: number;
    fileId: number;
    name: string;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { id, fileId, name } = arg;
    boardFileApi
      .download(id, fileId)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const { retentionPeriod, attachedFiles } = view;
  const noticeRange =
    view.notifyStartDate === '1000-01-01' && view.notifyEndDate === '9999-12-31'
      ? getLocalizedText('상시')
      : `${dateTimeFormat(
          view.notifyStartDate,
          'yyyy-MM-DD',
        )} ~ ${dateTimeFormat(view.notifyEndDate, 'yyyy-MM-DD')}`;
  const viewContent: JSX.Element = (
    <PostView data-post-type={type}>
      <PostView.Head>
        <PostView.Title>
          {folderName ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '5px', fontSize: '15px' }}>
                <Chip theme="cancel" label={folderName} />
              </div>
              {view.subject}
            </div>
          ) : (
            `${view.subject}`
          )}
        </PostView.Title>
        <UserInfo
          className="view-author"
          avatar={writer ? writer.avatar : undefined}
          icon={writer ? undefined : 'person'}
          name={writer ? writer.employeeName : getLocalizedText('익명')}
          from={writer ? writer.organizationName : undefined}
          date={view.createAt}
          dateType="customFormat"
          dateEtc={`${getLocalizedText('조회')} ${view.views}`}
        />
      </PostView.Head>
      <PostView.Info column>
        {retentionPeriod !== '' && (
          <PostView.ExcerptItem
            title={getLocalizedText('보존기간')}
            value={replaceRetentionPeriodToString(retentionPeriod)}
          />
        )}
        {view.isNotified && (
          <PostView.ExcerptItem
            title={getLocalizedText('공지기간')}
            value={noticeRange}
          />
        )}
      </PostView.Info>
      <PostView.Body>
        <PostView.Content data={view.contents} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 0 0 4px',
            fontSize: '14px',
          }}
        >
          <span>{getLocalizedText('좋아요')}</span>
          <span style={{ marginLeft: '4px' }}>
            {view.likes > 99 ? '99+' : view.likes}
          </span>
        </div>
        {((attachedFiles && attachedFiles.length > 0) ||
          folder?.option.useReply) && <Divider />}
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={() => handleAttachedFileDownloadAll(attachedFiles[0].id)}
          >
            {attachedFiles.map((x) => (
              <AttachmentsItem
                key={x.fileId}
                name={x.name}
                size={x.size}
                onClick={() =>
                  handleAttachedFileDownload({
                    id: x.id,
                    fileId: x.fileId,
                    name: x.name,
                  })
                }
              />
            ))}
          </AttachmentsList>
        )}
        {folder?.option.useReply && (
          <Comment count={view.comments} comments={comments ?? []} />
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {viewContent}
    </div>
  );
}

export default BoardTrashBodyView;
