import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import ApprovalApproverProxyContentList from './ApprovalApproverProxyContentList';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ApprovalApproverProxyContentEdit from './ApprovalApproverProxyContentEdit';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { surrogateApprovalActions } from '../../../../stores/approval/surrogate-approval';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ApprovalApproverProxyContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const approverproxyData = useSelector(
    (state: RootState) => state.approval2.surrogateApproval.approver,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6005)?.name;
  const [state, setState] = useState<{
    modecheck: boolean;
    selectedId?: string;
  }>({
    modecheck: true,
    selectedId: undefined,
  });

  const handleSelectList = async (arg: DirectoryTreeItemArg) => {
    // console.log(`handleItemClick(arg)`, arg);
    if (arg.item.extra.type === 'employee') {
      const id = arg.item.extra.employeeId;
      const parmeter = {
        companyId: arg.item.extra.companyId,
        id,
      };
      dispatch(surrogateApprovalActions.find(parmeter)).then((result) => {
        if ((result as { error?: string }).error === undefined) {
          setState((prevState) => ({
            ...prevState,
            modecheck: false,
            selectedId: `${parmeter.companyId}_${arg.item.extra.organizationId}_${parmeter.id}`,
          }));
        }
      });
    }
    // 조직도 목록 사원 클릭시 우측에 상세 및 편집 뷰어 표시
  };

  const handleCloseView = () => {
    setState((prevState) => ({
      ...prevState,
      modecheck: true,
      selectedId: undefined,
    }));
  };

  const { modecheck, selectedId } = state;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{`${getLocalizedText(`${title}`)}`}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ApprovalApproverProxyContentList
              selectedId={selectedId}
              onItemClick={handleSelectList}
            />
          </EuiSetting.Left>
          {modecheck ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleCloseView}>
              <ApprovalApproverProxyContentEdit
                approverproxyData={approverproxyData}
              />
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
    </>
  );
}

export default ApprovalApproverProxyContainer;
