import React from 'react';
import SplitPane from 'react-split-pane';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import EuiToolbar from './EuiToolbar';
import { RootState } from '../../groupware-webapp/app/store';
import Tooltip from '../tooltip/Tooltip';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function EuiSettingLeft(props: { children: React.ReactNode }): JSX.Element {
  return <div className="ui-setting-left">{props.children}</div>;
}

function EuiSettingRight(props: {
  children: React.ReactNode;
  onClose?(): void;
}): JSX.Element {
  let classname = 'ui-setting-right';
  if (props.onClose) classname += ' hasClose';
  return (
    <div className={classname}>
      {props.onClose && (
        <Button
          text={getLocalizedText('닫기')}
          iconType
          icon="close"
          className="setting-close"
          onClick={props.onClose}
          color="secondary"
        />
      )}
      {props.children}
    </div>
  );
}

function EuiSettingHeader(props: {
  title?: string;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <div className="ui-setting-header">
      {props.title !== undefined && (
        <h3 className="header-title">{props.title}</h3>
      )}
      <div className="header-action">{props.children}</div>
    </div>
  );
}

function EuiSettingToolbar(props: { children: React.ReactNode }): JSX.Element {
  return (
    <EuiToolbar className="ui-setting-toolbar">{props.children}</EuiToolbar>
  );
}

function EuiSettingToolbarLeft(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <EuiToolbar.Left>{props.children}</EuiToolbar.Left>;
}

function EuiSettingToolbarRight(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <EuiToolbar.Right>{props.children}</EuiToolbar.Right>;
}

function EuiSettingContent(props: { children: React.ReactNode }): JSX.Element {
  return <div className="ui-setting-content">{props.children}</div>;
}

function EuiSettingFooter(props: { children: React.ReactNode }): JSX.Element {
  return <div className="ui-setting-footer">{props.children}</div>;
}

function EuiSettingGroup(props: { title: string }): JSX.Element {
  return <div className="ui-setting-group">{props.title}</div>;
}

function EuiSettingItem(props: {
  title: string;
  tooltip?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="ui-setting-item" style={props.style}>
      <div className="setting-item-title">
        {props.title}
        {props.tooltip && (
          <Tooltip title={props.tooltip}>
            <i
              className="eui-icon eui-icon-info-circle-fill tip"
              style={{ fontSize: '15px', margin: '3px 0 1px 4px' }}
            />
          </Tooltip>
        )}
      </div>
      <div className="setting-item-content">{props.children}</div>
    </div>
  );
}

function EuiSettingItemGroup(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="ui-setting-item-group">{props.children}</div>;
}

function EuiSettingItemValue(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="setting-item-value">{props.children}</div>;
}

function EuiSetting(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  const display = useSelector((state: RootState) => state.session.display);
  let classname = 'ui-setting';
  if (props.className) classname += ` ${props.className}`;

  return (
    <>
      {display === 'phone' ? (
        <div className={classname}>{props.children}</div>
      ) : (
        <SplitPane
          className={`ui-split ${classname}`}
          split="vertical"
          minSize={361}
          defaultSize={361}
        >
          {props.children}
        </SplitPane>
      )}
    </>
  );
}

EuiSetting.Left = EuiSettingLeft;
EuiSetting.Right = EuiSettingRight;
EuiSetting.Header = EuiSettingHeader;
EuiSetting.Toolbar = EuiSettingToolbar;
EuiSetting.ToolbarLeft = EuiSettingToolbarLeft;
EuiSetting.ToolbarRight = EuiSettingToolbarRight;
EuiSetting.Content = EuiSettingContent;
EuiSetting.Footer = EuiSettingFooter;
EuiSetting.Group = EuiSettingGroup;
EuiSetting.Item = EuiSettingItem;
EuiSetting.ItemGroup = EuiSettingItemGroup;
EuiSetting.Value = EuiSettingItemValue;

export default EuiSetting;
