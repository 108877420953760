import React from 'react';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function Alert(props: {
  /**
   * 상태
   * - [error]: 오류
   * - [warning]: 경고
   * - [info]: 팁 / 주의사항
   * - [success]: 성공 / 정상처리
   * */
  severity?: 'error' | 'warning' | 'info' | 'success';
  title?: string | React.ReactNode;
  /**
   * 형태
   * - [outlined] 테두리만 있는
   * - [filled] 배경색으로 채움
   * */
  variant?: 'outlined' | 'filled';
  icon?: IconType;
  size?: 'sm';
  children: React.ReactNode;
  /**
   * 커스텀 액션
   * - <Button text="액션명" color="inherit" size="sm" onClick="handleClickEventName">
   * */
  action?: React.ReactNode;
  onClose?(): void;
}): JSX.Element {
  let classname = 'eui-alert';
  if (props.severity) classname += ` alert-${props.severity}`;
  if (props.variant) classname += ` alert-${props.variant}`;
  if (props.size) classname += ` alert-${props.size}`;

  return (
    <div className={classname}>
      {props.icon ? (
        <Icon icon={props.icon} className="alert-icon custom-icon" />
      ) : (
        <i className="alert-icon default-icon" />
      )}
      <div className="alert-message">
        {props.title && <strong className="alert-title">{props.title}</strong>}
        <div className="alert-body">{props.children}</div>
      </div>
      {props.action && <div className="alert-action">{props.action}</div>}
      {props.onClose && (
        <div className="alert-close">
          <Button
            text={getLocalizedText('닫기')}
            iconType
            icon="times-small"
            onClick={props.onClose}
            size="sm"
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(Alert);
