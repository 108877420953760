import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../../../../components/button/Button';
import { getAvatarPath } from '../../../../../../groupware-common/utils';
import { createLocalizedTextFactory } from '../../../../../../groupware-common/utils/i18n';
import Dialog from '../../../../../../components/dialog/Dialog';
import Tab from '../../../../../../components/tab/Tab';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import SimpleSearch from '../../../../../../components/search/SimpleSearch';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import MenuItem from '../../../../../../components/menu/MenuItem';
import UserInfo from '../../../../../../components/user/UserInfo';
import Menu from '../../../../../../components/menu/Menu';
import MenuDivider from '../../../../../../components/menu/MenuDivider';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../../../../../components/tree/DirectoryTree';
import Icon from '../../../../../../components/icon/Icon';
import { approverMacroApi } from '../../../../../apis/approval/v1/approvarmacro';
import {
  getCompanyName,
  getEmployeeName,
  getOrganizationName,
  useDirectory,
} from '../../../../../../groupware-directory/stores/directory';
import Loading from '../../../../../../components/loading/Loading';
import FeedBack from '../../../../../../components/alert/FeedBack';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { getApprovalLineGroupNameFromType } from '../../../../../stores/approval/document';

function getApprovalLineGroupItemOptions(
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty',
): Array<
  | {
      code: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
      text: string;
      checked: boolean;
    }
  | {
      code: 'divider';
    }
  | {
      code: 'option';
      text: string;
      checked: boolean;
      disabled: boolean;
    }
> {
  const getLocalizedText = createLocalizedTextFactory('approval');
  return [
    {
      code: 'jobposition' as const,
      text: getLocalizedText('직위로 표시'),
      checked: jobClassType === 'jobposition',
    },
    {
      code: 'jobduty' as const,
      text: getLocalizedText('직책으로 표시'),
      checked: jobClassType === 'jobduty',
    },
    {
      code: 'jobposition+jobduty' as const,
      text: getLocalizedText('직위/직책으로 표시'),
      checked: jobClassType === 'jobposition+jobduty',
    },
  ];
}

function getApprovalLineGroupItemAgreeOptions(
  option: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'option';
      text: string;
    }
> {
  const getLocalizedText = createLocalizedTextFactory('approval');
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'option' as const,
      text: option
        ? getLocalizedText('선택 해제')
        : getLocalizedText('선택으로 지정'),
    },
  ];
}

function getApprovalLineGroupItemApprovalOptions(
  arbitraryDecision: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'arbitrarydecision';
      text: string;
    }
> {
  const getLocalizedText = createLocalizedTextFactory('approval');
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'arbitrarydecision' as const,
      text: arbitraryDecision
        ? getLocalizedText('전결권한 해제')
        : getLocalizedText('전결권한 지정'),
    },
  ];
}

function ApprovalLineGroupItem(
  props:
    | {
        groupId: string;
        id: string;
        organizationName: string;
        onDelete?(arg: {
          groupId: string;
          id: string;
          event: React.MouseEvent;
        }): void;
        options?: Array<
          | {
              code: 'divider';
            }
          | {
              // code: 'option';
              code: string;
              text: string;
              checked?: boolean;
              disabled?: boolean;
            }
        >;
        onOptionChange?(arg: {
          groupId: string;
          id: string;
          code: string;
          checked: boolean;
        }): void;
        dragable?: boolean;
      }
    | {
        groupId: string;
        id: string;
        employeeText: string;
        organizationName: string;
        avatar?: string;
        macroName?: string;
        onDelete?(arg: {
          groupId: string;
          id: string;
          event: React.MouseEvent;
        }): void;
        options?: Array<
          | {
              code: 'divider';
            }
          | {
              // code:
              //   | 'jobposition'
              //   | 'jobduty'
              //   | 'jobposition+jobduty'
              //   | 'option'
              //   | 'arbitrarydecision';
              code: string;
              text: string;
              checked?: boolean;
              disabled?: boolean;
            }
        >;
        onOptionChange?(arg: {
          groupId: string;
          id: string;
          code: string;
          checked: boolean;
        }): void;
        dragable?: boolean;
      },
) {
  const { dragable = true } = props;
  const [optionMenuPoint, setOptionMenuPoint] = useState<
    { x: number; y: number; width: number; height: number } | undefined
  >(undefined);

  const getLocalizedText = createLocalizedTextFactory('approval');

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { groupId, id, onDelete } = props;
    if (onDelete) onDelete({ groupId, id, event });
  };

  const handleOptionMenuToggle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (optionMenuPoint === undefined) {
      const rect = event.currentTarget.getBoundingClientRect();
      setOptionMenuPoint({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      });
    } else handleOptionMenuClose();
  };

  const handleOptionMenuClose = () => {
    setOptionMenuPoint(undefined);
  };

  const handleOptionMenuItemClick = (code: string, checked: boolean) => {
    const { groupId, id, onOptionChange } = props;
    if (onOptionChange) {
      if (
        code === 'jobposition' ||
        code === 'jobduty' ||
        code === 'jobposition+jobduty'
      ) {
        if (checked !== true)
          onOptionChange({ groupId, id, code, checked: true });
      } else onOptionChange({ groupId, id, code, checked });
    }
    handleOptionMenuClose();
  };

  const defaultPropsValue = {
    organizationName: '',
    onDelete: props.onDelete,
    options: props.options,
    employeeText: undefined,
    avatar: undefined,
    macroName: undefined,
  };

  const {
    organizationName,
    onDelete,
    options,
    employeeText,
    avatar,
    macroName,
  } = { ...defaultPropsValue, ...props };

  let userInfo: JSX.Element = (
    <UserInfo name={organizationName} icon="sitemap-fill" />
  );
  if (employeeText !== undefined) {
    userInfo = macroName ? (
      <UserInfo
        name={employeeText}
        from={organizationName}
        icon="user-tie"
        etc={`[${macroName}]`}
      />
    ) : (
      <UserInfo name={employeeText} from={organizationName} avatar={avatar} />
    );
  }
  return (
    <div className="approval-line-item">
      {dragable && <div className="drag" />}
      {userInfo}
      {options && (
        <Button
          className="action setting"
          text={getLocalizedText('설정')}
          iconType
          icon="cog-fill-small"
          onClick={handleOptionMenuToggle}
          color="secondary"
        />
      )}
      {onDelete && (
        <Button
          className="action delete"
          text={getLocalizedText('삭제')}
          iconType
          icon="times-circle-fill-small"
          onClick={handleDeleteClick}
          color="secondary"
        />
      )}
      {options && optionMenuPoint && (
        <Menu point={optionMenuPoint} onClose={handleOptionMenuClose} size="sm">
          {options.map((a, i) => {
            const defaultOptionsValue = {
              code: a.code,
              text: '',
              checked: undefined,
              disabled: undefined,
            };
            const { code, text, checked, disabled } = {
              ...defaultOptionsValue,
              ...a,
            };

            const key = `${i}_${code}`;
            if (code === 'divider') return <MenuDivider key={key} />;
            return (
              <MenuItem
                key={key}
                label={text}
                onClick={() =>
                  handleOptionMenuItemClick(code, checked ?? false)
                }
                checked={checked}
                disabled={disabled}
              />
            );
          })}
        </Menu>
      )}
    </div>
  );
}

/** 공유 권한 그룹 항목 조직 유형. */
type SharePermissionGroupItemOrganizationType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: undefined;
  employeeName: undefined;
};

/** 공유 권한 그룹 항목 직원 유형. */
type SharePermissionGroupItemEmployeeType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  macroId?: number;
  macroName?: string;
};

/** 공유 권한 그룹 항목 유형. */
export type SharePermissionGroupItemType =
  | SharePermissionGroupItemOrganizationType
  | SharePermissionGroupItemEmployeeType;

/** 결재선 그룹 항목 조직 유형 */
type ApprovalLineGroupItemOrganizationType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: undefined;
  employeeName: undefined;
};

/** 결재선 그룹 항목 직원 유형 */
type ApprovalLineGroupItemEmployeeType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  macroId?: number;
  macroName?: string;
};

/** 결재선 그룹 항목 기본 유형 (참조권 및 조회권 사용) */
type ApprovalLineGroupItemDefaultType =
  | ApprovalLineGroupItemOrganizationType
  | ApprovalLineGroupItemEmployeeType;

/** 결재선 그룹 항목 유형 (전체) */
type ApprovalLineGroupItemType =
  | ApprovalLineGroupItemDefaultType
  | (ApprovalLineGroupItemEmployeeType & { approval: boolean })
  | (ApprovalLineGroupItemDefaultType & { option: boolean })
  | (ApprovalLineGroupItemEmployeeType & { arbitraryDecision: boolean });

/** 결재선 그룹 항목 배열 기안 유형 */
type ApprovalLineGroupItemsDraftType = Array<
  ApprovalLineGroupItemEmployeeType & { approval: boolean }
>;

/** 결재선 그룹 항목 배열 결재 유형 */
type ApprovalLineGroupItemsApprovalType = Array<
  | ApprovalLineGroupItemOrganizationType
  | (ApprovalLineGroupItemEmployeeType & {
      arbitraryDecision: boolean;
    })
>;

/** 결재선 그룹 항목 배열 합의 유형 */
type ApprovalLineGroupItemsAgreeType = Array<
  (
    | ApprovalLineGroupItemOrganizationType
    | ApprovalLineGroupItemEmployeeType
  ) & { option: boolean }
>;

/** 결재선 그룹 항목 배열 감사 유형 */
type ApprovalLineGroupItemsAuditType = Array<
  ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
>;

/** 결재선 그룹 항목 배열 수신 유형 */
type ApprovalLineGroupItemsReceiveType = Array<
  ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
>;

/** 결재 유형 */
type ApprovalType = 'draft' | 'agree' | 'receive' | 'approval' | 'audit';

/** 결재선 그룹 유형 */
type ApprovalLineGroupType =
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'draft';
      approval: boolean; // 기안자 결재 여부.
      items: Array<
        (
          | ApprovalLineGroupItemEmployeeType
          | ApprovalLineGroupItemOrganizationType
        ) & { approval: boolean }
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'approval';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<
        | ApprovalLineGroupItemOrganizationType
        | (ApprovalLineGroupItemEmployeeType & {
            arbitraryDecision: boolean;
          })
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'agree';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      parallel: boolean; // 병렬 여부
      items: Array<
        (
          | ApprovalLineGroupItemOrganizationType
          | ApprovalLineGroupItemEmployeeType
        ) & { option: boolean }
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'audit';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'receive';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      parallel: boolean; // 병렬 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    };

function createApprovalLineGroup(type: ApprovalType): ApprovalLineGroupType {
  const id = `${Date.now()}`;
  const modify = true;
  switch (type) {
    case 'agree':
      return { id, type, required: false, modify, parallel: false, items: [] };
    case 'receive':
      return { id, type, required: false, modify, parallel: false, items: [] };
    case 'approval':
      return { id, type, required: false, modify, items: [] };
    case 'audit':
      return { id, type, required: false, modify, items: [] };
    // draft
    default:
      return { id, type, approval: false, items: [] };
  }
}

/** 결재 라인 유형 */
export type ApprovalLineType = {
  version: '0.1';
  groups: ApprovalLineGroupType[];
};

/** 공유 권한 그룹 유형 */
type SharePermissionGroupType = {
  id: string;
  required: boolean; // 필수 여부
  modify: boolean; // 수정 여부
  items: Array<SharePermissionGroupItemType>;
};

/** 공유 권한 유형 (조회권 및 참조권) */
export type SharePermissionType = {
  version: '0.1';
  groups: SharePermissionGroupType[];
};

export type SharePermissionGroupItems = {
  id: string;
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: string;
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  macroId: number;
  macroName: string;
}[];

/** 공유 권한 그룹 배열 */
function SharePermissionGroups(props: {
  title: string;
  selectedId: string;
  groups: SharePermissionGroupType[];
  dragable?: boolean;
  onSortChange?(groups: SharePermissionGroupType[]): void;
  onSelect?(arg: { id: string; modify: boolean }): void;
  onRequiredChange?(arg: { id: string }): void;
  onModifyChange?(arg: { id: string }): void;
  onDelete?(arg: { id: string; event: React.MouseEvent }): void;
  onItemAppend?(): void;
  onItemSortChange?(arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }): void;
  onItemOptionChange?(arg: {
    groupId: string;
    id: string;
    code: string;
    checked: boolean;
  }): void;
  onItemDelete?(arg: { groupId: string; id: string }): void;
}) {
  const getLocalizedText = createLocalizedTextFactory('approval');

  const handleSortChange = (groups: SharePermissionGroupType[]) => {
    const { onSortChange } = props;
    if (onSortChange) onSortChange(groups);
  };

  const handleItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    const { onItemSortChange } = props;
    if (onItemSortChange) onItemSortChange(arg);
  };

  const {
    title,
    selectedId,
    groups,
    dragable = false,
    onRequiredChange,
    onModifyChange,
    onDelete,
    onItemAppend,
    onSelect,
    onItemOptionChange,
    onItemDelete,
  } = props;

  return (
    <ReactSortable
      className="line-root edit"
      list={groups}
      setList={handleSortChange}
      animation={200}
      handle=".group-drag"
    >
      {groups.map((a) => {
        // const { id, modify } = a;
        const { id } = a;
        const modify = true;
        return (
          <div
            key={a.id}
            className="line-group"
            onClick={onSelect ? () => onSelect({ id, modify }) : undefined}
            aria-selected={a.id === selectedId}
          >
            <div className="group-head">
              <div className="title">{title}</div>
              {onRequiredChange && (
                <Checkbox
                  label={getLocalizedText('필수')}
                  checked={a.required}
                  onChange={() => onRequiredChange({ id: a.id })}
                  className="group-option"
                />
              )}
              {onModifyChange && (
                <Checkbox
                  label={getLocalizedText('수정')}
                  checked={a.modify}
                  onChange={() =>
                    onModifyChange({
                      id: a.id,
                    })
                  }
                  className="group-option"
                />
              )}
              <div className="action">
                {onDelete && (
                  <Button
                    text={getLocalizedText('삭제')}
                    iconType
                    icon="trash-full"
                    onClick={(event) => onDelete({ id: a.id, event })}
                    color="secondary"
                    className="group-delete"
                  />
                )}
                {dragable && <div className="drag group-drag" />}
              </div>
            </div>
            <div className="group-body">
              <ReactSortable
                className="approval-line-group"
                list={a.items}
                setList={(newState, sortable, store) =>
                  handleItemSortChange({
                    groupId: a.id,
                    items: newState,
                    sortable,
                    store,
                  })
                }
                animation={200}
                handle={modify ? '.drag' : undefined}
              >
                {a.items.map((b) => {
                  // 직원인 경우
                  if (b.employeeId !== undefined) {
                    const text = getEmployeeName({
                      name: b.employeeName,
                      type: b.jobClassType,
                      jobPosition: b.jobPositionName,
                      jobDuty: b.jobDutyName,
                    });
                    const options = onItemOptionChange
                      ? getApprovalLineGroupItemOptions(b.jobClassType)
                      : undefined;
                    return (
                      <ApprovalLineGroupItem
                        key={b.id}
                        groupId={a.id}
                        id={b.id}
                        employeeText={text}
                        organizationName={b.organizationName}
                        avatar={getAvatarPath(b)}
                        onDelete={modify ? onItemDelete : undefined}
                        options={options}
                        onOptionChange={modify ? onItemOptionChange : undefined}
                        dragable={modify}
                      />
                    );
                  }
                  // 조직인 경우
                  return (
                    <ApprovalLineGroupItem
                      key={b.id}
                      groupId={a.id}
                      id={b.id}
                      organizationName={b.organizationName}
                      onDelete={modify ? onItemDelete : undefined}
                      dragable={modify}
                    />
                  );
                })}
              </ReactSortable>
              {onItemAppend && (
                <Button
                  className="add-approval-line-item"
                  text={getLocalizedText('편집')}
                  onClick={onItemAppend}
                  block
                />
              )}
            </div>
          </div>
        );
      })}
    </ReactSortable>
  );
}

/** 조회권 및 참조권 플랫 */
function SharePermissionFlat({
  items,
  onDeleteAll,
}: {
  items: { id: string; organizationName: string; employeeName?: string }[];
  onDeleteAll?(): void;
}): JSX.Element {
  const getLocalizedText = createLocalizedTextFactory('approval');
  return (
    <>
      <span className="eui-approval-line">
        {items.map(({ id, organizationName, employeeName }, i) => (
          <span key={id} className="item">
            {i === 0 ? '' : ', '}
            {employeeName || organizationName}
          </span>
        ))}
      </span>
      {onDeleteAll && (
        <Button
          className="delete"
          text={getLocalizedText('모두삭제')}
          iconType
          icon="trash-full"
          onClick={onDeleteAll}
          color="secondary"
          size="xs"
        />
      )}
    </>
  );
}

function approvalLineGroupClassName(type: string) {
  switch (type) {
    case 'draft':
      return 'drafter';
    case 'agree':
      return 'agree';
    case 'receive':
      return 'receiver';
    case 'approval':
      return 'approval';
    case 'audit':
      return 'auditor';
    default:
      return '';
  }
}

/**
 * 업무 결재선 플랫
 * @param props 프롭스
 * @returns 엘리먼트
 */
function WorkApprovalLineFlat(props: {
  approvalLine: ApprovalLineType;
}): JSX.Element {
  const { approvalLine } = props;
  const getLocalizedText = createLocalizedTextFactory('approval');
  return (
    <span className="eui-approval-line inline">
      {approvalLine.groups.map((g, i) => {
        const items = (g.items as ApprovalLineGroupItemType[]).map((b, j) => {
          const name =
            b.employeeId !== undefined ? b.employeeName : b.organizationName;
          const option = g.type === 'agree' && g.items[j].option;
          let arbitraryDecision = false;
          if (g.type === 'approval') {
            const item = g.items[j];
            if (item.employeeId !== undefined)
              arbitraryDecision = item.arbitraryDecision;
          }

          return (
            <span key={`${g.id}_${b.id}`}>
              {j === 0 ? '' : ', '}
              {name}
              {option && <em>{getLocalizedText('[선택]')}</em>}
              {arbitraryDecision && <em>{getLocalizedText('[전결]')}</em>}
            </span>
          );
        });

        const classname = ` ${approvalLineGroupClassName(g.type)}`;
        const required = g.type !== 'draft' && g.required;
        const parallel =
          (g.type === 'agree' || g.type === 'receive') &&
          g.items.length > 1 &&
          g.parallel;
        const approvalGroupName = getApprovalLineGroupNameFromType(g.type);
        return (
          <span key={g.id}>
            {i === 0 ? '' : <span className="arrow">▶</span>}
            <span className={`item sequential ${classname}`}>
              {getLocalizedText(`결재선그룹.${approvalGroupName}`)}
              {required ? <em>{getLocalizedText('[필수]')}</em> : ''}
              {parallel ? <em>{getLocalizedText('[병렬]')}</em> : ''}
              {items.length > 0 && (
                <span>{items.length > 0 && <span>({items})</span>}</span>
              )}
            </span>
          </span>
        );
      })}
    </span>
  );
}

/** 업무 결재선 지정 프롭스 */
type WorkApprovalLineDesignationProps = {
  approvalLine?: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  onCancel(): void;
  onSave(arg: {
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }): void;
};

/**
 * 업무 결재선 대화 상자 컨테이너
 * @param props WorkApprovalLineDesignationProps 업무 결재선 지정 프롭스
 * @returns JSX.Element
 */
function WorkApprovalLineDialogContainer(
  props: WorkApprovalLineDesignationProps,
): JSX.Element {
  const mobileChooseListRef = React.useRef<HTMLDivElement>(null);

  const display = useSelector((state: RootState) => state.session.display);

  const directory = useDirectory();
  const getLocalizedText = createLocalizedTextFactory('approval');

  const arbitraryDecisions = useSelector(
    (state: RootState) => state.approval2.arbitraryDecisions.arbitraryDecisions,
  );

  const approvalTypes = useSelector(
    (state: RootState) => state.approval2.preferences.approvalType,
  );

  const directoryTreeItems = useMemo(
    () => getDirectoryTreeItems({ ...directory }),
    [directory],
  );

  /** 조직 정보 찾기. */
  const getOrganizationData = ({
    companyId,
    organizationId,
  }: {
    companyId: number;
    organizationId: number;
  }) => {
    return {
      companyId,
      companyName: getCompanyName(companyId, ''),
      organizationId,
      organizationName: getOrganizationName(companyId, organizationId, ''),
    };
  };
  /** 결재선 생성 시 직원 정보 생성. */
  const createApprovalLineEmployeeData = (data: {
    companyId: number;
    organizationId?: number;
    employeeId: number;
  }) => {
    const { companyId, organizationId, employeeId } = data;
    const employeeData = getDirectoryData({
      ...directory,
      companyId,
      organizationId,
      employeeId,
    });
    return {
      companyId: employeeData.companyId,
      organizationId: employeeData.organizationId,
      employeeId: employeeData.employeeId,
      companyName: employeeData.companyName,
      organizationName: employeeData.organizationName,
      employeeName: employeeData.employeeName,
      jobPositionId: employeeData.jobPositionId,
      jobPositionName: employeeData.jobPositionName,
      jobClassType: employeeData.jobClassType,
      jobDutyId: employeeData.jobDutyId,
      jobDutyName: employeeData.jobDutyName,
      avatar: employeeData.avatar,
    };
  };

  /** 결재선 생성 시 조직 정보 생성. */
  const createApprovalLineOrganizationData = (
    companyId: number,
    organizationId: number,
  ) => {
    const organizationData = getOrganizationData({
      companyId,
      organizationId,
    });
    return {
      companyId: organizationData.companyId,
      organizationId: organizationData.organizationId,
      companyName: organizationData.companyName,
      organizationName: organizationData.organizationName,
    };
  };

  let propsApprovalLine = props.approvalLine;
  if (propsApprovalLine) {
    propsApprovalLine = {
      ...propsApprovalLine,
      groups: propsApprovalLine.groups.map((group) => {
        if (group.type === 'draft') {
          return {
            ...group,
          };
        }
        if (group.type === 'approval') {
          return {
            ...group,
            items: group.items
              .filter((a) =>
                directoryTreeItems.find(
                  (z) =>
                    z.id ===
                    (a.employeeId !== undefined
                      ? `${a.companyId}_${a.organizationId}_${a.employeeId}`
                      : `${a.companyId}_${a.organizationId}`),
                ),
              )
              .map((item) => {
                const { companyId, organizationId } = item;
                if (item.employeeId === undefined) {
                  return {
                    ...item,
                    ...createApprovalLineOrganizationData(
                      companyId,
                      organizationId,
                    ),
                  };
                }
                return {
                  ...item,
                  ...createApprovalLineEmployeeData({
                    companyId,
                    organizationId,
                    employeeId: item.employeeId,
                  }),
                };
              }),
          };
        }
        if (group.type === 'agree') {
          return {
            ...group,
            items: group.items
              .filter((a) =>
                directoryTreeItems.find(
                  (z) =>
                    z.id ===
                    (a.employeeId !== undefined
                      ? `${a.companyId}_${a.organizationId}_${a.employeeId}`
                      : `${a.companyId}_${a.organizationId}`),
                ),
              )
              .map((item) => {
                const { companyId, organizationId } = item;
                if (item.employeeId === undefined) {
                  return {
                    ...item,
                    ...createApprovalLineOrganizationData(
                      companyId,
                      organizationId,
                    ),
                  };
                }
                return {
                  ...item,
                  ...createApprovalLineEmployeeData({
                    companyId,
                    organizationId,
                    employeeId: item.employeeId,
                  }),
                };
              }),
          };
        }
        return {
          ...group,
          items: group.items
            .filter((a) =>
              directoryTreeItems.find(
                (z) =>
                  z.id ===
                  (a.employeeId !== undefined
                    ? `${a.companyId}_${a.organizationId}_${a.employeeId}`
                    : `${a.companyId}_${a.organizationId}`),
              ),
            )
            .map((item) => {
              const { companyId, organizationId } = item;
              if (item.employeeId === undefined) {
                return {
                  ...item,
                  ...createApprovalLineOrganizationData(
                    companyId,
                    organizationId,
                  ),
                };
              }
              return {
                ...item,
                ...createApprovalLineEmployeeData({
                  companyId,
                  organizationId,
                  employeeId: item.employeeId,
                }),
              };
            }),
        };
      }),
    };
  }
  const [state, setState] = useState<{
    validation: string;
    /** 선택 사용자 목록 표시 여부 (모바일 화면에서 사용) */
    chooseUserDialogVisible: boolean;
    chooseTabSelectedId: 'organizationChart' | 'approvalOfficer';
    directoryTreeFilter: string;
    directoryTreeSelectedId: string;
    approvalGroupSelectedId: string;
    approvalLineGroupSelectedId: string;
    referencePermissionGroupSelectedId: string;
    viewPermissionGroupSelectedId: string;
    approvalLine: ApprovalLineType;
    referencePermission: SharePermissionType;
    viewPermission: SharePermissionType;
  }>(() => {
    return {
      validation: '',
      display: '',
      chooseUserDialogVisible: false,
      chooseTabSelectedId: 'organizationChart',
      directoryTreeFilter: '',
      directoryTreeSelectedId: directoryTreeItems[0]?.id || '',
      approvalGroupSelectedId: '',
      approvalLineGroupSelectedId: '',
      referencePermissionGroupSelectedId: '',
      viewPermissionGroupSelectedId: '',
      approvalLine: propsApprovalLine || {
        version: '0.1',
        groups: [createApprovalLineGroup('draft')],
      },
      referencePermission: props.referencePermission || {
        version: '0.1',
        groups: [],
      },
      viewPermission: props.viewPermission || { version: '0.1', groups: [] },
    };
  });

  const [approvalOfficers, setApprovalOfficers] = useState<
    | {
        companyId: number;
        id: number;
        name: string;
        organizationId: number;
        employeeId: number;
        updateAt: string;
      }[]
    | undefined
  >(undefined);

  useEffect(() => {
    approverMacroApi.list(1, 100).then((response) => {
      if (response) setApprovalOfficers(response);
    });
  }, []);

  useEffect(() => {
    if (display === 'phone' && chooseUserDialogVisible) {
      mobileChooseListRef.current?.scrollTo(
        mobileChooseListRef.current?.scrollWidth,
        0,
      );
    }
  }, [state.approvalLine]);

  /** 선택 탭 항목 클릭 */
  const handleChooseTabItemClick = (
    id: 'organizationChart' | 'approvalOfficer',
  ) => {
    setState((prevState) => ({ ...prevState, chooseTabSelectedId: id }));
  };

  /** 디렉터리 트리 필터 */
  const handleDirectoryTreeFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const directoryTreeFilter = event.target.value;
    setState((prevState) => ({ ...prevState, directoryTreeFilter }));
  };

  /** 디렉터리 트리 아이템 클릭 */
  const handleDirectoryTreeItemClick = (arg: DirectoryTreeItemArg) => {
    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    const approvalLineGroupSelected =
      approvalGroupSelectedId === approvalLineGroupSelectedId;

    const { id: directoryTreeSelectedId, extra } = arg.item;
    const item =
      extra.type === 'employee'
        ? {
            id: approvalLineGroupSelected
              ? `${Date.now()}`
              : `${extra.companyId}_${extra.employeeId}`,
            companyId: extra.companyId,
            companyName: extra.companyName,
            organizationId: extra.organizationId,
            employeeId: extra.employeeId,
            employeeName: extra.employeeName,
            organizationName: extra.organizationName,
            jobClassType: extra.jobClassType,
            jobPositionId: extra.jobPositionId,
            jobPositionName: extra.jobPositionName,
            jobDutyId: extra.jobDutyId,
            jobDutyName: extra.jobDutyName,
            avatar: extra.avatar,
          }
        : {
            id: approvalLineGroupSelected
              ? `${Date.now()}`
              : `${extra.companyId}_${extra.organizationId}`,
            companyId: extra.companyId,
            companyName: extra.companyName,
            organizationId: extra.organizationId,
            organizationName: extra.organizationName,
          };

    // 결재선 그룹이 선택된 경우
    if (approvalLineGroupSelected) {
      const approvalLineGroupType = state.approvalLine.groups.find(
        (a) => a.id === approvalLineGroupSelectedId,
      )?.type;

      const arbitraryDecision =
        approvalLineGroupType === 'approval' && item.employeeId !== undefined
          ? arbitraryDecisions.find(
              (a) =>
                a.companyId === item.companyId &&
                a.organizationId === item.organizationId &&
                a.employeeId === item.employeeId,
            ) !== undefined
          : false;

      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          directoryTreeSelectedId,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === approvalLineGroupSelectedId) {
                switch (group.type) {
                  case 'draft':
                    return group;
                  case 'agree': {
                    return {
                      ...group,
                      items: [...group.items, { ...item, option: false }],
                    };
                  }
                  case 'approval': {
                    if (item.employeeId === undefined) {
                      return { ...group, items: [...group.items, item] };
                    }
                    return {
                      ...group,
                      items: [...group.items, { ...item, arbitraryDecision }],
                    };
                  }
                  default:
                    return { ...group, items: [...group.items, item] };
                }
              }
              return group;
            }),
          },
        };
      });
      //
    }
    // 참조권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      setState((prevState) => {
        const { referencePermission } = prevState;
        if (
          referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            item.employeeId !== undefined
              ? getLocalizedText('참조권에 존재하는 직원입니다.')
              : getLocalizedText('참조권에 존재하는 조직입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          directoryTreeSelectedId,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((a) => {
              if (a.id === referencePermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 조회권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      setState((prevState) => {
        const { viewPermission } = prevState;
        if (
          viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            item.employeeId !== undefined
              ? getLocalizedText('조회권에 존재하는 직원입니다.')
              : getLocalizedText('조회권에 존재하는 조직입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          directoryTreeSelectedId,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((a) => {
              if (a.id === viewPermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 선택된 그룹이 없는 경우
    else setState((prevState) => ({ ...prevState, directoryTreeSelectedId }));
  };

  /** 결재 담당자 항목 클릭 */
  const handleApprovalOfficerItemClick = (arg: {
    item: {
      companyId: number;
      id: number;
      name: string;
      organizationId: number;
      employeeId: number;
      updateAt: string;
    };
    event: React.MouseEvent;
  }) => {
    const { companyId, id: macroId, name: macroName, employeeId } = arg.item;
    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    if (
      approvalGroupSelectedId === '' ||
      (approvalGroupSelectedId !== approvalLineGroupSelectedId &&
        approvalGroupSelectedId !== referencePermissionGroupSelectedId &&
        approvalGroupSelectedId !== viewPermissionGroupSelectedId)
    ) {
      const validation = getLocalizedText('선택된 그룹이 없습니다.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const extra = directoryTreeItems.find(
      (a) =>
        a.extra.type === 'employee' &&
        a.extra.companyId === companyId &&
        a.extra.employeeId === employeeId,
    )?.extra;
    if (extra === undefined || extra.type !== 'employee') {
      const validation = getLocalizedText('지정된 담당자를 찾을 수 없습니다.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const item = {
      id:
        approvalGroupSelectedId === approvalLineGroupSelectedId
          ? `${Date.now()}`
          : `${companyId}_${macroId}`,
      companyId: extra.companyId,
      companyName: extra.companyName,
      organizationId: extra.organizationId,
      employeeId: extra.employeeId,
      employeeName: extra.employeeName,
      organizationName: extra.organizationName,
      jobClassType: extra.jobClassType,
      jobPositionId: extra.jobPositionId,
      jobPositionName: extra.jobPositionName,
      jobDutyId: extra.jobDutyId,
      jobDutyName: extra.jobDutyName,
      avatar: extra.avatar,
      macroId,
      macroName,
    };

    // 결재선 그룹이 선택된 경우
    if (approvalGroupSelectedId === approvalLineGroupSelectedId) {
      const approvalLineGroupType = state.approvalLine.groups.find(
        (a) => a.id === approvalLineGroupSelectedId,
      )?.type;

      const arbitraryDecision =
        approvalLineGroupType === 'approval' && item.employeeId !== undefined
          ? arbitraryDecisions.find(
              (a) =>
                a.companyId === item.companyId &&
                a.organizationId === item.organizationId &&
                a.employeeId === item.employeeId,
            ) !== undefined
          : false;

      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === approvalLineGroupSelectedId) {
                switch (group.type) {
                  case 'draft':
                    return group;
                  case 'agree': {
                    return {
                      ...group,
                      items: [...group.items, { ...item, option: false }],
                    };
                  }
                  case 'approval': {
                    if (item.employeeId === undefined) {
                      // return { ...group, items: [...group.items, item] };
                      return group;
                    }
                    return {
                      ...group,
                      items: [...group.items, { ...item, arbitraryDecision }],
                    };
                  }
                  default:
                    return { ...group, items: [...group.items, item] };
                }
              }
              return group;
            }),
          },
        };
      });
    }
    // 참조권 그룹이 선택된 경우
    if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      setState((prevState) => {
        const { referencePermission } = prevState;
        if (
          referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            getLocalizedText('참조권에 존재하는 담당자입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((a) => {
              if (a.id === referencePermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 조회권 그룹이 선택된 경우
    if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      setState((prevState) => {
        const { viewPermission } = prevState;
        if (
          viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            getLocalizedText('조회권에 존재하는 담당자입니다.');
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((a) => {
              if (a.id === viewPermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
  };

  /** 결재선 기안자 결재 여부(1인 결재) 변경. */
  const handleApprovalLineDrafterApprovalChange = () => {
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((group) => {
            if (group.type === 'draft') {
              const { approval = false } = group;
              return { ...group, approval: !approval };
            }
            return group;
          }),
        },
      };
    });
  };

  /** 결재선 그룹 정렬 변경 */
  const handleApprovalLineGroupSortChange = (
    groups: ApprovalLineGroupType[],
  ) => {
    const approvalLineGroups = state.approvalLine.groups;
    const prevGroups = approvalLineGroups.filter((g) => g.type !== 'draft');
    if (prevGroups.length === groups.length) {
      let sortChanged = false;
      for (let i = 0; i < prevGroups.length; i += 1) {
        if (prevGroups[i].id !== groups[i].id) {
          sortChanged = true;
          break;
        }
      }
      if (sortChanged) {
        const draftGroup = approvalLineGroups.find((g) => g.type === 'draft');
        if (draftGroup !== undefined) {
          const data = [draftGroup, ...groups];
          setState((prevState) => {
            const { approvalLine } = prevState;
            return {
              ...prevState,
              approvalLine: { ...approvalLine, groups: data },
            };
          });
        }
      }
    }
  };

  /** 결재선 그룹 추가 */
  const handleApprovalLineGroupAppend = (
    type: Exclude<ApprovalType, 'draft'>,
  ) => {
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: [...approvalLine.groups, createApprovalLineGroup(type)],
        },
      };
    });
  };

  /** 참조 권한 그룹 추가 */
  const handleReferencePermissionGroupAppend = () => {
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: [
            ...referencePermission.groups,
            {
              id: `${Date.now()}`,
              required: false, // 필수 여부
              modify: true, // 수정 여부
              items: [],
            },
          ],
        },
      };
    });
  };

  /** 조회 권한 그룹 추가 */
  const handleViewPermissionGroupAppend = () => {
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: [
            ...viewPermission.groups,
            {
              id: `${Date.now()}`,
              required: false, // 필수 여부
              modify: true, // 수정 여부
              items: [],
            },
          ],
        },
      };
    });
  };

  /** 결재선 그룹 선택 */
  const handleApprovalLineGroupSelect = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => ({
      ...prevState,
      approvalGroupSelectedId: id,
      approvalLineGroupSelectedId: id,
    }));
  };

  /** 결재선 그룹 필수 변경 (기안을 제외한 모두 적용) */
  const handleApprovalLineGroupRequiredChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLineGroupSelectedId: id,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((a) => {
            if (a.type !== 'draft' && a.id === id)
              return { ...a, required: !a.required };
            return a;
          }),
        },
      };
    });
  };

  /** 결재선 그룹 수정 변경 (기안을 제외한 모두 적용) */
  const handleApprovalLineGroupModifyChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLineGroupSelectedId: id,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((a) => {
            if (a.type !== 'draft' && a.id === id)
              return { ...a, modify: !a.modify };
            return a;
          }),
        },
      };
    });
  };

  /** 결재선 그룹 병렬 변경 (합의, 수신 그룹에서 사용) */
  const handleApprovalLineGroupParallelChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLineGroupSelectedId: id,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((a) => {
            if ((a.type === 'agree' || a.type === 'receive') && a.id === id)
              return { ...a, parallel: !a.parallel };
            return a;
          }),
        },
      };
    });
  };

  /** 결재선 그룹 삭제 (기안을 제외한 모두 적용) */
  const handleApprovalLineGroupDelete = (arg: {
    index: number;
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  }) => {
    const { index, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.filter((v, i) => i !== index),
        },
      };
    });
  };

  /** 결재선 그룹 항목 정렬 변경 */
  const handleApprovalLineGroupItemSortChange = (
    id: string,
    items: ApprovalLineGroupItemType[],
  ) => {
    const prevItems = state.approvalLine.groups.find((a) => a.id === id)?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    if (sortChanged)
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === id) {
                switch (group.type) {
                  case 'approval':
                    return {
                      ...group,
                      items: items as ApprovalLineGroupItemsApprovalType,
                    };
                  case 'agree':
                    return {
                      ...group,
                      parallel:
                        group.items.length === 1 ? false : group.parallel,
                      items: items as ApprovalLineGroupItemsAgreeType,
                    };
                  case 'receive':
                    return {
                      ...group,
                      parallel:
                        group.items.length === 1 ? false : group.parallel,
                      items: items as ApprovalLineGroupItemsReceiveType,
                    };
                  case 'audit':
                    return {
                      ...group,
                      items: items as ApprovalLineGroupItemsAuditType,
                    };
                  // draft
                  default:
                    return {
                      ...group,
                      items: items as ApprovalLineGroupItemsDraftType,
                    };
                }
              }
              return group;
            }),
          },
        };
      });
  };

  /** 결재선 그룹 항목 옵션 변경 */
  const handleApprovalLineGroupItemOptionChange = (arg: {
    groupId: string;
    id: string; // 그룹 아이템 아이디
    code: string;
    checked: boolean;
  }) => {
    const { groupId, id, code } = arg;
    if (
      code === 'jobposition' ||
      code === 'jobduty' ||
      code === 'jobposition+jobduty'
    ) {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'approval' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'agree' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'audit' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'receive' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'option') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'agree' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id)
                      return { ...item, option: !item.option };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'arbitrarydecision') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'approval' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return {
                        ...item,
                        arbitraryDecision: !item.arbitraryDecision,
                      };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    }
  };

  /** 결재선 그룹 항목 항목 삭제 */
  const handleApprovalLineGroupItemDelete = (arg: {
    groupId: string;
    id: string; // 그룹 아이템 아이디
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  }) => {
    const { groupId, id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      return {
        ...prevState,
        approvalLine: {
          ...prevState.approvalLine,
          groups: prevState.approvalLine.groups.map((a) => {
            if (a.id === groupId) {
              switch (a.type) {
                case 'agree':
                  return {
                    ...a,
                    parallel: a.items.length === 2 ? false : a.parallel,
                    items: a.items.filter((b) => b.id !== id),
                  };
                case 'approval':
                  return { ...a, items: a.items.filter((b) => b.id !== id) };
                case 'receive':
                  return {
                    ...a,
                    parallel: a.items.length === 2 ? false : a.parallel,
                    items: a.items.filter((b) => b.id !== id),
                  };
                case 'audit':
                  return { ...a, items: a.items.filter((b) => b.id !== id) };
                default:
                  return a;
              }
            }
            return a;
          }),
        },
      };
    });
  };

  /** 참조 권한 그룹 정렬 변경 */
  const handleReferencePermissionGroupSortChange = (
    groups: {
      id: string;
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    }[],
  ) => {
    const { groups: prevGroups } = state.referencePermission;
    let sortChanged = false;
    for (let i = 0; i < prevGroups.length; i += 1) {
      if (prevGroups[i].id !== groups[i].id) {
        sortChanged = true;
        break;
      }
    }
    if (sortChanged)
      setState((prevState) => {
        const { referencePermission } = prevState;
        return {
          ...prevState,
          referencePermission: { ...referencePermission, groups },
        };
      });
  };

  /** 참조 권한 그룹 선택 */
  const handleReferencePermissionGroupSelect = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => ({
      ...prevState,
      approvalGroupSelectedId: id,
      referencePermissionGroupSelectedId: id,
    }));
  };

  /** 참조 권한 그룹 필수 변경 */
  const handleReferencePermissionGroupRequiredChange = (arg: {
    id: string;
  }) => {
    const { id } = arg;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermissionGroupSelectedId: id,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((a) => {
            if (a.id === id) return { ...a, required: !a.required };
            return a;
          }),
        },
      };
    });
  };

  /** 참조 권한 그룹 수정 변경 */
  const handleReferencePermissionGroupModifyChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermissionGroupSelectedId: id,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((a) => {
            if (a.id === id) return { ...a, modify: !a.modify };
            return a;
          }),
        },
      };
    });
  };

  /** 참조 권한 그룹 삭제 */
  const handleReferencePermissionGroupDelete = (arg: {
    id: string;
    event: React.MouseEvent;
  }) => {
    const { id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const {
        approvalGroupSelectedId,
        referencePermissionGroupSelectedId,
        referencePermission,
      } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId:
          approvalGroupSelectedId === id ? '' : approvalGroupSelectedId,
        referencePermissionGroupSelectedId:
          referencePermissionGroupSelectedId === id
            ? ''
            : referencePermissionGroupSelectedId,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.filter((a) => a.id !== id),
        },
      };
    });
  };

  /** 참조 권한 그룹 항목 정렬 변경 */
  const handleReferencePermissionGroupItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    const { groupId, items } = arg;
    const prevItems = state.referencePermission.groups.find(
      ({ id }) => id === groupId,
    )?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    if (sortChanged)
      setState((prevState) => {
        const { referencePermission } = prevState;
        return {
          ...prevState,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((group) => {
              if (group.id === groupId) return { ...group, items };
              return group;
            }),
          },
        };
      });
  };

  /** 참조 권한 그룹 항목 삭제 */
  const handleReferencePermissionGroupItemDelete = (arg: {
    groupId: string;
    id: string;
  }) => {
    const { groupId, id } = arg;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((group) => {
            // if (group.id === groupId) return { ...group, items };
            if (group.id === groupId)
              return {
                ...group,
                items: group.items.filter((item) => item.id !== id),
              };
            return group;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 정렬 변경 */
  const handleViewPermissionGroupSortChange = (
    groups: SharePermissionGroupType[],
  ) => {
    const { groups: prevGroups } = state.viewPermission;
    let sortChanged = false;
    for (let i = 0; i < prevGroups.length; i += 1) {
      if (prevGroups[i].id !== groups[i].id) {
        sortChanged = true;
        break;
      }
    }
    if (sortChanged)
      setState((prevState) => {
        const { viewPermission } = prevState;
        return {
          ...prevState,
          viewPermission: { ...viewPermission, groups },
        };
      });
  };

  /** 조회 권한 그룹 선택 */
  const handleViewPermissionGroupSelect = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => ({
      ...prevState,
      approvalGroupSelectedId: id,
      viewPermissionGroupSelectedId: id,
    }));
  };

  /** 조회 권한 그룹 필수 변경 */
  const handleViewPermissionGroupRequiredChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId: id,
        viewPermissionGroupSelectedId: id,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((a) => {
            if (a.id === id) return { ...a, required: !a.required };
            return a;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 수정 변경 */
  const handleViewPermissionGroupModifyChange = (arg: { id: string }) => {
    const { id } = arg;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId: id,
        viewPermissionGroupSelectedId: id,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((a) => {
            if (a.id === id) return { ...a, modify: !a.modify };
            return a;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 삭제 */
  const handleViewPermissionGroupDelete = (arg: {
    id: string;
    event: React.MouseEvent;
  }) => {
    const { id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const {
        approvalGroupSelectedId,
        viewPermissionGroupSelectedId,
        viewPermission,
      } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId:
          approvalGroupSelectedId === id ? '' : approvalGroupSelectedId,
        viewPermissionGroupSelectedId:
          viewPermissionGroupSelectedId === id
            ? ''
            : viewPermissionGroupSelectedId,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.filter((a) => a.id !== id),
        },
      };
    });
  };

  /** 조회 권한 그룹 항목 정렬 변경 */
  const handleViewPermissionGroupItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    const { groupId, items } = arg;
    const prevItems = state.viewPermission.groups.find(
      ({ id }) => id === groupId,
    )?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    if (sortChanged)
      setState((prevState) => {
        const { viewPermission } = prevState;
        return {
          ...prevState,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((group) => {
              if (group.id === groupId) return { ...group, items };
              return group;
            }),
          },
        };
      });
  };

  /** 조회 권한 그룹 항목 삭제 */
  const handleViewPermissionGroupItemDelete = (arg: {
    groupId: string;
    id: string;
  }) => {
    const { groupId, id } = arg;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((group) => {
            if (group.id === groupId)
              return {
                ...group,
                items: group.items.filter((item) => item.id !== id),
              };
            return group;
          }),
        },
      };
    });
  };

  /** 선택 사용자 목록 대화 상자 열기 */
  const handleChooseUserDialogOpen = () => {
    setState((prevState) => ({ ...prevState, chooseUserDialogVisible: true }));
  };

  /** 선택 사용자 목록 대화 상자 닫기 */
  const handleChooseUserDialogClose = () => {
    setState((prevState) => ({ ...prevState, chooseUserDialogVisible: false }));
  };

  /** 결재선 그룹 항목 모두 삭제 */
  const handleApprovalLineGroupItemDeleteAll = () => {
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((group) => {
            if (group.type === 'draft') return group;
            return { ...group, items: [] };
          }),
        },
      };
    });
  };

  /** 참조 권한 그룹 항목 모두 삭제 */
  const handleReferencePermissionGroupItemDeleteAll = () => {
    setState((prevState) => ({
      ...prevState,
      referencePermission: { ...prevState.referencePermission, groups: [] },
    }));
  };

  /** 조회 권한 그룹 항목 모두 삭제 */
  const handleViewPermissionGroupItemDeleteAll = () => {
    setState((prevState) => ({
      ...prevState,
      viewPermission: { ...prevState.viewPermission, groups: [] },
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /**
   * 병렬 그룹의 중복 항목 유효성 검사.
   * @param approvalLine 결재선
   * @returns 결재선 병렬 그룹의 중복 항목이 없는 경우 true, 있는 경우 false
   */
  const validateDuplicateItemsInParallelGroups = (
    approvalLine: ApprovalLineType | undefined,
  ) => {
    if (approvalLine === undefined) return true;

    // 병렬 구성 중인 그룹에서 중복 항목이 있는 그룹 찾기.
    const group = approvalLine.groups.find((a) => {
      if ((a.type === 'agree' || a.type === 'receive') && a.parallel) {
        const organizationIds = a.items
          .filter((b) => b.employeeId === undefined)
          .map(({ organizationId }) => organizationId);
        const employeeIds = a.items
          .filter((b) => b.employeeId !== undefined)
          .map(({ employeeId }) => employeeId);

        return (
          organizationIds.length !==
            Array.from(new Set(organizationIds)).length ||
          employeeIds.length !== Array.from(new Set(employeeIds)).length
        );
      }
      return false;
    });
    if (group !== undefined) {
      const approvalGroupSelectedId = group.id;
      const approvalGroupName = getApprovalLineGroupNameFromType(group.type);
      const validation = `${getLocalizedText(
        `결재선그룹.${approvalGroupName}`,
      )} ${getLocalizedText(
        '그룹 병렬 구성 시 직원 또는 조직을 중복 설정할 수 없습니다.',
      )}`;

      setState((prevState) => ({
        ...prevState,
        approvalGroupSelectedId,
        approvalLineGroupSelectedId: approvalGroupSelectedId,
        validation,
      }));
      return false;
    }
    return true;
  };

  /** 저장 */
  const handleSave = () => {
    const { approvalLine, referencePermission, viewPermission } = state;

    let group: ApprovalLineGroupType | SharePermissionGroupType | undefined;
    group = approvalLine.groups.find((a) => a.type !== 'draft');
    if (group === undefined) {
      const validation = `${getLocalizedText('결재 그룹이 없습니다.')}`;
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (!validateDuplicateItemsInParallelGroups(approvalLine)) return;

    group = approvalLine.groups.find(
      (a) => a.type !== 'draft' && !a.modify && a.items.length === 0,
    );
    if (group !== undefined) {
      const approvalGroupSelectedId = group.id;
      const approvalLineGroupSelectedId = group.id;
      const validation = getLocalizedText(
        '고정 그룹은 직원 및 조직을 설정하여야 합니다.',
      );
      setState((prevState) => ({
        ...prevState,
        approvalGroupSelectedId,
        approvalLineGroupSelectedId,
        validation,
      }));
      return;
    }
    if (referencePermission.groups.length > 0) {
      group = referencePermission.groups.find(
        (a) => !a.modify && a.items.length === 0,
      );
      if (group !== undefined) {
        const approvalGroupSelectedId = group.id;
        const referencePermissionGroupSelectedId = group.id;
        const validation = getLocalizedText(
          '고정 그룹은 직원 및 조직을 설정하여야 합니다.',
        );
        setState((prevState) => ({
          ...prevState,
          approvalGroupSelectedId,
          referencePermissionGroupSelectedId,
          validation,
        }));
        return;
      }
    }
    if (viewPermission.groups.length > 0) {
      group = viewPermission.groups.find(
        (a) => !a.modify && a.items.length === 0,
      );
      if (group !== undefined) {
        const approvalGroupSelectedId = group.id;
        const viewPermissionGroupSelectedId = group.id;
        const validation = getLocalizedText(
          '고정 그룹은 직원 및 조직을 설정하여야 합니다.',
        );
        setState((prevState) => ({
          ...prevState,
          approvalGroupSelectedId,
          viewPermissionGroupSelectedId,
          validation,
        }));
        return;
      }
    }

    const param = {
      approvalLine,
      referencePermission:
        referencePermission.groups.length > 0 ? referencePermission : undefined,
      viewPermission:
        viewPermission.groups.length > 0 ? viewPermission : undefined,
    };
    props.onSave(param);
  };

  /** 선택 사용자 목록 렌더링 (모바일 화면 크기에서 사용) */
  const renderChooseUserList = () => {
    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    // 결재선 그룹이 선택된 경우
    if (approvalGroupSelectedId === approvalLineGroupSelectedId) {
      const { approvalLine } = state;
      return (
        <>
          {approvalLine.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    // 참조권 그룹이 선택된 경우
    if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      const { referencePermission } = state;
      return (
        <>
          {referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    // 조회권 그룹이 선택된 경우
    if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      const { viewPermission } = state;
      return (
        <>
          {viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    return null;
  };

  /** 선택 탭 렌더링 */
  const renderChooseTab = () => {
    const { chooseTabSelectedId } = state;

    let content = null;
    if (chooseTabSelectedId === 'organizationChart') {
      content = (
        <>
          <SimpleSearch
            keyword={state.directoryTreeFilter}
            onSearch={handleDirectoryTreeFilter}
          />
          <div className="tree-area">
            <DirectoryTree
              selectedId={state.directoryTreeSelectedId}
              items={directoryTreeItems}
              filter={state.directoryTreeFilter}
              onItemClick={handleDirectoryTreeItemClick}
            />
          </div>
        </>
      );
    } else if (chooseTabSelectedId === 'approvalOfficer') {
      // TODO 로딩중 퍼블리싱 작업 필요.
      content = (
        <div className="area-content manager-area">
          {approvalOfficers === undefined ? (
            <Loading />
          ) : (
            approvalOfficers.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  label={item.name}
                  icon="user-tie"
                  onClick={(event) =>
                    handleApprovalOfficerItemClick({ item, event })
                  }
                />
              );
            })
          )}
        </div>
      );
    }

    return (
      <>
        <Tab>
          <Tab.Item
            label={getLocalizedText('조직도')}
            selected={chooseTabSelectedId === 'organizationChart'}
            onClick={() => handleChooseTabItemClick('organizationChart')}
          />
          <Tab.Item
            label={getLocalizedText('결재담당자')}
            selected={chooseTabSelectedId === 'approvalOfficer'}
            onClick={() => handleChooseTabItemClick('approvalOfficer')}
          />
        </Tab>
        {content}
      </>
    );
  };

  /** 결재선 지정 렌더링 */
  const renderApprovalLineDesignation = () => {
    const {
      approvalGroupSelectedId,
      approvalLine,
      referencePermission,
      viewPermission,
    } = state;

    const draftApproval =
      approvalLine.groups.find(
        (group) => group.type === 'draft' && group.approval === true,
      ) !== undefined;

    const content = (
      <>
        <div className={`line-group ${approvalLineGroupClassName('draft')}`}>
          <div className="group-head">
            <div className="title">{getLocalizedText(`결재선그룹.기안`)}</div>
            <Checkbox
              label={getLocalizedText('1인결재')}
              checked={draftApproval}
              onChange={handleApprovalLineDrafterApprovalChange}
              className="group-option"
            />
          </div>
          <div className="group-body" />
        </div>
        <ReactSortable
          className="line-root edit"
          list={approvalLine.groups.filter((a) => a.type !== 'draft')}
          setList={handleApprovalLineGroupSortChange}
          animation={200}
          handle=".group-drag"
        >
          {approvalLine.groups
            .filter((a) => a.type !== 'draft')
            .map((a, i) => {
              const approvalGroupName = getApprovalLineGroupNameFromType(
                a.type,
              );
              return (
                <div
                  key={a.id}
                  className={`line-group ${approvalLineGroupClassName(a.type)}`}
                  onClick={() => handleApprovalLineGroupSelect({ id: a.id })}
                  aria-selected={a.id === approvalGroupSelectedId}
                >
                  <div className="group-head">
                    <div className="title">
                      {getLocalizedText(`결재선그룹.${approvalGroupName}`)}
                    </div>
                    <Checkbox
                      label={getLocalizedText('필수')}
                      checked={a.required}
                      onChange={() =>
                        handleApprovalLineGroupRequiredChange({ id: a.id })
                      }
                      className="group-option"
                    />
                    <Checkbox
                      label={getLocalizedText('수정')}
                      checked={a.modify}
                      onChange={() =>
                        handleApprovalLineGroupModifyChange({ id: a.id })
                      }
                      className="group-option"
                    />
                    {(a.type === 'agree' || a.type === 'receive') &&
                      a.items.length > 1 && (
                        <Checkbox
                          label={getLocalizedText('병렬')}
                          checked={a.parallel}
                          onChange={() =>
                            handleApprovalLineGroupParallelChange({ id: a.id })
                          }
                          className="group-option"
                        />
                      )}
                    <div className="action">
                      <Button
                        text={getLocalizedText('삭제')}
                        iconType
                        icon="trash-full"
                        onClick={(event) =>
                          handleApprovalLineGroupDelete({
                            index: i + 1,
                            event,
                          })
                        }
                        color="secondary"
                        className="group-delete"
                      />
                      <div className="drag group-drag" />
                    </div>
                  </div>
                  <div className="group-body">
                    <ReactSortable
                      className="approval-line-group"
                      list={a.items}
                      setList={(newState) =>
                        handleApprovalLineGroupItemSortChange(a.id, newState)
                      }
                      animation={200}
                      handle=".drag"
                      group="approval-line-group"
                    >
                      {(a.items as ApprovalLineGroupItemType[]).map((b) => {
                        const defaultItemValue = {
                          option: false,
                          arbitraryDecision: false,
                        };
                        const { option, arbitraryDecision } = {
                          ...defaultItemValue,
                          ...b,
                        };

                        // 직원인 경우
                        if (b.employeeId !== undefined) {
                          const text = getEmployeeName({
                            name: b.employeeName,
                            type: b.jobClassType,
                            jobPosition: b.jobPositionName,
                            jobDuty: b.jobDutyName,
                          });
                          const jobClassOptions =
                            getApprovalLineGroupItemOptions(b.jobClassType);

                          const agreeOptions =
                            a.type === 'agree'
                              ? getApprovalLineGroupItemAgreeOptions(option)
                              : [];

                          const approvalOptions =
                            a.type === 'approval'
                              ? getApprovalLineGroupItemApprovalOptions(
                                  arbitraryDecision,
                                )
                              : [];

                          const options = [
                            ...jobClassOptions,
                            ...agreeOptions,
                            ...approvalOptions,
                          ];

                          return (
                            <ApprovalLineGroupItem
                              key={b.id}
                              groupId={a.id}
                              id={b.id}
                              employeeText={text}
                              organizationName={b.organizationName}
                              avatar={getAvatarPath(b)}
                              macroName={b.macroName}
                              onDelete={handleApprovalLineGroupItemDelete}
                              options={options}
                              onOptionChange={
                                handleApprovalLineGroupItemOptionChange
                              }
                            />
                          );
                        }

                        const options =
                          a.type === 'agree'
                            ? getApprovalLineGroupItemAgreeOptions(
                                option,
                                false,
                              )
                            : [];

                        // 조직인 경우
                        return (
                          <ApprovalLineGroupItem
                            key={b.id}
                            groupId={a.id}
                            id={b.id}
                            organizationName={b.organizationName}
                            onDelete={handleApprovalLineGroupItemDelete}
                            options={options}
                            onOptionChange={
                              handleApprovalLineGroupItemOptionChange
                            }
                          />
                        );
                      })}
                    </ReactSortable>
                    {display === 'phone' && (
                      <Button
                        className="add-approval-line-item"
                        text={getLocalizedText('편집')}
                        onClick={handleChooseUserDialogOpen}
                        block
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </ReactSortable>
        <SharePermissionGroups
          title={getLocalizedText('참조')}
          selectedId={approvalGroupSelectedId}
          groups={referencePermission.groups}
          onSortChange={handleReferencePermissionGroupSortChange}
          onSelect={handleReferencePermissionGroupSelect}
          onRequiredChange={handleReferencePermissionGroupRequiredChange}
          onModifyChange={handleReferencePermissionGroupModifyChange}
          onDelete={handleReferencePermissionGroupDelete}
          onItemAppend={
            display === 'phone' ? handleChooseUserDialogOpen : undefined
          }
          onItemSortChange={handleReferencePermissionGroupItemSortChange}
          onItemDelete={handleReferencePermissionGroupItemDelete}
          dragable
        />
        <SharePermissionGroups
          title={getLocalizedText('조회')}
          selectedId={approvalGroupSelectedId}
          groups={viewPermission.groups}
          onSortChange={handleViewPermissionGroupSortChange}
          onSelect={handleViewPermissionGroupSelect}
          onRequiredChange={handleViewPermissionGroupRequiredChange}
          onModifyChange={handleViewPermissionGroupModifyChange}
          onDelete={handleViewPermissionGroupDelete}
          onItemAppend={
            display === 'phone' ? handleChooseUserDialogOpen : undefined
          }
          onItemSortChange={handleViewPermissionGroupItemSortChange}
          onItemDelete={handleViewPermissionGroupItemDelete}
          dragable
        />
        <dl className="add-line">
          <dt>{getLocalizedText('결재유형 추가')}</dt>
          <dd>
            {approvalTypes.find((a) => a === 'APPROVAL') && (
              <button
                type="button"
                onClick={() => handleApprovalLineGroupAppend('approval')}
                className="approval"
              >
                <Icon icon="check" />
                <span>{getLocalizedText('결재')}</span>
              </button>
            )}
            {approvalTypes.find((a) => a === 'AGREEMENT') && (
              <button
                type="button"
                onClick={() => handleApprovalLineGroupAppend('agree')}
                className="agree"
              >
                <Icon icon="equals" />
                <span>{getLocalizedText('합의')}</span>
              </button>
            )}
            {approvalTypes.find((a) => a === 'AUDIT') && (
              <button
                type="button"
                onClick={() => handleApprovalLineGroupAppend('audit')}
                className="auditor"
              >
                <Icon icon="user-tie" />
                <span>{getLocalizedText('감사')}</span>
              </button>
            )}
            {approvalTypes.find((a) => a === 'RECIPIENT') && (
              <button
                type="button"
                onClick={() => handleApprovalLineGroupAppend('receive')}
                className="receiver"
              >
                <Icon icon="flag-fill" />
                <span>{getLocalizedText('수신')}</span>
              </button>
            )}
            <button
              type="button"
              onClick={handleReferencePermissionGroupAppend}
              className="referrer"
            >
              <Icon icon="search" />
              <span>{getLocalizedText('참조')}</span>
            </button>
            <br />
            <button
              type="button"
              onClick={handleViewPermissionGroupAppend}
              className="viewer"
            >
              <Icon icon="eye" />
              <span>{getLocalizedText('조회')}</span>
            </button>
          </dd>
        </dl>
      </>
    );

    return <div className="approval-line">{content}</div>;
  };

  /** 결재 프로세스 렌더링 */
  const renderApprovalProcess = () => {
    const { approvalLine, referencePermission, viewPermission } = state;

    const approvalLineGroupItems = approvalLine.groups
      .map(({ items }) => {
        if (items.length === 0) return [];
        return items;
      })
      .flat();

    const referencePermissionItems = referencePermission.groups
      .map(({ items }) => {
        if (items.length === 0) return [];
        return items;
      })
      .flat();

    const viewPermissionItems = viewPermission.groups
      .map(({ items }) => {
        if (items.length === 0) return [];
        return items;
      })
      .flat();

    return (
      <div className="approval-process">
        <dl>
          <dt>{getLocalizedText('결재선')}:</dt>
          <dd>
            <WorkApprovalLineFlat approvalLine={approvalLine} />
            {approvalLineGroupItems.length > 0 && (
              <Button
                className="delete"
                text={getLocalizedText('모두삭제')}
                iconType
                icon="trash-full"
                onClick={handleApprovalLineGroupItemDeleteAll}
                color="secondary"
                size="xs"
              />
            )}
          </dd>
        </dl>
        <dl>
          <dt>{getLocalizedText('참조권')}</dt>
          <dd>
            <SharePermissionFlat
              items={referencePermissionItems}
              onDeleteAll={
                referencePermissionItems.length > 0
                  ? handleReferencePermissionGroupItemDeleteAll
                  : undefined
              }
            />
          </dd>
        </dl>
        <dl>
          <dt>{getLocalizedText('조회권')}</dt>
          <dd>
            <SharePermissionFlat
              items={viewPermissionItems}
              onDeleteAll={
                viewPermissionItems.length > 0
                  ? handleViewPermissionGroupItemDeleteAll
                  : undefined
              }
            />
          </dd>
        </dl>
      </div>
    );
  };

  const { onCancel } = props;
  const { chooseUserDialogVisible, validation } = state;
  return (
    <>
      <Dialog className="ui-approval-line-selection-dialog">
        <div
          className={`ui-approval-line-selection ${
            display === 'phone' && chooseUserDialogVisible ? 'fixed' : ''
          }`}
        >
          <div className="selection-head">
            <div className="title">{getLocalizedText('결재선 지정')}</div>
          </div>
          <div className="selection-body">
            <div className="selector-area">
              {(display !== 'phone' || chooseUserDialogVisible) && (
                <div className="select-panel">
                  <div className="choose-area">
                    {display === 'phone' && chooseUserDialogVisible && (
                      <div className="mobile-choose-user">
                        <div className="user-list" ref={mobileChooseListRef}>
                          {renderChooseUserList()}
                        </div>
                        <Button
                          text={getLocalizedText('선택완료')}
                          variant="contained"
                          onClick={handleChooseUserDialogClose}
                          className="action-close"
                          block
                        />
                      </div>
                    )}
                    {renderChooseTab()}
                  </div>
                </div>
              )}
              <div className="selected-panel">
                {renderApprovalLineDesignation()}
              </div>
            </div>
            <div className="result-area">{renderApprovalProcess()}</div>
          </div>
          <div className="selection-footer">
            <Button text={getLocalizedText('취소')} onClick={onCancel} />
            <Button
              text={getLocalizedText('저장')}
              variant="contained"
              onClick={handleSave}
            />
          </div>
        </div>
      </Dialog>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export { SharePermissionFlat, WorkApprovalLineFlat };

export default WorkApprovalLineDialogContainer;
