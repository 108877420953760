import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Chip from '../../../../../components/chip/Chip';
import Menu from '../../../../../components/menu/Menu';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import PostSubject from '../../../../../components/post/PostSubject';
import UserInfo from '../../../../../components/user/UserInfo';
import ApprovalLineFlat from '../../../../../groupware-approval/pages/common/components/ApprovalLineFlat';
import { ApprovalLineType } from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { Column } from '../../../../../groupware-common/ui/type';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';

type Subject = {
  value: string;
  security: boolean;
  attachments: boolean;
  urgent: boolean;
  chip?: {
    label: string;
    theme: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  };
};

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

function statusLabel(type: string) {
  switch (type) {
    case 'approval':
      return '결재';
    case 'progress':
      return '진행';
    case 'complete':
      return '완료';
    default:
      return '';
  }
}

function statusTheme(type: string) {
  switch (type) {
    case 'approval':
      return undefined;
    case 'progress':
      return 'primary';
    case 'complete':
      return 'success';
    default:
      return undefined;
  }
}
/**
 * @property id 아이디.
 * @property columns 컬럼 배열.
 * @property item 항목.
 * @property onCheckedChange 체크 변경 이벤트.
 * @property onClick 클릭 이벤트.
 */
type Props = {
  id?: number;
  selected?: boolean;
  columns?: Column<PostListItemType>[];
  item?: Record<string, Subject | User | boolean | string | undefined>;
  approvalLine: ApprovalLineType;
  onCheckedChange?(id: number, checked: boolean): void;
  onClick?(status: string, id: number): void;
};

function SecuritiesAuthorityBodyListItem(dumy: Props): JSX.Element {
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || 0,
    selected: dumy.selected || false,
    columns:
      dumy.columns ||
      ([
        { name: 'checked', text: '', type: 'action' },
        { name: 'work', text: '업무', type: 'form' },
        { name: 'documentNo', text: '문서번호', type: 'document' },
        { name: 'subject', text: '제목', type: 'post' },
        { name: 'drafter', text: '기안자', type: 'user' },
        { name: 'draftAt', text: '기안일', type: 'date' },
        { name: 'status', text: '상태', type: 'status' },
      ] as Column<PostListItemType>[]),
    item:
      dumy.item ||
      ({
        checked: false,
        work: '업무',
        documentNo: '부서-2021-00001',
        subject: {
          value: '제목',
          security: true,
          attachments: true,
          urgent: true,
        },
        drafter: {
          name: '홍길동',
          organization: '조직',
          job: '직위/직책',
          avatar: '/images/faces/faces_31.jpg',
        },
        draftAt: '2021-01-01',
        status: '',
      } as Record<string, Subject | User | boolean | string | undefined>),
    approvalLine: dumy.approvalLine || [],
    onCheckedChange: dumy.onCheckedChange || emptyFunction,
    onClick: dumy.onClick || emptyFunction,
  };

  /*
  TODO 결재라인 및 댓글 화면 구성해야 함.
  const [state, setState] = useState({
    approvalLineVisible: false,
    approvalCommentVisible: false,
  });
  */

  const handleCheckedChange = () => {
    const { id, item, onCheckedChange } = props;
    onCheckedChange(id, !item.checked);
  };

  const handleClick = () => {
    const { id, item, onClick } = props;
    if (timerRef.current) clearTimeout(timerRef.current);
    onClick(item.status as string, id);
  };

  const { selected, columns, item, approvalLine } = props;

  const [state, setState] = useState<{
    visible: boolean;
    point: { x: number; y: number; width: number; height: number };
  }>({
    visible: false,
    point: { x: 0, y: 0, width: 0, height: 0 },
  });

  const handleOpenApprovalLine = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    if (isDesktop) {
      const rect = event.currentTarget.getBoundingClientRect();

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = window.setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          point: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          visible: true,
        }));
      }, 500);
    }
  };

  const handleCloseApprovalLine = () => {
    if (isDesktop) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setState((prevState) => ({
        ...prevState,
        visible: false,
      }));
    }
  };

  return (
    <>
      <PostList.Item selected={selected} onClick={handleClick}>
        {columns.map(({ name, type }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;
          if (name === 'checked' && typeof value === 'boolean') {
            children = (
              <Checkbox checked={value} onChange={handleCheckedChange} />
            );
          } else if (type === 'post') {
            const subject = value as Subject;
            children = (
              <PostSubject
                title={subject.value}
                security={subject.security}
                attachments={subject.attachments}
                urgent={subject.urgent}
                onMouseEnter={handleOpenApprovalLine}
                onMouseLeave={handleCloseApprovalLine}
                chip={subject.chip}
              />
            );
          } else if (type === 'user') {
            const { avatar, name: username, organization } = value as User;
            children = (
              <UserInfo
                avatar={username === '' ? undefined : avatar}
                name={username === '' ? organization : username}
                from={username === '' ? undefined : organization}
                icon={username === '' ? 'sitemap-fill' : undefined}
              />
            );
          } else if (type === 'status') {
            const status = value as string;
            const data = statusLabel(status);
            const theme = statusTheme(status);
            children = (
              <Chip className="cell-tag" label={data} theme={theme} size="xs" />
            );
          } else if (typeof value === 'string') {
            if (type === 'date') {
              children = (
                <PostList.Value value={dateSimplify(value, 'dateNumber')} />
              );
            } else {
              children = <PostList.Value value={value} />;
            }
          }

          const style =
            name !== 'subject' ? { justifyContent: 'center' } : undefined;
          return (
            <PostList.Cell key={name} type={type} style={style}>
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
      {state.visible && (
        <Menu
          point={state.point}
          onClose={handleCloseApprovalLine}
          className="ui-approval-line-tooltip"
        >
          <ApprovalLineFlat approvalLine={approvalLine} />
        </Menu>
      )}
    </>
  );
}

export default SecuritiesAuthorityBodyListItem;
