import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import Menu from '../../../../../components/menu/Menu';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import MenuGroup from '../../../../../components/menu/MenuGroup';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import TextField from '../../../../../components/textfield/TextField';
import {
  b62,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { calendarsActions } from '../../../../stores/calendar/calendars';
import { schedulesActions } from '../../../../stores/calendar/schedules';
import CalendarItem from './CalendarItem';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function CalendarDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();
  const { menu } = getPathParams('/*/:menu', pathname);
  const queryParams = getQueryParams(search);

  const colorList = useSelector(
    (state: RootState) => state.calendar.userPreferences.color,
  );
  const display = useSelector((state: RootState) => state.session.display);
  const myCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.list,
  );
  const sharedCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.sharedList,
  );
  const subCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.subList,
  );
  const categories = useSelector(
    (state: RootState) => state.calendar.calendars.category,
  ).filter((a) => a.type === 'default');

  const [state, setState] = useState(() => {
    const myCalName = '';
    let mySettingMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    let sharedSettingMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    let subSettingMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    let addMyCalMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;

    return {
      myCalName,
      mySettingMenuPoint,
      sharedSettingMenuPoint,
      subSettingMenuPoint,
      addMyCalMenuPoint,
      itemId: 0,
      selectedDate: null as Date | null,
    };
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      selectedDate: timezoneDate(queryParams.startDate),
    }));
  }, [queryParams.startDate]);

  /** 날짜 이동 이벤트. - 캘린더 페이지가 아닌 경우 적용 안됨. */
  const handleChangeDate = (date: Date) => {
    if (menu || queryParams.contentMode) return;
    queryParams.startDate = dateFormat(initialDate(date), 'yyyy-MM-DD');
    go(pathname, queryParams, hash);
    dispatch(sessionActions.mobileNav(false));
  };

  const handleChangeMonth = () => {
    setState((prev) => ({ ...prev, selectedDate: null }));
  };

  // 캘린더 보기여부 변경.
  const handleCalendarLabelClick = (arg: {
    type: 'mine' | 'shared' | 'sub';
    id: number;
    isVisible: boolean;
  }) => {
    const { type, id, isVisible } = arg;
    dispatch(
      calendarsActions.changeChecked({
        type,
        id,
        isVisible,
        noLoading: true,
      }),
    );
  };

  const handleAddMyCalMenuToggle = (event?: React.MouseEvent) => {
    const { addMyCalMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        addMyCalMenuPoint: { x, y, width, height },
        myCalName: '',
      }));
    } else {
      setState((prev) => ({
        ...prev,
        addMyCalMenuPoint: undefined,
        myCalName: '',
      }));
    }
  };

  const handleMySettingMenuToggle = (event?: React.MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    const { mySettingMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        mySettingMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        mySettingMenuPoint: undefined,
        itemId: 0,
      }));
    }
  };

  const handleSharedSettingMenuToggle = (event?: React.MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    const { sharedSettingMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        sharedSettingMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        sharedSettingMenuPoint: undefined,
        itemId: 0,
      }));
    }
  };

  const handleSubSettingMenuToggle = (event?: React.MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    const { subSettingMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        subSettingMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        subSettingMenuPoint: undefined,
        itemId: 0,
      }));
    }
  };

  const handleMySettingClick = (itemId: number, event: React.MouseEvent) => {
    handleMySettingMenuToggle(event);
    setState((prev) => ({ ...prev, itemId }));
  };

  const handleSharedSettingClick = (
    itemId: number,
    event: React.MouseEvent,
  ) => {
    handleSharedSettingMenuToggle(event);
    setState((prev) => ({ ...prev, itemId }));
  };

  const handleSubSettingClick = (itemId: number, event: React.MouseEvent) => {
    handleSubSettingMenuToggle(event);
    setState((prev) => ({ ...prev, itemId }));
  };

  /** 캘린더 색상 변경 이벤트. */
  const handleChangeColor = (arg: {
    type: 'mine' | 'shared' | 'sub';
    color: string;
  }) => {
    const { type, color } = arg;
    dispatch(
      calendarsActions.changeColor({
        type,
        id: state.itemId,
        color,
        noLoading: true,
      }),
    );
    if (type === 'mine') handleMySettingMenuToggle();
    else if (type === 'shared') handleSharedSettingMenuToggle();
    else if (type === 'sub') handleSubSettingMenuToggle();
  };

  /** 작성자명 표시 변경 이벤트 */
  const handleChangeUseExposeCreator = (arg: {
    type: 'mine' | 'shared';
    id: number;
    useExposeCreator: boolean;
  }) => {
    const { type, id, useExposeCreator } = arg;
    dispatch(
      calendarsActions.changeUseExposeCreator({
        type,
        id,
        useExposeCreator,
        noLoading: true,
      }),
    );
    if (type === 'mine') handleMySettingMenuToggle();
    else if (type === 'shared') handleSharedSettingMenuToggle();
    else if (type === 'sub') handleSubSettingMenuToggle();
  };

  /** 내 캘린더 관리. */
  const handleMySettingCalGo = (event: React.MouseEvent) => {
    if (state.itemId === 0) return;
    handleMySettingMenuToggle(event);
    go(`/calendar/preferences/${b62(state.itemId)}`, '', '#myCalendar');
    dispatch(sessionActions.mobileNav(false));
  };

  /** 일정 등록. */
  const handleCreate = () => {
    queryParams.contentMode = 'create';
    dispatch(schedulesActions.updateDataClear());
    go(pathname, getQueryParams(queryParams), props.hash);
  };

  /** 내 캘린더 추가 시 이름 변경 이벤트. */
  const handleChangeMyCalName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      myCalName: event.target.value,
    }));
  };

  /** 내 캘린더 추가. */
  const handleSaveMyCalendar = () => {
    dispatch(calendarsActions.simpleSaveMyCalendar({ name: state.myCalName }));
    handleAddMyCalMenuToggle();
  };

  const mySelectedCal =
    state.itemId !== 0
      ? myCals.find(({ id }) => id === state.itemId)
      : undefined;
  const sharedSelectedCal =
    state.itemId !== 0
      ? sharedCals.find(({ id }) => id === state.itemId)
      : undefined;
  const subSelectedCal =
    state.itemId !== 0
      ? subCals.find(({ id }) => id === state.itemId)
      : undefined;
  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title={getLocalizedText('모듈.일정')} />
        <EuiSnb.Action>
          <Button
            block
            text={getLocalizedText('일정 등록')}
            variant="contained"
            onClick={handleCreate}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <Nav>
          <CustomDatePicker
            controlClassName="calendar-drawer-datepicker"
            dateFormat="yyyy-MM-dd"
            selected={state.selectedDate}
            inline
            onMonthChange={() => handleChangeMonth()}
            onChange={handleChangeDate}
          />
        </Nav>
        <Nav title={`${getLocalizedText('폴더.내 캘린더')}`}>
          {myCals
            .filter((a) => a.status)
            .map((a) => {
              return (
                <CalendarItem
                  key={a.id}
                  id={a.id}
                  initialCalendar={a.isInitialAtUserStarted}
                  label={a.name}
                  width="auto"
                  color={a.color}
                  labelChecked={a.checked}
                  onClick={(id, checked) =>
                    handleCalendarLabelClick({
                      type: 'mine',
                      id,
                      isVisible: checked,
                    })
                  }
                  onSettingClick={handleMySettingClick}
                />
              );
            })}
          {state.mySettingMenuPoint && mySelectedCal && (
            <Menu
              className="calendar-color-menu"
              width={185}
              point={state.mySettingMenuPoint}
              onClose={handleMySettingMenuToggle}
            >
              <div
                onClick={() =>
                  handleChangeUseExposeCreator({
                    type: 'mine',
                    id: mySelectedCal.id,
                    useExposeCreator: !mySelectedCal.useExposeCreator,
                  })
                }
                className="eui-nav-item"
              >
                <span className="nav-label">
                  {`작성자명 ${
                    mySelectedCal.useExposeCreator
                      ? getLocalizedText('표시안함')
                      : getLocalizedText('표시')
                  }`}
                </span>
              </div>
              <MenuItem
                width="auto"
                label={getLocalizedText('캘린더 관리')}
                onClick={handleMySettingCalGo}
              />
              <MenuDivider />
              <div
                style={
                  display !== 'phone' ? { display: 'inline-block' } : undefined
                }
              >
                {colorList.map((a, i) => {
                  const selected = mySelectedCal.color === a.rgb;
                  const key = `${i}_${a.name}`;
                  if (display === 'phone')
                    return (
                      <div
                        key={key}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          margin: '10px',
                        }}
                        onClick={() =>
                          handleChangeColor({ type: 'mine', color: a.rgb })
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 500,
                            fontSize: 'inherit',
                            fontFamily: 'inherit',
                            height: '30px',
                          }}
                        >
                          <i
                            className="eui-icon eui-icon-square-fill nav-badge"
                            style={{
                              color: `${a.rgb}`,
                              marginRight: '4px',
                            }}
                          />
                          <span>{a.name}</span>
                        </div>
                        <Button
                          style={{ marginLeft: 'auto' }}
                          iconType
                          icon={selected ? 'check' : undefined}
                        />
                      </div>
                    );
                  return (
                    <div
                      key={key}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: `${a.rgb}`,
                        display: 'inline-block',
                        width: '25px',
                        height: '25px',
                        float: 'left',
                        borderRadius: '2px',
                        margin: '3px 0 3px 3px',
                      }}
                      onClick={() =>
                        handleChangeColor({ type: 'mine', color: a.rgb })
                      }
                    >
                      <label className={selected ? 'checked' : undefined} />
                    </div>
                  );
                })}
              </div>
            </Menu>
          )}
          <MenuItem
            icon="plus"
            label={getLocalizedText('추가')}
            onClick={handleAddMyCalMenuToggle}
          />
          {state.addMyCalMenuPoint && (
            <Menu
              point={state.addMyCalMenuPoint}
              onClose={handleAddMyCalMenuToggle}
            >
              <MenuGroup>{getLocalizedText('캘린더 추가')}</MenuGroup>
              <div style={{ display: 'flex', margin: '5px' }}>
                <TextField
                  value={state.myCalName}
                  onChange={handleChangeMyCalName}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '4px',
                }}
              >
                <Button
                  size="xs"
                  text={getLocalizedText('확인')}
                  variant="contained"
                  onClick={handleSaveMyCalendar}
                />
                <Button
                  size="xs"
                  text={getLocalizedText('취소')}
                  variant="outlined"
                  onClick={handleAddMyCalMenuToggle}
                />
              </div>
            </Menu>
          )}
        </Nav>
        {sharedCals.filter((a) => a.status).length > 0 && (
          <Nav title={`${getLocalizedText('폴더.공유 캘린더')}`}>
            {sharedCals
              .filter((a) => a.status)
              .map((a) => {
                return (
                  <CalendarItem
                    key={a.id}
                    id={a.id}
                    label={a.name}
                    width="auto"
                    color={a.color}
                    labelChecked={a.checked}
                    onClick={(id, checked) =>
                      handleCalendarLabelClick({
                        type: 'shared',
                        id,
                        isVisible: checked,
                      })
                    }
                    onSettingClick={handleSharedSettingClick}
                  />
                );
              })}
            {state.sharedSettingMenuPoint && sharedSelectedCal && (
              <Menu
                className="calendar-color-menu"
                width={185}
                point={state.sharedSettingMenuPoint}
                onClose={handleSharedSettingMenuToggle}
              >
                <div
                  onClick={() =>
                    handleChangeUseExposeCreator({
                      type: 'shared',
                      id: sharedSelectedCal.id,
                      useExposeCreator: !sharedSelectedCal.useExposeCreator,
                    })
                  }
                  className="eui-nav-item"
                >
                  <span className="nav-label">
                    {`작성자명 ${
                      sharedSelectedCal.useExposeCreator
                        ? getLocalizedText('표시안함')
                        : getLocalizedText('표시')
                    }`}
                  </span>
                </div>
                <MenuDivider />
                <div
                  style={
                    display !== 'phone'
                      ? { display: 'inline-block' }
                      : undefined
                  }
                >
                  {colorList.map((a, i) => {
                    const selected = sharedSelectedCal.color === a.rgb;
                    const key = `${i}_${a.name}`;
                    if (display === 'phone')
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            margin: '10px',
                          }}
                          onClick={() =>
                            handleChangeColor({ type: 'shared', color: a.rgb })
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 500,
                              fontSize: 'inherit',
                              fontFamily: 'inherit',
                              height: '30px',
                            }}
                          >
                            <i
                              className="eui-icon eui-icon-square-fill nav-badge"
                              style={{
                                color: `${a.rgb}`,
                                marginRight: '4px',
                              }}
                            />
                            <span>{a.name}</span>
                          </div>
                          <Button
                            style={{ marginLeft: 'auto' }}
                            iconType
                            icon={selected ? 'check' : undefined}
                          />
                        </div>
                      );
                    return (
                      <div
                        key={key}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: `${a.rgb}`,
                          display: 'inline-block',
                          width: '25px',
                          height: '25px',
                          float: 'left',
                          borderRadius: '2px',
                          margin: '3px 0 3px 3px',
                        }}
                        onClick={() =>
                          handleChangeColor({ type: 'shared', color: a.rgb })
                        }
                      >
                        <label className={selected ? 'checked' : undefined} />
                      </div>
                    );
                  })}
                </div>
              </Menu>
            )}
          </Nav>
        )}
        {subCals.filter((a) => a.status).length > 0 && (
          <Nav title={`${getLocalizedText('폴더.구독 캘린더')}`}>
            {subCals
              .filter((a) => a.status)
              .map((a) => {
                return (
                  <CalendarItem
                    key={a.id}
                    id={a.id}
                    label={a.name}
                    width="auto"
                    color={a.color}
                    labelChecked={a.checked}
                    onClick={(id, checked) =>
                      handleCalendarLabelClick({
                        type: 'sub',
                        id,
                        isVisible: checked,
                      })
                    }
                    onSettingClick={handleSubSettingClick}
                  />
                );
              })}
            {state.subSettingMenuPoint && subSelectedCal && (
              <Menu
                className="calendar-color-menu"
                width={185}
                point={state.subSettingMenuPoint}
                onClose={handleSubSettingMenuToggle}
              >
                <div
                  style={
                    display !== 'phone'
                      ? { display: 'inline-block' }
                      : undefined
                  }
                >
                  {colorList.map((a, i) => {
                    const selected = subSelectedCal.color === a.rgb;
                    const key = `${i}_${a.name}`;
                    if (display === 'phone')
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            margin: '10px',
                          }}
                          onClick={() =>
                            handleChangeColor({ type: 'sub', color: a.rgb })
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 500,
                              fontSize: 'inherit',
                              fontFamily: 'inherit',
                              height: '30px',
                            }}
                          >
                            <i
                              className="eui-icon eui-icon-square-fill nav-badge"
                              style={{
                                color: `${a.rgb}`,
                                marginRight: '4px',
                              }}
                            />
                            <span>{a.name}</span>
                          </div>
                          <Button
                            style={{ marginLeft: 'auto' }}
                            iconType
                            icon={selected ? 'check' : undefined}
                          />
                        </div>
                      );
                    return (
                      <div
                        key={key}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: `${a.rgb}`,
                          display: 'inline-block',
                          width: '25px',
                          height: '25px',
                          float: 'left',
                          borderRadius: '2px',
                          margin: '3px 0 3px 3px',
                        }}
                        onClick={() =>
                          handleChangeColor({ type: 'sub', color: a.rgb })
                        }
                      >
                        <label className={selected ? 'checked' : undefined} />
                      </div>
                    );
                  })}
                </div>
              </Menu>
            )}
          </Nav>
        )}
        <MenuDivider />
        <MenuItem
          label={
            categories.find((a) => a.id === 'preferences')?.name
              ? getLocalizedText(
                  `폴더.${
                    categories.find((a) => a.id === 'preferences')?.name
                  }`,
                )
              : ''
          }
          icon="cog-fill"
          selected={menu === 'preferences'}
          onClick={() => {
            go('/calendar/preferences');
            dispatch(sessionActions.mobileNav(false));
          }}
        />
      </EuiSnb.Nav>
    </>
  );
}

export default CalendarDrawer;
