/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { Language } from '../../../../../groupware-common/types';
import {
  EntityEmployeeKeyable,
  EntityKeyable,
  EntityNameKeyable,
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

const useDummy = false;

const namespace = 'directory';

/**
 * 조직.
 * @property companyId 회사 아이디.
 * @property id 아이디.
 * @property parentId 부모 아이디.
 * @property seq 순번.
 * @property nameId 국제화 이름 아이디.
 * @property managerId 부서장 아이디.
 * @property description 설명.
 * @property updateAt 수정 날짜.
 */
type Organization = {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  nameId: number;
  managerId: number;
  description: string;
  updateAt: string;
};

// prettier-ignore
const dummy: Organization[] = [
  {companyId: 5001,id: 10001,parentId: 5001, seq: 0,nameId: 10001,managerId: 0,    description: '영업팀',updateAt: '1000-01-01T00:00:00.000001',},
  {companyId: 5001,id: 10002,parentId: 5001, seq: 0,nameId: 10002,managerId: 0,    description: '사업관리팀',updateAt: '1000-01-01T00:00:00.000002',},
  {companyId: 5001,id: 10003,parentId: 5001, seq: 0,nameId: 10003,managerId: 20006,description: '컨설팅팀',updateAt: '1000-01-01T00:00:00.000003',},
  {companyId: 5001,id: 10004,parentId: 5001, seq: 0,nameId: 10004,managerId: 0,    description: '이알피팀',updateAt: '1000-01-01T00:00:00.000004',},
  {companyId: 5001,id: 10005,parentId: 5001, seq: 0,nameId: 10005,managerId: 0,    description: '그룹웨어팀',updateAt: '1000-01-01T00:00:00.000005',},
  {companyId: 5001,id: 10006,parentId: 5001, seq: 0,nameId: 10006,managerId: 0,    description: '연구소',updateAt: '1000-01-01T00:00:00.000006',},
  {companyId: 5001,id: 10007,parentId: 5001, seq: 0,nameId: 10007,managerId: 0,    description: '구축컨설팅팀',updateAt: '1000-01-01T00:00:00.000007',},
  {companyId: 5001,id: 10008,parentId: 5001, seq: 0,nameId: 10008,managerId: 0,    description: '신규사업부',updateAt: '1000-01-01T00:00:00.000008',},
  {companyId: 5001,id: 10009,parentId: 10008,seq: 0,nameId: 10009,managerId: 0,    description: '프론트엔드팀',updateAt: '1000-01-01T00:00:00.000009',},
  {companyId: 5001,id: 10010,parentId: 10008,seq: 0,nameId: 10010,managerId: 0,    description: '백엔드팀',updateAt: '1000-01-01T00:00:00.000010',},
  {companyId: 5001,id: 10011,parentId: 10008,seq: 0,nameId: 10011,managerId: 0,    description: '운영관리팀',updateAt: '1000-01-01T00:00:00.000011',},
];

async function findLastUpdateAt() {
  /*
  const url = `https://localhost/${param1}/${param2}`
  const { data } = await axios.get<Board[]>(url)
  return data
  */
  let updateAt = '';
  dummy.forEach((x) => {
    if (updateAt < x.updateAt) updateAt = x.updateAt;
  });
  return updateAt;
}

async function findList() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization/all`;
    const response = await axios.get<Organization[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function findView(id: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization?id=${id}`;
    const response = await axios.get<Organization | null>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface OrganizationCreate {
  parentId: number;
  names: { code: Language; value: string }[];
  managerId: number;
  description: string;
}

async function create(arg: OrganizationCreate) {
  if (useDummy) return { companyId: 0, id: 0, nameId: 0, updateAt: '' };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization`;
    const response = await axios.post<EntityNameKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface OrganizationUpdate {
  id: number;
  parentId?: number;
  names?: { code: Language; value: string | null }[];
  managerId?: number;
  description?: string;
  updateAt: string;
}

async function update(arg: OrganizationUpdate) {
  if (useDummy) return { companyId: 0, id: 0, nameId: 0, updateAt: '' };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization`;
    const response = await axios.put<EntityNameKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 조직 순서변경. */
async function seq(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function remove(id: number, updateAt: string) {
  if (useDummy) return { companyId: 0, id, updateAt };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization`;
    const response = await axios.delete<EntityKeyable>(url, {
      headers,
      data: { id, updateAt },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function employeeDelete(
  id: number,
  employeeId: number,
  updateAt: string,
) {
  // return { companyId: 0, id, employeeId, updateAt };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/organization/employee`;
    const response = await axios.delete<EntityEmployeeKeyable>(url, {
      headers,
      data: { id, employeeId, updateAt },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 조직 직원 인터페이스 */
interface OrganizationEmployee {
  companyId: number;
  id: number;
  employeeId: number;
  seq: number;
  jobDutyId: number;
  updateAt: string;
}

// prettier-ignore
const employees: OrganizationEmployee[] = [
  {companyId: 5001,id: 5001, employeeId: 20001,seq: 1,jobDutyId: 0,    updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 5001, employeeId: 20002,seq: 1,jobDutyId: 0,    updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10001,employeeId: 20003,seq: 1,jobDutyId: 0,    updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10001,employeeId: 20004,seq: 1,jobDutyId: 0,    updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10002,employeeId: 20005,seq: 1,jobDutyId: 0,    updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10003,employeeId: 20006,seq: 1,jobDutyId: 40003,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10003,employeeId: 20007,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10003,employeeId: 20008,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10003,employeeId: 20009,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10003,employeeId: 20010,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10003,employeeId: 20011,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10004,employeeId: 20012,seq: 1,jobDutyId: 40003,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10004,employeeId: 20013,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10004,employeeId: 20014,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10004,employeeId: 20015,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10004,employeeId: 20016,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10004,employeeId: 20017,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20018,seq: 1,jobDutyId: 40003,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20019,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20020,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20021,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20022,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20023,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20024,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20025,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20026,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10005,employeeId: 20027,seq: 1,jobDutyId: 40004,updateAt: '1000-01-01T00:00:00.000000'},
  {companyId: 5001,id: 10007,employeeId: 20028,seq: 1,jobDutyId: 0,    updateAt: '1000-01-01T00:00:00.000000'},
];

async function findEmployee(id?: number) {
  if (useDummy) return employees;
  try {
    const { host, headers } = getApiConfig();
    const param = id !== undefined ? `?id=${id}` : '';
    const url = `${host}/api/directory/v1/organization/employee/all${param}`;
    const response = await axios.get<OrganizationEmployee[]>(url, { headers });
    // console.log('response: ', response);
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 조직도 연동 호출 */
async function updateEexternal() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/linkage`;
    const response = await axios.post(url, undefined, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const organizationApi = {
  namespace,
  findLastUpdateAt,
  findList,
  findView,

  create,
  update,
  seq,
  delete: remove,
  employeeDelete,

  updateEexternal,

  findEmployee,
};

export default organizationApi;
