import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AttachmentsItem from '../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../components/attachments/AttachmentsList';
// import Comment from '../../../components/comment/Comment';
import PostView from '../../../components/post/PostView';
import Print, { PrintClickProps } from '../../../components/print/Print';
import UserInfo from '../../../components/user/UserInfo';
import { useDirectory } from '../../../groupware-directory/stores/directory';
import { RootState, useAppDispatch } from '../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../groupware-webapp/stores/common/utils';
import { documentsHistoryApi } from '../../apis/document/v1/common';
import { documentsActions } from '../../stores/document/document';
import {
  folderActions,
  replaceRetentionPeriodToString,
} from '../../stores/document/folders';
import { getPathParams } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

function DocumentPrintView(props: {
  pathname: string;
  listId: { id: number }[];
  onClose(): void;
}): JSX.Element {
  const { p1 } = getPathParams<{ p1: string }>('/:p1', props.pathname);
  const { listId, onClose } = props;
  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const printData = useSelector(
    (state: RootState) => state.document.documents.printView,
  );

  const [state, setState] = useState<{
    pageNo: number;
    loading: boolean;
  }>({
    pageNo: 1,
    loading: true,
  });
  const [option, setOption] = useState<
    { value: string; label: string; checked: boolean }[]
  >([
    {
      value: 'info',
      label: getLocalizedText('정보'),
      checked: false,
    },
    {
      value: 'attachment',
      label: getLocalizedText('첨부파일'),
      checked: false,
    },
  ]);

  const { pageNo, loading } = state;
  useEffect(() => {
    if (listId.length === 0) return;
    dispatch(
      documentsActions.printView({
        id: listId[pageNo - 1].id,
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const { folderId } = result.payload as { folderId: number };
        dispatch(folderActions.userFolderView({ id: folderId }));
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
        scrollbar.current?.scrollTo(0, 0);
      } else {
        dispatch(documentsActions.printViewClear());
        onClose();
      }
    });
  }, [pageNo, dispatch]);

  const totalCount = listId.length;

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setOption((prev) =>
      prev.map((x) =>
        x.value === value ? { ...x, checked: event.target.checked } : x,
      ),
    );
  };

  const handleClick = (code: PrintClickProps) => {
    if (code === 'prev') {
      const page = (pageNo || 1) - 1;
      if (page < 1) return;
      setState({ pageNo: page, loading: true });
    }
    if (code === 'next') {
      if (pageNo >= totalCount) return;
      const page = (pageNo || 1) + 1;
      setState({ pageNo: page, loading: true });
    }
    if (code === 'close') {
      dispatch(documentsActions.printViewClear());
      onClose();
    }
  };

  if (printData === undefined) return <></>;

  /** 인쇄 문서 활동 저장 */
  const handleAddHistory = async () => {
    if (p1 !== 'adminconsole') await documentsHistoryApi.print(printData.id);
  };

  /** 글 작성자 */
  const writer = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: printData.updaterId,
  });
  //   const comments = getCommentItems(
  //     printData.commentData,
  //     principal.companyId,
  //     directory,
  //   );

  const { retentionPeriod } = printData;
  return (
    <Print
      loading={loading}
      pageNo={pageNo}
      totalPageCount={totalCount}
      option={option}
      onChangeOption={handleChangeOption}
      onClick={handleClick}
      onHistory={handleAddHistory}
    >
      <div className="ui-view-root" ref={scrollbar}>
        <PostView type="full">
          {option.some((a) => a.value === 'info' && a.checked) && (
            <>
              <PostView.Head>
                <PostView.Title>{printData.subject}</PostView.Title>
                <UserInfo
                  className="view-author"
                  avatar={writer.avatar}
                  name={writer.employeeName}
                  from={writer.organizationName}
                  date={printData.createAt}
                />
              </PostView.Head>
              <PostView.Info>
                {retentionPeriod !== '' && (
                  <PostView.InfoItem
                    title={getLocalizedText('보존기간')}
                    value={replaceRetentionPeriodToString(retentionPeriod)}
                  />
                )}
                <PostView.InfoItem
                  title={getLocalizedText('조회')}
                  value={printData.views}
                />
                {/* <PostView.InfoItem title="좋아요" value={printData.likes} /> */}
                {/* {printData.isNotified && (
                  <PostView.InfoItem title="공지기간" value={noticeRange} />
                )} */}
              </PostView.Info>
            </>
          )}
          <PostView.Body>
            <PostView.Content data={printData.contents} />
            {printData.attachedFiles &&
              printData.attachedFiles.length > 0 &&
              option.some((a) => a.value === 'attachment' && a.checked) && (
                <AttachmentsList
                  count={printData.attachedFiles.length}
                  className="view-attachments"
                >
                  {printData.attachedFiles.map(({ fileId: id, name, size }) => (
                    <AttachmentsItem key={id} name={name} size={size} />
                  ))}
                </AttachmentsList>
              )}
            {/* {option.some((a) => a.value === 'comment' && a.checked) && (
              <Comment count={printData.comments} comments={comments ?? []} />
            )} */}
          </PostView.Body>
        </PostView>
      </div>
    </Print>
  );
}

export default DocumentPrintView;
