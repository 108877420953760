import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { IconType } from '../../../../../../groupware-common/types/icon';
import Chip from '../../../../../../components/chip/Chip';
import { CommonBirtdayResponse } from '../../../../../apis/v1/dashboard/templates';
import { useDirectory } from '../../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import UserInfo from '../../../../../../components/user/UserInfo';
import Icon from '../../../../../../components/icon/Icon';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

type CommonBirtdayResponseData = Pick<CommonBirtdayResponse, 'data'>;
function CommonBirthdayTemplate(props: {
  responseData: CommonBirtdayResponseData;
}): JSX.Element {
  const companyId = useSelector(
    (state: RootState) => state.session.principal.companyId,
  );

  const directory = useDirectory();
  const { responseData } = props;

  return (
    <div className="template-content new-employee">
      {responseData.data.map((x) => {
        let employeeData:
          | {
              companyId: number;
              companyName: string;
              organizationId: number;
              organizationName: string;
              employeeId: number;
              employeeName: string;
              jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
              jobClassName: string;
              jobPositionId: number;
              jobPositionName: string;
              jobDutyId: number;
              jobDutyName: string;
              avatar?: string;
            }
          | undefined;
        if (x.employeeId) {
          employeeData = getDirectoryData({
            ...directory,
            companyId,
            employeeId: x.employeeId,
          });
        }

        const user = employeeData
          ? {
              name: employeeData.employeeName,
              from: employeeData.organizationName,
              jobClass: employeeData.jobClassName,
              avartar: employeeData.avatar,
            }
          : {
              name: '',
              icon: 'sitemap-fill' as IconType,
            };
        return (
          <a className="content-wrapper" key={x.employeeId}>
            <div
              className="employee-wrapper"
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                position: 'relative',
                lineHeight: 1,
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Icon icon="cake" theme="primary" />
              <UserInfo
                name={user.name}
                from={user.from}
                avatar={user.avartar}
              />
              <div style={{ marginLeft: 'auto' }}>
                <Chip
                  theme="cancel"
                  label={`${!x.isSolar ? getLocalizedText('음력') : ''}${moment(
                    x.birthday,
                  ).format('MM월 DD일')}`}
                />
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
}

export default CommonBirthdayTemplate;
