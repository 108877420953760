import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { preferencesActions } from '../../../../stores/preferences';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function DashBoardBasicRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    Promise.all([
      dispatch(preferencesActions.findAdministrators()),
      dispatch(preferencesActions.findPreferences()),
    ]).then(() => {
      if (mount) dispatch(sessionActions.setRoute({ pathname, search }));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default DashBoardBasicRoute;
