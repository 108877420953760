import React from 'react';
import { IconType } from '../../../../../../groupware-common/types/icon';
import Avatar from '../../../../../../components/avatar/Avatar';
import { dateSimplify } from '../../../../../../groupware-common/utils/ui';
import { ApprovalDocumentResponse } from '../../../../../apis/v1/dashboard/templates';
import { jsonToApprovalLine } from '../../../../../../groupware-approval/stores/approval/document';
import { getApprovalLineDrafter } from '../../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../../groupware-directory/stores/directory';
import { b62 } from '../../../../../../groupware-common/utils';
import { TemplateKey } from '../../../../../constants/templates';
import Loading from '../../../../../../components/loading/Loading';

function ApprovalDocumentContent(props: {
  subject: string;
  date: string;
  user: {
    name: string;
    from?: string;
    icon?: IconType;
    jobClass?: string;
    avartar?: string;
  };
  onAction?(): void;
}): JSX.Element {
  const { user, date, subject } = props;
  return (
    <div className="template-content-container">
      <a
        className="template-content approval-document"
        onClick={props.onAction}
      >
        <Avatar
          name={user.name}
          image={user.avartar}
          icon={user.icon}
          className="avatar"
        />
        <div className="content">
          <div className="head">
            <div className="user">
              <span className="name">{user.name}</span>
              {user.jobClass && <span className="class">{user.jobClass}</span>}
              {user.from && (
                <span className="organization">{user.from ?? ''}</span>
              )}
            </div>
            <div className="date">{dateSimplify(date)}</div>
          </div>
          <div className="subject">{subject}</div>
        </div>
      </a>
    </div>
  );
}

type ApprovalDocumentResponseData = Pick<
  ApprovalDocumentResponse,
  'data' | 'responseType'
>;

/** 클릭 이동 이벤트 추가 */
function ApprovalDocumentTemplate(props: {
  responseData: ApprovalDocumentResponseData;
  folderId: string;
  onRedirect(redirectURL: string, responseType: TemplateKey): void;
}): JSX.Element {
  const { responseData, folderId } = props;
  const directory = useDirectory();

  const children =
    Object.keys(responseData.data).length === 0 ? (
      <Loading />
    ) : (
      responseData.data.map((x) => {
        const approvalLine = jsonToApprovalLine(x.approvalline);
        const drafter = getApprovalLineDrafter(approvalLine);

        const handleGo = (id: number) => {
          props.onRedirect(
            `/approval/${b62(Number(folderId))}/${b62(id)}`,
            responseData.responseType,
          );
        };

        // 기안자 찾기
        if (drafter) {
          let drafterDirectoryData:
            | {
                companyId: number;
                companyName: string;
                organizationId: number;
                organizationName: string;
                employeeId: number;
                employeeName: string;
                jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
                jobClassName: string;
                jobPositionId: number;
                jobPositionName: string;
                jobDutyId: number;
                jobDutyName: string;
                avatar?: string;
              }
            | undefined;
          if (drafter.employeeId) {
            drafterDirectoryData = getDirectoryData({
              ...directory,
              ...drafter,
            });
          }

          const user = drafterDirectoryData
            ? {
                name: drafterDirectoryData.employeeName,
                from: drafterDirectoryData.organizationName,
                jobClass: drafterDirectoryData.jobClassName,
                avartar: drafterDirectoryData.avatar,
              }
            : {
                name: drafter.organizationName,
                icon: 'sitemap-fill' as IconType,
              };
          return (
            <ApprovalDocumentContent
              key={`approval-document-${x.id}`}
              subject={x.subject}
              date={x.createAt}
              user={user}
              onAction={() => handleGo(x.id)}
            />
          );
        }
        return <></>;
      })
    );
  return <>{children}</>;
}

export default ApprovalDocumentTemplate;
