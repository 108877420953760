/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../groupware-webapp/app/store';
import {
  CacheEntity,
  CacheMeta,
  Language,
  LocateArg,
} from '../../../../groupware-common/types';
import jobDutyApi from '../../../apis/directory/v1/jobduty';
import { thunkCondition } from '../../../../groupware-webapp/stores/utils';
import { appError } from '../../../../groupware-webapp/stores/common/utils';
import {
  RESOURCE_KEY,
  addResourceValue,
} from '../../../../groupware-common/utils/i18n';

const name = 'directory/jobduty';

type Item = {
  companyId: number;
  id: number;
  seq: number;
  parentId: number;
  updateAt: string;
};

export type View = Item & {
  description: string;
};

interface State {
  list: CacheEntity<{ items: Item[]; totalCount: number; search: string }>;
  view: CacheEntity<View | null | undefined>;
}

const initialState: State = {
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [], totalCount: 0, search: '' },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (_: void, { getState, rejectWithValue }) => {
    let meta: CacheMeta | undefined;
    let data: { items: Item[]; totalCount: number; search: string } | undefined;
    try {
      const rootState = getState() as RootState;
      const { list } = rootState.directory.employee;

      const timestamp = Date.now();
      if (list.meta.timestamp > timestamp - 300000) return { meta, data };

      meta = {
        timestamp,
        lastUpdateAt: await jobDutyApi.lastUpdateAt(),
      };

      if (!meta.lastUpdateAt || list.meta.lastUpdateAt !== meta.lastUpdateAt) {
        const response = await jobDutyApi.list();
        if (response === undefined) return { meta, data };
        const items = response.map((x) => {
          return {
            ...x,
            parentId: response.find((z) => z.seq === x.seq)?.id || 0,
          };
        });
        const totalCount = items.length;
        const search = '';

        data = { items, totalCount, search };
      }

      return { meta, data };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findList.typePrefix, arg, getState);
    },
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { id: number | 'first' } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    let data: View | null | undefined;
    let _response_id_: number | undefined;

    try {
      const state = (getState() as RootState).directory.jobDuty;

      const id: number | null | undefined =
        arg.id === 'first'
          ? state.list.data.items[0]?.id || (await jobDutyApi.firstId())
          : arg.id;

      if (typeof id !== 'number') {
        return { meta, data, _response_id_ };
        // return thunkApi.rejectWithValue({
        //   error: 'error',
        //   path: '',
        //   status: 500,
        //   timestamp: '',
        // });
      }

      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      const response = await jobDutyApi.view(id);

      if (response) {
        data = {
          ...response,
        };
      }

      return { meta, data, _response_id_ };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findView.typePrefix, arg, getState);
    },
  },
);

const save = createAsyncThunk(
  `${name}/save`,
  async (
    arg: {
      precedenceSeq: number;
      names: Array<{ language: Language; value: string }>;
      description: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    // console.log('module save arg : ', arg);

    let list:
      | CacheEntity<{ items: Item[]; totalCount: number; search: string }>
      | undefined;
    let view: CacheEntity<View | null | undefined> | undefined;
    let _response_id_: number | undefined;
    try {
      const timestamp = Date.now();

      const param = {
        ...arg,
        names: arg.names
          .filter(({ value }) => value)
          .map((a) => ({ code: a.language, value: a.value })),
      };

      const response = await jobDutyApi.save(param);

      // 다국어 업데이트.
      if (response !== undefined) {
        const { companyId, id } = response;
        await addResourceValue({
          data: arg.names.map((x) => ({
            language: x.language,
            value: x.value,
          })),
          key: `${RESOURCE_KEY.JOBCLASS.JOBDUTY}.${companyId}_${id}`,
        });
      }

      const lastUpdateAt = response.updateAt;

      const responseList = await jobDutyApi.list();
      if (responseList === undefined) return { list, view, _response_id_ };

      const items = responseList.map((x) => {
        return {
          ...x,
          parentId: responseList.find((z) => z.seq === x.seq)?.id || 0,
        };
      });
      const totalCount = items.length;
      const search = '';

      list = {
        meta: { timestamp, lastUpdateAt },
        data: { items, totalCount, search },
      };

      const viewData = await jobDutyApi.view(response.id);
      view = {
        meta: { timestamp, lastUpdateAt },
        data: viewData
          ? {
              ...viewData,
            }
          : viewData,
      };

      if (response.id && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = response.id;

      return { list, view, _response_id_ };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(save.typePrefix, arg, getState);
    },
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      id: number;
      precedenceSeq: number;
      names?: Array<{ language: Language; value: string }>;
      description?: string;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    // console.log('module update arg : ', arg);

    let list:
      | CacheEntity<{ items: Item[]; totalCount: number; search: string }>
      | undefined;
    let view: CacheEntity<View | null | undefined> | undefined;
    let _response_id_: number | undefined;
    try {
      const timestamp = Date.now();

      const param = {
        ...arg,
        names: arg.names
          ? arg.names.map((a) => ({ code: a.language, value: a.value }))
          : undefined,
      };

      const response = await jobDutyApi.update(param);

      // 다국어 업데이트.
      if (response !== undefined && arg.names) {
        const { companyId, id } = response;
        await addResourceValue({
          data: arg.names.map((x) => ({
            language: x.language,
            value: x.value,
          })),
          key: `${RESOURCE_KEY.JOBCLASS.JOBDUTY}.${companyId}_${id}`,
        });
      }

      const lastUpdateAt = response.updateAt;

      const responseList = await jobDutyApi.list();
      if (responseList === undefined) return { list, view, _response_id_ };

      const items = responseList.map((x) => {
        return {
          ...x,
          parentId: responseList.find((z) => z.seq === x.seq)?.id || 0,
        };
      });
      const totalCount = items.length;
      const search = '';

      list = {
        meta: { timestamp, lastUpdateAt },
        data: { items, totalCount, search },
      };

      const viewData = await jobDutyApi.view(response.id);
      view = {
        meta: { timestamp, lastUpdateAt },
        data: viewData
          ? {
              ...viewData,
            }
          : viewData,
      };

      if (response.id && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = response.id;

      return { list, view, _response_id_ };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(save.typePrefix, arg, getState);
    },
  },
);

const _delete = createAsyncThunk(
  `${name}/_delete`,
  async (
    arg: {
      id: number;
      seq: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    let list:
      | CacheEntity<{ items: Item[]; totalCount: number; search: string }>
      | undefined;
    let view: CacheEntity<View | null | undefined> | undefined;
    let _response_id_: number | undefined;
    try {
      const response = await jobDutyApi.delete(arg.id, arg.seq, arg.updateAt);
      if (response === undefined) return { list, view, _response_id_ };

      const lastUpdateAt = response.updateAt;

      const timestamp = Date.now();

      const responseList = await jobDutyApi.list();
      if (responseList === undefined) return { list, view, _response_id_ };

      const items = responseList.map((x) => {
        return {
          ...x,
          parentId: responseList.find((z) => z.seq === x.seq)?.id || 0,
        };
      });
      const totalCount = items.length;
      const search = '';

      list = {
        meta: { timestamp, lastUpdateAt },
        data: { items, totalCount, search },
      };

      return { list, view, _response_id_ };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(_delete.typePrefix, arg, getState);
    },
  },
);

const jobDutySlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 리스트 메타 상태 값 할당.
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 리스트 데이터 상태 값 할당.
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 뷰 메타 상태 값 할당.
        if (payload?.meta !== undefined) state.view.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 뷰 데이터 상태 값 할당.
        if (payload?.data !== undefined) state.view.data = payload.data;
      })
      .addCase(save.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.list !== undefined) state.list = payload.list;
        if (payload?.view !== undefined) state.view = payload.view;
      })
      .addCase(update.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.list !== undefined) state.list = payload.list;
        if (payload?.view !== undefined) state.view = payload.view;
      })
      .addCase(_delete.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.list !== undefined) state.list = payload.list;
        if (payload?.view !== undefined) state.view = payload.view;
      });
  },
});

export default jobDutySlice.reducer;

export const jobDutyActions = {
  findList,
  findView,
  save,
  update,
  delete: _delete,
};
