import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../app/store';
import { sessionActions } from '../../stores/session';
import TextField from '../../../components/textfield/TextField';
import HelperText from '../../../components/alert/HelperText';
import Checkbox from '../../../components/checkbox/Checkbox';
// import { go } from '../../../groupware-common/utils';
import Authentication from '../../../components/Authentication';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

export default function Signin(): JSX.Element {
  const dispatch = useAppDispatch();
  const signinStore = useSelector((state: RootState) => state.session.signin);

  let hasUserId = false;
  let userId = '';
  const cookie = document.cookie.split('; ');
  if (document.cookie !== '' && cookie.length > 0)
    cookie.forEach((a) => {
      const key = a.split('=')[0];
      const value = a.split('=')[1];
      if (key === 'USER_ID') {
        hasUserId = true;
        userId = value;
      }
    });

  const [state, setState] = useState<{
    id: string;
    password: string;
    saveId: boolean;
    code: string;
  }>({
    id: hasUserId ? userId : '',
    password: '',
    saveId: hasUserId,
    code: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeSaveId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      saveId: event.target.checked,
    });
  };

  /** 로그인 */
  const handleSubmit = () => {
    dispatch(
      sessionActions.signin({
        saveId: state.saveId,
        id: state.id,
        password: state.password,
      }),
    );
  };

  /** 비밀번호 찾기 바로가기 */
  // const handleFindPassword = () => {
  //   go('/password');
  // };

  const language = 'ko-KR';

  return (
    <Authentication>
      <Authentication.Logo src="/images/company_logo.png" alt="회사 로고" />
      <Authentication.Form onSubmit={() => handleSubmit()}>
        <TextField
          placeholder="ID"
          icon="person"
          value={state.id}
          name="id"
          onChange={handleChange}
          required
        />
        <TextField
          placeholder="PASSWORD"
          type="password"
          icon="lock"
          value={state.password}
          name="password"
          onChange={handleChange}
          required
        />
        <HelperText>{signinStore.errorMessage}</HelperText>
        <Authentication.Option>
          <Checkbox
            label={getLocalizedText('아이디 저장')}
            checked={state.saveId}
            onChange={handleChangeSaveId}
          />
        </Authentication.Option>
        <Authentication.Submit
          label={getLocalizedText('로그인')}
          loading={signinStore.status === 'loading'}
        />
      </Authentication.Form>
      {/* <Authentication.Link label="비밀번호 찾기" onClick={handleFindPassword} /> */}
      {/* {signinStore.status === 'loading' ? <div>loading</div> : null} */}
    </Authentication>
  );
}
