import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import EmptyData from '../../../../../../components/data/EmptyData';
import AttendancePreferencesApi from '../../../../../../groupware-attendance/apis/attendance/v1/preferences';
import { timeFormat } from '../../../../../../groupware-common/utils/ui';
import Divider from '../../../../../../components/divider/Divider';
import Loading from '../../../../../../components/loading/Loading';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

type DayOffType = {
  total: {
    format: string;
    minutes: number;
  };
  use: {
    format: string;
    minutes: number;
  };
  remain: {
    format: string;
    minutes: number;
  };
};

function AttendanceLeaveTemplate(props: {
  item: {
    occursLeaves: number;
    useLeaves: number;
    modifingLeaves: number;
    remainedLeaves: number;
  };
}): JSX.Element {
  const [state, setState] = useState<{
    dayOff?: DayOffType;
    loading: boolean;
  }>({ loading: true });

  useEffect(() => {
    let mount = true;
    async function run() {
      try {
        const { item } = props;
        if (!item) throw new Error();
        const { expressionUnit } =
          await AttendancePreferencesApi.findAttendanceBasic();

        const total = item.occursLeaves + item.modifingLeaves;
        const dayOff = {
          total: {
            format: timeFormat(total, expressionUnit),
            minutes: total,
          },
          use: {
            format: timeFormat(item.useLeaves, expressionUnit),
            minutes: item.useLeaves,
          },
          remain: {
            format: timeFormat(item.remainedLeaves, expressionUnit),
            minutes: item.remainedLeaves,
          },
        };

        if (mount)
          setState({
            loading: false,
            dayOff,
          });
      } catch (ex) {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, [props.item]);

  return (
    <div className="template-content attendance-leave">
      {state.loading && <Loading />}
      {!state.loading && state.dayOff ? (
        <>
          <div className="attendance-circle-items">
            <div className="circle-items">
              <CircularProgressbar
                value={state.dayOff.remain.minutes}
                maxValue={state.dayOff.total.minutes}
                strokeWidth={8}
                styles={buildStyles({
                  pathColor: 'var(--primary-color)',
                })}
              />
            </div>
            <div className="content">
              <div className="content-item">
                <span className="date">{state.dayOff.total.format}</span>
                <span className="subject">
                  {getLocalizedText('총 연차', { ns: 'attendance' })}
                </span>
              </div>
              <Divider orientation="vertical" />
              <div className="content-item">
                <span className="date highlight">
                  {state.dayOff.remain.format}
                </span>
                <span className="subject">
                  {getLocalizedText('잔여', { ns: 'attendance' })}
                </span>
              </div>
              <Divider orientation="vertical" />
              <div className="content-item">
                <span className="date">{state.dayOff.use.format}</span>
                <span className="subject">
                  {getLocalizedText('사용', { ns: 'attendance' })}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <EmptyData
          message={getLocalizedText('생성된 연차가 없습니다.', {
            ns: 'attendance',
          })}
        />
      )}
    </div>
  );
}

export default AttendanceLeaveTemplate;
