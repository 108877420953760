/* eslint-disable consistent-return */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { LocateArg, Routable } from '../../../groupware-common/types';
import { getPathParams, getQueryParams } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import documentFormApi, { formFolderApi } from '../../apis/document/v1/form';

const NAME = 'document/form';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${NAME}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${NAME}/`) === 0 && type.endsWith('/rejected');
}

function getFolderId(route: Routable) {
  const { folderId } = getPathParams<{ folderId: number }>(
    '/*/*/*/:folderId$base62',
    route.pathname,
  );
  return folderId;
}

type FolderView = {
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

interface Item {
  checked: boolean;
  id: number;
  folderId: number;
  name: string;
  isEnable: boolean;
  createAt: string;
  updateAt: string;
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];

  items: Item[];
  item:
    | {
        companyId: number;
        id: number;
        folderId: number;
        isEnable: boolean;
        name: string;
        content: string;
        description: string;
        createAt: string;
        updateAt: string;
      }
    | undefined;
  totalCount: number;

  folder: {
    list: {
      items: FolderView[];
    };
  };
}

const initialState: State = {
  requests: [],
  items: [],
  item: undefined,
  totalCount: 0,

  folder: {
    list: {
      items: [],
    },
  },
};

/** 폴더 리스트 전체 조회 */
const findFolderList = createAsyncThunk(
  `${NAME}/findFolderList`,
  async (
    arg: ({ parentid?: number } & LocateArg) | void,
    { rejectWithValue },
  ) => {
    try {
      const list = await formFolderApi.list();
      return { list };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 폴더 생성 */
const createFolder = createAsyncThunk(
  `${NAME}/creaetFolder`,
  async (
    arg: {
      parentId: number;
      name: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { parentId, name } = arg;
      const response = await formFolderApi.create({ parentId, name });
      let _response_id_: number | undefined;
      if (arg.route?.pathmap?.indexOf('{response_id') !== -1)
        _response_id_ = response.id;
      return { folder: { ...response, ...{ parentId, name } }, _response_id_ };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 폴더 수정. */
const updateFolder = createAsyncThunk(
  `${NAME}/updateFolder`,
  async (
    arg: {
      id: number;
      parentId: number;
      name: string;
      updateAt: string;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { id, parentId, name, updateAt } = arg;
      const folders = (getState() as RootState).document.form.folder.list.items;
      const folder = folders.find((v) => v.id === id);
      if (!folder)
        throw new Error(getLocalizedText('폴더를 찾을 수 없습니다.'));

      await formFolderApi.update({
        id,
        parentId,
        name,
        updateAt,
      });
      return { ...folder, ...{ id, parentId, name, updateAt } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 폴더 순서 변경 */
const sortFolder = createAsyncThunk(
  `${NAME}/sortFolder`,
  async (
    arg: {
      data: {
        parentId: number;
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const response = await formFolderApi.sort(data);
      if (route !== undefined) await dispatch(findFolderList());
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 폴더 삭제 */
const deleteFolder = createAsyncThunk(
  `${NAME}/deleteFolder`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      await formFolderApi.delete({ id, updateAt });
      dispatch(findFolderList());
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 목록 조회. */
const findFormList = createAsyncThunk(
  `${NAME}/findFormList`,
  async (
    arg: {
      folderId?: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { folderId, route } = arg;
      const { pageNo, rowsPerPage, searchCode, searchWord } = getQueryParams(
        route?.search ?? '',
      );
      const items = (
        await documentFormApi.list({
          folderId,
          pageNo: pageNo ?? 1,
          rowsPerPage: rowsPerPage ?? 15,
          searchCode: searchCode ?? '',
          searchWord: searchWord ?? '',
        })
      ).map((a) => ({
        id: a.id,
        folderId: a.folderId,
        name: a.name,
        isEnable: a.isEnable,
        updateAt: a.updateAt,
        createAt: a.createAt,
        checked: false,
      }));
      const totalCount = await documentFormApi.totalCount({
        folderId,
        searchCode: searchCode ?? '',
        searchWord: searchWord ?? '',
      });
      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 조회. */
const findFormView = createAsyncThunk(
  `${NAME}/findFormView`,
  async (
    arg: {
      formId: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const item = documentFormApi.view(arg.formId);
      return item;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 등록 */
const createForm = createAsyncThunk(
  `${NAME}/createForm`,
  async (
    arg: {
      folderId: number;
      isEnable: boolean;
      name: string;
      description: string;
      content: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { folderId, isEnable, name, description, content, route } = arg;
      await documentFormApi.create({
        folderId,
        isEnable,
        name,
        description,
        content,
      });

      if (route !== undefined)
        await dispatch(findFormList({ folderId: getFolderId(route), route }));
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 수정 */
const updateForm = createAsyncThunk(
  `${NAME}/updateForm`,
  async (
    arg: {
      id: number;
      folderId: number;
      isEnable: boolean;
      name: string;
      description: string;
      content: string;
      updateAt: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { folderId } = arg;
      await documentFormApi.update(arg);
      await dispatch(findFormList({ folderId }));
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 이동 */
const moveForm = createAsyncThunk(
  `${NAME}/moveForm`,
  async (
    arg: {
      data: {
        id: number;
        folderId: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      await documentFormApi.move(data);
      if (route !== undefined)
        await dispatch(findFormList({ folderId: getFolderId(route), route }));
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 삭제 */
const deleteForm = createAsyncThunk(
  `${NAME}/deleteForm`,
  async (
    arg: {
      data:
        | { id: number; updateAt: string }
        | { id: number; updateAt: string }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      if (Array.isArray(data)) {
        const result = await documentFormApi.delete(data);
        if (result.length !== data.length) {
          if (route !== undefined) {
            await dispatch(
              findFormList({ folderId: getFolderId(route), route }),
            );
            return rejectWithValue(
              appError({
                error: getLocalizedText(
                  '사용 중인 양식이 존재하여 일부 양식은 삭제 실패하였습니다.',
                ),
              }),
            );
          }
        }
      } else await documentFormApi.delete(data);
      if (route !== undefined)
        await dispatch(findFormList({ folderId: getFolderId(route), route }));
    } catch (ex) {
      await dispatch(sessionActions.setDialog());
      return rejectWithValue(ex);
    }
  },
);

const documentFormReducer = createSlice({
  name: NAME,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{
        id: number | 'all' | number[];
        checked: boolean;
      }>,
    ) {
      const { id, checked } = action.payload;
      if (state.items) {
        if (id === 'all') {
          state.items = state.items.map((x) => {
            if (x.checked === checked) return x;
            return { ...x, checked };
          });
        }
        if (Array.isArray(id)) {
          state.items = state.items.map((x) =>
            id.indexOf(x.id) > -1 ? { ...x, checked } : { ...x },
          );
        } else {
          const index = state.items.findIndex((x) => x.id === id);
          if (index > -1)
            state.items[index] = {
              ...state.items[index],
              checked,
            };
        }
      }
    },
    formListClear(state) {
      state.items = [];
    },
    formViewClear(state) {
      state.item = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFormView.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.item = payload;
        }
      })
      .addCase(findFolderList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload?.list !== undefined)
            state.folder.list.items = payload.list;
        }
      })
      .addCase(createFolder.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) {
            state.folder.list.items = [
              ...state.folder.list.items,
              payload.folder,
            ];
            state.items = [];
          }
        }
      })
      .addCase(updateFolder.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          state.folder.list.items = state.folder.list.items.map((v) => {
            if (v.id === payload.id) return payload;
            return v;
          });
        }
      })
      .addCase(findFormList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (
            payload?.items !== undefined &&
            payload.totalCount !== undefined
          ) {
            state.items = payload.items;
            state.totalCount = payload.totalCount;
          }
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default documentFormReducer.reducer;

export const formFolderActions = {
  list: findFolderList,
  create: createFolder,
  update: updateFolder,
  sort: sortFolder,
  delete: deleteFolder,
};

export const formActions = {
  checked: documentFormReducer.actions.checked,
  listClear: documentFormReducer.actions.formListClear,
  viewClear: documentFormReducer.actions.formViewClear,
  list: findFormList,
  view: findFormView,
  create: createForm,
  update: updateForm,
  move: moveForm,
  delete: deleteForm,
};
