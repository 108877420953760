import { useEffect } from 'react';
import {
  AppDispatchAbort,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { preferencesActions } from '../../../../../groupware-approval/stores/approval/preferences';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function SecuritiesComposeRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${SecuritiesComposeRoute.name}.render(props)`, props);

  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let promise: AppDispatchAbort | undefined;

    const route = { pathname, search, hash };

    Promise.all([dispatch(preferencesActions.findDocumentNo())]).then(() => {
      dispatch(sessionActions.setRoute(route));
    });

    return () => {
      if (promise !== undefined) promise.abort();
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default SecuritiesComposeRoute;
