import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Icon from '../../../../../../components/icon/Icon';
import UserInfo from '../../../../../../components/user/UserInfo';
import { ReservationResponse } from '../../../../../apis/v1/dashboard/templates';
import TimeLine from '../../../../../../components/timeline/TimeLine';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { useDirectory } from '../../../../../../groupware-directory/stores/directory';
import Chip from '../../../../../../components/chip/Chip';
import {
  replaceIsApproval,
  replaceTheme,
} from '../../../../../../groupware-resource/pages/adminconsole/reservation/ResourceReservationContainer';
import { TemplateKey } from '../../../../../constants/templates';

type ResourceReservationResponseData = Pick<
  ReservationResponse,
  'data' | 'responseType'
>;
function ResourceReservationItemTemplate(props: {
  responseData: ResourceReservationResponseData;
  onRedirect(redirectURL: string, responseType: TemplateKey): void;
  selected?: boolean;
}): JSX.Element {
  let className = `timeline-dot`;
  if (props.selected) className += ` selected`;

  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const directory = useDirectory();

  const { responseData } = props;

  const handleRedirect = (isShared: boolean) => {
    if (isShared)
      props.onRedirect(`/resource/shared`, responseData.responseType);
    else props.onRedirect(`/resource`, responseData.responseType);
  };

  return (
    <div>
      <TimeLine className="dashboard-reservations">
        {responseData.data.map((item) => {
          let status = 'proceeding';

          if (item.isApproval) status = 'done';
          else if (item.isApproval === 2) status = 'return';

          const start = moment(item.startDateTime);
          const end = moment(item.endDateTime);
          const today = moment();

          let startDate = '';
          if (start.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')) {
            startDate = start.format('HH:mm');
          } else if (start.year === today.year)
            startDate = start.format('MM-DD HH:mm');
          else startDate = start.format('YYYY-MM-DD HH:mm');

          let endDate = '';
          if (end.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')) {
            endDate = end.format('HH:mm');
          } else if (end.year === today.year)
            endDate = end.format('MM-DD HH:mm');
          else endDate = end.format('YYYY-MM-DD HH:mm');

          const directoryData = getDirectoryData({
            ...directory,
            companyId,
            employeeId: item.employeeId,
          });
          const user = {
            name: directoryData.employeeName,
            avatar: directoryData.avatar,
            from: directoryData.organizationName,
          };
          return (
            <li className="template-timeline-content timeline-item">
              <div
                className="timeline-content-wrapper"
                onClick={() => handleRedirect(item.employeeId !== employeeId)}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 0,
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    flexShrink: 0,
                    marginLeft: 'auto',
                    fontSize: '12px',
                    color: 'var(--secondary-text-color)',
                  }}
                />
                <div className="timeline-separator">
                  <span className={className} />
                  <span className="timeline-connector" />
                </div>
                <div className="timeline-content">
                  <span className="subject">{item.name}</span>
                  <span className="resource">
                    <Icon
                      icon="module"
                      style={{
                        fontSize: '16px',
                        marginRight: '4px',
                        position: 'relative',
                        top: 1,
                      }}
                    />
                    <span className="resource-title">{item.itemName}</span>
                    <div className="status">
                      <Chip
                        label={replaceIsApproval(item.isApproval)}
                        theme={replaceTheme(item.isApproval)}
                        size="xs"
                      />
                    </div>
                  </span>
                  <span className="date">
                    {startDate && <span>{startDate}</span>}
                    {startDate && endDate && ' ~ '}
                    {endDate && <span>{endDate}</span>}
                  </span>
                </div>
                <div className="timeline-user-info">
                  <UserInfo
                    avatar={user.avatar}
                    name={user.name}
                    from={user.from}
                    dateType="dateNumber"
                  />
                </div>
              </div>
            </li>
          );
        })}
      </TimeLine>
    </div>
  );
}

export default ResourceReservationItemTemplate;
