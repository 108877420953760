import React from 'react';
import Button from '../../../../../components/button/Button';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';

function SecuritiesAuthorityHeadView(
  props: (
    | {
        /** 유형. (full: 전체 보기, split: 분할 보기) */
        type: 'full';
        /** 목록으로 이동 이벤트. */
        onListGo?(): void;
      }
    | {
        /** 유형. (full: 전체 보기, split: 분할 보기) */
        type: 'split';
        onListGo?: undefined;
      }
  ) & {
    /** 도구 모음 버튼 배열. */
    toolbarButtons: ActionEventProps[];
    /** 도구 모음 액션 이벤트. */
    onToolbarAction(arg: { code: string; event: React.MouseEvent }): void;
  },
): JSX.Element {
  const { type, onListGo, onToolbarAction } = props;

  return (
    <EuiToolbar className={`view-toolbar ${type}`}>
      <EuiToolbar.Left>
        {onListGo && (
          <Button
            text="목록으로 돌아가기"
            iconType
            icon="arrow-left"
            onClick={onListGo}
            className="back-list"
          />
        )}
        <ToolbarAction event={props.toolbarButtons} onClick={onToolbarAction} />
      </EuiToolbar.Left>
    </EuiToolbar>
  );
}

export default React.memo(SecuritiesAuthorityHeadView);
