import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EmptyData from '../../../../../components/data/EmptyData';
import Divider from '../../../../../components/divider/Divider';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import PostView from '../../../../../components/post/PostView';
import TextField from '../../../../../components/textfield/TextField';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import UploadImage from '../../../../../components/upload/UploadImage';
import UserInfo from '../../../../../components/user/UserInfo';
import { authenticationActions } from '../../../../../groupware-authentication/store/login';
import {
  getParentItems,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import {
  getEmployeeName,
  getJobDutyName,
  getJobPositionName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { parseDirectoryTreeItemId } from '../../../../../groupware-directory/stores/directory/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { informationActions } from '../../../../stores/information';
import SettingInformationPasswordChangeDialog from './SettingInformationPasswordChangeDialog';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SettingInformationContainer(arg: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = arg;
  const dispatch = useAppDispatch();

  const queryParams = getQueryParams(search);
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  ).filter(({ id }) => id === principal.companyId);
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );
  const categories = useSelector(
    (state: RootState) => state.setting.information.categories,
  );
  const person = useSelector(
    (state: RootState) => state.setting.information.person,
  );
  const directoryItems = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );

  if (!person)
    return (
      <EmptyData
        message={getLocalizedText(
          '데이터가 이동되었거나 삭제되어 조회할 수 없습니다.',
        )}
      />
    );

  const initialState = {
    dialog: undefined as 'password' | undefined,
    isUserAvatar: person.isUserAvatar,
    avatar: person.isUserAvatar ? person.avatar : '',
    avatarError: false,
    personalEmail: person.personalEmail,
    companyPhoneNo: person.companyPhoneNo,
    extensionPhoneNo: person.extensionPhoneNo,
    mobilePhoneNo: person.mobilePhoneNo,
    task: person.task,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [person]);

  const affiliatedOrganizations = organizationEmployees
    .filter(
      (a) =>
        a.companyId === principal.companyId &&
        a.employeeId === principal.employeeId,
    )
    .map((a) => {
      return {
        representative: a.id === person.representativeOrganizationId,
        jobDutyName: getJobDutyName(
          a.companyId,
          a.jobDutyId,
          getLocalizedText('미지정'),
        ),
        paths: getParentItems(
          directoryItems,
          parseDirectoryTreeItemId(a.companyId, a.id),
        ).map((b) => b.text),
      };
    });
  const employeeName = getEmployeeName(
    principal.companyId,
    principal.employeeId,
    '',
  );
  const organizationName = getOrganizationName(
    principal.companyId,
    principal.organizationId,
    '',
  );
  const jobPositionName = getJobPositionName(
    principal.companyId,
    person.jobPositionId,
    getLocalizedText('미지정'),
  );

  /** 아바타 오류 이벤트.  */
  const handleErrorAvatar = () => {
    setState((prev) => ({ ...prev, avatar: '', avatarError: true }));
  };

  /** 아바타 변경 이벤트. */
  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) setState((prev) => ({ ...prev, avatar: base64.toString() }));
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.

      const eventTargetValue = { ...event };
      eventTargetValue.target.value = ''; // 같은 파일을 다시 받는 경우때문에 value reset
    }
    setState((prev) => ({ ...prev, isUserAvatar: true }));
  };

  /** 아바타 삭제 이벤트. */
  const handleDeleteAvatar = () => {
    setState((prev) => ({
      ...prev,
      avatar: '',
      isUserAvatar: false,
    }));
  };

  /** 개인 이메일 변경 이벤트. */
  const handleChangePersonalEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      personalEmail: event.target.value,
    }));
  };

  /** 비밀번호 변경 클릭 이벤트. */
  const handleChangePassWordClick = () => {
    dispatch(authenticationActions.loginSetting()).then(() => {
      setState((prev) => ({ ...prev, dialog: 'password' }));
    });
  };

  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      dialog: undefined,
    }));
  };

  /** 회사전화 변경 이벤트. */
  const handleChangeCompanyPhoneNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      companyPhoneNo: event.target.value,
    }));
  };

  /** 내선전화 변경 이벤트. */
  const handleChangeExtensionPhoneNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      extensionPhoneNo: event.target.value,
    }));
  };

  /** 휴대전화 변경 이벤트. */
  const handleChangeMobilePhoneNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      mobilePhoneNo: event.target.value,
    }));
  };

  /** 업무 변경 이벤트. */
  const handleChangeTask = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      task: event.target.value,
    }));
  };

  const handleUpdateClick = (type: 'update' | 'cancel') => {
    if (type === 'update')
      dispatch(sessionActions.setDialog({ mode: 'update' }));
    else dispatch(sessionActions.setDialog());
  };

  /** 저장 이벤트. */
  const handleSave = () => {
    let avatar: string | undefined;
    if (person.avatar === state.avatar) avatar = undefined;
    else if (state.avatar.indexOf('data:') === 0) avatar = state.avatar;
    else if (!person.isUserAvatar && state.avatar === '') avatar = undefined;
    else avatar = '';

    const data = {
      id: person.id,
      avatar,
      personalEmail:
        state.personalEmail === person.personalEmail
          ? undefined
          : state.personalEmail,
      companyPhoneNo:
        state.companyPhoneNo === person.companyPhoneNo
          ? undefined
          : state.companyPhoneNo,
      extensionPhoneNo:
        state.extensionPhoneNo === person.extensionPhoneNo
          ? undefined
          : state.extensionPhoneNo,
      mobilePhoneNo:
        state.mobilePhoneNo === person.mobilePhoneNo
          ? undefined
          : state.mobilePhoneNo,
      task: state.task === person.task ? undefined : state.task,
      updateAt: person.updateAt,
    };
    delete queryParams.dialogMode;
    dispatch(
      informationActions.updateInformation({
        ...data,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  const renderDialog = () => {
    const { dialog } = state;
    if (dialog === 'password')
      return (
        <SettingInformationPasswordChangeDialog onClose={handleCloseDialog} />
      );

    return null;
  };

  const title = categories.find((a) => a.id === 'information')?.name ?? '';
  const renderContent = () => {
    const { dialogMode } = queryParams;
    if (dialogMode === 'update')
      return (
        <>
          <EuiHeader>
            <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
          </EuiHeader>
          <EuiBody>
            <PostView type="full">
              <PostView.Body>
                <div style={{ display: 'flex' }}>
                  <div className="user-info view-profile">
                    <UploadImage
                      path={state.avatar}
                      name={employeeName}
                      type="profile"
                      style={{ width: '64px', height: '64px' }}
                      onError={handleErrorAvatar}
                      onChange={handleChangeAvatar}
                      onDelete={handleDeleteAvatar}
                    />
                    <div className="info">
                      <span className="name">{employeeName}</span>
                      <span className="from">{organizationName}</span>
                    </div>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      text={getLocalizedText('취소')}
                      variant="outlined"
                      onClick={() => handleUpdateClick('cancel')}
                    />
                    <Button
                      noDuplication
                      text={getLocalizedText('저장')}
                      variant="contained"
                      onClick={handleSave}
                    />
                  </div>
                </div>
                <Divider />
                <PostView.Category type="text">
                  <PostView.CategoryList>
                    <PostView.CategoryItem
                      className="my-page-list"
                      title={getLocalizedText('이메일')}
                    >
                      <PostView.CategoryValue value={person.email} />
                      <div className="item-value">
                        <TextField
                          value={state.personalEmail}
                          onChange={handleChangePersonalEmail}
                        />
                        <em className="value-label">
                          {getLocalizedText('개인')}
                        </em>
                      </div>
                      <PostView.CategoryValue>
                        <Button
                          text={getLocalizedText('비밀번호 변경')}
                          variant="outlined"
                          size="sm"
                          onClick={handleChangePassWordClick}
                        />
                      </PostView.CategoryValue>
                    </PostView.CategoryItem>
                    <PostView.CategoryItem
                      className="my-page-list"
                      title={getLocalizedText('연락처')}
                    >
                      <div className="item-value">
                        <TextField
                          value={state.companyPhoneNo}
                          onChange={handleChangeCompanyPhoneNo}
                        />
                        <em className="value-label">
                          {getLocalizedText('회사전화')}
                        </em>
                      </div>
                      <div className="item-value">
                        <TextField
                          value={state.extensionPhoneNo}
                          onChange={handleChangeExtensionPhoneNo}
                        />
                        <em className="value-label">
                          {getLocalizedText('내선전화')}
                        </em>
                      </div>
                      <div className="item-value">
                        <TextField
                          value={state.mobilePhoneNo}
                          onChange={handleChangeMobilePhoneNo}
                        />
                        <em className="value-label">
                          {getLocalizedText('휴대전화')}
                        </em>
                      </div>
                    </PostView.CategoryItem>
                  </PostView.CategoryList>
                  <PostView.CategoryList title={getLocalizedText('회사/소속')}>
                    <PostView.CategoryItem
                      className="my-page-list"
                      title={getLocalizedText('소속')}
                    >
                      {affiliatedOrganizations.map((a) => {
                        const value = a.paths.join(' > ');
                        const label = a.representative
                          ? `${a.jobDutyName} ${getLocalizedText(
                              '[대표 조직]',
                            )}`
                          : a.jobDutyName;
                        return (
                          <PostView.CategoryValue
                            key={value}
                            value={value}
                            label={label}
                          />
                        );
                      })}
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title={getLocalizedText('입사일자')}>
                      <PostView.CategoryValue value={person.enterDate} />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title={getLocalizedText('사원번호')}>
                      <PostView.CategoryValue value={person.no} />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title={getLocalizedText('직위')}>
                      <PostView.CategoryValue value={jobPositionName} />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title={getLocalizedText('업무')}>
                      <TextField
                        value={state.task}
                        onChange={handleChangeTask}
                      />
                    </PostView.CategoryItem>
                  </PostView.CategoryList>
                </PostView.Category>
              </PostView.Body>
            </PostView>
          </EuiBody>
        </>
      );

    return (
      <>
        <EuiHeader>
          <EuiHeader.Title>{getLocalizedText('기본정보')}</EuiHeader.Title>
        </EuiHeader>
        <EuiBody>
          <PostView type="full">
            <PostView.Body>
              <div style={{ display: 'flex' }}>
                <UserInfo
                  className="view-profile"
                  name={employeeName}
                  from={organizationName}
                  avatar={person.avatar}
                />
                <Button
                  style={{ marginLeft: 'auto', padding: '0px' }}
                  text={
                    display === 'phone' ? undefined : getLocalizedText('수정')
                  }
                  icon="edit"
                  onClick={() => handleUpdateClick('update')}
                />
              </div>
              <Divider />
              <PostView.Category type="text">
                <PostView.CategoryList>
                  <PostView.CategoryItem
                    className="my-page-list"
                    title={getLocalizedText('이메일')}
                  >
                    <PostView.CategoryValue value={person.email} />
                    <PostView.CategoryValue
                      value={person.personalEmail}
                      label={getLocalizedText('개인')}
                    />
                    <PostView.CategoryValue>
                      <Button
                        text={getLocalizedText('비밀번호 변경')}
                        variant="outlined"
                        size="sm"
                        onClick={handleChangePassWordClick}
                      />
                    </PostView.CategoryValue>
                  </PostView.CategoryItem>
                  <PostView.CategoryItem
                    className="my-page-list"
                    title={getLocalizedText('연락처')}
                  >
                    <PostView.CategoryValue
                      value={person.companyPhoneNo}
                      label={getLocalizedText('회사전화')}
                    />
                    <PostView.CategoryValue
                      value={person.extensionPhoneNo}
                      label={getLocalizedText('내선전화')}
                    />
                    <PostView.CategoryValue
                      value={person.mobilePhoneNo}
                      label={getLocalizedText('휴대전화')}
                    />
                  </PostView.CategoryItem>
                </PostView.CategoryList>
                <PostView.CategoryList title={getLocalizedText('회사/소속')}>
                  <PostView.CategoryItem
                    className="my-page-list"
                    title={getLocalizedText('소속')}
                  >
                    {affiliatedOrganizations.map((a) => {
                      const value = a.paths.join(' > ');
                      const label = a.representative
                        ? `${a.jobDutyName} ${getLocalizedText('[대표 조직]')}`
                        : a.jobDutyName;
                      return (
                        <PostView.CategoryValue
                          key={value}
                          value={value}
                          label={label}
                        />
                      );
                    })}
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title={getLocalizedText('입사일자')}>
                    <PostView.CategoryValue value={person.enterDate} />
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title={getLocalizedText('사원번호')}>
                    <PostView.CategoryValue value={person.no} />
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title={getLocalizedText('직위')}>
                    <PostView.CategoryValue value={jobPositionName} />
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title={getLocalizedText('업무')}>
                    <PostView.CategoryValue value={person.task} />
                  </PostView.CategoryItem>
                </PostView.CategoryList>
              </PostView.Category>
            </PostView.Body>
          </PostView>
        </EuiBody>
      </>
    );
  };

  return (
    <>
      {renderContent()}
      {renderDialog()}
    </>
  );
}

export default SettingInformationContainer;
