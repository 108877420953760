import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/button/Button';
import Divider from '../../../../components/divider/Divider';
import Menu from '../../../../components/menu/Menu';
import MenuItem from '../../../../components/menu/MenuItem';
import EuiHeader from '../../../../components/layout/EuiHeader';
import { getQueryParams } from '../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';
import MenuDivider from '../../../../components/menu/MenuDivider';
import MenuGroup from '../../../../components/menu/MenuGroup';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../components/toolbarAction/ToolbarAction';
import Pagination from './common/components/Pagination';
import { RootState } from '../../../../groupware-webapp/app/store';
import Search, { SearchDateProps } from '../../../../components/search/Search';
import {
  dateFormat,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';

/**
 * @property title 표제.
 * @property totalCount 총 수.
 * @property checkedCount 체크된 수.
 * @property onCheckedChange 목록 아이템 체크 변경 이벤트.
 * @property onClick 클릭이벤트 단순 버튼 클릭이벤트.
 * @property onOpenDrawer Drawer Drawer 열기.
 * @property onOpenDialog Dialog Dialog 열기.
 * @property sortOptions 옵션 정렬 옵션.
 * @property sort 선택값 정렬 선택값.
 * @property onChangeSort Dialog 정렬 변경 이벤트.
 * @property filterOptions 옵션 필터 옵션.
 * @property filter 선택값 필터 선택값.
 * @property onChangeFilter Dialog 필터 변경 이벤트.
 */
type Props = {
  search: string;
  title: string;
  pageNo: number;
  rowsPerPage: number;
  totalCount: number;
  checkedCount: number;
  onCheckedChange(itemId: 'all', checked: boolean): void;
  sortOptions?: { value: string; label: string }[];
  sort?: string;
  onChangeSort?(value: string): void;
  filterOptions?: { value: string; label: string }[];
  filter?: string;
  onChangeFilter?(value: string): void;
  toolbarButtons: ActionEventProps[];
  searchCode?: string;
  searchWord?: string;
  onSearch?(arg: { code: string; word: string }): void;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
  onSearchAdvanced?(arg: {
    filters: { code: string; keyword: string }[];
    date: SearchDateProps;
  }): void;
};

function ApprovalContentHeadList(props: Props): JSX.Element {
  // console.log(`${ApprovalContentHeadList.name}.render(props)`, props);
  const display = useSelector((state: RootState) => state.session.display);
  const queryParams = getQueryParams(props.search);

  const [state, setState] = useState<{
    filterPopupRect:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>({
    filterPopupRect: undefined,
  });

  // 필터
  const handleFilterPopup = (event?: React.MouseEvent) => {
    if (state.filterPopupRect)
      setState((prevState) => ({
        ...prevState,
        filterPopupRect: undefined,
      }));
    else if (event) {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        filterPopupRect: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  // 액션 더보기
  const {
    onCheckedChange,
    sortOptions,
    sort,
    onChangeSort,
    filterOptions,
    filter,
    onChangeFilter,
    searchCode = 'subject',
    searchWord = '',
  } = props;

  const handleFilter = (value: string) => {
    handleFilterPopup();
    if (onChangeFilter) onChangeFilter(value);
  };

  const handleSort = (value: string) => {
    handleFilterPopup();
    if (onChangeSort) onChangeSort(value);
  };

  /** 기본검색 */
  const handleSearch = (arg: { keyword: string; filter: string }) => {
    const { onSearch } = props;
    if (onSearch) onSearch({ code: arg.filter, word: arg.keyword });
  };

  /** 상세검색 */
  const handleSearchAdvanced = (arg: {
    filters: { code: string; keyword: string }[];
    date: SearchDateProps;
  }) => {
    const { onSearchAdvanced } = props;
    if (onSearchAdvanced) onSearchAdvanced(arg);
  };

  /** 툴바 버튼 클릭 */
  const handleActionClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    props.onAction({ code: arg.code, event: arg.event });
  };

  const { title, pageNo, rowsPerPage, totalCount, checkedCount, onAction } =
    props;

  const searchOptions: { label: string; value: string }[] = [
    { value: 'subject', label: getLocalizedText('제목') },
    {
      value: 'documentNumber',
      label: getLocalizedText('문서번호'),
    },
    { value: 'workName', label: getLocalizedText('업무명') },
    { value: 'drafterName', label: getLocalizedText('기안자') },
    { value: 'content', label: getLocalizedText('본문') },
  ];

  const advancedOptions = [
    { value: 'subject', label: getLocalizedText('제목') },
    {
      value: 'documentNumber',
      label: getLocalizedText('문서번호'),
    },
    { value: 'workName', label: getLocalizedText('업무명') },
    { value: 'formName', label: getLocalizedText('양식명') },
    { value: 'content', label: getLocalizedText('본문') },
    {
      value: 'attachedFileName',
      label: getLocalizedText('첨부파일명'),
    },
    { value: 'drafterName', label: getLocalizedText('기안자') },
    {
      value: 'draftOrganizationName',
      label: getLocalizedText('기안부서'),
    },
    {
      value: 'approvalTargetName',
      label: getLocalizedText('결재대상'),
    },
  ];

  const advancedFilter = [
    { code: 'subject', keyword: queryParams.subject ?? '' },
    { code: 'documentNumber', keyword: queryParams.documentNumber ?? '' },
    { code: 'workName', keyword: queryParams.workName ?? '' },
    { code: 'formName', keyword: queryParams.formName ?? '' },
    { code: 'content', keyword: queryParams.content ?? '' },
    { code: 'attachedFileName', keyword: queryParams.attachedFileName ?? '' },
    { code: 'drafterName', keyword: queryParams.drafterName ?? '' },
    {
      code: 'draftOrganizationName',
      keyword: queryParams.draftOrganizationName ?? '',
    },
    {
      code: 'approvalTargetName',
      keyword: queryParams.approvalTargetName ?? '',
    },
  ];

  /** 날짜 필터 */
  let dateFilter = {
    type: queryParams.dateType ? queryParams.dateType : 'all',
    start: queryParams.startDate ? timezoneDate(queryParams.startDate) : null,
    end: queryParams.endDate ? timezoneDate(queryParams.endDate) : null,
  };

  /** 상세검색 초기화 */
  const handleResetAdvanced = () => {
    advancedFilter.map((a) => ({
      ...a,
      keyword: '',
    }));
    dateFilter = {
      type: 'all',
      start: null,
      end: null,
    };
  };

  const isAdvancedSearch =
    advancedFilter.some((a) => a.keyword !== '') ||
    dateFilter.start !== null ||
    dateFilter.end !== null;

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <Search
            keyword={searchWord}
            options={searchOptions}
            advancedOptions={advancedOptions}
            advancedFilter={advancedFilter}
            filter={searchCode}
            onSearch={handleSearch}
            onResetAdvanced={handleResetAdvanced}
            onSearchAdvanced={handleSearchAdvanced}
            date={dateFilter}
          />
        </EuiHeader.Search>
        {isAdvancedSearch ? (
          <EuiHeader.SearchResult>
            {advancedFilter.map((a) => {
              if (a.keyword !== '')
                return (
                  <>
                    <strong className="category">
                      {`${
                        (
                          advancedOptions.find(
                            (option) => option.value === a.code,
                          ) || {}
                        ).label
                      } : `}
                    </strong>
                    <em className="keyword">{a.keyword}</em>
                  </>
                );
              return null;
            })}
            {dateFilter.start !== null && dateFilter.end !== null && (
              <div className="category-wrap">
                <strong className="category">
                  {getLocalizedText('기간')}:
                </strong>
                <em className="keyword">{`${dateFormat(
                  dateFilter.start,
                  'yyyy-MM-DD',
                )} ~ ${dateFormat(dateFilter.end, 'yyyy-MM-DD')}`}</em>
              </div>
            )}
          </EuiHeader.SearchResult>
        ) : null}
        <EuiHeader.Toolbar>
          {checkedCount === 0 ? (
            <>
              <EuiHeader.ToolbarLeft>
                <Button
                  className="check-all"
                  text={getLocalizedText('선택')}
                  iconType
                  icon="list-check"
                  onClick={() => {
                    onCheckedChange('all', true);
                  }}
                />
                {(sortOptions !== undefined || filterOptions !== undefined) && (
                  <Button
                    text={getLocalizedText('필터')}
                    iconType
                    icon="filter-list"
                    onClick={handleFilterPopup}
                  />
                )}
                {/* <Button
                  text={getLocalizedText('모두 읽은 상태로 표시')}
                  onClick={(event) =>
                    onAction({ code: 'read', event })
                  }
                /> */}
              </EuiHeader.ToolbarLeft>
              <EuiHeader.ToolbarRight>
                {totalCount > 0 && (
                  <Pagination
                    no={pageNo}
                    rows={rowsPerPage}
                    count={totalCount}
                    onPrev={(event) => onAction({ code: 'prevPage', event })}
                    onNext={(event) => onAction({ code: 'nextPage', event })}
                  />
                )}
                {totalCount > 0 && display !== 'phone' && (
                  <Divider orientation="vertical" />
                )}
                {display !== 'phone' && (
                  <Button
                    text={getLocalizedText('목록설정')}
                    iconType
                    icon="bar-cog"
                    onClick={(event) =>
                      onAction({ code: 'listSetting', event })
                    }
                  />
                )}
              </EuiHeader.ToolbarRight>
            </>
          ) : (
            <EuiHeader.ToolbarLeft>
              <Button
                className="action-close"
                text={getLocalizedText('취소')}
                iconType
                icon="close"
                onClick={() => {
                  props.onCheckedChange('all', false);
                }}
                vibrate
              />
              <span className="selected-count">
                <em>{props.checkedCount}</em>{' '}
                <span>{getLocalizedText('개 선택됨')}</span>
              </span>
              <ToolbarAction
                event={props.toolbarButtons}
                onClick={handleActionClick}
              />
            </EuiHeader.ToolbarLeft>
          )}
        </EuiHeader.Toolbar>
      </EuiHeader>
      {state.filterPopupRect && (
        <Menu
          point={state.filterPopupRect}
          onClose={handleFilterPopup}
          size="sm"
        >
          {filterOptions && (
            <>
              <MenuGroup>{getLocalizedText('필터')}</MenuGroup>
              {filterOptions.map((x) => (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  checked={x.value === filter}
                  onClick={() => handleFilter(x.value)}
                />
              ))}
            </>
          )}
          {sortOptions && (
            <>
              <MenuDivider />
              <MenuGroup>{getLocalizedText('정렬')}</MenuGroup>
              {sortOptions.map((x) => (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  checked={x.value === sort}
                  onClick={() => handleSort(x.value)}
                />
              ))}
            </>
          )}
        </Menu>
      )}
    </>
  );
}

export default ApprovalContentHeadList;
