/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig } from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../groupware-common/apis/common/v1';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../../pages/common/dialogs/ApprovalLineDialogContainer';
import { DocumentItem } from './permission';
import { AdvancedSearchParams } from '../../../../groupware-common/utils';

const namespace = 'approval';

function getNamespace(): string {
  return namespace;
}

async function findLastUpdateAt() {
  const date = new Date();
  // 10 초 간격으로 시간 변경 되도록 테스트 코드.
  // date.setSeconds(Math.ceil(date.getSeconds() / 10) * 10, 0);
  return date.toISOString();
}

/** 나의 기안 리스트 불러오기 */
async function findMyWishList(arg: { pageno: number; rowsperpage: number }) {
  const { pageno, rowsperpage } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/state/folders/1001/documents`;
    const response = await axios.get<{ count?: number; list: DocumentItem[] }>(
      url,
      {
        headers,
        params: {
          pageno,
          rowsperpage,
        },
      },
    );
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// 회수, 임시보관 문서 삭제
async function ListMoveToTrashApproval(arg: {
  data: {
    companyId: number;
    id: number;
    folderId: number;
    updateAt: string;
  }[];
}) {
  const { host, headers } = getApiConfig();
  // 회수, 임시보관
  if (arg.data[0].folderId === 1006 || arg.data[0].folderId === 1007) {
    const param: {
      companyId: number;
      status: number;
      id: number;
      updateAt: string;
    }[] = [];

    let status = 0;
    switch (arg.data[0].folderId) {
      // 회수
      case 1006:
        status = 12;
        break;
      // 임시보관
      default:
        status = 10;
    }

    arg.data.forEach((x) => {
      param.push({
        companyId: x.companyId,
        status,
        id: x.id,
        updateAt: x.updateAt,
      });
    });
    try {
      const url = `${host}/api/approval/v1/document/status/remove`;
      const response = await axios.delete(url, { headers, data: param });
      return response.data;
    } catch (ex) {
      throw apiError(ex);
    }
  }
}

// 반려 문서 삭제
async function ReturnDocumentListMoveToTrashApproval(arg: {
  data: {
    affiliatedCompanyId?: number;
    id: number;
  }[];
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/return`;
    const response = await axios.delete(url, { headers, data: arg.data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function ListDeleteApproval(
  data: {
    id: number;
    updateAt: string;
  }[],
) {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/document`;
  const response = await axios.get(url, { headers, data });
  return response.data;
}

async function ListAllReadApproval(
  data: {
    id: number;
    updateAt: string;
  }[],
) {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/document`;
  const response = await axios.get(url, { headers, data });
  return response.data;
}

async function ListPublicSave(
  data: {
    id: number;
    public: boolean;
    updateAt: string;
  }[],
) {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/document`;
  const response = await axios.get(url, { headers, data });
  return response.data;
}

async function ListBatchApproval(
  data: {
    id: number;
    updateAt: string;
  }[],
) {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/document`;
  const response = await axios.get(url, { headers, data });
  return response.data;
}

async function fetchArbitraryDecisionApi() {
  /*
    const url = `https://localhost/${param1}/${param2}`
    const { data } = await axios.get<Board[]>(url)
    return data
    */
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/arbitrarydecision/all`;
  const response = await axios.get(url, config);
  return response.data;
}

async function savefetchArbitraryDecisionApi(
  arg: (
    | {
        organizationId?: number;
        employeeId?: number;
      }
    | undefined
  )[],
) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/arbitrarydecision`;
  const response = await axios.post(url, arg, config);
  return response.data;
}

async function delfetchArbitraryDecisionApi(
  arg: (
    | {
        organizationId?: number;
        employeeId?: number;
        updateAt?: string;
      }
    | undefined
  )[],
) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
    data: arg,
  };
  const url = `${host}/api/approval/v1/arbitrarydecision`;
  const response = await axios.delete(url, config);
  return response.data;
}

async function fetchUserPreferencesApi(): Promise<{
  companyId: number;
  employeeId: number;
  useSurrogate: boolean;
  surrogateStartDate: string;
  surrogateEndDate: string;
  surrogateEmployeeId: number;
  surrogateOrganizationId: number;
  useSecurity: boolean;
  autosave: number;
  signaturePath?: string;
  usePrintInfo: boolean;
  usePrintOpinion: boolean;
  usePrintComment: string;
  createAt: string;
  updateAt: string;
}> {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences`;
  const response = await axios.get(url, config);
  return response.data;
}

/** 개인 알림설정 객체 */
export interface NoticeRead {
  companyId: number; // 회사 아이디.
  employeeId: number; // 직원 아이디.
  noticeEmail: string; // 알림 메일 주소.
  noticeRequest: number; // 결재 요청 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeReturn: number; // 결재 반려 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticePreviousReturn: number; // 전단계 반려 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeHold: number; // 보류 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeInterview: number; // 대면 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeDefer: number; // 후결 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeCompleted: number; // 결재 완료 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeApprovalPermissionDocumentCancel: number; // 문서 취소 (기안자, 중간결재자) 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeReferencePermissionDocumentCancel: number; // 문서 취소 (참조자) 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeViewPermissionDocumentCancel: number; // 문서 취소 (조회자) 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeApprovalPermissionDocumentChange: number; // 문서 변경 (기안자, 중간결재자) 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeReferencePermissionDocumentChange: number; // 문서 변경 (기안자, 중간결재자) 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeViewPermissionDocumentChange: number; // 문서 변경 (기안자, 중간결재자) 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeReferrer: number; // 참조 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeViewer: number; // 조회 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeOpinion: number; // 결재 의견 등록 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeOpinionReply: number; // 결재 의견 답글 등록 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeComment: number; // 결재 댓글 등록 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  noticeCommentReply: number; // 결재 댓글 답글 등록 알림 - 1: 알림, 2: 메일, 3: 알림 메일
  createAt: string; // 생성 날짜.
  updateAt: string; // 수정 날짜.
}

async function fetchUserPreferencesNoticeApi(): Promise<NoticeRead> {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences/notice`;
  const response = await axios.get(url, config);
  return response.data;
}

async function savefetchUserPreferencesApi1(arg: {
  useSurrogate: boolean;
  surrogateStartDate: string;
  surrogateEndDate: string;
  surrogateEmployeeId: number;
  surrogateOrganizationId: number;
  useSecurity: boolean;
  autosave: number | boolean;
  usePrintInfo: boolean;
  usePrintOpinion: boolean;
  updateAt: string;
  signature?: string | undefined;
}) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences`;
  const response = await axios.put(url, arg, config);
  return response.data;
}

async function savefetchUserPreferencesNoticeApi(arg: {
  noticeEmail: string;
  noticeRequest: number;
  noticeReturn: number;
  noticePreviousReturn: number;
  noticeHold: number;
  noticeInterview: number;
  noticeDefer: number;
  noticeCompleted: number;
  noticeApprovalPermissionDocumentCancel: number;
  noticeReferencePermissionDocumentCancel: number;
  noticeViewPermissionDocumentCancel: number;
  noticeApprovalPermissionDocumentChange: number;
  noticeReferencePermissionDocumentChange: number;
  noticeViewPermissionDocumentChange: number;
  noticeReferrer: number;
  noticeViewer: number;
  noticeOpinion: number;
  noticeOpinionReply: number;
  noticeComment: number;
  noticeCommentReply: number;
  updateAt: string;
}) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences/notice`;
  const response = await axios.put(url, arg, config);
  return response.data;
}

async function fetchPreferencesFolderApi() {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/userpreferences/folder/all`;
  const response = await axios.get(url, { headers });
  return response.data;
}

async function savefetchPreferencesFolderApi(arg: {
  status: number;
  name: string;
  description: string;
}) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences/folder`;
  const response = await axios.post(url, arg, config);
  return response.data;
}

async function modefetchPreferencesFolderApi(arg: {
  id: number;
  status: number;
  name: string;
  description: string;
  updateAt: string;
}) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences/folder`;
  const response = await axios.put(url, arg, config);
  return response.data;
}

async function deletefetchPreferencesFolderApi(arg: {
  id: number;
  updateAt: string;
}) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
    data: arg,
  };
  const url = `${host}/api/approval/v1/userpreferences/folder`;
  const response = await axios.delete(url, config);
  return response.data;
}

async function modefetchPreferencesFolderSeqApi(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/userpreferences/folder/seq`;
  const response = await axios.put(url, arg, config);
  return response.data;
}

// 개인결재선 리스트.
export interface ApprovalLine {
  id: number;
  seq: number;
  name: string;
  workChange: boolean;
  updateAt: string;
}

// 개인 결재선 보기.
export interface ApprovalLineView {
  id: number;
  name: string;
  workId: number;
  workName: string;
  workChange: boolean;
  approvalLine: ApprovalLineType;
  referencePermission: SharePermissionType | undefined;
  viewPermission: SharePermissionType | undefined;
  workUpdateAt: string;
  createAt: string;
  updateAt: string;
}

// 개인결재선 저장
async function savePreferencesApprovalLine(arg: {
  workId: number;
  name: string;
  approvalLine: string;
  referencePermission: string;
  viewPermission: string;
  workUpdateAt: string;
  id?: number;
  updateAt?: string;
  workName?: string;
}) {
  const {
    workId,
    name,
    approvalLine,
    referencePermission,
    viewPermission,
    workUpdateAt,
    id,
    updateAt,
  } = arg;
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/personal/approvalline`;
  const response =
    arg.id === undefined
      ? await axios.post<{
          id: number;
          seq: number;
          updateAt: string;
        }>(
          url,
          {
            name,
            workId,
            approvalline: approvalLine,
            referencePermission,
            viewPermission,
            workUpdateAt,
          },
          { headers },
        )
      : await axios.put<{
          id: number;
          updateAt: string;
        }>(
          url,
          {
            id,
            name,
            approvalline: approvalLine,
            referencePermission,
            viewPermission,
            updateAt,
          },
          { headers },
        );
  return response.data;
}

// 개인결재선 전체 조회
async function PreferencesApprovalLineList(workId?: number | undefined) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  let url = `${host}/api/approval/v1/personal/approvalline/all/simply`;
  if (workId !== undefined) url += `?workid=${workId}`;
  const response = await axios.get<ApprovalLine[]>(url, config);
  return response.data;
}

// 개인결재선 조회
async function PreferencesApprovalLineView(id: number) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
  };
  const url = `${host}/api/approval/v1/personal/approvalline?id=${id}`;
  const response = await axios.get<{
    id: number;
    name: string;
    workId: number;
    workName: string;
    workChange: boolean;
    approvalline: string;
    referencePermission: string;
    viewPermission: string;
    createAt: string;
    updateAt: string;
    workUpdateAt: string;
  }>(url, config);
  return response.data;
}

// 개인결재선 삭제
async function deletePreferencesApprovalLine(arg: {
  id: number;
  updateAt: string;
}) {
  const { host, headers } = getApiConfig();
  const config: AxiosRequestConfig = {
    headers,
    data: arg,
  };
  const url = `${host}/api/approval/v1/personal/approvalline`;
  const response = await axios.delete(url, config);
  return response.data;
}

// 개인결재선 순서변경
async function updatePreferencesApprovalLineOrder(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
) {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/approval/v1/personal/approvalline/sequence/all`;
  const response = await axios.put<
    {
      id: number;
      seq: number;
      updateAt: string;
    }[]
  >(url, arg, { headers });
  return response.data;
}

/**
 * 상태함 문서 목록을 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function documents(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<{
  count?: number; // 문서 총 갯수.
  list: DocumentItem[];
}> {
  const { folderId, pageno, rowsperpage, advancedSearch } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/state/folders/${folderId}/documents`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 상태함 문서 총 개수를 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function totalCount(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<{ count: number }> {
  const { folderId, pageno, rowsperpage, advancedSearch } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/state/folders/${folderId}/documents/count`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

export default {
  getNamespace,

  documents,
  totalCount,

  fetchArbitraryDecisionApi,
  savefetchArbitraryDecisionApi,
  delfetchArbitraryDecisionApi,

  fetchUserPreferencesApi,
  fetchUserPreferencesNoticeApi,
  savefetchUserPreferencesApi1,
  savefetchUserPreferencesNoticeApi,
  savefetchPreferencesFolderApi,
  fetchPreferencesFolderApi,
  modefetchPreferencesFolderApi,
  deletefetchPreferencesFolderApi,
  modefetchPreferencesFolderSeqApi,

  findLastUpdateAt,
  findMyWishList,

  PreferencesApprovalLineList,
  PreferencesApprovalLineView,
  deletePreferencesApprovalLine,
  savePreferencesApprovalLine,
  updatePreferencesApprovalLineOrder,

  ListBatchApproval,
  ListMoveToTrashApproval,
  ReturnDocumentListMoveToTrashApproval,
  ListDeleteApproval,
  ListAllReadApproval,
  ListPublicSave,
};
