import React, { useState } from 'react';
import Button from '../../../../../components/button/Button';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import Alert from '../../../../../components/alert/Alert';
import { createLocalizedTextFactory } from '../../../../../groupware-common/utils/i18n';

function ApprovalWorkDocumentNoDialog(props: {
  no: string;
  onConfirm(no: string): void;
  onClose(): void;
}): JSX.Element {
  // console.log(`${ApprovalWorkDocumentNoDialog.name}.render(props)`, props);

  const getLocalizedText = createLocalizedTextFactory('approval');

  const options = [
    {
      value: '┼',
      label: getLocalizedText('없음'),
    },
    {
      value: '제',
      label: getLocalizedText('제'),
    },
    { value: '{년도}', label: getLocalizedText('년도') },
    { value: '{월}', label: getLocalizedText('월') },
    { value: '{순번}', label: getLocalizedText('순번') },
    {
      value: '{업무명}',
      label: getLocalizedText('업무명'),
    },
    {
      value: '{양식명}',
      label: getLocalizedText('양식명'),
    },
    {
      value: '{조직명}',
      label: getLocalizedText('조직명'),
    },
    {
      value: '{기안자}',
      label: getLocalizedText('기안자'),
    },
  ];
  const separators = [
    {
      value: '┼',
      label: getLocalizedText('없음'),
    },
    { value: ' ', label: getLocalizedText('공백') },
    { value: '-', label: '-' },
    { value: ':', label: ':' },
  ];

  const [items, setItems] = useState(() => {
    let result = [
      { select: '제', input: '', extra: '' },
      { select: ' ', input: '', extra: '' },
      { select: '{년도}', input: '', extra: '' },
      { select: '┼', input: '', extra: '' },
      { select: '┼', input: '', extra: '' },
      { select: '┼', input: '', extra: '' },
      { select: '┼', input: '', extra: '' },
      { select: '┼', input: '', extra: '' },
      { select: '┼', input: '', extra: '' },
    ];

    // console.log(`props.no: ${props.no}`);

    const values = [
      ...options.map(({ value }) => value),
      ...separators.map(({ value }) => value),
    ];
    let { no } = props;
    let loop = 0;
    while (no !== '' && loop < result.length) {
      // console.log(`loop: ${loop}`);
      let select = '';
      for (let i = 0; i < values.length; i += 1) {
        if (no.indexOf(values[i]) === 0) {
          const n = values[i].length;
          select = no.substring(0, n);
          // const target = no.substring(n);
          // console.log(`no: '${no}', select: '${select}', target: ${target}`);
          no = no.substring(n);
          break;
        }
      }

      // eslint-disable-next-line no-loop-func
      result = result.map((a, index) => {
        if (index !== loop) return a;
        if (select === '') {
          let n = no.indexOf('┼');
          n = n !== -1 ? n : no.indexOf(' ');
          if (n > -1) {
            select = no.substring(0, n);
            no = no.substring(n);
          } else {
            select = no;
            no = '';
          }
          return { select, input: select, extra: select };
        }
        return { select, input: '', extra: '' };
      });

      loop += 1;
    }

    // console.log(`result`, result);

    return result;
  });

  const handleItemSelectChange = (arg: { index: number; select: string }) => {
    // console.log(`handleItemSelectChange(arg)`, arg);
    const { index, select } = arg;
    setItems((prevItem) =>
      prevItem.map((a, i) => {
        if (i !== index) return a;
        const { extra } = a;
        const input = select === extra ? extra : '';
        return { select, input, extra };
      }),
    );
  };

  const handleItemInputChange = (arg: { index: number; input: string }) => {
    // console.log(`handleItemInputChange(arg)`, arg);
    const { index, input } = arg;
    setItems((prevItem) =>
      prevItem.map((a, i) => {
        if (i !== index) return a;
        return { select: a.select, input, extra: a.extra };
      }),
    );
  };

  const handleItemInputBlur = (arg: { index: number; value: string }) => {
    // console.log(`handleItemInputBlur(arg)`, arg);
    const { index } = arg;
    const value = arg.value.trim();
    const select = value === '' ? '┼' : value;
    setItems((prevItem) =>
      prevItem.map((a, i) => {
        if (i !== index) return a;
        return { select, input: value, extra: value };
      }),
    );
  };

  const handleConfirm = () => {
    // console.log(`handleConfirm()`);
    const no = items.map(({ select }) => select).join('');
    // console.log(`no`, no);
    props.onConfirm(no);
  };

  const { onClose } = props;
  const preview = items
    .filter(({ select }) => select !== '┼')
    .map(({ select }) => select)
    .join('')
    .replace(/{([^}]*)}/gm, '$1');

  return (
    <Dialog size="sm">
      <DialogHeader>
        <DialogTitle>{getLocalizedText('문서번호')}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <div className="ui-document-number">
          <Alert severity="info">
            {getLocalizedText(
              '좌측의 선택항목에서 선택하거나 우측의 빈칸에 새로운 값을 입력하십시오',
            )}
          </Alert>
          <dl className="preview">
            <dt>{getLocalizedText('미리보기')}</dt>
            <dd>{preview}</dd>
          </dl>
          {items.map((item, index) => {
            const key = `${index}`;
            const temp = index % 2 === 0 ? options : separators;
            const data =
              item.extra === ''
                ? temp
                : [...temp, { value: item.extra, label: item.extra }];
            return (
              <div key={key} className="item">
                <SelectField
                  data={data}
                  value={item.select}
                  onChange={(select) =>
                    handleItemSelectChange({ index, select })
                  }
                />
                <TextField
                  value={item.input}
                  onChange={(event) =>
                    handleItemInputChange({ index, input: event.target.value })
                  }
                  onBlur={(event) =>
                    handleItemInputBlur({ index, value: event.target.value })
                  }
                />
              </div>
            );
          })}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button text={getLocalizedText('취소')} onClick={onClose} />
        <Button
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={handleConfirm}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default ApprovalWorkDocumentNoDialog;
