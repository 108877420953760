import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function HTTP404(props: { message?: string }): JSX.Element {
  const { message } = props;

  return (
    <div className="eui-error-page">
      <img src="/images/404.svg" alt={getLocalizedText('서버 오류 이미지')} />
      <h1>{getLocalizedText('페이지를 찾을 수 없습니다.')}</h1>
      {!message ? (
        <>
          <p>
            {getLocalizedText(
              '존재하지 않는 주소를 입력하였거나, 요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.',
            )}
          </p>
        </>
      ) : (
        <>
          <p>{message}</p>
        </>
      )}
    </div>
  );
}

export default HTTP404;
