import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import DocumentAuthorityRoute from '../pages/adminconsole/document/authority/DocumentAuthorityRoute';
import DocumentCheckoutRoute from '../pages/adminconsole/document/checkout/DocumentCheckoutRoute';
import DocumentFolderBoxRoute from '../pages/adminconsole/document/folderbox/company/DocumentFolderBoxRoute';
import DocumentFormRoute from '../pages/adminconsole/document/form/DocumentFormRoute';
import DocumentPreferencesRoute from '../pages/adminconsole/document/preferences/DocumentPreferencesRoute';
import DocumentTrashRoute from '../pages/adminconsole/document/trash/DocumentTrashRoute';

function AdminConsoleLocateRoute(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 문서함
  if (menu === 'folderbox')
    return (
      <DocumentFolderBoxRoute pathname={props.pathname} search={props.search} />
    );
  // 양식 관리
  if (menu === 'form') return <DocumentFormRoute pathname={props.pathname} />;
  // 일괄권한 관리
  if (menu === 'authority')
    return (
      <DocumentAuthorityRoute pathname={props.pathname} search={props.search} />
    );
  // 삭제함 관리
  if (menu === 'trash')
    return <DocumentTrashRoute pathname={pathname} search={search} />;
  // 체크아웃 관리
  if (menu === 'checkout')
    return <DocumentCheckoutRoute pathname={pathname} search={search} />;

  return <DocumentPreferencesRoute pathname={pathname} />;
}

export default AdminConsoleLocateRoute;
