import React from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import { getPathParams } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { RootState } from '../../../../../groupware-webapp/app/store';
import AttendanceDayOffAccountStatusContainer from './AttendanceDayOffAcountStatusContainer';
import AttendanceDayOffEnterStatusContainer from './AttendanceDayOffEnterStatusContainer';

function AttendanceDayOffStatusContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { folderId } = getPathParams<{ folderId: string }>(
    '/*/:folderId',
    pathname,
  );
  const folders = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  );
  const leave = useSelector(
    (state: RootState) => state.attendance.preferences.standard.leave,
  );
  const title = folders.find(({ id }) => id === folderId)?.name ?? '';

  if (leave === undefined)
    return <EmptyData message={getLocalizedText('생성된 연차가 없습니다.')} />;

  if (leave.leaveOccurType === 1)
    return (
      <AttendanceDayOffEnterStatusContainer
        pathname={pathname}
        search={search}
        title={getLocalizedText(`폴더.${title}`)}
      />
    );

  return (
    <AttendanceDayOffAccountStatusContainer
      pathname={pathname}
      search={search}
      title={getLocalizedText(`폴더.${title}`)}
    />
  );
}

export default AttendanceDayOffStatusContainer;
