import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import ServiceMenuRoute from '../pages/adminconsole/service/menu/ServiceMenuRoute';
import ServiceBasicRoute from '../pages/adminconsole/service/basic/ServiceBasicRoute';
import ServiceLinkMenuRoute from '../pages/adminconsole/service/linkmenu/ServiceLinkMenuRoute';

function AdminconsoleLocateRoute(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 메뉴 관리
  if (menu === 'menu')
    return <ServiceMenuRoute pathname={pathname} search={search} />;
  // 링크 메뉴 관리
  if (menu === 'link')
    return <ServiceLinkMenuRoute pathname={pathname} search={search} />;
  return <ServiceBasicRoute pathname={pathname} search={search} />;
}

export default AdminconsoleLocateRoute;
