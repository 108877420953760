import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import path from 'path';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import {
  filenameExtensionIcon,
  readableBytes,
} from '../../groupware-common/utils/ui';
import Button from '../button/Button';
import { FileUploadProps } from '../../groupware-common/types';
import Divider from '../divider/Divider';
import FeedBack from '../alert/FeedBack';
import LiearProgress from '../progress/LiearProgress';

function AddProgressAttachments(props: {
  data: FileUploadProps[];
  maxCount: number;
  maxCapacity: number;
  onRemove(id: string): void;
  onFileUpload(arg: FileUploadProps[]): void;
  onSortable(arg: FileUploadProps[]): void;
}): JSX.Element {
  const [count, setCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');

  const handleSnackbarClose = () => {
    setAlertMessage('');
  };
  const handleEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setCount((prevState) => prevState + 1);
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setCount((prevState) => prevState - 1);
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const { dataTransfer } = event;
    const { files } = dataTransfer;
    const file: FileUploadProps[] = [];

    let totalSize = 0;
    for (let i = 0; i < files.length; i += 1) {
      if (dataTransfer.items[i].webkitGetAsEntry()?.isDirectory) {
        setAlertMessage(getLocalizedText('폴더는 첨부하실 수 없습니다.'));
        break;
      }
      file.push({
        id: `${Date.now() + i}`,
        name: files[i].name,
        size: files[i].size,
        file: files[i],
        progress: 0,
        isUploaded: false,
      });

      totalSize += files[i].size;
    }

    if (props.data.length + files.length > props.maxCount) {
      setAlertMessage(
        getLocalizedText(
          '파일 첨부는 {{n}}개까지 가능합니다. 다시 선택해주세요.',
          {
            n: props.maxCount,
          },
        ),
      );
    } else if (totalFileSize + totalSize > props.maxCapacity) {
      setAlertMessage(
        getLocalizedText(
          '파일 첨부는 {{n}}까지 가능합니다. 다시 선택해주세요.',
          {
            n: readableBytes(props.maxCapacity, 1),
          },
        ),
      );
    } else {
      props.onFileUpload(file);
    }

    setCount(0);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const file: FileUploadProps[] = [];

    let totalSize = 0;
    for (let i = 0; i < files.length; i += 1) {
      file.push({
        id: `${Date.now() + i}`,
        name: files[i].name,
        size: files[i].size,
        file: files[i],
        progress: 0,
        isUploaded: false,
      });

      totalSize += files[i].size;
    }

    if (props.data.length + files.length > props.maxCount) {
      setAlertMessage(
        getLocalizedText(
          '파일 첨부는 {{n}}개까지 가능합니다. 다시 선택해주세요.',
          {
            n: props.maxCount,
          },
        ),
      );
    } else if (totalFileSize + totalSize > props.maxCapacity) {
      setAlertMessage(
        getLocalizedText(
          '파일 첨부는 {{n}}까지 가능합니다. 다시 선택해주세요.',
          {
            n: readableBytes(props.maxCapacity, 1),
          },
        ),
      );
    } else {
      props.onFileUpload(file);

      const eventTargetValue = { ...event };
      eventTargetValue.target.value = '';
    }

    setCount(0);
  };

  const completeFileList = props.data.filter((x) => x.progress === 100);

  let totalFileSize = 0;
  completeFileList.forEach((x) => {
    totalFileSize += x.size;
  });

  let classname = 'eui-file-upload';
  if (count > 0) classname += ' enter';

  return (
    <div
      className={classname}
      onDragEnter={handleEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleLeave}
      onDrop={handleDrop}
    >
      <div className="file-info">
        <strong className="title">{getLocalizedText('파일첨부')}</strong>
        <em className="count">
          {getLocalizedText('{{n}}개', {
            n: completeFileList.length,
          })}
          /{getLocalizedText('{{n}}개', { n: props.maxCount })}
        </em>
        <Divider orientation="vertical" />
        <em className="size">
          {`${readableBytes(totalFileSize, 1)} / ${readableBytes(
            props.maxCapacity,
            1,
          )}`}
        </em>
      </div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={count > 0 ? 'drag-here enter' : 'drag-here'}>
        <input type="file" multiple onChange={handleFileUpload} />
        {getLocalizedText('여기에 파일을 끌어다 놓거나 클릭하세요.')}
      </label>
      {props.data.length > 0 && props.onSortable && (
        <ReactSortable
          className="file-list"
          list={props.data}
          setList={props.onSortable}
          animation={200}
          delayOnTouchOnly
          delay={100}
        >
          {props.data.map((x) => {
            return (
              <div key={x.id} className="file-item">
                <div
                  className="category"
                  data-file-format={filenameExtensionIcon(x.name)}
                />
                <div className="content">
                  <div className="file">
                    <span className="name">
                      {path.basename(x.name, path.extname(x.name))}
                    </span>
                    <span className="format">{path.extname(x.name)}</span>
                  </div>
                  {x.progress !== undefined && !x.isUploaded ? (
                    <LiearProgress data={x.progress} />
                  ) : null}
                  {x.isUploaded && (
                    <em className="size">{readableBytes(x.size, 1)}</em>
                  )}
                  {x.isFail && (
                    <em
                      className="fail"
                      style={{
                        color: '#f44336',
                        marginTop: '4px',
                        fontStyle: 'normal',
                        fontSize: '12px',
                      }}
                    >
                      {getLocalizedText('전송 실패')}
                    </em>
                  )}
                </div>
                <Button
                  className="delete"
                  text={getLocalizedText('삭제')}
                  iconType
                  icon="times"
                  onClick={() => props.onRemove(x.id)}
                  size="sm"
                  color="secondary"
                />
              </div>
            );
          })}
        </ReactSortable>
      )}
      <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
    </div>
  );
}

export default AddProgressAttachments;
