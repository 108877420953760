/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  getAvatarPath,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import PostView from '../../../../../components/post/PostView';
import Avatar from '../../../../../components/avatar/Avatar';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import Comment from '../../../../../components/comment/Comment';
import {
  AttachFile,
  SharedFile,
  documentApi,
} from '../../../../apis/approval/v1/document';
import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { documentMacroReplace } from '../compose/ApprovalComposeContainer';
import Button from '../../../../../components/button/Button';
import ApprovalAttachedDocumentDialog from './ApprovalAttachedDocumentDialog';
import ApprovalAttachedFileDialog from './ApprovalAttachedFileDialog';
import FeedBack from '../../../../../components/alert/FeedBack';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import SecuritiesDocumentDialog from '../../../../../groupware-custom-sangsanginsecurities/pages/root/security/compose/SecuritiesDocumentDialog';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());

  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return `${getLocalizedText('{{n}}일', {
      n: day,
    })} ${getLocalizedText('{{n}}시간', { n: remainder })}`;
  }
  return getLocalizedText('{{n}}시간', { n: time });
}

interface NameKeyItem {
  companyId?: number;
  id: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function getCommentItems(
  items:
    | {
        companyId: number; // 문서 회사 아이디
        documentId: number; // 문서 아이디
        id: number; // 아이디
        parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
        employeeCompanyId: number; // 직원 회사 아이디
        employeeId: number; // 직원 아이디 - employee.id
        act?: number; // 행위 - 승인,부결,보류
        contents: string; // 내용
        likes: number; // 좋아요 수
        dislikes: number; // 싫어요 수
        loves: number; // 사랑해요 수
        createAt: string; // 생성 날짜
        updateAt: string; // 수정 날짜
      }[]
    | undefined,
  directory: {
    organizations: NameKeyItem[];
    organizationEmployees: {
      companyId: number;
      id: number;
      employeeId: number;
      jobDutyId: number;
    }[];
    employees: {
      companyId: number;
      id: number;
      representativeOrganizationId: number;
      jobPositionId: number;
      avatar: string;
    }[];
    jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
    jobPositions: NameKeyItem[];
    jobDuties: NameKeyItem[];
  },
) {
  if (items === undefined) return undefined;

  const temp: {
    companyId: number; // 문서 회사 아이디.
    documentId: number; // 문서 아이디.
    parentId: number; // 부모 아이디.
    id: number;
    from?: {
      key: string;
      name: string;
    };
    message: string;
    writer: {
      key: string;
      name: string; // 이름
      organization: string; // 부서
      class: string; // 직위 혹은 직책
      avatar: string; // 아바타
    };
    createAt: string;
    updateAt: string;
    authModify?: boolean;
    authDelete?: boolean;
  }[] = items.map((a) => {
    const {
      companyId,
      documentId,
      parentId,
      id,
      employeeCompanyId,
      employeeId,
      // act,
      contents,
      // likes,
      // dislikes,
      // loves,
      createAt,
      updateAt,
    } = a;

    const directoryData = getDirectoryData({
      ...directory,
      companyId: employeeCompanyId,
      employeeId,
    });

    const messages = contents.split('┼');

    let from: { key: string; name: string } | undefined;

    if (messages.length > 1) {
      const ids = messages[0].split('/');
      const fromData = getDirectoryData({
        ...directory,
        companyId: parseInt(ids[0], 10),
        employeeId: parseInt(ids[1], 10),
      });

      from = {
        key: messages[0],
        name: fromData.employeeName,
      };
    }

    const message = (messages.length === 1 ? messages[0] : messages[1]) || '';

    return {
      companyId,
      documentId,
      parentId,
      id,
      from,
      message,
      writer: {
        key: `${directoryData.companyId}/${directoryData.employeeId}`,
        name: directoryData.employeeName, // 이름
        organization: directoryData.organizationName, // 부서
        class: directoryData.jobClassName, // 직위 혹은 직책
        avatar: getAvatarPath(employeeCompanyId, employeeId), // 아바타
      },
      createAt,
      updateAt,
      authModify: false,
      authDelete: false,
    };
  });

  return temp
    .filter((a) => a.parentId === 0)
    .map((a) => {
      return {
        ...a,
        replies: temp.filter((b) => b.parentId === a.id),
      };
    });
}

const ApprovalOnlyViewContentContainer = (props: {
  pathname: string;
  search: string;
}): JSX.Element | null => {
  const queryParams = getQueryParams(props.search);
  const dispatch = useAppDispatch();
  const principal = useSelector((s: RootState) => s.session.principal);
  const directory = useDirectory();
  const view = useSelector((s: RootState) => s.approval2.document.view.data);

  const display = useSelector((state: RootState) => state.session.display);

  const scrollbar = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<{
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
    billSeq?: string;
    attachFileView?: AttachFile;
    sharedFileView?: SharedFile;
  }>({
    dialogs: [],
    billSeq: undefined,
    attachFileView: undefined,
    sharedFileView: undefined,
  });

  const [validation, setValidation] = useState('');

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props.pathname]);

  if (view === undefined || view === null)
    return (
      <div>{getLocalizedText('데이터가 이동되었거나 삭제되었습니다.')}</div>
    );

  const opinions = getCommentItems(view.opinions, directory);
  const comments = getCommentItems(view.comments, directory);

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: {
    companyId: number;
    documentId: number;
  }) => {
    // console.log(`handleAttachedFileDownloadAll(arg)`, arg);
    const { companyId, documentId } = arg;
    documentApi
      .downloadAttachfileAll(companyId, documentId)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    companyId: number;
    documentId: number;
    id: number;
    name: string;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { companyId, documentId, id, name } = arg;
    documentApi
      .downloadAttachfile(companyId, documentId, id)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 공유 파일 저장. */
  const handleSharedFileDownload = (arg: {
    documentId: number;
    id: number;
    name: string;
    affiliatedcompanyid?: number;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { affiliatedcompanyid, documentId, id, name } = arg;
    documentApi
      .downloadSharedfile(documentId, id, affiliatedcompanyid)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const {
    subject,
    content,
    draftAt,
    completeAt,
    approvalLine,
    attachedFiles,
    sharedFiles,
    attachedDocuments,
    parentCompanyId,
    parentId,
    parentApprovalLine,
    parentDrafteAt,
    parentCompleteAt,
    linkWait,
  } = view;

  const drafter = getApprovalLineDrafter(view.approvalLine);
  if (drafter === undefined) return <div>{getLocalizedText('오류')}</div>;

  const { organizationName: draftOrganizationName, employeeName: drafterName } =
    drafter;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : undefined;

  /** 부모 문서 정보(내부결재인 경우에만 있음) */
  let parentDrafterOrganizationName: string | undefined;
  let parentDrafterName: string | undefined;
  let parentDrafterAvatar: string | undefined;
  let parentLastApprovalAt: string | undefined;
  let parentWaiteAt: string | undefined;
  if (view.parentApprovalLine) {
    const parentDrafter = getApprovalLineDrafter(view.parentApprovalLine);
    if (parentDrafter === undefined)
      return <div>{getLocalizedText('오류')}</div>;

    parentDrafterOrganizationName = parentDrafter.organizationName;
    parentDrafterName = parentDrafter.employeeName;
    parentDrafterAvatar = parentDrafter.employeeId
      ? getAvatarPath(parentDrafter)
      : undefined;
    parentLastApprovalAt =
      parentCompleteAt === undefined
        ? getLastApprovalAt(view.parentApprovalLine)
        : undefined;
    parentWaiteAt =
      parentLastApprovalAt === undefined
        ? undefined
        : getWaitedAt(parentLastApprovalAt);
  }

  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  /** 마지막 결재 날짜 */
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(view.approvalLine) : undefined;

  /** 대기 시간 */
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  /** 공유파일 대화상자 보기 */
  const handleSharedFilePopup = (id: number) => {
    const file = view.sharedFiles?.find((a) => a.id === id);
    if (file === undefined) {
      setValidation(getLocalizedText('미리보기를 불러올 수 없습니다.'));
      return;
    }
    // TODO: 팝업 테스트 필요
    dispatch(sessionActions.setDialog({ type: 'sharedfile' }));
    setState((prev) => ({
      ...prev,
      sharedFileView: file,
    }));
  };

  /** 첨부파일 대화상자 보기 */
  const handleAttachedFilePopup = (id: number) => {
    const file = view.attachedFiles?.find((a) => a.id === id);
    if (file === undefined) {
      setValidation(getLocalizedText('미리보기를 불러올 수 없습니다.'));
      return;
    }
    // TODO: 팝업 테스트 필요
    dispatch(sessionActions.setDialog({ type: 'attachedfile' }));
    setState((prev) => ({
      ...prev,
      attachFileView: file,
    }));
  };

  // 첨부파일 대화상자
  const handleAttachedFileDialog = (file: AttachFile) => {
    dispatch(sessionActions.setDialog({ type: 'attachedfile' }));
    setState((prev) => ({
      ...prev,
      attachFileView: file,
    }));
  };

  /** dialog 닫기 */
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId, affiliateCompanyId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument') {
      if (state.dialogs.length > 2) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  /** 부모 문서 조회. */
  const handleParentDocumentPopup = (arg: {
    documentId: number;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;

    setState((prev) => ({
      ...prev,
      dialogs: [
        ...prev.dialogs,
        {
          dialogType: 'parentdocument',
          documentId,
          affiliatedCompanyId: affiliatedCompanyId ?? 0,
          receiptDocumentId,
          receiptAffiliatedCompanyId,
        },
      ],
    }));
  };

  // 첨부문서 대화상자 열기
  const handleAttachedDocumentDialog = (arg: {
    documentId: number;
    affiliatedCompanyId?: number | undefined;
  }) => {
    const { documentId, affiliatedCompanyId } = arg;
    // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
    if (state.dialogs.length === 0 && view) {
      setState((prev) => ({
        ...prev,
        dialogs: [
          {
            dialogType: 'attacheddocument',
            documentId: view.id,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
          },
          {
            dialogType: 'attacheddocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
          },
        ],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'attacheddocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
          },
        ],
      }));
    }
  };

  // 세금계산서 대화상자 열기
  const handleBillClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const billSeq = event.currentTarget.value;
    setState((prev) => ({
      ...prev,
      billSeq,
    }));
  };

  const handleCloseBillSeqPopup = () => {
    setState((prev) => ({
      ...prev,
      billSeq: undefined,
    }));
  };

  // 품의서 대화상자 열기
  const handleDraftDocumentClick = (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    const documentId = parseInt(event.currentTarget.value, 10);
    setState((prev) => ({
      ...prev,
      dialogs: [
        {
          dialogType: 'draftdocument',
          documentId,
          affiliatedCompanyId: 0,
        },
      ],
    }));
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, alertMessage: '' }));
  };

  /** 대화 상자 렌터링 */
  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 첨부파일 보기
    if (dialogType === 'attachedfile') {
      if (state.attachFileView === undefined) return null;
      const { attachFileView } = state;
      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: attachFileView.documentId,
            id: attachFileView.id,
            name: attachFileView.name,
            size: attachFileView.size,
            url: attachFileView.url ?? '',
            isFileprotection: attachFileView.isFileprotection,
          }}
        />
      );
    }

    // 공유파일 보기
    if (dialogType === 'sharedfile') {
      if (state.sharedFileView === undefined) return null;

      const { sharedFileView } = state;
      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: sharedFileView.documentId,
            id: sharedFileView.id,
            name: sharedFileView.name,
            size: sharedFileView.size,
            url: sharedFileView.viewerUrl ?? '',
          }}
        />
      );
    }

    const { dialogs } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={props.search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onAttachedFilePopup={handleAttachedFileDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }

    const { billSeq } = state;
    if (billSeq)
      return (
        <SecuritiesDocumentDialog
          billseq={billSeq}
          onClose={handleCloseBillSeqPopup}
        />
      );

    return null;
  };

  const element = (
    <PostView type="full">
      <PostView.Head>
        <PostView.Title
          chip={
            linkWait
              ? {
                  label: getLocalizedText('연동대기'),
                  theme: 'primary',
                }
              : undefined
          }
        >
          {subject}
        </PostView.Title>
        {parentApprovalLine && parentCompanyId && parentId && (
          <div
            className="approval-view-info"
            style={{
              paddingBottom: '16px',
              borderBottom: '1px solid var(--line-color)',
            }}
          >
            <Avatar
              className="avatar"
              name={parentDrafterName ?? parentDrafterOrganizationName ?? ''}
              image={parentDrafterAvatar}
              icon={parentDrafterAvatar ? undefined : 'sitemap-fill'}
            />
            <ApprovalLineFlat approvalLine={parentApprovalLine} />
            <Button
              parentDocument
              className="action"
              text={getLocalizedText('원문서 보기')}
              iconType
              icon="document-search"
              onClick={() =>
                handleParentDocumentPopup({
                  documentId: parentId,
                  affiliatedCompanyId:
                    view.parentAffiliatedCompanyId ?? undefined,
                  receiptAffiliatedCompanyId: view.affiliatedCompanyId,
                  receiptDocumentId: view.id,
                })
              }
              color="secondary"
            />
            <PostView.Info row>
              <PostView.InfoItem
                title={getLocalizedText('기안자')}
                value={parentDrafterName ?? ''}
              />
              <PostView.InfoItem
                title={getLocalizedText('기안부서')}
                value={parentDrafterOrganizationName ?? ''}
              />
              <PostView.InfoItem
                title={getLocalizedText('기안일')}
                value={parentDrafteAt ?? ''}
              />
              {parentCompleteAt && (
                <PostView.InfoItem
                  title={getLocalizedText('완료일')}
                  value={parentCompleteAt}
                />
              )}
              {parentWaiteAt && (
                <PostView.InfoItem
                  title={getLocalizedText('대기일')}
                  value={parentWaiteAt}
                />
              )}
            </PostView.Info>
          </div>
        )}
        <div className="approval-view-info">
          <Avatar
            className="avatar"
            name={drafterName ?? draftOrganizationName}
            image={drafterAvatar}
            icon={drafterAvatar ? undefined : 'sitemap-fill'}
          />
          <ApprovalLineFlat approvalLine={approvalLine} />
          <PostView.Info row>
            <PostView.InfoItem
              title={getLocalizedText('기안자')}
              value={drafterName ?? draftOrganizationName}
            />
            <PostView.InfoItem
              title={getLocalizedText('기안부서')}
              value={draftOrganizationName}
            />
            <PostView.InfoItem
              title={getLocalizedText('기안일')}
              value={draftAt}
            />
            {completeAt && (
              <PostView.InfoItem
                title={getLocalizedText('완료일')}
                value={completeAt}
              />
            )}
            {waitedAt && (
              <PostView.InfoItem
                title={getLocalizedText('대기일')}
                value={waitedAt}
              />
            )}
          </PostView.Info>
        </div>
      </PostView.Head>
      <PostView.Body>
        <PostView.Content data={content} />
        <input
          style={{ display: 'none' }}
          type="button"
          id="custom-draft-document-click"
          onClick={handleDraftDocumentClick}
        />
        <input
          style={{ display: 'none' }}
          type="button"
          id="custom-bill-click"
          onClick={handleBillClick}
        />
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={
              attachedFiles && display === 'pc'
                ? () =>
                    handleAttachedFileDownloadAll({
                      companyId: attachedFiles[0].companyId,
                      documentId: attachedFiles[0].documentId,
                    })
                : undefined
            }
          >
            {attachedFiles.map(
              ({ companyId, documentId, id, name, size, url }) =>
                url ? (
                  <AttachmentsItem
                    key={id}
                    name={name}
                    size={size}
                    onClick={
                      display === 'pc'
                        ? () =>
                            handleAttachedFileDownload({
                              companyId,
                              documentId,
                              id,
                              name,
                            })
                        : undefined
                    }
                    onView={() => handleAttachedFilePopup(id)}
                  />
                ) : (
                  <AttachmentsItem
                    key={id}
                    name={name}
                    size={size}
                    onClick={
                      display === 'pc'
                        ? () =>
                            handleAttachedFileDownload({
                              companyId,
                              documentId,
                              id,
                              name,
                            })
                        : undefined
                    }
                  />
                ),
            )}
          </AttachmentsList>
        )}
        {sharedFiles && sharedFiles.length > 0 && (
          <AttachmentsList
            title={getLocalizedText('공유파일')}
            count={sharedFiles.length}
            className="view-attachments"
          >
            {sharedFiles.map(
              ({ documentId, id, name, size, downloadUrl, viewerUrl }) => (
                <AttachmentsItem
                  key={id}
                  name={name}
                  size={size}
                  onClick={
                    downloadUrl !== undefined &&
                    downloadUrl !== null &&
                    display === 'pc'
                      ? () =>
                          handleSharedFileDownload({
                            documentId,
                            id,
                            name,
                          })
                      : undefined
                  }
                  onView={
                    viewerUrl !== undefined && viewerUrl !== null
                      ? () => handleSharedFilePopup(id)
                      : undefined
                  }
                />
              ),
            )}
          </AttachmentsList>
        )}
        {attachedDocuments && attachedDocuments.length > 0 && (
          <AttachDocumentList count={attachedDocuments.length}>
            {attachedDocuments.map((x) => (
              <AttachDocumentItem
                key={x.id}
                no={documentMacroReplace(x.no)}
                subject={x.subject}
                onClick={() =>
                  handleAttachedDocumentDialog({
                    affiliatedCompanyId: x.companyId,
                    documentId: x.id,
                  })
                }
              />
            ))}
          </AttachDocumentList>
        )}
        {opinions && (
          <Comment
            opinion
            count={opinions.length || 0}
            user={user}
            comments={opinions}
          />
        )}
        {comments && (
          <Comment
            count={comments.length || 0}
            user={user}
            comments={comments}
          />
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {element}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </div>
  );
};

export default ApprovalOnlyViewContentContainer;

export { getCommentItems };
