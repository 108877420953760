import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import SecuritiesSearch from '../common/SecuritiesSearch';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { RootState } from '../../../../../groupware-webapp/app/store';

type Props = {
  folderId?: string;
  title: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
  totalCount: number;
  checkedCount: number;
  onCheckedChange(itemId: 'all', checked: boolean): void;

  toolbarButtons: ActionEventProps[];
  onAction(arg: { code: string; event: React.MouseEvent }): void;
  onSearch?(arg: { code: string; word?: string }): void;
};

function SecuritiesContentHeadList(props: Props): JSX.Element {
  const {
    folderId,
    title,
    searchCode,
    searchWord,
    pageNo,
    rowsPerPage,
    totalCount,
    checkedCount,
    toolbarButtons,

    onAction,
    onCheckedChange,
    onSearch,
  } = props;
  const display = useSelector((state: RootState) => state.session.display);

  const options = [
    { label: '결재', value: 'approval' },
    { label: '진행', value: 'progress' },
    { label: '완료', value: 'complete' },
  ];

  /** 툴바 버튼 클릭 */
  const handleActionClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    onAction({ code: arg.code, event: arg.event });
  };

  /** 검색 이벤트. */
  const handleSearch = (arg: { keyword?: string; filter: string }) => {
    if (onSearch) onSearch({ code: arg.filter, word: arg.keyword });
  };

  return (
    <EuiHeader>
      <EuiHeader.Title>{title}</EuiHeader.Title>
      <EuiHeader.Search>
        {folderId && (
          <SecuritiesSearch
            options={options}
            filter={searchCode}
            keyword={searchWord ?? ''}
            onSearch={handleSearch}
          />
        )}
      </EuiHeader.Search>
      <EuiHeader.Toolbar>
        {checkedCount === 0 ? (
          <>
            <EuiHeader.ToolbarLeft>
              <Button
                className="check-all"
                text="선택"
                iconType
                icon="list-check"
                onClick={() => {
                  onCheckedChange('all', true);
                }}
              />
            </EuiHeader.ToolbarLeft>
            <EuiHeader.ToolbarRight>
              {totalCount > 0 && (
                <Pagination
                  no={pageNo}
                  rows={rowsPerPage}
                  count={totalCount}
                  onPrev={(event) => onAction({ code: 'prev', event })}
                  onNext={(event) => onAction({ code: 'next', event })}
                />
              )}
              {totalCount > 0 && display !== 'phone' && folderId && (
                <Divider orientation="vertical" />
              )}
              {display !== 'phone' && folderId && (
                <Button
                  text="목록설정"
                  iconType
                  icon="bar-cog"
                  onClick={(event) => onAction({ code: 'listSetting', event })}
                />
              )}
            </EuiHeader.ToolbarRight>
          </>
        ) : (
          <EuiHeader.ToolbarLeft>
            <Button
              className="action-close"
              text="취소"
              iconType
              icon="close"
              onClick={() => onCheckedChange('all', false)}
              vibrate
            />
            <span className="selected-count">
              <em>{checkedCount}</em> <span>개 선택됨</span>
            </span>
            <ToolbarAction event={toolbarButtons} onClick={handleActionClick} />
          </EuiHeader.ToolbarLeft>
        )}
      </EuiHeader.Toolbar>
    </EuiHeader>
  );
}

export default SecuritiesContentHeadList;
