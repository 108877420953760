import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Chip from '../../../../../components/chip/Chip';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import PostSubject from '../../../../../components/post/PostSubject';
import UserInfo from '../../../../../components/user/UserInfo';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type Status = 'proceeding' | 'done' | 'return';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

function statusLabel(type: string) {
  switch (type) {
    case 'proceeding':
      return getLocalizedText('승인대기');
    case 'return':
      return getLocalizedText('반려');
    case 'done':
      return getLocalizedText('예약완료');
    default:
      return '';
  }
}

function returnLabel(type: string) {
  switch (type) {
    case 'proceeding':
      return getLocalizedText('대여');
    case 'return':
      return getLocalizedText('반려');
    case 'done':
      return getLocalizedText('반납');
    default:
      return '';
  }
}

function statusTheme(type: string) {
  switch (type) {
    case 'proceeding':
      return 'primary';
    case 'return':
      return 'error';
    case 'done':
      return 'success';
    default:
      return undefined;
  }
}

/**
 * @property id 아이디.
 * @property columns 컬럼 배열.
 * @property item 항목.
 * @property onCheckedChange 체크 변경 이벤트.
 * @property onClick 클릭 이벤트.
 */
type Props = {
  tootip?: boolean;
  align?: boolean;
  menu?: string;
  id?: number;
  selected?: boolean;
  columns: Column<PostListItemType>[];
  item: Record<string, Status | User | boolean | string | number | undefined>;
  onCheckedChange?(id: number, checked: boolean): void;
  onClick?(id: number): void;
  onApprovalClick?(id: number, type: string): void;
  onClickCount?(id: number): void;
};

function ResourceReservationListItem(dumy: Props): JSX.Element {
  // console.log(`-------${ResourceReservationListItem.name}.render:props`, dumy);

  const display = useSelector((state: RootState) => state.session.display);
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || 0,
    selected: dumy.selected || false,
    columns: dumy.columns,
    item: dumy.item,
    onCheckedChange: dumy.onCheckedChange || emptyFunction,
    onClick: dumy.onClick || emptyFunction,
    onApprovalClick: dumy.onApprovalClick || emptyFunction,
    onClickCount: dumy.onClickCount || emptyFunction,
  };

  const handleCheckedChange = () => {
    const { id, item, onCheckedChange } = props;
    onCheckedChange(id, !item.checked);
  };

  const handleClick = (type?: string) => {
    const { id, onClick, onApprovalClick } = props;
    if (timerRef.current) clearTimeout(timerRef.current);
    if (type) onApprovalClick(id, type);
    else onClick(id);
  };

  const handleClickCount = () => {
    const { id, onClickCount } = props;
    onClickCount(id);
  };

  const { selected, columns, item } = props;

  // 승인관리 메뉴 여부
  const isApproval = props.menu === 'approval' || props.menu === 'return';
  return (
    <>
      <PostList.Item
        selected={selected}
        onClick={isApproval ? undefined : () => handleClick()}
      >
        {columns.map(({ name, text, type }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;
          if (name === 'checked' && typeof value === 'boolean') {
            children = (
              <Checkbox checked={value} onChange={handleCheckedChange} />
            );
          } else if (type === 'post') {
            const subject = value as string;
            children = (
              <PostSubject
                tootip={props.tootip ? subject : undefined}
                title={subject}
                onClick={isApproval ? () => handleClick() : undefined}
                onClickCount={handleClickCount}
              />
            );
          } else if (type === 'user') {
            const { avatar, name: username, organization } = value as User;
            children = (
              <UserInfo
                avatar={username === '' ? undefined : avatar}
                name={username === '' ? organization : username}
                from={username === '' ? undefined : organization}
                icon={username === '' ? 'sitemap-fill' : undefined}
              />
            );
          } else if (typeof value === 'string' || typeof value === 'number') {
            children = <PostList.Value value={value} />;
            if (name === 'status' && typeof value === 'string') {
              const data =
                props.menu === 'return'
                  ? returnLabel(value)
                  : statusLabel(value);
              const theme = statusTheme(value);
              children = (
                <Chip
                  className="cell-tag"
                  label={data}
                  theme={theme}
                  size="xs"
                />
              );
            }
            if (name === 'reservation' && typeof value === 'string') {
              const data = statusLabel(value);
              const theme = statusTheme(value);
              children = (
                <Chip
                  className="cell-tag"
                  label={data}
                  theme={theme}
                  size="xs"
                />
              );
            }
            if (name === 'reservationStatus' && typeof value === 'string') {
              const data: {
                useApprove: boolean;
                status: number; // 예약 승인 상태
                takeback: boolean; // 대여 반납 상태
              } = JSON.parse(value);
              if (props.menu === 'approval') {
                if (data.status === 0)
                  children = (
                    <>
                      <Chip
                        className="cell-tag"
                        size="xs"
                        label={getLocalizedText('승인')}
                        onClick={() => handleClick('approve')}
                      />
                      <div style={{ margin: '2px' }} />
                      <Chip
                        className="cell-tag"
                        size="xs"
                        label={getLocalizedText('반려')}
                        onClick={() => handleClick('reject')}
                      />
                    </>
                  );
                if (data.useApprove && data.status !== 0)
                  children = <PostList.Value value="" />;
                if (!data.useApprove) {
                  if (data.status === 1)
                    children = (
                      <Chip
                        className="cell-tag"
                        size="xs"
                        label={getLocalizedText('반려')}
                        onClick={() => handleClick('reject')}
                      />
                    );
                  else if (data.status === 2)
                    children = (
                      <Chip
                        className="cell-tag"
                        size="xs"
                        label={getLocalizedText('승인')}
                        onClick={() => handleClick('approve')}
                      />
                    );
                }
              } else if (props.menu === 'return') {
                if (data.takeback)
                  children = (
                    <Chip
                      disabled={data.status !== 1}
                      className="cell-tag"
                      size="xs"
                      label={getLocalizedText('대여')}
                      onClick={() => handleClick('rental')}
                    />
                  );
                else
                  children = (
                    <Chip
                      disabled={data.status !== 1}
                      className="cell-tag"
                      size="xs"
                      label={getLocalizedText('반납')}
                      onClick={() => handleClick('takeback')}
                    />
                  );
              }
            }
            if ((type === 'folder' || type === 'form') && display === 'phone') {
              children = (
                <span>
                  <PostList.Value value={text} />
                  <span style={{ paddingLeft: '20px' }}>{value}</span>
                </span>
              );
            }
            if (name === 'range' && type === 'period' && display === 'phone') {
              children = (
                <span>
                  <PostList.Value value={text} />
                  <span style={{ paddingLeft: '30px' }}>{value}</span>
                </span>
              );
            }
          }

          let style: React.CSSProperties | undefined;
          if (name === 'status' && props.menu === 'return')
            style = { width: '60px' };

          let align: 'center' | 'left' | 'right' | undefined;
          if (props.align && type !== 'post') align = 'center';
          else if (type === 'status' || type === 'count') align = 'center';
          return (
            <PostList.Cell key={name} type={type} align={align} style={style}>
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
    </>
  );
}

export default ResourceReservationListItem;
