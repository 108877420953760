import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../../../components/alert/FeedBack';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Loading from '../../../../../components/loading/Loading';
import Menu from '../../../../../components/menu/Menu';
import PostWrite from '../../../../../components/post/PostWrite';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import { summaryType } from '../../../../../components/repeatDialog/DetailRepeatDialog';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import Tree from '../../../../../components/tree/Tree';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  createQueryString,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { itemApi as resourceItemApi } from '../../../../../groupware-resource/apis/resource/v1/folder';
import { ResourceListItem } from '../../../../../groupware-resource/stores/folders';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  ParticipantStatusProps,
  schedulesActions,
} from '../../../../stores/calendar/schedules';
import CalendarResourceSelect from '../common/CalendarResourceSelect';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/** 사용 권한 아이템 포함하여 트리 재정렬 */
export function makeTreeData(
  result: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: 'folder' | 'document-check' | 'document-clock';
    seq: number;
  }[],
): {
  id: number;
  parentId: number;
  text: string;
  strings: string[][];
  icon: 'folder' | 'document-check' | 'document-clock';
}[] {
  let treeData: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    seq: number;
    icon: 'folder' | 'document-check' | 'document-clock';
  }[] = [];
  for (let i = 0; i < result.length; i += 1) {
    const { length } = treeData;
    if (i === 0) {
      const item = result.filter((x) => x.icon !== 'folder');
      treeData = item;
    } else {
      for (let a = 0; a < treeData.length; a += 1) {
        const b = treeData[a];
        const item = result.find((x) => x.id === b.parentId);
        if (item && !treeData.some((y) => y.id === item.id))
          treeData.push(item);
      }
    }
    if (length === treeData.length) break;
  }
  treeData = [
    ...treeData
      .filter((a) => a.icon === 'folder')
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ...treeData
      .filter((a) => a.icon !== 'folder')
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
  ];
  return treeData;
}

/** 예약 상태 */
function formatReservationStatus(status?: string) {
  switch (status) {
    case 'WAIT':
      return getLocalizedText('승인대기');
    case 'PASS':
      return getLocalizedText('예약완료');
    case 'REJECT':
      return getLocalizedText('반려');
    default:
      return '';
  }
}

function formatReservationTheme(status?: string) {
  switch (status) {
    case 'WAIT':
      return 'primary';
    case 'REJECT':
      return 'error';
    case 'PASS':
      return 'success';
    default:
      return undefined;
  }
}

function CalendarLookupDialog(props: {
  pathname: string;
  search: string;
  hash: string;
  onSave?(arg: {
    calendarId: number;
    data: {
      employeeId: number;
      name: string;
      description: string;
      startDateTime: string;
      endDateTime: string;
      resourceItemId?: number;
      isFull: boolean;
    };
  }): void;
  onUpdate?(arg: {
    isRepeat: boolean;
    repeatType: string;
    calendarId: number;
    eventId: number;
    data: {
      employeeId?: number;
      name: string;
      description: string;
      startDateTime: string;
      endDateTime: string;
      isFull: boolean;
      lookupStartDateTime?: string;
      updateAt: string;
    };
  }): void;
  onDelete?(arg: {
    isRepeat: boolean;
    repeatType: string;
    param: {
      rootEventId: number;
      calendarId: number;
      eventId: number;
      updateAt: string;
      removeStartDate?: string;
      isDeleteResource?: boolean;
    };
  }): void;
}): JSX.Element {
  const { pathname, hash } = props;
  const queryParams = getQueryParams(props.search);
  const queryWord:
    | {
        calendarId: number;
        calendarName: string;
        start: string;
        end: string;
        isAllDay: boolean;
      }
    | undefined = queryParams.queryWord
    ? JSON.parse(queryParams.queryWord)
    : undefined;
  const dispatch = useAppDispatch();
  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const list = useSelector(
    (state: RootState) => state.calendar.calendars.user.list,
  ).filter((a) => a.status);
  const sharedList = useSelector(
    (state: RootState) => state.calendar.calendars.user.sharedList,
  ).filter((a) => a.status);
  const calendars = [...list, ...sharedList];
  const view = useSelector((state: RootState) => state.calendar.schedules.view);
  const originStart = new Date(queryWord?.start ?? '');

  /** 트리 아이템 생성. */
  const treeData: {
    id: number;
    parentId: number;
    text: string;
    icon?: IconType;
    color?: string;
  }[] = [
    { id: 0, parentId: -1, text: getLocalizedText('캘린더 선택') },
    ...calendars
      .filter((a) => a.permissions.useWrite)
      .map((a) => {
        return {
          id: a.id,
          parentId: 0,
          text: a.name,
          icon: 'folder-fill' as const,
          color: a.color,
        };
      }),
  ];
  const time: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    time.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    time.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }

  const initialState = view
    ? {
        loading: true,
        view: true,
        delete: false,
        update: false,
        calendarId: view.calendarId,
        calendarName:
          calendars.find((a) => a.id === view.calendarId)?.name ?? '',
        start: queryWord
          ? new Date(queryWord.start)
          : timezoneDate(view.startDateTime),
        end: queryWord
          ? new Date(queryWord.end)
          : timezoneDate(view.endDateTime),
        name: view.name,
        isAllDay: queryWord?.isAllDay ?? false,
        useResource: view.resource !== undefined,
        resourceItemId: view.resource?.resourceItemId,
        resourcesItems: [],
        repeatType: 'piece',
        summary: view.repeat
          ? summaryType(timezoneDate(view.startDateTime), {
              frequency: view.repeat.frequency,
              cycle: view.repeat.cycle.toString(),
              repeatDays: view.repeat.repeatDays,
              monthRepeatStandard: view.repeat.monthRepeatStandard,
              endType:
                view.repeat.endDate !== '9999-12-31'
                  ? getLocalizedText('반복 종료일')
                  : getLocalizedText('계속 반복'),
              endDay: view.repeat.endDate,
              endCount: 0,
            })
          : undefined,
        alarms: view.alarms,
        participants: view.participants,
        description: view.description,
        calendarMenuPoint: undefined,
        resourceSelect: false,
      }
    : {
        loading: true,
        view: false,
        delete: false,
        update: false,
        calendarId: queryWord?.calendarId ?? 0,
        calendarName: queryWord?.calendarName ?? '',
        start: queryWord ? new Date(queryWord.start) : timezoneDate(),
        end: queryWord ? new Date(queryWord.end) : timezoneDate(),
        name: '',
        isAllDay: queryWord?.isAllDay ?? false,
        useResource: false,
        resourcesItems: [],
        repeatType: 'piece',
        alarms: [],
        participants: [],
        description: '',
        calendarMenuPoint: undefined,
        resourceSelect: false,
      };

  const [state, setState] = useState<{
    loading: boolean;
    view: boolean; // 보기 여부.
    delete: boolean; // 삭제 유무.
    update: boolean; // 수정 유무.
    calendarId: number;
    calendarName: string;
    start: Date;
    end: Date;
    name: string;
    isAllDay: boolean;
    useResource: boolean; // 자원 사용 여부.
    resourcesItems: ResourceListItem[];
    resourceItemId?: number;
    repeatType: string; // 반복 일정 삭제(수정) 시 타입.
    summary?: {
      one: string;
      two: string;
      three: string;
    };
    alarms: {
      type: string; // mail | alarm
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
    }[];
    participants: {
      participantCompanyId: number;
      participantId: number;
      participantReply?: ParticipantStatusProps;
    }[];
    description: string;
    calendarMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    resourceSelect: boolean;
  }>(initialState);
  const [validation, setValidation] = useState('');

  useEffect(() => {
    async function run() {
      const resourcesItems = (
        await resourceItemApi.userList({
          organizationId: principal.organizationId,
          employeeId: principal.employeeId,
        })
      )
        .map((a) => ({
          ...a,
          availableFromTime: a.useTimeAvailable ? a.availableFromTime : '00:00',
          availableToTime: a.useTimeAvailable ? a.availableToTime : '00:00',
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      setState((prev) => ({
        ...prev,
        resourcesItems,
        loading: false,
      }));
    }
    run();
  }, [state.start, state.end]);

  const alarmTypeData = [
    { value: 'NONE', label: '- 선택 -' },
    { value: 'ALARM', label: getLocalizedText('알람') },
    { value: 'MAIL', label: getLocalizedText('메일') },
  ];
  const timeTypeData = [
    { value: 'MINUTE', label: getLocalizedText('분전') },
    { value: 'HOUR', label: getLocalizedText('시간전') },
    { value: 'DAY', label: getLocalizedText('일전') },
    { value: 'WEEK', label: getLocalizedText('주전') },
  ];
  const resource = state.resourcesItems.find(
    (a) => a.id === state.resourceItemId,
  );

  /** 참석자 참석 여부 상태 아이콘 표시. */
  const participantStatusIcon = (status?: ParticipantStatusProps) => {
    switch (status) {
      case 'ACCEPT':
        return 'check-small';
      case 'REJECT':
        return 'times-small';
      case 'UNANSWER':
        return 'question-circle';
      default:
        return undefined;
    }
  };

  /** 참석자 참석 여부 상태 인원 수 표시. */
  const participantStatusLength = (status: ParticipantStatusProps) => {
    return state.participants.filter((a) => a.participantReply === status)
      .length;
  };

  const handleDialogClose = () => {
    const search = createQueryString(
      {
        queryWord: undefined,
        dialogMode: undefined,
        dialogType: undefined,
      },
      queryParams,
    );
    dispatch(schedulesActions.viewClear());
    dispatch(sessionActions.search(search));
  };

  /** 캘린더 드롭메뉴 클릭. */
  const handleDropClick = (event?: React.MouseEvent) => {
    const { calendarMenuPoint } = state;
    if (event !== undefined && calendarMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        calendarMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        calendarMenuPoint: undefined,
      }));
    }
  };

  /** 캘린더 선택. */
  const handleDropItemClick = (id: number) => {
    const name = calendars.find((a) => a.id === id)?.name ?? '';
    setState((prev) => ({
      ...prev,
      calendarId: id,
      calendarName: name,
      calendarMenuPoint: undefined,
    }));
  };

  /** 제목 변경. */
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  /** 날짜 변경. */
  const handleChangeDate = (date: Date | null, type: string) => {
    if (date === null) return;
    date.setSeconds(0);
    if (type === 'start') {
      let { end } = state;
      if (end <= date) {
        end = new Date(date);
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
        end.setSeconds(0);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      setState((prev) => ({
        ...prev,
        start: date < prev.start ? date : prev.start,
        end: date,
      }));
    }
  };

  /** 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const { start, end } = state;
    const newTime = Number(value.split(':')[0]);
    const newMinute = Number(value.split(':')[1]);
    if (type === 'start') {
      const date = new Date(start);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (end <= date) {
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      const date = new Date(end);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (date <= start) {
        start.setHours(date.getHours());
        start.setMinutes(date.getMinutes() - 30);
      }
      setState((prev) => ({ ...prev, start, end: date }));
    }
  };

  /** 종일 상태 변경 여부. */
  const handleChangeIsAllDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      isAllDay: event.target.checked,
    }));
  };

  /** 자원 예약 사용 여부 변경. */
  const handleChangeUseResource = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      useResource: event.target.checked,
      resourceItemId: undefined,
    }));
  };

  /** 자원 선택 대화상자 닫기 이벤트. */
  const handleResourceSelectClose = () => {
    setState((prev) => ({
      ...prev,
      resourceSelect: false,
    }));
  };

  /** 자원 선택 대화상자 확인 이벤트. */
  const handleResourceSelectConfirm = (arg: {
    start: Date;
    end: Date;
    checkResource: number;
  }) => {
    setState((prev) => ({
      ...prev,
      start: arg.start,
      end: arg.end,
      resourceItemId: arg.checkResource,
      resourceSelect: false,
    }));
  };

  /** 선택된 자원 삭제 이벤트. */
  const handleDeleteResourceId = () => {
    setState((prev) => ({
      ...prev,
      resourceItemId: undefined,
    }));
  };

  /** 내용 변경. */
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  /** 자세히. */
  const handleDetail = () => {
    const updateData = {
      calendarId: state.calendarId,
      calendarName: state.calendarName,
      start: dateFormat(state.start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(state.end, 'yyyy-MM-DD HH:mm'),
      isAllDay: state.isAllDay,
      useResource: state.useResource,
      resourceItemId: state.resourceItemId,
      name: state.name,
      description: state.description,
      originStart: queryWord?.start ?? '',
      originEnd: queryWord?.end ?? '',
      repeatType: view && state.summary ? state.repeatType : undefined,
    };
    dispatch(schedulesActions.updateData(updateData));
    go(
      pathname,
      createQueryString(
        {
          dialogMode: undefined,
          dialogType: undefined,
          queryWord: undefined,
          contentMode: view ? 'update' : 'create',
        },
        queryParams,
      ),
      hash,
    );
  };

  /** 일정 등록 */
  const handleSave = () => {
    if (!props.onSave) return;
    if (state.calendarId === 0 || state.calendarName === '') {
      setValidation(getLocalizedText('캘린더를 선택하세요.'));
      return;
    }
    if (state.name.trim() === '') {
      setValidation(getLocalizedText('제목을 입력하세요.'));
      return;
    }
    if (state.useResource && !state.resourceItemId) {
      setValidation(getLocalizedText('자원을 선택하세요.'));
      return;
    }

    const start = initialDate(state.start);
    const end = initialDate(state.end);

    if (state.isAllDay) {
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
    }
    props.onSave({
      calendarId: state.calendarId,
      data: {
        employeeId: principal.employeeId,
        name: state.name,
        description: state.description,
        startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
        endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
        resourceItemId: state.resourceItemId,
        isFull: state.isAllDay,
      },
    });
  };

  /** 일정 수정 클릭 이벤트. */
  const handleUpdateButtonClick = () => {
    if (view?.rootEventId !== 0) {
      // 공유받은 일정 수정할 경우 일정등록 컴포넌트로 이동.
      handleDetail();
      return;
    }

    if (state.summary) setState((prev) => ({ ...prev, update: true }));
    else setState((prev) => ({ ...prev, view: false }));
  };

  /** 일정 수정. */
  const handleUpdate = () => {
    if (!view || !props.onUpdate) return;
    if (state.calendarId === 0 || state.calendarName === '') {
      setValidation(getLocalizedText('캘린더를 선택하세요.'));
      return;
    }
    if (state.name.trim() === '') {
      setValidation(getLocalizedText('제목을 입력하세요.'));
      return;
    }

    const start = initialDate(state.start);
    const end = initialDate(state.end);
    if (state.isAllDay) {
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
    }

    // 반복 예약 수정.
    if (state.summary) {
      let arg = {
        isRepeat: true,
        repeatType: state.repeatType,
        calendarId: state.calendarId,
        eventId: view.id,
        data: {
          employeeId: view.employeeId,
          name: state.name,
          description: state.description,
          startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          isFull: state.isAllDay,
          lookupStartDateTime: undefined as string | undefined,
          updateAt: view.updateAt,
        },
      };
      if (state.repeatType !== 'all')
        arg = {
          ...arg,
          data: {
            ...arg.data,
            lookupStartDateTime: dateFormat(
              initialDate(originStart),
              'yyyy-MM-DD[T]HH:mm:ss.SSS',
            ),
          },
        };
      props.onUpdate(arg);
    }
    // 일반 예약 수정
    else {
      const isResourceDelete = view.resource
        ? view.resource && !state.resourceItemId
        : undefined;
      const resourceItemId =
        view.resource?.resourceItemId === state.resourceItemId
          ? undefined
          : state.resourceItemId;
      const arg = {
        isRepeat: false,
        repeatType: '',
        calendarId: state.calendarId,
        eventId: view.id,
        data: {
          name: state.name,
          description: state.description,
          startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          isFull: state.isAllDay,
          isResourceDelete,
          resourceItemId,
          updateAt: view.updateAt,
        },
      };
      props.onUpdate(arg);
    }
  };

  /** 일정 삭제 */
  const handleDelete = (isRepeat: boolean) => {
    if (!view || !props.onDelete) return;
    const start = initialDate(state.start);
    if (isRepeat) {
      props.onDelete({
        isRepeat,
        repeatType: state.repeatType,
        param: {
          rootEventId: view.rootEventId, // 공유받은 일정일 경우 rootEventId !== 0.
          calendarId: view.calendarId,
          eventId: view.id,
          updateAt: view.updateAt,
          removeStartDate: dateFormat(start, 'yyyy-MM-DD'),
        },
      });
    } else
      props.onDelete({
        isRepeat,
        repeatType: '',
        param: {
          rootEventId: view.rootEventId, // 공유받은 일정일 경우 rootEventId !== 0.
          calendarId: view.calendarId,
          eventId: view.id,
          updateAt: view.updateAt,
          isDeleteResource: !!view.resource && view.rootEventId === 0,
        },
      });
  };

  /** 수정,삭제 시 반복 일정 타입 변경. */
  const handleChangeRepeatType = (value: string) => {
    setState((prev) => ({
      ...prev,
      repeatType: value,
    }));
  };

  /** 공유받은 일정 - 참석 여부 변경. */
  const handleChangeParticipantStatus = (arg: {
    value: ParticipantStatusProps;
    employeeId?: number;
  }) => {
    const { value, employeeId } = arg;
    if (!view) return;
    delete queryParams.dialogMode;
    delete queryParams.queryWord;
    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
      },
    });
    dispatch(
      schedulesActions.updateScheduleParticipantStatus({
        employeeId: employeeId ?? principal.employeeId,
        rootEventId: view.rootEventId,
        data: {
          eventStartDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          eventEndDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
          answer: value,
          updateAt: view.updateAt,
        },
        location,
      }),
    );
  };

  /** 작성자 정보 */
  const writer = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: view?.employeeId ?? principal.employeeId,
  });

  const renderDialog = () => {
    if (state.resourceSelect) {
      return (
        <CalendarResourceSelect
          resourcesItems={state.resourcesItems}
          onResourceSelectConfirm={handleResourceSelectConfirm}
          onClose={handleResourceSelectClose}
          resourceItemId={state.resourceItemId}
          start={state.start}
          end={state.end}
        />
      );
    }
    return null;
  };

  const renderViewDialog = () => {
    if (state.delete) {
      if (view?.rootEventId === 0) {
        if (state.summary)
          return (
            <Dialog size="xs">
              <DialogHeader>
                <DialogTitle>{getLocalizedText('반복 일정 삭제')}</DialogTitle>
              </DialogHeader>
              <DialogBody>
                <div style={{ margin: '0 24px' }}>
                  <RadioGroup
                    column
                    name="repeat delete"
                    data={[
                      {
                        value: 'piece',
                        label: getLocalizedText('이 일정만 삭제'),
                      },
                      {
                        value: 'after',
                        label: getLocalizedText('이 일정 및 향후 일정 삭제'),
                      },
                      {
                        value: 'all',
                        label: getLocalizedText('모든 일정 삭제'),
                      },
                    ]}
                    value={state.repeatType}
                    onChange={handleChangeRepeatType}
                  />
                </div>
              </DialogBody>
              <DialogFooter>
                <Button
                  text={getLocalizedText('취소')}
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      delete: false,
                      repeatType: 'piece',
                    }))
                  }
                />
                <Button
                  noDuplication
                  text={getLocalizedText('삭제')}
                  variant="contained"
                  color="danger"
                  onClick={() => handleDelete(true)}
                />
              </DialogFooter>
            </Dialog>
          );
        return (
          <DeleteConfirmation
            onCancel={() =>
              setState((prev) => ({
                ...prev,
                delete: false,
              }))
            }
            onSubmit={() => handleDelete(false)}
          >
            <strong>&apos;111{state.name}&apos;</strong>
            {getLocalizedText('일정을 정말 삭제하시겠습니까?')}
          </DeleteConfirmation>
        );
      }
      const message: React.ReactNode = state.summary ? (
        <>
          <strong>&apos;{state.name}&apos;</strong>
          {getLocalizedText('은 공유받은 반복 일정입니다.')}
          <p>
            {getLocalizedText(
              '반복 일정을 삭제하면 해당 반복 일정 전체가 삭제됩니다. 정말 삭제하시겠습니까?',
            )}
          </p>
        </>
      ) : (
        <>
          <strong>&apos;{state.name}&apos;</strong>
          {getLocalizedText('은 공유받은 일정입니다.')}
          <p>{getLocalizedText('정말 삭제하시겠습니까?')}</p>
        </>
      );
      return (
        <DeleteConfirmation
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              delete: false,
            }))
          }
          onSubmit={() => handleDelete(false)}
        >
          {message}
        </DeleteConfirmation>
      );
    }
    if (state.update)
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>{getLocalizedText('반복 일정 수정')}</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div style={{ margin: '0 24px' }}>
              <RadioGroup
                column
                name="repeat update"
                data={[
                  {
                    value: 'piece',
                    label: getLocalizedText('이 일정만 수정'),
                  },
                  {
                    value: 'after',
                    label: getLocalizedText('이 일정 및 향후 일정 수정'),
                  },
                  {
                    value: 'all',
                    label: getLocalizedText('모든 일정 수정'),
                  },
                ]}
                value={state.repeatType}
                onChange={handleChangeRepeatType}
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text={getLocalizedText('취소')}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  update: false,
                  repeatType: 'piece',
                }))
              }
            />
            <Button
              text={getLocalizedText('수정')}
              variant="contained"
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  view: false,
                  start:
                    view && state.repeatType === 'all'
                      ? timezoneDate(view.startDateTime)
                      : prev.start,
                  end:
                    view && state.repeatType === 'all'
                      ? timezoneDate(view.endDateTime)
                      : prev.end,
                }))
              }
            />
          </DialogFooter>
        </Dialog>
      );
    return null;
  };

  const startTime = state.start.getHours().toString().padStart(2, '0');
  const startMinute = state.start.getMinutes().toString().padStart(2, '0');
  const endTime = state.end.getHours().toString().padStart(2, '0');
  const endMinute = state.end.getMinutes().toString().padStart(2, '0');
  const resourceStatus = formatReservationStatus(view?.resource?.status);
  const resourceStatusTheme = formatReservationTheme(view?.resource?.status);

  if (state.view) {
    const calendar = calendars.find((a) => a.id === view?.calendarId);
    /** 공유받은 캘린더 구분. */
    const isSharedCalendar = sharedList.some((a) => a.id === calendar?.id);
    const acceptParts = participantStatusLength('ACCEPT');
    const rejectParts = participantStatusLength('REJECT');
    const waitParts = participantStatusLength('WAIT');
    const unAnswerParts = participantStatusLength('UNANSWER');
    let participant = state.participants.find(
      (a) => a.participantId === principal.employeeId,
    );
    if (isSharedCalendar)
      participant = calendar?.permissions.useWrite
        ? state.participants.find(
            (a) => a.participantId === calendar?.creatorId,
          )
        : undefined;
    let range = `${dateFormat(state.start, 'yyyy-MM-DD HH:mm')} ~ ${dateFormat(
      state.end,
      'yyyy-MM-DD HH:mm',
    )}`;
    if (state.isAllDay) {
      if (moment(state.start).isSame(state.end, 'day'))
        range = `${dateFormat(state.start, 'yyyy-MM-DD')} (종일)`;
      else
        range = `${dateTimeFormat(state.start, 'yyyy-MM-DD')} ~ ${dateFormat(
          state.end,
          'yyyy-MM-DD',
        )} (종일)`;
    }
    return (
      <Dialog onClose={handleDialogClose}>
        <DialogHeader>
          <DialogTitle>{getLocalizedText('일정보기')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            {state.loading && <Loading />}
            <PostWrite.Item title={getLocalizedText('캘린더명')}>
              <span style={{ fontSize: '15px', fontWeight: 700 }}>
                {state.calendarName}
              </span>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('제목')}>
              {state.name}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('작성자')}>
              {`${writer.organizationName}/${writer.employeeName}`}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('일정기간')}>
              {range}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('내용')}>
              {state.description}
            </PostWrite.Item>
            {state.resourceItemId && resource && (
              <PostWrite.Item title={getLocalizedText('자원예약')}>
                <div className="eui-chip" style={{ height: 'auto' }}>
                  <div className="eui-avatar avatar icon" title={resource.name}>
                    {resource.useApprove ? (
                      <i className="eui-icon eui-icon-document-check" />
                    ) : (
                      <i className="eui-icon eui-icon-document-clock" />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      margin: '4px',
                    }}
                  >
                    <span className="text" style={{ marginBottom: '3px' }}>
                      <span className="label">
                        {resource.name}
                        <Chip
                          style={{ marginLeft: '5px' }}
                          size="xs"
                          theme={resourceStatusTheme}
                          label={resourceStatus ?? ''}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </PostWrite.Item>
            )}
            {view && view.repeat && (
              <PostWrite.Item title={getLocalizedText('반복일정')}>
                {view.repeat.frequency === 1
                  ? `${state.summary?.one}${state.summary?.three}`
                  : `${state.summary?.one}${state.summary?.two}${state.summary?.three}`}
              </PostWrite.Item>
            )}
            {state.alarms.length > 0 && (
              <PostWrite.Item title={getLocalizedText('알림')}>
                {state.alarms.map((a, i) => {
                  const alarmValue =
                    alarmTypeData.find((x) => x.value === a.type)?.label ?? '';
                  const timeValue =
                    timeTypeData.find((x) => x.value === a.timeUnit)?.label ??
                    '';
                  const key = `${a.type}_${i}`;
                  return (
                    <div key={key} style={{ marginBottom: '2px' }}>
                      {`${alarmValue} / ${a.ammount}${timeValue}`}
                    </div>
                  );
                })}
              </PostWrite.Item>
            )}
            {state.participants.length > 0 && (
              <PostWrite.Item
                title={
                  display !== 'phone' ? (
                    getLocalizedText(
                      '참석자 (수락 {{accept}}명, 미정 {{unAnswer}}명, 거절 {{reject}}명, 대기 {{wait}}명)',
                      {
                        accept: acceptParts,
                        unAnswer: unAnswerParts,
                        reject: rejectParts,
                        wait: waitParts,
                      },
                    )
                  ) : (
                    <>
                      <Trans
                        i18nKey="참석자<br>(수락 {{accept}}명, 미정 {{unAnswer}}명, 거절 {{reject}}명, 대기 {{wait}}명)"
                        values={{
                          accept: acceptParts,
                          unAnswer: unAnswerParts,
                          reject: rejectParts,
                          wait: waitParts,
                        }}
                        components={{ br: <br /> }}
                      >
                        참석자
                        <br />
                        (수락 {acceptParts}명, 미정 {unAnswerParts}명, 거절{' '}
                        {rejectParts}명, 대기 {waitParts}명)
                      </Trans>
                    </>
                  )
                }
              >
                <ChipGroup>
                  {state.participants.map((a) => {
                    const directoryData = getDirectoryData({
                      ...directory,
                      companyId: a.participantCompanyId,
                      employeeId: a.participantId,
                    });
                    return (
                      <Chip
                        key={`${a.participantCompanyId}/${a.participantId}`}
                        label={directoryData.employeeName}
                        etc={directoryData.organizationName}
                        avatar={directoryData.avatar}
                        status={{
                          label: a.participantReply ?? '',
                          icon: participantStatusIcon(a.participantReply),
                        }}
                      />
                    );
                  })}
                </ChipGroup>
              </PostWrite.Item>
            )}
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          {view?.rootEventId !== 0 && participant && (
            <div
              style={{
                display: 'flex',
                marginRight: 'auto',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: 500, marginRight: '8px' }}>
                참석 여부
              </div>
              <ChipGroup>
                <Chip
                  style={
                    participant.participantReply === 'ACCEPT'
                      ? { backgroundColor: 'var(--primary-light-color)' }
                      : undefined
                  }
                  label={getLocalizedText('예')}
                  onClick={() =>
                    handleChangeParticipantStatus({
                      value: 'ACCEPT',
                      employeeId: participant?.participantId,
                    })
                  }
                />
                <Chip
                  style={
                    participant.participantReply === 'REJECT'
                      ? { backgroundColor: 'var(--primary-light-color)' }
                      : undefined
                  }
                  label={getLocalizedText('아니오')}
                  onClick={() =>
                    handleChangeParticipantStatus({
                      value: 'REJECT',
                      employeeId: participant?.participantId,
                    })
                  }
                />
                <Chip
                  style={
                    participant.participantReply === 'UNANSWER'
                      ? { backgroundColor: 'var(--primary-light-color)' }
                      : undefined
                  }
                  label={getLocalizedText('미정')}
                  onClick={() =>
                    handleChangeParticipantStatus({
                      value: 'UNANSWER',
                      employeeId: participant?.participantId,
                    })
                  }
                />
              </ChipGroup>
            </div>
          )}
          {calendar?.permissions.useWrite && (
            <>
              <Button
                text={getLocalizedText('수정')}
                variant="outlined"
                onClick={handleUpdateButtonClick}
              />
              <Button
                text={getLocalizedText('삭제')}
                variant="contained"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    delete: true,
                  }))
                }
              />
            </>
          )}
        </DialogFooter>
        {renderViewDialog()}
      </Dialog>
    );
  }
  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('일정등록')}</DialogTitle>
      </DialogHeader>
      <DialogBody noneOverFlow>
        <PostWrite>
          {state.loading && <Loading />}
          <PostWrite.Item title={getLocalizedText('캘린더명')} required>
            <DropMenu
              value={state.calendarName}
              onClick={handleDropClick}
              label={getLocalizedText('캘린더 선택')}
            />
            {state.calendarMenuPoint && (
              <Menu
                point={state.calendarMenuPoint}
                onClose={() =>
                  setState((prev) => ({
                    ...prev,
                    calendarMenuPoint: undefined,
                  }))
                }
              >
                <div className="ui-organization-select">
                  <div className="body-panel">
                    <Tree
                      selectedId={state.calendarId}
                      items={treeData}
                      onItemClick={handleDropItemClick}
                    />
                  </div>
                </div>
              </Menu>
            )}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('제목')} required>
            <TextField value={state.name} onChange={handleChangeName} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('작성자')}>
            {`${writer.organizationName}/${writer.employeeName}`}
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('일정기간')} required>
            <div
              style={{
                display: 'inline-flex',
                flexDirection: display === 'phone' ? 'column' : undefined,
              }}
            >
              {display === 'phone' ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '5px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {getLocalizedText('시작')}
                    </span>
                    <CustomDatePicker
                      controlClassName="positionToTop"
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={state.start}
                      startDate={state.start}
                      endDate={state.end}
                      selectsStart
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'start')
                      }
                    />
                    {!state.isAllDay && (
                      <SelectField
                        data={time}
                        value={`${startTime}:${startMinute}`}
                        onChange={(value) => handleChangeTime(value, 'start')}
                      />
                    )}
                  </div>
                  <div
                    style={
                      display === 'phone'
                        ? {
                            display: 'flex',
                            flexDirection: 'row',
                          }
                        : undefined
                    }
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {getLocalizedText('종료')}
                    </span>
                    <CustomDatePicker
                      controlClassName="positionToTop"
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={state.end}
                      startDate={state.start}
                      endDate={state.end}
                      minDate={state.start}
                      selectsEnd
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'end')
                      }
                    />
                    {!state.isAllDay && (
                      <SelectField
                        data={time}
                        value={`${endTime}:${endMinute}`}
                        onChange={(value) => handleChangeTime(value, 'end')}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <CustomDatePicker
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.start}
                    startDate={state.start}
                    endDate={state.end}
                    selectsStart
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'start')
                    }
                  />
                  {!state.isAllDay && (
                    <SelectField
                      data={time}
                      value={`${startTime}:${startMinute}`}
                      onChange={(value) => handleChangeTime(value, 'start')}
                    />
                  )}
                  <span style={{ margin: '2px 10px' }}> ~</span>
                  <CustomDatePicker
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.end}
                    startDate={state.start}
                    endDate={state.end}
                    minDate={state.start}
                    selectsEnd
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'end')
                    }
                  />
                  {!state.isAllDay && (
                    <SelectField
                      data={time}
                      value={`${endTime}:${endMinute}`}
                      onChange={(value) => handleChangeTime(value, 'end')}
                    />
                  )}
                </>
              )}
              <Checkbox
                disabled={state.useResource}
                style={{ marginLeft: display !== 'phone' ? '8px' : undefined }}
                label={getLocalizedText('종일')}
                checked={state.isAllDay}
                onChange={handleChangeIsAllDay}
              />
            </div>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('자원예약')}>
            <Checkbox
              disabled={state.isAllDay}
              label={getLocalizedText('예약사용')}
              checked={state.useResource}
              onChange={handleChangeUseResource}
            />
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '5px',
                color: 'var(--secondary-text-color)',
              }}
            >
              {getLocalizedText(
                '* 종일 일정 사용 또는 공유받은 일정 수정 시에는 자원예약을 할 수 없습니다.',
              )}
            </span>
          </PostWrite.Item>
          {state.useResource && (
            <PostWrite.Item title={getLocalizedText('자원선택')}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                  onClick={() =>
                    setState((prev) => ({ ...prev, resourceSelect: true }))
                  }
                  iconType
                  icon="plus"
                  variant="contained"
                  size="xs"
                  style={{ marginRight: '8px' }}
                />
                {state.resourceItemId && resource && (
                  <div className="eui-chip" style={{ height: 'auto' }}>
                    <div
                      className="eui-avatar avatar icon"
                      title={resource.name}
                    >
                      {resource.useApprove ? (
                        <i className="eui-icon eui-icon-document-check" />
                      ) : (
                        <i className="eui-icon eui-icon-document-clock" />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        margin: '4px',
                      }}
                    >
                      <span className="text" style={{ marginBottom: '3px' }}>
                        <span className="label">
                          <span>
                            {resource.name}
                            {view?.resource?.resourceItemId ===
                              state.resourceItemId && (
                              <Chip
                                style={{ marginLeft: '5px' }}
                                size="xs"
                                theme={resourceStatusTheme}
                                label={resourceStatus ?? ''}
                              />
                            )}
                          </span>
                        </span>
                      </span>
                      <span
                        className="etc"
                        style={{ marginLeft: 'initial', width: 'fit-content' }}
                      >
                        {getLocalizedText('예약가능시간')}{' '}
                        {resource.useTimeAvailable
                          ? `${resource.availableFromTime} ~ ${resource.availableToTime}`
                          : getLocalizedText('종일')}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="delete"
                      aria-label={getLocalizedText('삭제')}
                      onClick={handleDeleteResourceId}
                    />
                  </div>
                )}
              </div>
              <HelperText
                text={getLocalizedText(
                  '자원예약 시에는 반복일정을 등록할 수 없습니다.',
                )}
              />
            </PostWrite.Item>
          )}
          <PostWrite.Item title={getLocalizedText('내용')}>
            <TextField
              value={state.description}
              multiline
              rows={2}
              onChange={handleChangeDescription}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          text={getLocalizedText('자세히')}
          variant="outlined"
          onClick={handleDetail}
        />
        {/* TODO 주석 처리 삭제 필요. */}
        <Button
          // noDuplication={validation === ''}
          text={getLocalizedText('저장')}
          variant="contained"
          onClick={view ? handleUpdate : handleSave}
        />
      </DialogFooter>
      <FeedBack text={validation} onClose={() => setValidation('')} />
      {renderDialog()}
    </Dialog>
  );
}

export default CalendarLookupDialog;
