/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import PostWrite from '../../../../../components/post/PostWrite';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import MemoizeDivElement from '../../../../../groupware-approval/pages/root/approval/common/components/MemoizeDivElement';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import {
  getPathMap,
  getQueryParams,
  go,
  goBack,
  hangul,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import {
  folderActions,
  replaceRetentionPeriodToString,
} from '../../../../stores/document/folders';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
} from '../../../../../groupware-common/utils/ui';
import Switch from '../../../../../components/switch/Switch';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { FileUploadProps } from '../../../../../groupware-common/types';
import NavigationGuard from '../../../../../components/prompt/NavigationGuard';
import { history } from '../../../../..';
import PromptMessage from '../../../../../components/prompt/PromptMessage';
import { documentsActions } from '../../../../stores/document/document';
import documentFormApi from '../../../../apis/document/v1/form';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { ApiError } from '../../../../../groupware-common/types/error';
import AddProgressAttachments from '../../../../../components/attachments/AddProgressAttachments';
import fileApi from '../../../../../groupware-common/apis/file/v1';

type Props = {
  pathname: string;
  search: string;
};

/** 첨부파일 객체 생성 */
const createAttachedFiles = (data: {
  attachedFiles: FileUploadProps[];
  isUpdateDocuments?: boolean;
  documentId?: number;
  uploadPath: string;
}): {
  path: string;
  id: number;
  documentId?: number;
  seq: number;
  name: string;
  size: number;
  delete?: boolean;
  copy?: boolean;
}[] => {
  const { attachedFiles, isUpdateDocuments, documentId, uploadPath } = data;

  return attachedFiles.map((a, i) => {
    return {
      path: a.file !== undefined ? uploadPath : '',
      id: parseInt(a.id, 10),
      documentId:
        isUpdateDocuments && a.file === undefined && documentId
          ? documentId
          : undefined,
      seq: i + 1,
      name: a.name,
      size: a.size,
      copy: isUpdateDocuments && a.file === undefined ? true : undefined,
    };
  });
};

/** 업로드 파일 객체 생성 */
const createUploadFiles = (attachedFiles: FileUploadProps[]) => {
  return attachedFiles
    .filter(
      (a): a is { id: string; name: string; size: number; file: File } =>
        a.file !== undefined,
    )
    .map((a) => {
      return {
        id: parseInt(a.id, 10),
        file: a.file,
      };
    });
};

function DocumentComposeContainer(props: Props): JSX.Element {
  const queryParams = getQueryParams(props.search);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  /** 임시보관 여부 */
  const isTemporarilyStored = queryParams.contentType === 'temp';
  const currentTimeZone = useSelector(
    (state: RootState) => state.session.basicSetting.currentTimeZone,
  );
  /** 폴더 리스트. */
  const folders = useSelector((state: RootState) => {
    if (
      queryParams.contentMode === 'create' ||
      queryParams.contentMode === 'update'
    ) {
      return state.document.folders.list.filter(
        (a) => a.permissions?.useRead || a.isAdmin,
      );
    }
    return state.document.folders.list;
  });
  /**  폴더. */
  const folder = useSelector((state: RootState) => state.document.folders.view);
  const preferences = useSelector(
    (state: RootState) => state.document.preferences.basic,
  );

  const principal = useSelector((state: RootState) => state.session.principal);

  const directory = useDirectory();

  const maxFileCount = preferences.numberOfAttachments;
  const maxFileCapacity = preferences.attachmentsCapacity;

  /** 문서 조회 */
  const view = useSelector((state: RootState) => state.document.documents.view);

  /** 보존기간 옵션, 기본 보존기간 값 가져오기 */
  function handleretentionPeriodOption() {
    let retentionPeriods: { value: string; label: string }[] = [];
    let defaultRetentionPeriod = '';

    // 폴더에서 보존기간을 사용할 경우
    if (folder !== undefined && folder.retentionPeriods.length > 0) {
      retentionPeriods = folder.retentionPeriods.map((a) => ({
        value: a.id,
        label: replaceRetentionPeriodToString(a.id),
      }));
      if (view !== undefined) {
        // 문서가 임시보관일 때
        if (isTemporarilyStored) {
          defaultRetentionPeriod = folder.retentionPeriods.some(
            (a) => a.id === view.retentionPeriod,
          )
            ? view.retentionPeriod
            : folder.defaultRetentionPeriod;
        }
        // 문서를 수정할 때
        else {
          // 문서와 폴더의 보존기간 맞지 않을 경우 문서 (문서 보존기간 + 폴더 보존기간)
          // eslint-disable-next-line no-lonely-if
          if (
            !folder.retentionPeriods.some(
              (a) => a.id === view.retentionPeriod,
            ) &&
            view.retentionPeriod !== ''
          ) {
            retentionPeriods.push({
              value: view.retentionPeriod,
              label: replaceRetentionPeriodToString(view.retentionPeriod),
            });
            defaultRetentionPeriod = view.retentionPeriod;
          }
          // 문서 보존기간 없을 경우 문서 (폴더 보존기간 + 사용안함 속성)
          else if (view.retentionPeriod === '') {
            retentionPeriods.push({
              value: '',
              label: getLocalizedText('사용안함'),
            });
            defaultRetentionPeriod = '';
          } else {
            // 보존기간 있을 경우 (폴더 보존기간)
            defaultRetentionPeriod = view.retentionPeriod;
          }
        }
      } else {
        // 문서 작성 시 (폴더 보존기간)
        defaultRetentionPeriod = folder.defaultRetentionPeriod;
      }
    } else if (view !== undefined) {
      // 폴더 보존기간을 사용하지 않으나 문서에 보존기간이 있을 경우 (문서 보존기간)
      retentionPeriods.push({
        value: view.retentionPeriod,
        label: replaceRetentionPeriodToString(view.retentionPeriod),
      });
      defaultRetentionPeriod = view.retentionPeriod;
    }
    return { retentionPeriods, defaultRetentionPeriod };
  }

  /** 트리 아이템 생성. */
  const treeFolders = useMemo(() => {
    const result = [
      ...folders
        .map((a) => {
          return {
            id: a.id,
            seq: a.seq,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as const,
            disabled: !(a.permissions?.useWrite === true || a.isAdmin === true),
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];
    return result;
  }, [folders, folder]);

  const { retentionPeriods: retentionOptions, defaultRetentionPeriod } =
    handleretentionPeriodOption();

  const initialState = view
    ? {
        subject: view.subject,
        contents: view.contents,
        summary: view.summary,

        selectedFormId: 0,
        selectedFormName: '',
        selectedRetentionPeriod: defaultRetentionPeriod,
        selectedFolderId: view.folderId,

        isNotified: false,
        notifyStartDate: null,
        notifyEndDate: null,
        datePoint: undefined,
        dateOptions: '',
        dateType: '',

        tempFormId: 0,
        tempFolderId: 0,
        formConfirmSelect: false,
        attachedFiles: view.attachedFiles
          ? view?.attachedFiles.map((a) => {
              return {
                ...a,
                id: `${a.fileId}`,
                progress: 100,
                isUploaded: true,
              };
            })
          : [],
        uploadPath: `${Date.now()}`,

        folderSelect: false,
        folderConfirmSelect: false,
        validation: '',
        updateVersion: !!folder?.option.isRequiredVersion,
        changeReason: '',
      }
    : {
        subject: '',
        contents: `<gw-fb-element data-role="control" data-option="default-editor" data-control="CONTROL/EDITOR" contenteditable="false" style="position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; width:100%;"><gw-fb-element-editor style="box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:100%; line-height:200px; text-align:center; background:#eee;" contenteditable="false">editor</gw-fb-element-editor></gw-fb-element>`,
        summary: '',

        selectedFormId: 0,
        selectedFormName: '',
        selectedRetentionPeriod: defaultRetentionPeriod,
        selectedFolderId: folder?.id ?? 0,
        isNotified: false,
        notifyStartDate: null,
        notifyEndDate: null,
        datePoint: undefined,
        dateOptions: getLocalizedText('상시'),
        dateType: getLocalizedText('상시'),
        attachedFiles: [],
        uploadPath: `${Date.now()}`,
        tempFormId: 0,
        tempFolderId: 0,
        formConfirmSelect: false,
        folderSelect: false,
        folderConfirmSelect: false,
        validation: '',
        changeReason: '',
        updateVersion: false,
      };

  const [state, setState] = useState<{
    subject: string;
    contents: string;
    summary: string;

    selectedFormId: number;
    selectedFormName: string;
    selectedRetentionPeriod: string;

    selectedFolderId: number;

    isNotified: boolean;
    notifyStartDate: Date | null;
    notifyEndDate: Date | null;

    datePoint:
      | {
          x: number;
          y: number;
          width: number;
          height: number;
        }
      | undefined;
    dateOptions: string;
    dateType: string;
    attachedFiles: FileUploadProps[];
    uploadPath: string;
    tempFormId: number;
    tempFolderId: number;
    validation: string;

    formConfirmSelect: boolean; // 양식 선택 확인 창
    folderConfirmSelect: boolean; // 폴더 변경 확인 창
    folderSelect: boolean; // 폴더 선택 창
    updateVersion: boolean;
    changeReason: string; // 변경 사유.
  }>(initialState);

  const [reload, setReload] = useState(true);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록 deprecated
  };

  /** 새로고침 방지 이벤트 */
  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', handlePrevClose);
    })();
    if (reload) {
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();

      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    return () => {
      window.removeEventListener('beforeunload', handlePrevClose);
    };
  }, [queryParams.contenttype]);

  const employeeData = useMemo(
    () =>
      getDirectoryData({
        ...directory,
        ...principal,
      }),
    [principal],
  );
  const handleIsFixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      isNotified: event.target.checked,
    }));
  };

  /** 취소 */
  const handleCancel = () => {
    if (view) {
      goBack();
      return;
    }
    delete queryParams.contentMode;
    delete queryParams.contentType;
    go(props.pathname, queryParams);
  };

  /** 제목 변경 */
  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, subject: event.target.value }));
  };

  /** 설명 변경 */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      summary: event.target.value,
    }));
  };

  /** 변경사유 변경 */
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      changeReason: event.target.value,
    }));
  };

  /** 폴더 변경 대화 상자 열기. */
  const handleFolderChangeDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      folderSelect: true,
    }));
  };

  /** 폴더 선택 대화 상자 열기. */
  const handleFolderSelectDialogOpen = () => {
    dispatch(folderActions.userFolderView({ id: state.tempFolderId })).then(
      (result) => {
        const { retentionPeriods } = result.payload as {
          retentionPeriods: {
            id: string;
            checked: boolean;
          }[];
        };

        setState((prevState) => ({
          ...prevState,
          selectedFolderId: state.tempFolderId,
          tempFolderId: 0,
          selectedRetentionPeriod:
            retentionPeriods.length > 0 ? retentionPeriods[0].id : '',
          contents: `<gw-fb-element data-role="control" data-option="default-editor" data-control="CONTROL/EDITOR" contenteditable="false" style="position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; width:100%;"><gw-fb-element-editor style="box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:100%; line-height:200px; text-align:center; background:#eee;" contenteditable="false">editor</gw-fb-element-editor></gw-fb-element>`,
          folderConfirmSelect: false,
          folderSelect: false,
          selectedFormId: 0,
          selectedFormName: '',
        }));
      },
    );
  };

  /** 양식 선택 대화 상자 확인. */
  const handleFormSelectConfirm = () => {
    async function run() {
      const form = await documentFormApi.userView(state.tempFormId);
      setState((prevState) => ({
        ...prevState,
        tempFormId: 0,
        selectedFormId: prevState.tempFormId,
        contents: form.content,
        formConfirmSelect: false,
      }));
    }
    if (state.tempFormId !== 0) run();
    else
      setState((prev) => ({
        ...prev,
        tempFormId: 0,
        selectedFormId: state.tempFormId,
        formConfirmSelect: false,
        contents: `<gw-fb-element data-role="control" data-option="default-editor" data-control="CONTROL/EDITOR" contenteditable="false" style="position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; width:100%;"><gw-fb-element-editor style="box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:100%; line-height:200px; text-align:center; background:#eee;" contenteditable="false">editor</gw-fb-element-editor></gw-fb-element>`,
      }));
  };

  /** 대화 상자 닫기. */
  const handleDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      tempFormId: 0,
      folderSelect: false,
      formConfirmSelect: false,
      folderConfirmSelect: false,
    }));
  };

  const getMacro = (arg: { id: string }) => {
    const { id } = arg;

    if (id === 'DOCUMENT/TODAY')
      return dateTimeFormat(new Date(), 'YYYY-MM-DD');
    if (id === 'DIRECTORY/COMAPNY') return employeeData.companyName;
    if (id === 'DOCUMENT/AUTHOR') return employeeData.employeeName;
    if (id === 'DOCUMENT/AUTHOR_ORGANIZATION')
      return employeeData.organizationName;
    if (id === 'DOCUMENT/AUTHOR_JOBPOSITION')
      return employeeData.jobPositionName;
    if (id === 'DOCUMENT/AUTHOR_JOBDUTY') return employeeData.jobDutyName;
    if (id === 'DOCUMENT/AUTHOR_JOBTITLE')
      return `${employeeData.jobPositionName}/${employeeData.jobDutyName}`;

    return undefined;
  };

  /** 문서 등록 */
  const handleSave = () => {
    const pathname = `${getPathMap('/*/*', props.pathname)}`;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });

    if (!state.selectedFolderId || state.selectedFolderId === 0) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('폴더를 선택 해주세요.'),
      }));
      return;
    }
    if (contentRef.current === null) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('문서를 읽어올 수 없습니다.'),
      }));
      return;
    }
    if (state.subject.trim() === '') {
      const validation = getLocalizedText('제목을 입력하세요.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.isNotified &&
      state.dateOptions === '' &&
      (state.notifyStartDate === null || state.notifyEndDate === null)
    ) {
      const validation = getLocalizedText('상단 고정 기간을 입력해 주세요.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = getLocalizedText(
        '파일 전송 중에는 문서를 저장할 수 없습니다.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => x.isFail) > -1
    ) {
      const validation = getLocalizedText(
        '전송 실패한 파일을 삭제 후 다시 시도 해주세요.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    let notifyStartDate: string | undefined;
    let notifyEndDate: string | undefined;
    if (state.isNotified && currentTimeZone !== 9) {
      if (state.notifyStartDate === null || state.notifyEndDate === null)
        return;
      notifyStartDate = dateFormat(
        initialDate(state.notifyStartDate),
        'yyyy-MM-DD',
      );
      notifyEndDate = dateFormat(
        initialDate(state.notifyEndDate),
        'yyyy-MM-DD',
      );
    }
    if (state.dateOptions === getLocalizedText('상시') && state.isNotified) {
      notifyStartDate = '1000-01-01';
      notifyEndDate = '9999-12-31';
    }

    // 새로고침 방지 false
    setReload(false);

    const param = {
      folderId: state.selectedFolderId,
      subject: state.subject,
      summary: state.summary,
      contents,
      isNotified: state.isNotified,
      notifyStartDate,
      notifyEndDate,
      retentionPeriod: state.selectedRetentionPeriod,
      attachedFiles:
        state.attachedFiles.length > 0
          ? state.attachedFiles.map((x, i) => ({
              fileId: Number(x.id),
              path: state.uploadPath,
              seq: i + 1,
              name: x.name,
              size: x.size,
            }))
          : undefined,
    };

    dispatch(documentsActions.create({ param, location }));
  };

  /** 문서 수정 */
  const handleUpdate = () => {
    const pathname = isTemporarilyStored
      ? `${getPathMap('/*/*', props.pathname)}`
      : `${getPathMap('/*/*/*', props.pathname)}`;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });
    if (!view) {
      const validation = getLocalizedText('문서를 읽을 수 없습니다.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (!state.selectedFolderId || state.selectedFolderId === 0) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('폴더를 선택 해주세요.'),
      }));
      return;
    }
    if (contentRef.current === null) {
      setState((prevState) => ({
        ...prevState,
        validation: getLocalizedText('문서를 읽어올 수 없습니다.'),
      }));
      return;
    }
    if (state.subject.trim() === '') {
      const validation = getLocalizedText('제목을 입력하세요.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = getLocalizedText(
        '파일 전송 중에는 문서를 저장할 수 없습니다.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => x.isFail) > -1
    ) {
      const validation = getLocalizedText(
        '전송 실패한 파일을 삭제 후 다시 시도 해주세요.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    if (
      !isTemporarilyStored &&
      state.updateVersion &&
      state.changeReason === ''
    ) {
      const validation = getLocalizedText('버전 변경 사유를 입력해 주세요.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.isNotified &&
      state.dateOptions === '' &&
      (state.notifyStartDate === null || state.notifyEndDate === null)
    ) {
      const validation = getLocalizedText('상단 고정 기간을 입력해 주세요.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });
    const attachedFiles: {
      fileId: number;
      path: string;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      documentId: view?.id,
      uploadPath: state.uploadPath,
    }).map((a) => ({ ...a, fileId: a.id }));

    // 새로고침 방지 false
    setReload(false);

    const param = {
      id: view.id,
      versionSeq: view.currentVersionSeq,
      folderId: isTemporarilyStored ? state.selectedFolderId : view.folderId,
      subject: state.subject,
      isVersion: state.updateVersion,
      changeReason: state.changeReason,
      summary: state.summary,
      contents,
      isNotified: false, // state.isNotified, // 문서 공지 사용안함. 프론트에서만 제거
      // notifyStartDate,
      // notifyEndDate,
      retentionPeriod: state.selectedRetentionPeriod,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      updateAt: view.updateAt,
    };

    if (isTemporarilyStored)
      dispatch(documentsActions.temporaryActive({ param, location }));
    else dispatch(documentsActions.update({ param, location }));
  };

  /** 임시 저장 */
  const handleTemporarySave = () => {
    if (contentRef.current === null) {
      const validation = getLocalizedText('작성 문서를 읽어올 수 없습니다.');
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = getLocalizedText(
        '파일 전송 중에는 문서를 저장할 수 없습니다.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => x.isFail) > -1
    ) {
      const validation = getLocalizedText(
        '전송 실패한 파일을 삭제 후 다시 시도 해주세요.',
      );
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });
    const attachedFiles: {
      fileId: number;
      path: string;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      documentId: view?.id,
      uploadPath: state.uploadPath,
    }).map((a) => ({ ...a, fileId: a.id }));

    if (isTemporarilyStored && view?.attachedFiles) {
      view.attachedFiles.forEach((a) => {
        if (!state.attachedFiles.find((x) => x.id === `${a.id}`))
          attachedFiles.push({
            path: '',
            fileId: a.id,
            seq: 0,
            name: '',
            size: 0,
            delete: true,
          });
      });

      if (attachedFiles.length === view.attachedFiles.length) {
        let changed = false;
        for (let i = 0; i < attachedFiles.length; i += 1) {
          const a = attachedFiles[i];
          const b = view.attachedFiles[i];
          if (a.fileId !== b.id || a.delete === true) {
            changed = true;
            break;
          }
        }
        if (changed === false) attachedFiles.splice(0, attachedFiles.length);
      }
    }

    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', props.pathname)}`,
        search: props.search,
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });
    setReload(false);

    const param = {
      id: view?.id ?? undefined,
      folderId: state.selectedFolderId,
      subject: state.subject,
      summary: state.summary,
      contents,
      isNotified: false, // 문서 공지 사용안함. 프론트에서만 제거
      retentionPeriod: state.selectedRetentionPeriod,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      updateAt: view?.updateAt ?? undefined,
    };
    dispatch(documentsActions.temporary({ param, location }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 보존기간 변경 */
  const handleChangeRetentionPeriod = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedRetentionPeriod: value,
    }));
  };

  /** 양식 변경 */
  const handleFormSelectChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      tempFormId: Number(value),
      formConfirmSelect: true,
    }));
  };

  /** 문서 버전 업데이트 */
  const handleChangeUpdateVersion = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      updateVersion: event.target.checked,
    }));
  };

  /** 파일 업로드 진행상태 업데이트 */
  const handleUploadProgress = (event: ProgressEvent, id: string) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    setState((prev) => ({
      ...prev,
      attachedFiles: prev.attachedFiles.map((x) => {
        if (x.id === id) {
          return { ...x, progress };
        }
        return x;
      }),
    }));
  };

  /** 첨부 파일 삭제 */
  const handleAttachedFileRemove = (id: string) => {
    setState((prevState) => ({
      ...prevState,
      attachedFiles: prevState.attachedFiles.filter((x) => x.id !== id),
    }));
  };

  /** 첨부 파일 업로드 이벤트 */
  const handleAttachedFileUpload = async (
    uploadingFiles: FileUploadProps[],
  ) => {
    try {
      const files = createUploadFiles(uploadingFiles);
      setState((prev) => ({
        ...prev,
        attachedFiles: [...prev.attachedFiles, ...uploadingFiles],
      }));
      const fileAsyncFunc = files.map(async (x) => {
        await fileApi.uploadOne({
          path: state.uploadPath,
          file: x,
          module: 'document',
          onProgress: handleUploadProgress,
        });
      });

      Promise.all(fileAsyncFunc)
        .then((response) => {
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              attachedFiles: prev.attachedFiles.map((attachedFile) => {
                const uploadingFileIndex = uploadingFiles.findIndex(
                  (x) => x.id === attachedFile.id,
                );
                if (uploadingFileIndex !== -1) {
                  return {
                    ...uploadingFiles[uploadingFileIndex],
                    progress: 100,
                    isUploaded: true,
                  };
                }
                return attachedFile;
              }),
            }));
          }, 100);
        })
        .catch((e) => {
          setState((prev) => ({
            ...prev,
            attachedFiles: prev.attachedFiles.map((attachedFile) => {
              const uploadingFileIndex = uploadingFiles.findIndex(
                (x) => x.id === attachedFile.id,
              );
              if (uploadingFileIndex !== -1) {
                return {
                  ...uploadingFiles[uploadingFileIndex],
                  progress: undefined,
                  isUploaded: false,
                  isFail: true,
                };
              }
              return attachedFile;
            }),
          }));
        });
    } catch (e) {
      dispatch(sessionActions.error(e as ApiError));
    }
  };

  /** 첨부 파일 순서 변경 */
  const handleAttachedFileSortable = (arg: FileUploadProps[]) => {
    setState((prevState) => ({
      ...prevState,
      attachedFiles: [...arg],
    }));
  };

  /** 콘텐트 로드. */
  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { contentMode } = queryParams;
      const { contents } = state;
      const { element } = arg;
      element.innerHTML = FormBuilder.createComposeHtml({
        html: contents,
        editorHeight: '100%',
        modify: contentMode === 'update' || isTemporarilyStored,
        getMacro,
      });
    },
    [queryParams.contentMode, state.contents, state.selectedFolderId],
  );

  /** 폴더 변경 이벤트. */
  const handleFolderSelect = (id: number) => {
    if (id === 0) return;
    setState((prev) => ({
      ...prev,
      tempFolderId: id,
      folderConfirmSelect: true,
      folderSelect: false,
    }));
  };

  /** 콘텐트 렌더링 */
  const renderContent = () => {
    const { contentMode: mode } = queryParams;

    const {
      subject,
      summary,
      selectedFormId,
      selectedRetentionPeriod,
      attachedFiles,
    } = state;

    if (mode === 'create' || mode === 'update') {
      if (contentRef.current !== null) {
        FormBuilder.binding({
          element: contentRef.current,
          getMacro,
        });
      }
    }

    const editorRegExr =
      /^<gw-fb-element[^>]+?data-control="CONTROL\/EDITOR"[^>]*>(.*?)<\/gw-fb-element>/;
    let editorClassName = 'editor-html';
    if (editorRegExr.test(state.contents)) editorClassName += ' no-overflow';
    return (
      <PostWrite name="posting" fullSize>
        <PostWrite.Toolbar onCancel={handleCancel}>
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={view ? handleUpdate : handleSave}
          />
          {queryParams.contentMode === 'create' && (
            <Button
              text={getLocalizedText('임시저장')}
              onClick={handleTemporarySave}
            />
          )}
        </PostWrite.Toolbar>
        <PostWrite.Body>
          <PostWrite.Content>
            <PostWrite.Item>
              {queryParams.contentMode !== 'update' && (
                <DropMenu
                  value={folder?.name ?? ''}
                  onClick={handleFolderChangeDialogOpen}
                  label={getLocalizedText('폴더 선택')}
                />
              )}
            </PostWrite.Item>
            <PostWrite.Title>
              <TextField
                value={subject}
                onChange={handleSubjectChange}
                placeholder={getLocalizedText('제목을 입력하세요.')}
              />
            </PostWrite.Title>
            <PostWrite.Edit>
              <MemoizeDivElement
                ref={contentRef}
                className={editorClassName}
                onLoad={handleContentLoad}
              />
            </PostWrite.Edit>
          </PostWrite.Content>
          <PostWrite.Side>
            {view && !isTemporarilyStored && (
              <PostWrite.Item title={getLocalizedText('버전관리')}>
                <Switch
                  disabled={
                    !folder?.option.isVersion ||
                    folder?.option.isRequiredVersion
                  }
                  checked={state.updateVersion}
                  onChange={handleChangeUpdateVersion}
                />
              </PostWrite.Item>
            )}
            {view && !isTemporarilyStored && (
              <PostWrite.Item title={getLocalizedText('변경사유')}>
                <TextField
                  value={state.changeReason ?? ''}
                  maxLength={200}
                  onChange={handleReasonChange}
                  placeholder={getLocalizedText('변경사유를 입력하세요.')}
                  multiline
                />
              </PostWrite.Item>
            )}
            {folder?.forms !== undefined && folder?.forms.length > 0 ? (
              <PostWrite.Item title={getLocalizedText('양식')}>
                <SelectField
                  data={[
                    ...folder.forms.map((a) => {
                      return {
                        value: a.id.toString(),
                        label: a.name,
                      };
                    }),
                    {
                      label: getLocalizedText('사용안함'),
                      value: '0',
                    },
                  ]}
                  value={selectedFormId.toString()}
                  onChange={handleFormSelectChange}
                />
              </PostWrite.Item>
            ) : null}
            {folder?.retentionPeriods !== undefined &&
            folder?.retentionPeriods.length > 0 ? (
              <PostWrite.Item title={getLocalizedText('보존기간')}>
                <SelectField
                  data={retentionOptions}
                  value={selectedRetentionPeriod}
                  onChange={handleChangeRetentionPeriod}
                />
              </PostWrite.Item>
            ) : null}
            <PostWrite.Item>
              <AddProgressAttachments
                data={attachedFiles}
                maxCount={maxFileCount}
                maxCapacity={maxFileCapacity}
                onRemove={handleAttachedFileRemove}
                onFileUpload={handleAttachedFileUpload}
                onSortable={handleAttachedFileSortable}
              />
            </PostWrite.Item>
          </PostWrite.Side>
        </PostWrite.Body>
      </PostWrite>
    );
  };

  const renderDialog = () => {
    if (state.folderSelect) {
      return (
        <TreePicker
          title={getLocalizedText('폴더 선택')}
          list={[
            {
              id: 0,
              parentId: -1,
              text: getLocalizedText('문서함'),
              icon: 'folder' as const,
            },
            ...treeFolders,
          ]}
          selectedId={state.selectedFolderId}
          rootId={0}
          onSelected={(id) => handleFolderSelect(id)}
          onClose={() => setState((prev) => ({ ...prev, folderSelect: false }))}
        />
      );
    }
    if (state.folderConfirmSelect) {
      return (
        <PromptMessage
          onSubmit={handleFolderSelectDialogOpen}
          onCancel={handleDialogClose}
        >
          {getLocalizedText(
            '폴더 변경 시 입력된 내용이 모두 사라집니다. 폴더를 변경하시겠습니까?',
          )}
        </PromptMessage>
      );
    }
    if (state.formConfirmSelect) {
      return (
        <PromptMessage
          onSubmit={handleFormSelectConfirm}
          onCancel={handleDialogClose}
        >
          {getLocalizedText(
            '양식 선택 시 입력된 내용이 모두 사라집니다. 양식을 선택하시겠습니까?',
          )}
        </PromptMessage>
      );
    }

    return null;
  };

  return (
    <>
      {renderContent()}
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
      <NavigationGuard
        pathname={props.pathname}
        search={props.search}
        reload={reload}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (reload) {
            return true;
          }
          return false;
        }}
      />
    </>
  );
}

export default DocumentComposeContainer;
