import React from 'react';
import Drawer from '../../../../components/drawer/Drawer';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import PostView from '../../../../components/post/PostView';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
  SharePermissionType,
} from '../dialogs/ApprovalLineDialogContainer';
import ApprovalLineFlat from '../components/ApprovalLineFlat';
import { SharePermissionFlat } from '../../adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';
import { documentMacroReplace } from '../../root/approval/compose/ApprovalComposeContainer';
import { createLocalizedTextFactory } from '../../../../groupware-common/utils/i18n';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();
  // console.log(`getLastApprovaledAt(approvalLine):items`, items);

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());
  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return `${createLocalizedTextFactory('approval')('{{n}}일', {
      n: day,
    })} ${createLocalizedTextFactory('approval')('{{n}}시간', {
      n: remainder,
    })}`;
  }
  return createLocalizedTextFactory('approval')('{{n}}시간', { n: time });
}

/** 보존 기간 가져오기. */
function getRetentionPeriod(draftAt: string, retentionPeriod: string): string {
  if (retentionPeriod === '9999-12-31') return '영구';

  const date1 = new Date(draftAt);
  const date2 = new Date(retentionPeriod);

  const day = Math.ceil(
    (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24),
  );

  const year = Math.floor(day / 365);
  const remainder = day % 365;
  const month = Math.ceil(remainder / 30);

  if (year > 0) return `${year} 년 ${month} 개월`;
  return `${month} 개월`;
}

/**
 * 문서 정보 드로워.
 * @param no 문서번호.
 * @param workName 업무 이름.
 * @param formName 양식 이름.
 * @param subject 제목.
 * @param publicOrNot 공개 여부 - 공개:1, 비공개: 0
 * @param approvalLine // 결재선
 * @param draftAt 기안 날짜
 * @param completeAt? 완료 날짜
 * @param retentionPeriod 보존 기간.
 * @returns JSX 엘리먼트.
 */
function ApprovalDocumentInfoDrawer(props: {
  view: {
    no: string; // 문서번호.
    workName: string; // 업무 이름.
    formName: string; // 양식 이름.
    subject: string; // 제목.
    publicOrNot: boolean; // 공개 여부. - 공개:1, 비공개: 0
    approvalLine: ApprovalLineType; // 결재선.
    referencePermission?: SharePermissionType; // 참조권.
    viewPermission?: SharePermissionType; // 조회권.
    draftAt: string; // 기안 날짜.
    completeAt?: string; // 완료 날짜.
    retentionPeriod: string; // 보존 기간.
  };
  onClose(): void;
}): JSX.Element {
  // console.log(`${ApprovalDocumentInfoDrawer.name}.render(props)`, props);

  const {
    workName,
    formName,
    no,
    subject,
    approvalLine,
    referencePermission,
    viewPermission,
    publicOrNot,
    draftAt,
    completeAt,
    retentionPeriod: retentionPeriodAt,
  } = props.view;

  const getLocalizedText = createLocalizedTextFactory('approval');

  const referencePermissionItems =
    referencePermission !== undefined
      ? referencePermission.groups
          .map(({ items }) => {
            if (items.length === 0) return [];
            return items;
          })
          .flat()
      : [];

  const viewPermissionItems =
    viewPermission !== undefined
      ? viewPermission.groups
          .map(({ items }) => {
            if (items.length === 0) return [];
            return items;
          })
          .flat()
      : [];

  /** 기안자 */
  const drafter = getApprovalLineDrafter(approvalLine);

  /** 마지막 결재 날짜 */
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(approvalLine) : undefined;

  /** 대기 시간 */
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  /** 보존 기간 */
  const retentionPeriod = getRetentionPeriod(draftAt, retentionPeriodAt);

  return (
    <Drawer
      title={getLocalizedText('문서정보')}
      size="sm"
      onClose={props.onClose}
    >
      <DrawerBody>
        <PostView>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title={getLocalizedText('문서번호')}>
                  <PostView.CategoryValue value={documentMacroReplace(no)} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('업무')}>
                  <PostView.CategoryValue value={workName} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('양식')}>
                  <PostView.CategoryValue value={formName} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('문서제목')}>
                  <PostView.CategoryValue value={subject} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('문서공개')}>
                  <PostView.CategoryValue
                    value={
                      publicOrNot
                        ? getLocalizedText('공개')
                        : getLocalizedText('비공개')
                    }
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('기안자')}>
                  <PostView.CategoryValue
                    value={drafter?.employeeName || drafter?.organizationName}
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('기안부서')}>
                  <PostView.CategoryValue
                    value={drafter?.organizationName || ''}
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('기안일')}>
                  <PostView.CategoryValue value={draftAt} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('결재선')}>
                  <ApprovalLineFlat
                    optionalNotation
                    approvalLine={approvalLine}
                  />
                </PostView.CategoryItem>
                {referencePermissionItems.length > 0 && (
                  <PostView.CategoryItem title={getLocalizedText('참조권')}>
                    <SharePermissionFlat items={referencePermissionItems} />
                  </PostView.CategoryItem>
                )}
                {viewPermissionItems.length > 0 && (
                  <PostView.CategoryItem title={getLocalizedText('조회권')}>
                    <SharePermissionFlat items={viewPermissionItems} />
                  </PostView.CategoryItem>
                )}
                {waitedAt && (
                  <PostView.CategoryItem title={getLocalizedText('대기')}>
                    <PostView.CategoryValue value={waitedAt} />
                  </PostView.CategoryItem>
                )}
                {completeAt && (
                  <PostView.CategoryItem title={getLocalizedText('완료일')}>
                    <PostView.CategoryValue value={completeAt} />
                  </PostView.CategoryItem>
                )}
                <PostView.CategoryItem title={getLocalizedText('보존기간')}>
                  <PostView.CategoryValue value={retentionPeriod} />
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </DrawerBody>
    </Drawer>
  );
}

export default ApprovalDocumentInfoDrawer;
