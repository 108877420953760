import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  getPathMap,
  getQueryParams,
  createQueryString,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import DataGrid, {
  DataGridAppendItemDef,
  DataGridColDef,
  DataGridMoreActionProps,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { attendanceHolidayActions } from '../../../../stores/attendance/holiday';
import FeedBack from '../../../../../components/alert/FeedBack';

function AttendanceHolidayBasicContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathmap = getPathMap('/*/*/*', props.pathname);
  const [state, setState] = useState<{
    holidayName: string;
    validation: string;
    holidayMonth: string;
    holidayDay: string;
  }>(() => {
    return {
      holidayName: '',
      validation: '',
      holidayMonth: '1',
      holidayDay: '1',
    };
  });

  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(props.search);
  const data = useSelector((s: RootState) => s.attendance.holiday.list.data);
  const categories = useSelector(
    (s: RootState) => s.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6002)?.name ?? '';

  const { pageNo = 1, rowsPerPage = 15 } = queryParams;

  const moreActions: DataGridMoreActionProps[] = [
    {
      code: 'delete',
      label: getLocalizedText('삭제'),
      icon: 'trash-full',
    },
  ];

  /** 기본공휴일 월 불러오기 */
  const monthOptions = [
    { value: '1', label: getLocalizedText('1월') },
    { value: '2', label: getLocalizedText('2월') },
    { value: '3', label: getLocalizedText('3월') },
    { value: '4', label: getLocalizedText('4월') },
    { value: '5', label: getLocalizedText('5월') },
    { value: '6', label: getLocalizedText('6월') },
    { value: '7', label: getLocalizedText('7월') },
    { value: '8', label: getLocalizedText('8월') },
    { value: '9', label: getLocalizedText('9월') },
    { value: '10', label: getLocalizedText('10월') },
    { value: '11', label: getLocalizedText('11월') },
    { value: '12', label: getLocalizedText('12월') },
  ];

  /** 월말 가져오기 */
  const handleLastOfMonth = (month: string): string => {
    let daysInMonth = '30';
    if (
      month === '1' ||
      month === '3' ||
      month === '5' ||
      month === '7' ||
      month === '8' ||
      month === '10' ||
      month === '12'
    ) {
      daysInMonth = '31';
    } else if (month === '2') {
      daysInMonth = '29';
    }
    return daysInMonth;
  };

  /** 월별 일 수 가져오기 */
  const handleDayOption = (
    month: string,
  ): { value: string; label: string }[] => {
    const lastDayOfMonth = handleLastOfMonth(month);
    const days: { value: string; label: string }[] = [];
    for (let i = 1; i <= Number(lastDayOfMonth); i += 1) {
      days.push({
        value: i.toString(),
        label: getLocalizedText('{{day}}일', { day: i }),
      });
    }
    return days;
  };

  /** 월 변경 */
  const handleChangeMonthOption = (value: string) => {
    const lastDayOfMonth = handleLastOfMonth(value);
    setState((prevState) => ({
      ...prevState,
      holidayMonth: value,
      holidayDay:
        Number(state.holidayDay) > Number(lastDayOfMonth)
          ? lastDayOfMonth
          : state.holidayDay,
    }));
  };

  /** 일 수 변경 */
  const handleChangeDayOption = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      holidayDay: value,
    }));
  };

  const columns: DataGridColDef[] = [
    {
      field: 'date',
      label: getLocalizedText('일자'),
      width: 250,
    },
    {
      field: 'subject',
      label: getLocalizedText('휴일명'),
      minWidth: 250,
    },
    { field: 'more', label: '', width: 100, align: 'right' },
  ];

  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id: string;
  }) => {
    const { code, id } = arg;
    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = pageNo - 1;
      const route = {
        pathname: `${props.pathname}`,
        search: getQueryParams(queryParams),
      };
      dispatch(attendanceHolidayActions.basicList({ route }));
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = pageNo + 1;
      const route = {
        pathname: `${props.pathname}`,
        search: getQueryParams(queryParams),
      };
      dispatch(attendanceHolidayActions.basicList({ route }));
    }

    /** 삭제 */
    if (code === 'delete') {
      const item = data.items.find((a) => a.holiday === id);
      if (item === undefined) return;
      const { holiday, holidayName, updateAt } = item;
      const params = { ...queryParams };
      delete queryParams.pageNo;
      const route = {
        pathname: props.pathname,
        search: createQueryString(params),
      };
      dispatch(
        attendanceHolidayActions.basicDelete({
          holiday,
          holidayName,
          updateAt,
          route,
        }),
      );
    }
  };

  /* 목록개수 변경 */
  const handleChangeRowLength = (value: number) => {
    const route = {
      pathname: `${pathmap}`,
      search: createQueryString({ pageNo: 1, rowsPerPage: value }, queryParams),
    };
    dispatch(attendanceHolidayActions.basicList({ route }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /* 휴일명 변경 */
  const handleHolidayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const holidayName = event.target.value;
    setState((prev) => ({ ...prev, holidayName }));
  };

  /* 휴일명에서 Enter 클릭 */
  const handleHolidayNameKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleHolidayAppend();
    }
  };

  /* 기본공휴일 추가 */
  const handleHolidayAppend = () => {
    const { holidayMonth, holidayDay, holidayName } = state;

    if (holidayName.trim() === '' || holidayName === undefined) {
      const validation = getLocalizedText('휴일명을 입력하세요.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const holiday = `00${holidayMonth}`.slice(-2) + `00${holidayDay}`.slice(-2);
    const params = { ...queryParams };
    delete queryParams.pageNo;
    const route = {
      pathname: props.pathname,
      search: createQueryString(params),
    };
    const arg = { holiday, holidayName, route };
    dispatch(attendanceHolidayActions.basicAppend(arg));
    handleCancelChange();
  };

  const handleCancelChange = () => {
    setState((prevState) => ({
      ...prevState,
      holidayName: '',
    }));
  };

  const rows: DataGridRowsProps<string> = data.items.map((l) => {
    const MM = l.holiday.substring(0, 2);
    const dd = l.holiday.substring(2, 4);
    return [
      {
        type: 'text' as const,
        value: getLocalizedText('{{month}}월 {{day}}일', {
          month: MM,
          day: dd,
        }),
      },
      { type: 'text' as const, value: l.holidayName },
      { type: 'more' as const, id: `${MM}${dd}` },
    ];
  });

  const appendItemRows: DataGridAppendItemDef<string> = [
    {
      type: 'holiday' as const,
      data: monthOptions,
      value: state.holidayMonth,
      onChange: handleChangeMonthOption,
    },
    {
      type: 'holiday' as const,
      data: handleDayOption(state.holidayMonth),
      value: state.holidayDay,
      onChange: handleChangeDayOption,
    },
    {
      type: 'text' as const,
      value: state.holidayName,
      maxLength: 20,
      onChange: handleHolidayNameChange,
      onKeyDown: handleHolidayNameKeyDown,
    },
    {
      type: 'button' as const,
      iconType: true,
      icon: 'plus',
      onClick: handleHolidayAppend,
    },
  ];

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          caption={getLocalizedText('기본공휴일 등록')}
          columns={columns}
          rows={rows}
          pagination={{
            no: pageNo,
            total: data.totalCount,
            row: rowsPerPage,
            onChangeRow: handleChangeRowLength,
          }}
          useAddListItems
          appendItemRow={appendItemRows}
          onClick={handleAction}
          moreActions={moreActions}
        />
      </EuiBody>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceHolidayBasicContainer;
