import React, { useState } from 'react';
import Chip from '../../../../../components/chip/Chip';
import Menu from '../../../../../components/menu/Menu';
import ChooseOrganizationChart from '../../../../../groupware-webapp/pages/popup/ChooseOrganizationChart';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

interface ViewersListType {
  id: string;
  name: string;
  organization: string;
  avatar: string;
}

function ApprovalSettingViewer(): JSX.Element {
  const [state, setState] = useState<{
    viewersType: string | null;
    record: ViewersListType[]; // 기록물 조회자
    approvalDocument: ViewersListType[]; // 결재문서 관리 조회자
    form: ViewersListType[]; // 양식함 조회자
    auditDocument: ViewersListType[]; // 감사문서 조회자
    userSelectVisible: boolean;
    userSelectPoint: { x: number; y: number; width: number; height: number };
    snackbarVisible: boolean;
    alertMessage: string;
  }>({
    viewersType: null,
    record: [],
    approvalDocument: [],
    form: [],
    auditDocument: [],
    userSelectVisible: false,
    userSelectPoint: { x: 0, y: 0, width: 0, height: 0 },
    snackbarVisible: false,
    alertMessage: '',
  });

  const handleOpenUserSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    viewersType: string,
  ) => {
    if (state.userSelectVisible) handleCloseUserSelect();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        viewersType,
        userSelectPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        userSelectVisible: true,
      }));
    }
  };

  const handleCloseUserSelect = () => {
    setState((prevState) => ({
      ...prevState,
      viewersType: null,
      userSelectVisible: false,
    }));
  };

  const handleUserSelected = (arg: {
    type: string;
    item: {
      id: string;
      name: string;
      organization: string;
      avatar: string;
    };
  }) => {
    if (arg.type === 'employee') {
      switch (state.viewersType) {
        case 'record':
          if (state.record.find((x) => x.id === arg.item.id)) {
            setState((prevState) => ({
              ...prevState,
              snackbarVisible: true,
              alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
            }));
            return;
          }
          setState((prevState) => ({
            ...prevState,
            record: [...prevState.record, arg.item],
          }));
          break;
        case 'approvalDocument':
          if (state.approvalDocument.find((x) => x.id === arg.item.id)) {
            setState((prevState) => ({
              ...prevState,
              snackbarVisible: true,
              alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
            }));
            return;
          }
          setState((prevState) => ({
            ...prevState,
            approvalDocument: [...prevState.approvalDocument, arg.item],
          }));
          break;
        case 'form':
          if (state.form.find((x) => x.id === arg.item.id)) {
            setState((prevState) => ({
              ...prevState,
              snackbarVisible: true,
              alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
            }));
            return;
          }
          setState((prevState) => ({
            ...prevState,
            form: [...prevState.form, arg.item],
          }));
          break;
        case 'auditDocument':
          if (state.auditDocument.find((x) => x.id === arg.item.id)) {
            setState((prevState) => ({
              ...prevState,
              snackbarVisible: true,
              alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
            }));
            return;
          }
          setState((prevState) => ({
            ...prevState,
            auditDocument: [...prevState.auditDocument, arg.item],
          }));
          break;
        default:
          break;
      }
    }
  };

  const handleDeleteUser = (viewersType: string, id: string) => {
    switch (viewersType) {
      case 'record':
        setState((prevState) => ({
          ...prevState,
          record: prevState.record.filter((x) => x.id !== id),
        }));
        break;
      case 'approvalDocument':
        setState((prevState) => ({
          ...prevState,
          approvalDocument: prevState.record.filter((x) => x.id !== id),
        }));
        break;
      case 'form':
        setState((prevState) => ({
          ...prevState,
          form: prevState.record.filter((x) => x.id !== id),
        }));
        break;
      case 'auditDocument':
        setState((prevState) => ({
          ...prevState,
          auditDocument: prevState.record.filter((x) => x.id !== id),
        }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <EuiSetting.Item title={getLocalizedText('기록물 조회자')}>
        <ChipGroup
          add={getLocalizedText('기록물 조회자 추가')}
          onAdd={(event) => handleOpenUserSelect(event, 'record')}
        >
          {state.record.map((x) => (
            <Chip
              key={x.id}
              label={x.name}
              etc={x.organization}
              avatar={x.avatar}
              onDelete={() => handleDeleteUser('record', x.id)}
            />
          ))}
        </ChipGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('결재문서 관리 조회자')}>
        <ChipGroup
          add={getLocalizedText('결재문서 관리 조회자 추가')}
          onAdd={(event) => handleOpenUserSelect(event, 'approvalDocument')}
        >
          {state.approvalDocument.map((x) => (
            <Chip
              key={x.id}
              label={x.name}
              etc={x.organization}
              avatar={x.avatar}
              onDelete={() => handleDeleteUser('approvalDocument', x.id)}
            />
          ))}
        </ChipGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('양식함 조회자')}>
        <ChipGroup
          add={getLocalizedText('양식함 조회자 추가')}
          onAdd={(event) => handleOpenUserSelect(event, 'form')}
        >
          {state.form.map((x) => (
            <Chip
              key={x.id}
              label={x.name}
              etc={x.organization}
              avatar={x.avatar}
              onDelete={() => handleDeleteUser('form', x.id)}
            />
          ))}
        </ChipGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title={getLocalizedText('감사문서 조회자')}>
        <ChipGroup
          add={getLocalizedText('감사문서 조회자 추가')}
          onAdd={(event) => handleOpenUserSelect(event, 'auditDocument')}
        >
          {state.auditDocument.map((x) => (
            <Chip
              key={x.id}
              label={x.name}
              etc={x.organization}
              avatar={x.avatar}
              onDelete={() => handleDeleteUser('auditDocument', x.id)}
            />
          ))}
        </ChipGroup>
      </EuiSetting.Item>
      {state.userSelectVisible && (
        <Menu point={state.userSelectPoint} onClose={handleCloseUserSelect}>
          <ChooseOrganizationChart onSelected={handleUserSelected} />
        </Menu>
      )}
      <EuiToolbar>
        <EuiToolbar.Left>
          <Button text={getLocalizedText('저장')} variant="contained" />
          <Button text={getLocalizedText('취소')} />
        </EuiToolbar.Left>
      </EuiToolbar>
    </>
  );
}

export default ApprovalSettingViewer;
