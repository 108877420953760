import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomDatePicker from '../../../../../../components/date/CustomDatePicker';
import EmptyData from '../../../../../../components/data/EmptyData';
import { contrastColor } from '../../../../../../groupware-common/utils/ui';
import { scheduleDate } from '../../../../../../groupware-calendar/stores/calendar/schedules';
import { templatesActions } from '../../../../../stores/templates';
import {
  objectArrayToUrlParams,
  urlSearchToObject,
} from '../../../../../stores/common';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

/** 기간 동안의 일정 데이터를 생성합니다. */
const createCalendarEventData = (arg: {
  startDate: Date;
  endDate: Date;
  calendarName: string;
  color: string;
  name: string;
  isFull: boolean;
  isAttendanceCalendar: boolean;
}): {
  date: Date;
  calendarName: string;
  color: string;
  name: string;
  isFull: boolean;
  isAttendanceCalendar: boolean;
  start?: string;
  end?: string;
}[] => {
  const { startDate, endDate, isFull, isAttendanceCalendar } = arg;

  const isFullDayEvent = isFull;
  const isSingleDayEvent = moment(startDate).isSame(endDate, 'day');

  let dates: {
    date: Date;
    calendarName: string;
    color: string;
    name: string;
    isFull: boolean;
    isAttendanceCalendar: boolean;
    start?: string;
    end?: string;
  }[] = [];

  if (isFullDayEvent) {
    dates = [
      {
        ...arg,
        date: startDate,
        start: getLocalizedText('종일'),
        end: undefined,
      },
    ];
    return dates;
  }
  if (isSingleDayEvent) {
    dates = [
      {
        ...arg,
        date: startDate,
        start: moment(startDate).format('HH:mm'),
        end: moment(endDate).format('HH:mm'),
      },
    ];
    return dates;
  }

  let momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);
  dates = [
    {
      ...arg,
      date: startDate,
      start: isAttendanceCalendar
        ? getLocalizedText('종일')
        : moment(startDate).format('HH:mm'),
      end: undefined,
    },
  ];
  const period = momentEndDate.diff(momentStartDate, 'days') - 1;
  for (let i = 0; i < period; i += 1) {
    momentStartDate = momentStartDate.add(1, 'day');
    dates = [
      ...dates,
      {
        ...arg,
        date: momentStartDate.toDate(),
        start: getLocalizedText('종일'),
        end: undefined,
      },
    ];
  }
  dates = [
    ...dates,
    {
      ...arg,
      date: endDate,
      start: isAttendanceCalendar
        ? getLocalizedText('종일')
        : moment(endDate).format('HH:mm'),
      end: undefined,
    },
  ];
  return dates;
};

function CalendarEventsContainer(props: {
  list: {
    calendarName: string;
    color: string;
    name: string;
    isFull: boolean;
    isAttendanceCalendar: boolean;
    start?: string | undefined;
    end?: string | undefined;
  }[];
}) {
  const { list } = props;
  return (
    <div className="calendar-events-container" style={{ width: '100%' }}>
      {list.length > 0 ? (
        list.map((x) => {
          return <CalendarEventsItem item={x} />;
        })
      ) : (
        <EmptyData />
      )}
    </div>
  );
}

function CalendarEventsItem(props: {
  item: {
    calendarName: string;
    color: string;
    name: string;
    isFull: boolean;
    isAttendanceCalendar: boolean;
    start?: string | undefined;
    end?: string | undefined;
  };
}) {
  const { item } = props;

  return (
    <div>
      <div
        className="content"
        style={{
          color: `${contrastColor(`#${item.color}`)}`,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: `#${item.color}`,
          borderRadius: 8,
          padding: '10px 10px 10px 14px',
          marginBottom: '6px',
        }}
      >
        <div className="head">
          <div
            className="subject"
            style={{ fontWeight: 500, marginBottom: '2px' }}
          >
            {item.name}
          </div>
          {item.start && <span className="start">{item.start}</span>}
          {item.start && item.end && <span className=""> ~ </span>}
          {item.end && <span className="end">{item.end}</span>}
        </div>
      </div>
    </div>
  );
}

function CalendarEventsContentTemplate(props: {
  id: number;
  list: {
    calendarName: string;
    color: string;
    name: string;
    startDateTime: string;
    endDateTime: string;
    isFull: boolean;
    isAttendanceCalendar: boolean;
  }[];
  search: string;
}): JSX.Element {
  const isMobile =
    useSelector((state: RootState) => state.session.display) === 'phone';
  const { list, id, search } = props;

  const sortList = [...list]
    .sort((a, b) => {
      if (a.isFull && !b.isFull) {
        return -1;
      }
      if (!a.isFull && b.isFull) {
        return 1;
      }
      return 0;
    })
    .map((x) => ({
      ...x,
      startDate: moment(x.startDateTime).toDate(),
      endDate: moment(x.endDateTime).toDate(),
    }));

  let result: {
    date: Date;
    calendarName: string;
    color: string;
    name: string;
    isFull: boolean;
    isAttendanceCalendar: boolean;
    start?: string;
    end?: string;
  }[] = [];
  sortList.forEach((x) => {
    result = [...result, ...createCalendarEventData(x)];
  });

  const [state, setState] = useState<{
    search?: {
      selectedDate: string;
      from: string;
      to: string;
    };
    selectedItems: {
      calendarName: string;
      color: string;
      name: string;
      isFull: boolean;
      isAttendanceCalendar: boolean;
      start?: string | undefined;
      end?: string | undefined;
    }[];
  }>({
    selectedItems: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const urlSearchObj = urlSearchToObject(search, 'CALENDAR-EVENTS');

    setState((prev) => ({
      ...prev,
      search: {
        ...prev.search,
        selectedDate: urlSearchObj.selectedDate,
        from: urlSearchObj.from,
        to: urlSearchObj.to,
      },
      selectedItems:
        prev.search === undefined ||
        urlSearchObj.selectedDate !== prev.search.selectedDate
          ? result.filter(
              (x) =>
                moment(x.date).format('YYYY-MM-DD') ===
                moment(urlSearchObj.selectedDate).format('YYYY-MM-DD'),
            )
          : prev.selectedItems,
    }));
  }, [props.search]);

  /** 날짜 선택 이벤트. */
  const handleChangeDate = (date: Date) => {
    const changedItems = result.filter(
      (x) =>
        moment(x.date).format('YYYY-MM-DD') ===
        moment(selectedDate).format('YYYY-MM-DD'),
    );
    dispatch(
      templatesActions.setSearch({
        id,
        search: objectArrayToUrlParams({
          ...state.search,
          selectedDate: moment(date).format('YYYY-MM-DD'),
        }),
      }),
    );
    setState((prev) => ({
      ...prev,
      selectedItems: changedItems,
    }));
  };

  // TODO 월요일 구분
  // const firstDayOfWeek = basic.firstDayOfWeek === 'SUN' ? 0 : 1;

  // 월 이동
  const handleSelectMonth = (date: Date) => {
    const from = scheduleDate({
      hash: '#monthly',
      date: moment(date).day(1).format('YYYY-MM-DD'),
      type: 'start',
    });
    const to = scheduleDate({
      hash: '#monthly',
      date: scheduleDate({
        hash: '#monthly',
        date: moment(date).day(1).format('YYYY-MM-DD'),
        type: 'start',
      }),
      type: 'end',
    });
    dispatch(
      templatesActions.view({
        id,
        search: objectArrayToUrlParams({
          ...state.search,
          from,
          to,
        }),
        noLoading: true,
      }),
    );
  };

  if (!state.search) return <></>;
  const { selectedDate } = state.search;

  return (
    <div className="template-content calendar-events">
      {!isMobile && (
        <CustomDatePicker
          controlClassName="calendar-dashboard-datepicker"
          dateFormat="yyyy-MM-dd"
          selected={selectedDate ? new Date(selectedDate) : undefined}
          inline
          onMonthChange={handleSelectMonth}
          onChange={handleChangeDate}
          highlightDates={result.map((x) => x.date)}
        />
      )}
      <CalendarEventsContainer list={state.selectedItems} />
    </div>
  );
}

export default CalendarEventsContentTemplate;
