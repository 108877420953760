import React, { useEffect, useState } from 'react';
import { getParentItems } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../../components/post/PostWrite';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import DropMenu from '../../../../../components/selectField/DropMenu';
import TextField from '../../../../../components/textfield/TextField';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import Loading from '../../../../../components/loading/Loading';

function BoardFormEditInfoDialog(props: {
  mode: 'create' | 'update' | 'loading';
  folders: {
    id: number;
    parentId: number;
    text: string;
  }[];
  folderId: number;
  name: string;
  description: string;
  status: boolean;
  onCancel(): void;
  onConfirm(arg: {
    folderId: number;
    name: string;
    description: string;
    status: boolean;
  }): void;
}): JSX.Element {
  const [state, setState] = useState(() => {
    const folders = props.folders.map(({ id, parentId, text }) => ({
      id,
      parentId,
      text,
      icon: 'folder' as const,
    }));
    const { mode, folderId, name, description, status } = props;
    return {
      mode,
      folders,
      folderPaths: [''], // string array
      folderId,
      folderHelp: '',
      name,
      nameHelp: '',
      description,
      status,
      folderSelectDialogOpen: false,
    };
  });
  useEffect(() => {
    const { mode, folders, folderId, name, description, status } = props;
    setState((prevState) => ({
      ...prevState,
      mode,
      folderPaths: getParentItems(folders, folderId).map((a) => a.text),
      folderId,
      name,
      description,
      status,
    }));
  }, [props.mode]);

  /** 폴더 선택 대화 상자 열기. */
  const handleFolderSelectDialogOpen = () => {
    const folderSelectDialogOpen = true;
    setState((prevState) => ({ ...prevState, folderSelectDialogOpen }));
  };

  /**  이름 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: name } = event.currentTarget;
    setState((prevState) => ({ ...prevState, name }));
  };

  /**  이름 블러. */
  const handleNameBlur = () => {
    const { name } = state;
    if (name.trim() === '') {
      const nameHelp = getLocalizedText('필수 정보입니다.');
      setState((prevState) => ({ ...prevState, nameHelp }));
      return;
    }
    setState((prevState) => ({ ...prevState, nameHelp: '' }));
  };

  /** 설명 변경. */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value: description } = event.currentTarget;
    setState((prevState) => ({ ...prevState, description }));
  };

  /** 상태 변경. */
  const handleStatusChange = (status: boolean) => {
    setState((prevState) => ({ ...prevState, status }));
  };

  /** 확인. */
  const handleConfirm = () => {
    const { onConfirm } = props;
    const { folderId, name, description, status } = state;

    if (folderId === 0) {
      setState((prevState) => ({
        ...prevState,
        folderHelp: getLocalizedText('필수 정보입니다.'),
      }));
      return;
    }
    if (name.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        nameHelp: getLocalizedText('필수 정보입니다.'),
      }));
      return;
    }
    onConfirm({ folderId, name, description, status });
  };

  const renderDialog = () => {
    const { folders, folderId, folderSelectDialogOpen } = state;

    if (folderSelectDialogOpen)
      return (
        <TreePicker
          title={getLocalizedText('분류 선택')}
          // type="group"
          list={folders}
          selectedId={folderId}
          onSelected={(id, text, paths) => {
            // console.log(`onSelected(arg)`, { id, text, paths });
            setState((prevState) => ({
              ...prevState,
              folderId: id,
              folderPaths: paths,
              folderHelp: '',
              folderSelectDialogOpen: false,
            }));
          }}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              folderSelectDialogOpen: false,
            }))
          }
        />
      );
    return null;
  };

  const { onCancel } = props;
  const { mode, folderPaths, folderHelp, name, nameHelp, description, status } =
    state;
  return (
    <>
      <Dialog className="ui-form-edit" size="xs">
        <DialogHeader>
          <DialogTitle>
            {mode === 'create'
              ? getLocalizedText('양식 등록하기')
              : getLocalizedText('양식 수정하기')}
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            <PostWrite.Item required title={getLocalizedText('양식 분류')}>
              <DropMenu
                value={folderPaths}
                error={folderHelp !== ''}
                label={getLocalizedText('양식 분류 선택')}
                onClick={handleFolderSelectDialogOpen}
                block
              />
            </PostWrite.Item>
            <PostWrite.Item required title={getLocalizedText('양식명')}>
              <TextField
                required
                value={name}
                error={nameHelp !== ''}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('양식설명')}>
              <TextField
                multiline
                rows={3}
                value={description}
                onChange={handleDescriptionChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사용여부')}>
              <RadioGroup
                data={[
                  { value: true, label: getLocalizedText('사용함') },
                  {
                    value: false,
                    label: getLocalizedText('사용안함'),
                  },
                ]}
                value={status}
                name="status"
                onChange={handleStatusChange}
              />
            </PostWrite.Item>
          </PostWrite>
          {mode === 'loading' && <Loading />}
        </DialogBody>
        <DialogFooter>
          <Button text={getLocalizedText('취소')} onClick={onCancel} />
          <Button
            text={getLocalizedText('계속')}
            variant="contained"
            onClick={handleConfirm}
            disabled={mode === 'loading'}
          />
        </DialogFooter>
      </Dialog>
      {renderDialog()}
    </>
  );
}

export default BoardFormEditInfoDialog;
