import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import DashBoardDraggableItem from './DashBoardDraggableItem';
import Button from '../../../../../components/button/Button';
import TextField from '../../../../../components/textfield/TextField';
import DashBoardComposeItem from './DashBoardComposeItem';
import {
  createQueryString,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import { portalsActions } from '../../../../stores/portals';
import DashBoardTemplateAddDialog from './DashBoardTemplateAddDialog';
import FeedBack from '../../../../../components/alert/FeedBack';
import {
  PortalTemplateDetail,
  templatesActions,
} from '../../../../stores/templates';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DashBoardComposeContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const queryParams = getQueryParams(props.search);
  const dispatch = useAppDispatch();

  const isTeams = useSelector(
    (state: RootState) => state.session.resource === 'teams',
  );
  const isPC = useSelector(
    (state: RootState) => state.session.display === 'pc',
  );
  const portalList = useSelector(
    (state: RootState) => state.dashboard.portals.list,
  );
  const portalView = useSelector(
    (state: RootState) => state.dashboard.portals.view,
  );
  const templateList = useSelector(
    (state: RootState) => state.dashboard.templates.list,
  );

  const isAdmin =
    getPathParams<{ p1: string }>('/:p1', props.pathname).p1 === 'adminconsole';

  const initialState = portalView
    ? {
        title: portalView.name,
      }
    : {
        title: '',
      };

  const [state, setState] = useState<{
    sortList: PortalTemplateDetail[];
    title: string;
    isTitleEdit: boolean;
    isOrderEdit: boolean;
    selectedId: number;
  }>({
    ...initialState,
    isTitleEdit: false,
    isOrderEdit: false,
    selectedId: 0,
    sortList: templateList,
  });

  const [validation, setValidation] = useState('');

  /** 수정 취소 */
  const handleClose = () => {
    delete queryParams.contentMode;
    const selectedId = portalList.find((x) => x.isInitial)?.id;
    if (isTeams) go('/dashboard');
    else if (selectedId) go('/');
  };

  /** 템플릿 추가 다이얼로그 보기 */
  const handleCreateDialogVisible = () => {
    dispatch(sessionActions.setDialog({ type: 'template', mode: 'create' }));
  };

  /** 타이틀 수정 보기 */
  const handleChangeTitleEdit = () => {
    setState((prev) => ({
      ...prev,
      isTitleEdit: true,
    }));
  };

  /** 타이틀 수정 취소 */
  const handleChangeTitleCancel = () => {
    setState((prev) => ({
      ...prev,
      title: portalView?.name ?? '',
      isTitleEdit: false,
    }));
  };

  /** 타이틀 수정 */
  const handleUpdateTitle = () => {
    if (state.title.trim() === '' || state.title.length > 20) {
      setValidation(
        getLocalizedText('포탈 이름은 1자리 이상 20자리 이하여야 합니다.'),
      );
      return;
    }
    if (portalView) {
      dispatch(
        portalsActions.updatePortal({
          portalId: portalView.id,
          title: state.title,
          updateAt: portalView.updateAt,
          isAdmin,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined) {
          setState((prev) => ({
            ...prev,
            isTitleEdit: false,
          }));
        }
      });
    }
  };

  /** 순서변경 보기 */
  const handleChangeOrderEdit = () => {
    setState((prev) => ({
      ...prev,
      isOrderEdit: true,
      sortList: templateList,
    }));
  };

  /** 순서 변경 */
  const handleChangeOrder = () => {
    const portalId = portalView?.id;
    if (!portalId) return;
    dispatch(
      templatesActions.sort({
        portalId,
        list: state.sortList.map((x, idx) => ({
          id: x.id,
          seq: idx + 1,
          updateAt: x.updateAt,
        })),
        isAdmin,
      }),
    ).then(() => {
      setState((prev) => ({
        ...prev,
        isOrderEdit: false,
        sortList: [],
      }));
    });
  };

  /** 순서변경 취소 */
  const handleChangeOrderCancle = () => {
    setState((prev) => ({
      ...prev,
      isOrderEdit: false,
      sortList: [],
    }));
  };

  /** 타이틀 입력 이벤트. */
  const handleChangeTitle = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState((prevState) => ({
      ...prevState,
      title: event.target.value,
    }));
  };

  /** 순서변경 저장 */
  const handleSort = (newList: PortalTemplateDetail[]) => {
    setState((prev) => ({
      ...prev,
      sortList: newList,
    }));
  };

  /** 템플릿 삭제 */
  const handleDeleteTemplate = () => {
    const item = templateList.find((x) => x.id === state.selectedId);
    if (item && portalView) {
      delete queryParams.dialogMode;
      delete queryParams.dialogType;
      const route = {
        pathname: props.pathname,
        search: getQueryParams(queryParams),
      };
      dispatch(
        templatesActions.deleteTemplate({
          portalId: portalView.id,
          portalTemplateId: item.id,
          updateAt: item.updateAt,
          isAdmin,
          route,
        }),
      );
    }
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setValidation('');
  };

  /** 템플릿 삭제 다이얼로그 보기 */
  const handleDeleteTemplateDialogVisible = (id: number) => {
    setState((prev) => ({
      ...prev,
      selectedId: id,
    }));
    dispatch(sessionActions.setDialog({ mode: 'delete' }));
  };

  /** 다이얼로그 닫기 */
  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      selectedId: 0,
    }));
    dispatch(sessionActions.setDialog());
  };

  /** 템플릿 수정 저장 */
  const handleUpdateTemplate = (url: string, id: number, options?: string) => {
    const item = templateList.find((x) => x.id === id);
    if (!item) return;
    dispatch(
      templatesActions.update({ data: { ...item, url, options }, isAdmin }),
    );
  };

  /** 템플릿 추가 */
  const handleCreateTemplate = (arg: {
    templateId: number;
    url: string;
    options?: string;
  }) => {
    const { templateId, options, url } = arg;
    delete queryParams.dialogType;
    delete queryParams.dialogMode;
    const route = {
      pathname: props.pathname,
      search: createQueryString(queryParams),
    };
    if (!portalView) return;
    dispatch(
      templatesActions.save({
        portalId: portalView.id,
        templateId,
        options,
        url,
        route,
        isAdmin,
      }),
    ).then(() => {
      // TODO 리스트 전체 조회 -> 스토어만 업데이트
      dispatch(
        templatesActions.findList({
          portalId: portalView.id,
          isAdmin,
        }),
      );
    });
  };

  const renderDialog = () => {
    const { dialogMode, dialogType } = queryParams;

    if (dialogMode === 'create' && dialogType === 'template') {
      return (
        <DashBoardTemplateAddDialog
          onSave={handleCreateTemplate}
          onClose={handleCloseDialog}
        />
      );
    }

    if (dialogMode === 'delete') {
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              {getLocalizedText('템플릿을 삭제 하시겠습니까?')}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text={getLocalizedText('취소')}
              onClick={handleCloseDialog}
            />
            <Button
              text={getLocalizedText('삭제')}
              variant="contained"
              onClick={handleDeleteTemplate}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  };

  const renderHead = () => {
    // TODO 모바일 gnb메뉴 개발 후 대시보드 타이틀 삭제 예정.
    const title =
      isPC || (isTeams && !isAdmin) ? (
        <></>
      ) : (
        <EuiHeader className="dashboard-title">
          <EuiHeader.Title>{getLocalizedText('홈')}</EuiHeader.Title>
        </EuiHeader>
      );

    let header = <></>;

    if (state.isTitleEdit) {
      header = (
        <div className="ui-dashboard-header">
          <TextField
            value={state.title}
            onChange={handleChangeTitle}
            width={200}
          />
          <div style={{ marginLeft: 12 }}>
            <Button
              text={getLocalizedText('저장')}
              onClick={handleUpdateTitle}
              icon="check-circle"
              iconType
            />
            <Button
              text={getLocalizedText('취소')}
              onClick={handleChangeTitleCancel}
              icon="close"
              iconType
            />
          </div>
        </div>
      );
    } else if (state.isOrderEdit) {
      header = (
        <div className="ui-dashboard-header">
          <div>
            <Button
              text={getLocalizedText('저장')}
              onClick={handleChangeOrder}
              variant="contained"
            />
            <Button
              text={getLocalizedText('취소')}
              onClick={handleChangeOrderCancle}
            />
          </div>
        </div>
      );
    } else
      header = (
        <div className="ui-dashboard-header">
          <div className="header-toolbar-left header-title">{state.title}</div>
          <div>
            <Button
              text={getLocalizedText('타이틀 수정')}
              onClick={handleChangeTitleEdit}
              icon="pen-fill"
              iconType
            />
          </div>
          <div className="header-toolbar-right">
            <Button
              text={getLocalizedText('순서변경')}
              onClick={handleChangeOrderEdit}
              variant="contained"
            />
            <Button
              text={getLocalizedText('템플릿 추가')}
              onClick={handleCreateDialogVisible}
              variant="contained"
            />
            {!isAdmin && (
              <Button
                text={getLocalizedText('취소')}
                onClick={handleClose}
                variant="outlined"
              />
            )}
          </div>
        </div>
      );

    return (
      <>
        {title}
        {header}
      </>
    );
  };

  const renderBody = () => {
    // 순서 변경
    if (state.isOrderEdit) {
      return (
        <ReactSortable
          list={state.sortList}
          setList={handleSort}
          animation={200}
          handle=".drag"
          className="ui-dashboard-container"
          forceFallback
        >
          {state.sortList.map((x) => {
            return (
              <DashBoardDraggableItem key={`dashboard-${x.id}`} item={x} />
            );
          })}
        </ReactSortable>
      );
    }
    return (
      <div className="ui-dashboard-container editing">
        {templateList.map((x: PortalTemplateDetail) => {
          return (
            <DashBoardComposeItem
              key={`dashboard-${x.id}`}
              item={x}
              onDelete={handleDeleteTemplateDialogVisible}
              onUpdate={handleUpdateTemplate}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default DashBoardComposeContainer;
