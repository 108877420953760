import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  LoginSettingItem,
  SaveLoginSettingItem,
} from '../../../../store/login';

/** 회사 로그인 정책 설정 조회. */
async function fetchLoginSetting(companyId: number): Promise<LoginSettingItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/signin/setting`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회사 로그인 정책 설정 저장. */
async function saveLoginSetting(arg: {
  companyId: number;
  data: SaveLoginSettingItem;
}): Promise<LoginSettingItem> {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/signin/setting`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const loginSettingApi = {
  loginSetting: fetchLoginSetting,
  saveLoginSetting,
};

export default loginSettingApi;
