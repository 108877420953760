import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Language,
  LocateArg,
  NamespaceType,
} from '../../../groupware-common/types';
import i18nApi from '../../apis/i18n/v1';
import { resourceReload } from '../../../groupware-common/utils/i18n';
import { RootState } from '../../app/store';

const name = 'i18n';

export interface LocaleString {
  code: Language;
  value: string;
}

interface NamespaceData {
  [key: string]: { lastUpdateAt: string };
}

interface State {
  lastUpdateAt: string;
  namespaces: {
    directory?: NamespaceData;
    jobclass?: NamespaceData;
  };
}
const initialState: State = {
  namespaces: {
    directory: {
      'ko-KR': { lastUpdateAt: '1000-01-01' },
    },
    jobclass: {
      'ko-KR': { lastUpdateAt: '1000-01-01' },
    },
  },
  lastUpdateAt: '1000-01-01',
};

const findLastupdateAt = createAsyncThunk(
  `${name}/findLastupdateAt`,
  async (
    arg: {
      language: Language;
      namespace: NamespaceType;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { namespaces } = (getState() as RootState).i18n;
      const data = await i18nApi.findLastupdateAt(arg);
      const lastUpdateAt =
        arg.namespace === 'directory'
          ? namespaces.directory?.[arg.language]?.lastUpdateAt
          : namespaces.jobclass?.[arg.language]?.lastUpdateAt;
      if (lastUpdateAt === undefined || data.lastUpdateAt !== lastUpdateAt) {
        await resourceReload(arg.language, arg.namespace);
        return { ...arg, lastUpdateAt: data.lastUpdateAt };
      }
      return undefined;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const i18nSlice = createSlice({
  name,
  initialState,
  reducers: {
    resourceLastUpdateAt(
      state,
      action: PayloadAction<{
        lastUpdateAt: string;
      }>,
    ) {
      state.lastUpdateAt = action.payload.lastUpdateAt;
    },
    localeTimestampUpdate(
      state,
      action: PayloadAction<{
        language: Language;
        namespace: string;
        lastUpdateAt: string;
      }>,
    ) {
      const { language, namespace, lastUpdateAt } = action.payload;

      if (namespace === 'directory') {
        const directoryLastUpdateAt =
          state.namespaces.directory?.[language]?.lastUpdateAt;
        if (!directoryLastUpdateAt || lastUpdateAt !== directoryLastUpdateAt) {
          state.namespaces.directory = {
            [language]: {
              lastUpdateAt,
            },
          };
        }
      }
      if (namespace === 'jobclass') {
        const jobClassLastUpdateAt =
          state.namespaces.jobclass?.[language]?.lastUpdateAt;
        if (!jobClassLastUpdateAt || lastUpdateAt !== jobClassLastUpdateAt) {
          state.namespaces.jobclass = {
            [language]: {
              lastUpdateAt,
            },
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findLastupdateAt.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { language, namespace, lastUpdateAt } = action.payload;

      if (namespace === 'directory') {
        state.namespaces.directory = {
          [language]: {
            lastUpdateAt,
          },
        };
      }
      if (namespace === 'jobclass') {
        state.namespaces.jobclass = {
          [language]: {
            lastUpdateAt,
          },
        };
      }
    });
  },
});

export default i18nSlice.reducer;

export const i18nActions = {
  localeTimestampUpdate: i18nSlice.actions.localeTimestampUpdate,
  resourceLastUpdateAt: i18nSlice.actions.resourceLastUpdateAt,
  findLastupdateAt,
};
