/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { extname } from 'path';
import { getI18n } from 'react-i18next';
import moment from 'moment';
import { Module } from '../types';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import 'moment/locale/ja';
import 'moment/locale/vi';
import { IconType } from '../types/icon';
import { ReadingPaneMode } from '../../groupware-webapp/stores/types';
import store from '../../groupware-webapp/app/store';
import { getLocalizedText } from './i18n';

export function moduleIcon(module: Module): IconType {
  switch (module) {
    case 'dashboard':
      return 'home-fill';
    case 'directory':
      return 'sitemap-fill';
    case 'approval':
      return 'stamp-fill';
    case 'attendance':
      return 'calendar-check-fill';
    case 'resource':
      return 'module';
    // case 'task':
    //   return 'check-todo';
    case 'calendar':
      return 'calendar-fill';
    // case 'address':
    //   return 'address-book';
    case 'document':
      return 'document-fill';
    case 'board':
      return 'notification';
    case 'mail':
      return 'mail-fill';
    // case 'note':
    //   return 'message-fill';
    case 'contacts':
      return 'address-book';
    // case 'survey':
    //   return 'clipboard-list-check-fill';
    case 'systemlink':
      return 'chain';
    default:
      return 'dot';
  }
}

export function readingPaneModeIcon(mode: ReadingPaneMode): IconType {
  switch (mode) {
    case 'vertical':
      return 'split-left-right';
    case 'media':
      return 'grid-4-fill';
    case 'gallery':
      return 'grid-9';
    default:
      return 'split-list';
  }
}

export function organizationIcon(s: string): IconType {
  switch (s) {
    case 'company':
      return 'company';
    case 'organization':
      return 'sitemap';
    case 'employee':
      return 'person';
    default:
      return 'person';
  }
}

export function filenameExtensionIcon(filename: string) {
  switch (extname(filename).toLowerCase()) {
    case '.7z':
    case '.egg':
    case '.rar':
    case '.zip':
      return 'zip';
    case '.txt':
      return 'text';
    case '.css':
    case '.js':
    case '.ts':
    case '.htm':
    case '.html':
      return 'code';
    case '.pdf':
      return 'pdf';
    case '.xls':
    case '.xlsx':
      return 'excel';
    case '.ppt':
    case '.pptx':
      return 'powerpoint';
    case '.doc':
    case '.docx':
      return 'word';
    case '.hwp':
      return 'hwp';
    case '.webp':
    case '.bmp':
    case '.jpeg':
    case '.jpg':
    case '.gif':
    case '.png':
    case '.svg':
      return 'image';
    case '.avi':
    case '.flv':
    case '.wmv':
    case '.mov':
    case '.mp4':
      return 'video';
    case '.aac':
    case '.ogg':
    case '.flac':
    case '.mp3':
      return 'audio';
    default:
      return 'etc';
  }
}

const ReadableBytesSpec = {
  JEDEC: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], // 1024 단위.
  IEC: ['b', 'Kib', 'Mib', 'Gib', 'Tib', 'Pib', 'Eib', 'Zib', 'Yib'], // 1024 단위.
  SI: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], // 1000 단위.
} as const;

export function readableBytes(
  value: number,
  fractionDigits = 0,
  spec: 'JEDEC' | 'IEC' | 'SI' = 'JEDEC',
) {
  if (value === 0) return `0 ${ReadableBytesSpec[spec][1]}`;

  const unit = spec === 'SI' ? 1000 : 1024;
  const i = Math.floor(Math.log(value) / Math.log(unit));
  // return `${(value / Math.pow(unit, i)).toFixed(fractionDigits)} ${ReadableBytesSpec[spec][i]}`;
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((value / Math.pow(unit, i)).toFixed(fractionDigits))} ${
    ReadableBytesSpec[spec][i]
  }`;
}

export type Byte = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';
// eslint-disable-next-line prettier/prettier
type IECByte = 'b' | 'Kib' | 'Mib' | 'Gib' | 'Tib' | 'Pib' | 'Eib' | 'Zib' | 'Yib';
export function readableBytesMaxGB(
  value: number,
  fractionDigits = 0,
  spec: 'JEDEC' | 'IEC' | 'SI' = 'JEDEC',
): {
  capacity: number;
  byte: Byte | IECByte;
} {
  if (value === 0)
    return {
      capacity: 0,
      byte: ReadableBytesSpec[spec][1],
    };

  const unit = spec === 'SI' ? 1000 : 1024;
  let i = Math.floor(Math.log(value) / Math.log(unit));
  if (i > 3) i = 3;
  return {
    // eslint-disable-next-line no-restricted-properties
    capacity: parseFloat((value / Math.pow(unit, i)).toFixed(fractionDigits)),
    byte: ReadableBytesSpec[spec][i],
  };
}

export function conversionToBytes(
  value: number,
  byte: Byte | IECByte,
  spec: 'JEDEC' | 'IEC' | 'SI' = 'JEDEC',
) {
  const unit = spec === 'SI' ? 1000 : 1024;
  const bytesIndex = ReadableBytesSpec[spec].findIndex(
    (x: Byte | IECByte) => x === byte,
  );
  let convert = value;
  for (let i = 0; i < bytesIndex; i += 1) {
    convert *= unit;
  }
  return convert;
}

// 날짜 간소화
export function dateSimplify(
  date: string,
  type?:
    | 'full'
    | 'fullNumber'
    | 'date'
    | 'dateNumber'
    | 'time'
    | 'timeNumber'
    | 'simple'
    | 'newSimple'
    | 'customFormat',
) {
  const utcOffset = store.getState().session.basicSetting.currentTimeZone;
  const get = moment(date);
  const current = moment();
  const set = get.locale(getI18n().language).utcOffset(utcOffset);
  const getYear = get.format('YYYY');
  const getMD = get.format('M D');
  const currentYear = current.format('YYYY');
  const currentMD = current.format('M D');

  if (type === 'fullNumber') {
    return set.format('YYYY-MM-DD HH:mm:ss');
  }

  if (type === 'full') {
    return set.format('llll');
  }

  if (type === 'date') {
    return set.format('ll');
  }

  if (type === 'dateNumber') {
    return set.format('YYYY-MM-DD');
  }

  if (type === 'customFormat') {
    return set.format('YYYY-MM-DD HH:mm');
  }

  if (type === 'time') {
    return set.format('LT');
  }

  if (type === 'timeNumber') {
    return set.format('HH:mm');
  }

  // 년도가 같지 않을때
  if (getYear !== currentYear) {
    if (type === 'newSimple') return set.format('YYYY년 MM월 DD일');
    return set.format('YY. M. D');
  }

  // 올해중 오늘이 아닐때
  if (getYear === currentYear && getMD !== currentMD) {
    return set.format('MMM Do');
  }

  // 오늘일때
  return set.format('LT');
}

/** 설정한 시간대의 날짜로 변경. */
export function timezoneDate(date?: string | Date) {
  const timeZone = store.getState().session.basicSetting.currentTimeZone;
  const setDate = moment(date)
    .locale(getI18n().language)
    .utcOffset(timeZone)
    .format('yyyy-MM-DD HH:mm');

  return new Date(setDate);
}

/** 한국 시간대의 날짜로 변경. */
export function initialDate(date?: string | Date): Date {
  const setDate = date ? new Date(date) : new Date();

  const timeZone = store.getState().session.basicSetting.currentTimeZone;
  if (timeZone !== 9) setDate.setHours(setDate.getHours() + 9 - timeZone);

  return setDate;
}

/** 타임존 미적용 날짜 포맷
 * - 참고: https://momentjs.com/docs/#/displaying/format/
 */
export function dateFormat(date: string | Date, format: string) {
  return moment(date).locale(getI18n().language).format(format);
}

/** 타임존 적용 날짜 포맷 */
export function dateTimeFormat(date: string | Date, format: string) {
  const timeZone = store.getState().session.basicSetting.currentTimeZone;

  return moment(date)
    .locale(getI18n().language)
    .utcOffset(timeZone)
    .format(format);
}

/**
 * 시간 포맷
 * DAY: N.N일
 * MINUTE : N일 N시간 N분
 * */
export function timeFormat(minutes: number, format: 'DAY' | 'MINUTE'): string {
  const absMinutes = Math.abs(minutes);
  if (format === 'MINUTE') {
    const days = Math.trunc(absMinutes / 480);
    const remainingTime = absMinutes % 480;
    const hours = Math.trunc(remainingTime / 60);
    const remainingMinutes = remainingTime % 60;

    const formattedTimeParts = [];
    if (days !== 0) formattedTimeParts.push(`${days}${getLocalizedText('일')}`);
    if (hours !== 0)
      formattedTimeParts.push(`${hours}${getLocalizedText('시간')}`);
    if (remainingMinutes !== 0 || formattedTimeParts.length === 0)
      formattedTimeParts.push(`${remainingMinutes}${getLocalizedText('분')}`);

    const formattedTime = formattedTimeParts.join(' ');
    return minutes < 0
      ? `-${formattedTime}`
      : formattedTime || getLocalizedText('0분');
  }

  return `${Math.trunc(absMinutes / 480)}${getLocalizedText('일')}`;
}

export function relativeTime(date: string, utcOffset = 0) {
  return moment(date).utcOffset(utcOffset).locale(getI18n().language).fromNow();
}

// yiq
export function contrastColor(hex: string) {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 150 ? '#000000' : '#ffffff'; // default 128
}

// hex to rgb
export function hexToRgb(hex: string) {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

  return null;
}

/** 5글자 랜덤 문자열 생성 */
export function CreateRandomString(): string {
  return Math.random().toString(36).substring(2, 7);
}
