import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import Avatar from '../../../../../components/avatar/Avatar';
import Comment from '../../../../../components/comment/Comment';
import PostView from '../../../../../components/post/PostView';
import { documentApi } from '../../../../../groupware-approval/apis/approval/v1/document';
import ApprovalLineFlat from '../../../../../groupware-approval/pages/common/components/ApprovalLineFlat';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { getCommentItems } from '../../../../../groupware-approval/pages/root/approval/ApprovalContentBodyViewContainer';
import { getAvatarPath } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  attendancesActions,
  AttendanceViewItem,
} from '../../../../stores/attendance/attendances';
import { DocumentDialog } from '../../../../../groupware-approval/pages/root/approval/content/ApprovalAttachedDocumentDialog';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import SecuritiesDocumentDialog from '../../../../../groupware-custom-sangsanginsecurities/pages/root/security/compose/SecuritiesDocumentDialog';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();
  // console.log(`getLastApprovaledAt(approvalLine):items`, items);

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());
  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return getLocalizedText('{{day}}일 {{hour}}시간', {
      day,
      hour: remainder,
    });
  }
  return getLocalizedText('{{hour}}시간', { hour: time });
}

function AttendanceContentBodyView(props: {
  pathname: string;
  type: 'full' | 'split';
  view: AttendanceViewItem | undefined;
  onDraftDocumentPopup?(documentId: number): void;
  onAttachedDocumentPopup(arg: DocumentDialog): void;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const [billSeq, setBillSeq] = useState(undefined as string | undefined);
  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props.pathname]);

  const { type, view } = props;
  if (!view) return <></>;

  const drafter = getApprovalLineDrafter(view.approvalLine);
  if (drafter === undefined) return <div>{getLocalizedText('오류')}</div>;

  const {
    organizationName: drafterOrganizationName,
    employeeName: drafterName,
  } = drafter;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : undefined;

  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });

  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  /** 첨부문서 대화상자. */
  const handleAttachedDocumentPopup = (arg: {
    documentId: number;
    affiliatedCompanyId?: number;
  }) => {
    props.onAttachedDocumentPopup({
      ...arg,
      dialogType: 'attacheddocument',
    });
  };

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: {
    companyId: number;
    documentId: number;
  }) => {
    // console.log(`handleAttachedFileDownloadAll(arg)`, arg);
    const { companyId, documentId } = arg;
    documentApi
      .downloadAttachfileAll(companyId, documentId)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    companyId: number;
    documentId: number;
    id: number;
    name: string;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { companyId, documentId, id, name } = arg;
    documentApi
      .downloadAttachfile(companyId, documentId, id)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 세금계산서 조회 */
  const handleBillClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setBillSeq(value);
  };

  /** 세금계산서 대화상자 닫기 */
  const handleCloseBillSeqPopup = () => {
    setBillSeq(undefined);
  };

  /** 품의서 조회 */
  const handleDraftDocumentClick = (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    const dialogDraftDocument = parseInt(event.currentTarget.value, 10);
    if (props.onDraftDocumentPopup)
      props.onDraftDocumentPopup(dialogDraftDocument);
  };

  /** 의견 답글 추가. */
  const handleOpinionAppend = (arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }) => {
    // console.log(`handleOpinionAppend(args)`, arg);
    if (opinions === undefined) return;

    const { id, parentId, message, parentWriterKey } = arg;

    // 답글의 답글 여부에 따른 내용 생성.
    const contents =
      parentWriterKey !== undefined ? `${parentWriterKey}┼${message}` : message;

    if (id === undefined)
      dispatch(
        attendancesActions.saveOpinion({
          companyId: principal.companyId,
          documentId: view.id,
          parentId,
          act: 0,
          contents,
        }),
      );
    else {
      const opinion = view.opinions?.find((a) => a.id === id);
      if (opinion === undefined) return;
      dispatch(
        attendancesActions.saveOpinion({
          companyId: opinion.companyId,
          documentId: opinion.documentId,
          parentId,
          id,
          act: 0,
          contents,
          updateAt: opinion.updateAt,
        }),
      );
    }
  };

  /** 의견 답글 삭제. */
  const handleOpinionDelete = (id: number) => {
    const opinion = view.opinions?.find((a) => a.id === id);
    if (opinion === undefined) return;
    dispatch(
      attendancesActions.deleteOpinion({
        companyId: opinion.companyId,
        documentId: opinion.documentId,
        id,
        parentId: opinion.parentId,
        updateAt: opinion.updateAt,
      }),
    );
  };

  /** 댓글 및 답글 추가. */
  const handleCommentAppend = (arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }) => {
    if (comments === undefined) return;

    const { id, parentId, message, parentWriterKey } = arg;

    // 답글의 답글 여부에 따른 내용 생성.
    const contents =
      parentWriterKey !== undefined ? `${parentWriterKey}┼${message}` : message;

    // 등록.
    if (id === undefined) {
      dispatch(
        attendancesActions.saveComment({
          companyId: principal.companyId,
          documentId: view.id,
          parentId,
          contents,
        }),
      ).then(() => {
        scrollbar.current?.scrollTo(0, scrollbar.current?.scrollHeight);
      });
    }
    // 수정.
    else {
      const comment = view.comments?.find((a) => a.id === id);
      if (comment === undefined) return;
      dispatch(
        attendancesActions.saveComment({
          companyId: comment.companyId,
          documentId: comment.documentId,
          id,
          parentId,
          contents,
          updateAt: comment.updateAt,
        }),
      );
    }
  };

  /** 댓글 및 답글 삭제. */
  const handleCommentDelete = (id: number) => {
    const comment = view.comments?.find((a) => a.id === id);
    if (comment === undefined) return;
    dispatch(
      attendancesActions.deleteComment({
        companyId: comment.companyId,
        documentId: comment.documentId,
        id,
        parentId: comment.parentId,
        updateAt: comment.updateAt,
      }),
    );
  };

  const opinions = getCommentItems(view.opinions, directory);
  const comments = getCommentItems(view.comments, directory);

  const {
    affiliatedCompanyId,
    subject,
    contents,
    draftAt,
    completeAt,
    approvalLine,
    attachedFiles,
    attachedDocuments,
  } = view;

  /** 마지막 결재 날짜 */
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(view.approvalLine) : undefined;

  /** 대기 시간 */
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  const renderDialog = () => {
    if (billSeq)
      return (
        <SecuritiesDocumentDialog
          billseq={billSeq}
          onClose={handleCloseBillSeqPopup}
        />
      );

    return null;
  };

  const element = (
    <PostView type={type}>
      <PostView.Head>
        <PostView.Title>{subject}</PostView.Title>
        <div className="approval-view-info">
          <Avatar
            className="avatar"
            name={drafterName ?? drafterOrganizationName}
            image={drafterAvatar}
            icon={drafterAvatar ? undefined : 'sitemap-fill'}
          />
          <ApprovalLineFlat approvalLine={approvalLine} />
          <PostView.Info row>
            <PostView.InfoItem
              title={getLocalizedText('기안자')}
              value={drafterName ?? drafterOrganizationName}
            />
            <PostView.InfoItem
              title={getLocalizedText('기안부서')}
              value={drafterOrganizationName}
            />
            <PostView.InfoItem
              title={getLocalizedText('기안일')}
              value={draftAt}
            />
            {completeAt && (
              <PostView.InfoItem
                title={getLocalizedText('완료일')}
                value={completeAt}
              />
            )}
            {waitedAt && (
              <PostView.InfoItem
                title={getLocalizedText('대기일')}
                value={waitedAt}
              />
            )}
          </PostView.Info>
        </div>
      </PostView.Head>
      <PostView.Body>
        <PostView.Content data={contents} />
        <input
          style={{ display: 'none' }}
          type="button"
          id="custom-bill-click"
          onClick={handleBillClick}
        />
        <input
          style={{ display: 'none' }}
          type="button"
          id="custom-draft-document-click"
          onClick={handleDraftDocumentClick}
        />
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={() =>
              handleAttachedFileDownloadAll({
                companyId: attachedFiles[0].companyId,
                documentId: attachedFiles[0].documentId,
              })
            }
          >
            {attachedFiles.map(({ companyId, documentId, id, name, size }) => (
              <AttachmentsItem
                key={id}
                name={name}
                size={size}
                onClick={() =>
                  handleAttachedFileDownload({
                    companyId,
                    documentId,
                    id,
                    name,
                  })
                }
              />
            ))}
          </AttachmentsList>
        )}
        {attachedDocuments && attachedDocuments.length > 0 && (
          <AttachDocumentList count={attachedDocuments.length}>
            {attachedDocuments.map((x) => (
              <AttachDocumentItem
                key={x.id}
                no={x.no
                  .replace(/{년도}/gm, `년도`)
                  .replace(/{월}/gm, `월`)
                  .replace(/{순번}/gm, '')}
                subject={x.subject}
                // TODO: 관계사 아이디 설정
                onClick={() =>
                  handleAttachedDocumentPopup({
                    affiliatedCompanyId,
                    documentId: x.id,
                  })
                }
              />
            ))}
          </AttachDocumentList>
        )}
        {opinions && (
          <Comment
            opinion
            count={opinions.length || 0}
            user={user}
            comments={opinions}
            onAdd={handleOpinionAppend}
            onDelete={handleOpinionDelete}
          />
        )}
        {comments && (
          <Comment
            count={comments.length || 0}
            user={user}
            comments={comments}
            onAdd={handleCommentAppend}
            onDelete={handleCommentDelete}
          />
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {element}
      {renderDialog()}
    </div>
  );
}

export default AttendanceContentBodyView;
