import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getPathMap,
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { portalsActions } from '../../../../stores/portals';
import { templatesActions } from '../../../../stores/templates';
import { folderBoxActions } from '../../../../../groupware-board/stores/folder';
import { folderActions } from '../../../../../groupware-document/stores/document/folders';

function DashBoardComposeRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { portalId } = getPathParams<{ portalId?: number }>(
    `/:module/:portalId$base62`,
    pathname,
  );
  const { organizationId, employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const queryParams = getQueryParams(search);

  useEffect(() => {
    dispatch(folderBoxActions.userFolderList({ organizationId, employeeId }));
    dispatch(folderActions.userFolderList({ employeeId }));
    if (queryParams.contentMode === 'update' && portalId) {
      dispatch(
        templatesActions.findList({
          portalId,
        }),
      );
      dispatch(
        portalsActions.findView({
          portalId,
          route: {
            pathname,
            search,
          },
        }),
      );
    }
  }, [pathname, search]);

  return null;
}

export default DashBoardComposeRoute;
