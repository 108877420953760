import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import SelectField from '../../../../../components/selectField/SelectField';
import { Country, Language } from '../../../../../groupware-common/types';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { languageList } from '../../../../../groupware-webapp/pages/common/containers/GnbContainer';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function SettingPreferencesContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const setting = useSelector((state: RootState) => state.session.basicSetting);
  const languages = useSelector((state: RootState) => state.session.languages);
  const categories = useSelector(
    (state: RootState) => state.setting.information.categories,
  );

  const initialState = {
    saveCheck: false,
    currentLanguage: setting.currentLanguage,
    timeZone: setting.currentTimeZone.toString(),
  };

  const [state, setState] = useState<{
    saveCheck: boolean;
    currentLanguage: Language;
    timeZone: string;
  }>(initialState);

  useEffect(() => {
    setState(initialState);
  }, [setting]);

  const languageOptions: {
    value: string;
    label: string;
    flag: Country;
  }[] = languages.map((a) => {
    const label = a.split('-')[1];
    return {
      value: a,
      label: languageList(a),
      flag: label as Country,
    };
  });
  // TODO 다국어 적용 필요.
  const timeZoneOptions = [
    {
      value: '-11',
      label: getLocalizedText('UTC(-11:00) 사모아', { nsSeparator: false }),
    },
    {
      value: '-10',
      label: getLocalizedText('UTC(-10:00) 하와이', { nsSeparator: false }),
    },
    {
      value: '-9',
      label: getLocalizedText('UTC(-09:00) 알래스카', { nsSeparator: false }),
    },
    {
      value: '-8',
      label: getLocalizedText('UTC(-08:00) 태평양 표준시(미국과 캐나다)', {
        nsSeparator: false,
      }),
    },
    {
      value: '-7',
      label: getLocalizedText('UTC(-07:00) 산지 표준시(미국과 캐나다)', {
        nsSeparator: false,
      }),
    },
    {
      value: '-6',
      label: getLocalizedText('UTC(-06:00) 중부 표준시(미국과 캐나다)', {
        nsSeparator: false,
      }),
    },
    {
      value: '-5',
      label: getLocalizedText('UTC(-05:00) 동부 표준시(미국과 캐나다)', {
        nsSeparator: false,
      }),
    },
    {
      value: '-4',
      label: getLocalizedText('UTC(-04:00) 대서양 표준시(캐나다)', {
        nsSeparator: false,
      }),
    },
    {
      value: '-3',
      label: getLocalizedText('UTC(-03:00) 브라질리아', { nsSeparator: false }),
    },
    {
      value: '-2',
      label: getLocalizedText('UTC(-02:00) 중부 - 대서양', {
        nsSeparator: false,
      }),
    },
    {
      value: '-1',
      label: getLocalizedText('UTC(-1:00) 아조레스', { nsSeparator: false }),
    },
    {
      value: '0',
      label: getLocalizedText('UTC(+00:00) 더블린, 에든버러, 리스본, 런던', {
        nsSeparator: false,
      }),
    },
    // eslint-disable-next-line prettier/prettier
    { value: '1', label: getLocalizedText('UTC(+01:00) 암스테르담, 베를린, 베른, 로마, 스톡홀름, 빈', {nsSeparator: false}) },
    {
      value: '2',
      label: getLocalizedText('UTC(+02:00) 아테네, 부카레스트', {
        nsSeparator: false,
      }),
    },
    {
      value: '3',
      label: getLocalizedText(
        'UTC(+03:00) 모스크바, 상트페테르부르크, 볼고그라드',
        { nsSeparator: false },
      ),
    },
    {
      value: '4',
      label: getLocalizedText('UTC(+04:00) 아부다비, 무스카트', {
        nsSeparator: false,
      }),
    },
    {
      value: '5',
      label: getLocalizedText('UTC(+05:00) 아슈하바트, 타슈켄트', {
        nsSeparator: false,
      }),
    },
    {
      value: '6',
      label: getLocalizedText('UTC(+06:00) 아스타나', { nsSeparator: false }),
    },
    {
      value: '7',
      label: getLocalizedText('UTC(+07:00) 방콕, 하노이, 자카르타', {
        nsSeparator: false,
      }),
    },
    {
      value: '8',
      label: getLocalizedText('UTC(+08:00) 베이징, 충칭, 홍콩, 우루무치', {
        nsSeparator: false,
      }),
    },
    {
      value: '9',
      label: getLocalizedText('UTC(+09:00) 서울, 도쿄', { nsSeparator: false }),
    },
    {
      value: '10',
      label: getLocalizedText('UTC(+10:00) 캔버라, 멜버른, 시드니', {
        nsSeparator: false,
      }),
    },
    {
      value: '11',
      label: getLocalizedText('UTC(+11:00) 솔로몬 제도, 뉴칼레도니아', {
        nsSeparator: false,
      }),
    },
    {
      value: '12',
      label: getLocalizedText('UTC(+12:00) 오클랜드, 웰링턴', {
        nsSeparator: false,
      }),
    },
  ];

  /** 언어 설정 변경 이벤트. */
  const handleChangeLanguage = (value: Language) => {
    setState((prev) => ({
      ...prev,
      saveCheck: true,
      currentLanguage: value,
    }));
  };

  /** 시간대 변경 이벤트. */
  const handleChangeTimeZone = (value: string) => {
    setState((prev) => ({
      ...prev,
      saveCheck: true,
      timeZone: value,
    }));
  };

  /** 저장 이벤트. */
  const handleSave = () => {
    Promise.all([
      i18n.changeLanguage(state.currentLanguage),
      dispatch(
        sessionActions.saveBasicSetting({
          language: state.currentLanguage,
          timeZone: parseInt(state.timeZone, 10),
          updateAt: setting.updateAt,
        }),
      ),
    ]).then(() => {
      setState((prev) => ({ ...prev, saveCheck: false }));
    });
  };

  const title = categories.find((a) => a.id === 'setting')?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('언어')}>
          <SelectField
            data={languageOptions}
            value={state.currentLanguage}
            onChange={handleChangeLanguage}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title={getLocalizedText('시간대')}>
          <SelectField
            data={timeZoneOptions}
            value={state.timeZone}
            onChange={handleChangeTimeZone}
          />
          {/* * 선택한 시간대를 기준으로 전자결재, 게시판, 문서관리에 대해서 보여지는 시간이 변경됩니다 */}
          {/* <HelperText text="" /> */}
        </EuiSetting.Item>
        <EuiToolbar>
          {state.saveCheck && (
            <>
              <Button
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleSave}
              />
              <Button
                text={getLocalizedText('취소')}
                variant="outlined"
                onClick={() => setState(initialState)}
              />
            </>
          )}
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default SettingPreferencesContainer;
