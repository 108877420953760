import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ReactSortable, Sortable } from 'react-sortablejs';
import Tab from '../../../../../components/tab/Tab';
import {
  b62,
  createQueryString,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { PortalItem, portalsActions } from '../../../../stores/portals';
import DashBoardBody from '../common/DashBoardBody';
import DashBoardHeader, {
  DashBoardHeaderAction,
} from '../common/DashBoardHeader';
import {
  objectArrayToUrlParams,
  urlSearchToObject,
} from '../../../../stores/common';
import {
  PortalTemplateDetail,
  templatesActions,
} from '../../../../stores/templates';
import { TemplateKey } from '../../../../constants/templates';
import DashBoardContentItem from './DashBoardContentItem';
import Button from '../../../../../components/button/Button';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import { DashBoardNumbers } from '../../../../../groupware-common/types';
import TextField from '../../../../../components/textfield/TextField';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../../../components/alert/FeedBack';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DashBoardContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { pathname } = props;
  const queryParams = getQueryParams(props.search);

  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const isAdmin =
    getPathParams<{ p1: string }>('/:p1', pathname).p1 === 'adminconsole';
  const isTeams =
    useSelector((state: RootState) => state.session.resource) === 'teams';
  const isPC =
    useSelector((state: RootState) => state.session.display) === 'pc';
  const isMobile =
    useSelector((state: RootState) => state.session.display) === 'phone';
  const portalList = useSelector(
    (state: RootState) => state.dashboard.portals.list,
  );
  const templateList = useSelector(
    (state: RootState) => state.dashboard.templates.list,
  );

  const preferences = useSelector(
    (state: RootState) => state.dashboard.preferences.basic,
  );

  const initialState = {
    confirmPortalDelete: false,
    title: '',
    validation: '',
    isEditing: false,
    saveing: false,
  };

  const [state, setState] = useState<{
    title: string;
    confirmPortalDelete: boolean;
    isEditing: boolean;
    validation: string;
    saveing: boolean;
    selectedId?: number;
  }>(initialState);

  useEffect(() => {
    async function run() {
      dispatch(
        portalsActions.createPortal({
          title: getLocalizedText('새 포탈'),
        }),
      ).then(() => {
        setState((prev) => ({
          ...prev,
          saveing: false,
        }));
      });
    }
    if (state.saveing) run();
  }, [state.saveing]);

  const sortList = useMemo(() => {
    const list = portalList
      .filter((x) => !x.isAdminPortal)
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
    return list;
  }, [portalList]);

  /** 메인 포탈 변경 */
  const handleChangeDashBoardPortal = (id: number) => {
    const route = {
      pathname,
      search: createQueryString(queryParams),
    };
    dispatch(portalsActions.findView({ portalId: id })).then(() => {
      dispatch(templatesActions.findList({ portalId: id, route }));
    });
  };

  /** 포탈 수정 */
  const handleUpdatePortal = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    queryParams.contentMode = 'update';
    go(`/dashboard/${b62(id)}`, queryParams);
  };

  /** 포탈 편집 다이얼로그 오픈 */
  const handleSettingPortalDialogOpen = () => {
    dispatch(sessionActions.setDialog({ type: 'portalUpdate' }));
  };

  /** 포탈 삭제 안내창 표시  */
  const handleDeletePortalConfirm = (selectedId: number) => {
    const item = portalList.find((x) => x.id === selectedId);
    if (!item) return;
    setState((prev) => ({
      ...prev,
      selectedId,
      isEditing: false,
      confirmPortalDelete: true,
    }));
  };

  /** 포탈 타이틀 수정  */
  const handleUpdatePortalTitle = (selectedId: number) => {
    const item = portalList.find((x) => x.id === selectedId);
    if (!item) return;
    setState((prev) => ({
      ...prev,
      confirmPortalDelete: false,
      selectedId,
      title: item.name,
      isEditing: true,
    }));
  };

  /** 포탈 추가 */
  const handleAddPortal = () => {
    setState((prev) => ({
      ...prev,
      saveing: true,
    }));
  };

  const handleSortPortal = (newList: PortalItem[]) => {
    // 포탈 순서 변경 여부 확인.
    let changed = sortList.length !== newList.length;
    if (!changed) {
      for (let i = 0; i < sortList.length; i += 1) {
        if (newList[i].id !== sortList[i].id) {
          changed = true;
          break;
        }
      }
    }

    if (changed) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      const updatedSortList = newList.map((x, i) => ({
        ...x,
        seq: i + 1,
        companyId,
      }));
      dispatch(portalsActions.sortPortal({ data: updatedSortList }));
    }
  };

  /** 포탈 삭제 */
  const handleDeletePortal = () => {
    const { selectedId } = state;
    const item = portalList.find((x) => x.id === selectedId);
    if (!item) return;
    dispatch(
      portalsActions.deletePortal({
        portalId: item.id,
        updateAt: item.updateAt,
      }),
    ).then(() => {
      setState((prev) => ({
        ...prev,
        selectedId: undefined,
        DeleteConfirmation: false,
      }));
    });
  };

  const handleRedirect = (redirectURL: string, responseType: TemplateKey) => {
    go(redirectURL);
  };

  /** 타이틀 입력 */
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  /** 타이틀 저장 취소 */
  const handleChangeTitleCancel = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setState((prev) => ({
      ...prev,
      title: '',
      validation: '',
      isEditing: false,
      selectedId: undefined,
    }));
  };

  /** 포탈 타이틀 편집 블러 */
  const handleTitleEditBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const target = event.relatedTarget as HTMLElement | null;

    // 취소 버튼 클릭 시 타이틀 저장하지 않음.
    if (target && target.classList.contains('cancel-title-edit')) return;

    if (!state.isEditing || !state.selectedId) return;
    const item = portalList.find((x) => x.id === state.selectedId);
    if (!item) return;
    /** 타이틀이 변경되지 않았을 경우 */
    if (state.title === item.name) {
      setState((prev) => ({
        ...prev,
        isEditing: false,
        selectedId: undefined,
      }));
      return;
    }
    dispatch(
      portalsActions.updatePortal({
        title: state.title,
        portalId: item.id,
        updateAt: item.updateAt,
      }),
    ).then(() => {
      setState((prev) => ({
        ...prev,
        isEditing: false,
        title: '',
        selectedId: undefined,
      }));
    });
  };

  const renderHead = () => {
    // TODO 모바일 gnb메뉴 개발 후 대시보드 타이틀 삭제 예정.
    const title =
      isPC || (isTeams && !isAdmin) ? (
        <></>
      ) : (
        <EuiHeader className="dashboard-title">
          <EuiHeader.Title>{getLocalizedText('홈')}</EuiHeader.Title>
        </EuiHeader>
      );

    const children = sortList.map((x) => {
      return (
        <Tab.Item
          key={`content-head-${x.id}`}
          label={x.name}
          selected={x.isInitial}
          onClick={() => handleChangeDashBoardPortal(x.id)}
          hovered={!isMobile}
          onHoveredClick={
            x.isAdminPortal || isMobile
              ? undefined
              : (event) => handleUpdatePortal(event, x.id)
          }
        />
      );
    });
    return (
      <>
        {title}
        <div className="ui-dashboard-header">
          <div
            className="dashboard-header-left"
            style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
          >
            <Tab>
              {
                // 회사 포탈
                portalList
                  .filter((x) => x.isAdminPortal)
                  .map((x) => {
                    return (
                      <Tab.Item
                        key={`content-head-${x.id}`}
                        label={x.name}
                        selected={x.isInitial}
                        onClick={() => handleChangeDashBoardPortal(x.id)}
                        hovered={!isMobile}
                      />
                    );
                  })
              }
              {children}
            </Tab>
          </div>
          <div
            className="dashboard-header-right"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {preferences.useEmployeePortal && (
              <Button
                icon="bar-cog"
                iconType
                text={getLocalizedText('대시보드 편집하기')}
                onClick={handleSettingPortalDialogOpen}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const handleActions = (code: string, id: number) => {
    // 캘린더 - 돌아가기
    if (code === 'redoToday') {
      const search = objectArrayToUrlParams(
        urlSearchToObject('', 'CALENDAR-EVENTS'),
      );
      dispatch(
        templatesActions.view({
          id,
          search,
          noLoading: true,
        }),
      );
    }
    // 캘린더 - 월 선택
    if (code === 'prevMonth' || code === 'nextMonth') {
      dispatch(templatesActions.setSearch({ id, search: '' }));
    }
    // 캘린더 - 캘린더 바로가기
    if (code === 'redirectCalendar') {
      go('/calendar');
    }
  };

  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      isEditing: false,
      selectedId: undefined,
    }));
    dispatch(sessionActions.setDialog());
  };

  const handleStart = (e: Sortable.SortableEvent) => {
    const persistentClone = e.from.insertBefore(
      e.item.cloneNode(true),
      e.from.children[e.oldIndex as number],
    ) as HTMLElement;
    persistentClone.classList.add('sortable-clone');

    persistentClone.classList.remove('sortable-chosen', 'sortable-ghost');
  };
  const handleEnd = (e: Sortable.SortableEvent) => {
    e.from
      .querySelectorAll('.sortable-clone')
      .forEach((child) => child.remove());
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    if (dialogType === 'portalUpdate') {
      return (
        <Dialog
          size="sm"
          className="user-portal-edit-dialog"
          onClose={handleCloseDialog}
        >
          <DialogHeader>
            <DialogTitle>{getLocalizedText('포탈 편집')}</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="portal-body">
              <div className="portal-item-group">
                {portalList
                  .filter((x) => x.isAdminPortal)
                  .map((x) => {
                    return (
                      <div
                        key={`portal-update-${x.id}`}
                        className="portal-item"
                        style={{ background: 'var(--disabled-bg-color)' }}
                      >
                        <div
                          className="title"
                          style={{
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'var(--disabled-text-color)',
                          }}
                        >
                          {x.name}
                        </div>
                      </div>
                    );
                  })}
                <ReactSortable
                  className="portal-group"
                  list={sortList}
                  setList={handleSortPortal}
                  onStart={handleStart}
                  onEnd={handleEnd}
                  animation={200}
                  handle=".drag"
                  draggable=".draggable"
                  forceFallback
                  fallbackTolerance={3}
                >
                  {sortList.map((x) => {
                    let className = 'portal-item';
                    if (!x.isAdminPortal) className += ' draggable';
                    if (x.id === state.selectedId && state.isEditing) {
                      return (
                        <div
                          key={`portal-sort-${x.id}`}
                          className="portal-item"
                        >
                          <TextField
                            value={state.title}
                            onChange={handleChangeTitle}
                            onBlur={(event) => handleTitleEditBlur(event)}
                          />
                          <div className="action-area">
                            <Button
                              className="cancel-title-edit"
                              text={getLocalizedText('취소')}
                              onClick={(event) =>
                                handleChangeTitleCancel(event)
                              }
                              icon="close"
                              iconType
                              color="secondary"
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className={className}>
                        <div className="drag" />
                        <div className="title">{x.name}</div>
                        <div className="action-area">
                          <Button
                            className="edit"
                            text={getLocalizedText('수정')}
                            iconType
                            icon="edit"
                            onClick={() => handleUpdatePortalTitle(x.id)}
                            color="secondary"
                          />
                          <Button
                            className="action delete"
                            text={getLocalizedText('삭제')}
                            iconType
                            icon="times-circle-fill"
                            onClick={() => handleDeletePortalConfirm(x.id)}
                            color="secondary"
                          />
                        </div>
                      </div>
                    );
                  })}
                </ReactSortable>
                {portalList.filter((x) => !x.isAdminPortal).length <
                  DashBoardNumbers.PORTAL_MAX && (
                  <div
                    style={{
                      width: 'inherit',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px 0',
                    }}
                  >
                    <Button
                      noDuplication={state.saveing}
                      loading={state.saveing}
                      text={getLocalizedText('새 포탈 추가')}
                      icon="plus"
                      onClick={handleAddPortal}
                    />
                  </div>
                )}
              </div>
            </div>
          </DialogBody>
        </Dialog>
      );
    }
    if (state.selectedId && state.confirmPortalDelete) {
      return (
        <DeleteConfirmation
          noDuplication
          onSubmit={handleDeletePortal}
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              selectedId: undefined,
              confirmPortalDelete: false,
            }))
          }
        >
          <strong>
            &apos;
            {portalList.find((x) => x.id === state.selectedId)?.name}
            &apos;
          </strong>{' '}
          {getLocalizedText('포탈을(를) 정말 삭제하시겠습니까?')}
        </DeleteConfirmation>
      );
    }
    return null;
  };

  if (portalList.length === 0) return <div />;

  return (
    <>
      {renderHead()}
      <div className="ui-dashboard-container">
        {templateList.map((x: PortalTemplateDetail) => {
          if (x.item) {
            const actions: DashBoardHeaderAction[] = [];
            if (x.search) {
              const obj = urlSearchToObject(x.search, x.templateKey);
              if (
                obj &&
                obj.selectedDate &&
                obj.selectedDate !== moment().format('YYYY-MM-DD')
              ) {
                actions.push({
                  text: getLocalizedText('오늘'),
                  code: 'redoToday',
                  onClick: handleActions,
                  icon: 'redo',
                  id: x.id,
                  variant: 'contained',
                });
              }
            }
            if (isMobile && x.templateKey === 'CALENDAR-EVENTS') {
              actions.push({
                text: getLocalizedText('일정 바로가기'),
                code: 'redirectCalendar',
                onClick: handleActions,
                icon: 'calendar',
                id: x.id,
                variant: 'outlined',
              });
            }
            return (
              <div className="dashboard-item" key={`content-item-${x.id}`}>
                <DashBoardHeader
                  title={getLocalizedText(x.title) ?? ''}
                  folderName={x.folderName}
                  actions={actions.length > 0 ? actions : undefined}
                />
                <DashBoardBody>
                  <DashBoardContentItem
                    template={x}
                    onRedirect={handleRedirect}
                  />
                </DashBoardBody>
              </div>
            );
          }
          return null;
        })}
      </div>
      {renderDialog()}
      {state.selectedId && state.confirmPortalDelete && (
        <DeleteConfirmation
          noDuplication
          onSubmit={handleDeletePortal}
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              selectedId: undefined,
              confirmPortalDelete: false,
            }))
          }
        >
          <strong>
            &apos;
            {portalList.find((x) => x.id === state.selectedId)?.name}
            &apos;
          </strong>{' '}
          {getLocalizedText('을(를) 정말 삭제하시겠습니까?')}
        </DeleteConfirmation>
      )}
      <FeedBack
        text={state.validation}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            validation: '',
          }))
        }
      />
    </>
  );
}

export default DashBoardContentContainer;
