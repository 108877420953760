/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../groupware-webapp/app/store';
import { LocateArg, NoLoading } from '../../../groupware-common/types';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import calendarsApi, {
  AuthorityType,
  SystemIdType,
} from '../../apis/calendar/v1/calendars';
import { b62, getPathMap } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';
import { IconType } from '../../../groupware-common/types/icon';

const name = 'calendar/calendars';

interface CategoryItem {
  type: 'status' | 'organization' | 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}
export interface PermissionType {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디 (organization | employee)
  referenceType: string; // 참조 유형
}

export interface PermissionUserType {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디 (organization | employee)
  referenceType: string; // 참조 유형
  options: {
    isRead: boolean; // 읽기.
    isWrite: boolean; // 쓰기.
  };
}

/** 관리자 캘린더 리스트 객체 */
export interface CalendarFolderList {
  id: number;
  seq: number;
  name: string;
  color: string;
  updateAt: string;
}

/** 관리자 구독 캘린더 리스트 객체 */
export interface CalendarSubFolderList extends CalendarFolderList {
  systemId: SystemIdType;
}

/** 사용자 캘린더 리스트 객체 */
export interface CalendarFolderUserList extends CalendarFolderList {
  systemId?: SystemIdType;
  checked: boolean;
  originalName: string;
  permissions: {
    useRead: boolean;
    useWrite: boolean;
    useControl: boolean;
  };
  isCommon: boolean; // 회사 캘린더 여부.
  isInitialAtUserStarted: boolean; // 사용자 최초 캘린더 여부.
  status: boolean; // 사용여부.
  useExposeCreator: boolean; // 작성자명 표시 여부.
  creatorId?: number; // 생성자 아이디.
}

/** 캘린더 단일 조회 객체 */
export interface CalendarFolderView {
  companyId: number;
  id: number;
  originName?: string;
  name: string;
  description: string;
  color: string;
  status: boolean;
  useExposeCreator: boolean;
  exceptioners: PermissionType[];
  managers: PermissionType[];
  users: PermissionUserType[];
  creatorId?: number;
  updateAt: string;
}

/** 구독 캘린더 단일 조회 객체 */
export interface CalendarSubFolderView {
  id: number;
  systemId: SystemIdType;
  name: string;
  color: string;
  description: string;
  status: boolean;
  useExposeAnniversary?: boolean;
  managerAuthority?: AuthorityType;
  generalUserAuthority?: AuthorityType;
  updateAt: string;
}

/** 캘린더 저장(수정) 데이터 객체 */
export interface SaveCalendarData {
  id?: number;
  name: string;
  description: string;
  color?: string;
  status: boolean;
  useExposeCreator?: boolean;
  exceptioners: PermissionType[];
  managers: PermissionType[];
  users: PermissionUserType[];
  updateAt?: string;
}

/** 기본 폴더 */
const categories: CategoryItem[] = [
  {
    type: 'default',
    id: 'preferences',
    name: '환경설정',
    icon: 'cog-fill',
  },
  // 관리자 - 메뉴.
  {
    type: 'setting',
    id: 6001,
    name: '기본설정',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6002,
    name: '구독 캘린더',
    icon: 'folder',
  },
  {
    type: 'setting',
    id: 6003,
    name: '공유 캘린더',
    icon: 'folder',
  },
];

interface State {
  category: CategoryItem[];

  list: CalendarFolderList[];

  user: {
    list: CalendarFolderUserList[];
    sharedList: CalendarFolderUserList[];
    subList: CalendarFolderUserList[];
  };

  view: CalendarFolderView | undefined;

  sub: {
    list: CalendarSubFolderList[];
    view: CalendarSubFolderView | undefined;
  };
}

const initialState: State = {
  category: categories,
  list: [],
  user: {
    list: [],
    sharedList: [],
    subList: [],
  },
  view: undefined,
  sub: {
    list: [],
    view: undefined,
  },
};

/** 관리자 - 공유 캘린더 조회. */
const findCompanyCalList = createAsyncThunk(
  `${name}/findCompanyCalList`,
  async (_: void | LocateArg, { rejectWithValue }) => {
    try {
      const data = calendarsApi.companyCal();
      const result = (await data)
        .map((a) => ({
          id: a.id,
          seq: a.seq,
          name: a.name,
          color: `#${a.color}`,
          updateAt: a.updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 구독 중인 캘린더 조회. */
const findSubCalendarList = createAsyncThunk(
  `${name}/findSubCalendarList`,
  async (_: void | LocateArg, { rejectWithValue }) => {
    try {
      const data = calendarsApi.subCal();
      const result = (await data)
        .map((a) => ({
          id: a.id,
          seq: a.seq,
          name: a.name,
          systemId: a.systemId,
          color: `#${a.color}`,
          updateAt: a.updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 내 캘린더 조회. */
const findMyCalendarList = createAsyncThunk(
  `${name}/findMyCalendarList`,
  async (_: void | LocateArg, { getState, rejectWithValue }) => {
    try {
      const { principal } = (getState() as RootState).session;
      const result = await calendarsApi.myCalendarList(principal.employeeId);
      const data: CalendarFolderUserList[] = result
        .map((a) => ({
          checked: a.isVisible,
          seq: a.seq,
          id: a.id,
          originalName: a.originalName,
          name: a.name,
          color: `#${a.color}`,
          permissions: a.permissions,
          isCommon: a.isCommon,
          isInitialAtUserStarted: a.isInitialAtUserStarted,
          status: a.status,
          useExposeCreator: a.useExposeCreator,
          updateAt: a.updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 공유받은 캘린더 조회. */
const findSharedCalendarList = createAsyncThunk(
  `${name}/findSharedCalendarList`,
  async (_: void | LocateArg, { getState, rejectWithValue }) => {
    try {
      const { principal } = (getState() as RootState).session;
      const result = await calendarsApi.sharedCalendarList(
        principal.employeeId,
      );
      const commonCalendars: CalendarFolderUserList[] = result
        .filter((a) => a.isCommon)
        .map((a) => ({
          checked: a.isVisible,
          seq: a.seq,
          id: a.id,
          originalName: a.originalName,
          name: a.name,
          color: `#${a.color}`,
          permissions: a.permissions,
          isCommon: a.isCommon,
          isInitialAtUserStarted: a.isInitialAtUserStarted,
          status: a.status,
          useExposeCreator: a.useExposeCreator,
          updateAt: a.updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      const sharedCalendrs: CalendarFolderUserList[] = result
        .filter((a) => !a.isCommon)
        .map((a) => ({
          checked: a.isVisible,
          seq: a.seq,
          id: a.id,
          originalName: a.originalName,
          name: a.name,
          color: `#${a.color}`,
          permissions: a.permissions,
          isCommon: a.isCommon,
          isInitialAtUserStarted: a.isInitialAtUserStarted,
          status: a.status,
          useExposeCreator: a.useExposeCreator,
          creatorId: a.creatorId,
          updateAt: a.updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return [...commonCalendars, ...sharedCalendrs];
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 구독 중인 캘린더 조회. */
const findSubUserCalendarList = createAsyncThunk(
  `${name}/findSubUserCalendarList`,
  async (_: void | LocateArg, { getState, rejectWithValue }) => {
    try {
      const { principal } = (getState() as RootState).session;
      const result = await calendarsApi.userSubCalendarList(
        principal.employeeId,
      );
      const data: CalendarFolderUserList[] = result
        .map((a) => ({
          checked: a.isVisible,
          seq: a.seq,
          id: a.id,
          systemId: a.systemId,
          originalName: a.originalName,
          name: a.name,
          color: `#${a.color}`,
          permissions: {
            useRead: true,
            useWrite: false,
            useControl: false,
          },
          isCommon: false,
          isInitialAtUserStarted: false,
          status: a.status,
          useExposeCreator: false,
          creatorId: 0,
          updateAt: a.updateAt,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 캘린더 단일 조회. */
const findCalendarView = createAsyncThunk(
  `${name}/findCalendarView`,
  async (
    arg: {
      type?: 'mine' | 'shared';
      id: number;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const organizations = (getState() as RootState).directory.organization.list
      .data.items;
    const employees = (getState() as RootState).directory.employee.list.data
      .items;
    try {
      const { type, id } = arg;
      let calendar: CalendarFolderView | undefined;
      if (type) {
        const response = await calendarsApi.calView({ id });
        const exceptioners = response.excluders.filter(
          ({ referenceId: i, referenceType: t }) =>
            t === 'COMPANY' ||
            (t === 'ORGANIZATION' && organizations.some((a) => a.id === i)) ||
            (t === 'EMPLOYEE' && employees.some((a) => a.id === i)),
        );
        const users: PermissionUserType[] = response.users
          .filter(
            ({ permission: p, referenceId: i, referenceType: t }) =>
              !p.useControl &&
              (t === 'COMPANY' ||
                (t === 'ORGANIZATION' &&
                  organizations.some((a) => a.id === i)) ||
                (t === 'EMPLOYEE' && employees.some((a) => a.id === i))),
          )
          .map((a) => ({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
            options: {
              isRead: a.permission.useRead,
              isWrite: a.permission.useWrite,
            },
          }));
        const managers: PermissionType[] = response.users
          .filter(
            ({ permission: p, referenceId: i }) =>
              p.useControl &&
              p.useRead &&
              p.useWrite &&
              employees.some((x) => x.id === i),
          )
          .map((a) => ({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
          }));
        calendar = {
          companyId: response.companyId,
          id: response.id,
          name: response.name,
          description: response.description,
          color: `#${response.color}`,
          status: response.status,
          useExposeCreator: response.useExposeCreator,
          exceptioners,
          users,
          managers,
          creatorId: response.creatorId,
          updateAt: response.updateAt,
        };
      } else {
        const response = await calendarsApi.companyCalView({ id });
        const exceptioners = response.excluders.filter(
          ({ referenceId: i, referenceType: t }) =>
            t === 'COMPANY' ||
            (t === 'ORGANIZATION' && organizations.some((a) => a.id === i)) ||
            (t === 'EMPLOYEE' && employees.some((a) => a.id === i)),
        );
        const users: PermissionUserType[] = response.users
          .filter(
            ({ permission: p, referenceId: i, referenceType: t }) =>
              !p.useControl &&
              (t === 'COMPANY' ||
                (t === 'ORGANIZATION' &&
                  organizations.some((a) => a.id === i)) ||
                (t === 'EMPLOYEE' && employees.some((a) => a.id === i))),
          )
          .map((a) => ({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
            options: {
              isRead: a.permission.useRead,
              isWrite: a.permission.useWrite,
            },
          }));
        calendar = {
          companyId: response.companyId,
          id: response.id,
          name: response.name,
          description: response.description,
          color: `#${response.color}`,
          status: response.status,
          useExposeCreator: response.useExposeCreator,
          exceptioners,
          users,
          managers: [],
          creatorId: response.creatorId,
          updateAt: response.updateAt,
        };
      }
      return calendar;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 구독 캘린더 단일 조회. */
const findSubCalendarView = createAsyncThunk(
  `${name}/findSubCalendarView`,
  async (
    arg: {
      systemId: SystemIdType;
      id: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { systemId, id } = arg;
      const data = await calendarsApi.subCalView({ systemId, id });
      const calendar = {
        id: data.id,
        systemId: data.systemId,
        name: data.name,
        color: `#${data.color}`,
        description: data.description,
        status: data.status,
        useExposeAnniversary: data.useExposeAnniversary,
        managerAuthority: data.managerAuthority,
        generalUserAuthority: data.generalUserAuthority,
        updateAt: data.updateAt,
      };
      return calendar;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 구독 캘린더 저장 이벤트. */
const saveSubCal = createAsyncThunk(
  `${name}/saveSubCal`,
  async (
    arg: {
      data: {
        systemId: SystemIdType;
        useExposeAnniversary?: boolean;
        managerAuthority?: AuthorityType;
        generalUserAuthority?: AuthorityType;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = arg;
      const result = await calendarsApi.createSubCal(data);

      if (arg.route) {
        const route = {
          pathname: `${getPathMap('/*/*/*', arg.route.pathname)}/${b62(
            result.id,
          )}`,
        };
        dispatch(findSubCalendarList());
        dispatch(
          findSubCalendarView({
            systemId: data.systemId,
            id: result.id,
            route,
          }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 구독 캘린더 수정 이벤트. */
const updateSubCal = createAsyncThunk(
  `${name}/updateSubCal`,
  async (
    arg: { data: CalendarSubFolderView } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    const { data, route } = arg;
    try {
      const params = {
        id: data.id,
        color: data.color.substring(1),
        name: data.name,
        description: data.description,
        status: data.status,
        useExposeAnniversary: data.useExposeAnniversary,
        managerAuthority: data.managerAuthority,
        generalUserAuthority: data.generalUserAuthority,
        updateAt: data.updateAt,
      };
      const result = await calendarsApi.updateSubCal({
        systemId: data.systemId,
        params,
      });

      if (route) {
        dispatch(findSubCalendarList());
        dispatch(
          findSubCalendarView({
            systemId: data.systemId,
            id: result.id,
            route,
          }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 구독 캘린더 삭제 이벤트. */
const deleteSubCal = createAsyncThunk(
  `${name}/deleteSubCal`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = arg;
      const response = await calendarsApi.deleteSubCal(data);
      dispatch(findSubCalendarList());
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 공유 캘린더 저장 이벤트. */
const saveCalendar = createAsyncThunk(
  `${name}/saveCalendar`,
  async (
    arg: { data: SaveCalendarData } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = arg;
      const params = {
        name: data.name,
        description: data.description,
        status: data.status,
        useExposeCreator: data.useExposeCreator,
        excluders: data.exceptioners,
        users: data.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          permission: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useControl: false,
          },
        })),
      };
      const result = await calendarsApi.createCompanyCal(params);

      if (arg.route) {
        const route = {
          pathname: `${getPathMap('/*/*/*', arg.route.pathname)}/${b62(
            result.id,
          )}`,
        };
        dispatch(findCompanyCalList());
        dispatch(findCalendarView({ id: result.id, route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 공유 캘린더 수정 이벤트. */
const updateCalendar = createAsyncThunk(
  `${name}/updateCalendar`,
  async (
    arg: { data: SaveCalendarData } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = arg;
      if (!data.id || !data.color || !data.updateAt) return;
      const params = {
        id: data.id,
        name: data.name,
        description: data.description,
        color: data.color.substring(1),
        status: data.status,
        useExposeCreator: data.useExposeCreator,
        excluders: data.exceptioners,
        users: data.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          permission: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useControl: false,
          },
        })),
        updateAt: data.updateAt,
      };
      const result = await calendarsApi.updateCompanyCal(params);

      if (arg.route) {
        dispatch(findCompanyCalList());
        dispatch(findCalendarView({ id: result.id, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 공유 캘린더 삭제 이벤트. */
const deleteCalendar = createAsyncThunk(
  `${name}/deleteCalendar`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      const response = await calendarsApi.deleteCompanyCal({ id, updateAt });
      dispatch(findCompanyCalList());
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 캘린더 저장 이벤트. */
const saveMyCalendar = createAsyncThunk(
  `${name}/saveMyCalendar`,
  async (
    arg: {
      data: SaveCalendarData;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = arg;
      const users: {
        referenceCompanyId: number; // 사용자 회사 아이디
        referenceId: number; // 사용자 아이디 (organization | employee)
        referenceType: string; // 참조 유형
        permission: {
          useRead: boolean;
          useWrite: boolean;
          useControl: boolean;
        };
      }[] = [];
      data.managers.forEach((a) => {
        users.push({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          permission: {
            useRead: true,
            useWrite: true,
            useControl: true,
          },
        });
      });
      data.users.forEach((a) => {
        users.push({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          permission: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useControl: false,
          },
        });
      });
      const params = {
        name: data.name,
        description: data.description,
        status: data.status,
        useExposeCreator: data.useExposeCreator,
        excluders: data.exceptioners,
        users,
      };
      const result = await calendarsApi.createMyCal(params);

      if (arg.route) {
        const route = {
          pathname: `${getPathMap('/*/*', arg.route.pathname)}/${b62(
            result.id,
          )}`,
          search: arg.route.search,
          hash: arg.route.hash,
        };
        dispatch(findMyCalendarList());
        dispatch(findCalendarView({ type: 'mine', id: result.id, route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 내 캘린더 간단 저장 이벤트. */
const simpleSaveMyCalendar = createAsyncThunk(
  `${name}/simpleSaveMyCalendar`,
  async (arg: { name: string } & LocateArg, { rejectWithValue, dispatch }) => {
    try {
      await calendarsApi.simpleCreateMyCal({ name: arg.name });
      dispatch(findMyCalendarList());
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 캘린더 수정 이벤트. */
const updateMyCalendar = createAsyncThunk(
  `${name}/updateMyCalendar`,
  async (
    arg: {
      type: 'mine' | 'shared';
      data: SaveCalendarData;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { type, data } = arg;
      if (!data.id || !data.updateAt) return;
      const users: {
        referenceCompanyId: number; // 사용자 회사 아이디
        referenceId: number; // 사용자 아이디 (organization | employee)
        referenceType: string; // 참조 유형
        permission: {
          useRead: boolean;
          useWrite: boolean;
          useControl: boolean;
        };
      }[] = [];
      data.managers.forEach((a) => {
        users.push({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          permission: {
            useRead: true,
            useWrite: true,
            useControl: true,
          },
        });
      });
      data.users.forEach((a) => {
        users.push({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          permission: {
            useRead: a.options.isRead,
            useWrite: a.options.isWrite,
            useControl: false,
          },
        });
      });
      const params = {
        id: data.id,
        name: data.name,
        description: data.description,
        status: data.status,
        excluders: data.exceptioners,
        users,
        updateAt: data.updateAt,
      };
      const result = await calendarsApi.updateMyCal(params);

      if (arg.route) {
        if (type === 'mine') dispatch(findMyCalendarList());
        if (type === 'shared') dispatch(findSharedCalendarList());
        dispatch(findCalendarView({ type, id: result.id, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 내 캘린더 삭제. */
const deleteMyCal = createAsyncThunk(
  `${name}/deleteMyCal`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      const response = await calendarsApi.deleteMyCal({ id, updateAt });
      dispatch(findMyCalendarList());
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 캘린더 보기 여부 변경. */
const changeChecked = createAsyncThunk(
  `${name}/changeChecked`,
  async (
    arg: {
      type: 'mine' | 'shared' | 'sub';
      id: number;
      isVisible: boolean;
    } & NoLoading,
    { getState, rejectWithValue },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      const result = await calendarsApi.changeChecked({
        employeeId,
        id: arg.id,
        isVisible: arg.isVisible,
      });
      return { type: arg.type, ...result };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 캘린더 색상 변경. */
const changeColor = createAsyncThunk(
  `${name}/changeColor`,
  async (
    arg: {
      type: 'mine' | 'shared' | 'sub';
      id: number;
      color: string;
    } & NoLoading,
    { getState, rejectWithValue },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      const result = await calendarsApi.changeColor({
        employeeId,
        id: arg.id,
        color: arg.color.substring(1),
      });
      return { type: arg.type, ...result };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 작성자명 표시 변경. */
const changeUseExposeCreator = createAsyncThunk(
  `${name}/changeUseExposeCreator`,
  async (
    arg: {
      type: 'mine' | 'shared';
      id: number;
      useExposeCreator: boolean;
    } & NoLoading,
    { getState, rejectWithValue },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      // TODO 작성자명 표시 변경 api 연결 필요.
      const result = await calendarsApi.changeUseExposeCreator({
        employeeId,
        id: arg.id,
        useExposeCreator: arg.useExposeCreator,
      });
      return { type: arg.type, ...result };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 공유 받은 캘린더 이름, 사용여부 변경. */
const changeSharedCal = createAsyncThunk(
  `${name}/changeSharedCal`,
  async (
    arg: {
      id: number;
      name: string;
      status: boolean;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      const nameResult = await calendarsApi.changeName({
        employeeId,
        id: arg.id,
        name: arg.name,
      });
      const statuseResult = await calendarsApi.changeStatus({
        employeeId,
        id: arg.id,
        status: arg.status,
      });
      return { ...nameResult, ...statuseResult };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const calendarSlice = createSlice({
  name,
  initialState,
  reducers: {
    viewClear(state) {
      state.view = undefined;
    },
    subViewClear(state) {
      state.sub.view = undefined;
    },
    subListChecked(
      state,
      action: PayloadAction<{ itemId: number; checked: boolean }>,
    ) {
      const index = state.user.subList.findIndex(
        (a) => a.id === action.payload.itemId,
      );
      if (index > -1)
        state.user.subList[index] = {
          ...state.user.subList[index],
          checked: !action.payload.checked,
        };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findCompanyCalList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.list = payload;
      })
      .addCase(findSubCalendarList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.sub.list = payload;
      })
      .addCase(findMyCalendarList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.user.list = payload;
      })
      .addCase(findSharedCalendarList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.user.sharedList = payload;
      })
      .addCase(findSubUserCalendarList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.user.subList = payload;
      })
      .addCase(findCalendarView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(findSubCalendarView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.sub.view = payload;
      })
      .addCase(deleteCalendar.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = undefined;
      })
      .addCase(deleteSubCal.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.sub.view = undefined;
      })
      .addCase(changeChecked.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          if (payload.type === 'mine')
            state.user.list = state.user.list.map((a) => {
              if (a.id === payload.id)
                return { ...a, checked: payload.isVisible };
              return a;
            });
          else if (payload.type === 'shared')
            state.user.sharedList = state.user.sharedList.map((a) => {
              if (a.id === payload.id)
                return { ...a, checked: payload.isVisible };
              return a;
            });
          else
            state.user.subList = state.user.subList.map((a) => {
              if (a.id === payload.id)
                return { ...a, checked: payload.isVisible };
              return a;
            });
        }
      })
      .addCase(changeColor.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const { view } = state;
          if (payload.type === 'mine')
            state.user.list = state.user.list.map((a) => {
              if (a.id === payload.id)
                return { ...a, color: `#${payload.color}` };
              return a;
            });
          else if (payload.type === 'shared')
            state.user.sharedList = state.user.sharedList.map((a) => {
              if (a.id === payload.id)
                return { ...a, color: `#${payload.color}` };
              return a;
            });
          else
            state.user.subList = state.user.subList.map((a) => {
              if (a.id === payload.id)
                return { ...a, color: `#${payload.color}` };
              return a;
            });
          if (view && view.id === payload.id)
            state.view = { ...view, color: `#${payload.color}` };
        }
      })
      .addCase(changeUseExposeCreator.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const { view } = state;
          if (payload.type === 'mine')
            state.user.list = state.user.list.map((a) => {
              if (a.id === payload.id)
                return { ...a, useExposeCreator: payload.useExposeCreator };
              return a;
            });
          else if (payload.type === 'shared')
            state.user.sharedList = state.user.sharedList.map((a) => {
              if (a.id === payload.id)
                return { ...a, useExposeCreator: payload.useExposeCreator };
              return a;
            });
          if (view && view.id === payload.id)
            state.view = {
              ...view,
              useExposeCreator: payload.useExposeCreator,
            };
        }
      })
      .addCase(changeSharedCal.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const { view } = state;
          state.user.sharedList = state.user.sharedList.map((a) => {
            if (a.id === payload.id)
              return { ...a, name: payload.name, status: payload.status };
            return a;
          });
          if (view)
            state.view = {
              ...view,
              name: payload.name,
              status: payload.status,
            };
        }
      });
  },
});

export default calendarSlice.reducer;

export const calendarsActions = {
  calendarViewClear: calendarSlice.actions.viewClear,
  subCalendarViewClear: calendarSlice.actions.subViewClear,
  subListChecked: calendarSlice.actions.subListChecked,

  companyCalList: findCompanyCalList,
  subCalendarList: findSubCalendarList,
  myCalendarList: findMyCalendarList,
  sharedCalendarList: findSharedCalendarList,
  subUserCalendarList: findSubUserCalendarList,
  calendarView: findCalendarView,
  subCalendarView: findSubCalendarView,

  saveCalendar,
  updateCalendar,
  deleteCalendar,

  saveSubCal,
  updateSubCal,
  deleteSubCal,

  saveMyCalendar,
  simpleSaveMyCalendar,
  updateMyCalendar,
  deleteMyCal,

  changeChecked,
  changeColor,
  changeUseExposeCreator,
  changeSharedCal,
};
