import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../components/button/Button';
import Chip from '../../../components/chip/Chip';
import ChipGroup from '../../../components/chip/ChipGroup';
import EuiSetting from '../../../components/layout/EuiSetting';
import PostView from '../../../components/post/PostView';
import { CustomNumbers } from '../../../groupware-common/types';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../groupware-directory/stores/directory';
import { RootState as R } from '../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../groupware-webapp/stores/common/utils';
import { CalendarFolderView } from '../../stores/calendar/calendars';

function CalendarFolderBoxView(props: {
  isAdmin?: boolean;
  paths: string[];
  view: CalendarFolderView;
  onUpdate(): void;
  onDelete?(): void;
}): JSX.Element {
  const { isAdmin, paths, view, onUpdate, onDelete } = props;

  const directory = useDirectory();
  /** 직위 */
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );
  const mine = useSelector((s: R) => s.calendar.calendars.user.list);
  const shared = useSelector((s: R) => s.calendar.calendars.user.sharedList);

  const isDelete =
    !shared.some((a) => a.id === view.id) &&
    !mine.find((a) => a.id === view.id)?.isInitialAtUserStarted;

  /** 권한 조회 */
  const handleMatchingPermissions = (arg: {
    isRead: boolean;
    isWrite: boolean;
  }): string[] => {
    const matchingPermissions: string[] = [];
    if (arg.isRead) {
      matchingPermissions.push(getLocalizedText('읽기'));
    }
    if (arg.isWrite) {
      matchingPermissions.push(getLocalizedText('쓰기/수정'));
    }
    return matchingPermissions;
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('캘린더 정보')}>
        <Button
          text={getLocalizedText('수정')}
          iconType
          icon="edit"
          onClick={onUpdate}
        />
        {isDelete && (
          <Button
            text={getLocalizedText('삭제')}
            iconType
            icon="trash-full"
            onClick={onDelete}
          />
        )}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Body>
            <PostView.Title>{view.name}</PostView.Title>
            <PostView.Category type="text">
              <PostView.CategoryList>
                {/* 관리자페이지에선 색상 컬럼이 보여야함. */}
                {isAdmin && (
                  <PostView.CategoryItem title={getLocalizedText('색상')}>
                    <Chip
                      style={{
                        height: '24px',
                        padding: '0 12px',
                        borderRadius: '4px',
                      }}
                      color={view.color}
                      label=""
                    />
                  </PostView.CategoryItem>
                )}
                <PostView.CategoryItem title={getLocalizedText('설명')}>
                  <PostView.CategoryValue value={view.description} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('사용여부')}>
                  <PostView.CategoryValue
                    value={
                      view.status
                        ? getLocalizedText('사용함')
                        : getLocalizedText('사용안함')
                    }
                  />
                </PostView.CategoryItem>
                {view.managers.length > 0 && (
                  <PostView.CategoryItem title={getLocalizedText('관리자')}>
                    <ChipGroup>
                      {view.managers
                        .map((a) => {
                          let jobClassSeq = 0;
                          if (a.referenceType === 'EMPLOYEE') {
                            const employeeData = getDirectoryData({
                              ...directory,
                              companyId: a.referenceCompanyId,
                              employeeId: a.referenceId,
                            });
                            // 직위 또는 직책 순서로 정렬 순서 결정.
                            jobClassSeq =
                              (jobClassType === 'jobduty'
                                ? jobDuties.find(
                                    (v) =>
                                      v.companyId === a.referenceCompanyId &&
                                      v.id === employeeData.jobDutyId,
                                  )?.seq
                                : jobPositions.find(
                                    (v) =>
                                      v.companyId === a.referenceCompanyId &&
                                      employeeData.jobPositionId === v.id,
                                  )?.seq) || CustomNumbers.SMALLINT_MAX;
                          }
                          return {
                            ...a,
                            jobClassSeq,
                          };
                        })
                        // 회사 → 부서 → 직원 순으로 정렬 후
                        // 직위 또는 직책 순번이 낮을수록 밑으로.
                        .sort((a, b) => {
                          if (a.referenceType !== b.referenceType) {
                            if (a.referenceType === 'COMPANY') return -1;
                            if (a.referenceType === 'ORGANIZATION') {
                              if (b.referenceType === 'COMPANY') return 1;
                              return -1;
                            }
                            return 1;
                          }
                          if (a.referenceType === 'EMPLOYEE') {
                            if (a.jobClassSeq < b.jobClassSeq) return -1;
                            if (a.jobClassSeq > b.jobClassSeq) return 1;
                          }
                          return 0;
                        })
                        .map(
                          ({
                            referenceCompanyId: companyId,
                            referenceId: id,
                          }) => {
                            const DirectoryData = getDirectoryData({
                              ...directory,
                              companyId,
                              employeeId: id,
                            });
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={DirectoryData.employeeName}
                                etc={getLocalizedText('관리자')}
                                avatar={DirectoryData.avatar}
                              />
                            );
                          },
                        )}
                    </ChipGroup>
                  </PostView.CategoryItem>
                )}
                <PostView.CategoryItem title={getLocalizedText('공유자')}>
                  <ChipGroup>
                    {view.users
                      .map((a) => {
                        let jobClassSeq = 0;
                        if (a.referenceType === 'EMPLOYEE') {
                          const employeeData = getDirectoryData({
                            ...directory,
                            companyId: a.referenceCompanyId,
                            employeeId: a.referenceId,
                          });
                          // 직위 또는 직책 순서로 정렬 순서 결정.
                          jobClassSeq =
                            (jobClassType === 'jobduty'
                              ? jobDuties.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    v.id === employeeData.jobDutyId,
                                )?.seq
                              : jobPositions.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    employeeData.jobPositionId === v.id,
                                )?.seq) || CustomNumbers.SMALLINT_MAX;
                        }
                        return {
                          ...a,
                          jobClassSeq,
                        };
                      })
                      // 회사 → 부서 → 직원 순으로 정렬 후
                      // 직위 또는 직책 순번이 낮을수록 밑으로.
                      .sort((a, b) => {
                        if (a.referenceType !== b.referenceType) {
                          if (a.referenceType === 'COMPANY') return -1;
                          if (a.referenceType === 'ORGANIZATION') {
                            if (b.referenceType === 'COMPANY') return 1;
                            return -1;
                          }
                          return 1;
                        }
                        if (a.referenceType === 'EMPLOYEE') {
                          if (a.jobClassSeq < b.jobClassSeq) return -1;
                          if (a.jobClassSeq > b.jobClassSeq) return 1;
                        }
                        return 0;
                      })
                      .map(
                        ({
                          referenceCompanyId: companyId,
                          referenceId: id,
                          referenceType,
                          options: { isRead, isWrite },
                        }) => {
                          if (referenceType === 'COMPANY') {
                            // 회사일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getCompanyName(companyId)}
                                icon="company"
                                etc={handleMatchingPermissions({
                                  isRead,
                                  isWrite,
                                }).join('/')}
                              />
                            );
                          }
                          if (referenceType === 'ORGANIZATION') {
                            // 부서일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getOrganizationName(companyId, id)}
                                etc={handleMatchingPermissions({
                                  isRead,
                                  isWrite,
                                }).join('/')}
                                icon="sitemap-fill"
                              />
                            );
                          }
                          if (referenceType === 'EMPLOYEE') {
                            // 사원일 경우
                            const DirectoryData = getDirectoryData({
                              ...directory,
                              companyId,
                              employeeId: id,
                            });
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={DirectoryData.employeeName}
                                etc={handleMatchingPermissions({
                                  isRead,
                                  isWrite,
                                }).join('/')}
                                avatar={DirectoryData.avatar}
                              />
                            );
                          }
                          return null;
                        },
                      )}
                  </ChipGroup>
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('예외자')}>
                  <ChipGroup>
                    {view.exceptioners
                      .map((a) => {
                        let jobClassSeq = 0;
                        if (a.referenceType === 'EMPLOYEE') {
                          const employeeData = getDirectoryData({
                            ...directory,
                            companyId: a.referenceCompanyId,
                            employeeId: a.referenceId,
                          });
                          // 직위 또는 직책 순서로 정렬 순서 결정.
                          jobClassSeq =
                            (jobClassType === 'jobduty'
                              ? jobDuties.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    v.id === employeeData.jobDutyId,
                                )?.seq
                              : jobPositions.find(
                                  (v) =>
                                    v.companyId === a.referenceCompanyId &&
                                    employeeData.jobPositionId === v.id,
                                )?.seq) || CustomNumbers.SMALLINT_MAX;
                        }
                        return {
                          ...a,
                          jobClassSeq,
                        };
                      })
                      // 회사 → 부서 → 직원 순으로 정렬 후
                      // 직위 또는 직책 순번이 낮을수록 밑으로.
                      .sort((a, b) => {
                        if (a.referenceType !== b.referenceType) {
                          if (a.referenceType === 'COMPANY') return -1;
                          if (a.referenceType === 'ORGANIZATION') {
                            if (b.referenceType === 'COMPANY') return 1;
                            return -1;
                          }
                          return 1;
                        }
                        if (a.referenceType === 'EMPLOYEE') {
                          if (a.jobClassSeq < b.jobClassSeq) return -1;
                          if (a.jobClassSeq > b.jobClassSeq) return 1;
                        }
                        return 0;
                      })
                      .map(
                        ({
                          referenceCompanyId: companyId,
                          referenceId: id,
                          referenceType,
                        }) => {
                          if (referenceType === 'COMPANY') {
                            // 회사일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getCompanyName(companyId)}
                                icon="company"
                                etc={getLocalizedText('예외자')}
                              />
                            );
                          }
                          if (referenceType === 'ORGANIZATION') {
                            // 부서일 경우
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={getOrganizationName(companyId, id)}
                                icon="sitemap-fill"
                                etc={getLocalizedText('예외자')}
                              />
                            );
                          }
                          if (referenceType === 'EMPLOYEE') {
                            // 사원일 경우
                            const DirectoryData = getDirectoryData({
                              ...directory,
                              companyId,
                              employeeId: id,
                            });
                            return (
                              <Chip
                                key={`${companyId}/${id}`}
                                label={DirectoryData.employeeName}
                                etc={getLocalizedText('예외자')}
                                avatar={DirectoryData.avatar}
                              />
                            );
                          }
                          return null;
                        },
                      )}
                  </ChipGroup>
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default CalendarFolderBoxView;
