import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import ApprovalLineFlat from '../../groupware-approval/pages/common/components/ApprovalLineFlat';
import { ApprovalLineType } from '../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import Menu from '../menu/Menu';
import Chip from '../chip/Chip';

function DataGridSubject(props: {
  className?: string;
  id: string | number;
  field: string;
  value: string;
  opinions?: number;
  comments?: number;
  approvalLine?: ApprovalLineType;
  onClick(arg: {
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    code: string;
    id?: number | string;
    name?: string;
    fileId?: number | string;
  }): void;
  chip?: {
    label: string;
    theme: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  };
}): JSX.Element {
  const { id, field, value, approvalLine, opinions, comments } = props;

  const timerRef = React.useRef<number>();

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const [state, setState] = useState<{
    visible: boolean;
    point: { x: number; y: number; width: number; height: number };
  }>({
    visible: false,
    point: { x: 0, y: 0, width: 0, height: 0 },
  });

  /** 마우스 커서 진입 열기 */
  const handleOpenApprovalLine = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    if (isDesktop) {
      const rect = event.currentTarget.getBoundingClientRect();

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = window.setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          point: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          visible: true,
        }));
      }, 500);
    }
  };

  /** 마우스 커서 이탈 닫기 */
  const handleCloseApprovalLine = () => {
    if (isDesktop) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setState((prevState) => ({
        ...prevState,
        visible: false,
      }));
    }
  };

  let className = 'eui-hyperlink';
  if (props.className !== undefined) className += ` ${props.className}`;

  return (
    <>
      <div>
        <a
          href="#"
          className={className}
          onMouseEnter={approvalLine ? handleOpenApprovalLine : undefined}
          onMouseLeave={approvalLine ? handleCloseApprovalLine : undefined}
          onClick={(event) => {
            event.preventDefault();
            props.onClick({
              code: 'subject',
              event,
              id,
              name: field,
            });
          }}
        >
          {value}
        </a>
        {opinions !== undefined && opinions !== 0 && (
          <span
            className="opinions"
            onClick={(event) => {
              event.preventDefault();
              props.onClick({
                code: 'opinions',
                event,
                id,
              });
            }}
          >
            <span className="title">{props.opinions}</span>
          </span>
        )}
        {comments !== undefined && comments !== 0 && (
          <span
            className="comments"
            onClick={(event) => {
              event.preventDefault();
              props.onClick({
                code: 'comments',
                event,
                id,
              });
            }}
          >
            <span className="title">{props.comments}</span>
          </span>
        )}
        {props.chip && (
          <span style={{ marginLeft: '3px' }}>
            <Chip label={props.chip.label} size="xs" theme={props.chip.theme} />
          </span>
        )}
        {approvalLine && state.visible && (
          <Menu
            point={state.point}
            onClose={handleCloseApprovalLine}
            className="ui-approval-line-tooltip"
          >
            <ApprovalLineFlat approvalLine={approvalLine} />
          </Menu>
        )}
      </div>
    </>
  );
}

export default DataGridSubject;
