import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CacheEntity,
  CacheMeta,
  CustomNumbers,
  LocateArg,
} from '../../../groupware-common/types';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
  thunkCondition,
} from '../../../groupware-webapp/stores/utils';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { ApiError } from '../../../groupware-common/types/error';
import attendanceWorkApi, {
  attendanceWorkFolderApi,
} from '../../apis/attendance/v1/work';
import { b62 } from '../../../groupware-common/utils';

/** 업무 */
const name = 'attendance/work';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

type FolderItem = {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

type FolderView = {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

type WorkItem = {
  id: number; // 업무 아이디',
  folderId: number; // 폴더 아이디',
  seq: number;
  status: number; // 상태 - 1: 사용, 2: 중지',
  name: string;
  updateAt: string;
};

type WorkView = {
  id: number; // 양식 아이디',
  folderId: number; // 폴더 아이디',
  seq: number; // 순서',
  status: number; // 상태 - 1: 사용, 2: 중지',
  name: string; //  varchar(128) NOT NULL COMMENT '이름',
  formId: number;
  formName: string;
  attendanceCode: number; // 근태코드
  // attendanceCodeName: string; // 근태코드명
  receiptFormId: number;
  receiptFormName?: string;
  documentNo: string; //  varchar(128) NOT NULL COMMENT '문서 번호',
  retentionPeriod: number; // 보존기간',
  approvalLine: ApprovalLineType; // json 결재선',
  referencePermission?: SharePermissionType; // json 참조자',
  viewPermission?: SharePermissionType; // json 조회자',
  useAttachFile: number; // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
  useAttachDocument: number; // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
  useOpinion: boolean; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
  useComment: boolean; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',
  description: string; // 설명
  updateAt: string; // 수정 날짜
};

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  folder: {
    list: CacheEntity<{
      items: FolderItem[];
      // totalCount: number;
      // search: string;
    }>;
    view: CacheEntity<FolderView | null | undefined>;
  };
  list: CacheEntity<{
    items: WorkItem[];
    // totalCount: number;
    // search: string;
  }>;
  view: CacheEntity<WorkView | null | undefined>;
  errors: ApiError[];
}

const initialState: State = {
  requests: [],
  folder: {
    list: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: { items: [] },
    },
    view: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: undefined,
    },
  },
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [] },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    // eslint-disable-next-line prettier/prettier
    data: { id: 10001, folderId: 1, seq: 1, status: 1, name: "DB 백업 정상 유무", attendanceCode: 0, formId: 10235, formName: '양식 이름', receiptFormId: 0, documentNo: '제-년도-', retentionPeriod: 0, useAttachFile: 1, useAttachDocument: 1, useOpinion: true, useComment: true, description: '', updateAt: '2020-12-28T07:41:43.513Z',
      // eslint-disable-next-line prettier/prettier
    approvalLine: { version: '0.1', groups: [
          // eslint-disable-next-line prettier/prettier
      { id: '1', type: 'draft', approval: false, items: [] },
          // eslint-disable-next-line prettier/prettier
      { id: '2', type: 'approval', required: true, modify: false, items: [
              // eslint-disable-next-line prettier/prettier
              { id: '1', companyId: 5001, companyName: '', organizationId: 5001,  organizationName: '', employeeId: 20002, employeeName: '이성계', jobClassType: 'jobposition', jobPositionId: 0, jobPositionName: '', jobDutyId: 0, jobDutyName: '', arbitraryDecision: false  },
              // eslint-disable-next-line prettier/prettier
          ], }, ], },
      referencePermission: { version: '0.1', groups: [] },
      viewPermission: { version: '0.1', groups: [] },
    },
  },
  errors: [],
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (arg: LocateArg, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;
      const response = await attendanceWorkApi.fetchList();
      const data = { items: response };
      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findList.typePrefix, arg, getState);
    },
  },
);

const findFolderList = createAsyncThunk(
  `${name}/findFolderList`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;

      const pageNo = 1;
      const rowsPerPage = CustomNumbers.SMALLINT_MAX;
      const response = await attendanceWorkFolderApi.fetchList(
        pageNo,
        rowsPerPage,
      );
      const data = { items: response };

      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { affiliatedCompanyId?: number; id: number | 'first' } & LocateArg,
    { rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    let data: WorkView | null | undefined;
    let _response_id_: number | undefined;
    try {
      let id: number | null = null;
      if (arg.id === 'first') {
        id = 1;
      } else id = arg.id;

      if (id !== null && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;
      const response = await attendanceWorkApi.fetchView({
        affiliatedCompanyId: arg.affiliatedCompanyId,
        id,
      });
      if (response) {
        data = {
          id: response.id, // 업무 아이디',
          folderId: response.folderId, // 폴더 아이디',
          seq: response.seq, // 순서',
          status: response.status, // 상태 - 1: 사용, 2: 중지',
          name: response.name, //  varchar(128) NOT NULL COMMENT '이름',
          formId: response.formId,
          formName: response.formName,
          attendanceCode: response.attendanceCode, // 근태코드
          // attendanceCodeName: response.attendanceCodeName, // 근태코드명
          receiptFormId: response.receiptFormId,
          receiptFormName: response.receiptFormName,
          documentNo: response.documentNo, //  varchar(128) NOT NULL COMMENT '문서 번호',
          retentionPeriod: response.retentionPeriod, // 보존기간',
          approvalLine: JSON.parse(response.approvalLine), // json 결재선',
          referencePermission:
            response.referrer !== '{}'
              ? JSON.parse(response.referrer)
              : undefined, // json 참조자',
          viewPermission:
            response.viewer !== '{}' ? JSON.parse(response.viewer) : undefined, // json 조회자',
          useAttachFile: response.useAttachFile, // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
          useAttachDocument: response.useAttachDocument ? 1 : 0, // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
          useOpinion: response.useOpinion, // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
          useComment: response.useComment, // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',
          description: response.description, // 설명
          updateAt: response.updateAt, // 수정 날짜
        };
      }
      return { meta, data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findView.typePrefix, arg, getState);
    },
  },
);

const save = createAsyncThunk(
  `${name}/save`,
  async (
    arg: {
      id: number | undefined;
      updateAt?: string;
      folderPaths: string[];
      folderId: number;
      name: string;
      attendanceCode: number;
      formId: number;
      receiptFormId: number;
      formName: string;
      documentNo: string;
      status: number;
      retentionPeriod: number;
      approvalLine: ApprovalLineType;
      referencePermission: SharePermissionType | undefined;
      viewPermission: SharePermissionType | undefined;
      useAttachFile: number;
      description: string;
    } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const {
        id,
        updateAt,
        folderId,
        // name,
        formId,
        receiptFormId,
        documentNo,
        status,
        retentionPeriod,
        // approvalLine,
        // referencePermission,
        // viewPermission,
        useAttachFile,
        description,

        relayLocation,
      } = arg;
      const approvalLine = JSON.stringify(arg.approvalLine);
      const referencePermission =
        arg.referencePermission !== undefined
          ? JSON.stringify(arg.referencePermission)
          : '{}';
      const viewPermission =
        arg.viewPermission !== undefined
          ? JSON.stringify(arg.viewPermission)
          : '{}';

      const response = await attendanceWorkApi.save({
        id,
        updateAt,
        folderId,
        name: arg.name,
        attendanceCode: arg.attendanceCode,
        formId,
        receiptFormId,
        documentNo,
        status,
        retentionPeriod,
        approvalLine,
        referrer: referencePermission, // TODO 백엔드 컬럼명 변경 예정.
        viewer: viewPermission, // TODO 백엔드 컬럼명 변경 예정.
        useAttachFile,
        useAttachDocument: 1,
        useOpinion: true,
        useComment: true,
        description,
      });
      if (relayLocation !== undefined) {
        if (id === undefined) {
          if (relayLocation.pathname.indexOf('{response_id}') !== -1) {
            const route = {
              pathname: relayLocation.pathname.replace(
                '{response_id}',
                b62(response.id),
              ),
            };
            dispatch(findFolderList());
            dispatch(findList({}));
            dispatch(findView({ id: response.id, route }));
          }
        } else {
          dispatch(findFolderList());
          dispatch(findList({}));
          dispatch(findView({ id, route: relayLocation }));
        }
      }
      return undefined;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 저장 */
const saveFolder = createAsyncThunk(
  `${name}/saveFolder`,
  async (
    arg: {
      id: number | undefined;
      updateAt?: string;
      parentId: number;
      name: string;
    } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await attendanceWorkFolderApi.save(arg);

      if (
        arg.id === undefined &&
        arg.relayLocation &&
        arg.relayLocation?.pathname.indexOf('{response_id}') !== -1
      ) {
        const route = {
          pathname: arg.relayLocation.pathname.replace(
            '{response_id}',
            b62(response.id),
          ),
        };
        dispatch(findFolderList({ route }));
      } else if (arg.relayLocation === undefined) dispatch(findFolderList());
      else dispatch(findFolderList({ route: arg.relayLocation }));
      return undefined;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 분류 폴더 삭제. */
const removeFolder = createAsyncThunk(
  `${name}/removeFolder`,
  async (
    arg: { id: number; updateAt: string } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id, updateAt, relayLocation } = arg;
      await attendanceWorkFolderApi.delete({ id, updateAt });

      if (relayLocation !== undefined)
        dispatch(findFolderList({ route: relayLocation }));
      return undefined;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 업무 삭제. */
const remove = createAsyncThunk(
  `${name}/remove`,
  async (
    arg: { id: number; updateAt: string } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id, updateAt, relayLocation } = arg;
      await attendanceWorkApi.delete({ id, updateAt });

      if (relayLocation !== undefined) {
        dispatch(findFolderList());
        dispatch(findList({ route: relayLocation }));
      }
      return undefined;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 분류 폴더 순서 변경 */
const sortFolder = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;
      await attendanceWorkFolderApi.sort(arg.data);
      const pageNo = 1;
      const rowsPerPage = CustomNumbers.SMALLINT_MAX;
      const response = await attendanceWorkFolderApi.fetchList(
        pageNo,
        rowsPerPage,
      );
      const data = { items: response };
      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 업무 순서 변경 */
const sortWork = createAsyncThunk(
  `${name}/sortWork`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;
      await attendanceWorkApi.sort(arg.data);
      const response = await attendanceWorkApi.fetchList();
      const data = { items: response };
      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const attendanceWorkSlice = createSlice({
  name,
  initialState,
  reducers: {
    clear(state) {
      state.view.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.folder.list.meta = payload.meta;
        if (payload?.data !== undefined) state.folder.list.data = payload.data;
      })
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.view.meta = payload.meta;
        if (payload?.data !== undefined) state.view.data = payload.data;
      })
      .addCase(sortFolder.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.folder.list.meta = payload.meta;
        if (payload?.data !== undefined) state.folder.list.data = payload.data;
      })
      .addCase(sortWork.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default attendanceWorkSlice.reducer;

export const attendanceWorkFolderActions = {
  list: findFolderList,
  save: saveFolder,
  delete: removeFolder,
  sort: sortFolder,
};

export const attendanceWorkActions = {
  list: findList,
  view: findView,
  clear: attendanceWorkSlice.actions.clear,
  save,
  delete: remove,
  sort: sortWork,
};
