import React, { useState, useEffect, useRef } from 'react';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Loading from '../../../../../components/loading/Loading';
import { sleep } from '../../../../../groupware-common/utils';
import { createLocalizedTextFactory } from '../../../../../groupware-common/utils/i18n';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { documentApi } from '../../../../apis/approval/v1/document';

function ApprovalAttachedFileDialog(props: {
  onCloseDialog(): void;
  file: {
    documentId: number;
    id: number;
    name: string;
    size: number;
    url: string;
    isFileprotection?: boolean; // 암호화된 파일 여부 (공유 파일일 경우 상태값 없음)
  };
}): JSX.Element | null {
  const dispatch = useAppDispatch();
  const getLocalizedText = createLocalizedTextFactory('approval');

  const scrollbar = useRef<HTMLDivElement>(null);
  const iframe = useRef<HTMLIFrameElement>(null);

  const [state, setState] = useState<{
    url: string;
    loading: boolean;
    content: string;
    accessKey: string;
  }>({
    url: '',
    content: '',
    loading: true,
    accessKey: '',
  });

  useEffect(() => {
    async function run() {
      try {
        // 암호화된 파일일 경우(true) 복호화
        if (file.isFileprotection) {
          let result = await documentApi.fetchAttachDecrypt(
            file.documentId,
            file.id,
          );
          // result = true 복호화 성공, false = 복호화 중, error = 실패
          if (result === false) {
            for (let i = 1; i < 4; i += 1) {
              // eslint-disable-next-line no-await-in-loop
              await sleep(1000);
              // eslint-disable-next-line no-await-in-loop
              result = await documentApi.fetchAttachDecrypt(
                file.documentId,
                file.id,
              );
              if (result === true) break;
            }
          }
          if (!result) {
            dispatch(
              sessionActions.error(
                getLocalizedText(
                  '복호화 진행 중입니다 잠시 후 다시 시도 해주세요.',
                ),
              ),
            );
            setState((prevState) => ({
              ...prevState,
              loading: false,
            }));
            return;
          }
        }

        // 액세스 키 추가.
        const accessKey = await documentApi.fetchAttachAccessKey();
        setState((prevState) => ({
          ...prevState,
          accessKey,
          loading: false,
        }));
      } catch (ex) {
        dispatch(
          sessionActions.error(getLocalizedText('복호화에 실패 하였습니다.')),
        );
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    }
    run();
  }, []);

  const handleClose = () => {
    props.onCloseDialog();
  };

  const { file } = props;

  if (state.loading || state.accessKey === '') {
    return (
      <Dialog onClose={handleClose} size="lg">
        <div style={{ height: window.innerHeight }}>
          <DialogHeader>
            <DialogTitle>{getLocalizedText('미리보기')}</DialogTitle>
          </DialogHeader>
          <DialogBody>{state.loading && <Loading />}</DialogBody>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={handleClose} size="lg">
      <DialogHeader>
        <DialogTitle>{getLocalizedText('미리보기')}</DialogTitle>
      </DialogHeader>
      <DialogBody
        scrollbar={scrollbar}
        height={window.innerHeight}
        className="viewer"
      >
        <iframe
          id="preview-iframe"
          title="content"
          ref={iframe}
          style={{ width: '100%', height: '99%' }}
          src={`${file.url}&accesskey=${state.accessKey}`}
          frameBorder={0}
          allowFullScreen
        />
      </DialogBody>
    </Dialog>
  );
}

export default ApprovalAttachedFileDialog;
