import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import DashBoardBasicRoute from '../pages/adminconsole/dashboard/basic/DashBoardBasicRoute';
import DashBoardCompanyPortalRoute from '../pages/adminconsole/dashboard/companyPortal/DashBoardCompanyPortalRoute';

function AdminconsoleLocateRoute(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 회사포탈 관리
  if (menu === 'company')
    return <DashBoardCompanyPortalRoute pathname={pathname} search={search} />;
  return <DashBoardBasicRoute pathname={pathname} search={search} />;
}

export default AdminconsoleLocateRoute;
