import React from 'react';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import ApprovalApproverMacroContainer from '../pages/adminconsole/approval/approvermacro/ApprovalApproverMacroContainer';
import ApprovalApproverProxyContainer from '../pages/adminconsole/approval/approverproxy/ApprovalApproverProxyContainer';
import ApprovalArbitraryDecisionContainer from '../pages/adminconsole/approval/arbitrarydecision/ApprovalArbitraryDecisionContainer';
import ApprovalDocumentContainer from '../pages/adminconsole/approval/document/ApprovalDocumentContainer';
import ApprovalFormContainer from '../pages/adminconsole/approval/form/ApprovalFormContainer';
import ApprovalSettingContainer from '../pages/adminconsole/approval/setting/ApprovalSettingContainer';
import ApprovalTrashContainer from '../pages/adminconsole/approval/trash/ApprovalTrashContainer';
import ApprovalAddPermissionContainer from '../pages/adminconsole/approval/viewpermission/ApprovalAddPermissionContainer';
import ApprovalWorkContainer from '../pages/adminconsole/approval/work/ApprovalWorkContainer';
import ApprovalComposeContainer from '../pages/root/approval/compose/ApprovalComposeContainer';
import ApprovalArchivesTransferContainer from '../pages/adminconsole/approval/archives/ApprovalArchivesTransferContainer';

function AdminConsoleLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element | null {
  // const languages = ['ko-KR' as const];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);
  const { contentMode } = getQueryParams(search);

  if (menu === undefined) {
    return <ApprovalSettingContainer pathname={pathname} hash={hash} />;
  }
  if (menu === 'document') {
    if (contentMode === 'update')
      return <ApprovalComposeContainer pathname={pathname} search={search} />;
    return <ApprovalDocumentContainer pathname={pathname} search={search} />;
  }
  if (menu === 'arbitrarydecision') {
    return <ApprovalArbitraryDecisionContainer />;
  }
  if (menu === 'approvermacro') {
    return (
      <ApprovalApproverMacroContainer pathname={pathname} search={search} />
    );
  }
  if (menu === 'approverproxy') {
    return <ApprovalApproverProxyContainer />;
  }
  if (menu === 'viewpermission' || menu === 'referencepermission') {
    return (
      <ApprovalAddPermissionContainer pathname={pathname} search={search} />
    );
  }
  if (menu === 'work') {
    return <ApprovalWorkContainer pathname={pathname} search={search} />;
  }
  if (menu === 'form') {
    return <ApprovalFormContainer pathname={pathname} search={search} />;
  }
  if (menu === 'trash') {
    return <ApprovalTrashContainer pathname={pathname} search={search} />;
  }
  if (menu === 'archives') {
    return <ApprovalArchivesTransferContainer />;
  }

  return null;
}

export default AdminConsoleLocateContainer;
