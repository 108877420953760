import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import PostView from '../../../../../components/post/PostView';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import {
  AttachFile,
  DocumentViewItem,
} from '../../../../stores/document/document';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import { documentsFileApi } from '../../../../apis/document/v1/common';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import documentApi from '../../../../apis/document/v1/document';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Loading from '../../../../../components/loading/Loading';
import UserInfo from '../../../../../components/user/UserInfo';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type Props = {
  id: number;
  versionSeq: number;
  onClose(): void;
};

const DocumentContentVersionViewDialog = (props: Props): JSX.Element | null => {
  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();
  const scrollbar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<{
    loading: boolean;
    versionView:
      | Pick<
          DocumentViewItem,
          | 'currentVersionSeq'
          | 'updaterId'
          | 'subject'
          | 'updateAt'
          | 'contents'
          | 'attachedFiles'
          | 'id'
        >
      | undefined;
  }>({
    loading: true,
    versionView: undefined,
  });

  useEffect(() => {
    async function run() {
      try {
        const { id, versionSeq } = props;

        if (id === undefined)
          throw new Error(getLocalizedText('문서를 찾을 수 없습니다.'));

        const response = await documentApi.versionView({
          id,
          versionSeq,
        });

        const attachedFiles: AttachFile[] = [];
        if (response.attachedFiles) {
          response.attachedFiles.forEach((a) => attachedFiles.push(a));
          attachedFiles.sort(
            (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
          );
        }

        setState((prevState) => ({
          ...prevState,
          loading: false,
          versionView: {
            ...response,
            ...attachedFiles,
          },
        }));
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        dispatch(sessionActions.error((e as Error).message));
      }
    }
    run();
  }, []);

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: { id: number }) => {
    const { id } = arg;
    documentsFileApi
      .downloadAll(id, versionView.currentVersionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    id: number;
    fileId: number;
    name: string;
  }) => {
    const { id, fileId, name } = arg;
    documentsFileApi
      .download(id, fileId, props.versionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error(
            getLocalizedText('파일이 이동되었거나 이름이 변경되었습니다.'),
          );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const handleClose = () => {
    dispatch(sessionActions.setDialog());
  };

  if (!state.versionView || state.loading) {
    return (
      <Dialog onClose={handleClose}>
        <div style={{ height: window.innerHeight }}>
          <DialogHeader>
            <DialogTitle>
              <></>
            </DialogTitle>
          </DialogHeader>
          <DialogBody>{state.loading && <Loading />}</DialogBody>
        </div>
      </Dialog>
    );
  }

  const { versionView } = state;
  if (versionView === undefined) return null;
  const author = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: versionView.updaterId,
  });

  return (
    <>
      <Dialog size="lg" onClose={props.onClose}>
        <DialogHeader>
          <DialogTitle>
            <></>
          </DialogTitle>
        </DialogHeader>
        <DialogBody scrollbar={scrollbar} height={window.innerHeight}>
          <PostView type="full">
            <PostView.Head>
              <PostView.Title>{versionView.subject}</PostView.Title>
              <div>
                <div>
                  <UserInfo
                    className="view-author"
                    key={author.employeeId}
                    name={author.employeeName}
                    avatar={author.avatar}
                    from={author.organizationName}
                    date={versionView.updateAt}
                  />
                </div>
              </div>
            </PostView.Head>
            <PostView.Body>
              <PostView.Content data={versionView.contents} />
              {versionView.attachedFiles &&
                versionView.attachedFiles.length > 0 && (
                  <AttachmentsList
                    count={versionView.attachedFiles.length}
                    className="view-attachments"
                    saveAll={() =>
                      handleAttachedFileDownloadAll({
                        id: versionView.id,
                      })
                    }
                  >
                    {versionView.attachedFiles.map(({ fileId, name, size }) => (
                      <AttachmentsItem
                        key={`attach_${fileId}`}
                        name={name}
                        size={size}
                        onClick={() =>
                          handleAttachedFileDownload({
                            id: versionView.id,
                            fileId,
                            name,
                          })
                        }
                      />
                    ))}
                  </AttachmentsList>
                )}
            </PostView.Body>
          </PostView>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default DocumentContentVersionViewDialog;
