import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import Avatar from '../../../../../components/avatar/Avatar';
import Button from '../../../../../components/button/Button';
import Comment from '../../../../../components/comment/Comment';
import PostView from '../../../../../components/post/PostView';
import { documentApi } from '../../../../../groupware-approval/apis/approval/v1/document';
import ApprovalLineFlat from '../../../../../groupware-approval/pages/common/components/ApprovalLineFlat';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { getCommentItems } from '../../../../../groupware-approval/pages/root/approval/ApprovalContentBodyViewContainer';
import { documentMacroReplace } from '../../../../../groupware-approval/pages/root/approval/compose/ApprovalComposeContainer';
import { DocumentDialog } from '../../../../../groupware-approval/pages/root/approval/content/ApprovalAttachedDocumentDialog';
import { getAvatarPath } from '../../../../../groupware-common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { AuthorityViewItem } from '../../../../stores/sangsanginsecurities/authority';
import SecuritiesDocumentDialog from '../compose/SecuritiesDocumentDialog';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();
  // console.log(`getLastApprovaledAt(approvalLine):items`, items);

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());
  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return `${day}일 ${remainder}시간`;
  }
  return `${time}시간`;
}

function SecuritiesAuthorityBodyView(props: {
  pathname: string;
  type: 'full' | 'split';
  view: AuthorityViewItem | undefined;
  onDraftDocumentPopup?(documentId: number): void;
  onAttachedDocumentPopup(arg: DocumentDialog): void;
}): JSX.Element | null {
  const { pathname, type, view } = props;

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const display = useSelector((state: RootState) => state.session.display);

  const [validation, setValidation] = useState('');
  const [billSeq, setBillSeq] = useState(undefined as string | undefined);
  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [pathname]);

  if (view === undefined) return null;

  const opinions = getCommentItems(view.opinions, directory);
  const comments = getCommentItems(view.comments, directory);

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: {
    companyId: number;
    documentId: number;
  }) => {
    // console.log(`handleAttachedFileDownloadAll(arg)`, arg);
    const { companyId, documentId } = arg;
    documentApi
      .downloadAttachfileAll(companyId, documentId)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    companyId: number;
    documentId: number;
    id: number;
    name: string;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { companyId, documentId, id, name } = arg;
    documentApi
      .downloadAttachfile(companyId, documentId, id)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const {
    affiliatedCompanyId,
    subject,
    content,
    draftAt,
    completeAt,
    approvalLine,
    attachedFiles,
    attachedDocuments,
    parentAffiliatedCompanyId,
    parentCompanyId,
    parentId,
    parentApprovalLine,
    parentDrafteAt,
    parentCompleteAt,
  } = view;

  const drafter = getApprovalLineDrafter(view.approvalLine);
  if (drafter === undefined) return <div>오류</div>;

  const { organizationName: draftOrganizationName, employeeName: drafterName } =
    drafter;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : undefined;

  /** 부모 문서 정보(내부결재인 경우에만 있음) */
  let parentDrafterOrganizationName: string | undefined;
  let parentDrafterName: string | undefined;
  let parentDrafterAvatar: string | undefined;
  let parentLastApprovalAt: string | undefined;
  let parentWaiteAt: string | undefined;
  if (view.parentApprovalLine) {
    const parentDrafter = getApprovalLineDrafter(view.parentApprovalLine);
    if (parentDrafter === undefined) return <div>오류</div>;

    parentDrafterOrganizationName = parentDrafter.organizationName;
    parentDrafterName = parentDrafter.employeeName;
    parentDrafterAvatar = parentDrafter.employeeId
      ? getAvatarPath(parentDrafter)
      : undefined;
    parentLastApprovalAt =
      parentCompleteAt === undefined
        ? getLastApprovalAt(view.parentApprovalLine)
        : undefined;
    parentWaiteAt =
      parentLastApprovalAt === undefined
        ? undefined
        : getWaitedAt(parentLastApprovalAt);
  }

  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  /** 마지막 결재 날짜 */
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(view.approvalLine) : undefined;

  /** 대기 시간 */
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  /** 첨부 문서 조회 */
  const handleAttachedDocumentPopup = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument';
    documentId: number;
    affiliatedCompanyId?: number;
  }) => {
    props.onAttachedDocumentPopup(arg);
  };

  /** 부모 문서 조회. */
  const handleParentDocumentPopup = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument';
    documentId: number;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    props.onAttachedDocumentPopup(arg);
  };

  /** 세금계산서 조회 */
  const handleBillClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setBillSeq(value);
  };

  /** 세금계산서 대화상자 닫기 */
  const handleCloseBillSeqPopup = () => {
    setBillSeq(undefined);
  };

  /** 품의서 조회 */
  const handleDraftDocumentClick = (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    const dialogDraftDocument = parseInt(event.currentTarget.value, 10);
    if (props.onDraftDocumentPopup)
      props.onDraftDocumentPopup(dialogDraftDocument);
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setValidation('');
  };

  const renderDialog = () => {
    if (billSeq)
      return (
        <SecuritiesDocumentDialog
          billseq={billSeq}
          onClose={handleCloseBillSeqPopup}
        />
      );

    return null;
  };

  const element = (
    <PostView type={type}>
      <PostView.Head>
        <PostView.Title
          chip={
            view && view.linkWait
              ? { label: '연동대기', theme: 'primary' }
              : undefined
          }
        >
          {subject}
        </PostView.Title>
        {parentApprovalLine && parentCompanyId && parentId && (
          <div
            className="approval-view-info"
            style={{
              paddingBottom: '16px',
              borderBottom: '1px solid var(--line-color)',
            }}
          >
            <Avatar
              className="avatar"
              name={parentDrafterName ?? parentDrafterOrganizationName ?? ''}
              image={parentDrafterAvatar}
              icon={parentDrafterAvatar ? undefined : 'sitemap-fill'}
            />
            <ApprovalLineFlat approvalLine={parentApprovalLine} />
            <Button
              parentDocument
              className="action"
              text="원문서 보기"
              iconType
              icon="document-search"
              onClick={() =>
                handleParentDocumentPopup({
                  dialogType: 'parentdocument' as const,
                  documentId: parentId,
                  affiliatedCompanyId: parentAffiliatedCompanyId ?? undefined,
                  receiptAffiliatedCompanyId: view.affiliatedCompanyId,
                  receiptDocumentId: view.id,
                })
              }
              color="secondary"
            />
            <PostView.Info row>
              <PostView.InfoItem
                title="기안자"
                value={parentDrafterName ?? ''}
              />
              <PostView.InfoItem
                title="기안부서"
                value={parentDrafterOrganizationName ?? ''}
              />
              <PostView.InfoItem title="기안일" value={parentDrafteAt ?? ''} />
              {parentCompleteAt && (
                <PostView.InfoItem title="완료일" value={parentCompleteAt} />
              )}
              {parentWaiteAt && (
                <PostView.InfoItem title="대기일" value={parentWaiteAt} />
              )}
            </PostView.Info>
          </div>
        )}
        <div className="approval-view-info">
          <Avatar
            className="avatar"
            name={drafterName ?? draftOrganizationName}
            image={drafterAvatar}
            icon={drafterAvatar ? undefined : 'sitemap-fill'}
          />
          <ApprovalLineFlat approvalLine={approvalLine} />
          <PostView.Info row>
            <PostView.InfoItem
              title="기안자"
              value={drafterName ?? draftOrganizationName}
            />
            <PostView.InfoItem title="기안부서" value={draftOrganizationName} />
            <PostView.InfoItem title="기안일" value={draftAt ?? ''} />
            {completeAt && (
              <PostView.InfoItem title="완료일" value={completeAt} />
            )}
            {waitedAt && <PostView.InfoItem title="대기일" value={waitedAt} />}
          </PostView.Info>
        </div>
      </PostView.Head>
      <PostView.Body>
        <PostView.Content data={content} />
        <input
          style={{ display: 'none' }}
          type="button"
          id="custom-bill-click"
          onClick={handleBillClick}
        />
        <input
          style={{ display: 'none' }}
          type="button"
          id="custom-draft-document-click"
          onClick={handleDraftDocumentClick}
        />
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={
              display === 'pc'
                ? () =>
                    handleAttachedFileDownloadAll({
                      companyId: attachedFiles[0].companyId,
                      documentId: attachedFiles[0].documentId,
                    })
                : undefined
            }
          >
            {attachedFiles.map(
              ({ companyId, documentId, id, name, size, url }) =>
                url ? (
                  <AttachmentsItem
                    key={id}
                    name={name}
                    size={size}
                    onClick={
                      display === 'pc'
                        ? () =>
                            handleAttachedFileDownload({
                              companyId,
                              documentId,
                              id,
                              name,
                            })
                        : undefined
                    }
                  />
                ) : (
                  <AttachmentsItem
                    key={id}
                    name={name}
                    size={size}
                    onClick={() =>
                      handleAttachedFileDownload({
                        companyId,
                        documentId,
                        id,
                        name,
                      })
                    }
                  />
                ),
            )}
          </AttachmentsList>
        )}
        {attachedDocuments && attachedDocuments.length > 0 && (
          <AttachDocumentList count={attachedDocuments.length}>
            {attachedDocuments.map((x) => (
              <AttachDocumentItem
                key={x.id}
                no={documentMacroReplace(x.no)}
                subject={x.subject}
                onClick={() =>
                  handleAttachedDocumentPopup({
                    dialogType: 'attacheddocument',
                    affiliatedCompanyId,
                    documentId: x.id,
                  })
                }
              />
            ))}
          </AttachDocumentList>
        )}
        {opinions && (
          <Comment
            opinion
            count={opinions.length || 0}
            user={user}
            comments={opinions}
          />
        )}
        {comments && (
          <Comment
            count={comments.length || 0}
            user={user}
            comments={comments}
          />
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {element}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </div>
  );
}

export default SecuritiesAuthorityBodyView;
