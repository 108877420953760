import React from 'react';

function DashBoardBody(props: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  const { children } = props;
  const className = props.className
    ? `dashboard-body ${props.className}`
    : 'dashboard-body';

  return <div className={className}>{children}</div>;
}

export default DashBoardBody;
