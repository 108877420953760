import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SplitPane from 'react-split-pane';
import { useHotkeys } from 'react-hotkeys-hook';
import { RootState, useAppDispatch } from '../app/store';
import { LocationProps, Language } from '../../groupware-common/types';
import {
  parseUriModule,
  getPathParams,
  getQueryParams,
} from '../../groupware-common/utils';
import GnbContainer from '../pages/common/containers/GnbContainer';
import EuiNav from '../../components/layout/EuiNav';
import EuiContainer from '../../components/layout/EuiContainer';

import EuiSnb from '../../components/layout/EuiSnb';
import { sessionActions } from '../stores/session';
import { CreateRandomString } from '../../groupware-common/utils/ui';

import DirectoryLocateRoute from '../../groupware-directory/locates/RootLocateRoute';
import DirectoryLocateContainer from '../../groupware-directory/locates/RootLocateContainer';
import ApprovalLocateRoute from '../../groupware-approval/locates/RootLocateRoute';
import ApprovalLocateDrawer from '../../groupware-approval/locates/RootLocateDrawer';
import ApprovalLocateContainer from '../../groupware-approval/locates/RootLocateContainer';
import BoardLocateRoute from '../../groupware-board/locates/BoardLocateRoute';
import BoardLocateDrawer from '../../groupware-board/locates/BoardLocateDrawer';
import BoardLocateContainer from '../../groupware-board/locates/BoardLocateContainer';
import AttendanceLocateRoute from '../../groupware-attendance/locates/AttendanceLocateRoute';
import AttendanceLocateContainer from '../../groupware-attendance/locates/AttendanceLocateContainer';
import AttendanceLocateDrawer from '../../groupware-attendance/locates/AttendanceLocateDrawer';
import ResourceLocateRoute from '../../groupware-resource/locates/ResourceLocateRoute';
import ResourceLocateDrawer from '../../groupware-resource/locates/ResourceLocateDrawer';
import ResourceLocateContainer from '../../groupware-resource/locates/ResourceLocateContainer';
import DocumentLocateRoute from '../../groupware-document/locates/DocumentLocateRoute';
import DocumentLocateDrawer from '../../groupware-document/locates/DocumentLocateDrawer';
import DocumentLocateContainer from '../../groupware-document/locates/DocumentLocateContainer';
import CalendarLocateRoute from '../../groupware-calendar/locates/CalendarLocateRoute';
import CalendarLocateDrawer from '../../groupware-calendar/locates/CalendarLocateDrawer';
import CalendarLocateContainer from '../../groupware-calendar/locates/CalendarLocateContainer';
import ContactLocateRoute from '../../groupware-contact/locates/ContactLocateRoute';
import ContactLocateContainer from '../../groupware-contact/locates/ContactLocateContainer';
import ContactLocateDrawer from '../../groupware-contact/locates/ContactLocateDrawer';
import SettingLocateRoute from '../../groupware-setting/locates/SettingLocateRoute';
import SettingLocateContainer from '../../groupware-setting/locates/SettingLocateContainer';
import SettingLocateDrawer from '../../groupware-setting/locates/SettingLocateDrawer';
import DashBoardLocateContainer from '../../groupware-dashboard/locates/DashBoardLocateContainer';
import DashBoardLocateRoute from '../../groupware-dashboard/locates/DashBoardLocateRoute';
import SecuritiesLocateRoute from '../../groupware-custom-sangsanginsecurities/locates/SecuritiesLocateRoute';
import SecuritiesLocateContainer from '../../groupware-custom-sangsanginsecurities/locates/SecuritiesLocateContainer';
import SecuritiesLocateDrawer from '../../groupware-custom-sangsanginsecurities/locates/SecuritiesLocateDrawer';
import ErrorPage from '../../components/error/ErrorPage';
import {
  getLocalizedText,
  getModuleNamespace,
  resourceReload,
} from '../../groupware-common/utils/i18n';

function RootRouter({ location }: LocationProps): JSX.Element {
  // console.log(`${RootRouter.name}.render({ location })`, location);

  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const resource = useSelector((state: RootState) => state.session.resource);
  const display = useSelector((state: RootState) => state.session.display);
  const mobileNav = useSelector((state: RootState) => state.session.mobileNav);
  const route = useSelector((state: RootState) => state.session.route);
  const modules = useSelector((state: RootState) => state.session.modules);
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  const localeUpdateAt = useSelector(
    (state: RootState) => state.i18n.lastUpdateAt,
  );

  const [lastLocaleUpdateAt, setLastLocaleUpdateAt] = useState(localeUpdateAt);

  /*
  const param: {
    organizationId: number;
  } = {
    organizationId: 10005,
  };
  
  const returns = dispatch(
     approvaluserArchivesActions.fetchArchivesLabel(param),
  );
  */

  useEffect(() => {
    if (mobileNav) {
      const { prevModule } = getPathParams<{ prevModule?: string }>(
        '/:prevModule',
        route.pathname,
      );
      const { nextModule } = getPathParams<{ nextModule?: string }>(
        '/:nextModule',
        location.pathname,
      );
      if (prevModule !== nextModule) dispatch(sessionActions.mobileNav(false));
    }
  }, [mobileNav, location.pathname, route.pathname]);

  useEffect(() => {
    const { prevModule } = getPathParams<{ prevModule?: string }>(
      '/:prevModule',
      route.pathname,
    );
    const { nextModule } = getPathParams<{ nextModule?: string }>(
      '/:nextModule',
      location.pathname,
    );
    if (nextModule === undefined || prevModule === undefined) return;

    if (prevModule !== nextModule) {
      const namespace = getModuleNamespace(nextModule);
      resourceReload(currentLanguage, namespace);
    }
  }, [location.pathname, route.pathname]);

  useEffect(() => {
    if (lastLocaleUpdateAt !== localeUpdateAt) {
      setLastLocaleUpdateAt(localeUpdateAt);
      dispatch(sessionActions.setRoute(route));
    }
  }, [localeUpdateAt]);

  const handleChangeLanguage = (language: Language) => {
    if (currentLanguage !== language)
      i18n.changeLanguage(language).then(() => {
        dispatch(sessionActions.setLanguage({ language }));
      });
  };

  const locateRoute = useMemo(() => {
    const { pathname: p, search, hash: h } = location;
    const { module } = getPathParams('/:module', p);
    const s = search.startsWith('?') ? search.substring(1) : search;
    const key = CreateRandomString();

    if (module === 'directory')
      return (
        <DirectoryLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'approval')
      return (
        <ApprovalLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'board')
      return (
        <BoardLocateRoute pathname={p} search={s} hash={h} locationKey={key} />
      );
    if (module === 'attendance')
      return (
        <AttendanceLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'resource')
      return (
        <ResourceLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'document')
      return (
        <DocumentLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'calendar')
      return (
        <CalendarLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'securities')
      return (
        <SecuritiesLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'contacts')
      return (
        <ContactLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'setting')
      return (
        <SettingLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (module === 'dashboard')
      return (
        <DashBoardLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    return (
      <DashBoardLocateRoute
        pathname={p}
        search={s}
        hash={h}
        locationKey={key}
      />
    );
  }, [location.pathname, location.search, location.hash, location.key]);

  const drawer = useMemo(() => {
    const { pathname: p, search: s, hash: h } = route;
    const { module, folderId } = getPathParams('/:module/:folderId', p);

    if (
      resource === 'teams' &&
      module === 'approval' &&
      (folderId === 'document' || folderId === 'approve')
    )
      return null;
    if (resource === 'teams' && module === 'attendance' && folderId === 'alert')
      return null;
    if (
      (module === 'approval' && folderId === 'approvalinterworking') ||
      module === 'approvalinterworking'
    )
      return null;
    if (module === 'approval')
      return <ApprovalLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'attendance')
      return <AttendanceLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'resource')
      return <ResourceLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'board')
      return <BoardLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'document')
      return <DocumentLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'calendar')
      return <CalendarLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'securities')
      return <SecuritiesLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'contacts')
      return <ContactLocateDrawer pathname={p} search={s} hash={h} />;
    if (module === 'setting')
      return <SettingLocateDrawer pathname={p} search={s} hash={h} />;
    return null;
  }, [route]);

  const container = useMemo(() => {
    const { pathname: p, search: s, hash: h, key: k } = route;
    const { module } = getPathParams('/:module', p);
    const { locationModule } = getPathParams(
      '/:locationModule',
      location.pathname,
    );
    const hasModuleAccess =
      locationModule === undefined ||
      locationModule === 'setting' ||
      locationModule === 'securities' ||
      modules.some((a) => a.id.toLowerCase() === locationModule);

    // 사용하지 않는 모듈로 접근 하는 경우.
    if (!hasModuleAccess) return <ErrorPage />;

    if (module === 'directory')
      return (
        <DirectoryLocateContainer
          pathname={p}
          search={s}
          hash={h}
          locationKey={k}
        />
      );
    if (module === 'approval')
      return (
        <ApprovalLocateContainer
          pathname={p}
          search={s}
          hash={h}
          locationKey={k}
        />
      );
    if (module === 'board')
      return (
        <BoardLocateContainer
          pathname={p}
          search={s}
          hash={h}
          locationKey={k}
        />
      );
    if (module === 'attendance')
      return (
        <AttendanceLocateContainer pathname={p} search={s} locationKey={k} />
      );
    if (module === 'resource')
      return (
        <ResourceLocateContainer
          pathname={p}
          search={s}
          hash={h}
          locationKey={k}
        />
      );
    if (module === 'document')
      return (
        <DocumentLocateContainer pathname={p} search={s} locationKey={k} />
      );
    if (module === 'calendar')
      return (
        <CalendarLocateContainer
          pathname={p}
          search={s}
          hash={h}
          locationKey={k}
        />
      );
    if (module === 'securities')
      return (
        <SecuritiesLocateContainer pathname={p} search={s} locationKey={k} />
      );
    if (module === 'contacts')
      return <ContactLocateContainer pathname={p} search={s} locationKey={k} />;
    if (module === 'setting')
      return <SettingLocateContainer pathname={p} search={s} locationKey={k} />;
    if (module === 'dashboard')
      return (
        <DashBoardLocateContainer pathname={p} search={s} locationKey={k} />
      );
    if (resource === 'teams') return <div />;
    return <DashBoardLocateContainer pathname={p} search={s} locationKey={k} />;
  }, [route]);

  const [state, setState] = useState<{
    toggleLabel: string;
    snbHidden: boolean;
  }>({
    toggleLabel: getLocalizedText('메뉴 접기'),
    snbHidden: false,
  });

  const getContent = () => {
    const { pathname, search } = route;
    const { module, folderId } = getPathParams('/:module/:folderId', pathname);
    const queryParams = getQueryParams(search);

    let gnb = null;
    if (resource !== 'teams' && pathname !== '')
      gnb = (
        <GnbContainer
          module={parseUriModule(module)}
          onChangeLanguage={handleChangeLanguage}
        />
      );
    if (
      (module === 'approval' && folderId === 'approvalinterworking') ||
      module === 'securities'
    )
      gnb = null;

    const isCreate = queryParams.contentMode === 'create';
    let pane1ClassName = `ui-split layout-split${
      state.snbHidden ? ' hidden' : ''
    }`;
    pane1ClassName = `${pane1ClassName}${resource === 'teams' ? ' teams' : ''}`;

    if (drawer !== null) {
      // TODO 개발 중 반응형 주석 처리 배포 또는 화면 개발 시 주석 해제
      // if (false) {
      if (display !== 'pc' && !isCreate) {
        return (
          <>
            {mobileNav && (
              <>
                <div className="ui-nav-cover" onClick={handleCloseMobileNav} />
                <EuiNav>
                  {gnb}
                  {drawer && <EuiSnb>{drawer}</EuiSnb>}
                </EuiNav>
              </>
            )}
            <EuiContainer>{container}</EuiContainer>
          </>
        );
      }

      if (isCreate) {
        const style: React.CSSProperties = {
          display: 'none',
        };

        return (
          <>
            {mobileNav ? (
              <>
                <div className="ui-nav-cover" onClick={handleCloseMobileNav} />
                <EuiNav>
                  {gnb}
                  {drawer && <EuiSnb>{drawer}</EuiSnb>}
                </EuiNav>
              </>
            ) : (
              <SplitPane
                className={pane1ClassName}
                split="vertical"
                minSize={resource !== 'teams' ? 333 : 265}
                maxSize={560}
                defaultSize={parseInt(
                  localStorage.getItem('layout-split-sizes') ||
                    resource !== 'teams'
                    ? '333'
                    : '265',
                  10,
                )}
                onChange={(size) =>
                  localStorage.setItem('layout-split-sizes', `${size}`)
                }
                pane1Style={display !== 'pc' ? style : undefined}
              >
                <EuiNav>
                  {gnb}
                  <EuiSnb
                    toggleLabel={state.toggleLabel}
                    onToggle={handleSnbToggle}
                  >
                    {drawer}
                  </EuiSnb>
                </EuiNav>
                <EuiContainer>{container}</EuiContainer>
              </SplitPane>
            )}
          </>
        );
      }

      return (
        <>
          <SplitPane
            className={pane1ClassName}
            split="vertical"
            minSize={resource !== 'teams' ? 333 : 265}
            maxSize={560}
            defaultSize={parseInt(
              localStorage.getItem('layout-split-sizes') || resource !== 'teams'
                ? '333'
                : '265',
              10,
            )}
            onChange={(size) =>
              localStorage.setItem('layout-split-sizes', `${size}`)
            }
          >
            <EuiNav>
              {gnb}
              <EuiSnb
                toggleLabel={state.toggleLabel}
                onToggle={handleSnbToggle}
              >
                {drawer}
              </EuiSnb>
            </EuiNav>
            <EuiContainer>{container}</EuiContainer>
          </SplitPane>
        </>
      );
    }

    return (
      <>
        {display !== 'pc' && !isCreate ? (
          mobileNav && (
            <>
              <div className="ui-nav-cover" onClick={handleCloseMobileNav} />
              <EuiNav className="no-drawer">{gnb}</EuiNav>
            </>
          )
        ) : (
          <EuiNav className="no-drawer">{gnb}</EuiNav>
        )}

        <EuiContainer>{container}</EuiContainer>
      </>
    );
  };

  const handleCloseMobileNav = () => {
    dispatch(sessionActions.mobileNav(false));
  };

  const handleSnbToggle = () => {
    setState((prevState) => ({
      ...prevState,
      toggleLabel: prevState.snbHidden
        ? getLocalizedText('메뉴 접기')
        : getLocalizedText('메뉴 펼치기'),
      snbHidden: !prevState.snbHidden,
    }));
  };

  useHotkeys('ctrl+.,command+.', handleSnbToggle);

  return (
    <>
      {locateRoute}
      {getContent()}
    </>
  );
}

/*
function areEqual(prevProps: RouteComponentProps, nextProps: RouteComponentProps) {
  //nextProp가 prevProps와 동일한 값을 가지면 true를 반환하고, 그렇지 않다면 false를 반환
  console.log('areEqual', `${prevProps.location.pathname} !== ${nextProps.location.pathname}`);

  if (prevProps.location.pathname !== nextProps.location.pathname) {
    const prevUrls = prevProps.location.pathname.split('/');
    const nextUrls = nextProps.location.pathname.split('/');
    console.log('prevProps.location.pathname !== nextProps.location.pathname', prevUrls[1] === nextUrls[1] && prevUrls[2] === nextUrls[2]);
    return prevUrls[1] === nextUrls[1] && prevUrls[2] === nextUrls[2];
  }
  else
    return true;
}

export default React.memo(RootRouter, areEqual);
*/
export default RootRouter;
