import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import { Redirect } from 'react-router-dom';
import { RootState, useAppDispatch } from '../app/store';
import {
  LocationProps,
  Module,
  ModuleItem,
} from '../../groupware-common/types';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import NavigationDrawer from '../pages/adminconsole/NavigationDrawer';
import Page from '../pages/test/Page';
import { sessionActions } from '../stores/session';
import EuiBody from '../../components/layout/EuiBody';
import Loading from '../../components/loading/Loading';

import DefaultPreferencesContainer from '../pages/adminconsole/default/preferences/DefaultPreferencesContainer';

import DirectoryLocateRoute from '../../groupware-directory/locates/AdminConsoleLocateRoute';
import DirectoryLocateContainer from '../../groupware-directory/locates/AdminConsoleLocateContainer';
import ApprovalLocateRoute from '../../groupware-approval/locates/AdminConsoleLocateRoute';
import ApprovalLocateContainer from '../../groupware-approval/locates/AdminConsoleLocateContainer';
import BoardLocateRoute from '../../groupware-board/locates/AdminConsoleLocateRoute';
import BoardLocateContainer from '../../groupware-board/locates/AdminConsoleLocateContainer';
import CalendarLocateRoute from '../../groupware-calendar/locates/AdminConsoleLocateRoute';
import CalendarLocateContainer from '../../groupware-calendar/locates/AdminConsoleLocateContainer';
import EmptyLocateRoute from '../../groupware-common/locates/EmptyLocateRoute';
import AttendanceLocateRoute from '../../groupware-attendance/locates/AdminConsoleLocateRoute';
import AttendanceLocateContainer from '../../groupware-attendance/locates/AdminConsoleLocateContainer';
import ResourceLocateRoute from '../../groupware-resource/locates/AdminconsoleLocateRoute';
import ResourceLocateContainer from '../../groupware-resource/locates/AdminconsoleLocateContainer';
import DocumentLocateRoute from '../../groupware-document/locates/AdminConsoleLocateRoute';
import DocumentLocateContainer from '../../groupware-document/locates/AdminConsoleLocateContainer';
import SystemLinkLocateRoute from '../../groupware-systemlink/locates/SystemLinkLocateRoute';
import SystemLinkLocateContainer from '../../groupware-systemlink/locates/SystemLinkLocateContainer';
import ServiceLocateRoute from '../../groupware-service/locates/AdminconsoleLocateRoute';
import ServiceLocateContainer from '../../groupware-service/locates/AdminconsoleLocateContainer';
import DashBoardLocateContainer from '../../groupware-dashboard/locates/AdminconsoleLocateContainer';
import DashBoardLocateRoute from '../../groupware-dashboard/locates/AdminconsoleLocateRoute';
import { CreateRandomString } from '../../groupware-common/utils/ui';
import { userGroupActions } from '../../groupware-directory/stores/directory/group';
import AuthenticationLocateRoute from '../../groupware-authentication/locates/AuthenticationLocateRoute';
import AuthenticationLocateContainer from '../../groupware-authentication/locates/AuthenticationLocateContainer';
import ErrorPage from '../../components/error/ErrorPage';
import {
  getLocalizedText,
  getModuleNamespace,
  resourceReload,
  resourceUpdate,
} from '../../groupware-common/utils/i18n';

function AdminConsoleRouter({ location }: LocationProps) {
  // console.log(`${AdminConsoleRouter.name}.render`);

  const dispatch = useAppDispatch();
  const modules = useSelector((state: RootState) => state.session.modules);
  const adminModules = [
    // eslint-disable-next-line prettier/prettier
    { code: 'directory', id: 'DIRECTORY', seq: 0,name: getLocalizedText('모듈.조직도'), } as ModuleItem,
    ...useSelector(
      (state: RootState) => state.service.menu.adminconsole.menus.list,
    ),
  ];
  adminModules.push({
    code: 'directory',
    id: 'DIRECTORY',
    seq: modules.length + 1,
    name: getLocalizedText('모듈.조직도'),
  });
  const roles = useSelector(
    (state: RootState) => state.session.principal.roles,
  );
  const resource = useSelector((state: RootState) => state.session.resource);
  let modulesToUse: ModuleItem[] = [];
  if (resource === 'teams') modulesToUse = [...modules];
  else
    roles.forEach((a) => {
      if (a === 'ADMIN' && adminModules.length > 0) {
        adminModules.forEach((m) => modulesToUse.push(m));
        return;
      }
      if (a === 'ADMIN' && adminModules.length === 0) {
        modulesToUse = [...modules];
        return;
      }
      modulesToUse.push({
        code: a.split('_')[0].toLowerCase() as Module,
        id: a.split('_')[0],
        name: getLocalizedText(`모듈.${a.split('_')[0]}`),
        seq: 0,
      });
    });
  const display = useSelector((state: RootState) => state.session.display);
  const mobileNav = useSelector((state: RootState) => state.session.mobileNav);
  const route = useSelector((state: RootState) => state.session.route);
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  const localeUpdateAt = useSelector(
    (state: RootState) => state.i18n.lastUpdateAt,
  );

  const [lastLocaleUpdateAt, setLastLocaleUpdateAt] = useState(localeUpdateAt);

  useEffect(() => {
    dispatch(userGroupActions.findList({}));
    const moduleNamespaces = adminModules.map((x) =>
      getModuleNamespace(x.code),
    );
    resourceUpdate([...moduleNamespaces], [currentLanguage]);
  }, []);

  useEffect(() => {
    if (mobileNav) {
      const { prevModule } = getPathParams<{ prevModule?: string }>(
        '/:prevModule',
        route.pathname,
      );
      const { nextModule } = getPathParams<{ nextModule?: string }>(
        '/:nextModule',
        location.pathname,
      );
      if (prevModule !== nextModule) dispatch(sessionActions.mobileNav(false));
    }
  }, [mobileNav, location.pathname, route.pathname]);

  useEffect(() => {
    const { prevModule } = getPathParams<{ prevModule?: string }>(
      '/*/:prevModule',
      route.pathname,
    );
    const { nextModule } = getPathParams<{ nextModule?: string }>(
      '/*/:nextModule',
      location.pathname,
    );
    if (nextModule === undefined || prevModule === undefined) return;

    if (prevModule !== nextModule) {
      const namespace = getModuleNamespace(nextModule);
      resourceReload(currentLanguage, namespace);
    }
  }, [location.pathname, route.pathname]);

  useEffect(() => {
    if (lastLocaleUpdateAt !== localeUpdateAt) {
      setLastLocaleUpdateAt(localeUpdateAt);
      dispatch(sessionActions.setRoute(route));
    }
  }, [localeUpdateAt]);

  const locateRoute = useMemo(() => {
    let result: JSX.Element | null = null;

    const { pathname: p, search, hash: h } = location;
    const pathParams = getPathParams('/*/:module', p);
    const s = search.startsWith('?') ? search.substring(1) : search;
    const key = CreateRandomString();
    // console.log(`locateRoute:pathname`, p);
    // console.log(`locateRoute:search`, s);
    // console.log(`locateRoute:hash`, h);
    // console.log(`locateRoute:key`, locationKey);
    // console.log(`locateRoute:pathParams`, pathParams);

    if (resource !== 'teams' && pathParams.module === undefined) {
      let adminconsolePath = '/adminconsole';
      if (roles.length > 0) {
        let role: string | undefined;
        for (let i = 0; i < modulesToUse.length; i += 1) {
          const moduleId = modulesToUse[i].id;
          role = roles.find((a) => a.split('_')[0] === moduleId)?.split('_')[0];
          if (role !== undefined) break;
        }
        // 전체 관리자인 경우 서비스 메뉴로 이동
        if (roles.some((a) => a === 'ADMIN')) adminconsolePath += '/service';
        // 모듈별 관리자인 경우 권한 있는 모듈 중 첫번째 위치한 모듈 메뉴로 이동
        else if (role) adminconsolePath += `/${role.toLowerCase()}`;
        else adminconsolePath += '/error';
      } else adminconsolePath += '/error';

      return <Redirect to={{ pathname: adminconsolePath }} />;
    }

    if (pathParams.module === 'directory')
      result = (
        <DirectoryLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'approval') {
      result = (
        <ApprovalLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    }
    if (pathParams.module === 'systemlink')
      result = (
        <SystemLinkLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'authentication')
      result = (
        <AuthenticationLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'board')
      result = <BoardLocateRoute pathname={p} search={s} hash={h} key={key} />;
    if (pathParams.module === 'calendar')
      result = (
        <CalendarLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'attendance')
      result = (
        <AttendanceLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (pathParams.module === 'resource')
      result = (
        <ResourceLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'document')
      result = <DocumentLocateRoute pathname={p} search={s} key={key} />;
    if (pathParams.module === 'service') {
      return <ServiceLocateRoute pathname={p} search={s} key={key} />;
    }
    if (pathParams.module === 'dashboard') {
      return <DashBoardLocateRoute pathname={p} search={s} key={key} />;
    }
    if (result === null)
      result = <EmptyLocateRoute pathname={p} search={s} hash={h} key={key} />;

    return result;
  }, [location.pathname, location.search, location.hash, location.key]);

  const locateContainer = useMemo(() => {
    const { pathname: p, search: s, hash: h, key: k } = route;
    const pathParams = getPathParams('/:root/:module', p);
    const { module: locationModule } = getPathParams<{ module?: string }>(
      '/*/:module',
      location.pathname,
    );
    // console.log(`locateContainer:pathname`, p);
    // console.log(`locateContainer:search`, s);
    // console.log(`locateContainer:hash`, h);
    // console.log(`locateContainer:key`, k);
    // console.log(`locateContainer:pathParams`, pathParams);

    // 모듈별 관리자 유무
    const isModuleAdmin = (module: string) => {
      if (roles.some((a) => a === 'ADMIN')) return true;
      return roles.some((a) => a.split('_')[0].toLowerCase() === module);
    };
    // 전체관리자 권한 유무
    const isAdmin = roles.some((a) => a === 'ADMIN');

    // CHECKLIST - 관리자 권한 잘 뜨는지 체크 필요.
    // 해당 메뉴에 대한 접근 권한 유무
    const hasModuleAccess = modulesToUse.some((module) => {
      if (locationModule === 'systemlink')
        return isModuleAdmin('approval') || isModuleAdmin('directory');
      if (locationModule === 'service' || locationModule === 'authentication')
        return isAdmin;
      return (
        module.id.toLowerCase() === locationModule &&
        isModuleAdmin(locationModule)
      );
    });

    if (!hasModuleAccess)
      return pathParams.root === 'adminconsole' ||
        pathParams.module === 'error' ? (
        <ErrorPage />
      ) : null;

    if (pathParams.module === 'default') return <DefaultPreferencesContainer />;
    if (pathParams.module === 'directory')
      return (
        <DirectoryLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'approval') {
      return (
        <ApprovalLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    }
    if (pathParams.module === 'systemlink')
      return <SystemLinkLocateContainer pathname={p} search={s} key={k} />;
    if (pathParams.module === 'authentication')
      return <AuthenticationLocateContainer pathname={p} search={s} key={k} />;
    if (pathParams.module === 'board')
      return <BoardLocateContainer pathname={p} search={s} hash={h} key={k} />;
    if (pathParams.module === 'calendar')
      return (
        <CalendarLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'test') return <Page />;
    if (pathParams.module === 'attendance')
      return (
        <AttendanceLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'resource')
      return (
        <ResourceLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'document')
      return <DocumentLocateContainer pathname={p} search={s} key={k} />;
    if (pathParams.module === 'service') {
      return <ServiceLocateContainer pathname={p} search={s} key={k} />;
    }
    if (pathParams.module === 'dashboard') {
      return <DashBoardLocateContainer pathname={p} search={s} key={k} />;
    }
    return null;
  }, [route, localeUpdateAt]);

  const handleCloseNav = () => {
    dispatch(sessionActions.mobileNav(false));
  };

  const getContent = () => {
    const { pathname: p, search } = route;
    const pathParams = getPathParams('/*/:module/:folder', p);
    const queryParams = getQueryParams(search);
    const isNotDetectedSize =
      (queryParams.dialogType === 'form' &&
        (queryParams.dialogMode === 'update' ||
          queryParams.dialogMode === 'create')) ||
      (pathParams.module === 'attendance' &&
        pathParams.folder === 'dayOffSetting');
    if (display !== 'pc' && !isNotDetectedSize)
      return (
        <>
          {mobileNav && (
            <>
              <div className="ui-nav-cover" onClick={handleCloseNav} />
              <NavigationDrawer pathname={route.pathname} hash={route.hash} />
            </>
          )}
          <div className="admin-container">
            {locateContainer || (
              <EuiBody>
                <Loading />
              </EuiBody>
            )}
          </div>
        </>
      );
    if (isNotDetectedSize) {
      const style: React.CSSProperties = {
        display: 'none',
      };

      return (
        <>
          {mobileNav ? (
            <>
              <div className="ui-nav-cover" onClick={handleCloseNav} />
              <NavigationDrawer pathname={route.pathname} hash={route.hash} />
            </>
          ) : (
            <SplitPane
              allowResize={false}
              className="ui-split layout-split"
              split="vertical"
              defaultSize={265}
              pane1Style={display !== 'pc' ? style : undefined}
            >
              <NavigationDrawer pathname={route.pathname} hash={route.hash} />
              <div className="admin-container">
                {locateContainer || (
                  <EuiBody>
                    <Loading />
                  </EuiBody>
                )}
              </div>
            </SplitPane>
          )}
        </>
      );
    }
    return (
      <SplitPane
        allowResize={false}
        className="ui-split layout-split"
        split="vertical"
        defaultSize={265}
      >
        <NavigationDrawer pathname={route.pathname} hash={route.hash} />
        <div className="admin-container">
          {locateContainer || (
            <EuiBody>
              <Loading />
            </EuiBody>
          )}
        </div>
      </SplitPane>
    );
  };

  return (
    <>
      {locateRoute}
      {getContent()}
    </>
  );
}

export default React.memo(AdminConsoleRouter);
