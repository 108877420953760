/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg, Module, ModuleItem } from '../../groupware-common/types';
import { appError } from '../../groupware-webapp/stores/common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import { IconType } from '../../groupware-common/types/icon';
import serviceMenuApi, { ModuleType } from '../apis/module';
import { getLinkMenuImagePath } from '../../groupware-common/utils';
import {
  UpdateMenuArg,
  createMenuArg,
} from '../pages/adminconsole/service/linkmenu/ServiceLinkMenuEdit';

const name = 'service/module';

export const formatModuleName = (module: ModuleType): string => {
  switch (module) {
    case 'APPROVAL':
      return '결재';
    case 'BOARD':
      return '게시';
    case 'ATTENDANCE':
      return '근태';
    case 'CALENDAR':
      return '일정';
    case 'CONTACTS':
      return '주소록';
    case 'DASHBOARD':
      return '대시보드';
    case 'DOCUMENT':
      return '문서';
    case 'MAIL':
      return '메일';
    case 'RESOURCE':
      return '자원';
    case 'DIRECTORY':
      return '조직도';
    default:
      return '';
  }
};

export interface LinkMenuItem {
  id: number;
  seq: number;
  name: string;
  status: boolean;
  updateAt: string;
  imagePath: string;
}

export interface LinkMenuDetail extends LinkMenuItem {
  url: string;
}

export interface ModulePermission {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디
  referenceType: string; // 참조 유형 (company | group | organization | employee)
  createAt?: string;
  isDeleted?: boolean;
}

export interface MenuItemDetail extends ModuleItem {
  isInitial: boolean;
  includers: ModulePermission[];
  excluders: ModulePermission[];
}

interface CategoryItem {
  type: 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}

const categories: CategoryItem[] = [
  // 관리자 - 메뉴.
  { type: 'setting', id: 7001, name: '기본 설정', icon: 'folder' },
  { type: 'setting', id: 7002, name: '메뉴 설정', icon: 'folder' },
  { type: 'setting', id: 7003, name: '링크메뉴 관리', icon: 'folder' },
  // { type: 'setting', id: 7003, name: '용량 관리', icon: 'folder' },
];

interface State {
  category: CategoryItem[];
  adminconsole: {
    menus: {
      list: ModuleItem[];
      view: MenuItemDetail | undefined;
    };

    linkMenus: {
      list: LinkMenuItem[];
      view: LinkMenuDetail | undefined;
    };
  };
}

const initialState: State = {
  category: categories,
  adminconsole: {
    menus: {
      list: [],
      view: undefined,
    },
    linkMenus: {
      list: [],
      view: undefined,
    },
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (arg: { companyId: number } & LocateArg, { rejectWithValue }) => {
    try {
      const { companyId } = arg;
      const list = await serviceMenuApi.findList({ companyId });
      return list.map((module) => ({
        ...module,
        id: module.module,
        name: formatModuleName(module.module),
        code: module.module.toLowerCase() as Module,
      }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { companyId: number; module: string } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { companyId, module } = arg;
      const view = await serviceMenuApi.findView({ companyId, module });
      return {
        ...view,
        id: view.module,
        name: formatModuleName(view.module as ModuleType),
        code: view.module.toLowerCase() as Module,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      data: {
        companyId: number;
        module: string;
        status: boolean;
        includers: ModulePermission[];
        excluders: ModulePermission[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { route, data } = arg;
      const { view } = (getState() as RootState).service.menu.adminconsole
        .menus;
      // 변경 사항이 없을 때 백엔드 호출하지 않음.
      if (
        data.includers.length <= 0 &&
        data.excluders.length <= 0 &&
        data.status === view?.status
      ) {
        if (route) dispatch(findList({ companyId: data.companyId, route }));
        return;
      }

      const response = await serviceMenuApi.update({
        ...data,
        includers: data.includers.length <= 0 ? undefined : data.includers,
        excluders: data.excluders.length <= 0 ? undefined : data.excluders,
      });
      if (route) dispatch(findList({ companyId: data.companyId, route }));
      return { data: response };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 메뉴 순서변경. */
const sortMenu = createAsyncThunk(
  `${name}/sortMenu`,
  async (
    arg: {
      companyId: number;
      list: {
        module: string;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { route, ...data } = arg;
      const list = await serviceMenuApi.sortMenu(data);
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 관리자 - 링크메뉴 리스트 조회
const linkList = createAsyncThunk(
  `${name}/linkList`,
  async (_: void | LocateArg, { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const response = await serviceMenuApi.linkList({ companyId });
      return response.map((x) => ({
        ...x,
        imagePath: getLinkMenuImagePath({
          companyId,
          linkMenuId: x.id,
          path: x.isImage ? 'nocache' : undefined,
        }),
      }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 관리자 - 링크메뉴 보기 조회
const linkView = createAsyncThunk(
  `${name}/linkView`,
  async (
    arg: { companyId: number; id: number } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { companyId, id } = arg;
      const response = await serviceMenuApi.linkView({ companyId, id });
      return {
        ...response,
        imagePath: getLinkMenuImagePath({
          companyId,
          linkMenuId: id,
          path: response.isImage ? 'nocache' : undefined,
        }),
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const deleteLink = createAsyncThunk(
  `${name}/deleteLink`,
  async (
    arg: {
      data: {
        companyId: number;
        id: number;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { route } = arg;
      const { companyId, ...data } = arg.data;
      const response = await serviceMenuApi.deleteLink({
        companyId,
        data,
      });
      if (route) {
        dispatch(linkList({ route }));
      }
      return { data: response };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);
const saveLink = createAsyncThunk(
  `${name}/saveLink`,
  async (
    arg: {
      data: createMenuArg;
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = arg;
      const { companyId } = (getState() as RootState).session.principal;

      const response = await serviceMenuApi.saveLink({
        companyId,
        data: {
          name: data.name,
          url: data.url,
          image: data.imageFile,
        },
      });

      if (arg.route) {
        const route = {
          pathname: `${arg.route.pathname}/${response.id}`,
        };
        dispatch(linkList({}));
        dispatch(linkView({ companyId, id: response.id, route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);
const updateLink = createAsyncThunk(
  `${name}/updateLink`,
  async (
    arg: {
      data: UpdateMenuArg;
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data, route } = arg;

      const { companyId } = (getState() as RootState).session.principal;

      const response = await serviceMenuApi.updateLink({
        companyId,
        data: {
          id: data.id,
          name: data.name,
          status: data.status,
          url: data.url,
          image: data.imageFile,
          updateAt: data.updateAt,
        },
      });

      if (route) {
        dispatch(linkList({}));
        dispatch(linkView({ companyId, id: response.id, route }));
      }
      return { data: response };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 링크 메뉴 순서변경. */
const sortLink = createAsyncThunk(
  `${name}/sortLink`,
  async (
    arg: {
      companyId: number;
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data, companyId } = arg;
      const list = await serviceMenuApi.sortLink({ companyId, data });
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const menuSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearView(state) {
      state.adminconsole.linkMenus.view = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.adminconsole.menus.list = payload;
        }
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.adminconsole.menus.view = payload;
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.adminconsole.menus.view) {
          const { data } = payload;
          state.adminconsole.menus.view = {
            ...state.adminconsole.menus.view,
            ...data,
          };
        }
      })
      .addCase(linkList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.adminconsole.linkMenus.list = payload;
          state.adminconsole.linkMenus.view = undefined;
        }
      })
      .addCase(linkView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.adminconsole.linkMenus.view = payload;
      })
      .addCase(updateLink.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.adminconsole.linkMenus.view) {
          const { data } = payload;
          state.adminconsole.linkMenus.view = {
            ...state.adminconsole.linkMenus.view,
            ...data,
          };
        }
      })
      .addCase(sortLink.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.adminconsole.linkMenus.list =
            state.adminconsole.linkMenus.list.map((x) => {
              const item = payload.find((y) => x.id === y.id);
              if (item) return { ...x, ...item };
              return x;
            });
      });
  },
});

export default menuSlice.reducer;

export const serviceMenuActions = {
  findList,
  findView,
  update,
  sortMenu,

  linkList,
  linkView,
  saveLink,
  updateLink,
  sortLink,
  deleteLink,

  clearView: menuSlice.actions.clearView,
};
