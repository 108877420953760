import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { serviceBasicActions } from '../../../../stores/basic';

function ServiceBasicRoute(props: { pathname: string; search: string }): null {
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    const route = { pathname, search, clearErrors: true };

    if (mount) {
      Promise.all([
        dispatch(serviceBasicActions.findAdministrators()),
        dispatch(serviceBasicActions.findPreferences({ companyId })),
      ]).then(() => {
        dispatch(sessionActions.setRoute(route));
      });
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default ServiceBasicRoute;
