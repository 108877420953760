import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Tab from '../../../../../components/tab/Tab';
import { getPathMap, go } from '../../../../../groupware-common/utils';
import { RootState } from '../../../../../groupware-webapp/app/store';
import DirectorySearch, {
  OptionType,
  OptionUseType,
  SearchDateProps,
} from '../../../../../components/search/DirectorySearch';
import Divider from '../../../../../components/divider/Divider';
import Button from '../../../../../components/button/Button';
import Pagination from '../common/components/Pagination';
import MenuGroup from '../../../../../components/menu/MenuGroup';
import MenuItem from '../../../../../components/menu/MenuItem';
import Menu from '../../../../../components/menu/Menu';
import MenuDivider from '../../../../../components/menu/MenuDivider';

export interface SearchDateProps2 {
  start: Date | null;
  end: Date | null;
}

function ApprovalArchiveSettingHead(props: {
  pathname: string;
  hash: string;
  searchCode?: string;
  searchWord?: string;
  date: SearchDateProps2;
  sortOptions?: { value: string; label: string }[];
  pageNo: number;
  rowsPerPage: number;
  totalCount: number;
  filterOptions?: { value: string; label: string }[];
  onAction(arg: { code: string; event: React.MouseEvent }): void;
  onChangeFilter?(value: string): void;
  onChangeSort?(value: string): void;
  onSearch?(arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    directoryFilter?: string;
    date: SearchDateProps;
    status?: string;
  }): void;
}): JSX.Element {
  // console.log(`${ApprovalArchiveSettingHead.name}.render(props)`, props);
  const { pathname, hash, searchCode = 'FOLDER_NAME', searchWord = '' } = props;
  const pathmap = getPathMap('/*/*', pathname);
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'default');
  // const queryParams = getQueryParams(props.search);
  // 고정 폴더 이름
  const title = categories.find((a) => a.id === 5004)?.name;
  const display = useSelector((state: RootState) => state.session.display);

  const menus = [
    { id: '', label: '기록물 설정' },
    { id: '#archivetransfer', label: '문서함 이관' },
    { id: '#transferhistory', label: '이관 내역' },
  ];

  const searchCategory: OptionType[] = [
    { value: 'FOLDER_NAME', label: '폴더 명', useType: 'text' },
    // eslint-disable-next-line prettier/prettier
    { value: 'SENDER_EMPLOYEE', label: '보낸 사람', useType: 'employeeDirectory' },
    // eslint-disable-next-line prettier/prettier
    { value: 'SENDER_ORGANIZATION', label: '보낸 부서', useType: 'organizationDirectory' },
    // eslint-disable-next-line prettier/prettier
    { value: 'RECEIVER_ORGANIZATION', label: '받은 부서', useType: 'organizationDirectory' },
  ];

  /** 기본검색 */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    directoryFilter?: string;
    date: SearchDateProps;
    status?: string;
  }) => {
    const { onSearch } = props;
    if (onSearch) onSearch(arg);
  };

  const [state, setState] = useState<{
    filterPopupRect:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>({
    filterPopupRect: undefined,
  });

  // 필터
  const handleFilterPopup = (event?: React.MouseEvent) => {
    if (state.filterPopupRect)
      setState((prevState) => ({
        ...prevState,
        filterPopupRect: undefined,
      }));
    else if (event) {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        filterPopupRect: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  const handleFilter = (value: string) => {
    handleFilterPopup();
    if (props.onChangeFilter) props.onChangeFilter(value);
  };

  const handleSort = (value: string) => {
    handleFilterPopup();
    if (props.onChangeSort) props.onChangeSort(value);
  };

  let useType: OptionUseType = 'text';
  if (searchCode === 'SENDER_EMPLOYEE') useType = 'employeeDirectory';
  if (
    searchCode === 'SENDER_ORGANIZATION' ||
    searchCode === 'RECEIVER_ORGANIZATION'
  )
    useType = 'organizationDirectory';

  return (
    <EuiHeader>
      <EuiHeader.Title>{title}</EuiHeader.Title>
      <Tab>
        {menus.map(({ id, label }) => (
          <Tab.Item
            key={id}
            label={label}
            selected={id === hash}
            onClick={() => go(pathmap, id)}
          />
        ))}
      </Tab>
      {hash === '#transferhistory' && (
        <>
          <EuiHeader.Search>
            <DirectorySearch
              dateOptions
              date={props.date}
              keyword={searchWord}
              options={searchCategory}
              filter={{
                value: searchCode,
                useType,
              }}
              onSearch={handleSearch}
            />
          </EuiHeader.Search>
          <EuiHeader.Toolbar>
            <EuiHeader.ToolbarLeft>
              <></>
            </EuiHeader.ToolbarLeft>
            <EuiHeader.ToolbarRight>
              {props.totalCount > 0 && (
                <Pagination
                  no={props.pageNo}
                  rows={props.rowsPerPage}
                  count={props.totalCount}
                  onPrev={(event) =>
                    props.onAction({ code: 'prevPage', event })
                  }
                  onNext={(event) =>
                    props.onAction({ code: 'nextPage', event })
                  }
                />
              )}
              {props.totalCount > 0 && display !== 'phone' && (
                <Divider orientation="vertical" />
              )}
              {display !== 'phone' && props.totalCount > 0 && (
                <Button
                  text="목록설정"
                  iconType
                  icon="bar-cog"
                  onClick={(event) =>
                    props.onAction({ code: 'listSetting', event })
                  }
                />
              )}
            </EuiHeader.ToolbarRight>
          </EuiHeader.Toolbar>
        </>
      )}

      {state.filterPopupRect && (
        <Menu
          point={state.filterPopupRect}
          onClose={handleFilterPopup}
          size="sm"
        >
          {props.filterOptions && (
            <>
              <MenuGroup>필터</MenuGroup>
              {props.filterOptions.map((x) => (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  onClick={() => handleFilter(x.value)}
                />
              ))}
            </>
          )}
          {props.sortOptions && (
            <>
              <MenuDivider />
              <MenuGroup>정렬</MenuGroup>
              {props.sortOptions.map((x) => (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  onClick={() => handleSort(x.value)}
                />
              ))}
            </>
          )}
        </Menu>
      )}
    </EuiHeader>
  );
}

export default ApprovalArchiveSettingHead;
