import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function ApprovalArchivesTransferRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    const route = { pathname, search, clearErrors: true };
    if (mount) dispatch(sessionActions.setRoute(route));

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default ApprovalArchivesTransferRoute;
