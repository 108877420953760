import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import {
  getPathParams,
  getQueryParams,
  goBack,
} from '../../groupware-common/utils';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import { RootState } from '../../groupware-webapp/app/store';

function ErrorDialog(props: {
  pathname?: string;
  search?: string;
  error?: { error?: string; path: string; message?: string; onConfirm(): void };
  authenticedError?: {
    error?: string;
    path: string;
    message?: string;
    onConfirm(): void;
  };
}): JSX.Element | null {
  // console.log(`${ErrorDialog.name}.render`);
  const teams =
    useSelector((state: RootState) => state.session.resource) === 'teams';
  const search = props.search ? getQueryParams(props.search) : undefined;

  // 인증 세션 만료인 경우
  if (props.authenticedError) {
    const { message, error, onConfirm } = props.authenticedError;

    return (
      <Dialog size="xs">
        <DialogBody>
          <div className="eui-alert-message">{message || error}</div>
        </DialogBody>
        <DialogFooter>
          <Button
            text={getLocalizedText('확인')}
            variant="contained"
            onClick={onConfirm}
          />
        </DialogFooter>
      </Dialog>
    );
  }

  if (props.error === undefined) return null;

  const { message, error, path } = props.error;
  const onConfirm = () => {
    props.error?.onConfirm();
    if (teams) {
      // 팀즈 - 결재하기 오류 시.
      if (props.pathname && props.pathname.startsWith('/approval/approve/')) {
        microsoftTeams.app.initialize().then(() => {
          microsoftTeams.dialog.url.submit();
        });
      }
      // 팀즈 - 문서 읽기 실패 시.
      if (props.pathname && props.pathname.startsWith('/approval/document/')) {
        microsoftTeams.app.initialize().then(() => {
          microsoftTeams.dialog.url.submit();
        });
      }
      // 팀즈 - 연차촉진알림 문서 읽기 실패 시.
      if (props.pathname && props.pathname.startsWith('/attendance/alert/')) {
        microsoftTeams.app.initialize().then(() => {
          microsoftTeams.dialog.url.submit();
        });
      }
      return;
    }
    // 문서 읽기 실패 시 goBack.
    if (
      props.pathname &&
      (path === '/api/approval/v1/document' ||
        path === '/api/approval/v1/share/reference/document' ||
        path === '/api/approval/v1/share/view/document') &&
      search?.contentMode === undefined &&
      search?.dialogType === undefined
    ) {
      const { p1, p2, p3, p4 } = getPathParams<{
        p1?: string;
        p2?: string;
        p3?: string;
        p4?: string;
      }>('/:p1/:p2/:p3/:p4', props.pathname);
      // 연동 문서 읽기 실패 시 윈도우 창 닫기.
      if (
        p1 === 'approval' &&
        p2 === 'approvalinterworking' &&
        p3 === 'document'
      ) {
        window.close();
        return;
      }
      if (
        (p1 === 'adminconsole' && p4) ||
        (p1 === 'approval' && p3) ||
        (p1 === 'attendance' && p3)
      )
        goBack();
    }
  };

  if (teams && props.pathname) {
    const { p1, p2 } = getPathParams<{
      p1?: string;
      p2?: string;
    }>('/:p1/:p2/*/*', props.pathname);
    if (p1 === 'approval' && (p2 === 'document' || p2 === 'approve')) {
      return (
        <div
          className="eui-dialog sm"
          style={{
            padding: 0,
            bottom: 'auto',
          }}
        >
          <div className="dialog-container">
            <DialogBody>
              <div className="eui-alert-message">{message || error}</div>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('확인')}
                variant="contained"
                onClick={onConfirm}
              />
            </DialogFooter>
          </div>
        </div>
      );
    }
  }
  return (
    <Dialog size="xs">
      <DialogBody>
        <div className="eui-alert-message">{message || error}</div>
      </DialogBody>
      <DialogFooter>
        <Button
          text={getLocalizedText('확인')}
          variant="contained"
          onClick={onConfirm}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default ErrorDialog;
