import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../components/layout/EuiToolbar';
import { DirectoryTreeItemArg } from '../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { preferencesActions } from '../../../stores/calendar/preferences';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function CalendarPreferencesContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const initAdmin = useSelector(
    (state: RootState) => state.calendar.preferences.administrators,
  );
  const categories = useSelector(
    (state: RootState) => state.calendar.calendars.category,
  ).filter((a) => a.type === 'setting');

  const initialState = {
    saveing: false,
    change: false,
    administrators: employees
      .filter(
        (a) =>
          a.companyId === principal.companyId &&
          initAdmin.some(({ employeeId }) => a.id === employeeId),
      )
      .map((a) => {
        const updateAt =
          initAdmin.find(({ employeeId }) => a.id === employeeId)?.updateAt ??
          '1000-01-01';
        return {
          companyId: a.companyId,
          employeeId: a.id,
          organizationId: a.representativeOrganizationId,
          avatar: a.avatar,
          updateAt,
        };
      }),
    administratorsMenuPoint: undefined as
      | { x: number; y: number; width: number; height: number }
      | undefined,
    validation: '',
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { administrators } = state;
      const removeAdministrators = initAdmin
        .filter(
          (a) =>
            administrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId, updateAt }) => ({ employeeId, updateAt }));
      const remove = await dispatch(
        preferencesActions.removeAdministrators(removeAdministrators),
      );

      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const appendAdministrators = administrators
        .filter(
          (a) =>
            initAdmin.find((b) => a.employeeId === b.employeeId) === undefined,
        )
        .map(({ employeeId }) => ({ employeeId }));
      const append = await dispatch(
        preferencesActions.appendAdministrators(appendAdministrators),
      );

      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  /** 관리자 디렉터리 트리 열기 */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { administratorsMenuPoint } = state;
    if (event !== undefined && administratorsMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 삭제 */
  const handleAdministratorsDelete = (employeeId: number) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      administrators: prevState.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
    }));
  };

  /** 관리자 추가 */
  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prevState) => {
      const { administrators } = prevState;
      const administratorsMenuPoint = undefined;
      if (administrators.some((a) => a.employeeId === employeeId))
        return { ...prevState, administratorsMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined)
        return { ...prevState, administratorsMenuPoint };

      const updateAt =
        initAdmin.find((a) => a.employeeId === employeeId)?.updateAt ?? '';

      return {
        ...prevState,
        change: true,
        administrators: [
          ...administrators,
          {
            companyId,
            employeeId,
            organizationId: employee.representativeOrganizationId,
            avatar: employee.avatar,
            updateAt,
          },
        ],
      };
    });
  };

  /** 저장 이벤트. */
  const handleSave = () => {
    setState((prev) => ({ ...prev, saveing: true }));
  };

  /** 취소 이벤트. */
  const handleCancel = () => {
    setState(initialState);
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  const { administrators, administratorsMenuPoint } = state;
  const title = categories.find((a) => a.id === 6001)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('관리자')}>
          <ChipGroup
            add={getLocalizedText('추가')}
            onAdd={handleDirectoryTreeMenuToggle}
          >
            {administrators.map(
              ({ companyId, employeeId, organizationId, avatar }) => (
                <Chip
                  key={`${companyId}/${employeeId}`}
                  label={getEmployeeName(companyId, employeeId)}
                  etc={getOrganizationName(companyId, organizationId)}
                  avatar={avatar}
                  onDelete={() => handleAdministratorsDelete(employeeId)}
                />
              ),
            )}
            {administratorsMenuPoint && (
              <DirectoryMenuTreeContainer
                point={administratorsMenuPoint}
                typeToFilter="employee"
                onItemClick={handleAdministratorsAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {state.change && (
              <Button
                noDuplication={state.validation === ''}
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleSave}
              />
            )}
            {state.change && !state.saveing && (
              <Button
                text={getLocalizedText('취소')}
                variant="outlined"
                onClick={handleCancel}
              />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default CalendarPreferencesContainer;
