import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import Confirmation from '../../../../../components/alert/Confirmation';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { documentApi } from '../../../../../groupware-approval/apis/approval/v1/document';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  b62,
  createQueryString,
  getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  RootState as R,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { authoritiesActions } from '../../../../stores/sangsanginsecurities/authority';
import SecuritiesContentHeadList from '../content/SecuritiesContentHeadList';
import SecuritiesAuthorityHeadView from './SecuritiesAuthorityHeadView';
import SecuritiesAuthorityBodyList from './SecuritiesAuthorityBodyList';
import {
  getApprovalLineDrafter,
  getCurrentApprover,
} from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import SecuritiesAuthorityBodyView from './SecuritiesAuthorityBodyView';
import ApprovalAttachedDocumentDialog from '../../../../../groupware-approval/pages/root/approval/content/ApprovalAttachedDocumentDialog';
import ApprovalPrint from '../../../../../groupware-approval/pages/common/components/ApprovalPrint';
import { ApprovalLineType } from '../../../../../groupware-approval/pages/adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import ApprovalInterworkingLogDrawer from '../../../../../groupware-approval/pages/common/drawers/ApprovalInterworkingLogDrawer';

function SecuritiesAuthorityContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${SecuritiesAuthorityContainer.name}.render(props)`, props);
  const { pathname, search } = props;
  const pathmap = getPathMap('/*/*', pathname);
  const { folderId, itemId } = getPathParams<{
    folderId?: string;
    itemId?: number;
  }>('/*/:folderId/:itemId$base62', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const principal = useSelector((s: R) => s.session.principal);
  const organizationIds = principal.affiliatedOrganizations.map(({ id }) => id);
  const display = useSelector((s: R) => s.session.display);
  const teamsHostClientType = useSelector(
    (s: R) => s.session.teamsHostClientType,
  );
  const isMobileTeams =
    teamsHostClientType &&
    (teamsHostClientType === 'android' || teamsHostClientType === 'ios');

  const directory = useDirectory();

  const displayDensity = useSelector(
    (s: R) => s.securites.authority.displayDensity,
  );
  const readingPaneMode = useSelector((s: R) =>
    display === 'phone' ? 'list' : s.securites.authority.readingPaneMode,
  );
  /** 분할 보기 여부. */
  const isSplitView =
    readingPaneMode === 'horizontal' || readingPaneMode === 'vertical';
  /** 결재 상태 여부. */
  const isApprovalStatus =
    queryParams.searchCode === undefined ||
    queryParams.searchCode === 'approval';

  const categories = useSelector((s: R) => s.securites.setting.categories);
  const list = useSelector((s: R) => s.securites.authority.list.items);
  const checkedList = list.filter((a) => a.checked);
  const total = useSelector((s: R) => s.securites.authority.list.totalCount);
  const view = useSelector((s: R) =>
    itemId !== undefined && itemId === s.securites.authority.view?.id
      ? s.securites.authority.view
      : undefined,
  );
  const title = categories.find((a) => a.id === 'authority')?.name ?? '';

  if (checkedList.length > 0)
    document.documentElement.setAttribute('data-selecting', 'true');
  else document.documentElement.removeAttribute('data-selecting');

  /** 리스트 항목 배열 */
  const items =
    list.map((item) => {
      const drafter = getApprovalLineDrafter(item.approvalLine);
      return {
        ...item,
        companyId: principal.companyId,
        attachments: item.attachments.length > 0,
        drafter: drafter?.employeeName || '',
        drafterOrganization: drafter?.organizationName || '',
        drafterJob: drafter?.employeeId ? drafter?.jobPositionName : '',
        drafterAvatar: drafter?.employeeId ? getAvatarPath(drafter) : '',
        draftAt: item.createAt,
        completeAt: item.completeAt || '',
        linkWait: item.linkWait || undefined,
      };
    }) || [];

  const [columns, setColumns] = useState<Column<PostListItemType>[]>([
    { name: 'checked', text: '', type: 'action', visible: true },
    { name: 'work', text: '업무', type: 'form', visible: true },
    { name: 'documentNo', text: '문서번호', type: 'number', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: '제목', type: 'post', visible: true, disable: true },
    { name: 'drafter', text: '기안자', type: 'user', visible: true },
    { name: 'draftAt', text: '기안일', type: 'date', visible: true },
    { name: 'status', text: '상태', type: 'status', visible: true },
  ]);

  const initialState = {
    dialogs: [],
    approvalDialogType: undefined,
    approval: {
      data: [],
      successCount: 0,
      failCount: 0,
      type: 'none' as const,
      submit: false,
    },
  };
  const [state, setState] = useState<{
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
    approvalDialogType?: 'progress' | 'complete';
    approval: {
      data: {
        approvalLine: ApprovalLineType;
        id: number;
        updateAt: string;
      }[];
      successCount: number;
      failCount: number;
      type:
        | 'none'
        | 'listApproval'
        | 'listReturn'
        | 'viewApproval'
        | 'viewReturn';
      submit: boolean;
    };
  }>(initialState);

  // 결재 시 전달 객체 생성.
  const approvalData = (arg: {
    approvalLine: ApprovalLineType;
    id: number;
    updateAt: string;
  }) => {
    const currentApprover = getCurrentApprover({
      approvalLine: arg.approvalLine,
      companyId: principal.companyId,
      organizationIds,
      employeeId: principal.employeeId,
      approvalFolder: true,
    });
    const approver =
      currentApprover?.employeeId === undefined
        ? getDirectoryData({
            ...directory,
            ...principal,
            organizationId:
              currentApprover?.organizationId ?? principal.organizationId,
          })
        : undefined;
    const data = {
      id: arg.id,
      updateAt: arg.updateAt,
      organizationId:
        currentApprover?.employeeId === undefined
          ? currentApprover?.organizationId
          : undefined,
      approver,
    };
    return data;
  };

  const completeApproval = (arg: { success: number; fail: number }) => {
    setState((prev) => ({
      ...prev,
      approvalDialogType: 'complete',
      approval: {
        ...prev.approval,
        successCount: arg.success,
        failCount: arg.fail,
        submit: false,
      },
    }));
  };

  useEffect(() => {
    async function run() {
      const { type: act } = state.approval;
      if (state.approval.data.length === 0) {
        completeApproval({ success: 0, fail: 0 });
        return;
      }
      const listData: {
        approvalLine: ApprovalLineType;
        id: number;
        updateAt: string;
      }[][] = [];
      let approval: {
        approvalLine: ApprovalLineType;
        id: number;
        updateAt: string;
      }[] = [];
      for (let i = 0; i < state.approval.data.length; i += 1) {
        approval.push(state.approval.data[i]);
        if (approval.length === 10) {
          listData.push(approval);
          approval = [];
        } else if (i === state.approval.data.length - 1)
          listData.push(approval);
      }

      let success = 0;
      let fail = 0;
      for (let i = 0; i < listData.length; i += 1) {
        let addSuccess = 0;
        let addFail = 0;
        const approvalEvent = listData[i].map(async (item) => {
          const data = approvalData({
            approvalLine: item.approvalLine,
            id: item.id,
            updateAt: item.updateAt,
          });
          // 승인 이벤트.
          if (act === 'listApproval' || act === 'viewApproval')
            await documentApi
              .approbate(data)
              .then(() => {
                addSuccess += 1;
              })
              .catch(() => {
                addFail += 1;
              });
          // 반려 이벤트.
          if (act === 'listReturn' || act === 'viewReturn')
            await documentApi
              .reject(data)
              .then(() => {
                addSuccess += 1;
              })
              .catch(() => {
                addFail += 1;
              });
        });
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(approvalEvent);
        success += addSuccess;
        fail += addFail;
      }
      completeApproval({ success, fail });
    }
    if (state.approval.submit) run();
  }, [state.approval.submit]);

  const handleItemClick = (status: string, id: number) => {
    queryParams.status = status !== '' ? status : undefined;
    if (isSplitView) {
      const route = {
        pathname: `${pathmap}/${b62(id)}`,
        search: getQueryParams(queryParams),
      };
      dispatch(authoritiesActions.view({ id, route }));
    } else {
      go(`${pathmap}/${b62(id)}`, queryParams);
    }
  };

  const handleListItemCheckedChange = (
    id: number | 'all',
    checked: boolean,
  ) => {
    dispatch(authoritiesActions.setListItemChecked({ itemId: id, checked }));
  };

  const handleHeadAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;

    // 이전 페이지.
    if (code === 'prev') {
      const pageNo = (queryParams.pageNo ?? 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(pathmap, queryParams);
      }
    }
    // 다음 페이지.
    if (code === 'next') {
      const pageNo = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * pageNo) < total) {
        queryParams.pageNo = pageNo;
        go(pathmap, queryParams);
      }
    }
    // 목록 설정.
    if (code === 'listSetting')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 인쇄.
    if (code === 'print') dispatch(sessionActions.setDialog({ type: 'print' }));
    if (code === 'interworking-log')
      dispatch(sessionActions.setDrawer({ type: 'interworking-log' }));
    // 일괄 결재.
    if (code === 'listApproval' || code === 'listReturn') {
      setState((prev) => ({
        ...prev,
        approvalDialogType: 'progress',
        approval: {
          ...prev.approval,
          data: checkedList.map((a) => ({
            approvalLine: a.approvalLine,
            id: a.id,
            updateAt: a.updateAt,
          })),
          type: code,
        },
      }));
    }
    // 단일 결재.
    if (code === 'viewApproval' || code === 'viewReturn') {
      setState((prev) => ({
        ...prev,
        approvalDialogType: 'progress',
        approval: {
          ...prev.approval,
          data: view
            ? [
                {
                  approvalLine: view.approvalLine,
                  id: view.id,
                  updateAt: view.updateAt,
                },
              ]
            : [],
          type: code,
        },
      }));
    }
  };

  const handleSearch = (arg: { code: string; word?: string }) => {
    const { code, word } = arg;

    let searchPath = pathname;
    if (isSplitView) searchPath = pathmap;
    queryParams.searchWord = !word || word === '' ? undefined : word;
    queryParams.searchCode = code;
    delete queryParams.pageNo;

    go(searchPath, queryParams);
  };

  // 읽기 창 모드 변경.
  const handleChangeReadingPaneMode = (
    mode: 'list' | 'vertical' | 'horizontal',
  ) => {
    if (mode === 'list') {
      const location = utils.getLocation({
        target: props,
        source: {
          pathname: pathmap,
          mode: 'replace',
        },
      });
      dispatch(sessionActions.setRoute(location));
    }
    dispatch(authoritiesActions.setReadingPaneMode(mode));
  };

  // 화면밀도 설정 이벤트.
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(authoritiesActions.displayDensity(value));
  };

  // 컬럼 보이기 변경 이벤트.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setColumns((prev) =>
      prev.map((x) => (x.name === name ? { ...x, visible } : x)),
    );
  };

  // 리스트 목록 개수 변경 이벤트.
  const handleChangeListCount = (value: string) => {
    go(
      pathname,
      createQueryString(
        {
          pageNo: undefined,
          rowsPerPage: parseInt(value, 10),
          drawerType: undefined,
          drawerMode: undefined,
        },
        queryParams,
      ),
    );
  };

  // 첨부문서 팝업 이벤트.
  const handleAttachedDocumentDialog = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument'; // 부모 문서, 첨부 문서
    documentId: number;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      dialogType,
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'parentdocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
            receiptDocumentId,
            receiptAffiliatedCompanyId,
          },
        ],
      }));
    }
    if (dialogType === 'attacheddocument') {
      // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
      if (state.dialogs.length === 0 && view) {
        setState((prev) => ({
          ...prev,
          dialogs: [
            {
              dialogType: 'attacheddocument',
              documentId: view.id,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [
            ...prev.dialogs,
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      }
    }
  };

  // 목록으로 돌아가기 이벤트.
  const handleListGo = () => {
    go(pathmap, search);
  };

  // 드로워 닫기 이벤트.
  const handleDrawerClose = () => {
    dispatch(sessionActions.setDrawer());
  };

  // 대화상자 닫기 이벤트.
  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  // 첨부문서 대화상자 닫기 이벤트.
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument' || dialogType === 'draftdocument') {
      if (state.dialogs.length > 2 || dialogType === 'draftdocument') {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  // 품의서 대화상자 열기
  const handleDraftDocumentDialog = (documentId: number) => {
    setState((prev) => ({
      ...prev,
      dialogs: [
        ...prev.dialogs,
        {
          dialogType: 'draftdocument',
          documentId,
          affiliatedCompanyId: 0,
        },
      ],
    }));
  };

  // 일괄 결재 버튼 클릭 이벤트.
  const handleApproval = () => {
    setState((prev) => ({
      ...prev,
      approval: {
        ...prev.approval,
        submit: true,
      },
    }));
  };

  // 일괄 결재 완료 후 이벤트.
  const handleApprovalComplete = () => {
    setState(initialState);
    if (queryParams.pageNo !== undefined || itemId !== undefined)
      go(pathmap, createQueryString({ pageNo: undefined }, queryParams));
    else
      dispatch(
        authoritiesActions.list({
          search: getQueryParams(queryParams),
          route: utils.getRoute({
            target: props,
            source: {
              pathname: pathmap,
              search: createQueryString({ pageNo: undefined }, queryParams),
            },
          }),
        }),
      );
  };

  const renderHead = (arg?: { type: 'list' | 'view' }) => {
    const type =
      arg?.type || (readingPaneMode === 'list' && itemId) ? 'view' : 'list';

    if (type === 'view') {
      if (!view) return null;
      let toolbarButtons: ActionEventProps[] = isMobileTeams
        ? []
        : [{ code: 'print', label: '인쇄', type: 'text', icon: 'print' }];
      if (
        view.linkType !== 'NONE' &&
        view.linkType !== 'ATTENDANCE' &&
        view.linkType !== 'ATTENDANCE_ALWAYS'
      )
        toolbarButtons = [
          ...toolbarButtons,
          // eslint-disable-next-line prettier/prettier
          { code: 'interworking-log', label: '연동 로그', type: 'text', icon: 'list' },
        ];

      if (isSplitView)
        return (
          <SecuritiesAuthorityHeadView
            type="split"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleHeadAction}
          />
        );

      if (isApprovalStatus)
        toolbarButtons = [
          // eslint-disable-next-line prettier/prettier
          { code: 'viewApproval', label: '승인', type: 'contained', icon: 'stamp-check' },
          // eslint-disable-next-line prettier/prettier
          { code: 'viewReturn', label: '반려', type: 'outlined', icon: 'undo-half' },
          ...toolbarButtons,
        ];
      return (
        <SecuritiesAuthorityHeadView
          type="full"
          onListGo={handleListGo}
          toolbarButtons={toolbarButtons}
          onToolbarAction={handleHeadAction}
        />
      );
    }

    const toolbarButtons: ActionEventProps[] = isApprovalStatus
      ? [
          // eslint-disable-next-line prettier/prettier
          { code: 'listApproval', label: '승인', type: 'contained', icon: 'stamp-check' },
          // eslint-disable-next-line prettier/prettier
          { code: 'listReturn', label: '반려', type: 'outlined', icon: 'undo-half' },
        ]
      : [];

    return (
      <SecuritiesContentHeadList
        folderId={folderId}
        title={title}
        searchCode={queryParams.searchCode}
        searchWord={queryParams.searchWord}
        pageNo={queryParams.pageNo || 1}
        rowsPerPage={queryParams.rowsPerPage || 15}
        totalCount={total}
        checkedCount={list.filter((x) => x.checked).length}
        onCheckedChange={handleListItemCheckedChange}
        toolbarButtons={toolbarButtons}
        onAction={handleHeadAction}
        onSearch={handleSearch}
      />
    );
  };

  const renderBody = () => {
    let result = null;
    switch (readingPaneMode) {
      // 좌우분할.
      case 'vertical':
        result = (
          <EuiBody>
            <SplitPane
              className="ui-split eui-post"
              split="vertical"
              minSize={344}
              maxSize={560}
              defaultSize={parseInt(
                localStorage.getItem('approval-split-vertical-sizes') || '400',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem('approval-split-vertical-sizes', `${size}`)
              }
            >
              <SecuritiesAuthorityBodyList
                type="split"
                search={search}
                density={displayDensity}
                columns={columns.filter((a) => a.visible)}
                items={items}
                selectedId={itemId}
                onItemClick={handleItemClick}
                onItemCheckedChange={handleListItemCheckedChange}
              />
              <>
                {renderHead({ type: 'view' })}
                <SecuritiesAuthorityBodyView
                  pathname={pathname}
                  type="split"
                  view={view}
                  onDraftDocumentPopup={handleDraftDocumentDialog}
                  onAttachedDocumentPopup={handleAttachedDocumentDialog}
                />
              </>
            </SplitPane>
          </EuiBody>
        );
        break;
      // 상하분할.
      case 'horizontal':
        result = (
          <EuiBody>
            <SplitPane
              className="ui-split eui-post"
              split="horizontal"
              minSize={150}
              defaultSize={parseInt(
                localStorage.getItem('approval-split-horizontal-sizes') ||
                  '250',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem(
                  'approval-split-horizontal-sizes',
                  `${size}`,
                )
              }
            >
              <SecuritiesAuthorityBodyList
                type="full"
                search={search}
                density={displayDensity}
                columns={columns.filter((a) => a.visible)}
                items={items}
                selectedId={itemId}
                onItemClick={handleItemClick}
                onItemCheckedChange={handleListItemCheckedChange}
              />
              <>
                {renderHead({ type: 'view' })}
                <SecuritiesAuthorityBodyView
                  pathname={pathname}
                  type="full"
                  view={view}
                  onDraftDocumentPopup={handleDraftDocumentDialog}
                  onAttachedDocumentPopup={handleAttachedDocumentDialog}
                />
              </>
            </SplitPane>
          </EuiBody>
        );
        break;
      // 리스트.
      default:
        if (itemId) {
          result = (
            <EuiBody>
              <SecuritiesAuthorityBodyView
                pathname={pathname}
                type="full"
                view={view}
                onDraftDocumentPopup={handleDraftDocumentDialog}
                onAttachedDocumentPopup={handleAttachedDocumentDialog}
              />
            </EuiBody>
          );
        } else {
          result = (
            <EuiBody>
              <SecuritiesAuthorityBodyList
                type="full"
                search={search}
                density={displayDensity}
                columns={columns.filter((a) => a.visible)}
                items={items}
                selectedId={itemId}
                onItemClick={handleItemClick}
                onItemCheckedChange={handleListItemCheckedChange}
              />
            </EuiBody>
          );
        }
    }

    return result;
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 인쇄
    if (dialogType === 'print') {
      if (folderId === undefined || view === undefined) return null;
      let status: 1002 | 1003 | 1004;
      switch (queryParams.searchCode) {
        case 'approval':
          status = 1002;
          break;
        case 'progress':
          status = 1003;
          break;
        case 'complete':
          status = 1004;
          break;
        default:
          status = 1002;
      }
      return (
        <ApprovalPrint
          onClose={handleDialogClose}
          listId={[
            {
              id: view.id,
            },
          ]}
          folderId={status}
        />
      );
    }

    // 첨부문서 보기
    const { dialogs, approvalDialogType } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }
    // 일괄 결재
    if (approvalDialogType) {
      const { approval } = state;
      const isApproval =
        approval.type === 'listApproval' || approval.type === 'viewApproval';
      const approvalType = isApproval ? '승인' : '반려';

      // 결재 이벤트.
      if (approvalDialogType === 'progress') {
        return (
          <Dialog size="xs">
            <DialogBody>
              <div className="eui-alert-message eui-confirmation-message">
                {approval.data.length}건의 결재를 {approvalType}하시겠습니까?
              </div>
            </DialogBody>
            <DialogFooter>
              {!state.approval.submit && (
                <Button text="취소" onClick={() => setState(initialState)} />
              )}
              <Button
                loading={state.approval.submit}
                text={approvalType}
                variant="contained"
                onClick={handleApproval}
              />
            </DialogFooter>
          </Dialog>
        );
      }
      // 결재 완료 이벤트.
      if (approvalDialogType === 'complete') {
        return (
          <Confirmation onSubmit={handleApprovalComplete}>
            {approval.data.length}건 중 {state.approval.successCount}건의 결재가{' '}
            {approvalType}
            되었습니다.
          </Confirmation>
        );
      }
    }

    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    // 목록설정
    if (drawerType === 'listSetting')
      return (
        <ListSetting
          type={readingPaneMode}
          readingPaneMode={readingPaneMode}
          onReadingPaneModeChange={handleChangeReadingPaneMode}
          density={displayDensity}
          onChangeDensity={handleChangeDensity}
          columns={columns.filter(({ name }) => name !== 'checked')}
          onColumnVisibleChange={handleColumnVisibleChange}
          listCount={`${queryParams.rowsPerPage ?? 15}`}
          onChangeListCount={handleChangeListCount}
          onClose={handleDrawerClose}
        />
      );

    // 연동오류 내역 확인
    if (drawerType === 'interworking-log') {
      if (view === undefined) return null;

      return (
        <ApprovalInterworkingLogDrawer
          documentCompanyId={principal.companyId}
          documentId={view.id}
          onClose={handleDrawerClose}
        />
      );
    }

    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default SecuritiesAuthorityContainer;
