import React, { useRef, useState } from 'react';
import Authentication from '../../../components/Authentication';
import TextField from '../../../components/textfield/TextField';
import HelperText from '../../../components/alert/HelperText';
import { go } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

function Password(): JSX.Element {
  const timerRef = React.useRef<number>();
  const codeRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<{
    view: 'beginning' | 'code' | 'reset' | 'complete';
    id: string;
    email: string;
    code: string;
    password: string;
    reconfirmPassword: string;
    errorMessage?: string;
  }>({
    view: 'beginning',
    id: '',
    email: '',
    code: '',
    password: '',
    reconfirmPassword: '',
  });

  const getContent = () => {
    switch (state.view) {
      case 'code':
        return (
          <>
            <Authentication.Title label={getLocalizedText('인증하기')} />
            <Authentication.Form onSubmit={handleCheckCode}>
              <TextField
                key="code"
                placeholder={getLocalizedText('인증번호')}
                icon="number"
                value={state.code}
                name="code"
                onChange={handleChange}
                ref={codeRef}
                required
              />
              <Authentication.Submit
                label={getLocalizedText('확인')}
                loading={false}
              />
            </Authentication.Form>
          </>
        );
      case 'reset':
        return (
          <>
            <Authentication.Title label={getLocalizedText('비밀번호 재설정')} />
            <Authentication.Form onSubmit={handleResetPassword}>
              <TextField
                key="password"
                placeholder={getLocalizedText('새 비밀번호')}
                type="password"
                icon="lock"
                value={state.password}
                name="password"
                onChange={handleChange}
                ref={passwordRef}
                required
              />
              <TextField
                key="reconfirmPassword"
                placeholder={getLocalizedText('새 비밀번호 확인')}
                type="password"
                icon="lock"
                value={state.reconfirmPassword}
                name="reconfirmPassword"
                onChange={handleChange}
                required
              />
              <HelperText>{state.errorMessage}</HelperText>
              <Authentication.Submit
                label={getLocalizedText('변경하기')}
                loading={false}
              />
            </Authentication.Form>
          </>
        );
      case 'complete':
        return (
          <div className="authentication-complete">
            <h1>{getLocalizedText('비밀번호 재설정 완료')}</h1>
          </div>
        );
      default:
        return (
          <>
            <Authentication.Title label={getLocalizedText('비밀번호 찾기')} />
            <Authentication.Form onSubmit={handleSendCode}>
              <TextField
                key="id"
                placeholder={getLocalizedText('아이디')}
                icon="person"
                value={state.id}
                name="id"
                onChange={handleChange}
                required
              />
              <TextField
                key="mail"
                placeholder={getLocalizedText('개인메일')}
                type="email"
                icon="mail"
                value={state.email}
                name="email"
                onChange={handleChange}
                required
              />
              <HelperText
                text={getLocalizedText(
                  '마이페이지 사용자정보에 등록된 개인메일',
                )}
              />
              <Authentication.Submit
                label={getLocalizedText('인증번호 발송')}
              />
            </Authentication.Form>
          </>
        );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSendCode = () => {
    setState((prevState) => ({
      ...prevState,
      view: 'code',
    }));

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      codeRef.current?.focus();
    }, 10);
  };

  const handleCheckCode = () => {
    setState((prevState) => ({
      ...prevState,
      view: 'reset',
    }));

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      passwordRef.current?.focus();
    }, 10);
  };

  const handleResetPassword = () => {
    if (state.password !== state.reconfirmPassword) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: getLocalizedText(
          '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
        ),
      }));
    } else {
      // 정상처리
      setState((prevState) => ({
        ...prevState,
        view: 'complete',
      }));
      if (timerRef.current) clearTimeout(timerRef.current);
    }
  };

  const handleGoLogin = () => {
    go('/signin');
  };

  return (
    <Authentication>
      {getContent()}
      <Authentication.Link
        label={getLocalizedText('로그인 페이지로 이동')}
        onClick={handleGoLogin}
      />
    </Authentication>
  );
}

export default Password;
