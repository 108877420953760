import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import CustomDatePicker from '../../../../components/date/CustomDatePicker';
import Dialog from '../../../../components/dialog/Dialog';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import Menu from '../../../../components/menu/Menu';
import PostWrite from '../../../../components/post/PostWrite';
import DropMenu from '../../../../components/selectField/DropMenu';
import SelectField from '../../../../components/selectField/SelectField';
import TextField from '../../../../components/textfield/TextField';
import {
  DirectoryTreeItem,
  getDirectoryTreeId,
} from '../../../../components/tree/DirectoryTree';
import Tree from '../../../../components/tree/Tree';
import { DirectoryType } from '../../../../groupware-common/types';
import { IconType } from '../../../../groupware-common/types/icon';
import { getQueryParams, hangul } from '../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';
import DirectoryMenuTreeContainer from '../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../stores/resources';
import { summaryType } from '../../../../components/repeatDialog/DetailRepeatDialog';
import { makeTreeData } from '../../root/resource/common/ResourceDrawer';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

/** 예약 상태 */
function formatReservationStatus(status: number) {
  switch (status) {
    case 0:
      return getLocalizedText('승인대기');
    case 1:
      return getLocalizedText('예약완료');
    case 2:
      return getLocalizedText('반려');
    default:
      return '';
  }
}

function formatReservationTheme(status: string) {
  switch (status) {
    case getLocalizedText('승인대기'):
      return 'primary';
    case getLocalizedText('반려'):
      return 'error';
    case getLocalizedText('예약완료'):
      return 'success';
    default:
      return undefined;
  }
}

function formatRentalTheme(takeback: boolean) {
  if (takeback) return 'success';
  return 'primary';
}

function ResourceAdminLookupDialog(props: {
  isDirectorySelected: boolean;
  pathname: string;
  search: string;
  hash: string;
  itemId: number;
  itemName: string;
  reservationStatus: number;
  date: {
    start: string;
    end: string;
  };
}): JSX.Element {
  const {
    isDirectorySelected,
    pathname,
    search,
    hash,
    itemId,
    itemName,
    reservationStatus,
  } = props;
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);

  const principal = useSelector((state: RootState) => state.session.principal);
  const display = useSelector((state: RootState) => state.session.display);
  const directory = useDirectory();
  const data = useSelector((state: RootState) => state.resource.resources.view);
  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.list,
  );
  const item = items.find((a) => a.id === itemId);

  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }

  const initialState = data
    ? {
        itemId,
        itemName,
        view: true,
        update: false,
        delete: false,
        start: new Date(props.date.start),
        end: new Date(props.date.end),
        isPopupVisible: false,
        reservationStatus: formatReservationStatus(reservationStatus),
        isTakeback: item && item.useRental ? data.isTakeback : undefined,
        title: data.name,
        remark: data.remark,
        summary: data.repeat
          ? summaryType(timezoneDate(data.startDateTime), {
              frequency: data.repeat.frequency,
              cycle: data.repeat.cycle.toString(),
              repeatDays: data.repeat.repeatDays,
              monthRepeatStandard: data.repeat.monthRepeatStandard,
              endType:
                data.repeat.endDate !== '9999-12-31'
                  ? getLocalizedText('반복 종료일')
                  : getLocalizedText('계속 반복'),
              endDay: data.repeat.endDate,
              endCount: 0,
            })
          : undefined,
        alarms: data.alarms,
        sharers: data.sharers,
        resourceMenuPoint: undefined,
        employeeId: data.employeeId,
        directoryMenuPoint: undefined,
      }
    : {
        itemId,
        itemName,
        view: false,
        update: false,
        delete: false,
        start: new Date(props.date.start),
        end: new Date(props.date.end),
        isPopupVisible: false,
        reservationStatus: formatReservationStatus(reservationStatus),
        title: '',
        remark: '',
        summary: undefined,
        alarms: [],
        sharers: [],
        resourceMenuPoint: undefined,
        employeeId: principal.employeeId,
        directoryMenuPoint: undefined,
      };

  const [state, setState] = useState<{
    itemId: number;
    itemName: string;
    view: boolean; // 보기 모드.
    delete: boolean; // 삭제 유무.
    update: boolean; // 수정 유무.
    start: Date;
    end: Date;
    isPopupVisible: boolean;
    reservationStatus: string; // 예약 상태.
    isTakeback?: boolean; // 반납 유무.
    title: string;
    remark: string;
    summary?: {
      one: string;
      two: string;
      three: string;
    };
    alarms: {
      type: string; // mail | alarm
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
    }[];
    sharers: {
      companyId: number;
      reservationId: number;
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      updateAt: string;
    }[];
    resourceMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    employeeId: number;
    directoryMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(initialState);
  const [validation, setValidation] = useState('');

  const alarmTypeData = [
    { value: 'NONE', label: '- 선택 -' },
    { value: 'ALARM', label: getLocalizedText('알람') },
    { value: 'MAIL', label: getLocalizedText('메일') },
  ];
  const timeTypeData = [
    { value: 'MINUTE', label: getLocalizedText('분전') },
    { value: 'HOUR', label: getLocalizedText('시간전') },
    { value: 'DAY', label: getLocalizedText('일전') },
    { value: 'WEEK', label: getLocalizedText('주전') },
  ];

  /** 예약명 변경. */
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  /** 날짜 변경. */
  const handleChangeDate = (date: Date | null, type: string) => {
    if (date === null) return;
    date.setSeconds(0);
    if (type === 'start') {
      let { end } = state;
      if (end <= date) {
        end = new Date(date);
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
        end.setSeconds(0);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      setState((prev) => ({
        ...prev,
        start: date < prev.start ? date : prev.start,
        end: date,
      }));
    }
  };

  /** 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const { start, end } = state;
    const newTime = Number(value.split(':')[0]);
    const newMinute = Number(value.split(':')[1]);
    if (type === 'start') {
      const date = new Date(start);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (end <= date) {
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      const date = new Date(end);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (date <= start) {
        start.setHours(date.getHours());
        start.setMinutes(date.getMinutes() - 30);
      }
      setState((prev) => ({ ...prev, start, end: date }));
    }
  };

  /** 신청 사유 변경. */
  const handleChangeRemark = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      remark: event.target.value,
    }));
  };

  /** 예약자 조직도 오픈. */
  const handleReservationMenuToggle = (event?: React.MouseEvent) => {
    const { directoryMenuPoint } = state;
    if (event !== undefined && directoryMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        directoryMenuPoint: { x, y, width, height },
      }));
    } else setState((prev) => ({ ...prev, directoryMenuPoint: undefined }));
  };

  /** 예약자 선택. (대리 예약.) */
  const handleReservationNameSelected = (arg: {
    item: DirectoryTreeItem;
    paths: {
      type: DirectoryType;
      id: number;
      name: string;
      treeId: string;
      text: string;
    }[];
  }) => {
    if (arg.item.extra.type === 'employee') {
      const parseData = arg.item.id.split('_');
      setState((prev) => ({ ...prev, employeeId: Number(parseData[2]) }));
    }
    handleReservationMenuToggle();
  };

  /** 대화상자 닫기. */
  const handleDialogClose = () => {
    dispatch(resourcesActions.reservationViewClear());
    dispatch(sessionActions.setDialog());
  };

  /** 자원명 드롭메뉴 클릭. */
  const handleDropClick = (event?: React.MouseEvent) => {
    const { resourceMenuPoint } = state;
    if (event !== undefined && resourceMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        resourceMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        resourceMenuPoint: undefined,
      }));
    }
  };

  /** 자원명 선택. */
  const handleDropItemClick = (
    id: number,
    parentId?: number,
    icon?: IconType,
  ) => {
    if (icon === ('folder' as const)) return;
    const name = items.find((a) => a.id === id)?.name ?? '';
    setState((prev) => ({
      ...prev,
      itemId: id,
      itemName: name,
      resourceMenuPoint: undefined,
    }));
  };

  /** 예약 상세입력 페이지로 이동. */
  const handleDetail = () => {
    const queryWord = {
      start: dateFormat(state.start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(state.end, 'yyyy-MM-DD HH:mm'),
      employeeId: state.employeeId,
      title: state.title,
      remark: state.remark,
      originStart: dateFormat(props.date.start, 'yyyy-MM-DD HH:mm'),
      originEnd: dateFormat(props.date.end, 'yyyy-MM-DD HH:mm'),
      repeatType: data && state.update ? 'all' : undefined,
      isDirectorySelected,
    };
    queryParams.contentMode = 'create';
    queryParams.id = state.itemId;
    queryParams.queryWord = JSON.stringify(queryWord);
    queryParams.dialogType = undefined;
    dispatch(
      sessionActions.setRoute({
        pathname,
        search: getQueryParams(queryParams),
        hash,
      }),
    );
  };

  /** 필수 입력값 확인 이벤트. */
  const handleEssential = () => {
    if (state.itemId === 0 || state.itemName === '') {
      setValidation(() => getLocalizedText('자원 아이템을 선택하세요.'));
      return;
    }
    if (state.title.trim() === '')
      setValidation(getLocalizedText('제목을 입력하세요.'));
  };

  /** 저장 */
  const handleSave = () => {
    delete queryParams.dialogType;
    delete queryParams.pageNo;
    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const route = { pathname, search: getQueryParams(queryParams) };
    const arg = {
      itemId: state.itemId,
      employeeId: state.employeeId,
      name: state.title,
      remark: state.remark,
      startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      sharers: [],
      alarms: [],
    };
    dispatch(resourcesActions.saveReservation({ data: arg })).then((result) => {
      if ((result as { error?: string }).error === undefined)
        dispatch(
          resourcesActions.adminReservationList({
            search: getQueryParams(queryParams),
            isDirectorySelected,
            route,
          }),
        );
    });
  };

  /** 수정 */
  const handleUpdate = () => {
    if (!data) return;
    delete queryParams.dialogType;
    if (state.update) delete queryParams.pageNo;
    const route = { pathname, search: getQueryParams(queryParams) };
    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const startDateTime = moment(data.startDateTime).isSame(start)
      ? undefined
      : dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    const endDateTime = moment(data.endDateTime).isSame(end)
      ? undefined
      : dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    // 반복 예약 수정
    if (state.update) {
      const arg = {
        id: data.id,
        itemId: state.itemId,
        employeeId: state.employeeId,
        name: state.title,
        remark: data.remark === state.remark ? undefined : state.remark,
        startDateTime,
        endDateTime,
        updateAt: data.updateAt,
      };
      dispatch(
        resourcesActions.updateRepeatReservation({
          type: 'all',
          data: arg,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined)
          dispatch(
            resourcesActions.adminReservationList({
              search: getQueryParams(queryParams),
              isDirectorySelected,
              route,
            }),
          );
      });
    }
    // 일반 예약 수정
    else {
      const arg = {
        id: data.id,
        itemId: state.itemId,
        employeeId: principal.employeeId,
        name: state.title,
        remark: data.remark === state.remark ? undefined : state.remark,
        startDateTime,
        endDateTime,
        updateAt: data.updateAt,
      };
      dispatch(resourcesActions.updateReservation({ data: arg })).then(
        (result) => {
          if ((result as { error?: string }).error === undefined)
            dispatch(
              resourcesActions.adminReservationList({
                search: getQueryParams(queryParams),
                isDirectorySelected,
                route,
              }),
            );
        },
      );
    }
  };

  /** 예약 삭제 */
  const handleDelete = (arg: {
    isRepeat: boolean; // 반복 예약 유무
  }) => {
    if (!data) return;
    const start = initialDate(state.start);
    delete queryParams.dialogType;
    delete queryParams.pageNo;
    const route = { pathname, search: getQueryParams(queryParams) };
    const { isRepeat } = arg;
    if (isRepeat) {
      const param = {
        type: 'all',
        id: data.id,
        startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
        updateAt: data.updateAt,
      };
      dispatch(resourcesActions.deleteRepeatReservation({ data: param })).then(
        (result) => {
          if ((result as { error?: string }).error === undefined)
            dispatch(
              resourcesActions.adminReservationList({
                search: getQueryParams(queryParams),
                isDirectorySelected,
                route,
              }),
            );
        },
      );
    } else {
      dispatch(
        resourcesActions.deleteReservation({
          id: data.id,
          updateAt: data.updateAt,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined)
          dispatch(
            resourcesActions.adminReservationList({
              search: getQueryParams(queryParams),
              isDirectorySelected,
              route,
            }),
          );
      });
    }
  };

  /** 트리 아이템 생성. */
  const treeData = useMemo(() => {
    const result = [
      ...folders.map((a) => {
        return {
          id: a.id,
          parentId: a.parentId,
          text: a.name,
          strings: hangul.d(a.name),
          icon: 'folder' as const,
          seq: a.seq,
        };
      }),
      ...items.map((a) => {
        return {
          id: a.id,
          parentId: a.folderId,
          text: a.name,
          strings: hangul.d(a.name),
          // eslint-disable-next-line prettier/prettier
          icon: a.useApprove ? 'document-check' as const : 'document-clock' as const,
          seq: a.seq,
        };
      }),
    ];
    return makeTreeData(result);
  }, [folders, items]);

  const renderDialog = () => {
    if (state.delete) {
      if (state.summary)
        return (
          <DeleteConfirmation
            onCancel={() =>
              setState((prev) => ({
                ...prev,
                delete: false,
              }))
            }
            onSubmit={() => handleDelete({ isRepeat: true })}
            message={getLocalizedText('반복 예약 삭제 시 모든 예약이 삭제')}
          >
            <strong>&apos;{state.title}&apos;</strong>
            {getLocalizedText('의 모든 예약을 정말 삭제하시겠습니까?')}
          </DeleteConfirmation>
        );
      return (
        <DeleteConfirmation
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              delete: false,
            }))
          }
          onSubmit={() => handleDelete({ isRepeat: false })}
        >
          <strong>&apos;{state.title}&apos;</strong> 예약을 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }
    if (state.update && state.summary) {
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              <p>
                <strong>&apos;{state.title}&apos;</strong>
                {getLocalizedText('의 모든 예약을 정말 수정하시겠습니까?')}
              </p>
              <p>
                {getLocalizedText('반복 예약 수정 시 모든 예약이 수정됩니다.')}
              </p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text={getLocalizedText('취소')}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  update: false,
                }))
              }
            />
            <Button
              text={getLocalizedText('수정')}
              variant="contained"
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  view: false,
                  start: data ? timezoneDate(data.startDateTime) : prev.start,
                  end: data ? timezoneDate(data.endDateTime) : prev.end,
                }))
              }
            />
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  };

  /** 작성자 정보 변경. */
  const writer = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: state.employeeId,
  });
  const startTime = state.start.getHours().toString().padStart(2, '0');
  const startMinute = state.start.getMinutes().toString().padStart(2, '0');
  const endTime = state.end.getHours().toString().padStart(2, '0');
  const endMinute = state.end.getMinutes().toString().padStart(2, '0');

  if (state.view)
    return (
      <Dialog onClose={handleDialogClose}>
        <DialogHeader>
          <DialogTitle>{getLocalizedText('예약보기')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            <PostWrite.Item title={getLocalizedText('자원명')}>
              <span style={{ fontSize: '15px', fontWeight: 700 }}>
                {state.itemName}
              </span>
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('제목')}>
              {state.title}
              <span style={{ marginLeft: '5px' }}>
                <Chip
                  className="cell-tag"
                  label={state.reservationStatus}
                  theme={formatReservationTheme(state.reservationStatus)}
                  size="xs"
                />
              </span>
              {state.isTakeback !== undefined && (
                <span style={{ marginLeft: '5px' }}>
                  <Chip
                    className="cell-tag"
                    label={
                      state.isTakeback
                        ? getLocalizedText('반납')
                        : getLocalizedText('대여')
                    }
                    theme={formatRentalTheme(state.isTakeback)}
                    size="xs"
                  />
                </span>
              )}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('예약자')}>
              {`${writer.organizationName}/${writer.employeeName}`}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사용기간')}>
              {`${dateFormat(state.start, 'yyyy-MM-DD HH:mm')} ~ ${dateFormat(
                state.end,
                'yyyy-MM-DD HH:mm',
              )}`}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('신청사유')}>
              {state.remark}
            </PostWrite.Item>
            {data && data.repeat && (
              <PostWrite.Item title={getLocalizedText('반복예약')}>
                {data.repeat.frequency === 1
                  ? `${state.summary?.one}${state.summary?.three}`
                  : `${state.summary?.one}${state.summary?.two}${state.summary?.three}`}
              </PostWrite.Item>
            )}
            {state.alarms.length > 0 && (
              <PostWrite.Item title={getLocalizedText('알림')}>
                {state.alarms.map((a) => {
                  const alarmValue =
                    alarmTypeData.find((x) => x.value === a.type)?.label ?? '';
                  const timeValue =
                    timeTypeData.find((x) => x.value === a.timeUnit)?.label ??
                    '';
                  return (
                    <div style={{ marginBottom: '2px' }}>
                      {`${alarmValue} / ${a.ammount}${timeValue}`}
                    </div>
                  );
                })}
              </PostWrite.Item>
            )}
            {state.sharers.length > 0 && (
              <PostWrite.Item title={getLocalizedText('공유자')}>
                <ChipGroup>
                  {state.sharers.map(
                    ({ referenceCompanyId, referenceId, referenceType }) => {
                      if (referenceType !== 3)
                        return (
                          <Chip
                            key={`${referenceCompanyId}/${referenceId}`}
                            label={getOrganizationName(
                              referenceCompanyId,
                              referenceId,
                            )}
                            etc={getCompanyName(referenceCompanyId)}
                            icon={
                              referenceType === 2
                                ? ('sitemap-fill' as const)
                                : ('company' as const)
                            }
                          />
                        );
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId: referenceCompanyId,
                        employeeId: referenceId,
                      });
                      return (
                        <Chip
                          key={`${referenceCompanyId}/${referenceId}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                        />
                      );
                    },
                  )}
                </ChipGroup>
              </PostWrite.Item>
            )}
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          {/* 예약제인 경우만 수정 가능. */}
          {!items.find(({ id }) => id === data?.itemId)?.useApprove && (
            <Button
              text={getLocalizedText('수정')}
              variant="outlined"
              onClick={
                state.summary
                  ? () =>
                      setState((prev) => ({
                        ...prev,
                        update: true,
                      }))
                  : () =>
                      setState((prev) => ({
                        ...prev,
                        view: false,
                      }))
              }
            />
          )}
          <Button
            text={getLocalizedText('삭제')}
            variant="contained"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                delete: true,
              }))
            }
          />
        </DialogFooter>
        {renderDialog()}
      </Dialog>
    );

  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>{getLocalizedText('예약설정')}</DialogTitle>
      </DialogHeader>
      <DialogBody noneOverFlow>
        <PostWrite>
          <PostWrite.Item required title={getLocalizedText('자원명')}>
            <DropMenu
              value={state.itemName}
              onClick={
                data
                  ? () =>
                      setValidation(
                        getLocalizedText(
                          '예약 수정 시 자원 수정은 불가능합니다.',
                        ),
                      )
                  : handleDropClick
              }
              label={getLocalizedText('자원 선택')}
            />
            {state.resourceMenuPoint && (
              <Menu
                point={state.resourceMenuPoint}
                onClose={() =>
                  setState((prev) => ({
                    ...prev,
                    resourceMenuPoint: undefined,
                  }))
                }
              >
                <div className="ui-organization-select">
                  <div className="body-panel">
                    <Tree items={treeData} onItemClick={handleDropItemClick} />
                  </div>
                </div>
              </Menu>
            )}
          </PostWrite.Item>
          <PostWrite.Item required title={getLocalizedText('제목')}>
            <TextField value={state.title} onChange={handleChangeTitle} />
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('예약자')}>
            <DropMenu
              value={`${writer.organizationName}/${writer.employeeName}`}
              label={getLocalizedText('예약자 선택')}
              pressed={state.directoryMenuPoint !== undefined}
              onClick={handleReservationMenuToggle}
            />
            {state.directoryMenuPoint && (
              <DirectoryMenuTreeContainer
                deduplication
                point={state.directoryMenuPoint}
                selectedId={getDirectoryTreeId(
                  writer.companyId,
                  writer.organizationId,
                  writer.employeeId,
                )}
                typeToFilter="employee"
                onItemClick={handleReservationNameSelected}
                onClose={handleReservationMenuToggle}
              />
            )}
          </PostWrite.Item>
          <PostWrite.Item required title={getLocalizedText('사용기간')}>
            <div
              style={{
                display: 'inline-flex',
                flexDirection: display === 'phone' ? 'column' : undefined,
              }}
            >
              {display === 'phone' ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '5px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      시작
                    </span>
                    <CustomDatePicker
                      controlClassName={
                        state.summary
                          ? 'disabled positionToTop'
                          : 'positionToTop'
                      }
                      disabled={!!state.summary}
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={state.start}
                      startDate={state.start}
                      endDate={state.end}
                      selectsStart
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'start')
                      }
                    />
                    <SelectField
                      data={bottom}
                      value={`${startTime}:${startMinute}`}
                      onChange={(value) => handleChangeTime(value, 'start')}
                    />
                  </div>
                  <div
                    style={
                      display === 'phone'
                        ? {
                            display: 'flex',
                            flexDirection: 'row',
                          }
                        : undefined
                    }
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      종료
                    </span>
                    <CustomDatePicker
                      controlClassName="positionToTop"
                      width={110}
                      dateFormat="yyyy-MM-dd"
                      selected={state.end}
                      startDate={state.start}
                      endDate={state.end}
                      minDate={state.start}
                      selectsEnd
                      onChange={(date: Date | null) =>
                        handleChangeDate(date, 'end')
                      }
                    />
                    <SelectField
                      data={bottom}
                      value={`${endTime}:${endMinute}`}
                      onChange={(value) => handleChangeTime(value, 'end')}
                    />
                  </div>
                </>
              ) : (
                <>
                  <CustomDatePicker
                    controlClassName={state.summary ? 'disabled' : undefined}
                    disabled={!!state.summary}
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.start}
                    startDate={state.start}
                    endDate={state.end}
                    selectsStart
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'start')
                    }
                  />
                  <SelectField
                    data={bottom}
                    value={`${startTime}:${startMinute}`}
                    onChange={(value) => handleChangeTime(value, 'start')}
                  />
                  <span style={{ margin: '2px 10px' }}> ~ </span>
                  <CustomDatePicker
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.end}
                    startDate={state.start}
                    endDate={state.end}
                    minDate={state.start}
                    selectsEnd
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'end')
                    }
                  />
                  <SelectField
                    data={bottom}
                    value={`${endTime}:${endMinute}`}
                    onChange={(value) => handleChangeTime(value, 'end')}
                  />
                </>
              )}
            </div>
          </PostWrite.Item>
          <PostWrite.Item title={getLocalizedText('신청사유')}>
            <TextField
              value={state.remark}
              multiline
              rows={2}
              onChange={handleChangeRemark}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          text={getLocalizedText('자세히')}
          variant="outlined"
          onClick={handleDetail}
        />
        {state.itemId === 0 || state.title.trim() === '' ? (
          <Button
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={handleEssential}
          />
        ) : (
          <Button
            noDuplication
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={data ? handleUpdate : handleSave}
          />
        )}
      </DialogFooter>
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </Dialog>
  );
}

export default ResourceAdminLookupDialog;
