import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../app/store';
import {
  getParentItems,
  getPathParams,
  go,
  parseModule,
} from '../../../groupware-common/utils';
import DefaultMenu from './DefaultMenu';
import { IconType } from '../../../groupware-common/types/icon';
import { moduleIcon } from '../../../groupware-common/utils/ui';
import { sessionActions } from '../../stores/session';
import Button from '../../../components/button/Button';
import Divider from '../../../components/divider/Divider';
import { Module, ModuleItem } from '../../../groupware-common/types';
import { formatModuleName } from '../../../groupware-service/stores/module';
import { ModuleType } from '../../../groupware-service/apis/module';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

interface MenuItem {
  isService?: boolean; // 서비스관련 메뉴인 경우. (ex. 서비스, 보안, ,,,)
  id: string;
  parentId: string;
  text: string;
  icon?: IconType;
  link?: boolean;
  isExpand?: boolean;
}

function NavigationDrawer(props: { pathname: string; hash: string }) {
  // console.log(`${NavigationDrawer.name}.render(props)`, props);

  const pathParams = getPathParams('/*/:module/:menuId', props.pathname);
  const module =
    pathParams.module !== undefined ? `adminconsole/${pathParams.module}` : '';
  const { menuId = '_' } = pathParams;

  const dispatch = useAppDispatch();

  // 세션 리소스 - teams: 팀즈, web: 웹
  const resource = useSelector((state: RootState) => state.session.resource);
  const isSessionLogin = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );
  // 개발 환경 유무.
  const isDevelopment =
    window.location.hostname === 'localhost' || !isSessionLogin;
  const modules = useSelector((state: RootState) => state.session.modules);
  const adminModules = [
    // eslint-disable-next-line prettier/prettier
    { code: 'directory', id: 'DIRECTORY', seq: 0, name: formatModuleName('DIRECTORY'), } as ModuleItem,
    ...useSelector(
      (state: RootState) => state.service.menu.adminconsole.menus.list,
    ),
  ];
  const providers = useSelector(
    (state: RootState) => state.systemlink.preferences.provider,
  );
  const roles = useSelector(
    (state: RootState) => state.session.principal.roles,
  );
  /** 제공하는 모듈 중 접근 가능한 모듈 리스트
   * 최상위 관리자 - 모든 모듈 접근 가능
   *  최상위 관리자가 아닌 경우 - 관리자 권한이 있는 모듈만 접근 가능.
   */
  let modulesToUse: ModuleItem[] = [];
  if (resource === 'teams') modulesToUse = [...modules];
  else
    roles.forEach((a) => {
      if (a === 'ADMIN' && adminModules.length > 0) {
        adminModules.forEach((m) => modulesToUse.push(m));
        return;
      }
      if (a === 'ADMIN' && adminModules.length === 0) {
        modulesToUse = [...modules];
        return;
      }
      modulesToUse.push({
        code: a.split('_')[0].toLowerCase() as Module,
        id: a.split('_')[0],
        name: formatModuleName(a.split('_')[0] as ModuleType),
        seq: 0,
      });
    });
  const syncLoading = useSelector(
    (state: RootState) => state.session.syncLoading,
  );
  const { isExists: isAttendanceExists } = useSelector(
    (state: RootState) => state.attendance.preferences.standard,
  );

  // 서비스 메뉴
  const serviceMenu = useSelector(
    (state: RootState) => state.service.menu.category,
  );
  // 결재 메뉴
  const approvalMenu = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  // 근태 메뉴
  const attendanceMenu = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  // 게시 메뉴
  const boardMenu = useSelector(
    (state: RootState) => state.boards.board.category,
  ).filter((a) => a.type === 'setting');
  // 자원 메뉴
  const resourceMenu = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'setting');
  // 문서 메뉴
  const documentMenu = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'setting');
  // 시스템연동 메뉴
  const systemlinkMenu = useSelector(
    (state: RootState) => state.systemlink.preferences.category,
  );
  // 대시보드 메뉴
  const dashboardMenu = useSelector(
    (state: RootState) => state.dashboard.portals.folder.folders,
  );

  /** 개발 중인 메뉴 아이디 배열. */
  const menuIdsInDevelopment = [
    'adminconsole/directory/group', // 사용자 그룹.
    'adminconsole/service',
    'adminconsole/dashboard',
  ];

  const dayOffSettingMenu: MenuItem[] = isAttendanceExists
    ? [
        {
          id: 'adminconsole/attendance/dayOffSetting',
          text: `${attendanceMenu.find((a) => a.id === 6004)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
        {
          id: 'adminconsole/attendance/dayOffAdjust',
          text: `${attendanceMenu.find((a) => a.id === 6005)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
        {
          id: 'adminconsole/attendance/dayOffStatus',
          text: `${attendanceMenu.find((a) => a.id === 6006)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
      ]
    : [
        {
          id: 'adminconsole/attendance/dayOffSetting',
          text: `${attendanceMenu.find((a) => a.id === 6014)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
      ];

  const services: MenuItem[] = [
    {
      isService: true,
      id: 'adminconsole/service',
      parentId: '',
      text: '서비스',
      icon: 'cog-fill',
    },
    {
      isService: true,
      id: 'adminconsole/service/_',
      parentId: 'adminconsole/service',
      text: `${serviceMenu.find((a) => a.id === 7001)?.name}`,
      link: true,
    },
    {
      isService: true,
      id: 'adminconsole/service/menu',
      parentId: 'adminconsole/service',
      text: `${serviceMenu.find((a) => a.id === 7002)?.name}`,
      link: true,
    },
    {
      isService: true,
      id: 'adminconsole/service/link',
      parentId: 'adminconsole/service',
      text: '링크메뉴 관리',
      link: true,
    },
    {
      isService: true,
      id: 'adminconsole/authentication',
      parentId: '',
      text: '보안',
      icon: 'lock-fill',
    },
    {
      isService: true,
      id: 'adminconsole/authentication/login/setting',
      parentId: 'adminconsole/authentication',
      text: '로그인 관리',
    },
    {
      isService: true,
      id: 'adminconsole/authentication/login',
      text: '기본설정',
      parentId: 'adminconsole/authentication/login/setting',
      link: true,
    },
    {
      isService: true,
      id: 'adminconsole/authentication/blockedUser',
      text: '차단된 사용자 관리',
      parentId: 'adminconsole/authentication/login/setting',
      link: true,
    },
    // {
    // isService: true,
    //   id: 'adminconsole/authentication/network',
    //   parentId: 'adminconsole/authentication',
    //   text: '네트워크 관리',
    //   link: true,
    // },
    // {
    // isService: true,
    //   id: 'adminconsole/authentication/mobile',
    //   parentId: 'adminconsole/authentication',
    //   text: '모바일 관리',
    //   link: true,
    // },
    { id: 'adminconsole/test', parentId: '', text: '테스트', link: true },
  ];

  const [state, setState] = useState<{
    menus: MenuItem[];
    selectedId: string;
  }>(() => {
    let menus: MenuItem[] = [];

    // 메뉴 전체 리스트.
    const menuLists: Record<string, MenuItem[]> = {
      DIRECTORY: [
        {
          id: 'adminconsole/directory',
          parentId: '',
          text: '조직도',
          icon: 'sitemap-fill',
        },
        {
          id: 'adminconsole/directory/_',
          parentId: 'adminconsole/directory',
          text: '기본설정',
          link: true,
        },
        {
          id: 'adminconsole/directory/organizationchart',
          parentId: 'adminconsole/directory',
          text: '조직도',
          link: true,
        },
        {
          id: 'adminconsole/directory/jobposition',
          parentId: 'adminconsole/directory',
          text: '직위',
          link: true,
        },
        {
          id: 'adminconsole/directory/jobduty',
          parentId: 'adminconsole/directory',
          text: '직책',
          link: true,
        },
        {
          id: 'adminconsole/directory/group',
          parentId: 'adminconsole/directory',
          text: '사용자 그룹',
          link: true,
        },
      ],
      APPROVAL: [
        {
          id: 'adminconsole/approval',
          text: '결재',
          parentId: '',
          icon: moduleIcon('approval'),
        },
        {
          id: 'adminconsole/approval/_',
          text: `${approvalMenu.find((a) => a.id === 6001)?.name}`,
          parentId: 'adminconsole/approval',
          link: true,
        },
        {
          id: 'adminconsole/approval/document/management',
          text: '결재문서 관리',
          parentId: 'adminconsole/approval',
        },
        {
          id: 'adminconsole/approval/document',
          text: `${approvalMenu.find((a) => a.id === 6002)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/arbitrarydecision',
          text: `${approvalMenu.find((a) => a.id === 6003)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/approvermacro',
          text: `${approvalMenu.find((a) => a.id === 6004)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/approverproxy',
          text: `${approvalMenu.find((a) => a.id === 6005)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/referencepermission',
          text: `${approvalMenu.find((a) => a.id === 6010)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/viewpermission',
          text: `${approvalMenu.find((a) => a.id === 6011)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/trash',
          text: `${approvalMenu.find((a) => a.id === 6007)?.name}`,
          parentId: 'adminconsole/approval/document/management',
          link: true,
        },
        {
          id: 'adminconsole/approval/form',
          text: `${approvalMenu.find((a) => a.id === 6008)?.name}`,
          parentId: 'adminconsole/approval',
          link: true,
        },
        {
          id: 'adminconsole/approval/work',
          text: `${approvalMenu.find((a) => a.id === 6009)?.name}`,
          parentId: 'adminconsole/approval',
          link: true,
        },
        {
          id: 'adminconsole/approval/archives',
          text: `${approvalMenu.find((a) => a.id === 6012)?.name}`,
          parentId: 'adminconsole/approval',
          link: true,
        },
      ],
      ATTENDANCE: [
        {
          id: 'adminconsole/attendance',
          text: '근태',
          parentId: '',
          icon: moduleIcon('attendance'),
        },
        {
          id: 'adminconsole/attendance/_',
          text: `${attendanceMenu.find((a) => a.id === 6001)?.name}`,
          parentId: 'adminconsole/attendance',
          link: true,
        },
        {
          id: 'adminconsole/attendance/detailStatus',
          text: `${attendanceMenu.find((a) => a.id === 6013)?.name}`,
          parentId: 'adminconsole/attendance',
          link: true,
        },
        {
          id: 'adminconsole/attendance/holiday',
          text: '휴일관리',
          parentId: 'adminconsole/attendance',
        },
        {
          id: 'adminconsole/attendance/basicHoliday',
          text: `${attendanceMenu.find((a) => a.id === 6002)?.name}`,
          parentId: 'adminconsole/attendance/holiday',
          link: true,
        },
        {
          id: 'adminconsole/attendance/holidaysave',
          text: `${attendanceMenu.find((a) => a.id === 6003)?.name}`,
          parentId: 'adminconsole/attendance/holiday',
          link: true,
        },
        {
          id: 'adminconsole/attendance/dayOff',
          text: '연차관리',
          parentId: 'adminconsole/attendance',
        },
        ...dayOffSettingMenu,
        {
          id: 'adminconsole/attendance/sendNotice',
          text: '연차사용촉진알림',
          parentId: 'adminconsole/attendance',
        },
        {
          id: 'adminconsole/attendance/noticeSetting',
          text: `${attendanceMenu.find((a) => a.id === 6007)?.name}`,
          parentId: 'adminconsole/attendance/sendNotice',
          link: true,
        },
        {
          id: 'adminconsole/attendance/sendList',
          text: `${attendanceMenu.find((a) => a.id === 6008)?.name}`,
          parentId: 'adminconsole/attendance/sendNotice',
          link: true,
        },
        {
          id: 'adminconsole/attendance/substitute',
          text: '대휴관리',
          parentId: 'adminconsole/attendance',
        },
        {
          id: 'adminconsole/attendance/substituteAdjust',
          text: `${attendanceMenu.find((a) => a.id === 6009)?.name}`,
          parentId: 'adminconsole/attendance/substitute',
          link: true,
        },
        {
          id: 'adminconsole/attendance/substituteStatus',
          text: `${attendanceMenu.find((a) => a.id === 6010)?.name}`,
          parentId: 'adminconsole/attendance/substitute',
          link: true,
        },
        {
          id: 'adminconsole/attendance/form',
          text: `${attendanceMenu.find((a) => a.id === 6011)?.name}`,
          parentId: 'adminconsole/attendance',
          link: true,
        },
        {
          id: 'adminconsole/attendance/work',
          text: `${attendanceMenu.find((a) => a.id === 6012)?.name}`,
          parentId: 'adminconsole/attendance',
          link: true,
        },
      ],
      BOARD: [
        {
          id: 'adminconsole/board',
          parentId: '',
          text: '게시',
          icon: 'file-fill',
        },
        {
          id: 'adminconsole/board/_',
          text: `${boardMenu.find((a) => a.id === 6001)?.name}`,
          parentId: 'adminconsole/board',
          link: true,
        },
        {
          id: 'adminconsole/board/folderbox',
          parentId: 'adminconsole/board',
          text: `${boardMenu.find((a) => a.id === 6002)?.name}`,
          link: true,
        },
        {
          id: 'adminconsole/board/authority',
          parentId: 'adminconsole/board',
          text: `${boardMenu.find((a) => a.id === 6003)?.name}`,
          link: true,
        },
        {
          id: 'adminconsole/board/trash',
          text: `${boardMenu.find((a) => a.id === 6004)?.name}`,
          parentId: 'adminconsole/board',
          link: true,
        },
        {
          id: 'adminconsole/board/form',
          text: `${boardMenu.find((a) => a.id === 6005)?.name}`,
          parentId: 'adminconsole/board',
          link: true,
        },
      ],
      DOCUMENT: [
        {
          id: 'adminconsole/document',
          text: '문서',
          parentId: '',
          icon: moduleIcon('document'),
        },
        {
          id: 'adminconsole/document/_',
          text: `${documentMenu.find((a) => a.id === 6001)?.name}`,
          parentId: 'adminconsole/document',
          link: true,
        },
        {
          id: 'adminconsole/document/folderbox',
          parentId: 'adminconsole/document',
          text: `${documentMenu.find((a) => a.id === 6002)?.name}`,
          link: true,
        },
        {
          id: 'adminconsole/document/authority',
          parentId: 'adminconsole/document',
          text: `${documentMenu.find((a) => a.id === 6003)?.name}`,
          link: true,
        },
        {
          id: 'adminconsole/document/trash',
          text: `${documentMenu.find((a) => a.id === 6004)?.name}`,
          parentId: 'adminconsole/document',
          link: true,
        },
        {
          id: 'adminconsole/document/form',
          text: `${documentMenu.find((a) => a.id === 6005)?.name}`,
          parentId: 'adminconsole/document',
          link: true,
        },
        {
          id: 'adminconsole/document/checkout',
          text: `${documentMenu.find((a) => a.id === 6006)?.name}`,
          parentId: 'adminconsole/document',
          link: true,
        },
      ],
      CALENDAR: [
        {
          id: 'adminconsole/calendar',
          text: '일정',
          parentId: '',
          icon: moduleIcon('calendar'),
        },
        {
          id: 'adminconsole/calendar/_',
          text: '기본설정',
          parentId: 'adminconsole/calendar',
          link: true,
        },
        {
          id: 'adminconsole/calendar/sub',
          text: '구독 캘린더',
          parentId: 'adminconsole/calendar',
          link: true,
        },
        {
          id: 'adminconsole/calendar/all',
          text: '공유 캘린더',
          parentId: 'adminconsole/calendar',
          link: true,
        },
      ],
      RESOURCE: [
        {
          id: 'adminconsole/resource',
          text: '자원',
          parentId: '',
          icon: moduleIcon('resource'),
        },
        {
          id: 'adminconsole/resource/_',
          text: `${resourceMenu.find((a) => a.id === 6001)?.name}`,
          parentId: 'adminconsole/resource',
          link: true,
        },
        {
          id: 'adminconsole/resource/folder',
          text: `${resourceMenu.find((a) => a.id === 6002)?.name}`,
          parentId: 'adminconsole/resource',
          link: true,
        },
        {
          id: 'adminconsole/resource/reservation',
          text: `${resourceMenu.find((a) => a.id === 6003)?.name}`,
          parentId: 'adminconsole/resource',
          link: true,
        },
      ],
      DASHBOARD: [
        {
          id: 'adminconsole/dashboard',
          parentId: '',
          text: '대시보드',
          icon: 'home-fill',
        },
        {
          id: 'adminconsole/dashboard/_',
          parentId: 'adminconsole/dashboard',
          text: `${dashboardMenu.find((x) => x.id === 6001)?.name}`,
          link: true,
        },
        {
          id: 'adminconsole/dashboard/company',
          parentId: 'adminconsole/dashboard',
          text: `${dashboardMenu.find((x) => x.id === 6002)?.name}`,
          link: true,
        },
      ],
      MAIL: [], // TODO 메일 관리자 메뉴 추가 필요.
    };

    // 메뉴 리스트에서 사용 가능한 모듈만 필터링.
    modulesToUse.forEach((a) => {
      const array = menuLists[a.id];
      if (array) menus.push(...array);
    });

    // 최상위 관리자인 경우 서비스 메뉴 추가.
    if (roles.some((role) => role === 'ADMIN')) menus.unshift(...services);

    // 팀즈 앱에서 실행 모듈만 표시 되도록 설정.
    if (resource === 'teams') {
      menus = menus.filter(({ id }) => {
        if (menuIdsInDevelopment.some((v) => v === id)) return false;
        const moduleId = id.split('/')[1];
        return modulesToUse.find(({ code }) => code === moduleId) !== undefined;
      });
    }
    // 웹에서 사용 설정된 모듈만 표시 되도록 설정. - 개발 환경이 아닌 경우.
    // 서비스 메뉴, 보안 메뉴 보이도록 설정.
    if (!isDevelopment && resource === 'web') {
      menus = menus.filter(({ id }) => {
        const moduleId = id.split('/')[1];
        if (
          moduleId === 'service' ||
          moduleId === 'authentication' ||
          moduleId === 'dashboard'
        )
          return true;
        if (menuIdsInDevelopment.some((v) => v === id)) return false;
        if (moduleId === 'directory') return true;
        return modulesToUse.find(({ code }) => code === moduleId) !== undefined;
      });
    }

    /** 시스템 연동 메뉴 - 서비스 관리자 또는 전체 관리자만 접근 가능. */
    if (
      (providers.approval.length > 0 || providers.directory.length > 0) &&
      roles.some(
        (a) =>
          a === 'ADMIN' || a === 'APPROVAL_ADMIN' || a === 'DIRECTORY_ADMIN',
      )
    ) {
      let isLinkApproval = providers.approval.length > 0;
      let isLinkDirecotry = providers.directory.length > 0;
      let isYLWApproval = providers.approval.find((a) => a === 'YLWSYSTEMEVER');
      let isYLWDirectory = providers.directory.find(
        (a) => a === 'YLWSYSTEMEVER',
      );

      // 팀즈 앱일 경우
      if (resource === 'teams') {
        // 실행 모듈이 전자결재 또는 조직도일 때만 시스템 연동 메뉴 보임.
        isLinkApproval =
          modulesToUse.findIndex((a) => a.code === 'approval') > -1 &&
          isLinkApproval;
        isLinkDirecotry =
          modulesToUse.findIndex((a) => a.code === 'directory') > -1 &&
          isLinkDirecotry;
        isYLWApproval = isLinkApproval === false ? undefined : isYLWApproval;
        isYLWDirectory = isLinkDirecotry === false ? undefined : isYLWDirectory;
      }
      if (isLinkApproval || isLinkDirecotry) {
        menus.push({
          id: 'adminconsole/systemlink',
          text: '시스템 연동',
          parentId: '',
          icon: moduleIcon('systemlink'),
        });
      }

      /** 영림원 암호화 키 세팅 페이지. */
      if (isYLWApproval || isYLWDirectory) {
        menus.push({
          id: `adminconsole/systemlink/systemever`,
          text: `${systemlinkMenu.find((a) => a.id === 6001)?.name}`,
          parentId: 'adminconsole/systemlink',
          link: true,
        });
      }

      /** 결재 구성일 경우. */
      if (
        isLinkApproval &&
        menus.find((a) => a.id === 'adminconsole/approval')
      ) {
        menus.push(
          {
            id: 'adminconsole/systemlink/form',
            text: `${systemlinkMenu.find((a) => a.id === 6002)?.name}`,
            parentId: 'adminconsole/systemlink',
            link: true,
          },
          {
            id: 'adminconsole/systemlink/work',
            text: `${systemlinkMenu.find((a) => a.id === 6003)?.name}`,
            parentId: 'adminconsole/systemlink',
            link: true,
          },
        );
      }
    }
    return {
      menus,
      selectedId: '',
    };
  });

  useEffect(() => {
    if (module === '_') return;

    const selectedId =
      props.hash !== '' &&
      menus.some((v) => v.id === `${module}/${menuId}/${props.hash}`)
        ? `${module}/${menuId}/${props.hash}`
        : `${module}/${menuId}`;

    if (state.selectedId !== selectedId) {
      setState((prev) => ({
        ...prev,
        selectedId,
      }));
    }
  }, [props.pathname, props.hash]);

  useEffect(() => {
    if (state.selectedId === '') return;

    const selectedItem = state.menus.find((x) => x.id === selectedId);
    if (!selectedItem) return;
    const parentItems =
      selectedItem.parentId !== ''
        ? getParentItems(state.menus, selectedId)
        : [];
    if (parentItems.length > 0) {
      setState((prev) => ({
        ...prev,
        menus: prev.menus.map((x) => {
          if (parentItems.findIndex((y) => y.id === x.id) > -1)
            return { ...x, isExpand: true };
          return x;
        }),
      }));
    }
  }, [state.selectedId]);

  const handleMenuToggle = (id: string) => {
    setState((prev) => ({
      ...prev,
      menus: prev.menus.map((x) => {
        if (x.id === id) return { ...x, isExpand: !x.isExpand };
        return { ...x };
      }),
    }));
  };

  const handleLink = (id: string) => {
    const menu = menus.find((x) => x.id === id);
    if (menu?.link === true) {
      const [adminconsole, b] = menu.id.split('/');

      // 모듈 기본 메뉴 경로인지 확인하여 경로 설정.
      const pathname = menu.id.endsWith('/_')
        ? `/${menu.id.slice(0, -2)}`
        : `/${menu.id}`;

      const a = parseModule(`${adminconsole}/${b}`);
      if (a !== undefined) {
        go(pathname);
        dispatch(sessionActions.mobileNav(false));
      }
    }
  };

  const { menus, selectedId } = state;

  return (
    <div className={`admin-header ${syncLoading ? 'loading' : ''}`}>
      <div className="header-title">
        <h1 className="title">{getLocalizedText('관리자 페이지')}</h1>
        <Button
          text={getLocalizedText('사용자 페이지로 이동')}
          iconType
          icon="home"
          className="home"
          onClick={() => {
            if (modules.length === 1) go(`/${modules[0].code}`);
            else go('/');
          }}
        />
      </div>
      <div className="header-nav">
        {menus.some((a) => a.isService) && (
          <>
            <DefaultMenu
              selectedId={selectedId}
              items={menus
                .filter((a) => a.isService)
                .map((a) => ({
                  ...a,
                  text: getLocalizedText(`${a.text}`),
                }))}
              onMenuClick={handleLink}
              onMenuToggle={handleMenuToggle}
            />
            <Divider className="lg" />
          </>
        )}
        <DefaultMenu
          selectedId={selectedId}
          items={menus
            .filter((a) => !a.isService)
            .map((a) => ({
              ...a,
              text: getLocalizedText(`${a.text}`),
            }))}
          onMenuClick={handleLink}
          onMenuToggle={handleMenuToggle}
        />
      </div>
    </div>
  );
}

export default React.memo(NavigationDrawer);
