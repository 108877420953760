/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getI18n } from 'react-i18next';
import { TOptions, StringMap } from 'i18next';
import { apiError } from '../apis/common/v1';
import { Language, NamespaceStrings, NamespaceType } from '../types';
import store from '../../groupware-webapp/app/store';
import { getPathParams } from '.';

type CustomI18nOptions<T> = Partial<T> & {
  ns?: NamespaceType | NamespaceType[];
};

export const RESOURCE_KEY = {
  DIRECTORY: {
    EMPLOYEE: 'directory:employees',
    ORGANIZATION: 'directory:organizations',
    COMPANY: 'directory:companies',
    GROUP: 'directory:groups',
  },
  JOBCLASS: {
    JOBDUTY: 'jobclass:jobduties',
    JOBPOSITION: 'jobclass:jobpositions',
    JOBLANK: 'jobclass:joblank',
  },
};

/** 모듈별 다국어 리소스 파일명 가져오기 */
export const getModuleNamespace = (module: string): NamespaceType => {
  return NamespaceStrings.find((x) => x === module) ?? 'common';
};

/** 지정된 경로의 모듈에서 네임스페이스를 추출합니다. */
export const getPathByNamespace = (): NamespaceType => {
  const { pathname } = store.getState().session.route;
  const pathParams = getPathParams('/:p1/:p2', pathname);
  const moduleId =
    pathParams.p1 === 'adminconsole' ? pathParams.p2 : pathParams.p1;
  if (moduleId) return NamespaceStrings.find((x) => x === moduleId) ?? 'common';
  return 'common';
};

/**
 * i18n 스토어에 데이터를 추가하는 함수입니다.
 * @param data - 언어 코드, 다국어 값.
 * @param namespace - 다국어 파일 이름.
 * @param nameKey - 다국어 키 값.
 */
export function addResourceValue(arg: {
  data:
    | {
        language: string;
        value: string;
      }[]
    | {
        language: string;
        value: string;
      };
  key: string;
  ns?: string;
}): void {
  try {
    const { data, ns } = arg;
    const [k1, k2] = arg.key.split(':');

    const namespace = ns ?? k1;
    const key = k2 ?? k1;

    if (!namespace || !key) return;

    const i18n = getI18n();
    if (Array.isArray(data)) {
      data.forEach((name) => {
        i18n.addResource(name.language, namespace, key, name.value);
      });
    } else {
      i18n.addResource(data.language, namespace, key, data.value);
    }
  } catch (e) {
    throw apiError(e);
  }
}

/* 다국어 키가 없을 경우 네임스페이스와 .를 제외한 마지막 문장을 값으로 추출합니다. */
export function getNamespaceKey(key: string): string {
  return key.split(':').pop()?.split('.').pop() ?? key;
}

/**
 * 다국어 번역 값을 가져옵니다.
 * @param key - 다국어 키.
 * @param options
 * @returns 다국어 번역 값
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getLocalizedText<TInterpolationMap extends object = StringMap>(
  key: string,
  options?: CustomI18nOptions<TOptions<TInterpolationMap>>,
): string {
  try {
    const [k1, k2] =
      options?.nsSeparator === undefined ? key.split(':') : [key, undefined];

    const nameKey = k2 ?? k1;
    const ns = nameKey === key ? getPathByNamespace() : k1;

    const i18n = getI18n();
    if (!i18n) {
      return options?.defaultValue ?? getNamespaceKey(key);
    }
    const lang = options?.lng ?? i18n.language;

    return i18n.getFixedT(lang, ns)(key, options);
  } catch (ex) {
    return options?.defaultValue ?? key;
  }
}

/** 지정된 네임스페이스를 사용하는 다국어 함수를 반환. */
export const createLocalizedTextFactory = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  TInterpolationMap extends object = StringMap,
>(
  ns: NamespaceType | NamespaceType[],
) => {
  return (
    key: string,
    options?: CustomI18nOptions<TOptions<TInterpolationMap>>,
  ) => getLocalizedText(key, { ns, ...options });
};

/**
 * 다국어 리소스를 다시 로드합니다.
 * @param namespace
 * @param languages
 */
export async function resourceUpdate(
  namespaces: Array<NamespaceType>,
  languages: Array<Language>,
) {
  const i18n = getI18n();

  const resource: { namespace: string; language: Language }[] = [];

  languages.forEach((lng) => {
    namespaces.forEach((ns) => {
      resource.push({ language: lng, namespace: ns });
    });
  });

  const promises = resource
    .map((x) => {
      if (!i18n.hasResourceBundle(x.language, x.namespace))
        return i18n.reloadResources([x.language], [x.namespace]);
      return undefined;
    })
    .filter((x) => x !== undefined);

  await Promise.all(promises);
}

/**
 * 다국어 리소스를 다시 로드합니다.
 * 언어와 네임스페이스 배열이 없을 경우 모든 리소스를 로드합니다.
 * @param lng
 * @param ns
 */
export async function resourceReload(
  lng?: Language | readonly Language[],
  ns?: NamespaceType | readonly NamespaceType[],
) {
  const i18n = getI18n();
  const lang = lng ?? ['ko-KR'];
  const namespace = ns ?? ['directory', 'jobclass'];
  await i18n.reloadResources(lang, namespace);
}
