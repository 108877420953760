import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import paymentsApi, {
  PaymentItem,
} from '../../apis/sangsanginsecurities/v1/payments';

const name = 'sangsanginsecurities/payments';

export interface PaymentListItem {
  checked: boolean;
  evidGrwNo: string; // 증빙기본번호
  evidncDeptCd: string; // 증빙부서
  evidncDeptNm: string; // 증빙부서명
  evidncDy: string; // 증빙일자
  evidncWrtDy: string; // 증빙작성일자
  evidncNo: string; // 증빙번호
  evidncDvnm: string; // 증빙구분
  pttnDy: string; // 전기일
  decisDy: string; // 결의일
  acctNm: string; // 계정과목
  payTypNm: string; // 지급유형
  sttlMthdNm: string; // 지급구분
  useNm: string; // 사용자
  conm: string; // 거래처
  amt: string; // 거래금액
  payTotAmt: string; // 지급금액
  payAmt: string; // 잔액
  decisTypDvnm: string; // 구분
  decisCont: string; // 설명
  slipNo: string; // 전표정보
}

interface State {
  items: {
    list: PaymentListItem[];
    totalCount: number;
  };
}

const initialState: State = {
  items: {
    list: [],
    totalCount: 0,
  },
};

function convertNullType(
  obj: PaymentItem,
): { [key in keyof PaymentItem]: string } {
  const result = {} as { [K in keyof PaymentItem]: string };

  Object.entries(obj).forEach((a) => {
    const [key, value] = a;
    if (typeof value === 'number')
      result[key as keyof PaymentItem] =
        value === null ? '' : value.toLocaleString();
    else if (typeof value === 'string')
      result[key as keyof PaymentItem] = value === null ? '' : value;
  });

  return result;
}

const findPaymentList = createAsyncThunk(
  `${name}/findPaymentList`,
  async (
    arg: { search: string } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const queryParams = getQueryParams(arg.search);
      const data = await paymentsApi.paymentList({
        companyId,
        employeeId,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 300,
      });
      const items: PaymentListItem[] = data.list.map((a) => {
        return {
          checked: false,
          ...convertNullType(a),
        };
      });
      const totalCount = data.count;
      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const paymentsRecuder = createSlice({
  initialState,
  name,
  reducers: {
    setListItemChecked(
      state,
      action: PayloadAction<{ id: 'all' | string; checked: boolean }>,
    ) {
      const { payload } = action;
      if (payload.id === 'all')
        state.items.list = state.items.list.map((a) => ({
          ...a,
          checked: payload.checked,
        }));
      else
        state.items.list = state.items.list.map((a) => {
          if (a.evidGrwNo === payload.id)
            return { ...a, checked: payload.checked };
          return a;
        });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findPaymentList.fulfilled, (state, { payload }) => {
      state.items.list = payload.items;
      state.items.totalCount = payload.totalCount;
    });
  },
});

export default paymentsRecuder.reducer;

export const paymentsActions = {
  setListItemChecked: paymentsRecuder.actions.setListItemChecked,

  paymentList: findPaymentList,
};
