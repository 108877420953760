import React from 'react';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function AttachDocumentList(props: {
  count: number;
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-document-file-list';
  if (props.className) classname += ` ${props.className}`;

  return (
    <div className={classname}>
      <div className="list-head">
        <div className="title">
          {getLocalizedText('첨부문서')}&nbsp;
          {getLocalizedText('{{n}}개', { n: props.count })}
        </div>
      </div>
      <div className="list-body">{props.children}</div>
    </div>
  );
}

export default AttachDocumentList;
