import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../groupware-common/apis/common/v1';

/**  사용자 - 직원의 역할을 조회합니다.
 * @returns 직원 역할 배열
 */
async function userFind(arg: {
  companyId: number;
  employeeId: number;
}): Promise<
  {
    employeeId: number;
    updateAt: string;
  }[]
> {
  const { companyId, employeeId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/preferences/v1/employees/companies/${companyId}/${employeeId}/roles`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**  관리자 - 직원의 역할을 조회합니다.
 * @returns 직원 역할 배열
 */
async function find(companyId: number): Promise<
  {
    employeeId: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/preferences/v1/companies/${companyId}/roles`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 직원의 역할을 등록합니다.
 * @returns 직원 역할 배열
 */
async function append(arg: {
  companyId: number;
  data: { employeeId: number }[];
}): Promise<
  {
    employeeId: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/preferences/v1/companies/${companyId}/roles`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 직원의 역할을 삭제합니다.
 * @returns 직원 역할 배열
 */
async function remove(arg: {
  companyId: number;
  data: {
    employeeId: number;
    updateAt: string;
  }[];
}): Promise<
  {
    employeeId: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/adminconsole/preferences/v1/companies/${companyId}/roles`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const roleApi = {
  userFind,

  find,
  append,
  remove,
};

export default roleApi;
