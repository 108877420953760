import React from 'react';

function TimeLine(props: {
  title?: string;
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let className = 'eui-timeline';
  if (props.className) className += ` ${props.className}`;
  return (
    <div className={className}>
      {props.title && (
        <div className="timeline-title">
          <div className="title">{props.title}</div>
        </div>
      )}
      <ul className="eui-timeline-root">{props.children}</ul>
    </div>
  );
}

export default TimeLine;
