import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../../components/post/PostWrite';
import FeedBack from '../../../../../components/alert/FeedBack';
import { RootState } from '../../../../../groupware-webapp/app/store';

import Icon from '../../../../../components/icon/Icon';
import { validateUrl } from '../../../../stores/common';
import DashBoardComposeItem from './DashBoardComposeItemBody';
import { PortalTemplateItem } from '../../../../stores/templates';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DashBoardTemplateAddDialog(props: {
  onSave(arg: { templateId: number; url: string; options?: string }): void;
  onClose(): void;
}): JSX.Element {
  const list = useSelector(
    (state: RootState) => state.dashboard.templates.availableList,
  );

  const [state, setState] = useState<{
    selectedItem?: PortalTemplateItem;
    url?: string;
    isEdit?: boolean;
  }>({});
  const [validation, setValidation] = useState('');

  // 템플릿 추가
  const handleSave = () => {
    const { selectedItem } = state;
    if (selectedItem === undefined) {
      setValidation(getLocalizedText('템플릿을 선택해 주세요.'));
      return;
    }

    if (
      selectedItem.isEdit &&
      validateUrl(selectedItem.url, selectedItem.templateKey) !== ''
    ) {
      setValidation(validateUrl(selectedItem.url, selectedItem.templateKey));
      return;
    }

    props.onSave({
      templateId: selectedItem.templateId,
      url: selectedItem.search ? `${selectedItem.url}` : selectedItem.url,
      options: selectedItem.options,
    });
  };

  const handleSelectItem = (id: number) => {
    setState((prev) => ({
      ...prev,
      selectedItem: list.find((x) => x.templateId === id),
    }));
  };

  const handleDialogVisible = () => {
    if (!state.selectedItem) {
      setValidation(getLocalizedText('템플릿을 선택해 주세요.'));
      return;
    }
    setState((prev) => ({
      ...prev,
      isEdit: true,
    }));
  };

  const handleChange = (arg: {
    url: string;
    options?: string;
    params?: string;
  }) => {
    const { selectedItem } = state;
    if (!selectedItem) return;

    const { url, options, params } = arg;

    setState((prev) => ({
      ...prev,
      selectedItem: {
        ...selectedItem,
        url,
        options,
        params,
      },
    }));
  };

  const renderDialogContent = () => {
    if (state.selectedItem && state.selectedItem.isEdit && state.isEdit) {
      return (
        <>
          <Dialog onClose={props.onClose}>
            <DialogHeader>
              <DialogTitle>{getLocalizedText('템플릿 편집')}</DialogTitle>
            </DialogHeader>
            <DialogBody noneOverFlow>
              <PostWrite>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: '100%',
                  }}
                >
                  <DashBoardComposeItem
                    item={state.selectedItem}
                    onChange={handleChange}
                  />
                </div>
              </PostWrite>
            </DialogBody>
            <DialogFooter>
              <Button
                text={getLocalizedText('이전')}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    selectedItem: undefined,
                    isEdit: false,
                  }))
                }
              />
              <Button
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleSave}
              />
            </DialogFooter>
          </Dialog>
        </>
      );
    }
    return (
      <>
        <DialogHeader>
          <DialogTitle>{getLocalizedText('템플릿 추가')}</DialogTitle>
        </DialogHeader>
        <DialogBody noneOverFlow>
          <PostWrite>
            <div className="dashboard-template-dialog">
              <div className="template-select-dialog">
                {list.map((item) => {
                  return (
                    <div
                      key={`template-add-${item.title}`}
                      className={`template-select-item ${
                        state.selectedItem &&
                        state.selectedItem.templateId === item.templateId
                          ? ' selected'
                          : ''
                      }`}
                      onClick={() => handleSelectItem(item.templateId)}
                    >
                      <div className="badge">
                        <Icon icon={item.icon} />{' '}
                      </div>
                      <div className="label">
                        {getLocalizedText(item.title)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          <Button
            text={
              state.selectedItem && state.selectedItem.isEdit
                ? getLocalizedText('다음')
                : getLocalizedText('추가')
            }
            variant="contained"
            onClick={
              state.selectedItem && state.selectedItem.isEdit
                ? () => handleDialogVisible()
                : () => handleSave()
            }
          />
          <Button text={getLocalizedText('취소')} onClick={props.onClose} />
        </DialogFooter>
      </>
    );
  };
  return (
    <>
      <Dialog onClose={props.onClose}>
        {renderDialogContent()}
        <FeedBack text={validation} onClose={() => setValidation('')} />
      </Dialog>
    </>
  );
}

export default DashBoardTemplateAddDialog;
