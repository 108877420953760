import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import { preferencesActions } from '../stores/approval/preferences';
import { surrogateApprovalActions } from '../stores/approval/surrogate-approval';
import { approvaluserPreferencesActions } from '../stores/approval/userPreferences';
import { archiveFolderActions } from '../stores/approval/archive';
import ApprovalContentRoute from '../pages/root/approval/ApprovalContentRoute';
import ApprovalArchiveSettingsRoute from '../pages/root/approval/archive-settings/ApprovalArchiveSettingsRoute';
import ApprovalComposeRoute from '../pages/root/approval/compose/ApprovalComposeRoute';
import ApprovalDashboardRoute from '../pages/root/approval/dashboard/ApprovalDashboardRoute';
import ApprovalPreferencesRoute from '../pages/root/approval/preferences/ApprovalPreferencesRoute';
import ApprovalStatusRoute from '../pages/root/approval/status/ApprovalStatusRoute';
import { approvalArbitraryDecisionActions } from '../stores/approval/arbitraryDecisions';
import ApprovallinkageComposeRoute from '../pages/root/approval/approvallinkage/ApprovallinkageComposeRoute';
import ApprovallinkageViewRoute from '../pages/root/approval/approvallinkage/ApprovallinkageViewRoute';
import ApprovalArchiveTransferRoute from '../pages/root/approval/archive-settings/ApprovalArchiveAdminstorRoute';

function RootLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // console.log(`${RootLocateRoute.name}.render(props)`, props);

  const dispatch = useAppDispatch();
  const organizationId = useSelector(
    (state: RootState) => state.session.principal.organizationId,
  );

  const { pathname, search, hash, locationKey: key } = props;
  useEffect(() => {
    dispatch(preferencesActions.findBasic());
    dispatch(preferencesActions.findApprovalType());
    dispatch(surrogateApprovalActions.designators());
    dispatch(approvaluserPreferencesActions.fetchPreferencesFolder());
    dispatch(approvalArbitraryDecisionActions.fetchArbitraryDecision());
    dispatch(
      archiveFolderActions.findAll({ data: { organizationId, current: true } }),
    );
    return () => {
      // console.log(`ApprovalRootLocateRoute:unmount`);
    };
  }, [dispatch]);

  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const { contentMode } = getQueryParams(search);

  // 결재작성.
  if (contentMode === 'create' || contentMode === 'update') {
    return (
      <ApprovalComposeRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  // 연동결재
  if (p1 === 'approvalinterworking') {
    // 상신.
    if (p2 === 'compose')
      return (
        <ApprovallinkageComposeRoute
          pathname={pathname}
          search={search}
          hash={hash}
          key={key}
        />
      );
    // 문서 보기.
    if (p2 === 'document')
      return (
        <ApprovallinkageViewRoute
          pathname={pathname}
          search={search}
          key={key}
        />
      );
  }
  // 대시보드.
  if (p1 === undefined && p2 === undefined) {
    return (
      <ApprovalDashboardRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  // 환경설정.
  if (p1 === 'preferences') {
    return (
      <ApprovalPreferencesRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  // 결재현황.
  if (p1 === 'status') {
    return (
      <ApprovalStatusRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  //  기록물 관리자 지정
  if (p1 === 'archivesadminstor') {
    return (
      <ApprovalArchiveTransferRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  // 기록물관리.
  if (p1 === 'archivesettings') {
    return (
      <ApprovalArchiveSettingsRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  return <ApprovalContentRoute pathname={pathname} search={search} key={key} />;
}

export default RootLocateRoute;
