import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import DirectorySearch, {
  OptionType,
  OptionUseType,
} from '../../../../../components/search/DirectorySearch';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import ApprovalAttachedDocumentDialog from '../../../../../groupware-approval/pages/root/approval/content/ApprovalAttachedDocumentDialog';
import {
  b62,
  getPathMap,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { attendanceDetailStatusActions } from '../../../../stores/attendance/detailStatus';
import {
  statusLabel,
  statusTheme,
} from '../../../root/attendance/content/AttendanceContentBodyListItem';
import AttendanceContentBodyView from '../../../root/attendance/content/AttendanceContentBodyView';
import { formatStatus } from '../../../root/attendance/content/AttendanceContentContainer';
import AttendanceContentHeadView from '../../../root/attendance/content/AttendanceContentHeadView';

function AttendanceDetailStatusContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;

  const attendanceCode = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );
  const list = useSelector(
    (state: RootState) => state.attendance.detailStatus.list.items,
  );
  const totalCount = useSelector(
    (state: RootState) => state.attendance.detailStatus.list.totalCount,
  );
  const view = useSelector(
    (state: RootState) => state.attendance.attendances.view.data,
  );

  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find(({ id }) => id === 6013)?.name ?? '';

  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);

  const [columns, setColumns] = useState<DataGridColDef[]>([
    {
      field: 'group',
      label: getLocalizedText('구분'),
      width: 120,
    },
    {
      field: 'startDate',
      label: getLocalizedText('시작날짜'),
      width: 118,
    },
    {
      field: 'endDate',
      label: getLocalizedText('종료날짜'),
      width: 118,
    },
    {
      field: 'useDays',
      label: getLocalizedText('발생'),
      width: 150,
    },
    {
      field: 'subject',
      label: getLocalizedText('제목'),
      minWidth: 200,
    },
    {
      field: 'name',
      label: getLocalizedText('이름'),
      width: 200,
    },
    {
      field: 'createAt',
      label: getLocalizedText('작성일'),
      width: 118,
      align: 'right',
    },
    {
      field: 'status',
      label: getLocalizedText('결재상태'),
      width: 98,
      align: 'right',
    },
  ]);

  function typeName(value: number) {
    const attendance = attendanceCode.find((a) => a.id === value);
    if (attendance) return attendance.name;
    return '';
  }

  const rows: DataGridRowsProps<number> = list.map((a) => {
    const directoryData = getDirectoryData({
      ...directory,
      employeeId: a.employeeId,
      companyId: principal.companyId,
    });

    const status = formatStatus(a.status);

    return [
      { type: 'text' as const, value: typeName(a.useType) },
      { type: 'date' as const, value: a.startDate, format: 'dateNumber' },
      { type: 'date' as const, value: a.endDate, format: 'dateNumber' },
      { type: 'text' as const, value: a.useMinutes },
      { type: 'subject' as const, value: a.subject, id: a.approvalId },
      {
        type: 'user' as const,
        avatar: directoryData.avatar ?? '',
        icon: directoryData.avatar === '' ? undefined : 'person',
        value: `${directoryData.employeeName} ${directoryData.jobPositionName}`,
        from: directoryData.organizationName,
      },
      { type: 'date' as const, value: a.createAt, format: 'dateNumber' },
      {
        type: 'chip' as const,
        value: statusLabel(status),
        theme: statusTheme(status),
      },
    ];
  });

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<{
    validation: string;
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
  }>({ validation: '', dialogs: [] });

  useEffect(() => {
    setLoading(false);
    scrollbar.current?.scrollTo(0, 0);
  }, [props]);

  /** 클릭이벤트 */
  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
    name?: string;
  }) => {
    const { code, id } = arg;

    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = (queryParams.pageNo || 1) - 1;
      if (queryParams.pageNo > 0) {
        if (queryParams.pageNo === 1) {
          delete queryParams.pageNo;
        }
        dispatch(
          attendanceDetailStatusActions.findList({
            route: {
              pathname,
              search: getQueryParams(queryParams),
            },
          }),
        );
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = (queryParams.pageNo || 1) + 1;
      dispatch(
        attendanceDetailStatusActions.findList({
          route: {
            pathname,
            search: getQueryParams(queryParams),
          },
        }),
      );
    }

    /** 리스트 아이템 클릭 */
    if (code === 'subject' && id) {
      go(`${getPathMap('/*/*/*', pathname)}/${b62(id)}`, search);
    }

    /** 취소 버튼 클릭. */
    if (code === 'cancelConfirm') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
  };

  /** 목록 개수 변경. */
  const handleChangeRowLength = (value: number) => {
    queryParams.rowsPerPage = value;
    delete queryParams.pageNo;
    dispatch(
      attendanceDetailStatusActions.findList({
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록 노출. */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({
      ...prev,
      validation: '',
    }));
  };

  // 첨부문서 대화상자 열기
  const handleAttachedDocumentDialog = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument'; // 부모 문서, 첨부 문서
    documentId: number;
    affiliateCompanyId?: number | undefined;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      dialogType,
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'parentdocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
            receiptDocumentId,
            receiptAffiliatedCompanyId,
          },
        ],
      }));
    }
    if (dialogType === 'attacheddocument') {
      // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
      if (state.dialogs.length === 0 && view) {
        setState((prev) => ({
          ...prev,
          dialogs: [
            {
              dialogType: 'attacheddocument',
              documentId: view.id,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [
            ...prev.dialogs,
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      }
    }
  };

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    filter: string;
    date: {
      start: Date | null;
      end: Date | null;
    };
    status?: string;
  }) => {
    const { keyword, filter, status, date } = arg;

    let word = '';
    if (filter === 'code' || filter === 'status') word = status ?? '';
    if (filter === 'subject') word = keyword ?? '';

    delete queryParams.pageNo;
    queryParams.startDate =
      date.start !== null
        ? dateFormat(initialDate(date.start), 'YYYY-MM-DD')
        : undefined;
    queryParams.endDate =
      date.end !== null
        ? dateFormat(initialDate(date.end), 'YYYY-MM-DD')
        : undefined;
    queryParams.searchCode = filter;
    queryParams.searchWord = word === '' ? undefined : word;

    dispatch(
      attendanceDetailStatusActions.findList({
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록 이동. */
  const handleListGo = () => {
    go(`${getPathMap('/*/*/*', pathname)}`, search);
  };

  /** 팝업 닫기. */
  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  /** 근태 취소 액션. */
  const handleCancelAttendance = () => {
    if (!view) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('문서를 찾을 수 없습니다.'),
      }));
      return;
    }

    const useId = JSON.parse(view.linkId).id;
    if (!useId) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('연동 아이디를 찾을 수 없습니다.'),
      }));
    }

    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        option: 'CLEAR_DIALOG',
      },
    });

    const data = {
      linkedKey: {
        companyId: principal.companyId,
        id: useId,
      },
      documentId: view.id,
      linkType: 'CANCEL' as const,
    };

    dispatch(attendanceDetailStatusActions.cancel({ ...data, route }));
  };

  /** dialog 닫기 */
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument' | 'draftdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId, affiliateCompanyId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument' || dialogType === 'draftdocument') {
      if (state.dialogs.length > 2 || dialogType === 'draftdocument') {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  // 품의서 대화상자 열기
  const handleDraftDocumentDialog = (documentId: number) => {
    setState((prev) => ({
      ...prev,
      dialogs: [
        ...prev.dialogs,
        {
          dialogType: 'draftdocument',
          documentId,
          affiliatedCompanyId: 0,
        },
      ],
    }));
  };

  const start = timezoneDate(queryParams.startDate ?? undefined);
  const end = timezoneDate(queryParams.endDate ?? undefined);

  const renderContent = () => {
    if (view) {
      const toolbarButtons: ActionEventProps[] = [];
      if (view.status === 'COMPLETE')
        toolbarButtons.push({
          code: 'cancelConfirm',
          label: getLocalizedText('취소'),
          type: 'text',
          icon: 'times-circle',
        });

      return (
        <>
          <AttendanceContentHeadView
            type="full"
            onListGo={handleListGo}
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleAction}
          />
          <AttendanceContentBodyView
            pathname={pathname}
            type="split"
            view={view}
            onDraftDocumentPopup={handleDraftDocumentDialog}
            onAttachedDocumentPopup={handleAttachedDocumentDialog}
          />
        </>
      );
    }

    const options: OptionType[] = [
      { value: 'code', label: getLocalizedText('구분'), useType: 'drop' },
      { value: 'subject', label: getLocalizedText('제목'), useType: 'text' },
      { value: 'status', label: getLocalizedText('상태'), useType: 'drop' },
    ];
    const searchWord =
      queryParams.searchCode === 'subject' ? queryParams.searchWord : undefined;
    const status =
      queryParams.searchCode === 'status' || queryParams.searchCode === 'code'
        ? queryParams.searchWord
        : undefined;
    const filter: { value: string; useType: OptionUseType } = {
      value: queryParams.searchCode ?? 'code',
      useType: queryParams.searchCode === 'subject' ? 'text' : 'drop',
    };

    return (
      <>
        <EuiHeader>
          <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
          <EuiHeader.Search>
            <DirectorySearch
              keyword={searchWord}
              status={status}
              options={options}
              statusOptions={[
                { value: 'all', label: getLocalizedText('전체') },
                // eslint-disable-next-line prettier/prettier
                { value: '1', label: getLocalizedText('진행중'), type: 'status' },
                { value: '3', label: getLocalizedText('완료'), type: 'status' },
                { value: '4', label: getLocalizedText('반려'), type: 'status' },
                // eslint-disable-next-line prettier/prettier
                { value: '44', label: getLocalizedText('취소'), type: 'status' },
                ...attendanceCode.map((a) => {
                  return {
                    value: `${a.id}`,
                    label: a.name,
                    type: 'code',
                  };
                }),
              ]}
              filter={filter}
              date={{ start, end }}
              onSearch={handleSearch}
            />
          </EuiHeader.Search>
        </EuiHeader>
        <EuiBody>
          <DataGrid
            scrollbar={scrollbar}
            caption={getLocalizedText(`${title}`)}
            columns={columns}
            rows={rows}
            onClick={handleAction}
            pagination={{
              no: queryParams.pageNo || 1,
              total: totalCount,
              row: queryParams.rowsPerPage || 15,
              onChangeRow: handleChangeRowLength,
            }}
            loading={loading}
            onChangeColumnVisible={handleChangeColumnVisible}
          />
        </EuiBody>
        <FeedBack
          text={state.validation}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              validation: '',
            }))
          }
        />
      </>
    );
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    if (dialogType === 'cancelConfirm') {
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              {getLocalizedText('근태 건을 취소 상태로 변경하시겠습니까?')}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text={getLocalizedText('닫기')}
              onClick={handleCloseDialog}
            />
            <Button
              text={getLocalizedText('확인')}
              variant="contained"
              onClick={handleCancelAttendance}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    const { dialogs } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderContent()}
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceDetailStatusContainer;
