import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelperText from '../../../../components/alert/HelperText';
import Button from '../../../../components/button/Button';
import Checkbox from '../../../../components/checkbox/Checkbox';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../components/radio/RadioGroup';
import SelectField from '../../../../components/selectField/SelectField';
import Switch from '../../../../components/switch/Switch';
import { timeFormat } from '../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { authenticationActions } from '../../../store/login';
import { createLocalizedTextFactory } from '../../../../groupware-common/utils/i18n';

interface Option {
  value: string;
  label: string;
}
function AuthenticationLoginContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const getLocalizedText = createLocalizedTextFactory('authentication');

  const login = useSelector(
    (state: RootState) => state.authentication.login.data,
  );
  const initialState = {
    change: false,
    saveing: false,
    usePasswordAlarm: login.usePasswordAlarm,
    passwordAlarmCycle: login.passwordAlarmCycle,
    usePasswordChangeRequired: login.usePasswordChangeRequired,
    useSigninFailureBlock: login.useSigninFailureBlock,
    signinFailureBlockLimit: login.signinFailureBlockLimit,
    useRequiredPasswordSpecialChar: login.useRequiredPasswordSpecialChar,
    useRecylePassword: false,
    recylePasswordLimit: 1,
    useAutoLogout: login.useAutoLogout,
    autoLogoutTime: login.autoLogoutTime,
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;
    async function run() {
      if (!mount) return;

      const data = {
        usePasswordAlarm: state.usePasswordAlarm,
        passwordAlarmCycle: state.passwordAlarmCycle,
        usePasswordChangeRequired: state.usePasswordChangeRequired,
        useSigninFailureBlock: state.useSigninFailureBlock,
        signinFailureBlockLimit: state.signinFailureBlockLimit,
        useRequiredPasswordSpecialChar: state.useRequiredPasswordSpecialChar,
        useAutoLogout: state.useAutoLogout,
        autoLogoutTime: state.autoLogoutTime,
        updateAt: login.updateAt,
      };
      await dispatch(authenticationActions.saveLoginSetting(data)).then(
        (result) => {
          if ((result as { error?: string }).error === undefined)
            setState((prev) => ({ ...prev, change: false, saveing: false }));
          else setState((prev) => ({ ...prev, saveing: false }));
        },
      );
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  // 로그인 옵션값에 존재하지 않는 값인 경우 값 추가 후 재정렬.
  const addOptionAndSort = (
    option: Option[],
    value: number,
    label: string,
  ): void => {
    // const { option, value, label } = arg;
    if (!option.find((a) => parseInt(a.value, 10) === value))
      option.push({ value: value.toString(), label });

    option.sort(
      (a, b) =>
        +(parseInt(a.value, 10) > parseInt(b.value, 10)) ||
        +(parseInt(a.value, 10) === parseInt(b.value, 10)) - 1,
    );
  };

  const cycleOption = [
    { value: '30', label: '30일' },
    { value: '60', label: '60일' },
    { value: '90', label: '90일' },
    { value: '180', label: '180일' },
    { value: '365', label: '365일' },
  ];
  const failureBlockOption = [] as {
    value: string;
    label: string;
  }[];
  for (let i = 3; i <= 10; i += 1) {
    failureBlockOption.push({
      value: i.toString(),
      label: `${i}회 연속 실패 시`,
    });
  }
  const autoLogoutOption = [
    { value: '60', label: '1시간' },
    { value: '120', label: '2시간' },
    { value: '180', label: '3시간' },
    { value: '360', label: '6시간' },
    { value: '720', label: '12시간' },
    { value: '1440', label: '24시간' },
  ];

  const recyclePasswordOption = [] as {
    value: string;
    label: string;
  }[];
  for (let i = 1; i <= 5; i += 1) {
    recyclePasswordOption.push({
      value: i.toString(),
      label: getLocalizedText('최근 {{n}}개의 비밀번호 사용 불가', {
        n: i,
      }),
    });
  }

  addOptionAndSort(
    cycleOption,
    login.passwordAlarmCycle,
    `${login.passwordAlarmCycle}일`,
  );
  addOptionAndSort(
    failureBlockOption,
    login.signinFailureBlockLimit,
    `${login.signinFailureBlockLimit}회 연속 실패 시`,
  );
  addOptionAndSort(
    autoLogoutOption,
    login.autoLogoutTime,
    timeFormat(login.autoLogoutTime, 'MINUTE'),
  );

  /** 비밀번호 만료 시 변경 알림 사용 여부. */
  const handleChangeUsePasswordAlarm = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      change: true,
      usePasswordAlarm: event.target.checked,
    }));
  };

  /** 비밀번호 변경 알림 주기. */
  const handleChangePasswordAlarmCycle = (value: string) => {
    setState((prev) => ({
      ...prev,
      change: true,
      passwordAlarmCycle: parseInt(value, 10),
    }));
  };

  /** 비밀번호 강제 변경 사용 여부. */
  const handleChangeUsePasswordChangeRequired = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      change: true,
      usePasswordChangeRequired: event.target.checked,
    }));
  };

  /** 로그인 시도 횟수 제한 사용 여부. */
  const handleChangeUseSigninFailureBlock = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      change: true,
      useSigninFailureBlock: event.target.checked,
    }));
  };

  /** 제한할 로그인 시도 횟수. */
  const handleChangeSigninFailureBlockLimit = (value: string) => {
    setState((prev) => ({
      ...prev,
      change: true,
      signinFailureBlockLimit: parseInt(value, 10),
    }));
  };

  /** 비밀번호 형식 변경 */
  const handleChangePasswordSpecialChar = (value: boolean) => {
    setState((prev) => ({
      ...prev,
      change: true,
      useRequiredPasswordSpecialChar: value,
    }));
  };

  /** 비밀번호 재사용 사용 여부. */
  // const handleChangeUseRecylePassword = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setState((prev) => ({
  //     ...prev,
  //     change: true,
  //     useRecylePassword: event.target.checked,
  //   }));
  // };

  /** 재사용 금지 비밀번호 개수. */
  // const handleChangeRecylePasswordLimit = (value: string) => {
  //   setState((prev) => ({
  //     ...prev,
  //     change: true,
  //     recylePasswordLimit: parseInt(value, 10),
  //   }));
  // };

  /** 자동 로그아웃 사용 여부 */
  const handleChangeUseAutoLogout = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      change: true,
      useAutoLogout: event.target.checked,
    }));
  };

  /**  자동 로그아웃 시간 */
  const handleChangeAutoLogoutTime = (value: string) => {
    setState((prev) => ({
      ...prev,
      change: true,
      autoLogoutTime: parseInt(value, 10),
    }));
  };

  const handleSave = () => {
    setState((prev) => ({ ...prev, saveing: true }));
  };

  const handleCancel = () => {
    setState(initialState);
  };

  const {
    usePasswordAlarm,
    passwordAlarmCycle,
    usePasswordChangeRequired,
    useSigninFailureBlock,
    signinFailureBlockLimit,
    useRequiredPasswordSpecialChar,
    // useRecylePassword,
    // recylePasswordLimit,
    useAutoLogout,
    autoLogoutTime,
  } = state;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText('로그인 관리')}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title={getLocalizedText('비밀번호 변경 주기')}>
          <div>
            <Switch
              checked={usePasswordAlarm}
              onChange={handleChangeUsePasswordAlarm}
            />

            {usePasswordAlarm && (
              <div style={{ marginTop: '8px', display: 'flex' }}>
                <SelectField
                  style={{ marginRight: '8px' }}
                  data={cycleOption}
                  value={passwordAlarmCycle.toString()}
                  onChange={handleChangePasswordAlarmCycle}
                />
                <Checkbox
                  checked={usePasswordChangeRequired}
                  label={getLocalizedText('강제 변경')}
                  onChange={handleChangeUsePasswordChangeRequired}
                />
              </div>
            )}
          </div>
        </EuiSetting.Item>
        <EuiSetting.Item title={getLocalizedText('로그인 시도 횟수 제한')}>
          <div>
            <Switch
              checked={useSigninFailureBlock}
              onChange={handleChangeUseSigninFailureBlock}
            />

            {useSigninFailureBlock && (
              <div style={{ marginTop: '8px', display: 'flex' }}>
                <SelectField
                  data={failureBlockOption}
                  value={signinFailureBlockLimit.toString()}
                  onChange={handleChangeSigninFailureBlockLimit}
                />
              </div>
            )}
          </div>
        </EuiSetting.Item>
        <EuiSetting.Item title={getLocalizedText('비밀번호 형식')}>
          <RadioGroup
            column
            name={getLocalizedText('비밀번호 형식')}
            value={useRequiredPasswordSpecialChar}
            onChange={handleChangePasswordSpecialChar}
            data={[
              {
                value: false,
                label: getLocalizedText('영문, 숫자 필수로 포함'),
              },
              {
                value: true,
                label: getLocalizedText(
                  '영문, 숫자, 특수문자(@, $, !, %, *, ?, &, #) 필수로 포함',
                ),
              },
            ]}
          />
        </EuiSetting.Item>
        {/* TODO 백엔드 개발 후 적용. */}
        {/* <EuiSetting.Item title={getLocalizedText('비밀번호 재사용')}>
          <div>  
          <Switch
              checked={useRecylePassword}
              onChange={handleChangeUseRecylePassword}
            />
 
            {useRecylePassword && (
              <div style={{marginTop: '8px', display: 'flex'}}>
                <SelectField
                             data={recyclePasswordOption}
                             value={recylePasswordLimit.toString()}
                             onChange={handleChangeRecylePasswordLimit}
              />
                </div>
                         )}</div>
        </EuiSetting.Item> */}
        <EuiSetting.Item title={getLocalizedText('자동 세션 종료')}>
          <div>
            <Switch
              checked={useAutoLogout}
              onChange={handleChangeUseAutoLogout}
            />

            {useAutoLogout && (
              <div style={{ marginTop: '8px', display: 'flex' }}>
                <SelectField
                  data={autoLogoutOption}
                  value={autoLogoutTime.toString()}
                  onChange={handleChangeAutoLogoutTime}
                />
              </div>
            )}
          </div>
          <HelperText
            text={getLocalizedText(
              '* 설정한 시간동안 아무런 동작을 하지 않은 경우 자동으로 세션이 종료됩니다.',
            )}
          />
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {state.change && (
              <>
                <Button
                  noDuplication={state.saveing}
                  variant="contained"
                  text={getLocalizedText('저장')}
                  onClick={handleSave}
                />
                {!state.saveing && (
                  <Button
                    variant="outlined"
                    text={getLocalizedText('취소')}
                    onClick={handleCancel}
                  />
                )}
              </>
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default AuthenticationLoginContainer;
