import React, { useState } from 'react';
import Button from '../../../../../components/button/Button';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import TreeList from '../../../../../components/tree/TreeList';
import { IconType } from '../../../../../groupware-common/types/icon';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function DirectoryUserGroupList(props: {
  folderId: number | undefined;
  items: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  onCreate(): void;
  onItemClick(id: number): void;
}): JSX.Element {
  const { items, folderId, onCreate, onItemClick } = props;

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleItemClick = (id: number) => {
    if (items.find((x) => x.id === id)) onItemClick(id);
    // if (items.find((x) => x.id === id)?.icon === 'folder') onItemClick(id);
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('그룹 목록')}>
        <Button
          text={getLocalizedText('그룹 추가')}
          iconType
          icon="plus"
          onClick={onCreate}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <TreeList
          selectedId={folderId}
          filter={filter}
          items={items}
          onItemClick={handleItemClick}
        />
      </EuiSetting.Content>
    </>
  );
}

export default DirectoryUserGroupList;
