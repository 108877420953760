import i18n from 'i18next';
import Backend, { BackendOptions, RequestCallback } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { basename } from 'path';
import axios from 'axios';
import store from './store';
import { i18nActions } from '../stores/i18n';
import { Language } from '../../groupware-common/types';
import {
  getApiConfig,
  getCurrentLanguage,
} from '../../groupware-common/apis/common/v1';
import { jwtDecode } from '../stores/session';

const initialLanguage = getCurrentLanguage();
const localDomain = ['localhost:3000', 'fe.test.com'];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'currentOnly',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?date=240814', // 배포 전 현재 배포하는 날짜로 변경하여 배포 필요.
      addPath: '/resources',
      parsePayload: (
        namespace: string,
        key: string,
        fallbackValue?: string,
      ) => {
        return { namespace, key, fallbackValue };
      },
      crossDomain: true,
      withCredentials: true,
      request: (
        options: BackendOptions,
        loadPath: string,
        payload: { namespace: string; key: string; fallbackValue: string },
        callback: RequestCallback,
      ) => {
        let url = loadPath;

        if (payload === undefined) {
          try {
            let host = '';
            let config;
            const pattern = /^(.+?)\.json/;
            const match = basename(loadPath).match(pattern);
            const namespace = match !== null ? match[1] : 'common';
            switch (namespace) {
              case 'directory':
              case 'jobclass': {
                const apiConfig = getApiConfig();
                const jwt = jwtDecode(
                  apiConfig.headers.Authorization.split(' ')[1] ?? '',
                );
                if (jwt !== undefined) {
                  const companyId = jwt.payload.cid;
                  const employeeId = jwt.payload.eid;
                  url = `/apis/i18n/v1/companies/${companyId}/employees/${employeeId}${url}`;
                }
                host = `${apiConfig.host}`;
                config = { headers: apiConfig.headers };
                break;
              }
              default:
                host = '';
                config = undefined;
                break;
            }
            axios
              .get(`${host}${url}`, config)
              .then((response) => {
                const { status, data } = response;
                const lastUpdateAt =
                  data.lastUpdateAt ?? '1000-01-01T00:00:00.000000';
                // NOTE namespace key 타입으로 변경
                if (
                  ['jobclass', 'directory'].findIndex((x) => x === namespace) >
                  -1
                ) {
                  store.dispatch(
                    i18nActions.localeTimestampUpdate({
                      language: i18n.language as Language,
                      namespace,
                      lastUpdateAt,
                    }),
                  );
                }
                callback(null, { status, data });
              })
              .catch(() => {
                callback(null, { status: 404, data: '' });
              });
            return;
          } catch (ex) {
            // console.log('load error', ex);
          }
        }

        // backend add
        try {
          const { namespace, key } = payload;
          const property = key.split('.');
          if (property.length <= 1 || property[1] === '') return;
          const [companyId, id] = property[property.length - 1]
            .split('_')
            .map((a) => parseInt(a, 10));
          // console.log('companyId', companyId)
          if (Number.isNaN(companyId) || Number.isNaN(id) || id === undefined)
            return;

          const { host, headers } = getApiConfig();
          const jwt = jwtDecode(headers.Authorization.split(' ')[1] ?? '');
          if (jwt !== undefined) {
            const jwtCompanyId = jwt.payload.cid;
            const jwtEmployeeId = jwt.payload.eid;
            url = `/apis/i18n/v1/companies/${jwtCompanyId}/employees/${jwtEmployeeId}${url}/${id}`;
          }

          axios
            .get<{
              companyId: number;
              resources: { languageTag: Language; value: string }[];
            }>(`${host}${url}`, { headers })
            .then((response) => {
              const { resources } = response.data;

              resources.forEach((a) => {
                i18n.addResource(a.languageTag, namespace, key, `${a.value}`);
              });

              const date = new Date();
              store.dispatch(
                i18nActions.resourceLastUpdateAt({
                  lastUpdateAt: date.toISOString(),
                }),
              );
            });
        } catch (ex) {
          // console.log('missing key error', ex);
        }
      },
      customHeaders: () => {
        const { headers } = getApiConfig();
        return headers;
      },
    },
    preload: ['ko-KR'], // preload 설정 시 해당 언어 모두 로드.
    lng: initialLanguage,
    fallbackLng: 'ko-KR',
    ns: ['authentication', 'code', 'common', 'error'],
    saveMissing: true,
    fallbackNS: 'common',
    defaultNS: 'common',

    debug: localDomain.includes(window.location.host), // 로그를 콘솔 출력에 기록.

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
