import React, { useCallback, useEffect, useState } from 'react';
import FeedBack from '../../../../../components/alert/FeedBack';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import { LinkMenuDetail } from '../../../../stores/module';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Switch from '../../../../../components/switch/Switch';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

export interface createMenuArg {
  name: string;
  url: string;
  status: boolean;
  imageFile: string | undefined;
}

export interface UpdateMenuArg extends createMenuArg {
  id: number;
  seq: number;
  updateAt: string;
}

function ServiceLinkMenuEdit(props: {
  view?: LinkMenuDetail;
  onSave(arg: UpdateMenuArg | createMenuArg): void;
  onClose(): void;
}): JSX.Element {
  const { view } = props;

  const initialState = view
    ? {
        id: view.id,
        name: view.name,
        imageData: view.imagePath,
        url: view.url,
        validation: '',
        status: view.status,
      }
    : {
        name: '',
        validation: '',
        status: true,
      };

  const [state, setState] = useState<{
    name: string;
    url?: string;
    imageData?: string;
    isImageChange?: boolean;
    validation: string;
    status: boolean;
  }>(initialState);

  const handleTextFieldChange = useCallback(
    (
      filedName: keyof typeof state,
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (filedName === 'name' || filedName === 'url') {
        setState((prev) => ({
          ...prev,
          [filedName]: event.target.value,
        }));
      }
    },
    [],
  );

  useEffect(() => {
    setState(initialState);
  }, [view]);

  /** 링크 이미지 변경 */
  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      try {
        const base64 = reader.result;
        if (base64) {
          // 이미지 사이즈 검증.
          const image = new Image();
          image.src = base64.toString();
          image.onload = () => {
            if (image.width > 192 || image.height > 192) {
              setState((prev) => ({
                ...prev,
                validation:
                  '이미지 사이즈는 최대 가로 192픽셀 세로 192픽셀 만 등록 가능합니다.',
              }));
              return;
            }
            setState((prevState) => ({
              ...prevState,
              imageData: base64.toString(),
              isImageChange: true,
            }));
          };
        }
      } catch (ex) {
        setState((prev) => ({
          ...prev,
          validation: '이미지를 업로드 할 수 없습니다.',
        }));
      }
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      const file = event.target.files[0];
      const fileType = file.type.split('/')[1];
      if (!['png', 'jpg', 'jpeg', 'gif'].includes(fileType)) {
        setState((prev) => ({
          ...prev,
          validation: '업로드 할 수 없는 파일 포맷입니다',
        }));
        return;
      }
      reader.readAsDataURL(file);
      const eventTargetValue = { ...event };
      eventTargetValue.target.value = '';
    }
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      status: event.target.checked,
    }));
  };

  /** 링크 이미지 삭제 */
  const handleRemoveImage = () => {
    setState((prev) => ({
      ...prev,
      imageData: undefined,
      isImageChange: false,
    }));
  };

  /** 링크메뉴 저장, 수정 */
  const handleSave = () => {
    if (!props.onSave) return;
    const { name, url, imageData } = state;
    if (name === '') {
      setState((prev) => ({
        ...prev,
        validation: '메뉴명을 입력해 주세요.',
      }));
      return;
    }

    if (!url || url === '') {
      setState((prev) => ({
        ...prev,
        validation: 'URL을 입력해 주세요.',
      }));
      return;
    }

    if (view) {
      props.onSave({
        id: view.id,
        seq: view.seq,
        updateAt: view.updateAt,
        name,
        url,
        status: state.status,
        imageFile: state.isImageChange ? imageData : undefined,
      });
    } else {
      props.onSave({
        name,
        url,
        status: state.status,
        imageFile: imageData,
      });
    }
  };

  return (
    <>
      <Drawer
        title={view ? '링크메뉴 수정' : '링크메뉴 등록'}
        size="sm"
        onClose={props.onClose}
      >
        <DrawerBody>
          <PostWrite>
            <EuiSetting.Content>
              <PostWrite>
                <PostWrite.Item title="">
                  <div
                    className="link-file-wrapper"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="preview-wrapper"
                      style={{
                        border: '1px dashed var(--line-color)',
                        position: 'relative',
                        display: 'flex',
                        padding: '8px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        width: '60px',
                        height: '60px',
                        marginRight: '6px',
                        alignItems: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      {state.imageData ? (
                        <div>
                          <img
                            src={state.imageData}
                            alt="이미지"
                            style={{
                              width: '44px',
                              height: '44px',
                              display: 'flex',
                              flexShrink: 0,
                              borderRadius: '4px',
                            }}
                          />
                          <Button
                            text="삭제"
                            iconType
                            icon="trash-fill"
                            onClick={handleRemoveImage}
                            className="delete"
                            size="xs"
                            style={{
                              position: 'absolute',
                              right: 0,
                              bottom: 0,
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="file-wrapper"
                      style={{ position: 'relative' }}
                    >
                      <Button
                        text="파일 업로드"
                        variant="contained"
                        style={{
                          position: 'relative',
                        }}
                      />
                      <input
                        className=""
                        type="file"
                        onChange={handleChangeImage}
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          fontSize: 0,
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </div>
                  <HelperText text="* 권장 사이즈 : 44 x 44" />
                  <HelperText text="* jpg, png, gif 포맷만 업로드 가능합니다." />
                </PostWrite.Item>
                <PostWrite.Item title="메뉴명" required>
                  <TextField
                    maxLength={20}
                    value={state.name}
                    onChange={(event) => handleTextFieldChange('name', event)}
                  />
                </PostWrite.Item>
                <PostWrite.Item title="URL" required>
                  <TextField
                    placeholder="예) https://www.website-adrress.com"
                    value={state.url ?? ''}
                    onChange={(event) => handleTextFieldChange('url', event)}
                  />
                </PostWrite.Item>
                <PostWrite.Item title="사용여부">
                  <Switch
                    checked={state.status}
                    onChange={handleChangeStatus}
                  />
                </PostWrite.Item>
              </PostWrite>
            </EuiSetting.Content>
          </PostWrite>
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getLocalizedText('저장')}
            onClick={handleSave}
            variant="contained"
          />
        </DrawerAction>
      </Drawer>
      <FeedBack
        text={state.validation}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            validation: '',
          }))
        }
      />
    </>
  );
}

export default ServiceLinkMenuEdit;
