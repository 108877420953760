import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/button/Button';
import PostView from '../../../../../../components/post/PostView';
import { useAppDispatch } from '../../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../../groupware-webapp/stores/session';
import sendNoticeApi from '../../../../../apis/attendance/v1/sendNotice';
import { AttendanceNotice } from '../AttendanceNoticeContainer';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

function AttendanceTeamsNoticeDocumentView(props: {
  view: AttendanceNotice;
  usePlanButtonVisible: boolean;
  search: string;
  onClick(rest: string, isAlertFormSignature: boolean, content?: string): void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const { view } = props;

  const [state, setState] = useState<{
    loading: boolean;
    form: {
      id: number;
      contents: string;
      updateAt?: string;
    };
  }>({
    loading: true,
    form: {
      id: 0,
      contents: '',
      updateAt: '',
    },
  });

  useEffect(() => {
    async function run() {
      try {
        let alertFormType = '';
        switch (view.columnsName) {
          case 'firstAlarmId':
            alertFormType = 'first';
            break;
          case 'workerPlanId':
            alertFormType = 'plan';
            break;
          case 'secondAlarmId':
            alertFormType = 'second';
            break;
          default:
            alertFormType = '';
        }
        /** 선택한 문서 불러오기. */
        const form = await sendNoticeApi.fetchView({
          employeeId: view.employeeId,
          standardDate: view.standardDate,
          alertType: view.alertType,
          alertFormType,
        });
        setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            form: {
              id: form.id,
              contents: form.contents,
              updateAt: form.updateAt,
            },
          };
        });
      } catch (e) {
        // 문서 오류 처리
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        dispatch(sessionActions.error((e as Error).message));
      }
    }
    run();
  }, []);

  const handleUsePlanClick = () => {
    const { contents } = state.form;
    const element = contents.match(
      /<gw-fb-element[^>]+?data-attendance="ATTENDANCE\/SENDNOTICE_REMAIN_COUNT"[^>]*>(.*?)<\/gw-fb-element>/gs,
    );
    const alertSignature = contents.match(
      /(<gw-fb-element[^>]+?data-attendance="ATTENDANCE\/SENDNOTICE_SIGNATURE"[^>]*>)(.*?)<\/gw-fb-element>/gs,
    ); // 연차촉진 대상자 서명 매크로
    const rest = element ? element[0].replace(/(<([^>]+)>)/gi, '') : ''; // 잔여연차
    props.onClick(
      rest,
      !!alertSignature,
      alertSignature ? contents : undefined,
    );
  };

  return (
    <>
      {state.loading ? (
        <></>
      ) : (
        <PostView type="full">
          <PostView.Body>
            <PostView.Content data={state.form.contents} />
          </PostView.Body>
          {view.columnsName === 'firstAlarmId' && props.usePlanButtonVisible ? (
            <div>
              <Button
                text={getLocalizedText('사용계획서 제출')}
                variant="contained"
                placement="top-start"
                onClick={handleUsePlanClick}
              />
            </div>
          ) : undefined}
        </PostView>
      )}
    </>
  );
}

export default AttendanceTeamsNoticeDocumentView;
