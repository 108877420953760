import React from 'react';
import { useSelector } from 'react-redux';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import { IconType } from '../../../../../groupware-common/types/icon';
import { getPathParams, go } from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function SettingDrawer(props: { pathname: string }): JSX.Element {
  const { pathname } = props;
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );

  const categories = useSelector(
    (state: RootState) => state.setting.information.categories,
  );

  // 고정 폴더.
  const getDefaultFolderData = (type: 'name' | 'icon', id: string) => {
    const category = categories.find((a) => a.id === id);
    if (!category) return undefined;
    if (type === 'icon') return category.icon;
    return getLocalizedText(`폴더.${category.name}`);
  };

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title={getLocalizedText('설정')} />
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <Nav>
          <MenuItem
            label={getDefaultFolderData('name', 'information')}
            icon={getDefaultFolderData('icon', 'information') as IconType}
            selected={folderId === 'information'}
            onClick={() => {
              go('/setting/information');
              dispatch(sessionActions.mobileNav(false));
            }}
          />
        </Nav>
        <Nav title={getLocalizedText('환경설정')}>
          <MenuItem
            label={getDefaultFolderData('name', 'setting')}
            icon={getDefaultFolderData('icon', 'setting') as IconType}
            selected={folderId === undefined}
            onClick={() => {
              go('/setting');
              dispatch(sessionActions.mobileNav(false));
            }}
          />
          {/* <MenuItem
            label={getDefaultFolderData('name', 'alarm')}
            icon={getDefaultFolderData('icon', 'alarm') as IconType}
            selected={folderId === 'alarm'}
            onClick={() => {
              go('/setting/alarm');
              dispatch(sessionActions.mobileNav(false));
            }}
          /> */}
        </Nav>
      </EuiSnb.Nav>
    </>
  );
}

export default SettingDrawer;
