import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/button/Button';
import Drawer from '../../../../components/drawer/Drawer';
import DrawerAction from '../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../components/post/PostWrite';
import DropMenu from '../../../../components/selectField/DropMenu';
import Switch from '../../../../components/switch/Switch';
import TextField from '../../../../components/textfield/TextField';
import { RootState as R } from '../../../../groupware-webapp/app/store';
import { CalendarSubFolderView } from '../../../stores/calendar/calendars';
import Menu from '../../../../components/menu/Menu';
import FeedBack from '../../../../components/alert/FeedBack';
import RadioGroup from '../../../../components/radio/RadioGroup';
import { AuthorityType } from '../../../apis/calendar/v1/calendars';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

function CalendarSubFolderEditDrawer(props: {
  view: CalendarSubFolderView;
  onSave(data: CalendarSubFolderView): void;
  onClose(): void;
}): JSX.Element {
  const { view, onClose } = props;
  const display = useSelector((s: R) => s.session.display);
  const colorList = useSelector((s: R) => s.calendar.preferences.color);
  const useExposeAnniversaryData = [
    {
      value: true,
      label: getLocalizedText('공휴일 및 기타 휴일'),
    },
    { value: false, label: getLocalizedText('공휴일만') },
  ];
  const authorityTypeData = [
    { value: 'COMPANY', label: getLocalizedText('회사') },
    { value: 'ORGANIZATION', label: getLocalizedText('부서') },
    {
      value: 'SUB_ORGANIZATION',
      label: getLocalizedText('부서 (하위부서 포함)'),
    },
    { value: 'EMPLOYEE', label: getLocalizedText('개인') },
  ];

  const [state, setState] = useState<{
    name: string;
    color: string;
    description: string;
    status: boolean;
    useExposeAnniversary: boolean;
    managerAuthority: string;
    generalUserAuthority: string;

    colorMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>({
    name: view.name,
    color: view.color,
    description: view.description,
    status: view.status,
    useExposeAnniversary: view.useExposeAnniversary ?? false,
    managerAuthority: view.managerAuthority ?? 'SUB_ORGANIZATION',
    generalUserAuthority: view.generalUserAuthority ?? 'EMPLPOYEE',
    colorMenuPoint: undefined,
  });
  const [validation, setValidation] = useState('');

  /** 캘린더명 변경 이벤트. */
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 50) {
      setValidation(getLocalizedText('캘린더명은 50자를 넘길 수 없습니다.'));
      return;
    }

    setState((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  /** 설명 변경 이벤트. */
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (event.target.value.length > 250) {
      setValidation(getLocalizedText('설명은 250자를 넘길 수 없습니다.'));
      return;
    }

    setState((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  /** 사용여부 변경 이벤트. */
  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      status: event.target.checked,
    }));
  };

  /** 색상 선택 메뉴 열기. */
  const handleOpenColorMenu = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    const { colorMenuPoint } = state;
    if (event !== undefined && colorMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        colorMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        colorMenuPoint: undefined,
      }));
    }
  };

  /** 색상 선택 메뉴 닫기. */
  const handleCloseColorMenu = () => {
    setState((prev) => ({
      ...prev,
      colorMenuPoint: undefined,
    }));
  };

  /** 색상 선택 이벤트. */
  const handleClickColor = (value: string) => {
    setState((prev) => ({
      ...prev,
      color: value,
      colorMenuPoint: undefined,
    }));
  };

  /** 근태 캘린더 - 부서장 권한 변경 이벤트. */
  const handleChangeManagerAuthority = (managerAuthority: string) => {
    setState((prev) => ({ ...prev, managerAuthority }));
  };

  /** 근태 캘린더 - 직원 권한 변경 이벤트. */
  const handleChangeGeneralUserAuthority = (generalUserAuthority: string) => {
    setState((prev) => ({ ...prev, generalUserAuthority }));
  };

  /** 휴일 캘린더 - 캘린더 내용 변경 이벤트. */
  const handleChangeUseExposeAnniversary = (useExposeAnniversary: boolean) => {
    setState((prev) => ({ ...prev, useExposeAnniversary }));
  };

  /** 저장 */
  const handleSave = () => {
    if (state.name.trim() === '') {
      setValidation(getLocalizedText('캘린더명을 입력하세요.'));
      return;
    }
    props.onSave({
      id: view.id,
      systemId: view.systemId,
      name: state.name,
      description: state.description,
      color: state.color,
      status: state.status,
      managerAuthority: state.managerAuthority as AuthorityType,
      generalUserAuthority: state.generalUserAuthority as AuthorityType,
      useExposeAnniversary: state.useExposeAnniversary,
      updateAt: view.updateAt,
    });
  };

  return (
    <>
      <Drawer title={getLocalizedText('캘린더 수정')} onClose={onClose}>
        <DrawerBody className="ui-calendar-company">
          <PostWrite>
            <PostWrite.Item title={getLocalizedText('캘린더명')}>
              <TextField
                count
                maxLength={50}
                value={state.name}
                onChange={handleChangeName}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('색상')}>
              <DropMenu
                label={getLocalizedText('색상')}
                useChip
                value={state.color}
                onClick={handleOpenColorMenu}
              />
              {state.colorMenuPoint && (
                <Menu
                  className="calendar-color-menu"
                  width={185}
                  point={state.colorMenuPoint}
                  onClose={handleCloseColorMenu}
                >
                  <div
                    style={
                      display !== 'phone'
                        ? { display: 'inline-block' }
                        : undefined
                    }
                  >
                    {colorList.map((a, i) => {
                      const selected = state.color === a.rgb;
                      const key = `${i}_${a.name}`;
                      if (display === 'phone')
                        return (
                          <div
                            onClick={() => handleClickColor(a.rgb)}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              margin: '10px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 500,
                                fontSize: 'inherit',
                                fontFamily: 'inherit',
                                height: '30px',
                              }}
                            >
                              <i
                                className="eui-icon eui-icon-square-fill nav-badge"
                                style={{
                                  color: `${a.rgb}`,
                                  marginRight: '4px',
                                }}
                              />
                              <span>{a.name}</span>
                            </div>
                            <Button
                              style={{ marginLeft: 'auto' }}
                              iconType
                              icon={selected ? 'check' : undefined}
                            />
                          </div>
                        );
                      return (
                        <div
                          key={key}
                          onClick={() => handleClickColor(a.rgb)}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: `${a.rgb}`,
                            display: 'inline-block',
                            width: '25px',
                            height: '25px',
                            float: 'left',
                            borderRadius: '2px',
                            margin: '3px 0 3px 3px',
                          }}
                        >
                          <label className={selected ? 'checked' : undefined} />
                        </div>
                      );
                    })}
                  </div>
                </Menu>
              )}
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('설명')}>
              <TextField
                multiline
                count
                maxLength={250}
                rows={2}
                value={state.description}
                onChange={handleChangeDescription}
              />
            </PostWrite.Item>
            <PostWrite.Item title={getLocalizedText('사용여부')}>
              <Switch checked={state.status} onChange={handleChangeStatus} />
            </PostWrite.Item>
            {view.systemId === 'ATTENDANCE' ? (
              <>
                <PostWrite.Item title={getLocalizedText('부서장 보기권한')}>
                  <RadioGroup
                    data={authorityTypeData}
                    value={state.managerAuthority}
                    name="managerAuthority"
                    onChange={handleChangeManagerAuthority}
                  />
                </PostWrite.Item>
                <PostWrite.Item title={getLocalizedText('사원 보기권한')}>
                  <RadioGroup
                    data={authorityTypeData}
                    value={state.generalUserAuthority}
                    name="generalUserAuthority"
                    onChange={handleChangeGeneralUserAuthority}
                  />
                </PostWrite.Item>
              </>
            ) : (
              <PostWrite.Item title={getLocalizedText('캘린더 내용')}>
                <RadioGroup
                  data={useExposeAnniversaryData}
                  value={state.useExposeAnniversary}
                  name="useExposeAnniversary"
                  onChange={handleChangeUseExposeAnniversary}
                />
              </PostWrite.Item>
            )}
          </PostWrite>
        </DrawerBody>
        <DrawerAction>
          <Button
            noDuplication={validation === ''}
            text={getLocalizedText('저장')}
            variant="contained"
            onClick={() => handleSave()}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </>
  );
}

export default CalendarSubFolderEditDrawer;
