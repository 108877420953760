import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import noticeApi from '../../apis/attendance/v1/notice';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';

const name = 'attendance/notice';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface noticeListItem {
  employeeId: number; // 사원 아이디
  standardDate: string; // 기준 일자
  alertType: number; // 연차촉진 알림 구분(1 : 1차, 2 : 2차, 0 :일반연차)
  firstAlarmSendDate: string; // 1차 촉진 알림 발송일
  firstAlarmId: number; // 1차 촉진 알림 발송 아이디
  firstAlarmSender: number; // 1차 촉진 알림 발송자 아이디 (알림 담당자)
  workerPlanSendDate: string; // 근로자 연차 사용 계획서 발송 일
  workerPlanId: number; // 근로자 연차 사용 계획서 발송 아이디
  secondAlarmSendDate: string; // 2차 지정 통보 알림 발송일
  secondAlarmId: number; // 2차 지정 통보 알림 발송 아이디
  updateAt: string; // 수정 시간
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: {
    items: noticeListItem[];
    totalCount: number;
  };
}

const initialState: State = {
  requests: [],
  list: {
    items: [],
    totalCount: 0,
  },
};

/** 연차사용촉진알림 목록 조회. */
const findNoticeList = createAsyncThunk(
  `${name}/findNoticeList`,
  async (
    arg: {
      employeeId: number;
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { employeeId } = arg;
      const items = await noticeApi.fetchList({
        employeeId,
        pageNo: 1,
        rowsPerPage: 15,
      });
      const totalCount = await noticeApi.totalCount({ employeeId });

      return {
        items,
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연차사용계획서 제출. */
const saveUsePlan = createAsyncThunk(
  `${name}/saveUsePlan`,
  async (
    data: {
      arg: {
        employeeId: number;
        alertType: number;
        standardDate: string;
        contents: string;
        firstAlarmContents?: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { arg } = data;

      const response = await noticeApi.createUsePlan(arg);
      if (response.id === undefined)
        return rejectWithValue(
          appError({
            error: getLocalizedText('발송 오류 발생'),
          }),
        );

      if (data.route) {
        await dispatch(
          findNoticeList({ employeeId: arg.employeeId, search: '' }),
        );
      }
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const attendanceNoticeReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findNoticeList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.list = payload;
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default attendanceNoticeReducer.reducer;

export const noticeActions = {
  list: findNoticeList,
  saveUsePlan,
};
