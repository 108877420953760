import React from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import ja from 'date-fns/locale/ja';
import zh from 'date-fns/locale/zh-CN';
import { useSelector } from 'react-redux';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import { RootState } from '../../groupware-webapp/app/store';

function CustomDatePicker(
  props: ReactDatePickerProps & {
    width?: number | string;
    controlClassName?: string;
  },
): JSX.Element {
  const { ...params } = props;
  const display = useSelector((state: RootState) => state.session.display);
  const currentTimeZone = useSelector(
    (state: RootState) => state.session.basicSetting.currentTimeZone,
  );
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (display === 'phone') {
      event.target.blur();
    }
  };

  switch (currentLanguage) {
    case 'ja-JP':
      registerLocale('language', ja);
      break;
    case 'zh-CN':
      registerLocale('language', zh);
      break;
    case 'en-US':
      registerLocale('language', en);
      break;
    default:
      registerLocale('language', ko);
      break;
  }

  let classname = '';

  if (params.todayButton) classname += ' hasTodayButton';
  if (params.showTimeSelect) classname += ' hasTimeSelect';
  if (params.showTimeInput) classname += ' hasTimeInput';
  if (params.monthsShown) classname += ' hasMultiMonths';
  if (params.dropdownMode) classname += ' hasDropdown';
  if (params.monthsShown === 12) classname += ' react-datepicker-1-year';
  // 사용자가 설정한 시간대와 한국 시간대가 일치하지 않는 경우.
  if (currentTimeZone !== 9) classname += ' notUseTodaySetting';
  if (currentLanguage === 'en-US' || currentLanguage === 'vi-VN')
    classname += ' right';

  const style: React.CSSProperties | undefined =
    props.width !== undefined ? { width: props.width } : undefined;

  let controlClassName = 'eui-datepicker-control';
  if (props.controlClassName) controlClassName += ` ${props.controlClassName}`;

  params.calendarClassName = classname;
  params.locale = params.locale === undefined ? 'language' : params.locale;
  // params.portalId = params.portalId === undefined ? 'portals' : params.portalId;
  params.dateFormatCalendar =
    params.dateFormatCalendar === undefined
      ? getLocalizedText('datePicker.yyyy년 M월')
      : params.dateFormatCalendar;
  params.showDisabledMonthNavigation =
    params.showDisabledMonthNavigation === undefined
      ? true
      : params.showDisabledMonthNavigation;
  params.fixedHeight =
    params.fixedHeight === undefined ? true : params.fixedHeight;
  params.highlightDates = params.highlightDates
    ? params.highlightDates
    : undefined;
  /* params.showMonthDropdown =
    params.showMonthDropdown === undefined ? true : params.showMonthDropdown;
  params.showYearDropdown =
    params.showYearDropdown === undefined ? true : params.showYearDropdown;
  params.dropdownMode =
    params.dropdownMode === undefined ? 'select' : params.dropdownMode;

  params.todayButton =
    params.todayButton === undefined ? getLocalizedText('오늘') : params.todayButton;
  params.calendarStartDay =
    params.calendarStartDay === undefined ? 0 : params.calendarStartDay; */
  return (
    <div className={controlClassName} style={style}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DatePicker {...params} onFocus={handleFocus} />
    </div>
  );
}

export default CustomDatePicker;
