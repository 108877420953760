import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostListDensity } from '../../../components/post/PostList';
import {
  AttachDocument,
  AttachFile,
  CommentDeleteArgApi,
  CommentSaveArgApi,
  documentApi,
  LinkType,
  OpinionDeleteArgApi,
  OpinionSaveArgApi,
  SharedFile,
} from '../../../groupware-approval/apis/approval/v1/document';
import { DocumentItem as DocumentApiItem } from '../../../groupware-approval/apis/approval/v1/permission';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
  getLastApprover,
  SharePermissionType,
} from '../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { documentMacroReplace } from '../../../groupware-approval/pages/root/approval/compose/ApprovalComposeContainer';
import {
  CommentItem,
  jsonToApprovalLine,
} from '../../../groupware-approval/stores/approval/document';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import { dateFormat } from '../../../groupware-common/utils/ui';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { ReadingPaneMode } from '../../../groupware-webapp/stores/types';
import securitiesApi from '../../apis/sangsanginsecurities/v1/securities';

const name = 'sangsanginsecurities/authority';

interface DocumentItem extends DocumentApiItem {
  listStatus: string;
}

export interface AuthorityListItem {
  checked: boolean; // 체크 여부.
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 아이디.
  workName: string; // 업무 이름.
  documentNo: string; // 문서번호.
  subject: string; // 제목.
  approvalLine: ApprovalLineType; // 결재선.
  attachments: Array<string>; // 첨부 파일 목록.
  completeAt?: string; // 완료일.
  opinions: number; // 의견 수.
  comments: number; // 댓글 수.
  createAt: string; // 작성일.
  updateAt: string; // 수정일.
  // urgent: boolean; // 긴급 여부.
  linkWait?: boolean; // 연동 대기 여부.
  isIncommingOrOutgoing?: boolean; // 수발신 문서 여부 (문서 삭제 확인용)
  listStatus?: string;
}

export interface AuthorityViewItem {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 문서 아이디 = documentId
  parentCompanyId?: number; // 부모 문서 회사 아이디
  parentId?: number; // 부모 문서 아이디
  parentStatus?: number; // 부모 문서 상태값
  parentApprovalLine?: ApprovalLineType; // 부모 문서 결재선.
  parentAffiliatedCompanyId?: number; // 부모 문서 관계사 회사 아이디.
  parentUpdateAt?: string; // 부모 문서 수정 날짜.
  parentDrafteAt?: string; // 부모 문서 기안 날짜.
  parentCompleteAt?: string; // 부모 문서 완료 날짜.
  workId: number; // 업무 아이디.
  formId: number; // 양식 아이디.
  workName: string; // 업무 이름.
  formName: string; // 양식 이름.
  status: string; // 결재상태 - DRAFT: 기안, PROGRESS: 진행, REJECT: 반려, COMPLETE: 완료, WITHDRAW: 회수
  no: string; // 문서번호.
  subject: string; // 제목
  content: string; // 내용
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  draftAt: string; // 기안 날짜
  completeAt?: string; // 완료 날짜
  retentionPeriod: string; // 보존기간.
  option: number; // 업무 옵션.
  isTopLevelOrganizationNameNumbering: boolean; // 최상위 조직 이름 채번 여부.
  isNumberingAtCompose: boolean; // 작성 시점에 채번 여부.
  isSyncIncomingAndOutgoing: boolean; // 접수 후 내부 결재 시 부모 문서번호 승계 여부.
  isApprovalboxSignatureImage: boolean; // 결재란에 서명 이미지 사용 여부.
  change: boolean; // 문서 변경 유무
  isReReceipt: boolean; // 반려된 내부문서 재접수 여부.
  isReDraft: boolean; // 재기안 여부.
  linkType: LinkType; // 연동 유형. (NONE: '없음', ATTENDANCE: '근태')
  linkId: string; // 연동 아이디.
  linkWait: boolean; // 연동 대기 여부. (true: 대기, false: 대기 없음)
  attachedFiles?: AttachFile[]; // 첨부 파일 배열.
  sharedFiles?: SharedFile[]; // 연동결재 공유 파일 배열.
  attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
  opinions?: (CommentItem & { act: number })[]; // 의견 배열.
  comments?: CommentItem[]; // 댓글 배열.
  updateAt: string;
  isOutgoing?: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt?: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt?: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  receiptFormId?: number;
  creatorId: number;
  publicOrNot: boolean;
}

export type OpinionSaveArg = OpinionSaveArgApi;
export type CommentSaveArg = CommentSaveArgApi;
export type OpinionDeleteArg = OpinionDeleteArgApi;
export type CommentDeleteArg = CommentDeleteArgApi;
export type AttachFileItem = AttachFile;
export type AttachDocumentItem = AttachDocument;

interface State {
  displayDensity: 'normal' | 'narrow' | 'wide';
  readingPaneMode: ReadingPaneMode;

  list: {
    items: AuthorityListItem[];
    totalCount: number;
  };

  view: AuthorityViewItem | undefined;
}

const initialState: State = {
  displayDensity: 'normal',
  readingPaneMode: 'list',
  list: {
    items: [],
    totalCount: 0,
  },
  view: undefined,
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      search: string;
      force?: true;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      if (companyId === undefined) throw new Error('companyId is undefined.');

      const queryParams = getQueryParams(arg.search);

      const { searchCode, searchWord, pageNo, rowsPerPage } = queryParams;
      const listStatus = searchCode ?? 'approval';
      const folderIdObj: { [key: string]: number } = {
        approval: 1002,
        progress: 1003,
        complete: 1004,
      };
      const folderId = folderIdObj[listStatus];
      const params = {
        companyId,
        employeeId,
        folderId,
        draftorganizationname: searchWord,
        pageno: pageNo ?? 1,
        rowsperpage: rowsPerPage ?? 15,
      };
      const data = await securitiesApi.documentList(params);
      const items: DocumentItem[] = data.list.map((a) => ({
        ...a,
        listStatus,
      }));
      const totalCount = data.count;
      const autholityItem = items.map((a) => {
        return {
          checked: false, // 체크 여부.
          affiliatedCompanyId:
            a.affiliatedCompanyId === null ? undefined : a.affiliatedCompanyId, // 관계사 아이디.
          id: a.id, // 아이디.
          workName: a.workName, // 업무 이름.
          documentNo: documentMacroReplace(a.number), // 문서 번호
          subject: a.subject, // 제목.
          approvalLine: jsonToApprovalLine(a.approvalline), // 결재선.
          attachments: [], // 첨부 파일 목록.
          opinions: a.opinionCount, // 의견 수.
          comments: a.commentCount, // 댓글 수.
          updateAt: a.updateAt, // 수정일.
          createAt: a.createAt, // 작성일.
          linkWait: a.linkWait,
          completeAt:
            a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
          listStatus: a.listStatus,
        };
      });

      return {
        data: {
          items: autholityItem,
          totalCount,
          search: arg.search,
        },
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 반려 그룹 */
function getReturnApprover(approvalLine: ApprovalLineType) {
  const returnGroup = approvalLine.groups.find((group) => {
    const item =
      group.type === 'agree'
        ? group.items.find(({ option, act }) => {
            return act === 'return' && option !== true;
          })
        : group.items.find(({ act }) => {
            return act === 'return';
          });
    return item !== undefined;
  });

  return returnGroup?.items.find((item) => item.act === 'return');
}

/** 문서 완료일 구하기. */
function getCompleteAt(approvalLine: ApprovalLineType): string | undefined {
  // 반려한 문서일 경우 반려 처리된 날짜 return.
  const returnApprover = getReturnApprover(approvalLine);
  if (returnApprover !== undefined) return returnApprover.actAt;

  const lastApprover = getLastApprover(approvalLine);
  if (Array.isArray(lastApprover)) {
    const sortedlastApprover = lastApprover
      .filter(({ act }) => act !== undefined && act !== 'receipt')
      .sort((a, b) => {
        if (a.actAt === undefined || b.actAt === undefined) return 0;
        if (a.actAt > b.actAt) return -1;
        if (a.actAt < b.actAt) return 1;
        return 0;
      });

    if (sortedlastApprover.length === lastApprover.length)
      return sortedlastApprover[sortedlastApprover.length - 1].actAt;
  } else if (
    lastApprover?.act !== undefined &&
    lastApprover?.act !== 'receipt'
  ) {
    return lastApprover?.actAt;
  }

  return undefined;
}

/** 문서 진행 여부. */
function isProgress(approvalLine: ApprovalLineType): boolean {
  return getCompleteAt(approvalLine) === undefined;
}

/** 문서 관계사 회사 아이디 구하기 */
function getAffiliatedCompanyId(
  documentCompanyId: number,
  companyId: number,
): number | undefined {
  if (documentCompanyId !== companyId) return documentCompanyId;
  return undefined;
}

const findView = createAsyncThunk(
  `${name}/findView`,
  async (arg: { id: number } & LocateArg, { rejectWithValue, getState }) => {
    try {
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      let data: AuthorityViewItem | undefined;

      const { id } = arg;

      if (typeof id !== 'number')
        return rejectWithValue({
          error: 'error',
          path: '',
          status: 500,
          timestamp: '',
        });

      let parentResponse:
        | {
            companyId: number;
            id: number;
            approvalLine: string;
            updateAt: string;
          }
        | undefined;

      const response = await securitiesApi.documentData({
        companyId,
        employeeId,
        documentId: id,
      });

      if (response) {
        if (response.id !== id) return undefined;
        // 첨부파일 사용 1 0000 0001
        // 첨부파일 필수 2 0000 0010
        // 첨부문서 사용 4 0000 0100
        // 첨부문서 필수 8 0000 1000
        // 의견 사용    16 0001 0000
        // 의견 필수    32 0010 0000
        // 댓글 사용    64 0100 0000
        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const {
          attachedSharedFileCount,
          attachedFileCount,
          attachedDocumentCount,
        } = response;

        const opinions = response.opinionCount
          ? await documentApi.fetchOpinionList(companyId, id)
          : [];
        const comments = response.commentCount
          ? await documentApi.fetchCommentList(companyId, id)
          : [];

        let parentStatus: number | undefined;
        let parentApprovalLine: ApprovalLineType | undefined;
        let parentAffiliatedCompanyId: number | undefined;
        let parentDrafteAt: string | undefined;
        let parentCompleteAt: string | undefined;
        if (response.parentId) {
          parentResponse = await documentApi.fetchApprovalLine(
            companyId,
            response.parentId,
          );
          if (parentResponse) {
            const approvalLine = jsonToApprovalLine(
              parentResponse.approvalLine,
            );
            parentApprovalLine = approvalLine;
            parentStatus = isProgress(approvalLine) === true ? 1 : undefined;
            parentAffiliatedCompanyId = getAffiliatedCompanyId(
              parentResponse.companyId,
              companyId,
            );
            parentDrafteAt = getApprovalLineDrafter(approvalLine)?.actAt;
            parentCompleteAt = getCompleteAt(approvalLine);
          }
        }

        const attachedFiles: AttachFile[] = [];
        if (attachedFileCount > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          const attachedUrlList = await documentApi.fetchAttachfileURLList(id);

          attachedList.forEach((x) => {
            const attachedURL = attachedUrlList.find((a) => x.id === a.id);
            attachedFiles.push({
              ...x,
              url: attachedURL?.url,
              isFileprotection: attachedURL?.isFileprotection,
            });
          });
          attachedFiles.sort(
            (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
          );
        }

        let sharedFiles: SharedFile[] = [];
        if (attachedSharedFileCount > 0) {
          sharedFiles = await documentApi.fetchSharedFileList(id);
        }

        data = {
          ...response,
          parentId: response.parentId ? response.parentId : undefined,
          publicOrNot: response.isPublic,
          parentStatus,
          parentApprovalLine,
          parentAffiliatedCompanyId,
          parentUpdateAt: parentResponse?.updateAt,
          parentDrafteAt: parentDrafteAt
            ? dateFormat(parentDrafteAt, 'YYYY-MM-DD')
            : undefined,
          parentCompleteAt: parentCompleteAt
            ? dateFormat(parentCompleteAt, 'YYYY-MM-DD')
            : undefined,
          approvalLine: jsonToApprovalLine(response.approvalline),
          referencePermission:
            response.referencePermission === '{}'
              ? undefined
              : JSON.parse(response.referencePermission),
          viewPermission:
            response.viewPermission === '{}'
              ? undefined
              : JSON.parse(response.viewPermission),
          completeAt:
            response.completeAt === '1000-01-01'
              ? undefined
              : response.completeAt,
          attachedFiles,
          sharedFiles,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion ? opinions : undefined,
          comments: useComment ? comments : undefined,
          no: response.number,
          // eslint-disable-next-line no-bitwise
          isTopLevelOrganizationNameNumbering: (response.setting & 8) === 8, // 최상위 조직 이름 채번 사용: 8
          // eslint-disable-next-line no-bitwise
          isNumberingAtCompose: (response.setting & 1) === 1, // 작성 시점에 채번: 1, 완료 시점에 채번: 2
          // eslint-disable-next-line no-bitwise
          isSyncIncomingAndOutgoing: (response.setting & 4) === 4, // 접수 후 내부 결재 시 부모 문서번호 승계: 4
          // eslint-disable-next-line no-bitwise
          isApprovalboxSignatureImage: (response.setting & 16) === 16, // 결재란에 서명 이미지 사용: 16
          // eslint-disable-next-line no-bitwise
          isReReceipt: (response.formWorkChange & 4) === 4, // 반려된 내부문서 재접수: 4,
          // eslint-disable-next-line no-bitwise
          isReDraft: response.formWorkChange !== 0 || response.linkId !== '', // 재기안 여부.
        };
      }
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const authoritiesReducer = createSlice({
  name,
  initialState,
  reducers: {
    setListItemChecked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (action.payload.itemId === 'all') {
        state.list.items = state.list.items.map((x) => {
          return { ...x, checked: action.payload.checked };
        });
      } else {
        const index = state.list.items.findIndex(
          (x) => x.id === action.payload.itemId,
        );
        if (index > -1) {
          state.list.items[index] = {
            ...state.list.items[index],
            checked: action.payload.checked,
          };
        }
      }
    },
    setReadingPaneMode(state, action: PayloadAction<ReadingPaneMode>) {
      if (state.readingPaneMode !== action.payload) {
        state.readingPaneMode = action.payload;
      }
    },
    setDisplayDensity(state, action: PayloadAction<PostListDensity>) {
      state.displayDensity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload.data !== undefined)
          state.list = {
            items: payload.data.items,
            totalCount: payload.data.totalCount,
          };
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      });
  },
});

export default authoritiesReducer.reducer;

export const authoritiesActions = {
  setListItemChecked: authoritiesReducer.actions.setListItemChecked,
  setReadingPaneMode: authoritiesReducer.actions.setReadingPaneMode,
  displayDensity: authoritiesReducer.actions.setDisplayDensity,

  list: findList,
  view: findView,
};
