import moment from 'moment';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import PostWrite from '../../../../../components/post/PostWrite';
import MemoizeDivElement from '../../../../../groupware-approval/pages/root/approval/common/components/MemoizeDivElement';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  dateFormat,
  timeFormat,
} from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { employeeActions } from '../../../../../groupware-directory/stores/directory/employee';

function AttendanceSendNoticeDocumentWriteDialog(props: {
  search: string;
  senderName: string;
  employeeId: number;
  rest: number;
  onClose(): void;
  onClick(content: string): void;
}): JSX.Element | null {
  const dispatch = useAppDispatch();
  const directory = useDirectory();

  const notice = useSelector(
    (state: RootState) => state.attendance.preferences.notice,
  );
  const principal = useSelector((state: RootState) => state.session.principal);
  const employeeView = useSelector(
    (s: RootState) => s.directory.employee.view.data,
  );
  const companyView = useSelector((s: RootState) => {
    return s.directory.company.view.data;
  });
  const { expressionUnit } = useSelector(
    (s: RootState) => s.attendance.preferences.basic,
  );
  const workingHours = useSelector(
    (s: RootState) => s.attendance.preferences.workingHours,
  );
  const queryParams = getQueryParams(props.search);
  const contentRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<{
    loading: boolean;
    contents: string;
    validation: string;
  }>({
    loading: true,
    contents: '',
    validation: '',
  });

  useEffect(() => {
    async function run() {
      dispatch(
        employeeActions.findView({
          id: props.employeeId,
        }),
      ).then(() => {
        let contents = '';
        if (queryParams.dialogType === 'notice')
          contents = notice.alertFormContents;
        else if (queryParams.dialogType === 'planNotify')
          contents = notice.planNotifyFormContents;

        setState((prev) => ({
          ...prev,
          contents,
        }));
      });
    }
    run();
  }, []);

  const getMacro = (arg: { id: string }) => {
    if (employeeView === null || employeeView === undefined) return undefined;
    const { id } = arg;
    const directoryData = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId: props.employeeId,
    });

    // 회사명. _CMP_NAME_
    if (id === 'DIRECTORY/COMAPNY') {
      return directoryData.companyName;
    }
    // 회사 우편번호.
    if (id === 'DIRECTORY/COMPANY_POSTALCODE') {
      return companyView?.postalCode ?? '';
    }
    // 회사주소.
    if (id === 'DIRECTORY/COMPANY_ADDRESS') {
      return companyView?.address ?? '';
    }
    // 회사전화.
    if (id === 'DIRECTORY/COMPANY_PHONE_NO') {
      return companyView?.phoneNo ?? '';
    }
    // 회사팩스.
    if (id === 'DIRECTORY/COMPANY_FAX_NO') {
      return companyView?.faxNo ?? '';
    }
    // 오늘날짜.
    if (id === 'COMMON/TODAY') {
      return dateFormat(new Date(), 'YYYY-MM-DD');
    }
    /** 연차촉진 발송자명 */
    if (id === 'ATTENDANCE/SENDNOTICE_SENDER_NAME') return props.senderName;
    /** 연차촉진 대상자명 */
    if (id === 'ATTENDANCE/SENDNOTICE_NAME') return directoryData.employeeName;
    /** 연차촉진 대상자사번 */
    if (id === 'ATTENDANCE/SENDNOTICE_EMPLOYEENO') return employeeView.no;
    /** 연차촉진 대상자부서 */
    if (id === 'ATTENDANCE/SENDNOTICE_ORGANIZATION')
      return directoryData.organizationName;
    /** 연차촉진 대상자직위 */
    if (id === 'ATTENDANCE/SENDNOTICE_JOBPOSITION')
      return directoryData.jobPositionName;
    /** 연차촉진 대상자입사일자 */
    if (id === 'ATTENDANCE/SENDNOTICE_ENTERDATE') return employeeView.enterDate;
    /** 연차촉진 잔여연차 */
    if (id === 'ATTENDANCE/SENDNOTICE_REMAIN_COUNT')
      return timeFormat(props.rest ?? 0, expressionUnit);
    /** 연차촉진 대상자서명 */
    if (id === 'ATTENDANCE/SENDNOTICE_SIGNATURE') return '';

    return undefined;
  };

  /** 콘텐트 로드. */
  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { element } = arg;
      element.innerHTML = FormBuilder.createComposeHtml({
        html: state.contents,
        expressionUnit,
        workingHours,
        getMacro,
      });
    },
    [state.contents],
  );

  /** 매크로 필수 입력값 입력안했을 경우 */
  function createComposeStyle(
    data: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  ) {
    const element = data;
    element.focus();
    element.style.background = '#ffebee';
  }

  /** 발송 버튼 클릭. */
  const handleClick = () => {
    if (contentRef.current === null) {
      const validation = getLocalizedText('작성 문서를 읽어올 수 없습니다.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    // 총사용일수 매크로의 설정이 필수일 때만
    const element = contentRef.current.querySelector(
      'gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL"]',
    );
    if (element && element.getAttribute('required') === 'true') {
      const totalUseElement = contents.match(
        /<gw-fb-element[^>]+?data-attendance="ATTENDANCE\/SENDNOTICE_SCHEDULED_DATE_TOTAL"[^>]*>(.*?)<\/gw-fb-element>/gs,
      );

      const totalUse = totalUseElement
        ? totalUseElement[0].replace(/(<([^>]+)>)/gi, '')
        : '';

      if (totalUse !== timeFormat(props.rest ?? 0, expressionUnit)) {
        const input = element.querySelector('input');
        if (input === null) return;
        createComposeStyle(input);
        const validation = getLocalizedText(
          '사용계획 잔여연차와 연차촉진 총사용일수 값은 다를 수 없습니다.',
        );
        setState((prevState) => ({ ...prevState, validation }));
        return;
      }
    }

    let validation = '';
    contentRef.current
      .querySelectorAll(
        'gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE"]',
      )
      .forEach((elem) => {
        const dateElement = elem.querySelector(
          'input[type="date"]',
        ) as HTMLInputElement | null;
        if (dateElement === null) return;
        const today = moment().format('YYYY-MM-DD');
        const date = dateElement.getAttribute('value');
        if (!date) {
          createComposeStyle(dateElement);
          validation = getLocalizedText('날짜가 선택되지 않았습니다.');
        } else if (date < today) {
          createComposeStyle(dateElement);
          validation = getLocalizedText('지난 날짜는 선택하실 수 없습니다.');
        }
      });
    if (validation !== '') {
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    props.onClick(contents);
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  return (
    <>
      <Dialog onClose={props.onClose}>
        <DialogBody>
          <PostWrite name="posting" fullSize>
            <PostWrite.Body>
              <PostWrite.Edit>
                <MemoizeDivElement
                  ref={contentRef}
                  onLoad={handleContentLoad}
                />
              </PostWrite.Edit>
            </PostWrite.Body>
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          <Button
            text={getLocalizedText('발송')}
            variant="contained"
            onClick={handleClick}
          />
        </DialogFooter>
      </Dialog>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceSendNoticeDocumentWriteDialog;
