import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { dateSimplify } from '../../../../../../groupware-common/utils/ui';
import { IconType } from '../../../../../../groupware-common/types/icon';
import { DocumentPostResponse } from '../../../../../apis/v1/dashboard/templates';
import { useDirectory } from '../../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { TemplateKey } from '../../../../../constants/templates';
import { b62 } from '../../../../../../groupware-common/utils';
import {
  DocumentFolderCategories,
  getFolderIdByKey,
} from '../../../../../stores/templates';

type DocumentPostResponseData = Pick<
  DocumentPostResponse,
  'data' | 'responseType'
>;
function DocumentPostTemplate(props: {
  responseData: DocumentPostResponseData;
  folderId: string;
  onRedirect(redirectURL: string, responseType: TemplateKey): void;
}): JSX.Element {
  const companyId = useSelector(
    (state: RootState) => state.session.principal.companyId,
  );

  const handleGo = (id: number) => {
    const folderId = getFolderIdByKey(props.folderId, DocumentFolderCategories);
    if (folderId)
      props.onRedirect(
        `/document/${folderId}/${b62(id)}`,
        responseData.responseType,
      );
    else
      props.onRedirect(
        `/document/${b62(Number(props.folderId))}/${b62(id)}`,
        responseData.responseType,
      );
  };

  const directory = useDirectory();
  const { responseData } = props;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {responseData.data.map((x) => {
        let employeeData:
          | {
              companyId: number;
              companyName: string;
              organizationId: number;
              organizationName: string;
              employeeId: number;
              employeeName: string;
              jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
              jobClassName: string;
              jobPositionId: number;
              jobPositionName: string;
              jobDutyId: number;
              jobDutyName: string;
              avatar?: string;
            }
          | undefined;
        if (x.updaterId) {
          employeeData = getDirectoryData({
            ...directory,
            companyId,
            employeeId: x.updaterId,
          });
        }

        const user = employeeData
          ? {
              name: employeeData.employeeName,
              from: employeeData.organizationName,
              jobClass: employeeData.jobClassName,
              avartar: employeeData.avatar,
            }
          : {
              name: '',
              icon: 'sitemap-fill' as IconType,
            };
        return (
          <a
            className="template-content document-post"
            key={`${x.id}_${x.updateAt}`}
            onClick={() => handleGo(x.id)}
          >
            <div className="content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div className="subject">{x.subject}</div>
              </div>
              <div className="head">
                <div className="user">
                  <span className="name">{user.name}</span>
                  {user.jobClass && (
                    <span className="class">{user.jobClass}</span>
                  )}
                  {user.from && (
                    <span className="organization">{user.from ?? ''}</span>
                  )}
                </div>
                <div className="date">{dateSimplify(x.updateAt)}</div>
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
}

export default DocumentPostTemplate;
