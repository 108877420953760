import React from 'react';
import { IconType } from '../../../groupware-common/types/icon';
import MenuItem from '../../../components/menu/MenuItem';

type Props = {
  id: string;
  parentId: string;
  text: string;
  selectedId?: string;
  isExpand?: boolean;
  icon?: IconType;
  items: {
    id: string;
    parentId: string;
    text: string;
    icon?: string;
    isExpand?: boolean;
  }[];
  onExpandToggle?(id: string): void;
  onClick?(id: string): void;
};

class DefaultMenuItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<Props>,
  ): boolean {
    // return (
    //   this.state !== nextState ||
    //   this.props.expandPath.indexOf(this.props.id) !== -1 ||
    //   nextProps.expandPath.indexOf(nextProps.id) !== -1
    // );
    // 선택 표시 오류로 인하여 값 변경 생세 내용 확인 필요.
    return true;
  }

  handleExpandToggle(): void {
    if (this.props.onExpandToggle !== undefined)
      this.props.onExpandToggle(this.props.id);
  }

  handleClick(): void {
    if (this.props.onClick !== undefined) this.props.onClick(this.props.id);
  }

  render(): JSX.Element {
    // console.log(`${DefaultMenuItem.name}.render`);
    const child = this.props.items.filter((x) => x.parentId === this.props.id);
    const selected = this.props.id === this.props.selectedId;
    const isChildSelected =
      this.props.parentId !== '' &&
      child.findIndex((x) => x.id === this.props.selectedId) > -1;
    const expand = this.props.isExpand;

    let classname = '';
    if (child.length > 0) classname += ' full';
    if (expand) classname += ' expanded';

    return (
      <li>
        <MenuItem
          label={this.props.text}
          className={classname}
          childrenCount={child.length}
          icon={this.props.icon}
          expanded={expand}
          selected={selected || isChildSelected}
          onToggleExpanded={this.handleExpandToggle}
          onClick={this.handleClick}
        />
        {expand && child.length > 0 && (
          <ul>
            {child.map((x) => {
              return (
                <DefaultMenuItem
                  key={x.id}
                  id={x.id}
                  parentId={this.props.id}
                  text={x.text}
                  items={this.props.items}
                  selectedId={this.props.selectedId}
                  isExpand={x.isExpand}
                  onExpandToggle={this.props.onExpandToggle}
                  onClick={this.props.onClick}
                />
              );
            })}
          </ul>
        )}
      </li>
    );
  }
}

export default DefaultMenuItem;
