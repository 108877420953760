import React from 'react';
import path from 'path';
import { useSelector } from 'react-redux';
import {
  filenameExtensionIcon,
  readableBytes,
} from '../../groupware-common/utils/ui';
import Button from '../button/Button';
import { RootState } from '../../groupware-webapp/app/store';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function AttachmentsItem(props: {
  name: string;
  size: number;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  onView?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  const teamsHostClientType = useSelector(
    (state: RootState) => state.session.teamsHostClientType,
  );
  const isMobileTeams =
    teamsHostClientType &&
    (teamsHostClientType === 'android' || teamsHostClientType === 'ios');

  return (
    <div className="file-item">
      <i
        className="type"
        data-file-format={filenameExtensionIcon(props.name)}
      />
      <div className="info">
        <span className="name">
          {path.basename(props.name, path.extname(props.name))}
        </span>
        <span className="format">{path.extname(props.name)}</span>
        <em className="size">{readableBytes(props.size, 1)}</em>
      </div>
      {!isMobileTeams && props.onClick && (
        <Button
          text={getLocalizedText('다운로드')}
          iconType
          icon="import"
          className="action"
          onClick={props.onClick}
          color="secondary"
        />
      )}
      {props.onView && (
        <Button
          className="action"
          text={getLocalizedText('보기')}
          iconType
          icon="document-search"
          onClick={props.onView}
          color="secondary"
        />
      )}
    </div>
  );
}

export default AttachmentsItem;
