import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import EuiBody from '../../../../../components/layout/EuiBody';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { SecurityListItem } from '../../../../stores/sangsanginsecurities/securities';
import SecuritiesContentBodyListItem from './SecuritiesContentBodyListItem';

type User = {
  name: string;
  organization?: string;
  job?: string;
  avatar?: string;
};

function SecuritiesContentBodyList(props: {
  search: string;
  columns: Column<PostListItemType>[];
  items: SecurityListItem[];
  onItemCheckedChange?(id: string, checked: boolean): void;
}): JSX.Element {
  const { search, items, columns } = props;
  const { pageNo } = getQueryParams(search);

  const display = useSelector((state: RootState) => state.session.display);

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo]);

  const minWidth = display !== 'phone' ? '1100px' : undefined;

  return (
    <EuiBody>
      {items.length === 0 ? (
        <EmptyData />
      ) : (
        <PostList
          name="security"
          type="full"
          density="normal"
          style={{ minWidth }}
        >
          <PostList.Head>
            {columns.map(({ name, type: colunmType, text }) => (
              <PostList.Cell
                key={name}
                name={text}
                type={colunmType}
                align={colunmType === 'post' ? 'left' : 'center'}
              />
            ))}
          </PostList.Head>
          <PostList.Body scrollableNodeProps={scrollbar}>
            {items.map((a, i) => {
              const item: Record<
                string,
                User | string | number | boolean | undefined
              > =
                display !== 'phone'
                  ? {
                      checked: a.checked,
                      decisGrwNo: a.decisGrwNo,
                      decisCont: a.decisCont,
                      decisDy: a.decisDy,
                      decisEmpNm: { name: a.decisEnoNm },
                      decisAmt: a.decisAmt,
                      pttnDy: a.pttnDy,
                      decisDeptNm: a.decisDeptNm,
                      status: 1,
                    }
                  : {
                      checked: a.checked,
                      decisGrwNo: a.decisGrwNo,
                      decisCont: a.decisCont,
                      decisDy: a.decisDy,
                      decisEmpNm: {
                        name: a.decisEnoNm,
                        organization: a.decisDeptNm,
                      },
                      decisAmt: a.decisAmt,
                      pttnDy: a.pttnDy,
                      status: 1,
                    };
              const key = `/i${i}/${a.listId}/}`;
              return (
                <SecuritiesContentBodyListItem
                  key={key}
                  id={a.listId}
                  selected={a.checked}
                  columns={columns}
                  item={item}
                  onCheckedChange={props.onItemCheckedChange}
                />
              );
            })}
          </PostList.Body>
        </PostList>
      )}
    </EuiBody>
  );
}

export default SecuritiesContentBodyList;
