import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import FormBuilder from '../../../../../../groupware-approval/stores/approval/FormBuilder';
import { employeeActions } from '../../../../../../groupware-directory/stores/directory/employee';
import {
  RootState,
  useAppDispatch,
} from '../../../../../../groupware-webapp/app/store';
import { noticeActions } from '../../../../../stores/attendance/notice';
import AttendanceTeamsNoticeDocumentEdit from './AttendanceTeamsNoticeDocumentEdit';
import AttendanceTeamsNoticeDocumentView from './AttendanceTeamsNoticeDocumentView';
import HTTP404 from '../../../../../../components/error/HTTP404';

function AttendanceTeamsNoticeViewContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);
  const notice = useSelector(
    (state: RootState) => state.attendance.preferences.notice,
  );
  const view = useSelector(
    (state: RootState) => state.attendance.sendNotice.view,
  );
  const list = useSelector(
    (state: RootState) => state.attendance.notice.list.items,
  );

  const [state, setState] = useState(() => {
    return {
      type: 'read' as 'read' | 'create',
      rest: '',
      isAlertFormSignature: false,
      alertForm: undefined as string | undefined,
      error: false,
    };
  });

  if (!list || !view) return <HTTP404 />;

  if (
    !notice ||
    notice.usePlanFormId === 0 ||
    notice.alertFormId === 0 ||
    notice.alertType === 0
  )
    return <HTTP404 />;

  const listItem = list.find(
    (x) =>
      x.alertType === view.alertType &&
      x.employeeId === view.employeeId &&
      x.standardDate === view.standardDate,
  );

  // 사용 계획서 제출 클릭 이벤트.
  const handleUsePlanClick = (
    rest: string,
    isAlertFormSignature: boolean,
    content?: string,
  ) => {
    dispatch(employeeActions.findView({ id: principal.employeeId })).then(
      (result) => {
        if ((result as { error?: string }).error === undefined) {
          setState((prevState) => ({
            ...prevState,
            type: 'create',
            rest,
            isAlertFormSignature,
            alertForm: content,
          }));
        }
      },
    );
  };

  // 사용 계획서 저장 클릭 이벤트.
  const handleUsePlanSave = (contents: string, signature?: string) => {
    const getMacro = (arg: { id: string }) => {
      const { id } = arg;
      /** 연차촉진 대상자서명 */
      if (id === 'ATTENDANCE/SENDNOTICE_SIGNATURE') return signature ?? '';
      return undefined;
    };

    let updateAlertForm = state.alertForm;
    if (state.isAlertFormSignature && state.alertForm && signature) {
      updateAlertForm = FormBuilder.binding({
        html: state.alertForm,
        getMacro,
      });
    }

    const arg = {
      employeeId: principal.employeeId,
      alertType: view.alertType,
      standardDate: view.standardDate,
      contents,
      firstAlarmContents: updateAlertForm,
    };

    dispatch(noticeActions.saveUsePlan({ arg })).then((result) => {
      if (result.type.endsWith('fulfilled')) {
        microsoftTeams.app.initialize().then(() => {
          microsoftTeams.dialog.url.submit();
        });
      } else if (result.type.endsWith('rejected')) {
        setState((prev) => ({
          ...prev,
          error: true,
        }));
      }
    });
  };

  const renderContent = () => {
    const { type } = state;
    // 사용계획서 작성
    if (type === 'create') {
      return (
        <AttendanceTeamsNoticeDocumentEdit
          search={props.search}
          onClick={handleUsePlanSave}
          rest={state.rest}
          isAlertFormSignature={state.isAlertFormSignature}
        />
      );
    }

    return (
      <AttendanceTeamsNoticeDocumentView
        view={{
          ...view,
          columnsName: 'firstAlarmId',
          columnsValue: 0,
          firstAlarmSendDate:
            listItem === undefined ? '' : listItem.firstAlarmSendDate,
          workerPlanId: notice.usePlanFormId,
        }}
        usePlanButtonVisible={view.isEnableWorkPlan ?? false}
        search={props.search}
        onClick={handleUsePlanClick}
      />
    );
  };

  return state.error ? <></> : <>{renderContent()}</>;
}

export default AttendanceTeamsNoticeViewContainer;
