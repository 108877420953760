import React from 'react';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function UploadImage(props: {
  path: string;
  name?: string;
  style?: React.CSSProperties;
  type: 'profile' | 'information'; // 아바타인 경우 - profile, 사진 정보인 경우 - information
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onDelete(): void;
  onError?(): void;
}): JSX.Element {
  const { path, name, style, type, onChange, onDelete, onError } = props;
  return (
    <div className={`eui-${type}-image`}>
      <label className="add" style={style}>
        <input accept="image/*" type="file" onChange={onChange} />
        <span className={path ? '' : 'on'}>
          <Icon icon="camera-fill" />
          {getLocalizedText('이미지 선택')}
        </span>
        {path && (
          <img
            src={path}
            alt={name || getLocalizedText('이미지')}
            onError={onError}
          />
        )}
      </label>
      {path !== undefined && path !== '' && (
        <Button
          text={getLocalizedText('삭제')}
          iconType
          icon="trash-fill"
          onClick={onDelete}
          className="delete"
          size="xs"
        />
      )}
    </div>
  );
}

export default UploadImage;
