import React from 'react';
import { Trans } from 'react-i18next';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function DeleteConfirmation(props: {
  children: React.ReactNode;
  noDuplication?: boolean; // 버튼 클릭 중복 가능 여부. (true: 중복클릭 불가능, false, undefined: 중복클릭 가능)
  delete?: boolean;
  message?: string;
  onSubmit(): void;
  onCancel(): void;
}): JSX.Element {
  return (
    <Dialog size="xs">
      <DialogBody>
        <div className="eui-alert-message eui-delete-message">
          <p>{props.children}</p>
          {!props.delete && (
            <p>
              {props.message ? (
                <>
                  <Trans
                    i18nKey="{{message}}되며 영구 삭제되어 복구할 수 없습니다."
                    values={{ message: props.message }}
                    components={{ em: <em /> }}
                  >
                    <em>{props.message}</em>되며 <em>영구 삭제</em>되어 복구할
                    수 없습니다.
                  </Trans>
                </>
              ) : (
                <>
                  <Trans
                    i18nKey="<em>영구 삭제</em>되어 복구할 수 없습니다."
                    components={{ em: <em /> }}
                  >
                    <em>영구 삭제</em>되어 복구할 수 없습니다.
                  </Trans>
                </>
              )}
            </p>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button text={getLocalizedText('취소')} onClick={props.onCancel} />
        <Button
          noDuplication={props.noDuplication ?? true}
          text={getLocalizedText('삭제')}
          variant="contained"
          color="danger"
          onClick={props.onSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default DeleteConfirmation;
