import { useEffect, useState } from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import * as microsoftTeams from '@microsoft/teams-js';

function checkInTeams() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const microsoftTeamsLib = microsoftTeams || (window as any).microsoftTeams;

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }
  if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    return true;
  }
  return false;
}

function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0, data = vars; i < data.length; i += 1) {
    const varPairs = data[i];
    const pair = varPairs.split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
}

// eslint-disable-next-line import/prefer-default-export
export function useTeams(onThemeChange?: (theme: string) => void): {
  inTeams: boolean | undefined;
  teamsHostClientType: string | undefined;
  theme: string;
}[] {
  const [inTeams, setInTeams] = useState<boolean | undefined>(undefined);
  // desktop, web, android, ios
  const [teamsHostClientType, setIsTeamsHostClientType] = useState<
    string | undefined
  >(undefined);
  const [theme, setTheme] = useState(getQueryVariable('theme') ?? 'default');

  useEffect(() => {
    if (checkInTeams()) {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app
          .getContext()
          .then((context: microsoftTeams.app.Context) => {
            batchedUpdates(() => {
              setInTeams(true);
              setIsTeamsHostClientType(context.app.host.clientType ?? '');
            });
          });
        microsoftTeams.app.registerOnThemeChangeHandler((aTheme) => {
          setTheme(aTheme);
          if (onThemeChange) onThemeChange(aTheme);
        });
      });
    } else {
      setInTeams(false);
      setIsTeamsHostClientType('');
    }
  }, []);

  return [{ inTeams, teamsHostClientType, theme }];
}
