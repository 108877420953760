import React, { useState } from 'react';
import Authentication from '../../../components/Authentication';
import TextField from '../../../components/textfield/TextField';
import { go } from '../../../groupware-common/utils';
import Icon from '../../../components/icon/Icon';
import { createLocalizedTextFactory } from '../../../groupware-common/utils/i18n';

function OnetimePassword(): JSX.Element {
  const [code, setCode] = useState('');
  const handleGoLogin = () => {
    go('/signin');
  };

  const getLocalizedText = createLocalizedTextFactory('authentication');

  return (
    <Authentication>
      <Authentication.Title label="Google OTP 설치 및 구성" />
      <div className="authentication-otp">
        <div className="manual">
          <ul>
            <li>
              {getLocalizedText(
                'OTP 인증은 그룹웨어 계정에 추가 보호 레이어를 제공합니다.',
              )}
            </li>
            <li>
              {getLocalizedText(
                'OTP 인증이 설정되면 그룹웨어에 로그인시 패스워드 외에 1회용 인증 코드가 필요합니다.',
              )}
            </li>
            <li>
              {getLocalizedText(
                '인증 코드를 생성하는 데 모바일 기기가 필요합니다.',
              )}
            </li>
            <li>
              {getLocalizedText('모바일 기기에 인증기 앱을 설치하십시오.')}
              <br />
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="android" /> Google OTP
              </a>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="apple" /> Google Authenticator
              </a>
            </li>
            <li>
              {getLocalizedText(
                '설치한 OTP 앱을 열고 아래 QR 코드를 스캔하고 앱에서 생성된 인증 코드를 입력하면 설정이 완료됩니다.',
              )}
            </li>
          </ul>
        </div>
        <div className="qrCode">
          <img src="/images/qrcode.png" alt="QR코드" className="image" />
          <TextField
            placeholder="6자리 코드 입력"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            maxLength={6}
            align="center"
          />
          <Authentication.Submit label={getLocalizedText('생성')} />
        </div>
      </div>
      <Authentication.Link
        label={getLocalizedText('로그인 페이지로 이동')}
        onClick={handleGoLogin}
      />
    </Authentication>
  );
}

export default OnetimePassword;
