import React from 'react';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import { PostListItemType } from '../../../../../components/post/PostList';
import SelectField from '../../../../../components/selectField/SelectField';
import { Column } from '../../../../../groupware-common/ui/type';

function ListSettingItem(props: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="setting-item">
      <div className="item-head">{props.title}</div>
      <div className="item-content">{props.children}</div>
    </div>
  );
}

function SecuritiesListSetting(props: {
  columns?: Column<PostListItemType>[];
  onColumnVisibleChange?(name: string, visible: boolean): void;
  listCount?: string;
  onChangeListCount?(value: string): void;
  onClose(): void;
}): JSX.Element {
  const {
    columns,
    onColumnVisibleChange,
    listCount,
    onChangeListCount,
    onClose,
  } = props;

  const countOptions = [
    { value: '10', label: '10 개' },
    { value: '15', label: '15 개' },
    { value: '20', label: '20 개' },
    { value: '30', label: '30 개' },
    { value: '50', label: '50 개' },
    { value: '100', label: '100 개' },
    { value: '300', label: '300 개' },
  ];

  const handleChangeListCount = (value: string) => {
    if (onChangeListCount) onChangeListCount(value);
  };

  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    if (onColumnVisibleChange) onColumnVisibleChange(name, visible);
  };

  return (
    <Drawer title="목록 설정" size="xs" onClose={onClose}>
      <DrawerBody>
        <div className="ui-setting-list">
          {columns && (
            <ListSettingItem title="목록 노출">
              <CheckboxGroup column>
                {columns.map(({ name, text, visible, disable }) => {
                  const checked = visible || false;
                  return (
                    <Checkbox
                      key={name}
                      label={text}
                      checked={checked}
                      onChange={() => handleColumnVisibleChange(name, !checked)}
                      disabled={disable}
                    />
                  );
                })}
              </CheckboxGroup>
            </ListSettingItem>
          )}
          {listCount && (
            <ListSettingItem title="목록 개수">
              <SelectField
                data={countOptions}
                value={listCount}
                onChange={handleChangeListCount}
              />
            </ListSettingItem>
          )}
        </div>
      </DrawerBody>
    </Drawer>
  );
}

export default React.memo(SecuritiesListSetting);
