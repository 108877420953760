import React from 'react';
import Button from '../../../../../components/button/Button';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function BoardContentHeadView(
  props: (
    | {
        type: 'full';
        onListGo?(): void;
        onPrev?(): void;
        onNext?(): void;
      }
    | {
        type: 'split';
        onListGo?: undefined;
        onPrev?: undefined;
        onNext?: undefined;
      }
  ) & {
    usePagination: boolean;
    toolbarButtons: ActionEventProps[];
    onToolbarAction(arg: { code: string; event: React.MouseEvent }): void;
  },
): JSX.Element {
  const { type, toolbarButtons, onToolbarAction, onListGo, onPrev, onNext } =
    props;

  let classname = 'view-toolbar';
  if (type) classname += ` ${type}`;

  return (
    <EuiToolbar className={classname}>
      <EuiToolbar.Left>
        {type === 'full' && onListGo && (
          <Button
            text={getLocalizedText('목록으로 돌아가기')}
            iconType
            icon="arrow-left"
            onClick={onListGo}
            className="back-list"
          />
        )}
        <ToolbarAction event={toolbarButtons} onClick={onToolbarAction} />
      </EuiToolbar.Left>
      {type === 'full' && props.usePagination && (
        <EuiToolbar.Right>
          <div className="eui-pagination">
            <Button
              text={getLocalizedText('다음')}
              className="page-action"
              iconType
              icon="chevron-up"
              disabled={onNext === undefined}
              onClick={onNext}
            />
            <Button
              text={getLocalizedText('이전')}
              className="page-action"
              iconType
              icon="chevron-down"
              disabled={onPrev === undefined}
              onClick={onPrev}
            />
          </div>
        </EuiToolbar.Right>
      )}
    </EuiToolbar>
  );
}

export default React.memo(BoardContentHeadView);
