import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';
import ApprovalPreferencesHead from './ApprovalPreferencesHead';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/** 팀즈일 때 - 알림 설정 컨테이너. */
function ApprovalPreferencesNotificationsContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${ApprovalPreferencesNotificationsContainer.name}.render`);

  const dispatch = useAppDispatch();
  const userPreferencesNotice = useSelector(
    (state: RootState) => state.approval2.userPreferences.userPreferencesNotice,
  );
  const datalist: {
    savecheck: boolean;
    email: string;
    notifications: {
      code: string;
      label: string;
      tip: string;
      notifications: boolean;
    }[];
  } = {
    savecheck: false,
    email: userPreferencesNotice.noticeEmail,
    notifications: [
      {
        code: 'request',
        label: getLocalizedText('요청'),
        tip: getLocalizedText('자신이 결재할 차례에 알림을 받음.'),
        notifications:
          userPreferencesNotice.noticeRequest === 1 ||
          userPreferencesNotice.noticeRequest === 3,
      },
      {
        code: 'return',
        label: getLocalizedText('반려'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재(합의 포함)한 문서가 반려되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeReturn === 1 ||
          userPreferencesNotice.noticeReturn === 3,
      },
      {
        code: 'previous_return',
        label: getLocalizedText('전단계 반려'),
        tip: getLocalizedText(
          '자신에게 중간결재(합의 포함)한 문서가 전단계 반려되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticePreviousReturn === 1 ||
          userPreferencesNotice.noticePreviousReturn === 3,
      },
      {
        code: 'hold',
        label: getLocalizedText('보류'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재(합의 포함)한 문서가 보류되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeHold === 1 ||
          userPreferencesNotice.noticeHold === 3,
      },
      {
        code: 'interview',
        label: getLocalizedText('대면'),
        tip: getLocalizedText('자신에게 대면요청이 왔을 경우 알림을 받음.'),
        notifications:
          userPreferencesNotice.noticeInterview === 1 ||
          userPreferencesNotice.noticeInterview === 3,
      },
      {
        code: 'defer',
        label: getLocalizedText('후결'),
        tip: getLocalizedText('자신이 후결처리되었을 경우 알림을 받음.'),
        notifications:
          userPreferencesNotice.noticeDefer === 1 ||
          userPreferencesNotice.noticeDefer === 3,
      },
      {
        code: 'completed',
        label: getLocalizedText('완료'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재(합의 포함)한 문서가 최종 완료되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeCompleted === 1 ||
          userPreferencesNotice.noticeCompleted === 3,
      },
      {
        code: 'cancel',
        label: getLocalizedText('문서 취소'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재한 문서가 취소되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeApprovalPermissionDocumentCancel === 1 ||
          userPreferencesNotice.noticeApprovalPermissionDocumentCancel === 3,
      },
      {
        code: 'referencePermissionCancel',
        label: getLocalizedText('문서 취소 (참조권한)'),
        tip: getLocalizedText(
          '자신이 참조권한에 포함된 문서가 취소되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeReferencePermissionDocumentCancel === 1 ||
          userPreferencesNotice.noticeReferencePermissionDocumentCancel === 3,
      },
      {
        code: 'viewPermissionCancel',
        label: getLocalizedText('문서 취소 (조회권한)'),
        tip: getLocalizedText(
          '자신이 조회권한에 포함된 문서가 취소되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeViewPermissionDocumentCancel === 1 ||
          userPreferencesNotice.noticeViewPermissionDocumentCancel === 3,
      },
      {
        code: 'change',
        label: getLocalizedText('문서 변경'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재한 문서가 변경되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeApprovalPermissionDocumentChange === 1 ||
          userPreferencesNotice.noticeApprovalPermissionDocumentChange === 3,
      },
      {
        code: 'referencePermissionChange',
        label: getLocalizedText('문서 변경 (참조권한)'),
        tip: getLocalizedText(
          '자신이 참조권한에 포함된 문서가 변경되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeReferencePermissionDocumentChange === 1 ||
          userPreferencesNotice.noticeReferencePermissionDocumentChange === 3,
      },
      {
        code: 'viewPermissionChange',
        label: getLocalizedText('문서 변경 (조회권한)'),
        tip: getLocalizedText(
          '자신이 조회권한에 포함된 문서가 변경되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeViewPermissionDocumentChange === 1 ||
          userPreferencesNotice.noticeViewPermissionDocumentChange === 3,
      },
      {
        code: 'referrer',
        label: getLocalizedText('참조'),
        tip: getLocalizedText(
          '자신이 참조자로 등록된 문서가 상신되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeReferrer === 1 ||
          userPreferencesNotice.noticeReferrer === 3,
      },
      {
        code: 'viewer',
        label: getLocalizedText('조회'),
        tip: getLocalizedText(
          '자신이 조회자로 등록된 문서가 완료되었을 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeViewer === 1 ||
          userPreferencesNotice.noticeViewer === 3,
      },
      {
        code: 'opinion',
        label: getLocalizedText('의견 등록'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재한 진행문서에 의견이 작성된 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeOpinion === 1 ||
          userPreferencesNotice.noticeOpinion === 3,
      },
      {
        code: 'opinionReply',
        label: getLocalizedText('의견 답글 등록'),
        tip: getLocalizedText(
          '자신이 기안 또는 중간결재한 진행문서에 의견에 답글이 작성된 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeOpinionReply === 1 ||
          userPreferencesNotice.noticeOpinionReply === 3,
      },
      {
        code: 'comment',
        label: getLocalizedText('댓글 등록'),
        tip: getLocalizedText(
          '자신에게 열람권이 있는 문서에 댓글이 작성된 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeComment === 1 ||
          userPreferencesNotice.noticeComment === 3,
      },
      {
        code: 'commentReply',
        label: getLocalizedText('댓글 답글 등록'),
        tip: getLocalizedText(
          '자신에게 열람권이 있는 문서에 댓글에 답글이 작성된 경우 알림을 받음.',
        ),
        notifications:
          userPreferencesNotice.noticeCommentReply === 1 ||
          userPreferencesNotice.noticeCommentReply === 3,
      },
    ],
  };
  const [state, setState] = useState<{
    savecheck: boolean;
    email: string;
    notifications: {
      code: string;
      label: string;
      tip: string;
      notifications: boolean;
    }[];
  }>(datalist);

  const handleChangeNotifications = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'notifications',
    code: string,
  ) => {
    switch (type) {
      case 'notifications':
        setState((prevState) => ({
          ...prevState,
          savecheck: true,
          notifications: prevState.notifications.map((x) =>
            x.code === code ? { ...x, notifications: event.target.checked } : x,
          ),
        }));
        break;
      default:
        break;
    }
  };

  const checked = (arg: {
    code: string;
    notifications: boolean;
    tip: string;
  }): number => {
    if (arg.notifications === true) return 1;
    return 0;
  };

  const handleUserPreferencesSave = () => {
    const arg = {
      noticeEmail: state.email,
      noticeRequest: checked(state.notifications[0]),
      noticeReturn: checked(state.notifications[1]),
      noticePreviousReturn: checked(state.notifications[2]),
      noticeHold: checked(state.notifications[3]),
      noticeInterview: checked(state.notifications[4]),
      noticeDefer: 0,
      noticeCompleted: checked(state.notifications[6]),
      noticeApprovalPermissionDocumentCancel: checked(state.notifications[7]),
      noticeReferencePermissionDocumentCancel: checked(state.notifications[8]),
      noticeViewPermissionDocumentCancel: checked(state.notifications[9]),
      noticeApprovalPermissionDocumentChange: checked(state.notifications[10]),
      noticeReferencePermissionDocumentChange: checked(state.notifications[11]),
      noticeViewPermissionDocumentChange: checked(state.notifications[12]),
      noticeReferrer: checked(state.notifications[13]),
      noticeViewer: checked(state.notifications[14]),
      noticeOpinion: checked(state.notifications[15]),
      noticeOpinionReply: checked(state.notifications[16]),
      noticeComment: checked(state.notifications[17]),
      noticeCommentReply: checked(state.notifications[18]),
      updateAt: userPreferencesNotice.updateAt,
    };

    dispatch(
      approvaluserPreferencesActions.savefetchUserPreferencesNotice(arg),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        setState((prevState) => ({ ...prevState, savecheck: false }));
      }
    });
  };

  const handleUserPreferencesCancel = () => {
    setState(datalist);
  };

  const { pathname, search, hash } = props;
  return (
    <>
      <ApprovalPreferencesHead
        pathname={pathname}
        search={search}
        hash={hash}
      />
      <EuiBody>
        {state.notifications
          .filter((a) => a.code !== 'defer') // 후결 기능 개발되지 않아 보이지 않게 처리
          .map((x) => (
            <EuiSetting.Item key={x.code} title={x.label} tooltip={x.tip}>
              <CheckboxGroup>
                <Checkbox
                  label={getLocalizedText('알림')}
                  checked={x.notifications}
                  onChange={(event) =>
                    handleChangeNotifications(event, 'notifications', x.code)
                  }
                />
              </CheckboxGroup>
            </EuiSetting.Item>
          ))}
        <EuiToolbar>
          {state.savecheck && (
            <EuiToolbar.Left>
              <Button
                text={getLocalizedText('저장')}
                variant="contained"
                onClick={handleUserPreferencesSave}
              />
              <Button
                text={getLocalizedText('취소')}
                onClick={handleUserPreferencesCancel}
              />
            </EuiToolbar.Left>
          )}
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default ApprovalPreferencesNotificationsContainer;
