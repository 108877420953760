import React from 'react';

type Props = {
  editorId?: string;
  editorConfig?: unknown;
  onLoad?(editor: unknown, DEXT5: unknown, editorDom: unknown): void;
  value?: string;
};
const Dext5 = React.forwardRef((props: Props, ref: any) => {
  // 에디터 로딩 후 발생 이벤트
  const dextEditorLoadedEvent = (editor: unknown) => {
    try {
      const editorId = props.editorId || 'editor';
      const iframe = ref;
      const iframeDocument: any =
        iframe.current?.contentWindow || iframe.current?.contentDocument;
      const DEXT5 = iframeDocument.getDEXT5();

      if (props.value) DEXT5.setBodyValue(props.value, editorId);

      const editorDom = iframeDocument.getDom(editorId) as
        | HTMLBodyElement
        | undefined;

      if (editorDom !== undefined) {
        const { parentElement } = editorDom;
        // console.log(`editorDom.parentElement:`, parentElement);
        if (parentElement?.tagName === 'HTML') {
          // console.log(`document.querySelector('html')?.getAttribute('style'):`, document.querySelector('html')?.getAttribute('style'));
          const style = document.querySelector('html')?.getAttribute('style');
          if (style) parentElement.setAttribute('style', style);
        }
      }

      // Dom.addEventListener('click', handleClickEditor, false);

      console.log(`dextEditorLoadedEvent()`, {
        editor,
        DEXT5,
        Dom: editorDom,
      });

      const { onLoad } = props;
      if (onLoad) onLoad(editor, DEXT5, editorDom);
    } catch (e) {
      console.log('error : ', e);
    }
  };

  // 에디터 모드 변경시 발생 이벤트 ( 디자인 <> HTML )
  function dextEditorAfterchangemodeEvent(
    afterMode: string,
    beforeMode: string,
    editorId: string,
  ) {
    if (afterMode === 'design') {
      const iframe = ref;
      const iframeDocument: any =
        iframe.current?.contentWindow || iframe.current?.contentDocument;

      const editorDom = iframeDocument.getDom(editorId) as
        | HTMLBodyElement
        | undefined;

      if (editorDom !== undefined) {
        const { parentElement } = editorDom;
        if (parentElement?.tagName === 'HTML') {
          const style = document.querySelector('html')?.getAttribute('style');
          if (style) parentElement.setAttribute('style', style);
        }
      }
      const { onLoad } = props;
      const DEXT5 = iframeDocument.getDEXT5();
      if (onLoad) onLoad(undefined, DEXT5, editorDom);
    }
  }

  // 에디터에 붙여넣기 전 이벤트 (디자인 모드에서만 실행.)
  function dextEditorBeforePasteEvent(pasteSource: string) {
    const contents = pasteSource
      .replaceAll(/<gw-fb-element[^>]*>.*?<\/gw-fb-element>/g, '')
      .replaceAll(/{_.*?_}/g, '');

    return contents;
  }

  // iframe 로딩 완료 후 발생 이벤트
  const handleLoad = (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>,
  ) => {
    const editorId = props.editorId || 'editor';
    const document: any =
      event.currentTarget.contentWindow || event.currentTarget.contentDocument;

    if (!document) {
      window.alert('frame 로딩 에러');
      return false;
    }

    // eslint-disable-next-line camelcase
    document.dext_editor_loaded_event = dextEditorLoadedEvent;
    // eslint-disable-next-line camelcase
    document.dext_editor_afterchangemode_event = dextEditorAfterchangemodeEvent;
    // eslint-disable-next-line camelcase
    document.dext_editor_beforepaste_event = dextEditorBeforePasteEvent;

    // setHtmlContentsEw완료후 이벤트
    // document.dext_editor_set_complete_event
    if (typeof document.setDEXT5 === 'function') {
      document.setDEXT5(props);
    } else {
      window.alert('에디터 로딩 에러');
      return false;
    }
    return true;
  };

  return (
    <iframe
      id={props.editorId || 'editor'}
      ref={ref}
      src="/editor/dext5/editor.html"
      title="에디터"
      style={{ width: '100%', height: '100%' }}
      onLoad={handleLoad}
    />
  );
});

Dext5.displayName = 'Dext5';

export default Dext5;
