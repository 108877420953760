import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import UserInfo from '../../../../../components/user/UserInfo';
import PostSubject from '../../../../../components/post/PostSubject';
import PostList, {
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import Menu from '../../../../../components/menu/Menu';
import { RootState } from '../../../../../groupware-webapp/app/store';

type User = {
  name?: string;
  organization?: string;
  avatar: string;
};

type Subject = {
  folderId?: number;
  folderName?: string;
  value: string;
  reason?: string;
};

type Props = {
  type?: PostListType;
  id?: number;
  listId?: string;
  selected?: boolean;
  text?: string;
  columns?: Column<PostListItemType>[];
  item?: Record<string, Subject | User | boolean | string | number | undefined>;
  onClick?(arg: { id: string }): void;
};

function ApprovalArchiveTransferHistoryListItem(dumy: Props) {
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const display = useSelector((state: RootState) => state.session.display);

  const [state, setState] = useState<{
    visible: boolean;
    point: { x: number; y: number; width: number; height: number };
  }>({
    visible: false,
    point: { x: 0, y: 0, width: 0, height: 0 },
  });

  const handleOpenReason = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    if (display !== 'phone') {
      if (isDesktop) {
        const rect = event.currentTarget.getBoundingClientRect();

        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = window.setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            point: {
              x: rect.x,
              y: rect.y,
              width: rect.width,
              height: rect.height,
            },
            visible: true,
          }));
        }, 500);
      }
    }
  };

  const handleCloseTransferReason = () => {
    if (isDesktop) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setState((prevState) => ({
        ...prevState,
        visible: false,
      }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || 0,
    listId: dumy.listId || '',
    selected: dumy.selected || false,
    columns: dumy.columns || ([] as Column<PostListItemType>[]),
    item:
      dumy.item ||
      ({
        subject: {
          value: '제목',
        },
        reason: '무제',
        sender: {
          name: '홍길동',
          organization: '조직',
          job: '직위/직책',
          avatar: '/images/faces/faces_31.jpg',
        },
        sendOrganization: {
          name: '홍길동',
          organization: '조직',
          job: '직위/직책',
          avatar: '/images/faces/faces_31.jpg',
        },
        reveiveOrganization: {
          name: '홍길동',
          organization: '조직',
          job: '직위/직책',
          avatar: '/images/faces/faces_31.jpg',
        },
        createAt: '2023-03-06',
      } as Record<
        string,
        Subject | User | boolean | string | number | undefined
      >),
    onClick: dumy.onClick || emptyFunction,
  };
  const { columns, item } = props;

  return (
    <>
      <PostList.Item>
        {columns.map(({ name, type, text }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;

          if (type === 'user') {
            const { avatar, name: username, organization } = value as User;
            children = (
              <UserInfo
                avatar={(username ?? '') === '' ? undefined : avatar ?? ''}
                name={
                  (username ?? '') === ''
                    ? (organization as string)
                    : username ?? ''
                }
                from={(username ?? '') === '' ? undefined : organization}
                icon={(username ?? '') === '' ? 'sitemap-fill' : undefined}
              />
            );
          }
          if (type === 'post') {
            const subject = value as Subject;
            children = (
              <PostSubject
                title={subject.value}
                onMouseEnter={handleOpenReason}
                onMouseLeave={handleCloseTransferReason}
              />
            );
          }
          if (typeof value === 'string') {
            if (type === 'date') {
              children = (
                <PostList.Value value={dateSimplify(value, 'dateNumber')} />
              );
            } else {
              children = <PostList.Value value={value} />;
            }
          }
          if (name === 'subject' && display === 'phone') {
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px' }}>{value}</span>
              </span>
            );
          }
          if (type === 'post' && display === 'phone') {
            const subject = value as Subject;
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px' }}>{subject.value}</span>
              </span>
            );
          }
          if (name === 'senderOrganization' && display === 'phone') {
            const { organization } = value as User;
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px' }}>{organization}</span>
              </span>
            );
          }
          if (name === 'receiveOrganization' && display === 'phone') {
            const { organization } = value as User;
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px' }}>{organization}</span>
              </span>
            );
          }

          let style: React.CSSProperties | undefined;
          return (
            <PostList.Cell
              key={name}
              type={type}
              style={
                type === 'post' && display !== 'phone'
                  ? {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }
                  : style
              }
            >
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
      {state.visible && (
        <Menu point={state.point} onClose={handleCloseTransferReason}>
          {props.text}
        </Menu>
      )}
    </>
  );
}

export default React.memo(ApprovalArchiveTransferHistoryListItem);
