/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import { IconType } from '../../../../../groupware-common/types/icon';
import Button from '../../../../../components/button/Button';

export interface DashBoardHeaderAction {
  text: string;
  code: string;
  onClick(code: string, id?: number): void;
  icon: IconType;
  iconType?: boolean;
  id?: number;
  variant?: 'contained' | 'outlined' | undefined;
}

function DashBoardHeader(props: {
  title: string;
  folderName?: string;
  actions?: DashBoardHeaderAction[];
}): JSX.Element {
  const { title, folderName, actions } = props;

  return (
    <div className="item-head">
      <div className="head-left">
        <div className="title">{title}</div>
        {folderName && (
          <div className="sub-title">
            <span className="sub">{folderName}</span>
          </div>
        )}
      </div>
      <div className="head-right">
        {actions &&
          actions.map((x) => (
            <Button
              key={x.code}
              text={x.text}
              onClick={() => x.onClick(x.code, x.id)}
              icon={x.icon}
              iconType={x.iconType}
              variant={x.variant}
            />
          ))}
      </div>
    </div>
  );
}

export default DashBoardHeader;
