import React, { useEffect, useRef } from 'react';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { ApprovalLineType } from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import { Column } from '../../../../../groupware-common/ui/type';
import { getQueryParams } from '../../../../../groupware-common/utils';
import SecuritiesAuthorityBodyListItem from './SecuritiesAuthorityBodyListItem';

type Item = {
  companyId: number;
  id: number;
  workName: string;
  documentNo: string;
  subject: string;
  attachments: boolean;
  opinions: number;
  comments: number;
  drafter: string;
  drafterOrganization: string;
  drafterJob: string;
  drafterAvatar: string;
  draftAt: string;
  completeAt: string;
  checked: boolean;
  approvalLine: ApprovalLineType;
  linkWait?: boolean;
  listStatus?: string;
};

function SecuritiesAuthorityBodyList(props: {
  search: string;
  type: PostListType;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: Item[];
  selectedId?: number;
  onItemClick?(status: string, id: number): void;
  onItemCheckedChange?(id: number, checked: boolean): void;
}): JSX.Element {
  const { search, type, density, items, selectedId, columns } = props;
  const { pageNo } = getQueryParams(search);

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo]);

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <PostList name="security" type={type} density={density}>
      <PostList.Head>
        {columns.map(({ name, type: colunmType, text }) => (
          <PostList.Cell
            key={name}
            name={text}
            type={colunmType}
            align={name !== 'subject' ? 'center' : undefined}
          />
        ))}
      </PostList.Head>
      <PostList.Body scrollableNodeProps={scrollbar}>
        {items.map((a, i) => {
          const item: {
            checked: boolean;
            work: string;
            documentNo: string;
            subject: {
              value: string;
              security: boolean;
              attachments: boolean;
              urgent: boolean;
              chip?: {
                label: string;
                theme: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
              };
            };
            drafter?: {
              name: string;
              organization: string;
              job: string;
              avatar: string;
            };
            draftAt: string;
            status: string;
          } = {
            checked: a.checked,
            work: a.workName,
            documentNo: a.documentNo,
            subject: {
              value: a.subject,
              security: false,
              attachments: a.attachments,
              urgent: false,
              chip: a.linkWait
                ? {
                    label: '연동대기',
                    theme: 'primary',
                  }
                : undefined,
            },
            drafter: {
              name: a.drafter,
              organization: a.drafterOrganization,
              job: a.drafterJob,
              avatar: a.drafterAvatar,
            },
            draftAt: a.draftAt,
            status: a.listStatus ?? '',
          };
          // 조인으로 인한 키 중복 데이터가 조회 되는 경우 오류가 발생하여 인덱스 값 추가.
          const key = `/i${i}/${a.id}/}`;
          return (
            <SecuritiesAuthorityBodyListItem
              key={key}
              id={a.id}
              selected={a.checked || a.id === selectedId}
              columns={columns}
              item={item}
              approvalLine={a.approvalLine}
              onClick={props.onItemClick}
              onCheckedChange={props.onItemCheckedChange}
            />
          );
        })}
      </PostList.Body>
    </PostList>
  );
}

export default SecuritiesAuthorityBodyList;
