import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CreateRandomString,
  moduleIcon,
} from '../../groupware-common/utils/ui';
import GnbItem from './GnbItem';
import { Module } from '../../groupware-common/types';
import Avatar from '../avatar/Avatar';
import Tooltip from '../tooltip/Tooltip';
import EuiGnb from '../layout/EuiGnb';
import { go } from '../../groupware-common/utils';
import { getLocalizedText } from '../../groupware-common/utils/i18n';
import { RootState } from '../../groupware-webapp/app/store';
import { LinkMenuItem } from '../../groupware-service/stores/module';
import Icon from '../icon/Icon';
import { gnbType } from '../../groupware-webapp/pages/common/containers/GnbContainer';

function Gnb(props: {
  id: string;
  items: Array<{ id: Module; text: string }>;
  employeeName: string;
  avatar?: string;
  linkMenus?: Array<LinkMenuItem>;
  onItemClick(id: string): void;
  onMenu(event: React.MouseEvent, menuType?: string): void;
}): JSX.Element {
  const person = useSelector(
    (state: RootState) => state.setting.information.person,
  );
  const isSessionLogin = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );
  // 개발 환경 유무.
  const isDevelopment =
    window.location.hostname === 'localhost' || !isSessionLogin;

  const [key, setKey] = useState(CreateRandomString());
  useEffect(() => {
    setKey(CreateRandomString());
  }, [person]);

  const items = props.items
    .filter((a) => a.id !== 'directory')
    .map((a) => {
      return (
        <GnbItem
          key={a.id}
          useTooltip={a.text.length > 4}
          visibleName
          id={a.id}
          text={a.text}
          icon={moduleIcon(a.id)}
          selected={props.id === a.id}
          onClick={props.onItemClick}
        />
      );
    });

  const { employeeName, avatar } = props;

  const handleMenuClick = (event: React.MouseEvent, type: gnbType) => {
    props.onMenu(event, type);
  };

  return (
    <EuiGnb>
      <div className="nav">{items}</div>
      {/* TODO 삭제 */}
      {/* <div className="service">
        {isDevelopment && (
          <GnbItem id="service" text="전체서비스" icon="grid-9" />
        )}
        {isDevelopment && (
          <GnbItem id="service" text="홈으로" icon="home-fill" />
        )}
      </div> */}
      <div className="utility">
        <GnbItem
          useTooltip
          id="organizationChart"
          text={getLocalizedText('조직도')}
          icon="sitemap-fill"
          onClick={() => go('/directory')}
        />
        {props.linkMenus && props.linkMenus.length > 0 && (
          <Tooltip title="링크 메뉴" placement="right">
            <button
              type="button"
              className="menu"
              onClick={(event) => handleMenuClick(event, 'linkMenu')}
            >
              <Icon className="badge" icon="external-link" />
            </button>
          </Tooltip>
        )}
        {isDevelopment && (
          <GnbItem
            useTooltip
            id="notifications"
            text={getLocalizedText('알림')}
            icon="bell"
          />
        )}
        <Tooltip title={getLocalizedText('기본 정보')} placement="right">
          <a
            className="menu my"
            onClick={(event) => handleMenuClick(event, 'profile')}
          >
            <Avatar
              key={key}
              image={avatar}
              name={employeeName}
              className="avatar"
            />
          </a>
        </Tooltip>
      </div>
    </EuiGnb>
  );
}

export default Gnb;
