import React, { useEffect, useState } from 'react';
import PostWrite from '../../../../../../components/post/PostWrite';
import { PortalTemplateItem } from '../../../../../stores/templates';
import { templateContentApi } from '../../../../../apis/v1/dashboard/templates';
import Loading from '../../../../../../components/loading/Loading';
import CheckboxGroup from '../../../../../../components/checkbox/CheckboxGroup';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';

function CommonProfileComposeTemplate(props: {
  item: PortalTemplateItem;
  onChange?(url: string, params?: string, options?: string): void;
  url?: string;
}): JSX.Element {
  const [state, setState] = useState<{
    loading: boolean;
    selectedOption: string[];
    menuPoint?: { x: number; y: number; width: number; height: number };
    options?: { label: string; value: string }[];
  }>(() => {
    const selectedOption: string[] = [];
    if (props.item.search) {
      new URLSearchParams(props.item.search).forEach((v, k) => {
        if (v && v !== '') selectedOption.push(v);
      });
    }
    return {
      loading: true,
      selectedOption,
    };
  });

  useEffect(() => {
    let mount = true;
    async function run() {
      try {
        const response = await templateContentApi.findProfileOption();
        if (response && mount) {
          const options = Object.keys(response).map((key) => ({
            label: response[key],
            value: key,
          }));
          setState((prev) => ({
            ...prev,
            selectedOption: prev.selectedOption.filter(
              (x) => options.findIndex((y) => y.value === x) > -1,
            ),
            loading: false,
            options,
          }));
        }
      } catch (ex) {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    if (state.selectedOption && props.onChange) {
      const search = state.selectedOption.map((x) => `module=${x}`).join('&');
      const url = `${props.item.url.split('?')[0]}?${search}`;
      props.onChange(url);
    }
  }, [state.selectedOption]);

  const handleChangeOption = (value: string) => {
    if (!state.options) return;
    if (state.selectedOption.includes(value)) {
      setState((prev) => ({
        ...prev,
        selectedOption: prev.selectedOption.filter((x) => x !== value),
      }));
    } else
      setState((prev) => ({
        ...prev,
        selectedOption: [...prev.selectedOption, value],
      }));
  };

  return (
    <>
      <div>
        <div className="template-content item-content">
          {(state.loading || !state.options) && <Loading />}
          {!state.loading && state.options && (
            <PostWrite.Item title={getLocalizedText('표시')}>
              <CheckboxGroup column>
                {state.options.map((x) => {
                  return (
                    <Checkbox
                      label={x.label}
                      checked={state.selectedOption.includes(x.value)}
                      onChange={() => handleChangeOption(x.value)}
                    />
                  );
                })}
              </CheckboxGroup>
            </PostWrite.Item>
          )}
        </div>
      </div>
    </>
  );
}

export default CommonProfileComposeTemplate;
