import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import { PostListItemType } from '../../../../../components/post/PostList';
import PostView from '../../../../../components/post/PostView';
import SelectField from '../../../../../components/selectField/SelectField';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState as R,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import securitiesApi from '../../../../apis/sangsanginsecurities/v1/securities';
import { securitiesActions } from '../../../../stores/sangsanginsecurities/securities';
import { settingActions } from '../../../../stores/sangsanginsecurities/setting';
import SecuritiesContentBodyList from './SecuritiesContentBodyList';
import SecuritiesContentHeadList from './SecuritiesContentHeadList';

type SecurityListDetailItem = {
  decisGrwNo: string; // 결의번호 (decis_grw_no)
  corpCd: string; // 회사명 (corp_cd)
  decisDeptNm: string; // 결의부서명 (decis_dept_nm)
  decisDy: string; // 결의일자 (decis_dy)
  decisNo: number; // 결의번호 (decis_no)
  decisEno: number; // 결의자사번 (decis_eno) string 아닌가?
  decisEnoNm: string; // 결의자명 (decis_emp_nm)
  useEno: string; // 사용자사번 (use_eno)
  useEnoNm: string; // 사용자명 (use_nm)
  decisAmt: string; // 결의금액 (decis_amt)
  decisCont: string; // 결의내용 (decis_cont)
  decisTypDvcd: string; // 결의구분코드 (decis_typ_dvcd) number?
  slipClassCd: string; // 전표분류코드 (slip_class_cd) number?
  crdrCd: string; // 차대변코드(1: 차변 2 : 대변) (crdr_cd) numebr?
  crdrNm: string; // 차대변이름 (crdr_nm)
  acctCd: string; // 계정과목코드 (acct_cdc) number?
  acctNm: string; // 계정과목명 (acct_nm)
  supplyAmt: string; // 공급가액 (supply_amt)
  vatAmt: string; // 부가세 (vat_amt)
  custCd: string; // 거래처코드 (cust_cd) number?
  custNm: string; // 거래처명 (cust_nm)
  bizplcAddr: string; // 거래처주소 (bizplc_addr)
  apprvNo: string; // 승인번호 (apprv_no)
  amt: string; // 금액 (amt)
  evidncDvcd?: string; // 증빙 구분 코드 (evidnc_dvcd)
  sttlMthdNm?: string; // 결제 방법 코드 (sttl_mthd_nm)
  pttnDy: string; // 전기일자 (pttn_dy)
  billseq: string; //  세금계산서 키
};

function SecuritiesContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${SecuritiesContentContainer.name}.render(props)`, props);
  const { pathname, search } = props;
  const { folder } = getPathParams<{
    folder?: string;
  }>('/*/:folder', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const accessToken = useSelector((s: R) => s.session.jwt);
  const principal = useSelector((s: R) => s.session.principal);
  const display = useSelector((s: R) => s.session.display);
  const directory = useDirectory();

  const currentOrganizationId = useSelector(
    (s: R) => s.securites.setting.currentOrganizationId,
  );
  const categories = useSelector((s: R) => s.securites.setting.categories);
  const list = useSelector((s: R) => s.securites.security.list.items);
  const checkedList = list.filter((a) => a.checked);
  const total = useSelector((s: R) => s.securites.security.list.totalCount);
  const title =
    categories.find((a) => a.id === (folder ?? 'securities'))?.name ?? '';

  const [columns, setColumns] = useState<Column<PostListItemType>[]>([
    { name: 'checked', text: '', type: 'action', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'decisGrwNo', text: '전표번호', type: 'number', visible: true, disable: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'decisCont', text: '설명', type: 'post', visible: true, disable: true },
    { name: 'decisDy', text: '결의일', type: 'date', visible: true },
    { name: 'decisEmpNm', text: '결의자', type: 'user', visible: true },
    { name: 'decisAmt', text: '결재액', type: 'folder', visible: true },
    { name: 'pttnDy', text: '전기일', type: 'date', visible: true },
    { name: 'decisDeptNm', text: '결의부서', type: 'folder', visible: true },
    { name: 'status', text: '상태', type: 'status', visible: true },
  ]);
  const [draft, setDraft] = useState(false);

  useEffect(() => {
    setColumns(
      columns.map((a) => {
        if (a.name === 'decisDy' || a.name === 'pttnDy')
          return {
            ...a,
            type: display === 'phone' ? 'folder' : 'date',
          };
        if (a.name === 'decisAmt')
          return {
            ...a,
            type: display === 'phone' ? 'number' : 'folder',
          };
        if (a.name === 'decisDeptNm')
          return { ...a, visible: display !== 'phone' };
        return a;
      }),
    );
  }, [display]);

  useEffect(() => {
    async function run() {
      const linkId: string[] = [];
      const listData = await securitiesApi.detailList({
        companyId: principal.companyId,
        decisGrwNos: checkedList.map((a) => {
          return a.decisGrwNo;
        }),
      });
      const detailList: SecurityListDetailItem[] = listData.map((a) => {
        return {
          ...a,
          decisDy: a.decisDy
            .toString()
            .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
          decisAmt: a.decisAmt.toLocaleString(),
          supplyAmt: a.supplyAmt.toLocaleString(),
          vatAmt: a.vatAmt.toLocaleString(),
          amt: a.amt.toLocaleString(),
          pttnDy: a.pttnDy.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
          evidncDvcd: a.evidncDvcd === null ? undefined : a.evidncDvcd,
          sttlMthdNm: a.sttlMthdNm === null ? undefined : a.sttlMthdNm,
        };
      });
      const headerOne = [
        '결의번호',
        '결의일자',
        '결의부서',
        '결의금액',
        '등록자',
        '내용',
        '',
        '',
        '전기일자',
      ];
      const headerTwo = [
        '',
        '차/대',
        '계정명',
        '금액',
        '부가세',
        '사용자',
        '상세',
        '지급구분',
        '',
      ];
      const table = document.createElement('table');
      const tbody = document.createElement('tbody');

      // 테이블 헤더 생성.
      for (let i = 0; i < 2; i += 1) {
        const tr = document.createElement('tr');
        headerOne.forEach((_, j) => {
          const td = document.createElement('td');
          const p = document.createElement('p');
          p.setAttribute('style', 'text-align: center; margin-block: 0.3em;');
          const span = document.createElement('span');
          const strong = document.createElement('strong');

          if (i === 0) {
            tr.style.height = '30px';
            if (j === 0 || j === 8) td.rowSpan = 2;
            if (j === 5) td.colSpan = 3;
            if (j === 6 || j === 7) return;
            strong.append(headerOne[j]);
          }
          if (i === 1) {
            tr.style.height = '50px';
            if (j === 0 || j === 8) return;
            strong.append(headerTwo[j]);
          }
          span.append(strong);
          p.append(span);
          td.append(p);
          td.setAttribute(
            'style',
            'border: 2px solid rgb(226, 226, 226); background-color: rgb(247, 247, 247);',
          );
          tr.append(td);
        });
        tbody.append(tr);
      }
      // 테이블 바디 생성.
      detailList.forEach((a) => {
        // 동일한 결의번호.
        const dupDecisGrwNo = detailList.filter(
          ({ decisGrwNo }) => decisGrwNo === a.decisGrwNo,
        );
        if (dupDecisGrwNo.slice(1).some((x) => x === a)) return;

        // 동일한 결의번호가 존재하는 경우.
        if (dupDecisGrwNo.length > 1) {
          dupDecisGrwNo.unshift(dupDecisGrwNo[0]);
          for (let x = 0; x < dupDecisGrwNo.length; x += 1) {
            const data = dupDecisGrwNo[x];
            let custnm: string | HTMLSpanElement | HTMLButtonElement =
              data.custNm;
            if (data.bizplcAddr !== '' && data.apprvNo !== '') {
              custnm = document.createElement('span');
              custnm.append(data.custNm);
              custnm.append(document.createElement('br'));
              custnm.append(
                `카드: ${data.bizplcAddr} / APPROVAL NO: ${data.apprvNo}`,
              );
            }
            if (data.billseq !== '') {
              custnm = document.createElement('button');
              custnm.className =
                'eui-button action icon secondary approval-linkage';
              custnm.setAttribute(
                'onClick',
                `(function(el) {
                  const clickInput = document.getElementById('custom-bill-click');
                  clickInput?.setAttribute('value', '${data.billseq}');
                  clickInput?.click();
              })(this)`,
              );
              const icon = document.createElement('i');
              icon.className = 'eui-icon eui-icon-document-search';
              custnm.appendChild(icon);
            }
            let custSttlNm: string | DocumentFragment = '';
            if (
              data.sttlMthdNm !== undefined ||
              data.evidncDvcd !== undefined
            ) {
              custSttlNm = document.createDocumentFragment();
              if (data.sttlMthdNm) custSttlNm.append(data.sttlMthdNm);
              if (data.sttlMthdNm && data.evidncDvcd)
                custSttlNm.append(document.createElement('br'));
              if (data.evidncDvcd) custSttlNm.append(data.evidncDvcd);
            }
            const bodyOne = [
              data.decisGrwNo,
              data.decisDy,
              data.decisDeptNm,
              data.decisAmt,
              data.decisEnoNm,
              data.decisCont,
              '',
              '',
              data.pttnDy,
            ];
            const bodyTwo = [
              '',
              data.crdrNm,
              data.acctNm,
              data.amt,
              data.vatAmt,
              `${data.useEnoNm}`,
              custnm,
              custSttlNm,
              data.pttnDy,
            ];
            const tr = document.createElement('tr');
            bodyOne.forEach((_, j) => {
              const td = document.createElement('td');
              const p = document.createElement('p');
              p.setAttribute('style', 'margin-block: 0.3em;');
              const span = document.createElement('span');
              if (x === 0) {
                tr.style.height = '30px';
                if (j === 3) p.style.textAlign = 'right';
                else {
                  p.style.textAlign = 'center';
                  if (j === 0) {
                    td.rowSpan = dupDecisGrwNo.length;
                    linkId.push(bodyOne[j]);
                  }
                  if (j === 5) td.colSpan = 3;
                  if (j === 6 || j === 7) return;
                }
                span.append(bodyOne[j]);
              } else {
                tr.style.minHeight = '50px';
                if (j === 3 || j === 4) p.style.textAlign = 'right';
                else {
                  p.style.textAlign = 'center';
                  if (j === 0) return;
                }
                span.append(bodyTwo[j]);
              }
              p.append(span);
              td.append(p);
              td.setAttribute('style', 'border: 2px solid rgb(226, 226, 226);');
              tr.append(td);
            });
            tbody.append(tr);
          }
        }
        // 동일한 결의번호가 존재하지 않는 경우.
        else {
          for (let x = 0; x < 2; x += 1) {
            let custnm: string | HTMLSpanElement | HTMLButtonElement = a.custNm;
            if (a.bizplcAddr !== '' && a.apprvNo !== '') {
              custnm = document.createElement('span');
              custnm.append(a.custNm);
              custnm.append(document.createElement('br'));
              custnm.append(
                `카드: ${a.bizplcAddr} / APPROVAL NO: ${a.apprvNo}`,
              );
            }
            if (a.billseq !== '') {
              custnm = document.createElement('button');
              custnm.className =
                'eui-button action icon secondary approval-linkage';
              custnm.setAttribute(
                'onClick',
                `(function(el) {
                  const clickInput = document.getElementById('custom-bill-click');
                  clickInput?.setAttribute('value', '${a.billseq}');
                  clickInput?.click();
              })(this)`,
              );
              const icon = document.createElement('i');
              icon.className = 'eui-icon eui-icon-document-search';
              custnm.appendChild(icon);
            }
            let custSttlNm: string | DocumentFragment = '';
            if (a.sttlMthdNm !== undefined || a.evidncDvcd !== undefined) {
              custSttlNm = document.createDocumentFragment();
              if (a.sttlMthdNm) custSttlNm.append(a.sttlMthdNm);
              if (a.sttlMthdNm && a.evidncDvcd)
                custSttlNm.append(document.createElement('br'));
              if (a.evidncDvcd) custSttlNm.append(a.evidncDvcd);
            }
            const bodyOne = [
              a.decisGrwNo,
              a.decisDy,
              a.decisDeptNm,
              a.decisAmt,
              a.decisEnoNm,
              a.decisCont,
              '',
              '',
              a.pttnDy,
            ];
            const bodyTwo = [
              '',
              a.crdrNm,
              a.acctNm,
              a.amt,
              a.vatAmt,
              `${a.useEnoNm}`,
              custnm,
              custSttlNm,
              a.pttnDy,
            ];
            const tr = document.createElement('tr');
            bodyOne.forEach((_, j) => {
              const td = document.createElement('td');
              const p = document.createElement('p');
              p.setAttribute('style', 'margin-block: 0.3em;');
              const span = document.createElement('span');

              if (x === 0) {
                tr.style.height = '30px';
                if (j === 3) p.style.textAlign = 'right';
                else {
                  p.style.textAlign = 'center';
                  if (j === 0) {
                    td.rowSpan = 2;
                    linkId.push(bodyOne[j]);
                  }
                  if (j === 5) td.colSpan = 3;
                  if (j === 6 || j === 7) return;
                }
                span.append(bodyOne[j]);
              }
              if (x === 1) {
                tr.style.minHeight = '50px';
                if (j === 3 || j === 4) p.style.textAlign = 'right';
                else {
                  p.style.textAlign = 'center';
                  if (j === 0) return;
                }
                span.append(bodyTwo[j]);
              }
              p.append(span);
              td.append(p);
              td.setAttribute('style', 'border: 2px solid rgb(226, 226, 226);');
              tr.append(td);
            });
            tbody.append(tr);
          }
        }
        const dividerTr = document.createElement('tr');
        const dividerTd = document.createElement('td');
        dividerTr.style.height = '15px';
        dividerTd.colSpan = 9;
        dividerTd.setAttribute(
          'style',
          'border: 2px solid rgb(226, 226, 226); background-color: rgb(239, 229, 194);',
        );
        dividerTr.append(dividerTd);
        tbody.append(dividerTr);
      });
      table.setAttribute('border', '1');
      table.setAttribute('cellspacing', '0');
      table.setAttribute('cellpadding', '0');
      table.setAttribute(
        'style',
        'display: inline-table; height: 100%; white-space: normal; word-break: break-all; font-size: 10pt; border: 1px none rgb(0, 0, 0); border-collapse: collapse;',
      );
      const colGroup = document.createElement('colgroup');
      const width = ['18%', '11%', '11%', '8%', '7%', '7%', '20%', '7%', '11%'];
      for (let i = 0; i < width.length; i += 1) {
        const col = document.createElement('col');
        col.setAttribute('width', width[i]);
        colGroup.append(col);
      }
      table.append(colGroup);
      table.append(tbody);
      // 마지막 row인 경우 스타일 수정.
      const lastTd = tbody.lastElementChild?.lastElementChild;
      if (lastTd !== null && lastTd !== undefined) {
        lastTd.removeAttribute('style');
        lastTd.setAttribute(
          'style',
          'border-width: 2px 2px medium; border-style: solid; border-color: rgb(226, 226, 226) rgb(226, 226, 226) rgb(0, 0, 0);',
        );
      }
      delete queryParams.dialogType;
      queryParams.contentMode = 'create';
      let value = '';
      try {
        await securitiesApi
          .create({
            provider: 'sangsanginsecurities',
            data: {
              linkType: 'DECIS',
              linkId: JSON.stringify(linkId),
              content: table.outerHTML,
              writerEmployeeNumber:
                directory.employees.find(
                  ({ id }) => id === principal.employeeId,
                )?.no ?? '',
              accessToken: accessToken ?? '',
            },
          })
          .then((result) => {
            const html = document.createElement('html');
            html.innerHTML = result.trim();
            const input = html.querySelector('form')?.querySelector('input');
            value = input?.getAttribute('value') ?? '';
          });
        if (value === '') throw new Error('데이터를 찾을 수 없습니다.');
        const data: {
          token: string;
          provision: {
            provider: string;
            linkType: string;
            linkId: string;
            subject: string;
            content: string;
            writerEmail: string;
            attachedSharedfiles?: {
              id: number;
              path: string;
              name: string;
              size: number;
              feature: number;
            }[];
          };
          form: {
            content: string;
            updateAt: string;
          };
          work: {
            id: number;
            folderId: number;
            name: string;
            formId: number;
            formName: string;
            documentNo: string;
            retentionPeriod: number;
            approvalLine: string;
            referrer: string;
            viewer: string;
            useAttachFile: number;
            useAttachDocument: number;
            useOpinion: boolean;
            useComment: boolean;
            linkType: string;
            updateAt: string;
          };
        } = JSON.parse(value);
        const approvalForm = {
          provision: data.provision,
          form: data.form,
          work: data.work,
        };
        dispatch(sessionActions.setApprovalForm({ approvalForm }));
        go(pathname, queryParams);
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
        setDraft(false);
      }
    }
    if (draft) run();
  }, [draft]);

  const handleListItemCheckedChange = (
    id: string | 'all',
    checked: boolean,
  ) => {
    dispatch(securitiesActions.setListItemChecked({ itemId: id, checked }));
  };

  const handleCloseOrganization = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleChangeOrganization = (value: string) => {
    dispatch(settingActions.saveCurrentOrganizationId(parseInt(value, 10)));
  };

  const handleHeadAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;

    // 이전 페이지.
    if (code === 'prev') {
      const pageNo = (queryParams.pageNo ?? 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(pathname, queryParams);
      }
    }
    // 다음 페이지.
    if (code === 'next') {
      const pageNo = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * pageNo) < total) {
        queryParams.pageNo = pageNo;
        go(pathname, queryParams);
      }
    }
    // 기안.
    if (code === 'draft') {
      if (principal.affiliatedOrganizations.length > 0 && display !== 'pc') {
        dispatch(sessionActions.setDialog({ type: 'organization' }));
      } else setDraft(true);
    }
  };

  const renderHead = () => {
    if (list === null || !list) return null;

    const toolbarButtons: ActionEventProps[] = [
      {
        code: 'draft',
        label: '기안',
        type: 'outlined',
        icon: 'clipboard-text',
      },
    ];

    return (
      <SecuritiesContentHeadList
        title={title}
        pageNo={queryParams.pageNo || 1}
        rowsPerPage={queryParams.rowsPerPage || 300}
        totalCount={total}
        checkedCount={list.filter((x) => x.checked).length}
        onCheckedChange={handleListItemCheckedChange}
        toolbarButtons={toolbarButtons}
        onAction={handleHeadAction}
      />
    );
  };

  const renderBody = () => {
    return (
      <SecuritiesContentBodyList
        search={search}
        columns={columns.filter((a) => a.visible === undefined || a.visible)}
        items={list}
        onItemCheckedChange={handleListItemCheckedChange}
      />
    );
  };

  const renderDialog = () => {
    if (queryParams.dialogType === 'organization' && display !== 'pc') {
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>기안부서 선택</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <PostView>
              <SelectField
                block
                data={principal.affiliatedOrganizations.map((a) => {
                  return {
                    value: a.id.toString(),
                    label: getOrganizationName(principal.companyId, a.id),
                  };
                })}
                value={currentOrganizationId.toString()}
                onChange={handleChangeOrganization}
              />
            </PostView>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleCloseOrganization} />
            <Button
              text="확인"
              variant="contained"
              onClick={() => {
                setDraft(true);
              }}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDialog()}
    </>
  );
}

export default SecuritiesContentContainer;
