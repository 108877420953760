import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import { ApprovalLineCodeProps } from '../ApprovalType';
import Avatar from '../../../../../components/avatar/Avatar';
import approvalCodeLabel from '../ApprovalFunction';
import { ApprovalLineGroupItemOutputType } from '../../../common/dialogs/ApprovalLineDialogContainer';
import Pagination from '../common/components/Pagination';
import Icon from '../../../../../components/icon/Icon';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import EmptyData from '../../../../../components/data/EmptyData';
import Search from '../../../../../components/search/Search';
import { RootState } from '../../../../../groupware-webapp/app/store';
import {
  b62,
  getAvatarPath,
  getPathMap,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

/**
 * 상태
 * - [appeal] 상신 (기안자)
 * - [approval] 승인 (결재한사람)
 * - [hold] 보류 (보류한사람)
 * - [defer] 후결 (후결한사람)
 * - [waiting] 대기 (결재차례)
 * - [expected] 예정 (결재 안한사람)
 * - [meet] 대면
 * - [receipt] 접수
 * */
type StatusProps =
  | 'appeal'
  | 'approved'
  | 'hold'
  | 'defer'
  | 'waiting'
  | 'expected'
  | 'meet'
  | 'receipt'
  | 'return';

const createStatus = (
  act?:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none',
) => {
  switch (act) {
    case 'draft':
      return 'appeal';
    case 'approval':
      return 'approved';
    case 'arbitraryDecision':
      return 'approved';
    case 'hold':
      return 'hold';
    case 'defer':
      return 'defer';
    case 'meet':
      return 'meet';
    case 'receipt':
      return 'receipt';
    case 'return':
      return 'return';
    default:
      return 'expected';
  }
};

const statusIcon = (status: StatusProps) => {
  switch (status) {
    case 'waiting':
      return 'stamp-fill';
    case 'hold':
      return 'clock-hands';
    case 'defer':
      return 'redo-half';
    case 'approved':
      return 'check-small';
    case 'receipt':
      return 'document-write';
    case 'meet':
      return 'clock-hands';
    case 'return':
      return 'undo-half';
    default:
      return 'stamp-fill';
  }
};

const statusValue = (
  status: StatusProps,
  arbitraryDecision: boolean | undefined,
): string => {
  switch (status) {
    case 'waiting':
      return getLocalizedText('결재');
    case 'hold':
      return getLocalizedText('보류');
    case 'defer':
      return getLocalizedText('후결');
    case 'approved':
      return arbitraryDecision
        ? getLocalizedText('전결')
        : getLocalizedText('승인');
    case 'receipt':
      return getLocalizedText('접수');
    case 'meet':
      return getLocalizedText('대면');
    case 'return':
      return getLocalizedText('반려');
    default:
      return getLocalizedText('예정');
  }
};

function ApprovalStatus(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const scrollbar = useRef<HTMLDivElement>(null);
  const queryParams = getQueryParams(props.search);
  const list = useSelector((s: RootState) => s.approval2.document.list);
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'default');
  // 고정 폴더 이름
  const title = categories.find((a) => a.id === 5002)?.name;
  function getApprovalLineCode(type: string): ApprovalLineCodeProps {
    switch (type) {
      case 'draft':
        return 'drafter';
      case 'agree':
        return 'agree';
      case 'receive':
        return 'receiver';
      case 'approval':
        return 'approval';
      case 'audit':
        return 'auditor';
      default:
        return 'drafter';
    }
  }

  const [totalCount, setTotalCount] = useState(0);

  /** 결재 */
  const [stateApproval, setStateApproval] = useState<
    {
      id: number;
      subject: string;
      line: {
        id: string;
        code: ApprovalLineCodeProps;
        category: string; // 'employee' | 'organization'; // 사원 혹은 부서
        name: string; // 이름
        avatar: string; // 아바타
        position: string; // 직위
        sort: boolean | undefined; // 병렬
        option: boolean | undefined; // 합의(선택)
        status: StatusProps;
        createAt: string;
        arbitraryDecision?: boolean; // 전결 확인
        return?: boolean; // 합의(선택) 반려일 경우.
      }[];
    }[]
  >([]);

  useEffect(() => {
    let mount = true;
    async function run() {
      if (mount) {
        const dataList = list.data.items.map((a) => {
          const line: {
            id: string; // '1000_1001_001',
            code: ApprovalLineCodeProps; // 'drafter',
            category: string; // 'employee',
            name: string; // '이상윤',
            avatar: string; // '/images/faces/faces_9.jpg',
            position: string; // '수석',
            sort: boolean | undefined; // undefined,
            option: boolean | undefined; // undefined,
            status: StatusProps; // 'appeal',
            createAt: string; // '2021-07-27',
            arbitraryDecision?: boolean; // 전결 표시
            return?: boolean; // 합의(선택) 반려일 경우.
          }[] = [];

          let approverSeq = 0; // 결재자 순번.
          let approvalSeq = 0; // 결재 순번.

          a.approvalLine.groups.forEach((x) => {
            const parallel =
              (x.type === 'agree' || x.type === 'receive') &&
              x.items.length > 1 &&
              x.parallel;

            if ((x.type === 'agree' || x.type === 'receive') && parallel)
              approverSeq += 1;
            (x.items as ApprovalLineGroupItemOutputType[]).forEach(
              (p, index) => {
                let status: StatusProps = createStatus(p.act);

                if (!parallel) approverSeq += 1;

                // 보류, 대면, 접수인 경우.
                if (
                  (p.act === 'hold' ||
                    p.act === 'meet' ||
                    p.act === 'receipt') &&
                  approvalSeq === 0
                )
                  approvalSeq = approverSeq;

                if (p.actAt === undefined && approvalSeq === 0)
                  approvalSeq = approverSeq;
                if (approvalSeq > 0 && approvalSeq < approverSeq)
                  status = 'expected';
                else if (approvalSeq === approverSeq && p.act === undefined)
                  status = 'waiting';

                line.push({
                  id: `${x.id}_${index}_${
                    p.employeeName ? p.employeeId : p.organizationId
                  }`,
                  code: getApprovalLineCode(x.type),
                  category: p.employeeName ? 'employee' : 'organization',
                  name: p.employeeName ? p.employeeName : p.organizationName,
                  avatar: getAvatarPath(p?.companyId || 0, p?.employeeId || 0),
                  position: p.employeeName ? p.jobPositionName : '',
                  sort:
                    x.type === 'agree' || x.type === 'receive'
                      ? x.parallel
                      : undefined,
                  option:
                    x.type === 'agree' ? x.items[index].option : undefined,
                  status,
                  createAt: p.actAt || '',
                  arbitraryDecision:
                    x.type === 'approval' && p.act === 'arbitraryDecision',
                  return: x.type === 'agree' && p.option && p.act === 'return',
                });
              },
            );
          });

          return {
            id: a.id,
            subject: a.subject,
            line,
          };
        });
        setStateApproval(dataList);
        setTotalCount(list.data.totalCount);
        scrollbar.current?.scrollTo(0, 0); // 페이지 이동 시 스크롤 초기화
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, [props]);

  const handlePrePage = () => {
    const pageNo = (queryParams.pageNo || 1) - 1;
    if (pageNo > 0) {
      if (pageNo > 1) queryParams.pageNo = pageNo;
      else delete queryParams.pageNo;
      go(`${getPathMap('/*/*', props.pathname)}`, queryParams);
    }
  };

  const handleNextPage = () => {
    const pageNo = (queryParams.pageNo || 1) + 1;
    queryParams.pageNo = pageNo;
    go(`${getPathMap('/*/*', props.pathname)}`, queryParams);
  };

  const handleSearch = (arg: { keyword: string; filter?: string }) => {
    queryParams.searchCode = arg.filter;
    queryParams.searchWord = arg.keyword;
    delete queryParams.pageNo;
    go(`${getPathMap('/*/*', props.pathname)}`, queryParams);
  };

  /** 리스트 클릭 이벤트 */
  const handleGo = (id: number, event: React.MouseEvent) => {
    event.preventDefault();
    const { pathname, search } = props;
    go(`${getPathMap('/*', pathname)}/${b62(1009)}/${b62(id)}`, search);
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`폴더.${title}`)}</EuiHeader.Title>
        <EuiHeader.Toolbar>
          <EuiHeader.ToolbarLeft>
            <Search
              keyword={queryParams.searchWord || ''}
              options={[
                {
                  value: 'subject',
                  label: getLocalizedText('제목'),
                },
                {
                  value: 'documentno',
                  label: getLocalizedText('문서번호'),
                },
                {
                  value: 'workname',
                  label: getLocalizedText('업무명'),
                },
                // { value: 'drafterid', label: '기안자' },
              ]}
              filter={queryParams.searchCode || 'subject'}
              onSearch={handleSearch}
            />
          </EuiHeader.ToolbarLeft>
          <EuiHeader.ToolbarRight>
            {totalCount > 0 && (
              <Pagination
                no={queryParams.pageNo || 1}
                rows={queryParams.rowsPerPage || 15}
                count={totalCount}
                onPrev={handlePrePage}
                onNext={handleNextPage}
              />
            )}
          </EuiHeader.ToolbarRight>
        </EuiHeader.Toolbar>
      </EuiHeader>
      <EuiBody scrollbar={scrollbar}>
        <div className="ui-approval-status">
          {stateApproval.length === 0 ? (
            <EmptyData
              message={getLocalizedText('진행 중인 결재가 없습니다.')}
            />
          ) : (
            stateApproval.map((item) => (
              <div className="status-item" key={item.id}>
                <a
                  href="#"
                  className="item-subject"
                  onClick={(event) => handleGo(item.id, event)}
                >
                  {item.subject}
                </a>
                <div className="item-line">
                  {item.line.map((line) => {
                    let classname = 'line-item';
                    if (line.code) classname += ` ${line.code}`;
                    if (line.sort) classname += ' sort';
                    if (line.option) classname += ' option';
                    if (line.status) classname += ` ${line.status}`;

                    return (
                      <div className={classname} key={line.id}>
                        <div className="code">
                          {approvalCodeLabel(line.code)}
                          {line.return &&
                            line.createAt !== '' &&
                            `[${getLocalizedText('선택')}}]`}
                        </div>
                        <div className="photo">
                          {line.category === 'organization' ? (
                            <Avatar
                              name={line.name}
                              icon="sitemap"
                              className="avatar"
                            />
                          ) : (
                            <Avatar
                              name={line.name}
                              image={line.avatar}
                              className="avatar"
                            />
                          )}
                          {line.status !== 'appeal' &&
                            line.status !== 'expected' && (
                              <Icon
                                className="status"
                                icon={statusIcon(line.status)}
                                tooltip={statusValue(
                                  line.status,
                                  line.arbitraryDecision,
                                )}
                              />
                            )}
                        </div>
                        <div className="name">
                          <strong>{line.name}</strong> <em>{line.position}</em>
                        </div>
                        {line.createAt && (
                          <div className="date">
                            <span>
                              {dateSimplify(line.createAt, 'dateNumber')}
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))
          )}
        </div>
      </EuiBody>
    </>
  );
}

export default ApprovalStatus;
