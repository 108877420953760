import React from 'react';
import DirectoryRoute from '../pages/root/directory/DirectoryRoute';

function RootLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // console.log(`${RootLocateRoute.name}.render(props)`, props);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash, locationKey: key } = props;

  return (
    <DirectoryRoute pathname={pathname} search={search} hash={hash} key={key} />
  );
}

export default RootLocateRoute;
