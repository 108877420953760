/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import { getLocalizedText } from '../../../groupware-common/utils/i18n';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import documentApi from '../../apis/document/v1/document';
import {
  AttachFile,
  DocumentItem,
  DocumentViewItem,
  CommentItem,
  VersionItem,
} from './document';

const name = 'document/trash';

interface State {
  list: DocumentItem[];
  totalCount: number;
  view: DocumentViewItem | undefined;
  version: {
    list: VersionItem[];
    view: DocumentViewItem | undefined; // DocumentContent | undefined;
  };
}

const initialState: State = {
  list: [],
  totalCount: 0,
  view: undefined,
  version: {
    list: [],
    view: undefined,
  },
};

/** 삭제함 리스트 조회. */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const queryParams = getQueryParams(arg.search);
      const list = await documentApi.trashList({
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 15,
      });
      const totalCount = await documentApi.trashTotalCount({
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      });
      return {
        list: list.map((a) => ({
          checked: false,
          isNotice: false,
          ...a,
          listId: `${a.id}`,
          deleterId: a.deleterId === null ? undefined : a.deleterId,
        })),
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 삭제함 문서 단일 조회. */
const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { id: number; updateAt?: string } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let commentData: CommentItem[] | undefined;
      const response = await documentApi.findReadOnlyView({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      const versions = await documentApi.versionList({ id: arg.id });
      if (response.comments > 0)
        commentData = await documentApi.findComments({ postid: response.id });
      const attachedFiles: AttachFile[] = [];
      if (response.attachedFiles) {
        response.attachedFiles.forEach((a) => attachedFiles.push(a));
        attachedFiles.sort(
          (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
        );
      }
      return {
        view: {
          ...response,
          attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
          commentData,
        },
        versions,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 복원. */
const restore = createAsyncThunk(
  `${name}/restore`,
  async (
    arg: {
      data:
        | {
            id: number;
            updateAt: string;
          }
        | {
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (Array.isArray(arg.data)) {
        await documentApi.restore(arg.data);
      } else {
        await documentApi.restore(arg.data);
      }
      if (arg.route) dispatch(findList({ search: arg.route.search ?? '' }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 완전 삭제. */
const forceDelete = createAsyncThunk(
  `${name}/forceDelete`,
  async (
    arg: {
      data:
        | {
            id: number;
            updateAt: string;
          }
        | {
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    const { data, route } = arg;
    try {
      if (Array.isArray(data)) {
        const result = await documentApi.forceDelete(data);
        if (Array.isArray(result) && result.length !== data.length) {
          const sessionRoute = (getState() as RootState).session.route;
          await dispatch(sessionActions.setDialog());
          await dispatch(findList({ search: sessionRoute.search ?? '' }));
          return rejectWithValue(
            appError({
              error: getLocalizedText(
                '문서 완전 삭제 중 오류가 발생하여 일부 게시글은 완전 삭제 실패하였습니다.',
              ),
            }),
          );
        }
      } else await documentApi.forceDelete(data);
      if (route) dispatch(findList({ search: route.search ?? '' }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 삭제함 비우기 */
const vacateDocument = createAsyncThunk(
  `${name}/vacateDocument`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      await documentApi.vacateDocument();
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const trashSlice = createSlice({
  name,
  initialState,
  reducers: {
    setListItemChecked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (action.payload.itemId === 'all') {
        state.list = state.list.map((x) => {
          if (x.checked === action.payload.checked) return x;
          return { ...x, checked: action.payload.checked };
        });
      } else {
        const index = state.list.findIndex(
          (x) => x.id === action.payload.itemId,
        );
        if (index > -1) {
          state.list[index] = {
            ...state.list[index],
            checked: action.payload.checked,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = payload.list;
          state.totalCount = payload.totalCount;
        }
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = payload.view;
          state.version.list = payload.versions;
        }
      })
      .addCase(vacateDocument.fulfilled, (state) => {
        state.list = [];
        state.totalCount = 0;
      });
  },
});

export default trashSlice.reducer;

export const trashActions = {
  setListItemChecked: trashSlice.actions.setListItemChecked,

  list: findList,
  view: findView,

  restore,
  forceDelete,
  vacateDocument,
};
