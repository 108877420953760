import React from 'react';
import ReactDOM from 'react-dom';
import Button from '../button/Button';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

const portals = document.getElementById('portals');

interface Props {
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'full';
  overflow?: 'visible' | 'hidden';
  children?: React.ReactNode;
  align?: 'center' | 'start';
  onClose?(): void;
}

class Dialog extends React.Component<Props> {
  element: HTMLDivElement;

  focusRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    let classname = 'eui-dialog';
    if (props.className) classname += ` ${props.className}`;
    if (props.align) classname += ` align-${props.align}`;
    if (props.size) classname += ` ${props.size}`;
    if (props.overflow) classname += ` ${props.overflow}`;

    super(props);
    this.focusRef = React.createRef();
    this.element = document.createElement('div');
    this.element.setAttribute('class', classname);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount(): void {
    // Portal 엘리먼트는 Modal의 자식이 마운트된 후 DOM 트리에 삽입됩니다.
    // 요컨대, 자식은 어디에도 연결되지 않은 DOM 노드로 마운트됩니다.
    // 만약 자식 컴포넌트가 마운트될 때 그것을 즉시 DOM 트리에 연결해야만 한다면,
    // 예를 들어, DOM 노드를 계산한다든지 자식 노드에서 'autoFocus'를 사용한다든지 하는 경우에,
    // Modal에 state를 추가하고 Modal이 DOM 트리에 삽입되어 있을 때만 자식을 렌더링하십시오.
    this.element.setAttribute('data-state', 'enter');
    portals?.appendChild(this.element);
    this.focusRef.current?.focus();
  }

  componentWillUnmount(): void {
    portals?.removeChild(this.element);
  }

  handleAnimationEnd(): void {
    if (this.element.getAttribute('data-state') === 'enter') {
      this.element.removeAttribute('data-state');
    } else if (this.element.getAttribute('data-state') === 'leave') {
      if (this.props.onClose) this.props.onClose();
      this.element.removeAttribute('data-state');
    }
  }

  handleClose(): void {
    this.element.setAttribute('data-state', 'leave');
  }

  // eslint-disable-next-line class-methods-use-this
  handleFocus(event: React.FocusEvent<HTMLInputElement>): void {
    event.currentTarget.blur();
  }

  handleBlur(): void {
    this.focusRef.current?.focus();
  }

  render(): React.ReactPortal {
    return ReactDOM.createPortal(
      <>
        <div className="overlay dialog-overlay" aria-expanded="true" />
        <div
          className="dialog-container"
          onAnimationEnd={this.handleAnimationEnd}
        >
          <div className="eui-helper-hidden-accessible">
            <input type="text" readOnly ref={this.focusRef} />
          </div>
          {this.props.children}
          {this.props.onClose && (
            <Button
              text={getLocalizedText('닫기')}
              iconType
              icon="close"
              className="dialog-close"
              onClick={this.handleClose}
              color="secondary"
            />
          )}
          <div className="eui-helper-hidden-accessible">
            <input
              type="text"
              readOnly
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          </div>
        </div>
      </>,
      this.element,
    );
  }
}

export default Dialog;
