import React from 'react';
import Button from '../button/Button';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function EuiSnbHeader(props: { children: React.ReactNode }): JSX.Element {
  return <div className="snb-header">{props.children}</div>;
}

function EuiSnbTitle(props: { title: string }): JSX.Element {
  return <h2 className="snb-title">{props.title}</h2>;
}

function EuiSnbAction(props: { children: React.ReactNode }): JSX.Element {
  return <div className="snb-action">{props.children}</div>;
}

function EuiSnbTab(props: { children: React.ReactNode }): JSX.Element {
  return <div className="snb-tab">{props.children}</div>;
}

function EuiSnbNav(props: { children: React.ReactNode }): JSX.Element {
  return <div className="snb-nav">{props.children}</div>;
}

function EuiSnb(props: {
  toggleLabel?: string;
  onToggle?(): void;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      <div className="ui-snb">{props.children}</div>
      {props.onToggle && (
        <div className="ui-snb-toggle">
          <Button
            text={props.toggleLabel || getLocalizedText('메뉴 접기')}
            iconType
            icon="angle-left"
            onClick={props.onToggle}
            placement="right"
            color="secondary"
            size="xs"
            className="toggle-button"
          />
        </div>
      )}
    </>
  );
}

EuiSnb.Header = EuiSnbHeader;
EuiSnb.Title = EuiSnbTitle;
EuiSnb.Action = EuiSnbAction;
EuiSnb.Tab = EuiSnbTab;
EuiSnb.Nav = EuiSnbNav;

export default EuiSnb;
