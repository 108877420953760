import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import loginSettingApi from '../apis/authentication/v1/login';

export interface LoginSettingItem {
  companyId: number; // 회사 아이디
  usePasswordAlarm: boolean; // 비밀번호 변경 알림 사용 여부
  passwordAlarmCycle: number; // 비밀번호 변경 알림 주기
  usePasswordChangeRequired: boolean; // 비밀번호 변경 강제 여부
  useSigninFailureBlock: boolean; // 로그인 시도 횟수 차단 사용 여부
  signinFailureBlockLimit: number; // 차단될 로그인 시도 횟수 제한 수
  useRequiredPasswordSpecialChar: boolean; // 비밀번호 특수문자 필수 여부
  useAutoLogout: boolean; // 자동 로그아웃 사용 여부
  autoLogoutTime: number; // 자동 로그아웃 시간
  updateAt: string;
}

export interface SaveLoginSettingItem {
  usePasswordAlarm?: boolean;
  passwordAlarmCycle?: number;
  usePasswordChangeRequired?: boolean;
  useSigninFailureBlock?: boolean;
  signinFailureBlockLimit?: number;
  useRequiredPasswordSpecialChar?: boolean;
  useAutoLogout?: boolean;
  autoLogoutTime?: number;
  updateAt: string;
}

const name = 'authentication/login';

interface State {
  data: LoginSettingItem;
}

const initialState: State = {
  data: {
    companyId: 0,
    usePasswordAlarm: false,
    passwordAlarmCycle: 30,
    usePasswordChangeRequired: false,
    useSigninFailureBlock: false,
    signinFailureBlockLimit: 1,
    useRequiredPasswordSpecialChar: true,
    useAutoLogout: false,
    autoLogoutTime: 60,
    updateAt: '1000-01-01T00:00:00.000000',
  },
};

const loginSetting = createAsyncThunk(
  `${name}/loginSetting`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const result = await loginSettingApi.loginSetting(companyId);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const saveLoginSetting = createAsyncThunk(
  `${name}/saveLoginSetting`,
  async (arg: SaveLoginSettingItem, { getState, rejectWithValue }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const result = await loginSettingApi.saveLoginSetting({
        companyId,
        data: arg,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const authenticationSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginSetting.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(saveLoginSetting.fulfilled, (state, { payload }) => {
        state.data = payload;
      });
  },
});

export default authenticationSlice.reducer;

export const authenticationActions = {
  loginSetting,
  saveLoginSetting,
};
