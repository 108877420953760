import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import Tree from '../../../../../components/tree/Tree';
import Button from '../../../../../components/button/Button';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';

// TODO 환경설정
function BoardDrawer(props: { pathname: string; search: string }) {
  // console.log(`${BoardDrawer.name}(props)`, props);
  const dispatch = useAppDispatch();
  const { pathname, search } = props;
  const queryParams = getQueryParams(search);
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const selectedId =
    !folderId ||
    folderId === 'all' ||
    folderId === 'importance' ||
    folderId === 'temp'
      ? undefined
      : b62(folderId);

  const resource = useSelector((state: RootState) => state.session.resource);
  const principal = useSelector((state: RootState) => state.session.principal);
  const folders = useSelector((state: RootState) => state.boards.folder.list);
  const categories = useSelector(
    (state: RootState) => state.boards.board.category,
  ).filter((a) => a.type === 'status');

  // 고정 폴더 이름.
  const getDefaultFolderName = (id: string) => {
    const category = categories.find((a) => a.id === id);
    if (!category) return undefined;
    return getLocalizedText(`폴더.${category.name}`);
  };

  const handleLink = (id?: number | string | 'create') => {
    const isWrite =
      folders.find((a) => a.id === selectedId)?.permissions?.useWrite ?? true;
    if (id === 'create') {
      if (isWrite) {
        queryParams.contentMode = 'create';
        delete queryParams.dialogType;
        delete queryParams.contentType;
        go(pathname, queryParams);
      } else {
        dispatch(
          sessionActions.error(
            appError({
              error: getLocalizedText('선택한 폴더에 작성 권한이 없습니다.'),
            }),
          ),
        );
      }
    } else if (typeof id === 'number') {
      go(`${getPathMap('/*', pathname)}/${b62(id)}`);
    } else if (typeof id === 'string') {
      go(`${getPathMap('/*', pathname)}/${id}`);
    } else {
      go(`${getPathMap('/*', pathname)}`);
    }
  };

  const items = useMemo(() => {
    return folders
      .map((a) => ({ ...a, text: a.name, icon: 'folder' as const }))
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
  }, [folders]);

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title={getLocalizedText('모듈.게시')} />
        <EuiSnb.Action>
          <Button
            text={getLocalizedText('글쓰기')}
            variant="contained"
            block
            onClick={() => handleLink('create')}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <Nav>
          <MenuItem
            label={getDefaultFolderName('all')}
            icon="archive"
            selected={!folderId || folderId === 'all'}
            onClick={() => handleLink('all')}
          />
          <MenuItem
            label={getDefaultFolderName('importance')}
            icon="star-fill"
            selected={folderId === 'importance'}
            onClick={() => go('/board/importance')}
          />
          <MenuItem
            label={getDefaultFolderName('temp')}
            icon="document"
            selected={folderId === 'temp'}
            onClick={() => go('/board/temp')}
          />
        </Nav>
        <Nav title={getLocalizedText('게시함')}>
          <Tree
            selectedId={b62(folderId)}
            items={items}
            onItemClick={(id: number) => handleLink(id)}
          />
        </Nav>
        {resource === 'teams' &&
          principal.roles.find(
            (role) => role === 'ADMIN' || role === 'BOARD_ADMIN',
          ) && (
            <>
              <MenuDivider />
              <Nav>
                <MenuItem
                  label={getLocalizedText('게시설정')}
                  icon="user-cog"
                  onClick={() => go('/adminconsole/board')}
                />
              </Nav>
            </>
          )}
      </EuiSnb.Nav>
    </>
  );
}

export default React.memo(BoardDrawer);
