import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import UserInfo from '../../../../../components/user/UserInfo';
import PostList, {
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import Button from '../../../../../components/button/Button';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';
import Chip from '../../../../../components/chip/Chip';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

type Label = {
  id: number;
  name: string;
};

type Props = {
  type?: PostListType;
  id?: number;
  selected?: boolean;
  columns?: Column<PostListItemType>[];
  item?: Record<string, User | Label[] | boolean | string | number | undefined>;
  onClick?(id: number): void;
  onCheckedChange?(itemId: number, checked: boolean): void;
  onImportanceChange?(id: number): void;
  onLabelClick?(id: number): void;
};

function ContactContentBodyListItem(dumy: Props) {
  const display = useSelector((state: RootState) => state.session.display);
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || 0,
    selected: dumy.selected || false,
    columns: dumy.columns || ([] as Column<PostListItemType>[]),
    item:
      dumy.item ||
      ({
        checked: false,
        importance: false,
        name: {
          name: getLocalizedText('홍길동'),
          organization: getLocalizedText('조직'),
          job: getLocalizedText('직위/직책'),
          avatar: '/images/faces/faces_31.jpg',
        },
        email: '',
        phone: '',
        company: '',
        lable: '',
      } as Record<string, User | boolean | string | number | undefined>),
    onCheckedChange: dumy.onCheckedChange || emptyFunction,
    onImportanceChange: dumy.onImportanceChange || emptyFunction,
    onClick: dumy.onClick || emptyFunction,
    onLabelClick: dumy.onLabelClick || emptyFunction,
  };
  const handleClick = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    props.onClick(props.id);
  };

  const handleCheckedChange = () => {
    props.onCheckedChange(props.id, !props.item.checked);
  };

  const handleImportanceChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    props.onImportanceChange(props.id);
  };

  const handleLabelClick = (arg: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    id: number;
  }) => {
    const { event, id } = arg;
    event.stopPropagation();
    props.onLabelClick(id);
  };

  const { selected, columns, item } = props;

  return (
    <PostList.Item selected={selected} onClick={handleClick}>
      {columns.map(({ name, type, text }) => {
        const value = item[name];
        let children: React.ReactNode | null = null;
        if (name === 'checked' && typeof value === 'boolean') {
          children = (
            <Checkbox checked={value} onChange={handleCheckedChange} />
          );
        } else if (name === 'importance' && typeof value === 'boolean') {
          children = (
            <Button
              text={getLocalizedText('별표표시')}
              icon={value ? 'star-fill' : 'star'}
              iconType
              onClick={handleImportanceChange}
            />
          );
        } else if (type === 'count' && typeof value === 'number') {
          if (display === 'phone')
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px' }}>{value}</span>
              </span>
            );
          else children = <PostList.Value value={value} />;
        } else if (type === 'contact') {
          const { avatar, name: username, organization, job } = value as User;
          children = (
            <UserInfo
              avatar={username === '' ? undefined : avatar}
              name={username === '' ? organization : username}
              from={username === '' ? undefined : organization}
              position={username === '' ? undefined : job}
              icon={username === '' ? 'sitemap-fill' : undefined}
            />
          );
        } else if (name === 'label') {
          const labels = value as Label[];
          if (labels.length === 0) children = <></>;
          else
            children = labels.map((a, i) => {
              return (
                <div
                  key={a.id}
                  style={{
                    display: 'flex',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: '12px',
                  }}
                >
                  <Chip
                    className="contact-label"
                    size="xs"
                    label={a.name}
                    onClick={(event) => handleLabelClick({ event, id: a.id })}
                  />
                  {i !== labels.length - 1 && <div style={{ margin: '2px' }} />}
                </div>
              );
            });
        } else if (typeof value === 'string') {
          if (display === 'phone')
            children = (
              <span>
                <PostList.Value value={text} />
                <span style={{ paddingLeft: '10px', wordBreak: 'break-all' }}>
                  {value}
                </span>
              </span>
            );
          else
            children = (
              <PostList.Value className="cell-contact-value" value={value} />
            );
        }
        const style =
          type === 'count' ? { justifyContent: 'center' } : undefined;
        return (
          <PostList.Cell
            key={name}
            type={type}
            style={style}
            align={type !== 'contact' ? 'center' : undefined}
          >
            {children}
          </PostList.Cell>
        );
      })}
    </PostList.Item>
  );
}

export default React.memo(ContactContentBodyListItem);
