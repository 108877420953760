import axios from 'axios';
import { DocumentData } from '../../../../../groupware-approval/apis/approval/v1/document';
import { DocumentItem } from '../../../../../groupware-approval/apis/approval/v1/permission';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

export interface SecuritiesItem {
  decisGrwNo: string; // 결의번호 (decis_grw_no)
  decisCont: string; // 결의내용 (decis_cont)
  decisDy: string; // 결의일자 (decis_dy)
  decisEnoNm: string; // 결의자명 (decis_emp_nm)
  decisDeptNm: string; // 결의부서명 (decis_dept_nm)
  decisAmt: string; // 결의금액 (decis_amt)
  pttnDy: string; // 전기일 (pttn_dy)
}

interface ListDetailItem {
  decisGrwNo: string; // 결의번호 (decis_grw_no)
  corpCd: string; // 회사명 (corp_cd)
  decisDeptNm: string; // 결의부서명 (decis_dept_nm)
  decisDy: number; // 결의일자 (decis_dy)
  decisNo: number; // 결의번호 (decis_no)
  decisEno: number; // 결의자사번 (decis_eno) string 아닌가?
  decisEnoNm: string; // 결의자명 (decis_emp_nm)
  useEno: string; // 사용자사번 (use_eno)
  useEnoNm: string; // 사용자명 (use_nm)
  decisAmt: number; // 결의금액 (decis_amt)
  decisCont: string; // 결의내용 (decis_cont)
  decisTypDvcd: string; // 결의구분코드 (decis_typ_dvcd) number?
  slipClassCd: string; // 전표분류코드 (slip_class_cd) number?
  crdrCd: string; // 차대변코드(1: 차변 2 : 대변) (crdr_cd) numebr?
  crdrNm: string; // 차대변이름 (crdr_nm)
  acctCd: string; // 계정과목코드 (acct_cdc) number?
  acctNm: string; // 계정과목명 (acct_nm)
  supplyAmt: number; // 공급가액 (supply_amt)
  vatAmt: number; // 부가세 (vat_amt)
  custCd: string; // 거래처코드 (cust_cd) number?
  custNm: string; // 거래처명 (cust_nm)
  bizplcAddr: string; // 거래처주소 (bizplc_addr)
  apprvNo: string; // 승인번호 (apprv_no)
  amt: number; // 금액 (amt)
  evidncDvcd: string | null; // 증빙 구분 코드 (evidnc_dvcd)
  sttlMthdNm: string | null; // 결제 방법 코드 (sttl_mthd_nm)
  pttnDy: string; // 전기일자 (pttn_dy)
  billseq: string; // 세금계산서 키
}

interface TaxReceiptItem {
  taxDvcd: string; // 세금 계산서 종류
  billseq: string; // 키값
  dt: string; // 날짜
  reportIssueId: string; // 승인번호
  svenderno: string; // 공급자 사업자번호
  scompany: string; // 공급자 상호
  sceoname: string; // 공급자 대표명
  saddress: string; // 공급자 주소
  suptae: string; // 공급자 업태
  supjong: string; // 공급자 업종
  semail: string; // 공급자 이메일
  rvenderno: string; // 공급받는자 사업자번호
  rcompany: string; // 공급받는자 회사명
  rceoname: string; // 공급받는자 대표명
  raddress: string; // 공급받는자 주소
  ruptae: string; // 공급받는자 업태
  rupjong: string; // 공급받는자 업종
  remail: string; // 공급받는자 이메일
  supmoney: number; // 공급가
  taxmoney: number; // 세액
  totmoney: number; // 총액
  cash: number | null; // 현금
  checks: number | null; // 수표
  note: number | null; // 어음
  credit: number | null; // 외상미수금
  reportAmendCd: string | null; // 수정사유코드
  amendNm: string | null; // 수정사유
  bigo: string; // 비고
  cyGubun: string; // 청구영수구분
  items: {
    itDt: string; // 날짜
    itMonth: string; // 달
    itDay: string; // 일
    obj: string | null; // 품목
    unit: string | null; // 규격
    vlm: string | null; // 수량
    danga: string | null; // 단가
    sup: number; // 공급가액
    tax: number; // 세액
    remark: string | null; // 비고
  }[]; // 항목
}

/** 회계 전표 목록 조회. */
async function findList(arg: {
  companyId: number;
  pageno: number;
  rowsperpage: number;
}): Promise<{
  count: number;
  list: SecuritiesItem[];
}> {
  try {
    const params = { pageno: arg.pageno, rowsperpage: arg.rowsperpage };
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/accounting/v1/companies/${arg.companyId}/slips/simply`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계전표 상세정보 조회 */
async function findDetailList(arg: {
  companyId: number;
  decisGrwNos: string[];
}): Promise<ListDetailItem[]> {
  try {
    const params = { decisGrwNos: arg.decisGrwNos };
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/accounting/v1/companies/${arg.companyId}/slips`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계전표 문서 작성. */
async function createDocument(arg: {
  provider: string;
  data: {
    linkType: string;
    linkId: string;
    content: string;
    writerEmployeeNumber: string;
    accessToken: string;
  };
}): Promise<string> {
  try {
    const { provider, data } = arg;
    const formData = new FormData();
    formData.append('linkType', data.linkType);
    formData.append('linkId', data.linkId);
    formData.append('content', data.content);
    formData.append('writerEmployeeNumber', data.writerEmployeeNumber);
    formData.append('accessToken', data.accessToken);
    const { host, headers } = getApiConfig();
    const url = `${host}/interworking/${provider}/approval/v1/compose`;
    const response = await axios.post(url, formData, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 세금계산서 조회 */
async function findTaxReceipts(arg: {
  companyId: number;
  employeeId: number;
  billseq: string;
}): Promise<TaxReceiptItem> {
  try {
    const { companyId, employeeId, billseq } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/accounting/v1/companies/${companyId}/employees/${employeeId}/taxReceipts/${billseq}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 전표 승인 목록 조회 */
async function findDocumentList(arg: {
  companyId: number;
  employeeId: number;
  folderId: number;
  draftorganizationname?: string;
  pageno: number;
  rowsperpage: number;
}): Promise<{
  count: number; // 문서 총 갯수.
  list: DocumentItem[];
}> {
  try {
    const { companyId, employeeId, folderId, ...params } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/approval/v1/companies/${companyId}/employees/${employeeId}/folders/${folderId}/documents`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 결재 문서 조회 */
async function findDocumentData(arg: {
  companyId: number;
  employeeId: number;
  documentId: number;
}): Promise<DocumentData> {
  try {
    const { companyId, employeeId, documentId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/approval/v1/companies/${companyId}/employees/${employeeId}/folders/all/documents/${documentId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const securitiesApi = {
  list: findList,
  detailList: findDetailList,
  taxReceipts: findTaxReceipts,
  documentList: findDocumentList,
  documentData: findDocumentData,

  create: createDocument,
};

export default securitiesApi;
