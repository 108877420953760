import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import ApprovalApproverMacroEditDrawer from './ApprovalApproverMacroEditDrawer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  getAvatarPath,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import { approverMacroActions } from '../../../../stores/approval/approverMacro';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { getDirectoryTreeId } from '../../../../../components/tree/DirectoryTree';
import DataGrid, {
  DataGridColDef,
  DataGridMoreActionProps,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import FeedBack from '../../../../../components/alert/FeedBack';

function ApprovalApproverMacroContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const defaultActions: ActionEventProps[] = [
    {
      code: 'create',
      label: getLocalizedText('신규'),
      type: 'icon',
      icon: 'plus-circle-fill',
    },
  ];

  const moreActions: DataGridMoreActionProps[] = [
    {
      code: 'delete',
      label: getLocalizedText('삭제'),
      icon: 'trash-full',
    },
  ];

  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();
  const directory = useDirectory();
  const list = useSelector(
    (state: RootState) => state.approval2.approverMacro.list.data,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6004)?.name;

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [list]);

  const items = list.items.map((item) => {
    const directoryData = getDirectoryData({ ...directory, ...item });
    const employeeName =
      directoryData.jobClassName === ''
        ? directoryData.employeeName
        : `${directoryData.employeeName} ${directoryData.jobClassName}`;
    return {
      ...item,
      organizationName: directoryData.organizationName,
      employeeName,
    };
  });

  const [columns, setColumns] = useState<DataGridColDef[]>([
    {
      field: 'subject',
      label: getLocalizedText('담당자명'),
      minWidth: 200,
    },
    {
      field: 'user',
      label: getLocalizedText('결재자'),
      width: 200,
    },
    { field: 'more', label: '', width: 100, align: 'right' },
  ]);

  const rows: DataGridRowsProps<number> = list.items.map((row) => {
    const directoryData = getDirectoryData({ ...directory, ...row });
    const employeeName =
      directoryData.jobClassName === ''
        ? directoryData.employeeName
        : `${directoryData.employeeName} ${directoryData.jobClassName}`;
    return [
      { type: 'subject' as const, value: row.name, id: row.id },
      {
        type: 'user' as const,
        avatar: getAvatarPath(row.companyId, row.employeeId),
        value: employeeName,
        from: directoryData.organizationName,
      },
      {
        type: 'more' as const,
        id: row.id,
      },
    ];
  });

  const [state, setState] = useState({ validation: '' });

  /** 저장. */
  const handleSave = (
    arg:
      | {
          id?: undefined;
          name: string;
          organizationId: number;
          employeeId: number;
        }
      | {
          id: number;
          name: string;
          organizationId: number;
          employeeId: number;
          updateAt: string;
        },
  ) => {
    // console.log(`handleSave(arg)`, arg);

    if (arg.employeeId === 0) {
      const validation = getLocalizedText('결재자를 선택해주세요.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    if (arg.name.trim() === '') {
      const validation = getLocalizedText('담당자를 입력해주세요.');
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const { pathname } = props;
    const params = { ...queryParams };
    delete params.drawerMode;
    delete params.id;
    const route = { pathname, search: getQueryParams(params) };
    dispatch(approverMacroActions.save({ arg, route }));
  };

  /** 드로워 닫기. */
  const handleDrawerClose = () => {
    const params = { ...queryParams };
    delete params.drawerMode;
    delete params.id;
    dispatch(sessionActions.search(getQueryParams(params)));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 드로워 렌터링 */
  const renderDrawer = () => {
    const { drawerMode } = queryParams;
    if (drawerMode === 'create') {
      return (
        <ApprovalApproverMacroEditDrawer
          onSave={handleSave}
          onClose={handleDrawerClose}
        />
      );
    }
    if (drawerMode === 'update') {
      const { id } = queryParams;
      const item = items.find((a) => a.id === id);
      if (item === undefined) {
        // '데이터가 이동되었거나 삭제되어 수정할 수 없습니다.'
        const params = { ...queryParams };
        delete params.drawerMode;
        delete params.id;
        dispatch(sessionActions.search(getQueryParams(params)));
        return null;
      }

      return (
        <ApprovalApproverMacroEditDrawer
          id={item.id}
          name={item.name}
          organizationId={item.organizationId}
          employeeId={item.employeeId}
          updateAt={item.updateAt}
          approverName={`${item.organizationName} / ${item.employeeName}`}
          selectedTreeId={getDirectoryTreeId(
            item.companyId,
            item.organizationId,
            item.employeeId,
          )}
          onSave={handleSave}
          onClose={handleDrawerClose}
        />
      );
    }
    return null;
  };

  const { validation } = state;
  const { totalCount } = list;

  const handleClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code, id } = arg;

    /** 생성 드로워 열기. */
    if (code === 'create') {
      dispatch(sessionActions.setDrawer({ mode: 'create' }));
    }

    if (code === 'subject') {
      const params = { ...queryParams };
      params.drawerMode = 'update';
      params.id = id;
      dispatch(sessionActions.search(getQueryParams(params)));
      return;
    }

    if (code === 'delete') {
      const item = list.items.find((a) => a.id === id);
      if (item === undefined) return;

      const { pathname } = props;
      delete queryParams.pageNo;

      const target = {
        id: item.id,
        updateAt: item.updateAt,
      };
      const route = { pathname, search: getQueryParams(queryParams) };
      dispatch(approverMacroActions.delete({ arg: target, route }));
    }

    /** 이전 페이지. */
    if (code === 'prePage') {
      const { pathname, search: propsSearch } = props;
      const params = { ...getQueryParams(propsSearch) };
      params.pageNo = (params.pageNo || 1) - 1;
      if (params.pageNo > 0) {
        const search = getQueryParams(params);
        const data = {
          arg: { search },
          route: { pathname, search },
        };
        dispatch(approverMacroActions.list(data));
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      const { pathname } = props;
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) + 1;
      const search = getQueryParams(params);
      const data = {
        arg: { search },
        route: { pathname, search },
      };
      dispatch(approverMacroActions.list(data));
    }

    if (code === 'viewer') {
      /** 사용 수 드로워 열기. */
      const params = { ...queryParams };
      params.drawerMode = 'read';
      params.id = id;
      dispatch(sessionActions.search(getQueryParams(params)));
    }
  };

  // 목록개수
  const handleChangeRowLength = (value: number) => {
    const { pathname } = props;
    const params = { ...queryParams };
    delete params.pageNo;
    params.rowsPerPage = value;
    const search = getQueryParams(params);
    const data = {
      arg: { search },
      route: { pathname, search },
    };
    dispatch(approverMacroActions.list(data));
  };

  // 목록노출
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          scrollbar={scrollbar}
          caption={getLocalizedText('결재담당자')}
          columns={columns}
          rows={rows}
          pagination={{
            no: queryParams.pageNo || 1,
            total: totalCount,
            row: queryParams.rowsPerPage || 15,
            onChangeRow: handleChangeRowLength,
          }}
          onClick={handleClick}
          defaultActions={defaultActions}
          moreActions={moreActions}
          onChangeColumnVisible={handleChangeColumnVisible}
        />
      </EuiBody>
      {renderDrawer()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default React.memo(ApprovalApproverMacroContainer);
