import React from 'react';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Tree from '../../../../../components/tree/Tree';
import { TreeItem } from '../../../../../groupware-common/types';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ApprovalArchiveSettingsFolderList(props: {
  selectedId?: number;
  items: TreeItem[];
  onItemClick(arg: { id: number }): void;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
}): JSX.Element {
  // console.log(`ApprovalArchiveSettingsList(props)`, props);

  const { selectedId, items, onItemClick, onAction } = props;

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('기록물')}>
        <Button
          text={getLocalizedText('추가')}
          iconType
          icon="plus"
          onClick={(event) => onAction({ code: 'create', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <Tree
          selectedId={selectedId}
          items={items}
          onItemClick={(id) => onItemClick({ id })}
        />
      </EuiSetting.Content>
    </>
  );
}

export default ApprovalArchiveSettingsFolderList;
