/* eslint-disable consistent-return */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import approvalWorkApi, {
  approvalWorkFolderApi,
} from '../../apis/approval/v1/work';
import {
  CacheEntity,
  CacheMeta,
  CustomNumbers,
  LocateArg,
} from '../../../groupware-common/types';
import { ApiError } from '../../../groupware-common/types/error';
import { b62 } from '../../../groupware-common/utils';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../pages/common/dialogs/ApprovalLineDialogContainer';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
  thunkCondition,
} from '../../../groupware-webapp/stores/utils';

/** 업무 */
const name = 'approval/work';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

type FolderItem = {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

type FolderView = {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

type WorkItem = {
  id: number; // 업무 아이디',
  folderId: number; // 폴더 아이디',
  seq: number;
  status: number; // 상태 - 1: 사용, 2: 중지',
  name: string;
  updateAt: string;
};

export type WorkView = {
  id: number; // 양식 아이디',
  folderId: number; // 폴더 아이디',
  seq: number; // 순서',
  status: number; // 상태 - 1: 사용, 2: 중지',
  name: string; //  varchar(128) NOT NULL COMMENT '이름',
  formId: number;
  formName: string;
  receiptFormId: number;
  receiptFormName?: string;
  documentNo: string; //  varchar(128) NOT NULL COMMENT '문서 번호',
  retentionPeriod: number; // 보존기간',
  approvalLine: ApprovalLineType; // json 결재선',
  referencePermission?: SharePermissionType; // json 참조자',
  viewPermission?: SharePermissionType; // json 조회자',
  useAttachFile: number; // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
  useAttachDocument: number; // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
  useOpinion: boolean; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
  useComment: boolean; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',
  description: string; // 설명
  updateAt: string; // 수정 날짜
};

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  folder: {
    list: CacheEntity<{
      items: FolderItem[];
      // totalCount: number;
      // search: string;
    }>;
    view: CacheEntity<FolderView | null | undefined>;
  };
  list: CacheEntity<{
    items: WorkItem[];
    // totalCount: number;
    // search: string;
  }>;
  view: CacheEntity<WorkView | null | undefined>;
  errors: ApiError[];
}

// const initialState: State = {
//   requests: [],
//   folder: {
//     list: {
//       meta: { lastUpdateAt: '', timestamp: 0 },
//       data: { items: [], totalCount: 0, search: '' },
//     },
//     view: {
//       meta: { lastUpdateAt: '', timestamp: 0 },
//       data: undefined,
//     },
//   },
//   list: {
//     meta: { lastUpdateAt: '', timestamp: 0 },
//     data: { items: [], totalCount: 0, search: '' },
//   },
//   view: {
//     meta: { lastUpdateAt: '', timestamp: 0 },
//     data: undefined,
//   },
//   errors: [],
// };

const initialState: State = {
  requests: [],
  folder: {
    list: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: { items: [] },
    },
    view: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: undefined,
    },
  },
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [] },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    // data: undefined,
    // eslint-disable-next-line prettier/prettier
    data: { id: 10001, folderId: 1, seq: 1, status: 1, name: 'DB 백업 정상 유무', formId: 10235, formName: '양식 이름', receiptFormId: 0, documentNo: '제-년도-', retentionPeriod: 0, useAttachFile: 1, useAttachDocument: 1, useOpinion: true, useComment: true, description: '', updateAt: '2020-12-28T07:41:43513Z',
      // eslint-disable-next-line prettier/prettier
      approvalLine: { version: '0.1', groups: [
          // eslint-disable-next-line prettier/prettier
        { id: '1', type: 'draft', approval: false, items: [] },
          // eslint-disable-next-line prettier/prettier
        { id: '2', type: 'approval', required: true, modify: false, items: [
              // eslint-disable-next-line prettier/prettier
            { id: '1', companyId: 5001, companyName: '', organizationId: 5001,  organizationName: '', employeeId: 20002, employeeName: '이성계', jobClassType: 'jobposition', jobPositionId: 0, jobPositionName: '', jobDutyId: 0, jobDutyName: '', arbitraryDecision: false  },
              // eslint-disable-next-line prettier/prettier
            ], }, ], },
      referencePermission: { version: '0.1', groups: [] },
      viewPermission: { version: '0.1', groups: [] },
    },
  },
  errors: [],
};

const findFolderList = createAsyncThunk(
  `${name}/findFolderList`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      // await sleep(300);

      // const timestamp = Date.now();
      let meta: CacheMeta | undefined;
      // let data: { items: FolderItem[] } | undefined;

      // if (state.folder[type].list.meta.timestamp > timestamp - 300000)
      //   return { meta, data };

      // meta = {
      //   timestamp,
      //   lastUpdateAt: await api.findFolderLastUpdateAt(type),
      // };

      // if (state.folder[type].list.meta.lastUpdateAt !== meta.lastUpdateAt) {
      //   const response = await api.findFolderList(type);
      //   data = {
      //     items: response.map<FolderItem>((x) => {
      //       return {
      //         companyId: x.companyId,
      //         id: x.id,
      //         parentId: x.parentId,
      //         updateAt: x.updateAt,
      //         permissions: x.permissions,
      //       };
      //     }),
      //   };
      // }
      const pageNo = 1;
      const rowsPerPage = CustomNumbers.SMALLINT_MAX;
      const response = await approvalWorkFolderApi.fetchList(
        pageNo,
        rowsPerPage,
      );
      const data = { items: response };

      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 분류 폴더 저장, 수정 */
const saveFolder = createAsyncThunk(
  `${name}/saveFolder`,
  async (
    arg: {
      id: number | undefined;
      updateAt?: string;

      parentId: number;
      name: string;
    } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await approvalWorkFolderApi.save(arg);

      if (
        arg.id === undefined &&
        arg.relayLocation &&
        arg.relayLocation?.pathname.indexOf('{response_id}') !== -1
      ) {
        const route = {
          pathname: arg.relayLocation.pathname.replace(
            '{response_id}',
            b62(response.id),
          ),
        };
        dispatch(findFolderList({ route }));
      } else if (arg.relayLocation === undefined) dispatch(findFolderList());
      else dispatch(findFolderList({ route: arg.relayLocation }));
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 분류 폴더 순서 변경 */
const sortFolder = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;
      await approvalWorkFolderApi.sort(arg.data);
      const pageNo = 1;
      const rowsPerPage = CustomNumbers.SMALLINT_MAX;
      const response = await approvalWorkFolderApi.fetchList(
        pageNo,
        rowsPerPage,
      );
      const data = { items: response };
      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 분류 폴더 삭제. */
const removeFolder = createAsyncThunk(
  `${name}/removeFolder`,
  async (
    arg: { id: number; updateAt: string } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id, updateAt, relayLocation } = arg;
      await approvalWorkFolderApi.delete({ id, updateAt });

      if (relayLocation !== undefined)
        dispatch(findFolderList({ route: relayLocation }));
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const findList = createAsyncThunk(
  `${name}/findList`,
  async (arg: LocateArg, { rejectWithValue }) => {
    try {
      // await sleep(300);
      // const timestamp = Date.now();
      let meta: CacheMeta | undefined;
      // let data: { items: WorkItem[] } | undefined;

      const response = await approvalWorkApi.fetchList();
      const data = { items: response };

      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findList.typePrefix, arg, getState);
    },
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { affiliatedCompanyId?: number; id: number | 'first' } & LocateArg,
    { rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    let data: WorkView | null | undefined;
    let _response_id_: number | undefined;

    try {
      let id: number | null = null;
      if (arg.id === 'first') {
        id = 1;
      } else id = arg.id;

      if (id !== null && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      const response = await approvalWorkApi.fetchView({
        affiliatedCompanyId: arg.affiliatedCompanyId,
        id,
      });
      if (response) {
        data = {
          id: response.id, // 업무 아이디',
          folderId: response.folderId, // 폴더 아이디',
          seq: response.seq, // 순서',
          status: response.status, // 상태 - 1: 사용, 2: 중지',
          name: response.name, //  varchar(128) NOT NULL COMMENT '이름',
          formId: response.formId,
          formName: response.formName,
          receiptFormId: response.receiptFormId,
          receiptFormName: response.receiptFormName,
          documentNo: response.documentNo, //  varchar(128) NOT NULL COMMENT '문서 번호',
          retentionPeriod: response.retentionPeriod, // 보존기간',
          approvalLine: JSON.parse(response.approvalLine), // json 결재선',
          referencePermission:
            response.referrer !== '{}'
              ? JSON.parse(response.referrer)
              : undefined, // json 참조자',
          viewPermission:
            response.viewer !== '{}' ? JSON.parse(response.viewer) : undefined, // json 조회자',
          useAttachFile: response.useAttachFile, // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
          useAttachDocument: response.useAttachDocument ? 1 : 0, // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
          useOpinion: response.useOpinion, // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
          useComment: response.useComment, // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',
          description: response.description, // 설명
          updateAt: response.updateAt, // 수정 날짜
        };
      }

      return { meta, data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findView.typePrefix, arg, getState);
    },
  },
);

const save = createAsyncThunk(
  `${name}/save`,
  async (
    arg: {
      id: number | undefined;
      updateAt?: string;

      folderPaths: string[];
      folderId: number;
      name: string;
      formId: number;
      receiptFormId: number;
      formName: string;
      documentNo: string;
      status: number;
      retentionPeriod: number;
      approvalLine: ApprovalLineType;
      referencePermission: SharePermissionType | undefined;
      viewPermission: SharePermissionType | undefined;
      useAttachFile: number;
      description: string;
    } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const {
        id,
        updateAt,

        folderId,
        // name,
        formId,
        receiptFormId,
        documentNo,
        status,
        retentionPeriod,
        // approvalLine,
        // referencePermission,
        // viewPermission,
        useAttachFile,
        description,

        relayLocation,
      } = arg;
      const approvalLine = JSON.stringify(arg.approvalLine);
      const referencePermission =
        arg.referencePermission !== undefined
          ? JSON.stringify(arg.referencePermission)
          : '{}';
      const viewPermission =
        arg.viewPermission !== undefined
          ? JSON.stringify(arg.viewPermission)
          : '{}';

      const response = await approvalWorkApi.save({
        id,
        updateAt,

        folderId,
        name: arg.name,
        formId,
        receiptFormId,
        documentNo,
        status,
        retentionPeriod,
        approvalLine,
        referrer: referencePermission, // TODO 백엔드 컬럼명 변경 예정.
        viewer: viewPermission, // TODO 백엔드 컬럼명 변경 예정.
        useAttachFile,
        useAttachDocument: 1,
        useOpinion: true,
        useComment: true,
        description,
      });

      if (relayLocation !== undefined) {
        if (id === undefined) {
          if (relayLocation.pathname.indexOf('{response_id}') !== -1) {
            const route = {
              pathname: relayLocation.pathname.replace(
                '{response_id}',
                b62(response.id),
              ),
            };
            dispatch(findFolderList());
            dispatch(findList({}));
            dispatch(findView({ id: response.id, route }));
          }
        } else {
          dispatch(findFolderList());
          dispatch(findList({}));
          dispatch(findView({ id, route: relayLocation }));
        }
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 업무 삭제. */
const remove = createAsyncThunk(
  `${name}/remove`,
  async (
    arg: { id: number; updateAt: string } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id, updateAt, relayLocation } = arg;
      await approvalWorkApi.delete({ id, updateAt });

      if (relayLocation !== undefined) {
        dispatch(findFolderList());
        dispatch(findList({ route: relayLocation }));
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 업무 순서 변경 */
const sortWork = createAsyncThunk(
  `${name}/sortWork`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;
      await approvalWorkApi.sort(arg.data);
      const response = await approvalWorkApi.fetchList();
      const data = { items: response };
      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const workSlice = createSlice({
  name,
  initialState,
  reducers: {
    clear(state, action: PayloadAction<'list' | 'view' | 'all'>) {
      const { payload: type } = action;
      if (type === 'list' || type === 'all') {
        state.list.meta = { lastUpdateAt: '', timestamp: 0 };
        state.list.data.items = [];
      }
      if (type === 'view' || type === 'all') {
        state.view.meta = { lastUpdateAt: '', timestamp: 0 };
        state.view.data = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.folder.list.meta = payload.meta;
        if (payload?.data !== undefined) state.folder.list.data = payload.data;
      })
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.view.meta = payload.meta;
        if (payload?.data !== undefined) state.view.data = payload.data;
      })
      .addCase(sortFolder.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.folder.list.meta = payload.meta;
        if (payload?.data !== undefined) state.folder.list.data = payload.data;
      })
      .addCase(sortWork.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default workSlice.reducer;

export const approvalWorkFolderActions = {
  list: findFolderList,
  save: saveFolder,
  delete: removeFolder,
  sort: sortFolder,
};

export const approvalWorkActions = {
  list: findList,
  view: findView,
  save,
  delete: remove,
  clear: workSlice.actions.clear,
  sort: sortWork,
};
