import React from 'react';
import EuiSetting from '../../../../components/layout/EuiSetting';
import PostView from '../../../../components/post/PostView';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

type Props = {
  name: string;
  representativesName: string;
  establishedDate: string;
  businessRegistrationNo: string;
  corporationRegistrationNo: string;
  businessType: string;
  businessItem: string;
  phoneNo: string;
  faxNo: string;
  postalCode: string;
  address: string;
  employeeCount: number;
};

function DirectoryCompanyView(props: Props): JSX.Element {
  const {
    name,
    representativesName,
    establishedDate,
    businessRegistrationNo,
    corporationRegistrationNo,
    businessType,
    businessItem,
    phoneNo,
    faxNo,
    postalCode,
    address,
    employeeCount,
  } = props;

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('회사정보')} />
      <EuiSetting.Content>
        <PostView>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title={getLocalizedText('회사명')}>
                  <PostView.CategoryValue value={name} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('대표자')}>
                  <PostView.CategoryValue value={representativesName} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('설립일')}>
                  <PostView.CategoryValue value={establishedDate} />
                </PostView.CategoryItem>
                <PostView.CategoryItem
                  title={getLocalizedText('사업자등록번호')}
                >
                  <PostView.CategoryValue value={businessRegistrationNo} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('법인등록번호')}>
                  <PostView.CategoryValue value={corporationRegistrationNo} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('업태')}>
                  <PostView.CategoryValue value={businessType} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('종목')}>
                  <PostView.CategoryValue value={businessItem} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('전화번호')}>
                  <PostView.CategoryValue value={phoneNo} copy />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('팩스번호')}>
                  <PostView.CategoryValue value={faxNo} copy />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('사업장소재지')}>
                  <PostView.CategoryValue
                    value={postalCode}
                    label={getLocalizedText('우편번호')}
                  />
                  <PostView.CategoryValue value={address} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title={getLocalizedText('직원수')}>
                  <PostView.CategoryValue value={employeeCount} />
                </PostView.CategoryItem>
                {/*
                <PostView.CategoryItem title="계정수">
                  <PostView.CategoryValue value="50" />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="홈페이지">
                  <PostView.CategoryValue
                    value="www.edenbiz.co.kr"
                    link="http://www.edenbiz.co.kr/"
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="오피스도메인">
                  <PostView.CategoryValue
                    value="demo.uplusgw.com"
                    link="http://demo.uplusgw.com"
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="메일도메인">
                  <PostView.CategoryValue
                    value="mail.uplusgw.com"
                    link="https://mail.uplusgw.com/pop3login/"
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="관리지 이름">
                  <PostView.CategoryValue value="홍길동" />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="관리지 ID">
                  <PostView.CategoryValue value="master" />
                </PostView.CategoryItem>
                */}
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default DirectoryCompanyView;
