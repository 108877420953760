import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import preferencesApi from '../../apis/calendar/v1/preferences';

const name = 'calendar/preferences';

export interface CalendarColorType {
  name: string;
  rgb: string;
  seq: number;
}

interface State {
  administrators: { employeeId: number; updateAt: string }[]; // 관리자
  color: CalendarColorType[];
}

const initialState: State = {
  administrators: [],
  color: [],
};

/** 관리자 조회. */
const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (_: void | LocateArg, { rejectWithValue }) => {
    try {
      const data = await preferencesApi.find({ role: 'CALENDAR_ADMIN' });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 추가. */
const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue }) => {
    try {
      const data = await preferencesApi.append(
        arg.map((a) => ({ ...a, role: 'CALENDAR_ADMIN' as const })),
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 삭제. */
const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue },
  ) => {
    try {
      const data = await preferencesApi.remove(
        arg.map((a) => ({ ...a, role: 'CALENDAR_ADMIN' as const })),
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 - 캘린더 색상 조회. */
const findColorList = createAsyncThunk(
  `${name}/findColorList`,
  async (_: void, { rejectWithValue }) => {
    try {
      const result = await preferencesApi.colorList();
      const color = result
        .map((a) => ({
          name: a.name,
          rgb: `#${a.rgb}`,
          seq: a.seq,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return color;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const preferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      })
      .addCase(findColorList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.color = payload;
      });
  },
});

export default preferencesSlice.reducer;

export const preferencesActions = {
  findAdministrators,
  appendAdministrators,
  removeAdministrators,

  colorList: findColorList,
};
