/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  attendanceWorkActions,
  attendanceWorkFolderActions,
} from '../../../../stores/attendance/work';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import FeedBack from '../../../../../components/alert/FeedBack';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import AttendanceWorkEditWork from './AttendanceWorkEditWork';
import AttendanceWorkEditFolder from './AttendanceWorkEditFolder';
import AttendanceWorkContentList from './AttendanceWorkContentList';
import AttendanceWorkContentView from './AttendanceWorkContentView';
import AttendanceWorkContentFolderView from './AttendanceWorkContentFolderView';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../../../../groupware-approval/pages/adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';

function AttendanceWorkContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${AttendanceWorkContainer.name}.render:props`, props);

  const dispatch = useAppDispatch();

  /** 업무 폴더 배열. */
  const folders = useSelector(
    (state: RootState) => state.attendance.work.folder.list.data.items,
  );
  // console.log("folders : " + folders[0]);

  /** 업무 배열. */
  const works = useSelector(
    (state: RootState) => state.attendance.work.list.data.items,
  );
  // console.log("works : " + works[0]);

  /** 업무 뷰. */
  const work = useSelector(
    (state: RootState) => state.attendance.work.view.data,
  );
  // console.log("work : " + work);
  /** 근태 코드 */
  const attendanceCode = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6012)?.name ?? '';

  const pathParams = getPathParams('/*/*/*/:id', props.pathname);
  const queryParams = getQueryParams(props.search);
  // console.log(`pathParams`, pathParams);
  // console.log(`queryParams`, queryParams);

  const selectedId = b62(pathParams.id);

  const [completeMessage, setCompleteMessage] = useState('');

  /** 트리 아이템 생성. */
  const items = useMemo(() => {
    const result = [
      ...folders
        .map((a) => {
          return {
            id: a.id,
            seq: a.seq,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as const,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
      ...works
        // .filter(
        //   (a) =>
        //     permissionFolders.find((b) => b.id === a.folderId) !== undefined,
        // )
        .map((a) => {
          return {
            id: a.id,
            seq: a.seq,
            parentId: a.folderId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'clipboard-edit' as const,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];
    return result;
  }, [folders, works]);

  const handleSnackbarClose = () => {
    setCompleteMessage('');
  };

  const handleTreeItemClick = (id: number) => {
    const item = items.find((a) => a.id === id);
    const route = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/${b62(id)}`,
    };

    if (item?.icon === 'folder') dispatch(sessionActions.setRoute(route));
    if (item?.icon === 'clipboard-edit')
      dispatch(attendanceWorkActions.view({ id, route }));
  };

  const handleCloseView = () => {
    const route = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}`,
    };
    dispatch(sessionActions.setRoute(route));
  };

  const handlePopupClose = () => {
    dispatch(sessionActions.search());
  };

  /** 액션. */
  const handleAction = (arg: { code: string; event: React.MouseEvent }) => {
    // console.log(`handleAction(arg)`, arg);
    const { code } = arg;
    // 분류 등록인 경우.
    if (code === 'folder/create') {
      const type = 'folder';
      const mode = 'create';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 분류 수정인 경우.
    else if (code === 'folder-edit') {
      const type = 'folder';
      const mode = 'update';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 분류 삭제인 경우.
    else if (code === 'folder-delete') {
      const type = 'folder';
      const mode = 'delete';
      dispatch(sessionActions.setDialog({ type, mode }));
    }
    // 분류 순서변경인 경우.
    else if (code === 'folder-sort') {
      const type = 'folderSort';
      const mode = 'update';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 업무 등록인 경우.
    else if (code === 'work/create') {
      if (attendanceCode === undefined || attendanceCode.length === 0) {
        setCompleteMessage(
          getLocalizedText(
            '업무를 생성할 수 없습니다. 먼저 근태 코드를 생성해 주세요.',
          ),
        );
        return;
      }
      const type = 'work';
      const mode = 'create';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 업무 수정인 경우.
    else if (code === 'work/update') {
      if (attendanceCode === undefined || attendanceCode.length === 0) {
        setCompleteMessage(
          getLocalizedText(
            '업무를 생성할 수 없습니다. 먼저 근태 코드를 생성해 주세요.',
          ),
        );
        return;
      }
      const type = 'work';
      const mode = 'update';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 업무 삭제인 경우.
    else if (code === 'work/delete') {
      const type = 'work';
      const mode = 'delete';
      dispatch(sessionActions.setDialog({ type, mode }));
    }
    // 업무 순서변경인 경우.
    else if (code === 'work/sort') {
      const type = 'workSort';
      const mode = 'update';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
  };

  /** 폴더 저장. */
  const handleFolderSave = (arg: {
    id?: number;
    parentId: number;
    name: string;
  }) => {
    const { id, parentId, name } = arg;

    // 등록인 경우.
    if (id === undefined) {
      const relayLocation = {
        pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/{response_id}`,
      };
      dispatch(
        attendanceWorkFolderActions.save({ id, parentId, name, relayLocation }),
      );
    }
    // 수정인 경우.
    else {
      const updateAt = folders.find((a) => a.id === id)?.updateAt;
      if (updateAt === undefined) return;
      const relayLocation = {
        pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/${b62(id)}`,
      };
      dispatch(
        attendanceWorkFolderActions.save({
          id,
          parentId,
          name,
          updateAt,
          relayLocation,
        }),
      );
    }
  };

  /** 폴더 삭제. */
  const handleFolderDelete = () => {
    const item = folders.find((a) => a.id === selectedId);
    if (item === undefined) return;
    const { id, updateAt } = item;
    const relayLocation = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}`,
    };
    dispatch(
      attendanceWorkFolderActions.delete({ id, updateAt, relayLocation }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        setCompleteMessage(getLocalizedText('결재분류를 삭제했습니다.'));
      }
    });
  };

  /** 업무 저장. */
  const handleWorkSave = (arg: {
    id: number | undefined;
    folderPaths: string[];
    folderId: number;
    name: string;
    attendanceCode: number;
    formId: number;
    receiptFormId: number;
    formName: string;
    documentNo: string;
    status: number;
    retentionPeriod: number;
    approvalLine: ApprovalLineType;
    referencePermission: SharePermissionType | undefined;
    viewPermission: SharePermissionType | undefined;
    useAttachFile: number;
    description: string;
  }) => {
    // console.log(`handleWorkSave(arg)`, arg);
    const { id } = arg;
    // 등록인 경우.
    if (id === undefined) {
      const relayLocation = {
        pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/{response_id}`,
      };
      dispatch(attendanceWorkActions.save({ ...arg, id, relayLocation }));
    }
    // 수정인 경우.
    else {
      const updateAt = work?.updateAt;
      if (updateAt === undefined) return;
      const relayLocation = {
        pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/${b62(id)}`,
      };
      dispatch(
        attendanceWorkActions.save({ ...arg, id, updateAt, relayLocation }),
      );
    }
  };

  /** 업무 삭제. */
  const handleWorkDelete = () => {
    if (work === null || work === undefined) return;
    const { id, updateAt } = work;
    const relayLocation = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}`,
    };
    dispatch(
      attendanceWorkActions.delete({ id, updateAt, relayLocation }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        setCompleteMessage(getLocalizedText('결재업무를 삭제했습니다.'));
      }
    });
  };

  /** 폴더 순서 변경. */
  const handleFolderSortChange = (sortList: { id: number }[]) => {
    const data = sortList.map((a, index) => {
      const item = folders.find(({ id }) => id === a.id);
      const updateAt = item?.updateAt ?? '';
      return {
        id: a.id,
        seq: index + 1,
        updateAt,
      };
    });
    const route = { pathname: props.pathname, search: '' };
    dispatch(attendanceWorkFolderActions.sort({ data, route }));
  };

  /** 업무 순서 변경. */
  const handleWorkSortChange = (sortList: { id: number }[]) => {
    const data = sortList.map((a, index) => {
      const item = works.find(({ id }) => id === a.id);
      const updateAt = item?.updateAt ?? '';
      return {
        id: a.id,
        seq: index + 1,
        updateAt,
      };
    });
    const route = { pathname: props.pathname, search: '' };
    dispatch(attendanceWorkActions.sort({ data, route }));
  };

  function attendanceName(value: number) {
    const attendance = attendanceCode.find((a) => a.id === value);
    if (attendance) return attendance.name;

    return '';
  }

  const renderContent = () => {
    const item = items.find(({ id }) => id === selectedId);
    if (item === undefined) return <SplitUnselected />;
    if (item.icon === 'folder')
      return (
        <EuiSetting.Right onClose={handleCloseView}>
          <AttendanceWorkContentFolderView
            paths={getParentItems(items, selectedId).map(({ text }) => text)}
            name={item.text}
            onAction={handleAction}
          />
        </EuiSetting.Right>
      );
    if (work === null || work === undefined)
      return <SplitUnselected label={getLocalizedText('찾을 수 없습니다.')} />;

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <AttendanceWorkContentView
          paths={getParentItems(items, work.id).map(({ text }) => text)}
          attendanceCodeName={attendanceName(work.attendanceCode)}
          name={work.name}
          status={work.status}
          useAttachFile={work.useAttachFile}
          retentionPeriod={work.retentionPeriod}
          formName={work.formName}
          receiptFormName={work.receiptFormName}
          approvalLine={work.approvalLine}
          referencePermission={work.referencePermission}
          viewPermission={work.viewPermission}
          description={work.description}
          onAction={handleAction}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerType, drawerMode } = queryParams;
    // console.log(
    //   `renderDrawer:drawerType:'${drawerType}':drawerMode:'${drawerMode}'`,
    // );
    const treeFolders = [
      {
        id: 0,
        parentId: -1,
        text: getLocalizedText('근태업무'),
        icon: 'folder' as const,
      },
      ...items.filter(({ icon }) => icon === 'folder'),
    ];
    // 분류 등록인 경우.
    if (drawerType === 'folder' && drawerMode === 'create') {
      const item = items.find(({ id }) => id === selectedId);
      let parentId = 0;
      if (item)
        parentId = item.icon === 'clipboard-edit' ? item.parentId : item?.id;
      return (
        <AttendanceWorkEditFolder
          folders={treeFolders}
          folderPaths={getParentItems(treeFolders, parentId).map(
            ({ text }) => text,
          )}
          parentId={parentId}
          onSave={handleFolderSave}
          onClose={handlePopupClose}
        />
      );
    }
    // 분류 수정인 경우.
    if (drawerType === 'folder' && drawerMode === 'update') {
      const item = items.find(({ id }) => id === selectedId);
      if (item === undefined) return null;
      return (
        <AttendanceWorkEditFolder
          folders={treeFolders.filter((a) => a.id !== item.id)}
          folderPaths={getParentItems(treeFolders, item.parentId).map(
            ({ text }) => text,
          )}
          onSave={handleFolderSave}
          onClose={handlePopupClose}
          id={item.id}
          parentId={item.parentId}
          name={item.text}
        />
      );
    }

    // 업무 등록인 경우.
    if (drawerType === 'work' && drawerMode === 'create') {
      const item = items.find((a) => a.id === selectedId);
      let folderId: number;
      if (item === undefined) folderId = 0;
      else if (item.icon === 'folder') folderId = item.id;
      else folderId = item.parentId;

      return (
        <AttendanceWorkEditWork
          attendanceCodeList={attendanceCode}
          folders={items.filter(({ icon }) => icon === 'folder')}
          folderPaths={getParentItems(items, folderId).map(({ text }) => text)}
          folderId={folderId}
          onSave={handleWorkSave}
          onClose={handlePopupClose}
        />
      );
    }
    // 업무 수정인 경우.
    if (drawerType === 'work' && drawerMode === 'update') {
      if (work === null || work === undefined) return null;

      return (
        <AttendanceWorkEditWork
          attendanceCodeList={attendanceCode}
          folders={items.filter(({ icon }) => icon === 'folder')}
          folderPaths={getParentItems(items, work.folderId).map(
            ({ text }) => text,
          )}
          folderId={work.folderId}
          onSave={handleWorkSave}
          onClose={handlePopupClose}
          id={work.id}
          name={work.name}
          formId={work.formId}
          formName={work.formName}
          receiptFormId={work.receiptFormId}
          receiptFormName={work.receiptFormName}
          documentNo={work.documentNo}
          status={work.status}
          retentionPeriod={work.retentionPeriod}
          approvalLine={work.approvalLine}
          referencePermission={work.referencePermission}
          viewPermission={work.viewPermission}
          useAttachFile={work.useAttachFile}
          description={work.description}
          attendanceCode={work.attendanceCode}
        />
      );
    }

    // 분류 순서변경인 경우.
    if (drawerType === 'folderSort' && drawerMode === 'update') {
      const selectFolder = folders.find(({ id }) => id === selectedId);
      if (!selectFolder) return null;
      const list = folders
        .filter((a) => a.parentId === selectFolder.parentId)
        .map(({ id, name: label, seq }) => ({ id, label, seq }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return (
        <ChangeOrder
          title={getLocalizedText('순서변경')}
          list={list}
          onChange={handleFolderSortChange}
          onClose={handlePopupClose}
        />
      );
    }

    // 업무 순서변경인 경우.
    if (drawerType === 'workSort' && drawerMode === 'update') {
      const selectWork = works.find(({ id }) => id === selectedId);
      if (!selectWork) return null;
      const list = works
        .filter((a) => a.folderId === selectWork.folderId)
        .map(({ id, name: label, seq }) => ({ id, label, seq }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return (
        <ChangeOrder
          title={getLocalizedText('순서변경')}
          list={list}
          onChange={handleWorkSortChange}
          onClose={handlePopupClose}
        />
      );
    }
    return null;
  };

  const renderDialog = () => {
    const { dialogType, dialogMode } = queryParams;
    if (
      (dialogType === 'folder' || dialogType === 'work') &&
      dialogMode === 'delete'
    ) {
      const name = items.find((a) => a.id === selectedId)?.text;
      if (name === undefined) return null;
      const element =
        dialogType === 'folder' ? (
          <>
            <strong>&apos;{name}&apos;</strong>{' '}
            {getLocalizedText('분류를 정말 삭제하시겠습니까?')}
          </>
        ) : (
          <>
            <strong>&apos;{name}&apos;</strong>{' '}
            {getLocalizedText('업무를 정말 삭제하시겠습니까?')}
          </>
        );

      return (
        <DeleteConfirmation
          onSubmit={
            dialogType === 'folder' ? handleFolderDelete : handleWorkDelete
          }
          onCancel={handlePopupClose}
        >
          {element}
        </DeleteConfirmation>
      );
    }
    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <AttendanceWorkContentList
              items={items}
              selectedId={selectedId}
              onAction={handleAction}
              onSelect={handleTreeItemClick}
            />
          </EuiSetting.Left>
          {renderContent()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
      <FeedBack text={completeMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceWorkContainer;
