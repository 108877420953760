import React from 'react';
import Icon from '../icon/Icon';
import { IconType } from '../../groupware-common/types/icon';
import Tooltip from '../tooltip/Tooltip';

class GnbItem extends React.PureComponent<{
  useTooltip?: boolean;
  visibleName?: boolean;
  id: string;
  text: string;
  selected?: boolean;
  icon: IconType;
  onClick?(id: string): void;
}> {
  handleClick(): void {
    if (this.props.onClick !== undefined) this.props.onClick(this.props.id);
  }

  render(): JSX.Element {
    const { useTooltip, visibleName, icon, text, selected } = this.props;
    if (useTooltip)
      return (
        <Tooltip title={text} placement="right">
          <button
            type="button"
            className={`menu ${visibleName ? 'visibleName' : ''} ${
              selected ? 'selected' : ''
            }`}
            onClick={this.handleClick.bind(this)}
          >
            <Icon className="badge" icon={icon} />
            <span className="name">{text}</span>
          </button>
        </Tooltip>
      );

    return (
      <button
        type="button"
        className={`menu ${visibleName ? 'visibleName' : ''} ${
          selected ? 'selected' : ''
        }`}
        onClick={this.handleClick.bind(this)}
      >
        <Icon className="badge" icon={icon} />
        <span className="name">{text}</span>
      </button>
    );
  }
}

export default GnbItem;
