import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiBody from '../../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../../components/split/SplitUnselected';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../../groupware-webapp/stores/session';
import {
  SaveFolderArg,
  folderActions,
} from '../../../../../stores/document/folders';
import DocumentEditFolderDrawer from './DocumentFolderEditDrawer';
import DocumentFolderContentView from './DocumentFolderContentView';
import DocumentSettingFolderList from './DocumentSettingFolderList';
import ChangeOrder from '../../../../../../groupware-webapp/pages/popup/ChangeOrder';
import DeleteConfirmation from '../../../../../../components/alert/DeleteConfirmation';

function DocumentFolderBoxContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname } = props;
  /** 폴더 리스트. */
  const folders = useSelector(
    (state: RootState) => state.document.folders.adminconsole.list,
  );
  /**  폴더. */
  const folder = useSelector((state: RootState) => state.document.folders.view);
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'setting');
  const pathmap = getPathMap('/*/*/*', pathname);

  /** 트리 아이템 생성. */
  const treeItems = useMemo(() => {
    const result = [
      ...folders
        .map((a) => {
          return {
            id: a.id,
            seq: a.seq,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as const,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];
    return result;
  }, [folders, folder]);

  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();

  const [state, setState] = useState<{
    completeMessage: string;
    filter: string;
  }>({
    completeMessage: '',
    filter: '',
  });

  const handleCloseView = () => {
    dispatch(
      sessionActions.setRoute({
        pathname: getPathMap('/*/*/*', props.pathname),
      }),
    );
  };

  /** 폴더 선택. */
  const handleListFolderClick = (id: number) => {
    const route = {
      pathname: `${pathmap}/${b62(id)}`,
    };
    dispatch(folderActions.view({ id, route }));
  };

  /** 액션. */
  const handleAction = (arg: { code: string }) => {
    const { code } = arg;
    // 분류 등록인 경우.
    if (code === 'folder/create') {
      const type = 'folder';
      const mode = 'create';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 분류 수정인 경우.
    else if (code === 'folder/update') {
      const type = 'folder';
      const mode = 'update';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
    // 분류 삭제인 경우.
    else if (code === 'folder/delete') {
      const type = 'folder';
      const mode = 'delete';
      dispatch(sessionActions.setDialog({ type, mode }));
    }
    if (code === 'folder/sort')
      dispatch(
        sessionActions.setDrawer({ type: 'folderSort', mode: 'update' }),
      );
  };

  /** 폴더 저장. */
  const handleFolderSave = (data: SaveFolderArg) => {
    const route = { pathname: props.pathname };
    // 등록인 경우
    if (data.id === undefined) {
      dispatch(folderActions.create({ data, route }));
    }
    // 수정인 경우
    else {
      dispatch(folderActions.update({ data, route }));
    }
  };

  /** 폴더 순서 변경. */
  const handleFolderSortChange = (sortList: { id: number }[]) => {
    const data = sortList.map((a, index) => {
      const item = folders.find(({ id }) => id === a.id);
      const parentId = item?.parentId ?? 0;
      const updateAt = item?.updateAt ?? '';

      return {
        parentId,
        id: a.id,
        seq: index + 1,
        updateAt,
      };
    });
    const route = { pathname: props.pathname, search: '' };
    dispatch(folderActions.sort({ data, route }));
  };

  /** 폴더 삭제. */
  const handleFolderDelete = () => {
    const item = folders.find((a) => a.id === selectedId);
    if (item === undefined) return;
    const { parentId, id, updateAt } = item;
    const route = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}`,
    };
    dispatch(folderActions.delete({ parentId, id, updateAt, route })).then(
      (result) => {
        if ((result as { error?: string }).error === undefined) {
          const completeMessage = getLocalizedText('문서 폴더를 삭제했습니다.');
          setState((prevState) => ({ ...prevState, completeMessage }));
        }
      },
    );
  };

  /** 팝업 닫기. */
  const handlePopupClose = () => {
    dispatch(sessionActions.search());
  };

  /** 드로워 닫기. */
  const handleDrawerClose = () => {
    dispatch(sessionActions.search());
  };

  const pathParams = getPathParams('/*/*/*/:id', props.pathname);
  const selectedId = b62(pathParams.id);

  const handleFilterChange = (filter: string) => {
    setState((prevState) => ({ ...prevState, filter }));
  };

  const renderList = () => {
    return (
      <>
        <DocumentSettingFolderList
          selectedId={selectedId}
          items={treeItems}
          filter={state.filter}
          onItemClick={handleListFolderClick}
          onAction={handleAction}
          onFilter={handleFilterChange}
        />
      </>
    );
  };

  const renderContent = () => {
    if (folder === null || folder === undefined)
      return (
        <SplitUnselected label={getLocalizedText('폴더를 찾을 수 없습니다.')} />
      );
    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <DocumentFolderContentView
          paths={getParentItems(folders, folder.id).map(({ name }) => name)}
          folder={folder}
          isEnable={folder.isEnable}
          name={folder.name}
          description={folder.description}
          forms={folder.forms}
          defaultRetentionPeriod={folder.defaultRetentionPeriod}
          onAction={handleAction}
        />
      </EuiSetting.Right>
    );
  };
  const renderDrawer = () => {
    const { drawerType, drawerMode } = queryParams;
    const treeFolders = [
      {
        id: 0,
        parentId: -1,
        text: getLocalizedText('상위 문서함'),
        icon: 'folder' as const,
      },
      ...treeItems.filter(({ icon }) => icon === 'folder'),
    ];
    if (drawerType === 'folder' && drawerMode === 'create') {
      return (
        <DocumentEditFolderDrawer
          folders={treeFolders}
          parentId={selectedId ?? 0}
          onSave={handleFolderSave}
          onClose={handleDrawerClose}
          search={props.search}
        />
      );
    }
    if (drawerType === 'folder' && drawerMode === 'update') {
      if (folder === undefined) return null;
      return (
        <DocumentEditFolderDrawer
          folder={folder}
          folders={treeFolders}
          parentId={folder.parentId}
          onSave={handleFolderSave}
          onClose={handleDrawerClose}
          search={props.search}
        />
      );
    }

    if (drawerType === 'folderSort' && drawerMode === 'update') {
      const selectFolder = folders.find(({ id }) => id === selectedId);
      if (!selectFolder) return null;
      const list = folders
        .filter((a) => a.parentId === selectFolder.parentId)
        .map(({ id, name: label, seq }) => ({ id, label, seq }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return (
        <ChangeOrder
          title={getLocalizedText('순서변경')}
          list={list}
          onChange={handleFolderSortChange}
          onClose={handleDrawerClose}
        />
      );
    }

    return null;
  };
  const renderDialog = () => {
    const { dialogType, dialogMode } = queryParams;

    if (dialogType === 'folder' && dialogMode === 'delete') {
      const name = treeItems.find((a) => a.id === selectedId)?.text;
      if (name === undefined) return null;
      const element = (
        <>
          <strong>&apos;{name}&apos;</strong>{' '}
          {getLocalizedText('폴더를 정말 삭제하시겠습니까?')}
        </>
      );

      return (
        <DeleteConfirmation
          onSubmit={handleFolderDelete}
          onCancel={handlePopupClose}
        >
          {element}
        </DeleteConfirmation>
      );
    }

    return null;
  };
  const title = categories.find((a) => a.id === 6002)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{getLocalizedText(`${title}`)}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          <EuiSetting.Right onClose={handleCloseView}>
            {renderContent()}
          </EuiSetting.Right>
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default DocumentFolderBoxContainer;
