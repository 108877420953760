import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Confirmation from '../../../../components/alert/Confirmation';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../components/data/DataGrid';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import { ActionEventProps } from '../../../../components/toolbarAction/ToolbarAction';
import { getQueryParams } from '../../../../groupware-common/utils';
import {
  getEmployeeName,
  useDirectory,
} from '../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import { blockedUserActions } from '../../../store/blockedUser';
import { createLocalizedTextFactory } from '../../../../groupware-common/utils/i18n';

function AuthenticationBlockedUserContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const list = useSelector(
    (state: RootState) => state.authentication.blockedUser.data,
  );
  const total = useSelector(
    (state: RootState) => state.authentication.blockedUser.total,
  );
  const directory = useDirectory();
  const getLocalizedText = createLocalizedTextFactory('authentication');

  const [state, setState] = useState<{
    unBlocking: boolean;
    employeeIds: number[];
  }>({
    unBlocking: false,
    employeeIds: [],
  });

  const columns: DataGridColDef[] = [
    { field: 'checkbox', label: '', width: 56 },
    {
      field: 'user',
      label: getLocalizedText('이름'),
      minWidth: 158,
    },
    {
      field: 'date',
      label: getLocalizedText('차단 날짜'),
      width: 118,
    },
    {
      field: 'unBlock',
      label: getLocalizedText('차단 해제'),
      width: 98,
      align: 'center',
    },
  ];

  const rows: DataGridRowsProps<number> = list.map((a) => {
    const directoryData = getDirectoryData({
      ...directory,
      companyId: a.companyId,
      employeeId: a.employeeId,
    });

    return [
      { type: 'checkbox' as const, id: a.employeeId, value: a.checked },
      {
        type: 'user' as const,
        avatar: directoryData.avatar ?? '',
        icon: directoryData.avatar === '' ? undefined : 'person-fill',
        value: `${directoryData.employeeName} ${directoryData.jobPositionName}`,
        from: directoryData.organizationName,
      },
      { type: 'date' as const, value: a.createAt, format: 'dateNumber' },
      {
        type: 'click' as const,
        id: a.employeeId,
        value: getLocalizedText('해제'),
        code: 'unBlock',
      },
    ];
  });

  const toolbarActions: ActionEventProps[] = [
    {
      code: 'unBlockedAll',
      label: getLocalizedText('선택한 사용자 차단 해제'),
      type: 'outlined',
      icon: 'user-unlock',
    },
  ];

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    dispatch(
      blockedUserActions.setItemChecked({
        id,
        checked: event.target.checked,
      }),
    );
  };
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      blockedUserActions.setItemChecked({
        id: 'all',
        checked: event.target.checked,
      }),
    );
  };

  const handleClickActions = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code, id } = arg;

    if (code === 'prePage') {
      queryParams.pageNo = (queryParams.pageNo || 1) - 1;
      if (queryParams.pageNo > 0) {
        if (queryParams.pageNo === 1) delete queryParams.pageNo;
        dispatch(
          blockedUserActions.blockedUsers({
            search: getQueryParams(queryParams),
            route: { pathname, search: getQueryParams(queryParams) },
          }),
        );
      }
    }

    if (code === 'nextPage') {
      queryParams.pageNo = (queryParams.pageNo || 1) + 1;
      if (queryParams.pageNo === 1) delete queryParams.pageNo;
      dispatch(
        blockedUserActions.blockedUsers({
          search: getQueryParams(queryParams),
          route: { pathname, search: getQueryParams(queryParams) },
        }),
      );
    }
    if (code === 'cancelSelected')
      dispatch(
        blockedUserActions.setItemChecked({
          id: 'all',
          checked: false,
        }),
      );

    if (code === 'unBlock' && id) {
      setState((prev) => ({
        ...prev,
        unBlocking: true,
        employeeIds: [id],
      }));
    }

    if (code === 'unBlockedAll') {
      const items = list.filter((a) => a.checked).map((a) => a.employeeId);
      setState((prev) => ({
        ...prev,
        unBlocking: true,
        employeeIds: items,
      }));
    }
  };

  const handleDialogClose = () => {
    setState((prev) => ({
      ...prev,
      unBlocking: false,
      employeeIds: [],
    }));
  };

  const handleUnBlockUser = () => {
    delete queryParams.pageNo;
    dispatch(
      blockedUserActions.unBlockUsers({
        data: state.employeeIds,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
    handleDialogClose();
  };

  const renderDialog = () => {
    if (state.unBlocking) {
      const employee = list.find((a) => a.employeeId === state.employeeIds[0]);
      if (!employee) return null;

      const message =
        state.employeeIds.length > 1 ? (
          getLocalizedText('선택한 사용자의 로그인 차단을 해제하시겠습니까?')
        ) : (
          <>
            <strong>
              &quot;{getEmployeeName(employee.companyId, employee.employeeId)}
              &quot;
            </strong>
            {getLocalizedText('의 로그인 차단을 해제하시겠습니까?')}
          </>
        );
      return (
        <Confirmation
          noDuplication
          submitLabel={getLocalizedText('해제')}
          onCancel={handleDialogClose}
          onSubmit={handleUnBlockUser}
        >
          {message}
        </Confirmation>
      );
    }
    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>
          {getLocalizedText('차단된 사용자 관리')}
        </EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          caption={getLocalizedText('차단된 사용자 관리')}
          checkedCount={list.filter((a) => a.checked).length}
          columns={columns}
          rows={rows}
          pagination={{
            no: queryParams.pageNo ?? 1,
            row: queryParams.rowsPerPage ?? 15,
            total,
          }}
          toolbarActions={toolbarActions}
          onChecked={handleChangeChecked}
          onCheckedAll={handleChangeCheckedAll}
          onClick={handleClickActions}
        />
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default AuthenticationBlockedUserContainer;
