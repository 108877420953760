import { getLocalizedText } from '../../../../groupware-common/utils/i18n';
import { ApprovalLineCodeProps } from './ApprovalType';

export default function approvalCodeLabel(type: ApprovalLineCodeProps): string {
  switch (type) {
    case 'drafter':
      return getLocalizedText('기안');
    case 'approval':
      return getLocalizedText('결재');
    case 'agree':
      return getLocalizedText('합의');
    case 'auditor':
      return getLocalizedText('감사');
    case 'receiver':
      return getLocalizedText('수신');
    default:
      return getLocalizedText('결재');
  }
}
