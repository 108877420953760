import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

export interface PaymentItem {
  evidGrwNo: string | null; // 증빙기본번호
  evidncDeptCd: string | null; // 증빙부서
  evidncDeptNm: string | null; // 증빙부서명
  evidncDy: string; // 증빙일자
  evidncWrtDy: string; // 증빙작성일자
  evidncNo: number | null; // 증빙번호
  evidncDvnm: string | null; // 증빙구분
  pttnDy: string; // 전기일
  decisDy: string; // 결의일
  acctNm: string | null; // 계정과목
  payTypNm: string | null; // 지급유형
  sttlMthdNm: string | null; // 지급구분
  useNm: string | null; // 사용자
  conm: string | null; // 거래처
  amt: number | null; // 거래금액
  payTotAmt: number | null; // 지급금액
  payAmt: number | null; // 잔액
  decisTypDvnm: string | null; // 구분
  decisCont: string | null; // 설명
  slipNo: string | null; // 전표정보
}

export interface PendingPaymentItem {
  deptNm: string | null; // 부서
  sttlPay: string | null; // 지급 구분
  conm: string | null; // 거래처
  payDest: string | null; // 지급처
  amt: number | null; // 금액
  decisCont: string | null; // 상세
  payBnkNm: string | null; // 은행명
  transferAccNo: string | null; // 계좌번호
  transferAccDpositNm: string | null; // 예금주
  grwDcsnum: string | null; // 그룹웨어문서번호
}

/** 출납 대기 목록 조회 */
async function findPaymentList(arg: {
  companyId: number;
  employeeId: number;
  pageno: number;
  rowsperpage: number;
}): Promise<{
  count: number;
  list: PaymentItem[];
}> {
  try {
    const { companyId, employeeId, pageno, rowsperpage } = arg;
    const params = { pageno, rowsperpage };
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/accounting/v1/companies/${companyId}/employees/${employeeId}/pendingPaymentDetails`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 출납 대기 항목 조회 */
async function findPendingPaymentList(arg: {
  companyId: number;
  employeeId: number;
  evidGrwNos: string[];
}): Promise<{ pendingPayments: PendingPaymentItem[] }> {
  try {
    const { companyId, employeeId, evidGrwNos } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/customs/sangsanginsecurities/accounting/v1/companies/${companyId}/employees/${employeeId}/pendingPayments`;
    const response = await axios.get(url, { headers, params: { evidGrwNos } });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const paymentsApi = {
  paymentList: findPaymentList,
  pendingPaymentList: findPendingPaymentList,
};

export default paymentsApi;
