import { useEffect } from 'react';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { documentActions } from '../../../../stores/approval/document';

function ApprovalAddPermissionRoute(props: {
  pathname: string;
  search: string;
}): null {
  const dispatch = useAppDispatch();
  const { pathname, search } = props;
  const { folderId } = getPathParams<{ folderId: string }>(
    '/*/*/:folderId',
    pathname,
  );
  useEffect(() => {
    const params = getQueryParams(search);

    dispatch(
      documentActions.adminconsolePermissionList({
        type: folderId === 'viewpermission' ? 'view' : 'reference',
        search,
        route: {
          pathname,
          search: getQueryParams(params),
        },
      }),
    );
  }, [dispatch, props]);

  return null;
}

export default ApprovalAddPermissionRoute;
