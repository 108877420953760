import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { authoritiesActions } from '../../../../stores/sangsanginsecurities/authority';

function SecuritiesAuthorityRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { itemId } = getPathParams<{ itemId?: number }>(
    '/*/*/:itemId$base62',
    pathname,
  );

  useEffect(() => {
    if (itemId !== undefined)
      dispatch(
        authoritiesActions.view({ id: itemId, route: { pathname, search } }),
      );
    else
      dispatch(
        authoritiesActions.list({ search, route: { pathname, search } }),
      );
  }, [dispatch, pathname, search]);

  return null;
}

export default SecuritiesAuthorityRoute;
