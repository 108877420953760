import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '../textfield/TextField';
import SelectField from '../selectField/SelectField';
import Button from '../button/Button';
import CustomDatePicker from '../date/CustomDatePicker';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../tree/DirectoryTree';
import { RootState } from '../../groupware-webapp/app/store';
import Menu from '../menu/Menu';
import useNoInitialEffect from '../../groupware-common/hooks/useDidMountEffect';
import DropMenu from '../selectField/DropMenu';
import { dateFormat } from '../../groupware-common/utils/ui';
import Radio from '../radio/Radio';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

export interface SearchDateProps {
  start: Date | null;
  end: Date | null;
}

export type OptionUseType =
  | 'text'
  | 'drop'
  | 'employeeDirectory'
  | 'organizationDirectory'
  | 'allDirectory';
export interface OptionType {
  value: string;
  label: string;
  useType: OptionUseType;
}

function DirectorySearch(props: {
  className?: string;
  // directoryTreeFilter?: ('company' | 'organization' | 'employee')[]; // 조직도 트리 타입 필터
  // directoryListFilter?: ('company' | 'organization' | 'employee')[]; // 조직도 리스트 타입 필터
  keyword?: string;
  filter?: { value: string; useType: OptionUseType };
  status?: string;
  options?: OptionType[];
  statusOptions?: { label: string; value: string; type?: string }[];
  dateOptions?: boolean; // 기간 검색 조건 추가 시.
  date?: SearchDateProps;
  full?: boolean;
  onSearch(arg: {
    keyword: string;
    filter: string;
    directoryKeyword?: string;
    directoryFilter?: string;
    date: SearchDateProps;
    status?: string;
  }): void;
}): JSX.Element {
  const display = useSelector((s: RootState) => s.session.display);
  const principal = useSelector((s: RootState) => s.session.principal);
  const companies = useSelector(
    (s: RootState) => s.directory.company.list.data.items,
  ).filter(({ id }) => id === principal.companyId);
  const organizations = useSelector(
    (s: RootState) => s.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (s: RootState) => s.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );

  const items = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );

  const keyWordRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<{
    keyword: string;
    directoryFilter: string;
    directoryKeyword: string;
    filter: { value: string; useType: OptionUseType };
    status?: string;
    dateType: string;
    dateOptions: string;
    date: SearchDateProps;
    directoryVisible: boolean;
    directoryPoint: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    directoryFixedPoint?: {
      left: string;
      right: string;
      top: string;
      bottom: string;
    };
    datePoint:
      | {
          x: number;
          y: number;
          width: number;
          height: number;
        }
      | undefined;
  }>({
    keyword: props.keyword ?? '',
    directoryFilter: '',
    directoryKeyword: '',
    filter: props.filter ?? { value: '', useType: 'text' },
    status: props.status,
    dateType: '',
    dateOptions:
      props.date && props.date.start === null && props.date.end === null
        ? '전체기간'
        : '',
    date: {
      start: props.date?.start ?? new Date(new Date().getFullYear(), 0, 1),
      end: props.date?.end ?? new Date(),
    },
    directoryVisible: false,
    directoryPoint: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    datePoint: undefined,
  });
  const useDirectory =
    state.filter.useType === 'allDirectory' ||
    state.filter.useType === 'employeeDirectory' ||
    state.filter.useType === 'organizationDirectory';

  let dierctoryTypeToFilter = ['organization', 'employee'] as
    | ('company' | 'organization' | 'employee')[];
  if (state.filter.useType === 'organizationDirectory')
    dierctoryTypeToFilter = ['organization'];
  if (state.filter.useType === 'employeeDirectory')
    dierctoryTypeToFilter = ['employee'];

  /** 중복 사원(겸직부서 있는 경우 대표부서만 보이도록) 제거. */
  const list = items
    .filter((a, index) => {
      if (a.extra.type === 'employee') {
        const id = a.extra.employeeId;
        const organizationId = employees.find(
          (x) => x.id === id,
        )?.representativeOrganizationId;
        if (organizationId) {
          return (
            items.findIndex((x) => {
              return (
                x.id.split('_')[2] === a.id.split('_')[2] &&
                x.id.split('_')[1] === organizationId.toString()
              );
            }) === index
          );
        }
      }
      return (
        items.findIndex((x) => {
          return (
            x.id.split('_')[x.id.split('_').length - 1] ===
            a.id.split('_')[a.id.split('_').length - 1]
          );
        }) === index
      );
    })
    .filter((a) => {
      if (state.filter.useType === 'organizationDirectory')
        return a.extra.type !== 'employee';
      return true;
    });

  /** 기본검색 필터 */
  const handleChangeFilter = (value: string) => {
    if (state.filter.value === value) return;
    const filter = {
      value,
      useType: props.options?.find((a) => a.value === value)?.useType ?? 'text',
    };
    setState((prevState) => ({
      ...prevState,
      filter,
      status: props.statusOptions ? props.statusOptions[0].value : undefined,
      keyword: '',
    }));
  };

  /** 상태 필터 */
  const handleChangeStatus = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  /** 검색 필드에 조직도 menu open 이벤트. */
  const handleDirectoryMenuOpen = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (useDirectory) {
      const rect = event.currentTarget.getBoundingClientRect();

      const documentHeight = document.documentElement.clientHeight;
      const bottom = documentHeight < 504 ? '0;' : '450px;';
      const directoryFixedPoint =
        display === 'phone'
          ? undefined
          : {
              left: `${(rect.x - 24).toString()}px;`,
              right: '24px;',
              top: `${rect.y + rect.height}px;`,
              bottom,
            };
      setState((prevState) => ({
        ...prevState,
        directoryVisible: true,
        directoryPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        directoryFixedPoint,
      }));
    }
  };

  /** 기본검색 키워드 */
  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      directoryFilter: '',
      keyword: event.target.value,
    }));

    if (event.target.value !== '') handleDirectoryMenuOpen(event);
  };

  /** 조직도 아이템 선택. */
  const handleItemClick = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (dierctoryTypeToFilter && !dierctoryTypeToFilter.includes(extra.type))
      return;

    let search = '';
    let directoryKeyword = '';
    if (extra.type === 'company') {
      search = extra.companyName;
      directoryKeyword = `${extra.companyId}`;
    }
    if (extra.type === 'organization') {
      search = extra.organizationName;
      directoryKeyword = `${extra.companyId}_${extra.organizationId}`;
    }
    if (extra.type === 'employee') {
      search = extra.employeeName;
      directoryKeyword = `${extra.companyId}_${extra.organizationId}_${extra.employeeId}`;
    }
    setState((prev) => ({
      ...prev,
      keyword: search,
      directoryKeyword,
      directoryFilter: extra.type,
      directoryVisible: false,
    }));
  };

  /** 기본검색 키워드에서 Enter 누름 */
  const handleSearchKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  /** 기본검색 키워드 비우기 */
  const handleClearKeyword = () => {
    setState((prevState) => ({
      ...prevState,
      directoryFilter: '',
      keyword: '',
    }));
    keyWordRef.current?.focus();
  };

  /** 기본검색 검색 */
  const handleSearch = () => {
    setState((prev) => ({ ...prev, directoryVisible: false }));
    props.onSearch({
      keyword: state.keyword,
      directoryKeyword:
        useDirectory && state.directoryFilter !== ''
          ? state.directoryKeyword
          : undefined,
      directoryFilter:
        useDirectory && state.directoryFilter !== ''
          ? state.directoryFilter
          : undefined,
      filter: state.filter.value,
      date: state.date,
      status: state.status === 'all' ? undefined : state.status,
    });
  };

  /** 검색 기준 시작일 변경. */
  const handleChangeCustomStartDate = (date: Date | null) => {
    const { start, end } = state.date;
    let newEnd = state.date.end;
    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        start: date !== null ? date : prevState.date.start,
      },
    }));
    if (end && date !== null && end < date) {
      newEnd = new Date(date);
      setState((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          end: date,
        },
      }));
    }
    if (start !== null && end !== null) {
      setState((prev) => ({
        ...prev,
        dateType: `${dateFormat(
          date !== null ? date : start,
          'yyyy-MM-DD',
        )} ~ ${dateFormat(newEnd !== null ? newEnd : end, 'yyyy-MM-DD')}`,
      }));
    }
  };

  /** 검색 기준 종료일 변경. */
  const handleChangeCustomEndDate = (date: Date | null) => {
    const { start, end } = state.date;
    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        end: date !== null ? date : prevState.date.end,
      },
    }));
    if (start !== null && end !== null) {
      setState((prev) => ({
        ...prev,
        dateType: `${dateFormat(start, 'yyyy-MM-DD')} ~ ${dateFormat(
          date !== null ? date : end,
          'yyyy-MM-DD',
        )}`,
      }));
    }
  };

  /** 기간 선택 메뉴 닫기. */
  const handleCustomDateClose = () => {
    setState((prev) => ({
      ...prev,
      datePoint: undefined,
    }));
  };

  /** 기간 드롭메뉴 클릭 이벤트. */
  const handleCustomDateClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    const { datePoint } = state;
    if (event !== undefined && datePoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        datePoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        datePoint: undefined,
      }));
    }
  };

  /** 기간 타입 변경 이벤트. */
  const handleChangeDateRadioType = (value: string) => {
    if (value === '') {
      const { start, end } = state.date;
      setState((prev) => ({
        ...prev,
        date: {
          start:
            start === null ? new Date(new Date().getFullYear(), 0, 1) : start,
          end: end === null ? new Date() : end,
        },
        dateType: `${dateFormat(
          start === null ? new Date(new Date().getFullYear(), 0, 1) : start,
          'yyyy-MM-DD',
        )} ~ ${dateFormat(end === null ? new Date() : end, 'yyyy-MM-DD')}`,
        dateOptions: value,
      }));
    } else
      setState((prev) => ({
        ...prev,
        date: {
          start: null,
          end: null,
        },
        dateType: getLocalizedText('전체기간'),
        dateOptions: value,
      }));
  };

  let classname = 'eui-search';
  if (props.className) classname += ` ${props.className}`;
  if (props.full) classname += ' full';

  useEffect(() => {
    let dateTypeString = '';
    let dateOptionsString = '';
    if (props.dateOptions) {
      if (props.date && props.date.start !== null && props.date.end !== null)
        dateTypeString = `${dateFormat(
          props.date.start,
          'yyyy-MM-DD',
        )} ~ ${dateFormat(props.date.end, 'yyyy-MM-DD')}`;
      else {
        dateTypeString = getLocalizedText('전체기간');
        dateOptionsString = '전체기간';
      }
    } else if (
      props.date &&
      props.date.start !== null &&
      props.date.end !== null
    ) {
      dateTypeString = `${dateFormat(
        props.date.start,
        'yyyy-MM-DD',
      )} ~ ${dateFormat(props.date.end, 'yyyy-MM-DD')}`;
    }
    setState((prevState) => ({
      ...prevState,
      keyword: props.keyword ?? '',
      filter: props.filter ?? { value: '', useType: 'text' },
      date: props.date ?? state.date,
      dateType: dateTypeString,
      dateOptions: dateOptionsString,
    }));
  }, [props]);

  useNoInitialEffect(() => {
    handleSearch();
  }, [state.directoryKeyword]);
  // console.log('state.directoryVisible', state.directoryVisible);
  return (
    <>
      <div
        className={classname}
        style={display === 'phone' ? { flexDirection: 'column' } : undefined}
      >
        {props.date && (
          <DropMenu
            style={{ width: 'fit-content', fontWeight: 'normal' }}
            onClick={handleCustomDateClick}
            pressed={state.datePoint !== undefined}
            value={state.dateType}
            label={getLocalizedText('기간')}
          />
        )}
        {state.datePoint && (
          <Menu
            overflow="visible !important"
            containerClassname={
              display === 'phone' ? 'none-overflow' : undefined
            }
            className="ui-search-period-layer"
            point={state.datePoint}
            onClose={handleCustomDateClose}
          >
            {props.dateOptions ? (
              <fieldset className="eui-check-group column">
                <div
                  className="group"
                  style={{
                    margin: '5px',
                    width: display === 'phone' ? '270px' : undefined,
                  }}
                >
                  <Radio
                    label={getLocalizedText('전체기간')}
                    checked={state.dateOptions === '전체기간'}
                    onChange={() => handleChangeDateRadioType('전체기간')}
                  />
                  <Radio
                    label={getLocalizedText('기간')}
                    checked={state.dateOptions === ''}
                    onChange={() => handleChangeDateRadioType('')}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        width: '210px',
                        marginRight: '8px',
                      }}
                    >
                      <div
                        style={{
                          background: 'var(--fill-color)',
                          borderRadius: '4px',
                          lineHeight: 1,
                          padding: '0 16px',
                          height: '36px',
                        }}
                      >
                        <CustomDatePicker
                          disabled={state.dateOptions !== ''}
                          placeholderText={getLocalizedText('연도.월.일')}
                          dateFormat="yyyy-MM-dd"
                          selected={state.date.start}
                          onChange={(date: Date) =>
                            handleChangeCustomStartDate(date)
                          }
                          startDate={state.date.start}
                          endDate={state.date.end}
                          selectsStart
                        />
                      </div>
                      <div style={{ marginRight: '4px' }} />

                      <div
                        style={{
                          background: 'var(--fill-color)',
                          borderRadius: '4px',
                          lineHeight: 1,
                          padding: '0 16px',
                          height: '36px',
                        }}
                      >
                        <CustomDatePicker
                          disabled={state.dateOptions !== ''}
                          placeholderText={getLocalizedText('연도.월.일')}
                          dateFormat="yyyy-MM-dd"
                          selected={state.date.end}
                          onChange={(date: Date) =>
                            handleChangeCustomEndDate(date)
                          }
                          startDate={state.date.start}
                          endDate={state.date.end}
                          minDate={state.date.start}
                          selectsEnd
                        />
                      </div>
                    </div>
                  </Radio>
                </div>
              </fieldset>
            ) : (
              <div
                style={{
                  display: 'inline-flex',
                  width: display !== 'phone' ? '270px' : undefined,
                  marginRight: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 600,
                    margin: 'auto 10px',
                  }}
                >
                  {getLocalizedText('기간')}
                </div>
                <CustomDatePicker
                  placeholderText={getLocalizedText('연도.월.일')}
                  dateFormat="yyyy-MM-dd"
                  selected={state.date.start}
                  onChange={(date: Date) => handleChangeCustomStartDate(date)}
                  startDate={state.date.start}
                  endDate={state.date.end}
                  selectsStart
                />
                <div style={{ marginRight: '4px' }} />
                <CustomDatePicker
                  placeholderText={getLocalizedText('연도.월.일')}
                  dateFormat="yyyy-MM-dd"
                  selected={state.date.end}
                  onChange={(date: Date) => handleChangeCustomEndDate(date)}
                  startDate={state.date.start}
                  endDate={state.date.end}
                  minDate={state.date.start}
                  selectsEnd
                />
              </div>
            )}
          </Menu>
        )}
        <div
          className="basic-search"
          style={
            display === 'phone'
              ? { flexGrow: 'initial', width: 'fit-content', marginTop: '3px' }
              : undefined
          }
        >
          {props.options && (
            <SelectField
              data={props.options}
              value={state.filter.value}
              onChange={handleChangeFilter}
              className="filter"
            />
          )}
          {state.filter.useType !== 'drop' && (
            <TextField
              type="search"
              placeholder={getLocalizedText('검색어')}
              className="keyword"
              value={state.keyword}
              clear={state.keyword !== ''}
              onFocus={
                display !== 'phone' ? handleDirectoryMenuOpen : undefined
              }
              onChange={handleChangeKeyword}
              onKeyDown={handleSearchKeyDown}
              onClear={handleClearKeyword}
              ref={keyWordRef}
            />
          )}
          {props.statusOptions && state.filter.useType === 'drop' && (
            <SelectField
              data={props.statusOptions.filter(
                ({ type }) => type === undefined || type === state.filter.value,
              )}
              value={state.status ?? props.statusOptions[0].value}
              onChange={handleChangeStatus}
              className="filter"
            />
          )}
          {useDirectory && state.directoryVisible && list.length > 0 && (
            <Menu
              fixedPoint={state.directoryFixedPoint}
              point={state.directoryPoint}
              onClose={() => {
                setState((prevState) => ({
                  ...prevState,
                  directoryVisible: false,
                  directoryPoint: {
                    x: 0,
                    y: 0,
                    height: 0,
                    width: 0,
                  },
                  directoryFixedPoint: undefined,
                }));
              }}
            >
              <div className="tree-area">
                <DirectoryTree
                  typeToFilter={dierctoryTypeToFilter}
                  items={list}
                  filter={state.keyword}
                  onItemClick={handleItemClick}
                />
              </div>
            </Menu>
          )}
          <Button
            text={getLocalizedText('검색')}
            iconType
            icon="search"
            className="submit"
            onClick={handleSearch}
          />
        </div>
      </div>
    </>
  );
}

export default DirectorySearch;
