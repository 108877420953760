import { useEffect } from 'react';

import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { statusActions } from '../../../../stores/attendance/dayOffStatus';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceDayOffStatusRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    if (mount)
      Promise.all([dispatch(attendancePreferencesActions.findStandard())]).then(
        () => {
          dispatch(statusActions.list({ search, route: { pathname, search } }));
        },
      );

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default AttendanceDayOffStatusRoute;
