import React, { useState } from 'react';
import Button from '../../../../components/button/Button';
import EuiSetting from '../../../../components/layout/EuiSetting';
import SimpleSearch from '../../../../components/search/SimpleSearch';
import DirectoryTree, {
  DirectoryTreeItem,
  DirectoryTreeItemArg,
} from '../../../../components/tree/DirectoryTree';
import { getLocalizedText } from '../../../../groupware-common/utils/i18n';

/**
 * @property selectedId 선택된 아이디.(? undefined 허용)
 * @property items 트리 아이템 배열.
 * @property onItemClick 트리 아이템 클릭 이벤트.(? undefined 허용)
 * @property onOrganizationSelection 선택이벤트.
 */
type Props = {
  selectedId?: string;
  items: DirectoryTreeItem[];
  onItemClick?(arg: DirectoryTreeItemArg): void;
  selectable?: boolean;
  onOrganizationSelection?(): void;
};

function OrganizationChartContentList(props: Props): JSX.Element {
  const [filter, setFilter] = useState('');

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <EuiSetting.Header title={getLocalizedText('모듈.조직도')}>
        <Button
          text={
            props.selectable
              ? getLocalizedText('취소')
              : getLocalizedText('선택')
          }
          iconType
          icon={props.selectable ? 'times' : 'plus'}
          onClick={props.onOrganizationSelection}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilter} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <DirectoryTree
          selectedId={props.selectedId}
          items={props.items}
          filter={filter}
          onItemClick={props.onItemClick}
        />
      </EuiSetting.Content>
    </>
  );
}

export default OrganizationChartContentList;
