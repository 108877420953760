import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import Chip from '../../../../../components/chip/Chip';
import PostWrite from '../../../../../components/post/PostWrite';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import FeedBack from '../../../../../components/alert/FeedBack';

import { archiveActions } from '../../../../stores/approval/archive';
import AccessDenied from '../../../../../components/error/AccessDenied';
import MenuItem from '../../../../../components/menu/MenuItem';
import Menu from '../../../../../components/menu/Menu';
import Loading from '../../../../../components/loading/Loading';
import { getEmployeeName } from '../../../../../groupware-directory/stores/directory';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ApprovalArchiveAdministratorContainer(props: {
  pathname: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname } = props;
  const dispatch = useAppDispatch();
  const route = useSelector((state: RootState) => state.session.route);
  const principal = useSelector((state: RootState) => state.session.principal);
  const items = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const affiliatedOrganizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );
  const organizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );
  const archiveAdministor = useSelector(
    (state: RootState) => state.approval2.archive.administrators,
  );
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'default');

  const title = categories.find((a) => a.id === 5005)?.name;

  const currentOrganizationId =
    organizationId === 0 ? principal.organizationId : organizationId;
  //  부서장
  const isManager =
    affiliatedOrganizations.find(({ id }) => id === currentOrganizationId)
      ?.manager ?? false;

  const initialState = {
    list: employees
      .filter(
        (a) =>
          a.companyId === principal.companyId &&
          items.some(({ managerId }) => managerId === a.id),
      )
      .map((a) => {
        const updateAt =
          items.find(({ managerId }) => managerId === a.id)?.updateAt ??
          '1000-01-01';
        return {
          managerId: a.id,
          managerName: getEmployeeName(a.companyId, a.id),
          avatar: a.avatar,
          updateAt,
        };
      }),
    userSelectPoint: undefined as
      | { x: number; y: number; width: number; height: number }
      | undefined,
    alertMessage: '',
    modecheck: false,
    saveing: false,
  };

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { list } = state;

      const removeAdministrators = items
        .filter(
          (a) => list.find((b) => a.managerId === b.managerId) === undefined,
        )
        .map(({ managerId, updateAt }) => ({ managerId, updateAt }));

      const remove = await dispatch(
        archiveActions.remove(removeAdministrators),
      );
      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          modecheck: false,
          saveing: false,
        }));
        return;
      }

      const data = list
        .filter(
          (a) => items.find((b) => a.managerId === b.managerId) === undefined,
        )
        .map(({ managerId }) => ({ managerId }));

      const append = await dispatch(archiveActions.designate({ data }));
      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        modecheck: false,
        saveing: false,
        list: list.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { managerId: number; updateAt: string }[]).find(
              (b) => b.managerId === v.managerId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  useEffect(() => {
    if (organizationId !== 0) setLoading(false);
    dispatch(archiveActions.check({ id: organizationId }));
    setState(initialState);
  }, [route.key, organizationId]);

  const members = useMemo(() => {
    const data: { id: number; text: string; avatar: string; name: string }[] =
      [];
    const result = getDirectoryTreeItems({
      companies,
      organizationEmployees,
      employees,
    }).filter((a) => {
      return a.extra.organizationId === organizationId;
    });
    result.forEach((a) => {
      if (
        a.extra.type !== 'employee' ||
        a.extra.employeeId === principal.employeeId
      )
        return;
      data.push({
        id: a.extra.employeeId,
        text: a.text,
        avatar: a.avatar ?? '',
        name: a.extra.employeeName,
      });
    });
    return data;
  }, [companies, organizationEmployees, employees, organizationId]);

  const handleAdministratorsAppend = (arg: {
    id: number;
    name: string;
    avatar: string;
  }) => {
    // 중복체크
    const duplication = list.some((k) => arg.id === k.managerId);
    if (duplication) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: getLocalizedText('이미 존재하는 사원입니다.'),
        userSelectPoint: undefined,
      }));
      return;
    }

    const updateAt =
      archiveAdministor.find((a) => a.managerId === arg.id)?.updateAt ?? '';
    setState((prevState) => ({
      ...prevState,
      list: [
        ...prevState.list,
        {
          managerId: arg.id,
          managerName: arg.name,
          avatar: arg.avatar,
          updateAt,
        },
      ],
      modecheck: true,
      userSelectPoint: undefined,
    }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      alertMessage: '',
    }));
  };

  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { userSelectPoint } = state;
    if (event !== undefined && userSelectPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        userSelectPoint: undefined,
      }));
    }
    if (members.length === 0)
      setState((prev) => ({
        ...prev,
        alertMessage: getLocalizedText('조직에 사원이 존재하지 않습니다.'),
        userSelectPoint: undefined,
      }));
  };

  const handleManagerMenuClose = () => {
    setState((prevState) => ({ ...prevState, userSelectPoint: undefined }));
  };

  const handleSave = () => {
    setState((prevState) => ({
      ...prevState,
      saveing: true,
    }));
  };

  const handleAdministratorsDelete = (managerId: number) => {
    setState((prevState) => ({
      ...prevState,
      modecheck: true,
      list: prevState.list.filter((a) => a.managerId !== managerId),
    }));
  };

  if (loading) return <Loading />;
  const { list, userSelectPoint, alertMessage, modecheck } = state;
  return !isManager ? (
    <AccessDenied />
  ) : (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <PostWrite>
          <PostWrite.Item>
            <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
              {list.map(({ managerId, managerName, avatar }) => {
                return (
                  <Chip
                    key={managerId}
                    label={managerName}
                    avatar={avatar}
                    onDelete={() => handleAdministratorsDelete(managerId)}
                  />
                );
              })}

              {userSelectPoint && (
                <Menu point={userSelectPoint} onClose={handleManagerMenuClose}>
                  {members.map((a) => (
                    <MenuItem
                      key={a.id}
                      label={a.text}
                      avatar={a.avatar}
                      onClick={() =>
                        handleAdministratorsAppend({
                          id: a.id,
                          avatar: a.avatar,
                          name: a.name,
                        })
                      }
                    />
                  ))}
                </Menu>
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
        <EuiToolbar>
          {modecheck && (
            <EuiToolbar.Right>
              <Button
                text="저장"
                variant="contained"
                loading={state.saveing}
                onClick={handleSave}
              />
              {state.modecheck && !state.saveing && (
                <Button text="취소" onClick={() => setState(initialState)} />
              )}
            </EuiToolbar.Right>
          )}
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalArchiveAdministratorContainer;
