import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ContactContentHeadList from './ContactContentHeadList';
import ContactContentBodyList from './ContactContentBodyList';
import { contactsActions, LabelSetting } from '../../../../stores/contacts';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import ContactContentViewDialog from './ContactContentViewDialog';
import PromptMessage from '../../../../../components/prompt/PromptMessage';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogBody from '../../../../../components/dialog/DialogBody';
import { IconType } from '../../../../../groupware-common/types/icon';
import Tree from '../../../../../components/tree/Tree';
import TextField from '../../../../../components/textfield/TextField';
import Button from '../../../../../components/button/Button';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import { labelActions } from '../../../../stores/labels';
import Divider from '../../../../../components/divider/Divider';
import ContactExportDialog from '../common/ContactExportDialog';
import ContactImportDialog from '../common/ContactImportDialog';
import FeedBack from '../../../../../components/alert/FeedBack';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';

function ContactContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const display = useSelector((state: RootState) => state.session.display);
  const categories = useSelector(
    (state: RootState) => state.contact.contacts.category,
  );
  const labels = useSelector((state: RootState) => state.contact.labels.list);
  const list = useSelector((state: RootState) => state.contact.contacts.list);
  const total = useSelector((state: RootState) => state.contact.contacts.total);
  const displayDensity = useSelector(
    (state: RootState) => state.contact.contacts.displayDensity,
  );
  const view = useSelector((state: RootState) => state.contact.contacts.view);
  const labelSetting = useSelector(
    (state: RootState) => state.contact.contacts.labelSetting,
  );

  const rowsPerPage =
    display === 'phone'
      ? queryParams.rowsPerPage ?? 15
      : queryParams.rowsPerPage ?? labelSetting.setting?.rowsPerPage ?? 15;

  /** 라벨 트리 아이템 배열 */
  const labelTreeItems = labels.map((a) => ({
    id: a.id,
    parentId: 0,
    text: a.name,
    icon: 'folder' as IconType,
  }));

  const getFolderTitle = () => {
    switch (folderId) {
      case 'all':
      case 'importance':
      case 'trash':
      case undefined: {
        const name = categories.find((a) => a.id === (folderId ?? 'all'))?.name;
        return name !== undefined ? getLocalizedText(`폴더.${name}`) : '';
      }
      default:
        return labels.find((a) => a.id === b62(folderId))?.name ?? '';
    }
  };

  const [state, setState] = useState<{
    selectedLabelId: number;
    newLabelName: string;

    createLabel: boolean;
    validation: string;
  }>({
    selectedLabelId: 0,
    newLabelName: '',

    createLabel: false,
    validation: '',
  });

  let columns: Column<PostListItemType>[] = [
    { name: 'checked', text: '', type: 'action', visible: true },
    { name: 'importance', text: '', type: 'importance', visible: true },
    {
      name: 'name',
      text: getLocalizedText('이름'),
      type: 'contact',
      visible: true,
    },
    {
      name: 'company',
      text: getLocalizedText('회사'),
      type: 'folder',
      visible: true,
    },
    {
      name: 'phone',
      text: getLocalizedText('전화번호'),
      type: 'form',
      visible: true,
    },
    {
      name: 'email',
      text: getLocalizedText('이메일'),
      type: 'email',
      visible: true,
    },
    {
      name: 'label',
      text: getLocalizedText('라벨'),
      type: 'contact-column',
      visible: true,
    },
  ];

  if (display === 'pc') columns = columns.map((a) => ({ ...a, visible: true }));
  else if (display === 'tablet')
    columns = columns.map((a) => {
      if (a.name === 'label') return { ...a, visible: false };
      return { ...a, visible: true };
    });
  else
    columns = columns.map((a) => {
      if (a.name === 'company' || a.name === 'label')
        return { ...a, visible: false };
      return { ...a, visible: true };
    });
  if (folderId === 'trash')
    columns = columns.filter(
      ({ name }) => name !== 'importance' && name !== 'label',
    );

  if (list.filter((x) => x.checked).length > 0) {
    document.documentElement.setAttribute('data-selecting', 'true');
  } else {
    document.documentElement.removeAttribute('data-selecting');
  }

  /** 검색 이벤트. */
  const handleSearch = (arg: { keyword: string }) => {
    if (arg.keyword === '') delete queryParams.searchWord;
    else queryParams.searchWord = arg.keyword;

    go(pathname, queryParams);
  };

  /** 리스트 아이템 클릭 이벤트 */
  const handleListItemClick = (id: number) => {
    queryParams.dialogMode = 'read';
    const route = utils.getRoute({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
      },
    });
    dispatch(contactsActions.view({ id, route }));
  };

  /** 체크박스 이벤트. */
  const handleListItemCheckedChange = (
    id: number | 'all',
    checked: boolean,
  ) => {
    dispatch(contactsActions.checked({ itemId: id, checked }));
  };

  /** 헤드 버튼 액션 이벤트. */
  const handleHeadAction = (arg: {
    code: string;
    event: React.MouseEvent<Element, MouseEvent>;
  }) => {
    const { code: type } = arg;
    // 목록 설정.
    if (type === 'listSetting') dispatch(sessionActions.setDrawer({ type }));
    // 이전 페이지.
    if (type === 'prev') {
      const pageNo = (queryParams.pageNo || 1) - 1;
      if (pageNo > 0) {
        queryParams.pageNo = pageNo;
        go(pathname, queryParams);
      }
      return;
    }
    // 다음 페이지.
    if (type === 'next') {
      const pageNo = (queryParams.pageNo || 1) + 1;
      if ((rowsPerPage || 1 * pageNo) < total) {
        queryParams.pageNo = pageNo;
        go(pathname, queryParams);
      }
    }
    /**
     * 휴지통으로 이동
     * 영구 삭제
     * 복원
     * 라벨으로 복사
     * 라벨에서 제외
     */
    if (
      type === 'deletes' ||
      type === 'permanentlyDelete' ||
      type === 'undo' ||
      type === 'groupCopy' ||
      type === 'groupCut'
    )
      dispatch(sessionActions.setDialog({ type }));
    // 내보내기.
    if (type === 'export')
      dispatch(
        sessionActions.setDialog({ mode: 'create', type: 'listExport' }),
      );
  };

  /** 수정 이벤트 */
  const handleUpdate = () => {
    delete queryParams.dialogMode;
    queryParams.contentMode = 'update';
    go(pathname, queryParams);
  };

  /** 삭제, 다중삭제 이벤트 */
  const handleDelete = () => {
    delete queryParams.pageNo;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    if (view) {
      dispatch(
        contactsActions.deleteContact({
          peopleId: view.id,
          data: { id: view.id, updateAt: view.updateAt },
          location,
        }),
      );
      return;
    }

    const checkedList = list.filter((a) => a.checked);
    const data = checkedList.map((a) => ({
      id: a.id,
      updateAt: a.updateAt,
    }));
    dispatch(contactsActions.deleteContact({ data, location }));
  };

  /** 영구 삭제 이벤트. */
  const handlePermanentlyDelete = () => {
    delete queryParams.pageNo;
    const checkedList = list.filter((a) => a.checked);
    const data = checkedList.map((a) => ({
      id: a.id,
      updateAt: a.updateAt,
    }));
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(contactsActions.permanentlyDeleteContact({ data, location }));
  };

  /** 복원 이벤트. */
  const handleRestore = () => {
    delete queryParams.pageNo;
    const checkedList = list.filter((a) => a.checked);
    const data = checkedList.map((a) => ({
      id: a.id,
      updateAt: a.updateAt,
    }));
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(contactsActions.restoreContact({ data, location }));
  };

  /** 화면 밀도 변경 이벤트. */
  const handleChangeDensity = (value: PostListDensity) => {
    const setting: LabelSetting = {
      version: '1.0',
      density: value,
      rowsPerPage,
    };
    dispatch(contactsActions.displayDensity(value));
    if (folderId !== 'trash')
      dispatch(
        contactsActions.saveLabelSetting({
          folderId,
          setting,
          updateAt: labelSetting.updateAt,
        }),
      );
  };

  /** 목록 개수 변경 이벤트. */
  const handleChangeListCount = (value: string) => {
    const setting: LabelSetting = {
      version: '1.0',
      density: displayDensity,
      rowsPerPage: parseInt(value, 10),
    };

    const pathmap = !folderId
      ? `${getPathMap('/*', pathname)}`
      : `${getPathMap('/*/*', pathname)}`;
    const query = createQueryString(
      {
        pageNo: undefined,
        rowsPerPage: parseInt(value, 10),
        drawerMode: undefined,
        drawerType: undefined,
      },
      queryParams,
    );
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: pathmap,
        search: query,
      },
    });
    if (folderId !== 'trash')
      dispatch(
        contactsActions.saveLabelSetting({
          folderId,
          setting,
          updateAt: labelSetting.updateAt,
          location,
        }),
      );
    else go(pathmap, query);
  };

  /** 라벨 트리 아이템 선택 이벤트. */
  const handleLabelTreeItemClick = (id: number) => {
    setState((prev) => ({ ...prev, selectedLabelId: id }));
  };

  /** 추가할 라벨 이름 변경 이벤트. */
  const handleChangeNewLabelName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, newLabelName: event.target.value }));
  };

  /** 라벨 추가 이벤트. */
  const handleAddLabel = () => {
    setState((prev) => ({ ...prev, createLabel: true }));
    if (state.newLabelName === '') {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('라벨명을 입력하세요.'),
        createLabel: false,
      }));
      return;
    }

    dispatch(labelActions.saveLabel({ name: state.newLabelName })).then(() => {
      setState((prev) => ({
        ...prev,
        newLabelName: '',
        createLabel: false,
      }));
    });
  };

  /** 중요 표시 변경 이벤트. */
  const handleListItemImportanceChange = (id: number) => {
    const item = list.find((a) => a.id === id);
    if (!item) return;
    dispatch(
      contactsActions.saveUnSaveStar({
        star: !item.isStarred,
        id,
        noLoading: true, // 로딩바 표시 안함.
      }),
    ).then((result) => {
      if (
        (result as { error?: string }).error === undefined &&
        folderId === 'importance'
      ) {
        const { pageNo = 1 } = queryParams;
        const currentPage = (pageNo - 1) * rowsPerPage + 1;
        if (total === currentPage && pageNo > 1) {
          queryParams.pageNo = pageNo - 1;
          go(pathname, queryParams);
        } else
          dispatch(
            contactsActions.list({
              folderId,
              search: getQueryParams(queryParams),
            }),
          );
      }
    });
  };

  /** 리스트 라벨 클릭 이벤트. */
  const handleListLabelClick = (id: number) => {
    go(`/contacts/${b62(id)}`);
  };

  /** 연락처 라벨으로 복사 */
  const handleCopyContactLabel = () => {
    const labelId = state.selectedLabelId;
    const peoples = list.filter((a) => a.checked).map(({ id }) => id);
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search,
        option: 'CLEAR_DIALOG',
      },
    });

    if (labelId === 0) {
      setState((prev) => ({
        ...prev,
        validation: getLocalizedText('선택된 라벨이 없습니다'),
      }));
      return;
    }

    setState((prev) => ({ ...prev, selectedLabelId: 0, newLabelName: '' }));
    dispatch(contactsActions.copyContactLabel({ labelId, peoples, location }));
  };

  /** 연락처 라벨에서 제외 */
  const handleCutContactLabel = () => {
    if (!folderId) return;
    const peoples = list.filter((a) => a.checked).map(({ id }) => id);
    delete queryParams.pageNo;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });

    dispatch(
      contactsActions.cutContactLabel({
        labelId: b62(folderId),
        peoples,
        location,
      }),
    );
  };

  const handleDrawerClose = () => {
    dispatch(sessionActions.setDrawer());
  };

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleCopyDialogClose = () => {
    setState((prev) => ({ ...prev, selectedLabelId: 0, newLabelName: '' }));
    handleDialogClose();
  };

  const renderDrawer = () => {
    if (queryParams.drawerType === 'listSetting') {
      return (
        <ListSetting
          isDisableModeOption
          type="list"
          listCount={`${rowsPerPage}`}
          density={displayDensity}
          onChangeDensity={handleChangeDensity}
          onChangeListCount={handleChangeListCount}
          onClose={handleDrawerClose}
        />
      );
    }

    return null;
  };

  const renderDialog = () => {
    const { dialogMode: mode, dialogType: type } = queryParams;
    if (mode === 'read')
      return (
        <ContactContentViewDialog
          pathname={pathname}
          search={search}
          onClose={handleDialogClose}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      );

    if (mode === 'create' && type === 'import')
      return (
        <ContactImportDialog
          pathname={pathname}
          search={search}
          onClose={handleDialogClose}
        />
      );

    if (mode === 'create' && (type === 'drawerExport' || type === 'listExport'))
      return (
        <ContactExportDialog
          clickType={type === 'drawerExport' ? 'drawer' : 'list'}
          folderId={folderId}
          onClose={handleDialogClose}
        />
      );

    if (type === 'deletes')
      return (
        <PromptMessage
          submitColor="danger"
          submitLabel={getLocalizedText('삭제')}
          submitNoDuplication
          onCancel={handleDialogClose}
          onSubmit={handleDelete}
        >
          {list.filter((a) => a.checked && a.isStarred).length > 0 && (
            <p>{getLocalizedText('중요 연락처가 포함되어 있습니다')}</p>
          )}
          <p>{getLocalizedText('선택하신 연락처를 삭제하시겠습니까?')}</p>
        </PromptMessage>
      );

    if (type === 'permanentlyDelete')
      return (
        <PromptMessage
          submitColor="danger"
          submitLabel={getLocalizedText('삭제')}
          submitNoDuplication
          onCancel={handleDialogClose}
          onSubmit={handlePermanentlyDelete}
        >
          <p>{getLocalizedText('선택하신 연락처를 영구 삭제하시겠습니까?')}</p>
          <p>{getLocalizedText('영구 삭제된 경우 복구되지 않습니다')}</p>
        </PromptMessage>
      );

    if (type === 'undo')
      return (
        <PromptMessage
          submitLabel={getLocalizedText('복원')}
          submitNoDuplication
          onCancel={handleDialogClose}
          onSubmit={handleRestore}
        >
          <p>{getLocalizedText('선택하신 연락처를 복원하시겠습니까?')}</p>
        </PromptMessage>
      );

    if (type === 'groupCopy')
      return (
        <Dialog size="xs" onClose={handleCopyDialogClose}>
          <DialogHeader>
            <DialogTitle>{getLocalizedText('라벨으로 복사')}</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <Tree
              disabledId={b62(folderId)}
              selectedId={state.selectedLabelId}
              onItemClick={handleLabelTreeItemClick}
              items={labelTreeItems}
            />
          </DialogBody>
          <div style={{ display: 'flex', padding: '12px 24px 0px' }}>
            <TextField
              value={state.newLabelName}
              placeholder={getLocalizedText('라벨명을 입력하세요.')}
              onChange={handleChangeNewLabelName}
            />
            <Button
              style={{ marginLeft: '5px' }}
              noDuplication={state.createLabel && state.validation === ''}
              text={getLocalizedText('생성')}
              variant="contained"
              onClick={handleAddLabel}
            />
          </div>
          <Divider />
          <DialogFooter>
            <Button
              text={getLocalizedText('취소')}
              onClick={handleCopyDialogClose}
            />
            <Button
              noDuplication={state.validation === ''}
              text={getLocalizedText('복사')}
              variant="contained"
              onClick={handleCopyContactLabel}
            />
          </DialogFooter>
        </Dialog>
      );

    if (type === 'groupCut')
      return (
        <PromptMessage
          submitNoDuplication
          onCancel={handleDialogClose}
          onSubmit={handleCutContactLabel}
        >
          <p>
            {getLocalizedText('선택하신 연락처의 라벨을 해제하시겠습니까?')}
          </p>
        </PromptMessage>
      );
    return null;
  };

  const renderHead = () => {
    const title = getFolderTitle();
    let toolbarButtons: ActionEventProps[] = labels.some(
      (a) => a.id === b62(folderId),
    )
      ? [
          {
            code: 'mail',
            label: getLocalizedText('메일발송'),
            type: 'icon',
            icon: 'mail',
          },
          {
            code: 'deletes',
            label: getLocalizedText('삭제'),
            type: 'icon',
            icon: 'trash-full',
          },
          // eslint-disable-next-line prettier/prettier
          { code: 'groupCopy', label: getLocalizedText('라벨으로 복사'), type: 'icon', icon: 'user-plus' },
          // eslint-disable-next-line prettier/prettier
          { code: 'groupCut', label: getLocalizedText('라벨에서 제외'), type: 'icon', icon: 'user-times' },
          {
            code: 'export',
            label: getLocalizedText('내보내기'),
            type: 'icon',
            icon: 'export',
          },
        ]
      : [
          {
            code: 'mail',
            label: getLocalizedText('메일발송'),
            type: 'icon',
            icon: 'mail',
          },
          {
            code: 'deletes',
            label: getLocalizedText('삭제'),
            type: 'icon',
            icon: 'trash-full',
          },
          // eslint-disable-next-line prettier/prettier
          { code: 'groupCopy', label: getLocalizedText('라벨으로 복사'), type: 'icon', icon: 'user-plus' },
          {
            code: 'export',
            label: getLocalizedText('내보내기'),
            type: 'icon',
            icon: 'export',
          },
        ];

    if (folderId === 'trash')
      toolbarButtons = [
        // eslint-disable-next-line prettier/prettier
        { code: 'permanentlyDelete', label: getLocalizedText('영구삭제'), type: 'icon', icon: 'trash-full' },
        {
          code: 'undo',
          label: getLocalizedText('복원'),
          type: 'icon',
          icon: 'undo',
        },
      ];
    return (
      <ContactContentHeadList
        title={title}
        toolbarButtons={toolbarButtons}
        pageNo={queryParams.pageNo ?? 1}
        rowsPerPage={rowsPerPage}
        totalCount={total}
        checkedCount={list.filter((x) => x.checked).length}
        searchWord={queryParams.searchWord}
        onSearch={handleSearch}
        onCheckedChange={handleListItemCheckedChange}
        onAction={handleHeadAction}
      />
    );
  };

  const renderBody = () => {
    return (
      <ContactContentBodyList
        pathname={pathname}
        search={search}
        columns={columns.filter(({ visible }) => visible)}
        density={displayDensity}
        list={list}
        onItemClick={folderId === 'trash' ? undefined : handleListItemClick}
        onItemCheckedChange={handleListItemCheckedChange}
        onItemImportanceChange={handleListItemImportanceChange}
        onLabelClick={handleListLabelClick}
      />
    );
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDrawer()}
      {renderDialog()}
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
    </>
  );
}

export default ContactContentContainer;
