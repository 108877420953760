/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { Language } from '../../../../../groupware-common/types';
import {
  EntityIdKeyable,
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

const namespace = 'directory';

/**
 * 마지막 수정시간 조회.
 * @return 엔티티 아이디 키 인터페이스.
 */
async function findLastUpdateAt() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/company/lastupdateat`;
    const response = await axios.get<EntityIdKeyable>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 회사 목록 조회. (관계사 포함)
 * @return 엔티티 아이디 키 배열 객체.
 */
async function findList() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/company/all`;
    const response = await axios.get<EntityIdKeyable[]>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface Company {
  id: number;
  representativesName: string;
  establishedDate: string;
  businessRegistrationNo: string;
  corporationRegistrationNo: string;
  businessType: string;
  businessItem: string;
  phoneNo: string;
  faxNo: string;
  postalCode: string;
  address: string;
  employeeCount: number;
  accountMaxLimit: number; // 계정 최대 한도.
  updateAt: string;
  primaryColor: string; // 회사 기본 테마 색상(8a48fd)
  useExternalDirectory: boolean; // 조직도 연동 사용여부(0: 사용안함, 1 : 사용함)
}

/**
 * 회사 조회.
 * @return 회사 객체.
 */
async function findView() {
  try {
    const { host, headers } = getApiConfig();
    // TODO 관계사 조회 구현 안 됨.
    const url = `${host}/api/directory/v1/company`;
    const response = await axios.get<Company>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface CompanyUpdate {
  names?: { code: Language; value: string }[];
  representativesName?: string;
  establishedDate?: string;
  businessRegistrationNo?: string;
  corporationRegistrationNo?: string;
  businessType?: string;
  businessItem?: string;
  phoneNo?: string;
  faxNo?: string;
  postalCode?: string;
  address?: string;
  employeeCount?: number;
  updateAt: string;
}

/**
 * 회사 수정.
 * @return 엔티티 아이디 키 객체.
 */
async function update(arg: CompanyUpdate) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/company`;
    const response = await axios.put<EntityIdKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const companyApi = {
  namespace,
  findLastUpdateAt,
  findList,
  findView,
  update,
};

export default companyApi;
