import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import { RootState } from '../../groupware-webapp/app/store';
import { getLocalizedText } from '../../groupware-common/utils/i18n';

function AttachmentsList(props: {
  count: number;
  children: React.ReactNode;
  className?: string;
  title?: string;
  saveAll?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  let classname = 'eui-file-list';
  if (props.className) classname += ` ${props.className}`;

  const teamsHostClientType = useSelector(
    (state: RootState) => state.session.teamsHostClientType,
  );
  const isMobileTeams =
    teamsHostClientType &&
    (teamsHostClientType === 'android' || teamsHostClientType === 'ios');

  return (
    <>
      {props.count > 0 && (
        <div className={classname}>
          <div className="list-head">
            <div className="title">
              {props.title ?? getLocalizedText('첨부파일')}&nbsp;
              {getLocalizedText('{{n}}개', { n: props.count })}
            </div>
            {!isMobileTeams && props.saveAll && (
              <Button
                text={getLocalizedText('모두저장')}
                onClick={props.saveAll}
                className="action"
              />
            )}
          </div>
          <div className="list-body" data-list-type="list">
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}

export default AttachmentsList;
