import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import Tree from '../../../../../components/tree/Tree';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  hangul,
} from '../../../../../groupware-common/utils';
import { getLocalizedText } from '../../../../../groupware-common/utils/i18n';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../../stores/resources';

/** 사용 권한 아이템 포함하여 트리 재정렬 */
export function makeTreeData(
  result: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: 'folder' | 'document-check' | 'document-clock';
    seq: number;
  }[],
): {
  id: number;
  parentId: number;
  text: string;
  strings: string[][];
  icon: 'folder' | 'document-check' | 'document-clock';
}[] {
  let treeData: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    seq: number;
    icon: 'folder' | 'document-check' | 'document-clock';
  }[] = [];
  for (let i = 0; i < result.length; i += 1) {
    const { length } = treeData;
    if (i === 0) {
      const item = result.filter((x) => x.icon !== 'folder');
      treeData = item;
    } else {
      for (let a = 0; a < treeData.length; a += 1) {
        const b = treeData[a];
        const item = result.find((x) => x.id === b.parentId);
        if (item && !treeData.some((y) => y.id === item.id))
          treeData.push(item);
      }
    }
    if (length === treeData.length) break;
  }
  treeData = [
    ...treeData
      .filter((a) => a.icon === 'folder')
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ...treeData
      .filter((a) => a.icon !== 'folder')
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
  ];
  return treeData;
}

function ResourceDrawer(props: {
  folderId: number | string | undefined;
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  /** 사용자 환경설정 */
  const basic = useSelector(
    (state: RootState) => state.resource.userPreferences.preferences,
  );
  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'status');

  // 고정 폴더 이름.
  const getDefaultFolderName = (id: string) => {
    const category = categories.find((a) => a.id === id);
    if (!category) return undefined;
    return getLocalizedText(`폴더.${category.name}`);
  };
  const approvalMenu: { label: string; icon: IconType }[] = [];
  if (items.some((a) => a.useApprove && a.isAdmin)) {
    approvalMenu.push({
      label: getDefaultFolderName('approval') ?? '',
      icon: 'clock-check',
    });
  }
  if (items.some((a) => a.useRental && a.isAdmin)) {
    approvalMenu.push({
      label: getDefaultFolderName('return') ?? '',
      icon: 'compare-arrows',
    });
  }

  const resource = useSelector((state: RootState) => state.session.resource);
  const principal = useSelector((state: RootState) => state.session.principal);
  const handleGo = (
    folderId: number | 'create',
    parentId?: number,
    icon?: IconType,
  ) => {
    const { pathname } = props;
    let { hash } = props;
    if (basic?.initianFrame === 0) hash = '';
    else if (basic?.initianFrame === 1) hash = '#weekly';
    else if (basic?.initianFrame === 2) hash = '#monthly';
    if (folderId === 'create') {
      const queryParams = getQueryParams(props.search);
      queryParams.contentMode = 'create';
      dispatch(resourcesActions.updateDataClear());
      go(pathname, getQueryParams(queryParams), props.hash);
    } else if (icon !== ('folder' as const) && parentId)
      go(
        `${getPathMap('/*', pathname)}/${b62(parentId)}/${b62(folderId)}`,
        '',
        hash,
      );
    else go(`${getPathMap('/*', pathname)}/${b62(folderId)}`, '', hash);
    dispatch(sessionActions.mobileNav(false));
  };
  const handleApproval = (icon?: IconType) => {
    if (icon === 'clock-check') go('/resource/approval');
    else go('/resource/return');
    dispatch(sessionActions.mobileNav(false));
  };
  const pathParams = getPathParams('/*/:id/:item', props.pathname);

  /** 트리 아이템 생성. */
  const data = useMemo(() => {
    const result = [
      ...folders.map((a) => {
        return {
          id: a.id,
          parentId: a.parentId,
          text: a.name,
          strings: hangul.d(a.name),
          icon: 'folder' as const,
          seq: a.seq,
        };
      }),
      ...items.map((a) => {
        return {
          id: a.id,
          parentId: a.folderId,
          text: a.name,
          strings: hangul.d(a.name),
          // eslint-disable-next-line prettier/prettier
          icon: a.useApprove ? 'document-check' as const : 'document-clock' as const,
          seq: a.seq,
        };
      }),
    ];
    return makeTreeData(result);
  }, [folders, items]);

  let selectedId = b62(pathParams.id);
  if (pathParams.item) selectedId = b62(pathParams.item);
  let approvalSelected: IconType | undefined;
  if (pathParams.id === 'approval') approvalSelected = 'clock-check';
  if (pathParams.id === 'return') approvalSelected = 'compare-arrows';
  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title={getLocalizedText('모듈.자원')} />
        <EuiSnb.Action>
          <Button
            text={getLocalizedText('예약신청')}
            variant="contained"
            block
            onClick={() => handleGo('create')}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <Nav>
          <MenuItem
            label={getDefaultFolderName('mine')}
            icon="user-my"
            selected={props.folderId === undefined}
            onClick={() => {
              go('/resource');
              dispatch(sessionActions.mobileNav(false));
            }}
          />
          <MenuItem
            label={getDefaultFolderName('shared')}
            icon="connect"
            selected={props.folderId === 'shared'}
            onClick={() => {
              go('/resource/shared');
              dispatch(sessionActions.mobileNav(false));
            }}
          />
        </Nav>
        <Nav title={getLocalizedText('전사자원관리')}>
          <Tree selectedId={selectedId} items={data} onItemClick={handleGo} />
        </Nav>
        {approvalMenu.length > 0 && (
          <Nav title={getLocalizedText('승인관리')}>
            {approvalMenu.map((a) => {
              return (
                <MenuItem
                  key={a.label}
                  label={a.label}
                  selected={approvalSelected === a.icon}
                  icon={a.icon}
                  onClick={() => handleApproval(a.icon)}
                />
              );
            })}
          </Nav>
        )}
        <MenuDivider />
        <Nav>
          <MenuItem
            label={getDefaultFolderName('preferences')}
            icon="cog-fill"
            selected={props.folderId === 'preferences'}
            onClick={() => {
              go('/resource/preferences');
              dispatch(sessionActions.mobileNav(false));
            }}
          />
          {resource === 'teams' &&
            principal.roles.find(
              (role) => role === 'ADMIN' || role === 'RESOURCE_ADMIN',
            ) && (
              <MenuItem
                label={getLocalizedText('자원설정')}
                icon="user-cog"
                onClick={() => go('/adminconsole/resource')}
              />
            )}
        </Nav>
      </EuiSnb.Nav>
    </>
  );
}

export default ResourceDrawer;
