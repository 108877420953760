import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
// import dummy from './dummy';

// const { folders } = dummy;

async function findAllFolder(arg: { organizationId: number }): Promise<
  {
    // companyId: number;
    organizationId: number;
    parentId: number;
    id: number;
    seq: number;
    name: string;
    description: string;
    // updaterId: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  // return new Promise(() => folders.filter((a) => a.organizationId === organizationId));
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function appendFolder(arg: {
  organizationId: number;
  parentId: number;
  name: string;
  description: string;
}): Promise<{
  // companyId: number;
  organizationId: number;
  parentId: number;
  id: number;
  seq: number;
  name: string;
  description: string;
  updaterId: number;
  createAt: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function modifyFolder(arg: {
  organizationId: number;
  id: number;
  parentId: number;
  name: string;
  description: string;
  updateAt: string;
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function removeFolder(arg: {
  organizationId: number;
  id: number;
  updateAt: string;
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function changeFolderSequence(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

// 기록물철 관리자 지정
async function designateManager(arg: {
  companyId: number;
  organizationId: number;
  data: { managerId: number }[];
}): Promise<
  {
    managerId: number;
    updateAt: string;
  }[]
> {
  const { companyId, organizationId, data } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/organizations/${organizationId}/archivemanagers`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

// 기록물철 관리자 조회
async function checkManager(arg: {
  companyId: number;
  organizationId: number;
}): Promise<
  {
    managerId: number;
    updateAt: string;
  }[]
> {
  const { companyId, organizationId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/organizations/${organizationId}/archivemanagers`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

// 기록물철 관리자 삭제
async function removeManager(arg: {
  companyId: number;
  organizationId: number;
  data: { managerId: number; updateAt: string }[];
}): Promise<
  {
    managerId: number;
    updateAt: string;
  }[]
> {
  const { companyId, organizationId, data } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/organizations/${organizationId}/archivemanagers`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

//  기록물철 문서 이관
async function archiveTransfer(arg: {
  companyId: number;
  organizationId: number;
  data: {
    folderIds: number[];
    senderOrganizationId: number;
    receiverOrganizationId: number;
    reason: string;
  };
}): Promise<boolean> {
  const { companyId, organizationId, data } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/organizations/${organizationId}/archivetransfer`;
    const response = await axios.put(url, data, { headers });
    return response.status === 200;
  } catch (ex) {
    throw apiError(ex);
  }
}

// 이관 내역 조회
async function transferHistory(arg: {
  companyId: number;
  organizationId: number;
  pageno?: number;
  rowsperpage?: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected?: boolean;
}): Promise<
  {
    id: number;
    senderOrganizationId: number;
    senderEmployeeId: number;
    receiveOrganizationId: number;
    folderName: string;
    reason: string;
    createAt: string;
  }[]
> {
  const { companyId, organizationId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/organizations/${organizationId}/archivetransfer/logs`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno: arg.pageno,
        rowsperpage: arg.rowsperpage,
        startdate: arg.startdate,
        enddate: arg.enddate,
        searchcode: arg.searchcode,
        searchword: arg.searchword,
        isdirectoryselected: arg.isdirectoryselected,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function totalCount(arg: {
  companyId: number;
  organizationId: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected?: boolean;
}): Promise<number> {
  try {
    const { companyId, organizationId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/apis/approval/v1/companies/${companyId}/organizations/${organizationId}/archivetransfer/logs/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate: arg.startdate,
        enddate: arg.enddate,
        searchcode: arg.searchcode,
        searchword: arg.searchword,
        isdirectoryselected: arg.isdirectoryselected,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const archiveApi = {
  designate: designateManager,
  check: checkManager,
  remove: removeManager,
  transfer: archiveTransfer,
  transferHistory,
  totalCount,
};

export default archiveApi;

export const archiveFolderApi = {
  findAll: findAllFolder,
  append: appendFolder,
  modify: modifyFolder,
  remove: removeFolder,
  change: changeFolderSequence,
};
