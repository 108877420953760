import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { documentApi } from '../../../apis/approval/v1/document';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import Drawer from '../../../../components/drawer/Drawer';
import { createLocalizedTextFactory } from '../../../../groupware-common/utils/i18n';
import EmptyData from '../../../../components/data/EmptyData';
import DrawerList from '../../../../components/drawer/DrawerList';
import { dateTimeFormat } from '../../../../groupware-common/utils/ui';
import Loading from '../../../../components/loading/Loading';
import { IconType } from '../../../../groupware-common/types/icon';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import Avatar from '../../../../components/avatar/Avatar';
import Icon from '../../../../components/icon/Icon';
import DrawerAction from '../../../../components/drawer/DrawerAction';
import Button from '../../../../components/button/Button';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { documentActions } from '../../../stores/approval/document';
import Confirmation from '../../../../components/alert/Confirmation';

/**
 * 결재 상태 드로워.
 * @param documentCompanyId 문서 회사 아이디.
 * @param documentId 문서 아이디.
 * @param documentLinkWait 문서 연동대기 여부.
 * @returns JSX 엘리먼트.
 */
function ApprovalInterworkingLogDrawer(props: {
  documentCompanyId: number;
  documentId: number;
  documentLinkWait?: boolean;
  onClose(): void;
}) {
  // console.log(`${ApprovalInterworkingLogDrawer.name}.render(props)`, props);
  const {
    documentCompanyId,
    documentId,
    documentLinkWait = false,
    onClose,
  } = props;
  const dispatch = useAppDispatch();
  const directory = useDirectory();
  const { employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const [state, setState] = useState<{
    loading: boolean;
    transfer: boolean;
    list: {
      id: number;
      requesterCompanyId: number;
      requesterId: number;
      documentStatus: string;
      interworkingStatus: 'success' | 'fail';
      isExpand: boolean;
      reason: string;
      requestAt: string;
    }[];
  }>({
    loading: true,
    transfer: false,
    list: [],
  });

  const getDocumentStatusText = (status: string): string => {
    switch (status) {
      case 'DRAFT':
        return getLocalizedText('기안');
      case 'REJECT':
        return getLocalizedText('반려');
      case 'COMPLETE':
        return getLocalizedText('완료');
      default:
        return getLocalizedText('진행');
    }
  };

  const getIconAndText = (
    status: 'success' | 'fail',
  ): { icon: IconType; text: string } => {
    switch (status) {
      case 'success':
        return {
          icon: 'check',
          text: getLocalizedText('전송 성공'),
        };
      case 'fail':
        return {
          icon: 'undo-half',
          text: getLocalizedText('전송 실패'),
        };
      default:
        return {
          icon: 'question-circle',
          text: '',
        };
    }
  };

  useEffect(() => {
    async function run() {
      const response = await documentApi.interworkingLogs({
        companyId: documentCompanyId,
        employeeId,
        documentId,
      });
      const list = response.map((a) => ({
        id: a.id,
        requesterCompanyId: a.requesterCompanyId,
        requesterId: a.requesterId,
        documentStatus: getDocumentStatusText(a.documentStatus),
        interworkingStatus: a.responseResult
          ? ('success' as const)
          : ('fail' as const),
        isExpand: false,
        reason: a.responseMessage,
        requestAt: a.requestAt,
      }));
      setState((prev) => ({
        ...prev,
        loading: false,
        list,
      }));
    }
    run();
  }, []);

  const handleExpandVisible = (id: number) => {
    setState((prev) => ({
      ...prev,
      list: prev.list.map((a) => {
        if (a.id === id) return { ...a, isExpand: !a.isExpand };
        return a;
      }),
    }));
  };

  const handleTransferDialogOpen = () => {
    setState((prev) => ({
      ...prev,
      transfer: true,
    }));
  };

  const handleTransferDialogClose = () => {
    setState((prev) => ({
      ...prev,
      transfer: false,
    }));
  };

  const handleTransferStatus = () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    dispatch(
      documentActions.transferInterworkingStatus({
        companyId: documentCompanyId,
        documentId,
      }),
    ).then(async () => {
      const response = await documentApi.interworkingLogs({
        companyId: documentCompanyId,
        employeeId,
        documentId,
      });
      const list = response.map((a) => ({
        id: a.id,
        requesterCompanyId: a.requesterCompanyId,
        requesterId: a.requesterId,
        documentStatus: getDocumentStatusText(a.documentStatus),
        interworkingStatus: a.responseResult
          ? ('success' as const)
          : ('fail' as const),
        isExpand: false,
        reason: a.responseMessage,
        requestAt: a.requestAt,
      }));
      setState((prev) => ({
        ...prev,
        loading: false,
        transfer: false,
        list,
      }));
    });
  };

  const renderDialog = () => {
    if (state.transfer)
      return (
        <Confirmation
          onSubmit={handleTransferStatus}
          onCancel={handleTransferDialogClose}
        >
          {getLocalizedText('연동 결재 상태를 재전송하시겠습니까?')}
        </Confirmation>
      );

    return null;
  };

  const getLocalizedText = createLocalizedTextFactory('approval');

  const { loading, list } = state;
  return (
    <Drawer title={getLocalizedText('연동 로그')} size="sm" onClose={onClose}>
      {documentLinkWait && (
        <DrawerAction>
          <Button
            size="sm"
            text={getLocalizedText('상태 재전송')}
            variant="contained"
            onClick={handleTransferDialogOpen}
          />
        </DrawerAction>
      )}
      <DrawerBody>
        <DrawerList>
          {loading && <Loading />}
          {list.length === 0 ? (
            <EmptyData />
          ) : (
            list.map((a) => {
              const listClassName = `interworking-log ${a.interworkingStatus}`;
              const directoryData = getDirectoryData({
                ...directory,
                companyId: a.requesterCompanyId,
                employeeId: a.requesterId,
              });
              const { icon, text } = getIconAndText(a.interworkingStatus);
              const useExpand = a.reason !== '';

              const reasonChildren = () => {
                if (useExpand) {
                  return (
                    <span style={{ color: 'var(--secondary-text-color)' }}>
                      - {a.reason}
                    </span>
                  );
                }
                return undefined;
              };
              return (
                <DrawerList.Item key={a.id}>
                  <div className={listClassName}>
                    <div className="info">
                      <Avatar
                        name={`${directoryData.employeeName} ${directoryData.jobClassName}`}
                        image={directoryData.avatar}
                        className="avatar"
                      />
                      <Icon className="status" icon={icon} tooltip={text} />
                    </div>
                    <div className="description">
                      <span>
                        <Trans
                          i18nKey="{{employee}} <b>{{documentStatus}}</b> <b className=interworking-{{interworkingStatus}}-status>{{text}}</b>"
                          values={{
                            employee: directoryData.employeeName,
                            documentStatus: a.documentStatus,
                            interworkingStatus: a.interworkingStatus,
                            text,
                          }}
                          ns="approval"
                          components={{ b: <b /> }}
                        />
                      </span>
                      <time className="date">
                        {dateTimeFormat(a.requestAt, 'YYYY-MM-DD(dd) H:mm')}
                      </time>
                    </div>
                    {useExpand && (
                      <div className="more">
                        <button
                          type="button"
                          className="button toggle"
                          aria-label={
                            a.isExpand
                              ? getLocalizedText('접기')
                              : getLocalizedText('펼치기')
                          }
                          onClick={() => handleExpandVisible(a.id)}
                          aria-expanded={a.isExpand}
                        />
                      </div>
                    )}
                  </div>
                  {a.isExpand && (
                    <div className="interworking-log-fail-reason">
                      {reasonChildren()}
                    </div>
                  )}
                </DrawerList.Item>
              );
            })
          )}
        </DrawerList>
      </DrawerBody>
      {renderDialog()}
    </Drawer>
  );
}

export default React.memo(ApprovalInterworkingLogDrawer);
